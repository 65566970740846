import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"
import { PlanEstudio } from "./plan_estudios.model"

@Injectable()
export class PlanEstudios extends NebuBaseService<PlanEstudio> {
    tableName = "plan_estudios"
    singularTableName = "plan_estudio"
    modelClass = PlanEstudio

    constructor(
        http: HttpClient,
        auth: NebuAuthService,
        router: Router,
        errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
