import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter } from "@angular/core"
import { CarreraSede } from "@puntaje/carreras/api-services"
import { Usuario } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { CarrerasPonderaciones } from "../carreras-ponderaciones.service"
import { Subscription, combineLatest } from "rxjs"

@Component({
    selector: "ponderaciones-bar",
    templateUrl: "ponderaciones-bar.component.html",
    styleUrls: ["ponderaciones-bar.component.scss"]
})
export class PonderacionesBarComponent implements OnInit {
    @Input() carreraSede: CarreraSede
    @Input() ponderablesBarra: any[]
    ponderables: any[]
    usuario: Usuario
    promedioByPonderable: any
    simulacionByPonderable: any

    @Input() visibleLabels: boolean = false

    startAnimValues: boolean = false
    enableSmallSize: boolean = false
    enableBigSize: boolean = false

    ponderablesFiltered: any[]
    usuarioPaisString: string = `usuario_${config.plataforma.pais}`
    promedioPonderado: number
    simulacionPonderado: number

    sub: Subscription

    constructor(protected cdr: ChangeDetectorRef, protected carrerasPonderaciones: CarrerasPonderaciones) {}

    ngOnInit() {
        this.sub = combineLatest([
            this.carrerasPonderaciones.ponderables$,
            this.carrerasPonderaciones.usuario$,
            this.carrerasPonderaciones.promedioByPonderable$,
            this.carrerasPonderaciones.simulacionByPonderable$
        ]).subscribe(([ponderables, usuario, promedioByPonderable, simulacionByPonderable]) => {
            this.ponderables = ponderables
            this.usuario = usuario
            this.promedioByPonderable = promedioByPonderable
            this.simulacionByPonderable = simulacionByPonderable

            setTimeout(() => {
                this.startAnimValues = true
            }, 1000)
        })
    }

    adaptToSize(cs: number) {
        this.enableSmallSize = cs > 0 && cs <= 500
        this.enableBigSize = cs > 0 && cs >= 800
        this.cdr.detectChanges()
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
