import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv, AppConfig } from "@puntaje/shared/core"
import { Asignatura } from "./asignaturas.model"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"

@Injectable()
export class Asignaturas extends NebuBaseService<Asignatura> {
    tableName = "asignaturas"
    singularTableName = "asignatura"
    protected objectQuery: Asignatura
    modelClass = () => Asignatura

    // _asignaturas: {[tipoEvaluacion: string]: Asignatura[] };
    // asignaturasPromise: Promise<any>;
    // asignaturasLoaded: boolean = false;

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv,
        protected config: AppConfig
    ) {
        super(http, auth, router, errores, environment)
    }

    public find(id: number, params = null) {
        if (this.config.plataforma.name !== "Nebulosa") {
            const plat_id = this.config.plataforma.id
            params = params || {}
            params = Object.assign(params, { plataforma_id: plat_id })
        }
        return super.find(id, params)
    }

    public unico(id: number, params: any = {}) {
        return this.one(id).concatExtra("unico").get(params)
    }

    // loadAsignaturas() {
    //   this.initAsignaturas();
    //   this.asignaturasPromise = this.getAsignaturas();
    // }

    // loadAsignaturasPromise = () => {
    //   this.initAsignaturas();
    //   this.asignaturasPromise = this.getAsignaturas(true, false);
    //   return this.asignaturasPromise;
    // }

    // get asignaturas() {
    //   !this.asignaturasLoaded && !this.asignaturasPromise && this.loadAsignaturas();
    //   return this._asignaturas;
    // }

    // initAsignaturas() {
    //   this._asignaturas = config.asignaturas.reduce((acc, x) => {
    //     x.evaluacionTipo.forEach(et => {
    //       acc[et] = acc[et] || [];
    //       acc[et].push(x);
    //     });

    //     return acc;
    //   }, {});
    // }

    // getAsignaturas(successValue: any = null, rejectValue: any = null) {
    //   const params: any = {
    //     asignatura: {
    //       asignatura: config.asignaturas.map((a) => a.asignatura)
    //     }
    //   };

    //   let promise = this.where(params).then((asignaturas: Asignatura[]) => {
    //     config.asignaturas.forEach((asignatura) => {
    //       let apiAsignatura = asignaturas.find((a) => a.asignatura == asignatura.asignatura);
    //       asignatura.id = apiAsignatura.id;
    //     });

    //     this.asignaturasLoaded = true;
    //     if (successValue !== undefined && successValue !== null) {
    //       return successValue;
    //     }
    //   }).catch((e) => {
    //     this.asignaturasPromise = null;
    //     if (rejectValue !== undefined && rejectValue !== null) {
    //       return rejectValue;
    //     }
    //   });

    //   return promise;
    // }
}
