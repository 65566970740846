import { Component, OnInit, Input } from "@angular/core"
import { PlanPersonal, PlanClaseService } from "@puntaje/puntaje/api-services"
import { AppConfig, capitalize } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "plan-clase-semanas-alumnos-new",
    templateUrl: "./plan-clase-semanas-alumnos-new.component.html",
    styleUrls: ["./plan-clase-semanas-alumnos-new.component.scss"]
})
export class PlanClaseSemanasAlumnosNewComponent implements OnInit {
    @Input() planPersonalSesion
    @Input() index
    @Input() clasificacionActividadByMaterialId
    @Input() clasificacionContextoByMaterialId
    @Input() type: string
    @Input() planPersonal: PlanPersonal
    @Input() grupoUsuarioId: number
    @Input() vistaPrevia: boolean
    @Input() position: "right" | "left"
    @Input() beforePlanContenidos
    @Input() isLast = false
    @Input() baseRoute = "/plan_clases"
    nombreSesiones: string = config.plataforma.nombreSesion || "Clase"
    nombreSesion = capitalize(this.nombreSesiones)
    aprendolibreCondition: boolean = config.plataforma.name == "Aprendolibre" ? true : false
    dateTimeNow: Date = new Date()
    visible: boolean

    contenidosVistosPorcentaje = 0
    contenidosVistosAnteriorPorcentaje: number
    isFirstElementActive = false

    constructor(protected planClaseService: PlanClaseService) {}

    ngOnInit() {
        if (this.planPersonalSesion.plan_personal_contenidos) {
            this.contenidosVistosPorcentaje = this.calculatePercentaje(this.planPersonalSesion.plan_personal_contenidos)
        }

        if (this.beforePlanContenidos) {
            this.contenidosVistosAnteriorPorcentaje = this.calculatePercentaje(this.beforePlanContenidos)
        }

        this.isFirstElementActive =
            this.contenidosVistosAnteriorPorcentaje === undefined && this.contenidosVistosPorcentaje >= 100

        if (config.plataforma.name == "College Board") this.baseRoute = "/plan_estudios"
        const fecha_inicial_plan_p_sesion: Date = this.planPersonalSesion.fecha_inicial
            ? new Date(this.planPersonalSesion.fecha_inicial)
            : new Date(new Date().setDate(new Date().getDate() + 1))
        this.visible = this.dateTimeNow > fecha_inicial_plan_p_sesion
    }

    calculatePercentaje(contenidos) {
        const totalContenidos = contenidos.length || 0
        let contenidosVistosContador = 0

        contenidos.forEach(contenido => {
            if (this.planClaseService.alumnoVisto(contenido)) {
                contenidosVistosContador += 1
            }
        })

        return (contenidosVistosContador / totalContenidos || 0) * 100
    }
}
