import { Component } from "@angular/core"

@Component({
    selector: "ll-subtitulo, el-subtitulo, rl-subtitulo, l-subtitulo",
    template: `
        <l-slot-content name="subtitulo">
            <ng-content></ng-content>
        </l-slot-content>
    `
})
export class LSubtituloComponent {}
