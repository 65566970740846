import { Component, OnInit, Input } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import {
    Duda,
    Dudas,
    DudaForm,
    DudaCategoria,
    DudaCategorias,
    DudaDudaCategoria,
    GrupoUsuarios,
    GrupoUsuario
} from "@puntaje/puntaje/api-services"
import { AuthService, CheckAchievementAsync, I18nService, InputComponent } from "@puntaje/shared/core"
import { Router, ActivatedRoute } from "@angular/router"
import { Store } from "@ngrx/store"
import { State, CrearAlertaLogros } from "@puntaje/puntaje/store"

@Component({
    selector: "duda-new",
    templateUrl: "duda_form.component.html",
    styleUrls: ["duda_form.component.scss"]
})
export class DudaNewComponent implements OnInit {
    duda: Duda
    form: UntypedFormGroup
    params = DudaForm.formParams

    dudaCategoriaFirst: DudaCategoria
    dudaCategoriaSecond: DudaCategoria
    dudaCategoriaThird: DudaCategoria

    dudaCategoriasFirst: DudaCategoria[]
    dudaCategoriasSecond: DudaCategoria[]
    dudaCategoriasThird: DudaCategoria[]
    @Input() item_id: number
    @Input() item_type: string
    @Input() item_categoria: string
    @Input() showCursos: boolean = true
    @Input() fromProfesor: boolean = false

    grupoUsuario: GrupoUsuario
    grupoUsuarios: GrupoUsuario[]

    ready: boolean = false
    showForm: boolean = false
    btnSaveDisabled: boolean = false

    selectedTab = "cont"
    datos: any

    constructor(
        protected dudaCategoriasService: DudaCategorias,
        protected authService: AuthService,
        protected router: Router,
        protected dudasService: Dudas,
        private activatedRoute: ActivatedRoute,
        protected grupoUsuariosService: GrupoUsuarios,
        protected store: Store<State>,
        private translatorService: I18nService
    ) {
        this.datos = {
            cont: {
                numero: 1,
                nombre: this.translatorService.translate("participa.dudas.nueva.paso_cont.nombre"),
                tab: "cont",
                next: "categ",
                activo: true,
                previous: "curs"
            },
            categ: {
                numero: 2,
                nombre: this.translatorService.translate("participa.dudas.nueva.paso_categ.nombre"),
                tab: "categ",
                next: "prev",
                activo: false,
                previous: "cont"
            },
            prev: {
                numero: 3,
                nombre: this.translatorService.translate("participa.dudas.nueva.paso_prev.nombre"),
                activo: false,
                tab: "prev",
                previous: "categ"
            }
        }

        this.params.duda.label = this.translatorService.translate("participa.dudas.nueva.paso_cont.titulo")
        this.params.detalle.label = this.translatorService.translate("participa.dudas.nueva.paso_cont.detalle")
    }

    async ngOnInit() {
        this.duda = new Duda()
        if (this.showCursos) {
            this.setCurso()
            this.getGrupoUsuario()
        }

        if (this.item_id && this.item_type) {
            this.duda = new Duda()
            this.duda.item_type = this.item_type
            this.duda.item_id = this.item_id
            //No usar texto por defecto.
            //this.duda.duda = this.premadeTitle(this.item_type, this.item_id);
            //this.duda.detalle = this.premadeText(this.item_type, this.item_id);
        }
        this.form = DudaForm.getForm(this.duda)
        await this.getDudaCategoriasFirst()
        this.showForm = true
    }

    setCurso() {
        this.datos["curs"] = {
            numero: 1,
            nombre: this.translatorService.translate("participa.dudas.nueva.paso_curs.nombre"),
            tab: "curs",
            next: "cont",
            activo: true
        }
        this.datos.cont.numero = 2
        this.datos.categ.numero = 3
        this.datos.prev.numero = 4
        this.selectTab("curs")
    }

    async getGrupoUsuario() {
        let params = {
            propios: 1
        }
        params["grupo_usuario_usuario"] = this.fromProfesor ? { propietario: 1 } : { propietario: 0 }
        this.grupoUsuarios = await this.grupoUsuariosService.where(params)
    }

    selectTab(tab) {
        if (this.showCursos) {
            this.datos["curs"].activo = false
        }
        this.datos.cont.activo = false
        this.datos.categ.activo = false
        this.datos.prev.activo = false
        this.selectedTab = tab

        this.datos[tab].activo = true
    }

    checkPremade() {
        return this.duda.duda == this.premadeTitle(this.item_type, this.item_id)
    }

    premadeTitle(item_type: string, item_id: number) {
        switch (item_type) {
            case "Pregunta":
                return "Pregunta #" + item_id
            case "Material":
                return "Material #" + item_id
            default:
                return undefined
        }
    }

    premadeText(item_type: string, item_id: number) {
        switch (item_type) {
            case "Pregunta":
                return "<p>Tengo una duda respecto de esta Pregunta </p>"
            case "Material":
                return "<p>Tengo una duda respecto de este Material </p>"
            default:
                return undefined
        }
    }

    getDudaCategorias(varName: string, dudaCategoriaPadre: DudaCategoria = null) {
        let dudaCategoriaId = dudaCategoriaPadre ? dudaCategoriaPadre.id : null

        this.dudaCategoriasService
            .where({ duda_categoria: { duda_categoria_padre_id: dudaCategoriaId } })
            .then((dudaCategorias: DudaCategoria[]) => {
                this[varName] = dudaCategorias
                if (this.item_categoria && !this[this.getSingle(varName)]) {
                    this[this.getSingle(varName)] = this.getNearestCategoria(dudaCategorias, this.item_categoria)
                    if (this[this.getSingle(varName)]) {
                        this["get" + this.getNext(varName)]()
                    }
                }
            })
    }

    getSingle(varName: string): string {
        switch (varName) {
            case "dudaCategoriasFirst":
                return "dudaCategoriaFirst"
            case "dudaCategoriasSecond":
                return "dudaCategoriaSecond"
            case "dudaCategoriasThird":
                return "dudaCategoriaThird"
            default:
                return "dudaCategoriasFirst"
        }
    }

    getNext(varName: string) {
        switch (varName) {
            case "dudaCategoriasFirst":
                return "DudaCategoriasSecond"
            case "dudaCategoriasSecond":
                return "DudaCategoriasThird"
            case "dudaCategoriasThird":
                return null
            default:
                return null
        }
    }

    /*Por la forma en que esto está implementado, no hay mejores maneras,
    no con la dificultad asignada a la tarea, sientase libre de ponerle casos sucios a gusto*/
    getNearestCategoria(categorias: DudaCategoria[], select: string): DudaCategoria {
        var firstTry = categorias.filter(dc => dc.duda_categoria == select)
        if (firstTry.length > 0) {
            return firstTry[0]
        }
        var secondTry = categorias.filter(dc => dc.duda_categoria.indexOf(select) >= 0)
        if (secondTry.length > 0) {
            return secondTry[0]
        }
        var thirdTry = categorias.filter(dc => select.indexOf(dc.duda_categoria) >= 0)
        if (thirdTry.length > 0) {
            return thirdTry[0]
        }
        return null
    }

    getDudaCategoriasFirst() {
        this.getDudaCategorias("dudaCategoriasFirst")
    }

    getDudaCategoriasSecond() {
        this.dudaCategoriaSecond = undefined
        this.dudaCategoriaThird = undefined

        this.dudaCategoriasSecond = undefined
        this.dudaCategoriasThird = undefined

        this.getDudaCategorias("dudaCategoriasSecond", this.dudaCategoriaFirst)
    }

    getDudaCategoriasThird() {
        this.dudaCategoriaThird = undefined

        this.dudaCategoriasThird = undefined

        this.getDudaCategorias("dudaCategoriasThird", this.dudaCategoriaSecond)
    }

    @CheckAchievementAsync("PN_COMUNIDAD_DUDAS_CREADAS", CrearAlertaLogros)
    publicar() {
        if (this.form.valid) {
            this.btnSaveDisabled = true
            this.duda.duda_duda_categorias = [
                this.dudaCategoriaFirst,
                this.dudaCategoriaSecond,
                this.dudaCategoriaThird
            ]
                .filter(x => x)
                .map(dudaCategoria => {
                    let dudaDudaCategoria = new DudaDudaCategoria()

                    dudaDudaCategoria.duda_categoria_id = dudaCategoria.id

                    return dudaDudaCategoria
                })
            if (this.item_id && this.item_type) {
                this.duda.duda = this.duda.duda + " (" + this.premadeTitle(this.item_type, this.item_id) + ")"
            }
            return this.dudasService.save(this.duda).then((duda: Duda) => {
                this.router.navigate(["dudas"])

                return duda
            })
        }

        return null
    }

    compareDudaCategoria(dc1: DudaCategoria, dc2: DudaCategoria): boolean {
        return dc1 && dc2 && dc1.id === dc2.id
    }

    cancel() {
        this.router.navigate(["dudas"])
    }
}
