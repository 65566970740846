import {
    Component,
    OnInit,
    ViewChild,
    Output,
    Input,
    EventEmitter,
    SimpleChanges,
    ViewChildren,
    QueryList,
    ChangeDetectorRef
} from "@angular/core"
import { Router } from "@angular/router"
import {
    Material,
    Materiales,
    Asignaturas,
    Asignatura,
    Clasificacion,
    ClasificacionTipos,
    Clasificaciones,
    ClasificacionTipo
} from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { ToggleExpandableDirective } from "@puntaje/shared/core"
import { State, selectAsignaturasList } from "@puntaje/puntaje/store"
import { Store } from "@ngrx/store"
import { filter, first } from "rxjs/operators"
import { MaterialPreviewModalComponent } from "./modals/material_preview_modal.component"

@Component({
    selector: "materiales-colapsables-por-clasificacion",
    templateUrl: "materiales_colapsables_por_clasificacion.component.html",
    styleUrls: ["materiales_colapsables_por_clasificacion.component.scss"]
})
export class MaterialesColapsablesPorClasificacionComponent implements OnInit {
    asignatura: Asignatura
    params = Material.show_params
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChildren("firstLevelToggle", { read: ToggleExpandableDirective })
    firstLevelToggle: QueryList<ToggleExpandableDirective>
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    @Input() asignaturaId: number
    @Input() cursoId: number
    @Input() tipoInstrumento: string
    @Input() clasificacionEspecial: string
    @Input() openOnInit: boolean = false
    config: typeof config = config
    @Input() enableSearch: boolean = false
    @Input() enableHideEmpty: boolean = false
    @Input() rutaMaterial: string = "/materiales"
    @ViewChild(MaterialPreviewModalComponent, { static: true }) modalMaterial: MaterialPreviewModalComponent
    renderedAsignatura: Asignatura // esto es para ir alternando entre resultados completos y filtrados
    filteredResult: Asignatura
    enableHighlight: boolean = false
    highlightedText: string
    contadorMateriales: any[]
    showModal: boolean = false

    textoClasificacion: string = ""

    clasificacionIds: number[]

    enableFiltroClasificacionEvTipo: boolean

    constructor(
        protected asignaturasService: Asignaturas,
        protected router: Router,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.loadingLayout.standby()
        this.enableFiltroClasificacionEvTipo = config.plataforma.hasOwnProperty("showFilterCET")
            ? config.plataforma.showFilterCET
            : true
    }

    async showAll() {
        this.loadingLayout.standby()
        this.cdr.detectChanges()

        let queryParams = { with_clasificaciones_with_materiales: 1, material_visible: 1 }
        if (this.cursoId) {
            queryParams["curso_id"] = this.cursoId
        }
        const asignaturas = await this.store
            .select(selectAsignaturasList)
            .pipe(
                filter(x => !!x),
                first()
            )
            .toPromise()
        const asignatura = asignaturas.find(a => a.id === this.asignaturaId)
        if (asignatura.materialClasificacion && asignatura.materialClasificacion[this.tipoInstrumento]) {
            queryParams["clasificacion_tipo"] = asignatura.materialClasificacion[this.tipoInstrumento].clasificacion
            this.textoClasificacion = asignatura.materialClasificacion[this.tipoInstrumento].label
        } else if (
            config.evaluaciones[this.tipoInstrumento || config.plataforma.evaluacionDefault].clasificacionMaterial
                .clasificacion
        ) {
            const clasificacionMaterial: any =
                config.evaluaciones[this.tipoInstrumento || config.plataforma.evaluacionDefault].clasificacionMaterial

            if (
                clasificacionMaterial.clasificacionByCursoId &&
                clasificacionMaterial.clasificacionByCursoId[this.cursoId]
            ) {
                queryParams["clasificacion_tipo"] = clasificacionMaterial.clasificacionByCursoId[this.cursoId]
            } else {
                queryParams["clasificacion_tipo"] = clasificacionMaterial.clasificacion
            }

            this.textoClasificacion =
                config.evaluaciones[
                    this.tipoInstrumento || config.plataforma.evaluacionDefault
                ].clasificacionMaterial.label
        }

        if (this.clasificacionIds && this.clasificacionIds.length > 0) {
            queryParams["and_clasificacion"] = 1
            queryParams["clasificacion"] = {
                id: this.clasificacionIds
            }
        }

        this.asignaturasService.find(this.asignaturaId, queryParams).then((response: Asignatura) => {
            this.asignatura = response
            this.renderedAsignatura = this.asignatura
            this.filterMaterialesOnlyVisible()
            this.setCounts()
            this.onReady.emit(this.asignatura)
            this.loadingLayout.ready()
            if (this.openOnInit) {
                setTimeout(() => {
                    this.firstLevelToggle.forEach(toggle => {
                        toggle.forceOpen()
                    })
                }, 300)
            }
        })
    }

    onChangeFiltroClasificacion(clasificacionIds: number[]) {
        this.clasificacionIds = clasificacionIds
        this.showAll()
    }

    filterMaterialesOnlyVisible() {
        this.renderedAsignatura.clasificaciones.forEach(c => {
            c.materiales_propios = c.materiales_propios.filter(m => m.visible)
            c.clasificaciones_hijas.forEach(h => {
                h.materiales = h.materiales.filter(m => m.visible)
            })
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"] || changes["cursoId"] || changes["tipoInstrumento"]) {
            this.showAll()
        }

        if (changes["asignaturaId"] || changes["tipoInstrumento"]) {
            this.clasificacionIds = []
        }
    }

    setCounts() {
        this.contadorMateriales = []
        let i = 0
        this.renderedAsignatura.clasificaciones.forEach(c => {
            let t = 0
            this.contadorMateriales[i] = {
                propios: c.materiales_propios.length,
                hijos: []
            }
            t = c.materiales_propios.length
            let j = 0
            c.clasificaciones_hijas.forEach(h => {
                this.contadorMateriales[i].hijos[j] = h.materiales.length
                t += h.materiales.length
                j++
            })
            this.contadorMateriales[i].total = t
            i++
        })
    }

    search(currentSearch: string) {
        this.loadingLayout.standby()
        this.filteredResult = this.asignatura.clone()
        // Para cada clasificación
        this.filteredResult.clasificaciones.forEach(clasificacion => {
            // Para cada material de una clasificación
            clasificacion.materiales_propios = clasificacion.materiales_propios.filter(
                m => m.material.toLowerCase().indexOf(currentSearch.toLowerCase()) > -1
            )
            // Para cada sub-clasificación
            clasificacion.clasificaciones_hijas.forEach(clasificacion_hija => {
                clasificacion_hija.materiales = clasificacion_hija.materiales.filter(
                    m => m.material.toLowerCase().indexOf(currentSearch.toLowerCase()) > -1
                )
            })
            clasificacion.clasificaciones_hijas = clasificacion.clasificaciones_hijas.filter(
                ch => ch.materiales.length > 0
            )
        })
        this.filteredResult.clasificaciones = this.filteredResult.clasificaciones.filter(
            cla => cla.materiales_propios.length > 0 || cla.clasificaciones_hijas.length > 0
        )
        this.highlightedText = currentSearch
        this.enableHighlight = true
        this.renderedAsignatura = this.filteredResult
        this.setCounts()
        this.loadingLayout.ready()
        setTimeout(() => {
            this.firstLevelToggle.forEach(toggle => {
                toggle.forceOpen()
            })
        }, 300)
    }

    clear() {
        this.loadingLayout.standby()
        this.enableHighlight = false
        this.renderedAsignatura = this.asignatura
        this.setCounts()
        if (this.filteredResult) {
            setTimeout(() => {
                this.firstLevelToggle.forEach(toggle => {
                    if (this.openOnInit) {
                        toggle.forceOpen()
                    } else {
                        toggle.forceClose()
                    }
                })
            }, 300)
            this.filteredResult = null
        }
        this.loadingLayout.ready()
    }

    openMaterialPreview(material) {
        this.modalMaterial.open(material)
    }
}
