import { CUILayoutsModule } from "@puntaje/shared/cui"
import { NgModule, ModuleWithProviders, Directive } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { UtilModule, ModelViewModule } from "@puntaje/shared/core"
import { LazyLoadImageModule } from "ng-lazyload-image"

// Utils
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { TooltipModule } from "ngx-bootstrap"

// Shared
import { SharedLayoutComponent } from "./shared_layout/shared_layout.component"
import { SimpleSharedLayoutComponent } from "./shared_layout/simple_shared_layout.component"

@NgModule({
    imports: [
        CUILayoutsModule,
        CommonModule,
        FormsModule,
        UtilModule,
        ModelViewModule,
        Angular2FontawesomeModule,
        TooltipModule,
        RouterModule,
        LazyLoadImageModule
    ],
    declarations: [SharedLayoutComponent, SimpleSharedLayoutComponent],
    exports: [SharedLayoutComponent, SimpleSharedLayoutComponent]
})
export class SharedLayoutsModule {
    static forRoot(): ModuleWithProviders<SharedLayoutsModule> {
        return {
            ngModule: SharedLayoutsModule,
            providers: []
        }
    }
}
