import { Component, Directive, ViewContainerRef, ContentChild, ViewChild, ChangeDetectorRef } from "@angular/core"
import { ViewLayout } from "./view_layout.component"

@Component({
    selector: "model-plain-value-layout",
    template: `
        <div class="plain_view">
            <ng-template #viewValue></ng-template>
        </div>
    `,
    styleUrls: ["model_plain_value.component.scss"]
})
export class ModelPlainValueLayout extends ViewLayout {
    constructor(private chref: ChangeDetectorRef) {
        super(chref)
    }
}
