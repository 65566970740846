import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Establecimiento } from "./establecimientos.model"
import { Lugar } from "./lugares.model"
import { TypeformWidgetTipo } from "./typeform_widget_tipos.model"
import { TypeformWidgetTipoUsuario } from "./typeform_widget_tipo_usuarios.model"

export class TypeformWidget extends BaseModel {
    public nombre_referencia: string
    public codigo: string
    public texto_boton: string
    public color_boton: string
    public fecha_inicio: Date
    public fecha_termino: Date
    public typeform_widget_tipo_id: number
    public typeform_widget_tipo_usuario_id: number
    public lugar_id: number
    public establecimiento_id: number

    @SaveType(() => TypeformWidgetTipo) typeform_widget_tipo: TypeformWidgetTipo
    @SaveType(() => TypeformWidgetTipoUsuario) typeform_widget_tipo_usuario: TypeformWidgetTipoUsuario
    @SaveType(() => Lugar) lugar: Lugar
    @SaveType(() => Establecimiento) establecimiento: Establecimiento
}
