import { BaseModel, SaveType } from "@puntaje/shared/core"
import { PushNotificacion } from "./push-notificaciones.model"
import { Usuario } from "./usuarios.model"

export class PushNotificacionUsuario extends BaseModel {
    public push_notificacion_id: number
    public usuario_id: number
    receptor_type: string
    receptor_id: number

    @SaveType(() => PushNotificacion) public push_notificacion: PushNotificacion
    @SaveType(() => Usuario) public usuario: Usuario
}
