import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core"

@Component({
    selector: "contestable-completable",
    templateUrl: "./contestable-completable.component.html",
    styleUrls: ["./contestable-completable.component.scss"]
})
export class ContestableCompletableComponent implements OnChanges {
    @Input() alternativaTexto: any
    @Output() alternativaTextoChange = new EventEmitter<any>()

    textoRespuesta: string

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["alternativaTexto"] && this.alternativaTexto.alternativa_texto) {
            this.textoRespuesta = this.alternativaTexto.alternativa_texto
        }
    }

    onChangeTextoRespuesta() {
        this.alternativaTextoChange.emit({
            alternativa_texto: this.textoRespuesta
        })
    }
}
