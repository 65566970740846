<model-plain-value *ngIf="fileParams" [params]="fileParams" [value]="alternativa" key="alternativa"></model-plain-value>
<img *ngIf="!fileParams && alternativa" [src]="alternativa?.alternativa | trustedurl" />
<cui-button
    class="link"
    *ngIf="appName == 'profesores' && alternativa"
    (click)="downloadFile()"
    [disabled]="isDownloading"
    [isLoading]="isDownloading"
    loaderPosition="right"
>
    Descargar
</cui-button>
