<ll-titulo>{{ "estudiar.plan_personales.titulo" | t }}</ll-titulo>

<!-- Caso normal -->
<menu-evaluacion-tipo-asignaturas
    *ngIf="!inCB"
    [showTitleOverMenu]="false"
    [diagnostico]="true"
></menu-evaluacion-tipo-asignaturas>
<!-- Caso en CB -->
<menu-asignaturas-horizontal
    *ngIf="inCB && asignaturaIds"
    [asignaturaId]="asignaturaId"
    [asignaturaIds]="asignaturaIds"
    (asignaturaChanged)="onSelectAsignatura($event)"
    [tipoEvaluacion]="tipoInstrumento"
></menu-asignaturas-horizontal>

<br />
<div class="row">
    <div [ngClass]="{ 'col-md-3': !inCB }">
        <planes-personales-sidemenu
            [tipoInstrumento]="tipoInstrumento"
            [asignaturaId]="asignaturaId"
            [generadorInstrumentoId]="generadorInstrumentoId"
            (generadorInstrumentoChange)="onSelectGeneradorInstrumento($event)"
            [ngClass]="{ hidden: inCB }"
        ></planes-personales-sidemenu>
    </div>
    <div [ngClass]="{ 'col-md-9': !inCB, 'col-md-12': inCB }">
        <planes-personales
            [asignaturaId]="asignaturaId"
            [generadorInstrumento]="generadorInstrumento"
        ></planes-personales>
    </div>
</div>
