import { AppConfig, BaseForm, I18nService, Validations } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"

declare const config: AppConfig

export class UsuarioEditUssForm extends BaseForm {
    public static get formParams() {
        const i18nService = this.injector?.get(I18nService, null)
        const cursosDefault = config.plataforma.cursosDefault
        const clasificacionesCursos =
            cursosDefault && cursosDefault.length > 0 ? cursosDefault.map(c => c.clasificacion) : undefined
        const clasificacionTipo = config.plataforma.clasificacionTipoNivel || "curso"

        return {
            id: { label: "id", type: "hidden", visible: false },
            nombre: {
                label: i18nService?.translate("usuarios_perfil_edit.nombre") || "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: i18nService?.translate("usuarios_perfil_edit.apellido_paterno") || "Apellido paterno",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_materno: {
                label: i18nService?.translate("usuarios_perfil_edit.apellido_materno") || "Apellido materno",
                type: "text",
                visible: true,
                validations: [Validators.minLength(2)]
            },
            email: {
                label: i18nService?.translate("usuarios_perfil_edit.correo_electronico") || "Correo electrónico",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmailNotSelf }]
            },
            nivel_id: {
                label: i18nService?.translate("usuarios_perfil_edit.curso") || "Curso",
                type: "select",
                visible: true,
                orden: "id",
                options: {
                    class: Clasificaciones,
                    params: {
                        clasificacion: {
                            clasificacion:
                                clasificacionesCursos ||
                                config.evaluaciones[config.plataforma.evaluacionDefault].cursos.map(
                                    curso => curso.clasificacion
                                )
                        },
                        clasificacion_tipo: { clasificacion_tipo: clasificacionTipo },
                        raw: true
                    }
                }
            },
            created_at: {
                label: i18nService?.translate("usuarios_perfil_edit.fecha_creacion") || "Fecha de creación",
                type: "hidden",
                visible: true
            },
            updated_at: {
                label: i18nService?.translate("usuarios_perfil_edit.fecha_actualizacion") || "Fecha de actualización",
                type: "hidden",
                visible: true
            }
        }
    }
}
