<div class="recomendados">
    <loading-layout #loadingLayout>
        <div class="flex-container">
            <ng-container *ngFor="let i of instrumentos; let j = index">
                <div class="mini-table" [class.selected]="i == currentInstrumento">
                    <div class="t_wrapper">
                        <div class="t_row">
                            <div class="t_cell icon_cell">
                                <div class="icon_container">
                                    <ogr-icon name="hacer-prueba" class="icono"></ogr-icon>
                                </div>
                            </div>
                            <div class="t_cell">
                                <h3 class="title">{{ i.nombre }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix">
                        <cui-button
                            type="button"
                            class="pull-right btn_style btn-continue btn-sm"
                            (click)="seleccionar(i)"
                        >
                            Seleccionar
                        </cui-button>
                    </div>
                </div>
            </ng-container>
        </div>
    </loading-layout>
</div>
