import { PipeTransform, Pipe } from "@angular/core"
import { EvaluacionTipoNombre, Diccionario } from "@puntaje/shared/core"
import { TipoInstrumentoDiccionario } from "../tipo_instrumento.diccionario"

@Pipe({ name: "transformInstrumento" })
export class TransformInstrumentoPipe implements PipeTransform {
    transform(tipoInstrumento: string, modificador: string, locale: string = "es") {
        let tipo = tipoInstrumento || "default"
        let diccionario: Diccionario = TipoInstrumentoDiccionario.getDiccionario(locale)
        let diccionarioCustom: Diccionario = TipoInstrumentoDiccionario.getCustomDiccionario(tipo, locale)
        if (!diccionario) {
            console.log(
                "tipoInstrumentoPipe: locale '" +
                    locale +
                    "' no existe en tipoInstrumentoDiccionario para '" +
                    tipo +
                    "'"
            )
        } else {
            if (!diccionario[tipo]) {
                console.log(
                    "tipoInstrumentoPipe: tipoInstrumento '" +
                        tipo +
                        "' no existe en tipoInstrumentoDiccionario."
                )
                if (!diccionario["default"][modificador]) {
                    console.log(
                        "tipoInstrumentoPipe: modificador '" +
                            modificador +
                            "' no existe en tipoInstrumentoDiccionario para 'default'."
                    )
                } else {
                    if(diccionarioCustom[modificador]){
                        return diccionarioCustom[modificador]
                    } else {
                        console.log(
                            "tipoInstrumentoPipe: modificador '" +
                                modificador +
                                "' no existe en tipoInstrumentoDiccionario."
                        )
                    }
                }
            } else {
                if (!diccionario[tipo][modificador]) {
                    console.log(
                        "tipoInstrumentoPipe: modificador '" +
                            modificador +
                            "' no existe en tipoInstrumentoDiccionario."
                    )
                } else {
                    return diccionario[tipo][modificador]
                }
            }
        }
        return tipo
    }
}
