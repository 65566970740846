import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Asignatura } from "./asignaturas.model"
import { Asignaturas } from "./asignaturas.service"
import { Lotes } from "./lotes.service"
import { LotePregunta } from "./lote_preguntas.model"
import { Persona } from "./personas.model"
import { Pregunta } from "./preguntas.model"

export class Lote extends BaseModel {
    public static className: string = "Lote"
    public asignatura_id: number = 0
    public lote: string
    public estado: number
    public visible: boolean
    public presencial: boolean
    public propietario_id: number
    public created_at: Date
    public updated_at: Date

    public nombre_asignatura: string
    public nombre_propietario: string
    public cantidad_preguntas: number

    @SaveType(() => LotePregunta) public lote_preguntas: LotePregunta[]
    @SaveType(() => Pregunta) public preguntas: Pregunta[]
    @SaveType(() => Asignatura) public asignatura: Asignatura
    @SaveType(() => Persona) public propietario: Persona

    constructor(empty: boolean = false) {
        super()

        if (!empty) {
            this.lote_preguntas = []
            this.estado = 1
            this.visible = false
        }
    }

    public static get model_params(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            lote: { label: "Lote", type: "text", visible: true },
            asignatura_id: { label: "Asignatura", type: "select", visible: true, options: { class: Asignaturas } },
            estado: { label: "Estado", type: "text", visible: true },
            visible: { label: "Visible", type: "checkbox", visible: true },
            presencial: { label: "Presencial", type: "checkbox", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: false },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: false },
            lote_preguntas: { type: "models", class: LotePregunta }
        }
    }

    public static get show_params(): any {
        return {
            id: { label: "Id", type: "text", tableVisible: true },
            lote: { label: "Lote", type: "link", tableVisible: true, options: { class: Lotes, key: "id" } },
            nombre_asignatura: {
                label: "Asignatura",
                type: "link",
                tableVisible: true,
                options: { class: Asignaturas, key: "asignatura_id" }
            },
            estado: { label: "Estado", type: "text", tableVisible: true },
            visible: { label: "Visible", type: "boolean", tableVisible: true },
            presencial: { label: "Presencial", type: "boolean", tableVisible: true },
            created_at: { label: "Fecha de creación", type: "date", tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: "date", tableVisible: true }
        }
    }

    public toString() {
        return this.lote
    }
}
