import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { InstrumentoPregunta } from "./instrumento_preguntas.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class InstrumentoPreguntas extends PuntajeBaseService<InstrumentoPregunta> {
    tableName = "instrumento_preguntas"
    singularTableName = "instrumento_pregunta"
    modelClass = InstrumentoPregunta

    constructor(
        http: HttpClient,
        auth: PuntajeAuthService,
        router: Router,
        errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public cambiarPregunta(id: number, params: any = {}) {
        return this.one(id).concatExtra("cambiar_pregunta").patch(params)
    }

    public setPregunta(id: number, params: any) {
        return this.one(id).concatExtra("set_pregunta").post(params)
    }

    public cambiarGrupo(obj: any) {
        return this.all().concatExtra("cambiar_grupo_pregunta").patch(obj)
    }

    public wherePost(params: any = null) {
        return this.all().concatExtra("post").post(params, 180000)
    }

    public cantidadRespuestasPregunta(params: any = null) {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("cantidad_respuestas_pregunta")
            .post(params, 180000)
            .then(res => {
                this.disableIgnoreModel()

                return res
            })
    }
}
