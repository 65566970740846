import { Component, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "estadistica-evaluacion",
    templateUrl: "./estadistica-evaluacion.component.html",
    styleUrls: ["./estadistica-evaluacion.component.scss"]
})
export class EstadisticaEvaluacionComponent implements OnInit {
    evaluacionId: number
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit(): void {
        this.loadingLayout.standby()
        this.route.params.subscribe(params => {
            this.evaluacionId = params["id"]
        })
        this.loadingLayout.ready()
    }
}
