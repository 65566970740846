<loading-layout #loadingLayout>
    <div class="contentCalificacion" *ngIf="!showEdit">
        <div class="acciones">
            <a (click)="changeEdit()">
                Editar
                <fa [name]="'pencil'"></fa>
            </a>
        </div>
        <div class="calificacion">
            {{ evaluacionInstancia.calificacion }}
            <span *ngIf="rangoCalificacion.max == 100">%</span>
        </div>
        <div class="titulo">Calificación</div>
    </div>

    <div class="contentCalificacion" *ngIf="showEdit">
        <div class="acciones">
            <a (click)="changeEdit()">
                Cancelar
                <fa [name]="'times'"></fa>
            </a>
        </div>
        <div class="calificacionInput">
            <div class="input-group">
                <input
                    type="text"
                    class="inputText"
                    [(ngModel)]="calificacion"
                    type="number"
                    step="0.01"
                    [placeholder]="placeHolder"
                />
                <span class="input-group-btn">
                    <button class="btn btn-default btn-link" (click)="calificarManual()">
                        Ingresar
                        <fa [name]="'pencil'"></fa>
                    </button>
                </span>
            </div>
        </div>
        <div class="titulo">Calificación</div>

        {{ mensajeInf }}
    </div>
</loading-layout>
