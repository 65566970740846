import { Injectable, Injector } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { mergeMap, map } from "rxjs/operators"
import { from } from "rxjs"
import { Observable } from "rxjs"
import { Action } from "@ngrx/store"
import { AsignaturaEvaluacionTipos, AsignaturaEvaluacionTipo, GrupoUsuarios } from "@puntaje/puntaje/api-services"
import {
    AsignaturasActionTypes,
    GetAsignaturasConfigSuccess,
    GetAsignaturasConfigSuccessOld
} from "../actions/asignaturas.actions"
import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { AppConfig, AuthService } from "@puntaje/shared/core"

@Injectable()
export class AsignaturasEffects {
    asignaturas$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(AsignaturasActionTypes.GetAsignaturasConfig),
            mergeMap(action => {
                if (!this.config.asignaturas || this.config.asignaturas.length == 0) {
                    return from(
                        new Promise<{
                            asignaturas: Asignatura[]
                            asignaturaEvaluacionTipos: AsignaturaEvaluacionTipo[]
                        }>(async resolve => {
                            const params = {
                                asignatura_evaluacion_tipo: { plataforma: this.config.plataforma.name },
                                evaluacion_tipo: {
                                    evaluacion_tipo: this.config.plataforma.evaluacionTipos.map(et => et.toString())
                                },
                                render_options: {
                                    include: {
                                        evaluacion_tipo: { only: ["evaluacion_tipo"] },
                                        asignatura_material_clasificacion_tipos: null,
                                        asignatura_material_niveles: null
                                    }
                                }
                            }
                            const establecimientos = await this.getEstablecimientoFromGrupoUsuario()

                            const promises = []

                            if (this.config.app.name == "profesores") {
                                params["establecimiento_ids"] = establecimientos
                                params["render_options"]["include"]["asignatura_evaluacion_tipo_establecimientos"] =
                                    null
                                params["grupo_usuario_activo"] = true

                                promises.push(this.asignaturaEvaluacionTiposService.where(params))
                            } else if (this.config.app.name == "alumnos") {
                                if (
                                    establecimientos !== undefined &&
                                    establecimientos !== null &&
                                    establecimientos.length > 0
                                ) {
                                    const paramsEstablecimientos = JSON.parse(JSON.stringify(params)) as any
                                    paramsEstablecimientos["establecimiento_ids"] = establecimientos
                                    paramsEstablecimientos["render_options"]["include"][
                                        "asignatura_evaluacion_tipo_establecimientos"
                                    ] = null
                                    promises.push(this.asignaturaEvaluacionTiposService.where(paramsEstablecimientos))

                                    const paramsPredefinidos = JSON.parse(JSON.stringify(params)) as any
                                    paramsPredefinidos["asignatura_evaluacion_tipo"]["predefinido"] = 1
                                    promises.push(this.asignaturaEvaluacionTiposService.where(paramsPredefinidos))
                                } else {
                                    params["asignatura_evaluacion_tipo"]["predefinido"] = 1
                                    promises.push(this.asignaturaEvaluacionTiposService.where(params))
                                }
                            } else {
                                promises.push(this.asignaturaEvaluacionTiposService.where(params))
                            }

                            Promise.all(promises).then((response: AsignaturaEvaluacionTipo[][]) => {
                                let asignaturaEvaluacionTipos
                                if (response.length > 1) {
                                    const asignaturaEvaluacionTiposEstablecimiento = response[0]
                                    const asignaturaEvaluacionTiposPredefinidos = response[1].filter(aetPredefinido => {
                                        return !asignaturaEvaluacionTiposEstablecimiento.find(aetEst => {
                                            return (
                                                aetEst.asignatura_id == aetPredefinido.asignatura_id &&
                                                aetEst.evaluacion_tipo_id == aetPredefinido.evaluacion_tipo_id
                                            )
                                        })
                                    })
                                    asignaturaEvaluacionTipos = asignaturaEvaluacionTiposEstablecimiento.concat(
                                        asignaturaEvaluacionTiposPredefinidos
                                    )
                                } else {
                                    asignaturaEvaluacionTipos = response.flat()
                                }

                                return this.asignaturasService
                                    .where({
                                        plataforma_id: this.config.plataforma.id,
                                        with_generador_instrumentos: 1,
                                        asignatura: { id: asignaturaEvaluacionTipos.map(a => a.asignatura_id) },
                                        sort_by: "id",
                                        order: "asc"
                                    })
                                    .then((asignaturas: Asignatura[]) => {
                                        resolve({ asignaturas, asignaturaEvaluacionTipos })
                                    })
                            })
                        })
                    ).pipe(
                        map(
                            (data: {
                                asignaturas: Asignatura[]
                                asignaturaEvaluacionTipos: AsignaturaEvaluacionTipo[]
                            }) => new GetAsignaturasConfigSuccess(data)
                        )
                    )
                } else {
                    return from(
                        this.asignaturasService.where({
                            plataforma_id: this.config.plataforma.id,
                            with_generador_instrumentos: 1,
                            asignatura: { asignatura: this.config.asignaturas.map(a => a.asignatura) }
                        })
                    ).pipe(map((data: Asignatura[]) => new GetAsignaturasConfigSuccessOld(data)))
                }
            })
        )
    )

    async getEstablecimientoFromGrupoUsuario() {
        const grupoUsuariosActivos = await this.grupoUsuarioService.where({
            grupo_usuario: {
                id: this.authService.getGrupoUsuariosActivos()
            }
        })

        const establecimientos: any = this.authService.getEstablecimientos() || []

        grupoUsuariosActivos.forEach(grupoUsuario => establecimientos.push(grupoUsuario.establecimiento_id))

        const result = establecimientos.reduce((acc, item) => {
            if (!acc.includes(item)) {
                acc.push(item)
            }
            return acc
        }, [])
        return result
    }

    constructor(
        private actions$: Actions,
        private asignaturasService: Asignaturas,
        private asignaturaEvaluacionTiposService: AsignaturaEvaluacionTipos,
        private authService: AuthService,
        private grupoUsuarioService: GrupoUsuarios,
        private config: AppConfig
    ) {}
}
