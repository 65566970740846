import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import {
    FormModule,
    ModelViewModule,
    BaseModule,
    UtilModule,
    PaginatorModule,
    SorterModule,
    TranslationsModule
} from "@puntaje/shared/core"
import { RouterModule } from "@angular/router"
import { ModalModule } from "ngx-bootstrap/modal"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { CUITextsModule, CUIInputsModule, CUIContainersModule } from "@puntaje/shared/cui"

import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { Ng2HighchartsModule } from "@ogr-sa/ng2-highcharts"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { MyDatePickerModule } from "mydatepicker"
import { BsDatepickerModule } from "ngx-bootstrap/datepicker"

import { EstadisticasComponent } from "./estadisticas.component"
import { InformacionGeneralUsuarioComponent } from "./informacion_general_usuario.component"
import { InformacionGeneralGruposComponent } from "./informacion_general_grupos.component"
import { FiltroEstadisticasDeclaradosComponent } from "./filtro_estadisticas_declarados.component"
import { EstadisticasDeclaradosComponent } from "./estadisticas_declarados.component"
import { EstadisticasUsuariosComponent } from "./estadisticas_usuarios.component"
import { FiltroEstadisticasComponent } from "./filtro_estadisticas.component"
import { EstadisticasCursosComponent } from "./estadisticas_cursos.component"
import { EstadisticasCursoComponent } from "./estadisticas_curso.component"
import { EstadisticasEvaluacionComponent } from "./estadisticas_evaluacion.component"
import { TablaResultadosPorPreguntaComponent } from "./tabla_resultados_por_pregunta.component"
import { ListadoPreguntasMasIncorrectasComponent } from "./listado_preguntas_mas_incorrectas.component"
import { EstadisticasShowcaseBoxComponent } from "./estadisticas_showcasebox.component"
import { EstadisticasProfesorComponent } from "./estadisticas_profesor.component"
import { TablaMiProgresoComponent } from "./tabla_mi_progreso.component"
import { TablaKpiComponent } from "./tabla_kpi/tabla_kpi.component"
import { TablaKpiAutoComponent } from "./tabla-kpi-auto/tabla_kpi_auto.component"
import { PreguntaInformeEstadisticaComponent } from "./pregunta_informe_estadistica.component"
import { ResumenUtpAnualComponent } from "./resumen_utp_anual.component"
import { BarraAvanceAsignaturaComponent } from "./barra_avance_asignatura.component"
import { BarraDistribucionAsignaturaComponent } from "./barra_distribucion_asignatura.component"
import { AvancesAsignaturasComponent } from "./avances_asignaturas.component"
import { DesempenoAsignaturasComponent } from "./desempeno_asignaturas.component"
import { EstadisticasCBShowcaseBoxComponent } from "./estadisticas_cb_showcasebox.component"
//Graficos
// import { EstadisticasGraficoComponent } from "./graficos/estadisticas_grafico.component"
import { GraficoDistribucionTramoPuntajeComponent } from "./graficos/grafico_distribucion_tramo_puntaje.component"
import { GraficoDesempenoMateriaCursoComponent } from "./graficos/grafico_desempeno_materia_curso.component"
import { GraficoDesempenoPromedioComponent } from "./graficos/grafico_desempeno_promedio.component"
import { GraficoDesempenoClasificacionCompuestoComponent } from "./graficos/grafico_desempeno_clasificacion_compuesto.component"
// import { GraficoDesempenoClasificacionComponent } from "./graficos/grafico_desempeno_clasificacion.component"
import { GraficoComparacionPuntajesGruposComponent } from "./graficos/grafico_comparacion_puntajes_grupos.component"
import { GraficoDesempenoEvolutivoComponent } from "./graficos/grafico_desempeno_evolutivo.component"
import { GraficoDesempenoEvolutivoOldComponent } from "./graficos/grafico_desempeno_evolutivo_old.component"
import { ToggleListAsignaturasGraficoComponent } from "./graficos/toggle_list_asignaturas_grafico.component"
import { TimeframeSelectorComponent } from "./graficos/timeframe_selector.component"
import { GraficoMiProgresoComponent } from "./grafico_mi_progreso.component"
import { GraficoMiProgresoCbComponent } from "./grafico_mi_progreso_cb.component"
import { GraficoMiProgresoOldComponent } from "./grafico_mi_progreso_old.component"
import { GraficoMiProgresoMultipleComponent } from "./grafico-mi-progreso-multiple/grafico_mi_progreso_multiple.component"
import { ResumenEstadisticasGeneralesComponent } from "./resumen_estadisticas_generales.component"
import { HorizontalStackedBarComponent } from "./graficos/horizontal_stacked_bar.component"
import { SelectorUsuariosPorColegioComponent } from "./graficos/selector_usuarios_por_colegio.component"

import { FiltroEstadisticasService } from "./filtro_estadisticas.service"
import { EstadisticasEvolutivasService } from "./estadisticas_evolutivas.service"

import { EstadisticasSimpleComponent } from "./estadisticas_simple.component"
import { EstadisticasSimpleAutoComponent } from "./estadisticas-simple-auto/estadisticas_simple_auto.component"
import { EstadisticasEvaluacionPortadaComponent } from "./estadisticas_evaluacion_portada.component"
import { EstadisticasUsuarioPortadaComponent } from "./estadisticas_usuario_portada.component"
import { TopAlumnosAnualComponent } from "./top-alumnos-anual/top-alumnos-anual.component"
import { TopDocentesAnualComponent } from "./top-docentes-anual/top-docentes-anual.component"
import { GraficosTipoClasificacionComponent } from "./graficos-tipo-clasificacion/graficos-tipo-clasificacion.component"
import { GraficosClasificacionTipoContainerComponent } from "./graficos-clasificacion-tipo-container/graficos-clasificacion-tipo-container.component"
import { TablaNumeroEstudiantesPorCursoComponent } from "./tabla-numero-estudiantes-por-curso/tabla-numero-estudiantes-por-curso.component"
import { NumeroEstudiantesActivosComponent } from "./numero-estudiantes-activos/numero-estudiantes-activos.component"
import { TablaNumeroEvaluacionesRealizadasComponent } from "./tabla-numero-evaluaciones-realizadas/tabla-numero-evaluaciones-realizadas.component"
import { TablaDesempenoEstudiantesComponent } from "./tabla-desempeno-estudiantes/tabla-desempeno-estudiantes.component"
import { InformeAnualComponent } from "./informe_anual/informe_anual.component"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { GraficoEvolutivoEvaluacionComponent } from "./graficos/grafico-evolutivo-evaluacion.component"
import { EstadisticasMultiplesProfesorComponent } from "./estadisticas-multiples-profesor/estadisticas-multiples-profesor.component"
import { FiltroEstadisticasMultiplesComponent } from "./filtro-estadisticas-multiples/filtro-estadisticas-multiples.component"
import { EstadisticasMultiplesCursoComponent } from "./estadisticas-multiples-curso/estadisticas-multiples-curso.component"
import { TablaPromedioGeneralYAsignaturasComponent } from "./tablas/tabla-promedio-general-y-asignaturas/tabla-promedio-general-y-asignaturas.component"
import { EstadisticasMultiplesEvaluacionAlumnoComponent } from "./estadisticas-multiples-evaluacion-alumno/estadisticas-multiples-evaluacion-alumno.component"
import { EstadisticasMultiplesCursosComponent } from "./estadisticas-multiples-cursos/estadisticas-multiples-cursos.component"
import { EstadisticasMultiplesEvaluacionComponent } from "./estadisticas-multiples-evaluacion/estadisticas-multiples-evaluacion.component"
import { TablaResumenEvaluacionInstanciaComponent } from "./tablas/tabla-resumen-evaluacion-instancia/tabla-resumen-evaluacion-instancia.component"
import { EstadisticasPortadaComponent } from "./estadisticas-portada/estadisticas-portada.component"
import { EstadisticasMultiplesPortadaComponent } from "./estadisticas-multiples-portada/estadisticas-multiples-portada.component"
import { EstadisticaUsuariosPreguntasFlexComponent } from "./estadistica-usuarios-preguntas-flex/estadistica-usuarios-preguntas-flex.component"
import { ResumenEstadisticasShowcaseboxComponent } from "./resumen-estadisticas-showcasebox/resumen-estadisticas-showcasebox.component"
import { GraficoMiProgresoShowcaseboxComponent } from "./grafico-mi-progreso-showcasebox/grafico-mi-progreso-showcasebox.component"
import { EnsayosRecientesShowcaseboxComponent } from "./ensayos-recientes-showcasebox/ensayos-recientes-showcasebox.component"
import { TablaDesempenoPorUsuarioClasificacionComponent } from "./tabla-desempeno-por-usuario-clasificacion/tabla_desempeno_por_usuario_clasificacion.component"
import { TablaResumenEvaluacionPorAlumnoComponent } from "./tabla-resumen-evaluacion-por-alumno/tabla-resumen-evaluacion-por-alumno.component"
import { EstadisticaEvaluacionAlumnoComponent } from "./estadistica-evaluacion-alumno/estadistica-evaluacion-alumno.component"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { BuscadorUsuarioResumenComponent } from "./buscador-usuario-resumen/buscador-usuario-resumen.component"
import { EstadisticasEvaluacionRecomendadaComponent } from "./estadisticas-evaluacion-recomendada/estadisticas-evaluacion-recomendada.component"
import { FiltroEstadisticasEvaluacionRecomendadaComponent } from "./filtro-estadisticas-evaluacion-recomendada/filtro-estadisticas-evaluacion-recomendada.component"
import { TablaResumenEvaluacionMultiplePorAlumnoComponent } from "./tabla-resumen-evaluacion-multiple-por-alumno/tabla-resumen-evaluacion-multiple-por-alumno.component"
import { InformeAlumnoComponent } from "./informe-alumno/informe-alumno.component"

@NgModule()
export class EstadisticasServices {}

@NgModule({
    imports: [
        Angular2FontawesomeModule,
        BaseModule,
        BsDatepickerModule.forRoot(),
        CommonModule,
        CommonsLayoutsModule,
        CUIContainersModule,
        CUIInputsModule,
        CUITextsModule,
        // EnsayosModule,
        FormModule,
        FormsModule,
        InstrumentosModule,
        ModalModule,
        ModelViewModule,
        MyDatePickerModule,
        NebuModule,
        Ng2HighchartsModule,
        PaginatorModule,
        PopoverModule,
        ProgressbarModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        SorterModule,
        TooltipModule,
        TranslationsModule,
        UtilModule,
        UsuariosModule
    ],

    declarations: [
        EstadisticasComponent,
        InformacionGeneralUsuarioComponent,
        InformacionGeneralGruposComponent,
        FiltroEstadisticasDeclaradosComponent,
        EstadisticasDeclaradosComponent,
        EstadisticasUsuariosComponent,
        FiltroEstadisticasComponent,
        EstadisticasCursosComponent,
        EstadisticasCursoComponent,
        EstadisticasEvaluacionComponent,
        // EstadisticasGraficoComponent,
        TablaResultadosPorPreguntaComponent,
        GraficoDistribucionTramoPuntajeComponent,
        GraficoDesempenoMateriaCursoComponent,
        GraficoDesempenoPromedioComponent,
        GraficoDesempenoClasificacionCompuestoComponent,
        // GraficoDesempenoClasificacionComponent,
        GraficoComparacionPuntajesGruposComponent,
        GraficoDesempenoEvolutivoComponent,
        EstadisticasShowcaseBoxComponent,
        EstadisticasProfesorComponent,
        TablaMiProgresoComponent,
        TablaKpiComponent,
        ListadoPreguntasMasIncorrectasComponent,
        PreguntaInformeEstadisticaComponent,
        ResumenUtpAnualComponent,
        ToggleListAsignaturasGraficoComponent,
        TimeframeSelectorComponent,
        GraficoMiProgresoComponent,
        BarraAvanceAsignaturaComponent,
        BarraDistribucionAsignaturaComponent,
        AvancesAsignaturasComponent,
        DesempenoAsignaturasComponent,
        ResumenEstadisticasGeneralesComponent,
        EstadisticasSimpleComponent,
        HorizontalStackedBarComponent,
        SelectorUsuariosPorColegioComponent,
        EstadisticasEvaluacionPortadaComponent,
        EstadisticasUsuarioPortadaComponent,
        EstadisticasCBShowcaseBoxComponent,
        TopAlumnosAnualComponent,
        TopDocentesAnualComponent,
        GraficosTipoClasificacionComponent,
        GraficosClasificacionTipoContainerComponent,
        TablaNumeroEstudiantesPorCursoComponent,
        NumeroEstudiantesActivosComponent,
        TablaNumeroEvaluacionesRealizadasComponent,
        TablaDesempenoEstudiantesComponent,
        InformeAnualComponent,
        GraficoEvolutivoEvaluacionComponent,
        EstadisticasMultiplesProfesorComponent,
        FiltroEstadisticasMultiplesComponent,
        EstadisticasMultiplesCursoComponent,
        TablaPromedioGeneralYAsignaturasComponent,
        EstadisticasMultiplesEvaluacionAlumnoComponent,
        EstadisticasMultiplesCursosComponent,
        EstadisticasMultiplesEvaluacionComponent,
        TablaResumenEvaluacionInstanciaComponent,
        EstadisticasPortadaComponent,
        EstadisticasMultiplesPortadaComponent,
        GraficoDesempenoEvolutivoOldComponent,
        GraficoMiProgresoOldComponent,
        GraficoMiProgresoCbComponent,
        EstadisticaUsuariosPreguntasFlexComponent,
        ResumenEstadisticasShowcaseboxComponent,
        GraficoMiProgresoShowcaseboxComponent,
        EnsayosRecientesShowcaseboxComponent,
        TablaDesempenoPorUsuarioClasificacionComponent,
        TablaResumenEvaluacionPorAlumnoComponent,
        EstadisticaEvaluacionAlumnoComponent,
        BuscadorUsuarioResumenComponent,
        GraficoMiProgresoMultipleComponent,
        TablaKpiAutoComponent,
        EstadisticasSimpleAutoComponent,
        EstadisticasEvaluacionRecomendadaComponent,
        FiltroEstadisticasEvaluacionRecomendadaComponent,
        TablaResumenEvaluacionMultiplePorAlumnoComponent,
        InformeAlumnoComponent
    ],

    exports: [
        EstadisticasComponent,
        InformacionGeneralUsuarioComponent,
        InformacionGeneralGruposComponent,
        FiltroEstadisticasDeclaradosComponent,
        EstadisticasDeclaradosComponent,
        EstadisticasUsuariosComponent,
        FiltroEstadisticasComponent,
        EstadisticasCursosComponent,
        EstadisticasCursoComponent,
        EstadisticasEvaluacionComponent,
        // EstadisticasGraficoComponent,
        TablaResultadosPorPreguntaComponent,
        GraficoDistribucionTramoPuntajeComponent,
        GraficoDesempenoMateriaCursoComponent,
        GraficoDesempenoPromedioComponent,
        GraficoDesempenoClasificacionCompuestoComponent,
        // GraficoDesempenoClasificacionComponent,
        GraficoComparacionPuntajesGruposComponent,
        GraficoDesempenoEvolutivoComponent,
        EstadisticasShowcaseBoxComponent,
        EstadisticasProfesorComponent,
        TablaMiProgresoComponent,
        TablaKpiComponent,
        ListadoPreguntasMasIncorrectasComponent,
        PreguntaInformeEstadisticaComponent,
        ResumenUtpAnualComponent,
        ToggleListAsignaturasGraficoComponent,
        TimeframeSelectorComponent,
        GraficoMiProgresoComponent,
        BarraAvanceAsignaturaComponent,
        BarraDistribucionAsignaturaComponent,
        AvancesAsignaturasComponent,
        DesempenoAsignaturasComponent,
        ResumenEstadisticasGeneralesComponent,
        EstadisticasSimpleComponent,
        HorizontalStackedBarComponent,
        SelectorUsuariosPorColegioComponent,
        EstadisticasEvaluacionPortadaComponent,
        EstadisticasUsuarioPortadaComponent,
        EstadisticasCBShowcaseBoxComponent,
        TopAlumnosAnualComponent,
        TopDocentesAnualComponent,
        GraficosTipoClasificacionComponent,
        GraficosClasificacionTipoContainerComponent,
        TablaNumeroEstudiantesPorCursoComponent,
        NumeroEstudiantesActivosComponent,
        TablaNumeroEvaluacionesRealizadasComponent,
        TablaDesempenoEstudiantesComponent,
        InformeAnualComponent,
        GraficoEvolutivoEvaluacionComponent,
        EstadisticasMultiplesProfesorComponent,
        FiltroEstadisticasMultiplesComponent,
        EstadisticasMultiplesCursoComponent,
        TablaPromedioGeneralYAsignaturasComponent,
        EstadisticasMultiplesEvaluacionAlumnoComponent,
        EstadisticasMultiplesCursosComponent,
        TablaPromedioGeneralYAsignaturasComponent,
        EstadisticasMultiplesEvaluacionComponent,
        TablaResumenEvaluacionInstanciaComponent,
        EstadisticasPortadaComponent,
        EstadisticasMultiplesPortadaComponent,
        GraficoDesempenoEvolutivoOldComponent,
        GraficoMiProgresoOldComponent,
        GraficoMiProgresoCbComponent,
        EstadisticaUsuariosPreguntasFlexComponent,
        ResumenEstadisticasShowcaseboxComponent,
        GraficoMiProgresoShowcaseboxComponent,
        EnsayosRecientesShowcaseboxComponent,
        TablaDesempenoPorUsuarioClasificacionComponent,
        TablaResumenEvaluacionPorAlumnoComponent,
        TablaResumenEvaluacionMultiplePorAlumnoComponent,
        EstadisticaEvaluacionAlumnoComponent,
        GraficoMiProgresoMultipleComponent,
        TablaKpiAutoComponent,
        EstadisticasSimpleAutoComponent,
        EstadisticasEvaluacionRecomendadaComponent,
        InformeAlumnoComponent
    ]
})
export class EstadisticasModule {
    public static services(): ModuleWithProviders<EstadisticasServices> {
        return {
            ngModule: EstadisticasServices,
            providers: [FiltroEstadisticasService, EstadisticasEvolutivasService]
        }
    }
}
