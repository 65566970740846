import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Notificacion, Notificaciones } from "@puntaje/puntaje/api-services"
import { NotificacionesPendingService } from "../notificaciones_pending.service"
import { GeneradorInstrumentos, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "notificacion-evaluacion",
    templateUrl: "notificacion_evaluacion.component.html",
    styleUrls: ["notificacion.component.scss"]
})
export class NotificacionEvaluacionComponent implements OnInit {
    @Input() notificacion: Notificacion
    @Input() enableMini: boolean = false
    @Input("disableMarkButton") disableMarkButton: boolean = false
    @Input() tipoEvaluacion: string
    showDetailsWhileMini: boolean = false
    usuario_remitente: string = ""
    usuario_remitente_link: string = ""
    asignatura: string = ""
    establecimiento: string = ""
    grupo: string = ""
    evaluacion: string = ""
    fecha_respuestas: Date
    numero_preguntas: string = ""
    tiempo: string = ""
    fecha_creacion: Date
    nombre_evaluacion: string
    fecha_termino: Date
    generador_instrumento: string = ""

    nombreGrupo: string = "curso"
    animateExit: boolean = false
    @Output() markAsNotPending: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        protected notificacionesService: Notificaciones,
        protected notificacionesPendingService: NotificacionesPendingService,
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        protected generadorInstrumentoService: GeneradorInstrumentos
    ) {}

    ngOnInit() {
        this.setStrings()
    }

    setStrings() {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias ? config.plataforma.grupoUsuarioAlias : "curso"
    }

    ngOnChanges() {
        if (this.notificacion) {
            this.notificacion.notificacion = JSON.parse(this.notificacion.notificacion)
            let data = this.notificacion.notificacion as any
            this.usuario_remitente = data.usuario.nombre + " " + data.usuario.apellido_paterno
            this.usuario_remitente_link = "/usuarios/" + data.usuario.id
            if (data.receptor.to_notif_s) {
                this.establecimiento = data.receptor.to_notif_s.establecimiento
            }
            this.asignatura = data.asignatura
            this.grupo = data.receptor.nombre
            this.evaluacion = data.evaluacion.evaluacion
            this.getGenerador(data.instrumento.generador_instrumento_id)

            if (data.evaluacion.fecha_mostrar_respuestas) {
                this.fecha_respuestas = new Date(data.evaluacion.fecha_mostrar_respuestas)
            }
            if (data.evaluacion.fecha_termino) {
                this.fecha_termino = new Date(data.evaluacion.fecha_termino)
            }
            this.numero_preguntas = data.instrumento.numero_preguntas
            this.tiempo = data.instrumento.tiempo
            this.fecha_creacion = new Date(this.notificacion.created_at)
        }
    }

    onClickLink() {
        this.router.navigate([this.notificacion.link])
        /*if(this.notificacion.fecha_visto) {
			this.router.navigate([this.notificacion.link]);
		} else {
			this.disableButton = true;
			this.animateExit = true;
			let n = new Notificacion();
			n.fecha_visto = new Date();
			this.notificacionesService.update(this.notificacion.id, n).then(() => {
				this.notificacionesPendingService.update();
				this.markAsNotPending.emit(this.notificacion.id);
				this.router.navigate([this.notificacion.link]);
			});
		}*/
    }

    getGenerador(generador_instrumento_id) {
        this.generadorInstrumentoService.find(generador_instrumento_id).then((res: GeneradorInstrumento) => {
            this.generador_instrumento = res.generador_instrumento
        })
    }

    toggleDetails() {
        this.showDetailsWhileMini = !this.showDetailsWhileMini
    }
}
