import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router"
import { Observable } from "rxjs"
import { Pregunta } from "../preguntas.model"
import { Preguntas } from "../preguntas.service"
import { SessionService } from "@puntaje/shared/core"

@Injectable()
export class PreguntaGuard implements CanActivate {
    constructor(protected sessionService: SessionService, protected preguntasService: Preguntas) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.sessionService.getPerfiles().includes("Super Administrador")) return true
        if (!this.sessionService.getPerfiles().includes("Docente")) return false

        return this.preguntasService.find(route.params["id"]).then((pregunta: Pregunta) => {
            return this.sessionService.getUserData().persona_id == pregunta.profesor_id
        })
    }
}
