<div class="row">
    <div class="col-md-4 aligner">
        <div class="input-container">
            <ng-container *ngFor="let input of inputs; let i = index">
                <div
                    class="input-sector"
                    [ngClass]="{
                        'has-error': this.touched && !input.valid,
                        'has-success': this.touched && input.valid
                    }"
                >
                    <input
                        #{{input.referenceName}}="ngModel"
                        class="form-control"
                        type="text"
                        maxlength="1"
                        tabindex="0"
                        [attr.aria-label]="input.ariaLabel"
                        [(ngModel)]="input.value"
                        (ngModelChange)="validateInput(input)"
                    />
                    <div role="radiogroup" [attr.aria-label]="input.ariaLabel">
                        <ng-container *ngFor="let option of input.datalist">
                            <br />
                            <label>
                                <ng-container *ngIf="option">
                                    <input
                                        tabindex="0"
                                        type="radio"
                                        name="radio-options-{{ preguntaId }}-{{ i }}"
                                        value="{{ option }}"
                                        [attr.aria-label]="option"
                                        [(ngModel)]="input.value"
                                        (ngModelChange)="validateInput(input)"
                                    />
                                    <span>{{ option }}</span>
                                </ng-container>
                            </label>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="col-md-8">
        <div *ngIf="hasWarnings" class="alert alert-warning" role="alert">
            <li *ngFor="let warning of warnings" [innerHTML]="warning"></li>
        </div>
        <div *ngIf="hasErrors" class="alert alert-danger" role="alert">
            <li *ngFor="let error of errors" [innerHTML]="error"></li>
        </div>
    </div>
</div>
