import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { Lugar } from "./lugares.model"
import { Establecimiento } from "./establecimientos.model"

export class UsuarioEdit extends BaseModel {
    public id: number
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public email: string
    public fecha_nacimiento: Date
    public avatar: string
    public establecimiento_id: number
    public nivel_id: number
    public asignatura_id: number
    public profesor: boolean

    @SaveType(() => Lugar) public lugar: Lugar
    @SaveType(() => Usuario) public usuario: Usuario
    @SaveType(() => Establecimiento) public establecimiento: Establecimiento

    constructor(id: number) {
        super()
        this.id = id
        this.lugar = new Lugar()
        this.usuario = new Usuario()
        this.establecimiento = new Establecimiento()
    }

    public toString() {
        return this.email
    }

    public fromUsuario(usuario: Usuario) {
        this.id = usuario.id
        this.nombre = usuario.nombre
        this.apellido_paterno = usuario.apellido_paterno
        this.apellido_materno = usuario.apellido_materno
        this.email = usuario.email
        this.fecha_nacimiento = usuario.fecha_nacimiento
        this.lugar = usuario.lugar
        this.avatar = usuario.avatar
        this.establecimiento_id = usuario.establecimiento_id
        this.nivel_id = usuario.nivel_id
        this.profesor = usuario.profesor
    }

    public toUsuario() {
        this.usuario.nombre = this.nombre
        this.usuario.apellido_paterno = this.apellido_paterno
        this.usuario.apellido_materno = this.apellido_materno
        this.usuario.fecha_nacimiento = this.fecha_nacimiento
        this.usuario.email = this.email
        this.usuario.lugar_id = this.lugar ? this.lugar.id : null
        this.usuario.establecimiento_id = this.establecimiento_id
        this.usuario.nivel_id = this.nivel_id
        //this.usuario.pais_id = 1; //Generalizar
        //this.usuario.rol_id = 3; //Generalizar, debería siempre ser 3 (alumno)
        this.usuario.avatar = this.avatar
        this.usuario.asignatura_id = this.asignatura_id
        this.usuario.profesor = this.profesor
        return this.usuario
    }
}
