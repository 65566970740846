import { BaseModel } from "@puntaje/shared/core"

export class TipoInstrumento extends BaseModel {
    public static className: string = "TipoInstrumento"
    public tipo_instrumento: string
    public created_at: Date
    public updated_at: Date

    public toString() {
        return this.tipo_instrumento
    }

    public getModelName() {
        return "TipoInstrumento"
    }
}
