<div class="clock" *ngIf="!invisibleClock && evaluacionTiempo">
    <div class="clock-positioning" [class.clock-positiong-hidden]="!showClock">
        <div
            class="clock-container"
            (click)="toggleClock()"
            [class.time-is-running-out]="segundosRestantes < warningTime && segundosRestantes > 0"
            [class.time-ran-out]="segundosRestantes <= 0"
            [class.starting-position]="animateOnStart"
        >
            <div class="clock-icon">
                <fa [name]="'clock-o'"></fa>
            </div>
            <div class="clock-content">
                <div *ngIf="segundosRestantes > 0">
                    <div id="tiempo-ensayo">
                        {{ horas | number: "2.0-2" }}:{{ minutos | number: "2.0-2" }}:{{ segundos | number: "2.0-2" }}
                    </div>
                </div>
                <div id="time_out" *ngIf="segundosRestantes == 0">
                    <div class="time_out_mensaje">{{ "widget_tiempo_ensayo.tiempo_terminado" | t }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
