import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Injectable()
export class AsociacionContactosBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    constructor() {}

    search(asociacion_contacto, asociacionId) {
        let params: any = {}
        if (!asociacion_contacto.id) {
            delete asociacion_contacto.id
        }
        if (asociacion_contacto.gte.fecha) {
            asociacion_contacto.gte.fecha = asociacion_contacto.gte.fecha.toISOString()
        } else {
            delete asociacion_contacto.gte.fecha
        }
        if (asociacion_contacto.lte.fecha) {
            asociacion_contacto.lte.fecha = asociacion_contacto.lte.fecha.toISOString()
        } else {
            delete asociacion_contacto.lte.fecha
        }
        if (asociacion_contacto.efectivo == undefined) {
            delete asociacion_contacto.efectivo
        }
        if (asociacion_contacto.resuelto == undefined) {
            delete asociacion_contacto.resuelto
        }
        if (asociacion_contacto.tipo_contacto_id == undefined) {
            delete asociacion_contacto.tipo_contacto_id
        }
        asociacion_contacto.asociacion_id = asociacionId
        params = { asociacion_contacto }
        this.searchParams.next(params)
    }
}
