import { Injector } from "@angular/core"
import { BaseModel, BooleanView, DateView, DownloadView, SaveType, TextView } from "@puntaje/shared/core"
import { Clasificacion } from "./clasificaciones.model"
import { ClasificacionMaterial } from "./clasificacion_materiales.model"
import { Materiales } from "./materiales.service"
import { MaterialPlataforma } from "./material_plataformas.model"
import { MaterialTipo, TipoMaterial } from "./material_tipos.model"
import { MaterialTipos } from "./material_tipos.service"
import { Plataforma } from "./plataformas.model"
import { Asignatura } from "./asignaturas.model"
import { Persona } from "./personas.model"
import { RevisionRecurso } from "./revision_recursos.model"
import { CertificacionRecurso } from "./certificacion_recursos.model"
import { PersonaExterna } from "./persona_externas.model"

export class Material extends BaseModel {
    public static className: string = "Material"

    public static uploadableToS3 = [
        TipoMaterial.AUDIO,
        TipoMaterial.FILE,
        TipoMaterial.INSTRUMENTO,
        TipoMaterial.MP4,
        TipoMaterial.PPT,
        TipoMaterial.WORD,
        TipoMaterial.EXCEL
    ]

    public material_tipo_id: number
    public material: string
    public visible: boolean
    public comentarios: string
    public created_at: Date
    public updated_at: Date
    public url: string
    public asignatura_id: number
    public contenido: string
    public likes: number
    public dislikes: number
    public profesor_id: number
    public thumbnail: string
    public thumbnail_file: File

    public cantidad_reportes: number
    public className = "Material"

    public file: File
    public fileSrc: ArrayBuffer
    public nombre_asignatura: string

    @SaveType(() => MaterialTipo) public material_tipo: MaterialTipo
    @SaveType(() => Asignatura) public asignatura: Asignatura
    @SaveType(() => PersonaExterna) public persona_externa: PersonaExterna
    @SaveType(() => Persona) public profesor: Persona
    @SaveType(() => Clasificacion) public clasificaciones: Clasificacion[]
    @SaveType(() => ClasificacionMaterial) public clasificacion_materiales: ClasificacionMaterial[]
    @SaveType(() => MaterialPlataforma) public material_plataformas: MaterialPlataforma[]
    @SaveType(() => Plataforma) public plataformas: Plataforma[]
    @SaveType(() => RevisionRecurso) public revision_recursos: RevisionRecurso[]
    @SaveType(() => CertificacionRecurso) public certificacion_recursos: CertificacionRecurso[]

    private _material_tipo_nombre: string

    get material_tipo_nombre() {
        return this.material_tipo?.material_tipo || this._material_tipo_nombre
    }

    set material_tipo_nombre(material_tipo_nombre: string) {
        this._material_tipo_nombre = material_tipo_nombre
    }

    constructor() {
        super()

        this.clasificaciones = []
        this.clasificacion_materiales = []
        this.plataformas = []
        this.material_plataformas = []
    }

    public static get show_params(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            material_tipo_nombre: {
                label: "Tipo de Material",
                type: TextView,
                tableVisible: true,
                options: { class: MaterialTipos, key: "material_tipo_id" }
            },
            material: { label: "Nombre material", type: TextView, tableVisible: true },
            visible: { label: "Visible", type: BooleanView, tableVisible: true },
            comentarios: { label: "Comentarios", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true },
            download: { label: "Link", type: DownloadView, tableVisible: true, options: { class: Materiales } }
        }
    }

    public toString() {
        return this.material
    }

    public getExtension() {
        return this.url.split(".").pop()
    }

    public getS3Params(material_tipo_id: number = 0) {
        return this.file
            ? {
                  key: this.cleanFileNameForUpload(this.file.name),
                  model_name: "material",
                  extra_params: {
                      material_tipo_id: material_tipo_id || this.material_tipo_id
                  }
              }
            : {}
    }

    public getS3ParamsThumb() {
        return this.thumbnail_file
            ? {
                  key: this.cleanFileNameForUpload(this.thumbnail_file.name),
                  model_name: "material",
                  extra_params: {
                      thumbnail: this.cleanFileNameForUpload(this.thumbnail_file.name)
                  }
              }
            : {}
    }

    public cleanFileNameForUpload(fileName: string) {
        return fileName.replace(/[#%&{}<>'"@:$=\|\`\+\/\!\?\*\\]/g, "")
    }

    public isFile() {
        if (this.url) {
            return this.url.split("/").pop().split(".").length > 1
        } else return false
    }

    public getLikePromise(injector: Injector, params: any = {}): Promise<any> {
        return injector.get(Materiales).like(this.id, params)
    }

    public getDislikePromise(injector: Injector, params: any = {}): Promise<any> {
        return injector.get(Materiales).dislike(this.id, params)
    }

    public getSentimentPromise(injector: Injector, params: any = {}): Promise<any> {
        return injector.get(Materiales).sentiment(this.id, params)
    }

    public addPlataforma(id) {
        let material_plataforma = new MaterialPlataforma()
        material_plataforma.plataforma_id = id
        this.material_plataformas.push(material_plataforma)
    }

    public removePlataforma(id) {}
}
