import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { Lugar } from "./lugares.model"

export class UsuarioEditUss extends BaseModel {
    public id: number
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public email: string
    public avatar: string
    public nivel_id: number

    @SaveType(() => Usuario) public usuario: Usuario

    constructor(id: number) {
        super()
        this.id = id
        this.usuario = new Usuario()
    }

    public toString() {
        return this.email
    }

    public fromUsuario(usuario: Usuario) {
        this.id = usuario.id
        this.nombre = usuario.nombre
        this.apellido_paterno = usuario.apellido_paterno
        this.apellido_materno = usuario.apellido_materno
        this.email = usuario.email
        this.avatar = usuario.avatar
        this.nivel_id = usuario.nivel_id
    }

    public toUsuario() {
        this.usuario.nombre = this.nombre
        this.usuario.apellido_paterno = this.apellido_paterno
        this.usuario.apellido_materno = this.apellido_materno
        this.usuario.email = this.email
        this.usuario.nivel_id = this.nivel_id
        //this.usuario.pais_id = 1; //Generalizar
        //this.usuario.rol_id = 3; //Generalizar, debería siempre ser 3 (alumno)
        this.usuario.avatar = this.avatar
        return this.usuario
    }
}
