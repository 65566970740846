import { SpanishHelper } from "./spanish.helper"

export class LanguagesHelper {
    public static getHelper(locale: string): any {
        switch (locale) {
            default: {
                return SpanishHelper
            }
        }
    }
}
