<div class="row">
    <div class="col-md-9">
        <ul class="list-group">
            <loading-layout>
                <li
                    *ngFor="let tutor of tutores"
                    class="list-group-item d-flex justify-content-between align-items-center"
                >
                    <cui-button class="btn_style btn-xs" (click)="quitar(tutor)">
                        <ogr-icon name="cruz-circle" class="icono"></ogr-icon>
                    </cui-button>
                    &nbsp;&nbsp;
                    {{ tutor.email }}
                    <span class="badge badge-pill" [class.badge-success]="tutor.confirmado">{{ estado(tutor) }}</span>
                </li>
                <li *ngIf="adding" class="list-group-item d-flex justify-content-between align-items-center">
                    <label for="email"></label>
                    <form-input
                        [form]="form"
                        [params]="params['email']"
                        [(value)]="tutor['email']"
                        [key]="'email'"
                    ></form-input>
                    <cui-button *ngIf="!limit" class="btn_style btn-sm" (click)="confirmar()">Confirmar</cui-button>
                </li>
            </loading-layout>
        </ul>
    </div>
    <div class="col-md-3">
        <cui-button class="btn_style btn-sm pull-right" (click)="add()">Agregar Tutor</cui-button>
    </div>
</div>
