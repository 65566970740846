import { BaseModel } from "@puntaje/shared/core"

export class UsuarioTec extends BaseModel {
    public telefono: string
    public usuario_id: number

    public promedio: string
    constructor() {
        super()
    }

    public toString() {
        return this.usuario_id
    }
}
