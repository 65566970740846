import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "recursos_interactivos.component.html",
    styleUrls: ["recursos_interactivos.component.scss"]
})
export class RecursosInteractivosComponent {
    nombreGrupo: string = config.plataforma.grupoUsuarioAlias ? config.plataforma.grupoUsuarioAlias : "curso"

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.materiales.recursos_interactivos")
    }
}
