import { SaveType } from "@puntaje/shared/core"
import { Lote } from "./lotes.model"
import { PlanEstudioComponente } from "./plan_estudio_componentes.model"
import { PlanEstudioLotes } from "./plan_estudio_lotes.service"

export class PlanEstudioLote extends PlanEstudioComponente {
    public lote_id: number
    public tipo: string = "Lote"

    // Campo virtual para mandarle al servidor, no existe en la bd (por ahora)
    generador_instrumento_id: number

    @SaveType(() => Lote) public lote: Lote

    constructor(empty: boolean = false) {
        super(empty)
        if (empty) {
            this.lote = new Lote()
        }
    }

    public static get serviceClass() {
        return PlanEstudioLotes
    }
}
