import {
    ChangeDetectorRef,
    Component,
    DoCheck,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core"
import { AbstractControl, FormArray, FormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { I18nService } from "../i18n/i18n.service"
import { Subscription } from "rxjs"
import { distinctUntilChanged } from "rxjs/operators"
import { BaseModel } from "../api/base.model"
import { BaseService } from "../api/base.service"
import { Validations } from "./validations"

@Component({
    templateUrl: "input-error.component.html",
    styleUrls: ["input.component.scss"],
    selector: "form-input-error"
})
export class InputErrorComponent {
    @Input() params: any
    @Input() value: any
    @Input() key: string
    @Input() form: UntypedFormGroup
    @Input() disabled: boolean = false
    @Input() withLabel: boolean = true
    @Input() xhr_upload: any
    @Input() preload_options: BaseModel[] = []
    @Input() placeholder: string
    @Input() tooltip: string
    @Input() enablePasswordBar: boolean = false
    @Input() customError = false
    @Input() removeOnDestroy = false
    @Input() control: AbstractControl & { global?: boolean }

    validations: typeof Validations = Validations

    constructor(protected injector: Injector, protected cdr: ChangeDetectorRef, public translator: I18nService) {}

    ngOnInit() {
        this.control = this.form.get(this.key)
    }
}
