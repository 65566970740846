<ll-titulo>{{ "notificaciones.titulo" | t }}</ll-titulo>
<ll-tabs>
    <ul class="nav nav-tabs" #tabList>
        <li role="presentation" class="active in">
            <a data-toggle="tab" href="#pending">{{ "notificaciones.pendientes" | t }}</a>
        </li>
        <li role="presentation">
            <a data-toggle="tab" href="#notifications">{{ "notificaciones.historial" | t }}</a>
        </li>
    </ul>
</ll-tabs>

<div class="tab-content">
    <div id="pending" class="tab-pane fade active in">
        <notificaciones [pendientes]="true"></notificaciones>
    </div>
    <div id="notifications" class="tab-pane fade">
        <notificaciones></notificaciones>
    </div>
</div>
