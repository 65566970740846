import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { Duda, Dudas, DudaCategoria, DudaCategorias } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { DudasBuscadorService } from "./dudas_buscador.service"

@Component({
    selector: "dudas-categorias",
    templateUrl: "dudas_categorias.component.html",
    styleUrls: ["dudas_categorias.component.scss"]
})
export class DudasCategoriasComponent implements OnInit {
    categorias: DudaCategoria[] = []
    hide_sub: boolean = true
    sub_categorias = {}
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected router: Router,
        protected dudacategoriasService: DudaCategorias,
        protected dudasBuscadorService: DudasBuscadorService
    ) {}

    ngOnInit() {
        this.dudacategoriasService.where({}).then((dudaCategorias: DudaCategoria[], total: number) => {
            this.categorias = dudaCategorias.filter(c => !c.duda_categoria_padre_id)
            dudaCategorias
                .filter(c => c.duda_categoria_padre_id)
                .forEach(c => {
                    if (this.sub_categorias[c.duda_categoria_padre_id]) {
                        this.sub_categorias[c.duda_categoria_padre_id].push(c)
                    } else {
                        this.sub_categorias[c.duda_categoria_padre_id] = [c]
                    }
                })
            this.loadingLayout.ready()
        })
    }

    ir_categoria(dudaCategoria: DudaCategoria) {
        this.router.navigate(["/dudas"], { queryParams: { categoria_id: dudaCategoria.id, page: 1, per: 10 } })
    }

    toggle_sub() {
        this.hide_sub = !this.hide_sub
    }
}
