import { Injectable, ElementRef } from "@angular/core"
import { Subject } from "rxjs"

declare let $

/******************
  Servicio para hacer scroll hacia cierto elemento por su ID. Usa jquery porque es lo que en la práctica
  demostró ser lo más smooth.
*******************/

@Injectable()
export class ScrollToService {
    scrollToPadding: number = 0
    scrollToDuration: number = 1000
    scrollToEasing: string = "easeInOutExpo"

    public scrollTo(targetEl: string | ElementRef, padding?: number, duration?: number, easing?: string) {
        let target = this.getTarget(targetEl)
        if (target) {
            $("html, body").animate(
                {
                    scrollTop: this.targetYPosition(target) + (padding ? +padding : +this.scrollToPadding)
                },
                duration ? duration : this.scrollToDuration,
                easing ? easing : this.scrollToEasing
            )
        }
    }

    public scrollToTop(padding?: number, duration?: number, easing?: string) {
        $("html, body").animate(
            {
                scrollTop: 0 + (padding ? +padding : +this.scrollToPadding)
            },
            duration ? duration : this.scrollToDuration,
            easing ? easing : this.scrollToEasing
        )
        return false
    }

    private targetYPosition(target) {
        var y = target.offsetTop
        var node = target
        while (node.offsetParent && node.offsetParent != document.body) {
            node = node.offsetParent
            y += node.offsetTop
        }
        return y
    }

    getTarget(scrollTarget: string | ElementRef) {
        let target
        if (typeof scrollTarget === "string") {
            target = document.getElementById(scrollTarget)
        } else {
            target = scrollTarget.nativeElement
        }
        return target
    }
}
