import { ActivatedRoute } from "@angular/router"
import { Component, Type } from "@angular/core"
import { Subscription } from "rxjs"
import { RegisterCompletarSocialAprendolibreColombiaComponent } from "@puntaje/puntaje/new-modules/usuarios"
import { I18nService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "completar_registro_social.component.html"
})
export class CompletarRegistroSocialComponent {
    unloggedLayoutTitle: string
    sub: Subscription
    subData: Subscription

    component: Type<any> = RegisterCompletarSocialAprendolibreColombiaComponent //RegisterCompletarSocialPuntajeComponent
    inputs: any = {
        usuarioId: null
    }
    outputs: any = {
        onTitleChange: this.onTitleChange
    }

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected translator: I18nService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.registro.completar_registro_social")
        this.unloggedLayoutTitle = this.translator.translate("landing.registro.completar_social.titulo")

        this.sub = this.route.params.subscribe(params => {
            this.inputs.usuarioId = +params["id"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.component = data.component ?? this.component
        })
    }

    onTitleChange(title) {
        this.unloggedLayoutTitle = title
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }
}
