import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router"
import { Observable } from "rxjs"
import { SessionService } from "@puntaje/shared/core"

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(protected sessionService: SessionService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.sessionService.getPerfiles().includes("Super Administrador")
    }
}
