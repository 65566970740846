import { Directive, Input, ElementRef, HostListener } from "@angular/core"
import { EasyScrollDirective } from "./easyscroll.directive"

declare let $

/******************
  Directiva que sirve para que al hacer click se scrollee a un elemento de la página según su id.
  Puede usarse con links (con href="#id") o dándole easyScrollTo="id" si no es un link.
  Usa el animate de jQuery.
  Ej:
  <a href="#id" easyScrollTo [easyScrollToPadding]="-40" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">

  Parámetros opcionales:
  - easyScrollToPadding
  - easyScrollToDuration
  - easyScrollToEasing (según los valores del script de jquery.easing)
*******************/

@Directive({
    selector: "[easyScrollTo]"
})
export class EasyScrollToDirective extends EasyScrollDirective {
    @Input("easyScrollTo") scrollTarget: string | ElementRef
    @Input("easyScrollToPadding") easyScrollPadding: number = 0
    @Input("easyScrollToDuration") easyScrollDuration: number = 1000
    @Input("easyScrollToEasing") easyScrollEasing: string = "easeInOutExpo"

    constructor(protected el: ElementRef) {
        super(el)
    }

    @HostListener("click", ["$event"]) onClick(event) {
        this.scroll()
    }
}
