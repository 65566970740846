<loading-layout #loadingLayout>
    <plan-personal-navbar
        *ngIf="planPersonal && planPersonalSesion"
        [planesPersonalesRoute]="planesPersonalesRoute"
        [planPersonal]="planPersonal"
        [enableNext]="enableProgressBtn"
        [orden]="planPersonalSesion.orden"
        [ciclo]="planPersonalCiclo.orden"
        [enableNavigateToPlan]="enableNavigateToPlan"
        [enablePrevious]="enableProgressBtn"
        [prevElementType]="prevElementType"
        [nextElementType]="nextElementType"
        (onGoToNext)="goToNext()"
        (onGoToPrevious)="goToPrevious()"
    ></plan-personal-navbar>
    <div class="clearfix">
        <div class="menu-sesion col-menu">
            <plan-personal-sesion-menu
                [componentesAvance]="componentesAvance"
                [planPersonalComponentes]="planPersonalComponentes"
                [currentComponentIndex]="activeComponent"
                [unrestrictedProgressionMode]="unrestrictedProgressionMode"
                (onComponentChange)="onMenuChangeComponent($event)"
                *ngIf="planPersonalComponentes && componentesReady"
            ></plan-personal-sesion-menu>
        </div>
        <div class="col-content content-wrap" *ngIf="componentesReady">
            <div *ngFor="let component of planPersonalComponentes; let i = index" class="enforce-title">
                <div *ngIf="i == activeComponent">
                    <div *ngIf="component.tipo == 'Material'">
                        <material
                            [enableDudas]="enableDudas"
                            [enableReporte]="enableReporte"
                            [material]="component.material"
                            [showVolver]="false"
                            (destroy)="onDestroyMaterial($event)"
                        ></material>
                        <div class="progress-btns clearfix" *ngIf="enableProgressBtn">
                            <ng-container *ngTemplateOutlet="progressButtonsDown"></ng-container>
                        </div>
                    </div>
                    <div *ngIf="component.tipo == 'Evaluacion'">
                        <div class="component-title">
                            {{ "estudiar.plan_personales.plan_personal_sesion.titulo_evaluacion" | t }}
                        </div>

                        <plan-personal-evaluacion
                            [enableDudas]="enableDudas"
                            [enableReporte]="enableReporte"
                            [componentesAvance]="componentesAvance"
                            [planPersonalInstancia]="planPersonalInstancia"
                            [evaluacionId]="hash_evaluaciones[plan_personal_sesion_id][0]"
                            [planPersonalContenido]="component"
                            [enableReporte]="enableReporte"
                            [planPersonalSesion]="planPersonalSesion"
                            *ngIf="checkIfEvaluationExists(0)"
                        >
                            <!--<plan-personal-evaluacion-progress-buttons-slot-up>
								<ng-container *ngTemplateOutlet="progressButtonsUp"></ng-container>
							</plan-personal-evaluacion-progress-buttons-slot-up>
							<plan-personal-evaluacion-progress-buttons-slot-down>
								<ng-container *ngTemplateOutlet="progressButtonsDown"></ng-container>
							</plan-personal-evaluacion-progress-buttons-slot-down>-->
                        </plan-personal-evaluacion>
                        <div *ngIf="!checkIfEvaluationExists(0)">
                            <ng-container *ngTemplateOutlet="notYet"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div *ngIf="plan_estudio_componentes.length==0">
				<ng-container *ngTemplateOutlet="notYet"></ng-container>
			</div>-->
        </div>
    </div>
</loading-layout>

<ng-template #progressButtonsDown>
    <!--Botones de progreso-->
    <button
        type="button"
        class="btn btn-default btn-progress pull-right"
        (click)="goToNextComponent()"
        *ngIf="activeComponent < planPersonalComponentes.length - 1"
    >
        {{ "estudiar.plan_personales.plan_personal_sesion.siguiente" | t }}
        <fa name="chevron-right" class="icono-right"></fa>
    </button>
    <button
        type="button"
        class="btn btn-default btn-progress pull-right"
        (click)="goToNextSesion()"
        *ngIf="nextSession && activeComponent == planPersonalComponentes.length - 1"
    >
        {{ "estudiar.plan_personales.plan_personal_sesion.siguiente_sesion" | t }}
        <fa name="chevron-right" class="icono-right"></fa>
    </button>
    <button
        type="button"
        class="btn btn-default btn-progress pull-right"
        (click)="goToEnd()"
        *ngIf="!nextSession && activeComponent == planPersonalComponentes.length - 1"
    >
        {{ "estudiar.plan_personales.plan_personal_sesion.volver_planes_personales" | t }}
        <fa name="chevron-right" class="icono-right"></fa>
    </button>
</ng-template>
<ng-template #progressButtonsUp>
    <!--Botones de progreso-->
    <!--<button type="button" class="btn btn-default btn-progress pull-right" (click)="goToNextComponent()" *ngIf="activeComponent < (planPersonalComponentes.length-1)">Siguiente<fa name="arrow-right" class="icono-right"></fa></button>
	<button type="button" class="btn btn-default btn-progress pull-right" (click)="goToNextSesion()" *ngIf="nextSession && activeComponent == (planPersonalComponentes.length-1)">Siguiente Sesión<fa name="arrow-right" class="icono-right"></fa></button>
	<button type="button" class="btn btn-default btn-progress pull-right" (click)="goToEnd()" *ngIf="!nextSession && activeComponent == (planPersonalComponentes.length-1)">Volver al Planes Personales<fa name="arrow-right" class="icono-right"></fa></button>-->
</ng-template>

<ng-template #notYet>
    <div class="contenido-placeholder">
        <ogr-icon name="clock" class="placeholder-icon"></ogr-icon>
        <div class="inner-text">
            <h2>{{ "estudiar.plan_personales.plan_personal_sesion.pronto" | t }}</h2>
            <p>{{ "estudiar.plan_personales.plan_personal_sesion.no_disponible" | t }}.</p>
        </div>
    </div>
</ng-template>
