import { ActivatedRoute } from "@angular/router"
import { Component, Type } from "@angular/core"
import { Subscription } from "rxjs"
import { RegisterCompletarPuntajeComponent } from "@puntaje/puntaje/new-modules/usuarios"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "completar_registro.component.html"
})
export class CompletarRegistroComponent {
    unloggedLayoutTitle: string
    subParams: Subscription
    subData: Subscription

    component: Type<any> = RegisterCompletarPuntajeComponent
    inputs: any = { usuarioId: null }
    outputs: any = { onTitleChange: this.onTitleChange }

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.registro.completar_registro")
        this.subParams = this.route.params.subscribe(params => {
            this.inputs.usuarioId = +params["id"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.component = data.component ?? this.component
            this.inputs = { ...this.inputs, ...data.inputs }
        })
    }

    onTitleChange(title) {
        this.unloggedLayoutTitle = title
    }

    ngOnDestroy() {
        this.subParams.unsubscribe()
        this.subData.unsubscribe()
    }
}
