import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable({ providedIn: "root" })
export class BuscadorUsuarioService {
    public buscadorValues: any = {}
    public buscadorSubject: Subject<any> = new Subject()

    setBuscadorValues(buscadorValues: any) {
        this.buscadorValues = buscadorValues
        this.buscadorSubject.next(this.buscadorValues)
    }
}