import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Evaluacion } from "./evaluaciones.model"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { Instrumento } from "./instrumentos.model"
import { EvaluacionMultiple } from "./evaluacion_multiples.model"
import { GrupoRecursoTipo } from "./grupo_recurso_tipos.model"
import { GrupoRecurso } from "./grupo_recursos.model"

export class EvaluacionSugerida extends BaseModel {
    nombre: string
    evaluacion_id: number
    asignatura_id: number
    evaluacion_multiple_id: number
    created_at: Date
    updated_at: Date
    orden: number
    activa: boolean

    @SaveType(() => Evaluacion) evaluacion: Evaluacion
    @SaveType(() => EvaluacionMultiple) evaluacion_multiple: EvaluacionMultiple
    @SaveType(() => Asignatura) asignatura: Asignatura
    @SaveType(() => Instrumento) instrumento: Instrumento
    @SaveType(() => GrupoRecursoTipo) grupo_recurso_tipos: GrupoRecursoTipo[]
    @SaveType(() => GrupoRecurso) grupo_recursos: GrupoRecurso[]

    evaluacion_realizada: boolean
}
