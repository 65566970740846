import { BaseForm, Validations } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Usuarios } from "./usuarios.service"

export class UsuarioRegistroBaseChileForm extends BaseForm {
    companyName: string = ""
    public static get formParams(): any {
        return {
            usuario_id: { type: "hidden", visible: true },
            rut: {
                label: "RUT",
                type: "text",
                placeholder: "11111111-1",
                validations: [Validators.required, Validations.validateRut],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueRut }]
            }
        }
    }
}
