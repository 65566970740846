import { BaseModel } from "@puntaje/shared/core"

export class PreferenciaCarreraSede extends BaseModel {
    public static className: string = "PreferenciaCarreraSede"

    public plataformaId: number
    public carreraSedeId: number
    public usuarioId: number
    public orden: number
}
