export const esAprendemas = {
    titles: {
        core: {
            plan_clases: {
                mis_plan_clases: "Mis módulos de aprendizaje"
            }
        }
    },
    estudiar: {
        plan_estudios: {
            titulo: "Módulos de aprendizaje",
            recomendados: {
                titulo: "Módulos de aprendizaje recomendados"
            },
            compartidos: {
                titulo_simple: "Módulos de aprendizaje"
            }
        },
        plan_de_clases: {
            not_found: "*No se encontraron módulos.",
        },
        plan_personales: {
            btn_comenzar: "Comenzar prueba diagnóstico"
        }
    },
    base_topmenu: {
        alumno: {
            planes_de_clase: "Módulos de aprendizaje",
            planes_adaptativos: "Diagnóstico y Reforzamiento"
        },
        profe: {
            planes_de_clase: "Módulos de aprendizaje"
        }
    }
}
