import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Post, Posts } from "@puntaje/puntaje/api-services"

@Component({
    selector: "blog",
    templateUrl: "./blog.component.html",
    styleUrls: ["./blog.component.scss"]
})
export class BlogComponent implements OnInit {
    @Input() perfiles: string[] = ["todos"]
    @Input() external: boolean = false //si necesito redirigir al blog logeado o deslogeado

    posts: Post[]
    per: number = 9

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected postsService: Posts, public cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.setData(1, this.per)
    }

    setData(page: number, per: number) {
        this.loadingLayout.standby()
        this.cdr.detectChanges()

        const date = new Date().toISOString()
        const postsParams = {
            page: page,
            per: per,
            sort_by: "created_at",
            order: "desc",
            include: "[usuario]",
            post_perfil: {
                post_perfil: this.perfiles
            },
            post: {
                no_listado: false,
                visible: true,
                lte: { fecha_inicial: date },
                gte: { fecha_final: date }
            }
        }

        return this.postsService.where(postsParams).then((posts: Post[], total: number) => {
            this.posts = posts
            this.loadingLayout.ready()

            return total
        })
    }
}
