import {
    Component,
    OnInit,
    Input,
    ViewChildren,
    ElementRef,
    QueryList,
    OnDestroy,
    ViewChild,
    HostBinding
} from "@angular/core"
import { Evaluacion } from "@puntaje/puntaje/api-services"
import { Store, select } from "@ngrx/store"
import {
    State,
    selectEvaluacionesSeeingIndex,
    selectEvaluacionesRespuestasActual,
    selectEvaluacionesActual
} from "@puntaje/puntaje/store"
import { merge, combineLatest, Subscription } from "rxjs"
import { map, filter, tap, take } from "rxjs/operators"
import {
    SetSeeingIndex,
    ResetDudaPreguntas,
    SetItems,
    SetDudaPreguntasNoContestadas,
    selectEvaluacionesDudaPreguntasActual,
    selectEvaluacionesItems
} from "@puntaje/puntaje/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

declare let $
@Component({
    selector: "progreso-preguntas-evaluacion-secuencial",
    templateUrl: "./progreso-preguntas-evaluacion-secuencial.component.html",
    styleUrls: ["./progreso-preguntas-evaluacion-secuencial.component.scss"]
})
export class ProgresoPreguntasEvaluacionSecuencialComponent implements OnInit, OnDestroy {
    @ViewChildren("preguntaBox") preguntaBoxes: QueryList<ElementRef>
    @ViewChild("listaBox") listaBox: ElementRef
    @HostBinding("attr.role") role: string = "region"
    @HostBinding("attr.aria-label") aria: string = "progreso preguntas"
    restrictedNavigation = config.plataforma?.evaluacionSecuencial?.restrictedNavigation || false
    autoDudaNoContestada = config.plataforma?.evaluacionSecuencial?.autoDudaNoContestada || false
    sub: Subscription
    itemSub: Subscription
    evaluacion$ = this.store.pipe(select(selectEvaluacionesActual))
    indexActual$ = this.store.pipe(select(selectEvaluacionesSeeingIndex))
    respuestas$ = this.store.pipe(select(selectEvaluacionesRespuestasActual))
    dudas$ = this.store.pipe(select(selectEvaluacionesDudaPreguntasActual))
    items$ = this.store.pipe(select(selectEvaluacionesItems))
    answered$ = this.respuestas$.pipe(
        map(respuestas =>
            respuestas.map(respuesta => respuesta && (!Array.isArray(respuesta) || respuesta.some(r => r)))
        )
    )
    progreso$ = combineLatest(
        this.evaluacion$.pipe(filter(x => !!x)),
        this.answered$.pipe(map(x => x.filter(y => y).length))
    ).pipe(
        map(([evaluacion, answered]) => {
            return (100.0 * answered) / evaluacion.instrumento.instrumento_preguntas.length
        })
    )

    constructor(protected store: Store<State>) {}

    ngOnInit() {
        this.sub = this.indexActual$
            .pipe(
                filter(indexActual => indexActual >= 0 && this.preguntaBoxes && this.preguntaBoxes.length > indexActual)
            )
            .subscribe(async indexActual => {
                const preguntaBox = this.preguntaBoxes.toArray()[indexActual].nativeElement
                const listaBox = this.listaBox.nativeElement
                listaBox.scrollLeft = preguntaBox.offsetLeft - listaBox.offsetLeft - 5
            })
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe()
        }
        if (this.itemSub) {
            this.itemSub.unsubscribe()
        }
    }

    async changeItem(index: number) {
        if (this.restrictedNavigation) {
            const answers = await this.answered$.pipe(take(1)).toPromise()
            if (answers[index]) return
        }
        this.store.dispatch(new SetSeeingIndex(index))
        if (this.autoDudaNoContestada) {
            this.store.dispatch(new SetDudaPreguntasNoContestadas())
        }
    }
}
