import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "duda_new.component.html"
})
export class DudaNewComponent implements OnInit, OnDestroy {
    sub: Subscription
    subData: Subscription
    item_id: number
    item_type: string
    item_categoria: string

    showCursos: boolean = true
    fromProfesor = config.app.name == "profesores"

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.dudas.dudas.new")
        this.sub = this.route.queryParams.subscribe(params => {
            this.item_id = +params["item_id"]
            this.item_type = params["item_type"]
            this.item_categoria = params["item_categoria"]

            if (params["show_cursos"]) {
                switch (params["show_cursos"]) {
                    case "0":
                        this.showCursos = false
                        break
                    case "1":
                        this.showCursos = true
                        break
                }
            }
        })

        this.subData = this.route.data.subscribe(data => {
            this.showCursos = data.showCursos ?? this.showCursos
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }
}
