import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule, PaginatorModule, FormModule } from "@puntaje/shared/core"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { PopoverModule } from "ngx-bootstrap/popover"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { CUIInputsModule, CUITextsModule } from "@puntaje/shared/cui"

import { DudaNewComponent } from "./duda_new.component"
import { DudaEditComponent } from "./duda_edit.component"
import { DudasComponent } from "./dudas.component"
import { DudaComponent } from "./duda.component"
import { DudaItemComponent } from "./duda_item.component"
import { DudasListItemComponent } from "./duda_list_item.component"
import { DudasListItemMiniComponent } from "./duda_list_item_mini.component"
import { FiltroDudasComponent } from "./filtro_dudas.component"
import { DudaRespuestaNewComponent } from "./duda_respuesta_new.component"
import { DudaRespuestaEditComponent } from "./duda_respuesta_edit.component"
import { DudaRespuestaComponent } from "./duda_respuesta.component"
import { DudaRespuestasComponent } from "./duda_respuestas.component"
import { DudasBuscadorService } from "./dudas_buscador.service"
import { DudasShowcaseComponent } from "./dudas_showcase.component"
import { DudaAvatarComponent } from "./duda_avatar.component"
import { DudasCategoriasComponent } from "./dudas_categorias.component"
import { DudasCategoriaBoxComponent } from "./dudas_categoria_box.component"

import { ComentarioNewComponent } from "./comentario_new.component"
import { ComentarioEditComponent } from "./comentario_edit.component"
import { ComentarioComponent } from "./comentario.component"
import { ComentariosListComponent } from "./comentarios_list.component"

import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SentimentsModule } from "@puntaje/puntaje/new-modules/sentiments"
import { ShowcaseComunidadComponent } from "./showcase-comunidad/showcase-comunidad.component"
import { DudasCategoriaBoxDosComponent } from "./dudas-categoria-box-dos/dudas-categoria-box-dos.component"
import { DudasRespondidasComponent } from "./dudas-respondidas/dudas-respondidas.component"
import { LogrosModule } from "@puntaje/puntaje/new-modules/logros"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormModule,
        UtilModule,
        PaginatorModule,
        CommonsLayoutsModule,
        SentimentsModule,
        Angular2FontawesomeModule,
        RouterModule,
        SharedModule,
        TooltipModule,
        PopoverModule,
        MaterialesModule,
        EnsayosModule,
        CUIInputsModule,
        CUITextsModule,
        LogrosModule
    ],
    declarations: [
        DudaNewComponent,
        DudaEditComponent,
        DudaComponent,
        DudaItemComponent,
        DudaRespuestaNewComponent,
        DudaRespuestaEditComponent,
        DudaRespuestasComponent,
        DudaRespuestaComponent,
        DudasComponent,
        DudasListItemComponent,
        DudasListItemMiniComponent,
        FiltroDudasComponent,
        DudasShowcaseComponent,
        ComentarioNewComponent,
        ComentarioEditComponent,
        ComentarioComponent,
        ComentariosListComponent,
        DudaAvatarComponent,
        DudasCategoriasComponent,
        DudasCategoriaBoxComponent,
        ShowcaseComunidadComponent,
        DudasCategoriaBoxDosComponent,
        DudasRespondidasComponent
    ],
    exports: [
        DudaNewComponent,
        DudaEditComponent,
        DudaComponent,
        DudaItemComponent,
        DudaRespuestaNewComponent,
        DudaRespuestaEditComponent,
        DudaRespuestasComponent,
        DudaRespuestaComponent,
        DudasComponent,
        DudasListItemComponent,
        DudasListItemMiniComponent,
        FiltroDudasComponent,
        DudasShowcaseComponent,
        ComentarioNewComponent,
        ComentarioEditComponent,
        ComentarioComponent,
        ComentariosListComponent,
        DudaAvatarComponent,
        DudasCategoriasComponent,
        DudasCategoriaBoxComponent,
        ShowcaseComunidadComponent,
        DudasRespondidasComponent
    ],
    providers: [
        // Dejado aquí porque es de uso interno al módulo, mover a un forRoot en caso que se requiera en otro lado.
        DudasBuscadorService
    ]
})
export class DudasModule {}
