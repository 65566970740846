<cui-button id="btnCrear" *ngIf="type == 'btn'" (click)="showModalDuda()" class="btn_style pull-left margin-right">
    <span class="glyphicon glyphicon-question-sign"></span>
    &nbsp;{{ "ensayos.crear_duda" | t }}
</cui-button>
<!-- <a id="linkCrear" *ngIf="type == 'link'" (click)="showModalDuda()" class="option">
    <fa name="question-circle-o" class="crearDudaIcono"></fa>
    {{ "ensayos.crear_duda" | t }}
</a> -->

<generic-modal (closeCrossCallback)="close()" [id]="'duda_' + tabla + '_' + entidad_id" [buttonClicked]="buttonPressed">
    <modal-titulo>{{ "ensayos.confirmacion" | t }}</modal-titulo>
    <modal-contenido>
        <p>{{ "ensayos.confirmacion_pregunta" | t }}</p>

        <p>{{ "ensayos.a_quien" | t }}:</p>
        <div class="container">
            <input
                [id]="'comunidad_' + entidad_id"
                type="radio"
                name="contexto"
                value="comunidad"
                [(ngModel)]="contexto"
            />
            <label [attr.for]="'comunidad_' + entidad_id">{{ "ensayos.comunidad_general" | t }}</label>
        </div>
        <div class="container">
            <input [id]="'curso_' + entidad_id" type="radio" name="contexto" value="curso" [(ngModel)]="contexto" />
            <label [attr.for]="'curso_' + entidad_id">{{ "ensayos.curso" | t }}</label>
        </div>
    </modal-contenido>
    <modal-botones>
        <button class="btn btn-default" (click)="makeDuda(true)">{{ "ensayos.aceptar" | t }}</button>
    </modal-botones>
</generic-modal>
