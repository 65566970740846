import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Establecimiento, Establecimientos, GrupoUsuario, GrupoUsuarios, Usuario } from "@puntaje/puntaje/api-services"

@Component({
    selector: "descargar-csv-prellenado",
    templateUrl: "./descargar-csv-prellenado.component.html",
    styleUrls: ["./descargar-csv-prellenado.component.scss"]
})
export class DescargarCsvPrellenadoComponent implements OnInit {
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    forma: number
    grupoUsuario: GrupoUsuario
    establecimientos: Establecimiento[] = []
    establecimiento: Establecimiento
    propietarios: Usuario[]
    propietario: Usuario
    messages: any
    loadingMessages: boolean = false
    faltanDatos: boolean = false

    constructor(
        protected cdr: ChangeDetectorRef,
        protected grupoUsuariosServices: GrupoUsuarios,
        protected establecimientosService: Establecimientos
    ) {}

    ngOnInit() {
        this.setEstablecimientos()
    }

    setEstablecimientos() {
        const today = new Date().toISOString()

        let params = {
            mios: 1,
            utp_establecimiento: 1,
            utp_asociacion: 1,
            establecimiento: { activo: 1 },
            fecha_inicial: today,
            fecha_final: today
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos
            if (this.establecimientos.length == 1) {
                this.establecimiento = this.establecimientos[0]
            } else if (this.establecimientos.length == 0) {
                this.establecimiento = undefined
            }
        })
    }

    descargarCsvPrellenado() {
        if (this.forma && this.grupoUsuario) {
            this.faltanDatos = false
            let par = {
                forma: this.forma,
                pais: config.plataforma.pais
            }

            this.grupoUsuariosServices.obtener_csv_alumnos(this.grupoUsuario.id, par).then((obj: any) => {
                const replacer = (key, value) => (value === null ? "" : value) // specify how you want to handle null values here

                const header = Object.keys(obj[0])
                let csv = obj.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(";"))
                let csvArray = csv.join("\r\n")

                let a = document.createElement("a")
                var blob = new Blob([csvArray], { type: "text/csv" }),
                    url = window.URL.createObjectURL(blob)

                a.href = url
                a.download = this.nombreArchivo()
                a.click()
                window.URL.revokeObjectURL(url)
                a.remove()
            })
        } else {
            if (this.grupoUsuario == undefined) {
                this.messages = "Selecione un curso"
            } else if (!this.forma) {
                this.messages = "Debe ingresar la forma"
            }
            this.faltanDatos = true
        }
    }

    nombreArchivo = () => {
        this.cdr.detectChanges()
        let name = ""
        if (this.establecimiento) name += this.establecimiento.establecimiento.toString()
        if (this.grupoUsuario) name += "-" + this.grupoUsuario.nombre.toString()
        if (this.forma) name += "-forma_" + this.forma.toString()
        if (name == "") name = "evaluacion-formato-para-llenar.csv"
        else name += ".csv"
        name = name.replace(/\s/g, "_")
        return name
    }
}
