import { Injectable } from "@angular/core"
import { Subject } from "rxjs"
import { Notificacion, Notificaciones } from "@puntaje/puntaje/api-services"

@Injectable()
export class NotificacionesPendingService {
    public notificacionesPendingCount: number
    public notificacionesCountSubject: Subject<any> = new Subject()

    constructor(private notificacionesService: Notificaciones) {
        this.update()
    }

    update() {
        this.notificacionesService.where({ pendientes: 1 }).then((notificaciones: Notificacion[]) => {
            this.notificacionesPendingCount = notificaciones.length
            this.notificacionesCountSubject.next(this.notificacionesPendingCount)
        })
    }
}
