import { Alternativa } from "@puntaje/nebulosa/api-services"
import { BaseModel, SaveType } from "@puntaje/shared/core"

export class Respuesta extends BaseModel {
    alternativa_id: number
    tiempo: number
    duda_marcada: boolean
    contestable_id: number
    pregunta_id: number
    correcta: boolean
    puntuacion: number | undefined

    @SaveType(() => Alternativa) alternativa: Alternativa
}
