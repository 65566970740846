import { Component, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Tutores } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "tutor_confirmar.component.html",
    styleUrls: ["tutor_confirmar.component.scss"]
})
export class TutorConfirmarComponent {
    token: string
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected tutoresService: Tutores
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.tutor_confirmar")
        this.loadingLayout.standby()
        this.sub = this.route.params.subscribe(params => {
            this.token = params["token"]
            this.tutoresService.confirmar(this.token).then(response => {
                this.loadingLayout.ready()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
