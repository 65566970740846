import { Pipe, PipeTransform } from "@angular/core"

@Pipe({ name: "prettydate" })
/** Precede the input string with the word "Awesome " */
export class PrettyDatePipe implements PipeTransform {
    transform(obj: any, separator: string = "/") {
        //2016-08-17T19:57:51.000Z
        var exp = "([0-9]{4})[-]([0-9]{2})[-]([0-9]{2})T([0-9]{2})[:]([0-9]{2})[:]([0-9]{2})[.][0-9]{3}Z"
        var regex = new RegExp(exp)
        var result = regex.exec(obj)

        if (result) {
            // 17/08/2016 19:57
            return result[3] + separator + result[2] + separator + result[1] + " " + result[4] + ":" + result[5]
        }

        exp = "([0-9]{4})[-]([0-9]{2})[-]([0-9]{2}) ([0-9]{2})[:]([0-9]{2})[:]([0-9]{2})"
        regex = new RegExp(exp)
        result = regex.exec(obj)
        if (result) {
            // 17/08/2016 12:03:04
            return result[3] + separator + result[2] + separator + result[1] + " " + result[4] + ":" + result[5]
        }
        //2016-08-17
        exp = "([0-9]{4})[-]([0-9]{2})[-]([0-9]{2})"
        regex = new RegExp(exp)
        result = regex.exec(obj)
        if (result) {
            // 17/08/2016
            return result[3] + separator + result[2] + separator + result[1]
        }
        return obj
    }
}
