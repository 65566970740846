import { BaseModel, SaveType } from "@puntaje/shared/core"
import { CampoExtra } from "./campo_extras.model"

export class CarreraSedeCampoExtra extends BaseModel {
    public static className: string = "CarreraSedeCampoExtra"

    public carreraSedeId: number
    public campoExtraId: number

    @SaveType(() => CampoExtra) campoExtra: CampoExtra
}
