import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Pais } from "./paises.model"

export class Lugar extends BaseModel {
    public static className: string = "Lugar"

    public lugar: string
    public lugarTipoId: number
    public lugarId: number
    public paisId: number

    @SaveType(() => Pais) pais: Pais
}
