import { Component, Input, OnInit, OnDestroy, Injector, Directive } from "@angular/core"

import { BaseModelClass, AuthService } from "@puntaje/shared/core"
import { Material, Pregunta, Materiales, Preguntas } from "@puntaje/nebulosa/api-services"
import { Usuarios, UsuarioRecurso, Duda, DudaRespuesta, Dudas, DudaRespuestas } from "@puntaje/puntaje/api-services"
import { Subject, Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Directive()
export class BaseSentimentComponent implements OnInit, OnDestroy {
    @Input() object: Material | Pregunta | Duda | DudaRespuesta

    config: typeof config = config
    likes = 0
    dislikes = 0
    sentimentStatusSubject: Subject<string> = new Subject<string>()
    sentimentStatus: string
    sub: Subscription
    userData: any
    loaded = false
    loading = false
    likesPercent = 0
    dislikesPercent = 0
    isLikeValue = false
    isDislikeValue = false

    constructor(private usuariosService: Usuarios, private authService: AuthService, private injector: Injector) {}

    ngOnInit() {
        this.userData = this.authService.getUserData()
        this.sub = this.sentimentStatusSubject.subscribe((value: string) => {
            if (!value) {
                this.sentimentStatus = "INDIFERENT"
            } else {
                this.sentimentStatus = value
            }
            this.setLikeDislikeValues()
        })
        this.getSentimentStatus()
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    setLikeDislikeValues() {
        this.isLikeValue = this.sentimentStatus === "LIKE"
        this.isDislikeValue = this.sentimentStatus === "DISLIKE"
    }

    getLikesPercent() {
        if (this.likes > 0) {
            return (this.likes / (this.likes + this.dislikes || 1)) * 100
        } else {
            return 0
        }
    }

    getDislikesPercent() {
        if (this.dislikes > 0) {
            return 100 - this.getLikesPercent()
        } else {
            return 0
        }
    }

    isLike() {
        return this.sentimentStatus === "LIKE"
    }

    isDislike() {
        return this.sentimentStatus === "DISLIKE"
    }

    getSentimentStatus() {
        this.loaded = false
        this.loading = true
        this.usuariosService.getSentiment(this.userData.id, this.getQuery()).then((usuarioRecursos: any[]) => {
            if (usuarioRecursos.length > 0) {
                this.sentimentStatusSubject.next(usuarioRecursos[0].sentiment)
            }
            this.object
                .getSentimentPromise(this.injector, { plataforma: this.config.plataforma.name })
                .then((response): any => {
                    this.setCounters(response)
                    this.loading = false

                    return {}
                })

            this.loading = false
            this.loaded = true
        })
    }

    like() {
        if (!this.loading) {
            let query = {}
            if (this.isLike()) {
                query = this.postQuery("INDIFERENT")
            } else {
                query = this.postQuery("LIKE")
            }
            this.setSentiment(query, this.updateLikes)
        }
    }

    dislike() {
        if (!this.loading) {
            let query = {}
            if (this.isDislike()) {
                query = this.postQuery("INDIFERENT")
            } else {
                query = this.postQuery("DISLIKE")
            }
            this.setSentiment(query, this.updateDislikes)
        }
    }

    setSentiment(query: any, fun: (v1: string, v2: string) => void) {
        this.loading = true
        this.usuariosService.setSentiment(this.userData.id, query).then((usuarioRecursos: any) => {
            this.sentimentStatusSubject.next(usuarioRecursos.sentiment)
            fun(query.sentiment.sentiment, query.sentiment.sentiment_was)
        })
    }

    getQuery() {
        return {
            sentiment: {
                modelo_recurso: this.object.getClassName(),
                modelo_recurso_id: this.object.id,
                usuario_id: this.userData.id
            }
        }
    }

    // status can be "LIKE", "DISLIKE", "INDIFERENT"
    postQuery(status: string) {
        return {
            sentiment: {
                modelo_recurso: this.object.getClassName(),
                modelo_recurso_id: this.object.id,
                sentiment: status,
                sentiment_was: this.sentimentStatus,
                usuario_id: this.userData.id
            }
        }
    }

    updateLikes = (status: string, status_was: string) => {
        const params = {
            sentiment: { status: status, status_was: status_was, plataforma: this.config.plataforma.name }
        }
        this.object.getLikePromise(this.injector, params).then((response: any) => {
            this.setCounters(response)
            this.loading = false
        })
    }

    updateDislikes = (status: string, status_was: string) => {
        const params = {
            sentiment: { status: status, status_was: status_was, plataforma: this.config.plataforma.name }
        }
        this.object.getDislikePromise(this.injector, params).then((response: any) => {
            this.setCounters(response)
            this.loading = false
        })
    }

    setCounters(response: any) {
        this.likes = 0
        this.dislikes = 0
        if (response.likes) {
            this.likes = response.likes
        }
        if (response.dislikes) {
            this.dislikes = response.dislikes
        }
        this.likesPercent = this.getLikesPercent()
        this.dislikesPercent = this.getDislikesPercent()
    }
}
