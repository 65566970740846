import { Component, OnInit, Input } from "@angular/core"
import { Duda, DudaCategoria, DudaCategorias } from "@puntaje/puntaje/api-services"
import { Router } from "@angular/router"

@Component({
    selector: "duda-list-item-mini",
    templateUrl: "duda_list_item_mini.component.html",
    styleUrls: ["duda_list_item_mini.component.scss"]
})
export class DudasListItemMiniComponent implements OnInit {
    @Input() duda: Duda
    titulo = "Titulo"
    hasAnswer = false
    hasSolution = false

    constructor(protected router: Router) {}

    ngOnInit() {
        if (this.duda?.mejor_respuesta_id) {
            this.hasSolution = true
        }
        if (this.duda?.participacion) {
            this.hasAnswer = true
        }
    }

    goToDuda() {
        this.router.navigate(["/dudas/" + this.duda.id])
    }
}
