import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core"
import { Comentarios, ComentarioForm, Comentario } from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "comentario-new",
    templateUrl: "comentario_form.component.html",
    styleUrls: ["comentario_form.component.scss"]
})
export class ComentarioNewComponent implements OnInit {
    @Input() dudaRespuestaId: number
    @Output() onSaveDone: EventEmitter<any> = new EventEmitter<any>()
    @Output() onCancel: EventEmitter<any> = new EventEmitter<any>()
    saveBtnText: string = "Comentar"
    labelText: string = "Agregar comentario"
    form: UntypedFormGroup
    params: any = ComentarioForm.formParams
    comentario: Comentario

    constructor(private comentariosService: Comentarios, private authService: AuthService) {}

    ngOnInit() {
        this.init()
    }

    init() {
        this.comentario = new Comentario()
        this.form = ComentarioForm.getForm(this.comentario)
    }

    save() {
        ComentarioForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.comentario.usuario_id = this.authService.getUserData().id
            this.comentario.duda_respuesta_id = this.dudaRespuestaId
            this.comentariosService.save(this.comentario).then((comentario: Comentario) => {
                this.onSaveDone.emit(comentario)
            })
        }
    }

    cancelar() {
        this.init()
        this.onCancel.emit(true)
    }
}
