import { Component, OnInit, ViewChild } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import {
    CarreraSedes,
    PreferenciaCarreraSedes,
    CarreraSede,
    PreferenciaCarreraSede
} from "@puntaje/carreras/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "mis-preferencia-carreras",
    templateUrl: "./mis-preferencia-carreras.component.html",
    styleUrls: ["./mis-preferencia-carreras.component.scss"]
})
export class MisPreferenciaCarrerasComponent implements OnInit {
    carreraSedes: CarreraSede[] = []
    form: UntypedFormGroup
    universidades: string[] = []
    preferenciaCarrerasSedes = []
    showEditCarreras = false
    showAddCarreras = false
    amountConfi: number
    amount: number
    indexSelected: number

    disableEliminarPrimeraPreferenciaCarreras = config.plataforma.disableEliminarPrimeraPreferenciaCarreras
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected authService: AuthService,
        public carreraSedesService: CarreraSedes,
        public preferenciaCarreraSedesService: PreferenciaCarreraSedes,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.carreraSedes = []
        this.loadPreferenciaCarreras()
        this.universidades = config.registro?.universidades ? config.registro.universidades : []
        this.amountConfi = config.registro?.amount ? config.registro.amount : 3
        this.loadingLayout.ready()
    }

    async loadPreferenciaCarreras() {
        const params = {
            include: "[carrera?,preferencia_carrera_sedes,sede:universidad]",
            preferenciaCarreraSede: {
                usuarioId: this.authService.getUserData().id
            },
            pais: {
                pais: config.plataforma.pais
            },
            sort_by: "PreferenciaCarreraSede.orden",
            order: "asc"
        }
        this.carreraSedes = await this.carreraSedesService.wherePost(params)
    }

    updatePreferencias(event) {
        this.preferenciaCarrerasSedes = event
    }

    saveCarreraSede() {
        if (this.showAddCarreras) {
            this.loadingLayout.standby()
            const params = []
            this.preferenciaCarrerasSedes.forEach(pcs => {
                const preferenciaCarreraSedes = new PreferenciaCarreraSede()
                preferenciaCarreraSedes.orden = pcs.orden
                preferenciaCarreraSedes.carreraSedeId = pcs.carreraSedeId
                preferenciaCarreraSedes.usuarioId = this.authService.getUserData().id
                params.push(preferenciaCarreraSedes)
            })
            this.preferenciaCarreraSedesService.saveMany({ preferenciaCarreraSedes: params }).then(pcs => {
                this.loadPreferenciaCarreras()
                this.showAddCarreras = false
                this.loadingLayout.ready()
            })
        }
        if (this.showEditCarreras) {
            const preferenciaCarreraSedes = this.carreraSedes[this.indexSelected].preferenciaCarreraSedes[0]
            this.loadingLayout.standby()
            const params = []
            this.preferenciaCarrerasSedes.forEach(pcs => {
                preferenciaCarreraSedes.carreraSedeId = pcs.carreraSedeId
                params.push(preferenciaCarreraSedes)
            })
            this.preferenciaCarreraSedesService.saveMany({ preferenciaCarreraSedes: params }).then(pcs => {
                this.loadPreferenciaCarreras()
                this.showEditCarreras = false
                this.loadingLayout.ready()
            })
        }
    }

    setAmount() {
        if (this.carreraSedes) {
            this.amount = this.amountConfi - this.carreraSedes.length
        } else {
            this.amount = this.amountConfi
        }
    }

    addCarreras() {
        this.setAmount()
        this.showAddCarreras = true
    }

    showModalDelete(preferenciaCarreraSede: PreferenciaCarreraSede, i) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro de eliminar esta preferencia de carreras?")
        this.simpleModalService.setModalCallback(() => this.delete(preferenciaCarreraSede, i))
        this.simpleModalService.showSimpleModal()
    }

    delete(preferenciaCarreraSede, i) {
        this.preferenciaCarreraSedesService.remove(preferenciaCarreraSede.id).then(response => {
            this.carreraSedes.splice(i, 1)
            this.setAmount()
        })
    }

    editPreferenciaCarrera(index) {
        if (index >= 0 && index < this.carreraSedes.length) {
            this.setAmount()
            this.indexSelected = index
            this.showEditCarreras = true
        } else {
            this.showEditCarreras = false
        }
    }

    cancelarCarreraSede() {
        this.showAddCarreras = false
        this.showEditCarreras = false
    }
}
