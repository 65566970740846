import { BaseModel } from "@puntaje/shared/core"

export class Actividad extends BaseModel {
    asignatura_id: number
    actividad: string
    link: string
    fecha: Date
    created_at: Date
    updated_at: Date

    public toString() {
        return this.id + ""
    }
}
