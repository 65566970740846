<notificacion-layout
    [notificacion]="notificacion"
    [enableMini]="enableMini"
    [tipoNotificacion]="'plan-personal'"
    [disableMarkButton]="disableMarkButton"
>
    <notificacion-contenido>
        <p class="texto-notificacion">
            <a [routerLink]="remitenteLink">
                {{ notificacionDetails.usuario.nombre }} {{ notificacionDetails.usuario.apellido_paterno }}
            </a>
            {{ "notificaciones.de" | t }} {{ notificacionDetails.grupo_usuario?.establecimiento?.establecimiento }} {{ "notificaciones.del_curso" | t }}
            {{ notificacionDetails.grupo_usuario?.nombre }} {{ "notificaciones.te_ha_compartido_ta" | t }}
            <a [routerLink]="realizarLink">{{ notificacionDetails.plan_personal.plan_personal }}</a>
        </p>
    </notificacion-contenido>
    <notificacion-acciones-footer>
        <a [routerLink]="realizarLink">
            <cui-button type="button" class="btn_style pull-right">{{ "notificaciones.realizar_ahora" | t }}</cui-button>
        </a>
    </notificacion-acciones-footer>
</notificacion-layout>
