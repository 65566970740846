<div class="popup-cookies" *ngIf="open">
    <div class="popup-cookies-wrap">
        <span
            class="close-char"
            tabindex="0"
            [attr.aria-label]="'popup_cookies.label_cerrar' | t"
            (click)="closeWithoutAccepting()"
            (keyup.enter)="closeWithoutAccepting()"
        >
            &times;
        </span>
        <div class="lock-icon-wrap">
            <fa name="lock"></fa>
        </div>
        <div class="msg-wrap">
            <h2 class="title">{{ "popup_cookies.titulo" | t }}</h2>
            <p class="texto">{{ "popup_cookies.texto" | t }}</p>
            <button class="btn-done" (click)="close()">
                <fa name="check"></fa>
                {{ "popup_cookies.btn_ok" | t }}
            </button>
        </div>
    </div>
</div>
