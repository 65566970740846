<ll-titulo>
    <ng-container *ngIf="customTitle !== undefined">
        {{ customTitle }}
    </ng-container>
    <ng-container *ngIf="customTitle === undefined">
        {{ "generar_ensayos.titulo" | t }}
        {{ evaluacionTipoAlias | transformInstrumento: "singular" | titlecase }}
    </ng-container>
</ll-titulo>
<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas
        [showTitleOverMenu]="false"
        [evaluacionTipos]="evaluacionTipos"
    ></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>
<generar-ensayo-alumno
    [tipoEvaluacion]="tipoInstrumento"
    [asignaturaId]="asignaturaId"
    (onReadyAsignatura)="onReady($event)"
    [hideButtonDescargar]="hideButtonDescargar"
></generar-ensayo-alumno>
