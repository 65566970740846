import { Component, Input, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core"
import { LayoutSlotsService } from "../layout-slots.service"

@Component({
    selector: "l-slot-content",
    template: `
        <ng-template>
            <ng-content></ng-content>
        </ng-template>
    `
})
export class LSlotContentComponent {
    @Input() name: string

    @ViewChild(TemplateRef, { static: true }) templateRef: TemplateRef<any>

    constructor(protected layoutService: LayoutSlotsService) {}

    ngOnInit(): void {
        this.layoutService.addSlot(this.name, this.templateRef)
    }
}
