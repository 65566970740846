import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Evaluacion } from "./evaluaciones.model"

export class EvaluacionEvaluacion extends BaseModel {
    evaluacion_id: number
    evaluacion_ref_id: number
    created_at: Date
    updated_at: Date

    @SaveType(() => Evaluacion) evaluacion: Evaluacion
    @SaveType(() => Evaluacion) evaluacion_ref: Evaluacion
}
