import {
    Input,
    Component,
    Injector,
    ViewContainerRef,
    ComponentRef,
    ComponentFactoryResolver,
    ViewChild,
    SimpleChanges,
    Directive
} from "@angular/core"
import { BaseModel } from "../api/base.model"

@Directive()
export class ModelViewComponent {
    @Input() params: any
    @Input() value: BaseModel
    @Input() key: any
    private component: ComponentRef<any>

    @ViewChild("layoutComponent", { static: true }) layoutComponent
    @ViewChild("content", { read: ViewContainerRef, static: true }) content: ViewContainerRef

    constructor(private injector: Injector, private resolver: ComponentFactoryResolver) {}

    ngAfterViewInit() {
        let cmpFactory = this.resolver.resolveComponentFactory(this.params.type)
        this.component = this.content.createComponent(cmpFactory, 0, this.injector)

        this.component.instance.label = this.params.label
        this.component.instance.value = this.value
        this.component.instance.key = this.key
        this.component.instance.options = this.params.options
        if (this.params.class) {
            this.component.instance.params = this.params.class.viewParams
        }
        this.layoutComponent.contentComponent = this.component.instance
        this.component.changeDetectorRef.detectChanges()
        this.layoutComponent.addViews()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.component) {
            let changed = false

            if (changes["params"]) {
                changed = true
                this.component.instance.options = this.params.options
                if (this.params.class) {
                    this.component.instance.params = this.params.class.viewParams
                }
            }
            if (changes["value"]) {
                changed = true
                this.component.instance.value = this.value
            }
            if (changes["key"]) {
                changed = true
                this.component.instance.key = this.key
            }

            if (changed) {
                if (this.component.hasOwnProperty(this.key)) this.component.instance[this.key] = this.value[this.key]
                this.component.changeDetectorRef.detectChanges()
                this.component.instance.ngOnChanges(changes)
            }
        }
    }
}
