import { Component, Input, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core"
import { queueScheduler, Subscription } from "rxjs"
import { map, observeOn } from "rxjs/operators"
import { LayoutSlotsService } from "../layout-slots.service"

@Component({
    selector: "l-slot",
    template: `
        <ng-container [ngTemplateOutlet]="templateRef"></ng-container>
    `
})
export class LSlotComponent {
    @Input() name: string

    sub: Subscription

    templateRef: TemplateRef<any>

    constructor(protected layoutService: LayoutSlotsService) {}

    ngOnInit(): void {
        this.sub = this.layoutService.templates$
            .pipe(observeOn(queueScheduler),map(templates => templates?.[this.name]))
            .subscribe(templateRef => {
                this.templateRef = templateRef
            })
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
    }
}
