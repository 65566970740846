import { Component, OnInit, Input } from "@angular/core"
import { RealizarEnsayoService } from "../realizar_ensayo.service"
import { combineLatest, Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { filter, map } from "rxjs/operators"
import { Pregunta, ContestableTipoEnum } from "@puntaje/nebulosa/api-services"
import { NgModel } from "@angular/forms"
import { Store } from "@ngrx/store"
import { SetRespuestaIndex, State } from "@puntaje/puntaje/store"
import { Evaluacion } from "@puntaje/puntaje/api-services"

@Component({
    selector: "responder-evaluacion-propia",
    templateUrl: "./responder-evaluacion-propia.component.html",
    styleUrls: ["./responder-evaluacion-propia.component.scss"]
})
export class ResponderEvaluacionPropiaComponent implements OnInit {
    @Input() startingPosition: boolean
    @Input() firstElement: boolean = false
    config = config
    numeroPreguntas = 30
    cantidadAlternativas = 5
    preguntas: Pregunta[]
    alternativaIds: (number | { alternativa_texto: string })[]
    letrasAlternativas: string[] = ["A", "B", "C", "D"]
    showComponent: boolean = true
    readySub: Subscription
    evaluacion: Evaluacion

    contestableTipoEnum = ContestableTipoEnum

    respuestas: any[] = []
    respuestasSub: Subscription
    respuestas$ = this.realizarEnsayoService.respuestas$

    constructor(protected realizarEnsayoService: RealizarEnsayoService, protected store: Store<State>) {}

    ngOnInit() {
        this.readySub = combineLatest([
            this.realizarEnsayoService.ready,
            this.realizarEnsayoService.preguntas$,
            this.realizarEnsayoService.evaluacion$
        ]).subscribe(([_, preguntas, evaluacion]) => {
            this.preguntas = preguntas
            this.letrasAlternativas = this.realizarEnsayoService.getLetrasAlternativas()
            this.evaluacion = evaluacion
        })

        this.respuestasSub = this.respuestas$.subscribe(respuestas => {
            this.respuestas = respuestas || []
        })
    }

    onClickRadio(index: number, contestableIndex: number, letra: string, radio: NgModel) {
        if (radio.value == letra) {
            this.buildRespuesta(index, contestableIndex, null)

            radio.reset()
        }
    }

    onSelectAlternativa(index: number, respuesta: any) {
        this.store.dispatch(new SetRespuestaIndex(respuesta, index))
        this.realizarEnsayoService.setTiempos(index)
        this.realizarEnsayoService.saveAlternativa()
    }

    buildRespuesta(index: number, contestableIndex: number, respuesta: any) {
        const newRespuesta = [...this.respuestas[index]]
        newRespuesta[contestableIndex] = respuesta

        this.onSelectAlternativa(index, newRespuesta)
    }

    toggleComponent() {
        this.showComponent = !this.showComponent
    }

    ngOnDestroy() {
        this.readySub.unsubscribe()
        this.respuestasSub.unsubscribe()
    }
}
