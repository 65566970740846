<sl-content-over-title *ngIf="contentOverTitle">
    <h2 class="subtitulo" *ngIf="subtitle">
        {{ subtitle }}
    </h2>
    <register-steps></register-steps>
</sl-content-over-title>
<sl-titulo>{{ unloggedLayoutTitle | easyplaceholder: "Registro" }}</sl-titulo>

<ndc-dynamic 
    [ndcDynamicComponent]="component"
    [ndcDynamicOutputs]="outputs"
></ndc-dynamic>