<div class="ayuda-thumbnail" *ngIf="ayuda" [ngClass]="{ 'without-upload-date': !showUploadDate }">
    <div class="thumbnail-wrap {{ thumbType }}" (click)="goToAyuda()">
        <div class="icono">
            <ogr-icon name="video" *ngIf="thumbType == 'video'"></ogr-icon>
            <img *ngIf="thumbType == 'video-youtube'" [src]="thumbnailUrl" alt="video de youtube" />
            <img *ngIf="thumbType == 'video-vimeo'" [src]="thumbnailUrl" alt="video de vimeo" />
            <ogr-icon name="archivo" *ngIf="thumbType == 'archivo'"></ogr-icon>
            <ogr-icon name="pdf" *ngIf="thumbType == 'pdf'"></ogr-icon>
        </div>
        <div class="overlay"></div>
    </div>
    <div class="descripcion-ayuda">
        <label class="titulo">{{ ayuda.ayuda }}</label>
        <div *ngIf="showUploadDate" class="fecha-subida">{{ 'ayuda.fecha_subida' | t}}:&nbsp;{{ ayuda.created_at | prettydate }}</div>
    </div>
    <div class="button-padding" *ngIf="showUploadDate"></div>
    <div class="button-container">
        <cui-button-link (click)="goToAyuda()" class="pull-right btn-sm">
            {{ 'ayuda.ver' | t}}&nbsp;
            <span class="glyphicon glyphicon-arrow-right"></span>
        </cui-button-link>
        <cui-button-link
            *ngIf="isFileLink(ayuda) && isArchivo"
            [attr.href]="getDownloadLink(ayuda)"
            (click)="trackDownload()"
            download
            class="btn-sm pull-right"
        >
            <span class="glyphicon glyphicon-cloud-download"></span>
            &nbsp;{{ 'ayuda.descargar' | t}}
        </cui-button-link>
    </div>
</div>
