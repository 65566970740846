import { Component, Input } from "@angular/core"
import { Pregunta } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "ver-pregunta",
    templateUrl: "ver_pregunta.component.html",
    styleUrls: ["ver_pregunta.component.scss"]
})
export class VerPreguntaComponent {
    @Input() pregunta: Pregunta
}
