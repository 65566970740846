import { BaseModel } from "@puntaje/shared/core"

export class SolicitudInformacion extends BaseModel {
    public static className: string = "SolicitudInformacion"

    public nombreCompleto: string
    public identificadorPais: string
    public nem: string
    public usuarioId: number
    public carreraSedeId: number
    public plataformaId: number
}
