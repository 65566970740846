import { Location } from "@angular/common"
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Clasificacion, Clasificaciones, Material, Materiales } from "@puntaje/nebulosa/api-services"
import { AuthService, ScrollToService, CheckAchievement, CheckActividad } from "@puntaje/shared/core"
import {
    PlanPersonal,
    PlanPersonalCiclo,
    PlanPersonalContenido,
    PlanPersonalContenidos,
    PlanPersonales,
    PlanPersonalInstancia,
    PlanPersonalInstancias,
    PlanPersonalMaterialInstancia,
    PlanPersonalMaterialInstancias,
    PlanPersonalSesion,
    PlanPersonalSesiones
} from "@puntaje/puntaje/api-services"

import { Store } from "@ngrx/store"
import { State, CrearAlertaLogros } from "@puntaje/puntaje/store"

import { SelectContenidosPlanificaciones } from "@puntaje/puntaje/new-modules/shared"

@Component({
    selector: "plan-personal-sesion",
    templateUrl: "plan_personal_sesion.component.html",
    styleUrls: ["plan_personal_sesion.component.scss"]
})
export class PlanPersonalSesionComponent {
    sub: any
    @Input() plan_personal_id: number
    @Input() enableNavigateToPlan: boolean = false
    private _plan_personal_ciclo_orden: number
    private _plan_personal_sesion_id: number
    planPersonalSesion: PlanPersonalSesion
    planPersonal: PlanPersonal
    planPersonalInstancia: PlanPersonalInstancia
    planPersonalCiclo: PlanPersonalCiclo
    usuarioId: number
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    hash_evaluaciones: any
    contenidoInstancias: { [x: string]: PlanPersonalContenido } = {}
    activeTab: number = 0
    planPersonalComponentes: PlanPersonalContenido[]
    activeComponent: number = 0
    enableProgressBtn: boolean = false
    enablePreviousBtn: boolean = false
    nextSession: PlanPersonalSesion
    previousSession: PlanPersonalSesion
    nextCiclo: PlanPersonalCiclo
    previousCiclo: PlanPersonalCiclo
    componentesReady: boolean = false
    componentesAvance: { [x: number]: boolean } = {}
    prevElementType: string
    nextElementType: string

    @Input() planesPersonalesRoute: string
    @Input() enableDudas: boolean = true
    @Input() enableReporte: boolean = true
    @Input() enableGoBack: boolean = false
    @Input() routeWithId: boolean = true
    @Input() backAtEnd: boolean = false
    @Input() unrestrictedProgressionMode: boolean = false
    @Input() goToDiagnosticoAtEnd: boolean = false

    clasificaciones: Clasificacion[]

    @Output() planPersonalChange: EventEmitter<PlanPersonal> = new EventEmitter<PlanPersonal>()

    initialDate: Date

    @Input()
    set plan_personal_ciclo_orden(plan_personal_ciclo_orden: any) {
        this._plan_personal_ciclo_orden = plan_personal_ciclo_orden
        //this.setup();
    }
    get plan_personal_ciclo_orden() {
        return this._plan_personal_ciclo_orden
    }
    @Input()
    set plan_personal_sesion_id(plan_personal_sesion_id: any) {
        this._plan_personal_sesion_id = plan_personal_sesion_id
        this.setup()
    }
    get plan_personal_sesion_id() {
        return this._plan_personal_sesion_id
    }

    constructor(
        private authService: AuthService,
        protected scrollToService: ScrollToService,
        protected router: Router,
        protected planPersonalInstanciasService: PlanPersonalInstancias,
        protected planPersonalSesiones: PlanPersonalSesiones,
        protected planPersonalesService: PlanPersonales,
        protected materialesService: Materiales,
        protected planPersonalContenidosService: PlanPersonalContenidos,
        protected planPersonalMaterialInstanciasService: PlanPersonalMaterialInstancias,
        protected selectContenidosPlanificaciones: SelectContenidosPlanificaciones,
        protected clasificacionesService: Clasificaciones,
        protected location: Location,
        protected store: Store<State>
    ) {
        this.usuarioId = this.authService.getUserData().id
    }

    setup() {
        this.activeTab = 0
        this.activeComponent = 0
        this.enableProgressBtn = false
        this.enablePreviousBtn = false
        this.planPersonalComponentes = null
        this.nextSession = null
        this.previousSession = null
        this.nextCiclo = null
        this.previousCiclo = null
        this.componentesReady = false

        let params = {
            plan_personal_instancia: {
                plan_personal_id: this.plan_personal_id,
                usuario_id: this.usuarioId
            }
        }

        this.planPersonalInstanciasService.where(params).then((planPersonalInstancias: PlanPersonalInstancia[]) => {
            if (planPersonalInstancias.length > 0) {
                this.planPersonalInstancia = planPersonalInstancias[0]

                this.setPlanPersonal()
            } else {
                let planPersonalInstancia = new PlanPersonalInstancia()
                planPersonalInstancia.plan_personal_id = this.plan_personal_id
                planPersonalInstancia.usuario_id = this.usuarioId

                this.planPersonalInstanciasService
                    .save(planPersonalInstancia)
                    .then((planPersonalInstancia: PlanPersonalInstancia) => {
                        this.planPersonalInstancia = planPersonalInstancia
                        this.setPlanPersonal()
                    })
            }
        })
    }

    setPlanPersonal() {
        const params = {
            plan_personal: {
                id: this.plan_personal_id
            },
            // plan_personal_usuarios: {
            //     usuario_id: this.usuarioId,
            //     item_id: this.usuarioId,
            //     tabla: 'Usuario'
            // },
            plan_personal_ciclo: {
                orden: this.plan_personal_ciclo_orden
            },
            // plan_personal_material_instancia: {
            // 	plan_personal_instancia_id: [this.planPersonalInstancia.id, null]
            // },
            // plan_personal_evaluacion_instancia: {
            // 	plan_personal_instancia_id: [this.planPersonalInstancia.id, null]
            // },
            render_options: {
                include: {
                    plan_personal_tipo: null,
                    generador_instrumento: { include: "asignatura" },
                    plan_personal_ciclos: {
                        include: {
                            plan_personal_sesiones: {
                                include: {
                                    plan_personal_contenidos: null
                                    // {
                                    //     include: ['plan_personal_material_instancias', 'plan_personal_evaluacion_instancias']
                                    // }
                                },
                                methods: ["activa"]
                            }
                        }
                    }
                }
            }
        }
        this.planPersonalesService.where(params).then((planesPersonales: PlanPersonal[]) => {
            if (planesPersonales.length > 0) {
                this.planPersonal = planesPersonales[0]
                this.planPersonalCiclo = this.planPersonal.plan_personal_ciclos.find(
                    c => c.orden == this.plan_personal_ciclo_orden
                )
                this.planPersonalSesion = this.planPersonalCiclo.plan_personal_sesiones.find(
                    s => s.id == this.plan_personal_sesion_id
                )
                this.nextSession = this.planPersonalCiclo.plan_personal_sesiones.find(
                    s => s.orden == this.planPersonalSesion.orden + 1
                )
                this.nextCiclo = this.planPersonal.plan_personal_ciclos.find(
                    c => c.orden == this.planPersonalCiclo.orden + 1
                )
                this.previousCiclo = this.planPersonal.plan_personal_ciclos.find(
                    c => c.orden == this.planPersonalCiclo.orden - 1
                )
                this.previousSession = this.planPersonalCiclo.plan_personal_sesiones.find(
                    s => s.orden == this.planPersonalSesion.orden - 1
                )

                if (this.planPersonal.plan_personal_tipo.tipo == "personal") {
                    this.planesPersonalesRoute = this.planesPersonalesRoute || "/planes_personales/"
                } else {
                    this.planesPersonalesRoute = this.planesPersonalesRoute || "/plan_personal/" + this.planPersonal.id
                }

                let componentes_params = {
                    plan_personal_contenido: {
                        plan_personal_sesion_id: this.planPersonalSesion.id
                    },
                    plan_personal_instancia_id: this.planPersonalInstancia.id,
                    render_options: {
                        include: {
                            plan_personal_evaluacion_instancias: null,
                            plan_personal_material_instancias: null
                        }
                    }
                }
                this.planPersonalContenidosService.where(componentes_params).then(planPersonalContenidos => {
                    if (this.selectContenidosPlanificaciones.getSelectedRecursos()) {
                        const selectedRecursos = this.selectContenidosPlanificaciones.getSelectedRecursos()
                        this.planPersonalComponentes = planPersonalContenidos.filter(ppc =>
                            selectedRecursos.find(sr => sr.contenido_id == ppc.id)
                        )
                        this.selectContenidosPlanificaciones.unsetSelectedRecursos()
                    } else {
                        this.planPersonalComponentes = planPersonalContenidos
                    }

                    this.planPersonalComponentes = this.planPersonalComponentes.sort((a, b) => a.orden - b.orden)

                    this.hash_evaluaciones = {}
                    this.planPersonalComponentes.forEach((ppc, index) => {
                        if (ppc.plan_personal_sesion_id == this.plan_personal_sesion_id && ppc.tipo == "Evaluacion") {
                            this.hash_evaluaciones[ppc.plan_personal_sesion_id] = {}
                            this.hash_evaluaciones[ppc.plan_personal_sesion_id][0] = ppc.tipo_id
                        }
                    })

                    this.planPersonalComponentes.forEach((component: PlanPersonalContenido) => {
                        let avance = false
                        if (component.tipo == "Material")
                            avance = component.plan_personal_material_instancias.length > 0
                        else if (component.tipo == "Evaluacion")
                            avance = component.plan_personal_evaluacion_instancias.length > 0

                        this.componentesAvance[component.id] = avance
                    })

                    this.markAsSeen(this.planPersonalComponentes[this.activeComponent])
                    this.setPrevElementType()
                    this.setNextElementType()
                    this.getMateriales()
                })

                this.planPersonalChange.emit(this.planPersonal)

                // this.contenidoInstancias = {};
                // this.planPersonalComponentes.forEach((c, index3) => {
                //     this.contenidoInstancias[c.tipo_id + c.tipo] = c;
                // });
            }
        })
    }

    getMateriales() {
        const promesas = []

        const materialIds = this.planPersonalComponentes
            .filter(ppc => {
                return ppc.tipo === "Material" && (ppc.material === undefined || ppc.material === null)
            })
            .map((ppc: PlanPersonalContenido) => ppc.tipo_id)
        if (materialIds.length > 0) {
            promesas.push(
                this.materialesService.wherePost({ material: { id: materialIds } }).then((materiales: Material[]) => {
                    materiales.forEach((material: Material) => {
                        const ppc = this.planPersonalComponentes.find(
                            ppc => ppc.tipo === "Material" && ppc.tipo_id == material.id
                        )
                        ppc.material = material
                    })
                })
            )
        }

        return Promise.all(promesas).then(() => {
            this.initialDate = new Date()

            this.componentesReady = true
            this.enableProgressBtn = true
            this.enablePreviousBtn = true
            this.loadingLayout.ready()
        })
    }

    markAsSeen(c: PlanPersonalContenido) {
        let promise: Promise<any>

        if (c.tipo == "Material") {
            if (c.plan_personal_material_instancias.length == 0) {
                let materialInstancia = new PlanPersonalMaterialInstancia()
                materialInstancia.plan_personal_instancia_id = this.planPersonalInstancia.id
                materialInstancia.plan_personal_contenido_id = c.id
                materialInstancia.tiempo = 0

                promise = this.planPersonalMaterialInstanciasService
                    .save(materialInstancia)
                    .then((materialInstancia: PlanPersonalMaterialInstancia) => {
                        c.plan_personal_material_instancias = [materialInstancia]

                        this.componentesAvance[c.id] = true

                        this.checkCompletedSesion()
                    })
            }
        }

        if (!promise) {
            promise = Promise.resolve()
        }

        this.planPersonalComponentes.forEach((component: PlanPersonalContenido) => {
            let avance = false
            if (component.tipo == "Material") avance = component.plan_personal_material_instancias.length > 0
            else avance = component.plan_personal_evaluacion_instancias.length > 0

            this.componentesAvance[component.id] = avance
        })

        return promise
    }

    @CheckAchievement("PN_PLAN_CLASES_COMPLETAR_CICLO", CrearAlertaLogros)
    @CheckActividad("SC")
    checkCompletedSesion() {
        return this.planPersonalSesion
    }

    onDestroyMaterial(m: Material) {
        let planPersonalMaterial = this.planPersonalComponentes.find(c => c.tipo == "Material" && c.tipo_id == m.id)
        if (!planPersonalMaterial) {
            console.log("Material sin plan personal contenido")
            return
        }

        let fun = (planPersonalMaterialInstancia: PlanPersonalMaterialInstancia) => {
            let tiempo = ~~((new Date().getTime() - this.initialDate.getTime()) / 1000)

            planPersonalMaterialInstancia.tiempo = planPersonalMaterialInstancia.tiempo || 0
            planPersonalMaterialInstancia.tiempo += tiempo

            this.planPersonalMaterialInstanciasService
                .update(planPersonalMaterialInstancia.id, planPersonalMaterialInstancia)
                .then((ppmi: PlanPersonalMaterialInstancia) => {
                    planPersonalMaterial.plan_personal_material_instancias = [ppmi]

                    this.initialDate = new Date()
                })
        }

        if (planPersonalMaterial.plan_personal_material_instancias.length != 0) {
            let planPersonalMaterialInstancia = planPersonalMaterial.plan_personal_material_instancias[0]

            fun(planPersonalMaterialInstancia)
        } else {
            this.markAsSeen(planPersonalMaterial).then(() => {
                let planPersonalMaterialInstancia = planPersonalMaterial.plan_personal_material_instancias[0]

                fun(planPersonalMaterialInstancia)
            })
        }
    }

    onMenuChangeComponent(component_id) {
        this.activeComponent = component_id
        if (this.unrestrictedProgressionMode) {
            this.markAsSeen(this.planPersonalComponentes[this.activeComponent])
        }
    }

    goToNextComponent() {
        if (this.activeComponent < this.planPersonalComponentes.length - 1) {
            this.activeComponent++
            this.markAsSeen(this.planPersonalComponentes[this.activeComponent])
            this.scrollToService.scrollToTop()
        }
    }

    goToPreviousComponent() {
        if (this.activeComponent > 0) {
            this.activeComponent--
            this.markAsSeen(this.planPersonalComponentes[this.activeComponent])
            this.scrollToService.scrollToTop()
        }
    }

    goToNextSesion() {
        if (!this.enableGoBack) {
            if (this.nextSession) {
                this.router.navigate([
                    "/plan_personal/" + this.planPersonal.id + "/ciclo/" + this.planPersonalCiclo.orden + "/sesiones",
                    this.nextSession.id
                ])
            } else if (this.nextCiclo) {
            }
        } else {
            this.router.navigate([this.planesPersonalesRoute])
        }
    }

    goToNextCiclo() {
        if (this.nextCiclo) {
            let nextSesion = this.nextCiclo.plan_personal_sesiones.find(s => s.orden == 0)
            this.router.navigate([
                "plan_personal",
                this.planPersonal.id,
                "ciclo",
                this.nextCiclo.orden,
                "sesiones",
                nextSesion.id
            ])
        }
    }

    goToPreviousSesion() {
        if (!this.enableGoBack) {
            if (this.previousSession) {
                this.router.navigate([
                    "/plan_personal/" + this.planPersonal.id + "/ciclo/" + this.planPersonalCiclo.orden + "/sesiones",
                    this.previousSession.id
                ])
            }
        } else {
            this.router.navigate([this.planesPersonalesRoute])
        }
    }

    goToPreviousCiclo() {
        if (this.previousCiclo) {
            let maxOrden = Math.max(...this.previousCiclo.plan_personal_sesiones.map(s => s.orden))
            let previousSesion = this.previousCiclo.plan_personal_sesiones.find(s => s.orden == maxOrden)
            this.router.navigate([
                "plan_personal",
                this.planPersonal.id,
                "ciclo",
                this.previousCiclo.orden,
                "sesiones",
                previousSesion.id
            ])
        }
    }

    goToBeginning() {
        // Ir al home del plan personal.
        this.goHomePlanesPersonales()
    }

    goToEnd() {
        // Si no hay más sesiones o ciclos, ir a la evaluación de diagnóstico
        // Por ahora todo te lleva a la página principal
        this.goToPlan()
    }

    goToPlan() {
        if (this.backAtEnd) {
            this.location.back()
        } else if (this.routeWithId) {
            let planesRoute = this.planesPersonalesRoute
            if (!planesRoute.includes(`${this.planPersonal.id}`)) {
                planesRoute = `${planesRoute}/${this.planPersonal.id}`
            }
            this.router.navigate([planesRoute])
        } else {
            this.router.navigate([this.planesPersonalesRoute], {
                queryParams: {
                    asignatura_id: this.planPersonal.generador_instrumento.asignatura.id,
                    ...(this.planPersonal.plan_personal_tipo.tipo != "personal"
                        ? {
                              generador_instrumento_id: this.planPersonal.generador_instrumento.id
                          }
                        : {})
                }
            })
        }
    }

    goHomePlanesPersonales() {
        let navigateParams = {}
        if (this.planPersonal.plan_personal_tipo.tipo == "personal") {
            navigateParams = { queryParams: { asignatura_id: this.planPersonal.generador_instrumento.asignatura.id } }
        }

        this.router.navigate([this.planesPersonalesRoute], navigateParams)
    }

    goToNext() {
        if (this.activeComponent < this.planPersonalComponentes.length - 1) {
            this.goToNextComponent()
        } else if (this.nextSession) {
            if (this.planPersonal.plan_personal_tipo.tipo == "planificacion") {
                if (this.nextSession.activa) this.goToNextSesion()
                else this.goToEnd()
            } else this.goToNextSesion()
        } else if (this.nextCiclo) {
            this.goToNextCiclo()
        } else if (this.goToDiagnosticoAtEnd) {
            if (this.planPersonal.plan_personal_tipo.tipo == "personal") {
                this.goToDiagnostico()
            } else {
                this.goToEnd()
            }
        } else {
            this.goToEnd()
        }
    }

    goToDiagnostico() {
        let params = {
            tipo_evaluacion: this.planPersonal.generador_instrumento.tipo_instrumento.tipo_instrumento,
            asignatura_id: this.planPersonal.generador_instrumento.asignatura.id,
            generador_instrumento_id: this.planPersonal.generador_instrumento_id,
            clasificaciones: this.planPersonalCiclo.clasificaciones.split(",")
        }
        this.router.navigate(["/diagnostico"], { queryParams: params })
    }

    goToPrevious() {
        if (this.activeComponent > 0) {
            this.goToPreviousComponent()
        } else if (this.previousSession) {
            this.goToPreviousSesion()
        } else if (this.previousCiclo) {
            this.goToPreviousCiclo()
        } else {
            this.goToBeginning()
        }
    }

    navigateToSesion(sesionId) {}

    checkIfEvaluationExists(orden: number) {
        orden = 0
        return (
            this.hash_evaluaciones &&
            this.hash_evaluaciones[this.plan_personal_sesion_id] &&
            this.hash_evaluaciones[this.plan_personal_sesion_id][orden]
        )
    }

    private ordenar(list_m: any[], list_l: any[]) {
        /*return list_m.concat(list_l).sort((a,b) => {
            return a.orden - b.orden;
        });*/
    }

    type(obj: any) {
        return obj.constructor
    }

    isActive(i): boolean {
        return i == this.activeTab ? true : false
    }

    setActive(i): void {
        this.activeTab = i
    }

    setPrevElementType() {
        if (this.activeComponent > 0) {
            this.prevElementType = "contenido"
        } else if (this.previousSession) {
            this.prevElementType = "sesion"
        } else if (this.previousCiclo) {
            this.prevElementType = "ciclo"
        } else {
            this.prevElementType = "plan"
        }
    }

    setNextElementType() {
        if (this.activeComponent < this.planPersonalComponentes.length - 1) {
            this.nextElementType = "contenido"
        } else if (this.nextSession) {
            if (this.planPersonal.plan_personal_tipo.tipo == "planificacion") {
                if (this.nextSession.activa) this.nextElementType = "sesion"
                else this.nextElementType = "plan"
            } else this.nextElementType = "sesion"
        } else if (this.nextCiclo) {
            this.nextElementType = "ciclo"
        } else {
            this.nextElementType = "plan"
        }
    }
}
