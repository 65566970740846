/** @format */

import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router"
// import { AuthService } from "./auth.service";
import { Store } from "@ngrx/store"
import { State, SetCodigoConfirmacionData } from "@puntaje/puntaje/store"
import { AppConfig, AuthGuard, AuthService, SessionService, ZopimService } from "@puntaje/shared/core"

@Injectable({
    providedIn: "root"
})
export class PuntajeAuthGuard extends AuthGuard implements CanActivate {
    constructor(
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected zopimService: ZopimService,
        protected router: Router,
        protected store: Store<State>,
        protected config: AppConfig
    ) {
        super(authService, sessionService, zopimService, router, config)
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authService.isLoggedIn() || !this.sessionService.minUserFields()) {
            const usuario = this.sessionService.getUserData()
            if ((this.sessionService.isFromNomina() && usuario) || !this.sessionService.minUserFields()) {
                this.router.navigate(["/usuarios/" + usuario.id + "/completar_registro"])
            } else if (this.sessionService.getResetPasswordSentAt()) {
                this.store.dispatch(
                    new SetCodigoConfirmacionData({
                        codigoConfirmacionData: {
                            id: usuario.id,
                            email: usuario.email,
                            tipo_envio: usuario.tipo_envio
                        }
                    })
                )
                const queryParams = {
                    queryParams: { typoEnvio: usuario.tipo_envio }
                }
                this.router.navigate(["/usuarios/registrado"], queryParams)
            }
        }

        return super.canActivate(route, state)
    }
}
