import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { TipoContacto } from "./tipo_contactos.model"

export class AsociacionContacto extends BaseModel {
    asociacion_id: number
    usuario_id: number
    tipo_contacto_id: number
    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => TipoContacto) tipo_contactos: TipoContacto[]

    fecha: string
    comentarios: string
    efectivo: boolean
}
