<div class="filter" [class.clearfix]="enableBarMode" [class.filter-bar]="enableBarMode">
    <div class="search-container clearfix" [class.bar-mode]="enableBarMode" [class.elastic]="elastic">
        <input
            type="text"
            name="currentSearch"
            [(ngModel)]="currentSearch"
            class="form-control"
            placeholder="{{ placeholder }}"
            (focusout)="autoclear()"
            (keyup.enter)="search()"
        />
        <cui-button class="btn-search" (click)="search()">
            <fa name="search" class="only-icon"></fa>
        </cui-button>
        <button type="button" class="btn-clear" (click)="clear()" *ngIf="currentSearch && currentSearch.length > 0">
            <fa name="times"></fa>
        </button>
    </div>
</div>
