import { Location } from "@angular/common"
import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ChangeDetectorRef,
    TemplateRef,
    SimpleChanges,
    OnChanges
} from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Material, Materiales, MaterialTipo } from "@puntaje/nebulosa/api-services"
import { AuthService, MaterialPdfService, FullscreenService, CheckAchievementAsync } from "@puntaje/shared/core"
import {
    DescargaMaterialUsuario,
    DescargaMaterialUsuarios,
    MaterialFavorito,
    MaterialFavoritos,
    MaterialRelacion,
    MaterialRelaciones,
    MaterialUsuarios
} from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store } from "@ngrx/store"
import { State, CrearAlertaLogros } from "@puntaje/puntaje/store"

@Component({
    selector: "material",
    templateUrl: "material.component.html",
    styleUrls: ["material.component.scss"]
})
export class MaterialComponent implements OnInit, OnDestroy, OnChanges {
    @Input() material: Material
    @Input() hideTitle: boolean = false
    @Input() showVolver: boolean = true
    @Input() enableFavorito: boolean = false
    @Input() enableMaterialRelacionado: boolean = true
    @Input() enableDudas: boolean = true
    @Input() enableReporte: boolean = true
    @Input() openInFullscreen: boolean = false
    @Input() isViewOnYoutubeEnabled: boolean = true
    @Input() enableSentiment: boolean = !config.plataforma.disableSentimentMaterial

    isFromProfesor: boolean = true
    materialFavorito: MaterialFavorito
    materialRelaciones: MaterialRelacion[]
    showFavorito: boolean = false
    disableBtnFavorito: boolean = false
    usuario_id: number
    params = Material.show_params
    private sub: Subscription
    private subPages: Subscription
    dudaQueryParams: any = {}

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    isVideo: boolean = false
    isArchivo: boolean = false
    isPdf: boolean = false
    isGame: boolean = false
    isHTML: boolean = false
    isPPT: boolean = false
    isInteractivo: boolean = false
    isWord: boolean = false
    isExcel: boolean = false
    key: string = "url"
    lookViewOnYoutube: boolean = false
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    @Output() destroy: EventEmitter<any> = new EventEmitter<any>()
    @Output() goNext: EventEmitter<any> = new EventEmitter<any>()
    @Output() goPrev: EventEmitter<any> = new EventEmitter<any>()
    currentPage: number

    @Input() id: number

    urlsafe: SafeResourceUrl
    fullScreen: boolean = false
    @ViewChild("pdfObject", { read: TemplateRef }) pdfObject: TemplateRef<any>
    @ViewChild("materialObject", { read: TemplateRef }) materialObject: TemplateRef<any>

    constructor(
        protected materialesService: Materiales,
        protected router: Router,
        protected route: ActivatedRoute,
        protected location: Location,
        protected materialFavoritosService: MaterialFavoritos,
        protected materialRelacionesService: MaterialRelaciones,
        protected sanitizer: DomSanitizer,
        protected cdr: ChangeDetectorRef,
        public materialPdfService: MaterialPdfService,
        public fullscreenService: FullscreenService,
        protected authService: AuthService,
        protected materialUsuariosService: MaterialUsuarios,
        protected descargaMaterialUsuarioService: DescargaMaterialUsuarios,
        protected store: Store<State>
    ) {
        let usuario = this.authService.getUserData()
        this.usuario_id = usuario.id
    }

    ngOnInit() {
        if (config.plataforma.disableButtonDuda) {
            this.enableDudas = false
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["material"] && changes["material"].currentValue) {
            this.materialPdfService.unsetNumberOfPages()
            this.processMaterial()
        }

        if (changes["id"] && changes["id"].currentValue) {
            this.materialPdfService.unsetNumberOfPages()
            this.getMaterial(this.id)
        }

        if (!changes["material"] && !changes["id"] && !this.sub) {
            this.sub = this.route.params.subscribe(params => {
                this.getMaterial(+params["id"])
            })
        }
    }

    onNavigate(material) {
        window.open(material.url, "_blank")
    }

    processMaterial() {
        if (
            config.plataforma.name == "College Board" &&
            (this.material.nombre_asignatura == "Lectura" || this.material.nombre_asignatura == "Redacción")
        ) {
            this.lookViewOnYoutube = true
        }

        this.isVideo =
            this.material.material_tipo_nombre == "Video" || this.material.material_tipo_nombre == "Streaming"
        this.isArchivo = this.material.material_tipo_nombre == "Archivo"
        this.isPdf = this.material.material_tipo_nombre == "Archivo" && this.material.getExtension() == "pdf"
        this.isGame = this.material.material_tipo_nombre == "Juego"
        this.isHTML = this.material.material_tipo_nombre == "HTML"
        this.isInteractivo = this.material.material_tipo_nombre == "Interactivo"
        this.isPPT =
            this.material.material_tipo_nombre == "PPT" &&
            (this.material.getExtension() == "ppt" || this.material.getExtension() == "pptx")
        this.isWord =
            this.material.material_tipo_nombre == "Word" &&
            (this.material.getExtension() == "doc" || this.material.getExtension() == "docx")
        this.isExcel =
            this.material.material_tipo_nombre == "Excel" &&
            (this.material.getExtension() == "xls" || this.material.getExtension() == "xlsx")
        if (this.enableFavorito) this.getFavorito()
        let query = {
            material_relacion: {
                material_id: this.material.id
            },
            methods: "material"
        }
        this.materialRelacionesService.where(query).then((response: MaterialRelacion[], total: number) => {
            this.materialRelaciones = response
        })
        this.materialUsuariosService
            .where({ material_usuario: { material_id: this.material.id } })
            .then(materialUsuarios => {
                this.isFromProfesor = materialUsuarios.length != 0
            })

        this.loadingLayout.ready()

        this.urlsafe = this.isPdf && this.sanitizer.bypassSecurityTrustResourceUrl(this.material.url + "#view=FitH")

        if (this.isPdf) {
            this.subPages = this.materialPdfService.numberOfPages$.subscribe(numPages => {
                this.currentPage = 1
                this.cdr.detectChanges()

                if (this.openInFullscreen) {
                    this.fullscreenService.openFullscreen(this.pdfObject)
                }
            })
        } else {
            this.cdr.detectChanges()

            if (this.openInFullscreen) {
                this.fullscreenService.openFullscreen(this.materialObject)
            }
        }

        this.dudaQueryParams = {
            item_id: this.material.id,
            item_type: "Material",
            item_categoria: this.material["nombre_asignatura"]
        }
    }

    getMaterial(materialId: number) {
        if (this.loadingLayout) this.loadingLayout.standby()

        this.materialesService.find(materialId).then((response: Material) => {
            this.material = response
            this.processMaterial()
            this.onReady.emit(this.material)
        })
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe()
        }

        if (this.subPages) {
            this.subPages.unsubscribe()
        }

        this.destroy.emit(this.material)
    }

    getDownloadLink = (m: Material) => {
        return m.url
    }

    isFileLink = (m: Material) => {
        return m.isFile()
    }

    printElem(m: Material) {
        var mywindow = window.open("", "PRINT", "height=600,width=900")

        mywindow.document.write("<html><head><title></title>")
        mywindow.document.write("</head><body >")
        mywindow.document.write("<h1></h1>")
        mywindow.document.write(document.getElementById("material" + m.id).innerHTML)
        mywindow.document.write("</body></html>")

        mywindow.document.close() // necessary for IE >= 10
        mywindow.focus() // necessary for IE >= 10*/

        mywindow.print()
        mywindow.close()
    }

    goBack() {
        this.location.back()
    }

    getFavorito() {
        this.materialFavoritosService
            .where({ material_favorito: { material_id: this.material.id, usuario_id: this.usuario_id } })
            .then((response: MaterialFavorito[]) => {
                if (response && response.length > 0) {
                    this.materialFavorito = response[0]
                }
                this.showFavorito = true
            })
    }

    goToGuia(idGuia) {
        this.router.navigate(["/materiales/" + idGuia])
    }

    goToEvaluacion(idEvaluacion) {
        this.router.navigate(["ensayos/" + idEvaluacion + "/realizar"])
    }

    toggleFavorito() {
        if (!this.disableBtnFavorito) {
            this.disableBtnFavorito = true
            if (this.materialFavorito) {
                this.materialFavoritosService.remove(this.materialFavorito.id).then((response: MaterialFavorito) => {
                    this.materialFavorito = null
                    this.disableBtnFavorito = false
                })
            } else {
                this.addMaterialFavorito()
            }
        }
    }

    @CheckAchievementAsync("PN_MATERIAL_FAVORITO", CrearAlertaLogros)
    addMaterialFavorito() {
        let favorito = new MaterialFavorito()
        favorito.material_id = this.material.id
        favorito.usuario_id = this.usuario_id
        return this.materialFavoritosService.save(favorito).then((response: MaterialFavorito) => {
            this.materialFavorito = response
            this.disableBtnFavorito = false

            return response
        })
    }

    arrowLeft(event) {
        if (this.currentPage > 1) {
            this.currentPage--
        } else {
            this.goPrev.emit()
        }
    }

    arrowRight(event) {
        if (this.currentPage < this.materialPdfService.numberOfPages) {
            this.currentPage++
        } else {
            this.goNext.emit()
        }
    }

    onDownload() {
        const descargaMatUsuario = new DescargaMaterialUsuario()
        descargaMatUsuario.material_id = this.material.id
        descargaMatUsuario.usuario_id = this.authService.getUserData().id

        this.descargaMaterialUsuarioService.save(descargaMatUsuario)
    }
}
