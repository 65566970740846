import { Component, OnInit, OnDestroy, Type } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import { AppConfig, AuthService } from "@puntaje/shared/core"
import {
    UsuariosCloneEditComponent,
    UsuariosEditComponent as UsuariosEditCoreComponent
} from "@puntaje/puntaje/new-modules/usuarios"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "usuarios.edit.component.html"
})
export class UsuariosEditComponent implements OnInit, OnDestroy {
    loggedLayoutTitle: string = "Editar mis datos"
    usuarioId: number

    component: Type<any> = config.app.name == "alumnos" ? UsuariosCloneEditComponent : UsuariosEditCoreComponent
    inputs: any = {
        usuarioId: null,
        disableEditNombres: false,
        enableShowCountryData: true,
        enableAsignaturaDefinida: true,
        enableDeleteMe: false,
        enableFacebookLink: true,
        enableTutor: false,
        showPreferencia: true,
        disableEditNivel: config.app.name === "profesores",
        enableDeleteMeWithGrupoUsuarios: true,
        disableEditLugar: false,
        disableViewLugar: false,
        disableViewNivel: config.app.name === "profesores"
    }

    subData: Subscription

    constructor(
        protected titleService: TitleService,
        protected authService: AuthService,
        protected route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.usuarios.edit")
        this.usuarioId = this.authService.getUserData().id
        this.inputs.usuarioId = this.authService.getUserData().id
        this.subData = this.route.data.subscribe(data => {
            this.component = data.component ?? this.component
            this.inputs.disableEditNombres = data.disableEditNombres ?? this.inputs.disableEditNombres
            this.inputs.enableAsignaturaDefinida = data.enableAsignaturaDefinida ?? this.inputs.disableEditNombres
            this.inputs.enableShowCountryData = data.enableShowCountryData ?? this.inputs.enableShowCountryData
            this.inputs.enableDeleteMe = data.enableDeleteMe ?? this.inputs.enableDeleteMe
            this.inputs.enableFacebookLink = data.enableFacebookLink ?? this.inputs.enableFacebookLink
            this.inputs.enableTutor = data.enableTutor ?? this.inputs.enableTutor
            this.inputs.showPreferencia = data.showPreferencia ?? this.inputs.showPreferencia
            this.inputs.disableEditNivel = data.disableEditNivel ?? this.inputs.disableEditNivel
            this.inputs.enableDeleteMeWithGrupoUsuarios =
                data.enableDeleteMeWithGrupoUsuarios ?? this.inputs.enableDeleteMeWithGrupoUsuarios
            this.inputs.disableEditLugar = data.disableEditLugar ?? this.inputs.disableEditLugar
            this.inputs.disableViewLugar = data.disableViewLugar ?? this.inputs.disableViewLugar
            this.inputs.disableViewNivel = data.disableViewNivel ?? this.inputs.disableViewNivel
        })
    }

    ngOnDestroy() {
        this.subData.unsubscribe()
    }
}
