<nav class="navbar" [attr.aria-label]="'navbar.aria' | t">
    <div class="container-fluid">
        <div class="navbar-content">
            <div class="navbar-left">
                <button
                    type="button"
                    [class.responsive-toggle]="!enableMenu"
                    class="topmenu-toggle"
                    (click)="toggleTopmenu()"
                >
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <div class="navbar-logo-container">
                    <a routerLink="/home">
                        <img class="navbar-logo" src="{{ logo }}" alt="{{ config.plataforma.info.companyName }}" />
                        <img
                            class="navbar-logo-res"
                            src="{{ logoMobile }}"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                    </a>
                </div>
            </div>
            <div class="user-menu">
                <ul class="nav navbar-top-links navbar-right">
                    <li>
                        <a routerLink="/home" role="button">
                            <ogr-icon class="centered-icons" name="home-o"></ogr-icon>
                        </a>
                    </li>
                    <li *ngIf="showNotificaciones">
                        <a routerLink="/notificaciones" role="button" class="alerta-container">
                            <span class="centered-icons">
                                <ogr-icon class="centered-icons" name="campana-o"></ogr-icon>
                            </span>
                            <alerta-notificaciones class="alerta"></alerta-notificaciones>
                        </a>
                    </li>
                    <li *ngIf="showAyuda">
                        <a (click)="goToAyuda()">{{ "navbar.ayuda" | t }}</a>
                    </li>
                    <li class="li-username">
                        <a [routerLink]="['/usuarios', usuario_id]">
                            <span title="{{ usuario_nombre | easyplaceholder: 'Usuario' }}" class="user-name user-info">
                                {{ usuario_nombre | easyplaceholder: "Usuario" }}
                            </span>
                            <span
                                *ngIf="showUserID && usuario_id"
                                title="{{ usuario_id | easyplaceholder: 'ID' }}"
                                class="user-id user-info"
                            >
                                ID:&nbsp;{{ usuario_id | easyplaceholder: "" }}
                            </span>
                            <fa name="user-o" class="centered-icons user-name-res"></fa>
                            <span
                                *ngIf="config.plataforma.enableAchievements && nivel"
                                class="nivel"
                                [attr.title]="ptosToNextLvl + ' ptos para el siguiente nivel'"
                            >
                                {{ nivel }}
                            </span>
                        </a>
                    </li>
                    <li class="dropdown last-list-item">
                        <a
                            class="dropdown-toggle"
                            [attr.aria-label]="'navbar.menu.aria' | t"
                            data-toggle="dropdown"
                            href="#"
                        >
                            <div class="clearfix last-item-cont">
                                <div class="user-circle-container pull-left">
                                    <img
                                        *ngIf="usuario_avatar"
                                        class="avatar"
                                        [attr.aria-label]="'navbar.avatar.aria' | t"
                                        [src]="usuario_avatar_safe"
                                    />
                                    <fa
                                        *ngIf="!usuario_avatar"
                                        class="fa-placeholder"
                                        [attr.aria-label]="'navbar.avatar.default_aria' | t"
                                        [name]="'user'"
                                    ></fa>
                                    <nivel-circlebar
                                        *ngIf="config.plataforma.enableAchievements"
                                        (onReady)="setNivel($event)"
                                    ></nivel-circlebar>
                                </div>
                                <div class="pull-right little-arrow-user">
                                    <fa [name]="'sort-desc'"></fa>
                                </div>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-user">
                            <li>
                                <a (click)="edit()">{{ "navbar.edit" | t }}</a>
                            </li>
                            <li>
                                <a (click)="logout()">{{ "navbar.logout" | t }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="topmenu-container">
        <base-topmenu
            *ngIf="showTopmenu"
            class="topmenu"
            [config]="config"
            (toggleTopmenuEvent)="toggleTopmenu()"
            @topmenu
        ></base-topmenu>
    </div>
    <base-menu-horizontal *ngIf="showMenuHorizontal"></base-menu-horizontal>
</nav>
<alerta-box *ngIf="config.plataforma.enableAchievements && nivel"></alerta-box>
