import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Asignatura } from "./asignaturas.model"
import { PlanEstudios } from "./plan_estudios.service"
import { PlanEstudioSesion } from "./plan_estudio_sesiones.model"
import { CertificacionRecurso } from "./certificacion_recursos.model"

export class PlanEstudio extends BaseModel {
    public static className: string = "PlanEstudio"
    public id: number
    public plan_estudio: string
    public descripcion: string
    public estado: number
    public asignatura_id: number
    public nombre_asignatura: string
    private sesionCount: number = -1
    public plan_estudio_sesiones_deleted: PlanEstudioSesion[] = []

    @SaveType(() => Asignatura) public asignatura: Asignatura
    @SaveType(() => PlanEstudioSesion) public plan_estudio_sesiones: PlanEstudioSesion[]
    @SaveType(() => CertificacionRecurso) public certificacion_recurso: CertificacionRecurso

    constructor() {
        super()
        this.asignatura = new Asignatura()
        this.plan_estudio_sesiones = []
    }

    public static get serviceClass() {
        return PlanEstudios
    }

    public toString() {
        return this.plan_estudio
    }

    public agregarSesion() {
        if (this.sesionCount == -1) {
            this.sesionCount = this.plan_estudio_sesiones.length // Max orden
        }
        var sesion = new PlanEstudioSesion()
        sesion.orden = this.sesionCount + 1
        sesion.estado = 1
        sesion.plan_estudio_id = this.id
        this.plan_estudio_sesiones.push(sesion)
        this.sesionCount = sesion.orden
        return sesion
    }

    public removerSesion(orden: number) {
        var elem = this.plan_estudio_sesiones.find(s => s.orden == orden)
        elem["_destroy"] = true
        this.plan_estudio_sesiones_deleted.push(elem)
        let sin = this.plan_estudio_sesiones.filter(s => s.orden != orden)
        this.plan_estudio_sesiones = sin
        this.plan_estudio_sesiones.map(sesion => {
            if (sesion.orden > orden) {
                sesion.orden = sesion.orden - 1
            }
        })
        this.sesionCount = this.sesionCount - 1
    }

    public agregarMaterialSesion(sOrden: number) {
        var m = null
        this.plan_estudio_sesiones.map(sesion => {
            if (sesion.orden == sOrden) {
                m = sesion.agregarSesionMaterial()
                return
            }
        })
        return m
    }

    public agregarLoteSesion(sOrden: number) {
        var l = null
        this.plan_estudio_sesiones.map(sesion => {
            if (sesion.orden == sOrden) {
                l = sesion.agregarSesionLote()
                return
            }
        })
        return l
    }

    public removerMaterialSesion(sOrden: number, orden: number) {
        this.plan_estudio_sesiones.map(sesion => {
            if (sesion.orden == sOrden) {
                sesion.removerSesionMaterial(orden)
            }
        })
    }

    public removerLoteSesion(sOrden: number, orden: number) {
        this.plan_estudio_sesiones.map(sesion => {
            if (sesion.orden == sOrden) {
                sesion.removerSesionLote(orden)
            }
        })
    }
}
