import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { BehaviorSubject } from "rxjs"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"
import { ReporteRazon } from "./reporte_razones.model"

@Injectable()
export class ReporteRazones extends NebuBaseService<ReporteRazon> {
    tableName = "reporte_razones"
    singularTableName = "reporte_razon"
    modelClass = ReporteRazon

    reporteRazonesSubject = new BehaviorSubject<{ [tabla: string]: ReporteRazon[] }>({})

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    loadReporteRazones() {
        this.where({ include: "reporte_sub_razones" }).then((reporteRazones: ReporteRazon[]) => {
            let reporteRazonesByTabla = reporteRazones.reduce((acc, rr) => {
                acc[rr.tabla] = acc[rr.tabla] || []
                acc[rr.tabla].push(rr)

                return acc
            }, {})

            this.reporteRazonesSubject.next(reporteRazonesByTabla)
        })
    }
}
