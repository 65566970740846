<div *ngIf="retroalimentacion" class="clearfix">
    <cui-section-headline>Retroalimentación</cui-section-headline>
    <ng-container [ngSwitch]="retroalimentacion.tipo">
        <ng-container *ngSwitchCase="'pdf'">
            <cui-button-link class="btn_style pull-right" [href]="retroalimentacion.retroalimentacion" target="_blank">
                Ver retroalimentación
            </cui-button-link>
        </ng-container>
        <ng-container *ngSwitchCase="'html'">
            <div [innerHTML]="retroalimentacion.retroalimentacion | trustedhtml"></div>
        </ng-container>
    </ng-container>
</div>
