import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Injector, Directive } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    UsuarioRegistroColombia,
    UsuariosRegistroIncompletoForm,
    Usuarios,
    Usuario,
    Establecimiento,
    Establecimientos
} from "@puntaje/puntaje/api-services"
import { Clasificaciones, Clasificacion } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Directive()
export class BaseRegisterIncompletoComponent implements OnInit {
    usuario: Usuario
    oUsuario: Usuario
    // params = UsuarioRegistroForm.formParams;
    params: any = UsuariosRegistroIncompletoForm.formParams
    form: UntypedFormGroup
    save_button_text = "Guardar"

    saved: boolean = false

    pais: string = config.plataforma.pais
    usuarioPaisParams: any = this.params["usuario_" + this.pais].class.formParams
    usuarioPaisForm: UntypedFormGroup

    egresado: Clasificacion

    filteredEstablecimientos: Establecimiento[]
    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()

    constructor(
        protected usuariosService: Usuarios,
        protected clasificacionesService: Clasificaciones,
        protected establecimientosService: Establecimientos,
        protected router: Router,
        protected injector: Injector,
        protected authService: AuthService,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (!this.authService.oauthData || !this.authService.oauthData.id) this.router.navigate(["landing"])

        let nivelTipo = {
            chile: "curso",
            colombia: "nivel colombia"
        }

        this.usuario = new Usuario()
        this.usuario["usuario_" + this.pais] = new Usuario["usuario_" + this.pais]()

        this.usuario.fillDataFromFB(this.authService.oauthData)

        this.form = UsuariosRegistroIncompletoForm.getForm(this.usuario, null, this.injector)
        this.usuarioPaisForm = this.form.controls["usuario_" + this.pais] as UntypedFormGroup

        this.clasificacionesService
            .where({
                clasificacion_tipo: { clasificacion_tipo: nivelTipo[this.pais] },
                clasificacion: { clasificacion: "Egresado" }
            })
            .then((clasificaciones: Clasificacion[]) => {
                this.egresado = clasificaciones[0]
            })
    }

    save() {
        UsuariosRegistroIncompletoForm.markFormControlsAsTouched(this.form)
        UsuariosRegistroIncompletoForm.markFormControlsAsDirty(this.form)

        if (this.form.valid) {
            if ((this.usuario as any).egresado) {
                this.usuario.nivel_id = this.egresado.id
            }

            if (this.usuario.establecimiento) {
                this.usuario.establecimiento_id = this.usuario.establecimiento.id
            }

            this.usuariosService.save(this.usuario).then(response => {
                this.onTitleChange.emit("¡Ya casi estás listo!")
                this.form.clearValidators()
                this.saved = true
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.usuario = new Usuario()
            UsuariosRegistroIncompletoForm.markFormControlsAsPristine(this.form)
            UsuariosRegistroIncompletoForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    back() {
        this.router.navigate([""])
    }

    getEstablecimientos(establecimientoFilter: string) {
        this.establecimientosService
            .where({ per: 100, establecimiento: { establecimiento: establecimientoFilter } })
            .then((establecimientos: Establecimiento[]) => {
                this.filteredEstablecimientos = establecimientos
            })
    }
}
