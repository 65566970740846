<div class="centered-div">
    <div class="logo-container">
        <a routerLink="/home">
            <img alt="logo" src="{{ config.app.assets.logoLarge }}" class="landing-logo" />
        </a>
    </div>
    <h2>{{ "errores.titulo_error" | t: { status: status } }}</h2>
    <div class="contenido">
        <div class="message">
            <p>{{ message }}</p>
        </div>
        <!--<div class="trace">
      {{trace}}
    </div>-->
    </div>
    <ng-container *ngIf="!disableContactInfo">
        <div *ngIf="!customErrorMessage" class="contact-info">
            <ng-container *ngIf="errores.base64Response">
                <p [innerHTML]="'errores.contacto_codigo' | t: { contact_mail: contact_mail }"></p>
            </ng-container>
            <ng-container *ngIf="!errores.base64Response">
                <div [innerHTML]="'errores.contacto' | t: { contact_mail: contact_mail }"></div>
            </ng-container>

            <p class="gracias">{{ "errores.gracias" | t }}</p>
        </div>
        <div *ngIf="customErrorMessage" class="custom-message" [innerHTML]="customErrorMessage"></div>
    </ng-container>
    <div class="code-container" *ngIf="errores.base64Response">
        <p>{{ "errores.codigo_error" | t }}</p>
        <div class="base-response-container" [class.show-all]="showAll">
            <div #element class="base-response" [class.show-all]="showAll">
                {{ errores.base64Response }}
            </div>
            <button (click)="toggleMoreCode()" [class.show-all]="showAll">
                {{ showAll ? ("errores.ver_menos" | t) : ("errores.ver_mas" | t) }}
            </button>
            <button (click)="copyToClipboard(element)" [class.show-all]="showAll">{{ "errores.copiar" | t }}</button>
        </div>
    </div>
    <div class="volver-container">
        <button (click)="goHome()">{{ "errores.volver_pagina_principal" | t }}</button>
    </div>
</div>
