import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { DudaRespuestas, DudaRespuestaForm, DudaRespuesta } from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"

@Component({
    selector: "duda-respuesta-edit",
    templateUrl: "./duda_respuesta_form.component.html",
    styleUrls: ["./duda_respuesta_form.component.scss"]
})
export class DudaRespuestaEditComponent implements OnInit {
    @Input() dudaRespuestaId: number
    @Output() save: EventEmitter<DudaRespuesta> = new EventEmitter<DudaRespuesta>()
    @Output() cancelReply: EventEmitter<any> = new EventEmitter<any>()

    form: UntypedFormGroup
    params = DudaRespuestaForm.formParams
    dudaRespuesta: DudaRespuesta
    oDudaRespuesta: DudaRespuesta
    loaded: boolean = false
    edit: boolean = true

    constructor(protected dudaRespuestasService: DudaRespuestas) {}

    ngOnInit() {
        this.dudaRespuesta = new DudaRespuesta()
        this.form = DudaRespuestaForm.getForm(this.dudaRespuesta)
        this.load()
    }

    responder() {
        this.dudaRespuestasService
            .update(this.dudaRespuestaId, this.dudaRespuesta)
            .then((dudaRespuesta: DudaRespuesta) => {
                this.dudaRespuesta = dudaRespuesta
                this.oDudaRespuesta = dudaRespuesta.clone()
                this.save.emit(dudaRespuesta)
            })
    }

    load() {
        this.loaded = false
        this.dudaRespuestasService.find(this.dudaRespuestaId).then((dudaRespuesta: DudaRespuesta) => {
            this.dudaRespuesta = dudaRespuesta
            this.oDudaRespuesta = dudaRespuesta.clone()
            this.loaded = true
        })
    }

    cancelar() {
        this.reset()
        this.cancelReply.emit()
    }

    reset() {
        this.dudaRespuesta = this.oDudaRespuesta.clone()
        DudaRespuestaForm.markFormControlsAsPristine(this.form)
        DudaRespuestaForm.markFormControlsAsUntouched(this.form)
    }
}
