import { Injectable } from "@angular/core"
import { Subject } from "rxjs"
import {
    EscalaGlobal,
    EscalaGlobalEvaluacion,
    EscalaGlobalEvaluaciones,
    EvaluacionInstancia
} from "@puntaje/puntaje/api-services"

@Injectable()
export class EvaluacionInstanciaEscalasService {
    evaluacionInstancia = new Subject<EvaluacionInstancia>()

    constructor(protected escalaGlobalEvaluacionesServices: EscalaGlobalEvaluaciones) {}

    protected porcentajeCorrectas(ei: EvaluacionInstancia) {
        const sum = ei.correctas + ei.incorrectas + ei.omitidas

        return sum > 0 ? Math.round(+parseFloat(((ei.correctas / sum) * 100).toFixed(1))) : sum
    }

    private getEscalaGlobalEvaluacionesParams(evaluacionInstancia: EvaluacionInstancia) {
        return {
            escala_global_evaluacion: {
                evaluacion_id: evaluacionInstancia.evaluacion_id
            },
            escala_global: { activa: 1 },
            render_options: {
                include: {
                    escala_global: {
                        include: { escala_global_aliases: null }
                    }
                }
            }
        }
    }

    public getEscalaGlobalAliasesPorcentaje(escalaGlobal: EscalaGlobal, evaluacionInstancia: EvaluacionInstancia) {
        const porcentaje = this.porcentajeCorrectas(evaluacionInstancia)

        return escalaGlobal.escala_global_aliases.filter(
            escalaAlias => porcentaje >= escalaAlias.equivalencia_min && porcentaje <= escalaAlias.equivalencia_max
        )
    }

    public getEscalas(evaluacionInstancia: EvaluacionInstancia) {
        const porcentaje = this.porcentajeCorrectas(evaluacionInstancia)

        return this.getAllEscalas(evaluacionInstancia).then(escalaGlobales => {
            escalaGlobales.forEach(escalaGlobal => {
                const evaluacionInstanciaEscalaGlobalAlias =
                    evaluacionInstancia.evaluacion_instancia_escala_global_aliases.find(
                        eiega => eiega.escala_global_id == escalaGlobal.id
                    )

                let escalaGlobalAliases = []
                if (evaluacionInstanciaEscalaGlobalAlias) {
                    escalaGlobalAliases = escalaGlobal.escala_global_aliases.filter(
                        ega => ega.id == evaluacionInstanciaEscalaGlobalAlias.escala_global_alias_id
                    )
                    escalaGlobal.escala_global_aliases = escalaGlobalAliases
                }

                if (escalaGlobalAliases.length == 0) {
                    escalaGlobal.escala_global_aliases = this.getEscalaGlobalAliasesPorcentaje(
                        escalaGlobal,
                        evaluacionInstancia
                    )
                }
            })

            return escalaGlobales.filter(escalaGlobal => escalaGlobal.escala_global_aliases.length)
        })
    }

    public getAllEscalas(evaluacionInstancia: EvaluacionInstancia) {
        const params = this.getEscalaGlobalEvaluacionesParams(evaluacionInstancia)

        return this.escalaGlobalEvaluacionesServices
            .where(params)
            .then((escalaGlobalEvaluaciones: EscalaGlobalEvaluacion[]) => {
                return escalaGlobalEvaluaciones
                    .map(escalaGlobalEvaluacion => {
                        return escalaGlobalEvaluacion.escala_global
                    })
                    .filter(escala => escala.escala_global_aliases.length)
            })
    }
}
