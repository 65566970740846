import { SaveType, BaseModel } from "@puntaje/shared/core"
import { BannerInstancia } from "./banner_instancias.model"
import { Sede } from "./sedes.model"
import { Universidad } from "./universidades.model"

export class Carrera extends BaseModel {
    public carrera: string
    public universidad_id: number
    public sede_id: number
    public orden: number
    public codigo_universidad: number
    public abreviatura: string

    public shortToString: boolean = false

    link_ficha: string
    @SaveType(() => Sede) public sede: Sede
    @SaveType(() => Universidad) public universidad: Universidad

    toString() {
        let str = this.carrera
        if (this.sede && !this.shortToString) {
            str = str + " | " + this.sede.sede
        }
        if (this.universidad && !this.shortToString) {
            str = str + " | " + this.universidad.universidad
        }
        return str
    }
}
