<cui-section-headline>{{ "subir_respuestas_imagen.titulo" | t }}</cui-section-headline>
<div class="clearfix" id="step1">
    <div class="row">
        <div class="col-md-5">
            <div class="tutorial clearfix">
                <div class="tutorial-image">
                    <div class="tutorial-image-pos">
                        <div class="tutorial-image-cell">
                            <img
                                src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/correccion_tutorial_1.png"
                                alt="Imagen de la hoja de respuestas"
                            />
                        </div>
                    </div>
                    <div class="step-number">1</div>
                </div>
                <h3 class="step">{{ "subir_respuestas_imagen.titulo_step_1" | t }}</h3>
                <p class="tutorial-text">
                    {{ "subir_respuestas_imagen.desc_step_1_1" | t }}
                    <a target="_blank" [href]="hojaRespuestaEjemploLink">{{ "subir_respuestas_imagen.desc_step_1_2" | t }}</a>
                    . {{ "subir_respuestas_imagen.desc_step_1_3" | t }}.
                    <br />
                    <br />
                    {{ "subir_respuestas_imagen.desc_step_1_4" | t }}
                    <a target="_blank" [href]="hojaRespuestaEjemploLink">{{ "subir_respuestas_imagen.desc_step_1_5" | t }}</a>
                    . {{ "subir_respuestas_imagen.desc_step_1_6" | t }}.
                </p>
                <div class="clearfix" *ngIf="respuestas">
                    <button
                        type="button"
                        class="next btn btn-default btn-sm"
                        href="#step2"
                        easyScrollTo
                        [easyScrollToPadding]="0"
                        [easyScrollToDuration]="800"
                        [easyScrollToEasing]="'easeInOut'"
                    >
                        {{ "subir_respuestas_imagen.siguiente" | t }}&nbsp;
                        <fa name="caret-right"></fa>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="form-group">
                <file
                    [(ngModel)]="file"
                    [xhr_upload]="xhrUpload"
                    key="imgFile"
                    (change)="checkIfImageExtension()"
                ></file>
                <div class="warning hide-warning" [class.show-warning]="wrongFileType">
                    <div class="warning-content">
                        <fa name="warning" class="warning-icon"></fa>
                        <div class="warning-message">
                            <p>{{ "subir_respuestas_imagen.step_1_error" | t }}:</p>
                            <ul>
                                <li>{{ "subir_respuestas_imagen.step_1_error" | t }}.</li>
                                <li>
                                    {{ "subir_respuestas_imagen.step_2_error" | t }}.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group clearfix">
                <cui-button class="btn_style pull-right" (click)="leerImagen()">
                    <ogr-icon name="correccion-imagen"></ogr-icon>
                    &nbsp;{{ "subir_respuestas_imagen.subir_foto" | t }}
                </cui-button>
            </div>
        </div>
    </div>
</div>
<div id="step2" class="clearfix">
    <loading-layout #loadingLayout>
        <div class="step-container" [hidden]="!respuestas">
            <div class="tutorial clearfix">
                <div class="tutorial-image">
                    <div class="tutorial-image-pos">
                        <div class="tutorial-image-cell">
                            <img
                                src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/correccion_tutorial_3.png"
                                alt="Revisar respuestas"
                            />
                        </div>
                    </div>
                    <div class="step-number">2</div>
                </div>
                <h3 class="step">{{ "subir_respuestas_imagen.titulo_step_2" | t }}</h3>
                <p class="tutorial-text">
                    {{ "subir_respuestas_imagen.desc_step_2" | t }}.
                </p>
                <div class="clearfix">
                    <button
                        type="button"
                        class="next btn btn-default btn-sm"
                        href="#step3"
                        easyScrollTo
                        [easyScrollToPadding]="0"
                        [easyScrollToDuration]="800"
                        [easyScrollToEasing]="'easeInOut'"
                    >
                        {{ "subir_respuestas_imagen.siguiente" | t }}&nbsp;
                        <fa name="caret-right"></fa>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8" [hidden]="!respuestas">
                    <div class="canvas-container">
                        <h3>{{ "subir_respuestas_imagen.hoja_respuesta" | t }}</h3>
                        <canvas
                            #canvas
                            id="canvas"
                            (click)="onClickCanvas($event)"
                            style="width: 100%; height: 100%"
                        ></canvas>
                    </div>
                </div>

                <div class="col-md-4" *ngIf="respuestas">
                    <div class="resultados-container">
                        <h3>{{ "subir_respuestas_imagen.resultados" | t }}</h3>

                        <div class="multiple-col">
                            <ul class="lista-resultados">
                                <li *ngFor="let respuestaIndex of respuestas | keys" class="respuesta">
                                    <span class="numero">{{ parseInt(respuestaIndex) + 1 }}:</span>
                                    &nbsp;
                                    <subir-respuestas-valor-feedback
                                        *ngIf="!editing"
                                        [valor]="respuestas[respuestaIndex] | easyplaceholder: '-'"
                                        class="valor"
                                    ></subir-respuestas-valor-feedback>

                                    <input
                                        type="text"
                                        *ngIf="editing"
                                        [(ngModel)]="respuestas[respuestaIndex]"
                                        class="valor edit-respuesta form-control"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </loading-layout>
</div>
<div id="step3" [hidden]="!respuestas || loadingLayout.loading">
    <div class="step-container">
        <div class="tutorial clearfix">
            <div class="tutorial-image">
                <div class="tutorial-image-pos">
                    <div class="tutorial-image-cell">
                        <img
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/correccion_tutorial_2.png"
                            alt="Revisar respuestas"
                        />
                    </div>
                </div>
                <div class="step-number">3</div>
            </div>
            <h3 class="step">{{ "subir_respuestas_imagen.titulo_step_3" | t }}</h3>
            <p class="tutorial-text">{{ "subir_respuestas_imagen.desc_step_3" | t }}.</p>
            <br />
            <div
                class="warning hide-warning"
                [class.show-warning]="messages && messages.warnings.length > 0 && !this.loadingMessages"
            >
                <div class="warning-content">
                    <fa name="warning" class="warning-icon"></fa>
                    <div class="warning-message">
                        <p>
                            {{ "subir_respuestas_imagen.step_3_error" | t }}:
                        </p>
                        <ul *ngIf="messages">
                            <li *ngFor="let warning of messages.warnings">{{ warning }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="clearfix">
                <cui-button class="btn_style pull-right" (click)="verificaExisteEval()">
                    <fa name="upload"></fa>
                    &nbsp;{{ "subir_respuestas_imagen.subir_resultados" | t }}
                </cui-button>
                <loading-layout
                    #loadingLayoutSubir
                    [loaderType]="'default-small'"
                    class="loader-upload"
                ></loading-layout>
            </div>
        </div>
    </div>
</div>
