import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Universidad } from "./universidades.model"
import { CarreraSede } from "./carrera_sedes.model"
import { Lugar } from "./lugares.model"
import { SedeImagen } from "./sede_imagenes.model"

export class Sede extends BaseModel {
    public static className: string = "Sede"

    public sede: string
    public lugarId: number
    public universidadId: number
    public direccion: string
    public descripcion: string
    public urlVideo: string

    @SaveType(() => Universidad) universidad: Universidad
    @SaveType(() => CarreraSede) carreraSedes: CarreraSede[]
    @SaveType(() => Lugar) lugar: Lugar
    @SaveType(() => SedeImagen) sedeImagenes: SedeImagen[]

    public toString() {
        return this.sede
    }
}
