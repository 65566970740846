import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { CarrerasAuthService } from "./carreras_auth.service"
import { CarrerasBaseService } from "./carreras_base.service"
import { Universidad } from "./universidades.model"

@Injectable()
export class Universidades extends CarrerasBaseService<Universidad> {
    tableName = "universidades"
    singularTableName = "universidad"
    modelClass = Universidad

    constructor(
        protected http: HttpClient,
        protected auth: CarrerasAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public getRandom(params: any) {
        return this.all().concatExtra("random").get(params)
    }
}
