import { BaseModel, SaveType } from "@puntaje/shared/core"
import { NotificacionTipo } from "./notificacion_tipos.model"

export class Notificacion extends BaseModel {
    notificacion: string
    created_at: Date
    fecha_visto: Date
    id: number
    link: string
    notificacion_tipo_id: number
    receptor_type: string
    receptor_id: number
    tabla_type: string
    tabla_id: number

    @SaveType(() => NotificacionTipo) notificacion_tipo: NotificacionTipo

    public toString() {
        return this.notificacion
    }
}
