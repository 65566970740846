import { Evaluacion, EvaluacionTiempo } from "@puntaje/puntaje/api-services"
import { EvaluacionesActions, EvaluacionesActionTypes, Item } from "../actions/evaluaciones.actions"
import { GrupoPregunta, Pregunta, Instruccion, Material } from "@puntaje/nebulosa/api-services"

export interface GrupoPreguntaItem {
    textoOriginal: string
    grupoPregunta: GrupoPregunta
    numeroPreguntaInicial: number
    numeroPreguntaFinal: number
}

export interface State {
    actual: Evaluacion
    loadingEvaluacion: boolean
    evaluacionTiempoActual: EvaluacionTiempo
    respuestasActual: any[]
    tiemposActual: number[]
    seeingIndex: number
    items: Item[]
    loadingItems: boolean
    dudaPreguntasActual: Item[]
    tabCorreccionClasses: any
    grupoPreguntaItems: { [key: number]: GrupoPreguntaItem }
    highlightMode: boolean
    eraseHighlightMode: boolean
    instruccionFija: Instruccion
    material: Material
}

export const initialState: State = {
    actual: null,
    loadingEvaluacion: false,
    evaluacionTiempoActual: null,
    respuestasActual: [],
    tiemposActual: [],
    seeingIndex: 0,
    items: [],
    loadingItems: false,
    dudaPreguntasActual: [],
    tabCorreccionClasses: {
        "imagen-multi": {
            active: false,
            in: false
        },
        csv: {
            active: false,
            in: false
        },
        camara: {
            active: true,
            in: true
        },
        imagen: {
            active: false,
            in: false
        }
    },
    grupoPreguntaItems: {},
    highlightMode: true,
    eraseHighlightMode: false,
    instruccionFija: null,
    material: null
}

// tslint:disable-next-line: cyclomatic-complexity
export function reducer(state = initialState, action: EvaluacionesActions): State {
    const respuestas = state.respuestasActual.map(
        respuesta => respuesta && (!Array.isArray(respuesta) || respuesta.some(r => r))
    )
    const lastRespuestaIndex = respuestas.lastIndexOf(true)
    let dudaPreguntasActual
    let seeingIndex
    let nextDudas
    let prevDudas

    switch (action.type) {
        case EvaluacionesActionTypes.SetActual:
            return { ...state, ...action.payload }

        case EvaluacionesActionTypes.SetLoadingEvaluacion:
            return { ...state, ...action.payload }

        case EvaluacionesActionTypes.SetEvaluacionTiempoActual:
            return { ...state, ...action.payload }

        case EvaluacionesActionTypes.SetRespuestasActual:
            return { ...state, ...action.payload }

        case EvaluacionesActionTypes.SetRespuestaIndex:
            const respuestasActual = [...state.respuestasActual]
            respuestasActual[action.index] = action.respuesta

            return { ...state, respuestasActual }

        case EvaluacionesActionTypes.SetTiemposActual:
            return { ...state, ...action.payload }

        case EvaluacionesActionTypes.AddTiempoIndex:
            const tiemposActual = [...state.tiemposActual]

            tiemposActual[action.index] += action.tiempo

            return { ...state, tiemposActual }

        case EvaluacionesActionTypes.SetSeeingIndex:
            seeingIndex = action.payload
            return { ...state, seeingIndex }

        case EvaluacionesActionTypes.MoveSeeingIndex:
            const nextIndex = state.seeingIndex + action.payload
            return { ...state, seeingIndex: nextIndex }

        case EvaluacionesActionTypes.MoveSeeingIndexToLastRespuesta:
            return { ...state, seeingIndex: lastRespuestaIndex + 1 }

        case EvaluacionesActionTypes.SetItems:
            return { ...state, ...action.payload }

        case EvaluacionesActionTypes.SetLoadingItems:
            return { ...state, ...action.payload }

        case EvaluacionesActionTypes.SetMaterial:
            return { ...state, ...action.payload }

        case EvaluacionesActionTypes.SetGrupoPreguntaItems:
            const grupoPreguntaItems = action.payload.items
                .filter(i => i.tipo == "pregunta")
                .reduce((acc, item) => {
                    const pregunta = <Pregunta>item.item

                    if (
                        pregunta.grupo_pregunta &&
                        pregunta.grupo_pregunta.id &&
                        !Object.keys(acc).includes(pregunta.grupo_pregunta.id.toString())
                    ) {
                        acc[pregunta.grupo_pregunta.id] = {
                            textoOriginal: pregunta.grupo_pregunta.texto,
                            grupoPregunta: pregunta.grupo_pregunta,
                            numeroPreguntaInicial: item.numero,
                            numeroPreguntaFinal: item.numero
                        }
                    } else {
                        if (pregunta.grupo_pregunta && pregunta.grupo_pregunta.id) {
                            if (acc[pregunta.grupo_pregunta.id.toString()].numeroPreguntaInicial > item.numero) {
                                acc[pregunta.grupo_pregunta.id.toString()].numeroPreguntaInicial = item.numero
                            }
                            if (acc[pregunta.grupo_pregunta.id.toString()].numeroPreguntaFinal < item.numero) {
                                acc[pregunta.grupo_pregunta.id.toString()].numeroPreguntaFinal = item.numero
                            }
                        }
                    }

                    return acc
                }, {})

            return { ...state, ...{ grupoPreguntaItems } }

        case EvaluacionesActionTypes.ToggleDudaPregunta:
            dudaPreguntasActual = state.dudaPreguntasActual.slice(0)
            dudaPreguntasActual[action.payload] = !dudaPreguntasActual[action.payload]
            return { ...state, dudaPreguntasActual }

        case EvaluacionesActionTypes.ToggleDudaPreguntaActual:
            return {
                ...state,
                items: state.items.map((e, index) => (index == state.seeingIndex ? { ...e, duda: !e.duda } : e))
            }

        case EvaluacionesActionTypes.ResetDudaPreguntas:
            return { ...state, items: state.items.map(i => (i.tipo == "pregunta" ? { ...i, duda: false } : i)) }

        case EvaluacionesActionTypes.MoveToNextDuda:
            nextDudas = state.items
                .filter(e => e.tipo == "pregunta" && e.duda)
                .map(e => state.items.indexOf(e))
                .filter(e => e > state.seeingIndex)
            if (nextDudas.length == 0) return { ...state }
            else return { ...state, seeingIndex: Math.min(...nextDudas) }

        case EvaluacionesActionTypes.MoveToPrevDuda:
            prevDudas = state.items
                .filter(e => e.tipo == "pregunta" && e.duda)
                .map(e => state.items.indexOf(e))
                .filter(e => e < state.seeingIndex)
            if (prevDudas.length == 0) return { ...state }
            else return { ...state, seeingIndex: Math.max(...prevDudas) }

        case EvaluacionesActionTypes.SetDudaPreguntasNoContestadas:
            const newItems = state.items.map((e, index) => {
                return e.tipo == "pregunta" &&
                    (index < lastRespuestaIndex || index < state.seeingIndex || respuestas[index])
                    ? {
                          ...e,
                          duda: respuestas[index] ? false : true
                      }
                    : e
            })
            return {
                ...state,
                items: newItems
            }

        case EvaluacionesActionTypes.ChangeCorreccionTab:
            let nuevasClases = {
                "imagen-multi": {
                    active: false,
                    in: false
                },
                csv: {
                    active: false,
                    in: false
                },
                camara: {
                    active: false,
                    in: false
                },
                imagen: {
                    active: false,
                    in: false
                }
            }
            nuevasClases[action.payload] = { active: true, in: true }
            return { ...state, tabCorreccionClasses: nuevasClases }

        case EvaluacionesActionTypes.UpdateGrupoPreguntaItem:
            const grupoPregunta = action.payload
            let grupoPreguntaItem = {}
            grupoPreguntaItem[grupoPregunta.id] = {
                ...state.grupoPreguntaItems[grupoPregunta.id],
                ...{ grupoPregunta }
            }
            return { ...state, grupoPreguntaItems: { ...state.grupoPreguntaItems, ...grupoPreguntaItem } }

        case EvaluacionesActionTypes.EnableHighlightMode:
            return { ...state, highlightMode: true, eraseHighlightMode: false }

        case EvaluacionesActionTypes.EnableEraseHighlightMode:
            return { ...state, highlightMode: false, eraseHighlightMode: true }

        case EvaluacionesActionTypes.SetInstruccionFija:
            return { ...state, ...action.payload }

        case EvaluacionesActionTypes.ResetEvaluacion:
            return {
                ...state,
                actual: null,
                evaluacionTiempoActual: null,
                respuestasActual: [],
                tiemposActual: [],
                seeingIndex: 0,
                items: [],
                dudaPreguntasActual: [],
                grupoPreguntaItems: {},
                highlightMode: true,
                eraseHighlightMode: false,
                instruccionFija: null,
                material: null
            }
        default:
            return state
    }
}
