import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { GrupoPregunta } from "./grupo_preguntas.model"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"

@Injectable()
export class GrupoPreguntas extends NebuBaseService<GrupoPregunta> {
    tableName = "grupo_preguntas"
    singularTableName = "grupo_pregunta"
    modelClass = GrupoPregunta

    constructor(
        http: HttpClient,
        auth: NebuAuthService,
        router: Router,
        errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public previewLatex(params: any) {
        return this.all().concatExtra("preview_latex").post(params)
    }

    public previewLatexBlob(params: any) {
        this.enableIgnoreModel()
        this.enableIgnoreCatch()

        return this.all()
            .concatExtra("preview_latex")
            .post(params)
            .then(response => {
                this.disableIgnoreModel()

                const object = response as any

                if (object.info.errors || !object.success) {
                    return Promise.resolve({ error: true }) as any
                }

                return this.downloadPDF(object.info.link).then((responseLink: any) => {
                    return { blob: new Blob([responseLink], { type: "application/pdf" }) }
                })
            })
            .catch(_ => {
                return Promise.resolve({ error: true }) as any
            })
    }
}
