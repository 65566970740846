<menu-asignaturas-horizontal
    [tipoAsignaturas]="evaluacionTipo"
    (asignaturaChanged)="onChangeAsignatura($event)"
></menu-asignaturas-horizontal>
<div class="filtro" *ngIf="generadorInstrumentos" [hidden]="generadorInstrumentos && generadorInstrumentos.length == 1">
    <h4>Tipo</h4>
    <generador-instrumento-select
        [generadorInstrumentos]="generadorInstrumentos"
        [(generadorInstrumento)]="generadorInstrumento"
        (generadorInstrumentoChange)="getEstadisticas()"
    ></generador-instrumento-select>
</div>
<loading-layout #loadingLayout>
    <div *ngIf="enableGraphs">
        <grafico-desempeno-promedio
            [asignatura]="asignatura"
            [dataOmitidas]="desempeno_promedio_omitidas"
            [dataIncorrectas]="desempeno_promedio_incorrectas"
            [dataCorrectas]="desempeno_promedio_correctas"
            [tipoEvaluacion]="evaluacionTipo"
        ></grafico-desempeno-promedio>

        <grafico-distribucion-tramo-puntaje
            [asignatura]="asignatura"
            [data]="$any(distribucion_ensayos_tramo_puntaje)"
            [tipoGrupo]="'alumnos'"
            [tipoEvaluacion]="evaluacionTipo"
            [nombreEjeY]="'progreso.estadisticas.graficos_asignatura.distr_tramos.eje_y' | t"
            [nombreLeyenda]="'progreso.estadisticas.graficos_asignatura.distr_tramos.leyenda' | t"
        ></grafico-distribucion-tramo-puntaje>
    </div>
</loading-layout>
