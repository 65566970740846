<p *ngIf="notFound">{{ "biblioteca.not_found" | t }}</p>
<p *ngIf="clasificacionTipo">
    <b>{{ clasificacionTipo }}:</b>
    <span *ngFor="let clasificacion of clasificaciones">{{ clasificacion.clasificacion }} |</span>
</p>
<div class="preview" [class.interactivo]="isInteractivo" id="material{{ material.id }}" *ngIf="enablePreview">
    <ng-template
        [ngIf]="
            isPdf ||
            isVideo ||
            isGame ||
            isHTML ||
            isInteractivo ||
            isAudio ||
            isMp4 ||
            isPPT ||
            isLink ||
            isWord ||
            isExcel
        "
    >
        <model-plain-value
            [params]="params[this.material.material_tipo_nombre].params"
            [value]="material"
            [key]="params[this.material.material_tipo_nombre].key"
            [class.html-content]="isHTML"
        ></model-plain-value>
    </ng-template>
</div>
