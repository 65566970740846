<ng-container *ngIf="!saved">
    <form *ngIf="form" novalidate [formGroup]="form">
        <div class="content-wrap">
            <div class="row">
                <div class="col-md-6">
                    <h3>Datos personales</h3>
                    <form-input
                        [form]="form"
                        [params]="params['nombre']"
                        [(value)]="usuario['nombre']"
                        [key]="'nombre'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params['apellido_paterno']"
                        [(value)]="usuario['apellido_paterno']"
                        [key]="'apellido_paterno'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params['apellido_materno']"
                        [(value)]="usuario['apellido_materno']"
                        [key]="'apellido_materno'"
                    ></form-input>
                    <form-input
                        [form]="usuarioPaisForm"
                        [params]="usuarioPaisParams[identificador]"
                        [(value)]="usuario['usuario_' + pais][identificador]"
                        [key]="identificador"
                    ></form-input>
                    <div *ngIf="soporteMail" class="info">
                        Si no tienes {{ identificadorAlias }} escríbenos a {{ soporteMail }} para ayudarte con tu cuenta
                    </div>
                    <br />
                    <form-input
                        *ngIf="enableFechaNacimiento"
                        [form]="form"
                        [params]="params['fecha_nacimiento']"
                        [(value)]="usuario['fecha_nacimiento']"
                        [key]="'fecha_nacimiento'"
                    ></form-input>
                    <form-input
                        *ngIf="enableTelefono"
                        [form]="form"
                        [params]="params['telefono']"
                        [(value)]="usuario['telefono']"
                        [key]="'telefono'"
                        [withLabel]="false"
                    >
                        <label for="telefono" [attr.for]="'telefono'">
                            Teléfono celular
                            <fa
                                [name]="'question-circle-o'"
                                [tooltip]="
                                    'Te recomendamos agregar tu celular para que puedas activar o recuperar tu cuenta fácilmente a través de SMS.'
                                "
                            ></fa>
                            &nbsp;
                            <span class="required-mark"></span>
                        </label>
                    </form-input>

                    <form-input
                        *ngIf="enableNivel"
                        [form]="form"
                        [params]="params['nivel_id']"
                        [(value)]="usuario['nivel_id']"
                        [key]="'nivel_id'"
                    ></form-input>

                    <form-input
                        *ngIf="enableEspecialidad"
                        [form]="form"
                        [params]="params['especialidad']"
                        [(value)]="usuario['especialidad']"
                        [key]="'especialidad'"
                    ></form-input>

                    <div *ngIf="enableColegio && ($any(usuario['nivel_id']) != '13' || showInputColegioEgresado)">
                        <label [attr.for]="'lugarColegio'">
                            Comuna de tu colegio
                            <span *ngIf="colegioObligatorio" class="required-mark"></span>
                        </label>
                        <autocomplete
                            [placeholder]="'Escribe y selecciona entre las opciones'"
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="lugarColegio"
                            [options]="filteredLugarColegio"
                            (filterChange)="getLugarColegio($event)"
                            (controlValue)="updateLugarColegio($event)"
                            class="autocomplete"
                        ></autocomplete>

                        <label [attr.for]="'colegio'">
                            {{ params["colegio"].label }}
                            <span *ngIf="colegioObligatorio" class="required-mark"></span>
                        </label>
                        <autocomplete
                            [placeholder]="'Escribe y selecciona entre las opciones'"
                            [formControlName]="'colegio'"
                            [options]="filteredEstablecimientos"
                            (filterChange)="getEstablecimientos($event)"
                            (controlValue)="updateColegio($event)"
                            showTextFun="toStringWithIdPais"
                            class="autocomplete"
                            [disabled]="!lugarColegio"
                        ></autocomplete>
                        <form-input-error
                            [form]="form"
                            [params]="params['colegio']"
                            [value]="usuario['colegio']"
                            [key]="'colegio'"
                        ></form-input-error>
                    </div>
                </div>
                <div class="col-md-6">
                    <h3>Datos de la cuenta</h3>
                    <form-input
                        [form]="form"
                        [params]="params['email']"
                        [(value)]="usuario['email']"
                        [key]="'email'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params['password']"
                        [enablePasswordBar]="true"
                        [(value)]="usuario['password']"
                        [key]="'password'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params['password_confirmation']"
                        [(value)]="usuario['password_confirmation']"
                        [key]="'password_confirmation'"
                    ></form-input>
                    <div *ngIf="enableCarreras">
                        <h3>
                            Preferencia carreras
                            <span *ngIf="carrerasObligatorio" class="required-mark"></span>
                        </h3>
                        <embed-preferencia-carrera-sedes-by-sede
                            #preferencias
                            [universidadesNombres]="universidades"
                            [form]="form"
                            [amount]="3"
                            (emitPreferencias)="updatePreferencias($event)"
                        ></embed-preferencia-carrera-sedes-by-sede>
                        <error-messages
                            *ngIf="carrerasObligatorio"
                            [isExpanded]="triedToSubmit && usuario.preferenciaCarreraSedes?.length == 0"
                            containerId="preferencias-error"
                        >
                            <ng-container *errorMessage>Las preferencias de carrera son obligatorias.</ng-container>
                        </error-messages>
                    </div>
                    <form-input
                        [form]="form"
                        [params]="params['terminos_y_condiciones']"
                        [(value)]="usuario['terminos_y_condiciones']"
                        [key]="'terminos_y_condiciones'"
                    ></form-input>
                </div>
            </div>
            <div class="btn-container">
                <div class="row">
                    <div class="col-md-6">
                        <cui-button [isBlock]="true" (click)="back()" type="button" class="btn_style cancel-btn">
                            <fa name="arrow-left"></fa>
                            &nbsp;Volver a la página de Inicio
                        </cui-button>
                    </div>
                    <div class="col-md-6">
                        <cui-button [isBlock]="true" (click)="save()" type="button" class="btn_style submit-btn">
                            Regístrate&nbsp;
                            <fa name="arrow-right"></fa>
                        </cui-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    {{ mensajeFin }}
</ng-container>
