import { Injectable, TemplateRef } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { LayoutService } from "../layout.service"

interface EvaluationLayoutOptions {
    contenidoLateralXL?: boolean
    singleColumn?: boolean
    borderedColumns?: boolean
    hideTitle?: boolean
}

@Injectable({ providedIn: "root" })
export class EvaluationLayoutService extends LayoutService<EvaluationLayoutOptions> {
    defaultOptions: EvaluationLayoutOptions = {
        contenidoLateralXL: false,
        singleColumn: false,
        borderedColumns: false,
        hideTitle: false
    }

    options$ = new BehaviorSubject<EvaluationLayoutOptions>(this.defaultOptions)
}
