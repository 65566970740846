import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { NoticiaInstancia } from "./noticia_instancias.model"

@Injectable()
export class NoticiaInstancias extends PuntajeBaseService<NoticiaInstancia> {
    tableName = "noticia_instancias"
    singularTableName = "noticia_instancia"
    modelClass = NoticiaInstancia

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
