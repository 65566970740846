import { Component, Input, Output, EventEmitter, ViewChild, OnInit, ChangeDetectorRef } from "@angular/core"
import {
    Clasificacion,
    GeneradorInstrumentos,
    ClasificacionTipo,
    ClasificacionTipos
} from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "configurar-clasificaciones",
    templateUrl: "configurar_clasificaciones.component.html",
    styleUrls: ["configurar_clasificaciones.component.scss"]
})
export class ConfigurarClasificacionesComponent implements OnInit {
    @Input() enableCantidadPreguntas: boolean = false
    @Input() enableObjetivoPriorizado: boolean = false
    @Input() tipoInstrumento: string
    @Input() asignaturaId: number
    @Input() validadas: boolean = false
    @Input() clasificacionesIds: number[] = []
    @Input() customId: string
    @Output() clasificacionesIdsChange: EventEmitter<any> = new EventEmitter<any>()

    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    clasificacionesByTipo: { [x: number]: Clasificacion[] }

    show_contenidos: boolean = false

    clasificaciones: Clasificacion[]
    clasificacion_tipos: ClasificacionTipo[]
    filtroClasificacionTiposConfiguracion: any[]
    names = {}
    concats: any
    config: typeof config
    checkAllCheckBoxesGenerador = true

    private _generadorInstrumentoId: number

    constructor(
        protected generadorInstrumentosService: GeneradorInstrumentos,
        private clasificacionTiposService: ClasificacionTipos,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (config.plataforma.checkAllCheckBoxesGenerador == false) {
            this.checkAllCheckBoxesGenerador = false
        }
        this.config = config
        this.concats = config.evaluaciones[this.tipoInstrumento].clasificaciones.concats
    }

    @Input()
    set generadorInstrumentoId(gid: number) {
        this._generadorInstrumentoId = gid

        gid && this.setClasificaciones()
    }

    get generadorInstrumentoId() {
        return this._generadorInstrumentoId
    }

    async setClasificaciones() {
        this.concats = this.concats || config.evaluaciones[this.tipoInstrumento].clasificaciones.concats

        this.loadingLayout.standby()

        const ct_params = {
            render_options: {
                include: {
                    clasificacion_tipo_aliases: {
                        include: {
                            asignatura_plataforma: null
                        }
                    }
                }
            }
        }
        this.clasificacionTiposService.where(ct_params).then((response: ClasificacionTipo[]) => {
            this.clasificacion_tipos = response
        })

        const params: any = {
            platform: config.plataforma.name
        }
        if (this.concats) params.concats = this.concats
        if (this.validadas) params.validadas = this.validadas

        // params.ordenar = config.plataforma.orderFiltroClasificaciones

        this.filtroClasificacionTiposConfiguracion =
            await this.generadorInstrumentosService.filtroClasificacionTiposConfiguracion(this.generadorInstrumentoId)

        this.generadorInstrumentosService
            .filtroClasificaciones(this.generadorInstrumentoId, params)
            .then((clasificaciones: any[]) => {
                if (config.plataforma.sortClasificacionesFiltro) {
                    clasificaciones.sort(function (a, b) {
                        var textA = a.clasificacion.toUpperCase()
                        var textB = b.clasificacion.toUpperCase()
                        return textA < textB ? -1 : textA > textB ? 1 : 0
                    })
                }

                this.clasificacionesByTipo = (clasificaciones as any).groupBy(
                    c => c.clasificacion_tipo.clasificacion_tipo
                )

                this.clasificaciones = clasificaciones
                this.emitToParent()
                if (!this.cdr["destroyed"]) {
                    this.cdr.detectChanges()
                }

                this.setNames()
                this.loadingLayout.ready()
                this.onReady.emit()
            })
    }

    emitToParent() {
        const flattenClasificacionAndHijos = (clasificacion: Clasificacion) => {
            if (clasificacion.clasificaciones_hijas) {
                return [].concat.apply(
                    [clasificacion],
                    clasificacion.clasificaciones_hijas.map(flattenClasificacionAndHijos)
                )
            } else {
                return [clasificacion]
            }
        }

        let padresEHijos = ([] as Clasificacion[]).concat.apply(
            [],
            this.clasificaciones.map(flattenClasificacionAndHijos)
        )
        this.clasificacionesIdsChange.emit(padresEHijos.filter(c => c["selected"]).map(c => c.id))
    }

    setNames() {
        for (let c of this.filtroClasificacionTiposConfiguracion) {
            this.names[c.name] = this.getName(c)
        }
    }

    getName(c: any) {
        let cid = this.clasificacionesByTipo[c.clasificacionTipo]?.[0]["clasificacion_tipo_id"]
        let cts = this.clasificacion_tipos?.filter(ct => ct.id === cid)
        if (cts && cts.length > 0) {
            if (cts[0].clasificacion_tipo_aliases.length > 0) {
                let ctas = cts[0].clasificacion_tipo_aliases.filter(
                    cta =>
                        cta.asignatura_plataforma.plataforma_id == this.config.plataforma.id &&
                        cta.asignatura_plataforma.asignatura_id == this.asignaturaId
                )
                if (ctas.length > 0) {
                    return ctas[0].alias
                }
            }
        }
        return c.name
    }
}
