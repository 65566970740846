import { Component } from "@angular/core"
import { View } from "./view.component"

@Component({
    selector: "entitiesview",
    template: `
        <ng-template #viewLabel></ng-template>
        <ng-template #viewValue>
            <ng-template ngFor let-model [ngForOf]="value">
                <model-simple-view
                    *ngFor="let key of params | keys"
                    [key]="key"
                    [params]="params[key]"
                    [value]="model"
                ></model-simple-view>
            </ng-template>
        </ng-template>
    `
})
export class EntitiesView extends View {}
