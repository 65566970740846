import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"

export class UsuarioAvatarEdit extends BaseModel {
    public usuario_id: number
    public avatar: string
    @SaveType(() => Usuario) public usuario: Usuario
    @SaveType() public file: File

    constructor(id: number) {
        super()
        this.usuario_id = id
        this.usuario = new Usuario()
    }

    public toString() {
        return this.usuario_id
    }

    public fromUsuario(usuario: Usuario) {
        this.usuario_id = usuario.id
        this.avatar = usuario.avatar
    }

    public toUsuario() {
        this.usuario.avatar = this.avatar
        return this.usuario
    }

    public getS3Params() {
        return this.file
            ? {
                  key: this.file.name,
                  model_name: "Usuario"
              }
            : {}
    }
}
