import { Injectable } from "@angular/core"
import { Subject } from "rxjs"
import { DudaCategoria, GrupoUsuario } from "@puntaje/puntaje/api-services"

@Injectable()
export class DudasBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    answerStatuses: any[] = [
        { value: 0, label: "Contestadas o no" },
        { value: 1, label: "Sin contestar" },
        { value: 2, label: "Contestadas" }
    ]
    usuarioId: number
    answerStatus: any
    dudaCategoria: DudaCategoria
    dudaCategoriaId: number
    grupoUsuario: GrupoUsuario
    grupoUsuarioId: number

    titleLike: string

    constructor() {}

    getAnswerStatuses() {
        return this.answerStatuses
    }

    // Perteneciente al filtro de dudas
    setUsuario(value: number) {
        this.usuarioId = value
        this.search()
    }

    // Perteneciente al filtro de dudas
    setAnswerStatus(value: any) {
        this.answerStatus = value
        this.search()
    }

    // Perteneciente al filtro de dudas
    setDudaCategoria(value: DudaCategoria) {
        this.dudaCategoria = value
        this.dudaCategoriaId = value ? value.id : undefined
        this.search()
    }

    // Perteneciente al filtro de dudas
    setDudaCategoriaId(value: number) {
        this.dudaCategoriaId = value
        this.search()
    }

    // Perteneciente al filtro de dudas grupo usuario
    setGrupoUsuario(value: GrupoUsuario) {
        this.grupoUsuario = value
        this.grupoUsuarioId = value ? value.id : undefined
        this.search()
    }

    // Pertenece a la barra de búsqueda
    setSearchBarParams(value: string) {
        if (value != this.titleLike) {
            this.titleLike = value
            this.search()
        }
    }

    search() {
        let params: any = {}
        params.duda = {}
        params.duda_categoria = {}
        params.grupo_usuario = {}

        // todas las dudas o solo las del usuario
        if (this.usuarioId) {
            params.duda["usuario_id"] = this.usuarioId
        }

        // contestadas o no
        switch (this.answerStatus) {
            case this.answerStatuses[1]:
                params.duda["mejor_respuesta_id"] = null
                break
            case this.answerStatuses[2]:
                params.duda.not = { mejor_respuesta_id: null }
                delete params.duda["mejor_respuesta_id"]
                break
            default:
                break
        }

        // por categoría
        if (this.dudaCategoriaId) {
            params.duda_categoria = { id: [this.dudaCategoriaId] }
        }

        // por texto en el título
        if (this.titleLike && this.titleLike.length > 0) {
            params.duda.like = { duda: this.titleLike }
        }

        // por grupo usuario
        if (this.grupoUsuarioId) {
            params.grupo_usuario = { id: this.grupoUsuarioId }
        }

        this.searchParams.next(params)
    }
}
