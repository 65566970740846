import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { Tutor } from "./tutores.model"

@Injectable()
export class Tutores extends PuntajeBaseService<Tutor> {
    tableName = "tutores"
    singularTableName = "tutor"
    modelClass = Tutor

    constructor(
        http: HttpClient,
        auth: PuntajeAuthService,
        router: Router,
        errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public confirmar(token: string) {
        return this.all().concatExtra("confirmar").get({ token: token })
    }
}
