import { BaseForm, Validations } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"

export class UsuariosCompletarRegistroChileForm extends BaseForm {
    companyName: string = ""
    // constructor(){
    //  super();
    //  this.companyName = config.plataforma.info.companyName;
    // }

    public static get formParams(): any {
        return {
            usuario_id: { type: "hidden", visible: true },
            rut: {
                label: "RUT",
                type: "text",
                validations: [Validators.required, Validations.validateRut],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueRutNotSelf }]
            },
            telefono: { label: "Teléfono", type: "text", visible: true, validations: [Validators.required] }
        }
    }
}
