import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"

import { BaseService, AuthService, ErroresService, AppEnv } from "@puntaje/shared/core"

import { InstrumentoMultiple } from "./instrumento_multiples.model"

@Injectable()
export class InstrumentoMultiples extends BaseService<InstrumentoMultiple> {
    tableName = "instrumento_multiples"
    singularTableName = "instrumento_multiple"
    modelClass = InstrumentoMultiple

    constructor(
        protected http: HttpClient,
        protected auth: AuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
