export const esPNCO = {
    practicar: {
        showcasebox: {
            resumen: {
                ensayos: "Simulacros"
            }
        }
    },
    home: {
        resumen: {
            ensayos: "Simulacros"
        }
    },
    tour: {
        topmenu: {
            practicar: "Simulacros para practicar"
        }
    },
    estudiar: {
        plan_estudios: {
            compartidos: {
                titulo_simple: "Planes de estudio compartidos por mi profesor"
            }
        }
    }
}
