import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { Notificacion, Retroalimentacion, Notificaciones } from "@puntaje/puntaje/api-services"
import { NotificacionesPendingService } from "../../notificaciones_pending.service"

@Component({
    selector: "notificacion-retroalimentacion",
    templateUrl: "./notificacion-retroalimentacion.component.html",
    styleUrls: ["../notificacion.component.scss"]
})
export class NotificacionRetroalimentacionComponent implements OnInit {
    @Input() notificacion: Notificacion
    @Input() enableMini: boolean = false
    @Input("disableMarkButton") disableMarkButton: boolean = false
    @Output() markAsNotPending: EventEmitter<any> = new EventEmitter<any>()
    retroalimentacion: Retroalimentacion
    usuario_remitente: string = ""
    retroalimentable: string = ""
    data: any = {}

    constructor(
        protected notificacionesService: Notificaciones,
        protected notificacionesPendingService: NotificacionesPendingService
    ) {}

    ngOnInit() {
        if (this.notificacion) {
            this.notificacion.notificacion = JSON.parse(this.notificacion.notificacion)
            this.data = this.notificacion.notificacion as any
            this.retroalimentacion = this.data.retroalimentacion
            this.usuario_remitente = this.data.usuario.nombre + " " + this.data.usuario.apellido_paterno
            this.retroalimentable = String(this.data.retroalimentable)
        }
    }

    onClickLink() {
        let n = new Notificacion()
        n.fecha_visto = new Date()
        this.notificacionesService.update(this.notificacion.id, n).then(() => {
            this.notificacionesPendingService.update()
            this.markAsNotPending.emit(this.notificacion.id)
        })
    }
}
