import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { select, Store } from "@ngrx/store"
import { GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import {
    selectAsignaturasList,
    selectSelectedAsignaturaId,
    selectSelectedEvaluacionTipo,
    State
} from "@puntaje/puntaje/store"
import { AppConfig } from "@puntaje/shared/core"
import { combineLatest, Subscription } from "rxjs"
import { filter, first } from "rxjs/operators"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "planes_personales.component.html"
})
export class PlanesPersonalesComponent implements OnInit, OnDestroy {
    asignaturaId: number
    generadorInstrumento: GeneradorInstrumento
    generadorInstrumentoId: number
    tipoInstrumento: string
    private sub: Subscription

    evaluacionTipos = config.plataforma.evaluacionTipos.filter(et => et.includes("diagnostico"))

    // TODO: refactorizar esto en caso de que alguna vez se quieran sacar asignaturas del adaptive para otras plataformas
    inCB = config.plataforma.name == "College Board"

    asignaturas$ = this.store.pipe(
        select(selectAsignaturasList),
        filter(x => !!x),
        first()
    )
    asignaturaIds: number[]

    asignaturaId$ = this.store.pipe(
        select(selectSelectedAsignaturaId),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(
        protected titleService: TitleService,
        private route: ActivatedRoute,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.list")
        this.tipoInstrumento = config.plataforma.evaluacionTipoAdaptiveDefault ?? "diagnostico prueba transicion"

        this.sub = this.route.queryParams.subscribe(query => {
            this.asignaturaId = +query["asignatura_id"]
            this.generadorInstrumentoId = +query["generador_instrumento_id"] || undefined
        })

        if (this.inCB) {
            this.sub = this.route.queryParams.subscribe(query => {
                this.asignaturaId = +query["asignatura_id"]
                this.generadorInstrumentoId = +query["generador_instrumento_id"] || undefined
            })

            this.setAsignaturaIds()
        } else {
            this.sub = combineLatest(this.asignaturaId$, this.evaluacionTipo$, this.route.queryParams).subscribe(
                ([asignaturaId, evaluacionTipo, query]) => {
                    this.asignaturaId = asignaturaId
                    this.tipoInstrumento = evaluacionTipo
                    this.generadorInstrumentoId = +query["generador_instrumento_id"] || undefined

                    this.cdr.detectChanges()
                }
            )
        }
    }

    async setAsignaturaIds() {
        const asignaturas = await this.asignaturas$.toPromise()
        this.asignaturaIds = asignaturas
            .filter(asignatura => asignatura.asignatura != "Lectura" && asignatura.asignatura != "Redacción")
            .map(a => a.id)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    onSelectAsignatura(asignaturaId) {
        this.asignaturaId = asignaturaId
    }

    onSelectGeneradorInstrumento(generadorInstrumento) {
        this.generadorInstrumento = generadorInstrumento
    }
}
