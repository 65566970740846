import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { LibroCodigoRegistro } from "./libro_codigo_registros.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class LibroCodigoRegistros extends PuntajeBaseService<LibroCodigoRegistro> {
    tableName = "libro_codigo_registros"
    singularTableName = "libro_codigo_registro"
    modelClass = LibroCodigoRegistro

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public async validarCodigo(params) {
        this.enableIgnoreModel()
        return this.all()
            .concatExtra("validar_codigo")
            .get(params)
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }
}
