<div class="content-container">
    <div class="row">
        <div class="col-sm-12">
            <div class="container-fluid bottom-padding with-max-width responsive-no-padding">
                <div class="clearfix" [class.content-header-with-topside]="showTopside">
                    <titlebar [noSocialLinks]="true">
                        <titlebar-titulo><ng-content select="restricted-layout-titulo"></ng-content></titlebar-titulo>
                        <titlebar-subtitulo>
                            <ng-content select="restricted-layout-subtitulo"></ng-content>
                        </titlebar-subtitulo>
                    </titlebar>
                    <div [class.logged-layout-topside]="showTopside" #restrictedLayoutTopside>
                        <ng-content select="restricted-layout-topside"></ng-content>
                    </div>
                </div>
                <div [class.logged-layout-tabs]="showTabs" #restrictedLayoutTabs>
                    <ng-content select="restricted-layout-tabs"></ng-content>
                </div>
                <div
                    [class.logged-layout-contenido]="showContenido && !freeContentStyle"
                    [class.with-tabs]="showTabs"
                    [class.free-logged-layout-contenido]="freeContentStyle"
                    #restrictedLayoutContenido
                >
                    <ng-content select="restricted-layout-contenido"></ng-content>
                </div>
            </div>
        </div>
    </div>
    <div class="push-footer"></div>
</div>
<simple-modal></simple-modal>
