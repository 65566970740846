import { NebuModule } from "@puntaje/nebulosa/api-services"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { UtilModule, FormModule, TranslationsModule, SorterModule, PaginatorModule } from "@puntaje/shared/core"
import { AudiovisualesModule } from "@puntaje/puntaje/new-modules/audiovisuales"
import { CommonModule } from "@angular/common"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { ModalModule } from "ngx-bootstrap/modal"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { TypeaheadModule } from "ngx-bootstrap/typeahead"
import { RouterModule } from "@angular/router"
import { AgmCoreModule } from "@agm/core"
import {
    CUIInputsModule,
    CUITextsModule,
    CUITablesModule,
    CUILayoutsModule,
    CUIContainersModule
} from "@puntaje/shared/cui"
import { CarrerasShowcaseBoxComponent } from "./carreras-showcasebox/carreras-showcasebox.component"
import { CarrerasListItemComponent } from "./carreras-list-item/carreras-list-item.component"
import { PonderacionesBarComponent } from "./ponderaciones-bar/ponderaciones-bar.component"
import { PonderacionesBarDividerComponent } from "./ponderaciones-bar/ponderaciones-bar-divider.component"
import { CarreraSedeComponent } from "./carrera-sede/carrera-sede.component"
import { MisCarrerasComponent } from "./mis-carreras/mis-carreras.component"
import { BuscadorCarrerasComponent } from "./buscador-carreras/buscador-carreras.component"
import { MisPuntajesPromedioCarrerasComponent } from "./mis-puntajes-promedio-carreras/mis-puntajes-promedio-carreras.component"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { MiPonderacionComponent } from "./mi-ponderacion/mi-ponderacion.component"
import { CarrerasPonderaciones } from "./carreras-ponderaciones.service"
import { SeguirCarreraComponent } from "./seguir-carrera/seguir-carrera.component"
import { CarrerasTerminosModalComponent } from "./carreras-terminos-modal/carreras-terminos-modal.component"
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown"
import { CarrerasTableComponent } from "./carreras-table/carreras-table.component"
import { CarrerasBuscadorService } from "./buscador-carreras/carreras_buscador.service"
import { BuscadorCarrerasShowcaseBoxComponent } from "./buscador-carreras-showcasebox/buscador-carreras-showcasebox.component"

@NgModule()
export class CarrerasServices {}

@NgModule({
    imports: [
        AgmCoreModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        UtilModule,
        CommonsLayoutsModule,
        Angular2FontawesomeModule,
        FormModule,
        ModalModule,
        ProgressbarModule,
        PopoverModule,
        TooltipModule,
        SorterModule,
        PaginatorModule,
        NebuModule,
        TranslationsModule,
        AudiovisualesModule,
        SharedModule,
        TypeaheadModule.forRoot(),
        CUITextsModule,
        CUIInputsModule,
        CUITablesModule,
        CUILayoutsModule,
        CUIContainersModule,
        NgMultiSelectDropDownModule.forRoot()
    ],
    declarations: [
        CarrerasShowcaseBoxComponent,
        CarrerasListItemComponent,
        PonderacionesBarComponent,
        PonderacionesBarDividerComponent,
        CarreraSedeComponent,
        MisCarrerasComponent,
        BuscadorCarrerasComponent,
        BuscadorCarrerasShowcaseBoxComponent,
        MisPuntajesPromedioCarrerasComponent,
        MiPonderacionComponent,
        SeguirCarreraComponent,
        CarrerasTerminosModalComponent,
        CarrerasTableComponent
    ],
    exports: [
        CarrerasShowcaseBoxComponent,
        CarrerasListItemComponent,
        PonderacionesBarComponent,
        PonderacionesBarDividerComponent,
        CarreraSedeComponent,
        MisCarrerasComponent,
        BuscadorCarrerasComponent,
        BuscadorCarrerasShowcaseBoxComponent,
        MisPuntajesPromedioCarrerasComponent,
        CarrerasTerminosModalComponent
    ],
    providers: []
})
export class CarrerasModule {
    static services(): ModuleWithProviders<CarrerasServices> {
        return {
            ngModule: CarrerasServices,
            providers: [CarrerasPonderaciones, CarrerasBuscadorService]
        }
    }
}
