export const esALCL = {
    titles: {
        core: {
            noticias: {
                noticias_profesor: "Mis comunicaciones"
            }
        }
    },
    noticias: {
        form: {
            noticia: "Comunicación",
            fecha_inicio: "Fecha de inicio comunicación",
            fecha_termino: "Fecha de término comunicación"
        },
        profe: {
            publicar: "Publicar comunicación",
            publicar_boton: "Publicar comunicación",
            not_found: "No has publicado comunicaciones aún",
            cursos_compartir: "Cursos a los cuales compartirá la comunicación",
        }
    }
}
