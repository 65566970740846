<div *ngIf="values && values.length > 0" class="panel panel-info">
    <div class="panel-heading">{{ title }}</div>
    <div class="panel-body">
        <ul [class.no-padding-left]="noPaddingLeft">
            <li *ngFor="let value of values; index as i">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" value="value" (click)="onClick(value)" [disabled]="optionsDisabled" />
                        {{ labels[i] || value }}
                    </label>
                </div>
            </li>
        </ul>
    </div>
</div>
