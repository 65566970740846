import { Injectable } from "@angular/core"

@Injectable()
export class SelectContenidosPlanificaciones {
    selectedRecursos: any[]

    setSelectedRecursos(sr: any[]) {
        this.selectedRecursos = sr
    }

    getSelectedRecursos() {
        return this.selectedRecursos
    }

    unsetSelectedRecursos() {
        this.selectedRecursos = undefined
    }
}
