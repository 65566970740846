<error-messages
    [isExpanded]="
        (control && control.errors && control.touched) ||
        (form.errors && form.invalid && control.touched && control['global'])
    "
    [containerId]="key + '_error'"
>
    <ng-container *ngIf="control && control.errors && control.touched">
        <ng-container *ngFor="let errorKey of control.errors | keys">
            <ng-container *errorMessage>
                {{
                    validations.getMessage(
                        params.label,
                        errorKey,
                        control.errors[errorKey],
                        params.customError,
                        translator
                    )
                }}
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="form.errors && form.invalid && control.touched && control['global']">
        <ng-container *ngFor="let formError of form.errors | keys">
            <ng-container *ngIf="form.errors[formError].keys && form.errors[formError].keys.includes(key)">
                <ng-container *errorMessage>
                    {{ validations.getMessage(key, formError, form.errors[formError], null, translator) }}
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="customError">
        <ng-container *errorMessage>
            <ng-content select=".custom-error"></ng-content>
        </ng-container>
    </ng-container>
</error-messages>
