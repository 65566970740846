<a class="showLink" (click)="openModal.emit()">
    {{ "orientacion.carreras.terminos_modal.terminos_y_condiciones" | t }}
</a>
<generic-modal
    [buttonClicked]="openModal"
    [id]="'carreras-terminos-modal'"
    [autoHeight]="true"
    [justifyBody]="true"
    #genericModal
>
    <modal-titulo>{{ "orientacion.carreras.terminos_modal.terminos_y_condiciones" | t }}</modal-titulo>
    <modal-contenido class="modal-contenido">
        <div *ngIf="pais === 'colombia'">
            <p>
                En esta sección puedes buscar carreras de distintas instituciones. Al seleccionar
                <b>"seguir"</b>
                en una carrera, esta se agregará a
                <b>"Mis carreras de interés"</b>
                , para que puedas encontrarla más rápido la próxima vez.
            </p>
            <p>
                Adicionalmente, aceptas que la institución educativa te pueda enviar información adicional sobre esa
                carrera, su proceso o similares. ¡Sigue cuantas carreras quieras!
            </p>
        </div>
        <div *ngIf="pais === 'chile'">
            <p>
                En esta sección puedes buscar carreras de distintas instituciones, ver su información actualizada para
                el
                <b>Proceso de Admisión 2025</b>
                y cuánto estás ponderando con los últimos ensayos que has realizado. También puedes simular tu
                ponderación con puntajes ficticios.
            </p>
            <p>
                Al seleccionar "
                <b>seguir</b>
                " en una carrera, esta se agregará en Orientación y podrás encontrarla en la sección mis carreras y
                simulador. Adicionalmente, aceptas que la institución educativa te pueda enviar información adicional
                sobre esa carrera, su proceso o similares. ¡Sigue cuantas carreras quieras!
            </p>
        </div>
        <div *ngIf="pais === 'brasil'">
            <p>
                Ao selecionar "
                <b>seguir</b>
                " em um curso, ele será adicionado na seção Orientação e você poderá encontrá-lo na seção meus cursos e
                simulador. Além disso, você aceita que a instituição de ensino possa enviar informações adicionais sobre
                esse curso , seu processo ou similar. Siga quantos cursos você quiser!
            </p>
        </div>
    </modal-contenido>
    <modal-botones>
        <cui-button type="button" class="" (click)="onAccept()">Aceptar</cui-button>
    </modal-botones>
</generic-modal>
