<div *ngIf="carrerasLoaded">
    <ng-container *ngFor="let param of params; let i = index">
        <ng-container>
            <form-input
                *ngIf="param.universidadParams.done && universidades.length > 1"
                [form]="form"
                [preload_options]="universidades"
                [params]="param.universidadParams"
                [key]="param.universidadParams.key"
                (valueChange)="universidadChanged(i, $event)"
            ></form-input>
            <form-input
                *ngIf="param.sedeParams.done"
                [form]="form"
                [preload_options]="universidadSedes[i]"
                [params]="param.sedeParams"
                [key]="param.sedeParams.key"
                (valueChange)="sedeChanged(i, $event)"
            ></form-input>
            <form-input
                *ngIf="param.carreraSedeParams.done"
                [form]="form"
                [preload_options]="sedeCarreraSedes[i]"
                [params]="param.carreraSedeParams"
                [key]="param.carreraSedeParams.key"
                (valueChange)="setVals(i, $event)"
            ></form-input>
        </ng-container>
    </ng-container>
</div>
