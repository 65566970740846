import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Instrumento } from "./instrumentos.model"
import { EvaluacionUsuario } from "./evaluacion_usuarios.model"

export class InstrumentoMensual extends BaseModel {
    public instrumento_id: number
    public nombre: string
    public month: number
    public year: number
    public asignatura_id: number
    public tipo_instrumento_id: number
    public evaluacion_usuario_id: number
    public created_at: Date
    public updated_at: Date

    @SaveType(() => EvaluacionUsuario) public evaluacion_usuario: EvaluacionUsuario
    @SaveType(() => Instrumento) public instrumento: Instrumento

    public toString() {
        return "(" + this.instrumento_id + "," + this.nombre + ")"
    }
}
