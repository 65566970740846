<div *ngIf="reset_password_token">
    <p>{{ "landing.reiniciar_password.ingresa_nuevo_password" | t }}:</p>
    <flash-message></flash-message>
    <div class="form-group">
        <input
            id="password"
            type="password"
            [(ngModel)]="password"
            (ngModelChange)="isBreachedPassword(passwordControl)"
            #passwordControl="ngModel"
            class="form-control"
            placeholder="{{ 'landing.reiniciar_password.nuevo_password_placeholder' | t }}"
        />
    </div>
    <password-strength-bar *ngIf="password" [passwordToCheck]="password"></password-strength-bar>
    <div class="form-group">
        <input
            id="password_confirmation"
            type="password"
            [(ngModel)]="passwordConfirmation"
            class="form-control"
            placeholder="{{ 'landing.reiniciar_password.confirmar_password_placeholder' | t }}"
        />
    </div>
    <div class="clearfix">
        <p class="pull-left">{{ "landing.reiniciar_password.password_largo_minimo" | t }}</p>
        <cui-button class="btn_style pull-right" type="button" (click)="changePassword()">
            {{ "landing.reiniciar_password.guardar_password" | t }}
        </cui-button>
    </div>
</div>
