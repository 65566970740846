import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Logro } from "./logros.model"

export class Categoria extends BaseModel {
    categoria: string

    @SaveType(() => Logro) logros: Logro[]

    public toString() {
        return this.categoria
    }
}
