<ng-template [ngIf]="params && params.type != 'model' && params.type != 'models' && !params.hidden">
    <div [formGroup]="form" [class.grouped]="params.filter">
        <ng-container *ngIf="params.type == 'hidden'">
            <input type="hidden" [formControlName]="key" />
        </ng-container>

        <div *ngIf="params.type == 'text'" class="form-group">
            <ng-container *ngTemplateOutlet="customLabel"></ng-container>
            <ng-container *ngIf="tooltip || params.tooltip">
                <label *ngIf="withLabel" [attr.for]="key" [tooltip]="tooltip || params.tooltip">
                    {{ params.label }}
                    <span class="glyphicon glyphicon-question-sign"></span>
                    <span *ngIf="isRequired" class="required-mark"></span>
                </label>
            </ng-container>
            <ng-container *ngIf="!tooltip && !params.tooltip">
                <label *ngIf="withLabel" [attr.for]="key">
                    {{ params.label }}
                    <span *ngIf="isRequired" class="required-mark"></span>
                </label>
            </ng-container>
            <input
                [attr.disabled]="disabled ? '' : null"
                [id]="key"
                [attr.aria-required]="isRequired"
                [attr.aria-invalid]="
                    (control && control.errors && control.touched) ||
                    (form.errors && form.invalid && control.touched && control['global'])
                "
                [attr.aria-describedby]="key + '_error'"
                type="text"
                class="form-control"
                [formControlName]="key"
                placeholder="{{ params?.placeholder }}"
            />
        </div>

        <div *ngIf="params.type == 'color'" class="form-group">
            <ng-container *ngTemplateOutlet="customLabel"></ng-container>
            <ng-container *ngIf="tooltip || params.tooltip">
                <label *ngIf="withLabel" [attr.for]="key" [tooltip]="tooltip || params.tooltip">
                    {{ params.label }}
                    <span *ngIf="isRequired" class="required-mark"></span>
                </label>
            </ng-container>
            <ng-container *ngIf="!tooltip && !params.tooltip">
                <label *ngIf="withLabel" [attr.for]="key">
                    {{ params.label }}
                    <span *ngIf="isRequired" class="required-mark"></span>
                </label>
            </ng-container>
            <input
                [attr.disabled]="disabled ? '' : null"
                [id]="key"
                [attr.aria-required]="isRequired"
                [attr.aria-invalid]="
                    (control && control.errors && control.touched) ||
                    (form.errors && form.invalid && control.touched && control['global'])
                "
                [attr.aria-describedby]="key + '_error'"
                type="color"
                class="form-control"
                [formControlName]="key"
                placeholder="{{ params?.placeholder }}"
            />
        </div>

        <div *ngIf="params.type == 'text-fix'" class="form-group">
            <ng-container *ngIf="tooltip || params.tooltip">
                <label *ngIf="withLabel" [attr.for]="key" [tooltip]="tooltip || params.tooltip">
                    {{ params.label }}
                    <span *ngIf="isRequired" class="required-mark"></span>
                </label>
            </ng-container>
            <ng-container *ngIf="!tooltip && !params.tooltip">
                <label *ngIf="withLabel" [attr.for]="key">
                    {{ params.label }}
                    <span *ngIf="isRequired" class="required-mark"></span>
                </label>
            </ng-container>
            <div class="input-group">
                <div class="input-group-addon" *ngIf="params.prefix">{{ params.prefix }}</div>
                <input
                    type="text"
                    class="form-control"
                    [formControlName]="key"
                    [id]="key"
                    [attr.disabled]="disabled ? '' : null"
                    [attr.aria-required]="isRequired"
                    [attr.aria-invalid]="
                        (control && control.errors && control.touched) ||
                        (form.errors && form.invalid && control.touched && control['global'])
                    "
                    [attr.aria-describedby]="key + '_error'"
                    placeholder="{{ params?.placeholder }}"
                />
                <div class="input-group-addon" *ngIf="params.suffix">{{ params.suffix }}</div>
            </div>
        </div>

        <div *ngIf="params.type == 'number'" class="form-group">
            <ng-container *ngTemplateOutlet="customLabel"></ng-container>
            <label *ngIf="withLabel" [attr.for]="key">
                {{ params.label }}
                <span *ngIf="isRequired" class="required-mark"></span>
            </label>
            <input
                type="number"
                class="form-control"
                [id]="key"
                [formControlName]="key"
                [attr.disabled]="disabled ? '' : null"
                [attr.aria-required]="isRequired"
                [attr.aria-invalid]="
                    (control && control.errors && control.touched) ||
                    (form.errors && form.invalid && control.touched && control['global'])
                "
                [attr.aria-describedby]="key + '_error'"
                placeholder="{{ params?.placeholder }}"
            />
        </div>

        <ng-container *ngIf="params.type == 'password'">
            <div class="form-group">
                <label [attr.for]="key">
                    {{ params.label }}
                    <span *ngIf="isRequired" class="required-mark"></span>
                </label>
                <input
                    type="password"
                    class="form-control"
                    [formControlName]="key"
                    [id]="key"
                    [attr.disabled]="disabled ? '' : null"
                    [attr.aria-required]="isRequired"
                    [attr.aria-invalid]="
                        (control && control.errors && control.touched) ||
                        (form.errors && form.invalid && control.touched && control['global'])
                    "
                    [attr.aria-describedby]="key + '_error'"
                    placeholder="{{ params?.placeholder }}"
                />
            </div>
        </ng-container>

        <div *ngIf="params.type == 'autocomplete'" class="form-group" #autocomplete>
            <label [attr.for]="key">
                {{ params.label }}
                <span *ngIf="isRequired" class="required-mark"></span>
            </label>
            <input
                type="text"
                class="form-control autocomplete-input"
                autocomplete="off"
                [id]="key"
                [formControlName]="key"
                (keyup)="filter($event.code)"
                (keydown)="keepIndex($event.code)"
                (focus)="onAutocompleteFocus()"
                (focusout)="onAutocompleteLoseFocus()"
                [attr.disabled]="disabled ? '' : null"
                [attr.aria-required]="isRequired"
                [attr.aria-invalid]="
                    (control && control.errors && control.touched) ||
                    (form.errors && form.invalid && control.touched && control['global'])
                "
                [attr.aria-describedby]="key + '_error'"
                placeholder="{{ params?.placeholder }}"
            />
            <div class="suggestions-container">
                <div class="suggestions" *ngIf="filtered_options?.length > 0" #suggestions>
                    <ul class="ui-autocomplete ui-menu">
                        <li
                            *ngFor="let item of filtered_options; let i = index"
                            class="ui-menu-item"
                            [class.ui-state-hover]="filterId == i + 1"
                            [focused]="filterId == i + 1"
                            (mouseover)="hoverSelect(i)"
                        >
                            <a (click)="select(item)">
                                {{ item }}
                                {{ addedAtEndForCreating && filtered_options.length - 1 == i ? "(Nuevo)" : "" }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div *ngIf="params.type == 'date'" class="form-group">
            <label [attr.for]="key">
                {{ params.label }}
                <span *ngIf="isRequired" class="required-mark"></span>
            </label>
            <input
                type="date"
                class="form-control"
                [id]="key"
                [formControlName]="key"
                placeholder="{{ params?.placeholder }}"
                min="{{ params?.min }}"
                max="{{ params?.max }}"
                [attr.disabled]="disabled ? '' : null"
                [attr.aria-required]="isRequired"
                [attr.aria-invalid]="
                    (control && control.errors && control.touched) ||
                    (form.errors && form.invalid && control.touched && control['global'])
                "
                [attr.aria-describedby]="key + '_error'"
            />
            <!--<label [attr.for]="key">{{ params.label }} <span *ngIf="isRequired" class="required-mark"></span></label>
		    <input [(ngModel)]="dateModel" class="form-control" (focus)="showPopup()" [formControlName]="key" readonly/>
		    <datepicker class="popup-datepicker" [(ngModel)]="dateModel" *ngIf="showDatepicker" [formControlName]="key" [showWeeks]="true" (selectionDone)="hidePopup($event)"></datepicker>-->
        </div>

        <div *ngIf="params.type == 'datepicker'" class="form-group">
            <label [attr.for]="key">
                {{ params.label }}
                <span *ngIf="isRequired" class="required-mark"></span>
            </label>
            <datepicker-form
                [attr.aria-required]="isRequired"
                [attr.aria-invalid]="
                    (control && control.errors && control.touched) ||
                    (form.errors && form.invalid && control.touched && control['global'])
                "
                [attr.aria-describedby]="key + '_error'"
                [formControlName]="key"
                [isMeridian]="params.isMeridian"
                [disableTimepicker]="params.disableTimepicker"
                [asISOString]="params.asISOString"
            ></datepicker-form>
        </div>

        <div *ngIf="params.type == 'birthdate'" class="form-group">
            <fieldset>
                <legend [attr.for]="key">
                    {{ params.label }}
                    <span class="sr-only">Necesaria</span>
                    <span *ngIf="isRequired" class="required-mark"></span>
                </legend>
                <birthdate-form
                    [attr.aria-required]="isRequired"
                    [attr.aria-invalid]="
                        (control && control.errors && control.touched) ||
                        (form.errors && form.invalid && control.touched && control['global'])
                    "
                    [attr.aria-describedby]="key + '_error'"
                    [date]="dateModel"
                    [formControlName]="key"
                    [invalid]="
                        (control && control.errors && control.touched) ||
                        (form.errors && form.invalid && control.touched && control['global'])
                    "
                    [describeError]="key + '_error'"
                ></birthdate-form>
            </fieldset>
        </div>

        <div *ngIf="params.type == 'textarea'" class="form-group">
            <label [attr.for]="key">
                {{ params.label }}
                <span *ngIf="isRequired" class="required-mark"></span>
            </label>
            <textarea
                [attr.aria-required]="isRequired"
                [attr.aria-invalid]="
                    (control && control.errors && control.touched) ||
                    (form.errors && form.invalid && control.touched && control['global'])
                "
                [attr.aria-describedby]="key + '_error'"
                [id]="key"
                rows="5"
                cols="30"
                [formControlName]="key"
                class="form-control"
            ></textarea>
        </div>

        <div *ngIf="params.type == 'ckeditor'" class="form-group">
            <label [attr.for]="key">{{ params.label }}</label>
            <ckeditor
                [attr.aria-required]="isRequired"
                [attr.aria-invalid]="
                    (control && control.errors && control.touched) ||
                    (form.errors && form.invalid && control.touched && control['global'])
                "
                [attr.aria-describedby]="key + '_error'"
                [formControlName]="key"
                [config]="configDefault"
                (fileUploadResponse)="ckEditorFileReady($event)"
                debounce="500"
            ></ckeditor>
        </div>

        <div *ngIf="params.type == 'select' && !params.multiple" class="form-group">
            <ng-container *ngTemplateOutlet="customLabel"></ng-container>
            <label *ngIf="withLabel" [attr.for]="key">
                {{ params.label }}
                <span *ngIf="isRequired" class="required-mark"></span>
            </label>
            <select
                [attr.aria-invalid]="
                    (control && control.errors && control.touched) ||
                    (form.errors && form.invalid && control.touched && control['global'])
                "
                [attr.aria-describedby]="key + '_error'"
                [attr.aria-required]="isRequired"
                [id]="key"
                [formControlName]="key"
                class="form-control"
            >
                <option value="" *ngIf="params.showNameSelect">-- Seleccione {{ params.label | lowercase }} --</option>
                <option value="" *ngIf="!params.showNameSelect">-- Seleccione --</option>
                <option *ngFor="let option of options" [value]="$any(option)[params.idField || 'id']">
                    {{ option.toString() }}
                </option>
            </select>
        </div>

        <div *ngIf="params.type == 'select' && params.multiple" class="form-group">
            <ng-container *ngTemplateOutlet="customLabel"></ng-container>
            <label *ngIf="withLabel" [attr.for]="key">
                {{ params.label }}
                <span *ngIf="isRequired" class="required-mark"></span>
            </label>
            <select
                [attr.aria-invalid]="
                    (control && control.errors && control.touched) ||
                    (form.errors && form.invalid && control.touched && control['global'])
                "
                [attr.aria-required]="isRequired"
                [attr.aria-describedby]="key + '_error'"
                multiple
                [id]="key"
                [formControlName]="key"
                class="form-control"
            >
                <option value="">-- Seleccione {{ params.label | lowercase }} --</option>
                <option *ngFor="let option of options" [value]="$any(option)[params.idField || 'id']">
                    {{ option.toString() }}
                </option>
            </select>
        </div>

        <div *ngIf="params.type == 'checkbox' && !params.multiple" class="form-group">
            <div class="checkbox">
                <label [attr.for]="key + id_instance_counter">
                    <input
                        [attr.disabled]="disabled ? '' : null"
                        [attr.aria-required]="isRequired"
                        [attr.aria-invalid]="
                            (control && control.errors && control.touched) ||
                            (form.errors && form.invalid && control.touched && control['global'])
                        "
                        [attr.aria-describedby]="key + '_error'"
                        type="checkbox"
                        [formControlName]="key"
                        [id]="key + id_instance_counter"
                    />
                    <span *ngIf="params.label">{{ params.label }}</span>
                    <div *ngIf="params.innerhtml" [innerHTML]="params.innerhtml"></div>
                </label>
            </div>
        </div>

        <div *ngIf="params.type == 'checkbox' && params.multiple" class="form-group">
            <label [attr.for]="key">
                {{ params.label }}
                <span *ngIf="isRequired" class="required-mark"></span>
            </label>
            <checkboxes
                [attr.aria-required]="isRequired"
                [attr.aria-invalid]="
                    (control && control.errors && control.touched) ||
                    (form.errors && form.invalid && control.touched && control['global'])
                "
                [attr.aria-describedby]="key + '_error'"
                *ngIf="options && options.length != 0"
                [options]="options"
                [formControlName]="key"
            ></checkboxes>
        </div>

        <div *ngIf="params.type == 'radio'" class="form-group">
            <div class="radio-input">
                <div class="radio-label">
                    <label [attr.for]="key">{{ params.label }}</label>
                    <span *ngIf="isRequired" class="required-mark"></span>
                </div>
                <div *ngFor="let option of options">
                    <label class="option-label">
                        <input
                            [attr.disabled]="disabled ? '' : null"
                            [attr.aria-required]="isRequired"
                            [attr.aria-invalid]="
                                (control && control.errors && control.touched) ||
                                (form.errors && form.invalid && control.touched && control['global'])
                            "
                            [attr.aria-describedby]="key + '_error'"
                            type="radio"
                            [id]="key"
                            [formControlName]="key"
                            [value]="option.id"
                        />
                        <ogr-icon class="icono" [name]="option.toString()" *ngIf="params.icono"></ogr-icon>
                        {{ option.toString() }}
                    </label>
                </div>
            </div>
        </div>

        <div *ngIf="params.filter && value && value.every">
            <!-- No se puede hacer "value instanceOf Array", así que lo verificamos como los campeones -->
            <form-input
                *ngFor="let key of params.filter[filterId] | keys; let i = index"
                [key]="key"
                [params]="params.filter[filterId][key]"
                [(value)]="intermediateValue[i]"
                (valueChange)="emitToParent()"
                [form]="form"
            ></form-input>
        </div>

        <div *ngIf="params.type == 'file'" class="form-group">
            <label [attr.for]="key">
                {{ params.label }}
                <span *ngIf="isRequired" class="required-mark"></span>
            </label>
            <file
                [attr.aria-required]="isRequired"
                [attr.aria-invalid]="
                    (control && control.errors && control.touched) ||
                    (form.errors && form.invalid && control.touched && control['global'])
                "
                [attr.aria-describedby]="key + '_error'"
                [formControlName]="key"
                [xhr_upload]="xhr_upload"
                [key]="key"
                [label]="params.label"
            ></file>
        </div>
    </div>

    <form-input-error [params]="params" [key]="key" [form]="form" [control]="control" [customError]="customError">
        <span class="custom-error">
            <ng-content select=".custom-error"></ng-content>
        </span>
    </form-input-error>

    <!-- <div
        class="contracted-error"
        [class.expanded-error]="
            (control && control.errors && control.touched) ||
            (form.errors && form.invalid && control.touched && control['global'])
        "
        [id]="key + '_error'"
    >
        <ul *ngIf="control && control.errors && control.touched" class="error-list" #error>
            <li *ngFor="let errorKey of control.errors | keys">
                <div class="align-table">
                    <div class="left-icon">
                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    </div>
                    <div [id]="'error-message-' + key" class="right-message">
                        {{
                            validations.getMessage(params.label, errorKey, control.errors[errorKey], params.customError)
                        }}
                    </div>
                </div>
            </li>
            <li *ngIf="customError">
                <div class="align-table">
                    <div class="left-icon">
                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    </div>
                    <div class="right-message">
                        <ng-content select=".custom-error"></ng-content>
                    </div>
                </div>
            </li>
        </ul>
        <ul *ngIf="form.errors && form.invalid && control.touched && control['global']" class="error-list" #error>
            <li *ngFor="let formError of form.errors | keys">
                <div
                    *ngIf="form.errors[formError].keys && form.errors[formError].keys.includes(key)"
                    class="align-table"
                >
                    <div class="left-icon">
                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    </div>
                    <div class="right-message">
                        {{ validations.getMessage(key, formError, form.errors[formError]) }}
                    </div>
                </div>
            </li>
        </ul>
    </div> -->

    <ng-container *ngIf="enablePasswordBar && params.type == 'password' && value">
        <password-strength-bar [passwordToCheck]="value"></password-strength-bar>
    </ng-container>
</ng-template>

<ng-template [ngIf]="params && params.type == 'model' && !params.hidden">
    <form-input
        *ngFor="let k of model_params | keys"
        [key]="k"
        [params]="model_params[k]"
        [(value)]="value[k]"
        [(form)]="childForm"
        (formChange)="setFormParentGroup($event)"
    ></form-input>
</ng-template>
<ng-template [ngIf]="params && params.type == 'models' && !params.hidden">
    <ng-template ngFor let-model [ngForOf]="value" let-i="index">
        <form-input
            *ngFor="let k of model_params | keys"
            [key]="k"
            [params]="model_params[k]"
            [(value)]="model[k]"
            [(form)]="$any(form.controls)[key]['controls'][i]"
            (formChange)="setFormParentArray($event)"
        ></form-input>
    </ng-template>
</ng-template>

<ng-template #customLabel>
    <ng-content select="label"></ng-content>
</ng-template>
