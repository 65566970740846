import { BaseModel } from "@puntaje/shared/core"

export class MonitorPregunta extends BaseModel {
    public static className: string = "MonitorPregunta"
    public cantidad: number
    public modelo: string
    public modelo_id: number
    public visible: boolean
    public estado: number
    public presencial: boolean
    public created_at: Date
    public updated_at: Date

    public toString() {
        return this.id
    }
}
