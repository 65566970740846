import { BaseModel, SaveType } from "@puntaje/shared/core"
import { GeneradorInstrumento } from "./generador_instrumentos.model"
import { Clasificacion } from "./clasificaciones.model"

export class GeneradorInstrumentoClasificacion extends BaseModel {
    public static className: string = "GeneradorInstrumentoClasificacion"
    public generador_instrumento_id: number
    public clasificacion_id: number
    public clasificacion_mencion_id: number
    public clasificacion_suficiencia_id: number
    public porcentaje: number
    public orden: number
    public created_at: Date
    public updated_at: Date

    @SaveType(() => GeneradorInstrumento) generador_instrumento: GeneradorInstrumento
    @SaveType(() => Clasificacion) clasificacion: Clasificacion
    @SaveType(() => Clasificacion) clasificacion_mencion: Clasificacion
    @SaveType(() => Clasificacion) clasificacion_suficiencia: Clasificacion
}
