import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Banner } from "./banners.model"
import { BannerZona } from "./banner_zonas.model"

export class BannerInstancia extends BaseModel {
    public banner_id: number
    public banner_zona_id: number
    public banner_pagina_id: number
    public clicks: number = 0
    public impresiones: number = 0
    public activo: boolean = true

    @SaveType(() => Banner) public banner: Banner
    @SaveType(() => BannerZona) public banner_zona: BannerZona

    constructor() {
        super()
    }

    public toString() {
        return this.banner_zona_id + "_" + this.banner_pagina_id
    }
}
