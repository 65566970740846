import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Universidad } from "./universidades.model"

export class PreferenciaUniversidad extends BaseModel {
    public static className: string = "PreferenciaUniversidad"

    public plataformaId: number
    public universidadId: number
    public usuarioId: number
    public orden: number
    public _destroy?: boolean
    @SaveType(() => Universidad) public universidad?: Universidad
}
