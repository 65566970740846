import { Component, OnInit, Input, Output, EventEmitter, Optional } from "@angular/core"
import { Location } from "@angular/common"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"

import { Ayuda, Ayudas } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PdfView, YoutubeVideoView, GameView, I18nService } from "@puntaje/shared/core"

import { Angulartics2 } from "angulartics2"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { HttpClient } from "@angular/common/http"

@Component({
    selector: "ayuda-with-thumbnail",
    templateUrl: "ayuda_with_thumbnail.component.html",
    styleUrls: ["ayuda_with_thumbnail.component.scss"]
})
export class AyudaWithThumbnailComponent implements OnInit {
    @Input() tracking: boolean = false
    @Input() ayuda: Ayuda
    isVideo: boolean = false
    isArchivo: boolean = false
    isPdf: boolean = false
    isGame: boolean = false
    thumbType: string = "archivo"
    showUploadDate: boolean =
        config.app.baseStyle?.ayuda?.showUploadDate !== undefined ? config.app.baseStyle.ayuda.showUploadDate : true
    thumbnailUrl: string
    @Input() id: number

    constructor(
        protected ayudasService: Ayudas,
        protected router: Router,
        protected route: ActivatedRoute,
        protected location: Location,
        private http: HttpClient,
        protected i18nService: I18nService,
        @Optional() public angulartics2: Angulartics2
    ) { }

    async ngOnInit() {
        //ayuda viene incluido como input
        if (this.ayuda) {
            this.isVideo = this.ayuda.material_tipo_nombre == "Video" || this.ayuda.material_tipo_nombre == "Streaming"
            this.isArchivo = this.ayuda.material_tipo_nombre == "Archivo"
            this.isPdf = this.ayuda.material_tipo_nombre == "Archivo" && this.ayuda.getExtension() == "pdf"
            this.isGame = this.ayuda.material_tipo_nombre == "Juego"

            switch (this.ayuda.material_tipo_nombre) {
                case "Video":
                case "Streaming":
                    if (this.ayuda.url.indexOf("youtube") != -1 || this.ayuda.url.indexOf("youtu.be") != -1) {
                        this.thumbType = "video-youtube"
                        this.thumbnailUrl =
                            "https://img.youtube.com/vi/" + this.getYoutubeVideoId(this.ayuda.url) + "/mqdefault.jpg"
                    } else if (this.ayuda.url.indexOf("vimeo") != -1) {
                        this.thumbType = "video-vimeo"
                        this.thumbnailUrl = await this.getVimeoVideoUri(this.ayuda.url)
                    } else {
                        this.thumbType = "video"
                    }
                    break
                case "Archivo":
                    this.thumbType = this.isPdf ? "pdf" : "archivo"
                    break
                default:
                    this.thumbType = "archivo"
                    break
            }
        }
    }

    getYoutubeVideoId(url) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
        var match = url.match(regExp)
        return match && match[2].length == 11 ? match[2] : false
    }

    async getVimeoVideoUri(url) {
        const vCode = url.substr(url.indexOf("vimeo.com/") + 10, url.length)

        return this.http
            .get("https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/" + vCode)
            .toPromise()
            .then(response => {
                const privateVideoId = response["uri"]
                    .substring(response["uri"].indexOf("/videos/") + 8, response["uri"].length)
                    .replace(":", "/")

                return this.http
                    .get("https://vimeo.com/api/oembed.json?url=https://vimeo.com/" + privateVideoId)
                    .toPromise()
                    .then(videoData => {
                        return videoData["thumbnail_url"]
                    })
            })
    }

    goToAyuda() {
        this.router.navigate(["/ayudas/" + this.ayuda.id])
    }

    getDownloadLink = (m: Ayuda) => {
        return m.url
    }

    isFileLink = (m: Ayuda) => {
        return m.isFile()
    }

    trackClickEvent(category: string, label: string): void {
        if (this.tracking && this.angulartics2) {
            this.angulartics2.eventTrack.next({
                action: this.i18nService.translate("ayuda.visualizar", {}),
                properties: {
                    category: category,
                    label: label
                }
            })
        }
    }

    trackDownload() {
        if (this.tracking && this.angulartics2) {
            this.angulartics2.eventTrack.next({
                action: this.i18nService.translate("ayuda.descargar", {}),
                properties: {
                    category: (this.ayuda as any).nombre_asignatura,
                    label: this.ayuda.material_tipo_nombre
                }
            })
        }
    }
}
