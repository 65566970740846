import { Validators } from "@angular/forms"
import { BaseModel, SaveType } from "@puntaje/shared/core"
import { ReporteRazon } from "./reporte_razones.model"
import { ReporteRazones } from "./reporte_razones.service"

export class ReporteSubRazon extends BaseModel {
    public static className: string = "ReporteSubRazon"
    public id: number
    public reporte_razon_id: number
    public sub_reporte: string
    public explicacion: string
    public gatillante: boolean
    public explicado: boolean
    public created_at: Date
    public updated_at: Date

    @SaveType(() => ReporteRazon) public reporte_razon: ReporteRazon

    constructor() {
        super()
        this.reporte_razon = new ReporteRazon()
    }

    public static get model_params(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            reporte_razon_id: {
                label: "Razón de Reporte",
                type: "select",
                visible: true,
                options: { class: ReporteRazones }
            },
            sub_reporte: {
                label: "Sub Razón",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            explicacion: {
                label: "Explicación",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            gatillante: { label: "Gatillante", type: "checkbox", visible: true },
            explicado: { label: "Con Explicación", type: "checkbox", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }

    public toString() {
        return this.explicacion
    }
}
