import { Input, Component, EventEmitter, Output, OnInit, ChangeDetectorRef } from "@angular/core"
import { UntypedFormGroup, Validators } from "@angular/forms"
import { Sede, CarreraSede, Universidades, Universidad, PreferenciaCarreraSede } from "@puntaje/carreras/api-services"
import { CapitalizePipe } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "embed-preferencia-carrera-sedes-by-sede",
    templateUrl: "embed_preferencia_carrera_sedes_by_sede.component.html"
})
export class EmbedPreferenciaCarreraSedesBySedeComponent implements OnInit {
    @Input() universidadesNombres: string[]
    @Input() amount: number
    @Input() form: UntypedFormGroup
    @Input() notRequired = false
    @Output() emitPreferencias: EventEmitter<PreferenciaCarreraSede[]> = new EventEmitter<PreferenciaCarreraSede[]>()

    universidades: Universidad[] = []
    sedes: Sede[] = []
    params: any[] = []
    universidadSedes: Sede[][] = []
    sedeCarreraSedes: CarreraSede[][] = []
    preferenciaCarreraSedes: PreferenciaCarreraSede[] = []
    carrerasLoaded: boolean = false

    // TODO: usar capitalize de ogr-core-lib en vez del pipe
    capitalize = new CapitalizePipe()

    constructor(private universidadesService: Universidades, public cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.setSelectsAndModels()
        if (this.universidadesNombres) {
            const universidadesParams = {
                universidad: {
                    universidad: this.universidadesNombres
                },
                include: "[sede?:[lugar?:[pais?],carreraSede?]]"
            }
            this.universidadesService.where(universidadesParams).then((universidades: Universidad[]) => {
                if (universidades.length > 0) {
                    this.universidades = universidades.filter(universidad => {
                        return universidad.sedes.find(
                            sede => sede.lugar.pais.pais == this.capitalize.transform(config.plataforma.pais)
                        )
                    })
                    this.universidades.forEach(universidad => {
                        universidad.sedes = universidad.sedes.filter(
                            sede => sede.lugar?.pais?.pais == this.capitalize.transform(config.plataforma.pais)
                        )
                    })

                    this.sedes = universidades[0].sedes.sort((a, b) => (a.sede > b.sede ? 1 : -1))

                    if (this.universidades.length == 1) {
                        this.universidadChanged(0, this.universidades[0].id)
                    }

                    this.carrerasLoaded = true
                }
            })
        }
    }

    setSelectsAndModels() {
        this.params = []
        this.universidadSedes = []
        this.sedeCarreraSedes = []
        this.preferenciaCarreraSedes = []
        let amount = this.amount ? this.amount : 3

        for (var i = 0; i < amount; i++) {
            let paramI = {}

            paramI["universidadId"] = null
            paramI["sedeId"] = null
            paramI["carreraSedeId"] = null

            const sufijo = this.amount == 1 ? "" : "" + (i + 1)

            // Universidad
            const labelUniversidad = "Seleccionar Institución " + sufijo
            const keyUniversidad = "universidad_" + (i + 1)
            paramI["universidadParams"] = {
                type: "select",
                label: labelUniversidad,
                visible: true,
                key: keyUniversidad,
                done: false
            }

            //Sede
            const labelSede = "Seleccionar Sede " + sufijo
            const keySede = "carrera_sede_" + (i + 1)
            paramI["sedeParams"] = {
                type: "select",
                label: labelSede,
                visible: true,
                key: keySede,
                done: false,
                sede: null,
                carrera: null
            }

            // CarreraSede
            const labelCarreraSede = "Seleccionar Carrera " + sufijo
            const keyCarreraSede = "preferencia_carrera_sede_" + (i + 1)
            paramI["carreraSedeParams"] = {
                type: "select",
                label: labelCarreraSede,
                visible: true,
                key: keyCarreraSede,
                done: false
            }

            this.preferenciaCarreraSedes.push(new PreferenciaCarreraSede())

            this.universidadSedes.push([])
            this.sedeCarreraSedes.push([])

            this.params.push(paramI)
        }

        // Primera carrera obligatoria
        if (!this.notRequired) {
            this.params[0].universidadParams.validations = [Validators.required]
            this.params[0].sedeParams.validations = [Validators.required]
            this.params[0].carreraSedeParams.validations = [Validators.required]
        }

        this.params[0].universidadParams.done = true
    }

    setVals(index, val) {
        this.params[index].sedeId = null
        this.params[index].carreraSedeId = null

        if (val) {
            this.preferenciaCarreraSedes[index].carreraSedeId = Number(val)
            this.preferenciaCarreraSedes[index].orden = index + 1

            let carreras = this.preferenciaCarreraSedes.filter(
                x => x.carreraSedeId !== null && x.carreraSedeId !== undefined
            )
            this.emitPreferencias.next(carreras)
            if (index + 1 < this.params.length) {
                let sedeId: number = this.sedeCarreraSedes[index].find((v: any) => v.id == val).sedeId
                this.params[index + 1].universidadParams.done = true
                if (this.universidades.length == 1) {
                    this.universidadChanged(index + 1, this.universidades[0].id)
                }

                this.params[index].sedeId = sedeId
                this.params[index].carreraSedeId = val
            }
        }

        // ordenan por aliases los valores de los arrays de sedeCarreraSedes
        this.sedeCarreraSedes.forEach((sedeCarreraSedes: CarreraSede[]) => {
            sedeCarreraSedes.sort((a, b) => (a.alias > b.alias ? 1 : -1))
        })

        this.cdr.detectChanges()
    }

    hideParams(index) {
        let j = index + 1
        while (j < this.params.length) {
            this.params[j].universidadParams.done = false
            this.params[j].sedeParams.done = false
            this.params[j].carreraSedeParams.done = false
            j += 1
        }
    }

    universidadChanged(index, val) {
        if (val) {
            this.universidadSedes[index] = this.universidades.find(u => u.id == val).sedes
            this.params[index].universidadId = val
            this.params[index].sedeParams.done = true
            this.params[index].carreraSedeParams.done = false
            this.hideParams(index)
        }
        this.cdr.detectChanges()
    }

    sedeChanged(index, val) {
        if (val) {
            this.params[index].carreraSedeParams.done = true

            let carreraSedes = this.universidadSedes[index].find(s => s.id == val).carreraSedes
            if (index > 0) {
                carreraSedes = carreraSedes.slice()
                let j = index
                while (j > 0) {
                    if (
                        this.params[j - 1].sedeId &&
                        this.params[j - 1].carreraSedeId &&
                        this.params[j - 1].sedeId == val
                    ) {
                        carreraSedes = carreraSedes.filter((c: any) => c.id != this.params[j - 1].carreraSedeId)
                    }
                    j -= 1
                }
                this.sedeCarreraSedes[index] = carreraSedes
            } else {
                this.sedeCarreraSedes[index] = carreraSedes
            }
            this.hideParams(index)
        }
        this.cdr.detectChanges()
    }

    public reset() {
        this.setSelectsAndModels()
        this.emitPreferencias.next([])
        this.cdr.detectChanges()
    }
}
