<filtro-dudas
    #filtroDudas
    [fromProfesor]="fromProfesor"
    [showCursos]="showCursos"
    [dudaCategoriaId]="categoriaId"
></filtro-dudas>
<div class="container-dudas">
    <simple-search-filter
        [enableBarMode]="false"
        class="search-filter"
        (updateSearch)="search($event)"
        (clearSearch)="clear()"
        [placeholder]="'participa.dudas.listado.buscar' | t"
    ></simple-search-filter>
    <ul class="sort-options clearfix">
        <!--<li>
			<button type="button" class="btn btn_default">Relevancia</button>
		</li>-->
        <li>
            <button type="button" class="btn btn_default">{{ "participa.dudas.listado.orden.fecha" | t }}</button>
        </li>
    </ul>
    <loading-layout #loadingLayout>
        <div class="lista-dudas">
            <duda-list-item *ngFor="let duda of dudas" [duda]="duda"></duda-list-item>
            <p class="not-found" *ngIf="dudas.length == 0">
                {{ "participa.dudas.listado.not_found" | t }}
            </p>
        </div>
        <paginator #paginator [getList]="showAll"></paginator>
    </loading-layout>
</div>
