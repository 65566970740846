import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Material } from "./materiales.model"
import { PlanEstudio } from "./plan_estudios.model"
import { PlanEstudioSesion } from "./plan_estudio_sesiones.model"

export class PlanEstudioComponente extends BaseModel {
    public static className: string = "PlanEstudioComponente"
    public id: number
    public nombre: string
    public plan_estudio_id: number
    public plan_estudio_sesion_id: number
    public orden: number
    public tipo: string

    @SaveType(() => PlanEstudio) public plan_estudio: PlanEstudio
    @SaveType(() => PlanEstudioSesion) public plan_estudio_sesion: PlanEstudioSesion
    @SaveType(() => Material) public material: Material

    constructor(empty: boolean = false) {
        super()
        if (!empty) {
            this.plan_estudio = new PlanEstudio()
            this.plan_estudio_sesion = new PlanEstudioSesion()
        }
    }

    public toString() {
        return this.nombre
    }
}
