<cui-section-headline>{{ 'materiales_favoritos.title' | t }}</cui-section-headline>
<p class="tutorial">{{ 'materiales_favoritos.tutorial' | t }}.</p>
<loading-layout #loadingLayout>
    <ng-container *ngIf="materialFavoritos">
        <table class="table tabla-materiales table-stripped">
            <tbody>
                <tr *ngFor="let mf of materialFavoritos; let i = index" [class.odd]="i % 2 == 1">
                    <td class="actions first-action">
                        <button (click)="showModalDelete(mf)" type="button" class="btn btn-default btn-sm unsubscribe">
                            <fa name="times" class="icono"></fa>
                        </button>
                    </td>
                    <td class="file-icon-cell">
                        <div class="file-icon">
                            <tipo-material-icon [tipo]="mf.material.material_tipo.material_tipo"></tipo-material-icon>
                        </div>
                    </td>
                    <td>
                        <a [routerLink]="'/materiales/' + mf.material.id">
                            <span [innerHtml]="mf.material.material"></span>
                        </a>
                    </td>
                    <td class="actions">
                        <cui-button-link
                            [routerLinkValue]="'/materiales/' + mf.material.id"
                            class="btn_style btn-small"
                        >
                            {{ 'materiales_favoritos.abrir' | t }}
                        </cui-button-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="not-found" *ngIf="materialFavoritos.length == 0">
            <fa name="heart-o"></fa>
            &nbsp;{{ 'materiales_favoritos.not_found' | t }}.
        </p>
    </ng-container>
    <paginator [getList]="getFavoritos.bind(this)"></paginator>
</loading-layout>
