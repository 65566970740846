import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "biblioteca.component.html",
    styleUrls: ["biblioteca.component.scss"]
})
export class BibliotecaComponent implements OnInit, OnDestroy {
    asignatura: Asignatura
    asignaturaId: number
    tipoInstrumento: string
    cursoId: number
    cursos: any[]
    private sub: Subscription
    subData: Subscription
    enableResponsive: boolean = false
    @ViewChild("innerContent", { static: true }) loadingLayout: LoadingLayoutComponent
    openOnInit: boolean = true
    enableHideEmpty: boolean = false
    rutaMaterial: string = "/materiales"

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.materiales.biblioteca")
        this.sub = this.route.queryParams.subscribe(query => {
            this.tipoInstrumento = query["tipo_instrumento"] || config.plataforma.evaluacionDefault
            this.cursos = config.evaluaciones[this.tipoInstrumento].cursosMaterial
            this.cursoId = query["curso_id"] || this.cursos[0]?.id
        })
        this.subData = this.route.data.subscribe(data => {
            this.openOnInit = data.openOnInit ?? this.openOnInit
            this.enableHideEmpty = data.enableHideEmpty ?? this.enableHideEmpty
            this.rutaMaterial = data.rutaMaterial ?? this.rutaMaterial
        })
        this.enableResponsive = window.innerWidth <= 900
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    onResize(event) {
        this.enableResponsive = event.target.innerWidth <= 900
    }

    onChangeAsignatura(asignaturaId) {
        this.loadingLayout.standby()
        this.asignaturaId = asignaturaId
        this.loadingLayout.ready()
    }

    onChangeCurso(curso) {
        this.loadingLayout.standby()
        this.cursoId = curso
        this.loadingLayout.ready()
        this.cdr.detectChanges()
    }
}
