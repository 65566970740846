import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { LoggedLayoutService } from "@puntaje/shared/cui"
import { AppConfig } from "@puntaje/shared/core"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "clase-plan-clase.component.html"
})
export class ClasePlanClaseComponent {
    sub: Subscription
    subQuery: Subscription
    subData: Subscription

    planEstudioId: number
    planEstudioSesionId: number
    sesionesRoute: string
    grupoUsuarioId: number

    titulo: string = "Sesión"
    planUrl: string = "/plan_clases"
    isLibroFrom: string = config.app.name == "alumnos" ? "alumno" : "docente"
    enableReporte: boolean = false
    enableDudas: boolean = true
    showVerLibro: boolean = false
    esAdmin = config.app.name == "admin"

    constructor(
        protected titleService: TitleService,
        private route: ActivatedRoute,
        protected loggedLayoutService: LoggedLayoutService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_clases.clase_plan_clase")
        this.sub = this.route.params.subscribe(params => {
            this.planEstudioId = +params["id"]
            this.planEstudioSesionId = +params["sesion_id"] || +params["sesionId"]
            this.sesionesRoute = "/plan_estudios/" + this.planEstudioId + "/sesiones"
        })
        this.subQuery = this.route.queryParams.subscribe(params => {
            this.grupoUsuarioId = +params["grupo_usuario_id"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.titulo = data.titulo ?? this.titulo
            this.planUrl = data.planUrl ?? this.planUrl
            this.isLibroFrom = data.isLibroFrom ?? this.isLibroFrom
            this.enableReporte = data.enableReporte ?? this.enableReporte
            this.enableDudas = data.enableDudas ?? this.enableDudas
            this.showVerLibro = data.showVerLibro ?? this.showVerLibro
        })

        this.loggedLayoutService.setOptions({ freeContentStyle: true, noTitle: true, noSpaceIfNoTitle: true })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subQuery.unsubscribe()
        this.subData.unsubscribe()
    }
}
