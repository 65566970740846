import { SaveType, BaseModel } from "@puntaje/shared/core"
import { GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { Evaluacion } from "./evaluaciones.model"
import { EvaluacionInstancia } from "./evaluacion_instancias.model"
import { PlanPersonalSesion } from "./plan_personal_sesiones.model"
import { PlanPersonal } from "./plan_personales.model"

export class PlanPersonalCiclo extends BaseModel {
    public generador_instrumento_id: number
    public plan_personal_id: number
    public evaluacion_id: number
    public evaluacion_instancia_id: number
    public orden: number
    public clasificaciones: string
    @SaveType(() => PlanPersonal) public plan_personal: PlanPersonal
    @SaveType(() => PlanPersonalSesion) public plan_personal_sesiones: PlanPersonalSesion[]
    @SaveType(() => Evaluacion) public evaluacion: Evaluacion
}
