<div [class]="contenedor" *ngIf="escalasGlobales?.length">
    <ng-container *ngFor="let escalaGlobal of escalasGlobales">
        <img
            *ngIf="escalaGlobal.tipo == 'Imagen'"
            class="img-alias"
            [src]="escalaGlobal.escala_global_aliases[0]?.alias"
        />
        <span *ngIf="escalaGlobal.tipo != 'Imagen'" class="nota-alias">
            {{ escalaGlobal.escala_global_aliases[0]?.alias }}
        </span>
    </ng-container>
</div>
