export class Loading {
    public loading: boolean = true
    constructor(val: boolean) {
        this.loading = val
    }
    standby() {
        this.loading = true
    }
    ready() {
        this.loading = false
    }
}
