<cui-showcasebox
    type="establecimientos"
    [hideContent]="!loadingLayout.loading && (!grupoUsuarios || (grupoUsuarios && grupoUsuarios.length == 0))"
>
    <cui-showcasebox-title-icon>
        <ogr-icon name="{{ config.plataforma.iconSet.cursosShowcase }}" class="icon"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>
        {{ "home.showcasebox.grupo_usuarios.soy_docente" | t }}
    </cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <loading-layout #loadingLayout>
            <div *ngIf="grupoUsuarios && grupoUsuarios.length > 0">
                <ul class="lista-grupo-usuarios">
                    <li *ngFor="let grupo of grupoUsuarios">
                        <p class="grupo-usuario">
                            <ogr-icon name="comunidad" class="icono" align="middle"></ogr-icon>
                            &nbsp;{{ grupo }}
                        </p>
                        <p class="establecimiento">
                            <ogr-icon name="establecimiento-o" class="icono" align="middle"></ogr-icon>
                            &nbsp;{{ grupo.establecimiento.establecimiento }}
                        </p>
                    </li>
                </ul>
                <paginator [getList]="setData"></paginator>
            </div>
        </loading-layout>
    </cui-showcasebox-content>
    <cui-showcasebox-data-not-found
        *ngIf="!loadingLayout.loading && (!grupoUsuarios || (grupoUsuarios && grupoUsuarios.length == 0))"
    >
        <p class="not-found" [class.default-not-found]="defaultNotFound">
            {{ "home.showcasebox.grupo_usuarios.sin_cursos1" | t }}
            {{ config.plataforma.grupoUsuarioAlias | pluralize }}
            {{ "home.showcasebox.grupo_usuarios.sin_cursos2" | t }}.
        </p>
    </cui-showcasebox-data-not-found>
    <cui-showcasebox-view-more>
        <a [routerLink]="[rutaGrupoUsuarios || '/grupo_usuarios']" class="pull-right">
            {{ "home.showcasebox.grupo_usuarios.ir_cursos" | t }}
        </a>
    </cui-showcasebox-view-more>
</cui-showcasebox>
