import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()
export class AllReadyService {
    allReady = new Subject<any>()
    counters = {}

    constructor() {}

    setCounterWithKey(key: string, limit: number) {
        this.counters[key] = { count: 0, limit: limit }
    }

    updateCounterWithKey(key: string) {
        if (this.counters[key]) this.counters[key].count++
        if (this.counters[key] && this.counters[key].count == this.counters[key].limit) this.allReady.next(key)
    }
}
