import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
// import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { AyudaModule as AyudaMod } from "@puntaje/puntaje/new-modules/ayuda"
import { UtilModule } from "@puntaje/shared/core"
import { AyudaComponent } from "./ayuda.component"
import { CUILayoutsModule, CUIInputsModule } from "@puntaje/shared/cui"
import { AyudasComponent } from "./ayudas.component"
import { HomeAyudasProfesorComponent } from "./home_ayudas_profesor.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { AyudaContactoComponent } from "./ayuda-contacto/ayuda-contacto.component"

// import { AyudaComponent } from "./ayuda.component"
// import { MaterialesAyudasComponent } from "./materiales_ayudas.component"
// import { HomeAyudasComponent } from "./home_ayudas.component"
// import { ayudaRouting } from "./profesores_ayuda.routing"
// import { PreguntasFrecuentesComponent } from "./preguntas-frecuentes/preguntas-frecuentes.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilModule,
        ReactiveFormsModule,
        AyudaMod,
        CUILayoutsModule,
        CommonsLayoutsModule,
        CUIInputsModule
    ],
    declarations: [AyudaComponent, AyudasComponent, HomeAyudasProfesorComponent, AyudaContactoComponent],
    exports: [AyudaComponent, AyudasComponent, HomeAyudasProfesorComponent]
})
export class PuntajeAyudaModule { }
