import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { Duda } from "./dudas.model"

export class DudaCategoria extends BaseModel {
    public duda_categoria: string
    public asignatura_id: number
    public clasificacion_id: number
    public duda_categoria_padre_id: number
    public total_dudas: number

    @SaveType(() => DudaCategoria) public duda_categoria_padre: DudaCategoria
    @SaveType(() => Asignatura) public asignatura: Asignatura
    @SaveType(() => Duda) public last_duda: Duda
    @SaveType(() => Duda) public dudas: Duda

    toString() {
        return this.duda_categoria
    }
}
