import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Ayuda } from "@puntaje/nebulosa/api-services"
import { PdfView, YoutubeVideoView, GameView, HtmlView } from "@puntaje/shared/core"

@Component({
    selector: "ayuda-preview",
    templateUrl: "ayuda_preview.component.html",
    styleUrls: ["ayuda_preview.component.scss"]
})
export class AyudaPreviewComponent implements OnInit {
    @Input() ayuda: Ayuda
    enablePreview: boolean = false
    params: any = {}
    isVideo: boolean = false
    isFile: boolean = false
    isPdf: boolean = false
    isGame: boolean = false
    isHTML: boolean = false

    constructor() {
        this.params = {
            Video: { params: { label: "Reproducir", type: YoutubeVideoView, tableVisible: true }, key: "url" },
            Streaming: { params: { label: "Reproducir", type: YoutubeVideoView, tableVisible: true }, key: "url" },
            Archivo: { params: { label: "Previsualizar", type: PdfView, tableVisible: true }, key: "url" },
            Juego: { params: { label: "Jugar", type: GameView, tableVisible: true }, key: "url" },
            HTML: { params: { label: "Ver", type: HtmlView, tableVisible: true }, key: "contenido" }
        }
    }

    ngOnInit() {
        this.loadPreview()
    }

    loadPreview() {
        if (this.ayuda) {
            this.isVideo = this.ayuda.material_tipo_nombre == "Video"
            if (!this.isVideo) {
                this.isVideo = this.ayuda.material_tipo_nombre == "Streaming"
            }
            this.isFile = this.ayuda.material_tipo_nombre == "Archivo"
            this.isPdf = this.ayuda.material_tipo_nombre == "Archivo" && this.ayuda.getExtension() == "pdf"
            this.isGame = this.ayuda.material_tipo_nombre == "Juego"
            this.isHTML = this.ayuda.material_tipo_nombre == "HTML"
            this.enablePreview = true
        }
    }
}
