import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Carrera } from "./carreras.model"

export class PreferenciaCarrera extends BaseModel {
    public carrera_id: number
    public usuario_id: number
    public orden: number
    public created_at: any
    public updated_at: any
    public link_ficha: string

    @SaveType(() => Carrera) public carrera: Carrera
}
