import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Clasificacion } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "clasificaciones-checkboxes",
    templateUrl: "clasificaciones_checkboxes.component.html",
    styleUrls: ["clasificaciones_checkboxes.component.scss"]
})
export class ClasificacionesCheckboxesComponent implements OnInit {
    @Input() name: string
    @Input() id: string
    @Input() hasHijos: boolean
    @Input() tipoInstrumento: string
    @Input() enableCantidadPreguntas: boolean
    @Input() enableObjetivoPriorizado: boolean
    @Input() checkAll: boolean = true
    @Input() showOpened: boolean = false

    _clasificaciones: Clasificacion[]
    clasificacionHijaToClasificacionPadreMap: { [clasificacionId: number]: Clasificacion } = {}

    mensajePersonalizar: string

    @Input()
    set clasificaciones(items) {
        this._clasificaciones = items
        this.checkClasificaciones()
    }
    get clasificaciones() {
        return this._clasificaciones
    }

    concats: any

    @Output() emitToParent: EventEmitter<any> = new EventEmitter<any>()

    constructor() {}

    ngOnInit() {
        this.concats = config.evaluaciones[this.tipoInstrumento].clasificaciones.concats

        this.mensajePersonalizar = config.evaluaciones[this.tipoInstrumento].mensajePersonalizar
    }

    checkClasificaciones() {
        const selectClasificacion = (c: any) => {
            c.selected = this.checkAll
            if (c.clasificaciones_hijas) {
                c.clasificaciones_hijas.forEach(selectClasificacion)
            }
        }

        this.clasificaciones.forEach(selectClasificacion)

        this.clasificacionHijaToClasificacionPadreMap = {}
        const setClasificacionToClasificacionPadre = (clasificacion: Clasificacion, clasificacionPadre) => {
            this.clasificacionHijaToClasificacionPadreMap[clasificacion.id] = clasificacionPadre
            clasificacion.clasificaciones_hijas?.forEach(c => setClasificacionToClasificacionPadre(c, clasificacion))
        }
        this.clasificaciones.forEach(c => setClasificacionToClasificacionPadre(c, null))

        this.clasificacionesEmit()
    }

    clasificacionesEmit() {
        this.emitToParent.emit()
    }

    clasificacionChange(clasificacion: Clasificacion, clasificacionPadre: Clasificacion) {
        const clasificacionSelect = (c: any) => {
            if (!c) return

            c.selected = c.clasificaciones_hijas.reduce((acc, x) => acc || (x as any).selected, false)
            clasificacionSelect(this.clasificacionHijaToClasificacionPadreMap[c.id])
        }

        const clasificacionPadreSelect = (c: any) => {
            c.clasificaciones_hijas?.forEach(ch => {
                ch.selected = c.selected

                clasificacionPadreSelect(ch)
            })
        }

        clasificacionSelect(clasificacionPadre)
        clasificacionPadreSelect(clasificacion)

        this.clasificacionesEmit()
    }
}
