import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Evaluacion } from "./evaluaciones.model"
import { Respuesta } from "./respuestas.model"
import { Usuario } from "./usuarios.model"
import { EvaluacionMultipleInstancia } from "./evaluacion_multiple_instancias.model"
import { EvaluacionInstanciaAsignatura } from "./evaluacion_instancia_asignaturas.model"
import { Retroalimentacion } from "./retroalimentaciones.model"
import { EvaluacionInstanciaEscalaGlobalAlias } from "./evaluacion_instancia_escala_global_aliases.model"

export class EvaluacionInstancia extends BaseModel {
    public static className: string = "EvaluacionInstancia"

    usuario_id: number
    evaluacion_id: number

    puntuacion: number
    correctas: number
    incorrectas: number
    omitidas: number
    calificacion: number
    tiempo_ocupado: number
    nintento: number

    oficial: boolean
    usuario_simple: string

    created_at: Date

    row: number

    asignatura_id: number
    has_forma: string

    @SaveType(() => Evaluacion) evaluacion: Evaluacion
    @SaveType(() => Respuesta) respuestas: Respuesta[]
    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => EvaluacionMultipleInstancia) evaluacion_multiple_instancia: EvaluacionMultipleInstancia
    @SaveType(() => EvaluacionInstanciaAsignatura) evaluacion_instancia_asignaturas: EvaluacionInstanciaAsignatura[]
    @SaveType(() => Retroalimentacion) retroalimentacion: Retroalimentacion
    @SaveType(() => EvaluacionInstanciaEscalaGlobalAlias)
    evaluacion_instancia_escala_global_aliases: EvaluacionInstanciaEscalaGlobalAlias[]
    evaluacion_multiple_instancia_id: number
}
