<loading-layout #loadingLayout>
    <simple-search-filter *ngIf="enableSearch" (updateSearch)="updateSearch($event)"
        (clearSearch)="updateSearch($event)" [autoclearEnabled]="false" [placeholder]="'ayudas.buscar' | t">
    </simple-search-filter>
    <div *ngIf="!loadingLayout.loading && ayudas && ayudas.length != 0">
        <div *ngFor="let catAyuda of categoriasAyudas">
            <div *ngIf="ayudasCategoriaFiltradas[catAyuda.id].length !== 0">
                <div class="linea-verde-agua">
                    <h3 class="h3-title">{{ catAyuda.categoria_ayuda }}</h3>
                </div>
                <div class="categoria-container">
                    <div *ngFor="let ayuda of ayudasCategoriaFiltradas[catAyuda.id], let i = index"
                        class="ayuda-container">
                        <ayuda-with-thumbnail *ngIf="!tracking" [ayuda]="ayuda"></ayuda-with-thumbnail>
                        <ayuda-with-thumbnail *ngIf="tracking" [tracking]="true" [ayuda]="ayuda"></ayuda-with-thumbnail>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!loadingLayout.loading && (!ayudas || (ayudas && ayudas.length == 0))">
        <p class="no-content">{{ "ayudas.no_disponible" | t }}</p>
    </div>
</loading-layout>