import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Plataforma } from "./plataformas.model"

export class PersonaExterna extends BaseModel {
    public static className: string = "PersonaExterna"
    public id: number
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public plataforma_usuario_id: number
    public plataforma_id: number
    public created_at: Date
    public updated_at: Date

    @SaveType(() => Plataforma) public plataforma: Plataforma

    public toString() {
        return this.nombre + " " + this.apellido_paterno
    }
}
