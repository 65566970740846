import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { GrupoUsuario } from "./grupo_usuarios.model"

export class GrupoUsuarioUsuario extends BaseModel {
    public usuario_id: number
    public grupo_usuario_id: number
    public propietario: boolean

    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => GrupoUsuario) grupo_usuario: GrupoUsuario

    public toString() {
        return this.id
    }
}
