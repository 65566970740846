import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "register_confirmation.component.html",
    styleUrls: ["register.scss"]
})
export class RegisterConfirmationComponent implements OnInit {
    config: typeof config = config

    token: string
    unloggedLayoutTitle = "Hazte una cuenta"

    constructor(protected titleService: TitleService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.register_confirmation")
        this.token = this.route.snapshot.params["token"]
    }
}
