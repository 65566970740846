import { Component, Input, ViewChild, EventEmitter, Output } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    CarreraSedes,
    CarreraSede,
    PreferenciaCarreraSedes,
    PreferenciaCarreraSede,
    Sede,
    Sedes,
    Universidad,
    Universidades,
    InfoAdicionales
} from "@puntaje/carreras/api-services"
import { AuthService, CapitalizePipe, CheckActividadAsync } from "@puntaje/shared/core"
import { CUITableComponent } from "@puntaje/shared/cui"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Subscription } from "rxjs"
import { CarrerasBuscadorService } from "../buscador-carreras/carreras_buscador.service"

@Component({
    selector: "carreras-table",
    templateUrl: "carreras-table.component.html",
    styleUrls: ["carreras-table.component.scss"]
})
export class CarrerasTableComponent {
    @Input() withPais = false
    @Output() seguirEvent: EventEmitter<boolean> = new EventEmitter<boolean>()
    @ViewChild(CUITableComponent) cuiTable: CUITableComponent

    capitalize = new CapitalizePipe()
    universidades: Universidad[]
    query: boolean = false
    carreraSedes: CarreraSede[]
    milisecondsTime = new Date().getTime()
    sub: Subscription
    searchParams: any = {}

    constructor(
        protected carreraSedesService: CarreraSedes,
        protected preferenciaCarreraSedesService: PreferenciaCarreraSedes,
        protected universidadesService: Universidades,
        protected authService: AuthService,
        protected carrerasBuscadorService: CarrerasBuscadorService
    ) {}

    ngOnInit() {
        this.sub = this.carrerasBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            if (this.cuiTable) {
                this.cuiTable.changePage(1)
            }
        })

        this.getAllUniversidades().then(() => {
            this.query = true
        })
    }

    getAllUniversidades() {
        const params: any = {}
        if (this.withPais) {
            params["pais"] = this.capitalize.transform(config.plataforma.pais)
        }
        if (config.registro?.universidades && config.registro.universidades.length > 0) {
            params["universidad"] = { universidad: config.registro.universidades }
        }
        return this.universidadesService.where(params).then((universidades: Universidad[]) => {
            this.universidades = universidades
        })
    }

    setData = (page: number, per: number) => {
        let params: any = {
            include: "[carrera?,preferencia_carrera_sedes?,info_adicionales?,sede:universidad]",
            preferenciaCarreraSede: {
                usuarioId: this.authService.getUserData().id
            },

            pais: {
                pais: this.capitalize.transform(config.plataforma.pais)
            },
            page,
            per,
            ...this.searchParams,
            sede: {
                universidadId: this.universidades.map(u => u.id),
                ...this.searchParams?.sede
            }
        }

        if (Object.keys(this.searchParams).length !== 0) {
            params.random = this.milisecondsTime
        }

        return Promise.all([
            this.carreraSedesService.wherePost(params),
            this.carreraSedesService.wherePostCount(params) as unknown as Promise<number>
        ]).then(([carreraSedes, total]) => {
            this.carreraSedes = carreraSedes
            return total
        })
    }

    @CheckActividadAsync("SCS")
    seguirCarreraSede(carreraSede) {
        const preferenciaCarreraSede = new PreferenciaCarreraSede()
        preferenciaCarreraSede.carreraSedeId = carreraSede.id

        return this.preferenciaCarreraSedesService.save(preferenciaCarreraSede).then(pcs => {
            carreraSede.preferenciaCarreraSedes.push(pcs)
            this.seguirEvent.emit(true)

            return pcs
        })
    }

    dejarSeguirCarreraSede(carreraSede) {
        const preferenciaCarreraSede = carreraSede.preferenciaCarreraSedes[0]

        if (config.plataforma.disableEliminarPrimeraPreferenciaCarreras && preferenciaCarreraSede.orden == 1) {
            return
        }

        this.preferenciaCarreraSedesService.remove(preferenciaCarreraSede.id).then(pcs => {
            carreraSede.preferenciaCarreraSedes = []
            this.seguirEvent.emit(true)
        })
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe()
    }
}
