import { Component, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { View } from "./view.component"

@Component({
    selector: "gameview",
    template: `
        <ng-template #viewLabel></ng-template>
        <ng-template #viewValue>
            <div #iframeContainer (window:resize)="onResize($event)" *ngIf="urlReady">
                <iframe
                    *ngIf="!options?.withoutPredefinedSize"
                    [width]="width"
                    [height]="height"
                    [src]="safeUrl"
                    (load)="onLoadIframe()"
                ></iframe>
                <iframe *ngIf="options?.withoutPredefinedSize" [src]="safeUrl" (load)="onLoadIframe()"></iframe>
            </div>
        </ng-template>
    `,
    styleUrls: ["gameview.component.scss"]
})
export class GameView extends View {
    width: number = 960
    height: number = 600
    max_width: number = 960
    max_height: number = 600
    url: string
    safeUrl: SafeResourceUrl
    urlReady: boolean = false
    @ViewChild("iframeContainer") iframeContainer: ElementRef

    constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {
        super()
    }

    ngOnInit() {
        this.url = this.value[this.key]
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
        this.urlReady = true
    }

    onResize(event) {
        this.setRatioSize()
    }

    setRatioSize() {
        if (this.iframeContainer) {
            if (this.iframeContainer.nativeElement.offsetWidth > this.max_width) {
                this.width = this.max_width
            } else {
                this.width = this.iframeContainer.nativeElement.offsetWidth
            }
        }
        this.height = (this.width * this.max_height) / this.max_width
    }

    onLoadIframe() {
        this.cdr.detectChanges()
        this.setRatioSize()
    }
}
