import { Injectable } from "@angular/core"
import { PlanPersonales } from "./plan_personales.service"
import { PlanPersonal } from "./plan_personales.model"
import { PlanPersonalContenidoGrupoUsuarios } from "./plan_personal_contenido_grupo_usuarios.service"
import { PlanPersonalSesionGrupoUsuarios } from "./plan_personal_sesion_grupo_usuarios.service"
import { GeneradorInstrumentos, Materiales } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { PlanPersonalInstancias } from "./plan_personal_instancias.service"
import { PlanPersonalInstancia } from "./plan_personal_instancias.model"
import { PlanPersonalMaterialInstancias } from "./plan_personal_material_instancias.service"
import { PlanPersonalEvaluacionInstancias } from "./plan_personal_evaluacion_instancias.service"
import { Evaluaciones } from "./evaluaciones.service"
import { PlanPersonalPredefinidos } from "./plan_personal_predefinidos.service"
import { PlanPersonalCiclos } from "./plan_personal_ciclos.service"
import { PlanPersonalSesiones } from "./plan_personal_sesiones.service"
import { PlanPersonalContenidos } from "./plan_personal_contenidos.service"

@Injectable()
export class PlanClaseService {
    planPersonal: PlanPersonal
    clasificacionContextoByMaterialId: any
    clasificacionActividadByMaterialId: any
    planPersonalInstancia: any
    planPersonalMaterialInstancias: any
    planPersonalEvaluacionInstancias: any
    thumbType: string = "archivo"

    constructor(
        protected planPersonalesService: PlanPersonales,
        protected planPersonalContenidoGrupoUsuariosService: PlanPersonalContenidoGrupoUsuarios,
        protected planPersonalSesionGrupoUsuariosService: PlanPersonalSesionGrupoUsuarios,
        protected materialesService: Materiales,
        protected authService: AuthService,
        protected planPersonalInstanciasService: PlanPersonalInstancias,
        protected planPersonalMaterialInstanciasService: PlanPersonalMaterialInstancias,
        protected planPersonalEvaluacionInstanciasService: PlanPersonalEvaluacionInstancias,
        protected evaluacionesService: Evaluaciones,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected planPersonalPredefinidosService: PlanPersonalPredefinidos,
        protected planPersonalCiclosService: PlanPersonalCiclos,
        protected planPersonalSesionesService: PlanPersonalSesiones,
        protected planPersonalContenidosService: PlanPersonalContenidos
    ) {}

    async getData(planPersonalId, grupoUsuarioId) {
        this.clasificacionContextoByMaterialId = {}
        this.clasificacionActividadByMaterialId = {}

        const usuarioId = this.authService.getUserData().id

        const params = {
            plan_personal_instancia: {
                plan_personal_id: planPersonalId,
                usuario_id: usuarioId
            }
        }

        const planPersonalInstancias = await this.planPersonalInstanciasService.where(params)
        if (planPersonalInstancias.length > 0) {
            this.planPersonalInstancia = planPersonalInstancias[0]

            const paramsMaterialInstancias = {
                plan_personal_material_instancia: {
                    plan_personal_instancia_id: this.planPersonalInstancia.id
                }
            }

            const planPersonalMaterialInstancias = await this.planPersonalMaterialInstanciasService.where(
                paramsMaterialInstancias
            )
            if (planPersonalMaterialInstancias.length > 0) {
                this.planPersonalMaterialInstancias = planPersonalMaterialInstancias
            }

            const paramsEvaluacionInstancias = {
                plan_personal_evaluacion_instancia: {
                    plan_personal_instancia_id: this.planPersonalInstancia.id
                }
            }

            const planPersonalEvaluacionInstancias = await this.planPersonalEvaluacionInstanciasService.where(
                paramsEvaluacionInstancias
            )

            if (planPersonalEvaluacionInstancias.length > 0) {
                this.planPersonalEvaluacionInstancias = planPersonalEvaluacionInstancias
            }
        }

        const planPersonal = await this.planPersonalesService.find(planPersonalId)

        const generadorInstrumento = await this.generadorInstrumentosService.find(
            planPersonal.generador_instrumento_id,
            { include: "asignatura" }
        )

        const planPersonalPredefinido = await this.planPersonalPredefinidosService.where({
            plan_personal_predefinido: { plan_personal_id: planPersonal.id }
        })[0]

        const planPersonalCiclos = await this.planPersonalCiclosService.wherePost({
            plan_personal_ciclo: { plan_personal_id: planPersonal.id }
        })

        const planPersonalCicloIds = planPersonalCiclos.map(planPersonalCiclo => planPersonalCiclo.id)

        const planPersonalSesiones = await this.planPersonalSesionesService.wherePost({
            plan_personal_sesion: { plan_personal_ciclo_id: planPersonalCicloIds }
        })

        const planPersonalContenidos = await this.planPersonalContenidosService.wherePost({
            plan_personal_contenido: {
                plan_personal_sesion_id: planPersonalSesiones.map(planPersonalSesion => planPersonalSesion.id)
            },
            render_options: {
                include: {
                    evaluacion: null,
                }
            }
        })

        const materialesFromContenidos = await this.materialesService.wherePost({
            material: {
                id: planPersonalContenidos.filter(planPersonalContenido => planPersonalContenido.tipo == 'Material').map(ppc => ppc.tipo_id)
            }
        })

        planPersonalContenidos.forEach(planPersonalContenido => {
            if (planPersonalContenido.tipo == 'Material') {
                planPersonalContenido.material = materialesFromContenidos.find(material => material.id == planPersonalContenido.tipo_id)
            }
        })

        let response
        let planPersonalSesionGrupoUsuarios = [],
            plan_personal_contenido_grupo_usuarios = []
        if (grupoUsuarioId) {
            response = await this.planPersonalesService.planClaseInit(planPersonalId, grupoUsuarioId)

            planPersonalSesionGrupoUsuarios = await this.planPersonalSesionGrupoUsuariosService.wherePost({
                plan_personal_sesion_grupo_usuario: {
                    plan_personal_sesion_id: planPersonalSesiones.map(planPersonalSesion => planPersonalSesion.id),
                    grupo_usuario_id: grupoUsuarioId
                }
            })

            plan_personal_contenido_grupo_usuarios = await this.planPersonalContenidoGrupoUsuariosService.wherePost({
                plan_personal_contenido_grupo_usuario: {
                    plan_personal_contenido_id: planPersonalContenidos.map(
                        planPersonalContenido => planPersonalContenido.id
                    ),
                    grupo_usuario_id: grupoUsuarioId
                }
            })
        }

        planPersonal.generador_instrumento = generadorInstrumento
        planPersonal.plan_personal_predefinido = planPersonalPredefinido
        planPersonal.plan_personal_ciclos = planPersonalCiclos
        planPersonal.plan_personal_ciclos.forEach(planPersonalCiclo => {
            planPersonalCiclo.plan_personal_sesiones = planPersonalSesiones.filter(
                planPersonalSesion => planPersonalSesion.plan_personal_ciclo_id === planPersonalCiclo.id
            )
            planPersonalCiclo.plan_personal_sesiones.forEach(planPersonalSesion => {
                planPersonalSesion.plan_personal_contenidos = planPersonalContenidos.filter(
                    planPersonalContenido => planPersonalContenido.plan_personal_sesion_id === planPersonalSesion.id
                )
                planPersonalSesion.plan_personal_sesion_grupo_usuarios = planPersonalSesionGrupoUsuarios.filter(
                    planPersonalSesionGrupoUsuario =>
                        planPersonalSesionGrupoUsuario.plan_personal_sesion_id === planPersonalSesion.id
                )
                planPersonalSesion.plan_personal_contenidos.forEach(planPersonalContenido => {
                    planPersonalContenido.plan_personal_contenido_grupo_usuarios =
                        plan_personal_contenido_grupo_usuarios.filter(
                            planPersonalContenidoGrupoUsuario =>
                                planPersonalContenidoGrupoUsuario.plan_personal_contenido_id ===
                                planPersonalContenido.id
                        )
                })
            })
        })

        const materialIds = planPersonal.plan_personal_ciclos
            .map(ppc =>
                ppc.plan_personal_sesiones
                    .map(pps =>
                        pps.plan_personal_contenidos.filter(ppco => ppco.tipo == "Material").map(ppco => +ppco.tipo_id)
                    )
                    .flat()
            )
            .flat()

        const materiales = await this.materialesService.wherePost({
            material: { id: materialIds },
            raw: 1,
            include: "[clasificaciones:clasificacion_tipo]",
            methods: "[material_tipo_nombre]"
        })
        const materialesByMaterialId = materiales.groupBy(material => material.id)

        const evaluacionIds = planPersonal.plan_personal_ciclos
            .map(ppc =>
                ppc.plan_personal_sesiones
                    .map(pps =>
                        pps.plan_personal_contenidos
                            .filter(ppco => ppco.tipo == "Evaluacion")
                            .map(ppco => +ppco.tipo_id)
                    )
                    .flat()
            )
            .flat()

        const evaluacionParams = {
            evaluacion: { id: evaluacionIds },
            include: "[evaluacion_tipo,instrumento,evaluacion_usuarios]"
        }

        const evaluaciones = await this.evaluacionesService.where(evaluacionParams)
        const evaluacionesByEvaluacionId = evaluaciones.groupBy(evaluacion => evaluacion.id)

        planPersonal.plan_personal_ciclos.forEach(ppc => {
            ppc.plan_personal_sesiones.forEach(pps => {
                if (grupoUsuarioId) {
                    pps.plan_personal_sesion_grupo_usuarios = pps.plan_personal_sesion_grupo_usuarios.filter(
                        ppsgu => ppsgu.grupo_usuario_id == grupoUsuarioId
                    )
                }

                pps.plan_personal_contenidos = pps.plan_personal_contenidos.sort((c1, c2) => c1.orden - c2.orden)

                pps.plan_personal_contenidos.forEach(ppco => {
                    if (materialesByMaterialId[ppco.tipo_id]) {
                        ppco.material = materialesByMaterialId[ppco.tipo_id][0]

                        this.clasificacionActividadByMaterialId[ppco.material.id] = ppco.material.clasificaciones.find(
                            c => c.clasificacion_tipo.clasificacion_tipo == "tipo actividad"
                        )
                        this.clasificacionContextoByMaterialId[ppco.material.id] = ppco.material.clasificaciones.find(
                            c => c.clasificacion_tipo.clasificacion_tipo == "contexto"
                        )
                    } else if (evaluacionesByEvaluacionId[ppco.tipo_id] && ppco.tipo == "Evaluacion") {
                        ppco.evaluacion = evaluacionesByEvaluacionId[ppco.tipo_id][0]
                    }

                    if (grupoUsuarioId) {
                        ppco.plan_personal_contenido_grupo_usuarios =
                            ppco.plan_personal_contenido_grupo_usuarios.filter(
                                ppcogu => ppcogu.grupo_usuario_id == grupoUsuarioId
                            )
                    }
                })
            })
        })

        this.planPersonal = planPersonal
    }

    toggleMarcado(planPersonalContenidoGrupoUsuario) {
        const oldMarcado = planPersonalContenidoGrupoUsuario.marcado

        planPersonalContenidoGrupoUsuario.marcado = !planPersonalContenidoGrupoUsuario.marcado

        this.planPersonalContenidoGrupoUsuariosService.enableIgnoreCatch()
        this.planPersonalContenidoGrupoUsuariosService
            .update(planPersonalContenidoGrupoUsuario.id, planPersonalContenidoGrupoUsuario)
            .then(ppcgu => {
                // se guardo bien
            })
            .catch(res => {
                // volver al anterior si no se guardo bien
                planPersonalContenidoGrupoUsuario.marcado = oldMarcado
            })
    }

    toggleVisto(planPersonalSesionGrupoUsuario) {
        const oldVisto = planPersonalSesionGrupoUsuario.visto

        planPersonalSesionGrupoUsuario.visto = !planPersonalSesionGrupoUsuario.visto

        this.planPersonalSesionGrupoUsuariosService.enableIgnoreCatch()
        return this.planPersonalSesionGrupoUsuariosService
            .update(planPersonalSesionGrupoUsuario.id, planPersonalSesionGrupoUsuario)
            .then(ppcgu => {
                return ppcgu
                // se guardo bien
            })
            .catch(res => {
                // volver al anterior si no se guardo bien
                planPersonalSesionGrupoUsuario.visto = oldVisto
                return res
            })
    }

    toggleActual(planPersonalSesionGrupoUsuario) {
        const planPersonalSesionActual = this.planPersonal.plan_personal_ciclos[0].plan_personal_sesiones.find(
            pps =>
                pps.plan_personal_sesion_grupo_usuarios[0].actual &&
                pps.plan_personal_sesion_grupo_usuarios[0].id != planPersonalSesionGrupoUsuario.id
        )
        const planPersonalSesionGrupoUsuarioActual = planPersonalSesionActual
            ? planPersonalSesionActual.plan_personal_sesion_grupo_usuarios[0]
            : undefined
        const oldActual = planPersonalSesionGrupoUsuario.actual

        planPersonalSesionGrupoUsuario.actual = !planPersonalSesionGrupoUsuario.actual

        let oldSesionActual
        if (planPersonalSesionGrupoUsuarioActual) {
            oldSesionActual = planPersonalSesionGrupoUsuarioActual.actual
            planPersonalSesionGrupoUsuarioActual.actual = !planPersonalSesionGrupoUsuarioActual.actual
        }

        this.planPersonalSesionGrupoUsuariosService.enableIgnoreCatch()
        this.planPersonalSesionGrupoUsuariosService
            .toggleActual(planPersonalSesionGrupoUsuario.id)
            .then(res => {
                // se toggleo bien
            })
            .catch(res => {
                // volver al anterior si no se toggleo
                planPersonalSesionGrupoUsuario.actual = oldActual
                if (planPersonalSesionGrupoUsuarioActual) {
                    planPersonalSesionGrupoUsuarioActual.actual = oldSesionActual
                }
            })
    }

    alumnoVisto(planPersonalContenido) {
        let planPersonalContenidoInstancias = []

        if (this.planPersonalMaterialInstancias) {
            planPersonalContenidoInstancias = planPersonalContenidoInstancias.concat(
                this.planPersonalMaterialInstancias
            )
        }

        if (this.planPersonalEvaluacionInstancias) {
            planPersonalContenidoInstancias = planPersonalContenidoInstancias.concat(
                this.planPersonalEvaluacionInstancias
            )
        }

        return (
            planPersonalContenidoInstancias.filter(ppci => ppci.plan_personal_contenido_id == planPersonalContenido.id)
                .length > 0
        )
    }

    getYoutubeVideoId(url) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
        var match = url.match(regExp)
        return match && match[2].length == 11 ? match[2] : false
    }

    getTipoMaterial(planPersonalContenido) {
        if (
            !planPersonalContenido.material ||
            planPersonalContenido.material.material_tipo_nombre == "Interactivo" ||
            planPersonalContenido.material.material_tipo_nombre == "HTML" ||
            planPersonalContenido.material.material_tipo_nombre == "Audio"
        ) {
            return "otros"
        }

        if (
            planPersonalContenido.material.material_tipo_nombre == "Archivo" ||
            planPersonalContenido.material.material_tipo_nombre == "Instrumento"
        ) {
            return "pdf"
        }

        if (
            planPersonalContenido.material.material_tipo_nombre == "Video" ||
            planPersonalContenido.material.material_tipo_nombre == "Streaming"
        ) {
            return "video"
        }

        return null
    }

    thumbnailsContenido(planPersonalContenido) {
        if (planPersonalContenido.material.thumbnail) {
            return planPersonalContenido.material.thumbnail
        }
        if (
            planPersonalContenido.material.material_tipo_nombre == "Archivo" ||
            planPersonalContenido.material.material_tipo_nombre == "Instrumento"
        ) {
            let nuevaUrl = planPersonalContenido.material.url
                .replace("archivo", "thumbnails")
                .replace(".pdf", "-thumbnail.png")
            return nuevaUrl
        }
        if (
            planPersonalContenido.material.material_tipo_nombre == "Video" ||
            planPersonalContenido.material.material_tipo_nombre == "Streaming"
        ) {
            return (
                "https://img.youtube.com/vi/" +
                this.getYoutubeVideoId(planPersonalContenido.material.url) +
                "/mqdefault.jpg"
            )
        }
    }
}
