import { animate, animateChild, query, stagger, style, transition, trigger } from "@angular/animations"
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import { FilterListComponent, PaginatorComponent } from "@puntaje/shared/core"
import { EvaluacionTipos, Notificacion, Notificaciones, EvaluacionTipo } from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"
import { NotificacionesPendingService } from "./notificaciones_pending.service"
import { NotificacionAccionesService } from "./notificacion_acciones.service"

@Component({
    selector: "notificaciones",
    templateUrl: "notificaciones.component.html",
    styleUrls: ["notificaciones.component.scss"],
    animations: [
        trigger("notificacion", [
            transition(":enter", [
                style({ transform: "translateX(-50px)", opacity: 0 }), // initial
                animate("0.3s ease"),
                style({ transform: "translateX(0px)", opacity: 1 }) // final
            ])
        ]),
        trigger("listaNotificaciones", [
            transition(":enter", [query("@notificacion", stagger(200, animateChild()), { optional: true })])
        ])
    ]
})
export class NotificacionesComponent implements OnInit, OnDestroy {
    notificaciones: Notificacion[]
    @Input() pendientes: boolean = false
    @Input() maximoNotificaciones: number = 5
    @Input() enableMaximo: boolean = false
    @Input() enableMini: boolean = false
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    @ViewChild(FilterListComponent) filtroComponent: FilterListComponent

    tipoDuda: string[] = ["duda", "respuesta_duda", "respuesta_correcta_duda"]
    tipoPlanPersonalReforzamiento: string[] = ["reforzamiento"]
    tipoPlanPersonalTarea: string[] = ["tarea"]
    tipoPlanPersonalPlanificacion: string[] = ["planificacion"]
    tipoProActivado: string[] = ["pro_activacion"]
    tipoRetroalimentacion: string[] = ["retroalimentacion"]
    tipoPlanPersonalSesionRecordatorio: string[] = ["plan_personal_sesion_recordatorio"]
    tipoPlanClase: string[] = ["plan de clase profesor"]
    tipoEvaluacion: string[]
    tipoNotificaciones: any

    showFiltro: boolean = false
    filtroValues: string[]
    filtroLabels: string[]
    filtrosSelected: string[] = []

    checkedSubscription: Subscription

    constructor(
        protected notificacionAccionesService: NotificacionAccionesService,
        protected notificacionesService: Notificaciones,
        protected notificacionesPendingService: NotificacionesPendingService,
        protected evaluacionTiposService: EvaluacionTipos,
        protected i18nService: I18nService
    ) {
        const notificacionTipos: any[] = config.plataforma.tiposNotificacion || []
        this.showFiltro = notificacionTipos.length > 0
        this.filtroValues = notificacionTipos.map(e => e.tipo)
        this.filtroLabels = notificacionTipos.map(e => e.label)
    }

    async ngOnInit() {
        this.tipoEvaluacion = await this.getEvaluacionTipos()
        this.notificacionesPendingService.update()
        this.showFiltro = this.showFiltro && !this.enableMini

        if (this.pendientes) {
            this.checkedSubscription = this.notificacionAccionesService.checked.subscribe(id => {
                this.markNotificationAsViewed(id)
            })
        }
        if (this.enableMaximo) this.showAll(1, this.maximoNotificaciones)
    }

    getEvaluacionTipos() {
        return this.evaluacionTiposService.where().then((evaluacionTipos: EvaluacionTipo[]) => {
            return evaluacionTipos.map(et => et.evaluacion_tipo)
        })
    }

    processNotificaciones(notificaciones: Notificacion[]) {
        this.tipoNotificaciones = {}
        notificaciones.forEach(n => {
            const nt = n.notificacion_tipo.notificacion_tipo
            if (this.tipoEvaluacion.includes(nt)) {
                this.tipoNotificaciones[n.id] = "evaluacion"
                return
            }
            if (this.tipoDuda.includes(nt)) {
                this.tipoNotificaciones[n.id] = "duda"
                return
            }
            if (this.tipoPlanPersonalReforzamiento.includes(nt)) {
                this.tipoNotificaciones[n.id] = "reforzamiento"
                return
            }
            if (this.tipoPlanPersonalTarea.includes(nt)) {
                this.tipoNotificaciones[n.id] = "tarea"
                return
            }
            if (this.tipoPlanPersonalPlanificacion.includes(nt)) {
                this.tipoNotificaciones[n.id] = "planificacion"
                return
            }
            if (this.tipoProActivado.includes(nt)) {
                this.tipoNotificaciones[n.id] = "pro"
                return
            }
            if (this.tipoRetroalimentacion.includes(nt)) {
                this.tipoNotificaciones[n.id] = "retroalimentacion"
                return
            }
            if (this.tipoPlanPersonalSesionRecordatorio.includes(nt)) {
                this.tipoNotificaciones[n.id] = "plan_personal_sesion_recordatorio"
                return
            }
            if (this.tipoPlanClase.includes(nt)) {
                this.tipoNotificaciones[n.id] = "plan de clase profesor"
                return
            }
        })
    }

    ngOnDestroy() {
        if (this.pendientes && this.checkedSubscription) {
            this.checkedSubscription.unsubscribe()
        }
    }

    showAll = (page: number, per: number) => {
        this.loadingLayout.standby()

        if (this.filtroComponent) this.filtroComponent.disableOptions()

        const params: any = {
            page: page,
            per: per
        }
        if (this.pendientes) {
            params.pendientes = 1
        }
        if (this.filtrosSelected && this.filtrosSelected.length > 0) {
            params.notificacion_tipo = { notificacion_tipo: this.filtrosSelected }
        }

        return this.notificacionesService.where(params).then((notificaciones: Notificacion[], total: number) => {
            this.notificaciones = notificaciones
            this.processNotificaciones(notificaciones)
            this.loadingLayout.ready()
            if (this.filtroComponent) this.filtroComponent.enableOptions()

            return total
        })
    }

    markNotificationAsViewed(notificationId: number) {
        this.notificaciones = this.notificaciones.filter(notificacion => notificacion.id != notificationId)
        this.loadMorePendingIfAvailable()
    }

    loadMorePendingIfAvailable() {
        if (this.pendientes) {
            if (this.paginator) {
                this.paginator.reload()
            } else if (this.enableMaximo) this.showAll(1, this.maximoNotificaciones)
        }
    }

    notificationId = (index, content) => {
        return content ? content.id : undefined
    }

    onFilterChange(values: string[]) {
        if (!this.loadingLayout.loading) {
            this.filtrosSelected = values
            this.paginator.reload()
        }
    }
}
