import { Component, OnDestroy, OnInit, Type } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { RegistroUsuarioBaseComponent } from "@puntaje/puntaje/new-modules/usuarios"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "registro",
    templateUrl: "./registro.component.html"
})
export class RegistroComponent implements OnInit, OnDestroy {
    tituloDeslogueado = "Hazte una cuenta"
    subData: Subscription

    component: Type<any> = RegistroUsuarioBaseComponent
    inputs: any = {
        formParamsRequired: [],
        showInputColegioEgresado: false,
        enableFechaNacimiento: false
    }
    outputs: any = {
        onTitleChange: this.onTitleChange
    }

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.registro.registro")
        this.subData = this.route.data.subscribe(data => {
            this.component = data.component ?? this.component
            this.inputs.formParamsRequired = data.formParamsRequired ?? this.inputs.formParamsRequired
            this.inputs.showInputColegioEgresado = data.showInputColegioEgresado ?? this.inputs.showInputColegioEgresado
            this.inputs.enableFechaNacimiento = data.enableFechaNacimiento ?? this.inputs.enableFechaNacimiento
        })
    }

    onTitleChange(titulo) {
        this.tituloDeslogueado = titulo
    }

    ngOnDestroy() {
        this.subData?.unsubscribe()
    }
}
