import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Material } from "@puntaje/nebulosa/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "material_apps.component.html"
})
export class MaterialAppsComponent {
    material: Material

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.materiales.material_app")
    }

    onReady(material) {
        this.material = material
    }
}
