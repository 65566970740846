import { Component } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"

/*#################################

Template simple para todas las páginas.

#################################*/

@Component({
    templateUrl: "main_layout.component.html",
    selector: "main-layout",
    styleUrls: ["main_layout.scss"]
})
export class MainLayoutComponent {
    constructor(public authService: AuthService) {}

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }
}
