import { Component, Input, ViewEncapsulation } from "@angular/core"
import { I18nService } from "../i18n/i18n.service"

@Component({
    selector: "password-strength-bar",
    template: `
        <ng9-password-strength-bar
            [passwordToCheck]="passwordToCheck"
            [barColors]="myColors"
            [barLabel]="barLabel"
            [strengthLabels]="strengthLabels"
        ></ng9-password-strength-bar>
    `,
    styleUrls: ["password-strength-bar.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class PasswordStrengthBarComponent {
    @Input() passwordToCheck: any

    constructor(protected i18nService: I18nService) {}

    public barLabel = this.i18nService.translate("landing.reiniciar_password.fortaleza_password")
    public myColors = ["#ea6767", "#FF6D00", "#e6b74c", "#9ec74d", "#70a704"]
    public strengthLabels = [
        this.i18nService.translate("landing.reiniciar_password.muy_debil"),
        this.i18nService.translate("landing.reiniciar_password.debil"),
        this.i18nService.translate("landing.reiniciar_password.moderada"),
        this.i18nService.translate("landing.reiniciar_password.segura"),
        this.i18nService.translate("landing.reiniciar_password.muy_segura")
    ]

    ngOnInit() {}
}
