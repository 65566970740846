import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core"
import { Notificacion } from "@puntaje/puntaje/api-services"
import { NotificacionAccionesService } from "../notificacion_acciones.service"
import { Subscription } from "rxjs"

@Component({
    selector: "notificacion-plan-personal-planificacion",
    templateUrl: "notificacion_plan_personal_planificacion.component.html",
    styleUrls: ["notificacion.component.scss"]
})
export class NotificacionPlanPersonalPlanificacionComponent implements OnInit {
    @Input() notificacion: Notificacion
    @Input() enableMini: boolean = false
    @Input("disableMarkButton") disableMarkButton: boolean = false
    @Output() markAsNotPending: EventEmitter<any> = new EventEmitter<any>()
    checkedSubscription: Subscription
    notificacionDetails: any
    remitenteLink: string
    realizarLink: string

    constructor(protected notificacionAccionesService: NotificacionAccionesService) {}

    ngOnInit() {
        this.notificacionDetails = JSON.parse(this.notificacion.notificacion)
        this.remitenteLink = "/usuarios/" + this.notificacionDetails.usuario.id
        this.realizarLink =
            "/plan_personal/" +
            this.notificacionDetails.plan_personal_id +
            "/ciclo/" +
            this.notificacionDetails.ciclo +
            "/sesiones/" +
            this.notificacionDetails.plan_personal_sesion.id
    }
}
