<div class="grupo" *ngIf="grupoPregunta" [class.with-padding]="enableGrupoPreguntaId">
    <div *ngIf="enableGrupoPreguntaId" class="gp-id print-hide">#{{ grupoPregunta.id }}</div>
    <div
        role="region"
        aria-label="Herramienta para Marcar y Desmarcar textos"
        class="btn-container"
        *ngIf="enableTextHighlight"
    >
        <cui-button
            class="btn_style"
            [class.active]="!eraseMode"
            [attr.aria-current]="!eraseMode"
            (click)="enableHighlightMode()"
        >
            <fa [attr.aria-hidden]="true" name="pencil"></fa>
            Marcar
        </cui-button>
        <cui-button
            class="btn_style"
            [class.active]="eraseMode"
            [attr.aria-current]="eraseMode"
            (click)="enableEraseMode()"
        >
            <fa [attr.aria-hidden]="true" name="eraser"></fa>
            Desmarcar
        </cui-button>
    </div>
    <text-highlight
        *ngIf="enableTextHighlight"
        [eraseMode]="eraseMode"
        (textHighlightEvent)="updateGrupoPreguntaItem($event)"
    >
        <div
            #grupoPreguntaTexto
            [grupoPreguntaTexto]="grupoPregunta.texto | clearLatex | katex"
            [grupoPreguntaId]="grupoPregunta.id"
            [numeroPrimeraPregunta]="grupoPreguntaItem?.numeroPreguntaInicial"
            [numeroUltimaPregunta]="grupoPreguntaItem?.numeroPreguntaFinal"
        ></div>
    </text-highlight>
    <!-- refactorizar este componente porfa -->
    <div
        class="grupo-pregunta"
        #grupoPreguntaTexto
        *ngIf="!enableTextHighlight && grupoPreguntaItem"
        [grupoPreguntaTexto]="grupoPregunta.texto | clearLatex | katex"
        [grupoPreguntaId]="grupoPregunta.id"
        [numeroPrimeraPregunta]="grupoPreguntaItem?.numeroPreguntaInicial"
        [numeroUltimaPregunta]="grupoPreguntaItem?.numeroPreguntaFinal"
        role="region"
        attr.aria-label="Los ejercicios del {{
            grupoPreguntaItem?.numeroPreguntaInicial == 0
                ? grupoPreguntaItem?.numeroPreguntaInicial + 1
                : grupoPreguntaItem?.numeroPreguntaInicial
        }} al {{ grupoPreguntaItem?.numeroPreguntaFinal }} se basan en la siguiente lectura"
    ></div>
    <div
        class="grupo-pregunta"
        #grupoPreguntaTexto
        *ngIf="!enableTextHighlight && !grupoPreguntaItem"
        [grupoPreguntaTexto]="grupoPregunta.texto | clearLatex | katex"
        [grupoPreguntaId]="grupoPregunta.id"
        [numeroPrimeraPregunta]="0"
        [numeroUltimaPregunta]="0"
        role="region"
    ></div>
</div>
