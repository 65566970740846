<div class="instrumento" *ngIf="!loading && locked != null" [class.locked]="locked">
    <span role="heading" aria-level="3" class="evaluacion">
        <ogr-icon name="prueba" *ngIf="!locked" class="icono"></ogr-icon>
        <fa name="lock" *ngIf="locked" class="lock"></fa>
        {{ evaluacionMultiple.evaluacion_multiple }}
    </span>
    <div class="ultimo-puntaje">
        <ng-container *ngIf="evaluacionMultiple">
            <ul class="resultados" *ngIf="asignaturasByEvaluacionTipo$ | async as asignaturasByEvaluacionTipo">
                <li
                    *ngFor="
                        let asignatura of asignaturasByEvaluacionTipo[
                            evaluacionMultiple.evaluacion_tipo.evaluacion_tipo
                        ]
                    "
                    class="{{ asignatura.clase }}"
                >
                    <ogr-icon [name]="asignatura.icono" class="icono"></ogr-icon>
                    <span class="asignatura">
                        {{ asignatura.asignatura }}
                    </span>
                    <span class="realizar" *ngIf="plataforma !== 'Sanisidoro'">
                        <a
                            [routerLink]="['/evaluaciones', evaluacionByAsignaturaId[asignatura.id].id, 'realizar']"
                            [queryParams]="{ without_multiple: 1 }"
                        >
                            {{ evaluacionInstanciaByAsignaturaId[asignatura.id] ? "Volver a realizar" : "Realizar" }}
                        </a>
                    </span>
                    <span class="resultado" *ngIf="evaluacionInstanciaByAsignaturaId[asignatura.id]">
                        <div *ngIf="plataforma !== 'Sanisidoro'; else sanIsidoro">
                            <a
                                [routerLink]="[
                                    '/ensayo_instancias',
                                    evaluacionInstanciaByAsignaturaId[asignatura.id].id
                                ]"
                            >
                                {{ porcentajePorAsignatura[asignatura.id] | percentage: 0 }}
                            </a>
                        </div>
                        <ng-template #sanIsidoro>
                            <a
                                [routerLink]="[
                                    '/ensayo_instancias',
                                    evaluacionInstanciaByAsignaturaId[asignatura.id].id
                                ]"
                            >
                                {{ porcentajePorAsignatura[asignatura.id] }}
                            </a>
                        </ng-template>
                    </span>
                </li>
            </ul>
        </ng-container>
    </div>
    <div class="navegacion">
        <a
            class="ver opciones-link-realizar"
            *ngIf="evaluacionMultipleDone"
            [routerLink]="['/evaluacion_multiple_instancias', evaluacionMultipleInstancia.id]"
            [attr.aria-disabled]="locked"
        >
            <span class="comenzar">
                Ver&nbsp;
                <fa name="chevron-right"></fa>
            </span>
        </a>
        <a
            class="opciones-link-realizar"
            id="link-realizar-completa-{{ evaluacionMultiple.id }}"
            [routerLink]="['/evaluaciones/' + evaluacionMultiple.id + '/realizar_multiple']"
            [queryParams]="{ from_start: 1 }"
            [attr.aria-disabled]="locked"
            [attr.aria-describedby]="'evaluacion_tooltip-' + evaluacionMultiple.id"
        >
            <span *ngIf="evaluacionMultipleInstancia" class="comenzar">
                Volver a realizar completa&nbsp;
                <fa name="chevron-right"></fa>
            </span>

            <span *ngIf="!evaluacionMultipleInstancia" class="comenzar">
                Realizar completa &nbsp;
                <fa name="chevron-right"></fa>
            </span>
        </a>
    </div>
</div>
