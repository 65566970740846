import { Component, Injector, ComponentFactoryResolver } from "@angular/core"
import { ModelViewComponent } from "./model_view.component"

/*#################################

Vista simple para componentes.
Requiere que esté definido un show_params en el modelo. Recibe show_params como params, el objeto como value, y el valor actual a evaluar
del objeto como key (por ejemplo, objeto asignatura, con key de fecha_creación, que dentro de show_params es type "date").
Dentro de show_params:
Type: puede ser text, date, link. Si es link, se requiere parámetros adicionales para saber hacia donde se debe linkear.
Label: el texto del label.

#################################*/

@Component({
    template: `
        <model-simple-view-layout #layoutComponent>
            <div #content></div>
        </model-simple-view-layout>
    `,
    styleUrls: ["model_simple_view.component.scss"],
    selector: "model-simple-view"
})
export class ModelSimpleViewComponent extends ModelViewComponent {
    constructor(injector: Injector, resolver: ComponentFactoryResolver) {
        super(injector, resolver)
    }
}
