// tslint:disable:variable-name
import { BaseModel, SaveType } from "@puntaje/shared/core"
import { EvaluacionMultiple } from "./evaluacion_multiples.model"
import { Evaluacion } from "./evaluaciones.model"

export class EvaluacionMultipleEvaluacion extends BaseModel {
    evaluacion_multiple_id: number
    evaluacion_id: number

    created_at: string
    updated_at: string

    @SaveType(() => EvaluacionMultiple) evaluacion_multiple: EvaluacionMultiple
    @SaveType(() => Evaluacion) evaluacion: Evaluacion
}
