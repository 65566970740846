import { Component } from "@angular/core"
import { View } from "./view.component"

@Component({
    selector: "textview",
    template: `
        <ng-template #viewLabel>{{ label }}:</ng-template>
        <ng-template #viewValue>
            <ng-container *ngIf="options && options.showFn">
                {{ value ? options.showFn(value) : "" }}
            </ng-container>
            <ng-container *ngIf="!options || !options.showFn">
                {{ value ? value[key] : "" }}
            </ng-container>
        </ng-template>
    `
})
export class TextView extends View {}
