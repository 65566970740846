<ng-container *ngIf="!saved">
    <form novalidate [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <h3>Datos Personales</h3>
                <form-input [form]="form" [params]="params['id']" [(value)]="usuario['id']" [key]="'id'"></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['usuario_id']"
                    [(value)]="usuario['usuario_' + pais]['usuario_id']"
                    [key]="'usuario_id'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['ti']"
                    [(value)]="usuario['usuario_' + pais]['ti']"
                    [key]="'ti'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['cc']"
                    [(value)]="usuario['usuario_' + pais]['cc']"
                    [key]="'cc'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['telefono']"
                    [(value)]="usuario['usuario_' + pais]['telefono']"
                    [key]="'telefono'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['lugar']"
                    [(value)]="usuario['lugar']"
                    [key]="'lugar'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['fecha_nacimiento']"
                    [(value)]="usuario['fecha_nacimiento']"
                    [key]="'fecha_nacimiento'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['genero']"
                    [(value)]="usuario['genero']"
                    [key]="'genero'"
                ></form-input>
            </div>

            <div class="col-md-6">
                <h3>Datos Académicos</h3>
                <form-input
                    [form]="form"
                    [params]="params['egresado']"
                    [(value)]="usuario['egresado']"
                    [key]="'egresado'"
                ></form-input>

                <label [attr.for]="'establecimiento'">{{ params["establecimiento"].label }}</label>
                <autocomplete
                    [(ngModel)]="usuario['establecimiento']"
                    [formControlName]="'establecimiento'"
                    [options]="filteredEstablecimientos"
                    (filterChange)="getEstablecimientos($event)"
                    showTextFun="toStringWithIdPais"
                ></autocomplete>

                <form-input
                    [form]="form"
                    [params]="params['nivel_id']"
                    [(value)]="usuario['nivel_id']"
                    [key]="'nivel_id'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuario['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                ></form-input>

                <button (click)="save()" type="button" class="btn btn-default btn-block submit-btn">Actualizar</button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
    minutos más, por favor revisa tu carpeta de spam.
</ng-container>
