import { Component, Input, ViewChild, ElementRef, Output, EventEmitter, SimpleChanges } from "@angular/core"
import { AuthService, I18nService } from "@puntaje/shared/core"
import { ActivatedRoute, Router } from "@angular/router"
import { PlanPersonal, PlanPersonalSesion, PlanPersonalContenido } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "plan-personal-navbar",
    templateUrl: "plan_personal_navbar.component.html",
    styleUrls: ["plan_personal_navbar.component.scss"]
})
export class PlanPersonalNavbarComponent {
    @Input() nombrePlan: string
    @Input() nombreAsignatura: string
    @Input() enableNext: boolean
    @Input() enablePrevious: boolean
    @Input() planPersonalSesiones: PlanPersonalSesion[]
    @Input() PlanPersonalContenido: PlanPersonalContenido[]
    @Input() orden: number
    @Input() ciclo: number = 0
    @Input() planesPersonalesRoute: string
    @Input() enableNavigateToPlan: boolean = false
    asignaturaId: number
    asignatura: AsignaturaWithConfig
    buttonPrevName: string = "anterior"
    buttonNextName: string = "siguiente"
    _prevElementType: string = "contenido"
    _nextElementType: string = "contenido"
    @Input() evaluacionTipo: string = config.plataforma.evaluacionDefault
    nextSesion: PlanPersonalSesion
    previousSesion: PlanPersonalSesion
    currentSesion: PlanPersonalSesion
    @Output() onGoToNext: EventEmitter<any> = new EventEmitter<any>()
    @Output() onGoToPrevious: EventEmitter<any> = new EventEmitter<any>()
    _planPersonal: PlanPersonal
    @Input()
    set planPersonal(planPersonal: any) {
        this._planPersonal = planPersonal
        if (this._planPersonal) {
            this.asignaturaId = this._planPersonal.generador_instrumento.asignatura.id
        }
    }
    get planPersonal() {
        return this._planPersonal
    }
    _activeComponent: number = 0
    @Input()
    set activeComponent(activeComponent: any) {
        this._activeComponent = activeComponent
    }
    get activeComponent() {
        return this._planPersonal
    }
    @Input()
    set prevElementType(prevElementType: any) {
        this._prevElementType = prevElementType
        this.setButtonPrevName()
    }
    get prevElementType() {
        return this._prevElementType
    }
    @Input()
    set nextElementType(nextElementType: any) {
        this._nextElementType = nextElementType
        this.setButtonNextName()
    }
    get nextElementType() {
        return this._nextElementType
    }

    constructor(private authService: AuthService, protected router: Router, protected i18nService: I18nService) {}

    goToPrevious() {
        this.onGoToPrevious.emit()
    }

    goToNext() {
        this.onGoToNext.emit()
    }

    setButtonPrevName() {
        if (this.prevElementType === "plan") this.buttonPrevName = "volver_plan"
        else this.buttonPrevName = "anterior"
    }

    setButtonNextName() {
        if (this.nextElementType === "plan") this.buttonNextName = "volver_plan"
        else this.buttonNextName = "siguiente"
    }
}
