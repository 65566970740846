import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ToolNavbar } from "./toolnavbar"

@NgModule({
    declarations: [ToolNavbar],
    imports: [CommonModule],
    exports: [ToolNavbar]
})
export class ToolNavbarModule {}
