<div class="solucion-container">
    <cui-button
        class="btn_style pull-right btn-sol print-hide"
        type="button"
        toggleExpandable="preg_solucion_{{ pregunta.id }}"
    >
        <ogr-icon name="resultados"></ogr-icon>
        <span class="ver">Ver</span>
        <span class="ocultar">Ocultar</span>
        &nbsp;Solución
    </cui-button>
    <div id="preg_solucion_{{ pregunta.id }}" class="print-force-expand" expandableContent>
        <h4 class="subtitulo titulo-solucion">
            <div class="icon-solucion print-hide">
                <ogr-icon name="resultados"></ogr-icon>
            </div>
            Solución
        </h4>
        <div class="solucion" *ngIf="pregunta.solucion">
            <div
                class="solucion_contenido"
                [class.solucion-show]="showSolucion"
                [innerHTML]="pregunta.solucion | clearLatex | katex | trustedhtml"
            ></div>
            <model-plain-value
                *ngIf="pregunta.solucion_video"
                [params]="videoParams"
                [value]="pregunta"
                key="solucion_video"
            ></model-plain-value>
        </div>
        <div class="sin-solucion print-hide" *ngIf="!pregunta.solucion">
            <p>Solución aún no disponible</p>
        </div>
    </div>
</div>
