<div *ngIf="showButton && instruccionfija">
    <cui-button (click)="openModalInstruccion()" class="btn-sm btn_style button-open-modal">
        {{ "instruncciones_fijas.instruccion" | t }}
        <fa name="info-circle"></fa>
    </cui-button>
    <cui-button (click)="openModalInstruccion()" class="btn_style button-open-modal-hidden">
        <fa name="info-circle"></fa>
    </cui-button>
</div>

<ng-container *ngIf="openModal">
    <generic-modal [buttonClicked]="openModal">
        <modal-titulo>{{ "instruncciones_fijas.instruccion" | t }}</modal-titulo>
        <modal-contenido>
            <div *ngIf="instruccionfija">
                <div [innerHTML]="instruccionfija.instruccion"></div>
            </div>
        </modal-contenido>
    </generic-modal>
</ng-container>
