<div class="contenido-placeholder">
    <ogr-icon [name]="'tecnicos'" class="placeholder-icon"></ogr-icon>
    <div class="inner-text">
        <h2>¡Ups...!</h2>
        <p>Esta sección aún no está lista o se encuentra en mantención.</p>
        <p>
            Por favor vuelve más tarde.
            <fa [name]="'smile-o'"></fa>
        </p>
    </div>
</div>
