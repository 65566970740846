import { Component, Input } from "@angular/core"

@Component({
    selector: "[ribbon-flag]",
    templateUrl: "ribbon_flag.component.html",
    styleUrls: ["ribbon_flag.component.scss"]
})
export class RibbonFlagComponent {
    constructor() {}
}
