import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from "@angular/core"
import { Location } from "@angular/common"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"

import { Ayuda, Ayudas } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PdfView, YoutubeVideoView, GameView, HtmlView } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "ayuda",
    templateUrl: "ayuda.component.html",
    styleUrls: ["ayuda.component.scss"]
})
export class AyudaComponent implements OnInit, OnDestroy {
    @Input() ayuda: Ayuda
    @Input() hideTitle: boolean = false
    @Input() showVolver: boolean = true
    params = Ayuda.show_params
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    isVideo: boolean = false
    isArchivo: boolean = false
    isPdf: boolean = false
    isGame: boolean = false
    isHTML: boolean = false
    showYoutubeButton: boolean =
        config.app.baseStyle?.ayuda?.showYoutubeButton !== undefined
            ? config.app.baseStyle.ayuda.showYoutubeButton
            : true
    key: string = "url"
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()

    @Input() id: number

    constructor(
        protected ayudasService: Ayudas,
        protected router: Router,
        protected route: ActivatedRoute,
        protected location: Location
    ) {}

    parche() {
        if (this.ayuda.material_tipo_id == 1) {
            this.ayuda.material_tipo_nombre = "Archivo"
        }
        if (this.ayuda.material_tipo_id == 2) {
            this.ayuda.material_tipo_nombre = "Video"
        }
        if (this.ayuda.material_tipo_id == 3) {
            this.ayuda.material_tipo_nombre = "Streaming"
        }
        if (this.ayuda.material_tipo_id == 4) {
            this.ayuda.material_tipo_nombre = "HTML"
        }
    }

    ngOnInit() {
        //Material viene incluido como input
        if (this.ayuda) {
            this.isVideo = this.ayuda.material_tipo_nombre == "Video" || this.ayuda.material_tipo_nombre == "Streaming"
            this.isArchivo = this.ayuda.material_tipo_nombre == "Archivo"
            this.isPdf = this.ayuda.material_tipo_nombre == "Archivo" && this.ayuda.getExtension() == "pdf"
            this.isGame = this.ayuda.material_tipo_nombre == "Juego"
            this.isHTML = this.ayuda.material_tipo_nombre == "HTML"
            this.loadingLayout.ready()
        } else {
            //Funcionamiento normal, material se consigue a partir de la url
            this.sub = this.route.params.subscribe(params => {
                this.ayudasService.find(+params["id"]).then((response: Ayuda) => {
                    this.ayuda = response
                    this.isVideo =
                        this.ayuda.material_tipo_nombre == "Video" || this.ayuda.material_tipo_nombre == "Streaming"
                    this.isArchivo = this.ayuda.material_tipo_nombre == "Archivo"
                    this.isPdf = this.ayuda.material_tipo_nombre == "Archivo" && this.ayuda.getExtension() == "pdf"
                    this.isGame = this.ayuda.material_tipo_nombre == "Juego"
                    this.isHTML = this.ayuda.material_tipo_nombre == "HTML"
                    this.onReady.emit(this.ayuda)
                    this.loadingLayout.ready()
                })
            })
        }
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe()
        }
    }

    getDownloadLink = (m: Ayuda) => {
        return m.url
    }

    isFileLink = (m: Ayuda) => {
        return m.isFile()
    }

    printElem(m: Ayuda) {
        var mywindow = window.open("", "PRINT", "height=600,width=900")

        mywindow.document.write("<html><head><title></title>")
        mywindow.document.write("</head><body >")
        mywindow.document.write("<h1></h1>")
        mywindow.document.write(document.getElementById("material" + m.id).innerHTML)
        mywindow.document.write("</body></html>")

        mywindow.document.close() // necessary for IE >= 10
        mywindow.focus() // necessary for IE >= 10*/

        mywindow.print()
        mywindow.close()
    }

    goBack() {
        this.location.back()
    }
}
