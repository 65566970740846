<div class="comentario-box clearfix" *ngIf="!_enableEditar">
    <div class="user-col">
        <duda-avatar *ngIf="comentario" [usuario]="comentario.usuario" [enableMiniMode]="true"></duda-avatar>
    </div>
    <div class="content-col">
        <a *ngIf="canEdit" class="pointer edit-link pull-right" (click)="enableEditar()">
            {{ "dudas.comentario.editar" | t }}
            <fa name="pencil"></fa>
        </a>
        <p class="thread-info">
            <a class="autor" routerLink="/usuarios/{{ comentario?.usuario.id }}">{{ comentario?.usuario }}</a>
            <span class="fecha">{{ fecha | fechaAtras }}</span>
            <span *ngIf="isEdited && comentario && !comentario.ban" class="fecha">
                &nbsp;({{ "dudas.comentario.editado" | t }}&nbsp;{{ fecha_editado | fechaAtras }})
            </span>
        </p>
        <div *ngIf="comentario && !comentario.ban" [innerHTML]="comentarioParsed"></div>
        <div *ngIf="comentario && comentario.ban">
            <span class="ban">
                <fa name="gavel"></fa>
            </span>
        </div>
        <div class="bottom-options-push" *ngIf="isAdmin && comentario && !comentario.ban"></div>
        <div class="clearfix bottom-options">
            <!--<a *ngIf="isAdmin" href="javascript:void(0)" (click)="banUser(comentario?.usuario_id)">Banear Usuario</a>-->
            <cui-button
                *ngIf="isAdmin && comentario && !comentario.ban"
                (click)="banHammer()"
                type="button"
                class="btn_danger pull-right"
            >
                {{ "dudas.comentario.deshabilitar" | t }}&nbsp;
                <fa name="gavel"></fa>
            </cui-button>
        </div>
    </div>
</div>
<div *ngIf="_enableEditar" class="editar">
    <comentario-edit
        [comentario]="comentario"
        (onSaveDone)="onSaveDoneListener($event)"
        (onCancel)="onCancelListener($event)"
    ></comentario-edit>
</div>
