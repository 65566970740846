import { ModuleWithProviders, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { CarouselModule } from "ngx-bootstrap/carousel"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TabsModule } from "ngx-bootstrap/tabs"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { CUIInputsModule, CUITextsModule } from "@puntaje/shared/cui"
import { PlanClaseComponent } from "./plan-clase/plan-clase.component"
import { DatosGeneralesPlanClaseComponent } from "./datos-generales-plan-clase/datos-generales-plan-clase.component"
import { PlanClaseSemanasComponent } from "./plan-clase-semanas/plan-clase-semanas.component"
import { UtilModule, FormModule, ModelViewModule, PaginatorModule } from "@puntaje/shared/core"
import { CarouselSemanalComponent } from "./carousel-semanal/carousel-semanal.component"
import { CardMaterialProfesorComponent } from "./card-material-profesor/card-material-profesor.component"
import { CursosPlanClaseComponent } from "./cursos-plan-clase/cursos-plan-clase.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { BarraAvancePlanClaseComponent } from "./barra-avance-plan-clase/barra-avance-plan-clase.component"
import { ReplicarPlanComponent } from "./replicar-plan/replicar-plan.component"
import { ClasePlanClaseComponent } from "./clase-plan-clase/clase-plan-clase.component"
import { RouterModule } from "@angular/router"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { PlanClaseSemanasAlumnosComponent } from "./plan-clase-semanas-alumnos/plan-clase-semanas-alumnos.component"
import { CardMaterialAlumnoComponent } from "./card-material-alumno/card-material-alumno.component"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { PlanClaseAlumnoComponent } from "./plan-clase-alumno/plan-clase-alumno.component"
import { CarouselSemanalAlumnoComponent } from "./carousel-semanal-alumno/carousel-semanal-alumno.component"
import { PlanClasesProfesorComponent } from "./plan-clases-profesor/plan-clases-profesor.component"
import { PlanificacionesModule } from "@puntaje/puntaje/new-modules/planificaciones"
import { PlanClasesAlumnosComponent } from "./plan-clases-alumnos/plan-clases-alumnos.component"
import { PlanClaseBoxComponent } from "./plan-clase-box/plan-clase-box.component"
import { ClaseShowcaseComponent } from "./clase-showcase/clase-showcase.component"
import { ClasesHomeAlumnoComponent } from "./clases-home-alumno/clases-home-alumno.component"
import { ClaseShowcaseProfesorComponent } from "./clase-showcase-profesor/clase-showcase-profesor.component"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { RespuestasActividadComponent } from "./respuestas-actividad/respuestas-actividad.component"
import { ResultadosActividadComponent } from "./resultados-actividad/resultados-actividad.component"
import { PlanClaseNewComponent } from "./plan-clase-new/plan-clase-new.component"
import { PlanClaseSesionFormComponent } from "./plan-clase-sesion-form/plan-clase-sesion-form.component"
import { PlanClaseFormComponent } from "./plan-clase-form/plan-clase-form.component"
import { PlanClaseEditComponent } from "./plan-clase-edit/plan-clase-edit.component"
import { AgregarEvaluacionPlanClaseComponent } from "./agregar-evaluacion-plan-clase/agregar-evaluacion-plan-clase.component"
import { SortableModule } from "ngx-bootstrap/sortable"
import { PlanClaseModeloComponent } from "./plan-clase-modelo/plan-clase-modelo.component"
import { PlanClaseModelosComponent } from "./plan-clase-modelos/plan-clase-modelos.component"
import { PlanClaseContenidoBoxComponent } from "./plan-clase-contenido-box/plan-clase-contenido-box.component"
import { AgregarPruebaPropiaPlanClaseComponent } from "./agregar-prueba-propia-plan-clase/agregar-prueba-propia-plan-clase.component"
import { PlanClaseSesionAvancePorAlumnoComponent } from "./plan-clase-sesion-avance-por-alumno/plan-clase-sesion-avance-por-alumno.component"
import { PlanClaseSesionDetallePorContenidoComponent } from "./plan-clase-sesion-detalle-por-contenido/plan-clase-sesion-detalle-por-contenido.component"
import { PlanClaseAdminComponent } from "./plan-clases-admin/plan-clase-admin.component"
import { FiltroPlanClaseAdminComponent } from "./plan-clases-admin/filtro-plan-clase-admin.component"
import { FiltroPlanClaseAdminUsuariosComponent } from "./plan-clases-admin/filtro-plan-clase-admin-usuarios.component"
import { PlanClaseSemanasAdminComponent } from "./plan-clase-semanas-admin/plan-clase-semanas-admin.component"
import { PlanClaseSesionAvancePorAlumnoAdminComponent } from "./plan-clase-sesion-avance-por-alumno-admin/plan-clase-sesion-avance-por-alumno-admin.component"
import { PlanClaseSesionDetallePorContenidoAdminComponent } from "./plan-clase-sesion-detalle-por-contenido-admin/plan-clase-sesion-detalle-por-contenido-admin.component"
import { PlanClaseAlumnoNewComponent } from "./plan-clase-alumno-new/plan-clase-alumno-new.component"
import { PlanClaseSemanasAlumnosNewComponent } from "./plan-clase-semanas-alumnos-new/plan-clase-semanas-alumnos-new.component"

@NgModule({
    exports: [
        PlanClaseComponent,
        BarraAvancePlanClaseComponent,
        DatosGeneralesPlanClaseComponent,
        CursosPlanClaseComponent,
        ReplicarPlanComponent,
        ClasePlanClaseComponent,
        PlanClaseAlumnoComponent,
        PlanClasesProfesorComponent,
        PlanClasesAlumnosComponent,
        ClaseShowcaseComponent,
        ClaseShowcaseProfesorComponent,
        RespuestasActividadComponent,
        ResultadosActividadComponent,
        ClasesHomeAlumnoComponent,
        PlanClaseNewComponent,
        PlanClaseFormComponent,
        PlanClaseEditComponent,
        AgregarEvaluacionPlanClaseComponent,
        PlanClaseModelosComponent,
        PlanClaseBoxComponent,
        PlanClaseAdminComponent,
        FiltroPlanClaseAdminComponent,
        FiltroPlanClaseAdminUsuariosComponent,
        PlanClaseSemanasAdminComponent,
        PlanClaseSesionAvancePorAlumnoAdminComponent,
        PlanClaseSesionDetallePorContenidoAdminComponent,
        PlanClaseAlumnoNewComponent,
    ],
    declarations: [
        PlanClaseComponent,
        BarraAvancePlanClaseComponent,
        DatosGeneralesPlanClaseComponent,
        CursosPlanClaseComponent,
        PlanClaseSemanasComponent,
        CarouselSemanalComponent,
        ReplicarPlanComponent,
        CardMaterialProfesorComponent,
        ClasePlanClaseComponent,
        PlanClaseSemanasAlumnosComponent,
        CardMaterialAlumnoComponent,
        PlanClaseAlumnoComponent,
        CarouselSemanalAlumnoComponent,
        PlanClasesProfesorComponent,
        PlanClasesAlumnosComponent,
        PlanClaseBoxComponent,
        ClaseShowcaseComponent,
        ClaseShowcaseProfesorComponent,
        RespuestasActividadComponent,
        ResultadosActividadComponent,
        ClasesHomeAlumnoComponent,
        PlanClaseNewComponent,
        PlanClaseSesionFormComponent,
        PlanClaseFormComponent,
        PlanClaseEditComponent,
        AgregarEvaluacionPlanClaseComponent,
        PlanClaseModeloComponent,
        PlanClaseModelosComponent,
        PlanClaseContenidoBoxComponent,
        AgregarPruebaPropiaPlanClaseComponent,
        PlanClaseSesionAvancePorAlumnoComponent,
        PlanClaseSesionDetallePorContenidoComponent,
        PlanClaseAdminComponent,
        FiltroPlanClaseAdminComponent,
        FiltroPlanClaseAdminUsuariosComponent,
        PlanClaseSemanasAdminComponent,
        PlanClaseSesionAvancePorAlumnoAdminComponent,
        PlanClaseSesionDetallePorContenidoAdminComponent,
        PlanClaseAlumnoNewComponent,
        PlanClaseSemanasAlumnosNewComponent
    ],
    imports: [
        PopoverModule.forRoot(),
        EnsayosModule,
        InstrumentosModule,
        CommonModule,
        Angular2FontawesomeModule,
        CarouselModule,
        FormsModule,
        ReactiveFormsModule,
        CommonsLayoutsModule,
        UtilModule,
        MaterialesModule,
        RouterModule,
        SharedModule,
        PlanificacionesModule,
        FormModule,
        ProgressbarModule,
        TabsModule,
        ModelViewModule,
        SortableModule,
        PaginatorModule,
        CUIInputsModule,
        CUITextsModule
    ]
})
export class PlanClasesModule {}
