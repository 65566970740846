import { BaseModel } from "@puntaje/shared/core"
export class UsuarioMexico extends BaseModel {
    public numero_unico: string
    public telefono: string
    public usuario_id: number

    constructor() {
        super()
    }

    public toString() {
        return this.numero_unico
    }
}
