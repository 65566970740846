import { Subject } from "rxjs"
import { Injectable } from "@angular/core"

@Injectable()
export class EvaluacionInteractivaStatusService {
    statusUpdated: Subject<any> = new Subject()
    correctasCounter: number = 0
    incorrectasCounter: number = 0
    pistasCounter: number = 0
    preguntasCounter: number = 0
    preguntasTotales: number = 0
    intentosCounter: number = 0

    resetStatus() {
        this.correctasCounter = 0
        this.incorrectasCounter = 0
        this.pistasCounter = 0
        this.preguntasCounter = 0
        this.preguntasTotales = 0
        this.incorrectasCounter = 0
    }

    addCorrecta() {
        this.correctasCounter++
        this.updateStatus()
    }

    addIncorrecta() {
        this.incorrectasCounter++
        this.updateStatus()
    }

    addIntento() {
        this.intentosCounter++
        this.updateStatus()
    }

    addPista() {
        this.pistasCounter++
        this.updateStatus()
    }

    setTotalPreguntas(value: number) {
        this.preguntasTotales = value
    }

    nextPregunta() {
        this.preguntasCounter++
        if (this.preguntasCounter > this.preguntasTotales) this.preguntasCounter = this.preguntasTotales
        this.updateStatus()
    }

    updateStatus() {
        let status: any = {}
        status.correctas = this.correctasCounter
        status.incorrectas = this.incorrectasCounter
        status.pistas = this.pistasCounter
        status.preguntaActual = this.preguntasCounter
        status.totales = this.preguntasTotales
        status.intentos = this.intentosCounter
        this.statusUpdated.next(status)
    }
}
