import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core"
import { Duda, Dudas, UsuarioBaneados, UsuarioBaneado } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Router } from "@angular/router"
import { DudaRespuestasComponent } from "./duda_respuestas.component"
import { DudaItemComponent } from "./duda_item.component"
import { SessionService, AuthService } from "@puntaje/shared/core"

@Component({
    selector: "duda",
    templateUrl: "duda.component.html",
    styleUrls: ["duda.component.scss"]
})
export class DudaComponent implements OnInit {
    @Input() dudaId: number
    @Input() enableShareFb: boolean = true
    duda: Duda
    usuarioId: number
    @Output() dudaChange = new EventEmitter<Duda>()
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("item", { static: true }) item: DudaItemComponent
    @ViewChild(DudaRespuestasComponent) dudaRespuestasComponent: DudaRespuestasComponent
    respuestaEnabled: boolean = false
    enableAcceptAnswer: boolean = false
    isDocente: boolean = false
    isAdmin: boolean = false
    isPro: boolean = false
    isEdited: boolean = false
    marcoPro: string = ""
    canEdit: boolean = false

    constructor(
        protected dudasService: Dudas,
        protected usuarioBaneadosService: UsuarioBaneados,
        protected router: Router,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {
        this.usuarioId = this.sessionService.getUserData().id
    }

    ngOnInit() {
        this.setData()
    }

    setData() {
        this.loadingLayout.standby()
        this.dudasService
            .find(this.dudaId, {
                include: "[usuario:[rol_usuarios:[rol]],duda_categorias]",
                methods: "[usuario_valores]"
            })
            .then((duda: Duda) => {
                this.duda = duda
                this.enableAcceptAnswer = this.usuarioId == this.duda.usuario_id
                this.canEdit = this.canEditar(this.duda, this.usuarioId)
                this.setBadges()
                this.checkAdmin()
                this.marcoPro = this.duda.usuario.is_pro ? "marco01" : ""
                this.dudaChange.emit(duda)
                this.setItem()
                this.loadingLayout.ready()
            })
    }

    setItem() {
        if (this.duda && this.item) {
            switch (this.duda.item_type) {
                case "Pregunta":
                    this.item.load(this.duda.item_type, this.duda.pregunta)
                    break
                case "Material":
                    this.item.load(this.duda.item_type, this.duda.material)
                    break
            }
        }
    }

    onSaveDudaRespuesta() {
        this.dudaRespuestasComponent.setData()
        this.respuestaEnabled = false
    }

    cancelarResponder() {
        this.respuestaEnabled = false
    }

    aceptarRespuesta() {
        this.setData()
        //this.dudaRespuestasComponent.setData();
    }

    setBadges() {
        //if(this.duda.usuario.rol_usuarios)
        let rol_docente = this.duda.usuario.rol_usuarios.find(r => {
            return r.rol.rol == "Docente"
        })
        if (rol_docente) {
            this.isDocente = true
        }
        if (this.duda.created_at != this.duda.updated_at) {
            this.isEdited = true
        }
        this.isPro = this.duda.usuario.is_pro
    }

    checkAdmin() {
        let some_admin = this.sessionService.getRoles().find(r => {
            return r == "SuperAdministrador" || r == "Administrador" || r == "Monitor"
        })
        if (some_admin) {
            this.isAdmin = true
        }
    }

    canEditar(duda: Duda, usuarioId: number): boolean {
        return duda.usuario_id === usuarioId
    }

    compartirFacebook() {
        let dir = "https://" + window.location.hostname + "/dudas/" + this.duda.id
        this.authService.shareFacebook(dir)
    }

    banHammer() {
        this.duda.ban = true
        this.dudasService.update(this.duda.id, this.duda).then(resp => {})
    }

    banUser(usuarioId) {
        let now = new Date()

        let usuarioBaneado = new UsuarioBaneado()
        usuarioBaneado.usuario_id = usuarioId
        usuarioBaneado.fecha_inicio = new Date()
        usuarioBaneado.fecha_termino = new Date()
        usuarioBaneado.fecha_termino.setDate(now.getDate() + 7)

        this.usuarioBaneadosService.save(usuarioBaneado).then((usuarioBaneado: UsuarioBaneado) => {})
    }
}
