import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"

export class DudaForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            duda: {
                label: "Título",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            detalle: {
                label: "Escribe tu duda a continuación",
                type: "ckeditor",
                visible: true,
                validations: [Validators.required, Validators.minLength(4)]
            },
            grupo_usuario_id: {
                label: "Cursos",
                type: "hidden",
                visible: false
            }
        }
    }
}
