import { SaveType, BaseModel } from "@puntaje/shared/core"
import { DudaCategoria } from "./duda_categorias.model"
import { DudaDudaCategoria } from "./duda_duda_categorias.model"
import { DudaUsuarioInfo } from "./duda_usuario_info.model"
import { Usuario } from "./usuarios.model"
import { Pregunta, Material } from "@puntaje/nebulosa/api-services"
import { Dudas } from "./dudas.service"
import { Injector } from "@angular/core"

export class Duda extends BaseModel {
    public duda: string
    public detalle: string
    public usuario_id: number
    public mejor_respuesta_id: number
    public item_id: number
    public item_type: string
    public thumbs_up: number
    public thumbs_down: number
    public ban: boolean
    public created_at: string | Date
    public updated_at: string | Date
    public className: string = "Duda"
    public participacion: number
    public grupo_usuario_id: number

    @SaveType(() => DudaCategoria) duda_categorias: DudaCategoria[]
    @SaveType(() => DudaDudaCategoria) duda_duda_categorias: DudaDudaCategoria[]
    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => Pregunta) pregunta: Pregunta
    @SaveType(() => Material) material: Material
    @SaveType(() => DudaUsuarioInfo) usuario_valores: DudaUsuarioInfo

    public getLikePromise(injector: Injector, params: any = {}): Promise<any> {
        return injector
            .get(Dudas)
            .like(this.id, params)
            .then((duda: Duda) => {
                let obj: any = {}
                obj.likes = duda.thumbs_up
                obj.dislikes = duda.thumbs_down

                return obj
            })
    }

    public getDislikePromise(injector: Injector, params: any = {}): Promise<any> {
        return injector
            .get(Dudas)
            .dislike(this.id, params)
            .then((duda: Duda) => {
                let obj: any = {}
                obj.likes = duda.thumbs_up
                obj.dislikes = duda.thumbs_down

                return obj
            })
    }

    public getSentimentPromise(injector: Injector, params: any = {}): Promise<any> {
        return injector
            .get(Dudas)
            .find(this.id)
            .then((duda: Duda) => {
                let obj: any = {}
                obj.likes = duda.thumbs_up
                obj.dislikes = duda.thumbs_down

                return obj
            })
    }
}
