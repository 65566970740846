import { BaseModel } from "@puntaje/shared/core"

export class Horario extends BaseModel {
    public url: string
    public descripcion: string
    public created_at: string | Date
    public updated_at: string | Date
    public image_file: File

    public toString() {
        return this.descripcion
    }

    public getS3Params() {
        return this.image_file
            ? {
                  key: this.image_file.name,
                  model_name: "Horario"
              }
            : {}
    }
}
