import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { Estadisticas } from "@puntaje/puntaje/api-services"
import { Asignatura, Asignaturas, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "estadisticas-simple",
    templateUrl: "estadisticas_simple.component.html",
    styleUrls: ["estadisticas_simple.component.scss"]
})
export class EstadisticasSimpleComponent implements OnInit {
    @Input() evaluacionTipo: string = config.plataforma.evaluacionDefault
    estadisticas: any

    asignatura: Asignatura

    generadorInstrumento: GeneradorInstrumento
    generadorInstrumentos: GeneradorInstrumento[]

    distribucion_ensayos_tramo_puntaje: number
    desempeno_promedio_omitidas: number
    desempeno_promedio_correctas: number
    desempeno_promedio_incorrectas: number

    enableGraphs: boolean = false

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected estadisticasService: Estadisticas,
        protected authService: AuthService,
        protected asignaturasService: Asignaturas
    ) {}

    ngOnInit() {}

    onChangeAsignatura(asignaturaId) {
        this.loadingLayout.standby()
        this.enableGraphs = false
        this.generadorInstrumento = null
        this.asignaturasService
            .find(asignaturaId, { with_generador_instrumentos: 1, tipo_instrumento: this.evaluacionTipo })
            .then((asignatura: Asignatura) => {
                this.asignatura = asignatura
                this.generadorInstrumentos = asignatura.generador_instrumentos

                this.enableGraphs = true
            })
    }

    getEstadisticas() {
        this.loadingLayout.standby()
        this.enableGraphs = false
        if (this.generadorInstrumentos && this.generadorInstrumentos.length > 1 && !this.generadorInstrumento) {
            this.generadorInstrumento = this.generadorInstrumentos[0]
        }
        this.estadisticasService
            .where({
                collection: "EstadisticaUsuario",
                estadistica: {
                    generador_instrumento_id: this.generadorInstrumento.id,
                    oficial: 1,
                    evaluacion_tipo: this.generadorInstrumento.tipo_instrumento.tipo_instrumento,
                    usuario_id: this.authService.getUserData().id,
                    formativa: false
                }
            })
            .then(estadisticas => {
                this.estadisticas = estadisticas
                if (this.estadisticas.length > 0) this.setValues()
                else {
                    this.distribucion_ensayos_tramo_puntaje =
                        this.desempeno_promedio_correctas =
                        this.desempeno_promedio_incorrectas =
                        this.desempeno_promedio_omitidas =
                            null
                }
                this.loadingLayout.ready()
                this.enableGraphs = true
            })
    }

    setValues() {
        this.distribucion_ensayos_tramo_puntaje = this.estadisticas.reduce((acc, e) => {
            Object.keys(e.distribucion_calificaciones).forEach(key => {
                const calificacion = +key.replace(",", ".")

                acc[calificacion] = acc[calificacion] || 0
                acc[calificacion] += e.distribucion_calificaciones[key]
            })

            return acc
        }, {})
        const correctas = this.estadisticas.map(e => e.correctas).reduce((acc, x) => acc + x)
        const incorrectas = this.estadisticas.map(e => e.incorrectas).reduce((acc, x) => acc + x)
        const omitidas = this.estadisticas.map(e => e.omitidas).reduce((acc, x) => acc + x)
        const numeroEvaluaciones = this.estadisticas.map(e => e.numero_evaluaciones).reduce((acc, x) => acc + x)

        this.desempeno_promedio_omitidas = ~~(omitidas / numeroEvaluaciones)
        this.desempeno_promedio_correctas = ~~(correctas / numeroEvaluaciones)
        this.desempeno_promedio_incorrectas = ~~(incorrectas / numeroEvaluaciones)
    }

    ngOnChanges(changes) {
        if (changes["evaluacionTipo"] && this.asignatura) {
            this.onChangeAsignatura(this.asignatura.id)
        }
    }
}
