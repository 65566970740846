import {
    Component,
    Input,
    forwardRef,
    SimpleChanges,
    ViewChildren,
    ElementRef,
    QueryList,
    Output,
    EventEmitter
} from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"
import { BaseModel } from "../api/base.model"

@Component({
    selector: "checkboxes",
    template: `
        <ng-container *ngFor="let key of groupedOptions | keys; let k = index">
            {{ key }}
            <div *ngFor="let option of groupedOptions[key]; let i = index" class="checkbox">
                <label [attr.for]="i + '_' + key">
                    <input
                        type="checkbox"
                        #element
                        (change)="checkboxChange(i, k, groupedOffsets[key], element, key)"
                        [id]="i + '_' + key"
                    />
                    {{ optionsName[i] ?? option.toString() }}
                </label>
            </div>
        </ng-container>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxesComponent),
            multi: true
        }
    ]
})
export class CheckboxesComponent implements ControlValueAccessor {
    selected: any[] = []
    groupedOptions: { [key: string]: BaseModel[] }
    groupedOffsets: { [key: string]: number } = {}

    @ViewChildren("element") checkboxes: QueryList<ElementRef>

    @Input() options: BaseModel[]
    @Input() optionsName: string[] = []
    @Input() groupBy: Function

    @Output() changeSelected = new EventEmitter<any[]>()
    onChange = (_: any) => {}
    onTouch = () => {}

    ngOnInit() {
        this.setOptions()
    }

    ngAfterViewInit() {
        this.reflectCheckboxes()
    }

    setOptions() {
        if (!this.options) this.options = []

        let groupBy = (arr: any[], f: Function) => {
            return arr.reduce((acc, x) => {
                acc[f(x)] = acc[f(x)] || []
                acc[f(x)].push(x)

                return acc
            }, {})
        }

        let fun = o => {
            if (this.groupBy) {
                return this.groupBy(o)
            } else {
                return ""
            }
        }

        this.groupedOptions = groupBy(this.options, fun)
        let keyOffset = 0
        Object.keys(this.groupedOptions).forEach(key => {
            this.groupedOffsets[key] = keyOffset
            keyOffset += this.groupedOptions[key].length
        })

        this.options = Object.keys(this.groupedOptions)
            .map(key => this.groupedOptions[key])
            .flat()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["options"]) {
            this.setOptions()
        }
    }

    checkboxChange(i, k, lengthGroup, element, groupedBy) {
        this.selected[i + k * lengthGroup] = element.checked && this.groupedOptions[groupedBy][i]
        this.onChange(this.selected)
        this.changeSelected.emit(this.selected)
    }

    reflectCheckboxes() {
        const checkboxes = this.checkboxes.toArray()
        Object.keys(this.groupedOptions).forEach((key, k) => {
            this.groupedOptions[key].forEach((option, i) => {
                checkboxes[i + this.groupedOffsets[key]].nativeElement.checked =
                    !!this.selected[i + this.groupedOffsets[key]]
            })
        })
    }

    writeValue(value: any) {
        if (value instanceof Array) {
            value = this.options.map((o, i) => {
                return value.find(sl => o.id == sl.id)
            })

            this.selected = value

            if (this.checkboxes) this.reflectCheckboxes()
            /*this.onChange(this.selected);*/
        }
    }

    registerOnChange(fn: (value: any) => any) {
        this.onChange = (_: any) => {
            let filterSelected = this.selected.filter(v => v)

            return fn(filterSelected)
        }
    }

    registerOnTouched(fn: () => any) {
        this.onTouch = fn
    }
}
