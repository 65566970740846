import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { ApiModule } from "@puntaje/puntaje/api-services"
import { FormModule, UtilModule, ModelViewModule, PaginatorModule } from "@puntaje/shared/core"
import { RouterModule } from "@angular/router"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { CUITextsModule, CUIInputsModule, CUIContainersModule } from "@puntaje/shared/cui"
import { SentimentsModule } from "@puntaje/puntaje/new-modules/sentiments"

import { MaterialComponent } from "./material.component"
import { MaterialesRawComponent } from "./materiales_raw.component"
import { MaterialesComponent } from "./materiales.component"
import { MaterialesToolNavbarComponent } from "./materiales.toolnavbar.component"
import { TipoMaterialIconComponent } from "./tipo_material_icon.component"
import { MenuAsignaturasComponent } from "./menu_asignaturas.component"
import { MaterialesShowcaseBoxComponent } from "./materiales_showcasebox.component"
import { MenuAsignaturasVerticalComponent } from "./menu_asignaturas_vertical.component"
import { BannerMaterialesSIMCEComponent } from "./banner_materiales_simce.component"
import { MaterialesSIMCEShowcaseBoxComponent } from "./materiales_simce_showcasebox.component"
import { MaterialesCursosComponent } from "./materiales_cursos.component"
import { BannerMaterialesSaberComponent } from "./banner_materiales_saber.component"
import { MaterialesSaberShowcaseBoxComponent } from "./materiales_saber_showcasebox.component"
import { MaterialesUDDSComponent } from "./materiales_udds.component"
import { MaterialesAppsComponent } from "./materiales_apps.component"
import { LastMaterialShowcaseComponent } from "./last_material_showcase.component"
import { MaterialesWithThumbnailsComponent } from "./materiales_with_thumbnails.component"
import { MaterialWithThumbnailComponent } from "./material_with_thumbnail.component"
import { MaterialPreviewComponent } from "./material_preview.component"
import { MaterialesColapsablesPorClasificacionComponent } from "./materiales_colapsables_por_clasificacion.component"
import { CursoSelectComponent } from "./curso_select.component"
import { MaterialesFavoritosComponent } from "./materiales_favoritos.component"
import { MaterialesDirigidosMenuComponent } from "./materiales_dirigidos_menu.component"
import { CursoSearchFilterComponent } from "./curso_search_filter.component"

import { Angulartics2Module } from "angulartics2"
import { MaterialPreviewModalComponent } from "./modals/material_preview_modal.component"
import { MaterialPreviewModalService } from "./modals/material_preview_modal.service"
import { MaterialesPropiosComponent } from "./materiales-propios/materiales-propios.component"
import { SubirMaterialProfesorComponent } from "./subir-material-profesor/subir-material-profesor.component"
import { MaterialesBibliotecaComponent } from "./materiales-biblioteca/materiales-biblioteca.component"
import { MaterialesListaComponent } from "./materiales-lista/materiales-lista.component"
import { ThumbnailMaterialComponent } from "./thumbnail-material/thumbnail-material.component"
import { VideotecaCBShowcaseBoxComponent } from "./videoteca-cb-showcasebox/videoteca_cb_showcasebox.component"
import { FiltroClasificacionesMaterialesComponent } from "./filtro-clasificaciones-materiales/filtro-clasificaciones-materiales.component"
import { MaterialFavoritoShowcaseboxComponent } from "./material-favorito-showcasebox/material-favorito-showcasebox.component";
import { FiltrosClasificacionesEvaluacionTipoComponent } from './filtros-clasificaciones-evaluacion-tipo/filtros-clasificaciones-evaluacion-tipo.component'

@NgModule()
export class MaterialesServices {}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ApiModule,
        NebuModule,
        UtilModule,
        PaginatorModule,
        FormModule,
        ModelViewModule,
        Angular2FontawesomeModule,
        CommonsLayoutsModule,
        Angulartics2Module,
        SentimentsModule,
        SharedModule,
        CUITextsModule,
        CUIInputsModule,
        CUIContainersModule
    ],
    declarations: [
        MaterialComponent,
        MaterialesRawComponent,
        MaterialesComponent,
        MaterialesToolNavbarComponent,
        TipoMaterialIconComponent,
        MenuAsignaturasComponent,
        MaterialesShowcaseBoxComponent,
        MenuAsignaturasVerticalComponent,
        BannerMaterialesSIMCEComponent,
        MaterialesSIMCEShowcaseBoxComponent,
        MaterialesCursosComponent,
        BannerMaterialesSaberComponent,
        MaterialesSaberShowcaseBoxComponent,
        MaterialesUDDSComponent,
        MaterialesAppsComponent,
        LastMaterialShowcaseComponent,
        MaterialesWithThumbnailsComponent,
        MaterialWithThumbnailComponent,
        MaterialPreviewComponent,
        MaterialesColapsablesPorClasificacionComponent,
        CursoSelectComponent,
        MaterialesFavoritosComponent,
        MaterialesDirigidosMenuComponent,
        MaterialPreviewModalComponent,
        MaterialesPropiosComponent,
        SubirMaterialProfesorComponent,
        MaterialesBibliotecaComponent,
        MaterialesListaComponent,
        ThumbnailMaterialComponent,
        VideotecaCBShowcaseBoxComponent,
        CursoSearchFilterComponent,
        FiltroClasificacionesMaterialesComponent,
        MaterialFavoritoShowcaseboxComponent,
        FiltrosClasificacionesEvaluacionTipoComponent
    ],
    exports: [
        MaterialComponent,
        MaterialesComponent,
        MaterialesRawComponent,
        MaterialesToolNavbarComponent,
        MenuAsignaturasComponent,
        MaterialesShowcaseBoxComponent,
        MenuAsignaturasVerticalComponent,
        BannerMaterialesSIMCEComponent,
        MaterialesSIMCEShowcaseBoxComponent,
        MaterialesCursosComponent,
        BannerMaterialesSaberComponent,
        MaterialesSaberShowcaseBoxComponent,
        MaterialesUDDSComponent,
        MaterialesAppsComponent,
        LastMaterialShowcaseComponent,
        MaterialesWithThumbnailsComponent,
        MaterialWithThumbnailComponent,
        MaterialPreviewComponent,
        MaterialesColapsablesPorClasificacionComponent,
        CursoSelectComponent,
        MaterialesFavoritosComponent,
        MaterialesDirigidosMenuComponent,
        MaterialPreviewModalComponent,
        MaterialesPropiosComponent,
        SubirMaterialProfesorComponent,
        MaterialesBibliotecaComponent,
        ThumbnailMaterialComponent,
        VideotecaCBShowcaseBoxComponent,
        CursoSearchFilterComponent,
        MaterialFavoritoShowcaseboxComponent,
        FiltrosClasificacionesEvaluacionTipoComponent
    ],
    providers: []
})
export class MaterialesModule {
    static services(): ModuleWithProviders<MaterialesServices> {
        return {
            ngModule: MaterialesServices,
            providers: [MaterialPreviewModalService]
        }
    }
}
