import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { ClasificacionMaterial } from "./clasificacion_materiales.model"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"

@Injectable()
export class ClasificacionMateriales extends NebuBaseService<ClasificacionMaterial> {
    tableName = "clasificacion_materiales"
    singularTableName = "clasificacion_material"
    modelClass = ClasificacionMaterial

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
