<div class="responder-propia">
    <div class="responder-propia-positioning alternativas{{ letrasAlternativas.length }}">
        <div
            class="responder-propia-container alternativas{{ letrasAlternativas.length }}"
            [class.responder-propia-container-hidden]="!showComponent"
            [class.starting-position]="animateOnStart && !animateOnStartTrigger"
            [style.transform]="shrinkValueX ? 'scale(' + shrinkValueX + ',' + shrinkValueY + ')' : undefined"
        >
            <div class="component-content" [class.show-content]="enableToggle && showComponent">
                <div class="title">
                    <div class="component-icon" (click)="toggleComponent()">
                        <fa [name]="'pencil-square-o'"></fa>
                    </div>
                    <span>{{ "widget_prueba_propias.respuestas" | t }}</span>
                    <button (click)="toggleComponent()" type="button" class="closeBtn" aria-label="Close">
                        <fa *ngIf="showComponent" name="times"></fa>
                        <fa *ngIf="!showComponent" name="chevron-right"></fa>
                    </button>
                </div>
                <div class="preguntas-container">
                    <div class="preguntas-container-wrap">
                        <div class="preguntas">
                            <div class="letras">
                                <div class="empty-letra"></div>
                                <div *ngFor="let letra of letrasAlternativas" class="letra">{{ letra }}</div>
                                <div class="empty-cell"></div>
                                <div class="pt-cell pt-title">Puntaje</div>
                            </div>
                            <div *ngFor="let pregunta of preguntas; let i = index" class="pregunta">
                                <div class="numero">{{ i + 1 }})</div>
                                <ng-container *ngFor="let contestable of pregunta.contestables; let j = index">
                                    <ng-container [ngSwitch]="contestable.contestable_tipo?.contestable_tipo">
                                        <ng-container *ngSwitchCase="contestableTipoEnum.libre">
                                            <div class="alternativa with-btn">
                                                <generic-modal #genericModal>
                                                    <modal-titulo>Respuesta a pregunta #{{ i + 1 }}</modal-titulo>
                                                    <modal-contenido>
                                                        <contestable-libre
                                                            [alternativaTexto]="respuestas[i][j]"
                                                            (alternativaTextoChange)="buildRespuesta(i, j, $event)"
                                                        ></contestable-libre>
                                                    </modal-contenido>
                                                    <modal-botones>
                                                        <button class="btn btn-default" (click)="genericModal.close()">
                                                            Cerrar
                                                        </button>
                                                    </modal-botones>
                                                </generic-modal>
                                                <cui-button
                                                    class="btn-contestar"
                                                    [isBtn]="true"
                                                    size="xsmall"
                                                    [class.btn_style]="
                                                        respuestas[i][j] && respuestas[i][j].alternativa_texto
                                                    "
                                                    (click)="genericModal.buttonPressed()"
                                                >
                                                    <fa
                                                        [name]="
                                                            respuestas[i][j] && respuestas[i][j].alternativa_texto
                                                                ? 'pencil'
                                                                : 'align-justify'
                                                        "
                                                    ></fa>
                                                    {{
                                                        respuestas[i][j] && respuestas[i][j].alternativa_texto
                                                            ? "Editar"
                                                            : "Contestar"
                                                    }}
                                                </cui-button>
                                            </div>
                                            <div *ngFor="let _ of letrasAlternativas.slice(1)" class="empty-cell"></div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="contestableTipoEnum.archivo">
                                            <div class="alternativa with-btn">
                                                <generic-modal #genericModal>
                                                    <modal-titulo>
                                                        {{ "widget_prueba_propias.respuest_a_pregunta" | t }} #{{
                                                            i + 1
                                                        }}
                                                    </modal-titulo>
                                                    <modal-contenido>
                                                        <contestable-archivo
                                                            [alternativaTexto]="respuestas[i][j]"
                                                            (alternativaTextoChange)="buildRespuesta(i, j, $event)"
                                                            [contestableId]="contestable.id"
                                                        ></contestable-archivo>
                                                    </modal-contenido>
                                                    <modal-botones>
                                                        <button class="btn btn-default" (click)="genericModal.close()">
                                                            {{ "widget_prueba_propias.cerrar" | t }}
                                                        </button>
                                                    </modal-botones>
                                                </generic-modal>
                                                <cui-button
                                                    class="btn-contestar"
                                                    [isBtn]="true"
                                                    size="xsmall"
                                                    [class.btn_style]="
                                                        respuestas[i][j] && respuestas[i][j].alternativa_texto
                                                    "
                                                    (click)="genericModal.buttonPressed()"
                                                >
                                                    <fa
                                                        [name]="
                                                            respuestas[i][j] && respuestas[i][j].alternativa_texto
                                                                ? 'pencil'
                                                                : 'cloud-upload'
                                                        "
                                                    ></fa>
                                                    {{
                                                        respuestas[i][j] && respuestas[i][j].alternativa_texto
                                                            ? "Editar"
                                                            : "Contestar"
                                                    }}
                                                </cui-button>
                                            </div>
                                            <div *ngFor="let _ of letrasAlternativas.slice(1)" class="empty-cell"></div>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <div *ngFor="let letra of letrasAlternativas" class="alternativa">
                                                <div class="radio">
                                                    <input
                                                        #radio="ngModel"
                                                        id="{{ i }}_{{ letra }}"
                                                        name="pregunta_{{ i }}"
                                                        class="alternativa-checkbox"
                                                        type="radio"
                                                        [value]="letra"
                                                        [ngModel]="respuestas[i] && respuestas[i][j]"
                                                        (ngModelChange)="buildRespuesta(i, j, $event)"
                                                        (click)="onClickRadio(i, j, letra, radio)"
                                                    />
                                                    <label [attr.for]="i + '_' + letra"></label>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <div class="empty-cell"></div>
                                <div class="pt-cell">
                                    {{ evaluacion.instrumento.instrumento_preguntas[i].puntuacion }} pt
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [class.show-content]="enableToggle && !showComponent" class="open-icons">
            <button (click)="toggleComponent()" type="button" class="openBtn" aria-label="Close">
                <fa [name]="'pencil-square-o'"></fa>
            </button>
        </div>
    </div>
</div>
