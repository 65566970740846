<ng-template [ngIf]="total">
    <div class="clearfix" [class.extraMiniMode]="extraMiniMode">
        <p class="pagination_text_small">
            {{ "otros.paginador_html_1" | t }}
            <b>{{ total }}</b>
            {{ total | i18nPlural: resultadosStringMapping }}.
            <ng-container *ngIf="total > 1">
                <span [innerHTML]="'otros.paginador_html_2' | t: { first: first, last: last } | trustedhtml"></span>
            </ng-container>
        </p>
        <nav aria-label="Page navigation" class="pages_container" *ngIf="pages > 1">
            <ul class="pagination pagination-sm">
                <!--Ir a la primera pagina-->
                <li *ngIf="range()[0] != 1">
                    <a href="javascript:;" aria-label="Primera página" (click)="changePage(1)">Primera</a>
                </li>
                <!--Ir a la página anterior-->
                <li *ngIf="currentPage != 1">
                    <a href="javascript:;" aria-label="Anterior" (click)="changePage(currentPage - 1)">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                <!--Lista de páginas-->
                <li *ngFor="let page of range()" [class.active]="page == currentPage">
                    <a href="javascript:;" *ngIf="page == currentPage" aria-current="true" (click)="(false)">
                        {{ page }}
                    </a>
                    <a href="javascript:;" *ngIf="page != currentPage" (click)="changePage(page)">{{ page }}</a>
                </li>
                <!--Ir a la página siguiente-->
                <li *ngIf="currentPage != pages">
                    <a href="javascript:;" aria-label="Siguiente" (click)="changePage(currentPage + 1)">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
                <!--Ir a la última página-->
                <li *ngIf="range()[range().length - 1] != pages">
                    <a href="javascript:;" aria-label="Última página" (click)="changePage(pages)">Última</a>
                </li>
            </ul>
        </nav>
        <p class="pagination_text">
            {{ "otros.paginador_html_1" | t }}
            <b>{{ total }}</b>
            {{ total | i18nPlural: resultadosStringMapping }}.
            <ng-container *ngIf="total > 1">
                <span [innerHTML]="'otros.paginador_html_2' | t: { first: first, last: last } | trustedhtml"></span>
            </ng-container>
        </p>
    </div>
</ng-template>
