<div class="content-box">
    <div class="header">
        <div class="presets">
            <ul class="clearfix">
                <li>
                    <a (click)="presetLastXMonths(12)">{{ "libs.modules.estadisticas.timeframe.ultimo_ano" | t }}</a>
                </li>
                <li>
                    <a (click)="presetLastXMonths(6)">{{ "libs.modules.estadisticas.timeframe.ultimos_meses" | t }}</a>
                </li>
                <li>
                    <a (click)="presetLastXMonths(1)">{{ "libs.modules.estadisticas.timeframe.ultimo_mes" | t }}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="tab-content">
        <div class="range-container">
            <div class="from-to">
                <label class="date-label">{{ "libs.modules.estadisticas.timeframe.desde" | t }}:&nbsp;</label>
                <my-date-picker
                    class="datepicker"
                    [options]="myDatePickerOptions"
                    [(ngModel)]="fechaInicio"
                    (ngModelChange)="onChangeDateRange()"
                    locale="es"
                ></my-date-picker>
                <!-- <birthdate-form
                    class="date-input"
                    [(ngModel)]="fechaInicio"
                    (ngModelChange)="onChangeDateRange()"
                ></birthdate-form> -->
            </div>
            <div class="from-to">
                <label class="date-label">{{ "libs.modules.estadisticas.timeframe.hasta" | t }}:&nbsp;</label>
                <my-date-picker
                    class="datepicker"
                    [options]="myDatePickerOptions"
                    [(ngModel)]="fechaTermino"
                    (ngModelChange)="onChangeDateRange()"
                    locale="es"
                ></my-date-picker>
                <!-- <birthdate-form
                    class="date-input"
                    [(ngModel)]="fechaTermino"
                    (ngModelChange)="onChangeDateRange()"
                ></birthdate-form> -->
            </div>
        </div>
    </div>
</div>
