import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Instruccion } from "./instrucciones.model"
import { GeneradorInstrumento } from "./generador_instrumentos.model"
import { Clasificacion } from "./clasificaciones.model"

export class GeneradorInstrumentoInstruccion extends BaseModel {
    public static className: string = "GeneradorInstrumentoInstruccion"
    generador_instrumento_id: number
    instruccion_id: number
    clasificacion_id: number
    created_at: Date
    updated_at: Date
    orden: number
    fija: boolean

    @SaveType(() => Instruccion) instruccion: Instruccion
    @SaveType(() => GeneradorInstrumento) generador_instrumento: GeneradorInstrumento
    @SaveType(() => Clasificacion) clasificacion: Clasificacion
}
