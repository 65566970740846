import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { GrupoUsuarios, GrupoUsuario } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { last, first } from "rxjs/operators"

@Component({
    templateUrl: "home_saber.component.html",
    styleUrls: ["home_saber.component.scss"]
})
export class HomeSaberComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []
    config: typeof config = config
    disableMenu: boolean = false

    constructor(
        protected store: Store<State>,
        protected grupoUsuariosService: GrupoUsuarios,
        protected authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()
        this.getGrupoUsuarios()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.pipe(first()).toPromise()

        this.menuItems = [
            {
                route: "/saber/resultados/",
                params: { tipo_instrumento: "saber", asignatura_id: asignaturasByEvaluacionTipo["saber"][0].id },
                label: "Historial",
                text: "Encuentra aquí la lista de todos los simulacros que has realizado.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/saber/compartidas/",
                params: { tipo_instrumento: "saber", asignatura_id: asignaturasByEvaluacionTipo["saber"][0].id },
                label: "Historial de simulacros compartidos",
                text: "Encuentra aquí la lista de todos los simulacros que te han compartido.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/saber/mi_progreso/",
                params: { tipo_instrumento: "saber" },
                label: "Mi Progreso",
                text: "Observa la evolución en el tiempo de tus resultados obtenidos.",
                linkText: "Ver mi progreso",
                icon: "estadistica-o"
            },
            {
                route: "/saber/estadisticas/",
                params: { tipo_instrumento: "saber" },
                label: "Estadísticas Simulacros",
                text: "Encuentra aquí estadísticas generadas a partir de tus resultados en los Simulacros Saber.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            }
        ]
    }

    getGrupoUsuarios() {
        let usuario = this.authService.getUserData()
        let params = {
            establecimiento_id_not_nil: 1,
            propios: 1,
            grupo_usuario: {
                privado: 1
            },
            grupo_usuario_usuario: {
                propietario: 0,
                usuario_id: usuario.id
            }
        }

        this.grupoUsuariosService.where(params).then((grupoUsuarios: GrupoUsuario[]) => {
            this.disableMenu = grupoUsuarios.length <= 0
        })
    }
}
