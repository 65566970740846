import { Component, OnInit, Input } from "@angular/core"

@Component({
    selector: "mi-ponderacion",
    templateUrl: "./mi-ponderacion.component.html",
    styleUrls: ["./mi-ponderacion.component.scss"]
})
export class MiPonderacionComponent implements OnInit {
    @Input() ponderacion: number
    @Input() simulacion: number

    msj: string =
        "Este puntaje se calcula promediando tus 3 últimos ensayos de cada materia y considerando las ponderaciones de la carrera."

    ngOnInit() {}
}
