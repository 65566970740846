<div class="duda-categoria-box {{ categoria.asignatura?.clase }}" *ngIf="categoria">
    <div class="duda-categoria-row">
        <div class="icon-cell">
            <ogr-icon
                [name]="categoria.asignatura ? categoria.asignatura['icono'] : 'dialogos'"
                class="icono"
            ></ogr-icon>
        </div>
        <div class="info-cell">
            <h2>
                <a routerLink="/dudas" [queryParams]="{ categoria_id: categoria.id, page: 1, per: 10 }">
                    {{ categoria }}
                </a>
            </h2>
            <p>
                {{ "participa.dudas.categorias.total" | t }}
                <ogr-icon name="dialogos" class="dialogos"></ogr-icon>
                {{ categoria.total_dudas }}
            </p>
        </div>
        <div class="ultima-duda-cell">
            <p class="ultima-duda">
                <ogr-icon name="dialogos" class="dialogos"></ogr-icon>
                {{ "participa.dudas.categorias.ultima" | t }}
            </p>
            <duda-list-item-mini
                *ngIf="categoria.last_duda"
                [duda]="categoria.last_duda"
                class="duda-mini"
            ></duda-list-item-mini>
            <div *ngIf="!categoria.last_duda">
                <p class="not-found">{{ "participa.dudas.categorias.sin_actividad" | t }}</p>
            </div>
        </div>
    </div>
</div>
