import { Injectable } from "@angular/core"
import { SessionService } from "../session/session.service"

function getWindow(): any {
    return window
}

@Injectable({
    providedIn: "root"
})
export class ZopimService {
    private window

    constructor(protected sessionService: SessionService) {
        this.window = getWindow()
    }

    public setZopimData() {
        const user = this.sessionService.getUserData()
        if (this.window.$zopim) {
            this.window.$zopim(() => {
                this.window.$zopim.livechat.setName(
                    user.nombre + " " + user.apellido_paterno + " " + user.apellido_materno
                )
                this.window.$zopim.livechat.setEmail(user.email)
            })
        }
    }

    public unsetZopimData() {
        if (this.window.$zopim) {
            this.window.$zopim(() => {
                this.window.$zopim.livechat.clearAll()
            })
        }
    }

    public hideZopim() {
        if (this.window.$zopim) {
            this.window.$zopim(() => {
                this.window.$zopim.livechat.hideAll()
            })
        }
    }

    public showZopim() {
        if (this.window.$zopim) {
            this.window.$zopim(() => {
                this.window.$zopim.livechat.button.show()
            })
        }
    }
}
