import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv

import { Router } from "@angular/router"
import { AuthService, SessionService } from "@puntaje/shared/core"

@Component({
    selector: "register-confirmation",
    templateUrl: "register_confirmation.component.html"
})
export class RegisterConfirmationComponent implements OnInit {
    @Input() token: string
    @Output() confirmationStatus: EventEmitter<any> = new EventEmitter<any>()
    done: boolean = false
    status: boolean

    constructor(protected http: HttpClient, protected router: Router, protected sessionService: SessionService) {}

    ngOnInit() {
        this.confirm_by_token(this.token)
    }

    confirm_by_token(token) {
        this.http
            .get(environment.endpoints.base + "/usuarios/confirmar_email/" + this.token)
            .toPromise()
            .then((response: any) => {
                this.done = true
                if (response.confirmed_at) this.sessionService.setConfirmedAt(response.confirmed_at)
                this.status = response.confirmation_done == false ? response.confirmation_done : response
                this.confirmationStatus.emit(this.status)
                setTimeout((router: Router) => {
                    this.router.navigate([""])
                }, 5000)
            })
    }
}
