import {
    Component,
    Directive,
    ViewChild,
    AfterContentInit,
    Input,
    OnInit,
    TemplateRef,
    ChangeDetectorRef
} from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { queueScheduler, Subscription } from "rxjs"
import { observeOn } from "rxjs/operators"
import { EvaluationLayoutService } from "./evaluation-layout.service"

/*#################################

Template simple para evaluaciones. Incluye el navbar en la parte superior.
Implementa una sección de contenido, un título, y contenido lateral a la izquierda

evaluation-layout-titulo
evaluation-layout-contenido
evaluation-layout-contenido-lateral

#################################*/

@Component({
    templateUrl: "cui-evaluation-layout.component.html",
    selector: "cui-evaluation-layout",
    styleUrls: ["cui-evaluation-layout.component.scss"]
})
export class CUIEvaluationLayoutComponent implements OnInit {
    @ViewChild("evaluationLayoutContenido", { static: true }) evaluationLayoutContenido
    showContenido: boolean = false
    showTopside: boolean = false

    @Input() contenidoLateralXL: boolean = false
    @Input() singleColumn: boolean = false
    @Input() borderedColumns: boolean = true
    @Input() hideTitle: boolean = false
    @Input() customRow: boolean = true

    firstColumnWidth = "col-sm-3 col-md-3"
    secondColumnWidth = "col-sm-9 col-md-9"

    templateObj: { [name: string]: TemplateRef<any> } = {}

    sub: Subscription
    subTemplate: Subscription

    constructor(
        protected authService: AuthService,
        protected cdr: ChangeDetectorRef,
        protected evaluationLayoutService: EvaluationLayoutService
    ) {}

    ngOnInit() {
        this.setColumnsClasses()

        this.evaluationLayoutService.resetSlots()
        this.evaluationLayoutService.setOptions({
            contenidoLateralXL: this.contenidoLateralXL,
            singleColumn: this.singleColumn,
            borderedColumns: this.borderedColumns,
            hideTitle: this.hideTitle
        })

        this.sub = this.evaluationLayoutService.options$.subscribe(options => {
            this.contenidoLateralXL = options.contenidoLateralXL
            this.singleColumn = options.singleColumn
            this.borderedColumns = options.borderedColumns
            this.hideTitle = options.hideTitle

            this.setColumnsClasses()
            this.cdr.detectChanges()
        })

        this.subTemplate = this.evaluationLayoutService.templates$
            .pipe(observeOn(queueScheduler))
            .subscribe(templates => {
                this.templateObj = templates

                this.checkSlots()
                this.cdr.detectChanges()
            })
    }

    setColumnsClasses() {
        if (this.contenidoLateralXL) {
            this.firstColumnWidth = "col-sm-4 col-md-4"
            this.secondColumnWidth = "col-sm-8 col-md-8"
        } else if (this.singleColumn) {
            this.firstColumnWidth = ""
            this.secondColumnWidth = ""
        } else {
            this.firstColumnWidth = "col-sm-3 col-md-3"
            this.secondColumnWidth = "col-sm-9 col-md-9"
        }
    }

    checkSlots() {
        this.showContenido = this.evaluationLayoutContenido.nativeElement.children.length > 0
        this.showTopside = !!this.templateObj?.["topside"]
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
        this.subTemplate.unsubscribe()
    }
}
