<div class="row">
    <div class="col-lg-12">
        <ul class="nav nav-tabs">
            <li class="active">
                <a href="#tab1" role="tab" data-toggle="tab">
                    <span class="tab_text">Retroalimentación por escrito</span>
                </a>
            </li>
            <li>
                <a href="#tab2" role="tab" data-toggle="tab">
                    <span class="tab_text">Subir un archivo pdf</span>
                </a>
            </li>
        </ul>
    </div>
</div>
<br />

<div class="row">
    <div class="col-lg-12">
        <div class="tab-content">
            <!-- retroalimentacion por escrito -->
            <div id="tab1" class="active tab-pane clearfix" #tabContent>
                <form-input
                    *ngIf="form"
                    [form]="form"
                    [params]="params['texto']"
                    [(value)]="_retroalimentacion.texto"
                    key="texto"
                ></form-input>
                <div class="btn-bar">
                    <cui-button
                        class="btn_style"
                        (click)="cargarRetroalimentacion('html')"
                        [disabled]="processingFeedback"
                    >
                        {{ nombreButton }}&nbsp;
                        <fa name="check-square-o"></fa>
                    </cui-button>
                </div>
            </div>

            <!-- subir un archivo pdf -->
            <div id="tab2" class="tab-pane" #tabContent>
                <cui-section-headline *ngIf="showTitulo">Retroalimentación</cui-section-headline>
                <div
                    class="column-layout"
                    resize-aware
                    (currentSize)="adaptToSize($event)"
                    [class.bigSize]="changeView"
                >
                    <div class="feedback-options">
                        <p *ngIf="showTitulo">Envía retroalimentación a tus estudiantes a través de un archivo PDF.</p>
                        <div class="form-group">
                            <file [(ngModel)]="retroalimentacion.file" key="file" (change)="onChangeFile()"></file>
                            <div class="warning hide-warning" [class.show-warning]="wrongFileType">
                                <div class="warning-content">
                                    <fa name="warning" class="warning-icon"></fa>
                                    <div class="warning-message">
                                        <p>El archivo no corresponde a un PDF válido.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-bar">
                                <cui-button class="btn_style" (click)="goToShowPreview()">
                                    <ogr-icon name="upload"></ogr-icon>
                                    &nbsp;Subir archivo
                                </cui-button>
                            </div>
                        </div>
                        <ng-container *ngIf="showPreview">
                            <cui-section-headline>{{ titleConfirm }}</cui-section-headline>
                            <p>{{ descripcionConfirm }}</p>
                            <div class="btn-bar">
                                <loader
                                    *ngIf="processingFeedback"
                                    class="pushed pull-right"
                                    loaderType="default-xsmall"
                                ></loader>
                                <cui-button
                                    [disabled]="processingFeedback"
                                    class="btn_style"
                                    (click)="cargarRetroalimentacion('pdf')"
                                >
                                    {{ nombreButton }}&nbsp;
                                    <fa name="check-square-o"></fa>
                                </cui-button>
                            </div>
                            <div class="success" *ngIf="showSuccess">
                                <div class="success-content">
                                    <fa name="smile-o" class="success-icon"></fa>
                                    <p class="success-message">Retroalimentación enviada.</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="feedback-preview-wrap" *ngIf="showPreview">
                        <p class="preview-title" *ngIf="showTitulo">Vista previa retroalimentación actual</p>
                        <model-plain-value
                            *ngIf="retroalimentacion.fileSrc"
                            [params]="pdfParams"
                            [value]="retroalimentacion"
                            key="fileSrc"
                        ></model-plain-value>
                        <model-plain-value
                            *ngIf="!retroalimentacion.fileSrc && retroalimentacion.retroalimentacion"
                            [params]="pdfParams"
                            [value]="retroalimentacion"
                            key="retroalimentacion"
                        ></model-plain-value>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
