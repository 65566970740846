import { BaseModel, SaveType } from "@puntaje/shared/core"
import { CriterioAsignacion } from "./criterio_asignaciones.model"

export class UsuarioRecursoTipo extends BaseModel {
    public static className: string = "UsuarioRecursoTipo"
    public usuario_id: number
    public tipo_recurso: string

    @SaveType(() => CriterioAsignacion) criterio_asignaciones: CriterioAsignacion[]

    asignaturaIds(): number[] {
        const criterioAsignaturas = this.criterio_asignaciones.filter(ca => ca.criterio_type == "Asignatura")
        if (criterioAsignaturas.length == 0) {
            const criterio_clasificaciones = this.criterio_asignaciones.filter(
                ca => ca.criterio_type == "Clasificacion"
            )
            const ids = criterio_clasificaciones.map(c => (c.criterio as any).asignatura_id).filter(id => !!id)
            if (ids.length == 0) {
                return []
            } else {
                const distinct = (value, index, self) => {
                    return self.indexOf(value) === index
                }
                return ids.filter(distinct)
            }
        } else return criterioAsignaturas.map(as => as.criterio_id)
    }

    clasificacionTipoIds(): number[] {
        const tipos = this.criterio_asignaciones.filter(ca => ca.criterio_type == "ClasificacionTipo")
        if (tipos.length > 0) return tipos.map(t => t.criterio_id)

        const criterio_clasificaciones = this.criterio_asignaciones.filter(ca => ca.criterio_type == "Clasificacion")
        const ids = criterio_clasificaciones.map(c => (c.criterio as any).clasificacion_tipo_id)

        const distinct = (value, index, self) => {
            return self.indexOf(value) === index
        }

        return ids.filter(distinct)
    }

    clasificacionIds(): number[] {
        return this.criterio_asignaciones.filter(ca => ca.criterio_type == "Clasificacion").map(ca => ca.criterio_id)
    }
}
