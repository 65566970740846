import { Component, Input, OnInit, SimpleChanges } from "@angular/core"
import { Store } from "@ngrx/store"
import { AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { EvaluacionSugerida, EvaluacionSugeridas } from "@puntaje/puntaje/api-services"
import { selectAsignaturasById, State } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators"
import { I18nService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"

@Component({
    selector: "evaluacion-recomendada-alumno-card",
    templateUrl: "evaluacion-recomendada-alumno-card.component.html",
    styleUrls: ["evaluacion-recomendada-alumno-card.component.scss"]
})
export class EvaluacionRecomendadaAlumnoCardComponent implements OnInit {
    @Input() evaluacionSugerida: EvaluacionSugerida
    @Input() evaluacionTipo: string = "ensayo"
    asignatura: AsignaturaWithConfig
    asignaturasById$ = this.store.select(selectAsignaturasById).pipe(filter(x => !!x))
    sub: Subscription
    textButton: string

    baseUrl: string

    constructor(
        protected config: AppConfig,
        protected store: Store<State>,
        protected evaluacionesSugeridasService: EvaluacionSugeridas,
        protected translatorService: I18nService
    ) {}

    ngOnInit() {
        this.baseUrl = this.config.evaluaciones[this.evaluacionTipo].baseUrl
            ? this.config.evaluaciones[this.evaluacionTipo].baseUrl
            : "/evaluaciones"

        this.sub = this.asignaturasById$.subscribe(asignaturasById => {
            this.asignatura = asignaturasById[this.evaluacionSugerida.asignatura_id]
        })

        this.textButton = this.evaluacionSugerida.evaluacion_realizada
            ? this.translatorService.translate("evaluacion_recomendada.repetir_evaluacion", {})
            : this.translatorService.translate("evaluacion_recomendada.comenzar", {})
    }
    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
