import { Component, OnInit, ViewChild, Input, SimpleChanges } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { BaseChart, PieChart, ChartColorType, I18nService } from "@puntaje/shared/core"
import { Store, select } from "@ngrx/store"
import { State, selectSelectedAsignatura } from "@puntaje/puntaje/store"
import { Subscription, combineLatest } from "rxjs"

/*********************************
Gráfico de torta de desempeño promedio por tipo de respuesta por asignatura.
Recibe dataOmitidas: number, dataCorrectas: number, dataIncorrectas: number
**********************************/

@Component({
    selector: "grafico-desempeno-promedio",
    template: `
        <loading-layout #loadingLayout>
            <estadisticas-grafico [grafico]="grafico" *ngIf="!loadingLayout.loading"></estadisticas-grafico>
        </loading-layout>
    `
})
export class GraficoDesempenoPromedioComponent implements OnInit {
    @Input() asignatura: Asignatura
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() dataOmitidas: number
    @Input() dataCorrectas: number
    @Input() dataIncorrectas: number
    @Input() tipoEvaluacion: string
    grafico: BaseChart
    enableGraph: boolean = false
    subQuery: Subscription

    constructor(private translatorService: I18nService, protected store: Store<State>) {}

    ngOnInit() {
        this.subQuery = combineLatest([this.asignatura$]).subscribe(([asignatura]) => {
            this.asignatura = asignatura

            this.setGrafico()
        })
    }

    asignatura$ = this.store.pipe(select(selectSelectedAsignatura))

    setGrafico() {
        this.loadingLayout.standby()
        let g1: any = {}

        let abreviacion: string = ""
        if (this.asignatura) {
            if (this.tipoEvaluacion) {
                abreviacion = this.asignatura["confByEvaluacionTipo"]?.[this.tipoEvaluacion]?.abreviacion
            } else {
                abreviacion = this.asignatura.asignatura
            }
        } else {
            abreviacion = "todos"
        }

        g1.data = [
            {
                nombre: this.translatorService.translate("progreso.estadisticas.graficos_asignatura.correctas"),
                data: this.dataCorrectas || 0
            },
            {
                nombre: this.translatorService.translate("progreso.estadisticas.graficos_asignatura.incorrectas"),
                data: this.dataIncorrectas || 0
            },
            {
                nombre: this.translatorService.translate("progreso.estadisticas.graficos_asignatura.omitidas"),
                data: this.dataOmitidas || 0
            }
        ]
        g1.titulo = this.translatorService.translate("progreso.estadisticas.graficos_asignatura.titulo", {
            asignatura: abreviacion
        })
        g1.descripcion = this.translatorService.translate("progreso.estadisticas.graficos_asignatura.descripcion")
        g1.nombreSerie = this.translatorService.translate("progreso.estadisticas.graficos_asignatura.nombre", {
            asignatura: abreviacion
        })
        g1.tooltip = "{series.name}: <b>{point.percentage:.1f}%</b>"
        g1.label = "<b>{point.name}</b>: {point.percentage:.1f} %"
        g1.tipoColor = ChartColorType.Resultados
        this.grafico = new PieChart(g1)
        this.loadingLayout.ready()
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setGrafico()
    }

    ngOnDestroy(): void {
        this.subQuery.unsubscribe()
    }
}
