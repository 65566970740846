import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule, PaginatorModule, FormModule } from "@puntaje/shared/core"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { CUIContainersModule, CUIInputsModule } from "@puntaje/shared/cui"

import { HorariosComponent } from "./horarios/horarios.component"
import { HorariosEditComponent } from "./horarios-edit/horarios-edit.component"
import { HorariosNewComponent } from "./horarios-new/horarios-new.component"
import { HorarioComponent } from "./horario/horario.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormModule,
        UtilModule,
        PaginatorModule,
        CommonsLayoutsModule,
        Angular2FontawesomeModule,
        RouterModule,
        SharedModule,
        CUIContainersModule,
        CUIInputsModule
    ],
    declarations: [HorariosComponent, HorariosEditComponent, HorariosNewComponent, HorarioComponent],
    exports: [HorariosNewComponent, HorariosEditComponent, HorarioComponent, HorariosComponent]
})
export class HorariosModule {}
