import { OnInit, Component, Directive, Input, Output, EventEmitter } from "@angular/core"
import { NotificacionesPendingService } from "../notificaciones_pending.service"
import { NotificacionAccionesService } from "../notificacion_acciones.service"
import { Notificacion, Notificaciones } from "@puntaje/puntaje/api-services"
import { trigger, transition, style, animate, keyframes, query, state } from "@angular/animations"

@Component({
    templateUrl: "notificacion_layout.component.html",
    selector: "notificacion-layout",
    styleUrls: ["notificacion.component.scss"],
    animations: [
        trigger("slideWhenChecked", [
            state("slideRight", style({ opacity: 0, transform: "translateX(50px)" })),
            transition("* => slideRight", [
                animate(
                    "0.3s 0ms ease",
                    keyframes([
                        style({ opacity: 1, transform: "translateX(0)", offset: 0 }),
                        style({ opacity: 0, transform: "translateX(50px)", offset: 1.0 })
                    ])
                )
            ])
        ])
    ]
})
export class NotificacionLayoutComponent implements OnInit {
    @Input() notificacion
    @Input() enableMini: boolean = false
    @Input("disableMarkButton") disableMarkButton: boolean = false
    @Input() icono: string = "campana-o"
    @Input() tipoNotificacion: string = "default"
    animateExit = false
    slideState: string = "none"

    constructor(
        protected notificacionAccionesService: NotificacionAccionesService,
        protected notificacionesPendingService: NotificacionesPendingService,
        protected notificacionesService: Notificaciones
    ) {}

    ngOnInit() {}

    onClickChecked() {
        this.disableMarkButton = true
        this.slideState = "slideRight"
        //this.animateExit = true;
        let n = new Notificacion()
        n.fecha_visto = new Date() //el servidor recibe esta fecha, pero luego le pone otra desde allá
        this.notificacionesService.update(this.notificacion.id, n).then(() => {
            this.notificacionesPendingService.update()
            this.notificacionAccionesService.checked.next(this.notificacion.id)
        })
    }
}

@Directive({ selector: "notificacion-contenido, notificacion-acciones-footer" })
export class NotificacionLayoutComponentTags {}
