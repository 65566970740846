<ng-template #genericModalTemplate>
    <div class="modal-content">
        <div class="modal-header">
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Cerrar"
                *ngIf="!unskipable"
                (click)="genericModalRef.hide()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <h3 class="modal-title">
                <ng-content select="modal-titulo"></ng-content>
            </h3>
        </div>
        <div class="modal-body" [class.height-auto]="autoHeight" [class.justify]="justifyBody">
            <ng-content select="modal-contenido"></ng-content>
        </div>
        <div class="modal-footer" *ngIf="botones">
            <ng-content select="modal-botones"></ng-content>
        </div>
    </div>
</ng-template>
