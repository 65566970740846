import { Chart } from "./chart.interface"
import { BaseChart } from "./base_chart.model"
import { ChartType } from "./chart_type.enum"

export class BarChart extends BaseChart {
    // Gráfico de barras horizontales

    constructor(g: Chart) {
        super(g)
    }

    public setChartData() {
        //tipo
        this.tipo = ChartType.Bar
        this.chartData.chart = {
            ...this.chartData.chart,
            type: "bar"
        }
        //opciones de ploteo
        this.chartData.plotOptions = {
            bar: {
                dataLabels: {
                    enabled: true,
                    ...(this.formatter ? { formatter: this.formatter } : { format: "{y:.1f}" })
                }
            }
        }
        //EjeX
        if (this.ejeX) {
            let height = this.rowHeight * this.ejeX.length > 400 ? this.rowHeight * this.ejeX.length : null
            if (height) {
                this.chartData.chart.height = height
            }

            this.chartData.xAxis = {
                categories: this.ejeX,
                title: {
                    text: null
                },
                labels: {
                    step: 1
                }
            }
        }
        //EjeY
        if (this.ejeY) {
            this.chartData.yAxis = {
                min: this.ejeY.min ? this.ejeY.min : 0,
                title: {
                    text: this.ejeY.title,
                    align: "high"
                },
                labels: {
                    overflow: "justify"
                },
                tickInterval: this.ejeY.tickInterval,
                max: this.ejeY.max,
                allowDecimals: this.ejeY.allowDecimals
            }
        }
        //datos
        let data_array = []
        for (let d of this.data) {
            if (d.nombre && d.data != null && d.data != undefined) {
                let v = { name: d.nombre, data: d.data }
                data_array.push(v)
            }
        }

        this.chartData.series = data_array
    }
}
