import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Injectable()
export class CarrerasBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    constructor() {}

    search(universidad, sede, carrera, area, modalidad, infoAdicionales) {
        let params: any = {}

        let someQuery = false

        if (universidad) {
            someQuery = true
            params.universidad = { like: { universidad: `%${universidad}%` } }
        }
        if (sede) {
            someQuery = true
            params.sede = { like: { sede: `%${sede}%` } }
        }
        if (carrera) {
            someQuery = true
            params.carreraSede = { like: { alias: `%${carrera}%` } }
        }
        if (area) {
            someQuery = true
            params.carreraSede = { ...params.carreraSede, area }
        }
        if (modalidad) {
            someQuery = true
            params.carreraSede = { ...params.carreraSede, modalidad }
        }
        if (infoAdicionales.length > 0) {
            someQuery = true
            params.infoFilters = JSON.stringify(infoAdicionales)
        }

        this.searchParams.next(params)
    }
}
