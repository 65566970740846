import { Pipe, PipeTransform } from "@angular/core"
import { DomSanitizer, SafeHtml } from "@angular/platform-browser"

@Pipe({ name: "trustedhtml" })
export class TrustedHTMLPipe {
    constructor(private sanitizer: DomSanitizer) {}

    transform(html): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html)
    }
}
