import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core"
import { Notificacion, Notificaciones } from "@puntaje/puntaje/api-services"
import { NotificacionesPendingService } from "../../notificaciones_pending.service"
import { Router } from "@angular/router"
import { Observable, Subscription } from "rxjs"
import { AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { State, selectAsignaturasById } from "@puntaje/puntaje/store"
import { I18nService } from "@puntaje/shared/core"
import { Store, select } from "@ngrx/store"
import { filter } from "rxjs/operators"

@Component({
    selector: "notificacion-duda",
    templateUrl: "notificacion_duda.component.html",
    styleUrls: ["../notificacion.component.scss"]
})
export class NotificacionDudaComponent implements OnInit {
    @Input() notificacion: Notificacion
    @Input() enableMini: boolean = false
    @Input("disableMarkButton") disableMarkButton: boolean = false
    usuario_remitente: string = ""
    usuario_remitente_link: string = ""
    duda: any
    dudaCategoria: any
    fecha_creacion: Date
    nombre_evaluacion: string
    texto_duda: string = "ha contestado"
    animateExit: boolean = false
    @Output() markAsNotPending: EventEmitter<any> = new EventEmitter<any>()

    sub: Subscription
    asignaturasById$: Observable<{ [id: number]: AsignaturaWithConfig }> = this.store.pipe(
        select(selectAsignaturasById),
        filter(x => !!x)
    )

    constructor(
        protected notificacionesService: Notificaciones,
        protected notificacionesPendingService: NotificacionesPendingService,
        protected router: Router,
        protected store: Store<State>,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {}

    ngOnChanges() {
        if (this.notificacion) {
            this.setText()
            this.notificacion.notificacion = JSON.parse(this.notificacion.notificacion)
            let data = this.notificacion.notificacion as any
            this.duda = data.duda
            this.usuario_remitente = data.usuario.nombre + " " + data.usuario.apellido_paterno
            this.usuario_remitente_link = "/usuarios/" + data.usuario.id
            this.fecha_creacion = new Date(this.notificacion.created_at)
            if (data.duda_categoria) {
                this.dudaCategoria = data.duda_categoria
                this.setDudasCategorias()
            }
        }
    }

    setDudasCategorias() {
        this.sub = this.asignaturasById$.subscribe(asignaturasById => {
            if (this.dudaCategoria.asignatura_id) {
                this.dudaCategoria["asignatura"] = asignaturasById[this.dudaCategoria.asignatura_id]
            }
        })
    }

    setText() {
        if (this.notificacion.notificacion_tipo) {
            let tipo = this.notificacion.notificacion_tipo.notificacion_tipo
            switch (tipo) {
                case "duda":
                    this.texto_duda = this.i18nService.translate("notificaciones.ha_creado", {})
                    break
                case "respuesta_duda":
                    this.texto_duda = this.i18nService.translate("notificaciones.ha_contestado", {})
                    break
                case "respuesta_correcta_duda":
                    this.texto_duda = this.i18nService.translate("notificaciones.ha_marcado_c", {})
            }
        }
    }

    onClickLink() {
        if (this.notificacion.fecha_visto) {
            this.router.navigate([this.notificacion.link])
        } else {
            this.animateExit = true
            let n = new Notificacion()
            n.fecha_visto = new Date()
            this.notificacionesService.update(this.notificacion.id, n).then(() => {
                this.notificacionesPendingService.update()
                this.markAsNotPending.emit(this.notificacion.id)
                this.router.navigate([this.notificacion.link])
            })
        }
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
    }
}
