import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Injectable()
export class EstablecimientoReportesBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    constructor() {}

    search(establecimiento_reporte, establecimientoId) {
        let params: any = {}
        if (!establecimiento_reporte.id) {
            delete establecimiento_reporte.id
        }
        if (establecimiento_reporte.gte.fecha) {
            establecimiento_reporte.gte.fecha = establecimiento_reporte.gte.fecha.toISOString()
        } else {
            delete establecimiento_reporte.gte.fecha
        }
        if (establecimiento_reporte.lte.fecha) {
            establecimiento_reporte.lte.fecha = establecimiento_reporte.lte.fecha.toISOString()
        } else {
            delete establecimiento_reporte.lte.fecha
        }
        if (establecimiento_reporte.resuelto == undefined) {
            delete establecimiento_reporte.resuelto
        }
        if (establecimiento_reporte.tipo_contacto_id == undefined) {
            delete establecimiento_reporte.tipo_contacto_id
        }
        establecimiento_reporte.establecimiento_id = establecimientoId
        params = { establecimiento_reporte }
        this.searchParams.next(params)
    }
}
