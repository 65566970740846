<loading-layout #loadingLayout>
    <ng-container *ngIf="evaluacionesSugeridas && evaluacionesSugeridas.length > 0">
        <ng-container *ngIf="!showEvaluacionMultiple">
            <ng-container *ngFor="let ordenGrupo of evaluacionSugeridasByGrupo | keys; let j = index">
                <ng-container *ngFor="let nombreGrupo of evaluacionSugeridasByGrupo[ordenGrupo] | keys">
                    <h3 *ngIf="nombreGrupo">{{ nombreGrupo }}</h3>
                    <div class="card-grid">
                        <ng-container *ngFor="let es of evaluacionSugeridasByGrupo[ordenGrupo][nombreGrupo]">
                            <evaluacion-recomendada-alumno-card
                                *ngIf="es"
                                [evaluacionTipo]="evaluacionTipo"
                                [evaluacionSugerida]="es"
                            ></evaluacion-recomendada-alumno-card>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <div class="card-grid" *ngIf="showEvaluacionMultiple">
            <ng-container *ngFor="let es of evaluacionesSugeridas">
                <simulacro-recomendado-alumno-card
                    [evaluacionTipo]="evaluacionTipo"
                    [evaluacionSugerida]="es"
                ></simulacro-recomendado-alumno-card>
            </ng-container>
        </div>
        <br />
    </ng-container>
    <paginator [getList]="getEvaluacionesSugeridas" [per]="30"></paginator>
    <p *ngIf="evaluacionesSugeridas && evaluacionesSugeridas.length == 0" class="not-found">
        {{ "practicar.recomendados.no_encontrados" | t }}
    </p>
</loading-layout>
