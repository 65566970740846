import { Chart } from "./chart.interface"
import { BaseChart } from "./base_chart.model"
import { ChartType } from "./chart_type.enum"

export class SplineChart extends BaseChart {
    // Gráfico de spline

    constructor(g: Chart) {
        super(g)
    }

    public setChartData() {
        //tipo
        this.tipo = ChartType.Spline
        //opciones de ploteo
        this.chartData.plotOptions = {
            series: {
                label: {
                    connectorAllowed: false
                },
                pointStart: this.chartData.pointStart
            }
        }
        //EjeX
        if (this.ejeX) {
            this.chartData.xAxis = {
                ...this.ejeX,
                type: this.ejeX.type,
                title: this.ejeX.title
            }

            if (this.ejeX.type == "datetime") {
                this.chartData.xAxis = {
                    ...this.chartData.xAxis,
                    dateTimeLabelFormats: {
                        month: "%e. %b",
                        year: "%b"
                    },
                    title: {
                        text: "Fecha"
                    },
                    startOnTick: false,
                    endOnTick: false
                }
            }
        }
        //EjeY
        if (this.ejeY) {
            this.chartData.yAxis = {
                min: this.ejeY.min,
                max: this.ejeY.max,
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: this.ejeY.title
                }
            }
        }
        if (this.tooltip) {
            this.chartData.tooltip = {
                pointFormat: this.tooltip
            }
        }
        this.chartData.plotOptions = {
            spline: {
                marker: {
                    enabled: true
                }
            }
        }
        //datos
        let data_array = []
        for (let d of this.data) {
            if (d.nombre && d.data != null && d.data != undefined) {
                let v = { name: d.nombre, data: d.data }
                data_array.push(v)
            }
        }
        this.chartData.series = data_array
        this.chartData.exporting = { enabled: false }
    }
}
