import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Ponderable } from "./ponderables.model"

export class Ponderacion extends BaseModel {
    public static className: string = "Ponderacion"

    public ponderacion: string
    public ponderableId: number
    public carreraSedeId: number

    @SaveType(() => Ponderable) ponderable: Ponderable
}
