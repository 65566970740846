import { Component, Input, SimpleChanges, OnChanges, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
import { Subscription, combineLatest } from "rxjs"
import {
    TipoInstrumentoPlataformas,
    Plataformas,
    Plataforma,
    TipoInstrumentos,
    TipoInstrumento,
    TipoInstrumentoPlataforma
} from "@puntaje/nebulosa/api-services"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import {
    State,
    selectEvaluacionTiposEvaluacionTipos,
    selectSelectedEvaluacionTipo,
    SetSelectedEvaluacionTipo,
    CheckAsignaturaInEvaluacionTipo,
    selectAsignaturasByEvaluacionTipo
} from "@puntaje/puntaje/store"
import { map } from "rxjs/operators"

@Component({
    selector: "menu-evaluacion-tipo",
    templateUrl: "./menu-evaluacion-tipo.component.html",
    styleUrls: ["./menu-evaluacion-tipo.component.scss"]
})
export class MenuEvaluacionTipoComponent {
    evaluacionTipos$ = combineLatest([
        this.store.pipe(select(selectEvaluacionTiposEvaluacionTipos)),
        this.store.pipe(select(selectAsignaturasByEvaluacionTipo))
    ]).pipe(
        map(([evaluacionTipos, asignaturasByEvaluacionTipo]) =>
            evaluacionTipos.filter(et => asignaturasByEvaluacionTipo?.[et]?.length > 0)
        )
    )
    evaluacionTipo$ = this.store.pipe(select(selectSelectedEvaluacionTipo))
    sub: Subscription
    plataforma: Plataforma
    tipoInstrumentos: TipoInstrumento[]
    evalTiposDict: any = {}

    constructor(
        protected store: Store<State>,
        private plataformasService: Plataformas,
        private tipoInstrumentoPlataformasService: TipoInstrumentoPlataformas,
        private tipoInstrumentosService: TipoInstrumentos
    ) {}

    ngOnInit() {
        this.sub = combineLatest([this.evaluacionTipos$, this.evaluacionTipo$]).subscribe(
            async ([evaluacionTipos, evaluacionTipo]) => {
                const plataformaParams = {
                    plataforma: {
                        plataforma: config.plataforma.name
                    }
                }

                const plataformas: Plataforma[] = await this.plataformasService.where(plataformaParams)
                this.plataforma = plataformas[0]

                const tipoInstrumentoParams = {
                    tipo_instrumento: {
                        tipo_instrumento: evaluacionTipos
                    }
                }

                const tipoInstrumentos: TipoInstrumento[] = await this.tipoInstrumentosService.where(
                    tipoInstrumentoParams
                )
                this.tipoInstrumentos = tipoInstrumentos

                const tipoInstrumentoPlataformasParams = {
                    tipo_instrumento_plataforma: {
                        plataforma_id: this.plataforma.id,
                        tipo_instrumento_id: this.tipoInstrumentos.map(ti => ti.id)
                    }
                }

                const tipoInstrumentoPlataformas: TipoInstrumentoPlataforma[] =
                    await this.tipoInstrumentoPlataformasService.where(tipoInstrumentoPlataformasParams)
                let evaluacionTipoAliases = this.tipoInstrumentos.map(ti => {
                    let tip = tipoInstrumentoPlataformas.find(t => t.tipo_instrumento_id == ti.id)
                    if (tip && tip.alias) {
                        this.evalTiposDict[ti.tipo_instrumento] = tip.alias
                        return tip.alias
                    } else {
                        this.evalTiposDict[ti.tipo_instrumento] = ti.tipo_instrumento
                        return ti.tipo_instrumento
                    }
                })
            }
        )
    }

    selectEvaluacionTipo(selectedEvaluacionTipo) {
        this.store.dispatch(new SetSelectedEvaluacionTipo({ selectedEvaluacionTipo }))
        this.store.dispatch(new CheckAsignaturaInEvaluacionTipo({ evaluacionTipo: selectedEvaluacionTipo }))
    }
}
