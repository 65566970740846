import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"

export class PremiumUsuario extends BaseModel {
    public id: number
    public usuario_id: number
    public fecha_inicio: Date
    public fecha_termino: Date
    public activo: boolean

    @SaveType(() => Usuario) public usuario: Usuario

    constructor() {
        super()
    }

    public toString() {
        return this.id
    }
}
