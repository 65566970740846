import { BaseModel } from "./base.model"
import { SaveType } from "../util/decorators"

export class S3 extends BaseModel {
    @SaveType() public data: Object
    keys: string[] = ["AWSAccessKeyId", "Content-Type", "acl", "key", "policy", "signature"]

    constructor() {
        super()
    }

    public toString() {
        return this.id.toString()
    }

    public getFormData = (): FormData => {
        let object: S3 = this
        let formData: FormData = new FormData()
        for (var key in this.keys) {
            formData.append(this.keys[key], object[this.keys[key]])
        }
        return formData
    }
}
