import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ListaAsignaturaEvaluacionTiposComponent } from "./lista-asignatura-evaluacion-tipos/lista-asignatura-evaluacion-tipos.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { UtilModule, FormModule, ModelViewModule, PaginatorModule, TranslationsModule } from "@puntaje/shared/core"
import { AsignaturaEvaluacionTiposToolnavbarComponent } from "./asignatura-evaluacion-tipos-toolnavbar/asignatura-evaluacion-tipos-toolnavbar.component"
import { AsignaturaEvaluacionTiposNewComponent } from "./asignatura-evaluacion-tipos-new/asignatura-evaluacion-tipos-new.component"
import { AsignaturaEvaluacionTiposEditComponent } from "./asignatura-evaluacion-tipos-edit/asignatura-evaluacion-tipos-edit.component"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { ApiModule } from "@puntaje/puntaje/api-services"

@NgModule({
    declarations: [
        ListaAsignaturaEvaluacionTiposComponent,
        AsignaturaEvaluacionTiposToolnavbarComponent,
        AsignaturaEvaluacionTiposNewComponent,
        AsignaturaEvaluacionTiposEditComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        ModelViewModule,
        PaginatorModule,
        TranslationsModule
    ],
    exports: [
        ListaAsignaturaEvaluacionTiposComponent,
        AsignaturaEvaluacionTiposToolnavbarComponent,
        AsignaturaEvaluacionTiposNewComponent,
        AsignaturaEvaluacionTiposEditComponent
    ]
})
export class AsignaturaEvaluacionTiposModule {}
