import { Component, Input, ViewChild } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Evaluaciones, Evaluacion } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "imprimir-ensayo-externo",
    templateUrl: "imprimir_ensayo_externo.component.html",
    styleUrls: ["imprimir_ensayo_externo.component.scss"]
})
export class InprimirEnsayoExternoComponent {
    @ViewChild("loadingLayoutNothing", { static: true }) loadingLayoutNothing: LoadingLayoutComponent
    @Input() evaluacionId: number
    message = "Estamos imprimiendo tu ensayo, espera un momento."

    constructor(protected evaluacionesService: Evaluaciones, protected authService: AuthService) {}

    ngOnInit() {
        this.loadingLayoutNothing.standby()
        this.evaluacionesService.find(this.evaluacionId).then((e: Evaluacion) => {
            this.imprimir(e)
        })
    }

    logInfoLatex(statusCodeLambda, exitStatus, stdout, stderr) {
        console.log("STATUS CODE LAMBDA")
        console.log("%c " + statusCodeLambda, "color: blue")
        console.log("EXIT STATUS LATEX")
        console.log("%c " + exitStatus, "color: blue")
        console.log("SALIDA ESTANDAR LATEX")
        console.log("%c " + stdout, "color: blue")
        console.log("SALIDA DE ERRORES LATEX")
        console.log("%c " + stderr, "color: crimson")
    }

    createLinkAndOpen(link, downloadString) {
        const a = document.createElement("a")
        a.download = downloadString
        a.href = link
        a.click()
    }

    imprimir(e: Evaluacion) {
        let params: any = {}
        params.plataforma = config.plataforma.name
        this.evaluacionesService.enableIgnoreModel()
        this.evaluacionesService.imprimir(e.id, params).then((obj: any) => {
            this.evaluacionesService.disableIgnoreModel()
            const info = obj.info
            this.logInfoLatex(info.statusCode, info.exit_status, info.stdout, info.stderr)
            const downloadString = "evaluacion_" + e.id + ".pdf"

            this.createLinkAndOpen(info.link, downloadString)
            this.message = "¡Ya estamos listos!"
            this.loadingLayoutNothing.ready()
        })
    }
}
