<cui-table *ngIf="query" [withPaginator]="true" [paginatorGetList]="setData" [verticalOnBreak]="700">
    <tr cui-table-header-row>
        <th>{{ "orientacion.carreras.buscador_carreras.carrera" | t }}</th>
        <th>{{ "orientacion.carreras.buscador_carreras.universidad" | t }}</th>
        <th>{{ "orientacion.carreras.buscador_carreras.sede" | t }}</th>
        <th class="actions last"></th>
    </tr>
    <tr cui-table-data-row *ngFor="let c of carreraSedes; let i = index">
        <td class="carrera">
            <a [routerLink]="['/carrera_sedes', c.id]">{{ c.alias }}</a>
        </td>
        <td class="universidad">{{ c.sede.universidad.universidad }}</td>
        <td class="sede">{{ c.sede.sede }}</td>
        <td class="actions last">
            <cui-button-link class="btnVer" size="xsmall" [routerLinkValue]="['/carrera_sedes', c.id]">
                <fa name="eye" class="icono left-icon"></fa>
                {{ "orientacion.carreras.buscador_carreras.ver" | t }}
            </cui-button-link>
            <cui-button
                class="btnSeguir"
                size="xsmall"
                *ngIf="c.preferenciaCarreraSedes.length == 0"
                (click)="seguirCarreraSede(c)"
            >
                <fa name="heart-o" class="icono left-icon"></fa>
                {{ "orientacion.carreras.buscador_carreras.seguir" | t }}
            </cui-button>
            <span *ngIf="c.preferenciaCarreraSedes.length > 0" class="siguiendo" (click)="dejarSeguirCarreraSede(c)">
                <fa name="heart-o" class="icono"></fa>
                {{ "orientacion.carreras.buscador_carreras.siguiendo" | t }}
            </span>
        </td>
    </tr>
    <cui-table-no-data>{{ "orientacion.carreras.buscador_carreras.not_found" | t }}</cui-table-no-data>
</cui-table>
