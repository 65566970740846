import { ModuleWithProviders, NgModule } from "@angular/core"
import { Areas } from "./areas.service"
import { Audiovisuales } from "./audiovisuales.service"
import { CarreraSedes } from "./carrera_sedes.service"
import { Carreras } from "./carreras.service"
import { Facultades } from "./facultades.service"
import { InfoAdicionales } from "./info_adicionales.service"
import { Lugares } from "./lugares.service"
import { PreferenciaAreas } from "./preferencia_areas.service"
import { PreferenciaCarreraSedes } from "./preferencia_carrera_sedes.service"
import { PreferenciaUniversidades } from "./preferencia_universidades.service"
import { Sedes } from "./sedes.service"
import { TipoAudiovisuales } from "./tipo_audiovisuales.service"
import { Universidades } from "./universidades.service"
import { CarrerasAuthService } from "./carreras_auth.service"
import { CarrerasBaseService } from "./carreras_base.service"
import { SolicitudInformaciones } from "./solicitud_informaciones.service"
import { UniversidadRecomendadas } from "./universidad_recomendadas.service"
import { Ponderables } from "./ponderables.service"

@NgModule({
    imports: [],
    declarations: [],
    exports: []
})
export class CarrerasApiModule {
    static forRoot(): ModuleWithProviders<CarrerasApiModule> {
        return {
            ngModule: CarrerasApiModule,
            providers: [
                Areas,
                Audiovisuales,
                CarreraSedes,
                Carreras,
                Facultades,
                InfoAdicionales,
                Lugares,
                PreferenciaAreas,
                PreferenciaCarreraSedes,
                PreferenciaUniversidades,
                SolicitudInformaciones,
                Sedes,
                TipoAudiovisuales,
                Universidades,
                CarrerasAuthService,
                CarrerasBaseService,
                UniversidadRecomendadas,
                Ponderables
            ]
        }
    }
}
