import { AppConfig, BaseModel, SaveType } from "@puntaje/shared/core"
import { GrupoUsuario } from "./grupo_usuarios.model"
import { Asociacion } from "./asociaciones.model"
import { AsociacionEstablecimiento } from "./asociacion_establecimientos.model"
import { EstablecimientoUsuario } from "./establecimiento_usuarios.model"
import { EstablecimientoMonitor } from "./establecimiento_monitores.model"
import { Usuario } from "./usuarios.model"

import { EstablecimientoChile } from "./establecimiento_chile.model"
import { EstablecimientoColombia } from "./establecimiento_colombia.model"
import { EstablecimientoMexico } from "./establecimiento_mexico.model"
import { Lugar } from "./lugares.model"
import { EscalaEvaluacion } from "./escala_evaluaciones.model"
import { EstablecimientoBrasil } from "./establecimiento_brasil.model"

declare const config: AppConfig

export class Establecimiento extends BaseModel {
    public static className: string = "Establecimiento"

    public establecimiento: string
    public static establecimiento_chile = EstablecimientoChile
    public static establecimiento_colombia = EstablecimientoColombia
    public static establecimiento_mexico = EstablecimientoMexico
    public static establecimiento_brasil = EstablecimientoBrasil
    lugar_id: number
    escala_evaluacion_id: number
    rbd: number
    activo: boolean

    encargado_nombre: string

    @SaveType(() => Asociacion) public asociaciones: Asociacion[]
    @SaveType(() => AsociacionEstablecimiento) public asociacion_establecimientos: AsociacionEstablecimiento[]

    @SaveType(() => EstablecimientoUsuario) public establecimiento_usuarios: EstablecimientoUsuario[]
    @SaveType(() => Usuario) public usuarios: Usuario[]

    @SaveType(() => EstablecimientoMonitor) public establecimiento_monitores: EstablecimientoMonitor[]
    @SaveType(() => Usuario) public monitores: Usuario[]

    @SaveType(() => EstablecimientoChile) establecimiento_chile: EstablecimientoChile
    @SaveType(() => EstablecimientoColombia) establecimiento_colombia: EstablecimientoColombia
    @SaveType(() => EstablecimientoMexico) establecimiento_mexico: EstablecimientoMexico
    @SaveType(() => EstablecimientoBrasil) establecimiento_brasil: EstablecimientoBrasil

    @SaveType(() => Lugar) lugar: Lugar

    @SaveType(() => EscalaEvaluacion) escala_evaluacion: EscalaEvaluacion

    constructor(empty = false) {
        super()

        if (!empty) {
            this.asociaciones = []
            this.asociacion_establecimientos = []
        }
    }

    @SaveType(() => GrupoUsuario) grupo_usuarios: GrupoUsuario[]

    public toString() {
        return this.establecimiento
    }

    public toStringRBD() {
        const rbd = this.rbd ? this.rbd : this.establecimiento_chile ? this.establecimiento_chile.rbd : 0

        return this.establecimiento + " - (RBD: " + rbd + ")"
    }

    public toStringWithIdPais() {
        const establecimientoPais = this.getEstablecimientoPais()

        const str =
            establecimientoPais == undefined || establecimientoPais == null
                ? "No aplica"
                : establecimientoPais.getFieldName() + ": " + establecimientoPais.getFieldValue()

        return this.establecimiento ? this.establecimiento + " - " + str : ""
    }

    public toStringIdPais() {
        const establecimientoPais = this.getEstablecimientoPais()

        return establecimientoPais.getFieldValue() + " - " + this.establecimiento
    }

    public AgregarAsociacion() {
        const a = new AsociacionEstablecimiento()
        this.asociacion_establecimientos.push(a)
    }

    public getEstablecimientoPais() {
        return this["establecimiento_" + config.plataforma.pais]
    }
}
