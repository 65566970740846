import { LanguagesHelper } from "./languages.helper"
import { PipeTransform, Pipe, Inject } from "@angular/core"
import { Diccionario } from "./diccionario.interface"

@Pipe({ name: "genderize" })
export class GenderizePipe implements PipeTransform {
    constructor(@Inject("DICCIONARIO") private diccionario: Diccionario) {}

    transform(word: string, base: string, locale: string = "es") {
        let languageHelper = LanguagesHelper.getHelper(locale)
        let genero: "f" | "m" = "m"
        let diccionario = this.diccionario["tipoInstrumento"]
        // checkear si el string es un tipo de instrumento y si existe en el diccinario
        if (diccionario && diccionario[base]) {
            genero = diccionario[base]["genero"]
        } else {
            genero = languageHelper.guessGenero(base)
        }
        return languageHelper.genderize(word, genero)
    }
}
