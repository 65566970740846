<div class="google-login">
    <cui-button
        [isBlock]="true"
        type="button"
        [ngClass]="!googleBtnDarkTheme ? 'google-btn-light btn_style' : 'google-btn-dark btn_style'"
        (click)="loginGoogle()"
    >
        <img
            *ngIf="showIcono"
            class="google-logo"
            src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/puntaje/landing/google_logo.png"
        />
        &nbsp;
        <span style="padding-left: 8px" [innerHTML]="'landing.login.btn_google' | t | trustedhtml"></span>
    </cui-button>
</div>

<ng-container *ngIf="openModal && validarIdentificador">
    <generic-modal [buttonClicked]="openModal">
        <modal-titulo>
            <ng-container *ngIf="pais === 'colombia'; else default">
                <div class="modalTitulo">Valida tu número de identificación</div>
            </ng-container>
            <ng-template #default>
                <div class="modalTitulo">Validar {{ identificador ? identificador : identificador_pais }}</div>
            </ng-template>
        </modal-titulo>
        <modal-contenido>
            <form novalidate [formGroup]="usuarioPaisForm" (ngSubmit)="enviar()">
                <div class="form-group">
                    <ng-container *ngIf="pais === 'colombia'; else default">
                        <label>Ingresa tu número de identificación</label>
                    </ng-container>
                    <ng-template #default>
                        <label>Ingrese su {{ identificador ? identificador : identificador_pais }}</label>
                    </ng-template>
                    <input
                        id="identificador"
                        type="text"
                        formControlName="identificador"
                        class="form-control"
                        [placeholder]="placeholder"
                        aria-describedby="flashMensajeError"
                    />
                    <br />
                    <ng-container *ngIf="pais === 'brasil'; else default">
                        <p *ngIf="showMensaje">
                            Conta existente. Esqueceu a senha?
                            <a (click)="forgotPassword()">Clique aqui</a>
                            ou faça login com a sua conta. Caso não lembre a senha envie um e-mail para contacto@ogr.cl
                        </p>
                    </ng-container>
                    <ng-container>
                        <p *ngIf="showMensaje">
                            Tu cuenta ya existe ¿Olvidaste tu contraseña?
                            <a (click)="forgotPassword()">Haz click aquí</a>
                            o inicia sesión con tu cuenta. Si no recuerdas tu contraseña, escribe a contacto@ogr.cl
                        </p>
                    </ng-container>
                </div>
                <div class="btn-container">
                    <cui-button class="btn_style" type="submit">Enviar</cui-button>
                </div>
            </form>
        </modal-contenido>
    </generic-modal>
</ng-container>
