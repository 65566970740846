import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { ModalModule } from "ngx-bootstrap/modal"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { RouterModule } from "@angular/router"
import { CUIBasicLayoutComponent } from "./cui-basic-layout/cui-basic-layout.component"
import { CUILayoutWithSidemenuComponent } from "./cui-layout-with-sidemenu/cui-layout-with-sidemenu.component"
import { CUITwoColumnsLayoutComponent } from "./cui-two-columns-layout/cui-two-columns-layout.component"
import { LSlotContentComponent } from "./cui-slots/l-slot-content.component"
import { LOverTitleComponent } from "./cui-slots/l-over-title.component"
import { LTopsideComponent } from "./cui-slots/l-topside.component"
import { LTituloComponent } from "./cui-slots/l-titulo.component"
import { LSubtituloComponent } from "./cui-slots/l-subtitulo.component"
import { LTabsComponent } from "./cui-slots/l-tabs.component"
import { LTopContenidoComponent } from "./cui-slots/l-top-contenido.component"
import { LToolnavbarComponent } from "./cui-slots/l-toolnavbar.component"
import { LContenidoLateralComponent } from "./cui-slots/l-contenido-lateral.component"
import { LTituloContenidoLateralComponent } from "./cui-slots/l-titulo-contenido-lateral.component"
import { LContentOverTitleComponent } from "./cui-slots/l-content-over-title.component"
import { CUITextsModule } from "../texts/texts.module"
import { LMiddleGreySectionComponent } from "./cui-slots/l-middle-grey-section.component"
import { LContenidoExtraComponent } from "./cui-slots/l-contenido-extra.component"
import { LSlotComponent } from "./cui-slots/l-slot.component"
import { CUILoggedLayoutComponent } from "./cui-logged-layout/cui-logged-layout.component"
import { CUILoggedLayoutSidebarComponent } from "./cui-logged-layout-sidebar/cui-logged-layout-sidebar.component"
import { CUIEvaluationLayoutComponent } from "./cui-evaluation-layout/cui-evaluation-layout.component"
import { CUIRestrictedLayoutComponent } from "./cui-restricted-layout/cui-restricted-layout.component"
import { CUILayoutTagsDirective } from "./cui-layout-tags.directive"
import { CUISharedLayoutComponent } from "./cui-shared-layout/cui-shared-layout.component"
import { CUISimpleSharedLayoutComponent } from "./cui-simple-shared-layout/cui-simple-shared-layout.component"
import { LazyLoadImageModule } from "ng-lazyload-image"

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        Angular2FontawesomeModule,
        ModalModule,
        ProgressbarModule,
        PopoverModule,
        TooltipModule,
        CUITextsModule,
        LazyLoadImageModule
    ],
    declarations: [
        CUIBasicLayoutComponent,
        CUILayoutWithSidemenuComponent,
        CUITwoColumnsLayoutComponent,
        LSlotContentComponent,
        LOverTitleComponent,
        LTopsideComponent,
        LTituloComponent,
        LSubtituloComponent,
        LTabsComponent,
        LTopContenidoComponent,
        LToolnavbarComponent,
        LMiddleGreySectionComponent,
        LContenidoExtraComponent,
        LContenidoLateralComponent,
        LTituloContenidoLateralComponent,
        LContentOverTitleComponent,
        LSlotComponent,
        CUILoggedLayoutComponent,
        CUILoggedLayoutSidebarComponent,
        CUIEvaluationLayoutComponent,
        CUIRestrictedLayoutComponent,
        CUISharedLayoutComponent,
        CUISimpleSharedLayoutComponent,
        CUILayoutTagsDirective
    ],
    exports: [
        CUIBasicLayoutComponent,
        CUILayoutWithSidemenuComponent,
        CUITwoColumnsLayoutComponent,
        LSlotContentComponent,
        LOverTitleComponent,
        LTopsideComponent,
        LTituloComponent,
        LSubtituloComponent,
        LTabsComponent,
        LTopContenidoComponent,
        LToolnavbarComponent,
        LMiddleGreySectionComponent,
        LContenidoExtraComponent,
        LContenidoLateralComponent,
        LTituloContenidoLateralComponent,
        LContentOverTitleComponent,
        LSlotComponent,
        CUILoggedLayoutComponent,
        CUILoggedLayoutSidebarComponent,
        CUIEvaluationLayoutComponent,
        CUIRestrictedLayoutComponent,
        CUISharedLayoutComponent,
        CUISimpleSharedLayoutComponent,
        CUILayoutTagsDirective
    ],
    providers: []
})
export class CUILayoutsModule {
    static forRoot(): ModuleWithProviders<CUILayoutsModule> {
        return {
            ngModule: CUILayoutsModule,
            providers: []
        }
    }
}
