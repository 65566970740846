import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Injectable()
export class EstablecimientoContactosBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    constructor() {}

    search(establecimiento_contacto, establecimientoId, tipo_contacto_id) {
        let params: any = {}
        if (!establecimiento_contacto.id) {
            delete establecimiento_contacto.id
        }
        if (establecimiento_contacto.gte.fecha) {
            establecimiento_contacto.gte.fecha = establecimiento_contacto.gte.fecha.toISOString()
        } else {
            delete establecimiento_contacto.gte.fecha
        }
        if (establecimiento_contacto.lte.fecha) {
            establecimiento_contacto.lte.fecha = establecimiento_contacto.lte.fecha.toISOString()
        } else {
            delete establecimiento_contacto.lte.fecha
        }
        if (establecimiento_contacto.efectivo == undefined) {
            delete establecimiento_contacto.efectivo
        }
        if (establecimiento_contacto.resuelto == undefined) {
            delete establecimiento_contacto.resuelto
        }

        establecimiento_contacto.establecimiento_id = establecimientoId
        params = { establecimiento_contacto }

        if (tipo_contacto_id != undefined && tipo_contacto_id != null) {
            params["tipo_contacto"] = {
                id: tipo_contacto_id
            }
        }

        this.searchParams.next(params)
    }
}
