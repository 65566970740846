import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Universidad } from "./universidades.model"
import { CarreraSede } from "./carrera_sedes.model"

export class Facultad extends BaseModel {
    public static className: string = "Facultad"

    public facultad: string
    public universidadId: number

    @SaveType(() => Universidad) universidad: Universidad
    @SaveType(() => CarreraSede) carreraSedes: CarreraSede[]

    public toString() {
        return this.facultad
    }
}
