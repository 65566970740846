import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { Duda } from "./dudas.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class Dudas extends PuntajeBaseService<Duda> {
    tableName = "dudas"
    singularTableName = "duda"
    modelClass = Duda

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public like(dudaId: number, params: any) {
        return this.one(dudaId).concatExtra("like").post(params)
    }

    public dislike(dudaId: number, params: any) {
        return this.one(dudaId).concatExtra("dislike").post(params)
    }

    public respondidas() {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("respondidas")
            .get()
            .then(response => {
                this.disableIgnoreModel()

                return response
            })
    }
}
