import { Input, ViewChild, TemplateRef, SimpleChanges, SimpleChange, Directive } from "@angular/core"

@Directive()
export class View {
    @Input() value
    @Input() label
    @Input() key
    @Input() options
    @Input() params

    @ViewChild("viewLabel", { read: TemplateRef, static: true }) viewLabel: TemplateRef<any>
    @ViewChild("viewValue", /* TODO: add static flag */ { read: TemplateRef }) viewValue: TemplateRef<any>

    ngOnChanges(changes: any) {}
}
