<div *ngIf="estilo === 'default'" class="sentiment">
    <div class="left-btn">
        <button
            type="button"
            class="btn btn-link no-outline btn-sentiment"
            (click)="like()"
            [class.active]="isLikeValue"
        >
            <span>
                <fa name="thumbs-up"></fa>
                {{ likes }}
            </span>
        </button>
    </div>
    <div class="right-btn">
        <button
            type="button"
            class="btn btn-link no-outline btn-sentiment"
            (click)="dislike()"
            [class.active]="isDislikeValue"
        >
            <span>
                <fa name="thumbs-down"></fa>
                {{ dislikes }}
            </span>
        </button>
    </div>
    <div *ngIf="showProgressBar" class="sentiment-bar">
        <div class="progress">
            <div sentimentBar [width]="likesPercent" class="progress-bar progress-bar-success">
                <span class="sr-only">{{ likesPercent }}% likes</span>
            </div>
            <div sentimentBar [width]="dislikesPercent" class="progress-bar progress-bar-danger">
                <span class="sr-only">{{ dislikesPercent }}% dislikes</span>
            </div>
        </div>
    </div>
</div>
