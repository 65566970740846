import { Component, ElementRef, ViewEncapsulation, HostListener } from "@angular/core"
import { CUIButtonBaseComponent } from "./cui-button-base.component"

/***********
 * Por qué hay un ngIf=true?
 * Porque si no, en algunos casos (cuando los componentes cargan rápido y no hay loadingLayouts por ejemplo),
 * el estilo del botón transiciona de forma visible entre no tener estilo y tenerlo
 * Arreglar si se encuentra la causa de esto
 ***********/

@Component({
    selector: "[cui-button],cui-button",
    template: `
        <button
            *ngIf="true"
            [type]="type"
            [class.btn]="isBtn"
            [class.block]="isBlock"
            class="{{ size }} loader-{{ loaderPosition }} {{
                buttonTypes[buttonType] ? buttonTypes[buttonType] : buttonClass
            }}"
            (click)="onClickButton($event)"
            [disabled]="disabled"
            [ngClass]="{
                xsmall: size === 'xsmall',
                small: size === 'small',
                large: size === 'large',
                xlarge: size === 'xlarge',
                'btn-xs': size === 'xsmall' && isBtn,
                'btn-sm': size === 'small' && isBtn,
                'btn-lg': size === 'large' && isBtn,
                'btn-xl': size === 'xlarge' && isBtn
            }"
        >
            <ng-content select=".left-icon"></ng-content>
            <ng-content></ng-content>
            <ng-content select=".right-icon"></ng-content>
        </button>
        <span *ngIf="isLoading" class="loader {{ loaderPosition }}" [class.loaderLight]="loaderLight"></span>
    `,
    styleUrls: ["cui-button.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUIButtonComponent extends CUIButtonBaseComponent {
    @HostListener("click", ["$event"]) onClick(e) {
        if (this.type != "submit") {
            e.preventDefault()
            e.stopPropagation()
        }
    }
    constructor(protected el: ElementRef) {
        super(el)
    }
}
