import { BaseModel, SaveType } from "@puntaje/shared/core"
import { GrupoUsuario } from "./grupo_usuarios.model"
import { Usuario } from "./usuarios.model"
import { EvaluacionMultiple } from "./evaluacion_multiples.model"

export class EvaluacionMultipleUsuario extends BaseModel {
    usuario_id: number
    evaluacion_multiple_id: number

    receptor_type: string
    receptor_id: number

    @SaveType(() => GrupoUsuario) grupo_usuario: GrupoUsuario
    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => EvaluacionMultiple) evaluacion_multiple: EvaluacionMultiple
}
