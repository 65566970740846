import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Categoria } from "./categorias.model"

export class Logro extends BaseModel {
    logro: string
    logo: string
    codigo: string
    experiencia: number
    monedas: number
    categoria_id: number
    plataforma_id: number
    descripcion: string
    url: string

    @SaveType(() => Categoria) categoria: Categoria
}
