import { BaseModel, SaveType } from "@puntaje/shared/core"
import { PushNotificacionTipo } from "./push-notificacion-tipos.model"
import { PushNotificacionUsuario } from "./push-notificacion-usuarios.model"

export class PushNotificacion extends BaseModel {
    public push_notificacion: string
    public titulo: string
    public descripcion: string
    public link: string
    public push_notificacion_tipo_id: number
    public fecha: string | Date

    @SaveType(() => PushNotificacionTipo) public push_notificacion_tipo: PushNotificacionTipo
    @SaveType(() => PushNotificacionUsuario) public push_notificacion_usuarios: PushNotificacionUsuario[]

    public toString() {
        return "(" + this.push_notificacion + ")"
    }
}
