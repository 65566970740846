<ng-container *ngIf="!onlyLetras">
    <grupo-pregunta
        class="grupo-pregunta"
        tabindex="0"
        role="region"
        [attr.aria-label]="'Texto de la pregunta número ' + (index + 1)"
        [enableTextHighlight]="true"
        [class.compact-view]="enableCompactView"
        *ngIf="pregunta && grupoPreguntaId && (!grupoPreguntaPrev || grupoPreguntaId != grupoPreguntaPrev.id)"
        [grupoPregunta]="grupoPregunta"
        [preguntaIndex]="index"
        [enableGrupoPreguntaId]="enablePreguntaId"
    ></grupo-pregunta>
    <div class="pregunta-wrap" [class.compact-view]="enableCompactView && grupoPreguntaId">
        <div class="clearfix">
            <cui-button
                [buttonType]="(duda$ | async) ? 'confirm' : 'primary'"
                *ngIf="enableBtnMarkAsUncertain"
                class="pull-right btn-uncertain"
                (click)="toggleDuda()"
            >
                {{ (duda$ | async) ? "Ya lo revisé" : "Volver a revisar" }}
                <fa class="right-icon" name="flag"></fa>
            </cui-button>
            <ng-container *ngIf="instruccionFija$ | async as instruccionFija">
                <cui-button
                    buttonType="confirm"
                    class="pull-right btn-instruccion-fija"
                    (click)="genericModal.buttonPressed()"
                >
                    {{ "pregunta_ensayo.formulas" | t }}
                    <fa class="right-icon" name="info-circle"></fa>
                </cui-button>
            </ng-container>
        </div>
        <div
            class="pregunta_ensayo"
            *ngIf="pregunta"
            id="preg_{{ index + 1 }}"
            role="region"
            [attr.aria-label]="'Pregunta número ' + (index + 1)"
            [class.feedback-right]="enableFeedbackRight"
            [class.feedback-wrong]="enableFeedbackWrong"
            [class.is-from-group]="grupoPreguntaNext && grupoPreguntaId && grupoPreguntaId == grupoPreguntaNext.id"
        >
            <div class="numero_pregunta numero_pregunta_style">
                {{ index + 1 }}
            </div>
            <div class="pregunta-container">
                <div *ngIf="enablePreguntaId" class="resp-id">#{{ pregunta.id }}</div>
                <ng-container *ngFor="let contenido of contenidos; let contenidoIndex = index">
                    <ng-container [ngSwitch]="$any(contenido).constructor.className">
                        <ng-container *ngSwitchCase="'Taxativo'">
                            <pregunta-taxativo
                                [taxativo]="$any(contenido).taxativo"
                                [grupoPreguntaId]="pregunta.grupo_pregunta_id"
                                [makeFirstChildInline]="
                                    contenidos[contenidoIndex - 1] &&
                                    contenidos[contenidoIndex - 1]['contestable_tipo']?.contestable_tipo ==
                                        contestableTipo.completable
                                "
                                [makeLastChildInline]="
                                    contenidos[contenidoIndex + 1] &&
                                    contenidos[contenidoIndex + 1]['contestable_tipo']?.contestable_tipo ==
                                        contestableTipo.completable
                                "
                            ></pregunta-taxativo>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Contestable'">
                            <ng-container [ngSwitch]="$any(contenido).contestable_tipo?.contestable_tipo">
                                <ng-container *ngSwitchCase="contestableTipo.spr">
                                    <contestable-spr
                                        (respuestaChange)="buildRespuesta(contenidoIndex, $event)"
                                        [alternativaId]="respuesta[contenidoIndexToContestableIndex[contenidoIndex]]"
                                        [preguntaId]="pregunta.id"
                                    ></contestable-spr>
                                </ng-container>
                                <ng-container *ngSwitchCase="contestableTipo.libre">
                                    <contestable-libre
                                        [alternativaTexto]="respuesta[contenidoIndexToContestableIndex[contenidoIndex]]"
                                        (alternativaTextoChange)="buildRespuesta(contenidoIndex, $event)"
                                    ></contestable-libre>
                                </ng-container>
                                <ng-container *ngSwitchCase="contestableTipo.completable">
                                    <contestable-completable
                                        [alternativaTexto]="respuesta[contenidoIndexToContestableIndex[contenidoIndex]]"
                                        (alternativaTextoChange)="buildRespuesta(contenidoIndex, $event)"
                                    ></contestable-completable>
                                </ng-container>
                                <ng-container *ngSwitchCase="contestableTipo.imagen">
                                    <contestable-imagen
                                        [alternativaTexto]="respuesta[contenidoIndexToContestableIndex[contenidoIndex]]"
                                        (alternativaTextoChange)="buildRespuesta(contenidoIndex, $event)"
                                        [contestableId]="contenido.id"
                                    ></contestable-imagen>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <contestable-sm
                                        [evaluacionTipo]="evaluacionTipo"
                                        [disableOmitida]="disableOmitida"
                                        [index]="index"
                                        [grupoPreguntaId]="grupoPreguntaId"
                                        [contestable]="
                                            pregunta.contestables[contenidoIndexToContestableIndex[contenidoIndex]]
                                        "
                                        [alternativaId]="respuesta[contenidoIndexToContestableIndex[contenidoIndex]]"
                                        (alternativaIdChange)="buildRespuesta(contenidoIndex, $event)"
                                        [generadorInstrumento]="generadorInstrumento"
                                    ></contestable-sm>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchDefault>-</ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <div *ngIf="showComplains" class="reporte-container">
                <crear-duda
                    *ngIf="enableDudaBtn"
                    class="btn-duda"
                    [tabla]="'Pregunta'"
                    [entidad_id]="pregunta.id"
                    [queryParams]="dudaQueryParams"
                ></crear-duda>
                <reportes-modal *ngIf="enableReporte" [tabla]="'Pregunta'" [entidad_id]="pregunta.id"></reportes-modal>
                <div class="feedback-bottom clearfix" *ngIf="enableFeedbackRight">
                    <span class="feedbackIcon glyphicon glyphicon glyphicon-ok-circle"></span>
                    <span class="feedbackText">{{ "pregunta_ensayo.correcto" | t }}</span>
                </div>
                <div class="feedback-bottom clearfix" *ngIf="enableFeedbackWrong">
                    <span class="feedbackIcon glyphicon glyphicon glyphicon-remove-circle"></span>
                    <span class="feedbackText">{{ "pregunta_ensayo.incorrecto" | t }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<generic-modal>
    <modal-titulo>{{ "pregunta_ensayo.instruccion" | t }}</modal-titulo>
    <modal-contenido>
        <div
            *ngIf="instruccionFija$ | async as instruccionFija"
            [innerHTML]="instruccionFija.instruccion | trustedhtml"
        ></div>
    </modal-contenido>
</generic-modal>
