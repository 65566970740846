import { NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { TranslationsModule, UtilModule } from "@puntaje/shared/core"
import { CUITextsModule, CUIInputsModule } from "@puntaje/shared/cui"
import { PlanPersonalesModule } from "@puntaje/puntaje/new-modules/plan-personales"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { PlanesPersonalesComponent } from "./planes_personales.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { AvanceTareaComponent } from "./avance-tarea/avance-tarea.component"
import { DetallePlanAlumnoComponent } from "./detalle-plan-alumno/detalle-plan-alumno.component"
import { PlanesPersonalesReforzamientosComponent } from "./planes-personales-reforzamientos/planes-personales-reforzamientos.component"
import { DiagnosticoComponent } from "./diagnostico.component"
import { EstadisticasPlanesPersonalesComponent } from "./estadisticas_planes_personales.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { GenerarPlanPersonalComponent } from "./generar_plan_personal.component"
import { HistorialPlanesPersonalesComponent } from "./historial_planes_personales.component"
import { PlanPersonalProfesorComponent } from "./plan_personal_profesor.component"
import { PlanPersonalSesionComponent } from "./plan_personal_sesion.component"
import { HistorialPlanesCompartidosComponent } from "./historial-planes-compartidos/historial-planes-compartidos.component"
import { PlanPersonalComponent } from "./plan-personal/plan-personal.component"
import { RealizarPlanCompartidoComponent } from "./realizar_plan_compartido.component"
import { PlanesPersonalesAsignaturasComponent } from "./planes-personales-asignaturas.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PlanPersonalesModule,
        EnsayosModule,
        SharedModule,
        NebuModule,
        TranslationsModule,
        CUITextsModule,
        CUIInputsModule,
        CUILayoutsModule,
        CommonsLayoutsModule,
        UtilModule
    ],
    exports: [
        PlanesPersonalesComponent,
        AvanceTareaComponent,
        DetallePlanAlumnoComponent,
        PlanesPersonalesReforzamientosComponent,
        DiagnosticoComponent,
        EstadisticasPlanesPersonalesComponent,
        GenerarPlanPersonalComponent,
        HistorialPlanesPersonalesComponent,
        PlanPersonalProfesorComponent,
        PlanPersonalSesionComponent,
        HistorialPlanesCompartidosComponent,
        PlanPersonalComponent,
        RealizarPlanCompartidoComponent,
        PlanesPersonalesAsignaturasComponent
    ],
    declarations: [
        PlanesPersonalesComponent,
        AvanceTareaComponent,
        DetallePlanAlumnoComponent,
        PlanesPersonalesReforzamientosComponent,
        DiagnosticoComponent,
        EstadisticasPlanesPersonalesComponent,
        GenerarPlanPersonalComponent,
        HistorialPlanesPersonalesComponent,
        PlanPersonalProfesorComponent,
        PlanPersonalSesionComponent,
        HistorialPlanesCompartidosComponent,
        PlanPersonalComponent,
        RealizarPlanCompartidoComponent,
        PlanesPersonalesAsignaturasComponent
    ]
})
export class PuntajePlanPersonalesModule {}
