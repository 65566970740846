import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Evaluacion } from "./evaluaciones.model"
import { Respuesta } from "./respuestas.model"
import { Usuario } from "./usuarios.model"
import { EvaluacionMultipleInstancia } from "./evaluacion_multiple_instancias.model"

export class EvaluacionInstanciaAsignatura extends BaseModel {
    evaluacion_instancia_id: number
    asignatura_id: number
    usuario_id: number

    correctas: number
    incorrectas: number
    omitidas: number
    calificacion: number

    created_at: Date
}
