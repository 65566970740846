import { Component, Input, HostBinding } from "@angular/core"

@Component({
    selector: "[corner-ribbon]",
    templateUrl: "corner-ribbon.component.html",
    styleUrls: ["corner-ribbon.component.scss"]
})
export class CornerRibbonComponent {
    @Input() @HostBinding("class.top-right") topRight: boolean = true
    @Input() shadow: boolean = true
    constructor() {}
}
