import { Pipe, PipeTransform } from "@angular/core"
import { I18nService } from "../../i18n/i18n.service"

@Pipe({ name: "t" })
export class TranslatePipe implements PipeTransform {
    constructor(private i18nService: I18nService) {}

    transform(value: string, options: any = {}) {
        return this.i18nService.translate(value, options)
    }
}

@Pipe({ name: "te" })
export class TranslateExtrasPipe implements PipeTransform {
    constructor(private i18nService: I18nService) {}

    transform(value: string, options: any = {}) {
        return this.i18nService.translate(`extras.${value}`, options)
    }
}
