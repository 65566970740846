import { Directive, Input, Renderer2, ElementRef, OnInit, OnDestroy, NgZone } from "@angular/core"

declare let $

@Directive({
    selector: "[scrollAndFollow]"
})
export class ScrollAndFollowDirective implements OnInit, OnDestroy {
    _scrollAndFollow: boolean = true
    @Input("scrollAndFollow")
    set scrollAndFollow(value: boolean) {
        this._scrollAndFollow = value
        if (!value) {
            $(this.elementRef.nativeElement).stop()
            $(this.elementRef.nativeElement).css("margin-top", 0)
        }
    }
    get scrollAndFollow() {
        return this._scrollAndFollow
    }
    @Input("topPadding") topPadding: number = 0
    @Input("bottomPadding") bottomPadding: number = 0
    @Input("followTopReference") topReference: HTMLElement
    @Input("followBottomReference") bottomReference: HTMLElement
    @Input("responsiveBreak") responsiveBreak: number = 0
    responsiveDisable: boolean = false
    offset: any
    maxOffsetValue: number

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private ngZone: NgZone) {}

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener("scroll", this.onScroll.bind(this), true) //third parameter
            window.addEventListener("resize", this.onResize.bind(this), true)
        })

        this.offset = $(this.elementRef.nativeElement).offset()
        if (this.topReference) {
            this.offset = $(this.topReference).offset()
        }
        this.responsiveDisable = window.innerWidth < this.responsiveBreak
    }

    ngOnDestroy() {
        window.removeEventListener("scroll", this.onScroll.bind(this), true)
        window.removeEventListener("resize", this.onResize.bind(this), true)
    }

    onResize() {
        if (this.topReference) {
            this.offset = $(this.topReference).offset()
        }
        this.responsiveDisable = window.innerWidth < this.responsiveBreak
    }

    onScroll() {
        this.ngZone.runOutsideAngular(() => {
            if (this.scrollAndFollow && !this.responsiveDisable) {
                if (this.topReference) {
                    this.offset = $(this.topReference).offset()
                }
                if ($(window).scrollTop() > this.offset.top && this.scrollAndFollow) {
                    let deltaMove = $(window).scrollTop() - this.offset.top + this.topPadding
                    if (this.bottomReference) {
                        this.maxOffsetValue =
                            $(this.bottomReference).height() -
                            $(this.elementRef.nativeElement).height() -
                            this.topPadding -
                            this.bottomPadding
                    }
                    $(this.elementRef.nativeElement)
                        .stop()
                        .animate({
                            marginTop:
                                this.maxOffsetValue == undefined || deltaMove < this.maxOffsetValue
                                    ? deltaMove
                                    : this.maxOffsetValue
                        })
                } else {
                    $(this.elementRef.nativeElement).stop().animate({
                        marginTop: 0
                    })
                }
            } else {
                $(this.elementRef.nativeElement).stop()
                $(this.elementRef.nativeElement).css("margin-top", 0)
            }
        })
    }
}
