import { BaseSentimentComponent } from "./base_sentiment.component"
import { Component, Injector, Input } from "@angular/core"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "sentiment",
    templateUrl: "sentiment.component.html",
    styleUrls: ["sentiment.component.scss"]
})
export class SentimentComponent extends BaseSentimentComponent {
    @Input() glyphiconLike: string
    @Input() glyphiconDislike: string
    @Input() showProgressBar: boolean = true
    @Input() estilo: string = "default"

    constructor(usuariosService: Usuarios, authService: AuthService, injector: Injector) {
        super(usuariosService, authService, injector)
    }
}
