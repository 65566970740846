import { Diccionario } from "@puntaje/shared/core"

export class TipoInstrumentoDiccionarioEs {
    public static getDiccionario(): Diccionario {
        let evaluacionPorDefecto: Diccionario = {
            genero: "f",
            singular: "evaluación",
            plural: "evaluaciones",
            conArticulo: "la evaluación",
            conArticuloPlural: "las evaluaciones",
            conArticuloIndefinido: "una evaluación",
            conDeterminante: "esta evaluación",
            filtroEstadistica: "evaluación",
            nombreCalificacion: "calificación",
            nombreCalificacionPlural: "calificaciones",
            evaluacionTipo: "evaluación"
        }

        let evaluacionPruebaPorDefecto: Diccionario = {
            genero: "f",
            singular: "prueba",
            plural: "pruebas",
            conArticulo: "la prueba",
            conArticuloPlural: "las pruebas",
            conArticuloIndefinido: "una prueba",
            conDeterminante: "esta prueba",
            filtroEstadistica: "prueba",
            pluralCompleto: "pruebas Curriculares",
            nombreCalificacion: "nota",
            nombreCalificacionPlural: "notas"
        }

        return {
            curricular: {
                genero: "f",
                singular: "prueba",
                plural: "pruebas",
                conArticulo: "la prueba",
                conArticuloPlural: "las pruebas",
                conArticuloIndefinido: "una prueba",
                conDeterminante: "esta prueba",
                filtroEstadistica: "prueba",
                pluralCompleto: "pruebas Curriculares",
                nombreCalificacion: "nota",
                nombreCalificacionPlural: "notas",
                evaluacionTipo: "Curricular"
            },
            "curricular mexico": {
                ...evaluacionPruebaPorDefecto,
                nombreCalificacion: "porcentaje",
                nombreCalificacionPlural: "porcentajes"
            },
            "curricular colombia": {
                ...evaluacionPruebaPorDefecto,
                nombreCalificacion: "porcentaje",
                nombreCalificacionPlural: "porcentajes"
            },
            "curricular PIE": {
                genero: "f",
                singular: "prueba",
                plural: "pruebas",
                conArticulo: "la ",
                conArticuloPlural: "las pruebas",
                conArticuloIndefinido: "una prueba",
                conDeterminante: "esta prueba",
                filtroEstadistica: "prueba",
                evaluacionTipo: "Curricular PIE"
            },
            "diagnostico ensayo": {
                genero: "m",
                singular: "ensayo de diagnóstico",
                plural: "ensayos de diagnóstico",
                conArticulo: "el ensayo de diagnóstico",
                conArticuloPlural: "los ensayos de diagnóstico",
                conArticuloIndefinido: "un ensayo de diagnóstico",
                conDeterminante: "este ensayo de diagnóstico",
                filtroEstadistica: "ensayo de diagnóstico",
                evaluacionTipo: "ensayo de diagnóstico"
            },
            "diagnostico paa": {
                genero: "f",
                singular: "prueba",
                plural: "pruebas",
                conArticulo: "la ",
                conArticuloPlural: "las pruebas",
                conArticuloIndefinido: "una prueba",
                conDeterminante: "esta prueba",
                filtroEstadistica: "prueba",
                evaluacionTipo: "PAA"
            },
            "diagnostico plan": evaluacionPorDefecto,
            "diagnostico plan estudio": evaluacionPorDefecto,
            ensayo: {
                genero: "m",
                singular: "ensayo PSU",
                plural: "ensayos PSU",
                conArticulo: "el ensayo PSU",
                conArticuloPlural: "los ensayos PSU",
                conArticuloIndefinido: "un ensayo PSU",
                conDeterminante: "este ensayo PSU",
                filtroEstadistica: "ensayo PSU",
                pluralCompleto: "ensayos PSU",
                nombreCalificacion: "puntaje",
                nombreCalificacionPlural: "puntajes",
                evaluacionTipo: "Ensayo PSU"
            },
            ensayoPSU: {
                genero: "m",
                singular: "ensayo PSU",
                plural: "ensayos PSU",
                conArticulo: "el ensayo PSU",
                conArticuloPlural: "los ensayos PSU",
                conArticuloIndefinido: "un ensayo PSU",
                conDeterminante: "este ensayo PSU",
                filtroEstadistica: "ensayo PSU",
                evaluacionTipo: "Ensayo PSU"
            },
            "evaluacion plan": evaluacionPorDefecto,
            "evaluacion plan estudio": evaluacionPorDefecto,
            "mini ensayo umayor": {
                genero: "m",
                singular: "mini ensayo",
                plural: "mini ensayos",
                conArticulo: "el mini ensayo",
                conArticuloPlural: "los mini ensayos",
                conArticuloIndefinido: "un mini ensayo",
                conDeterminante: "este mini ensayo",
                filtroEstadistica: "mini ensayo",
                evaluacionTipo: "Mini Ensayo"
            },
            paa: {
                genero: "f",
                singular: "prueba",
                plural: "pruebas",
                conArticulo: "la ",
                conArticuloPlural: "las pruebas",
                conArticuloIndefinido: "una prueba",
                conDeterminante: "esta prueba",
                filtroEstadistica: "prueba",
                nombreCalificacion: "porcentaje",
                nombreCalificacionPlural: "porcentajes",
                evaluacionTipo: "PAA"
            },
            saber: {
                genero: "m",
                singular: "simulacro saber",
                plural: "simulacros saber",
                conArticulo: "el simulacro saber",
                conArticuloPlural: "los simulacros saber",
                conArticuloIndefinido: "un simulacro saber",
                conDeterminante: "este simulacro saber",
                filtroEstadistica: "saber 3, 5, 9",
                evaluacionTipo: "Simulacro Saber"
            },
            saber11: {
                genero: "m",
                singular: "simulacro saber",
                plural: "simulacros saber",
                conArticulo: "el simulacro saber",
                conArticuloPlural: "los simulacros saber",
                conArticuloIndefinido: "un simulacro saber",
                conDeterminante: "este simulacro saber",
                filtroEstadistica: "saber 11",
                evaluacionTipo: "Saber 11"
            },
            simce: {
                genero: "f",
                singular: "prueba SIMCE",
                plural: "pruebas SIMCE",
                conArticulo: "la prueba SIMCE",
                conArticuloPlural: "las pruebas SIMCE",
                conArticuloIndefinido: "una prueba SIMCE",
                conDeterminante: "esta prueba SIMCE",
                filtroEstadistica: "prueba SIMCE",
                pluralCompleto: "pruebas SIMCE",
                evaluacionTipo: "Prueba SIMCE"
            },
            "simulacro saber11": {
                genero: "m",
                singular: "simulacro saber 11",
                singularSimple: "simulacro",
                plural: "simulacros saber 11",
                conArticulo: "el simulacro saber 11",
                conArticuloPlural: "los simulacros saber 11",
                conArticuloIndefinido: "un simulacro saber 11",
                conDeterminante: "este simulacro saber 11",
                filtroEstadistica: "simulacro saber 11",
                evaluacionTipo: "Simulacro Saber 11"
            },
            "prueba de transición": {
                genero: "f",
                singular: "Prueba de Transición",
                plural: "Pruebas de Transición",
                conArticulo: "la Prueba de Transición",
                conArticuloPlural: "las Pruebas de Transición",
                conArticuloIndefinido: "una Prueba de Transición",
                conDeterminante: "esta Prueba de Transición",
                filtroEstadistica: "Prueba de Transición",
                pluralCompleto: "Pruebas de Transición",
                evaluacionTipo: "Prueba de Transición",
                nombreCalificacion: "puntaje",
                nombreCalificacionPlural: "puntajes"
            },
            "Prueba de Invierno": {
                genero: "f",
                singular: "Prueba de Invierno",
                plural: "Pruebas de Invierno",
                conArticulo: "la Prueba de Invierno",
                conArticuloPlural: "las Pruebas de Invierno",
                conArticuloIndefinido: "una Prueba de Invierno",
                conDeterminante: "esta Prueba de Invierno",
                filtroEstadistica: "Prueba de Invierno",
                pluralCompleto: "Pruebas de Invierno",
                evaluacionTipo: "Prueba de Invierno",
                nombreCalificacion: "puntaje",
                nombreCalificacionPlural: "puntajes"
            },
            Enem: {
                genero: "m",
                singular: "Enem",
                plural: "Enem",
                conArticulo: "el Enem",
                conArticuloPlural: "los Enem",
                conArticuloIndefinido: "un Enem",
                conDeterminante: "este Enem",
                filtroEstadistica: "Enem",
                pluralCompleto: "Enem",
                evaluacionTipo: "Enem",
                nombreCalificacion: "pontuação",
                nombreCalificacionPlural: "pontuações"
            },
            Anuies: {
                genero: "f",
                singular: "Anuies",
                plural: "Anuies",
                conArticulo: "la Prueba Anuies",
                conArticuloPlural: "las Pruebas de Anuies",
                conArticuloIndefinido: "una Prueba de Anuies",
                conDeterminante: "esta Prueba de Anuies",
                filtroEstadistica: "Prueba de Anuies",
                pluralCompleto: "Pruebas de Anuies",
                evaluacionTipo: "Prueba de Anuies",
                nombreCalificacion: "puntaje",
                nombreCalificacionPlural: "puntajes"
            },
            paes: {
                genero: "f",
                singular: "PAES",
                plural: "PAES",
                conArticulo: "la PAES",
                conArticuloPlural: "las PAES",
                conArticuloIndefinido: "una PAES",
                conDeterminante: "esta PAES",
                filtroEstadistica: "PAES",
                pluralCompleto: "PAES",
                evaluacionTipo: "PAES",
                nombreCalificacion: "puntaje",
                nombreCalificacionPlural: "puntajes"
            },
            PAES: {
                genero: "f",
                singular: "PAES",
                plural: "PAES",
                conArticulo: "la PAES",
                conArticuloPlural: "las PAES",
                conArticuloIndefinido: "una PAES",
                conDeterminante: "esta PAES",
                filtroEstadistica: "PAES",
                pluralCompleto: "PAES",
                evaluacionTipo: "PAES",
                nombreCalificacion: "puntaje",
                nombreCalificacionPlural: "puntajes"
            },
            Curricular: {
                ...evaluacionPruebaPorDefecto,
                evaluacionTipo: "Curricular"
            },
            "Nivelación INACAP": {
                ...evaluacionPruebaPorDefecto,
                evaluacionTipo: "Nivelación INACAP"
            },
            "Aprende+": {
                ...evaluacionPruebaPorDefecto,
                evaluacionTipo: "Aprende+"
            },
            Paes: {
                genero: "f",
                singular: "Paes",
                plural: "Paes",
                conArticulo: "la Paes",
                conArticuloPlural: "las Paes",
                conArticuloIndefinido: "una Paes",
                conDeterminante: "esta Paes",
                filtroEstadistica: "Paes",
                pluralCompleto: "Paes",
                evaluacionTipo: "Paes",
                nombreCalificacion: "puntaje",
                nombreCalificacionPlural: "puntajes"
            },
            default: evaluacionPorDefecto
        }
    }

    public static getCustomDiccionario(tipo_instrumento: string = "evaluación"): Diccionario {
        return {
            genero: "f",
            singular: `${tipo_instrumento}`,
            plural: `${tipo_instrumento}s`,
            conArticulo: `la ${tipo_instrumento}`,
            conArticuloPlural: `las ${tipo_instrumento}s`,
            conArticuloIndefinido: `una ${tipo_instrumento}`,
            conDeterminante: `esta ${tipo_instrumento}`,
            filtroEstadistica: `${tipo_instrumento}`,
            pluralCompleto: `${tipo_instrumento}s`,
            nombreCalificacion: "calificación",
            nombreCalificacionPlural: "calificaciones",
            evaluacionTipo: `${tipo_instrumento}`
        }
    }
}

export class TipoInstrumentoDiccionario {
    public static getDiccionario(locale: string = "es"): Diccionario {
        switch (locale) {
            case "es": {
                return TipoInstrumentoDiccionarioEs.getDiccionario()
            }
            default: {
                console.log("TipoInstrumentoDiccionario: locale '" + locale + "' no existe.")
                return null
            }
        }
    }

    public static getCustomDiccionario(tipo_instrumento: string = "evaluacion", locale: string = "es"): Diccionario {
        switch (locale) {
            case "es": {
                return TipoInstrumentoDiccionarioEs.getCustomDiccionario(tipo_instrumento)
            }
            default: {
                console.log("TipoInstrumentoDiccionario: locale '" + locale + "' no existe.")
                return null
            }
        }
    }
}
