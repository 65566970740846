import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { mergeMap, map } from "rxjs/operators"
import { Observable } from "rxjs"
import { Action } from "@ngrx/store"
import { StoreActionTypes, GetStoreConfig } from "../actions/store.actions"
import { GetAsignaturasConfig } from "../actions/asignaturas.actions"
import { GetEvaluacionTipoAliases } from "../actions/evaluacion_tipos.actions"

@Injectable()
export class StoreEffects {
    
    storeConfig$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(StoreActionTypes.GetStoreConfig),
        mergeMap(action => {
            return [new GetAsignaturasConfig(), new GetEvaluacionTipoAliases()]
        })
    ))

    constructor(private actions$: Actions) {}
}
