import { Injectable } from "@angular/core"
import { HttpHeaders, HttpClient } from "@angular/common/http"
import { FacebookService } from "@ogr-sa/ngx-facebook"
import { AppConfig, AppEnv, AuthService, SessionService } from "@puntaje/shared/core"

@Injectable({
    providedIn: "root"
})
export class AchievementsAuthService extends AuthService {
    /* The auth provided here refers to the auth system of the main app, and is the one
     * that handles the request to get the token from nebulosa
     */
    constructor(
        protected http: HttpClient,
        protected fb: FacebookService,
        private auth: AuthService,
        sessionService: SessionService,
        config: AppConfig,
        environment: AppEnv
    ) {
        super(http, fb, null, sessionService, config, environment)
        this.userProfile = "profile1"
        this.authTokenName = "auth_token_logros"
        this.authUriPath = "/authenticate"
        this.apiTokenKeyName = "auth_token"
        this.apiUserKey = "user"
    }
}
