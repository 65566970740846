import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Establecimiento } from "./establecimientos.model"
import { Usuario } from "./usuarios.model"
import { GrupoUsuarioUsuario } from "./grupo_usuario_usuarios.model"
import { Clasificacion, Asignatura } from "@puntaje/nebulosa/api-services"

export class GrupoUsuario extends BaseModel {
    public static className: string = "GrupoUsuario"

    public nombre: string
    public privado: boolean
    public curso_id: number
    public asignatura_id: number
    public numero_alumnos: number
    public numero_propietarios: number
    public oficial: boolean

    fecha_inicial: Date
    fecha_final: Date
    establecimiento_id: number
    usuarios: Usuario[]
    @SaveType(() => Establecimiento) establecimiento: Establecimiento
    @SaveType(() => Usuario) propietarios: Usuario[]
    @SaveType(() => Usuario) alumnos: Usuario[]
    @SaveType(() => Clasificacion) curso: Clasificacion
    @SaveType(() => Asignatura) asignatura: Asignatura
    @SaveType(() => GrupoUsuarioUsuario) grupo_usuario_usuarios: GrupoUsuarioUsuario[]

    public toString() {
        return this.nombre
    }
}
