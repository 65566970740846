<div class="showcasebox" [class.open]="openStyle" [class.overDark]="overDarkStyle">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_icon">
                <div class="icon-container">
                    <span class="icon">
                        <ng-content select="cui-showcasebox-title-icon"></ng-content>
                    </span>
                </div>
            </div>
            <div class="showcasebox_title_text">
                <ng-content select="cui-showcasebox-title-text"></ng-content>
            </div>
        </div>
    </div>
    <div
        [class.showcasebox_content]="!openStyle"
        [class.showcasebox_content_hollow]="openStyle"
        *ngIf="content && !hideContent"
    >
        <ng-content select="cui-showcasebox-content"></ng-content>
    </div>
    <div class="showcasebox_data_not_found" *ngIf="dataNotFound">
        <ng-content select="cui-showcasebox-data-not-found"></ng-content>
    </div>
    <div class="showcasebox_view_more" *ngIf="viewMore">
        <ng-content select="cui-showcasebox-view-more"></ng-content>
    </div>
</div>
