import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router } from "@angular/router"
import { Duda, DudaCategoria } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { DudasBuscadorService } from "./dudas_buscador.service"
import { Asignatura, Asignaturas, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Subscription, Observable, BehaviorSubject, combineLatest } from "rxjs"
import { select, Store } from "@ngrx/store"
import { selectAsignaturasById, State } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"

@Component({
    selector: "dudas-categoria-box",
    templateUrl: "dudas_categoria_box.component.html",
    styleUrls: ["dudas_categoria_box.component.scss"]
})
export class DudasCategoriaBoxComponent implements OnInit {
    @Input() sub_categorias: any = {}
    hide_sub: boolean = true
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    sub: Subscription
    asignaturasById$: Observable<{ [id: number]: AsignaturaWithConfig }> = this.store.pipe(
        select(selectAsignaturasById),
        filter(x => !!x)
    )

    // Revisar si esto se puede cambiar porque esta raro (es decir, usar el input de angular y observables)
    categoriaSubject: BehaviorSubject<DudaCategoria> = new BehaviorSubject<DudaCategoria>(null)
    _categoria: DudaCategoria
    @Input()
    set categoria(categoria: DudaCategoria) {
        this._categoria = categoria
        this.categoriaSubject.next(categoria)
    }
    get categoria() {
        return this._categoria
    }

    constructor(
        protected router: Router,
        protected dudasBuscadorService: DudasBuscadorService,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.sub = combineLatest(this.categoriaSubject.pipe(filter(x => !!x)), this.asignaturasById$).subscribe(
            ([categoria, asignaturasById]) => {
                this.setAsignatura(categoria, asignaturasById)
            }
        )
    }

    ir_categoria(dudaCategoria: DudaCategoria) {
        this.router.navigate(["/dudas"], { queryParams: { categoria_id: dudaCategoria.id, page: 1, per: 10 } })
    }

    toggle_sub() {
        this.hide_sub = !this.hide_sub
    }

    setAsignatura(categoria, asignaturasById) {
        if (categoria.asignatura_id) {
            categoria["asignatura"] = asignaturasById[categoria.asignatura_id]
        }
    }
}
