import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Asignatura } from "./asignaturas.model"
import { TipoInstrumento } from "./tipo_instrumentos.model"
import { GeneradorInstrumentoFiltroClasificacion } from "./generador_instrumento_filtro_clasificaciones.model"
import { GeneradorInstrumentoFiltroClasificacionTipo } from "./generador_instrumento_filtro_clasificacion_tipos.model"
import { GeneradorInstrumentoClasificacion } from "./generador_instrumento_clasificaciones.model"
import { Clasificacion } from "./clasificaciones.model"
import { GeneradorInstrumentoCategoria } from "./generador_instrumento_categorias.model"
import { AsignaturaPlataforma } from "./asignatura_plataformas.model"
import { GeneradorInstrumentoInstruccion } from "./generador_instrumento_instrucciones.model"

export class GeneradorInstrumento extends BaseModel {
    public static className: string = "GeneradorInstrumento"
    public generador_instrumento: string
    public tipo_instrumento_id: number
    public valido: boolean
    public created_at: Date
    public updated_at: Date
    public asignatura_plataforma_id: number
    public numero_preguntas: number
    public tiempo: number
    public orden: number
    public alias: string
    public grupal: boolean
    public numero_preguntas_piloto: number
    public maximo_alternativas: number
    public multiple: boolean
    public visible: boolean

    @SaveType(() => TipoInstrumento) tipo_instrumento: TipoInstrumento
    @SaveType(() => Asignatura) asignatura: Asignatura
    @SaveType(() => GeneradorInstrumentoFiltroClasificacion)
    generador_instrumento_filtro_clasificaciones: GeneradorInstrumentoFiltroClasificacion[]
    @SaveType(() => GeneradorInstrumentoClasificacion)
    generador_instrumento_clasificaciones: GeneradorInstrumentoClasificacion[]
    @SaveType(() => Clasificacion) clasificaciones: Clasificacion
    @SaveType(() => GeneradorInstrumentoCategoria) generador_instrumento_categorias: GeneradorInstrumentoCategoria[]
    @SaveType(() => GeneradorInstrumento) generador_instrumentos_hijos: GeneradorInstrumento[]
    @SaveType(() => AsignaturaPlataforma) asignatura_plataforma: AsignaturaPlataforma
    @SaveType(() => GeneradorInstrumentoInstruccion)
    generador_instrumento_instrucciones: GeneradorInstrumentoInstruccion[]
    @SaveType(() => GeneradorInstrumentoFiltroClasificacionTipo)
    generador_instrumento_filtro_clasificacion_tipos: GeneradorInstrumentoFiltroClasificacionTipo[]

    public getModelName() {
        return "GeneradorInstrumento"
    }

    toString() {
        return this.generador_instrumento
    }

    toStringWithTipoInstrumento() {
        if (this.tipo_instrumento) {
            return `${this.generador_instrumento} - ${this.tipo_instrumento.tipo_instrumento}`
        }

        return this.toString()
    }

    toStringWithTipoInstrumentoWithAsignaturaPlataforma() {
        if (this.asignatura_plataforma) {
            const arr = [this.toStringWithTipoInstrumento()]

            if (this.asignatura_plataforma.asignatura) {
                arr.push(this.asignatura_plataforma.asignatura.asignatura)
            }

            if (this.asignatura_plataforma.plataforma) {
                arr.push(this.asignatura_plataforma.plataforma.plataforma)
            }

            return arr.join(" - ")
        }

        return this.toStringWithTipoInstrumento()
    }
}
