import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { Establecimiento } from "./establecimientos.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class Establecimientos extends PuntajeBaseService<Establecimiento> {
    tableName = "establecimientos"
    singularTableName = "establecimiento"
    modelClass = Establecimiento

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public stats(establecimientoId: number, params: any = null) {
        this.enableIgnoreModel()
        return this.one(establecimientoId)
            .concatExtra("stats")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }

    public statsMultiple(params: any = null) {
        this.enableIgnoreModel()
        return this.all()
            .concatExtra("stats_multiple")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }

    public top_alumnos(establecimientoId: number, params: any = {}) {
        this.enableIgnoreModel()
        return this.one(establecimientoId)
            .concatExtra("top_alumnos")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }

    public top_docentes(establecimientoId: number, params: any = {}) {
        this.enableIgnoreModel()
        return this.one(establecimientoId)
            .concatExtra("top_docentes")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }

    public grupoUsuariosPorNivel(establecimientoId: number, params: any = {}) {
        this.enableIgnoreModel()
        return this.one(establecimientoId)
            .concatExtra("grupo_usuarios_por_nivel")
            .get(params)
            .then(grupo_usuarios => {
                this.disableIgnoreModel()
                return grupo_usuarios
            })
    }
    public tabla_numero_alumnos_por_curso(establecimientoId: number, params: any = {}) {
        this.enableIgnoreModel()
        return this.one(establecimientoId)
            .concatExtra("tabla_numero_alumnos_por_curso")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }

    public tabla_numero_evaluaciones_por_asignatura(establecimientoId: number, params: any = {}) {
        this.enableIgnoreModel()
        return this.one(establecimientoId)
            .concatExtra("tabla_numero_evaluaciones_por_asignatura")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }

    public tabla_desempeno_alumnos(establecimientoId: number, params: any = {}) {
        this.enableIgnoreModel()
        return this.one(establecimientoId)
            .concatExtra("tabla_desempeno_alumnos")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }

    public numero_alumnos_activos(establecimientoId: number, params: any = {}) {
        this.enableIgnoreModel()
        return this.one(establecimientoId)
            .concatExtra("numero_alumnos_activos")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }

    public avancePlanesAdaptive(establecimientoId: number, params: any = null) {
        this.enableIgnoreModel()
        return this.one(establecimientoId)
            .concatExtra("avance_planes_adaptive")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }
}
