import { Injectable } from "@angular/core"
import { Scannerjs } from "@puntaje/shared/scanner"
import { AppConfig } from "../conf/app_config"

@Injectable({
    providedIn: "root"
})
export class ScannerService2 {
    dimSectores: any[]
    lastMarkers: any[]

    configuration: any

    constructor(protected config: AppConfig) {}

    initialize(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
        return new Scannerjs({
            context: context,
            canvasWidth: canvas.width,
            canvasHeight: canvas.height
        })
    }

    detectMarkers(scanner: Scannerjs) {
        const markers = scanner.detectMarkers()

        this.lastMarkers = markers

        return markers
    }

    resolveIds(scanner: Scannerjs, id1: number, id2: number, cols, rows, inverse = false) {
        return scanner.resolveIds(id1, id2, cols, rows, inverse)
    }

    align(scanner: Scannerjs, id1: number, id2: number) {
        return scanner.align(id1, id2)
    }

    allRespuestaMarkersVisible(markers) {
        return Array.from(new Set(this.config.hojaRespuesta.sectores.flat().concat([6, 9, 10]))).every(x =>
            markers.find(m => m.id == x)
        )
    }

    changeMarked(scanner: Scannerjs, nCanvas: HTMLCanvasElement, rawSectoresValues: any[][], event) {
        const ratioX = nCanvas.width / nCanvas.offsetWidth
        const ratioY = nCanvas.height / nCanvas.offsetHeight

        scanner.changeMarked(
            ~~(event.offsetX * ratioX),
            ~~(event.offsetY * ratioY),
            rawSectoresValues,
            this.dimSectores,
            this.configuration.sectores
        )
    }

    changeRespuestasMarkedByIndices(
        scanner: Scannerjs,
        rawSectoresValues: any[][],
        valueName: string,
        valueIndex: number,
        value: string
    ) {
        let sectorIndex
        let valueElement
        let rows = 0

        for (let key of Object.keys(this.configuration[valueName])) {
            if (!isNaN(parseInt(key))) {
                const configSector = this.configuration[valueName][key]

                valueElement = configSector.elements.find(element => {
                    rows += element.size.group
                    if (valueIndex < rows) {
                        return true
                    }

                    return false
                })

                if (valueElement) {
                    sectorIndex = +key
                    break
                }
            }
        }

        if (!sectorIndex || !valueElement) {
            return
        }

        const letras = "ABCDEFGH"
        let i = valueIndex - (rows - valueElement.size.group) + valueElement.offset.y
        let j = letras.indexOf(value.toUpperCase()) + valueElement.offset.x

        scanner.changeMarkedByIndices(
            i,
            j,
            rawSectoresValues,
            this.dimSectores,
            this.configuration.sectores,
            sectorIndex
        )
    }

    getGroup(resolvedObj, type, offsetX, offsetY, circles, group, inverted) {
        const fromIndexFirst = inverted ? offsetX : offsetY
        const toIndexFirst = fromIndexFirst + group

        const fromIndexSecond = inverted ? offsetY : offsetX
        const toIndexSecond = fromIndexSecond + circles

        if (type == "single") {
            return [
                resolvedObj
                    .filter(o => o.row < toIndexFirst && o.row >= fromIndexFirst)
                    .reduce(
                        (acc, o, i) => {
                            acc.ans = acc.ans.concat(
                                o.ans
                                    .filter(a => a >= fromIndexSecond && a < toIndexSecond)
                                    .map(a => a - fromIndexSecond + i * circles)
                            )

                            return acc
                        },
                        { row: 0, ans: [] }
                    )
            ]
        } else {
            return resolvedObj
                .filter(o => o.row < toIndexFirst && o.row >= fromIndexFirst)
                .map((o, i) => ({
                    row: i,
                    ans: o.ans.filter(a => a >= fromIndexSecond && a < toIndexSecond).map(a => a - fromIndexSecond)
                }))
        }
    }

    getSectoresValue(rawSectoresValues: any[][], configuration, valueName) {
        const sectoresArray = []

        Object.keys(configuration[valueName]).forEach(key => {
            if (!isNaN(parseInt(key))) {
                const configSector = configuration[valueName][key]

                const resolvedObj = rawSectoresValues[+key]

                const elementsArray = []
                configSector.elements.forEach(element => {
                    const elementArray = []
                    const rObj = this.getGroup(
                        resolvedObj,
                        element.type,
                        element.offset.x,
                        element.offset.y,
                        element.size.circles,
                        element.size.group,
                        configSector.inverted
                    )

                    rObj.forEach(o => {
                        const value = o.ans
                            .map(a => {
                                if (Array.isArray(element.markValue)) {
                                    return element.markValue[a]
                                } else {
                                    return element.markValue.charAt(a)
                                }
                            })
                            .join(",")

                        elementArray.push(value)
                    })

                    if (element.separator != undefined && element.separator != null) {
                        elementsArray.push(elementArray.join(element.separator))
                    } else {
                        elementsArray.push(...elementArray)
                    }
                })

                if (configSector.separator != undefined && configSector.separator != null) {
                    sectoresArray.push(elementsArray.join(configSector.separator))
                } else {
                    sectoresArray.push(...elementsArray)
                }
            }
        })

        let value: any = sectoresArray
        if (configuration[valueName].separator != undefined && configuration[valueName].separator != null) {
            value = sectoresArray.join(configuration[valueName].separator)
        }

        return value
    }

    calculateSectoresValues(scanner, rawSectoresValues: any[][]) {
        this.dimSectores = new Array(this.configuration.sectores.length)

        const concatedConfiguration = {
            ...this.configuration.respuestas,
            ...this.configuration.forma,
            ...this.configuration.idValue
        }

        Object.keys(concatedConfiguration).forEach(key => {
            if (!isNaN(parseInt(key))) {
                const sector = this.configuration.sectores[+key]
                const configSector = concatedConfiguration[+key]

                rawSectoresValues[+key] = this.resolveIds(
                    scanner,
                    sector[0],
                    sector[1],
                    configSector.columns,
                    configSector.rows,
                    configSector.inverted
                )

                this.dimSectores[+key] = {
                    cols: configSector.columns,
                    rows: configSector.rows,
                    inverted: configSector.inverted
                }
            }
        })
    }

    getProcessedData(rawSectoresValues: any[][]) {
        const respuestas = this.getSectoresValue(rawSectoresValues, this.configuration, "respuestas")
        const forma = this.getSectoresValue(rawSectoresValues, this.configuration, "forma")
        const idValue = this.getSectoresValue(rawSectoresValues, this.configuration, "idValue")
        const formaType = "" //this.getSectoresValue(rawSectoresValues, this.configuration, "formaType")

        return { respuestas, idValue, forma, formaType }
    }

    hasConfigurationByMarkers() {
        if (this.config.hojaRespuesta.configurationByMarkers) {
            const uniqueMarkersQty = new Set(this.lastMarkers.map(m => m.id)).size

            return !!this.config.hojaRespuesta.configurationByMarkers[uniqueMarkersQty]
        }

        return false
    }

    getHorizontalMarkers() {
        if (this.config.hojaRespuesta.configuration) {
            return this.config.hojaRespuesta.configuration.horizontalMarkers
        } else if (this.hasConfigurationByMarkers()) {
            const uniqueMarkersQty = new Set(this.lastMarkers.map(m => m.id)).size

            return this.config.hojaRespuesta.configurationByMarkers[uniqueMarkersQty].horizontalMarkers
        } else return [1, 9]
    }

    getVerticalMarkers() {
        if (this.config.hojaRespuesta.configuration) {
            return this.config.hojaRespuesta.configuration.verticalMarkers
        } else if (this.hasConfigurationByMarkers()) {
            const uniqueMarkersQty = new Set(this.lastMarkers.map(m => m.id)).size

            return this.config.hojaRespuesta.configurationByMarkers[uniqueMarkersQty].verticalMarkers
        } else return [1, 3]
    }

    resolveData(scanner: Scannerjs, rawSectoresValues: any[][]) {
        if (this.config.hojaRespuesta.configuration) {
            this.configuration = this.config.hojaRespuesta.configuration

            this.calculateSectoresValues(scanner, rawSectoresValues)

            return this.getProcessedData(rawSectoresValues)
        } else if (this.hasConfigurationByMarkers()) {
            const uniqueMarkersQty = new Set(this.lastMarkers.map(m => m.id)).size
            this.configuration = this.config.hojaRespuesta.configurationByMarkers[uniqueMarkersQty]

            this.calculateSectoresValues(scanner, rawSectoresValues)

            return this.getProcessedData(rawSectoresValues)
        } else {
            const letras = "ABCDE"
            const numeros = "0123456789"
            const rutChars = "0123456789K"

            const rutObj = scanner.resolveIds(6, 10, 10, 10, true)
            const formaObj = scanner.resolveIds(9, 6, 10, 10, true)

            const resps = this.config.hojaRespuesta.sectores.map(ids =>
                scanner.resolveIds(ids[0], ids[1], this.config.hojaRespuesta.alternativas || 5, 10)
            )
            const respuestas = resps.reduce((acc, r, i) => {
                r.forEach(o => {
                    acc[10 * i + o.row] = o.ans.map(a => letras.charAt(a)).join(",")
                })
                return acc
            }, {})

            const idValue = []
            if (this.config.plataforma.pais == "chile") {
                idValue.push(
                    rutObj
                        .filter((o, i) => i < 9)
                        .map((o, i) => {
                            return o.ans.map(a => rutChars.charAt(a)).join(",")
                        })
                        .join("")
                )
                idValue.push(rutObj[9].ans.map(a => rutChars.charAt(a)).join(","))
            } else if (this.config.plataforma.pais == "colombia") {
                idValue.push(rutObj.map((o, i) => o.ans.map(a => rutChars.charAt(a)).join(",")).join(""))
            }

            let forma = null
            let formaType = ""

            const lastIndex = formaObj.length - 1
            const hasSomethingBeforeSpace = formaObj.slice(0, lastIndex - 1).some(obj => obj.ans.length > 0)

            if (
                formaObj[lastIndex - 1].ans.length == 0 &&
                formaObj[lastIndex].ans.length > 0 &&
                hasSomethingBeforeSpace
            ) {
                forma = formaObj
                    .map((o, i) => {
                        let valores = []
                        if (i != 8 && i != 9) {
                            valores.push(o.ans.map(a => numeros.charAt(a)).join(","))
                        }
                        return valores
                    })
                    .join("")

                if (formaObj[9].ans[0] == 0) {
                    formaType = "A"
                } else if (formaObj[9].ans[0] == 1) {
                    formaType = "B"
                }
            } else {
                forma = formaObj.map(o => o.ans.map(a => numeros.charAt(a)).join(",")).join("")
            }

            return { respuestas, idValue, forma, formaType }
        }
    }
}
