import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    QueryList,
    ViewChildren,
    Directive
} from "@angular/core"
import { Subject } from "rxjs"
import { Scannerjs } from "@puntaje/shared/scanner"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { EvaluacionInstancias } from "@puntaje/puntaje/api-services"
import { Router } from "@angular/router"
import { ScrollToService, AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { ScannerService } from "@puntaje/shared/core"

import {
    State,
    LoadImages,
    ProcessClickCanvas,
    SubirLecturas,
    ResetHojaRespuestas,
    InitArchivosOriginales,
    InitCanvases,
    ChangeSelectedFile,
    PreviousImage,
    NextImage,
    ChangeForma,
    ChangeFormaType,
    ChangeRespuesta,
    ChangeIdValuePart,
    ChangeFileEditing,
    ChangeFormaGlobal,
    ChangeEnableThreshold,
    selectArchivosProcesados,
    selectProcessedAll,
    selectSelectedFile,
    selectFormaGlobal,
    selectProcessedFiles,
    selectLecturasProcesadas,
    selectLecturasTotal,
    selectEnableThreshold
} from "@puntaje/puntaje/store"
import { Store, select } from "@ngrx/store"
import { map } from "rxjs/operators"
import { Subscription, combineLatest } from "rxjs"

declare let loadImage: any

@Directive()
export class SubirRespuestasImagenBaseComponent implements OnInit {
    @Input() perfil = "alumno"
    @Input() link_message = "Ver estadística."
    file: File
    xhrUpload: Subject<XMLHttpRequest> = new Subject()

    messages: any
    loadingMessages: boolean = false
    scanner: Scannerjs
    wrongFileType: boolean = false
    instancia_id: number = 0

    respuestasObj: any[]
    formaObj: any
    rutObj: any

    respuestas: any
    forma: string
    rut: string
    dv: string
    protected datosBoton: any

    editing: boolean = false

    idValue: string[] = []

    markers: any[] = []
    sectores: number[][]
    sectoresRespuestas: number[][]
    dimSectores: { [key: string]: any }

    @ViewChild("canvas", { static: true }) canvas: ElementRef
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutSubir", { static: true }) loadingLayoutUpload: LoadingLayoutComponent

    @ViewChildren("canvas") canvases: QueryList<ElementRef>

    // Selectors y Subscriptions -------------------------------------------------------
    archivosOriginales$ = this.store.pipe(
        select(selectArchivosProcesados),
        map(ap => ap.original)
    )
    archivosGood$ = this.store.pipe(
        select(selectArchivosProcesados),
        map(ap => Object.values(ap.good))
    )
    archivosBad$ = this.store.pipe(
        select(selectArchivosProcesados),
        map(ap => Object.values(ap.bad))
    )
    archivosNotImages$ = this.store.pipe(
        select(selectArchivosProcesados),
        map(ap => Object.values(ap.notImages))
    )

    processedAll$ = this.store.pipe(select(selectProcessedAll))

    selectedFile$ = this.store.pipe(select(selectSelectedFile))
    formaGlobal$ = this.store.pipe(select(selectFormaGlobal))
    enableThreshold$ = this.store.pipe(select(selectEnableThreshold))

    processedFiles$ = this.store.pipe(select(selectProcessedFiles))
    progressWidth$ = combineLatest(this.processedFiles$, this.archivosOriginales$.pipe(map(ao => ao.length))).pipe(
        map(([processedFiles, totalOriginales]) => (100 * processedFiles) / totalOriginales + "%")
    )

    lecturasProcesadas$ = this.store.pipe(select(selectLecturasProcesadas))
    lecturasTotal$ = this.store.pipe(select(selectLecturasTotal))
    uploadProgressWidth$ = combineLatest(this.lecturasProcesadas$, this.lecturasTotal$).pipe(
        map(([processed, total]) => (100 * processed) / total + "%")
    )

    subScrollToStep2: Subscription = this.processedAll$.subscribe(processedAll => {
        if (processedAll) {
            this.scrollToStep2()
        }
    })

    subLecturasProcesadas: Subscription = combineLatest(this.lecturasProcesadas$, this.archivosGood$).subscribe(
        ([lecturasProcesadas, archivosGood]) => {
            if (archivosGood.length > 0 && lecturasProcesadas == archivosGood.length) {
                this.loadingLayoutUpload.ready()
            }
        }
    )

    constructor(
        protected evaluacionesInstanciasService: EvaluacionInstancias,
        protected router: Router,
        protected scrollToService: ScrollToService,
        protected scannerService: ScannerService,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.loadingLayout.ready()
        this.loadingLayoutUpload.ready()
    }

    reset() {
        this.store.dispatch(new ResetHojaRespuestas())
        this.cdr.detectChanges()
        this.scrollToService.scrollToTop()
    }

    subirLectura() {
        this.subirLecturas()
    }

    subirLecturas() {
        this.loadingLayoutUpload.standby()
        this.store.dispatch(new SubirLecturas({ perfil: this.perfil }))
    }

    defaultDimSectores() {
        return {
            "1,2": { cols: 5, rows: 10 },
            "2,3": { cols: 5, rows: 10 },
            "3,4": { cols: 5, rows: 10 },
            "5,2": { cols: 5, rows: 10 },
            "4,5": { cols: 5, rows: 10 },
            "2,7": { cols: 5, rows: 10 },
            "7,4": { cols: 5, rows: 10 },
            "5,6": { cols: 5, rows: 10 },
            "6,7": { cols: 5, rows: 10 },
            "8,9": { cols: 5, rows: 10 },
            "7,8": { cols: 5, rows: 10 },
            "9,6": { cols: 10, rows: 10 },
            "6,10": { cols: 10, rows: 10 }
        }
    }

    leerImagenes(files: Array<any>) {
        if (files && files.length > 0) {
            this.store.dispatch(new InitArchivosOriginales({ files }))
            this.cdr.detectChanges()
            this.store.dispatch(new InitCanvases({ canvases: this.canvases.toArray() }))
            this.store.dispatch(new LoadImages())
        }
    }

    setValues() {
        this.idValue = []

        let letras = "ABCDE"
        let numeros = "0123456789"
        let rutChars = "0123456789K"

        this.respuestas = this.respuestasObj.reduce((acc, r, i) => {
            r.forEach(o => {
                acc[10 * i + o.row] = o.ans.map(a => letras.charAt(a)).join(",")
            })

            return acc
        }, {})

        // if(config.plataforma.pais == "chile") {
        //     this.idValue.push(this.rutObj.filter((o, i) => i < 9).map((o, i) => {
        //         return o.ans.map((a) => rutChars.charAt(a)).join(",");
        //     }).join(""));
        //     this.idValue.push(this.rutObj[9].ans.map((a) => rutChars.charAt(a)).join(","));
        // } else if(config.plataforma.pais == "colombia") {
        //     this.idValue.push(this.rutObj.map((o, i) => o.ans.map((a) => rutChars.charAt(a)).join(",")).join(""));
        // }

        // this.forma = this.formaObj.map((o) => o.ans.map((a) => numeros.charAt(a)).join(",")).join("");
    }

    onClickCanvas(event) {
        this.onClickCanvasWithIndex(0, event)
    }

    onClickCanvasWithIndex(canvasIndex, event) {
        this.store.dispatch(new ProcessClickCanvas({ index: canvasIndex, event }))

        this.cdr.detectChanges()
    }

    parseInt(i) {
        return parseInt(i)
    }

    checkIfImageExtension() {
        if (this.file && (this.file.type as any).startsWith("image/")) {
            this.wrongFileType = false
        } else {
            this.wrongFileType = true
        }
    }

    scrollToStep2() {
        this.scrollToService.scrollTo("step2")
    }

    notifyChangeOf(resp: ElementRef) {
        resp.nativeElement.class = "asdf"
    }

    resetAndStartOver() {
        this.file = null
        this.messages = null
        this.loadingMessages = false
        this.wrongFileType = false
        this.respuestas = null
        this.cdr.detectChanges()
        this.scrollToService.scrollToTop()
    }

    botonContinuar(obj: any) {
        this.router.navigate(["/ensayo_instancias", obj.instancia_id])
    }

    getIndex(index, _) {
        return index
    }

    getPos(obj) {
        return obj.pos
    }

    enableEditObj(obj: any) {
        this.store.dispatch(new ChangeFileEditing({ index: obj.pos, editing: true }))
    }

    disableEditObj(obj: any) {
        this.store.dispatch(new ChangeFileEditing({ index: obj.pos, editing: false }))
    }

    changeIdValuePart(indexFile, indexPart, idValuePart) {
        this.store.dispatch(new ChangeIdValuePart({ indexFile, indexPart, idValuePart }))
    }

    changeForma(index, forma) {
        this.store.dispatch(new ChangeForma({ index, forma }))
    }

    changeFormaType(index, formaType) {
        this.store.dispatch(new ChangeFormaType({ index, formaType }))
    }

    changeRespuesta(indexFile, indexRespuesta, respuesta) {
        this.store.dispatch(new ChangeRespuesta({ indexFile, indexRespuesta, respuesta }))
    }

    changeEnableThreshold(enableThreshold) {
        this.store.dispatch(new ChangeEnableThreshold({ enableThreshold }))
    }

    ngOnDestroy() {
        this.subScrollToStep2.unsubscribe()
        this.subLecturasProcesadas.unsubscribe()
    }
}
