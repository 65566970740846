import { Component, Input } from "@angular/core"
import { AppConfig, Loading } from "@puntaje/shared/core"

/*#################################

Template simple que carga un loader por defecto mientras su variable 'loading' sea verdadera, y luego carga el contenido.
Extiende de la clase 'Loading' (que se encuentra en shared/templates), por lo que incluye funciones ready(),
stanby() y variable pública loading.

#################################*/

@Component({
    templateUrl: "loading_layout.component.html",
    selector: "loading-layout",
    styleUrls: ["loading_layout.component.scss"]
})
export class LoadingLayoutComponent extends Loading {
    @Input() loading: boolean
    @Input() loaderType: string = ""
    @Input() opaque: boolean = false
    @Input() noMarginY: boolean = false

    constructor(config: AppConfig) {
        super(true)
        if (this.loaderType == "" && config.app.loader) {
            this.loaderType = config.app.loader
        }
    }
}
