import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Banner } from "./banners.model"

export class ImageBanner extends BaseModel {
    public banner_id: number
    public image_url: string
    public link_url: string
    public size: number

    @SaveType(() => Banner) public banner: Banner
    @SaveType() public file: File

    public toString() {
        if (this.banner) return this.banner.nombre
        else return "ImageBanner id: " + this.id
    }

    public getS3Params() {
        return this.file
            ? {
                  key: this.file.name,
                  model_name: "ImageBanner"
              }
            : {}
    }
}
