import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { EstablecimientosModule } from "@puntaje/puntaje/new-modules/establecimientos"
import { AsociacionesModule } from "@puntaje/puntaje/new-modules/asociaciones"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { EstadisticasModule } from "@puntaje/puntaje/new-modules/estadisticas"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { CUIInputsModule, CUITextsModule } from "@puntaje/shared/cui"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { ResumenUsoAsociacionesComponent } from "./resumen-uso-asociaciones/resumen-uso-asociaciones.component"
import { ProgresoComponent } from "./progreso.component"
import { EstadisticasComponent } from "./estadisticas.component"
import { ResumenGeneralComponent } from "./resumen_general.component"
import { ResumenUsoComponent } from "./resumen_uso.component"
import { EstadisticaEvaluacionComponent } from "./estadistica-evaluacion/estadistica-evaluacion.component"
import { EvaluacionInstanciaEstadisticasComponent } from "./evaluacion-instancia-estadisticas/evaluacion-instancia-estadisticas.component"
import { EstadisticasProgresoComponent } from "./estadisticas_progreso.component"
import { EstadisticasAlumnoComponent } from "./estadisticas_alumno.component"
import { ProgresoAlumnoComponent } from "./progreso_alumno.component"
import { InformeAlumnoComponent } from "./informe-alumno/informe-alumno.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { TutorEstadisticasComponent } from "./tutor_estadisticas.component"
import { EstadisticasDeclaradosComponent } from "./estadisticas_declarados.component"
import { InformeAnualComponent } from "./informe_anual.component"
import { EstadisticasEvaluacionMultipleComponent } from "./estadisticas_evaluacion_multiple.component"
import { EstadisticasMultiplesComponent } from "./estadisticas-multiples/estadisticas-multiples.component"
import { EstadisticasEvolutivasPlanComponent } from "./estadisticas-evolutivas-plan/estadisticas-evolutivas-plan.component"
import { InformesComponent } from "./informes.component"
import { UtilModule } from "@puntaje/shared/core"
import { PlanificacionesModule } from "@puntaje/puntaje/new-modules/planificaciones"
import { FormModule } from "@puntaje/shared/core"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        Angular2FontawesomeModule,
        TooltipModule,
        EstadisticasModule,
        EnsayosModule,
        EstablecimientosModule,
        AsociacionesModule,
        SharedModule,
        CUIInputsModule,
        CUITextsModule,
        CUILayoutsModule,
        CommonsLayoutsModule,
        UtilModule,
        PlanificacionesModule,
        FormModule
    ],
    declarations: [
        ResumenUsoAsociacionesComponent,
        ProgresoComponent,
        EstadisticasComponent,
        ResumenGeneralComponent,
        ResumenUsoComponent,
        EstadisticaEvaluacionComponent,
        EvaluacionInstanciaEstadisticasComponent,
        EstadisticasProgresoComponent,
        EstadisticasAlumnoComponent,
        ProgresoAlumnoComponent,
        InformeAlumnoComponent,
        TutorEstadisticasComponent,
        EstadisticasDeclaradosComponent,
        InformeAnualComponent,
        EstadisticasEvaluacionMultipleComponent,
        EstadisticasMultiplesComponent,
        EstadisticasEvolutivasPlanComponent,
        InformesComponent
    ],
    exports: [
        ResumenUsoAsociacionesComponent,
        ProgresoComponent,
        EstadisticasComponent,
        ResumenGeneralComponent,
        ResumenUsoComponent,
        EstadisticaEvaluacionComponent,
        EvaluacionInstanciaEstadisticasComponent,
        EstadisticasProgresoComponent,
        EstadisticasAlumnoComponent,
        ProgresoAlumnoComponent,
        InformeAlumnoComponent,
        TutorEstadisticasComponent,
        EstadisticasDeclaradosComponent,
        InformeAnualComponent,
        EstadisticasEvaluacionMultipleComponent,
        EstadisticasMultiplesComponent,
        EstadisticasEvolutivasPlanComponent,
        InformesComponent
    ]
})
export class PuntajeEstadisticasModule { }
