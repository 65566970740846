import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "reset_password.component.html"
})
export class ResetPasswordComponent implements OnInit {
    config: typeof config = config
    reset_password_token: string

    constructor(protected titleService: TitleService, public activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.passwords.reset")
        this.activatedRoute.params.subscribe((params: any) => {
            this.reset_password_token = params["reset_password_token"]
        })
    }
}
