import { Chart } from "./chart.interface"
import { ChartType } from "./chart_type.enum"
import { ChartColorType } from "./chart_color_type.enum"

export class BaseChart implements Chart {
    // Base para gráficos
    titulo: string
    subtitulo: string
    descripcion: string
    tipo: ChartType = ChartType.Pie
    tipoColor: ChartColorType = ChartColorType.Default
    colors: string[]
    data: any = []
    ejeX: any = []
    ejeY: any = []
    nombreSerie: string
    tooltip: string
    label: string
    formatter: string
    chartData: any = { chart: {} }
    height: number
    categorias: string[]
    leyenda: any

    rowHeight: number = 20

    constructor(g: Chart) {
        this.titulo = g.titulo
        this.subtitulo = g.subtitulo
        this.descripcion = g.descripcion
        this.nombreSerie = g.nombreSerie
        this.ejeX = g.ejeX
        this.ejeY = g.ejeY
        this.tooltip = g.tooltip
        this.label = g.label
        this.formatter = g.formatter
        this.data = g.data
        this.height = g.height
        this.categorias = g.categorias
        this.leyenda = g.leyenda

        //creditos
        this.chartData.credits = {
            enabled: false
        }
        this.chartData.exporting = {
            enabled: true
        }
        //datos en común
        //titulo
        this.chartData.title = {
            text: this.titulo
        }
        //titulo
        this.chartData.subtitle = {
            text: this.subtitulo
        }
        //tooltip
        if (this.tooltip) {
            this.chartData.tooltip = {
                pointFormat: this.tooltip
            }
        }
        if (this.height) {
            this.chartData.chart.height = this.height
        }
        if (g.tipoColor) {
            this.tipoColor = g.tipoColor
        }
        if (g.colors) {
            this.colors = g.colors
        }
        if (this.leyenda) {
            this.chartData.legend = this.leyenda
        }

        this.setChartColors()
        //datos dependientes del tipo de gráfico
        this.setChartData()
    }

    public setChartData() {
        throw new Error("Not implemented")
    }

    public setChartColors() {
        if (this.colors) {
            this.chartData.colors = this.colors
            return
        }

        switch (this.tipoColor) {
            case ChartColorType.Resultados:
                this.chartData.colors = ["#9ec74d", "#ea6767", "#c0c0c0"]
                break

            default:
                this.chartData.colors = [
                    "#32a0cc",
                    "#93c149",
                    "#d74545",
                    "#6159ce",
                    "#48b38c",
                    "#fbcb21",
                    "#ee7d30",
                    "#2b908f",
                    "#434348",
                    "#91e8e1"
                ]
                break
        }
    }

    public setCustomChartColors(colors: string[]) {
        this.chartData.colors = colors
    }
}
