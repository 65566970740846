import { Component, Input } from "@angular/core"
import { MenuItemBoxData } from "./menu_item_box.interface"
import { trigger, state, style, animate, transition, keyframes, query, stagger } from "@angular/animations"

@Component({
    selector: "menu-grid-layout",
    templateUrl: "menu_grid_layout.component.html",
    styleUrls: ["menu_grid_layout.component.scss"],
    animations: [
        trigger("slideIn", [
            transition("* => *", [
                query(".slideIn", style({ opacity: 0 }), { optional: true }),
                query(
                    ".slideIn",
                    [
                        stagger(100, [
                            animate(
                                "0.3s ease-in",
                                keyframes([
                                    style({ opacity: 0, transform: "translateY(-20px)", offset: 0 }),
                                    style({ opacity: 1, transform: "translateY(5px)", offset: 0.8 }),
                                    style({ opacity: 1, transform: "translateY(0)", offset: 1.0 })
                                ])
                            )
                        ])
                    ],
                    { optional: true }
                )
            ])
        ])
    ]
})
export class MenuGridLayoutComponent {
    @Input() _menuItems: MenuItemBoxData[]
    firstColumn: MenuItemBoxData[]
    secondColumn: MenuItemBoxData[]
    @Input() maxColumns: number = 2
    columnClasses: string = "col-md-6"

    @Input()
    set menuItems(items) {
        this._menuItems = items
        this.setColumns()
    }
    get estadisticas() {
        return this._menuItems
    }

    setColumns() {
        let indexToSplit = 0
        switch (this.maxColumns) {
            case 1:
                this.firstColumn = this._menuItems
                this.columnClasses = "col-md-12"
                break

            case 2:
                indexToSplit = this._menuItems.length / 2
                this.firstColumn = this._menuItems.filter((m, i) => i % 2 == 0)
                this.secondColumn = this._menuItems.filter((m, i) => i % 2 != 0)
                this.columnClasses = "col-md-6"
                break

            default:
                indexToSplit = this._menuItems.length / 2
                this.firstColumn = this._menuItems.filter((m, i) => i % 2 == 0)
                this.secondColumn = this._menuItems.filter((m, i) => i % 2 != 0)
                this.columnClasses = "col-md-6"
                break
        }
    }
}
