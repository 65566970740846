import { Component, OnInit, Type } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { RegisterIncompletoPuntajeColombiaComponent } from "@puntaje/puntaje/new-modules/usuarios"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "incomplete_register.component.html"
})
export class IncompleteRegisterComponent implements OnInit {
    unloggedLayoutTitle = "Ya estás casi listo!"
    subData: Subscription

    component: Type<any> = RegisterIncompletoPuntajeColombiaComponent
    outputs: any = { onTitleChange: this.onTitleChange }

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.incomplete_register")
        this.subData = this.route.data.subscribe(data => {
            this.component = data.component ?? this.component
        })
    }

    onTitleChange(title) {
        this.unloggedLayoutTitle = title
    }
}
