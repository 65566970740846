import {
    EventEmitter,
    Component,
    ViewChild,
    Input,
    Output,
    OnInit,
    AfterViewInit,
    ChangeDetectorRef
} from "@angular/core"
import { AuthService, GenericModalComponent } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Material, Materiales } from "@puntaje/nebulosa/api-services"
import { MaterialPreviewModalService } from "./material_preview_modal.service"
import { Subscription } from "rxjs"
import { Router } from "@angular/router"
import { MaterialPreviewComponent } from "../material_preview.component"
import { DescargaMaterialUsuario, DescargaMaterialUsuarios } from "@puntaje/puntaje/api-services"

@Component({
    selector: "material-preview-modal",
    templateUrl: "material_preview_modal.component.html",
    styleUrls: ["material_preview_modal.component.scss"]
})
export class MaterialPreviewModalComponent implements OnInit, AfterViewInit {
    @Input() modalId: string
    @Input() materialId: number
    @Input() clasificacionTipo: string
    @Input() reloadMaterial: boolean = true
    @Input() disableSaveTiempoMaterialUsuario: boolean = false

    material: Material
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @ViewChild("previewMaterial") previewMaterial: MaterialPreviewComponent

    @Output() closeCrossCallback: EventEmitter<any> = new EventEmitter<any>()

    openSub: Subscription
    closeSub: Subscription

    constructor(
        protected materialesService: Materiales,
        protected cdr: ChangeDetectorRef,
        protected router: Router,
        protected materialPreviewModalService: MaterialPreviewModalService,
        protected descargaMaterialUsuarioService: DescargaMaterialUsuarios,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.openSub = this.materialPreviewModalService.open$.subscribe(material => {
            this.open(material)
        })

        this.closeSub = this.materialPreviewModalService.close$.subscribe(() => {
            this.close()
        })
    }

    ngAfterViewInit() {
        this.loadingLayout.standby()
    }

    loadMaterial(material) {
        if (this.reloadMaterial) {
            this.loadingLayout.standby()
            this.materialesService.find(material.id).then(material => {
                this.material = material
                this.cdr.detectChanges()
                this.loadingLayout.ready()
            })
        } else {
            this.material = material
            this.cdr.detectChanges()
            this.loadingLayout.ready()
        }
    }

    closeCross() {
        this.closeCrossCallback.emit()
    }

    public open(material) {
        this.loadMaterial(material)
        this.genericModal.buttonPressed()
    }

    public close() {
        this.genericModal.close()
    }

    getDownloadLink = (m: Material) => {
        return m.url
    }

    ngOnDestroy() {
        this.openSub.unsubscribe()
        this.closeSub.unsubscribe()
    }

    goToMaterial(material) {
        this.close()
        this.router.navigate(["/materiales/" + material.id])
    }

    closeModalMaterial() {
        this.previewMaterial.clearIntervalo()
    }

    onDownload() {
        const descargaMatUsuario = new DescargaMaterialUsuario()
        descargaMatUsuario.material_id = this.material.id
        descargaMatUsuario.usuario_id = this.authService.getUserData().id

        this.descargaMaterialUsuarioService.save(descargaMatUsuario)
    }
}
