import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"

import { Subscription } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "evaluaciones_compartidas.component.html"
})
export class EvaluacionesCompartidasComponent implements OnInit, OnDestroy {
    asignaturaId: number
    tipoInstrumento: string
    sub: Subscription
    subData: Subscription
    asignatura: Asignatura
    hideButtonEscanear = true

    constructor(protected titleService: TitleService, protected route: ActivatedRoute, protected router: Router) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.evaluaciones_compartidas")
        this.sub = this.route.queryParams.subscribe(queryParams => {
            this.asignaturaId = +queryParams["asignatura_id"]
            this.tipoInstrumento = queryParams["tipo_instrumento"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.hideButtonEscanear = data.hideButtonEscanear ?? this.hideButtonEscanear
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    onSelectAsignatura(asignaturaId) {
        this.asignaturaId = asignaturaId
    }
}
