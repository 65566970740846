import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter } from "@angular/core"
import { CarreraSede } from "@puntaje/carreras/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Usuario } from "@puntaje/puntaje/api-services"
import { CarrerasPonderaciones } from "../carreras-ponderaciones.service"
import { Subscription, combineLatest } from "rxjs"

@Component({
    selector: "ponderaciones-bar-divider",
    templateUrl: "ponderaciones-bar-divider.component.html",
    styleUrls: ["ponderaciones-bar-divider.component.scss"]
})
export class PonderacionesBarDividerComponent implements OnInit {
    @Input() carreraSede: CarreraSede
    ponderables: any[]
    ponderablesBarra: any[]
    ponderablesBarraHistoria: any[]
    ponderablesBarraCiencia: any[]
    promedioByPonderable: any
    simulacionByPonderable: any
    usuario: Usuario

    @Input() visibleLabels: boolean = false
    @Input() homeView: boolean = false
    @Input() disableLegenda: boolean = false

    startAnimValues: boolean = false
    enableSmallSize: boolean = false
    enableBigSize: boolean = false

    ponderablesFiltered: any[]
    promedioPonderado: number
    simulacionPonderado: number
    promedioPonderadoHistoria: number
    simulacionPonderadoHistoria: number
    promedioPonderadoCiencia: number
    simulacionPonderadoCiencia: number

    multipleBars: boolean = false

    sub: Subscription

    constructor(protected cdr: ChangeDetectorRef, protected carrerasPonderaciones: CarrerasPonderaciones) {}

    ngOnInit() {
        this.sub = combineLatest(
            this.carrerasPonderaciones.ponderables$,
            this.carrerasPonderaciones.usuario$,
            this.carrerasPonderaciones.promedioByPonderable$,
            this.carrerasPonderaciones.simulacionByPonderable$
        ).subscribe(([ponderables, usuario, promedioByPonderable, simulacionByPonderable]) => {
            this.ponderables = ponderables
            this.usuario = usuario
            this.promedioByPonderable = promedioByPonderable
            this.simulacionByPonderable = simulacionByPonderable

            this.carrerasPonderaciones.carreraSede = this.carreraSede
            this.carrerasPonderaciones.ponderables = this.ponderables
            this.carrerasPonderaciones.resetBarData()
            this.setBarData()
        })
    }

    adaptToSize(cs: number) {
        this.enableSmallSize = cs > 0 && cs <= 500
        this.enableBigSize = cs > 0 && cs >= 800
        this.cdr.detectChanges()
    }

    setBarData() {
        this.carrerasPonderaciones.setPonderacionesBars()
        this.multipleBars = this.carrerasPonderaciones.multipleBars
        this.ponderablesBarra = this.carrerasPonderaciones.ponderablesBarra
        this.ponderablesBarraHistoria = this.carrerasPonderaciones.ponderablesBarraHistoria
        this.ponderablesBarraCiencia = this.carrerasPonderaciones.ponderablesBarraCiencia

        this.promedioPonderado = this.carrerasPonderaciones.promedioPonderado
        this.simulacionPonderado = this.carrerasPonderaciones.simulacionPonderado
        this.promedioPonderadoHistoria = this.carrerasPonderaciones.promedioPonderadoHistoria
        this.simulacionPonderadoHistoria = this.carrerasPonderaciones.simulacionPonderadoHistoria
        this.promedioPonderadoCiencia = this.carrerasPonderaciones.promedioPonderadoCiencia
        this.simulacionPonderadoCiencia = this.carrerasPonderaciones.simulacionPonderadoCiencia
    }
}
