import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { EstadoReporte } from "./estado_reportes.model"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"

@Injectable()
export class EstadoReportes extends NebuBaseService<EstadoReporte> {
    tableName = "estado_reportes"
    singularTableName = "estado_reporte"
    protected objectQuery: EstadoReporte
    modelClass = EstadoReporte

    constructor(
        http: HttpClient,
        auth: NebuAuthService,
        router: Router,
        errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
