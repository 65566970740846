/** @format */

import { Action } from "@ngrx/store"

export enum EvaluacionTiposActionTypes {
    SetSelectedEvaluacionTipo = "[EvaluacionTipos] Set Selected Evaluacion Tipo",
    SetEvaluacionTipos = "[EvaluacionTipos] Set Evaluacion Tipos",
    GetEvaluacionTipoAliases = "[EvaluacionTipos] Get Evaluacion Tipo Aliases",
    SetEvaluacionTipoAliases = "[EvaluacionTipos] Set Evaluacion Tipo Aliases"
}

export class SetSelectedEvaluacionTipo implements Action {
    readonly type = EvaluacionTiposActionTypes.SetSelectedEvaluacionTipo

    constructor(public payload: { selectedEvaluacionTipo: string }) {}
}

export class SetEvaluacionTipos implements Action {
    readonly type = EvaluacionTiposActionTypes.SetEvaluacionTipos

    constructor(public payload: { evaluacionTipos: string[] }) {}
}

export class GetEvaluacionTipoAliases implements Action {
    readonly type = EvaluacionTiposActionTypes.GetEvaluacionTipoAliases
}

export class SetEvaluacionTipoAliases implements Action {
    readonly type = EvaluacionTiposActionTypes.SetEvaluacionTipoAliases

    constructor(public payload: { evaluacionTipoAliases: any }) {}
}

export type EvaluacionTiposActions =
    | SetSelectedEvaluacionTipo
    | SetEvaluacionTipos
    | GetEvaluacionTipoAliases
    | SetEvaluacionTipoAliases
