<div (window:resize)="checkResponsive()" #tablaContenedor>
    <loading-layout #loadingLayout>
        <div class="contenido">
            <cui-section-headline *ngIf="asignatura$ | async as asignatura">
                {{ evaluacionTipoAlias | transformInstrumento: "plural" | capitalize }} {{ "ensayo_instancias.de" | t }}
                {{ asignatura?.["confByEvaluacionTipo"]?.[evaluacionTipo]?.abreviacion }}
                {{ "progreso.historial.terminadas.titulo" | t | genderize: evaluacionTipo }}
            </cui-section-headline>
            <div class="boton_realizar_ensayo" *ngIf="enableButtonRealizarEvaluacion">
                <cui-button-link
                    class="btn_style btn-sm"
                    [routerLinkValue]="['/ensayos', 'generar_ensayo', asignaturaId]"
                >
                    {{ "ensayo_instancias.realizar" | t }} {{ evaluacionTipo | transformInstrumento: "singular" }}
                </cui-button-link>
            </div>
            <table
                id="tabla_ensayos"
                class="table tabla_ensayos"
                *ngIf="evaluacionInstancias.length > 0"
                [class.trigger-responsive]="enableResponsiveTable"
            >
                <thead>
                    <tr>
                        <th scope="col">{{ "progreso.historial.terminadas.forma" | t }}</th>
                        <th scope="col" *ngIf="!hideNombre">{{ "progreso.historial.terminadas.nombre" | t }}</th>
                        <th scope="col">{{ nombreColumna }}</th>
                        <!-- <th>Estado</th> -->
                        <!-- <th>Oficial</th> -->
                        <!-- <th>Correctas</th> -->
                        <!-- <th>Incorrectas</th> -->
                        <!-- <th>Omitidas</th> -->
                        <th scope="col" *ngIf="hasResultDateHeader">
                            {{ "progreso.historial.terminadas.resultados" | t }}
                        </th>
                        <th *ngIf="graduate" scope="col">{{ "progreso.historial.terminadas.calificacion" | t }}</th>
                        <th *ngIf="graduate; else otherTH" scope="col">
                            {{ "progreso.historial.terminadas.porcentaje" | t }}
                        </th>
                        <ng-template #otherTH>
                            <th scope="col">{{ "progreso.historial.terminadas.calificacion" | t }}</th>
                        </ng-template>
                        <th *ngIf="!hideCompartidoPor" scope="col">
                            {{ "progreso.historial.terminadas.compartido" | t }}
                        </th>
                        <th scope="col">{{ "progreso.historial.terminadas.fecha" | t }}</th>
                        <th *ngIf="false" scope="col">
                            {{ "progreso.historial.terminadas.reforzamiento" | t }}
                        </th>
                        <!-- <th>Tipo</th> -->
                        <th scope="col">{{ "progreso.historial.terminadas.acciones" | t }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let evaluacionInstancia of evaluacionInstancias; let i = index"
                        [class.odd]="i % 2 == 1"
                    >
                        <th scope="row" class="row_header">
                            <span class="resp-th">{{ "progreso.historial.terminadas.forma" | t }}</span>
                            {{ evaluacionInstancia.evaluacion_id }}
                        </th>
                        <td *ngIf="!hideNombre">
                            <span class="resp-th">{{ "progreso.historial.terminadas.nombre" | t }}</span>
                            {{ evaluacionInstancia.evaluacion.evaluacion || "Sin nombre" }}
                        </td>
                        <td>
                            <span class="resp-th">{{ nombreColumna }}</span>
                            {{ evaluacionInstancia.evaluacion.instrumento.numero_preguntas }}
                        </td>
                        <!-- <td><span class="resp-th">Estado</span>Terminado</td> -->
                        <!-- <td><span class="resp-th">Oficial</span>{{ evaluacionInstancia.oficial ? 'Sí' : 'No' }}</td> -->
                        <!-- <ng-container *ngIf="checkDateAvailable(evaluacionInstancia.evaluacion.fecha_mostrar_respuestas)">
							<td><span class="resp-th">Correctas</span>{{ evaluacionInstancia.correctas }}</td>
							<td><span class="resp-th">Incorrectas</span>{{ evaluacionInstancia.incorrectas }}</td>
							<td><span class="resp-th">Omitidas</span>{{ evaluacionInstancia.omitidas }}</td>
						</ng-container> -->

                        <td class="text-center" *ngIf="hasResultDateHeader">
                            <ng-container
                                *ngIf="!checkDateAvailable(evaluacionInstancia.evaluacion.fecha_mostrar_respuestas)"
                            >
                                {{ "progreso.historial.terminadas.fecha_disponible" | t }}
                                {{ getFechaCL(evaluacionInstancia.evaluacion.fecha_mostrar_respuestas) }}
                            </ng-container>
                        </td>
                        <td *ngIf="graduate">
                            <span class="resp-th">{{ "ensayo_instancias.puntaje" | t }}</span>
                            {{
                                getNotaEscalaSiete(
                                    evaluacionInstancia.correctas,
                                    evaluacionInstancia.incorrectas,
                                    evaluacionInstancia.omitidas
                                ) | roundfloat
                            }}
                        </td>
                        <td>
                            <ng-container *ngIf="evaluacionInstancia.evaluacion.sumativa">
                                <ng-container
                                    [ngSwitch]="evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo"
                                >
                                    <ng-container *ngSwitchCase="'evaluacion plan estudio'">
                                        <span class="resp-th">{{ "ensayo_instancias.puntaje" | t }}</span>
                                        {{ porcentajeCorrectas(evaluacionInstancia) | roundfloat }} %
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'diagnostico plan estudio'">
                                        <span class="resp-th">{{ "ensayo_instancias.puntaje" | t }}</span>
                                        {{ porcentajeCorrectas(evaluacionInstancia) | roundfloat }} %
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'paa'">
                                        <span class="resp-th">{{ "ensayo_instancias.puntaje" | t }}</span>
                                        {{ porcentajeCorrectas(evaluacionInstancia) | roundfloat }} %
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <span class="resp-th">{{ "ensayo_instancias.puntaje" | t }}</span>
                                        {{ evaluacionInstancia.calificacion | roundfloat: 1 }}
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!evaluacionInstancia.evaluacion.sumativa">-</ng-container>
                        </td>
                        <td *ngIf="!hideCompartidoPor">
                            <span class="resp-th">{{ "progreso.historial.terminadas.compartido" | t }}</span>
                            {{ evaluacionInstancia.evaluacion.compartido_por }}
                        </td>
                        <td>
                            <span class="resp-th">{{ "progreso.historial.terminadas.fecha" | t }}</span>
                            {{ evaluacionInstancia.created_at | fechaAtras }}
                        </td>
                        <td *ngIf="false" class="text-center">
                            <span class="resp-th">{{ "progreso.historial.terminadas.reforzamiento" | t }}</span>
                            <span
                                *ngIf="estadoReforzamientoPorEvaluacion[evaluacionInstancia.evaluacion_id] == undefined"
                            >
                                -
                            </span>
                            <span
                                *ngIf="estadoReforzamientoPorEvaluacion[evaluacionInstancia.evaluacion_id] != undefined"
                                class="reforzamiento"
                                [class.unfinished]="
                                    !estadoReforzamientoPorEvaluacion[evaluacionInstancia.evaluacion_id]
                                "
                                [class.finished]="estadoReforzamientoPorEvaluacion[evaluacionInstancia.evaluacion_id]"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="{{
                                    estadoReforzamientoPorEvaluacion[evaluacionInstancia.evaluacion_id]
                                        ? 'Reforzamiento realizado'
                                        : 'Reforzamiento pendiente'
                                }}"
                            >
                                R
                            </span>
                        </td>
                        <!-- <td><span class="resp-th">Tipo</span>{{ evaluacionInstancia.evaluacion.presencial ? 'Presencial' : 'Online' }}</td> -->
                        <td class="last">
                            <span class="resp-th">{{ "progreso.historial.terminadas.acciones" | t }}</span>
                            <cui-button-link
                                class="btn_style btn-sm tabla-btn icon_a"
                                [routerLinkValue]="[urlResultado, evaluacionInstancia.id]"
                            >
                                <span class="glyphicon glyphicon-search"></span>
                                {{ "progreso.historial.terminadas.ver" | t }}
                            </cui-button-link>
                            <cui-button-link
                                *ngIf="
                                    !hideVolverARealizar &&
                                    evaluacionInstancia.evaluacion.evaluacion_multiples.length === 0 &&
                                    !evaluacionInstancia.evaluacion.presencial
                                "
                                class="btn_style btn-sm tabla-btn icon_a"
                                [attr.aria-label]="'Volver a realizar'"
                                [routerLinkValue]="[urlRealizar, evaluacionInstancia.evaluacion_id, 'realizar']"
                            >
                                <span class="glyphicon glyphicon-repeat"></span>
                            </cui-button-link>
                            <cui-button-link
                                *ngIf="
                                    showButton &&
                                    checkDateAvailable(evaluacionInstancia.evaluacion.fecha_mostrar_respuestas)
                                "
                                class="btn_style btn-sm tabla-btn icon_a"
                                [routerLinkValue]="['/ensayo_instancias/estadisticas', evaluacionInstancia.id]"
                            >
                                <span class="glyphicon glyphicon-signal"></span>
                                {{ "progreso.historial.terminadas.estadisticas" | t }}
                            </cui-button-link>
                            <cui-button-link
                                *ngIf="
                                    estadoReforzamientoPorEvaluacion[evaluacionInstancia.evaluacion_id] != undefined &&
                                    reforzamientosByEvaluacionId[evaluacionInstancia.evaluacion_id]
                                        .plan_personal_ciclos[0].plan_personal_sesiones[0] != undefined
                                "
                                class="btn_style btn-sm tabla-btn icon_a"
                                [attr.aria-label]="'Volver a realizar'"
                                [routerLinkValue]="[
                                    '/plan_personal',
                                    reforzamientosByEvaluacionId[evaluacionInstancia.evaluacion_id].id,
                                    'ciclo',
                                    '0',
                                    'sesiones',
                                    reforzamientosByEvaluacionId[evaluacionInstancia.evaluacion_id]
                                        .plan_personal_ciclos[0].plan_personal_sesiones[0].id
                                ]"
                            >
                                <fa name="repeat" class="icono"></fa>
                                {{
                                    estadoReforzamientoPorEvaluacion[evaluacionInstancia.evaluacion_id]
                                        ? ("progreso.historial.terminadas.ver_reforzamiento" | t)
                                        : ("progreso.historial.terminadas.realizar_reforzamiento" | t)
                                }}
                            </cui-button-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <paginator *ngIf="asignatura" [getList]="showAll"></paginator>
        </div>
        <p class="no-data" *ngIf="evaluacionInstancias.length == 0">
            {{
                "progreso.historial.terminadas.not_found"
                    | t: { evaluacionTipo: evaluacionTipoAlias | transformInstrumento: "plural" }
            }}
        </p>
    </loading-layout>
</div>
