<div class="lateral-container">
    <div class="resultado_puntaje">
        <loading-layout #lateralLoadingLayout>
            <div class="fade-lateral" *ngIf="evaluacionInstancia">
                <div role="heading" aria-level="1" class="resultado_puntaje_titulo">
                    <ng-container *ngIf="evaluacionInstancia.evaluacion.tarea; else default">
                        {{ "progreso.historial.resultados.tarea" | t }} #{{ evaluacionInstancia.evaluacion_id }}
                    </ng-container>
                    <ng-template #default>
                        {{ "progreso.historial.resultados.titulo" | t }}
                        {{ tipoEvaluacionParaTexto || tipoEvaluacion | transformInstrumento: "singular" }} #{{
                            evaluacionInstancia.evaluacion_id
                        }}
                    </ng-template>
                </div>
                <div class="">
                    <evaluacion-escalas-globales
                        *ngIf="evaluacionInstancia.evaluacion.sumativa && escalasGlobalesAliases?.length"
                        [escalasGlobales]="escalasGlobalesAliases"
                    ></evaluacion-escalas-globales>

                    <div
                        class="resultado_puntaje_puntos_wrap"
                        *ngIf="evaluacionInstancia.evaluacion.sumativa && !escalasGlobalesAliases?.length"
                    >
                        <div class="resultado_puntaje_puntos" *ngIf="calificacion != null || calificacion != undefined">
                            <ng-container [ngSwitch]="evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo">
                                <div *ngSwitchCase="'curricular'">
                                    <div class="resultado_puntaje_nota">
                                        {{ calificacion | roundfloat: 1 }}
                                    </div>
                                </div>
                                <div *ngSwitchCase="'evaluacion plan estudio'">
                                    <div class="resultado_puntaje_porcentaje_numero">
                                        {{ porcentajeCorrectas(evaluacionInstancia) | roundfloat }} %
                                    </div>
                                </div>
                                <div *ngSwitchCase="'diagnostico plan estudio'">
                                    <div class="resultado_puntaje_porcentaje_numero">
                                        {{ porcentajeCorrectas(evaluacionInstancia) | roundfloat }} %
                                    </div>
                                </div>
                                <div *ngSwitchDefault>
                                    <ng-container *ngIf="layaoutResultadoCalificacion == 'vertical'">
                                        <div class="resultado_puntaje_puntos_numero">
                                            {{ calificacion | roundfloat }}
                                        </div>
                                        <div class="resultado_puntaje_puntos_texto">
                                            {{ nombreTipoCalificacion }}
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="layaoutResultadoCalificacion == 'horizontal'">
                                        <span class="resultado_puntaje_puntos_numero horizontal">
                                            {{ calificacion | roundfloat }}
                                        </span>
                                        <span class="resultado_puntaje_puntos_texto horizontal">
                                            {{ nombreTipoCalificacion }}
                                        </span>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div class="resultado_puntaje_valores">
                        <table class="table">
                            <tbody>
                                <tr (click)="selectTab('correctas')" class="correctas_row pointer">
                                    <th scope="row" class="without_border_top">
                                        <span class="glyphicon glyphicon glyphicon-ok-circle res-icon"></span>
                                        <span class="res-icon-text">
                                            {{ "progreso.historial.resultados.correctas" | t }}
                                        </span>
                                    </th>
                                    <td>{{ evaluacionInstancia.correctas }}</td>
                                </tr>
                                <tr (click)="selectTab('incorrectas')" class="incorrectas_row pointer">
                                    <th scope="row" class="without_border_top">
                                        <span class="glyphicon glyphicon glyphicon-remove-circle res-icon"></span>
                                        {{ "progreso.historial.resultados.incorrectas" | t }}
                                    </th>
                                    <td>{{ evaluacionInstancia.incorrectas }}</td>
                                </tr>
                                <tr (click)="selectTab('omitidas')" class="omitidas_row pointer">
                                    <th scope="row" class="without_border_top">
                                        <span class="glyphicon glyphicon glyphicon-ban-circle res-icon"></span>
                                        {{ "progreso.historial.resultados.omitidas" | t }}
                                    </th>
                                    <td>{{ evaluacionInstancia.omitidas }}</td>
                                </tr>
                                <tr class="omitidas_row" *ngIf="quantityPilotaje && quantityPilotaje != 0">
                                    <th scope="row" class="without_border_top">
                                        <span class="glyphicon glyphicon-question-sign res-icon"></span>
                                        {{ "progreso.historial.resultados.pilotaje" | t }}
                                    </th>
                                    <td>{{ quantityPilotaje }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="recomendaciones" *ngIf="config.plataforma.name !== 'Sanisidoro'">
                        <h3>{{ "progreso.historial.resultados.recomendaciones" | t }}</h3>
                        <ul>
                            <li class="recomendacion">
                                <span class="icon_solucion">
                                    {{ "progreso.historial.resultados.ver_solucion" | t }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <!-- recomendaciones -->

                    <div class="recomendaciones" *ngIf="evaluacionInstancia.evaluacion.debilidades">
                        <ul>
                            <li class="recomendacion">
                                <span class="icon_solucion">
                                    <fa class="icon" [name]="'exclamation'"></fa>
                                    {{ "progreso.historial.resultados.debilidades" | t }}
                                    <a routerLink="/home">Actualizadas</a>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="opciones-resultado clearfix" *ngIf="!disableOptions">
                    <h3>{{ "progreso.historial.resultados.opciones" | t }}</h3>
                    <ng-container *ngIf="enableCompartirResultados">
                        <cui-button class="btn_style opcion-btn" (click)="openModal.emit()">
                            <span class="glyphicon glyphicon-repeat"></span>
                            {{ "progreso.historial.resultados.compartir" | t }}
                        </cui-button>
                    </ng-container>
                    <cui-button-link
                        class="btn_style opcion-btn"
                        href="/ensayo/realizar/id/8064143"
                        *ngIf="volverARealizar"
                        [routerLinkValue]="[rutaVolverARealizar, evaluacionInstancia.evaluacion_id, 'realizar']"
                    >
                        <span class="glyphicon glyphicon-repeat"></span>
                        {{ "progreso.historial.resultados.volver" | t }}
                    </cui-button-link>
                    <cui-button-link
                        *ngIf="realizaNuevo"
                        class="btn_style opcion-btn"
                        [routerLinkValue]="[
                            '/ensayos',
                            'generar_ensayo',
                            evaluacionInstancia.evaluacion.instrumento.asignatura_id
                        ]"
                    >
                        <span class="glyphicon glyphicon-repeat"></span>
                        {{ "progreso.historial.resultados.realizar" | t }} {{ "nuevo" | genderize: tipoEvaluacion }}
                        {{ tipoEvaluacionParaTexto || tipoEvaluacion | transformInstrumento: "singular" }}
                    </cui-button-link>
                    <cui-button-link
                        class="btn_style opcion-btn"
                        [routerLinkValue]="[rutaVerEvaluaciones || '/ensayo_instancias']"
                        [queryParamsValue]="{
                            tipo_instrumento: evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo,
                            asignatura_id: evaluacionInstancia.evaluacion.instrumento.asignatura_id
                        }"
                    >
                        <span class="glyphicon glyphicon-search"></span>
                        {{ "progreso.historial.resultados.ver" | t }}
                        {{ tipoEvaluacion | transformInstrumento: "plural" }} de {{ nombreAsignatura }}
                        {{ "realizados" | genderize: tipoEvaluacion }}
                    </cui-button-link>
                    <cui-button-link
                        *ngIf="backButton"
                        class="btn_style opcion-btn"
                        [routerLinkValue]="backButtonUrl"
                        (click)="clearButton()"
                    >
                        <span class="glyphicon glyphicon-arrow-left"></span>
                        &nbsp;{{ backButtonText }}
                    </cui-button-link>
                </div>
            </div>
        </loading-layout>
    </div>
    <!-- resultado_puntaje -->
</div>
<compartir-resultados-evaluacion
    *ngIf="evaluacionInstancia"
    [openModal]="openModal"
    [evaluacionInstancia]="evaluacionInstancia"
    [tipoEvaluacion]="tipoEvaluacion"
></compartir-resultados-evaluacion>
