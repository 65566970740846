import { HttpClient } from "@angular/common/http"
import { Component, OnInit, ViewChild } from "@angular/core"
import { NgModel } from "@angular/forms"
import { Router } from "@angular/router"
import { AuthService, I18nService, Validations } from "@puntaje/shared/core"
import { Usuario, Usuarios } from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"

@Component({
    selector: "usuario-edit-password",
    templateUrl: "edit_password.form.component.html",
    styleUrls: ["edit_password.component.scss"],
    providers: [Usuarios]
})
export class EditPasswordComponent implements OnInit {
    usuario: Usuario = new Usuario()
    usuario_id: number
    current_password: string
    password: string
    password_confirmation: string
    hasPassword: boolean
    userData: any
    currentPaswordMsg: string
    passwordConfirmationMsg: string
    passwordMsg: string
    invalidPassword: boolean = false
    passwordMinLength: number = 8

    checkingSubscription: Subscription
    breachedPassword: boolean = false

    @ViewChild("passwordControl") passwordControl: NgModel

    constructor(
        protected authService: AuthService,
        protected usuariosService: Usuarios,
        protected router: Router,
        protected http: HttpClient,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {
        this.userData = this.authService.getUserData()
        this.hasPassword = this.userData.has_password
        this.usuario_id = this.userData.id
    }

    async changePassword() {
        this.usuario.id = this.usuario_id

        await this.isBreachedPassword(this.passwordControl)

        if (
            this.validPassword(this.password) &&
            this.validPassword(this.password_confirmation) &&
            !this.breachedPassword
        ) {
            if (this.hasPassword) {
                this.usuario["current_password"] = this.current_password
            }
            this.usuario["password"] = this.password
            this.usuario["password_confirmation"] = this.password_confirmation
            this.updateUser()
        } else if (!this.validPassword(this.password) || !this.validPassword(this.password_confirmation)) {
            this.invalidPassword = true
        }
    }

    validPassword(password) {
        return password && password.length >= this.passwordMinLength
    }

    isBreachedPassword(control) {
        const validator = Validations.checkPasswordBreach(this.http)

        return new Promise((resolve, reject) => {
            this.checkingSubscription?.unsubscribe()

            this.checkingSubscription = validator(control).subscribe(validation => {
                this.breachedPassword = validation?.breachedPassword?.valid == false ? true : false // checkeo si es falso porque si es null, es como si fuese valid == true

                resolve(this.breachedPassword)
            })
        })
    }

    updateUser = () => {
        this.usuariosService.enableIgnoreCatch()
        this.usuariosService
            .updatePassword(this.usuario_id, this.usuario)
            .then(response => {
                this.userData.has_password = true
                this.authService.setUserData(this.userData)
                this.router.navigate([""])
            })
            .catch(response => {
                if (response && response.status == 422) {
                    if (response.error.current_password && response.error.current_password[0] == `can't be blank`) {
                        this.currentPaswordMsg = this.i18nService.translate("usuario_edit_password.no_vacia")
                    } else if (response.error.current_password && response.error.current_password[0] == `is invalid`) {
                        this.currentPaswordMsg = this.i18nService.translate("usuario_edit_password.no_valida")
                    } else {
                        this.currentPaswordMsg = ""
                    }
                    if (
                        response.error.password_confirmation &&
                        response.error.password_confirmation[0] == `doesn't match Password`
                    ) {
                        this.passwordConfirmationMsg = this.i18nService.translate("usuario_edit_password.no_coinciden")
                    } else {
                        this.passwordConfirmationMsg = ""
                    }
                } else {
                    throw new Error("Error " + response.status)
                }
            })
    }
}
