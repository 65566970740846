import { PuntajeDudasModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { CUIInputsModule } from "@puntaje/shared/cui"

import { routing } from "./alumnos_dudas.routing"
import { DudasModule } from "@puntaje/puntaje/new-modules/dudas"
import { ApiModule as PuntajeApiModule } from "@puntaje/puntaje/api-services"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"

import { UtilModule } from "@puntaje/shared/core"

@NgModule({
    imports: [
        CommonModule,
        PuntajeApiModule,
        AlumnosLayoutsModule,
        DudasModule,
        routing,
        Angular2FontawesomeModule,
        CUIInputsModule,
        UtilModule,
        PuntajeDudasModule
    ],
    declarations: [],
    exports: []
})
export class AlumnosDudasModule {}
