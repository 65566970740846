import { Component, OnInit, OnChanges, ViewChild, Input, SimpleChanges } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "horizontal-stacked-bar",
    templateUrl: "horizontal_stacked_bar.component.html",
    styleUrls: ["horizontal_stacked_bar.component.scss"]
})
export class HorizontalStackedBarComponent implements OnInit, OnChanges {
    @Input() values: number[]
    @Input() isMain: boolean = false
    total: number
    percentageValues: number[]
    isEmpty: boolean = false
    classes = ["correctas", "incorrectas", "omitidas"]

    constructor() {}

    ngOnInit() {
        this.drawBars()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["values"] && this.isMain) {
            this.drawBars()
        }
    }

    drawBars() {
        this.total = this.values.reduce((acc, v) => acc + v, 0)
        if (this.total == 0) {
            this.isEmpty = true
        } else if (this.isMain && this.total == this.values[this.values.length - 1]) {
            this.isEmpty = true
        } else {
            this.percentageValues = this.values.map(v => {
                return (100 * v) / this.total
            })
            this.isEmpty = false
        }
    }
}
