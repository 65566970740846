import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { CheckActividadAsync } from "@puntaje/shared/core"
import { CarreraSede, PreferenciaCarreraSedes, PreferenciaCarreraSede } from "@puntaje/carreras/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "seguir-carrera",
    templateUrl: "./seguir-carrera.component.html",
    styleUrls: ["./seguir-carrera.component.scss"]
})
export class SeguirCarreraComponent implements OnInit {
    @Input() carreraSede: CarreraSede
    @Output() followEvent = new EventEmitter()
    config: typeof config
    hideNotFollow: boolean = config.carreras?.hideNotFollowCarrera

    constructor(protected preferenciaCarreraSedesService: PreferenciaCarreraSedes) {}

    ngOnInit() {}

    @CheckActividadAsync("SCS")
    seguirCarreraSede(carreraSede) {
        const preferenciaCarreraSede = new PreferenciaCarreraSede()
        preferenciaCarreraSede.carreraSedeId = carreraSede.id

        return this.preferenciaCarreraSedesService.save(preferenciaCarreraSede).then(pcs => {
            carreraSede.preferenciaCarreraSedes.push(pcs)
            this.followEvent.emit(true)

            return pcs
        })
    }

    noSeguirCarreraSede(carreraSede) {
        const preferenciaCarreraSedeId = carreraSede.preferenciaCarreraSedes[0].id
        this.preferenciaCarreraSedesService.remove(preferenciaCarreraSedeId).then(response => {
            carreraSede.preferenciaCarreraSedes = []
            this.followEvent.emit(false)
        })
    }
}
