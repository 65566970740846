<div *ngIf="data" class="tabla-container clearfix">
    <!-- Tabla nueva -->
    <table class="table tabla-resumen">
        <thead>
            <tr>
                <th *ngIf="!multiples && !fromAlumnos; else headIntentos" class="alumno">
                    {{ "tabla_resumen_evaluacion_instancia.estudiante" | t }}
                </th>
                <th class="resultados">Resultados</th>
                <th class="puntaje">{{ "tabla_resumen_evaluacion_instancia.puntaje" | t }}</th>
                <th class="fecha">{{ "tabla_resumen_evaluacion_instancia.fecha" | t }}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container
                *ngIf="multiples; then multipleEvaluacionInstancias; else singleEvaluacionInstacia"
            ></ng-container>
        </tbody>
    </table>
</div>
<!-- Tabla nueva -->
<ng-template #headIntentos>
    <th class="alumno">{{ "tabla_resumen_evaluacion_instancia.intentos" | t }}</th>
</ng-template>

<ng-template #multipleEvaluacionInstancias>
    <tr *ngFor="let ei of data">
        <td>
            <p>
                <fa name="user" class="icono"></fa>
                {{ "tabla_resumen_evaluacion_instancia.intento" | t }} #{{ ei.nintento }}
            </p>
        </td>
        <td>
            <div class="celda-resultados">
                <div class="separador-vertical"></div>
                <div class="col-detalles">
                    <div class="fila-resultados">
                        <div>
                            <p class="valor correctas">{{ ei.correctas | easyplaceholder: "-" }}</p>
                            <p class="subtitulo">{{ "tabla_resumen_evaluacion_instancia.correctas" | t }}</p>
                        </div>
                        <div>
                            <p class="valor incorrectas">{{ ei.incorrectas | easyplaceholder: "-" }}</p>
                            <p class="subtitulo">{{ "tabla_resumen_evaluacion_instancia.incorrectas" | t }}</p>
                        </div>
                        <div>
                            <p class="valor omitidas">{{ ei.omitidas | easyplaceholder: "-" }}</p>
                            <p class="subtitulo">{{ "tabla_resumen_evaluacion_instancia.omitidas" | t }}</p>
                        </div>
                    </div>
                </div>
                <div class="separador-vertical"></div>
            </div>
        </td>
        <td>
            <p class="valor puntaje">{{ ei.calificacion | roundfloat: 1 | easyplaceholder: "-" }}</p>
        </td>
        <td class="fecha">
            <p>{{ ei.created_at | date: "dd-MM-yyyy" | easyplaceholder: "-" }}</p>
            <p>{{ ei.created_at | date: "HH:mm" | easyplaceholder: "-" }}</p>
        </td>
    </tr>
</ng-template>

<ng-template #singleEvaluacionInstacia>
    <tr>
        <td>
            <p>
                <fa name="user" class="icono"></fa>
                {{ data.usuario.nombre }} {{ data.usuario.apellido_materno }}
            </p>
        </td>
        <td>
            <div class="celda-resultados">
                <div class="separador-vertical"></div>
                <div class="col-detalles">
                    <div class="fila-resultados">
                        <div>
                            <p class="valor correctas">{{ data.correctas | easyplaceholder: "-" }}</p>
                            <p class="subtitulo">{{ "tabla_resumen_evaluacion_instancia.correctas" | t }}</p>
                        </div>
                        <div>
                            <p class="valor incorrectas">{{ data.incorrectas | easyplaceholder: "-" }}</p>
                            <p class="subtitulo">{{ "tabla_resumen_evaluacion_instancia.incorrectas" | t }}</p>
                        </div>
                        <div>
                            <p class="valor omitidas">{{ data.omitidas | easyplaceholder: "-" }}</p>
                            <p class="subtitulo">{{ "tabla_resumen_evaluacion_instancia.omitidas" | t }}</p>
                        </div>
                    </div>
                </div>
                <div class="separador-vertical"></div>
            </div>
        </td>
        <td>
            <p class="valor puntaje">{{ data.calificacion | roundfloat | easyplaceholder: "-" }}</p>
        </td>
        <td class="fecha">
            <p>{{ data.created_at | date: "dd-MM-yyyy" | easyplaceholder: "-" }}</p>
            <p>{{ data.created_at | date: "HH:mm" | easyplaceholder: "-" }}</p>
        </td>
    </tr>
</ng-template>
