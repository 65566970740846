import { Directive, Input, ElementRef, HostListener } from "@angular/core"

declare let $

/******************
  Directiva que sirve para scrollear al elemento que lo tiene y no tener esa misma función
  repetida en muchos lados.
  Usa el animate de jQuery (porque es el más smooth)
  Ej:
  <div easyScroll [easyScrollToPadding]="-40" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'"></div>

  Parámetros opcionales:
  - easyScrollToPadding
  - easyScrollToDuration
  - easyScrollToEasing (según los valores del script de jquery.easing)
*******************/

@Directive({
    selector: "[easyScroll]"
})
export class EasyScrollDirective {
    @Input("easyScroll") scrollTarget: string | ElementRef
    @Input("easyScrollPadding") easyScrollPadding: number = 0
    @Input("easyScrollDuration") easyScrollDuration: number = 1000
    @Input("easyScrollEasing") easyScrollEasing: string = "easeInOutExpo"
    _scrollOnVariableChange: any
    @Input()
    set scrollOnVariableChange(variable: any) {
        if (this._scrollOnVariableChange != variable) this.scroll()
        this._scrollOnVariableChange = variable
    }
    get asignatura() {
        return this._scrollOnVariableChange
    }

    constructor(protected el: ElementRef) {}

    scroll() {
        var target = this.getTarget()
        //Descomentar y comentar el resto en caso que no se use jquery, pero es mucho menos suave
        //this.smoothScroll(target, +this.easyScrollToPadding);
        if (target) {
            $("html, body").animate(
                {
                    scrollTop: this.targetYPosition(target) + +this.easyScrollPadding
                },
                this.easyScrollDuration,
                this.easyScrollEasing
            )
        }
    }

    currentYPosition() {
        // Firefox, Chrome, Opera, Safari
        if (this.el.nativeElement.pageYOffset) return this.el.nativeElement.pageYOffset
        // Internet Explorer 6 - standards mode
        if (document.documentElement && document.documentElement.scrollTop) return document.documentElement.scrollTop
        // Internet Explorer 6, 7 and 8
        if (document.body.scrollTop) return document.body.scrollTop
        return 0
    }

    targetYPosition(target) {
        var y = target.offsetTop
        var node = target
        while (node.offsetParent && node.offsetParent != document.body) {
            node = node.offsetParent
            y += node.offsetTop
        }
        return y
    }

    //Fallback en caso que no se use jquery, no es smooth
    smoothScroll(target, padding) {
        var startY = this.currentYPosition()
        var stopY = this.targetYPosition(target)
        stopY += padding
        var distance = stopY > startY ? stopY - startY : startY - stopY
        if (distance < 100) {
            scrollTo(0, stopY)
            return
        }
        var speed = Math.round(distance / 100)
        if (speed >= 20) speed = 20
        var step = Math.round(distance / 25)
        var leapY = stopY > startY ? startY + step : startY - step
        var timer = 0
        if (stopY > startY) {
            for (var i = startY; i < stopY; i += step) {
                setTimeout(window.scrollTo.bind(null, 0, leapY), timer * speed)
                leapY += step
                if (leapY > stopY) leapY = stopY
                timer++
            }
            return
        }
        for (var i = startY; i > stopY; i -= step) {
            setTimeout(window.scrollTo.bind(null, 0, leapY), timer * speed)
            leapY -= step
            if (leapY < stopY) leapY = stopY
            timer++
        }
    }

    getTarget() {
        let target
        if (typeof this.scrollTarget === "string") {
            var targetLink = this.el.nativeElement.getAttribute("href")
            if (targetLink) {
                event.preventDefault()
                target = document.getElementById(targetLink.replace("#", ""))
            } else {
                target =
                    this.scrollTarget.length > 0 ? document.getElementById(this.scrollTarget) : this.el.nativeElement
            }
        } else {
            target = this.scrollTarget.nativeElement
        }
        return target
    }
}
