import { Injectable } from "@angular/core"
import { Subject, Subscription } from "rxjs"

@Injectable()
export class FlashMessageService {
    jumps: number = 0

    _status: string
    _message: string
    status_subject: Subject<string | null>
    message_subject: Subject<string | null>

    statusSub: Subscription
    messageSub: Subscription

    // can be success, warning, info, danger
    set status(value: string) {
        this.status_subject.next(value)
        this.jumps = 1
    }

    get status() {
        return this._status
    }

    set message(value: string) {
        this.message_subject.next(value)
        this.jumps = 1
    }

    get message() {
        return this._message
    }

    setParams(status: string, message: string, jumps: number = 1) {
        this.status_subject.next(status)
        this.message_subject.next(message)
        this.jumps = jumps
    }

    constructor() {
        this.status_subject = new Subject<string>()
        this.message_subject = new Subject<string>()
        this.statusSub = this.status_subject.subscribe((value: string | null) => {
            this._status = value
        })
        this.messageSub = this.message_subject.subscribe((value: string | null) => {
            this._message = value
        })
    }

    clear() {
        if (this.jumps <= 1) {
            this.status_subject.next(null)
            this.message_subject.next(null)
        }

        this.jumps = Math.max(this.jumps - 1, 0)
    }
}
