import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "tabla-resumen-evaluacion-instancia",
    templateUrl: "./tabla-resumen-evaluacion-instancia.component.html",
    styleUrls: ["./tabla-resumen-evaluacion-instancia.component.scss"]
})
export class TablaResumenEvaluacionInstanciaComponent implements OnInit {
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @Input() data
    @Input() multiples: boolean = false
    @Input() fromAlumnos: boolean = false

    constructor() {}

    ngOnInit() {}
}
