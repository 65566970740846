import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { UsuarioReferrer } from "./usuario_referrers.model"

@Injectable()
export class UsuarioReferrers extends PuntajeBaseService<UsuarioReferrer> {
    tableName = "usuario_referrers"
    singularTableName = "usuario_referrer"
    protected objectQuery: UsuarioReferrer
    modelClass = UsuarioReferrer

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
