import { Action } from "@ngrx/store"
import { ArchivoProcesados, ArchivoProcesado } from "../reducers/hoja_respuestas.reducer"

export enum HojaRespuestasActionTypes {
    LeerImagenes = "[HojaRespuestas] Leer Imágenes",
    SubirLecturas = "[HojaRespuestas] Subir Lecturas",
    SubirLectura = "[HojaRespuestas] Subir Lectura",
    LoadImages = "[HojaRespuestas] Load Images",
    ProcessImage = "[HojaRespuestas] Process Loaded Image",
    InitArchivosOriginales = "[HojaRespuesta] Init Archivos Originales",
    InitCanvases = "[HojaRespuesta] Init Canvases",
    scanImage = "[HojaRespuesta] Scan Image",
    ProcessNotImage = "[HojaRespuesta] Process Not Image",
    updateCanvas = "[HojaRespuesta] UpdateCanvas",
    processClickCanvas = "[HojaRespuesta] Process Click Canvas",
    setValues = "[HojaRespuesta] Set Values",
    SetValuesObjHojaRespuesta = "[HojaRespuesta] Set Values Obj",
    SetRespuestas = "[HojaRespuesta] Set Respuestas Hoja",
    SetObjRespuestas = "[HojaRespuesta] Set Obj Respuestas Hoja",
    processedAll = "[HojaRespuesta] Processed All",
    initSubirLecturas = "[HojaRespuestas] Init Subir Lecturas",
    LecturaProcesada = "[HojaRespuestas] Lectura Procesada",
    finishSubirLecturas = "[HojaRespuestas] Finish Subir Lecturas",
    disableIgnoreEvalInstancia = "[HojaRespuestas] Disable Ignore Eval Instancia",
    ResetHojaRespuestas = "[HojaRespuestas] Reset Hoja Respuesta",
    UpdateProcessed = "[HojaRespuestas] Update Processed",
    PreviousImage = "[HojaRespuestas] Previous Image",
    NextImage = "[HojaRespuestas] Next Image",
    ChangeSelectedFile = "[HojaRespuestas] Change Selected File",
    ChangeIdValuePart = "[HojaRespuestas] Change IdValuePart",
    ChangeForma = "[HojaRespuestas] Change Forma",
    ChangeFormaType = "[HojaRespuestas] Change FormaType",
    ChangeRespuesta = "[HojaRespuestas] Change Respuesta",
    ChangeFileEditing = "[HojaRespuestas] Change File Editing",
    ChangeFormaGlobal = "[HojaRespuestas] Change Forma Global",
    ChangeEnableThreshold = "[HojaRespuestas] Change Enable Threshold",
    ChangeInputFile = "[HojaRespuestas] Change inputFile"
}

export class PreviousImage implements Action {
    readonly type = HojaRespuestasActionTypes.PreviousImage
}

export class NextImage implements Action {
    readonly type = HojaRespuestasActionTypes.NextImage
}

export class ResetHojaRespuestas implements Action {
    readonly type = HojaRespuestasActionTypes.ResetHojaRespuestas
}

export class LeerImagenes implements Action {
    readonly type = HojaRespuestasActionTypes.LeerImagenes

    constructor(public payload: { files: any[]; canvases: any[] }) {}
}

export class SubirLecturas implements Action {
    readonly type = HojaRespuestasActionTypes.SubirLecturas

    constructor(public payload: { perfil: string }) {}
}

export class SubirLectura implements Action {
    readonly type = HojaRespuestasActionTypes.SubirLectura

    constructor(public payload: { index: number; perfil: string }) {}
}

export class FinishSubirLecturas implements Action {
    readonly type = HojaRespuestasActionTypes.finishSubirLecturas
}

export class LoadImages implements Action {
    readonly type = HojaRespuestasActionTypes.LoadImages
}

export class ScanImage implements Action {
    readonly type = HojaRespuestasActionTypes.scanImage

    constructor(public payload: { image: any; index: number }) {}
}

export class ProcessImage implements Action {
    readonly type = HojaRespuestasActionTypes.ProcessImage

    constructor(
        public payload: {
            index: number
            archivoProcesado: ArchivoProcesado
            error: boolean
        }
    ) {}
}

export class ProcessNotImage implements Action {
    readonly type = HojaRespuestasActionTypes.ProcessNotImage

    constructor(public payload: { file: any; index: number }) {}
}

export class InitArchivosOriginales implements Action {
    readonly type = HojaRespuestasActionTypes.InitArchivosOriginales

    constructor(public payload: { files: any[] }) {}
}

export class InitCanvases implements Action {
    readonly type = HojaRespuestasActionTypes.InitCanvases

    constructor(public payload: { canvases: any[] }) {}
}

export class UpdateCanvas implements Action {
    readonly type = HojaRespuestasActionTypes.updateCanvas

    constructor(public payload: { image: any; index: number }) {}
}

export class ProcessClickCanvas implements Action {
    readonly type = HojaRespuestasActionTypes.processClickCanvas

    constructor(public payload: { event: any; index: number }) {}
}

export class SetValuesHojaRespuesta implements Action {
    readonly type = HojaRespuestasActionTypes.setValues

    constructor(public payload: { index: number; respuestas: any; idValue: string; forma: any; formaType: any }) {}
}

export class SetValuesObjHojaRespuesta implements Action {
    readonly type = HojaRespuestasActionTypes.SetValuesObjHojaRespuesta

    constructor(public payload: { index: number; respuestasObj: any; rutObj: any; formaObj: any }) {}
}

export class SetRespuestas implements Action {
    readonly type = HojaRespuestasActionTypes.SetRespuestas

    constructor(public payload: { index: number; respuestas: any; }) {}
}

export class SetObjRespuestas implements Action {
    readonly type = HojaRespuestasActionTypes.SetObjRespuestas

    constructor(public payload: { index: number; respuestasObj: any; }) {}
}

export class ProcessedAll implements Action {
    readonly type = HojaRespuestasActionTypes.processedAll
}

export class InitSubirLecturas implements Action {
    readonly type = HojaRespuestasActionTypes.initSubirLecturas
}

export class LecturaProcesada implements Action {
    readonly type = HojaRespuestasActionTypes.LecturaProcesada

    constructor(public payload: { obj: any; index: number }) {}
}

export class DisableIgnoreEvalInstancia implements Action {
    readonly type = HojaRespuestasActionTypes.disableIgnoreEvalInstancia
}

export class UpdateProcessed implements Action {
    readonly type = HojaRespuestasActionTypes.UpdateProcessed
}

export class ChangeSelectedFile implements Action {
    readonly type = HojaRespuestasActionTypes.ChangeSelectedFile

    constructor(public payload: { selectedFile: number }) {}
}

export class ChangeIdValuePart implements Action {
    readonly type = HojaRespuestasActionTypes.ChangeIdValuePart

    constructor(public payload: { indexFile: number; indexPart: number; idValuePart: string[] }) {}
}

export class ChangeForma implements Action {
    readonly type = HojaRespuestasActionTypes.ChangeForma

    constructor(public payload: { index: number; forma: any }) {}
}

export class ChangeFormaType implements Action {
    readonly type = HojaRespuestasActionTypes.ChangeFormaType

    constructor(public payload: { index: number; formaType: any }) {}
}

export class ChangeRespuesta implements Action {
    readonly type = HojaRespuestasActionTypes.ChangeRespuesta

    constructor(public payload: { indexFile: number; indexRespuesta: number; respuesta: string }) {}
}

export class ChangeFileEditing implements Action {
    readonly type = HojaRespuestasActionTypes.ChangeFileEditing

    constructor(public payload: { index: number; editing?: boolean }) {}
}

export class ChangeFormaGlobal implements Action {
    readonly type = HojaRespuestasActionTypes.ChangeFormaGlobal

    constructor(public payload: { formaGlobal: any }) {}
}

export class ChangeEnableThreshold implements Action {
    readonly type = HojaRespuestasActionTypes.ChangeEnableThreshold

    constructor(public payload: { enableThreshold: boolean }) {}
}

export class ChangeInputFile implements Action {
    readonly type = HojaRespuestasActionTypes.ChangeInputFile

    constructor(public payload: { file: File }) {}
}

export type HojaRespuestasActions =
    | InitArchivosOriginales
    | InitCanvases
    | LeerImagenes
    | SubirLecturas
    | SubirLectura
    | FinishSubirLecturas
    | LoadImages
    | ProcessImage
    | ProcessNotImage
    | ScanImage
    | UpdateCanvas
    | ProcessClickCanvas
    | SetValuesHojaRespuesta
    | SetValuesObjHojaRespuesta
    | SetRespuestas
    | SetObjRespuestas
    | ProcessedAll
    | InitSubirLecturas
    | LecturaProcesada
    | DisableIgnoreEvalInstancia
    | ResetHojaRespuestas
    | UpdateProcessed
    | PreviousImage
    | NextImage
    | ChangeSelectedFile
    | ChangeIdValuePart
    | ChangeForma
    | ChangeFormaType
    | ChangeRespuesta
    | ChangeFileEditing
    | ChangeFormaGlobal
    | ChangeEnableThreshold
    | ChangeInputFile
