import { Pipe, PipeTransform } from "@angular/core"
import { percentage } from "./percentage"

@Pipe({ name: "roundfloat" })
export class RoundFloatPipe implements PipeTransform {
    transform(value: any, exp?: any, roundStrict?: boolean) {
        if (isNaN(value)) return value
        return roundStrict
            ? Math.round(+percentage(value, exp, Math.round, ""))
            : percentage(value, exp, Math.round, "")
    }
}
