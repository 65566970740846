import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule, registerLocaleData } from "@angular/common"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { ModalModule } from "ngx-bootstrap/modal"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { RouterModule } from "@angular/router"
import { CUIButtonBaseComponent } from "./cui-button/cui-button-base.component"
import { CUIButtonComponent } from "./cui-button/cui-button.component"
import { CUIButtonLinkComponent, CUIButtonLinkDirective } from "./cui-button/cui-button-link.component"
import { CUITabsComponent } from "./cui-tabs/cui-tabs.component"
import { CUITabComponent } from "./cui-tabs/cui-tab.component"
import { CUIPillsComponent } from "./cui-pills/cui-pills.component"
import { CUIPillComponent } from "./cui-pills/cui-pill.component"

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        Angular2FontawesomeModule,
        ModalModule,
        ProgressbarModule,
        PopoverModule,
        TooltipModule
    ],
    declarations: [
        CUIButtonComponent,
        CUIButtonLinkComponent,
        CUIButtonLinkDirective,
        CUITabsComponent,
        CUITabComponent,
        CUIPillsComponent,
        CUIPillComponent,
        CUIButtonBaseComponent
    ],
    exports: [
        CUIButtonComponent,
        CUIButtonLinkComponent,
        CUIButtonLinkDirective,
        CUITabsComponent,
        CUITabComponent,
        CUIPillsComponent,
        CUIPillComponent,
        CUIButtonBaseComponent
    ],
    providers: []
})
export class CUIInputsModule {
    static forRoot(): ModuleWithProviders<CUIInputsModule> {
        return {
            ngModule: CUIInputsModule,
            providers: []
        }
    }
}
