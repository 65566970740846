import { Component } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "mis-carreras.component.html",
    styleUrls: ["mis-carreras.component.scss"]
})
export class MisCarrerasComponent {
    subData: Subscription

    withPais = true
    withTerminosCarreras = true
    hidePuntajesPromedio = false

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.carreras.mis_carreras")
        this.subData = this.route.data.subscribe(data => {
            this.withPais = data.withPais ?? this.withPais
            this.withTerminosCarreras = data.withTerminosCarreras ?? this.withTerminosCarreras
            this.hidePuntajesPromedio = data.hidePuntajesPromedio ?? this.hidePuntajesPromedio
        })
    }
}
