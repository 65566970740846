import { ChangeDetectorRef, Component, Input } from "@angular/core"
import { InstrumentoMultiple, InstrumentoPredefinido } from "@puntaje/puntaje/api-services"
@Component({
    selector: "generar-ensayo-multiple-profesor",
    templateUrl: "./generar-ensayo-multiple-profesor.component.html",
    styleUrls: ["./generar-ensayo-multiple-profesor.component.scss"]
})
export class GenerarEnsayoMultipleProfesorComponent {
    instrumentoMultiple: InstrumentoMultiple
    @Input() redirect: string
    @Input() tipoEvaluacion: string
    enableOpcionesPregunta = true

    constructor(protected cdr: ChangeDetectorRef) {}

    callbackRecomendado(i: InstrumentoPredefinido) {
        this.enableOpcionesPregunta = false
        this.instrumentoMultiple = i.instrumento_multiple
    }

    onInstrumentoReady() {
        //this.loadingLayout.ready();
        // this.scrollToInstrumento();
    }

    onAsignaturaInstrumentoReady(instrumentoAsignatura) {}
}
