import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core"
import { DudaRespuestas, DudaRespuesta, Duda } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "duda-respuestas",
    templateUrl: "./duda_respuestas.component.html",
    styleUrls: ["./duda_respuestas.component.scss"]
})
export class DudaRespuestasComponent implements OnInit {
    @Input() _duda: Duda
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    dudaRespuestas: DudaRespuesta[]
    mejorDudaRespuesta: DudaRespuesta
    @Input() enableAcceptAnswer: boolean = false
    @Output() updateDuda = new EventEmitter<any>()

    @Input()
    set duda(duda: Duda) {
        this._duda = duda
        this.setData()
    }
    get duda() {
        return this._duda
    }

    constructor(protected dudaRespuestasService: DudaRespuestas) {}

    ngOnInit() {}

    setData() {
        this.loadingLayout.standby()

        if (this.duda.mejor_respuesta_id) {
            this.dudaRespuestasService
                .find(this.duda.mejor_respuesta_id, {
                    include: "[usuario:[rol_usuarios:[rol]]]",
                    methods: "[usuario_valores]"
                })
                .then((dudaRespuesta: DudaRespuesta) => {
                    if (dudaRespuesta) {
                        this.mejorDudaRespuesta = dudaRespuesta
                    }
                })
        }

        let params = {
            duda_respuesta: {
                duda_id: this.duda.id,
                not: {
                    id: this.duda.mejor_respuesta_id
                }
            },
            include: "[usuario:[rol_usuarios:[rol]]]",
            methods: "[usuario_valores]"
        }

        this.dudaRespuestasService.where(params).then((dudaRespuestas: DudaRespuesta[]) => {
            this.dudaRespuestas = dudaRespuestas
            this.loadingLayout.ready()
        })
    }

    aceptarRespuesta() {
        this.updateDuda.emit()
    }
}
