<div>
    <div class="{{ asignatura?.clase }}" *ngIf="planificacion">
        <div ribbon-flag *ngIf="isDone"></div>
        <div class="plan_estudio_wrapper">
            <div class="plan_estudio_row">
                <div class="plan_estudio_cell icon_cell">
                    <div class="icon_container">
                        <ogr-icon class="icono" [name]="asignatura?.icono"></ogr-icon>
                    </div>
                </div>
                <div class="plan_estudio_cell">
                    <a class="title cursor-pointer" [class.ribbon-padding]="isDone" (click)="navigateToPlanificacion()">
                        {{ planificacion.plan_personal }}
                    </a>
                    <div class="clearfix">
                        <h4 class="asignatura" *ngIf="showAsignatura">
                            {{ planificacion.generador_instrumento.asignatura.asignatura }}
                        </h4>
                        <div *ngIf="!aprendolibreCondition">
                            <span class="sesiones" [class.show_sesiones]="showSesiones">
                                {{ planificacion.total_sesiones }}&nbsp; {{ nombreSesiones }}
                            </span>
                        </div>
                    </div>
                    <div *ngIf="!disableNivelCalugas" class="clearfix">
                        <h4 class="asignatura">{{ nivel?.clasificacion }}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="push-bottom"></div>
    </div>
    <div class="clearfix bottom-btns">
        <button
            type="button"
            class="btn btn-default pull-right btn-continue btn-sm"
            (click)="navigateToPlanificacion()"
        >
            {{
                planPersonalInstancia
                    ? ("plan_clases.plan_clase_box.button_ver" | t)
                    : ("plan_clases.plan_clase_box.button_ver" | t)
            }}
        </button>
        <button
            *ngIf="enableEditPlan"
            type="button"
            class="btn btn-default pull-right btn-continue btn-sm btn-edit"
            (click)="navigateToEdit()"
        >
            {{ "plan_clases.plan_clase_box.button_editar" | t }}
        </button>
    </div>
    <div class="progressbar" *ngIf="!hideProgress">
        <progressbar [striped]="true" [value]="animatedValue ? progressBarValue : 0" [type]="'success'">
            <i>{{ progressBarValue | percentage }}</i>
        </progressbar>
    </div>
</div>
