import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { CarrerasAuthService } from "./carreras_auth.service"
import { CarrerasBaseService } from "./carreras_base.service"
import { Area } from "./areas.model"

@Injectable()
export class Areas extends CarrerasBaseService<Area> {
    tableName = "areas"
    singularTableName = "area"
    modelClass = Area

    constructor(
        protected http: HttpClient,
        protected auth: CarrerasAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
