import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Plataforma } from "./plataformas.model"
import { PonderableGenerador } from "./ponderable_generadores.model"

export class Ponderable extends BaseModel {
    public static className: string = "Ponderable"

    public ponderable: string
    public tipoInstrumentoId: number
    public asignaturaId: number
    public tooltip: string
    public campoOrigen: string
    public plataformaId: number
    public valorMinimo: number
    public valorMaximo: number
    public libre: boolean
    public orden: number
    public clase: string
    public color: string

    @SaveType(() => PonderableGenerador) ponderableGeneradores: PonderableGenerador[]
    @SaveType(() => Plataforma) plataforma: Plataforma
}
