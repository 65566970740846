<cui-section-headline>
    {{ "orientacion.carreras.buscador_carreras.titulo" | t }}
</cui-section-headline>
<p *ngIf="universidades && universidades.length == 1">{{ universidades[0].universidad }}</p>

<div class="options">
    <div class="buscador-options">
        <div class="tipo-busqueda">
            <label [attr.for]="'radio_1'">
                <span>{{ "orientacion.carreras.buscador_carreras.carrera" | t }}</span>
            </label>
            <input
                id="input-carrera"
                autocomplete="off"
                [(ngModel)]="carreraSedeInput"
                [typeahead]="filteredCarreraSedes"
                [typeaheadMinLength]="0"
                placeholder="{{ 'orientacion.carreras.buscador_carreras.buscador_placeholder' | t }}"
                class="form-control"
                [typeaheadScrollable]="true"
                [typeaheadOptionsInScrollableView]="10"
            />
        </div>

        <div class="search-btn">
            <cui-button (click)="updateSearch()">
                <fa name="search" class="icono left-icon"></fa>
                {{ "orientacion.carreras.buscador_carreras.buscar" | t }}
            </cui-button>
        </div>
    </div>

    <div expandableContent id="mas-opciones" #masOpciones="expandableContent">
        <div class="buscador-options">
            <div class="tipo-busqueda" *ngIf="universidades && universidades.length > 1">
                <label [attr.for]="'input-universidad'">
                    <span>{{ "orientacion.carreras.buscador_carreras.universidad" | t }}</span>
                </label>
                <input
                    id="input-universidad"
                    [(ngModel)]="universidad"
                    [typeahead]="universidades"
                    [typeaheadMinLength]="0"
                    placeholder="{{ 'orientacion.carreras.buscador_carreras.buscador_placeholder_universidad' | t }}"
                    class="form-control"
                    autocomplete="off"
                    [typeaheadScrollable]="true"
                    [typeaheadOptionsInScrollableView]="10"
                    [typeaheadOptionsLimit]="50"
                    (typeaheadOnSelect)="onSelectUniversidad($event)"
                    (input)="onInputUniversidadChanged($event)"
                />
            </div>
            <div class="tipo-busqueda" *ngIf="showSedes">
                <label [attr.for]="'input-sede'">
                    <span>{{ "orientacion.carreras.buscador_carreras.sede" | t }}</span>
                </label>
                <input
                    autocomplete="off"
                    id="input-sede"
                    [(ngModel)]="sede"
                    [typeahead]="sedes"
                    [typeaheadMinLength]="0"
                    placeholder="{{ 'orientacion.carreras.buscador_carreras.buscador_placeholder_sede' | t }}"
                    class="form-control"
                    [typeaheadScrollable]="true"
                    [typeaheadOptionsInScrollableView]="10"
                    (typeaheadOnSelect)="onSelectSede($event)"
                    (input)="onInputSedeChanged($event)"
                />
            </div>
            <ng-container *ngIf="enableAdditionalSearch">
                <ng-container *ngFor="let filter of aditionalFilters">
                    <div class="tipo-busqueda" *ngIf="filter.visible">
                        <label [attr.for]="filter.tipo">
                            <span>{{ filter.filter }}</span>
                        </label>
                        <select
                            id="filter.tipo"
                            class="form-control"
                            (change)="onChangeInfoAdicional(filter.filter, $any($event.target).value, filter.tipo)"
                        >
                            <option [ngValue]="undefined">-- Seleccione una {{ filter.filter }} --</option>
                            <option *ngFor="let obj of filter.value; let i = index" [value]="obj.valor">
                                {{ obj.valor }}
                            </option>
                        </select>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div *ngIf="enableAdditionalSearch" id="avancedFilters">
            <div class="opciones-adicionales">
                <label [attr.for]="'multiselect'">
                    <span>{{ "orientacion.carreras.buscador_carreras.opciones_adicionales" | t }}</span>
                </label>
                <ng-multiselect-dropdown
                    [settings]="dropdownAditionalSettings"
                    id="multiselect"
                    placeholder="{{ 'orientacion.carreras.buscador_carreras.opciones_adicionales_placeholder' | t }}"
                    [data]="aditionalFilters"
                    [(ngModel)]="selectedFilters"
                    (onSelect)="onItemSelectFilter($event)"
                    (onDeSelect)="onUnselectFilter($event)"
                ></ng-multiselect-dropdown>
            </div>
        </div>
    </div>
    <a href="javascript:void(0)" toggleExpandable="mas-opciones">
        <ng-container *ngIf="masOpciones.expanded">
            {{ "orientacion.carreras.buscador_carreras.menos_opciones_busqueda" | t }}
        </ng-container>

        <ng-container *ngIf="!masOpciones.expanded">
            {{ "orientacion.carreras.buscador_carreras.mas_opciones_busqueda" | t }}
        </ng-container>
    </a>
</div>

<carreras-table *ngIf="query" [withPais]="withPais"></carreras-table>
