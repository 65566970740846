<ng-container *ngIf="retroalimentacion || perfil == 'profesor'">
    <cui-section-headline>Retroalimentación Individual</cui-section-headline>
    <div class="contentDescripcion" [class.html]="isHtml">
        <div class="contentleft">
            <div>
                <ogr-icon [name]="icono" class="icon"></ogr-icon>
            </div>
            <div class="descripcion">
                <div *ngIf="!retroalimentacion && perfil == 'profesor'">
                    Aún no has incluido una retroalimentación individual para este estudiante.
                </div>
                <div
                    *ngIf="retroalimentacion && retroalimentacion.tipo == 'html'"
                    [innerHTML]="retroalimentacion.retroalimentacion"
                ></div>
                <div *ngIf="retroalimentacion && retroalimentacion.tipo == 'pdf'">
                    <a href="javascript:void(0)" (click)="openMaterialPreview(material)">
                        Ver archivo de retroalimentación
                    </a>
                </div>
            </div>
        </div>
        <div *ngIf="perfil == 'profesor'" class="contentButoon">
            <cui-button class="btn-defaul btn_style" [class.pull-right]="isHtml" (click)="openModalRetroalimentacion()">
                {{ boton }}

                <fa [name]="'pencil'"></fa>
            </cui-button>
        </div>
    </div>
</ng-container>

<material-preview-modal
    #materialPreviewModal
    *ngIf="materialOpen"
    (onHide)="closeMaterialPreview()"
    [reloadMaterial]="false"
    [disableSaveTiempoMaterialUsuario]="true"
></material-preview-modal>

<ng-container *ngIf="openModal">
    <generic-modal [buttonClicked]="openModal" (onHide)="closeModalRetroalimentacion()">
        <modal-titulo>
            <div class="modalTitulo">Retroalimentación individual</div>
        </modal-titulo>
        <modal-contenido>
            <p>Escoge un medio para enviar tu retroalimentación.</p>
            <retroalimentacion-compartir
                *ngIf="showModal"
                [retroalimentable]="retroalimentable"
                [receptor]="receptor"
                [retroalimentacion]="retroalimentacion"
                [showTitulo]="false"
                [titleConfirm]="'Vista previa'"
                [descripcionConfirm]="descripcionConfirm"
                (retroalimentacionChanged)="retroalimentacionChanged($event)"
            ></retroalimentacion-compartir>
        </modal-contenido>
    </generic-modal>
</ng-container>
