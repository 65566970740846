import { Component, ElementRef, ViewEncapsulation, HostListener, Output, EventEmitter, Input } from "@angular/core"

@Component({
    selector: "[cui-pill],cui-pill",
    template: `
        <a [class.active]="isActive" [class.inactive]="!isActive" (click)="onClickButton($event)">
            <ng-content></ng-content>
        </a>
    `,
    styleUrls: ["cui-pill.component.scss"]
})
export class CUIPillComponent {
    @Input() isActive: boolean = false
    @Output("click") click: EventEmitter<any> = new EventEmitter<any>()

    @HostListener("click", ["$event"]) onClick(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    constructor(protected el: ElementRef) {}

    onClickButton(event) {
        this.click.emit(event)
    }
}
