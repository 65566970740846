<div class="form-group" *ngIf="hasPassword">
    <label for="current_password">{{ "usuario_edit_password.contrasena_actual" | t }}</label>
    <input type="password" [(ngModel)]="current_password" id="current_password" class="form-control" />
    <div class="mensaje-error-input" *ngIf="currentPaswordMsg">
        <p>{{ currentPaswordMsg }}</p>
    </div>
</div>
<div class="form-group">
    <label for="password">{{ "usuario_edit_password.nueva_contrasena" | t }}</label>
    <input
        type="password"
        #passwordControl="ngModel"
        [(ngModel)]="password"
        (ngModelChange)="isBreachedPassword(passwordControl)"
        id="password"
        class="form-control"
    />
    <div class="mensaje-error-input" *ngIf="(password && password.length < passwordMinLength) || invalidPassword">
        <p>{{ "usuario_edit_password.minimo_caracteres" | t: { passwordMinLength: passwordMinLength } }}</p>
    </div>
    <div class="mensaje-error-input" *ngIf="breachedPassword">
        <p>{{ "usuario_edit_password.vulnerable" | t }}</p>
    </div>
    <password-strength-bar *ngIf="password" [passwordToCheck]="password"></password-strength-bar>
</div>
<div class="form-group">
    <label for="password_confirmation">{{ "usuario_edit_password.confirmar_contrasena" | t }}</label>
    <input type="password" [(ngModel)]="password_confirmation" id="password_confirmation" class="form-control" />
    <div
        class="mensaje-error-input"
        *ngIf="(password_confirmation && password_confirmation.length < passwordMinLength) || invalidPassword"
    >
        <p>{{ "usuario_edit_password.minimo_caracteres" | t: { passwordMinLength: passwordMinLength } }}</p>
    </div>
    <div class="mensaje-error-input" *ngIf="passwordConfirmationMsg">
        <p>{{ passwordConfirmationMsg }}</p>
    </div>
</div>
<cui-button class="btn_style" type="button" (click)="changePassword()">
    {{ "usuario_edit_password.cambiar_contrasena" | t }}
</cui-button>
