import { Component, Input, ViewChild, ViewChildren, QueryList, OnInit, AfterViewInit } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    CarreraSedes,
    CarreraSede,
    PreferenciaCarreraSede,
    PreferenciaCarreraSedes
} from "@puntaje/carreras/api-services"
import { AuthService, CapitalizePipe } from "@puntaje/shared/core"
import { Usuario } from "@puntaje/puntaje/api-services"
import { Subscription, combineLatest } from "rxjs"
import { CarrerasPonderaciones } from "../carreras-ponderaciones.service"
import { CarrerasListItemComponent } from "../carreras-list-item/carreras-list-item.component"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "mis-carreras",
    templateUrl: "mis-carreras.component.html",
    styleUrls: ["mis-carreras.component.scss"]
})
export class MisCarrerasComponent implements OnInit, AfterViewInit {
    @Input() hidePuntajesPromedio: boolean = false

    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent
    carreraSedes: CarreraSede[]
    disablePonderaciones: boolean = !config.carreras?.ponderables || config.carreras.ponderables.length == 0

    changingOrder: boolean = false
    capitalize = new CapitalizePipe()

    constructor(
        protected carreraSedesService: CarreraSedes,
        protected authService: AuthService,
        protected preferenciaCarreraSedesService: PreferenciaCarreraSedes,
        protected carrerasPonderaciones: CarrerasPonderaciones
    ) {}

    ngOnInit() {
        this.loadPreferenciaCarreras()
    }

    public loadPreferenciaCarreras() {
        this.loadingLayout.standby()
        const params = {
            include: "[carrera?,preferencia_carrera_sedes,sede:universidad,ponderaciones?:ponderable?]",
            preferenciaCarreraSede: {
                usuarioId: this.authService.getUserData().id
            },
            pais: {
                pais: this.capitalize.transform(config.plataforma.pais)
            },
            withLikesCarrera: 1,
            withLikesUniversidad: 1,
            sort_by: "PreferenciaCarreraSede.orden",
            order: "asc"
        }

        this.carreraSedesService.wherePost(params).then(carreraSedes => {
            this.carreraSedes = carreraSedes
            this.loadingLayout.ready()
        })
    }

    ngAfterViewInit() {}

    onChangePosition({
        preferenciaCarreraSede,
        value
    }: {
        preferenciaCarreraSede: PreferenciaCarreraSede
        value: number
    }) {
        const preferenciaCarreraSedes = this.carreraSedes.map(cs => cs.preferenciaCarreraSedes).flat()
        const newOrden = preferenciaCarreraSede.orden + value
        const preferenciaCarreraSedeRelated = preferenciaCarreraSedes.find(pcs => pcs.orden == newOrden)
        if (!preferenciaCarreraSedeRelated) return

        this.changingOrder = true
        const updatedPreferenciaCarreraSede = new PreferenciaCarreraSede()
        Object.assign(updatedPreferenciaCarreraSede, preferenciaCarreraSede)
        updatedPreferenciaCarreraSede.orden = newOrden
        this.preferenciaCarreraSedesService
            .update(updatedPreferenciaCarreraSede.id, updatedPreferenciaCarreraSede)
            .then(pcs => {
                preferenciaCarreraSedeRelated.orden = preferenciaCarreraSede.orden
                preferenciaCarreraSede.orden = pcs.orden

                this.carreraSedes = this.carreraSedes.sort((cs1, cs2) => {
                    return cs1.preferenciaCarreraSedes[0].orden - cs2.preferenciaCarreraSedes[0].orden
                })

                this.changingOrder = false
            })
    }
}
