import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import {
    UtilModule,
    FormModule,
    PaginatorModule,
    FlashMessageModule,
    TranslationsModule,
    ModelViewModule
} from "@puntaje/shared/core"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"

import { AudiovisualPreviewComponent } from "./audiovisual-preview/audiovisual-preview.component"

@NgModule({
    declarations: [AudiovisualPreviewComponent],
    imports: [
        CommonModule,
        CommonsLayoutsModule,
        UtilModule,
        FormModule,
        PaginatorModule,
        FlashMessageModule,
        TranslationsModule,
        ModelViewModule
    ],
    exports: [AudiovisualPreviewComponent]
})
export class AudiovisualesModule {}
