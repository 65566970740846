import { SaveType, BaseModel } from "@puntaje/shared/core"
import { PlanEstudioAplicado } from "./plan_estudio_aplicados.model"
import { PlanEstudio } from "@puntaje/nebulosa/api-services"
import { Evaluacion } from "./evaluaciones.model"

export class PlanEstudioEvaluacion extends BaseModel {
    public plan_estudio_aplicado_id: number
    public plan_estudio_id: number
    public plan_estudio_sesion_id: number
    public plan_estudio_lote_id: number
    public evaluacion_id: number
    public orden: number

    public plan_estudio: PlanEstudio

    @SaveType(() => PlanEstudioAplicado) public plan_estudio_aplicado: PlanEstudioAplicado
    @SaveType(() => Evaluacion) public evaluacion: Evaluacion

    constructor() {
        super()
        this.plan_estudio_aplicado = new PlanEstudioAplicado()
        this.evaluacion = new Evaluacion()
    }

    public toString() {
        return this.plan_estudio.plan_estudio
    }
}
