<cui-button
    id="btnReport"
    type="button"
    *ngIf="enableButton"
    (click)="pressButton()"
    class="btn_style"
    data-toggle="modal"
>
    <span class="left-icon glyphicon glyphicon-warning-sign"></span>
    {{ "otros.reportar" | t }}
</cui-button>
<a id="linkReport" *ngIf="enableLink" (click)="pressButton()" class="report-link" data-toggle="modal">
    <fa class="reportIcono" name="exclamation-triangle"></fa>
    {{ "otros.reportar" | t }}
</a>

<generic-modal (closeCrossCallback)="clear()" [id]="tabla + '_' + entidad_id" [buttonClicked]="buttonPressed">
    <modal-titulo>{{ "reportes_modal.titulo" | t: { tabla: tabla } }}</modal-titulo>
    <modal-contenido>
        <div *ngIf="filling">
            <form novalidate [formGroup]="form">
                <div class="form-group">
                    <label for="reporte_razon_id">
                        {{ "reportes_modal.categoria" | t }}
                        <span class="required-mark"></span>
                    </label>
                    <select
                        id="reporte_razon_id"
                        formControlName="reporte_razon_id"
                        (change)="loadSub($event)"
                        class="form-control"
                    >
                        <option value="">{{ "reportes_modal.seleccione_categoria" | t }}</option>
                        <option *ngFor="let razon of razones" [value]="razon.id">{{ razon.toString() }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="reporte_sub_razon_id">
                        {{ "reportes_modal.razon" | t }}
                        <span class="required-mark"></span>
                    </label>
                    <select id="reporte_sub_razon_id" formControlName="reporte_sub_razon_id" class="form-control">
                        <option value="">{{ "reportes_modal.seleccione_razon" | t }}</option>
                        <option
                            *ngFor="let sub_razon of sub_razones"
                            [attr.title]="sub_razon.explicacion"
                            [value]="sub_razon.id"
                        >
                            {{ sub_razon.toString() }}
                        </option>
                    </select>
                </div>
                <div
                    class="form-group"
                    [ngClass]="{
                        'has-error': this.form.controls['reporte'].touched && this.form.controls['reporte'].errors
                    }"
                >
                    <label for="reporte">
                        {{ "reportes_modal.detalle" | t }}
                        <span class="required-mark"></span>
                    </label>
                    <textarea
                        rows="5"
                        cols="30"
                        maxlength="500"
                        formControlName="reporte"
                        class="form-control"
                        placeholder="{{ 'reportes_modal.detalle_placeholder' | t }}"
                    ></textarea>
                    <div
                        *ngIf="this.form.controls['reporte'].touched && this.form.controls['reporte'].errors"
                        class="alert alert-danger"
                    >
                        {{ "reportes_modal.detalle_descripcion" | t }}
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="!filling">
            <p>{{ "reportes_modal.mensaje_reporte" | t }}</p>
        </div>
    </modal-contenido>
    <modal-botones>
        <div *ngIf="filling">
            <cui-button buttonType="cancel" (click)="clear()" type="button" class="btn_style_2" data-dismiss="modal">
                {{ "reportes_modal.cancelar" | t }}
            </cui-button>
            <cui-button (click)="save()" type="button" class="btn_style">
                {{ "reportes_modal.reportar" | t }}
            </cui-button>
        </div>
        <div *ngIf="!filling">
            <cui-button (click)="clear()" type="button" class="btn_style_2" data-dismiss="modal">
                {{ "reportes_modal.cerrar" | t }}
            </cui-button>
        </div>
    </modal-botones>
</generic-modal>
