// angular
import { NgModule, ModuleWithProviders } from "@angular/core"
import { HttpClientModule } from "@angular/common/http"
// ogr-core-lib
import { BaseModule, provideConfig } from "@puntaje/shared/core"

import { NebuModule } from "@puntaje/nebulosa/api-services"

// services
import { Amigos } from "./amigos.service"
import { Asociaciones } from "./asociaciones.service"
import { Estadisticas } from "./estadisticas.service"
import { InstrumentoPreguntas } from "./instrumento_preguntas.service"
import { AsociacionEstablecimientos } from "./asociacion_establecimientos.service"
import { AsociacionUsuarios } from "./asociacion_usuarios.service"
import { Banners } from "./banners.service"
import { BannerZonas } from "./banner_zonas.service"
import { BannerPaginas } from "./banner_paginas.service"
import { BannerInstancias } from "./banner_instancias.service"
import { BannerClicks } from "./banner_clicks.service"
import { BannerImpresiones } from "./banner_impresiones.service"
import { BannerTipos } from "./banner_tipos.service"
import { Comentarios } from "./comentarios.service"
import { Configuraciones } from "./configuraciones.service"
import { IframeBanners } from "./iframe_banners.service"
import { ImageBanners } from "./image_banners.service"
import { InstrumentoMensuales } from "./instrumento_mensuales.service"
import { InstrumentoPredefinidos } from "./instrumento_predefinidos.service"
import { Carreras } from "./carreras.service"
import { Dudas } from "./dudas.service"
import { DudaCategorias } from "./duda_categorias.service"
import { DudaRespuestas } from "./duda_respuestas.service"
import { EscalaEvaluaciones } from "./escala_evaluaciones.service"
import { Establecimientos } from "./establecimientos.service"
import { EstablecimientoContactos } from "./establecimiento_contactos.service"
import { EstablecimientoReportes } from "./establecimiento_reportes.service"
import { EstablecimientoUsuarios } from "./establecimiento_usuarios.service"
import { EvaluacionInstancias } from "./evaluacion_instancias.service"
import { EvaluacionTiempos } from "./evaluacion_tiempos.service"
import { Evaluaciones } from "./evaluaciones.service"
import { EvaluacionUsuarios } from "./evaluacion_usuarios.service"
import { GrupoRecursos } from "./grupo_recursos.service"
import { GrupoUsuarios } from "./grupo_usuarios.service"
import { GrupoUsuarioUsuarios } from "./grupo_usuario_usuarios.service"
import { Instrumentos } from "./instrumentos.service"
import { InstrumentoMultiples } from "./instrumento_multiples.service"
import { LogEvaluaciones } from "./log_evaluaciones.service"
import { LugarTipos } from "./lugar_tipos.service"
import { Lugares } from "./lugares.service"
import { MaterialFavoritos } from "./material_favoritos.service"
import { MaterialRelaciones } from "./material_relaciones.service"
import { Nominas } from "./nominas.service"
import { Noticias } from "./noticias.service"
import { NoticiaInstancias } from "./noticia_instancias.service"
import { NoticiasPerfiles } from "./noticias_perfil.service"
import { Notificaciones } from "./notificaciones.service"
import { Paises } from "./paises.service"
import { PlanEstudioAplicados } from "./plan_estudio_aplicados.service"
import { PlanEstudioEvaluaciones } from "./plan_estudio_evaluaciones.service"
import { PlanEstudioAplicadoInstancias } from "./plan_estudio_aplicado_instancias.service"
import { PlanEstudioSesionAplicadaInstancias } from "./plan_estudio_sesion_aplicada_instancias.service"
import { PlanEstudioContenidoInstancias } from "./plan_estudio_contenido_instancias.service"
import { PreferenciaCarreras } from "./preferencia_carreras.service"
import { PremiumUsuarios } from "./premium_usuarios.service"
import { Roles } from "./roles.service"
import { RolUsuarios } from "./rol_usuarios.service"
import { Sedes } from "./sedes.service"
import { Universidades } from "./universidades.service"
import { Usuarios } from "./usuarios.service"
import { UsuarioReferrers } from "./usuario_referrers.service"
import { Streamings } from "./streamings.service"
import { Tutores } from "./tutores.service"
import { TipoContactos } from "./tipo_contactos.service"
import { Excels } from "./excels.service"
import { PlanPersonales } from "./plan_personales.service"
import { PlanPersonalInstancias } from "./plan_personal_instancias.service"
import { PlanPersonalCiclos } from "./plan_personal_ciclos.service"
import { PlanPersonalSesiones } from "./plan_personal_sesiones.service"
import { PlanPersonalContenidos } from "./plan_personal_contenidos.service"
import { PlanPersonalMaterialInstancias } from "./plan_personal_material_instancias.service"
import { PlanPersonalEvaluacionInstancias } from "./plan_personal_evaluacion_instancias.service"
import { PlanPersonalTipos } from "./plan_personal_tipos.service"
import { Recordatorios } from "./recordatorios.service"
import { TiempoMaterialUsuarios } from "./tiempo_material_usuarios.service"
import { DescargaMaterialUsuarios } from "./descarga_material_usuarios.service"
import { EscalaGlobales } from "./escala_globales.service"
import { PushNotificaciones } from "./push-notificaciones.service"
import { PushNotificacionTipos } from "./push-notificacion-tipos.service"

// guards
import { EntrenadorMetas } from "./entrenador_metas.service"
import { EntrenadorUsuarios } from "./entrenador_usuarios.service"
import { Respuestas } from "./respuestas.service"
import { UsuarioBaneados } from "./usuario_baneados.service"
import { EstablecimientoMonitores } from "./establecimiento_monitores.service"
import { AsociacionMonitores } from "./asociacion_monitores.service"
import { EvaluacionMultiples } from "./evaluacion_multiples.service"
import { AsociacionContactos } from "./asociacion_contactos.service"
import { PlanPersonalUsuarios } from "./plan_personal_usuarios.service"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { PlanPersonalPredefinidos } from "./plan_personal_predefinidos.service"
import { EvaluacionMultipleInstancias } from "./evaluacion_multiple_instancias.service"
import { EvaluacionFormas } from "./evaluacion_formas.service"
import { EvaluacionFormaInstrumentoPreguntas } from "./evaluacion_forma_instrumento_preguntas.service"
import { EvaluacionFormaUsuarios } from "./evaluacion_forma_usuarios.service"
import { Actividades } from "./actividades.service"
import { AuthServiceConfig } from "angularx-social-login"
import { PlanPersonalContenidoGrupoUsuarios } from "./plan_personal_contenido_grupo_usuarios.service"
import { PlanPersonalSesionGrupoUsuarios } from "./plan_personal_sesion_grupo_usuarios.service"
import { EvaluacionSugeridas } from "./evaluacion_sugeridas.service"
import { PlanClaseService } from "./plan-clase.service"
import { Libros } from "./libros.service"
import { LibroCodigoRegistros } from "./libro-codigo-registros.service"
import { CodigoLibros } from "./codigo_libros.service"
import { LibroEvaluaciones } from "./libro_evaluaciones.service"
import { UsuariosPaa } from "./usuario_paa.service"
import { Retroalimentaciones } from "./retroalimentaciones.service"
import { MaterialUsuarios } from "./material_usuarios.service"
import { GrupoRecursoTipos } from "./grupo_recurso_tipos.service"
import { NoticiaTipos } from "./noticia_tipos.service"
import { EvaluacionMultipleEvaluaciones } from "./evaluacion_multiple_evaluaciones.service"
import { AsignaturaEvaluacionTipos } from "./asignatura_evaluacion_tipos.service"
import { AsignaturaEvaluacionTipoEstablecimientos } from "./asignatura_evaluacion_tipo_establecimientos.services"
import { EscalaGlobalAliases } from "./escala_global_aliases.services"
import { Smowls } from "./smowl.service"
import { Posts } from "./posts.service"
import { PostsPerfiles } from "./posts_perfil.service"
import { EvaluacionMultipleUsuarios } from "./evaluacion_multiple_usuarios.service"
import { EscalaGlobalEvaluaciones } from "./escala_global_evaluaciones.service"
import { EvaluacionTipos } from "./evaluacion_tipos.service"
import { ScriptsService } from "./script.service"
import { Horarios } from "./horarios.service"
import { MonitoreoInstrumentoPredefinidos } from "./monitoreo_instrumento_predefinidos.service"
import { MonitoreoInstrumentos } from "./monitoreo_instrumentos.service"
import { TypeformWidgetes } from "./typeform_widgetes.service"
import { TypeformWidgetTipos } from "./typeform_widget_tipos.service"
import { TypeformWidgetTipoUsuarios } from "./typeform_widget_tipo_usuarios.service"
import { CategoriaAyudas } from ".."
import { AyudaCategoriaAyudas } from "./ayuda_categoria_ayudas.service"
import { FileSaverModule } from "ngx-filesaver"
import { GrupoRecursoTipoCategorias } from "./grupo_recurso_tipo_categorias.service"
import { GeneradorInstrumentoDiagnosticoEvaluaciones } from "./generador_instrumento_diagnostico_evaluaciones.service"

@NgModule({
    imports: [HttpClientModule, BaseModule, NebuModule, FileSaverModule],
    declarations: [],
    exports: []
})
export class ApiModule {
    static forRoot(): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [
                Actividades,
                Amigos,
                AsignaturaEvaluacionTipos,
                AsociacionContactos,
                Asociaciones,
                AsociacionEstablecimientos,
                AsociacionMonitores,
                AsociacionUsuarios,
                AyudaCategoriaAyudas,
                BannerClicks,
                BannerImpresiones,
                BannerInstancias,
                BannerPaginas,
                Banners,
                BannerTipos,
                BannerZonas,
                Carreras,
                CategoriaAyudas,
                CodigoLibros,
                Comentarios,
                Configuraciones,
                DudaCategorias,
                DudaRespuestas,
                Dudas,
                EntrenadorMetas,
                EntrenadorUsuarios,
                EscalaEvaluaciones,
                EstablecimientoContactos,
                EstablecimientoMonitores,
                EstablecimientoReportes,
                Establecimientos,
                EstablecimientoUsuarios,
                Estadisticas,
                Evaluaciones,
                EvaluacionFormaInstrumentoPreguntas,
                EvaluacionFormas,
                EvaluacionFormaUsuarios,
                EvaluacionInstancias,
                EvaluacionMultipleEvaluaciones,
                EvaluacionMultipleInstancias,
                EvaluacionMultiples,
                EvaluacionMultipleEvaluaciones,
                EvaluacionSugeridas,
                EvaluacionTiempos,
                EvaluacionTipos,
                EvaluacionUsuarios,
                EvaluacionMultipleUsuarios,
                Excels,
                GrupoRecursos,
                GrupoRecursoTipos,
                GrupoRecursoTipoCategorias,
                GrupoUsuarios,
                GrupoUsuarioUsuarios,
                Horarios,
                IframeBanners,
                ImageBanners,
                InstrumentoMensuales,
                InstrumentoMultiples,
                InstrumentoPredefinidos,
                InstrumentoPreguntas,
                Instrumentos,
                LibroCodigoRegistros,
                LibroEvaluaciones,
                Libros,
                LogEvaluaciones,
                Lugares,
                LugarTipos,
                MaterialFavoritos,
                MaterialRelaciones,
                MaterialUsuarios,
                Nominas,
                NoticiaInstancias,
                Noticias,
                NoticiasPerfiles,
                NoticiaTipos,
                Notificaciones,
                Paises,
                PlanClaseService,
                PlanEstudioAplicadoInstancias,
                PlanEstudioAplicados,
                PlanEstudioContenidoInstancias,
                PlanEstudioEvaluaciones,
                PlanEstudioSesionAplicadaInstancias,
                PlanPersonalCiclos,
                PlanPersonalContenidoGrupoUsuarios,
                PlanPersonalContenidos,
                PlanPersonales,
                PlanPersonalEvaluacionInstancias,
                PlanPersonalInstancias,
                PlanPersonalMaterialInstancias,
                PlanPersonalPredefinidos,
                PlanPersonalSesiones,
                PlanPersonalSesionGrupoUsuarios,
                PlanPersonalTipos,
                PlanPersonalUsuarios,
                Posts,
                PostsPerfiles,
                PreferenciaCarreras,
                PremiumUsuarios,
                PuntajeAuthService,
                PuntajeBaseService,
                PushNotificaciones,
                PushNotificacionTipos,
                Recordatorios,
                Respuestas,
                Retroalimentaciones,
                Roles,
                RolUsuarios,
                ScriptsService,
                Sedes,
                Streamings,
                Smowls,
                TipoContactos,
                Tutores,
                TypeformWidgetes,
                TypeformWidgetTipos,
                TypeformWidgetTipoUsuarios,
                Universidades,
                UsuarioBaneados,
                UsuarioReferrers,
                Usuarios,
                TiempoMaterialUsuarios,
                DescargaMaterialUsuarios,
                UsuariosPaa,
                AsignaturaEvaluacionTipos,
                EscalaGlobales,
                EscalaGlobalAliases,
                EscalaGlobalEvaluaciones,
                AsignaturaEvaluacionTipoEstablecimientos,
                {
                    provide: AuthServiceConfig,
                    useFactory: provideConfig
                },
                MonitoreoInstrumentoPredefinidos,
                MonitoreoInstrumentos,
                GeneradorInstrumentoDiagnosticoEvaluaciones
            ]
        }
    }
}
