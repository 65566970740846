<div class="showcasebox">
    <div class="clearfix grid-layout">
        <div class="left_column">
            <div class="user-circle-container">
                <img
                    *ngIf="usuario_avatar; else other_avatar"
                    alt="avatar"
                    class="avatar"
                    [src]="usuario_avatar | trustedurl"
                />
                <ng-template #other_avatar><fa class="fa-placeholder" [name]="'user'"></fa></ng-template>
            </div>
        </div>
        <div class="right_column">
            <h4 (click)="goToProfile()">{{ usuario_nombre }} {{ usuario_apellido_paterno }}</h4>
            <ng-container *ngIf="showUserDetails">
                <p class="email" *ngIf="usuario_email; else no_email">
                    <fa name="envelope-o"></fa>
                    {{ usuario_email }}
                </p>
                <ng-template #no_email>
                    <p class="no-email">
                        <fa name="warning"></fa>
                        Aún no has agregado tu correo.
                    </p>
                </ng-template>
                <p class="userid" *ngIf="showUserID && usuario_id">
                    <b>ID:&nbsp;</b>
                    {{ usuario_id }}
                </p>
            </ng-container>
            <ng-container *ngIf="!showEstablecimiento">
                <ng-container *ngTemplateOutlet="user_details"></ng-container>
            </ng-container>
        </div>

        <div *ngIf="showEstablecimiento" class="bottom_row">
            <div class="establecimientos">
                <span class="badge establecimiento-badge" *ngFor="let e of establecimientos">
                    {{ e.establecimiento }}
                </span>
            </div>
            <ng-container *ngTemplateOutlet="user_details"></ng-container>
        </div>
    </div>
</div>

<ng-template #user_details *ngIf="showUserDetails">
    <div>
        <a (click)="goToProfile()" class="profile-link clickeable">
            <fa name="user-o" class="icono"></fa>
            Ver mi perfil
        </a>
    </div>
    <div>
        <a routerLink="/usuarios/editar" class="edit-link">
            <fa name="edit" class="icono"></fa>
            Editar mis datos
        </a>
    </div>
</ng-template>
