<cui-shared-layout>
    <cui-layout-footer>
        <footer>
            <img
                class="footer-logo"
                src="{{ logoFooter }}"
                alt="{{ config.plataforma.info.companyName }}"
                title="{{ config.plataforma.info.companyName }}"
            />
        </footer>
    </cui-layout-footer>
</cui-shared-layout>
