<p *ngIf="enableLoadingMessage" class="loadingMessage">
    {{ "plan_personales.diagnostico.generando" | t }}
</p>
<loading-layout #loadingLayout>
    <evaluacion-tiempo
        *ngIf="evaluacion && loadClock"
        [evaluacion]="evaluacion"
        [invisibleClock]="true"
        (onReady)="onTiempoReady($event)"
    ></evaluacion-tiempo>

    <progreso-preguntas-evaluacion-secuencial></progreso-preguntas-evaluacion-secuencial>

    <evaluacion-secuencial
        *ngIf="enablePreguntas"
        [enableReporte]="enableReporte"
        [evaluacionId]="evaluacionId"
        [evaluacionTiempo]="evaluacionTiempo"
        [enableBtnMarkAsUncertain]="true"
        (onReadyEvaluacion)="readyEvaluacion($event)"
        (onLoadClockChange)="onLoadClockChange($event)"
        (evaluacionEntregada)="onEvaluationSent($event)"
    ></evaluacion-secuencial>
</loading-layout>
