<div class="mi-ponderacion">
    <p class="original">
        original
        <b>{{ ponderacion | number: "1.0-0" }} pts</b>
    </p>

    <h3 [ngClass]="{ simulacion: simulacion != ponderacion }">{{ simulacion | number: "1.0-0" }} pts</h3>
    <p>Mi ponderación simulada</p>
    <fa class="help-icon" [popover]="msj" [outsideClick]="true" placement="left" name="question-circle"></fa>
</div>
