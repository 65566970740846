import { NgModule, ModuleWithProviders } from "@angular/core"
import { UtilModule, PaginatorModule } from "@puntaje/shared/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import {
    CUIDataListComponent,
    CUIDataListHeader,
    CUIDataListItemComponent,
    CUIDataListItemActions,
    CUIDataListItemIcon,
    CUIDataListItemInfo,
    CUIDataListItemTitle
} from "./cui-data-list/cui-data-list.component"
import {
    CUITableComponent,
    CUITableDataRowComponent,
    CUITableHeaderRowComponent,
    CUITableNoDataComponent
} from "./cui-table/cui-table.component"

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        Angular2FontawesomeModule,
        UtilModule,
        PaginatorModule,
        CommonsLayoutsModule
    ],
    declarations: [
        CUIDataListComponent,
        CUIDataListHeader,
        CUIDataListItemComponent,
        CUIDataListItemActions,
        CUIDataListItemIcon,
        CUIDataListItemInfo,
        CUIDataListItemTitle,
        CUITableComponent,
        CUITableDataRowComponent,
        CUITableHeaderRowComponent,
        CUITableNoDataComponent
    ],
    exports: [
        CUIDataListComponent,
        CUIDataListHeader,
        CUIDataListItemComponent,
        CUIDataListItemActions,
        CUIDataListItemIcon,
        CUIDataListItemInfo,
        CUIDataListItemTitle,
        CUITableComponent,
        CUITableDataRowComponent,
        CUITableHeaderRowComponent,
        CUITableNoDataComponent
    ],
    providers: []
})
export class CUITablesModule {
    static forRoot(): ModuleWithProviders<CUITablesModule> {
        return {
            ngModule: CUITablesModule,
            providers: []
        }
    }
}
