import { Component, ChangeDetectorRef } from "@angular/core"
import { ViewLayout } from "./view_layout.component"

@Component({
    selector: "model-simple-view-layout",
    template: `
        <div class="row bottom_lined">
            <div class="col-sm-3">
                <label><ng-template #viewLabel></ng-template></label>
            </div>
            <div class="col-sm-9">
                <span class="content-wrap"><ng-template #viewValue></ng-template></span>
            </div>
        </div>
    `,
    styleUrls: ["model_simple_view.component.scss"]
})
export class ModelSimpleViewLayout extends ViewLayout {
    constructor(private chref: ChangeDetectorRef) {
        super(chref)
    }
}
