import {
    Directive,
    ElementRef,
    Renderer2,
    HostListener,
    OnInit,
    Input,
    Component,
    ViewChild,
    HostBinding,
    ChangeDetectorRef
} from "@angular/core"

@Directive({
    selector: "[sticky-top-item]"
})
export class StickyTopDirective implements OnInit {
    @HostBinding("class.isSticky") fixedState: boolean = false
    @Input("topPadding") topPadding: number = 10
    @Input("zIndex") zIndex: number = 2
    @Input() enableStickyWhiteBackground: boolean = true

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        setTimeout(() => {
            this.checkIfFixed()
        }, 1)
    }

    private setPositionStyle() {
        this.renderer.setStyle(this.element.nativeElement, "position", "fixed")
        this.renderer.setStyle(this.element.nativeElement, "top", "0px")
    }

    private setStyle() {
        this.setPositionStyle()
        this.renderer.setStyle(this.element.nativeElement, "z-index", this.zIndex)
        if (this.enableStickyWhiteBackground)
            this.renderer.setStyle(this.element.nativeElement, "background", "#ffffff")
        this.renderer.setStyle(this.element.nativeElement, "padding-top", this.topPadding + "px")
        this.renderer.setStyle(this.element.nativeElement, "padding-bottom", this.topPadding + "px")
    }

    private removePositionStyle() {
        this.renderer.setStyle(this.element.nativeElement, "position", null)
        this.renderer.setStyle(this.element.nativeElement, "top", null)
    }

    private removeStyle() {
        this.removePositionStyle()
        this.renderer.setStyle(this.element.nativeElement, "z-index", null)
        if (this.enableStickyWhiteBackground) this.renderer.setStyle(this.element.nativeElement, "background", null)
        this.renderer.setStyle(this.element.nativeElement, "padding", "0px")
    }

    @HostListener("window:scroll", ["$event"])
    private handleScroll($event: Event) {
        this.checkIfFixed()
    }

    checkIfFixed() {
        // let currentScroll = $event.srcElement.children[0].scrollTop;
        let currentScroll = window.scrollY

        //if not fixed
        //and we have not yet scrolled until the original position of the element
        //add the fixed class
        if (!this.fixedState && this.element.nativeElement.getBoundingClientRect().top <= 0) {
            this.fixedState = true
            this.setStyle()
        }
        //if fixed
        else if (this.fixedState && this.element.nativeElement.parentElement.getBoundingClientRect().top >= 0) {
            //if not fixed
            //if the parent offset is below the window scroll
            //remove the fixed class
            this.fixedState = false
            this.removeStyle()
        }
    }
}

@Component({
    selector: "[sticky-top], sticky-top",
    template: `
        <div [style.height]="height" resize-aware (currentSize)="adaptToSize($event)">
            <div
                [style.width]="width"
                sticky-top-item
                [topPadding]="topPadding"
                [zIndex]="zIndex"
                [enableStickyWhiteBackground]="enableStickyWhiteBackground"
            >
                <ng-content></ng-content>
            </div>
        </div>
    `
})
export class StickyTopComponent {
    height: string = "auto"
    @Input() width: string = "auto"
    @Input("topPadding") topPadding: number = 10
    @Input("zIndex") zIndex: number = 2
    @Input("enableStickyWhiteBackground") enableStickyWhiteBackground: boolean = true

    @ViewChild(StickyTopDirective, { read: ElementRef, static: true }) itemView: ElementRef

    constructor(protected cdr: ChangeDetectorRef) {}

    ngAfterContentInit() {
        setTimeout(() => {
            this.height = this.itemView.nativeElement.offsetHeight + "px"
            this.width = this.itemView.nativeElement.offsetWidth + "px"
        }, 0)
    }

    adaptToSize(event) {
        this.width = event + "px"
        this.cdr.detectChanges()
    }
}
