import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { combineLatest, Subscription } from "rxjs"
import { select, Store } from "@ngrx/store"
import {
    selectEvaluacionTipoAliases,
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    SetSelectedAsignaturaWithId,
    SetSelectedEvaluacionTipo,
    State
} from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "generar_ensayo.component.html"
})
export class GenerarEnsayoComponent {
    asignaturaId: number
    tipoInstrumento: string
    asignatura: Asignatura
    sub: Subscription
    subQuery: Subscription
    subData: Subscription
    evaluacionTipoAlias: string

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    config: typeof config

    hideButtonDescargar = false
    evaluacionTipos: string[] = []

    redirectOnChangeAsignatura = false
    customTitle = config.plataforma.customGenerateTitle

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.generar_ensayo")
        this.config = config

        this.sub = combineLatest(this.route.params, this.route.queryParams).subscribe(([params, query]) => {
            const asignaturaId = +params["asignatura_id"]

            if (asignaturaId && !query["asignatura_id"]) {
                this.store.dispatch(new SetSelectedAsignaturaWithId({ asignaturaId }))
            }
        })

        let firstRedirectAsignatura = true

        this.subQuery = combineLatest([this.asignatura$, this.evaluacionTipo$, this.evaluacionTipoAliases$]).subscribe(
            ([asignatura, evaluacionTipo, evaluacionTipoAliases]) => {
                this.asignatura = asignatura
                this.asignaturaId = asignatura.id
                this.tipoInstrumento = evaluacionTipo
                this.evaluacionTipoAlias = evaluacionTipoAliases[this.tipoInstrumento]

                if (this.redirectOnChangeAsignatura) {
                    this.router.navigate(["ensayos", "generar_ensayo", this.asignaturaId], {
                        replaceUrl: firstRedirectAsignatura
                    })

                    firstRedirectAsignatura = false
                }
            }
        )

        this.subData = this.route.data.subscribe(data => {
            this.hideButtonDescargar = data.hideButtonDescargar ?? this.hideButtonDescargar
            this.evaluacionTipos = data.evaluacionTipos ?? this.evaluacionTipos
        })
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subQuery.unsubscribe()
        this.subData.unsubscribe()
    }
}
