import { Pipe, PipeTransform } from "@angular/core"

@Pipe({ name: "secondsToTime" })
export class SecondsToTime implements PipeTransform {
    transform(s: any, exp: any, format: any = null) {
        if (s) {
            s = ~~s

            let ms
            let secs
            let mins
            let hrs

            if (exp == "ms") {
                ms = s % 1000
                s = (s - ms) / 1000
                secs = s % 60
                s = (s - secs) / 60
                mins = s % 60
                hrs = (s - mins) / 60
            }

            if (exp == "s") {
                secs = s % 60
                s = (s - secs) / 60
                mins = s % 60
                hrs = (s - mins) / 60
            }

            let time
            switch (format) {
                case "hrminseg":
                    time = this.toStr(hrs, "hr") + this.toStr(mins, "min") + this.toStr(secs, "seg")
                    break

                default:
                    // hh:mm:ss
                    time = this.addZ(hrs) + ":" + this.addZ(mins) + ":" + this.addZ(secs)
                    break
            }

            return time
        } else if (s === 0) {
            return "0"
        } else {
            return null
        }
    }

    addZ(n) {
        return (n < 10 ? "0" : "") + n
    }

    toStr(n, sufix) {
        return n == 0 ? "" : n + " " + sufix + ". "
    }
    /* transform(s: any, exp: any, format: any = null) {
        return new Date(s * 1000).toISOString().substr(11, 8)
    } */
}
