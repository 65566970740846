import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { ModalModule } from "ngx-bootstrap/modal"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { RouterModule } from "@angular/router"
import { CUIMainHeadlineComponent } from "./cui-main-headline/cui-main-headline.component"
import { CUISectionHeadlineComponent } from "./cui-section-headline/cui-section-headline.component"
import { CUITitleHeadlineComponent } from "./cui-title-headline/cui-title-headline.component"
import { CUITitlebarComponent, CUITitlebarComponentTags } from "./cui-titlebar/cui-titlebar.component"

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        Angular2FontawesomeModule,
        ModalModule,
        ProgressbarModule,
        PopoverModule,
        TooltipModule
    ],
    declarations: [
        CUISectionHeadlineComponent,
        CUIMainHeadlineComponent,
        CUITitleHeadlineComponent,
        CUITitlebarComponent,
        CUITitlebarComponentTags
    ],
    exports: [
        CUISectionHeadlineComponent,
        CUIMainHeadlineComponent,
        CUITitleHeadlineComponent,
        CUITitlebarComponent,
        CUITitlebarComponentTags
    ],
    providers: []
})
export class CUITextsModule {
    static forRoot(): ModuleWithProviders<CUITextsModule> {
        return {
            ngModule: CUITextsModule,
            providers: []
        }
    }
}
