<div class="showcasebox" [class.hide-box]="establecimientos.length == 0">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_icon">
                <div class="icon-container">
                    <ogr-icon name="{{ config.plataforma.iconSet.establecimientosShowcase }}" class="icon"></ogr-icon>
                </div>
            </div>
            <div class="showcasebox_title_text" *ngIf="establecimientos.length > 1">
                Lista de Establecimientos asociados
            </div>
            <div class="showcasebox_title_text" *ngIf="establecimientos.length == 1">Establecimientos asociado</div>
        </div>
    </div>
    <div class="showcasebox_content">
        <loading-layout #loadingLayout>
            <div *ngIf="establecimientos.length > 0">
                <ul class="lista-establecimientos">
                    <!--<li>{{ establecimiento.establecimiento }}{{ establecimiento.asociaciones.length > 0 ? ":" : "" }} {{ establecimiento.asociaciones.join("/ ") }}</li>-->
                    <li *ngFor="let establecimiento of establecimientos">
                        <p class="establecimiento">
                            <ogr-icon name="establecimiento2" class="icono" align="middle"></ogr-icon>
                            &nbsp;{{ establecimiento.establecimiento }}
                        </p>
                        <ng-template [ngIf]="establecimiento.asociaciones.length > 0">
                            <p class="mini-title" *ngIf="establecimiento.asociaciones.length > 1">
                                Asociaciones a las que pertenece:
                            </p>
                            <p class="mini-title" *ngIf="establecimiento.asociaciones.length == 1">
                                Asociación a la que pertenece:
                            </p>
                            <p class="asociaciones">{{ establecimiento.asociaciones.join(", ") }}</p>
                        </ng-template>
                        <div class="ver-mas clearfix">
                            <a (click)="goToDetails(establecimiento.id)" class="pull-right">
                                Ver {{ config.plataforma.grupoUsuarioAlias | pluralize }}
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
            <paginator [getList]="setData"></paginator>
        </loading-layout>
    </div>
    <!--<div class="clearfix ver-mas">
        <a [routerLink]="['/establecimientos/administrar']" class="pull-right" *ngIf="establecimientos.length > 1">Administrar establecimientos</a>
        <a [routerLink]="['/establecimientos/administrar']" class="pull-right" *ngIf="establecimientos.length == 1">Administrar establecimiento</a>
    </div>-->
</div>
