import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./post.component.html",
    styleUrls: ["./post.component.scss"]
})
export class PostComponent implements OnInit {
    postSlug: string
    protected sub: Subscription
    subData: Subscription

    external = false

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.blog.posts.show")
        this.sub = this.route.params.subscribe(params => {
            this.postSlug = params["slug"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.external = data.external ?? this.external
        })
    }
}
