import { SaveType, BaseModel } from "@puntaje/shared/core"
import { AsignaturaEvaluacionTipo } from "./asignatura_evaluacion_tipos.model"
import { Establecimiento } from "./establecimientos.model"

export class AsignaturaEvaluacionTipoEstablecimiento extends BaseModel {
    public asignatura_evaluacion_tipo_id: number
    public establecimiento_id: number
    public created_at: Date

    @SaveType(() => Establecimiento) public establecimiento: Establecimiento
    @SaveType(() => AsignaturaEvaluacionTipo) public asignatura_evaluacion_tipo: AsignaturaEvaluacionTipo
}
