import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()
export class MaterialPdfService {
    public numberOfPages$: Subject<number> = new Subject()
    public _numberOfPages: number

    public get numberOfPages() {
        return this._numberOfPages
    }

    public set numberOfPages(nPages: number) {
        this._numberOfPages = nPages
        this.numberOfPages$.next(nPages)
    }

    public unsetNumberOfPages() {
        this._numberOfPages = undefined
    }
}
