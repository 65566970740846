import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv, PdfService, RoundFloatPipe, S3Service, S3 } from "@puntaje/shared/core"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { SecondsToTime } from "@puntaje/shared/core"
import { DatePipe } from "@angular/common"
import JSZip from "jszip"
import { FileSaverService } from "ngx-filesaver"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Injectable()
export class Estadisticas extends PuntajeBaseService<any> {
    tableName = "estadisticas"
    singularTableName = "estadistica"
    ignoreModel = true

    constructor(
        http: HttpClient,
        auth: PuntajeAuthService,
        router: Router,
        errores: ErroresService,
        protected environment: AppEnv,
        protected pdfService: PdfService,
        protected fileSaverService: FileSaverService,
        protected s3Service: S3Service
    ) {
        super(http, auth, router, errores, environment)
    }

    public tablaUmayor(user_id: number, params: any = null) {
        return this.all().concatExtra("umayor", user_id).get(params, true)
    }

    public wherePost(params: any = null) {
        return this.all().concatExtra("post").post(params, 120000)
    }

    public informeUsoAlumno(params: any) {
        this.enableIgnoreModel()
        return this.all()
            .concatExtra("informe_uso_alumno")
            .post(params, 300000)
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }

    public informeUsoGrupoUsuario(params: any) {
        this.enableIgnoreModel()
        return this.all()
            .concatExtra("informe_uso_grupo_usuario")
            .post(params, 300000)
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }

    public sendInformesUsoAlumno(params: any) {
        this.enableIgnoreModel()
        return this.all()
            .concatExtra("send_informes_uso_alumno")
            .post(params)
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }

    /**
     * Descarga el informe de uso de los alumnos del grupo_usuario en un archivo zip
     * @param usuario_id id del grupo_usuario
     * @param establecimiento_id id del establecimiento del grupo_usuario
     * @param fecha_inicio inicio del periodo
     * @param fecha_fin fin del periodo
     * @param evaluacion_tipos array de evaluacion_tipo para las tablas kpi
     * @return {Promise} promesa de la descarga del archivo
     */
    public imprimirInformeUsoGrupoUsuario(
        grupo_usuario_id: number,
        establecimiento_id: number,
        fecha_inicio: string,
        fecha_fin: string,
        evaluacion_tipos: string[]
    ) {
        return this.informeUsoGrupoUsuario({
            grupo_usuario_id,
            establecimiento_id,
            fecha_inicio,
            fecha_fin,
            evaluacion_tipos,
            version_full: true
        }).then(async data_usuarios => {
            const zip = new JSZip()

            for (let data of data_usuarios) {
                const docDefinition = this.docDefinitionUsuario(data, evaluacion_tipos)
                const blob = await this.pdfService.blob(docDefinition)

                zip.file(`informe_estudiante_${data.info_alumno.rut}.pdf`, blob, { binary: true })
            }

            return zip.generateAsync({ type: "blob" }).then(content => {
                this.fileSaverService.save(content, "informe_estudiante.zip")
            })
        })
    }

    /**
     * Descarga el informe de uso de un alumno en un archivo pdf.
     * @param usuario_id id del usuario alumno
     * @param establecimiento_id id del establecimiento del alumno
     * @param fecha_inicio inicio del periodo
     * @param fecha_fin fin del periodo
     * @param evaluacion_tipos array de evaluacion_tipo para las tablas kpi
     * @return {Promise} promesa de la descarga del archivo
     */
    public imprimirInformeUsoAlumno(
        usuario_id: number,
        establecimiento_id: number,
        fecha_inicio: string,
        fecha_fin: string,
        evaluacion_tipos: string[]
    ) {
        return this.informeUsoAlumno({
            usuario_id,
            establecimiento_id,
            fecha_inicio,
            fecha_fin,
            evaluacion_tipos,
            version_full: true
        }).then(data => {
            const docDefinition = this.docDefinitionUsuario(data, evaluacion_tipos)

            return this.pdfService.download(docDefinition, `informe_estudiante_${data.info_alumno.rut}.pdf`)
        })
    }

    /**
     * Descarga el informe de uso de los alumnos del grupo_usuario en un archivo zip
     * @param usuario_id id del grupo_usuario
     * @param establecimiento_id id del establecimiento del grupo_usuario
     * @param fecha_inicio inicio del periodo
     * @param fecha_fin fin del periodo
     * @param evaluacion_tipos array de evaluacion_tipo para las tablas kpi
     * @return {Promise} promesa de la descarga del archivo
     */
    public sendInformeUsoGrupoUsuario(
        grupo_usuario_id: number,
        establecimiento_id: number,
        fecha_inicio: string,
        fecha_fin: string,
        evaluacion_tipos: string[]
    ) {
        return this.informeUsoGrupoUsuario({
            grupo_usuario_id,
            establecimiento_id,
            fecha_inicio,
            fecha_fin,
            evaluacion_tipos,
            version_full: true
        }).then(async data_usuarios => {  
            if (data_usuarios.length) {
                const promisesBlob = data_usuarios.map(data => {
                    const docDefinition = this.docDefinitionUsuario(data, evaluacion_tipos)
                    return this.pdfService.blob(docDefinition)
                })
                const blobs = await Promise.all(promisesBlob)
                const usuariosIds = []
                const urls = []
                const promisesUpload = []

                await data_usuarios.map(async (data, index) => {
                    const filename = `informe_estudiante_${data.info_alumno.rut}.pdf`
                    const file = new File([blobs[index]], filename)
                    const s3Params = {
                        key: filename,
                        model_name: "Estadistica"
                    }

                    const promiseUpload = new Promise((resolve, reject) => {
                        this.s3Service.where(s3Params).then(policy => {
                            const url = policy["key"].includes("temp/")
                                ? config.amazon.s3BucketUri + policy["key"]
                                : policy["key"]
                            urls.push(url)
                            usuariosIds.push(data.info_alumno.id)
                            this.s3Service.uploadToS3(policy as any as S3, file, "", resolve)
                        })
                    })
                    promisesUpload.push(promiseUpload)
                })

                await Promise.all(promisesUpload).then(() => {
                    return this.sendInformesUsoAlumno({
                        usuario_ids: usuariosIds,
                        urls: urls,
                        establecimiento_id: establecimiento_id,
                        profesor_id: this.auth.getUserData().id
                    })
                })
            }
        })
    }

    private docDefinitionUsuario(data, evaluacion_tipos) {
        const today: string = new DatePipe("en-US").transform(new Date(), "dd-MM-yyyy")
        const year: number = new Date().getFullYear()
        const secondsToTimePipe = new SecondsToTime()
        const roundFloat = new RoundFloatPipe()
        const percentageSign = evaluacionTipo => {
            return evaluacionTipo == "paa"
        }

        const headers = ["", "Núm. evaluaciones", "Última calificación", "Calificación máxima", "Calificación promedio"]

        const hasAchievements = !!this.environment.endpoints.achievements

        const docDefinition = {
            pageSize: "A4",
            pageMargins: [72, 72, 72, 72],
            content: [
                { text: "Fecha: " + today, alignment: "right", fontSize: 10 },
                { stack: ["INFORME DE ESTUDIANTE", year], style: "header" },
                {
                    text: "En el siguiente informe encontrarás información sobre el uso y desempeño en la plataforma de:"
                },
                " ",
                // Informacion del alumno
                { text: data.info_alumno.nombre_completo, bold: true },
                { text: data.info_alumno.rut, bold: true },
                { text: data.info_alumno.fecha_nacimiento, bold: true },
                { text: data.info_alumno.email, bold: true },
                " ",
                { text: "Establecimiento: " + data.info_alumno.establecimiento },
                {
                    text: "Curso/s: " + data.info_alumno.cursos.join(", ")
                },
                // Uso de la plataforma (counts)
                { text: "Uso de la plataforma", style: "subheader" },
                { text: "A continuación se detallan algunas estadísticas de uso de la plataforma:" },
                " ",
                {
                    text: "Número de ingresos a la plataforma: " + data.estadisticas_uso.cantidad_ingresos_plataforma,
                    bold: true
                },
                {
                    text: "(Este número se calcula en base a la cantidad de veces que un alumno hace Login en la plataforma, no contabiliza la cantidad de veces que ingresó sin autentificarse. La plataforma permanece autentificada por varios días a menos que se cierre la sesión o no se utilice)",
                    italics: true
                },
                " ",
                {
                    text: "Cantidad de materiales vistos: " + data.estadisticas_uso.cantidad_materiales_vistos,
                    bold: true
                },
                " ",
                {
                    text:
                        "Cantidad de materiales descargados: " + data.estadisticas_uso.cantidad_materiales_descargados,
                    bold: true
                },
                " ",
                {
                    text:
                        "Tiempo visualizando materiales: " +
                        secondsToTimePipe.transform(
                            data.estadisticas_uso.tiempo_visualizacion_materiales,
                            "s",
                            "hrminseg"
                        ),
                    bold: true
                },
                " ",
                {
                    text:
                        "Sesiones de planes de clase terminadas: " +
                        data.estadisticas_uso.cantidad_sesiones_planes_terminadas,
                    bold: true
                },
                " ",
                {
                    text:
                        "Número de evaluaciones respondidas: " +
                        data.estadisticas_uso.cantidad_evaluaciones_respondidas,
                    bold: true
                },
                " ",
                " ",
                ...(hasAchievements
                    ? [
                          { text: "Logros obtenidos: " + data.estadisticas_uso.cantidad_logros, bold: true },
                          {
                              text: "En la medida en que los estudiantes desarrollan ciertas actividades en la plataforma (como estudiar, practicar, participar, etc.) obtienen reconocimientos llamados “Logros”.",
                              italics: true
                          },
                          " ",
                          { ul: data.estadisticas_uso.logros }
                      ]
                    : []),

                // Resultados
                { text: "Resultados", style: "subheader" },
                {
                    text: "A continuación se presenta el desempeño de el o la estudiante por asignatura, para todas las evaluaciones que haya respondido, tanto a través de su establecimiento como de forma autónoma."
                },
                { text: "Resumen general", style: "subheader2" },

                ...evaluacion_tipos
                    .map(evaluacionTipo => {
                        return [
                            { text: data.tablas_kpi[evaluacionTipo].title, style: "tableHeader" },
                            {
                                table: {
                                    headerRows: 1,
                                    body: [
                                        headers.map(header => {
                                            return {
                                                text: header,
                                                color: "white",
                                                fillColor: "#34a1ac",
                                                alignment: "center"
                                            }
                                        }),
                                        ...data.tablas_kpi[evaluacionTipo].array.map(row => [
                                            {
                                                text: row.asignatura.abreviacion,
                                                fillColor: row.asignatura.background_color,
                                                fillOpacity: 0.6
                                            },
                                            { text: row.numero_evaluaciones, alignment: "center" },
                                            { text: roundFloat.transform(row.ultimo_puntaje), alignment: "center" },
                                            { text: roundFloat.transform(row.puntaje_maximo), alignment: "center" },
                                            {
                                                text:
                                                    roundFloat.transform(row.puntaje_promedio) +
                                                    (percentageSign(evaluacionTipo) ? " %" : ""),
                                                alignment: "center"
                                            }
                                        ])
                                    ]
                                },
                                layout: {
                                    fillColor: function (rowIndex, node, columnIndex) {
                                        return rowIndex % 2 === 0 ? "#E5E4E2" : null
                                    },
                                    hLineWidth: () => 0,
                                    vLineWidth: () => 0
                                }
                            },
                            " ",
                            " "
                        ]
                    })
                    .flat()
            ],
            defaultStyle: {
                fontSize: 12
            },
            styles: {
                header: {
                    fontSize: 14,
                    alignment: "center",
                    margin: [0, 0, 0, 20]
                },
                subheader: {
                    fontSize: 13,
                    bold: true,
                    decoration: "underline",
                    margin: [0, 10, 0, 10]
                },
                subheader2: {
                    fontSize: 13,
                    bold: true,
                    margin: [0, 10, 0, 10]
                },
                tableHeader: {
                    bold: true,
                    margin: [0, 0, 0, 10]
                }
            }
        }

        return docDefinition
    }
}
