import { Directive, ElementRef, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core"

@Directive({
    selector: "[sentimentBar]"
})
export class SentimentBarDirective implements OnInit, OnChanges {
    @Input() width: number

    constructor(protected el: ElementRef) {}

    ngOnInit() {
        this.setWidth()
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setWidth()
    }

    setWidth() {
        this.el.nativeElement.style.width = this.width + "%"
    }
}
