import { Pipe, PipeTransform } from "@angular/core"
import { I18nService } from "../../i18n/i18n.service"

@Pipe({ name: "fechaAtras" })
export class FechaAtrasPipe implements PipeTransform {
    constructor(private translatorService: I18nService) {}

    transform(obj: any) {
        let time: number
        if (!obj) {
            return obj
        } else if (obj.constructor == String) {
            time = new Date(obj.toString()).getTime()
        } else if (obj.constructor == Date) {
            time = obj.getTime()
        }

        let text = ""

        let now = Date.now()
        let diff = now - time

        if (this.areSameDate(new Date(now), new Date(time))) {
            text = this.translatorService.translate("otros.fecha_atras_pipe.hoy", {})
        } else if (diff / (1000 * 60 * 60 * 24) < 1) {
            text = this.translatorService.translate("otros.fecha_atras_pipe.ayer", {})
        } else if (diff / (1000 * 60 * 60 * 24 * 7) < 1) {
            let medida = Math.floor(diff / (1000 * 60 * 60 * 24))
            text = this.translatorService.translate("otros.fecha_atras_pipe.hace_dia", { smart_count: medida })
        } else if (diff / (1000 * 60 * 60 * 24 * 7 * 4) < 1) {
            let medida = Math.floor(diff / (1000 * 60 * 60 * 24 * 7))
            text = this.translatorService.translate("otros.fecha_atras_pipe.hace_semana", { smart_count: medida })
        } else if (diff / (1000 * 60 * 60 * 24 * 365) < 1) {
            let medida = Math.floor(diff / (1000 * 60 * 60 * 24 * 7 * 4))
            text = this.translatorService.translate("otros.fecha_atras_pipe.hace_mes", { smart_count: medida })
        } else {
            let medida = Math.floor(diff / (1000 * 60 * 60 * 24 * 365))
            text = this.translatorService.translate("otros.fecha_atras_pipe.hace_ano", { smart_count: medida })
        }

        return text
    }

    /**
     * Función para evaluar si una fecha es del mismo día que otra.
     * @param d1  Fecha a comparar
     * @param d2  Fecha a comparar
     * @returns   Retorna si fecha d1 y d2 están en el mismo día o no
     */
    areSameDate(d1: Date, d2: Date) {
        return d1.getFullYear() == d2.getFullYear() && d1.getMonth() == d2.getMonth() && d1.getDate() == d2.getDate()
    }
}
