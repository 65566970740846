import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"
import { RevisionRecurso } from "./revision_recursos.model"

@Injectable()
export class RevisionRecursos extends NebuBaseService<RevisionRecurso> {
    tableName = "revision_recursos"
    singularTableName = "revision_recurso"
    protected objectQuery: RevisionRecurso
    modelClass = RevisionRecurso

    constructor(
        http: HttpClient,
        auth: NebuAuthService,
        router: Router,
        errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
