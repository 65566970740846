import { Subject } from "rxjs"
import { Injectable } from "@angular/core"

@Injectable()
export class EscalaGlobalesSearchService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    constructor() {}

    search(params: any) {
        this.searchParams.next(params)
    }
}
