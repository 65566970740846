import {
    Component,
    ElementRef,
    ViewEncapsulation,
    ContentChildren,
    Input,
    QueryList,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    HostListener,
    AfterViewInit,
    OnDestroy
} from "@angular/core"
import { CUITabComponent } from "./cui-tab.component"

@Component({
    selector: "cui-tabs",
    template: `
        <ul
            class="nav print-hide cui-tabs-links"
            [style.justify-content]="justifyContent"
            role="tablist"
            [attr.aria-label]="ariaLabel ? ariaLabel : undefined"
            [class.cui-tabs-headerless]="headerLess && tabs.length == 1"
        >
            <li *ngFor="let t of tabs.toArray(); let i = index" role="presentation">
                <a
                    (click)="selectTab(t)"
                    [class.active]="t.active"
                    [class.inactive]="!t.active"
                    [attr.id]="t.ariaLabelledBy ? t.ariaLabelledBy : undefined"
                    role="tab"
                    [style.max-width]="tabMaxWidth ? tabMaxWidth + 'px' : 'none'"
                    [attr.aria-selected]="t.active"
                    [attr.tabindex]="t.active ? 0 : -1"
                    (keyup.enter)="selectTab(t)"
                    [attr.index]="i"
                >
                    {{ t.title }}
                    <span class="sr-only" *ngIf="t.active">Activo</span>
                </a>
            </li>
        </ul>
        <ng-container *ngIf="isReady">
            <ng-content select="cui-tab"></ng-content>
        </ng-container>
    `,
    styleUrls: ["cui-tabs.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUITabsComponent implements AfterViewInit, OnDestroy {
    @Input() justifyContent: "space-around" | "space-between" | "flex-start" | "flex-end" | "center" = "center"

    @Input() ariaLabel: string
    @Input() usingDisplayNone = true
    @Input() tabMaxWidth: number
    @Input() useTabActive = false
    @Input() orientation = "horizontal"
    @Input() headerLess = false
    isReady = false
    @ContentChildren(CUITabComponent) tabs: QueryList<CUITabComponent>
    @Output() select: EventEmitter<any> = new EventEmitter<any>()

    keys = {
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        enter: 13,
        space: 32
    }

    direction = {
        37: -1,
        38: -1,
        39: 1,
        40: 1
    }

    tabsHtml: any

    constructor(protected el: ElementRef, public cdr: ChangeDetectorRef) {}

    ngAfterContentInit(): void {
        if (this.tabs.length > 0) {
            this.setInitial()
        }
    }

    ngAfterViewInit() {
        // this.tabsHtml = document.querySelectorAll('[role="tab"]')
        this.tabsHtml = this.el.nativeElement.querySelectorAll('[role="tab"]')
        this.addListeners()
    }

    addListeners() {
        this.tabsHtml.forEach((tab, index) => {
            this.addTabListeners(tab, index)
        })
    }

    addTabListeners(tab, index) {
        tab.addEventListener("keydown", this.keydownEventListener.bind(this))
        tab.addEventListener("keyup", this.keyupEventListener.bind(this))

        tab.index = index
    }

    removeListeners() {
        this.tabsHtml.forEach(tab => {
            this.removeTabListeners(tab)
        })
    }

    removeTabListeners(tab) {
        tab.removeEventListener("keydown", this.keydownEventListener)
        tab.removeEventListener("keyup", this.keyupEventListener)
    }

    // Handle keydown on tabs
    keydownEventListener(event) {
        var key = event.keyCode

        switch (key) {
            case this.keys.end:
                event.preventDefault()
                // Activate last tab
                this.focusLastTab()
                break
            case this.keys.home:
                event.preventDefault()
                // Activate first tab
                this.focusFirstTab()
                break

            // Up and down are in keydown
            // because we need to prevent page scroll >:)
            case this.keys.up:
            case this.keys.down:
                this.determineOrientation(event)
                break
            case this.keys.space:
                event.preventDefault()
        }
    }

    // Handle keyup on tabs
    keyupEventListener(event) {
        var key = event.keyCode

        switch (key) {
            case this.keys.left:
            case this.keys.right:
                this.determineOrientation(event)
                break
            case this.keys.enter:
            case this.keys.space:
                event.preventDefault()
                this.activateTab(event.target)
                break
        }
    }

    activateTab(target) {
        const tab = this.tabs.toArray()[target.index]
        this.selectTab(tab)
    }

    setInitial() {
        if (!this.useTabActive) {
            this.tabs.toArray().forEach(tab => {
                tab.active = false
                tab.usingDisplayNone = this.usingDisplayNone
            })
            if (this.tabs.length > 0) {
                this.tabs.first.active = true
            }
        }

        this.isReady = true
        this.cdr.detectChanges()
    }

    selectTab(tab: CUITabComponent) {
        // deactivate all tabs
        this.tabs.toArray().forEach(tab => {
            tab.active = false
        })

        // activate the tab the user has clicked on.
        tab.active = true
        this.select.emit(tab)
        tab.select.emit(tab)
    }

    // When a tablistâ€™s aria-orientation is set to vertical,
    // only up and down arrow should function.
    // In all other cases only left and right arrow function.
    determineOrientation(event) {
        const keyCode = event.code
        const vertical = this.orientation == "vertical"
        let proceed = false

        if (vertical) {
            if (keyCode === "ArrowUp" || keyCode === "ArrowDown") {
                event.preventDefault()
                proceed = true
            }
        } else {
            if (keyCode === "ArrowLeft" || keyCode === "ArrowRight") {
                proceed = true
            }
        }

        if (proceed) {
            this.switchTabOnArrowPress(event)
        }
    }

    // Either focus the next, previous, first, or last tab
    // depending on key pressed
    switchTabOnArrowPress(event) {
        var pressed = event.keyCode

        if (this.direction[pressed]) {
            var target = event.target
            if (target.index !== undefined) {
                if (this.tabsHtml[target.index + this.direction[pressed]]) {
                    this.tabsHtml[target.index + this.direction[pressed]].focus()
                } else if (pressed === this.keys.left || pressed === this.keys.up) {
                    this.focusLastTab()
                } else if (pressed === this.keys.right || pressed == this.keys.down) {
                    this.focusFirstTab()
                }
            }
        }
    }

    focusLastTab() {
        const tab = <HTMLElement>this.tabsHtml[this.tabsHtml.length - 1]
        tab.focus()
    }

    focusFirstTab() {
        const tab = <HTMLElement>this.tabsHtml[0]
        tab.focus()
    }

    ngOnDestroy() {
        this.removeListeners()
    }
}
