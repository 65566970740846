import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Logro } from "./logros.model"

export class LogroUsuarioPlataforma extends BaseModel {
    logro_id: number
    usuario_plataforma_id: number
    created_at: string
    valor_progreso: number
    valor_completado: number

    @SaveType(() => Logro) logro: Logro
}
