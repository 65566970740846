import { TextView, LinkView, DateView, EntitiesView, I18nService } from "@puntaje/shared/core"
import { Usuarios } from "./usuarios.service"
import { GrupoUsuario } from "./grupo_usuarios.model"
import { Establecimientos } from "./establecimientos.service"
import { Amigo } from "./amigos.model"
import { Injector } from "@angular/core"

export class UsuarioView {
    static injector: Injector

    public static get viewParams() {
        const translatorService = this.injector ? this.injector.get(I18nService) : null

        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: Usuarios, key: "id" } },
            nombre: { label: "Nombre", type: TextView, tableVisible: true },
            apellido_paterno: { label: "Apellido paterno", type: TextView, tableVisible: true },
            apellido_materno: { label: "Apellido materno", type: TextView, tableVisible: true },
            fecha_nacimiento: {
                label: translatorService
                    ? translatorService.translate("libs.api_services.view_params.usuario.fecha_nacimiento")
                    : "Fecha de nacimiento",
                type: DateView,
                tableVisible: true
            },
            email: {
                label: translatorService
                    ? translatorService.translate("libs.api_services.view_params.usuario.email")
                    : "Correo electrónico",
                type: TextView,
                tableVisible: true
            },
            establecimiento: {
                label: translatorService
                    ? translatorService.translate("libs.api_services.view_params.usuario.establecimiento")
                    : "Establecimiento",
                type: LinkView,
                tableVisible: true,
                options: { class: Establecimientos, key: "id" }
            },
            grupo_usuarios: {
                label: "Grupos de Usuarios",
                type: EntitiesView,
                class: GrupoUsuario,
                tableVisible: false
            },
            super_amigos: { label: "Amigos", type: EntitiesView, class: Amigo, tableVisible: false },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true },
            nivel_id: { label: "Curso", type: TextView, tableVisible: true }
        }
    }
}
