import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { EvaluacionInstancia } from "./evaluacion_instancias.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class EvaluacionInstancias extends PuntajeBaseService<EvaluacionInstancia> {
    tableName = "evaluacion_instancias"
    singularTableName = "evaluacion_instancia"
    modelClass = EvaluacionInstancia

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public subir() {
        return this.all().concatExtra("subir")
    }

    public subirLectura(params: any = null, timeout = 30000) {
        return this.all().concatExtra("subir_lectura").post(params, timeout)
    }

    public getRanked(params: any) {
        return this.all().concatExtra("ranking").get(params)
    }

    public exists(params: any = null) {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("exists")
            .get(params)
            .then(value => {
                this.disableIgnoreModel()

                return value
            })
    }

    public wherePost(params: any = null) {
        return this.all().concatExtra("post").post(params, 120000)
    }

    public compartirResultados(id: number, params: any = null) {
        return this.one(id).concatExtra("compartir_resultados").post(params)
    }

    public data_grafico_clasificacion(params: any = null) {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("data_grafico_clasificacion")
            .post(params)
            .then(data => {
                this.disableIgnoreModel()

                return data
            })
    }

    public asignaturaIdsRespondidasByPeriodo(params: any = null) {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("asignatura_ids_respondidas_by_periodo")
            .post(params)
            .then((data: any) => {
                this.disableIgnoreModel()

                return data.asignatura_ids
            })
    }

    public destroy_many(params: any = null, timeout = 120000) {
        return this.all().concatExtra("destroy").post(params, timeout)
    }

    public promedioAsignaturas(params: any = null, timeout = 30000) {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("promedio_asignaturas")
            .get(params, false, timeout)
            .then(data => {
                this.disableIgnoreModel()

                return data
            })
    }

    public promedioGeneradores(params: any = null, timeout = 30000) {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("promedio_generadores")
            .get(params, false, timeout)
            .then(data => {
                this.disableIgnoreModel()

                return data
            })
    }

    public resumen(params: any = null, timeout = 120000) {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("resumen")
            .get(params, false, timeout)
            .then(data => {
                this.disableIgnoreModel()

                return data
            })
    }

    public updateInstanciaAndEstadistica(params: any = null, timeout = 30000) {
        return this.all().concatExtra("update_instancia_estadistica").post(params, timeout)
    }

    public porGenerador(params: any = null, timeout = 30000) {
        return this.all().concatExtra("por_generador").get(params, true, timeout)
    }

    public porGeneradorStats(params: any = null, timeout = 30000) {
        this.enableIgnoreModel()
        return this.all()
            .concatExtra("por_generador_stats")
            .get(params, false, timeout)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }
    public feedback(evaluacionInstanciaId: number, params: any = null, timeout = 30000) {
        this.enableIgnoreModel()
        return this.one(evaluacionInstanciaId)
            .concatExtra("feedback")
            .post(params, timeout)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }
}
