import { Component, ContentChildren, Directive, Input, OnInit, TemplateRef, QueryList } from "@angular/core"
import { Subscription } from "rxjs"

@Directive({
    selector: "[errorMessage]"
})
export class ErrorMessageDirective {
    constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
    selector: "error-messages",
    templateUrl: "./error-messages.component.html",
    styleUrls: ["./error-messages.component.scss"]
})
export class ErrorMessagesComponent implements OnInit {
    @Input() isExpanded: boolean
    @Input() containerId: string = "id_" + Math.random() * 1000

    @ContentChildren(ErrorMessageDirective) errorMessagesQuery: QueryList<ErrorMessageDirective>

    errorMessages: ErrorMessageDirective[]

    sub: Subscription

    constructor() {}

    ngOnInit(): void {}

    ngAfterContentInit(): void {
        this.errorMessages = this.errorMessagesQuery.toArray()

        this.sub = this.errorMessagesQuery.changes.subscribe(query => {
            this.errorMessages = query.toArray()
        })
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe()
    }
}
