<div class="clearfix">
    <cui-button type="button" (click)="goBack()" class="btn_style pull-left btn-sm">
        <span class="glyphicon glyphicon-chevron-left"></span>
        &nbsp;Volver
    </cui-button>
</div>
<br />
<loading-layout #loadingLayout>
    <div class="preview" *ngIf="urlReady && !loadingLayout.loading" class="contenido-app">
        <model-plain-value [params]="getVisualizer()" [value]="material" [key]="'url'"></model-plain-value>
    </div>
</loading-layout>
