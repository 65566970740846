/** @format */

import { ActionReducerMap, createSelector, MetaReducer, Action, ActionReducer } from "@ngrx/store"
import * as fromAsignaturas from "./asignaturas.reducer"
import * as fromEstadisticas from "./estadisticas.reducer"
import * as fromEvaluaciones from "./evaluaciones.reducer"
import * as fromRegistro from "./registro.reducer"
import * as fromHojaRespuestas from "./hoja_respuestas.reducer"
import * as fromEvaluacionTipos from "./evaluacion_tipos.reducer"
import * as fromAlertas from "./alertas.reducer"
import { AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { AppEnv } from "@puntaje/shared/core"

export { GrupoPreguntaItem } from "./evaluaciones.reducer"

declare const environment: AppEnv

export interface State {
    asignaturas: fromAsignaturas.State
    evaluaciones: fromEvaluaciones.State
    estadisticas: fromEstadisticas.State
    registro: fromRegistro.State
    hojaRespuestas: fromHojaRespuestas.State
    evaluacionTipos: fromEvaluacionTipos.State
    alertas: fromAlertas.State
}

export const reducers = {
    asignaturas: fromAsignaturas.reducer,
    evaluaciones: fromEvaluaciones.reducer,
    estadisticas: fromEstadisticas.reducer,
    registro: fromRegistro.reducer,
    hojaRespuestas: fromHojaRespuestas.reducer,
    evaluacionTipos: fromEvaluacionTipos.reducer,
    alertas: fromAlertas.reducer
}

function setSavedState(state: any, localStorageKey: string) {
    localStorage.setItem(localStorageKey, JSON.stringify(state))
}

function getSavedState(localStorageKey: string): any {
    try {
        return JSON.parse(localStorage.getItem(localStorageKey))
    } catch (e) {
        return {}
    }
}

const localStorageKey = "__store__estadisticas__"

export function storageMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>) {
    let onInit = true

    return function (state: S, action: A): S {
        let nextState

        if (onInit) {
            onInit = false
            const savedState = getSavedState(localStorageKey) || {}
            const updatedState = {
                ...state,
                estadisticas: {
                    filtros: {
                        ...savedState.filtros
                    }
                }
            }

            nextState = reducer(updatedState, action)
        } else {
            nextState = reducer(state, action)
        }

        setSavedState(nextState.estadisticas, localStorageKey)

        return nextState
    }
}

export const metaReducers: MetaReducer<State>[] = [storageMetaReducer]

export const selectRegistro = (state: State) => state.registro
export const selectCodigoConfirmacionData = createSelector(
    selectRegistro,
    (state: fromRegistro.State) => state.codigoConfirmacionData
)

export const selectAsignaturas = (state: State) => state.asignaturas
export const selectAsignaturasList = createSelector(selectAsignaturas, (state: fromAsignaturas.State) => state.list)

export const selectAsignaturasById = createSelector(selectAsignaturas, (state: fromAsignaturas.State) => state.byId)

export const selectAsignaturasByEvaluacionTipo = createSelector(
    selectAsignaturas,
    (state: fromAsignaturas.State) => state.byEvaluacionTipo
)

export const selectSelectedAsignaturaId = createSelector(
    selectAsignaturas,
    (state: fromAsignaturas.State) => state.selectedAsignaturaId
)

export const selectSelectedAsignatura = createSelector(
    selectSelectedAsignaturaId,
    selectAsignaturasById,
    (selectedAsignaturaId: number, asignaturaById: fromAsignaturas.AsignaturaById) =>
        asignaturaById ? asignaturaById[selectedAsignaturaId] : null
)

export const selectEvaluaciones = (state: State) => state.evaluaciones
export const selectEvaluacionesActual = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.actual
)
export const selectEvaluacionesRespuestasActual = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.respuestasActual
)
export const selectEvaluacionesSeeingIndex = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.seeingIndex
)
export const selectEvaluacionesItems = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.items
)

export const selectEvaluacionesMaterial = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.material
)

export const selectEvaluacionesTiemposActual = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.tiemposActual
)

export const selectEvaluacionesEvaluacionTiempoActual = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.evaluacionTiempoActual
)

export const selectEvaluacionesLoadingEvaluacion = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.loadingEvaluacion
)

export const selectEvaluacionesLoadingItems = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.loadingItems
)

export const selectEvaluacionesDudaPreguntasActual = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.dudaPreguntasActual
)

export const selectEstadisticas = (state: State) => state.estadisticas
export const selectEstadisticasFiltros = createSelector(
    selectEstadisticas,
    (state: fromEstadisticas.State) => state.filtros
)

export const selectCorrecionTabClasses = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.tabCorreccionClasses
)

export const selectGrupoPreguntaItems = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.grupoPreguntaItems
)

export const selectHighlightMode = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.highlightMode
)

export const selectEraseHighlightMode = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.eraseHighlightMode
)

export const selectEvaluacionesInstruccionFija = createSelector(
    selectEvaluaciones,
    (state: fromEvaluaciones.State) => state.instruccionFija
)

export const selectHojaRespuestas = (state: State) => state.hojaRespuestas

export const selectCanvases = createSelector(selectHojaRespuestas, (state: fromHojaRespuestas.State) => state.canvases)
export const selectArchivosProcesados = createSelector(
    selectHojaRespuestas,
    (state: fromHojaRespuestas.State) => state.archivosProcesados
)
export const selectFormaGlobal = createSelector(
    selectHojaRespuestas,
    (state: fromHojaRespuestas.State) => state.formaGlobal
)
export const selectSectores = createSelector(selectHojaRespuestas, (state: fromHojaRespuestas.State) => state.sectores)
export const selectProcessedAll = createSelector(
    selectHojaRespuestas,
    (state: fromHojaRespuestas.State) => state.processedAll
)
export const selectSelectedFile = createSelector(
    selectHojaRespuestas,
    (state: fromHojaRespuestas.State) => state.selectedFile
)

export const selectProcessedFiles = createSelector(
    selectHojaRespuestas,
    (state: fromHojaRespuestas.State) => state.processedFiles
)

export const selectLecturasProcesadas = createSelector(
    selectHojaRespuestas,
    (state: fromHojaRespuestas.State) => state.lecturasProcesadas
)

export const selectLecturasTotal = createSelector(
    selectHojaRespuestas,
    (state: fromHojaRespuestas.State) => state.lecturasTotal
)

export const selectEnableThreshold = createSelector(
    selectHojaRespuestas,
    (state: fromHojaRespuestas.State) => state.enableThreshold
)

export const selectInputFile = createSelector(
    selectHojaRespuestas,
    (state: fromHojaRespuestas.State) => state.inputFile
)

export const selectEvaluacionTipos = (state: State) => state.evaluacionTipos
export const selectEvaluacionTiposEvaluacionTipos = createSelector(
    selectEvaluacionTipos,
    (state: fromEvaluacionTipos.State) => state.evaluacionTipos
)

export const selectSelectedEvaluacionTipo = createSelector(
    selectEvaluacionTipos,
    (state: fromEvaluacionTipos.State) => state.selectedEvaluacionTipo
)

export const selectEvaluacionTipoAliases = createSelector(
    selectEvaluacionTipos,
    (state: fromEvaluacionTipos.State) => state.evaluacionTipoAliases
)

export const selectSelectedAsignaturaEvaluacionTipo = createSelector(
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    (selectedAsignatura: AsignaturaWithConfig, selectedEvaluacionTipo: string) =>
        selectedAsignatura ? selectedAsignatura.confByEvaluacionTipo[selectedEvaluacionTipo] : null
)

export const selectAlertas = (state: State) => state.alertas
export const selectLogros = createSelector(selectAlertas, (state: fromAlertas.State) => state.logros)
