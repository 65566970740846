import { Action } from "@ngrx/store"

export enum StoreActionTypes {
    GetStoreConfig = "[Store] Get Store Config"
}

export class GetStoreConfig implements Action {
    readonly type = StoreActionTypes.GetStoreConfig
}

export type StoreActions = GetStoreConfig
