<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-6">
            <div *ngFor="let a of firstColumn">
                <barra-distribucion-asignatura
                    [asignatura]="a"
                    [valores]="a.estadistica_clasificaciones.valores"
                    [clasificaciones]="a.estadistica_clasificaciones.clasificaciones"
                    *ngIf="a.estadistica_clasificaciones"
                    [tipoInstrumento]="tipoEvaluacion"
                ></barra-distribucion-asignatura>
            </div>
        </div>
        <div class="col-md-6">
            <div *ngFor="let a of secondColumn">
                <barra-distribucion-asignatura
                    [asignatura]="a"
                    [valores]="a.estadistica_clasificaciones.valores"
                    [clasificaciones]="a.estadistica_clasificaciones.clasificaciones"
                    *ngIf="a.estadistica_clasificaciones"
                    [tipoInstrumento]="tipoEvaluacion"
                ></barra-distribucion-asignatura>
            </div>
        </div>
    </div>
</loading-layout>
