import { PuntajeCarrerasModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { UtilModule, TranslationsModule } from "@puntaje/shared/core"
import { routing } from "./alumnos_carreras.routing"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { CarrerasModule } from "@puntaje/puntaje/new-modules/carreras"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EnsayosModule,
        AlumnosLayoutsModule,
        SharedModule,
        routing,
        Angular2FontawesomeModule,
        UtilModule,
        BannersModule,
        NebuModule,
        TranslationsModule,
        CarrerasModule,
        PuntajeCarrerasModule
    ],

    declarations: [],
    exports: []
})
export class AlumnosCarrerasModule {}
