<!-- Modal -->
<div id="simpleModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button
                    *ngIf="!hideBtnX"
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    (click)="onClickCancel(); cleanModal()"
                >
                    &times;
                </button>
                <h4 class="modal-title">{{ header }}</h4>
            </div>
            <div class="modal-body">
                <p style="white-space: pre-wrap" *ngIf="contentString != ''">{{ contentString }}</p>
                <div *ngIf="contentHTML" [outerHTML]="contentHTML"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal" (click)="onClickOk()">
                    {{ callbackBtnText }}
                </button>
                <button
                    *ngIf="!disableBtnCancel"
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    (click)="onClickCancel()"
                >
                    {{ "simple_modal.cancelar" | t }}
                </button>
            </div>
        </div>
    </div>
</div>
