<div class="row">
    <div class="col-md-5">
        <div class="tutorial clearfix">
            <h3 class="step">Descarga CSV prellenado</h3>
            <div class="select">
                <select
                    [disabled]="establecimientos && establecimientos.length == 1"
                    [(ngModel)]="establecimiento"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- Seleccione un establecimiento --</option>
                    <option *ngFor="let e of establecimientos" [ngValue]="e">{{ e.establecimiento }}</option>
                </select>
            </div>
            <div class="select">
                <select *ngIf="establecimiento" [(ngModel)]="grupoUsuario" class="form-control select-controller">
                    <option [ngValue]="undefined">-- Seleccione un curso --</option>
                    <option *ngFor="let gu of establecimiento.grupo_usuarios" [ngValue]="gu">{{ gu.nombre }}</option>
                </select>
            </div>
            <div class="select">
                <input
                    type="text"
                    class="form-control select-controller"
                    placeholder="Escribe la forma"
                    [(ngModel)]="forma"
                />
            </div>
            <div *ngIf="faltanDatos" class="warning hide-warning" [class.show-warning]="faltanDatos">
                <div class="warning-content">
                    <fa name="warning" class="warning-icon"></fa>
                    <div class="warning-message">
                        {{ messages }}
                    </div>
                </div>
            </div>
            <div class="button">
                <cui-button
                    [disabled]="loadingMessages"
                    class="btn_style pull-right"
                    (click)="descargarCsvPrellenado()"
                >
                    <ogr-icon name="download" align="text-top"></ogr-icon>
                    &nbsp;Descargar CSV
                </cui-button>
                <loader *ngIf="loadingMessages" class="pushed pull-right" loaderType="default-xsmall"></loader>
            </div>
        </div>
    </div>
    <div class="col-md-7"></div>
</div>
