import { BaseForm, Validations, GroupValidator, DateHelper, AppConfig } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"

import { Clasificaciones } from "@puntaje/nebulosa/api-services"

import { UsuarioChileForm } from "./usuario_chile.form"
import { UsuarioColombiaForm } from "./usuario_colombia.form"

declare const config: AppConfig

export class UsuariosRegistroIncompletoForm extends BaseForm {
    companyName: string = ""
    // constructor(){
    //  super();
    //  this.companyName = config.plataforma.info.companyName;
    // }

    public static get formParams(): any {
        // let companyName = this.companyName;
        let companyName = config.plataforma.info.companyName
        let femenino = { id: 0, toString: () => "Femenino" }
        let masculino = { id: 1, toString: () => "Masculino" }

        let classes = {
            chile: { class: UsuarioChileForm, nivel: { tipo: "curso", nombre: "Curso" } },
            colombia: { class: UsuarioColombiaForm, nivel: { tipo: "nivel colombia", nombre: "Grado" } }
        }

        let params: any = {
            id: { label: "ID", type: "hidden", visible: false },
            fecha_nacimiento: {
                label: "Fecha de nacimiento",
                type: "birthdate",
                visible: true,
                min: DateHelper.firstDayYear(150),
                max: DateHelper.today()
            },
            lugar: {
                label: config.plataforma.lugarTipo,
                type: "autocomplete",
                visible: true,
                options: { class: Lugares, params: { lugar_tipo: config.plataforma.lugarTipo } }
            },
            terminos_y_condiciones: {
                innerhtml:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de " +
                    companyName +
                    "</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            },
            nombre: {
                label: "Nombre",
                type: "text",
                validations: [Validators.required],
                visible: true
            },
            apellido_paterno: {
                label: "Apellido Paterno",
                type: "text",
                validations: [Validators.required],
                visible: true
            },
            genero: {
                label: "Género",
                type: "radio",
                visible: true,
                options: [femenino, masculino]
            },
            egresado: { label: "Egresado", type: "checkbox", visible: true },
            establecimiento: { label: "Colegio", type: "text", visible: true },
            nivel_id: {
                label: classes[config.plataforma.pais].nivel.nombre,
                type: "select",
                visible: true,
                options: {
                    class: Clasificaciones,
                    params: { clasificacion_tipo: { clasificacion_tipo: classes[config.plataforma.pais].nivel.tipo } }
                }
            },
            ["usuario_" + config.plataforma.pais]: { type: "model", class: classes[config.plataforma.pais].class }
        }

        return params
    }
}
