import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core"
import { S3Service, S3 } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { NebuAuthService } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "contestable-imagen",
    templateUrl: "./contestable-imagen.component.html",
    styleUrls: ["./contestable-imagen.component.scss"]
})
export class ContestableImagenComponent implements OnChanges {
    @Input() alternativaTexto: any
    @Output() alternativaTextoChange = new EventEmitter()

    @Input() contestableId: number

    urlImagen: string
    urlImagenPath: string

    loading: boolean = false

    constructor(protected s3Service: S3Service, protected nebuAuthService: NebuAuthService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["alternativaTexto"] && this.alternativaTexto.alternativa_texto) {
            this.urlImagen = this.alternativaTexto.alternativa_texto
            this.urlImagenPath = config.amazon.s3BucketUriNebu + this.urlImagen
        }
    }

    onChangeFile(file) {
        this.loading = true

        this.s3Service.enableNebuBaseUrl()
        this.s3Service.changeAuthService(this.nebuAuthService)

        const s3Params = {
            key: this.cleanFileNameForUpload(file.name),
            model_name: "alternativa"
        }

        this.s3Service.where(s3Params).then(policy => {
            const p = policy as any as S3

            this.s3Service.uploadToS3(p, file, "", () => {
                this.urlImagen = p["key"]
                this.urlImagenPath = config.amazon.s3BucketUriNebu + this.urlImagen

                this.loading = false

                this.onChangeTextoRespuesta()
            })
        })
    }

    onChangeTextoRespuesta() {
        this.alternativaTextoChange.emit({
            alternativa_texto: this.urlImagen
        })
    }

    cleanFileNameForUpload(fileName: string) {
        return fileName.replace(/[#%&{}<>'"@:$=\|\`\+\/\!\?\*\\]/g, "")
    }
}
