import { Component, EventEmitter, Input, Output } from "@angular/core"
import { Clasificacion } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "clasificacion-checkboxes",
    templateUrl: "clasificacion-checkboxes.component.html",
    styleUrls: ["clasificacion-checkboxes.component.scss"]
})
export class ClasificacionCheckboxesComponent {
    @Input() id: string
    @Input() clasificacionPadre: Clasificacion
    @Input() clasificacion: Clasificacion
    @Input() enableObjetivoPriorizado: boolean
    @Input() concats: any
    @Input() enableCantidadPreguntas: boolean

    @Output() clasificacionChange: EventEmitter<{ clasificacion: Clasificacion; clasificacionPadre: Clasificacion }> =
        new EventEmitter<{ clasificacion: Clasificacion; clasificacionPadre: Clasificacion }>()

    onClasificacionChange(clasificacion: Clasificacion, clasificacionPadre: Clasificacion) {
        this.clasificacionChange.emit({ clasificacion, clasificacionPadre })
    }
}
