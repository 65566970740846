import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { FacebookService } from "@ogr-sa/ngx-facebook"
import { AppConfig, AppEnv, AuthService, SessionService, ZopimService } from "@puntaje/shared/core"
import { AuthService as SocialAuthService } from "angularx-social-login"

@Injectable()
export class PuntajeAuthService extends AuthService {
    constructor(
        protected http: HttpClient,
        protected fb: FacebookService,
        protected socialAuthService: SocialAuthService,
        protected sessionService: SessionService,
        protected zopimService: ZopimService,
        protected config: AppConfig,
        protected environment: AppEnv
    ) {
        super(http, fb, socialAuthService, sessionService, config, environment)
        this.userProfile = "profile1"
        this.authTokenName = "auth_token_puntaje"
        this.authUriPath = "/usuarios/sign_in"
        this.apiTokenKeyName = "token"
        this.apiUserKey = "user"
    }

    public getAuthObject(email: string, password: string, recaptchaResponse: string = null) {
        return { usuario: { email: email, password: password, recaptcha_response: recaptchaResponse } }
    }

    public beforeLogout() {
        this.zopimService.hideZopim()
        this.zopimService.unsetZopimData()
    }
}
