import { BaseModel, SaveType } from "@puntaje/shared/core"
import { EscalaGlobalAlias } from "./escala_global_aliases.model"

export class EvaluacionInstanciaEscalaGlobalAlias extends BaseModel {
    evaluacion_instancia_id: number
    escala_global_alias_id: number
    escala_global_id: number

    @SaveType(() => EscalaGlobalAlias) escala_global_alias: EscalaGlobalAlias
}
