import { Pipe, PipeTransform } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"

@Pipe({ name: "trustedurl" })
export class TrustedUrlPipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    transform(value: any) {
        if (value) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(value)
        }
        return value
    }
}
