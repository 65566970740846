import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { CarrerasAuthService } from "./carreras_auth.service"
import { CarrerasBaseService } from "./carreras_base.service"
import { CarreraSede } from "./carrera_sedes.model"

@Injectable()
export class CarreraSedes extends CarrerasBaseService<CarreraSede> {
    tableName = "carrera_sedes"
    singularTableName = "carrera_sede"
    modelClass = CarreraSede

    constructor(
        protected http: HttpClient,
        protected auth: CarrerasAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public wherePost(params: any = null) {
        return this.all().concatExtra("post").post(params, 120000)
    }

    public wherePostCount(params: any = null) {
        this.enableIgnoreModel()

        const countParams = {
            ...params,
            count: 1
        }

        return this.all()
            .concatExtra("post")
            .post(countParams, 120000)
            .then(count => {
                this.disableIgnoreModel()

                return count
            })
    }

    public getValueModalidadArea(params: any) {
        return this.all().concatExtra("getValueOf").get(params)
    }
}
