import { Component, HostBinding, Input, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService, I18nService } from "@puntaje/shared/core"
import { GrupoUsuario, GrupoUsuarios, GrupoUsuarioUsuarios, Usuarios } from "@puntaje/puntaje/api-services"
import { PeriodosService } from "@puntaje/puntaje/new-modules/shared"

@Component({
    selector: "grupo-usuarios-profesor-showcase",
    templateUrl: "grupo_usuarios_profesor_showcase.component.html",
    styleUrls: ["grupo_usuarios_profesor_showcase.component.scss"]
})
export class GrupoUsuariosProfesorShowcaseComponent implements OnInit {
    @Input() rutaGrupoUsuarios: string
    @Input() defaultNotFound: boolean = true

    grupoUsuarios: GrupoUsuario[] = []
    grupoUsuario: GrupoUsuario
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    fechaInicial: Date
    fechaFinal: Date
    config: typeof config = config
    @HostBinding("class") innerClass = "showcasebox_style_1_establecimientos"

    constructor(
        protected usuariosService: Usuarios,
        protected grupoUsuariosService: GrupoUsuarios,
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios,
        protected authService: AuthService,
        protected periodosService: PeriodosService,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {
        this.loadingLayout.standby()
        const currentDate = new Date()
        const p = this.periodosService.getPeriodo(currentDate)
        this.fechaInicial = p.fechaInicial(currentDate.getFullYear())
        this.fechaFinal = p.fechaFinal(currentDate.getFullYear())
        this.setData()
    }

    setData = (page: number = 1, per: number = 10) => {
        const params = {
            page: page,
            per: per,
            activos: 1,
            establecimiento_id_not_nil: 1,
            propios: 1,
            grupo_usuario: {
                privado: 1
            },
            grupo_usuario_usuario: {
                propietario: 1
            },
            establecimiento: {
                activo: 1
            },
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal
        }

        return this.grupoUsuariosService.where(params).then((grupoUsuarios: GrupoUsuario[], total: number) => {
            this.grupoUsuarios = grupoUsuarios
            this.loadingLayout.ready()

            return total
        })
    }
}
