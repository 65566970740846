<div class="alertas" [@SlideInAndOut]="alertas?.length">
    <div *ngFor="let alerta of alertas | slice: 0:9" class="alerta clearfix">
        <div class="alerta-icono-container">
            <img class="img" src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/logros/trofeo_g-01.png" />
        </div>
        <div class="alerta-text">
            <div class="content-icono">
                <ogr-icon name="trofeo-o" class="icono"></ogr-icon>
                Logro
            </div>

            <a class="titulo" [routerLink]="'/usuarios/' + usuario.id">{{ alerta.titulo }}</a>
        </div>
        <button tipe="button" class="alerta-close" (click)="dismissAlert(alerta)">
            <fa name="times"></fa>
        </button>
    </div>
</div>
