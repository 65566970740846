import { Component, OnInit, ViewChild, Output, EventEmitter, Injector, ChangeDetectorRef } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Usuario,
    Usuarios,
    UsuarioRegistroIncompleto,
    UsuarioRegistroGraduateForm,
    Establecimientos
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"

import { BaseRegisterIncompletoComponent } from "./base_register_incompleto.component"

@Component({
    selector: "usuario-registro-incompleto-puntaje-colombia",
    templateUrl: "register_incompleto_puntaje_colombia.component.html",
    styleUrls: ["base_register_incompleto.component.scss"]
})
export class RegisterIncompletoPuntajeColombiaComponent extends BaseRegisterIncompletoComponent {
    constructor(
        usuariosService: Usuarios,
        clasificacionesService: Clasificaciones,
        establecimientosService: Establecimientos,
        router: Router,
        injector: Injector,
        authService: AuthService,
        cdr: ChangeDetectorRef
    ) {
        super(usuariosService, clasificacionesService, establecimientosService, router, injector, authService, cdr)
    }
}
