import { Component, Input, ViewChild, OnDestroy, HostBinding } from "@angular/core"
import { Usuario, Establecimiento, Establecimientos } from "@puntaje/puntaje/api-services"
import { AuthService, TabService, SessionService } from "@puntaje/shared/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Subscription } from "rxjs"

@Component({
    selector: "usuario-showcasebox",
    templateUrl: "usuario_showcasebox.component.html",
    styleUrls: ["usuario_showcasebox.component.scss"]
})
export class UsuarioShowcaseBoxComponent implements OnDestroy {
    usuario_nombre: string
    usuario_apellido_paterno: string
    usuario_id: string
    usuario_avatar: string
    usuario_email: string
    userUpdateSubscription: Subscription
    @Input() showEstablecimiento: boolean
    establecimientos: Establecimiento[] = []
    @Input() showUserDetails = true
    @Input() showUserID = false
    @HostBinding("class.showcasebox_style_user") useShowcaseStyle = true

    user_details: any

    @HostBinding("class") innerClass = "showcasebox_style_2 showcasebox_style_user"

    constructor(
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected router: Router,
        protected tabService: TabService,
        protected establecimentosService: Establecimientos
    ) {
        const usuario = this.authService.getUserData()
        this.usuario_id = usuario.id ? usuario.id + "" : "0"
        this.usuario_nombre = usuario.nombre ? usuario.nombre + "" : null
        this.usuario_apellido_paterno = usuario.apellido_paterno ? usuario.apellido_paterno + "" : null
        this.usuario_avatar = usuario.avatar ? usuario.avatar + "" : null
        this.usuario_email = usuario.email ? usuario.email + "" : null

        this.userUpdateSubscription = this.sessionService.userDataUpdated.subscribe(usuario => {
            this.updateUserData(usuario)
        })
    }

    ngOnInit() {
        if (this.showEstablecimiento) {
            this.establecimentosService
                .where({ establecimiento: { id: this.authService.getEstablecimientos() } })
                .then(establecimientos => {
                    this.establecimientos = establecimientos
                })
        }
    }

    updateUserData(usuario) {
        this.usuario_id = usuario.id ? usuario.id + "" : "0"
        this.usuario_nombre = usuario.nombre ? usuario.nombre + "" : null
        this.usuario_apellido_paterno = usuario.apellido_paterno ? usuario.apellido_paterno + "" : null
        this.usuario_avatar = usuario.avatar ? usuario.avatar + "" : null
    }

    goToProfile() {
        if (this.usuario_id != null) {
            this.tabService.setComponentTab("UsuarioComponent", "perfil")
            this.router.navigate(["/usuarios/" + this.usuario_id])
        }
    }

    ngOnDestroy() {
        this.userUpdateSubscription.unsubscribe()
    }
}
