import { BaseModel, SaveType } from "@puntaje/shared/core"
import { LugarTipo } from "./lugar_tipos.model"

export class Lugar extends BaseModel {
    public nombre: string
    public lugar_tipo_id: number
    public lugar_id: number
    public pais_id: number

    @SaveType(() => Lugar) lugar: Lugar
    @SaveType(() => LugarTipo) lugar_tipo: LugarTipo

    public toString() {
        if (this.nombre) {
            return this.nombre
        } else {
            return ""
        }
    }
}
