import { Component, ViewChild, EventEmitter, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { AuthService } from "@puntaje/shared/core"
import { Evaluacion, Evaluaciones } from "@puntaje/puntaje/api-services"
import { State, selectEvaluacionTipoAliases, selectSelectedEvaluacionTipo } from "@puntaje/puntaje/store"
import { select, Store } from "@ngrx/store"
import { filter } from "rxjs/operators"
import { combineLatest } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "diagnostico.component.html"
})
export class DiagnosticoComponent implements OnInit {
    asignaturaId: number
    generadorInstrumentoId: number
    evaluacion: Evaluacion
    showPreguntasMenu: boolean = false
    tipoEvaluacion: string
    tipoEvaluacionFromEvaluacion: string
    nombreEvaluacion: string
    sub: Subscription
    onSelectPregunta: EventEmitter<any> = new EventEmitter<any>()
    enableDiagnostico: boolean = false
    subData: Subscription
    rutaRedirect: string = "/planes_personales"
    redirectToPlan = false

    evaluacionTipoAlias: string
    evaluacionTipoAliases: any
    subAlias: Subscription

    generarDesdeEvaluacion: boolean = false

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected authService: AuthService,
        protected evaluacionesService: Evaluaciones,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.diagnostico")
        this.sub = this.route.queryParams.subscribe(params => {
            this.asignaturaId = +params["asignatura_id"]
            this.generadorInstrumentoId = +params["generador_instrumento_id"]
            this.tipoEvaluacion = params["tipo_evaluacion"] || config.plataforma.evaluacionDefault //Cambiar por diagnostico
            this.enableDiagnostico = true
            this.generarDesdeEvaluacion = params["inicial"] === "1" && config.plataforma.generarDiagnosticoDesdeEvaluacionGenerador === true
        })
        this.subData = this.route.data.subscribe(data => {
            this.rutaRedirect = data.rutaRedirect ?? this.rutaRedirect
            this.redirectToPlan = data.redirectToPlan ?? this.redirectToPlan
        })
        this.subAlias = this.evaluacionTipoAliases$.subscribe(evaluacionTipoAliases => {
            this.evaluacionTipoAliases = evaluacionTipoAliases
            if (this.tipoEvaluacionFromEvaluacion)
                this.evaluacionTipoAlias = evaluacionTipoAliases[this.tipoEvaluacionFromEvaluacion]
        })
    }

    onReadyEvaluacion(evaluacion) {
        this.evaluacion = evaluacion
        this.tipoEvaluacionFromEvaluacion = evaluacion.evaluacion_tipo.evaluacion_tipo
        if (this.evaluacionTipoAliases)
            this.evaluacionTipoAlias = this.evaluacionTipoAliases[this.tipoEvaluacionFromEvaluacion]
    }

    onShowPreguntasMenuChange(showPreguntasMenu) {
        this.showPreguntasMenu = showPreguntasMenu
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }
}
