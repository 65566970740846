import { BaseModel, SaveType } from "@puntaje/shared/core"

export class EstablecimientoBrasil extends BaseModel {
    public inep_mec: string
    public establecimiento_id: number

    getFieldName() {
        return "INEP/MEC"
    }

    getFieldValue() {
        return this.inep_mec
    }
}
