import { BaseModel, SaveType } from "@puntaje/shared/core"
import { PlanEstudio } from "./plan_estudios.model"
import { PlanEstudioLote } from "./plan_estudio_lotes.model"
import { PlanEstudioMaterial } from "./plan_estudio_materiales.model"
import { PlanEstudioSesiones } from "./plan_estudio_sesiones.service"

export class PlanEstudioSesion extends BaseModel {
    public static className: string = "PlanEstudioSesion"
    public id: number
    public sesion: string
    public descripcion: string
    public estado: number
    public orden: number
    public plan_estudio_id: number
    public componenteCount: number = -1
    public plan_estudio_lotes_deleted: PlanEstudioLote[] = []
    public plan_estudio_materiales_deleted: PlanEstudioMaterial[] = []
    public cantidad_contenidos: number

    @SaveType(() => PlanEstudio) public plan_estudio: PlanEstudio
    @SaveType(() => PlanEstudioLote) public plan_estudio_lotes: PlanEstudioLote[]
    @SaveType(() => PlanEstudioMaterial) public plan_estudio_materiales: PlanEstudioMaterial[]

    constructor() {
        super()
        this.plan_estudio = new PlanEstudio()
        this.plan_estudio_lotes = []
        this.plan_estudio_materiales = []
    }

    public static get serviceClass() {
        return PlanEstudioSesiones
    }

    public agregarSesionMaterial() {
        if (this.componenteCount == -1) {
            this.componenteCount = this.plan_estudio_materiales.length + this.plan_estudio_lotes.length // Max orden
        }
        var material = new PlanEstudioMaterial()
        material.orden = this.componenteCount + 1
        material.plan_estudio_id = this.plan_estudio_id
        material.plan_estudio_sesion_id = this.id
        this.plan_estudio_materiales.push(material)
        this.componenteCount = material.orden
        return material
    }

    public removerSesionMaterial(orden: number) {
        var elem = this.plan_estudio_materiales.find(s => s.orden == orden)
        elem["_destroy"] = true
        this.plan_estudio_materiales_deleted.push(elem)
        let sin = this.plan_estudio_materiales.filter(s => s.orden != orden)
        this.plan_estudio_materiales = sin
        this.plan_estudio_materiales.map(material => {
            if (material.orden > orden) {
                material.orden = material.orden - 1
            }
        })
        this.plan_estudio_lotes.map(lote => {
            if (lote.orden > orden) {
                lote.orden = lote.orden - 1
            }
        })
        this.componenteCount = this.componenteCount - 1
    }

    public agregarSesionLote() {
        if (this.componenteCount == -1) {
            this.componenteCount = this.plan_estudio_materiales.length + this.plan_estudio_lotes.length // Max orden
        }
        var lote = new PlanEstudioLote()
        lote.orden = this.componenteCount + 1
        lote.plan_estudio_id = this.plan_estudio_id
        lote.plan_estudio_sesion_id = this.id
        this.plan_estudio_lotes.push(lote)
        this.componenteCount = lote.orden
        return lote
    }

    public removerSesionLote(orden: number) {
        var elem = this.plan_estudio_lotes.find(s => s.orden == orden)
        elem["_destroy"] = true
        this.plan_estudio_lotes_deleted.push(elem)
        let sin = this.plan_estudio_lotes.filter(s => s.orden != orden)
        this.plan_estudio_lotes = sin
        this.plan_estudio_lotes.map(lote => {
            if (lote.orden > orden) {
                lote.orden = lote.orden - 1
            }
        })
        this.plan_estudio_materiales.map(material => {
            if (material.orden > orden) {
                material.orden = material.orden - 1
            }
        })
        this.componenteCount = this.componenteCount - 1
    }

    public toString() {
        return this.sesion
    }
}
