import { Component, ViewChild, ChangeDetectorRef, ElementRef, OnInit, AfterViewInit } from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { View } from "./view.component"

@Component({
    selector: "docsview",
    template: `
        <ng-template #viewLabel></ng-template>
        <ng-template #viewValue>
            <div #docsViewer (window:resize)="onResize($event)">
                <ngx-doc-viewer
                    queryParams="hl=Nl"
                    [url]="url"
                    [viewer]="viewer"
                    style="width:100%;height:50vh;overflow: scroll;"
                ></ngx-doc-viewer>
            </div>
        </ng-template>
    `,
    styleUrls: ["model_simple_view.component.scss"]
})
export class DocsView extends View {
    width: number = 640
    height: number = 480
    max_width: number = 640
    max_height: number = 480
    url: string
    safeUrl: SafeResourceUrl
    urlReady: boolean = false
    @ViewChild("docsViewer") docsViewer: ElementRef
    viewer: "office" | "mammoth" = "office"

    constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {
        super()
    }

    ngOnInit() {
        if (this.key != "url" && this.key != "alternativa") {
            this.viewer = "mammoth"
        }
        this.url = this.value[this.key]

        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
        this.urlReady = true
        this.setRatioSize()
    }

    onResize(event) {
        this.setRatioSize()
    }

    setRatioSize() {
        if (this.docsViewer) {
            if (this.docsViewer.nativeElement.offsetWidth > this.max_width) {
                this.width = this.max_width
            } else {
                this.width = this.docsViewer.nativeElement.offsetWidth
            }
        }
        this.height = (this.width * this.max_height) / this.max_width
    }
}
