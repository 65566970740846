import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core"
import { Comentario, Comentarios, UsuarioBaneado, UsuarioBaneados } from "@puntaje/puntaje/api-services"
import { DomSanitizer, SafeHtml } from "@angular/platform-browser"
import { SessionService } from "@puntaje/shared/core"

@Component({
    selector: "comentario",
    templateUrl: "comentario.component.html",
    styleUrls: ["comentario.component.scss"]
})
export class ComentarioComponent implements OnInit {
    @Input() comentario: Comentario
    @Output() editDone: EventEmitter<boolean> = new EventEmitter<boolean>()
    comentarioParsed: SafeHtml = ""
    fecha: any
    fecha_editado: any
    isAdmin = false
    isEdited = false

    _enableEditar = false
    canEdit = false

    constructor(
        private domSanitizer: DomSanitizer,
        private sessionService: SessionService,
        private comentariosService: Comentarios,
        protected usuarioBaneadosService: UsuarioBaneados
    ) {}

    ngOnInit() {
        if (this.comentario) {
            this.comentarioParsed = this.sanitize(this.comentario.comentario)
            this.fecha = this.comentario.created_at
            this.fecha_editado = this.comentario.updated_at
            this.setEditPerms()
            this.setBadges()
            this.checkAdmin()
        }
    }

    sanitize(value: string): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(value)
    }

    sanitizeUrl(input: string) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(input)
    }

    enableEditar() {
        this._enableEditar = true
    }

    setEditPerms() {
        this.canEdit = this.sessionService.getUserData().id === this.comentario.usuario_id
    }

    onSaveDoneListener(value: any) {
        this.editDone.emit(true)
    }

    onCancelListener(value: any) {
        this._enableEditar = false
    }

    setBadges() {
        if (this.comentario.created_at != this.comentario.updated_at) {
            this.isEdited = true
        }
    }

    checkAdmin() {
        const some_admin = this.sessionService.getRoles().find(r => {
            return r == "SuperAdministrador" || r == "Administrador" || r == "Monitor"
        })
        if (some_admin) {
            this.isAdmin = true
        }
    }

    banHammer() {
        this.comentario.ban = true
        this.comentariosService.update(this.comentario.id, this.comentario).then(resp => {})
    }

    banUser(usuarioId) {
        const now = new Date()

        const usuarioBaneado = new UsuarioBaneado()
        usuarioBaneado.usuario_id = usuarioId
        usuarioBaneado.fecha_inicio = new Date()
        usuarioBaneado.fecha_termino = new Date()
        usuarioBaneado.fecha_termino.setDate(now.getDate() + 7)

        this.usuarioBaneadosService.save(usuarioBaneado).then((usuarioBaneado: UsuarioBaneado) => {})
    }
}
