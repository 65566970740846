import { Component, OnInit, ViewChild, Input, ElementRef, SimpleChanges } from "@angular/core"
import { PlanPersonales, PlanPersonal, GrupoUsuarioUsuarios } from "@puntaje/puntaje/api-services"
import { GeneradorInstrumentos, Asignatura, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { AuthService, I18nService } from "@puntaje/shared/core"
import { Store, select } from "@ngrx/store"
import { Router } from "@angular/router"
import { BehaviorSubject, Subscription } from "rxjs"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "plan-clases-alumnos",
    templateUrl: "./plan-clases-alumnos.component.html",
    styleUrls: ["./plan-clases-alumnos.component.scss"]
})
export class PlanClasesAlumnosComponent implements OnInit {
    planificaciones: PlanPersonal[] = []
    planificacionesByAsignatura: { [key: number]: PlanPersonal[] }
    shownPlanificaciones: PlanPersonal[] = []
    evaluacionTipoSubject = new BehaviorSubject(null)
    sub: Subscription
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )
    asignaturas: Asignatura[]
    showAll: boolean = true
    alumnoLibreSinCursos: boolean = false

    @ViewChild("misPlanes", { static: true }) misPlanes: ElementRef
    colNumber1: boolean = false
    colNumber2: boolean = false
    colNumber3: boolean = false
    colNumber4: boolean = false
    anoSeleccionado: number
    planesAnos: number[] = []


    @Input() planClaseRoute: string = "/plan_estudios"
    @Input() msjNoPlanes: string
    @Input() evaluacionTipo: string = "curricular"
    @Input() hideTitle: boolean = false
    @Input() titulo: string
    @Input() ignoreNiveles: boolean = false // de alumnos
    @Input() generadorInstrumentos: GeneradorInstrumento[]
    @Input() showMisPlanes = false
    @Input() showPlanesPredefinidos = false
    @Input() verSinCompartir: boolean = false
    @Input() showFromProfesor: boolean = false //busca por niveles del profesor
    @Input() ocultarSinPlanes: boolean = false
    @Input() verificarFechaInicial: boolean = false
    @Input() showAsignatura: boolean = true
    @Input() showYearFilter = false

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected planificacionesService: PlanPersonales,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected authService: AuthService,
        protected i18nService: I18nService,
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios,
        protected store: Store<State>,
        protected router: Router
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes["generadorInstrumentos"] &&
            !changes["generadorInstrumentos"].firstChange &&
            this.generadorInstrumentos
        ) {
            this.loadInstancias()
        }
    }

    ngOnInit() {
        this.msjNoPlanes = this.msjNoPlanes || this.i18nService.translate("plan_clase_alumno.not_found")
        this.titulo = this.titulo || this.i18nService.translate("plan_clase_alumno.title")

        if (this.showMisPlanes || this.showPlanesPredefinidos) {
            this.showAll = false
        }

        if (this.evaluacionTipo) {
            if (!this.generadorInstrumentos) {
                this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
                    this.asignaturas = asignaturasByEvaluacionTipo[this.evaluacionTipo]
                    const params = {
                        asignatura_plataforma: {
                            asignatura_id: (this.asignaturas || []).map(a => a.id),
                            plataforma_id: config.plataforma.id
                        },
                        tipo_instrumento: {
                            tipo_instrumento: this.evaluacionTipo
                        },
                        visible: true
                    }
                    this.generadorInstrumentosService
                        .where(params)
                        .then((generadorInstrumentos: GeneradorInstrumento[]) => {
                            this.generadorInstrumentos = generadorInstrumentos
                            this.loadInstancias()
                        })
                })
            } else {
                this.loadInstancias()
            }
        }
    }

    async loadInstancias() {
        this.loadingLayout.standby()

        const params = {
            order: "desc",
            sort_by: "created_at",
            plan_personal: {
                generador_instrumento_id: this.generadorInstrumentos.map(g => g.id)
            },
            render_options: {
                include: {
                    usuario: null,
                    generador_instrumento: null
                },
                methods: ["total_ciclos", "total_sesiones"]
            }
        }
        if (!this.ignoreNiveles) {
            params["mis_niveles_alumno"] = 1
        }

        if (this.verificarFechaInicial) {
            params["fecha_inicial"] = 1
        }

        if (this.showFromProfesor) {
            params["mis_niveles_profesor"] = 1
        }

        let planClases = [],
            planClasesProfesor = []

        if (this.showAll || this.showPlanesPredefinidos) {
            planClases = await this.getPlanificaciones({
                ...params,
                predefinidos: 1,
                plan_personal_tipo: {
                    tipo: ["plan de clase", "plan de clase alumno"]
                }
            })
        }
        if (this.showAll || this.showMisPlanes) {
            planClasesProfesor = await this.getPlanificaciones({
                ...params,
                compartidos_conmigo: 1,
                plan_personal_tipo: {
                    tipo: "plan de clase profesor"
                }
            })
        }
        
        this.planificaciones = [...planClases, ...planClasesProfesor]
            .sort((p1, p2) => new Date(p2.created_at).getTime() - new Date(p1.created_at).getTime())
            .filter(planificacion => {
                const fecha_inicial = new Date(planificacion.fecha_inicial)
                this.planesAnos.push(fecha_inicial.getFullYear())
                return new Date() > fecha_inicial
            })
        this.planesAnos = [...new Set(this.planesAnos)]
        this.shownPlanificaciones = this.planificaciones

        if (this.ocultarSinPlanes && !this.planificaciones.length) {
            const grupoUsuarioUsuarios = await this.grupoUsuarioUsuariosService.where({
                grupo_usuario_usuario: { usuario_id: this.authService.getUserData().id }
            })

            this.alumnoLibreSinCursos = grupoUsuarioUsuarios.length == 0
        }

        this.loadingLayout.ready()
    }

    getPlanificaciones = async params => {
        const response = await this.planificacionesService.where(params)

        return response
    }

    checkSize() {
        let width = this.misPlanes.nativeElement.offsetWidth
        if (width > 1100) {
            this.colNumber1 = false
            this.colNumber2 = false
            this.colNumber3 = false
            this.colNumber4 = true
        } else if (width > 700 && width <= 1100) {
            this.colNumber1 = false
            this.colNumber2 = false
            this.colNumber3 = true
        } else if (width > 500 && width <= 700) {
            this.colNumber1 = false
            this.colNumber2 = true
        } else {
            this.colNumber1 = true
        }
    }

    anoChanged(event) {
        this.anoSeleccionado = event
        if(!this.anoSeleccionado) {
            this.shownPlanificaciones = this.planificaciones
            return
        }

        this.shownPlanificaciones = this.planificaciones.filter(planificacion => {
            const fecha_inicial = new Date(planificacion.fecha_inicial)
            return this.anoSeleccionado === fecha_inicial.getFullYear()
        })
    }
}
