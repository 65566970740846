<loading-layout #loadingLayout [opaque]="true">
    <ng-template [ngIf]="carreraSede">
        <div class="flex-wrap">
            <carreras-list-item
                *ngIf="carreraSede"
                class="carrera"
                [carreraSede]="carreraSede"
                [showPonderaciones]="false"
                [showPosition]="false"
            ></carreras-list-item>
            <div class="flex-wrap-follow">
                <seguir-carrera
                    [carreraSede]="carreraSede"
                    class="flex-item"
                    (followEvent)="updateCarreraSede($event)"
                ></seguir-carrera>
                <div *ngIf="carreraSede && following" class="btn-bar flex-item">
                    {{ "orientacion.carreras.carrera.preferencia" | t }}
                    <ng-template [ngIf]="!editingOrden">
                        <cui-button class="btn_style btn_base_1 btn-preferencia" (click)="startEditOrden()">
                            {{ orden }}
                            <fa class="icono" name="pencil"></fa>
                        </cui-button>
                    </ng-template>
                    <ng-template [ngIf]="editingOrden">
                        <select [(ngModel)]="orden">
                            <option
                                *ngFor="let preferencia of preferenciaCarreraSedes"
                                [ngValue]="preferencia.orden"
                                [attr.selected]="preferencia.orden == orden"
                            >
                                {{ preferencia.orden }}
                            </option>
                        </select>
                        <cui-button class="btn_style btn_base_1" (click)="updateOrden()">
                            {{ "orientacion.carreras.carrera.guardar" | t }}
                        </cui-button>
                    </ng-template>
                </div>

                <cui-button
                    *ngIf="!informacionSolicitada && enableSolicitarInformacion"
                    (click)="solicitarInformacion()"
                    class="btn_style btn_base_1"
                >
                    <fa class="icono" name="envelope"></fa>
                    {{ "orientacion.carreras.carrera.solicitar_informacion" | t }}
                </cui-button>
                <p *ngIf="informacionSolicitada && enableSolicitarInformacion" class="alert alert-success">
                    {{ "orientacion.carreras.carrera.informacion_solicitada" | t }}
                </p>
            </div>
        </div>
        <ng-container
            *ngIf="
                carreraSede.sede.universidad.anuncioImgNarrow &&
                carreraSede.sede.universidad.anuncioImgWide &&
                carreraSede.sede.universidad.anuncioLink
            "
        >
            <br />
            <div class="promo-universidad-container">
                <a [href]="carreraSede.sede.universidad.anuncioLink" target="_blank">
                    <img class="desktop" [src]="carreraSede.sede.universidad.anuncioImgWide" />
                    <img class="mobile" [src]="carreraSede.sede.universidad.anuncioImgNarrow" />
                </a>
            </div>
        </ng-container>
        <br />
        <ng-container *ngIf="!disablePonderaciones && carreraSede.ponderaciones.length !== 0">
            <cui-section-headline>{{ "orientacion.carreras.carrera.ponderaciones" | t }}</cui-section-headline>

            <ponderaciones-bar-divider [carreraSede]="carreraSede" [visibleLabels]="true"></ponderaciones-bar-divider>

            <br />
        </ng-container>
        <ng-container *ngIf="video">
            <cui-section-headline>{{ "orientacion.carreras.carrera.video" | t }}</cui-section-headline>
            <audiovisual-preview [audiovisual]="video"></audiovisual-preview>
            <br />
        </ng-container>
        <ng-container *ngIf="malla">
            <cui-section-headline>{{ "orientacion.carreras.carrera.malla_curricular" | t }}</cui-section-headline>
            <audiovisual-preview [audiovisual]="malla"></audiovisual-preview>
            <br />
        </ng-container>
        <ng-container *ngIf="carreraSede.modalidad || carreraSede.area">
            <cui-section-headline>{{ "orientacion.carreras.carrera.detalles" | t }}</cui-section-headline>
            <table class="table">
                <tbody>
                    <tr *ngIf="carreraSede.modalidad">
                        <td>{{ "orientacion.carreras.carrera.modalidad" | t }}</td>
                        <td>{{ carreraSede.modalidad }}</td>
                    </tr>
                    <tr *ngIf="carreraSede.area">
                        <td>{{ "orientacion.carreras.carrera.area" | t }}</td>
                        <td>{{ carreraSede.area }}</td>
                    </tr>
                </tbody>
            </table>
            <br />
        </ng-container>
        <ng-container *ngIf="carreraSede.infoadicionales.length > 0">
            <cui-section-headline>{{ "orientacion.carreras.carrera.informacion_adicional" | t }}</cui-section-headline>
            <table class="table">
                <tbody>
                    <ng-container *ngFor="let info of carreraSede.infoadicionales">
                        <tr>
                            <td>{{ info.infoAdicional }}</td>
                            <td *ngIf="info.isLink">
                                <a href="{{ info.valor }}">{{ info.valor }}</a>
                            </td>
                            <td *ngIf="!info.isLink">{{ info.valor }}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <br />
        </ng-container>
        <ng-container *ngIf="latitude && longitude && showMap">
            <cui-section-headline>{{ "orientacion.carreras.carrera.ubicacion" | t }}</cui-section-headline>
            <agm-map
                *ngIf="showMap"
                class="sede-map"
                [zoom]="16"
                [latitude]="latitude"
                [longitude]="longitude"
                [mapTypeId]="mapType"
            >
                <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
            </agm-map>
            <br />
        </ng-container>
        <ng-container *ngFor="let carreraSedeCampoExtra of carreraSede.carreraSedeCampoExtras">
            <cui-section-headline>{{ carreraSedeCampoExtra.campoExtra.campoExtra }}</cui-section-headline>
            <div [innerHTML]="carreraSedeCampoExtra.campoExtra.contenido"></div>
            <br />
        </ng-container>
    </ng-template>
</loading-layout>
