import { Component, ViewChild, EventEmitter, HostListener } from "@angular/core"
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router"
import { PreguntasEnsayoComponent } from "@puntaje/puntaje/new-modules/ensayos"
import { AuthService } from "@puntaje/shared/core"
import { Evaluacion, Evaluaciones, EvaluacionTiempos } from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"
import { RealizarEnsayoService } from "@puntaje/puntaje/new-modules/ensayos"
import { AppConfig } from "@puntaje/shared/core"
import { EvaluationLayoutService } from "@puntaje/shared/cui"
import { Store, select } from "@ngrx/store"
import { State, selectEvaluacionTipoAliases } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "realizar_ensayo.component.html",
    styleUrls: ["realizar_ensayo.component.scss"]
})
export class RealizarEnsayoComponent {
    evaluacion: Evaluacion
    evaluacionId: number
    showPreguntasMenu: boolean = false
    loadClock: boolean = false
    enableSecuencial = false

    tipoEvaluacion: string
    tipoEvaluacionFromEvaluacion: string

    sub: Subscription
    subQuery: Subscription
    subData: Subscription
    onSelectPregunta: EventEmitter<any> = new EventEmitter<any>()
    readyEvaluacionSub: Subscription
    showPreguntasMenuChangeSub: Subscription
    loadClockChangeSub: Subscription
    subAlias: Subscription

    enableSmowlFrame = false
    theme: string
    enableBtnMarkAsUncertain = false
    enablePreguntasId = true
    enableDudasBtn = false
    textoCompletar: string
    isFromPlanPersonal = false
    enableEntregarGeneric = false
    nombreEvaluacion: string
    disableWarningOmitidas = false
    disableOmitida = false
    unpermitted = false

    tipoInstrumentoAlias: string
    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )
    evaluacionTipoAliases: any
    checkingEvaluacion: boolean = true
    evaluacionNoDisponible: boolean = false
    errorEvalNoDisponible: any = null

    @ViewChild("preguntasEnsayo", { static: true }) preguntasEnsayo: PreguntasEnsayoComponent

    @HostListener("document:keydown", ["$event"])
    public onKeyDown(event: any): void {
        if ((event.ctrlKey || event.metaKey) && event.code == "KeyF") {
            event.preventDefault()
        }
    }

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected authService: AuthService,
        protected store: Store<State>,
        protected realizarEnsayoService: RealizarEnsayoService,
        protected router: Router,
        protected evaluationLayoutService: EvaluationLayoutService,
        protected evaluacionesService: Evaluaciones
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.realizar_ensayo")
        this.sub = this.route.params.subscribe(params => {
            this.evaluacionId = +params["id"]
            this.unpermitted = false

            // TODO: reconsiderar lógica que está dentro de cada componente
            // (preguntas-ensayo y evaluacion-secuencial respectivamente) que va a buscar la evaluación
            this.checkingEvaluacion = true
            this.evaluacionesService
                .find(this.evaluacionId, { raw: 1, include: "[instrumento]" })
                .then(evaluacion => {
                    this.checkingEvaluacion = false

                    if (evaluacion.isAvailable()) {
                        this.enableSecuencial = this.enableSecuencial && !evaluacion.instrumento.propio
                        this.evaluationLayoutService.setOptions({ singleColumn: this.enableSecuencial })
                    } else {
                        this.errorEvalNoDisponible = {
                            fechaInicio: evaluacion.fecha_inicio,
                            fechaTermino: evaluacion.fecha_termino
                        }
                        this.evaluacionNoDisponible = true
                    }
                })
                .catch(e => {
                    if (e.status == 403) {
                        this.unpermitted = true
                    } else {
                        this.checkingEvaluacion = false
                    }
                })
        })

        this.subAlias = this.evaluacionTipoAliases$.subscribe(evaluacionTipoAliases => {
            this.evaluacionTipoAliases = evaluacionTipoAliases
            this.tipoInstrumentoAlias = evaluacionTipoAliases[this.tipoEvaluacion]
        })

        this.subQuery = this.route.queryParams.subscribe(params => {
            this.tipoEvaluacion = params["tipo_evaluacion"] || config.plataforma.evaluacionDefault
            this.tipoInstrumentoAlias = this.evaluacionTipoAliases[this.tipoEvaluacion]

            this.realizarEnsayoService.withoutMultiple = !!params["without_multiple"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.enableSmowlFrame = data.enableSmowlFrame ?? this.enableSmowlFrame
            this.theme = data.theme ?? this.theme
            this.enableBtnMarkAsUncertain = data.enableBtnMarkAsUncertain ?? this.enableBtnMarkAsUncertain
            this.enablePreguntasId = data.enablePreguntasId ?? this.enablePreguntasId
            this.enableDudasBtn = data.enableDudasBtn ?? this.enableDudasBtn
            this.textoCompletar = data.textoCompletar ?? this.textoCompletar
            this.isFromPlanPersonal = data.isFromPlanPersonal ?? this.isFromPlanPersonal
            this.enableEntregarGeneric = data.enableEntregarGeneric ?? this.enableEntregarGeneric
            this.nombreEvaluacion = data.nombreEvaluacion ?? this.nombreEvaluacion
            this.disableWarningOmitidas = data.disableWarningOmitidas ?? this.disableWarningOmitidas
            this.disableOmitida = data.disableOmitida ?? this.disableOmitida
        })

        this.readyEvaluacionSub = this.realizarEnsayoService.readyEvaluacion.subscribe(
            this.onReadyEvaluacion.bind(this)
        )
        this.showPreguntasMenuChangeSub = this.realizarEnsayoService.ready.subscribe(
            this.onShowPreguntasMenuChange.bind(this)
        )

        this.enableSecuencial =
            config.plataforma.evaluacionResponder == "secuencial" || this.tipoEvaluacion == "simulacro saber11"

        this.evaluationLayoutService.setOptions({ singleColumn: this.enableSecuencial })
    }

    onReadyEvaluacion(evaluacion) {
        this.evaluacion = evaluacion
        this.tipoEvaluacionFromEvaluacion = evaluacion.evaluacion_tipo.evaluacion_tipo
        this.tipoEvaluacion = this.tipoEvaluacionFromEvaluacion

        const params: NavigationExtras = {
            queryParams: { tipo_evaluacion: this.tipoEvaluacion },
            replaceUrl: true,
            queryParamsHandling: "merge"
        }

        const configEvalTipo = config.evaluaciones[this.tipoEvaluacion]
        const baseUrlEvalTipo = configEvalTipo && configEvalTipo.baseUrl ? configEvalTipo.baseUrl : "ensayos"

        this.router.navigate([], params)
    }

    onEvaluationSent(ei) {
        this.router.navigate(["/ensayo_instancias", ei.id])
    }

    onShowPreguntasMenuChange(showPreguntasMenu) {
        this.showPreguntasMenu = showPreguntasMenu
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subQuery.unsubscribe()
        this.subData.unsubscribe()
        this.readyEvaluacionSub.unsubscribe()
        this.showPreguntasMenuChangeSub.unsubscribe()

        this.realizarEnsayoService.withoutMultiple = false
    }
}
