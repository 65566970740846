<div
    *ngIf="
        !onlyLetras &&
        (showOnly == 'todas' ||
            (showOnly == 'correctas' && isCorrect) ||
            (showOnly == 'omitidas' && isOmitted) ||
            (showOnly == 'incorrectas' && !isOmitted && !isCorrect))
    "
    [class.duda]="enableDuda"
>
    <p class="duda-info" *ngIf="enableDuda">
        <span class="glyphicon glyphicon-question-sign res-icon"></span>
        {{ "progreso.historial.respuestas.indicaste_dudas" | t }}
    </p>
    <grupo-pregunta
        *ngIf="pregunta && grupoPreguntaId && (!grupoPreguntaPrev || grupoPreguntaId != grupoPreguntaPrev.id)"
        [grupoPregunta]="pregunta.grupo_pregunta"
        [preguntaIndex]="index"
        [enableGrupoPreguntaId]="false"
        [enableTextHighlight]="false"
        role="region"
        [attr.aria-label]="'Texto de la pregunta número ' + (index + 1) + ' en adelante'"
    ></grupo-pregunta>
    <span class="sr-only" *ngIf="!grupoPreguntaId">{{ "progreso.historial.respuesta.sin_texto_asociado" | t }}.</span>

    <div *ngIf="isPiloto" class="piloto-flag">
        <button
            class="piloto-info-link"
            [popover]="'progreso.historial.preguntas_piloto_mensaje' | t"
            placement="left"
            [outsideClick]="true"
        >
            {{ "progreso.historial.respuestas.preguntas_piloto" | t }}
        </button>
        <div *ngIf="showPilotajeInfo">
            <div class="pilotaje-info">
                {{ "progreso.historial.respuestas.preguntas_piloto_mensaje" | t }}
            </div>
        </div>
    </div>
    <div
        class="respuesta_ensayo"
        *ngIf="pregunta"
        [class.piloto]="isPiloto"
        role="region"
        [attr.aria-label]="'Pregunta número ' + (index + 1)"
    >
        <div class="numero_pregunta numero_pregunta_style">{{ index + 1 }}</div>
        <div class="pregunta_contenido">
            <div class="resp-id">#{{ pregunta.id }}</div>

            <ng-container *ngIf="!disableCorreccion">
                <div *ngIf="isOmitted" class="resp-omitida-circle">
                    <span class="glyphicon glyphicon glyphicon-ban-circle">
                        <span class="sr-only">{{ "progreso.historial.respuesta.omitida" | t }}</span>
                    </span>
                </div>
                <div *ngIf="isCorrect" class="resp-correcta-circle">
                    <span class="glyphicon glyphicon glyphicon-ok-circle">
                        <span class="sr-only">{{ "progreso.historial.respuesta.correcta" | t }}</span>
                    </span>
                </div>
                <div *ngIf="!isOmitted && !isCorrect" class="resp-incorrecta-circle">
                    <span class="glyphicon glyphicon glyphicon-remove-circle">
                        <span class="sr-only">{{ "progreso.historial.respuesta.incorrecta" | t }}</span>
                    </span>
                </div>
            </ng-container>

            <ng-container *ngFor="let contenido of contenidos; let contenidoIndex = index">
                <ng-container [ngSwitch]="$any(contenido).constructor.className">
                    <ng-container *ngSwitchCase="'Taxativo'">
                        <pregunta-taxativo
                            class="pregunta-taxativo"
                            [taxativo]="$any(contenido).taxativo"
                            [grupoPreguntaId]="pregunta.grupo_pregunta_id"
                            [makeFirstChildInline]="
                                contenidos[contenidoIndex - 1] &&
                                contenidos[contenidoIndex - 1]['contestable_tipo']?.contestable_tipo ==
                                    contestableTipo.completable
                            "
                            [makeLastChildInline]="
                                contenidos[contenidoIndex + 1] &&
                                contenidos[contenidoIndex + 1]['contestable_tipo']?.contestable_tipo ==
                                    contestableTipo.completable
                            "
                        ></pregunta-taxativo>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Contestable'">
                        <ng-container [ngSwitch]="$any(contenido).contestable_tipo?.contestable_tipo">
                            <ng-container *ngSwitchCase="contestableTipo.spr">
                                <div>
                                    <respuesta-spr
                                        [alternativas]="$any(contenido).alternativas"
                                        [respuestaId]="
                                            respuestas &&
                                            respuestas[contenidoIndexByContenidoIndex[contenidoIndex]].alternativa_id
                                        "
                                    ></respuesta-spr>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="contestableTipo.libre">
                                <div>
                                    <respuesta-libre
                                        [alternativas]="$any(contenido).alternativas"
                                        [respuestaId]="
                                            respuestas &&
                                            respuestas[contenidoIndexByContenidoIndex[contenidoIndex]].alternativa_id
                                        "
                                    ></respuesta-libre>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="contestableTipo.completable">
                                <respuesta-completable
                                    [alternativas]="$any(contenido).alternativas"
                                    [respuestaId]="
                                        respuestas &&
                                        respuestas[contenidoIndexByContenidoIndex[contenidoIndex]].alternativa_id
                                    "
                                ></respuesta-completable>
                            </ng-container>
                            <ng-container *ngSwitchCase="contestableTipo.imagen">
                                <respuesta-imagen
                                    [alternativas]="$any(contenido).alternativas"
                                    [respuestaId]="
                                        respuestas &&
                                        respuestas[contenidoIndexByContenidoIndex[contenidoIndex]].alternativa_id
                                    "
                                ></respuesta-imagen>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <div class="prueba-alternativas">
                                    <div class="form-group">
                                        <ul class="alternativas-list">
                                            <li
                                                *ngFor="
                                                    let alternativa of $any(contenido).getAlternativas(
                                                        generadorInstrumento
                                                    );
                                                    let i = index
                                                "
                                                [class.alternativa-correcta]="
                                                    !disableCorreccion && alternativa.correcta
                                                "
                                                [class.respuesta-correcta]="
                                                    !disableCorreccion &&
                                                    respuestas &&
                                                    alternativa.id ==
                                                        respuestas[contenidoIndexByContenidoIndex[contenidoIndex]]
                                                            .alternativa_id &&
                                                    alternativa.correcta
                                                "
                                            >
                                                <div
                                                    *ngIf="!disableCorreccion && alternativa.correcta"
                                                    class="alternativa-correcta-icon"
                                                ></div>
                                                <div class="alternativa_container">
                                                    <div class="letra">
                                                        <div
                                                            *ngIf="
                                                                respuestas &&
                                                                alternativa.id ==
                                                                    respuestas[
                                                                        contenidoIndexByContenidoIndex[contenidoIndex]
                                                                    ].alternativa_id &&
                                                                alternativa.correcta
                                                            "
                                                            class="resp-correcta resp-arrow"
                                                        >
                                                            <span class="sr-only">
                                                                {{
                                                                    "progreso.historial.respuesta.respuesta_estudiante"
                                                                        | t
                                                                }}
                                                            </span>
                                                            <span class="sr-only">
                                                                {{ "progreso.historial.respuesta.correcta" | t }}
                                                            </span>
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                respuestas &&
                                                                alternativa.id ==
                                                                    respuestas[
                                                                        contenidoIndexByContenidoIndex[contenidoIndex]
                                                                    ].alternativa_id &&
                                                                !alternativa.correcta
                                                            "
                                                            class="resp-incorrecta resp-arrow"
                                                        >
                                                            <span class="sr-only">
                                                                {{
                                                                    "progreso.historial.respuesta.respuesta_estudiante"
                                                                        | t
                                                                }}
                                                            </span>
                                                            <span class="sr-only">
                                                                {{ "progreso.historial.respuesta.incorrecta" | t }}
                                                            </span>
                                                        </div>
                                                        {{ getLetra(i) }})
                                                    </div>
                                                    <div
                                                        class="alternativa"
                                                        [innerHTML]="
                                                            alternativa.alternativa
                                                                | clearLatex
                                                                | taxativoNumeroTexto
                                                                    : grupoPreguntaTextoService.sentenceToLine[
                                                                          pregunta.grupo_pregunta_id
                                                                      ]
                                                                | katex
                                                                | trustedhtml
                                                        "
                                                    ></div>
                                                </div>
                                                <p
                                                    class="marcador-solucion"
                                                    *ngIf="!disableCorreccion && alternativa.correcta"
                                                >
                                                    <span
                                                        [innerHTML]="
                                                            'progreso.historial.respuesta.correcta' | t | trustedhtml
                                                        "
                                                    ></span>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchDefault>-</ng-container>
                </ng-container>
            </ng-container>
        </div>

        <tabla-clasificaciones-pregunta
            [pregunta]="pregunta"
            *ngIf="showClasificaciones"
        ></tabla-clasificaciones-pregunta>
        <div class="tiempo-estimado">
            <fa name="clock-o"></fa>
            {{ "progreso.historial.respuestas.tiempo" | t }}
            <ng-container *ngIf="minutos != 0">
                {{ minutos }} {{ "progreso.historial.respuesta.minuto" | t }}{{ minutos != 1 ? "s" : "" }} y
            </ng-container>
            {{ segundos }} {{ "progreso.historial.respuesta.segundo" | t }}{{ segundos != 1 ? "s" : "" }}
        </div>
        <div class="solucion clearfix">
            <div class="pull-right reporte" *ngIf="showDuda">
                <crear-duda
                    *ngIf="enableCrearDuda"
                    [tabla]="'Pregunta'"
                    [entidad_id]="pregunta.id"
                    [queryParams]="dudaQueryParams"
                ></crear-duda>
                &nbsp;&nbsp;
                <reportes-modal *ngIf="enableReporte" [tabla]="'Pregunta'" [entidad_id]="pregunta.id"></reportes-modal>
            </div>

            <ng-container *ngIf="!disableSolucion">
                <cui-button
                    id="{{ 'pregunta_' + evaluacionId + '_' + (index + 1) }}"
                    class="btn_style"
                    (click)="toggleSolution()"
                    type="button"
                    [attr.aria-expanded]="showSolution"
                    [attr.aria-controls]="showSolution ? showOnly + '_solucion_pregunta_id_' + pregunta.id : ''"
                >
                    <span class="glyphicon glyphicon-search"></span>
                    <span *ngIf="!showSolution">{{ "progreso.historial.respuestas.solucion.ver" | t }}</span>
                    <span *ngIf="showSolution">{{ "progreso.historial.respuestas.solucion.ocultar" | t }}</span>
                    &nbsp;
                    {{ "progreso.historial.respuestas.solucion.solucion" | t }}
                </cui-button>
                <div
                    [id]="showOnly + '_solucion_pregunta_id_' + pregunta.id"
                    [attr.aria-labelledby]="'pregunta_' + evaluacionId + '_' + (index + 1)"
                    role="region"
                >
                    <div
                        role="region"
                        [attr.aria-label]="('progreso.historial.respuesta.solucion_pregunta' | t) + (index + 1)"
                        class="solucion_contenido"
                        [class.solucion-show]="showSolution"
                        [innerHTML]="pregunta.solucion | clearLatex | katex | trustedhtml"
                    ></div>
                    <div
                        *ngIf="hasVideoUrl"
                        role="region"
                        [attr.aria-label]="('progreso.historial.respuesta.solucion_pregunta' | t) + (index + 1)"
                        class="solucion_video"
                        [class.solucion-show]="showSolution"
                    >
                        ​
                        <iframe
                            width="560"
                            height="315"
                            [attr.src]="safeUrl"
                            frameborder="0"
                            allow="autoplay; encrypted-media"
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-container *ngIf="onlyLetras">
    <div class="numero-pregunta-letras">{{ index + 1 }})</div>
    <ng-container *ngFor="let contestable of pregunta.contestables; let i = index">
        <ng-container [ngSwitch]="contestable.contestable_tipo?.contestable_tipo">
            <ng-container *ngSwitchCase="contestableTipo.libre">
                <div class="alternativa-letras white-space with-ver">
                    <generic-modal #genericModal>
                        <modal-titulo>
                            {{ "progreso.historial.respuesta.respuesta_pregunta" | t }} #{{ index + 1 }}
                        </modal-titulo>
                        <modal-contenido>
                            <respuesta-libre
                                [alternativas]="contestable.alternativas"
                                [respuestaId]="respuestas[i].alternativa_id"
                            ></respuesta-libre>
                        </modal-contenido>
                        <modal-botones>
                            <button class="btn btn-default" (click)="genericModal.close()">
                                {{ "progreso.historial.respuesta.cerrar" | t }}
                            </button>
                        </modal-botones>
                    </generic-modal>

                    <button class="btn btn-default btn-ver" (click)="genericModal.buttonPressed()">Ver</button>
                </div>
                <div class="alternativa-letras white-space" *ngFor="let letra of letrasAlternativas.slice(1)"></div>
            </ng-container>
            <ng-container *ngSwitchCase="contestableTipo.archivo">
                <div class="alternativa-letras white-space with-ver">
                    <generic-modal #genericModal>
                        <modal-titulo>
                            {{ "progreso.historial.respuesta.respuesta_pregunta" | t }} #{{ index + 1 }}
                        </modal-titulo>
                        <modal-contenido>
                            <respuesta-pdf
                                *ngIf="respuestas[i].alternativa_id"
                                [alternativas]="contestable.alternativas"
                                [respuestaId]="respuestas[i].alternativa_id"
                            ></respuesta-pdf>
                        </modal-contenido>
                        <modal-botones>
                            <button class="btn btn-default" (click)="genericModal.close()">
                                {{ "progreso.historial.respuesta.cerrar" | t }}
                            </button>
                        </modal-botones>
                    </generic-modal>

                    <button class="btn btn-default btn-ver" (click)="genericModal.buttonPressed()">
                        {{ "progreso.historial.respuesta.ver" | t }}
                    </button>
                </div>
                <div class="alternativa-letras white-space" *ngFor="let letra of letrasAlternativas.slice(1)"></div>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <div class="alternativa-letras" *ngFor="let letra of letrasAlternativas">
                    <div
                        class="espacio-marcar"
                        [class.marcada]="letra.toLowerCase() == alternativaEscogida?.letra?.toLowerCase()"
                        [class.correcta]="
                            letra.toLowerCase() != alternativaEscogida?.letra?.toLowerCase() &&
                            letra.toLowerCase() == alternativaCorrecta?.letra?.toLowerCase()
                        "
                    >
                        <span
                            *ngIf="
                                letra.toLowerCase() != alternativaEscogida?.letra?.toLowerCase() &&
                                letra.toLowerCase() == alternativaCorrecta?.letra?.toLowerCase()
                            "
                            class="glyphicon glyphicon glyphicon-ok"
                        ></span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <div class="icono-resultado" [class.extended]="tieneCorreccionManual && perfil == 'profesores'">
        <ng-container *ngIf="alternativaEscogida">
            <ng-container *ngIf="tieneCorreccionManual && perfil == 'profesores'">
                <button
                    class="alternativa-letras-correcta first-icon clickable"
                    [class.unmarked]="!isMarkedCorrecta"
                    (click)="toggleCorrecta(true)"
                    [disabled]="disableEdit"
                >
                    <span class="glyphicon glyphicon glyphicon-ok"></span>
                </button>
                <button
                    class="alternativa-letras-incorrecta second-icon clickable"
                    (click)="toggleCorrecta(false)"
                    [class.unmarked]="isMarkedCorrecta == true || isMarkedCorrecta == null"
                    [disabled]="disableEdit"
                >
                    <span class="glyphicon glyphicon glyphicon-remove"></span>
                </button>
                <select
                    class="form-control select-puntuacion"
                    [ngModel]="selectedPuntuacion ?? 0.0"
                    (ngModelChange)="onChangePuntuacion($event)"
                    [disabled]="disableEdit"
                >
                    <option *ngFor="let posiblePuntuacion of posiblePuntuaciones" [ngValue]="posiblePuntuacion">
                        {{ posiblePuntuacion }}
                    </option>
                </select>
            </ng-container>
            <ng-container *ngIf="tieneCorreccionManual && perfil == 'alumnos' && isMarkedCorrecta != null">
                <div *ngIf="isMarkedCorrecta == true" class="alternativa-letras-correcta">
                    <span class="glyphicon glyphicon glyphicon-ok"></span>
                </div>
                <div *ngIf="isMarkedCorrecta == false" class="alternativa-letras-incorrecta">
                    <span class="glyphicon glyphicon glyphicon-remove"></span>
                </div>
            </ng-container>
            <ng-container *ngIf="!tieneCorreccionManual">
                <div
                    *ngIf="alternativaEscogida?.letra?.toLowerCase() == alternativaCorrecta?.letra?.toLowerCase()"
                    class="alternativa-letras-correcta"
                >
                    <span class="glyphicon glyphicon glyphicon-ok"></span>
                </div>
                <div
                    *ngIf="alternativaEscogida?.letra?.toLowerCase() != alternativaCorrecta?.letra?.toLowerCase()"
                    class="alternativa-letras-incorrecta"
                >
                    <span class="glyphicon glyphicon glyphicon-remove"></span>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!alternativaEscogida">
            <div class="alternativa-letras-omitida">
                <span class="glyphicon glyphicon glyphicon-ban-circle"></span>
            </div>
        </ng-container>
    </div>
    <div class="icono-resultado" *ngIf="perfil == 'alumnos'">
        <div *ngIf="puntuacionObtenida != null" class="letra">{{ puntuacionObtenida }} / {{ puntuacion }}</div>
        <div *ngIf="puntuacionObtenida == null" class="letra">- / {{ puntuacion }}</div>
    </div>
    <div *ngIf="isPiloto">P</div>
</ng-container>
