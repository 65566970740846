import { BaseModel, SaveType, Validations } from "@puntaje/shared/core"
import { Instrumento } from "./instrumentos.model"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { InstrumentoMensual } from "./instrumento_mensuales.model"
import { InstrumentoMultiple } from "./instrumento_multiples.model"
import { GrupoRecursoTipo } from "./grupo_recurso_tipos.model"
import { GrupoRecurso } from "./grupo_recursos.model"

export class InstrumentoPredefinido extends BaseModel {
    public instrumento_id: number
    public instrumento_multiple_id: number
    public nombre: string
    public descripcion: string
    public tag: string
    public asignatura_id: number

    @SaveType(() => Instrumento) public instrumento: Instrumento
    @SaveType(() => InstrumentoMultiple) public instrumento_multiple: InstrumentoMultiple
    @SaveType(() => Asignatura) public asignatura: Asignatura
    @SaveType(() => InstrumentoMensual) public instrumento_mensuales: InstrumentoMensual[]
    @SaveType(() => GrupoRecursoTipo) grupo_recurso_tipos: GrupoRecursoTipo[]
    @SaveType(() => GrupoRecurso) grupo_recursos: GrupoRecurso[]

    constructor() {
        super()
        this.instrumento = new Instrumento()
        this.asignatura = null
    }

    public toString() {
        return "(" + this.instrumento_id + "," + this.nombre + "," + this.tag + ")"
    }

    get generador_instrumento() {
        if (this.instrumento?.generador_instrumento) {
            return this.instrumento.generador_instrumento.generador_instrumento
        } else {
            return "Generador indefinido"
        }
    }
}
