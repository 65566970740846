import { BaseModel, SaveType } from "@puntaje/shared/core"

export class EstablecimientoColombia extends BaseModel {
    public icfes_id: number
    public establecimiento_id: number

    getFieldName() {
        return "Icfes ID"
    }

    getFieldValue() {
        return this.icfes_id
    }
}
