import { Component, OnInit, ViewChild, Input, SimpleChanges, ChangeDetectorRef } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    Asignatura,
    ClasificacionTipoAlias,
    ClasificacionTipoAliases,
    ClasificacionTipos
} from "@puntaje/nebulosa/api-services"
import { BaseChart, BarChart, ChartColorType, I18nService, AppConfig } from "@puntaje/shared/core"
import { Store, select } from "@ngrx/store"
import { State, selectSelectedAsignatura } from "@puntaje/puntaje/store"
import { Subscription, combineLatest } from "rxjs"
declare const config: AppConfig

/*********************************
Gráfico de barras de desempeño por clasificación.
Recibe clasificaciones: string[], data: number[]
ej: [5, 3, 4]
**********************************/

@Component({
    selector: "grafico-desempeno-clasificacion",
    template: `
        <loading-layout #loadingLayout>
            <estadisticas-grafico
                [grafico]="grafico"
                *ngIf="!loadingLayout.loading && dataTotal.length > 0"
            ></estadisticas-grafico>
        </loading-layout>
    `
})
export class GraficoDesempenoClasificacionComponent implements OnInit {
    @Input() asignatura: Asignatura
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() clasificaciones: string[] = []
    @Input() data: number[] = []
    @Input() dataTotal: number[] = []
    @Input() tipoClasificacion: string
    @Input() tipoEvaluacion: string
    public grafico: BaseChart
    enableGraph: boolean = false
    subQuery: Subscription
    clasificacionTipoAlias: string = ""

    constructor(
        protected i18nService: I18nService,
        protected store: Store<State>,
        private clasificacionTipoAliasesService: ClasificacionTipoAliases,
        private clasificacionTiposService: ClasificacionTipos,
        private cdr: ChangeDetectorRef
    ) {}

    async ngOnInit() {
        if (!this.tipoClasificacion) this.tipoClasificacion = "eje temático"

        this.clasificacionTipoAlias = this.tipoClasificacion

        this.subQuery = combineLatest([this.asignatura$]).subscribe(async ([asignatura]) => {
            this.asignatura = asignatura
            await this.setAlias()
            this.setGrafico()
        })
    }

    asignatura$ = this.store.pipe(select(selectSelectedAsignatura))

    async setAlias() {
        const clasificacionTipo = (
            await this.clasificacionTiposService.where({
                clasificacion_tipo: { clasificacion_tipo: this.tipoClasificacion }
            })
        )[0]
        if (!clasificacionTipo) {
            return
        }

        const params = {
            clasificacion_tipo_alias: { clasificacion_tipo_id: clasificacionTipo.id },
            render_options: {
                include: {
                    asignatura_plataforma: {
                        include: {
                            asignatura: null,
                            plataforma: null
                        }
                    }
                }
            }
        }

        return this.clasificacionTipoAliasesService
            .where(params)
            .then((clasificacion_tipo_aliases: ClasificacionTipoAlias[]) => {
                if (clasificacion_tipo_aliases.length > 0) {
                    let ctas = clasificacion_tipo_aliases.filter(
                        cta =>
                            cta.asignatura_plataforma.plataforma_id == config.plataforma.id &&
                            cta.asignatura_plataforma.asignatura_id == this.asignatura.id
                    )
                    if (ctas.length > 0) {
                        this.clasificacionTipoAlias = ctas[0].alias
                    }
                    this.cdr.detectChanges()
                }
            })
    }

    setGrafico() {
        this.data = this.data || []
        this.dataTotal = this.dataTotal || []

        let sufix = ""
        if (this.asignatura) {
            let confEvaluacionTipo = this.asignatura["confByEvaluacionTipo"]?.[this.tipoEvaluacion]
            if (!confEvaluacionTipo) {
                confEvaluacionTipo = this.asignatura["confByEvaluacionTipo"]?.[config.plataforma.evaluacionDefault]
            }

            sufix = " para " + (confEvaluacionTipo?.abreviacion ?? this.asignatura.asignatura)
        }

        this.loadingLayout.standby()
        let g1: any = {}
        g1.titulo =
            this.i18nService.translate("grafico_desempeno_clasificacion.titulo") + this.clasificacionTipoAlias + sufix
        g1.descripcion =
            this.i18nService.translate("grafico_desempeno_clasificacion.descripcion") +
            this.clasificacionTipoAlias +
            "."
        g1.ejeX = this.clasificaciones
        g1.ejeY = { min: 0, max: 100 }
        g1.data = [
            {
                nombre: this.i18nService.translate("grafico_desempeno_clasificacion.desempeno"),
                data: this.data.map((x, i) => (100 * x) / this.dataTotal[i])
            }
        ]
        g1.tooltip = '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.1f}%</b><br/>'
        this.grafico = new BarChart(g1)
        this.loadingLayout.ready()
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setGrafico()
    }
}
