<div>
    <loading-layout #loadingLayout [opaque]="true">
        <div class="row">
            <div class="col-md-6">
                <cui-section-headline>{{ "generar_ensayos.configurar" | t }}</cui-section-headline>
                <div class="options-container">
                    <h4 class="h4_sub_title_1">
                        <fa name="arrow-right" class="highlight-arrow"></fa>
                        {{ "generar_ensayos.configurar_preguntas_tiempo" | t }}
                    </h4>
                    <numero-preguntas-tiempo
                        [generadorInstrumento]="generadorInstrumento"
                        [(numeroPreguntas)]="numeroPreguntas"
                        [(tiempo)]="tiempo"
                        [tipoEvaluacion]="tipoEvaluacion"
                    ></numero-preguntas-tiempo>
                </div>
                <div
                    class="options-container container-bottom-margin"
                    *ngIf="generadorInstrumentos"
                    [hidden]="!generadorInstrumentos || generadorInstrumentos.length === 1"
                >
                    <h4 class="h4_sub_title_1">
                        <fa name="arrow-right" class="highlight-arrow"></fa>
                        {{ "generar_ensayos.asignatura" | t }}
                    </h4>
                    <generador-instrumento-select
                        [generadorInstrumentos]="generadorInstrumentos"
                        [(generadorInstrumento)]="generadorInstrumento"
                        (generadorInstrumentoChange)="clasificacionesIds = null"
                    ></generador-instrumento-select>
                </div>
            </div>
            <div class="col-md-6" id="opciones_avanzadas">
                <div
                    id="contenidos-container"
                    class="contenidos-container clear-both container-bottom-margin"
                    [class.hide-personalizar]="!showPersonalizar"
                >
                    <cui-section-headline>{{ "generar_ensayos.personalizar" | t }}</cui-section-headline>
                    <p class="tutorial">
                        {{ "generar_ensayos.descripcion_personalizar" | t }}
                    </p>
                    <div class="generar-col2 contenidos-ensayo">
                        <configurar-clasificaciones
                            *ngIf="generadorInstrumento"
                            (onReady)="onReady()"
                            [tipoInstrumento]="tipoEvaluacion"
                            [generadorInstrumentoId]="generadorInstrumento.id"
                            [asignaturaId]="asignaturaId"
                            [(clasificacionesIds)]="clasificacionesIds"
                        ></configurar-clasificaciones>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <cui-button
            class="btn-realizar"
            (click)="generarEnsayo()"
            [disabled]="!numeroPreguntas || !tiempo || numeroPreguntas < 5"
        >
            {{ realizarButton }}
        </cui-button>

        <cui-button
            *ngIf="
                plataforma !== 'College Board' &&
                plataforma !== 'UST' &&
                plataforma !== 'USACH' &&
                plataforma !== 'UA' &&
                !hideButtonDescargar
            "
            class="btn-download"
            (click)="instruccionesImpresion()"
        >
            {{ descargarButton }}
        </cui-button>
    </loading-layout>
</div>
<div
    class="modal fade staticModal"
    bsModal
    #mandatoryLoading="bs-modal"
    [config]="{ backdrop: 'static', keyboard: false }"
    tabindex="0"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <loading-layout #loadingLayout></loading-layout>
                <p class="loading-message">
                    {{ "generar_ensayos.loading_mensaje_uno" | t }}
                    <br />
                    {{ "generar_ensayos.loading_mensaje_dos" | t }}
                </p>
            </div>
        </div>
    </div>
</div>
<modal-imprimir-evaluacion-alumnos
    #tutorialModal
    (confirmCallback)="confirmInstructionsAndContinue()"
></modal-imprimir-evaluacion-alumnos>
