<notificacion-layout
    [notificacion]="notificacion"
    [enableMini]="enableMini"
    [icono]="'dialogos'"
    [tipoNotificacion]="'retroalimentacion'"
    [disableMarkButton]="disableMarkButton"
>
    <notificacion-contenido>
        <ng-container *ngIf="retroalimentacion">
            <div *ngIf="retroalimentacion.retroalimentable_type == 'EvaluacionInstancia'">
                <p class="texto-notificacion">
                    {{ usuario_remitente }}
                    <ng-container *ngIf="retroalimentacion.tipo == 'pdf'">
                        {{ "notificaciones.material_complementario" | t }}
                    </ng-container>
                    <ng-container *ngIf="retroalimentacion.tipo == 'html'">{{ "notificaciones.agrego_comentario" | t }}</ng-container>
                    {{ "notificaciones.a_tu_r" | t }} {{ data.evaluacion_tipo | transformInstrumento: "conArticulo" }}
                    <b>{{ data.evaluacion.evaluacion }}</b>
                    {{ "notificaciones.de" | t }} {{ data.asignatura }}. {{ "notificaciones.revisalo" | t }}
                    <a [href]="notificacion.link" target="_blank" (click)="onClickLink()">{{ "notificaciones.aqui" | t }}.</a>
                </p>
            </div>
            <div *ngIf="retroalimentacion.retroalimentable_type != 'EvaluacionInstancia'">
                <p *ngIf="retroalimentacion.tipo == 'pdf'" class="texto-notificacion">
                    {{ usuario_remitente }} {{ "notificaciones.ha_subido_m_c" | t }} {{ retroalimentable }}. {{ "notificaciones.revisalo" | t }}
                    <a [href]="retroalimentacion.retroalimentacion" target="_blank" (click)="onClickLink()">{{ "notificaciones.aqui" | t }}.</a>
                </p>
            </div>
        </ng-container>
    </notificacion-contenido>
</notificacion-layout>
