import { Component } from "@angular/core"

@Component({
    selector: "ll-titulo, el-titulo, sl-titulo, rl-titulo, l-titulo",
    template: `
        <l-slot-content name="titulo">
            <ng-content></ng-content>
        </l-slot-content>
    `
})
export class LTituloComponent {}
