import { BaseModel, SaveType } from "@puntaje/shared/core"

export class SmowlObject extends BaseModel {
    public className: string = "SmowlObject"
    public entity: string
    public licenseKey: string
    @SaveType(() => Object) public result: {
        ack?: string
    }
    @SaveType(() => Object) public error: Object

    constructor() {
        super()
        this.result = {}
        this.error = {}
    }
}
