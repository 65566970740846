import { Component, Input, ElementRef, ChangeDetectorRef } from "@angular/core"
import { BaseChart } from "@puntaje/shared/core"

/***************************
	Componete básico compartido para todos los componentes de tipo gráfico
****************************/

@Component({
    selector: "estadisticas-grafico",
    templateUrl: "estadisticas_grafico.component.html",
    styleUrls: ["estadisticas_grafico.component.scss"]
})
export class EstadisticasGraficoComponent {
    @Input("grafico") grafico: BaseChart
    @Input() titulo: string
    @Input() ariaLabel = "Gráfico"
    enableGraph: boolean
    parentsLoaded: boolean

    constructor(public el: ElementRef, protected cdr: ChangeDetectorRef) {}

    ngOnInit() {}

    ngAfterViewInit() {
        if (this.grafico) {
            this.enableGraph = true
        }

        this.checkIfParentsLoaded()
    }

    ngAfterViewChecked() {
        !this.parentsLoaded && setTimeout(() => this.checkIfParentsLoaded(), 1)
    }

    checkIfParentsLoaded() {
        if (document.contains(this.el.nativeElement)) {
            this.parentsLoaded = true
            this.cdr.detectChanges()
            this.el.nativeElement.querySelector("svg")?.setAttribute("aria-label", this.ariaLabel)
        }
        if (!this.cdr["destroyed"]) {
            this.cdr.detectChanges()
        }
    }
}
