import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router"
import { PuntajeAuthService, UsuarioBaneado, UsuarioBaneados } from "@puntaje/puntaje/api-services"

@Injectable({ providedIn: "root" })
export class BanGuard implements CanActivate {
    constructor(
        private authService: PuntajeAuthService,
        private router: Router,
        private usuarioBaneadosService: UsuarioBaneados
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Promise<boolean>(resolve => {
            let now = new Date()
            let params = {
                usuario_baneado: {
                    usuario_id: this.authService.getUserData().id,
                    lte: {
                        fecha_inicio: now.toISOString()
                    },
                    gte: {
                        fecha_termino: now.toISOString()
                    }
                }
            }
            this.usuarioBaneadosService.where(params).then((usuarioBaneados: UsuarioBaneado[]) => {
                if (usuarioBaneados[0]) {
                    this.router.navigate([""])
                    resolve(false)
                } else resolve(true)
            })
        })
    }
}
