export const esAbily = {
    estudiar: {
        plan_estudios: {
            compartidos: {
                titulo: "Módulos de aprendizaje compartidos por mi profesor",
                titulo_simple: "Módulos de aprendizaje"
            },
            msj_no_planes: "no se ha encontrado ningún módulo de aprendizaje.",
            recomendados: {
                titulo: "Módulos de aprendizaje recomendados"
            },
            titulo: "Módulos de aprendizaje"
        },
        plan_personales: {
            btn_comenzar: "Comenzar prueba diagnóstico"
        }
    },
    plan_clase_alumno: {
        title: "Mis módulos de aprendizaje",
        not_found: "No se encontraron módulos de aprendizaje."
    },
    puntaje_core: {
        plan_clases: {
            titulo: "Módulos de aprendizaje",
            tab_planes_alumnos: "Módulos de aprendizaje estudiantes",
            tab_mis_planes: "Mis módulos de aprendizaje",
            planes_compartidos: "Módulos de aprendizaje compartidos por mí",
            planes_recomendados: "Módulos de aprendizaje recomendados"
        }
    }
}
