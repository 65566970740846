import { BaseModel, SaveType } from "@puntaje/shared/core"
import { GeneradorInstrumento } from "./generador_instrumentos.model"
import { Clasificacion } from "./clasificaciones.model"

export class GeneradorInstrumentoFiltroClasificacion extends BaseModel {
    public static className: string = "GeneradorInstrumentoFiltroClasificacion"
    public generador_instrumento_id: number
    public clasificacion_id: number

    @SaveType(() => GeneradorInstrumento) public generador_instrumento: GeneradorInstrumento
    @SaveType(() => Clasificacion) public clasificacion: Clasificacion
}
