import { Action } from "@ngrx/store"
import { Evaluacion, Evaluaciones, EvaluacionTiempo } from "@puntaje/puntaje/api-services"
import { Instruccion, Pregunta, GrupoPregunta, Material } from "@puntaje/nebulosa/api-services"

export interface Item {
    numero?: number
    duda?: boolean
    tipo: "pregunta" | "instruccion" | "confirmacion"
    item: Pregunta | Instruccion | {}
    first?: boolean
    last?: boolean
    iniGrupoPregunta?: boolean
    endGrupoPregunta?: boolean
}

export enum EvaluacionesActionTypes {
    SetActual = "[Evaluaciones] Set Actual",
    SetLoadingEvaluacion = "[Evaluaciones] Set Loading Evaluacion",
    SetRespuestasActual = "[Evaluaciones] Set Respuestas Actual",
    SetRespuestaIndex = "[Evaluaciones] Set Respuesta Index",
    SetSeeingIndex = "[Evaluaciones] Set Seeing Index",
    MoveSeeingIndex = "[Evaluaciones] Move Seeing Index",
    MoveSeeingIndexToLastRespuesta = "[Evaluacion] Move Seeing Index To Last Respuesta",
    SetItems = "[Evaluaciones] Set Items",
    SetGrupoPreguntaItems = "[Evaluaciones] Set GrupoPregunta Items",
    SetMaterial = "[Evaluaciones] Set Material",
    ToggleDudaPregunta = "[Evaluaciones] Toggle Duda Pregunta",
    ToggleDudaPreguntaActual = "[Evaluaciones] Toggle Duda Pregunta Actual",
    SetDudaPreguntasNoContestadas = "[Evaluaciones] Set Duda Preguntas No Contestadas",
    ResetDudaPreguntas = "[Evaluaciones] Reset Duda Preguntas",
    GetItems = "[Evaluaciones] Get Items",
    SetLoadingItems = "[Evaluaciones] Set Loading Items",
    MoveToNextDuda = "[Evaluaciones] Next Duda",
    MoveToPrevDuda = "[Evaluaciones] Prev Duda",
    ChangeCorreccionTab = "[Evaluaciones] Change Correccion Tab",
    UpdateGrupoPreguntaItem = "[Evaluaciones] Update GrupoPregunta Item",
    EnableHighlightMode = "[Evaluaciones] Enable Highlight Mode",
    EnableEraseHighlightMode = "[Evaluaciones] Enable Erase Highlight Mode",
    SetInstruccionFija = "[Evaluaciones] Set Instruccion Fija",
    FetchRespuestasActual = "[Evaluaciones] Fetch Respuestas Actual",
    SaveRespuestasActual = "[Evaluaciones] Save Respuestas Actual",
    CleanRespuestasActual = "[Evaluaciones] Clean Respuestas Actual",
    SetTiemposActual = "[Evaluaciones] Set Tiempos Actual",
    AddTiempoIndex = "[Evaluaciones] Set Tiempo Index",
    SetEvaluacionTiempoActual = "[Evaluaciones] Set Evaluacion Tiempo Actual",
    ResetEvaluacion = "[Evaluaciones] Reset Evaluaciones"
}

export class SetActual implements Action {
    readonly type = EvaluacionesActionTypes.SetActual

    constructor(public payload: { actual: Evaluacion }) {}
}

export class SetDudaPreguntasNoContestadas implements Action {
    readonly type = EvaluacionesActionTypes.SetDudaPreguntasNoContestadas
}
export class SetRespuestasActual implements Action {
    readonly type = EvaluacionesActionTypes.SetRespuestasActual

    constructor(public payload: { respuestasActual: any[] }) {}
}

export class SetRespuestaIndex implements Action {
    readonly type = EvaluacionesActionTypes.SetRespuestaIndex

    constructor(public respuesta: any, public index: number) {}
}

export class SetSeeingIndex implements Action {
    readonly type = EvaluacionesActionTypes.SetSeeingIndex

    constructor(public payload: number) {}
}

export class MoveSeeingIndex implements Action {
    readonly type = EvaluacionesActionTypes.MoveSeeingIndex

    constructor(public payload: number) {}
}

export class MoveSeeingIndexToLastRespuesta implements Action {
    readonly type = EvaluacionesActionTypes.MoveSeeingIndexToLastRespuesta
}

export class ToggleDudaPregunta implements Action {
    readonly type = EvaluacionesActionTypes.ToggleDudaPregunta

    constructor(public payload: number) {}
}

export class ToggleDudaPreguntaActual implements Action {
    readonly type = EvaluacionesActionTypes.ToggleDudaPreguntaActual
}

export class SetItems implements Action {
    readonly type = EvaluacionesActionTypes.SetItems

    constructor(public payload: { items: Item[] }) {}
}

export class SetGrupoPreguntaItems implements Action {
    readonly type = EvaluacionesActionTypes.SetGrupoPreguntaItems

    constructor(public payload: { items: Item[] }) {}
}

export class GetItems implements Action {
    readonly type = EvaluacionesActionTypes.GetItems

    constructor(public payload: { evaluacion: Evaluacion }) {}
}

export class ResetDudaPreguntas implements Action {
    readonly type = EvaluacionesActionTypes.ResetDudaPreguntas
}

export class MoveToNextDuda implements Action {
    readonly type = EvaluacionesActionTypes.MoveToNextDuda
}

export class MoveToPrevDuda implements Action {
    readonly type = EvaluacionesActionTypes.MoveToPrevDuda
}

export class ChangeCorreccionTab implements Action {
    readonly type = EvaluacionesActionTypes.ChangeCorreccionTab
    constructor(public payload: string) {}
}

export class UpdateGrupoPreguntaItem implements Action {
    readonly type = EvaluacionesActionTypes.UpdateGrupoPreguntaItem

    constructor(public payload: GrupoPregunta) {}
}

export class EnableHighlightMode implements Action {
    readonly type = EvaluacionesActionTypes.EnableHighlightMode
}

export class EnableEraseHighlightMode implements Action {
    readonly type = EvaluacionesActionTypes.EnableEraseHighlightMode
}

export class SetInstruccionFija implements Action {
    readonly type = EvaluacionesActionTypes.SetInstruccionFija
    constructor(public payload: { instruccionFija: Instruccion }) {}
}

export class SetMaterial implements Action {
    readonly type = EvaluacionesActionTypes.SetMaterial
    constructor(public payload: { material: Material }) {}
}

export class FetchRespuestasActual implements Action {
    readonly type = EvaluacionesActionTypes.FetchRespuestasActual
    constructor() {}
}

export class SaveRespuestasActual implements Action {
    readonly type = EvaluacionesActionTypes.SaveRespuestasActual
    constructor(public lastPreguntaIndex?: number) {}
}

export class CleanRespuestasActual implements Action {
    readonly type = EvaluacionesActionTypes.CleanRespuestasActual
    constructor() {}
}

export class SetTiemposActual implements Action {
    readonly type = EvaluacionesActionTypes.SetTiemposActual
    constructor(public payload: { tiemposActual: number[] }) {}
}

export class AddTiempoIndex implements Action {
    readonly type = EvaluacionesActionTypes.AddTiempoIndex
    constructor(public tiempo: number, public index: number) {}
}

export class SetEvaluacionTiempoActual implements Action {
    readonly type = EvaluacionesActionTypes.SetEvaluacionTiempoActual
    constructor(public payload: { evaluacionTiempoActual: EvaluacionTiempo }) {}
}

export class ResetEvaluacion implements Action {
    readonly type = EvaluacionesActionTypes.ResetEvaluacion
    constructor() {}
}

export class SetLoadingEvaluacion implements Action {
    readonly type = EvaluacionesActionTypes.SetLoadingEvaluacion
    constructor(public payload: { loadingEvaluacion: boolean }) {}
}

export class SetLoadingItems implements Action {
    readonly type = EvaluacionesActionTypes.SetLoadingItems
    constructor(public payload: { loadingItems: boolean }) {}
}

export type EvaluacionesActions =
    | SetActual
    | SetSeeingIndex
    | SetItems
    | SetGrupoPreguntaItems
    | GetItems
    | ToggleDudaPregunta
    | ToggleDudaPreguntaActual
    | ResetDudaPreguntas
    | SetRespuestasActual
    | MoveSeeingIndex
    | MoveSeeingIndexToLastRespuesta
    | MoveToNextDuda
    | MoveToPrevDuda
    | ChangeCorreccionTab
    | UpdateGrupoPreguntaItem
    | EnableHighlightMode
    | EnableEraseHighlightMode
    | SetInstruccionFija
    | SetMaterial
    | FetchRespuestasActual
    | SetTiemposActual
    | SetEvaluacionTiempoActual
    | SaveRespuestasActual
    | ResetEvaluacion
    | CleanRespuestasActual
    | AddTiempoIndex
    | SetRespuestaIndex
    | SetLoadingEvaluacion
    | SetLoadingItems
    | SetDudaPreguntasNoContestadas
