import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { UtilModule, FormModule, ModelViewModule, PaginatorModule } from "@puntaje/shared/core"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { CUIContainersModule, CUITextsModule, CUIInputsModule } from "@puntaje/shared/cui"

import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { PlanClasesModule } from "@puntaje/puntaje/new-modules/plan-clases"

import { PlanEstudioComponent } from "./plan_estudio.component"
import { PlanEstudiosComponent } from "./plan_estudios.component"
import { PlanEstudiosToolNavbarComponent } from "./plan_estudios.toolnavbar.component"
import { PlanEstudioAplicadoSesionComponent } from "./plan_estudio_aplicado_sesion.component"
import { PlanesEstudioComponent } from "./planes_estudio.component"
import { PlanEstudioSesionesComponent } from "./plan_estudio_sesiones.component"
import { PlanEstudioBoxComponent } from "./plan_estudio_box.component"
import { PlanesEstudiosAlumnoComponent } from "./planes_estudios_alumno.component"
import { PlanesEstudiosAsignaturaComponent } from "./planes_estudios_asignatura.component"
import { PlanesEstudiosAsignaturasComponent } from "./planes_estudios_asignaturas.component"
import { DatosGeneralesPlanEstudioComponent } from "./datos_generales_plan_estudio.component"
import { PlanEstudioSesionBoxComponent } from "./plan_estudio_sesion_box.component"
import { PlanEstudioSesionMenuComponent } from "./plan_estudio_sesion_menu.component"
import {
    PlanEstudiosEvaluacionComponent,
    PlanEstudiosEvaluacionComponentTags
} from "./plan_estudios_evaluacion.component"
import { PlanEstudioAplicadosComponent } from "./plan-estudio-aplicados/plan-estudio-aplicados.component"
import { PlanesEstudiosShowcaseBoxComponent } from "./planes_estudios_showcasebox.component"
import { PlanesEstudioProfesorComponent } from "./planes_estudio_profesor.component"
import { PlanEstudioSesionesProfesorComponent } from "./plan_estudio_sesiones_profesor.component"
import { PlanEstudioAplicadoSesionProfesorComponent } from "./plan_estudio_aplicado_sesion_profesor.component"
import {
    PlanEstudiosEvaluacionProfesorComponent,
    PlanEstudiosEvaluacionProfesorComponentTags
} from "./plan_estudios_evaluacion_profesor.component"
import { PlanEstudioNavbarComponent } from "./plan_estudio_navbar.component"
import { PlanesEstudiosShowcaseBoxOldComponent } from "./planes_estudios_showcasebox_old.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        ModelViewModule,
        PaginatorModule,
        RouterModule,
        MaterialesModule,
        ProgressbarModule,
        Angular2FontawesomeModule,
        EnsayosModule,
        SharedModule,
        InstrumentosModule,
        CUIContainersModule,
        CUITextsModule,
        CUIInputsModule,
        PlanClasesModule
    ],
    declarations: [
        PlanEstudioComponent,
        PlanEstudiosComponent,
        PlanEstudiosToolNavbarComponent,
        PlanEstudioAplicadoSesionComponent,
        PlanesEstudioComponent,
        PlanEstudioSesionesComponent,
        PlanEstudioBoxComponent,
        PlanesEstudiosAlumnoComponent,
        PlanesEstudiosAsignaturaComponent,
        PlanesEstudiosAsignaturasComponent,
        DatosGeneralesPlanEstudioComponent,
        PlanEstudioSesionBoxComponent,
        PlanEstudioSesionMenuComponent,
        PlanEstudiosEvaluacionComponent,
        PlanEstudiosEvaluacionComponentTags,
        PlanEstudioAplicadosComponent,
        PlanesEstudiosShowcaseBoxComponent,
        PlanesEstudiosShowcaseBoxOldComponent,
        PlanesEstudioProfesorComponent,
        PlanEstudioSesionesProfesorComponent,
        PlanEstudioAplicadoSesionProfesorComponent,
        PlanEstudiosEvaluacionProfesorComponent,
        PlanEstudiosEvaluacionProfesorComponentTags,
        PlanEstudioNavbarComponent
    ],
    exports: [
        PlanEstudioComponent,
        PlanEstudiosComponent,
        PlanEstudiosToolNavbarComponent,
        PlanEstudioAplicadoSesionComponent,
        PlanesEstudioComponent,
        PlanEstudioSesionesComponent,
        PlanEstudioBoxComponent,
        PlanesEstudiosAlumnoComponent,
        PlanesEstudiosAsignaturaComponent,
        PlanesEstudiosAsignaturasComponent,
        DatosGeneralesPlanEstudioComponent,
        PlanEstudioSesionBoxComponent,
        PlanEstudioSesionMenuComponent,
        PlanEstudiosEvaluacionComponent,
        PlanEstudiosEvaluacionComponentTags,
        PlanEstudioAplicadosComponent,
        PlanesEstudiosShowcaseBoxComponent,
        PlanesEstudiosShowcaseBoxOldComponent,
        PlanesEstudioProfesorComponent,
        PlanEstudioSesionesProfesorComponent,
        PlanEstudioAplicadoSesionProfesorComponent,
        PlanEstudiosEvaluacionProfesorComponent,
        PlanEstudiosEvaluacionProfesorComponentTags,
        PlanEstudioNavbarComponent
    ]
})
export class PlanEstudiosModule {}
