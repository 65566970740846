import { SaveType, BaseModel } from "@puntaje/shared/core"
import { PlanPersonal } from "./plan_personales.model"
import { Usuario } from "./usuarios.model"
import { PlanPersonalEvaluacionInstancia } from "./plan_personal_evaluacion_instancias.model"
import { PlanPersonalMaterialInstancia } from "./plan_personal_material_instancias.model"

export class PlanPersonalInstancia extends BaseModel {
    public plan_personal_id: number
    public usuario_id: number
    public avance: number
    @SaveType(() => PlanPersonal) public plan_personal: PlanPersonal
    @SaveType(() => PlanPersonalEvaluacionInstancia)
    public plan_personal_evaluacion_instancias: PlanPersonalEvaluacionInstancia[]
    @SaveType(() => PlanPersonalMaterialInstancia)
    public plan_personal_material_instancias: PlanPersonalMaterialInstancia[]
    @SaveType(() => Usuario) public usuario: Usuario
}
