import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import {
    Establecimiento,
    Notificacion,
    Notificaciones,
    PlanPersonal,
    PlanPersonalSesion
} from "@puntaje/puntaje/api-services"
import { NotificacionesPendingService } from "../../notificaciones_pending.service"

@Component({
    selector: "notificacion-plan-personal-sesion-recordatorio",
    templateUrl: "./notificacion-plan-personal-sesion-recordatorio.component.html",
    styleUrls: ["../notificacion.component.scss"]
})
export class NotificacionPlanPersonalSesionRecordatorioComponent implements OnInit {
    @Input() notificacion: Notificacion
    @Input() enableMini: boolean = false
    @Input("disableMarkButton") disableMarkButton: boolean = false
    @Output() markAsNotPending: EventEmitter<any> = new EventEmitter<any>()

    usuario_remitente: string = ""
    establecimiento: Establecimiento
    asignatura: Asignatura
    planPersonal: PlanPersonal
    planPersonalSesion: PlanPersonalSesion
    data: any = {}

    constructor(
        protected notificacionesService: Notificaciones,
        protected notificacionesPendingService: NotificacionesPendingService
    ) {}

    ngOnInit() {
        if (this.notificacion) {
            this.notificacion.notificacion = JSON.parse(this.notificacion.notificacion)
            this.data = this.notificacion.notificacion as any
            this.establecimiento = this.data.establecimiento
            this.asignatura = this.data.asignatura
            this.usuario_remitente = this.data.usuario.nombre + " " + this.data.usuario.apellido_paterno
            this.planPersonalSesion = this.data.plan_personal_sesion
            this.planPersonal = this.data.plan_personal
        }
    }

    onClickLink() {
        let n = new Notificacion()
        n.fecha_visto = new Date()
        this.notificacionesService.update(this.notificacion.id, n).then(() => {
            this.notificacionesPendingService.update()
            this.markAsNotPending.emit(this.notificacion.id)
        })
    }
}
