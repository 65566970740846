import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core"

@Component({
    selector: "filter-list",
    templateUrl: "filter_list.component.html",
    styleUrls: ["filter_list.component.scss"]
})
export class FilterListComponent implements OnInit {
    @Input() title: string = "Filtros"
    @Input() values: string[]
    @Input() labels: string[]
    @Input() noPaddingLeft: boolean = false
    optionsDisabled: boolean = false

    filtersSelected: string[]

    @Output() filtrosChange: EventEmitter<string[]> = new EventEmitter<string[]>()

    ngOnInit() {
        this.filtersSelected = []
    }

    onClick(value: string) {
        if (this.filtersSelected.includes(value)) {
            this.filtersSelected = this.filtersSelected.filter(e => e !== value)
        } else {
            this.filtersSelected.push(value)
        }
        this.filtrosChange.emit(this.filtersSelected)
    }

    public disableOptions() {
        this.optionsDisabled = true
    }

    public enableOptions() {
        this.optionsDisabled = false
    }
}
