import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    Asignatura,
    Clasificacion,
    Clasificaciones,
    ClasificacionTipos,
    GeneradorInstrumentos
} from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Estadisticas, EvaluacionInstancia, EvaluacionInstancias } from "@puntaje/puntaje/api-services"
import { EstadisticasEvolutivasService } from "../estadisticas_evolutivas.service"
import { I18nService } from "@puntaje/shared/core"
import { Store, select } from "@ngrx/store"
import { State, selectSelectedAsignatura, selectSelectedEvaluacionTipo } from "@puntaje/puntaje/store"
import { Subscription, combineLatest } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    selector: "grafico-mi-progreso-multiple",
    templateUrl: "grafico_mi_progreso_multiple.component.html",
    styleUrls: ["grafico_mi_progreso_multiple.component.scss"]
})
export class GraficoMiProgresoMultipleComponent implements OnInit, OnChanges {
    timeFrameUTC: number[]
    toggleList: any
    colorList: any
    dataSets: any
    dataSetsClasificaciones: any[]
    escala: number[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() enableDemoMode: boolean = false
    @Input() innerTimeFrame: boolean = true
    @Input() withoutProgresoClasificacion: boolean = false
    enableResponsive: boolean = false

    @Input() userIds: number[]
    @Input() timeFrame: Date[]

    @Input() usuarioId: number
    @Input() nivelIds: number[]

    @Input() nombreClasificacionTipo: string = "eje" //esto esta feo

    @Input() evaluacionTipos: string[] = []
    evaluacionTipo: string
    asignatura: Asignatura
    evaluacionInstancias: EvaluacionInstancia[]
    evaluacionInstanciaById: { [id: number]: EvaluacionInstancia }

    subQuery: Subscription

    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(
        protected estadisticasApiService: EstadisticasEvolutivasService,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected estadisticasService: Estadisticas,
        protected clasificacionTiposService: ClasificacionTipos,
        protected clasificacionesService: Clasificaciones,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected cdr: ChangeDetectorRef,
        protected authService: AuthService,
        private translatorService: I18nService,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.subQuery = combineLatest(this.asignatura$, this.evaluacionTipo$).subscribe(
            ([asignatura, evaluacionTipo]) => {
                this.asignatura = asignatura
                this.evaluacionTipo = evaluacionTipo
                this.cdr.detectChanges()
                this.updateDate()
            }
        )
        this.enableResponsive = window.innerWidth <= 800
        this.loadingLayout.ready()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["userIds"] || changes["evaluacionTipo"] || changes["asignatura"] || changes["usuarioId"]) {
            this.updateDate()
        }
        if (changes["timeFrame"]) {
            this.timeFrameUTC = [this.timeFrame[0].getTime(), this.timeFrame[1].getTime()]
            this.updateDate()
        }
    }

    onResize(event) {
        this.enableResponsive = event.target.innerWidth <= 800
    }

    onTimeFrameChange(timeFrame: any) {
        this.timeFrame = timeFrame
        this.timeFrameUTC = [this.timeFrame[0].getTime(), this.timeFrame[1].getTime()]
        this.updateDate()

        this.cdr.detectChanges()
    }

    onAsignaturaChange(asignatura: Asignatura) {
        this.asignatura = asignatura

        this.updateDate()
    }

    async setData() {
        if (!this.usuarioId) {
            this.usuarioId = this.authService.getUserData().id
        }

        this.loadingLayout.standby()

        const fechaInicial = this.timeFrame[0].toISOString()
        const fechaFinal = this.timeFrame[1].toISOString()

        let generadorInstrumentoIds = this.asignatura.asignatura_plataformas
            .map(ap => ap.generador_instrumentos)
            .flat()
            .filter(gi => gi.tipo_instrumento.tipo_instrumento == this.evaluacionTipo)
            .map(gi => gi.id)

        if (
            this.nivelIds &&
            this.nivelIds.length > 0 &&
            config.evaluaciones[this.evaluacionTipo].clasificaciones.estadisticasEvolutivasNiveles
        ) {
            const generadorInstrumentoParams = {
                generador_instrumento: {
                    id: generadorInstrumentoIds
                },
                generador_instrumento_categoria: {
                    categoria_id: this.nivelIds
                }
            }

            const generadorInstrumentos = await this.generadorInstrumentosService.where(generadorInstrumentoParams)
            generadorInstrumentoIds = generadorInstrumentos.map(gi => gi.id)
        }

        const params = {
            evaluacion_instancia: {
                oficial: 1,
                usuario_id: this.usuarioId,
                lte: {
                    created_at: fechaFinal
                },
                gte: {
                    created_at: fechaInicial
                }
            },
            evaluacion: {
                sumativa: true
            },
            evaluacion_tipo: {
                evaluacion_tipo: this.evaluacionTipo
            },
            instrumento: {
                generador_instrumento_id: generadorInstrumentoIds
            }
        }

        const evaluacionInstancias = await this.evaluacionInstanciasService.where(params)
        this.evaluacionInstancias = evaluacionInstancias

        this.dataSets = [
            {
                nombre: this.asignatura["confByEvaluacionTipo"][this.evaluacionTipo].abreviacion,
                data: evaluacionInstancias.map(ei => {
                    return [new Date(ei.created_at).toLocaleDateString("es-CL"), ei.calificacion]
                })
            }
        ]

        const estParams = {
            collection: "EstadisticaInstanciaClasificacion",
            estadistica: {
                evaluacion_instancia_id: evaluacionInstancias.map(ei => ei.id)
            }
        }

        const estadisticas = await this.estadisticasService.wherePost(estParams)
        const estadisticasByEvaluacionInstanciaId = estadisticas.reduce((acc, e) => {
            acc[e.evaluacion_instancia_id] = e

            return acc
        }, {})

        let estadisticasEvolutivas = config.evaluaciones[this.evaluacionTipo].clasificaciones.estadisticasEvolutivas
        if (!Array.isArray(estadisticasEvolutivas)) {
            estadisticasEvolutivas = [estadisticasEvolutivas]
        }

        const clasificacionParams = {
            generador_instrumento_filtro_clasificacion: {
                generador_instrumento_id: generadorInstrumentoIds
            },
            group_by: estadisticasEvolutivas
        }
        const clasificaciones = await this.clasificacionesService.where(clasificacionParams)

        this.dataSetsClasificaciones = clasificaciones.map(clasificacion => {
            clasificacion.clasificaciones_hijas = clasificacion.clasificaciones_hijas || []
            return {
                ...this.getClasificacionDataSet(
                    clasificacion,
                    evaluacionInstancias,
                    estadisticasByEvaluacionInstanciaId
                ),
                dataClasificacionesHijas: clasificacion.clasificaciones_hijas.map(c =>
                    this.getClasificacionDataSet(c, evaluacionInstancias, estadisticasByEvaluacionInstanciaId)
                )
            }
        })

        this.loadingLayout.ready()
    }

    getClasificacionDataSet(
        clasificacion: Clasificacion,
        evaluacionInstancias: EvaluacionInstancia[],
        estadisticasByEvaluacionInstanciaId: any
    ) {
        return {
            titulo: clasificacion.clasificacion,
            descripcion: `${this.translatorService.translate("progreso.showcasebox.grafico.desenpenio_para")}  ${
                clasificacion.clasificacion
            }`,
            nombre: clasificacion.clasificacion,
            data: evaluacionInstancias
                .map(ei => {
                    const estadistica = estadisticasByEvaluacionInstanciaId[ei.id]
                    if (!estadistica || !estadistica.estadistica_clasificaciones) return null

                    const estadisticaClasificacion = estadistica.estadistica_clasificaciones.find(
                        ec => ec.clasificacion_id == clasificacion.id
                    )
                    if (!estadisticaClasificacion) return null

                    const { correctas, incorrectas, omitidas } = estadisticaClasificacion
                    const desempeno = (100 * correctas) / (correctas + incorrectas + omitidas)

                    return [new Date(ei.created_at).toLocaleDateString("es-CL"), desempeno]
                })
                .filter(d => d)
        }
    }

    updateDate() {
        if (this.timeFrame && this.asignatura && this.evaluacionTipo) {
            if (this.enableDemoMode) {
                this.setDemoData()
            } else {
                this.setData()
            }
        }
    }

    setDemoData() {
        // para probar timings
        let dataSetsDemo = [
            {
                nombre: "Matemáticas",
                data: [
                    [Date.UTC(2017, 1, 21), 4.0],
                    [Date.UTC(2017, 3, 21), 5.0]
                ]
            },
            {
                nombre: "Lenguaje",
                data: [
                    [Date.UTC(2017, 1, 26), 5.0],
                    [Date.UTC(2017, 3, 27), 5.5]
                ]
            },
            {
                nombre: "Historia",
                data: [
                    [Date.UTC(2017, 4, 1), 5.2],
                    [Date.UTC(2017, 6, 10), 4.5]
                ]
            },
            {
                nombre: "Ciencias",
                data: [
                    [Date.UTC(2017, 7, 26), 6.0],
                    [Date.UTC(2017, 8, 1), 6.5]
                ]
            }
        ]
        let remov = dataSetsDemo.length - this.toggleList.length
        for (let i = 0; i < remov; i++) {
            dataSetsDemo.pop()
        }
        this.dataSets = dataSetsDemo
    }
}
