import { Injectable, TemplateRef } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { LayoutService } from "../layout.service"

interface LoggedLayoutOptions {
    subtitle?: string
    freeContentStyle?: boolean
    hideTitleOnPrint?: boolean
    noTitleBar?: boolean
    noSpaceIfNoTitle?: boolean
    hideTitle?: boolean
    noTitle?: boolean
}

@Injectable({ providedIn: "root" })
export class LoggedLayoutService extends LayoutService<LoggedLayoutOptions> {
    defaultOptions: LoggedLayoutOptions = {
        subtitle: "",
        freeContentStyle: false,
        hideTitleOnPrint: false,
        noTitleBar: false,
        noSpaceIfNoTitle: false,
        noTitle: false,
        hideTitle: false
    }

    options$ = new BehaviorSubject<LoggedLayoutOptions>(this.defaultOptions)
}
