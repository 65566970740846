<div
    #flashMensajeError
    id="flashMensajeError"
    *ngIf="showed && statusText"
    class="flash {{ statusText }}"
    tabindex="-1"
>
    {{ messageText }}
    <button
        type="button"
        class="button-no-style pull-right"
        aria-label="cierra el mensaje"
        (click)="deleteFlashMessage()"
    >
        <span class="glyphicon glyphicon-remove"></span>
    </button>
</div>
