import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core"
import { FlashMessageService } from "./flash_message.service"
import { Subscription } from "rxjs"

@Component({
    selector: "flash-message",
    templateUrl: "flash_message.component.html",
    styleUrls: ["flash_message.component.scss"]
})
export class FlashMessageComponent implements OnInit {
    showed: boolean = true
    statusText: string
    messageText: string

    statusSub: Subscription
    messageSub: Subscription
    @ViewChild("flashMensajeError", { static: false }) mensajeElement: ElementRef

    constructor(public flashMessage: FlashMessageService, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.statusText = this.flashMessage.status
        this.messageText = this.flashMessage.message
        this.statusSub = this.flashMessage.status_subject.subscribe((value: string) => {
            this.statusText = value
            this.showed = !!value
            if (this.showed && this.statusText) {
                this.focusMessage()
            }
        })
        this.messageSub = this.flashMessage.message_subject.subscribe((value: string) => {
            this.messageText = value
            this.showed = !!value
        })
    }

    deleteFlashMessage() {
        this.showed = false
        this.flashMessage.clear()
    }

    show() {
        this.showed = true
    }

    ngOnDestroy() {
        this.flashMessage.clear()
        this.statusSub.unsubscribe()
        this.messageSub.unsubscribe()
    }

    /**
     * Focus the div message
     */
    private focusMessage() {
        this.cdr.detectChanges()
        this.mensajeElement.nativeElement.focus()
    }
}
