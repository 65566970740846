<div class="flex-wrap" [class.bigSize]="enableBigSize">
    <div class="left-info">
        <div *ngIf="showPosition" class="position-container" [class.with-buttons]="enableOrderEdit">
            <button
                class="order-btn"
                *ngIf="enableOrderEdit"
                [disabled]="changingOrder"
                [class.isHidden]="carreraSede.preferenciaCarreraSedes[0].orden <= 1"
                (click)="onChangePosition(-1)"
            >
                <fa name="arrow-up"></fa>
            </button>
            <span>{{ carreraSede.preferenciaCarreraSedes[0].orden }}</span>
            <button
                class="order-btn"
                *ngIf="enableOrderEdit"
                [disabled]="changingOrder"
                [class.isHidden]="isLast"
                (click)="onChangePosition(1)"
            >
                <fa name="arrow-down"></fa>
            </button>
        </div>
        <div class="logo-container">
            <img
                *ngIf="carreraSede.sede.universidad.logo"
                src="{{ carreraSede.sede.universidad.logo }}"
                alt="{{ carreraSede.carrera ? carreraSede.carrera.carrera : carreraSede.alias }} - {{
                    carreraSede.sede.universidad.universidad
                }}"
            />
            <ogr-icon *ngIf="!carreraSede.sede.universidad.logo" name="establecimiento-o"></ogr-icon>
        </div>
    </div>
    <div class="carrera-content">
        <h3 class="carrera-title">
            <a [routerLink]="['/carrera_sedes', carreraSede.id]">
                {{ carreraSede.carrera ? carreraSede.carrera.carrera : carreraSede.alias }}
            </a>
            <span class="like-container likes-carrera">
                <fa class="icono" name="heart-o"></fa>
                {{ carreraSede.likesCarrera | easyplaceholder: 0 }}
            </span>
        </h3>
        <h4 class="universidad">
            {{ carreraSede.sede.universidad.universidad }}
            <span class="like-container likes-universidad">
                <fa class="icono" name="heart-o"></fa>
                {{ carreraSede.sede.universidad.likesUniversidad | easyplaceholder: 0 }}
            </span>
        </h4>
        <p class="sede">{{ carreraSede.sede.sede }}</p>
    </div>
</div>
<ponderaciones-bar-divider
    [carreraSede]="carreraSede"
    [homeView]="true"
    *ngIf="showPonderaciones && !disablePonderaciones"
    class="ponderaciones list-view"
    resize-aware
    (currentSize)="adaptToSize($event)"
    [class.bigSize]="enableBigSize"
></ponderaciones-bar-divider>
