import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { NgModel } from "@angular/forms"
import { Contestable, Alternativa, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { GrupoPreguntaTextoService } from "@puntaje/shared/core"
import { empty, fromEvent, Observable, Subscription } from "rxjs"
import { switchMap } from "rxjs/operators"
import { RealizarEnsayoService } from "../realizar_ensayo.service"

@Component({
    selector: "contestable-sm",
    templateUrl: "./contestable-sm.component.html",
    styleUrls: ["./contestable-sm.component.scss"]
})
export class ContestableSmComponent implements OnInit {
    public static instances: number = 0
    private _instanceNumber: number

    @Input() disableOmitida: boolean
    // TODO: hacer que no dependa del indice, dado que debiese ser independiente de la prueba.
    @Input() index: number
    @Input() evaluacionTipo: string
    @Input() grupoPreguntaId: number
    @Input() contestable: Contestable
    @Input() alternativaId: number
    @Input() generadorInstrumento: GeneradorInstrumento
    @Output() alternativaIdChange: EventEmitter<number> = new EventEmitter()

    keydownSubscription: Subscription

    constructor(
        public grupoPreguntaTextoService: GrupoPreguntaTextoService,
        public realizarEnsayoService: RealizarEnsayoService
    ) {}

    ngOnInit() {
        this.keydownSubscription = this.realizarEnsayoService.currentItem$
            .pipe(
                switchMap(item =>
                    item.numero == this.index ? (fromEvent(document, "keydown") as Observable<KeyboardEvent>) : empty()
                )
            )
            .subscribe((event: KeyboardEvent) => {
                let index
                switch (event.code) {
                    case "KeyA":
                        index = 0
                        break
                    case "KeyB":
                        index = 1
                        break
                    case "KeyC":
                        index = 2
                        break
                    case "KeyD":
                        index = 3
                        break
                    case "KeyE":
                        index = 4
                        break
                    default:
                        return
                }

                let letraRepetida = this.validarLetra(this.alternativaId, this.contestable, index)

                const alternativa = this.contestable.getAlternativas(this.generadorInstrumento)[index]

                if (letraRepetida) {
                    this.onChangeAlternativa(null)
                } else if (alternativa) {
                    this.onChangeAlternativa(alternativa.id)
                }
            })
    }

    public get instanceNumber() {
        if (!this._instanceNumber) {
            ContestableSmComponent.instances++
            this._instanceNumber = ContestableSmComponent.instances
        }

        return this._instanceNumber
    }

    checkIfReset(control: NgModel, alternativaId: number, event) {
        if (control.value && alternativaId == control.value) {
            control.reset()
            setTimeout(() => (event.target.checked = false), 20)
            this.onChangeAlternativa(null)
            event.preventDefault()
        }
    }

    selectedFromKeyboard(e, alternativaId) {
        if (e.keyCode == 13 && e.code == "Enter") {
            this.onChangeAlternativa(alternativaId)
        }
    }

    onChangeAlternativa(alternativaId) {
        this.alternativaIdChange.emit(alternativaId)

        //desfocusea las alternativas para que los niños puedan usar las flechas para serguir navegando la página
        if (typeof alternativaId == "number") {
            const element = document.getElementById("alternativa_" + alternativaId)
            if (element) {
                element.blur()
            }
        } else if (typeof alternativaId == "string") {
            document.getElementById("alternativa_" + alternativaId + "_" + this.contestable.id).blur()
        }
    }

    validarLetra(id, contestable, index) {
        if (id > 0) {
            return id == contestable.alternativasProcesadas[index].id ? true : false
        } else {
            return false
        }
    }

    getLetra(pos: number) {
        return Alternativa.getLetra(pos)
    }

    ngOnDestroy(): void {
        if (this.keydownSubscription) {
            this.keydownSubscription.unsubscribe()
        }
    }
}
