<ng-container [ngSwitch]="tipo">
    <ng-container *ngSwitchCase="'otros'">
        <ogr-icon class="icono-archivo" name="archivo"></ogr-icon>
    </ng-container>
    <ng-container *ngSwitchCase="'mp4'">
        <ogr-icon class="icono-archivo" name="video"></ogr-icon>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <img [src]="thumbnailUrl" />
    </ng-container>
</ng-container>
