<loading-layout #loadingLayout>
    <ng-container *ngFor="let c of filtroClasificacionTiposConfiguracion">
        <clasificaciones-checkboxes
            *ngIf="clasificacionesByTipo && clasificacionesByTipo[c.clasificacionTipo]"
            [checkAll]="checkAllCheckBoxesGenerador"
            [enableCantidadPreguntas]="enableCantidadPreguntas"
            [enableObjetivoPriorizado]="enableObjetivoPriorizado"
            (emitToParent)="emitToParent()"
            [id]="customId ? customId + '-' + c.htmlId : c.htmlId"
            [name]="names[c.name]"
            [clasificaciones]="clasificacionesByTipo[c.clasificacionTipo]"
            [hasHijos]="c.hasHijos"
            [tipoInstrumento]="tipoInstrumento"
            [showOpened]="filtroClasificacionTiposConfiguracion.length == 1"
        ></clasificaciones-checkboxes>
    </ng-container>
</loading-layout>
