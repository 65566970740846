import { Component, OnDestroy, OnInit, Type } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import { TermsAndConditionsComponent as TermsConditionsComponent } from "@puntaje/puntaje/new-modules/landing"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "terms_and_conditions.component.html"
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {
    subData: Subscription
    component: Type<any> = TermsConditionsComponent
    title: string

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.landing.terminos_y_condiciones")
        this.subData = this.route.data.subscribe(data => {
            this.component = data.component ?? this.component
            this.title = data.title || null
        })
    }

    ngOnDestroy() {
        this.subData.unsubscribe()
    }
}
