import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { Instrumento } from "./instrumentos.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class Instrumentos extends PuntajeBaseService<Instrumento> {
    tableName = "instrumentos"
    singularTableName = "instrumento"
    modelClass = Instrumento

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public desdeLote(objectQuery: any) {
        this.restQuery = ""

        return this.all().concatExtra("desde_lote").post(objectQuery)
    }

    public desdeListaPreguntas(objectQuery: any) {
        this.restQuery = ""

        return this.all().concatExtra("desde_lista_preguntas").post(objectQuery)
    }

    public savePropio(objectQuery: any) {
        return this.all().concatExtra("create_propio").post(objectQuery)
    }

    public updatePropio(id: number, params: any) {
        return this.one(id).concatExtra("propio").patch(params)
    }

    public csvTablaEspecificaciones(instrumentoId: number, params: any = null) {
        this.enableIgnoreModel()
        return this.one(instrumentoId)
            .concatExtra("csv_tabla_especificaciones")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }

    public imprimir(evaluacionId: number, params: any = null) {
        this.enableIgnoreModel()

        return this.one(evaluacionId)
            .concatExtra("imprimir")
            .get(params)
            .then(resp => {
                this.disableIgnoreModel()

                return resp
            })
    }
}
