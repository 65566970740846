import { Injectable } from "@angular/core"
import { CarrerasBaseService } from "./carreras_base.service"
import { SolicitudInformacion } from "./solicitud_informacion.model"

@Injectable()
export class SolicitudInformaciones extends CarrerasBaseService<SolicitudInformacion> {
    tableName = "solicitud_informaciones"
    singularTableName = "solicitud_informacion"
    modelClass = SolicitudInformacion
}
