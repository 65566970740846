import { Component, ViewChild, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import {
    Evaluacion,
    Evaluaciones,
    EvaluacionTiempo,
    PlanPersonales,
    PlanPersonal,
    EvaluacionInstancia,
    GeneradorInstrumentoDiagnosticoEvaluaciones,
    GeneradorInstrumentoDiagnosticoEvaluacion
} from "@puntaje/puntaje/api-services"
import { AppConfig, AppEnv, AuthService, CheckAchievement } from "@puntaje/shared/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { EvaluacionSecuencialComponent } from "@puntaje/puntaje/new-modules/ensayos"
import { Store } from "@ngrx/store"
import { State, SetActual, CrearAlertaLogros } from "@puntaje/puntaje/store"
import { SetSeeingIndex, SetRespuestasActual, ResetDudaPreguntas, SetItems } from "@puntaje/puntaje/store"
import { GeneradorInstrumentos, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "generar-evaluacion-diagnostico",
    templateUrl: "generar_evaluacion_diagnostico.component.html",
    styleUrls: ["generar_evaluacion_diagnostico.component.scss"]
})
export class GenerarEvaluacionDiagnosticoComponent implements OnInit {
    @Input() asignaturaId: number
    @Input() generadorInstrumentoId: number
    @Input() planPersonalId: number
    @Input() rutaRedirect: string = "/planes_personales"
    @Input() redirectToPlan: boolean = false
    @Input() enableReporte: boolean = true
    evaluacionId: number
    evaluacion: Evaluacion
    @Input() tipoEvaluacion: string
    @Output() onReadyEvaluacion = new EventEmitter()
    enablePreguntas: boolean = false
    @Input() onSelectPregunta: EventEmitter<any>
    @Output() showPreguntasMenuChange: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    enableLoadingMessage: boolean = false
    @Input() generarDesdeEvaluacion: boolean = false

    loadClock: boolean = false
    evaluacionTiempo: EvaluacionTiempo

    @ViewChild(EvaluacionSecuencialComponent)
    evaluacionSecuencial: EvaluacionSecuencialComponent

    generadorInstrumento: GeneradorInstrumento

    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected evaluacionesService: Evaluaciones,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef,
        protected planPersonalService: PlanPersonales,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected environment: AppEnv,
        protected config: AppConfig,
        protected generadorInstrumentoDiagnosticoEvaluacionesService: GeneradorInstrumentoDiagnosticoEvaluaciones
    ) {}

    ngOnInit() {
        this.loadingLayout.standby()
        this.enableLoadingMessage = true

        this.store.dispatch(new SetActual({ actual: null }))
        this.store.dispatch(new SetRespuestasActual({ respuestasActual: [] }))
        this.store.dispatch(new SetSeeingIndex(0))

        if (this.generarDesdeEvaluacion) {
            this.setDiagnostico()
        } else {
            this.buildDiagnostico()
        }
    }

    setDiagnostico() {
        this.generadorInstrumentoDiagnosticoEvaluacionesService
            .where({ generador_instrumento_diagnostico_evaluacion: { generador_instrumento_id: this.generadorInstrumentoId } })
            .then((giDiagnosticoEvaluacion: GeneradorInstrumentoDiagnosticoEvaluacion[], total: number) => {
                if (giDiagnosticoEvaluacion && giDiagnosticoEvaluacion.length) {
                    this.evaluacionId = giDiagnosticoEvaluacion[0].evaluacion_id
                    this.enablePreguntas = true
                    this.loadingLayout.ready()
                } else {
                    this.buildDiagnostico()
                }
            })
    }

    buildDiagnostico() {
        this.generadorInstrumentosService.find(this.generadorInstrumentoId).then(generadorInstrumento => {
            this.generadorInstrumento = generadorInstrumento
            let evaluacion = new Evaluacion() as any
            evaluacion.tiempo = 0
            evaluacion.asignatura_id = this.asignaturaId
            evaluacion.generador_instrumento_id = this.generadorInstrumentoId
            evaluacion.numero_preguntas = this.generadorInstrumento.numero_preguntas //this.numeroPreguntas;
            evaluacion.evaluacion_tipo = this.tipoEvaluacion
            this.evaluacionesService.save(evaluacion).then((e: Evaluacion) => {
                evaluacion.id = e.id
                this.evaluacionId = e.id
                this.loadingLayout.ready()
                this.enablePreguntas = true
            })    
        })
    }

    onShowPreguntasMenuChange(showPreguntasMenu) {
        this.showPreguntasMenuChange.emit(showPreguntasMenu)
    }

    readyEvaluacion(evaluacion) {
        this.evaluacion = evaluacion
        this.enableLoadingMessage = false
        this.onReadyEvaluacion.emit(this.evaluacion)
    }

    onLoadClockChange(loadClock) {
        this.loadClock = loadClock
    }

    onTiempoReady = function (ensayoTiempo) {
        this.evaluacionTiempo = ensayoTiempo
        this.cdr.detectChanges()

        this.evaluacionSecuencial.getRespuestasFromLocal()
        this.evaluacionSecuencial.displayPreguntas = true
    }

    onEvaluationSent(evaluacionInstancia: EvaluacionInstancia) {
        this.loadingLayout.standby()

        this.checkDiagnostico(evaluacionInstancia)

        this.resetStore()
        this.loadClock = false
        if (this.redirectToPlan) {
            let generador = evaluacionInstancia.evaluacion.instrumento.generador_instrumento_id
            this.checkPlanesPersonalesExistentes([generador], evaluacionInstancia.usuario_id).then(
                (planPersonales: PlanPersonal[]) => {
                    if (planPersonales) {
                        this.router.navigate(["plan_personal", planPersonales[0].id])
                    } else {
                        this.router.navigate([this.rutaRedirect])
                    }
                }
            )
        } else {
            this.router.navigate([this.rutaRedirect], {
                queryParams: { asignatura_id: this.asignaturaId, generador_instrumento_id: this.generadorInstrumentoId }
            })
        }
    }

    @CheckAchievement("PN_PLAN_CLASES_DIAGNOSTICO", CrearAlertaLogros)
    checkDiagnostico(evaluacionInstancia: EvaluacionInstancia) {
        return evaluacionInstancia
    }

    private checkPlanesPersonalesExistentes = (generadoresId: number[], usuarioId: number) => {
        return this.planPersonalService
            .wherePost(this.planPersonalParams(generadoresId, usuarioId))
            .then((planesPersonales: PlanPersonal[]) => {
                if (planesPersonales.length > 0) {
                    return planesPersonales
                } else {
                    return null
                }
            })
    }

    private planPersonalParams = (generadoresId: number[], usuarioId: number) => {
        return {
            plan_personal: {
                generador_instrumento_id: generadoresId
            },
            plan_personal_usuario: {
                usuario_id: usuarioId,
                receptor_id: usuarioId,
                receptor_type: "Usuario"
            }
        }
    }

    resetStore() {
        this.store.dispatch(new SetActual({ actual: null }))
        this.store.dispatch(new SetItems({ items: [] }))
        this.store.dispatch(new SetRespuestasActual({ respuestasActual: [] }))
        this.store.dispatch(new ResetDudaPreguntas())
        this.store.dispatch(new SetSeeingIndex(0))
    }
}
