import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Lugar } from "./lugares.model"
import { NoticiasPerfil } from "./noticias_perfil.model"
import { NoticiaInstancia } from "./noticia_instancias.model"
import { NoticiaTipo } from "./noticia_tipos.model"
import { NoticiaUsuario } from "./noticia_usuarios.model"

export class Noticia extends BaseModel {
    public noticia: string
    public titulo: string
    public destacado: boolean
    public noticias_perfil_id: number
    public noticia_tipo_id: number
    public fecha_inicio: string
    public fecha_termino: string
    public receptor: BaseModel
    public receptor_type: string
    public receptor_id: number
    public tipo: "login" | "una_sola_vez" | "ninguno"
    public updated_at: string | Date
    public lugar_id: number

    @SaveType(() => NoticiasPerfil) public noticias_perfil: NoticiasPerfil
    @SaveType(() => NoticiaTipo) public noticia_tipo: NoticiaTipo
    @SaveType(() => NoticiaInstancia) public noticia_instancias: NoticiaInstancia[]
    @SaveType(() => NoticiaUsuario) noticia_usuarios: NoticiaUsuario[] = []
    @SaveType(() => Lugar) lugar: Lugar

    constructor() {
        super()
        this.noticias_perfil = new NoticiasPerfil()
    }

    public toString() {
        return "(" + this.noticia + "," + this.noticias_perfil_id + ")"
    }
}
