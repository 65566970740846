import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Evaluacion } from "./evaluaciones.model"
import { Usuario } from "./usuarios.model"
import { EvaluacionUsuario } from "./evaluacion_usuarios.model"

export class LogEvaluacion extends BaseModel {
    public static className: string = "LogEvaluacion"

    evaluacion_id: number
    usuario_id: number
    action: string
    options: JSON
    created_at: Date
    updated_at: Date

    realizado_por: string

    @SaveType(() => Evaluacion) evaluacion: Evaluacion
    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => EvaluacionUsuario) evaluacionUsuario: EvaluacionUsuario

    public toString() {
        return this.evaluacion_id
    }
}
