import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Evaluacion } from "./evaluaciones.model"
import { Material } from "@puntaje/nebulosa/api-services"
import { PlanPersonalEvaluacionInstancia } from "./plan_personal_evaluacion_instancias.model"
import { PlanPersonalMaterialInstancia } from "./plan_personal_material_instancias.model"
import { PlanPersonalContenidoGrupoUsuario } from "./plan_personal_contenido_grupo_usuarios.model"

export class PlanPersonalContenido extends BaseModel {
    public plan_personal_sesion_id: number
    public tipo: string
    public tipo_id: number
    public tiempo: number
    public avance: boolean
    public orden: number

    @SaveType(() => Material) public material: Material
    @SaveType(() => Evaluacion) public evaluacion: Evaluacion
    @SaveType(() => PlanPersonalEvaluacionInstancia)
    public plan_personal_evaluacion_instancias: PlanPersonalEvaluacionInstancia[]
    @SaveType(() => PlanPersonalMaterialInstancia)
    public plan_personal_material_instancias: PlanPersonalMaterialInstancia[]
    @SaveType(() => PlanPersonalContenidoGrupoUsuario)
    public plan_personal_contenido_grupo_usuarios: PlanPersonalContenidoGrupoUsuario[]
}
