import { Component, EventEmitter, ViewChild, Injector, ChangeDetectorRef } from "@angular/core"
import { BaseRegisterCompletarSocialComponent } from "../base_register_completar_social.component"
import { GenericModalComponent, AuthService, SessionService, BaseForm } from "@puntaje/shared/core"
import { Usuarios, GrupoUsuarios, Establecimientos, Lugares } from "@puntaje/puntaje/api-services"
import { Router } from "@angular/router"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "usuario-completar-social-aprendolibre-colombia",
    templateUrl: "./register-completar-social-aprendolibre-colombia.component.html"
})
export class RegisterCompletarSocialAprendolibreColombiaComponent extends BaseRegisterCompletarSocialComponent {
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    openModal: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        usuariosService: Usuarios,
        authService: AuthService,
        sessionService: SessionService,
        router: Router,
        injector: Injector,
        grupoUsuariosService: GrupoUsuarios,
        cdr: ChangeDetectorRef,
        establecimientosService: Establecimientos,
        lugaresService: Lugares,
        clasificacionesService: Clasificaciones
    ) {
        super(
            usuariosService,
            authService,
            sessionService,
            router,
            injector,
            grupoUsuariosService,
            cdr,
            establecimientosService,
            lugaresService,
            clasificacionesService
        )
    }
}
