<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <!-- Titulo -->
        <cui-section-headline *ngIf="!hideTitle">{{ ayuda.ayuda }}</cui-section-headline>
        <!-- Comentarios -->
        <div class="comentario-container" *ngIf="ayuda.comentarios">
            <p>{{ ayuda.comentarios }}</p>
        </div>
        <!--Opciones-->
        <div class="clearfix options-container">
            <cui-button *ngIf="showVolver" (click)="goBack()" size="small" class="pull-left margin-right margin-bottom">
                <span class="glyphicon glyphicon-chevron-left left-icon"></span>
                {{ "ayudas.ayuda.volver" | t }}
            </cui-button>
            <cui-button-link
                *ngIf="isFileLink(ayuda) && isArchivo"
                [href]="ayuda.url"
                [isDownload]="true"
                size="small"
                class="pull-right margin-bottom"
            >
                <span class="glyphicon glyphicon-cloud-download left-icon"></span>
                {{ "ayudas.ayuda.descargar" | t }}
            </cui-button-link>
            <cui-button-link
                *ngIf="!isFileLink(ayuda) && isVideo && showYoutubeButton"
                [href]="ayuda.url"
                size="small"
                targetValue="_blank"
                class="pull-right btn-video"
            >
                <fa name="youtube-play" class="youtube-btn left-icon"></fa>
                {{ "ayudas.ayuda.ver_youtube" | t }}
            </cui-button-link>
            <cui-button-link *ngIf="isHTML" (click)="printElem(ayuda)" size="small" class="pull-right margin-bottom">
                <fa name="print" class="left-icon"></fa>
                {{ "ayudas.ayuda.imprimir" | t }}
            </cui-button-link>
        </div>
        <!-- Previsualización -->
        <ayuda-preview [ayuda]="ayuda"></ayuda-preview>
        <!-- <sentiment [object]="ayuda"></sentiment> -->
    </div>
</loading-layout>
