import { Component } from "@angular/core"

@Component({
    selector: "ll-topside, rl-topside, l-topside, el-topside",
    template: `
        <l-slot-content name="topside">
            <ng-content></ng-content>
        </l-slot-content>
    `
})
export class LTopsideComponent {}
