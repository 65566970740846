<div class="duda-box" *ngIf="duda" (click)="goToDuda()">
    <h2>
        <a routerLink="/dudas/{{ duda.id }}">{{ duda.duda }}</a>
    </h2>
    <p class="thread-info">
        {{ "home.showcasebox.dudas_recientes.creado_por" | t }}:&nbsp;
        <a class="thread-author" routerLink="/usuarios/{{ duda.usuario_id }}">{{ duda.usuario.nombreCompleto() }}</a>
        &nbsp;
        <span class="thread-time">{{ duda.created_at | fechaAtras }}</span>
    </p>
    <p class="category-info">
        <span class="categoria" *ngFor="let c of duda.duda_categorias">{{ c.duda_categoria }}</span>
    </p>
    <div class="bottom-info clearfix">
        <p *ngIf="hasSolution" class="solucionada">
            {{ "home.showcasebox.dudas_recientes.solucionada" | t }}
            <ogr-icon class="icono" name="ticket-circle"></ogr-icon>
        </p>
        <p *ngIf="!hasSolution && hasAnswer" class="contestada">
            {{ "home.showcasebox.dudas_recientes.contestada" | t: {resp: duda.participacion} }}
        </p>
        <p *ngIf="!hasSolution && !hasAnswer" class="sin-contestar">
            {{ "home.showcasebox.dudas_recientes.pendiente" | t }}
        </p>
    </div>
</div>
