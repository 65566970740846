<div *ngIf="useIdentificadorOnly; else useIdentificador">
    <p>{{ "landing.recuperar_password.instruccion_email_identificador" | t: { identificador: identificadorPais } }}</p>
</div>
<ng-template #useIdentificador>
    <p>{{ "landing.recuperar_password.instruccion_email" | t: { identificador: identificadorPais } }}</p>
</ng-template>
<p *ngIf="!useIdentificador">{{ "landing.recuperar_password.instruccion_email_no_identificador" | t }}</p>
<flash-message></flash-message>
<form novalidate [formGroup]="form">
    <div class="form-group">
        <input
            aria-label="email"
            id="email"
            type="text"
            formControlName="email"
            class="form-control"
            [placeholder]="placeholder"
            [attr.aria-describedby]="messageShowed ? 'flashMensajeError' : ''"
            [attr.aria-invalid]="!this.valid"
            (change)="checkEmail()"
        />
    </div>
    <cui-button-bar>
        <cui-button-link
            class="right-side"
            buttonType="cancel"
            href="javascript:void(0)"
            [routerLinkValue]="['']"
            type="button"
            id="btn-volver"
        >
            {{ "landing.recuperar_password.boton_volver" | t }}
        </cui-button-link>
        <cui-button
            class="right-side"
            type="button"
            id="btn-continuar"
            (click)="canRecoverPassword()"
            [disabled]="sending"
        >
            {{
                sent
                    ? ("landing.recuperar_password.boton_reenviar" | t)
                    : ("landing.recuperar_password.boton_continuar" | t)
            }}
        </cui-button>
    </cui-button-bar>
</form>

<modal-select-tipo-envio (tipo)="handleRecovery($event)" [telefono]="telefonoHolder"></modal-select-tipo-envio>

<div class="forgot-email" *ngIf="!hideForgotPassMessageContacto && messageShowed">
    <p class="custom-error">
        <span
            *ngIf="disableForgotMessageContactoModal"
            [innerHTML]="
                'landing.recuperar_password.mensaje_contacto_no_modal'
                    | t: { email: config.plataforma.info?.soporte?.mail }
                    | trustedhtml
            "
        ></span>
        <ng-container *ngIf="!disableForgotMessageContactoModal">
            ¿No recibiste el correo?
            <br />
            Para acceder a tu cuenta ponte en contacto con nosotros
            <a href="javascript:void(0)" (click)="openContactoRegistro()">aquí</a>
        </ng-container>
    </p>
</div>

<contacto-registro></contacto-registro>
