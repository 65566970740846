import { NebuModule } from "@puntaje/nebulosa/api-services"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import {
    UtilModule,
    FormModule,
    TranslationsModule,
    SorterModule,
    PaginatorModule,
    ModelViewModule
} from "@puntaje/shared/core"
import { CommonModule } from "@angular/common"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { ModalModule } from "ngx-bootstrap/modal"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { registerLocaleData } from "@angular/common"
import localeEs from "@angular/common/locales/es"
import { CUIInputsModule, CUITextsModule } from "@puntaje/shared/cui"
import { CalendarModule, DateAdapter } from "angular-calendar"
import { adapterFactory } from "angular-calendar/date-adapters/date-fns"
import { FiltroEvaluacionTipoComponent } from "./filtro_evaluacion_tipo.component"
import { MenuAsignaturasHorizontalComponent } from "./menu_asignaturas_horizontal.component"
import { SimpleSearchFilterComponent } from "./simple_search_filter.component"
import { FiltroPeriodoComponent } from "./filtro_periodo.component"
import { PeriodosService } from "./periodos.service"
import { EstrellasRatingComponent } from "./estrellas_rating.component"
import { RibbonFlagComponent } from "./ribbon_flag.component"
import { PopupGrupoUsuariosComponent } from "./popup_grupo_usuarios.component"
import { AllReadyService } from "./all_ready.service"
import { LongWaitLoadingModalComponent } from "./long_wait_loading_modal.component"
import { GrupoPreguntaComponent } from "./grupo_pregunta.component"
import { SubirRespuestasValorFeedbackComponent } from "./subir_respuestas_valor_feedback.component"
import { CalendarioComponent } from "./calendario/calendario.component"
import { CalendarEventMiniComponent } from "./calendar-templates/calendar-event-mini.component"
import { CalendarEventComponent } from "./calendar-templates/calendar-event.component"
import { CalendarioActividadesComponent } from "./calendario-actividades/calendario-actividades.component"
import { CalendarioSemanalShowcaseBoxComponent } from "./calendario-semanal-showcasebox/calendario-semanal-showcasebox.component"
import { RouterModule } from "@angular/router"
import { PendientesShowcaseBoxComponent } from "./pendientes-showcase-box/pendientes-showcase-box.component"
import { PreguntaTaxativoComponent } from "./pregunta-taxativo/pregunta-taxativo.component"
import { GraficoDesempenoClasificacionComponent } from "./grafico-desempeno-clasificacion/grafico_desempeno_clasificacion.component"
import { EstadisticasGraficoComponent } from "./estadisticas-graficos/estadisticas_grafico.component"
import { ResumenEstadisticasEvaluacionComponent } from "./resumen-estadisticas-evaluacion/resumen-estadisticas-evaluacion.component"
import { Ng2HighchartsModule } from "@ogr-sa/ng2-highcharts"
import { MenuEvaluacionTipoAsignaturasComponent } from "./menu-evaluacion-tipo-asignaturas/menu-evaluacion-tipo-asignaturas.component"
import { MenuEvaluacionTipoComponent } from "./menu-evaluacion-tipo/menu-evaluacion-tipo.component"
import { RetroalimentacionCompartirComponent } from "./retroalimentacion-compartir/retroalimentacion-compartir.component"
import { CrearDudaComponent } from "./crear-duda/crear-duda.component"
import { BtnGenerarReforzamientosComponent } from "./btn-generar-reforzamientos/btn-generar-reforzamientos.component"
import { AlertasService } from "./alertas/alertas.service"
import { AlertaBoxComponent } from "./alertas/alerta_box.component"
import { CheckStreamingOnlineDirective } from "./alertas/check_streaming_online.directive"
import { ReportComponent } from "./report/report.component"
import { MenuComponent } from "./menu/menu.component"
import { SelectContenidosPlanificaciones } from "./select-contenidos-planificaciones.service"
import { PlanPersonalesBuscadorService } from "./plan-personales-buscador.service"
import { ModalInstructivoSubirRespuestasComponent } from "./modal-instructivo-subir-respuestas/modal-instructivo-subir-respuestas.component"
import { SolucionComponent } from "./solucion/solucion.component"
import { UsuariosTramosComponent } from "./usuarios-tramos/usuarios-tramos.component"
import { WhatsappTicketComponent } from "./whatsapp-ticket/whatsapp-ticket.component"

registerLocaleData(localeEs)

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        UtilModule,
        CommonsLayoutsModule,
        Angular2FontawesomeModule,
        FormModule,
        ModalModule,
        ProgressbarModule,
        PopoverModule,
        TooltipModule,
        SorterModule,
        PaginatorModule,
        NebuModule,
        TranslationsModule,
        CalendarModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        Ng2HighchartsModule,
        ModelViewModule,
        CUIInputsModule,
        CUITextsModule
        // BrowserAnimationsModule
    ],
    declarations: [
        FiltroEvaluacionTipoComponent,
        MenuAsignaturasHorizontalComponent,
        SimpleSearchFilterComponent,
        FiltroPeriodoComponent,
        EstrellasRatingComponent,
        RibbonFlagComponent,
        PopupGrupoUsuariosComponent,
        LongWaitLoadingModalComponent,
        GrupoPreguntaComponent,
        SubirRespuestasValorFeedbackComponent,
        CalendarioComponent,
        CalendarEventMiniComponent,
        CalendarEventComponent,
        CalendarioActividadesComponent,
        CalendarioSemanalShowcaseBoxComponent,
        PendientesShowcaseBoxComponent,
        PreguntaTaxativoComponent,
        GraficoDesempenoClasificacionComponent,
        EstadisticasGraficoComponent,
        ResumenEstadisticasEvaluacionComponent,
        MenuEvaluacionTipoAsignaturasComponent,
        MenuEvaluacionTipoComponent,
        RetroalimentacionCompartirComponent,
        CrearDudaComponent,
        BtnGenerarReforzamientosComponent,
        AlertaBoxComponent,
        CheckStreamingOnlineDirective,
        ReportComponent,
        MenuComponent,
        ModalInstructivoSubirRespuestasComponent,
        SolucionComponent,
        UsuariosTramosComponent,
        WhatsappTicketComponent
    ],
    exports: [
        FiltroEvaluacionTipoComponent,
        MenuAsignaturasHorizontalComponent,
        SimpleSearchFilterComponent,
        FiltroPeriodoComponent,
        EstrellasRatingComponent,
        RibbonFlagComponent,
        PopupGrupoUsuariosComponent,
        LongWaitLoadingModalComponent,
        GrupoPreguntaComponent,
        PreguntaTaxativoComponent,
        SubirRespuestasValorFeedbackComponent,
        CalendarioComponent,
        CalendarEventMiniComponent,
        CalendarEventComponent,
        CalendarioActividadesComponent,
        CalendarioSemanalShowcaseBoxComponent,
        PendientesShowcaseBoxComponent,
        GraficoDesempenoClasificacionComponent,
        EstadisticasGraficoComponent,
        ResumenEstadisticasEvaluacionComponent,
        MenuEvaluacionTipoAsignaturasComponent,
        MenuEvaluacionTipoComponent,
        RetroalimentacionCompartirComponent,
        CrearDudaComponent,
        BtnGenerarReforzamientosComponent,
        AlertaBoxComponent,
        CheckStreamingOnlineDirective,
        ReportComponent,
        MenuComponent,
        ModalInstructivoSubirRespuestasComponent,
        SolucionComponent,
        UsuariosTramosComponent,
        WhatsappTicketComponent
    ],
    providers: []
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                PeriodosService,
                AllReadyService,
                AlertasService,
                SelectContenidosPlanificaciones,
                PlanPersonalesBuscadorService
            ]
        }
    }
}
