import { Component, HostBinding, OnInit, ViewChild } from "@angular/core"
import { Establecimiento, Establecimientos, Usuarios, Usuario } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { EstablecimientosAdministrarService } from "../establecimientos_administrar.service"
import { Router } from "@angular/router"

@Component({
    selector: "lista-establecimientos-profesor",
    templateUrl: "./lista_establecimientos_profesor.component.html",
    styleUrls: ["./lista_establecimientos_profesor.component.scss"]
})
export class ListaEstablecimientosProfesorComponent implements OnInit {
    establecimientos: Establecimiento[] = []
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    config: typeof config = config
    @HostBinding("class") innerClass = "showcasebox_style_1_establecimientos"

    constructor(
        protected establecimientosService: Establecimientos,
        protected usuariosService: Usuarios,
        protected auth: AuthService,
        protected establecimientosAdministrarService: EstablecimientosAdministrarService,
        protected router: Router
    ) {}

    ngOnInit() {
        // this.establecimientosService.where({include: "asociaciones", establecimiento: {id: this.auth.getEstablecimientos()}}).then((establecimientos: Establecimiento[]) => {
        //     this.establecimientos = establecimientos;
        //     this.loadingLayout.ready();
        // });
        this.setData()
    }

    goToDetails(establecimientoId: number) {
        this.establecimientosAdministrarService.setFiltrosId(establecimientoId)
        this.router.navigate(["/establecimientos/administrar"])
    }

    setData = (page: number = 1, per: number = 10) => {
        return this.establecimientosService
            .where({ include: "asociaciones", establecimiento: { id: this.auth.getEstablecimientos(), activo: 1 } })
            .then((establecimientos: Establecimiento[], total: number) => {
                this.establecimientos = establecimientos
                this.loadingLayout.ready()
                return total
            })
    }
}
