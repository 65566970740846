import { BaseModel, SaveType } from "@puntaje/shared/core"
import { GrupoRecurso } from "./grupo_recursos.model"

export class GrupoRecursoTipo extends BaseModel {
    grupo_recurso_tipo: string
    grupo_recurso_tipo_categoria_id: number
    orden: number

    @SaveType(() => GrupoRecurso) grupo_recursos: GrupoRecurso[]
}
