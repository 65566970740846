<div class="content">
    <div class="acciones">
        <ng-container *ngIf="editing">
            <select [(ngModel)]="escalaGlobalAlias" class="select-alias">
                <option [ngValue]="undefined">-</option>
                <option *ngFor="let alias of escalaGlobal.escala_global_aliases" [ngValue]="alias">
                    {{ alias.equivalencia_min }}% - {{ alias.equivalencia_max }}%
                </option>
            </select>

            <a (click)="save()">Guardar</a>
        </ng-container>
        <div class="spacer"></div>
        <a (click)="toggleEdit()">
            {{ editing ? "Cancelar" : "Editar" }}
            <fa [name]="'pencil'"></fa>
        </a>
    </div>
    <img *ngIf="escalaGlobal.tipo == 'Imagen'" class="img-alias" [src]="escalaGlobalAlias?.alias" />
    <span *ngIf="escalaGlobal.tipo != 'Imagen'" class="nota-alias">
        {{ escalaGlobalAlias?.alias }}
    </span>
    <div class="texto">Alias</div>
</div>
