<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div *ngIf="showMessage" class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        <model-plain-value *ngIf="instrumentoPDF && !evaluacionForma" [params]="pdfParams" [value]="instrumentoPDF"
            key="pdf">
        </model-plain-value>
        <model-plain-value *ngIf="evaluacionForma && instrumentoPDFByForma && instrumentoPDFByForma[evaluacionForma.id]"
            [params]="pdfParams" [value]="instrumentoPDFByForma[evaluacionForma.id]" key="pdf">
        </model-plain-value>
    </div>
</loading-layout>
