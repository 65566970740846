import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PlanPersonalSesion } from "./plan_personal_sesiones.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class PlanPersonalSesiones extends PuntajeBaseService<PlanPersonalSesion> {
    tableName = "plan_personal_sesiones"
    singularTableName = "plan_personal_sesion"
    modelClass = PlanPersonalSesion

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    moverAdelante(id) {
        return this.one(id).concatExtra("mover_adelante").post()
    }

    moverAtras(id) {
        return this.one(id).concatExtra("mover_atras").post()
    }

    setActual(id) {
        return this.one(id).concatExtra("set_actual").post()
    }

    sendReminder(id, grupoUsuarioId = null) {
        return this.one(id).concatExtra("send_reminder").get({ grupo_usuario_id: grupoUsuarioId })
    }

    avanceGrupoUsuario(id, grupoUsuarioId) {
        this.enableIgnoreModel()
        return this.one(id)
            .concatExtra("avance_grupo_usuario")
            .get({ grupo_usuario_id: grupoUsuarioId })
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }

    avanceEstablecimiento(id, establecimientoId) {
        this.enableIgnoreModel()
        return this.one(id)
            .concatExtra("avance_establecimiento")
            .get({ establecimiento_id: establecimientoId })
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }

    avancePorAlumno(id, grupoUsuarioId, page, per) {
        this.enableIgnoreModel()
        return this.one(id)
            .concatExtra("avance_por_alumno")
            .get({ grupo_usuario_id: grupoUsuarioId, page, per })
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }

    detalleContenidoSesion(id: number, params: any) {
        this.enableIgnoreModel()
        return this.one(id)
            .concatExtra("detalle_contenido")
            .get(params)
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }

    avancePorAlumnoConjunto(id, conjunto, page, per) {
        this.enableIgnoreModel()
        return this.one(id)
            .concatExtra("avance_por_alumno_conjunto")
            .post({ conjunto, page, per } as any)
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }

    detalleContenidoSesionConjunto(id: number, params: any) {
        this.enableIgnoreModel()
        return this.one(id)
            .concatExtra("detalle_contenido_conjunto")
            .post(params)
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }

    wherePost(params: any = null, timeout: number = 30000) {
        return this.all().concatExtra("post").post(params, timeout)
    }
}
