import { Validators } from "@angular/forms"
import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Asignatura } from "./asignaturas.model"
import { Asignaturas } from "./asignaturas.service"
import { Clasificaciones } from "./clasificaciones.service"
import { ClasificacionClasificacion } from "./clasificacion_clasificaciones.model"
import { ClasificacionTipo } from "./clasificacion_tipos.model"
import { GeneradorInstrumentoInstruccion } from "./generador_instrumento_instrucciones.model"
import { Material } from "./materiales.model"
import { ClasificacionMaterial } from "./clasificacion_materiales.model"
import { ClasificacionPregunta } from "./clasificacion_preguntas.model"
import { Pregunta } from "./preguntas.model"
import { GeneradorInstrumentoClasificacion } from "./generador_instrumento_clasificaciones.model"
import { GeneradorInstrumento } from "./generador_instrumentos.model"
import { GeneradorInstrumentoFiltroClasificacion } from "./generador_instrumento_filtro_clasificaciones.model"

export class Clasificacion extends BaseModel {
    public static className: string = "Clasificacion"
    public clasificacion_tipo_id: number
    public asignatura_id: number
    public created_at: Date
    public updated_at: Date
    public clasificacion_padre_id: number
    public clasificacion: string
    public descripcion: string
    public medible: boolean
    public priorizado: boolean

    public nombre_jerarquico: string
    public cantidad_preguntas?: number
    public full_nombre_jerarquico: string

    public nota_ia?: string

    public selected?: boolean

    @SaveType(() => ClasificacionTipo) public clasificacion_tipo: ClasificacionTipo
    @SaveType(() => Asignatura) public asignatura: Asignatura
    @SaveType(() => ClasificacionMaterial) public clasificacion_materiales: ClasificacionMaterial[]
    @SaveType(() => Material) public materiales: Material[]
    @SaveType(() => ClasificacionPregunta) public clasificacion_preguntas: ClasificacionPregunta[]
    @SaveType(() => Pregunta) preguntas: Pregunta[]
    @SaveType(() => ClasificacionClasificacion) public clasificacion_clasificaciones_ref: ClasificacionClasificacion[]
    @SaveType(() => Clasificacion) public clasificaciones_hijas: Clasificacion[]
    @SaveType(() => Clasificacion) public clasificacion_padre: Clasificacion
    @SaveType(() => ClasificacionClasificacion) public clasificacion_clasificaciones: ClasificacionClasificacion[]
    @SaveType(() => Clasificacion) public clasificaciones: Clasificacion[]
    @SaveType(() => GeneradorInstrumentoClasificacion)
    public generador_instrumento_clasificaciones: GeneradorInstrumentoClasificacion[]
    @SaveType(() => GeneradorInstrumento) public generador_instrumentos: GeneradorInstrumento[]
    @SaveType(() => GeneradorInstrumentoFiltroClasificacion)
    public generador_instrumento_filtro_clasificaciones: GeneradorInstrumentoFiltroClasificacion
    @SaveType(() => GeneradorInstrumentoInstruccion)
    public generador_instrumento_instrucciones: GeneradorInstrumentoInstruccion[]

    @SaveType(() => Clasificacion) public curso: Clasificacion
    @SaveType(() => Material) public materiales_propios: Material[]

    constructor(empty: boolean = false) {
        super()

        if (!empty) {
            this.clasificacion_tipo = new ClasificacionTipo()
            this.asignatura = new Asignatura()
            this.clasificacion_padre = this.getPadre()
            this.clasificaciones_hijas = []
            this.clasificacion_clasificaciones = []
            this.clasificaciones = []
            this.materiales = []
            this.materiales_propios = []
        }
    }

    getPadre(): Clasificacion {
        if (this.clasificacion_tipo_id != null) {
            return new Clasificacion()
        } else {
            return null
        }
    }

    public static get model_params(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            clasificacion: {
                label: "Clasificación",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(5)]
            },
            descripcion: {
                label: "Descripción",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(5)]
            },
            asignatura_id: { label: "Asignatura", type: "select", visible: true, options: { class: Asignaturas } },
            clasificacion_padre_id: {
                label: "Padre",
                type: "select",
                visible: true,
                options: { class: Clasificaciones }
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true },
            medible: { label: "Medible", type: "checkbox", visible: true },
            priorizado: { label: "Priorización curricular", type: "checkbox", visible: true }
        }
    }

    public static get show_params(): any {
        return {
            id: { label: "Id", type: "text", tableVisible: true },
            clasificacion: {
                label: "Clasificación",
                type: "link",
                tableVisible: true,
                options: { class: Clasificaciones, key: "id" }
            },
            descripcion: { label: "Descripción", type: "text", tableVisible: false },
            asignatura: {
                label: "Asignatura",
                type: "modelLink",
                tableVisible: true,
                options: { class: Asignaturas, key: "id", modelKey: "asignatura" }
            },
            clasificacion_padre_id: { label: "Clasificación padre", type: "text", tableVisible: false },
            created_at: { label: "Fecha de creación", type: "date", tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: "date", tableVisible: true }
        }
    }

    public toString() {
        if (this.nombre_jerarquico) return this.nombre_jerarquico
        else return this.clasificacion
    }

    public AgregarClasificacion() {
        let a = new ClasificacionClasificacion()
        a.id = null
        a.clasificacion_id = null
        a.clasificacion_ref_id = null
        this.clasificacion_clasificaciones.push(a)
    }

    public LimpiarClasificaciones() {
        this.clasificacion_clasificaciones = []
    }
}
