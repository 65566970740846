/** @format */

import { AppConfig, BaseModel, SaveType } from "@puntaje/shared/core"
import { Alternativa } from "./alternativas.model"
import { Pregunta } from "./preguntas.model"
import { ContestableTipo } from "./contestable_tipos.model"
import { GeneradorInstrumento } from "./generador_instrumentos.model"

declare const config: AppConfig

export class Contestable extends BaseModel {
    public static className: string = "Contestable"
    public pregunta_id: number
    public contestable: boolean
    public orden: number
    public contestable_tipo_id: number
    public created_at: Date
    public updated_at: Date

    private alternativasProcesadas: Alternativa[]

    @SaveType(() => Alternativa) public alternativas: Alternativa[]
    @SaveType(() => Pregunta) public pregunta: Pregunta
    @SaveType(() => ContestableTipo) public contestable_tipo: ContestableTipo

    constructor(contestableTipoId: number = undefined, cantidadAlternativas: number = 2) {
        super()

        if (contestableTipoId !== undefined) {
            this.contestable_tipo_id = contestableTipoId
        }

        this.alternativas = []

        for (var i = 0; i < cantidadAlternativas; i++) {
            this.alternativas.push(new Alternativa())
        }
    }

    public static get model_params(): any {
        return {
            alternativas: { type: "models", class: Alternativa }
        }
    }

    public agregarAlternativa() {
        this.alternativas.push(new Alternativa())
    }

    public removeAlternativa(index: number) {
        if (this.alternativas.length > 2) {
            let alternativa_id = this.alternativas[index].id
            this.alternativas.splice(index, 1)
            return alternativa_id
        }
        return false
    }

    public getAlternativas(generadorInstrumento?: GeneradorInstrumento) {
        if (this.alternativasProcesadas) return this.alternativasProcesadas
        if (generadorInstrumento && generadorInstrumento.maximo_alternativas) {
            let alts: Alternativa[] = this.alternativas
            /* let correcta = alts.find(x => x.correcta);
			let otras: Alternativa[] = this.alternativas.filter(x => !x.correcta);
			otras.sort((a, b) => a.orden_ocultar - b.orden_ocultar); */
            let toRemove = alts.length - generadorInstrumento.maximo_alternativas
            let quedan: Alternativa[] = this.alternativas.filter(
                x => x.orden_ocultar == null || x.orden_ocultar >= toRemove
            )

            /* if (toRemove > 0) {
				otras.splice(0, toRemove);
			} */

            /* otras.push(correcta); */
            if (config.plataforma.ordenAlternativasAleatorias) {
                quedan.forEach(alternativa => {
                    alternativa.orden = +localStorage.getItem(`ordenAlternativaId_${alternativa.id}`) || Math.random()

                    localStorage.setItem(`ordenAlternativaId_${alternativa.id}`, `${alternativa.orden}`)
                })
            }
            quedan.sort((a, b) => a.orden - b.orden)
            /* otras = otras.filter(x => !!x) */
            this.alternativasProcesadas = quedan
            return quedan
        } else {
            this.alternativasProcesadas = this.alternativas
            this.alternativasProcesadas.sort((a, b) => a.orden - b.orden)

            return this.alternativas
        }
    }
}
