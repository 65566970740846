import { Component, OnInit, Input } from "@angular/core"
import { Alternativa } from "@puntaje/nebulosa/api-services"
import { AppConfig, DocsView, PdfView } from "@puntaje/shared/core"

@Component({
    selector: "respuesta-pdf",
    templateUrl: "./respuesta-pdf.component.html",
    styleUrls: ["./respuesta-pdf.component.scss"]
})
export class RespuestaPdfComponent implements OnInit {
    @Input() respuestaId: number
    @Input() alternativas: Alternativa[]
    fileParams: any
    alternativa: Alternativa

    appName: string = this.config.app.name
    isDownloading: boolean = false

    constructor(protected config: AppConfig) {}

    ngOnInit() {
        this.alternativa = this.alternativas.find(a => a.id == this.respuestaId)
        this.setParams()
    }

    setParams() {
        if (this.alternativa.getExtension() == "docx" || this.alternativa.getExtension() == "doc") {
            this.fileParams = { label: "Previsualizar", type: DocsView }
        } else if (this.alternativa.getExtension() == "pdf") {
            this.fileParams = { label: "Previsualizar", type: PdfView }
        }
    }

    downloadFile() {
        this.isDownloading = true

        const a = document.createElement("a")
        a.target = "_blank"
        a.download = this.alternativa.alternativa.substring(this.alternativa.alternativa.lastIndexOf("/") + 1)

        fetch(this.alternativa.alternativa)
            .then(response => {
                const reader = response.body.getReader()

                return new ReadableStream({
                    start(controller) {
                        return pump()
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close()
                                    return
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value)
                                return pump()
                            })
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                a.href = url

                a.click()
            })
            .catch(err => {
                console.error(err)

                a.href = this.alternativa.alternativa
                a.click()
            })
            .finally(() => {
                this.isDownloading = false
            })
    }
}
