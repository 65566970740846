import { SaveType, BaseModel } from "@puntaje/shared/core"
import { BannerInstancia } from "./banner_instancias.model"
import { BannerClick } from "./banner_clicks.model"
import { BannerImpresion } from "./banner_impresiones.model"
import { BannerTipo } from "./banner_tipos.model"
import { ImageBanner } from "./image_banners.model"
import { IframeBanner } from "./iframe_banners.model"

export class Banner extends BaseModel {
    public nombre: string
    public clicks: number = 0
    public impresiones: number = 0
    public max_clicks: number = 999999999
    public max_impresiones: number = 999999999
    public banner_tipo_id: number
    public activo: boolean
    public fecha_inicial: Date
    public fecha_final: Date

    @SaveType(() => BannerInstancia) public banner_instancias: BannerInstancia[]
    @SaveType(() => BannerClick) public banner_clicks: BannerClick[]
    @SaveType(() => BannerImpresion) public banner_impresiones: BannerImpresion[]
    @SaveType(() => BannerTipo) public banner_tipo: BannerTipo
    @SaveType(() => ImageBanner) public image_banners: ImageBanner[]
    @SaveType(() => IframeBanner) public iframe_banners: IframeBanner[]

    // public static image_banner = ImageBanner;
    // public static iframe_banner = IframeBanner;

    constructor(subModel?: string) {
        super()
        if (subModel == "Imagen con link") {
            this.image_banners = [new ImageBanner()]
        }
        if (subModel == "Iframe") {
            this.iframe_banners = [new IframeBanner()]
        }
    }

    addImageBanner() {
        let ib = new ImageBanner()
        this.image_banners.push(ib)
    }

    public toString() {
        return this.nombre
    }
}
