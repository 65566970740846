import { Pipe, PipeTransform } from "@angular/core"

@Pipe({ name: "taxativoNumeroTexto" })
export class TaxativoNumeroTextoPipe implements PipeTransform {
    transform(value: string, sentenceToLine: any) {
        if (value && sentenceToLine) {
            const newVal = value.replace(/\{\{([^\}]+)\}\}/g, function (match, p1) {
                const el = document.createElement("span")
                el.innerHTML = p1

                return `${sentenceToLine[el.textContent] || "no encontrada/o"}`
            })

            return newVal
        }

        return value
    }
}
