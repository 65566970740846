import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"
import { Pregunta, Preguntas, GrupoPregunta, Clasificacion } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "tabla-estadisticas-pregunta",
    templateUrl: "tabla-estadisticas-pregunta.component.html",
    styleUrls: ["tabla-estadisticas-pregunta.component.scss"]
})
export class TablaEstadisticasPreguntaComponent implements OnInit {
    _estadistica: any = null
    @Input() dificultad: string = "-"

    totalRespuestas: number
    porcentajeCorrectas: number

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {}

    @Input()
    get estadistica(): any {
        return this._estadistica
    }

    set estadistica(e: any) {
        this._estadistica = e

        this.updateEstadisticas()
    }

    /**
     * Calcula el total de respuestas y el porcentaje de respuestas correctas para mostrar estadisticas.
     */
    updateEstadisticas() {
        this.totalRespuestas = this.estadistica.correctas + this.estadistica.incorrectas + this.estadistica.omitidas
        this.porcentajeCorrectas = Math.floor((this.estadistica.correctas * 100) / this.totalRespuestas)

        this.cdr.detectChanges()
    }
}
