<ll-toolnavbar>
    <materiales-toolnavbar></materiales-toolnavbar>
</ll-toolnavbar>
<ll-titulo>Recursos interactivos</ll-titulo>
<!--<materiales-udds></materiales-udds>-->
<p class="pre-title">Visita nuestra colección de recursos interactivos.</p>
<cui-section-headline>Unidades didácticas digitales</cui-section-headline>
<div class="row">
    <div class="col-md-6">
        <div class="udd-container">
            <a [routerLink]="['/material_udd']">
                <img
                    class="preview-image"
                    src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/library/images/apss_udds/udds.png"
                    alt="UDD"
                />
            </a>
            <p class="texto-udd">
                Descubre las Unidades didácticas digitales. Encuentra guías, juegos y actividades de Matemáticas, para
                primero a sexto básico.
            </p>
            <div class="push-btn"></div>
            <cui-button-link class="ver-btn" [routerLinkValue]="['/material_udd']">
                <fa name="arrow-right" class="left-icon"></fa>
                Ver contenido
            </cui-button-link>
        </div>
    </div>
</div>
<cui-section-headline>Aplicaciones didácticas</cui-section-headline>
<div class="row">
    <div class="col-md-6">
        <div class="app-container">
            <div class="c_row">
                <div class="c_left_col">
                    <a [routerLink]="['/material_app']" [queryParams]="{ aplicacion: 'egipto' }">
                        <img
                            class="preview-image"
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/library/images/apss_udds/egipto.png"
                            alt="Egipto"
                        />
                    </a>
                </div>
                <div class="c_right_col">
                    <h4 class="titulo_app">Egipto</h4>
                    <p class="texto-app">
                        Ayuda a reconstruir Egipto en esta actividad interactiva de matemáticas y geometría.
                    </p>
                </div>
            </div>
            <div class="descripcion-app">
                <div>
                    <label class="titulo-asig">Asignatura:</label>
                    <div class="asig-mate">Matemáticas</div>
                </div>
                <div>
                    <label class="titulo-curso">{{ nombreGrupo | capitalize }}:</label>
                    <div class="curs">Primero básico</div>
                </div>
                <label class="titulo-oa">Objetivos de Aprendizaje:</label>
                <p class="desc-oa">
                    <b class="color-oa">14:</b>
                    &nbsp;Identificar en el entorno figuras 3D y 2D y relacionarlas, usando material concreto.
                </p>
                <div class="push-btn"></div>
                <cui-button-link
                    class="ver-btn"
                    [routerLinkValue]="['/material_app']"
                    [queryParamsValue]="{ aplicacion: 'egipto' }"
                >
                    <fa name="arrow-right" class="left-icon"></fa>
                    Ver aplicación
                </cui-button-link>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="app-container">
            <div class="c_row">
                <div class="c_left_col">
                    <a [routerLink]="['/material_app']" [queryParams]="{ aplicacion: 'piratas' }">
                        <img
                            class="preview-image"
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/library/images/apss_udds/piratas.png"
                            alt="Pequeños Piratas"
                        />
                    </a>
                </div>
                <div class="c_right_col">
                    <h4 class="titulo_app">Pequeños Piratas</h4>
                    <p class="texto-app">
                        Busca el tesoro perdido de la isla en esta actividad interactiva de matemáticas.
                    </p>
                </div>
            </div>
            <div class="descripcion-app">
                <div>
                    <label class="titulo-asig">Asignatura:</label>
                    <div class="asig-mate">Matemáticas</div>
                </div>
                <div>
                    <label class="titulo-curso">{{ nombreGrupo | capitalize }}:</label>
                    <div class="curs">Primero básico</div>
                </div>
                <label class="titulo-oa">Objetivos de Aprendizaje:</label>
                <p class="desc-oa">
                    <b class="color-oa">13:</b>
                    &nbsp;Describir la posición de objetos y personas con relación a sí mismos y a otros objetos y
                    personas, usando un lenguaje común (como derecha e izquierda).
                </p>
                <div class="push-btn"></div>
                <cui-button-link
                    class="ver-btn"
                    [routerLinkValue]="['/material_app']"
                    [queryParamsValue]="{ aplicacion: 'piratas' }"
                >
                    <fa name="arrow-right" class="left-icon"></fa>
                    Ver aplicación
                </cui-button-link>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="app-container">
            <div class="c_row">
                <div class="c_left_col">
                    <a [routerLink]="['/material_app']" [queryParams]="{ aplicacion: 'parque_diversiones' }">
                        <img
                            class="preview-image"
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/library/images/apss_udds/parque_diversiones.png"
                            alt="Parque de Diversiones"
                        />
                    </a>
                </div>
                <div class="c_right_col">
                    <h4 class="titulo_app">Parque de Diversiones</h4>
                    <p class="texto-app">
                        Participa en los distintos juegos del Parque de Diversiones y realiza actividades interactivas
                        de matemáticas.
                    </p>
                </div>
            </div>
            <div class="descripcion-app">
                <div>
                    <label class="titulo-asig">Asignatura:</label>
                    <div class="asig-mate">Matemáticas</div>
                </div>
                <div>
                    <label class="titulo-curso">{{ nombreGrupo | capitalize }}:</label>
                    <div class="curs">Primero básico</div>
                </div>
                <label class="titulo-oa">Objetivos de Aprendizaje:</label>
                <p class="desc-oa">
                    <b class="color-oa">15:</b>
                    &nbsp;Identificar y dibujar líneas rectas y curvas.
                </p>
                <div class="push-btn"></div>
                <cui-button-link
                    class="ver-btn"
                    [routerLinkValue]="['/material_app']"
                    [queryParamsValue]="{ aplicacion: 'parque_diversiones' }"
                >
                    <fa name="arrow-right" class="left-icon"></fa>
                    Ver aplicación
                </cui-button-link>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="app-container">
            <div class="c_row">
                <div class="c_left_col">
                    <a [routerLink]="['/material_app']" [queryParams]="{ aplicacion: 'sandwich' }">
                        <img
                            class="preview-image"
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/library/images/apss_udds/sandwich.png"
                            alt="Sandwich Espacial"
                        />
                    </a>
                </div>
                <div class="c_right_col">
                    <h4 class="titulo_app">Sandwich Espacial</h4>
                    <p class="texto-app">
                        Conviértete en un cocinero espacial, y aprende a identificar y comparar la longitud de
                        diferentes objetos.
                    </p>
                </div>
            </div>
            <div class="descripcion-app">
                <div>
                    <label class="titulo-asig">Asignatura:</label>
                    <div class="asig-mate">Matemáticas</div>
                </div>
                <div>
                    <label class="titulo-curso">{{ nombreGrupo | capitalize }}:</label>
                    <div class="curs">Primero básico</div>
                </div>
                <label class="titulo-oa">Objetivos de Aprendizaje:</label>
                <p class="desc-oa">
                    <b class="color-oa">18:</b>
                    &nbsp;Identificar y comparar la longitud de objetos, usando palabras como largo y corto.
                </p>
                <div class="push-btn"></div>
                <cui-button-link
                    class="ver-btn"
                    [routerLinkValue]="['/material_app']"
                    [queryParamsValue]="{ aplicacion: 'parque_diversiones' }"
                >
                    <fa name="arrow-right" class="left-icon"></fa>
                    Ver aplicación
                </cui-button-link>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="app-container">
            <div class="c_row">
                <div class="c_left_col">
                    <a [routerLink]="['/material_app']" [queryParams]="{ aplicacion: 'calendario' }">
                        <img
                            class="preview-image"
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/library/images/apss_udds/calendario.png"
                            alt="Calendario"
                        />
                    </a>
                </div>
                <div class="c_right_col">
                    <h4 class="titulo_app">El Calendario de Lumamié Juevisadom</h4>
                    <p class="texto-app">
                        Ayuda a Lumamié Juevisadom y aprende sobre fechas significativas y a secuenciar eventos en un
                        calendario.
                    </p>
                </div>
            </div>
            <div class="descripcion-app">
                <div>
                    <label class="titulo-asig">Asignatura:</label>
                    <div class="asig-mate">Matemáticas</div>
                </div>
                <div>
                    <label class="titulo-curso">{{ nombreGrupo | capitalize }}:</label>
                    <div class="curs">Primero básico</div>
                </div>
                <label class="titulo-oa">Objetivos de Aprendizaje:</label>
                <p class="desc-oa">
                    <b class="color-oa">17:</b>
                    &nbsp;Usar un lenguaje cotidiano para secuenciar eventos en el tiempo: días de la semana, meses del
                    año y algunas fechas significativas.
                </p>
                <div class="push-btn"></div>
                <cui-button-link
                    class="ver-btn"
                    [routerLinkValue]="['/material_app']"
                    [queryParamsValue]="{ aplicacion: 'calendario' }"
                >
                    <fa name="arrow-right" class="left-icon"></fa>
                    Ver aplicación
                </cui-button-link>
            </div>
        </div>
    </div>
</div>
