import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "imprimir_ensayo.component.html"
})
export class ImprimirEnsayoComponent implements OnInit, OnDestroy {
    private sub: Subscription
    evaluacionId: number
    enableResponsive: boolean = false

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.imprimir_ensayo")
        this.sub = this.route.params.subscribe(params => {
            this.evaluacionId = +params["id"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
