<el-titulo>
    <ng-template [ngIf]="evaluacionInstancia"></ng-template>
</el-titulo>
<el-subtitulo>
    <ng-template [ngIf]="evaluacionInstancia"></ng-template>
</el-subtitulo>

<el-contenido-lateral>
    <ensayo-instancia-resumen
        *ngIf="evaluacionInstancia?.respuestas"
        [evaluacionInstancia]="evaluacionInstancia"
        [porcentaje]="porcentaje"
        [rutaVerEvaluaciones]="rutaVerEvaluaciones"
        [volverARealizar]="volverARealizar"
        [realizaNuevo]="realizaNuevo"
        (tabSelected)="respuestasEnsayo.selectTab($event)"
        [rutaVerEvaluacionesMultiples]="'/saber11/compartidos'"
        [rutaVolverARealizar]="rutaVolverARealizar"
    ></ensayo-instancia-resumen>
</el-contenido-lateral>

<ver-retroalimentacion *ngIf="enableRetroalimentacion" [evaluacionInstanciaId]="evaluacionInstanciaId"></ver-retroalimentacion>
<respuestas-ensayo
    #respuestasEnsayo
    [evaluacionInstanciaId]="evaluacionInstanciaId"
    (onEvaluacionInstanciaReady)="onEvaluacionInstanciaReady($event)"
    [enableDudas]="enableDudas"
    [enableReporte]="enableReporte"
    [enableDesempeno]="enableDesempeno"
></respuestas-ensayo>
