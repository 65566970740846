import { ModuleWithProviders } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { ErroresComponent } from "@puntaje/shared/core"

const appRoutes: Routes = [
    { path: "", redirectTo: "landing", pathMatch: "full" },
    { path: "**", component: ErroresComponent }
]

export const appRoutingProviders: any[] = []

export const routing = RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: "enabled",
    relativeLinkResolution: "legacy"
})
