import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Rol } from "./roles.model"
import { LibroEvaluacion } from "./libro_evaluaciones.model"
import { LibroCodigoRegistro } from "./libro_codigo_registros.model"

export class Libro extends BaseModel {
    public nombre: string
    public asignatura_id: number
    public codigo_identificador: string
    public edicion: number
    public portada: string
    public activo: boolean

    // TODO: esto en verdad es roles, lo arreglé para que los tipos de los componentes no reclamaran de que la variable no existia
    @SaveType(() => Rol) rol: Rol

    @SaveType(() => LibroEvaluacion) libro_evaluaciones: LibroEvaluacion[]
    @SaveType(() => LibroCodigoRegistro) libro_codigo_registros: LibroCodigoRegistro[]

    public toString() {
        return this.nombre
    }
}
