import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import {
    EvaluacionInstancia,
    EvaluacionInstancias,
    PlanPersonales,
    PlanPersonal,
    PlanPersonalInstancias,
    PlanPersonalInstancia
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Store, select } from "@ngrx/store"
import { State, selectSelectedAsignatura, selectEvaluacionTipoAliases } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    selector: "ensayo-instancias",
    templateUrl: "ensayo_instancias.component.html",
    styleUrls: ["ensayo_instancias.component.scss"]
})
export class EnsayoInstanciasComponent implements OnInit, OnChanges, OnDestroy {
    @Input() asignaturaId: number
    @Input() enableButtonRealizarEvaluacion: boolean = true
    @Input() porcentaje: boolean = false
    @Input() showAllResults: boolean = true
    @Input() hideNombre: boolean = false
    @Input() hideCompartidoPor: boolean = false
    @Input() hideReforzamiento = false

    @Input() evaluacionTipo: string
    @Input() hideBtnEstadisticaByEvalTipo: boolean = false

    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("tablaContenedor", { static: true }) tabla_contenedor: ElementRef

    hideVolverARealizar: boolean = config.plataforma.hideVolverARealizar
    asignatura: Asignatura
    evaluacionInstancias: EvaluacionInstancia[] = []
    enableResponsiveTable: boolean = false
    showButton: boolean = true
    nombreColumna: String
    graduate: boolean = config.plataforma.name == "Graduate" ? true : false
    baseUrl: string = "/ensayos"
    urlResultado: string = "/ensayo_instancias"
    urlRealizar: string = "/ensayos"
    estadoReforzamientoPorEvaluacion: any = {}
    reforzamientosByEvaluacionId: any = {}
    hasResultDateHeader: boolean = false

    asignatura$ = this.store.pipe(select(selectSelectedAsignatura))

    subAlias: Subscription
    evaluacionTipoAlias: string
    evaluacionTipoAliases: any

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    constructor(
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected asignaturasService: Asignaturas,
        protected authService: AuthService,
        protected planPersonalesService: PlanPersonales,
        protected planPersonalInstanciasService: PlanPersonalInstancias,
        protected cdr: ChangeDetectorRef,
        protected store: Store<State>,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {
        this.nombreColumna =
            config.plataforma.nombreColumnaResultados || this.i18nService.translate("ensayo_instancias.n_preguntas")
        const baseUrlConfig = config.evaluaciones[this.evaluacionTipo]
            ? config.evaluaciones[this.evaluacionTipo].baseUrl
            : null

        this.urlResultado = baseUrlConfig ? baseUrlConfig + "/instancias" : this.urlResultado
        this.urlRealizar = baseUrlConfig ? baseUrlConfig : this.urlRealizar

        this.subAlias = this.evaluacionTipoAliases$.subscribe(evaluacionTipoAliases => {
            this.evaluacionTipoAliases = evaluacionTipoAliases
            this.evaluacionTipoAlias = this.evaluacionTipoAliases[this.evaluacionTipo]
        })

        this.setData()
        if (
            config.plataforma.name == "College Board" ||
            (this.hideBtnEstadisticaByEvalTipo && config.evaluaciones[this.evaluacionTipo].hideEstadistica)
        ) {
            this.showButton = false
        }
    }

    setData() {
        this.loadingLayout.standby()
        this.checkResponsive()
        this.asignatura = null
        this.asignaturasService.find(this.asignaturaId, { simple: 1 }).then((asignatura: Asignatura) => {
            this.asignatura = asignatura
            this.onReady.emit(this.asignatura)
        })
    }

    porcentajeCorrectas(ei: EvaluacionInstancia) {
        return (ei.correctas / (ei.correctas + ei.incorrectas + ei.omitidas)) * 100
    }

    /**
     * arrow functions keep context of `this` if
     * inside a class
     */
    showAll = (page: number, per: number) => {
        this.loadingLayout.standby()
        let params: any = {
            page: page,
            per: per,
            mios: 1,
            evaluacion_instancia: {
                asignatura_id: this.asignaturaId
            },
            render_options: {
                include: {
                    evaluacion: {
                        include: {
                            instrumento: null,
                            evaluacion_tipo: null,
                            evaluacion_multiples: null
                        },
                        methods: ["compartido_por"]
                    }
                }
            }
        }

        if (this.evaluacionTipo) params.evaluacion_tipo = { evaluacion_tipo: this.evaluacionTipo }

        return this.evaluacionInstanciasService.where(params).then((eis: EvaluacionInstancia[], total: number) => {
            this.evaluacionInstancias = eis
            this.hasResultDateHeader = false
            // reviso si es que tengo que mostrar o no la columna de fecha de resultados, para que no aparezca una columna vacía si no hay nada
            this.evaluacionInstancias.forEach(ei => {
                this.hasResultDateHeader =
                    !this.checkDateAvailable(ei.evaluacion.fecha_mostrar_respuestas as string) ||
                    this.hasResultDateHeader
            })
            this.getReforzamientos()

            return total
        })
    }

    checkResponsive() {
        this.enableResponsiveTable = this.tabla_contenedor && this.tabla_contenedor.nativeElement.clientWidth < 690
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.asignatura && (changes["asignaturaId"] || changes["evaluacionTipo"])) {
            this.setData()
        }

        if (changes["evaluacionTipo"]) {
            this.evaluacionTipoAlias = this.evaluacionTipoAliases?.[this.evaluacionTipo]
        }
    }

    checkDateAvailable(date: string | Date) {
        let dateTime: Date
        if (date instanceof Date) {
            dateTime = date
        } else {
            dateTime = new Date(date)
        }

        const timeNow: Date = new Date()
        return this.showAllResults || timeNow >= dateTime
    }

    getFechaCL(date: string | Date) {
        const fecha: Date = new Date(date as string)
        return fecha.toLocaleString("CL")
    }

    getNotaEscalaSiete(correctas: number, incorrectas: number, omitidas: number, escala?: any) {
        let min, mid, max, p_mid
        mid = 4.0
        if (escala != null) {
            min = escala.min
            max = escala.max
            p_mid = escala.p_mid
        } else {
            min = 1.0
            max = 7.0
            p_mid = 0.6
        }
        const totales = correctas + incorrectas + omitidas
        const fraccion = (min * correctas) / totales
        let nota = min

        if (fraccion <= p_mid) {
            nota = min + fraccion * ((mid - min) / p_mid)
        } else {
            const fraccionAprobado = (correctas - p_mid * totales) / (totales - p_mid * totales)
            const temp = mid + fraccionAprobado * ((max - mid) / min)
            nota = temp > max ? max : temp
        }

        return nota
    }

    getReforzamientos() {
        if (this.evaluacionInstancias.length > 0) {
            const reforzamientosParams = {
                plan_personal_usuario: {
                    receptor_type: "Usuario",
                    receptor_id: this.authService.getUserData().id
                },
                plan_personal_ciclo: {
                    evaluacion_id: this.evaluacionInstancias.map(instancia => instancia.evaluacion_id) // cambiar mas adelante
                },
                plan_personal_tipo: {
                    tipo: "reforzamiento"
                },
                render_options: {
                    include: {
                        plan_personal_usuarios: null,
                        plan_personal_ciclos: {
                            include: {
                                evaluacion: null,
                                plan_personal_sesiones: {
                                    include: {
                                        plan_personal_contenidos: {
                                            include: [
                                                "plan_personal_material_instancias",
                                                "plan_personal_evaluacion_instancias"
                                            ]
                                        }
                                    },
                                    methods: ["activa"]
                                }
                            }
                        }
                    }
                }
            }
            return this.planPersonalesService.where(reforzamientosParams).then((planPersonales: PlanPersonal[]) => {
                if (planPersonales.length > 0) {
                    this.setEstadoReforzamientos(planPersonales)
                }
                this.loadingLayout.ready()
            })
        } else {
            this.loadingLayout.ready()

            return null
        }
    }

    setEstadoReforzamientos(planPersonales) {
        planPersonales.forEach(reforzamiento => {
            const evaluacion_ref_id = reforzamiento.plan_personal_ciclos[0].evaluacion_id
            const realizado = reforzamiento.plan_personal_ciclos[0].plan_personal_sesiones.every(pps =>
                pps.checkAvance()
            )
            this.estadoReforzamientoPorEvaluacion[evaluacion_ref_id] = realizado
            this.reforzamientosByEvaluacionId[evaluacion_ref_id] = reforzamiento
        })
    }

    ngOnDestroy() {
        this.subAlias.unsubscribe()
    }
}
