import { Component, OnInit, ViewChild } from "@angular/core"
import { EvaluacionInstancias } from "@puntaje/puntaje/api-services"
import { ActivatedRoute } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignaturas, Asignatura, ClasificacionTipo, ClasificacionTipos } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "evaluacion-instancia-estadisticas",
    templateUrl: "./evaluacion-instancia-estadisticas.component.html",
    styleUrls: ["./evaluacion-instancia-estadisticas.component.scss"]
})
export class EvaluacionInstanciaEstadisticasComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    evaluacionInstanciaId: number
    data: any
    asignatura: Asignatura
    clasificacionTipos: ClasificacionTipo[]
    clasificacionTiposEstadisticas: any
    evaluacionTipo: any
    dataGraficoClasificacion: any = []

    constructor(
        protected titleService: TitleService,
        protected evaluacionInstanciasServices: EvaluacionInstancias,
        protected route: ActivatedRoute,
        protected asignaturasService: Asignaturas,
        protected clasificacionTiposService: ClasificacionTipos
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas.evaluacion_instancia_estadisticas")
        this.loadingLayout.standby()
        this.route.params.subscribe(params => {
            this.evaluacionInstanciaId = params["id"]
        })
        let params = {
            with_usuario: true
        }
        this.evaluacionInstanciasServices.find(this.evaluacionInstanciaId, params).then(respond => {
            this.data = respond
            this.evaluacionTipo = respond.evaluacion.evaluacion_tipo
            this.getAsignatura(this.data)
            this.datosGraficoClasificacionInstancia()
        })
    }

    async getAsignatura(data) {
        this.asignatura = await this.asignaturasService.find(data.evaluacion.instrumento.asignatura_id, { simple: 1 })
    }

    async datosGraficoClasificacionInstancia() {
        this.clasificacionTiposEstadisticas =
            config.evaluaciones[this.evaluacionTipo.evaluacion_tipo].clasificacionTiposEstadisticas
        let cTipos = this.clasificacionTiposEstadisticas.filter(ct => ct.alumnos).map(ct => ct.nombre)

        let params = {
            with_clasificacion: true,
            clasificacion_tipo: { clasificacion_tipo: cTipos }
        }
        this.clasificacionTipos = <ClasificacionTipo[]>await this.clasificacionTiposService.where(params)

        for (let ct of this.clasificacionTipos) {
            let allClasificaciones = ct.clasificaciones
                .map(c => {
                    let resp = {
                        id: c.id,
                        clasificacion: c.clasificacion
                    }
                    return resp
                })
                .flat()

            let dataGrafico = await this.evaluacionInstanciasServices.data_grafico_clasificacion({
                id: this.evaluacionInstanciaId,
                clasificaciones: allClasificaciones,
                clasificacion_tipo: ct.clasificacion_tipo
            })

            let tipoClasificacion = dataGrafico[0] ? dataGrafico[0]["clasificacion_tipo"] : ""
            this.dataGraficoClasificacion.push({
                tipoClasificacion,
                clasificaciones: dataGrafico.map(c => c["clasificacion"]),
                data: dataGrafico.map(c => c["estadistica_clasificacion"]["correctas"]),
                dataTotal: dataGrafico.map(
                    c =>
                        c["estadistica_clasificacion"]["correctas"] +
                        c["estadistica_clasificacion"]["omitidas"] +
                        c["estadistica_clasificacion"]["incorrectas"]
                )
            })
        }
        this.loadingLayout.ready()
    }
}
