<ll-titulo>{{ "progreso.historial.titulo" | t }}</ll-titulo>
<ll-subtitulo *ngIf="asignatura$ | async as asignatura">
    {{ asignatura["confByEvaluacionTipo"][tipoInstrumento].abreviacion || asignatura.asignatura }}
</ll-subtitulo>
<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas
        [showTitleOverMenu]="showTitleOverMenuAsignaturas"
        [evaluacionTipos]="evaluacionTipos"
        [allTipoInstrumentos]="allTipoInstrumentos"
    ></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>

<ensayo-instancias
    *ngIf="asignaturaId"
    [evaluacionTipo]="tipoInstrumento"
    [asignaturaId]="asignaturaId"
    (onReady)="onReady($event)"
    [enableButtonRealizarEvaluacion]="false"
    [porcentaje]="true"
    [showAllResults]="false"
    [ngClass]="{ 'theme-cb': theme === 'theme-cb' }"
    [hideNombre]="hideNombre"
    [hideCompartidoPor]="hideCompartidoPor"
    [hideBtnEstadisticaByEvalTipo]="hideBtnEstadisticaByEvalTipo"
    [hideReforzamiento]="hideReforzamiento"
></ensayo-instancias>
<evaluaciones-compartidas-alumno
    *ngIf="asignaturaId"
    [asignaturaId]="asignaturaId"
    [evaluacionTipo]="tipoInstrumento"
    [disableDownloadNotPropio]="disableDownloadNotPropio"
    [urlRealizar]="urlRealizar"
    [attrPresencial]="attrPresencial"
></evaluaciones-compartidas-alumno>
