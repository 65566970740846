import {
    Component,
    ViewChild,
    ElementRef,
    Renderer2,
    OnInit,
    Input,
    SimpleChange,
    OnChanges,
    SimpleChanges
} from "@angular/core"
import { BaseModel } from "../api/base.model"
import { View } from "./view.component"
import { MaterialPdfService } from "../util/services/material-pdf.service"
import { AppConfig } from "../conf/app_config"

@Component({
    selector: "view,pdfview",
    templateUrl: "pdfview.component.html",
    styleUrls: ["pdfview.component.scss"]
})
export class PdfView extends View implements OnInit {
    page = 1
    @Input() url: string
    zoom = 1.0
    @ViewChild("pdfComponent") pdfComponent: ElementRef
    @ViewChild("pdfViewer") pdfViewer
    firstRender = true
    locale: string
    locales = {
        es: "es-CL",
        br: "pt-BR",
        "es-cl": "es-CL",
        "es-co": "es-CL",
        "es-mx": "es-CL",
        "es-gen": "en",
        "es-pn-co": "es-CL"
    }

    constructor(
        private renderer: Renderer2,
        protected materialPdfService: MaterialPdfService,
        protected config: AppConfig
    ) {
        super()
        this.locale =
            config.plataforma?.locale && this.locales[config.plataforma.locale]
                ? this.locales[config.plataforma.locale]
                : this.locales["es"]
    }

    ngOnInit() {
        if (!this.url) {
            if (this.value && this.value[this.key]) this.url = this.value[this.key]

            if ((this.url as any) instanceof ArrayBuffer) {
                this.url = <any>new Blob([this.url])
            }
        }
    }

    onPdfLoad(numPages: number) {
        this.materialPdfService.numberOfPages = numPages
        this.onResize()
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.onResize()
        }, 1500)
    }

    onResize(event: any = {}) {
        let heightValue = ~~((this.pdfComponent.nativeElement.offsetWidth * 3) / 4)
        if (this.pdfComponent.nativeElement.offsetWidth <= 700) {
            heightValue = ~~((this.pdfComponent.nativeElement.offsetWidth * 4) / 3)
        }
        if (heightValue == 0) {
            this.pdfComponent.nativeElement.style.height = "70vh"
        } else {
            this.pdfComponent.nativeElement.style.height = heightValue + "px"
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["value"] && changes["value"].currentValue) {
            this.url = this.value[this.key]
            if (this.pdfViewer && !changes["value"].isFirstChange()) {
                this.pdfViewer.pdfSrc = this.url
                this.pdfViewer.refresh()
            }
        }
    }
}
