import { Component, Input, SimpleChanges, Output, EventEmitter, OnChanges, ViewChild, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { IMyDpOptions, IMyDayLabels, IMyDateModel } from "mydatepicker"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "timeframe-selector",
    templateUrl: "timeframe_selector.component.html",
    styleUrls: ["timeframe_selector.component.scss"]
})
export class TimeframeSelectorComponent implements OnInit {
    @Output() onTimeFrameChanged = new EventEmitter()
    fechaInicio: any = {}
    fechaTermino: any = {}
    enableEmit: boolean = false

    public myDatePickerOptions: IMyDpOptions = {
        dateFormat: "dd/mm/yyyy",
        editableDateField: false,
        openSelectorOnInputClick: true
    }

    constructor() {
        //this.setAsignaturas();
        this.fechaTermino.jsdate = new Date()
        this.fechaInicio.jsdate = new Date()
        this.fechaInicio.jsdate.setMonth(this.fechaInicio.jsdate.getMonth() - 6)
    }

    ngOnInit() {
        this.enableEmit = true
        this.updateAndEmit()
    }

    updateAndEmit() {
        let timeframe = [this.fechaInicio.jsdate, this.fechaTermino.jsdate]
        this.onTimeFrameChanged.emit(timeframe)
    }

    onChangeDateRange() {
        if (this.enableEmit) {
            this.updateAndEmit()
        }
    }

    presetLastXMonths(months: number) {
        this.fechaTermino = {}
        this.fechaInicio = {}
        this.fechaTermino.jsdate = new Date()
        this.fechaInicio.jsdate = new Date()
        this.fechaInicio.jsdate.setMonth(this.fechaInicio.jsdate.getMonth() - months)
        this.updateAndEmit()
    }
}
