import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { PaginatorComponent } from "./paginator.component"
import { UtilModule } from "../util/util.module"

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, FormsModule, UtilModule],
    declarations: [PaginatorComponent],
    exports: [PaginatorComponent]
})
export class PaginatorModule {}
