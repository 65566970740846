import { Chart } from "./chart.interface"
import { BaseChart } from "./base_chart.model"
import { ChartType } from "./chart_type.enum"

export class PieChart extends BaseChart {
    // Gráfico de torta

    constructor(g: Chart) {
        super(g)
    }

    public setChartData() {
        //tipo
        this.tipo = ChartType.Pie
        this.chartData.chart = {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie"
        }
        //opciones de ploteo
        this.chartData.plotOptions = {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: this.label,
                    format: this.label
                },
                showInLegend: true
            }
        }
        //datos
        let data_array = []
        for (let d of this.data) {
            if (d.nombre && d.data != null && d.data != undefined) {
                let v = { name: d.nombre, y: d.data }
                data_array.push(v)
            }
        }
        this.chartData.series = [
            {
                name: this.nombreSerie,
                colorByPoint: true,
                data: data_array
            }
        ]
    }
}
