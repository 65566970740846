import { Component, Directive, ViewChild } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Template simple para páginas para usuario sin tipo de usuario en particular, particularmente
para la recuperación de contraseña.
Implementa una sección de contenido.

simple-shared-layout-contenido

#################################*/

@Component({
    templateUrl: "cui-simple-shared-layout.component.html",
    selector: "cui-simple-shared-layout",
    styleUrls: ["cui-simple-shared-layout.component.scss"]
})
export class CUISimpleSharedLayoutComponent {
    config: typeof config = config
    @ViewChild("simpleSharedLayoutContenido") simpleSharedLayoutContenido
    showContenido: boolean
    logoAlt: string = `${config.plataforma.logoAlt || config.plataforma.info.companyName} Inicio`.trim()
    logoSrc: string = config.app?.baseStyle?.sharedLayout?.logoSimpleLayout || config.app.assets.logoMedium

    constructor() {}
}
