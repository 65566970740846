<ll-titulo>
    {{ usuario?.nombreCompleto() }}
</ll-titulo>
<ll-tabs>
    <ul class="nav nav-tabs" #tabList>
        <li role="presentation" [ngClass]="{ in: currentTab == 'perfil', active: currentTab == 'perfil' }">
            <a data-toggle="tab" href="#profile" (click)="setCurrentTab('perfil')">Perfil</a>
        </li>
        <li *ngIf="enableFavoritos" role="presentation" [ngClass]="{ in: currentTab == 'favoritos', active: currentTab == 'favoritos' }">
            <a data-toggle="tab" href="#favoritos" (click)="setCurrentTab('favoritos')">Favoritos</a>
        </li>
    </ul>
</ll-tabs>

<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="tab-content">
            <div
                id="profile"
                class="tab-pane fade"
                [ngClass]="{ in: currentTab == 'perfil', active: currentTab == 'perfil' }"
            >
                <cui-button-link
                    [routerLinkValue]="['/usuarios/editar']"
                    class="pull-right edit-btn"
                    *ngIf="!isPrivate"
                >
                    <fa name="edit"></fa>
                    <span>{{ 'perfil.editar_cuenta' | t}}</span>
                </cui-button-link>
                <usuario [usuario]="usuario" [enableShowCountryData]="true"></usuario>
            </div>
            <div
                *ngIf="enableFavoritos"
                id="favoritos"
                class="tab-pane fade"
                [ngClass]="{ in: currentTab == 'favoritos', active: currentTab == 'favoritos' }"
            >
                <materiales-favoritos></materiales-favoritos>
            </div>
        </div>
    </div>
</loading-layout>
