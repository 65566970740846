<generic-modal [buttonClicked]="openModal" [id]="'contacto-registro'">
    <modal-titulo>Formulario para recuperar cuenta</modal-titulo>
    <modal-contenido>
        <ng-container *ngIf="!sent && !error">
            <p>
                Si tu RUT ya está registrado quiere decir que existe una cuenta creada asociada a ese RUT. Para
                recuperar tu cuenta necesitamos validar tu identidad, por lo que te pedimos completar los siguientes
                datos. Te enviaremos un correo electrónico con los pasos a seguir para que puedas ingresar.
            </p>

            <form novalidate [formGroup]="form">
                <form-input
                    [form]="form"
                    [params]="params['email']"
                    [(value)]="contacto['email']"
                    [key]="'email'"
                    (valueChange)="emailChange($event)"
                ></form-input>
                <p *ngIf="showMessageEmailExists" class="alert alert-warning">
                    Ya hay una cuenta creada con ese correo. Debes recuperar tu contraseña
                    <a class="alert-link" (click)="goToForgotPassword()">aquí</a>
                </p>
                <form-input
                    [form]="form"
                    [params]="params['rut']"
                    [(value)]="contacto['rut']"
                    [key]="'rut'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['nombre_completo']"
                    [(value)]="contacto['nombre_completo']"
                    [key]="'nombre_completo'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['numero_documento']"
                    [(value)]="contacto['numero_documento']"
                    [key]="'numero_documento'"
                ></form-input>
            </form>
        </ng-container>
        <ng-container *ngIf="sent">
            <p>Te contactaremos a la brevedad con los pasos a seguir para recuperar tu cuenta.</p>
        </ng-container>
        <ng-container *ngIf="error">
            <p>Hubo un error al enviar el formulario. Por favor, intenta nuevamente más tarde.</p>
        </ng-container>
    </modal-contenido>
    <modal-botones>
        <cui-button
            class="btn-enviar"
            (click)="save()"
            [disabled]="loading || sent || error || showMessageEmailExists"
            [isLoading]="loading"
        >
            Enviar
        </cui-button>
        <cui-button (click)="close()">Cerrar</cui-button>
    </modal-botones>
</generic-modal>
