import { Injectable, Input } from "@angular/core"
import { Usuario, Usuarios, EvaluacionInstancias } from "@puntaje/puntaje/api-services"
import { Store } from "@ngrx/store"
import { State, selectAsignaturasList } from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { BehaviorSubject, Observable } from "rxjs"
import { AuthService } from "@puntaje/shared/core"
import { CarreraSede, Ponderable, Ponderables } from "@puntaje/carreras/api-services"

@Injectable()
export class CarrerasPonderaciones {
    carreraSede: CarreraSede
    ponderables: Ponderable[] = []
    usuario: Usuario
    ponderablesBarra: any[]
    ponderablesBarraHistoria: any[]
    ponderablesBarraCiencia: any[]
    ponderablesDisplay: any[]
    promedioByPonderable: any = {}
    simulacionByPonderable: any = {}
    multipleBars: boolean = false
    ponderablesFiltered: any[]
    barChoice: string = "all"
    startAnimValues: boolean = false

    usuarioPaisString: string = `usuario_${config.plataforma.pais}`

    usuarioSubject: BehaviorSubject<Usuario> = new BehaviorSubject(null)
    ponderablesSubject: BehaviorSubject<any> = new BehaviorSubject(null)
    promedioByPonderableSubject: BehaviorSubject<any> = new BehaviorSubject(null)
    simulacionByPonderableSubject = new BehaviorSubject<any>(null)

    usuario$: Observable<Usuario> = this.usuarioSubject.pipe(filter(x => !!x))
    ponderables$: Observable<any> = this.ponderablesSubject.pipe(filter(x => !!x))
    promedioByPonderable$: Observable<any> = this.promedioByPonderableSubject.pipe(filter(x => !!x))
    simulacionByPonderable$ = this.simulacionByPonderableSubject.pipe(filter(x => !!x))

    asignaturas$ = this.store.select(selectAsignaturasList).pipe(
        filter(x => !!x),
        first()
    )

    promedioPonderado: number
    simulacionPonderado: number
    promedioPonderadoHistoria: number
    simulacionPonderadoHistoria: number
    promedioPonderadoCiencia: number
    simulacionPonderadoCiencia: number

    constructor(
        protected store: Store<State>,
        protected usuariosService: Usuarios,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected authService: AuthService,
        protected ponderablesService: Ponderables
    ) {}

    async loadVariables() {
        const asignaturas = await this.asignaturas$.toPromise()

        this.ponderables = await this.ponderablesService.where({
            plataforma: {
                plataforma: config.plataforma.name
            },
            sort_by: "orden",
            order: "asc",
            include: "[ponderable_generadores?]"
        })

        const dataByPonderable = {}

        this.ponderables.forEach(ponderable => {
            if (ponderable.asignaturaId) {
                const asignatura = asignaturas.find(a => a.id == ponderable.asignaturaId)
                if (asignatura) {
                    ponderable.clase = asignatura.clase
                }
            }

            if (!ponderable.libre) {
                dataByPonderable[ponderable.ponderable] = {
                    asignatura_id: ponderable.asignaturaId,
                    generadores_ids: ponderable.ponderableGeneradores.map(
                        ponderableGenerador => ponderableGenerador.generadorId
                    ),
                    tipo_instrumento_id: ponderable.tipoInstrumentoId
                }
            }
        })

        this.ponderablesSubject.next(this.ponderables)

        const params: any = {}
        params.include = `[${this.usuarioPaisString}]`

        this.usuario = await this.usuariosService.find(this.authService.getUserData().id, params)
        this.usuarioSubject.next(this.usuario)

        if (!this.usuario[this.usuarioPaisString]) {
            this.usuario[this.usuarioPaisString] = new Usuario[this.usuarioPaisString]()
        }

        const tipoInstrumentoIdByPonderable = this.ponderables
            .filter(p => !p.libre)
            .reduce((acc, ponderable) => {
                acc[ponderable.ponderable] = ponderable.tipoInstrumentoId
                return acc
            }, {})

        const promedioGeneradores = await this.evaluacionInstanciasService.promedioGeneradores({
            keys: this.ponderables.filter(p => !p.libre).map(p => p.ponderable),
            data_by_key: dataByPonderable,
            evaluacion_tipo: config.carreras?.evaluacionTipo || config.plataforma.evaluacionDefault,
            numero: 3
        })

        this.promedioByPonderable = this.ponderables.reduce((acc, ponderable) => {
            acc[ponderable.ponderable] = promedioGeneradores[ponderable.ponderable] || 0
            return acc
        }, {})

        this.promedioByPonderableSubject.next(this.promedioByPonderable)

        Object.keys(this.promedioByPonderable).forEach(k => {
            this.simulacionByPonderable[k] = Math.round(this.promedioByPonderable[k])
        })
        this.simulacionByPonderableSubject.next(this.simulacionByPonderable)
    }

    public setPonderacionesBars() {
        this.carreraSede.ponderaciones = this.carreraSede.ponderaciones.filter(ponderacion => ponderacion.ponderable)
        const ponderablesByCategoria = this.ponderables
            .filter(ponderable => {
                return this.carreraSede.ponderaciones.find(p => {
                    return p.ponderable.ponderable == ponderable.ponderable
                })
            })
            .map(p => ({ ...p }))
            .groupBy(p => p.categoria || "sin categoria")

        this.ponderablesBarra = this.getPonderablesBarra()
        this.ponderablesFiltered = this.getPonderablesFiltered(ponderablesByCategoria, "all")

        if (this.multipleBars) {
            this.setSeparateBars()

            let ponderablesFilteredHistoria = this.getPonderablesFiltered(ponderablesByCategoria, "historia")
            this.promedioPonderadoHistoria = this.calculatePromedioPonderado(
                this.promedioByPonderable,
                ponderablesFilteredHistoria
            )
            this.simulacionPonderadoHistoria = this.calculatePromedioPonderado(
                this.simulacionByPonderable,
                ponderablesFilteredHistoria
            )

            let ponderablesFilteredCiencia = this.getPonderablesFiltered(ponderablesByCategoria, "ciencias")
            this.promedioPonderadoCiencia = this.calculatePromedioPonderado(
                this.promedioByPonderable,
                ponderablesFilteredCiencia
            )
            this.simulacionPonderadoCiencia = this.calculatePromedioPonderado(
                this.simulacionByPonderable,
                ponderablesFilteredCiencia
            )
        } else {
            this.promedioPonderado = this.calculatePromedioPonderado(
                this.promedioByPonderable,
                this.ponderablesFiltered
            )
            this.simulacionPonderado = this.calculatePromedioPonderado(
                this.simulacionByPonderable,
                this.ponderablesFiltered
            )
        }
    }

    getPonderablesFiltered(ponderablesByCategoria, barChoice) {
        return Object.keys(ponderablesByCategoria)
            .map(categoria => {
                if (categoria == "sin categoria" || ponderablesByCategoria[categoria].length == 1) {
                    return ponderablesByCategoria[categoria]
                } else {
                    this.multipleBars = this.multipleBars || ponderablesByCategoria[categoria].length > 1

                    return ponderablesByCategoria[categoria].reduce((acc, ponderable) => {
                        if (barChoice == "all") {
                            if (
                                this.promedioByPonderable[ponderable.ponderable] &&
                                this.promedioByPonderable[ponderable.ponderable] >
                                    this.promedioByPonderable[acc.ponderable]
                            ) {
                                return ponderable
                            } else {
                                return acc
                            }
                        } else {
                            // una iteracion de mas para evitar hardcodear
                            return ponderablesByCategoria[categoria].find(p => p.clase == barChoice)
                        }
                    })
                }
            })
            .flat()
            .sort((p1, p2) => {
                return p1.orden - p2.orden
            })
    }

    getPonderablesBarra() {
        const totalSum = this.carreraSede.ponderaciones.reduce(
            (acc, ponderacion) => acc + parseInt(ponderacion.ponderacion),
            0
        )

        return this.ponderables
            .filter(ponderable => {
                return this.carreraSede.ponderaciones.find(p => p.ponderable.ponderable == ponderable.ponderable)
            })
            .map(ponderableConfig => {
                const subPonderacion = this.carreraSede.ponderaciones.find(
                    p => p.ponderable.ponderable == ponderableConfig.ponderable
                )

                return {
                    ...ponderableConfig,
                    value: "" + (parseInt(subPonderacion.ponderacion) / totalSum) * 100,
                    ponderacion: subPonderacion.ponderacion
                }
            })
    }

    setSeparateBars() {
        this.ponderablesBarraCiencia = this.getPonderablesBarra()
        this.ponderablesBarraHistoria = this.ponderablesBarraCiencia.map(x => Object.assign({}, x)) //copiar para evitar excesivas llamadas a la api

        this.ponderablesBarraCiencia = this.ponderablesBarraCiencia.filter(
            obj => obj.ponderable !== "Historia y Ciencias Sociales"
        )
        let totalValCiencia = this.ponderablesBarraCiencia
            .map(ponderacion => parseFloat(ponderacion.value))
            .reduce((prev, next) => prev + next)
        this.ponderablesBarraCiencia.forEach(ponderable => (ponderable.value *= 100 / totalValCiencia))

        this.ponderablesBarraHistoria = this.ponderablesBarraHistoria.filter(obj => obj.ponderable !== "Ciencias")
        let totalValHistoria = this.ponderablesBarraHistoria
            .map(ponderacion => parseFloat(ponderacion.value))
            .reduce((prev, next) => prev + next)
        this.ponderablesBarraHistoria.forEach(ponderable => (ponderable.value *= 100 / totalValHistoria))
    }

    calculatePromedioPonderado(ponderaciones, ponderablesFiltered) {
        let ponderacion = 0
        ponderablesFiltered.forEach(ponderable => {
            if (ponderable != undefined) {
                const subPonderacion = this.carreraSede.ponderaciones.find(
                    p => p.ponderable.ponderable == ponderable.ponderable
                )

                ponderable.value = subPonderacion.ponderacion

                if (!ponderable.libre) {
                    const datoPonderaciones = ponderaciones[subPonderacion.ponderable.ponderable]
                        ? ponderaciones[subPonderacion.ponderable.ponderable]
                        : 0
                    ponderacion += (+subPonderacion.ponderacion * datoPonderaciones) / 100
                } else if (ponderable.libre) {
                    ponderacion +=
                        (+subPonderacion.ponderacion *
                            (this.usuario[this.usuarioPaisString]
                                ? this.usuario[this.usuarioPaisString][ponderable.campoOrigen]
                                : 0)) /
                        100
                }
            }
        })
        return ponderacion
    }

    resetBarData() {
        this.multipleBars = false
        this.ponderablesBarra = []
        this.ponderablesBarraHistoria = []
        this.ponderablesBarraCiencia = []
    }

    updatePonderacion(ponderacion, valor: number) {
        if (this.simulacionByPonderable.hasOwnProperty(ponderacion)) {
            this.simulacionByPonderable[ponderacion] = Math.round(valor)
            this.simulacionByPonderableSubject.next(this.simulacionByPonderable)
        }
    }

    updateUsuario(usuario) {
        this.usuario = usuario
        this.usuarioSubject.next(this.usuario)
    }
}
