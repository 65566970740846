/** @format */

import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, HttpVerbs, AppEnv, AppConfig } from "@puntaje/shared/core"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { Usuario } from "./usuarios.model"

@Injectable()
export class Usuarios extends PuntajeBaseService<Usuario> {
    tableName = "usuarios"
    singularTableName = "usuario"
    protected objectQuery: Usuario
    modelClass = Usuario

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv,
        public config: AppConfig
    ) {
        super(http, auth, router, errores, environment)
    }

    public getRequestOptions() {
        const opts = super.getRequestOptions()

        if (this.httpVerb == HttpVerbs.GET) {
            opts.headers = opts.headers || new HttpHeaders()
            opts.headers = opts.headers.set("Cache-Control", "no-cache, no-store")
        }

        return opts
    }

    public save(objectQuery: Usuario) {
        objectQuery.lugar_id = objectQuery.lugar ? objectQuery.lugar.id : null
        ;(<any>objectQuery).plataforma = this.config.plataforma.name
        return super.save(objectQuery)
    }

    public informeUss() {
        return this.all().concatExtra("informe_csv").get()
    }

    public exists(params: any) {
        return this.all().concatExtra("exists").get(params)
    }

    public getSentiment(usuarioId: number, sentiment: any = null) {
        return this.one(usuarioId).concatExtra("sentiment").get(sentiment)
    }

    public setSentiment(usuarioId: number, sentiment: any = null) {
        return this.one(usuarioId).concatExtra("sentiment").post(sentiment)
    }

    public getMercadopago(params: any = null) {
        return this.all().concatExtra("mercadopago").get(params)
    }

    public getFreeTrial(usuarioId: number) {
        return this.one(usuarioId).concatExtra("free_trial").get()
    }

    public getUrlWithToken(usuarioId: number, params: any) {
        return this.one(usuarioId).concatExtra("token_url").get(params)
    }

    public sendCodigoConfirmation(params: any) {
        return this.all().concatExtra("confirm_codigo").post(params)
    }

    public sendCodigo(params: any) {
        return this.all().concatExtra("send_codigo").post(params)
    }

    public requestConfirmarEmail() {
        return this.all().concatExtra("request_confirmar_email").post()
    }

    public activar(usuarioId: number) {
        return this.one(usuarioId).concatExtra("activar").post()
    }

    public desactivar(usuarioId: number) {
        return this.one(usuarioId).concatExtra("desactivar").post()
    }

    public updatePassword(usuarioId: number, obj: any) {
        return this.all().concatExtra(`update_password/${usuarioId}`).patch(obj)
    }

    public async validarRutSii(rut, serial) {
        this.enableIgnoreModel()
        return this.all()
            .concatExtra("validar_rut_sii")
            .get({ rut: rut, serial: serial })
            .then(response => {
                this.disableIgnoreModel()
                return !!(response as any).valido
            })
    }

    public exists_email_identificador(params: any) {
        this.enableIgnoreModel()
        return this.all()
            .concatExtra("exists_email_identificador")
            .post(params)
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }

    public captchaRequired(params: any = {}) {
        this.enableIgnoreModel()
        return this.all()
            .concatExtra("captcha_required")
            .get(params)
            .then(response => {
                this.disableIgnoreModel()
                return !!(response as any).required
            })
    }

    public codigoRegistroValido(codigo: string) {
        this.enableIgnoreModel()
        return this.all()
            .concatExtra("codigo_registro_valido")
            .get({ codigo })
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }

    public resetMassivePasswords(grupo_id, docente) {
        const params: any = { grupo_id: grupo_id, propietario: docente }
        return this.all().concatExtra("reset_massive_passwords").post(params)
    }

    public activarRestriccionDatos(usuarioId: number, restriccion_datos: boolean) {
        const params: any = { restriccion_datos }
        return this.one(usuarioId).concatExtra("activar_restriccion_datos").post(params)
    }
}
