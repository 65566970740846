<div resize-aware (currentSize)="adaptToSize($event)">
    <loading-layout #loadingLayout>
        <table
            class="table cui-table"
            [class.verticalContent]="applyVertical"
            [class.withLabels]="withVerticalLabels"
            *ngIf="rows && rows.length > 0"
        >
            <thead>
                <ng-content select="tr[cui-table-header-row]"></ng-content>
            </thead>
            <tbody>
                <ng-content select="tr[cui-table-data-row]"></ng-content>
            </tbody>
        </table>
    </loading-layout>
    <paginator (onGetPage)="autoload($event)" *ngIf="withPaginator" [getList]="paginatorGetList"></paginator>
    <p *ngIf="isReady && rows && rows.length == 0" class="no-data">
        <ng-content select="cui-table-no-data"></ng-content>
    </p>
</div>
