import { Subject } from "rxjs"
import { Injectable } from "@angular/core"

@Injectable()
export class FiltroEstadisticasPlanPersonalService {
    changeParams: Subject<any> = new Subject()

    establecimientoId: number
    grupoUsuarioId: number
    planPersonalId: number
    generadorInstrumentoId: number

    constructor() {}

    setFiltrosId(establecimientoId, grupoUsuarioId, planPersonalId, generadorInstrumentoId) {
        this.establecimientoId = establecimientoId
        this.grupoUsuarioId = grupoUsuarioId
        this.planPersonalId = planPersonalId
        this.generadorInstrumentoId = generadorInstrumentoId
    }

    onChangeParams() {
        this.changeParams.next({
            establecimientoId: this.establecimientoId,
            grupoUsuarioId: this.grupoUsuarioId,
            planPersonalId: this.planPersonalId,
            generadorInstrumentoId: this.generadorInstrumentoId
        })
        this.clearParams()
    }

    clearParams() {
        this.establecimientoId = this.grupoUsuarioId = this.planPersonalId = null
    }
}
