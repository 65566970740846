import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "terms-and-conditions",
    templateUrl: "terms_and_conditions.component.html",
    styleUrls: ["terms_and_conditions.component.scss"]
})
export class TermsAndConditionsComponent {
    companySite: string
    companyName: string

    constructor(protected router: Router, protected route: ActivatedRoute) {
        let cfg = config
        this.companyName = config.plataforma.info.companyName
        this.companySite = config.plataforma.info.companySite
    }

    ngOnInit() {}
}
