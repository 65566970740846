import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core"
import { Notificacion, Notificaciones } from "@puntaje/puntaje/api-services"
import { NotificacionAccionesService } from "../notificacion_acciones.service"
import { Subscription } from "rxjs"
import { Router } from "@angular/router"
import { NotificacionesPendingService } from "../notificaciones_pending.service"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "notificacion-plan-personal-reforzamiento",
    templateUrl: "notificacion_plan_personal_reforzamiento.component.html",
    styleUrls: ["notificacion.component.scss"]
})
export class NotificacionPlanPersonalReforzamientoComponent implements OnInit {
    @Input() notificacion: Notificacion
    @Input() enableMini: boolean = false
    @Input("disableMarkButton") disableMarkButton: boolean = false
    @Output() markAsNotPending: EventEmitter<any> = new EventEmitter<any>()
    checkedSubscription: Subscription
    notificacionDetails: any
    remitenteLink: string
    realizarLink: string
    establecimiento: string
    grupo: string
    animateExit: boolean = false
    menuSesiones: boolean = config.plataforma.menuSesiones || false
    nombreGrupo: string = config.plataforma.grupoUsuarioAlias || "curso"

    constructor(
        protected notificacionesService: Notificaciones,
        protected notificacionesPendingService: NotificacionesPendingService,
        protected router: Router
    ) {}

    ngOnInit() {
        this.notificacionDetails = JSON.parse(this.notificacion.notificacion)

        const planPersonal = this.notificacionDetails.plan_personal
        const planPersonalSesion = this.notificacionDetails.plan_personal_sesion
        const ciclo = this.notificacionDetails.ciclo

        if (!this.menuSesiones) {
            this.realizarLink = `/plan_personal/${planPersonal.id}/ciclo/${ciclo}/sesiones/${planPersonalSesion.id}`
        } else {
            this.realizarLink = `/plan_personal/${planPersonal.id}`
        }

        const receptor = this.notificacionDetails.receptor
        if (receptor.to_notif_s && receptor.to_notif_s.establecimiento) {
            this.establecimiento = receptor.to_notif_s.establecimiento
            this.grupo = receptor.nombre
        }
    }

    onClickLink() {
        if (this.notificacion.fecha_visto) {
            this.router.navigate([this.realizarLink])
        } else {
            this.animateExit = true
            let n = new Notificacion()
            n.fecha_visto = new Date()
            this.notificacionesService.update(this.notificacion.id, n).then(() => {
                this.notificacionesPendingService.update()
                this.markAsNotPending.emit(this.notificacion.id)
                this.router.navigate([this.realizarLink])
            })
        }
    }
}
