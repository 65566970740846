import { Component } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "tutor_estadisticas.component.html",
    styleUrls: ["tutor_estadisticas.component.scss"]
})
export class TutorEstadisticasComponent {
    asignatura: Asignatura
    cursoId: number = 1
    asignaturaId: number
    private sub: Subscription
    enableResponsive: boolean = false
    tipoInstrumento: string

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas.tutor_estadisticas")
        this.sub = this.route.queryParams.subscribe(queryParams => {
            this.tipoInstrumento = queryParams["tipo_instrumento"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
