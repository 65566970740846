<notificacion-layout
    [notificacion]="notificacion"
    [enableMini]="enableMini"
    [icono]="'prueba'"
    [tipoNotificacion]="'evaluacion'"
    [disableMarkButton]="disableMarkButton"
>
    <notificacion-contenido>
        <p class="texto-notificacion">
            <a [routerLink]="usuario_remitente_link">{{ usuario_remitente }}</a>
            <span *ngIf="establecimiento">
                {{ "notificaciones.de" | t }}&nbsp;{{ establecimiento }}, {{ "notificaciones.del" | t }}&nbsp;{{
                    nombreGrupo
                }}&nbsp;{{ grupo }}
            </span>
            <span *ngIf="!establecimiento">{{ "notificaciones.del_grupo" | t }} {{ grupo }}</span>
            {{ "notificaciones.te_ha_enviado" | t }}
            {{ tipoEvaluacion | transformInstrumento: "conArticuloIndefinido" }} {{ "notificaciones.de" | t }} "{{
                generador_instrumento
            }}" {{ "denominada" | genderize: tipoEvaluacion }} "
            <button type="button" class="btn-link" (click)="onClickLink()">{{ evaluacion }}</button>
            ".
        </p>
        <ul class="lista-detalles" [class.showDetails]="showDetailsWhileMini">
            <li>
                <span class="detail-icon preguntas-icon"><fa [name]="'pencil-square-o'"></fa></span>
                {{ "notificaciones.n_preguntas" | t }}: {{ numero_preguntas }}
            </li>
            <li>
                <span class="detail-icon tiempo-icon"><fa [name]="'clock-o'"></fa></span>
                {{ "notificaciones.tiempo" | t }}: {{ tiempo }}
            </li>
            <li *ngIf="fecha_termino">
                <span class="detail-icon fecha-icon"><ogr-icon [name]="'calendario'"></ogr-icon></span>
                {{ "notificaciones.plazo_hasta" | t }}: {{ fecha_termino | date: "dd-MM-yyyy HH:mm" }}
            </li>
        </ul>
    </notificacion-contenido>
    <notificacion-acciones-footer>
        <cui-button type="button" class="btn_style pull-right" (click)="onClickLink()">
            {{ "notificaciones.realizar_ahora" | t }}
        </cui-button>
        <cui-button type="button" class="btn_style_2 pull-right" *ngIf="enableMini" (click)="toggleDetails()">
            {{ "notificaciones.detalles" | t }}
        </cui-button>
    </notificacion-acciones-footer>
</notificacion-layout>
