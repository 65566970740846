import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Ponderacion } from "./ponderaciones.model"
import { Carrera } from "./carreras.model"
import { PreferenciaCarreraSede } from "./preferencia_carrera_sedes.model"
import { Sede } from "./sedes.model"
import { Audiovisual } from "./audiovisuales.model"
import { InfoAdicional } from "./info_adicionales.model"
import { CampoExtra } from "./campo_extras.model"
import { CarreraSedeCampoExtra } from "./carrera_sede_campo_extras.model"
import { Facultad } from "./facultades.model"

export class CarreraSede extends BaseModel {
    public static className: string = "CarreraSede"

    public carreraId: number
    public sedeId: number
    public facultadId: number
    public alias: string
    public descripcion: string
    public jornada: "diurno" | "vespertino"
    public likesCarrera: number
    public modalidad: string
    public area: string

    @SaveType(() => Ponderacion) ponderaciones: Ponderacion[]
    @SaveType(() => InfoAdicional) infoadicionales: InfoAdicional[]
    @SaveType(() => Audiovisual) audiovisuales: Audiovisual[]
    @SaveType(() => Carrera) carrera: Carrera
    @SaveType(() => Sede) sede: Sede
    @SaveType(() => Facultad) facultad: Facultad
    @SaveType(() => PreferenciaCarreraSede) preferenciaCarreraSedes: PreferenciaCarreraSede[]
    @SaveType(() => CampoExtra) campoextras: CampoExtra[]
    @SaveType(() => CarreraSedeCampoExtra) carreraSedeCampoExtras: CarreraSedeCampoExtra[]

    public toString() {
        let nombre = this.alias
        if (this.jornada) {
            nombre += " (" + this.jornada + ")"
        }

        return nombre
    }
}
