import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"

export class ComentarioForm extends BaseForm {
    public static get formParams() {
        return {
            id: {
                label: "Id",
                type: "hidden",
                visible: false
            },
            comentario: {
                label: "",
                type: "ckeditor",
                visible: true,
                validations: [Validators.required, Validators.minLength(4)]
            }
        }
    }
}
