import { Injector } from "@angular/core"
import { Validators } from "@angular/forms"
import { AppConfig, BaseModel, SaveType } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { Clasificacion } from "./clasificaciones.model"
import { ClasificacionPregunta } from "./clasificacion_preguntas.model"
import { Contestable } from "./contestables.model"
import { GeneradorInstrumentoInstruccion } from "./generador_instrumento_instrucciones.model"
import { GrupoPregunta } from "./grupo_preguntas.model"
import { Preguntas } from "./preguntas.service"
import { Taxativo } from "./taxativos.model"
import { Persona } from "./personas.model"
import { PreguntaMaterial } from "./pregunta_materiales.model"
import { RevisionRecurso } from "./revision_recursos.model"
import { CertificacionRecurso } from "./certificacion_recursos.model"
import { PreguntaAsignatura } from "./pregunta_asignaturas.model"
import { Asignatura } from "./asignaturas.model"
import { PreguntaPlataforma } from "./pregunta_plataformas.model"
import { Plataforma } from "./plataformas.model"
import { PersonaExterna } from "./persona_externas.model"
import { UsuarioRecurso } from "./usuario_recursos.model"

declare const config: AppConfig
export class Pregunta extends BaseModel {
    public static className: string = "Pregunta"
    public id: number
    public asignatura_id: number
    public grupo_pregunta_id: number
    public solucion: string
    public solucion_video: string
    public estado: number
    public solucion_latex: string
    public visible: boolean
    public presencial: boolean
    public comentarios: string
    public profesor_id: number
    public encargado_reporte_id: number
    public autor_id: number
    public mencion: boolean //se traduce a categoría en API
    public cantidad_reportes: number
    public numero_alternativas: number //parametro de formulario de filtro
    public likes: number
    public dislikes: number
    public className = "Pregunta"
    public nombre_asignatura: string
    public created_at: string
    public asignatura_ids: number[]
    public pregunta_ref_id: number

    public nombre_autor: string
    public nombre_profesor: string
    public is_from_platform: boolean

    @SaveType(() => Taxativo) public taxativos: Taxativo[]
    @SaveType(() => Contestable) public contestables: Contestable[]
    @SaveType(() => GrupoPregunta) public grupo_pregunta: GrupoPregunta
    @SaveType(() => ClasificacionPregunta) public clasificacion_preguntas: ClasificacionPregunta[]
    @SaveType(() => Clasificacion) public clasificaciones: Clasificacion[]
    @SaveType(() => GeneradorInstrumentoInstruccion)
    generador_instrumento_instrucciones: GeneradorInstrumentoInstruccion[]
    @SaveType(() => Persona) public profesor: Persona
    @SaveType(() => Persona) public encargado_reporte: Persona
    @SaveType(() => Persona) public autor: Persona
    @SaveType(() => PreguntaMaterial) public pregunta_material: PreguntaMaterial
    @SaveType(() => RevisionRecurso) public revision_recursos: RevisionRecurso[]
    @SaveType(() => CertificacionRecurso) public certificacion_recursos: CertificacionRecurso[]
    @SaveType(() => UsuarioRecurso) public usuario_recursos: UsuarioRecurso[]
    // @SaveType(() => PreguntaAsignatura) public pregunta_asignaturas: PreguntaAsignatura[]
    @SaveType(() => Asignatura) public asignaturas: Asignatura[]
    @SaveType(() => Pregunta) public pregunta_ref: Pregunta
    @SaveType(() => PreguntaPlataforma) public pregunta_plataformas: PreguntaPlataforma[]
    @SaveType(() => Plataforma) public plataformas: Plataforma[]
    @SaveType(() => PersonaExterna) public persona_externa: PersonaExterna

    constructor(empty: boolean = false) {
        super()

        if (!empty) {
            this.taxativos = []
            this.contestables = []
            this.grupo_pregunta = new GrupoPregunta()
            this.clasificaciones = []
            this.clasificacion_preguntas = []
            this.plataformas = []
            this.visible = false
            this.presencial = false
            this.estado = 1
        }
    }

    public static get serviceClass() {
        return Preguntas
    }

    public static get filter_params(): any {
        return {
            id: { label: "Id", type: "text" },
            asignatura_id: { label: "Asignatura", type: "select", options: { class: Asignaturas } },
            //taxativo: {type: "model", class: Taxativo},
            solucion: { label: "Solucion", type: "text" }
        }
    }

    public static get model_params(): any {
        var params = {
            id: { label: "Id", type: "hidden", visible: false },
            asignatura_id: {
                label: "Asignatura",
                type: "select",
                visible: true,
                options: { class: Asignaturas },
                validations: [Validators.required]
            },
            grupo_pregunta_id: { label: "Grupo Pregunta", type: "select", visible: true },
            taxativos: { type: "model", class: Taxativo },
            contestables: { type: "model", class: Contestable },
            solucion: { label: "Solución", type: "ckeditor", visible: true, validations: [Validators.required] },
            solucion_video: { label: "Solución Video", type: "textarea", visible: true },
            solucion_latex: { label: "Solución Latex", type: "textarea", visible: true },
            estado: { label: "Estado", type: "checkbox", visible: true },
            visible: { label: "Visible", type: "checkbox", visible: true },
            presencial: { label: "Presencial", type: "checkbox", visible: true },
            comentarios: { label: "Comentarios", type: "textarea", visible: true }
        }
        return params
    }

    public static get show_params(): any {
        return {
            id: { label: "Id", type: "link", tableVisible: true, options: { class: Preguntas, key: "id" } },
            nombre_asignatura: {
                label: "Asignatura",
                type: "link",
                tableVisible: true,
                options: { class: Asignaturas, key: "asignatura_id" }
            },
            grupo_pregunta_id: { label: "Grupo Pregunta", type: "text", tableVisible: false },
            pregunta: { label: "Pregunta", type: "text", tableVisible: true },
            taxativos: { label: "Enunciado", type: "html", tableVisible: false },
            contestables: { label: "Contestable", type: "text", tableVisible: false },
            solucion: { label: "Solución", type: "text", tableVisible: false },
            solucion_video: { label: "Solución Video", type: "text", tableVisible: false },
            solucion_latex: { label: "Solución Latex", type: "text", tableVisible: false },
            estado: { label: "Estado", type: "text", tableVisible: true },
            visible: { label: "Visible", type: "boolean", tableVisible: true },
            presencial: { label: "Presencial", type: "boolean", tableVisible: true },
            comentarios: { label: "Comentarios", type: "text", tableVisible: false },
            profesor_id: { label: "Profesor", type: "text", tableVisible: false },
            encargado_reporte_id: { label: "Encargado Reporte", type: "text", tableVisible: false },
            created_at: { label: "Fecha de creación", type: "date", tableVisible: false },
            updated_at: { label: "Fecha de actualización", type: "date", tableVisible: false }
        }
    }

    public toString() {
        return this.id.toString()
    }

    public getLikePromise(injector: Injector, params: any = {}): Promise<any> {
        return injector.get(Preguntas).like(this.id, params)
    }

    public getDislikePromise(injector: Injector, params: any = {}): Promise<any> {
        return injector.get(Preguntas).dislike(this.id, params)
    }

    getSentimentPromise(injector: Injector, params: any = {}): Promise<any> {
        return injector.get(Preguntas).sentiment(this.id, params)
    }

    isFromPlatform() {
        return this.plataformas.length == 0 || this.plataformas.find(p => p.plataforma == config.plataforma.name)
    }
}
