import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { Clasificacion } from "./clasificaciones.model"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"

@Injectable()
export class Clasificaciones extends NebuBaseService<Clasificacion> {
    tableName = "clasificaciones"
    singularTableName = "clasificacion"
    modelClass = Clasificacion

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public wherePost(params: any = null) {
        return this.all().concatExtra("post").post(params, 120000)
    }
}
