import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Establecimiento } from "./establecimientos.model"
import { AsociacionEstablecimiento } from "./asociacion_establecimientos.model"
import { AsociacionUsuario } from "./asociacion_usuarios.model"
import { Usuario } from "./usuarios.model"
import { AsociacionMonitor } from "./asociacion_monitores.model"

export class Asociacion extends BaseModel {
    public asociacion: string
    @SaveType(() => Establecimiento) public establecimientos: Establecimiento[]
    @SaveType(() => AsociacionEstablecimiento) public asociacion_establecimientos: AsociacionEstablecimiento[]
    @SaveType(() => AsociacionUsuario) public asociacion_usuarios: AsociacionUsuario[]
    @SaveType(() => AsociacionMonitor) public asociacion_monitores: AsociacionMonitor[]
    @SaveType(() => Usuario) public usuarios: Usuario[]
    @SaveType(() => Usuario) public monitores: Usuario[]

    constructor() {
        super()
    }

    public toString() {
        return this.asociacion
    }
}
