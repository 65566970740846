import { BaseModel } from "@puntaje/shared/core"

export class TypeformWidgetTipo extends BaseModel {
    public tipo: string

    public toString() {
        if (this.tipo) {
            return this.tipo
        } else {
            return ""
        }
    }
}
