import { Component, OnInit, ChangeDetectorRef, HostBinding, Input } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import {
    EvaluacionMultiples,
    EvaluacionMultiple,
    EvaluacionInstancias,
    EvaluacionMultipleInstancia,
    EvaluacionMultipleInstancias,
    EvaluacionTiempos,
    GrupoRecursos,
    GrupoRecurso,
    EvaluacionInstancia
} from "@puntaje/puntaje/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import seedrandom from "seedrandom"

@Component({
    selector: "vista-instrumentos-multiples",
    templateUrl: "./vista-instrumentos-multiples.component.html",
    styleUrls: ["./vista-instrumentos-multiples.component.scss"]
})
export class VistaInstrumentosMultiplesComponent implements OnInit {
    usuarioId: string
    @Input() listaView: boolean = true

    evaluacionMultiples: EvaluacionMultiple[]
    evaluacionMultipleInstanciasByEvaluacionMultipleId: { [id: number]: EvaluacionMultipleInstancia }
    evaluacionInstanciasByEvaluacionMultipleId: { [id: number]: EvaluacionInstancia[] }
    lockedByEvaluacionMultipleId: { [id: number]: boolean } = {}
    @HostBinding("class") innerClass = "showcasebox_style_1"

    constructor(
        protected authService: AuthService,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected evaluacionMultiplesService: EvaluacionMultiples,
        protected evaluacionMultipleInstanciasService: EvaluacionMultipleInstancias,
        protected evaluacionTiemposService: EvaluacionTiempos,
        protected grupoRecursosService: GrupoRecursos,
        protected cdr: ChangeDetectorRef,
        protected store: Store<State>
    ) {}

    async ngOnInit() {
        let usuario = this.authService.getUserData()
        const usuarioId = usuario.id

        const grupoRecursos = await this.getGrupoRecursos()
        this.evaluacionMultiples = await this.getEvaluacionesMultiplesFromGrupoRecursos(grupoRecursos, usuarioId)
        const evaluacionMultipleInstancias = await this.getEvaluacionMultipleInstancias(
            this.evaluacionMultiples,
            usuarioId
        )

        this.evaluacionMultipleInstanciasByEvaluacionMultipleId = evaluacionMultipleInstancias.reduce((acc, emi) => {
            if (!acc[emi.evaluacion_multiple_id] || acc[emi.evaluacion_multiple_id].nintento < emi.nintento) {
                acc[emi.evaluacion_multiple_id] = emi
            }

            return acc
        }, {})

        const evaluacionInstancias = await this.getEvaluacionInstancias(this.evaluacionMultiples, usuarioId)

        this.evaluacionInstanciasByEvaluacionMultipleId = this.evaluacionMultiples.reduce((acc, em) => {
            const evaluacionInstanciasEvaluaciones = evaluacionInstancias.filter(ei =>
                em.evaluaciones.find(e => e.id === ei.evaluacion_id)
            )
            const evaluacionInstanciaByEvaluacion = evaluacionInstanciasEvaluaciones.reduce((acc, ei) => {
                acc[ei.evaluacion_id] = acc[ei.evaluacion_id] ?? ei
                if (ei.nintento > acc[ei.evaluacion_id].nintento) {
                    acc[ei.evaluacion_id] = ei
                }

                return acc
            }, {} as { [id: number]: EvaluacionInstancia })

            acc[em.id] = Object.values(evaluacionInstanciaByEvaluacion)

            return acc
        }, {})

        this.cdr.detectChanges()
    }

    private async getGrupoRecursos() {
        const params = {
            grupo_recurso: {
                recurso_type: "EvaluacionMultiple"
            },
            grupo_recurso_tipo: {
                grupo_recurso_tipo: "prueba de practica"
            }
        }

        this.grupoRecursosService.enableIgnoreModel()
        const grupoRecursos = await this.grupoRecursosService.where(params)
        this.grupoRecursosService.disableIgnoreModel()

        return grupoRecursos
    }

    private async getEvaluacionesMultiplesFromGrupoRecursos(grupoRecursos: GrupoRecurso[], usuarioId: number) {
        const evaluacionMultipleParams = {
            evaluacion_multiple: {
                id: grupoRecursos.map(gr => gr.recurso_id)
            },
            include: "[evaluacion_tipo,evaluaciones:[instrumento]]"
        }

        let evaluacionMultiples = await this.evaluacionMultiplesService.where(evaluacionMultipleParams)

        const rng = seedrandom(`${usuarioId}..`)
        const shuffledEvaluacionMultiples = evaluacionMultiples
            .map(em => ({ em, sortValue: rng() }))
            .sort((a, b) => a.sortValue - b.sortValue)
            .map(({ em }) => em)

        return shuffledEvaluacionMultiples
    }

    private async getEvaluacionMultipleInstancias(evaluacionMultiples: EvaluacionMultiple[], usuarioId: number) {
        const evaluacionMultipleInstanciasParams = {
            search: 1,
            sort_by: "created_at",
            order: "desc",
            evaluacion_multiple_instancia: {
                evaluacion_multiple_id: evaluacionMultiples.map(em => em.id),
                usuario_id: usuarioId
            },
            include: "[evaluacion_instancias:[evaluacion:[instrumento:generador_instrumento]]]",
            methods: "[calificacion]"
        }

        const evaluacionMultipleInstancias = await this.evaluacionMultipleInstanciasService.where(
            evaluacionMultipleInstanciasParams
        )

        return evaluacionMultipleInstancias
    }

    private async getEvaluacionInstancias(evaluacionMultiples: EvaluacionMultiple[], usuarioId: number) {
        const evaluacionInstanciasParams = {
            evaluacion_instancia: {
                evaluacion_id: evaluacionMultiples.map(em => em.evaluaciones.map(e => e.id)).flat(),
                usuario_id: usuarioId
            },
            include: "[evaluacion:[instrumento:generador_instrumento]]"
        }

        const evaluacionInstancias = await this.evaluacionInstanciasService.where(evaluacionInstanciasParams)

        return evaluacionInstancias
    }
}
