import { SaveType, BaseModel } from "@puntaje/shared/core"
import { GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { PlanPersonalCiclo } from "./plan_personal_ciclos.model"
import { PlanPersonalUsuario } from "./plan_personal_usuarios.model"
import { Usuario } from "./usuarios.model"
import { PlanPersonalTipo } from "./plan_personal_tipos.model"
import { PlanPersonalInstancia } from "./plan_personal_instancias.model"
import { PlanPersonalPredefinido } from "./plan_personal_predefinidos.model"

export class PlanPersonal extends BaseModel {
    public descripcion: string
    public plan_personal: string
    public usuario_id: number
    public generador_instrumento_id: number
    public plan_personal_tipo_id: number
    public evaluacion_id: number
    public evaluacion_instancia_id: number
    public plan_personal_padre_id: number
    public archivo_url: string
    public libro_url_profesor: string
    public libro_url_alumno: string
    public total_ciclos: number
    public created_at: Date
    public fecha_inicial: string

    porcentaje_transcurrido?: number
    total_sesiones?: number

    @SaveType(() => PlanPersonalCiclo) public plan_personal_ciclos: PlanPersonalCiclo[]
    @SaveType(() => Usuario) public usuario: Usuario
    @SaveType(() => GeneradorInstrumento) public generador_instrumento: GeneradorInstrumento
    @SaveType(() => PlanPersonalUsuario) public plan_personal_usuarios: PlanPersonalUsuario[]
    @SaveType(() => PlanPersonalInstancia) public plan_personal_instancias: PlanPersonalInstancia[]
    @SaveType(() => PlanPersonalTipo) public plan_personal_tipo: PlanPersonalTipo
    @SaveType(() => PlanPersonal) public plan_personal_padre: PlanPersonal
    @SaveType(() => PlanPersonalPredefinido) public plan_personal_predefinido: PlanPersonalPredefinido
}
