import { Injectable, OnInit } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { Estadisticas } from "@puntaje/puntaje/api-services"
import {
    Asignaturas,
    ClasificacionTipo,
    ClasificacionTipos,
    Clasificacion,
    Clasificaciones
} from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Observable } from "rxjs"

@Injectable()
export class EstadisticasEvolutivasService {
    clasificacionesTipos: { [key: string]: ClasificacionTipo }
    clasificacionesPadres: { [key: number]: number }

    constructor(
        protected auth: AuthService,
        protected asignaturaService: Asignaturas,
        protected clasificacionTiposService: ClasificacionTipos,
        protected clasificacionesService: Clasificaciones,
        protected estadisticasService: Estadisticas
    ) {}

    public getClasificacionesTipos() {
        return this.clasificacionesTipos
    }

    public getEstadisticasDiaRango(
        generadores: number[],
        desde: string,
        hasta: string,
        usuarioId: number[] = undefined
    ) {
        var userId = usuarioId
        if (!userId) {
            userId = this.auth.getUserData().id
        }
        let params = {
            collection: "EstadisticaUsuarioDia",
            estadistica: {
                oficial: 1,
                generador_instrumento_id: generadores,
                usuario_id: userId
            },
            dia: {
                gte: desde,
                lte: hasta
            }
        }

        return this.estadisticasService.where(params).then((estadisticas: any[]) => {
            if (estadisticas.length > 0) {
                var por_generador = {}
                estadisticas.map(e => {
                    if (por_generador[e["generador_instrumento_id"]]) {
                        por_generador[e["generador_instrumento_id"]].push([Date.parse(e["dia"]), e["maximo"]])
                    } else {
                        por_generador[e["generador_instrumento_id"]] = [[Date.parse(e["dia"]), e["maximo"]]]
                    }
                })
                let escala = Object.keys(estadisticas[0]["distribucion_calificaciones"])
                return [por_generador, [parseInt(escala[0]), parseInt(escala[escala.length - 1])]]
            } else {
                return [{}, [100, 1000]]
            }
        })
    }

    public getEstadisticaUsuario(
        generadores: number[],
        usuarioId?: number,
        grupoUsuarioId?: number | number[],
        evaluacionTipo?: string | string[],
        formativa?: Boolean
    ) {
        let userId = usuarioId
        if (!userId) {
            userId = this.auth.getUserData().id
        }
        const params = {
            collection: "EstadisticaUsuario",
            estadistica: {
                oficial: 1,
                generador_instrumento_id: generadores,
                usuario_id: userId
            }
        }

        if (grupoUsuarioId !== undefined) {
            params.estadistica["grupo_usuario_id"] = grupoUsuarioId
        }

        if (evaluacionTipo !== undefined && evaluacionTipo !== null && evaluacionTipo !== "") {
            params.estadistica["evaluacion_tipo"] = evaluacionTipo
        }

        if (formativa != undefined) {
            params.estadistica["formativa"] = formativa
        }
        this.estadisticasService.enableIgnoreModel()
        return this.estadisticasService.where(params).then((estadisticas: any[]) => {
            this.estadisticasService.disableIgnoreModel()
            return estadisticas
        })
    }

    private ordenarSubClasificaciones(clasificaciones: any) {
        var copy = clasificaciones
        Object.keys(clasificaciones).forEach(asig => {
            Object.keys(clasificaciones[asig]).forEach(c => {
                let padre = this.clasificacionesPadres[c]
                if (padre) {
                    if (clasificaciones[asig][padre]) {
                        if (!copy[asig][padre]["clasificaciones"]) {
                            copy[asig][padre]["clasificaciones"] = []
                        }
                        copy[asig][padre]["clasificaciones"].push(clasificaciones[asig][c])
                        delete copy[asig][c]
                    }
                }
            })
        })
        return copy
    }

    private auxEstadisticasInstanciaClasificaciones(clasificacion_tipo: string | string[], p: Promise<any>) {
        //Función auxiliar que calcula las barras de buenas, malas y omitidas
        return p.then((estadisticas: any[]) => {
            if (estadisticas.length > 0) {
                var por_generador = {}
                estadisticas.map(e => {
                    if (por_generador[e["generador_instrumento_id"]]) {
                        por_generador[e["generador_instrumento_id"]].push(e["estadistica_clasificaciones"])
                    } else {
                        por_generador[e["generador_instrumento_id"]] = [e["estadistica_clasificaciones"]]
                    }
                })
                var clasificaciones = {}
                for (let key in por_generador) {
                    por_generador[key].forEach(l => {
                        l.forEach(cl => {
                            var check = false
                            var cts = this.getClasificacionesTipos()
                            if (typeof clasificacion_tipo === "string") {
                                let ct_id = cts[clasificacion_tipo].id
                                check = cl.clasificacion.clasificacion_tipo_id == ct_id
                            } else {
                                if (cts && Object.keys(cts).length > 0) {
                                    let ct_ids = clasificacion_tipo.map(ct => {
                                        return cts[ct].id
                                    })
                                    check = ct_ids.includes(cl.clasificacion?.clasificacion_tipo_id)
                                }
                            }
                            if (check) {
                                if (!clasificaciones[cl.clasificacion.asignatura_id]) {
                                    clasificaciones[cl.clasificacion.asignatura_id] = {}
                                    clasificaciones[cl.clasificacion.asignatura_id][cl.clasificacion.id] = {
                                        clasificacion: cl.clasificacion.clasificacion,
                                        correctas: cl.correctas,
                                        incorrectas: cl.incorrectas,
                                        omitidas: cl.omitidas
                                    }
                                } else if (!clasificaciones[cl.clasificacion.asignatura_id][cl.clasificacion.id]) {
                                    clasificaciones[cl.clasificacion.asignatura_id][cl.clasificacion.id] = {
                                        clasificacion: cl.clasificacion.clasificacion,
                                        correctas: cl.correctas,
                                        incorrectas: cl.incorrectas,
                                        omitidas: cl.omitidas
                                    }
                                } else {
                                    clasificaciones[cl.clasificacion.asignatura_id][cl.clasificacion.id]["correctas"] =
                                        clasificaciones[cl.clasificacion.asignatura_id][cl.clasificacion.id][
                                            "correctas"
                                        ] + cl.correctas
                                    clasificaciones[cl.clasificacion.asignatura_id][cl.clasificacion.id][
                                        "incorrectas"
                                    ] =
                                        clasificaciones[cl.clasificacion.asignatura_id][cl.clasificacion.id][
                                            "incorrectas"
                                        ] + cl.incorrectas
                                    clasificaciones[cl.clasificacion.asignatura_id][cl.clasificacion.id]["omitidas"] =
                                        clasificaciones[cl.clasificacion.asignatura_id][cl.clasificacion.id][
                                            "omitidas"
                                        ] + cl.omitidas
                                }
                            }
                        })
                    })
                }
                let sub_clasificado = this.ordenarSubClasificaciones(clasificaciones)
                return sub_clasificado
            } else {
                return {}
            }
        })
    }

    private auxEICUsuario(
        clasificacion_tipo: string | string[],
        generadores: number[],
        desde: string,
        hasta: string,
        usuarioId: number[] = undefined
    ) {
        var userId = usuarioId
        if (!userId) {
            userId = this.auth.getUserData().id
        }
        let p = this.estadisticasService.where({
            collection: "EstadisticaUsuarioDia",
            estadistica: { oficial: 1, generador_instrumento_id: generadores, usuario_id: userId },
            dia: { gte: desde, lte: hasta }
        })
        return this.auxEstadisticasInstanciaClasificaciones(clasificacion_tipo, p)
    }

    private auxEICGrupoUsuario(
        clasificacion_tipo: string | string[],
        generadores: number[],
        desde: string,
        hasta: string,
        grupoUsuarioId: number[]
    ) {
        let p = this.estadisticasService.where({
            collection: "EstadisticaEvaluacion",
            estadistica: { oficial: 1, generador_instrumento_id: generadores, grupo_usuario_id: grupoUsuarioId },
            fecha_ultimo: { gte: desde, lte: hasta }
        })
        return this.auxEstadisticasInstanciaClasificaciones(clasificacion_tipo, p)
    }

    private checkClasificaciones(clasificacion_tipo: string | string[], fun: () => Promise<any>) {
        //Función auxiliar que chequear y setear los tipos de clasificación
        if (!this.clasificacionesTipos || !this.clasificacionesPadres) {
            return this.clasificacionesService
                .where({ clasificacion_tipo: { clasificacion_tipo: clasificacion_tipo }, with_clasificaciones: 1 })
                .then(cs => {
                    this.clasificacionesTipos = {}
                    this.clasificacionesPadres = {}
                    ;(cs as any).forEach(c => {
                        if (c.clasificaciones.length > 0) {
                            this.clasificacionesPadres[c.id] = c.clasificaciones[0].id
                        }
                    })
                    return this.clasificacionTiposService.where().then(cts => {
                        ;(cts as any).forEach(ct => {
                            this.clasificacionesTipos[ct.clasificacion_tipo] = ct
                        })
                        return fun()
                    })
                })
        } else {
            return fun()
        }
    }

    public getEstadisticasInstanciaClasificaciones(
        generadores: number[],
        desde: string,
        hasta: string,
        evaluacion_tipo: string = undefined,
        usuarioId: number[] = undefined
    ) {
        let eTipo = evaluacion_tipo || config.plataforma.evaluacionDefault
        let clasificacion_tipo: string | string[] = config.evaluaciones[eTipo].clasificaciones.estadisticas
        return this.checkClasificaciones(clasificacion_tipo, () =>
            this.auxEICUsuario(clasificacion_tipo, generadores, desde, hasta, usuarioId)
        )
    }

    public getEstadisticasInstanciaClasificacionesGrupo(
        generadores: number[],
        desde: string,
        hasta: string,
        evaluacion_tipo: string = undefined,
        grupoUsuarioId: number[] = undefined
    ) {
        let eTipo = evaluacion_tipo || config.plataforma.evaluacionDefault
        let clasificacion_tipo: string | string[] = config.evaluaciones[eTipo].clasificaciones.estadisticas
        return this.checkClasificaciones(clasificacion_tipo, () =>
            this.auxEICGrupoUsuario(clasificacion_tipo, generadores, desde, hasta, grupoUsuarioId)
        )
    }

    public getAsignaturasConGeneradores(tipo_instrumento: string | string[]) {
        return this.asignaturaService.where({
            with_generador_instrumentos: true,
            tipo_instrumento: tipo_instrumento,
            plataforma_id: config.plataforma.id
        })
    }
}
