import { Injectable, Optional } from "@angular/core"
import { CanActivate } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { Observable } from "rxjs"

@Injectable()
export class AsignaturasGuard implements CanActivate {
    constructor(@Optional() private authService: AuthService = null) {}

    canActivate(): Promise<boolean> | boolean | Observable<boolean> {
        return true
    }
}
