import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { Post } from "./posts.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class Posts extends PuntajeBaseService<Post> {
    tableName = "posts"
    singularTableName = "post"
    modelClass = Post

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    getTags() {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("tags")
            .get()
            .then(tags => {
                this.disableIgnoreModel()

                return tags
            })
    }
}
