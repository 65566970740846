import { Injectable } from "@angular/core"
import * as ActionCable from "actioncable"

@Injectable()
export class CableService {
    consumer: any

    constructor() {}

    connect(url: string) {
        this.consumer = ActionCable.createConsumer(url)
    }

    subscribe(channel: string, params = {}, callbacks = null) {
        if (!callbacks) {
            callbacks = params
            params = {}
        }

        return this.consumer.subscriptions.create({ channel, ...params }, callbacks)
    }
}
