import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core"
import { Store } from "@ngrx/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { GrupoPregunta } from "@puntaje/nebulosa/api-services"
import {
    EnableEraseHighlightMode,
    EnableHighlightMode,
    selectEraseHighlightMode,
    State,
    UpdateGrupoPreguntaItem,
    selectGrupoPreguntaItems
} from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { GrupoPreguntaItem } from "@puntaje/puntaje/store"

@Component({
    selector: "grupo-pregunta",
    templateUrl: "grupo_pregunta.component.html",
    styleUrls: ["grupo_pregunta.component.scss"]
})
export class GrupoPreguntaComponent implements OnInit {
    @Input() grupoPregunta: GrupoPregunta
    @Input() preguntaIndex: number = 0
    @Input() enableGrupoPreguntaId: boolean = false
    @Input() enableTextHighlight: boolean = false
    highliteSub: Subscription
    grupoPreguntaItemsSub: Subscription

    grupoPreguntaItem: GrupoPreguntaItem
    eraseMode = false

    @ViewChild("grupoPreguntaTexto") grupoPreguntaElement: ElementRef

    constructor(protected store: Store<State>) {}

    ngOnInit() {
        this.searchForBlanks()
        this.highliteSub = this.store.select(selectEraseHighlightMode).subscribe(erase => {
            this.eraseMode = erase
        })
        this.grupoPreguntaItemsSub = this.store.select(selectGrupoPreguntaItems).subscribe(grupoPreguntaItems => {
            this.grupoPreguntaItem = grupoPreguntaItems[this.grupoPregunta.id.toString()]
        })
    }

    ngOnDestroy() {
        this.highliteSub.unsubscribe()
        this.grupoPreguntaItemsSub.unsubscribe()
        this.enableTextHighlight = config.plataforma.canTextHighlight && this.enableTextHighlight
        this.searchForBlanks()
    }

    searchForBlanks() {
        // Buscar todos los caracteres de ((numero))
        if (this.grupoPregunta) {
            const regExpBlanks = /\(\((\d)\)\)/g
            const match = this.grupoPregunta.texto.match(regExpBlanks)
            if (match && match.length > 0) {
                const re = new RegExp(regExpBlanks, "g")
                this.grupoPregunta.texto = this.grupoPregunta.texto.replace(re, (a, n) => {
                    return +n + this.preguntaIndex + ""
                })
            }
        }
    }

    enableHighlightMode() {
        this.store.dispatch(new EnableHighlightMode())
    }

    enableEraseMode() {
        this.store.dispatch(new EnableEraseHighlightMode())
    }

    updateGrupoPreguntaItem(event) {
        const grupoPreguntaClone = this.grupoPregunta.clone()
        grupoPreguntaClone.texto = this.grupoPreguntaElement.nativeElement.innerHTML
        this.store.dispatch(new UpdateGrupoPreguntaItem(grupoPreguntaClone))
    }
}
