import { Component, OnInit, ViewChild, EventEmitter, Input, ChangeDetectorRef } from "@angular/core"
import { BaseModel } from "@puntaje/shared/core"
import { Retroalimentacion, Retroalimentaciones } from "@puntaje/puntaje/api-services"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { GenericModalComponent } from "@puntaje/shared/core"
import { MaterialPreviewModalComponent } from "@puntaje/puntaje/new-modules/materiales"
import { Material } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "retroalimentacion-individual",
    templateUrl: "./retroalimentacion-individual.component.html",
    styleUrls: ["./retroalimentacion-individual.component.scss"]
})
export class RetroalimentacionIndividualComponent implements OnInit {
    openModal: EventEmitter<any> = new EventEmitter<any>()
    _retroalimentable: { id: number; type: string }
    descripcionConfirm: string =
        "Confirma que deseas usar el archivo de la vista previa como retroalimentación para continuar."
    showLink: boolean = false
    boton: string = " Añadir retroalimentación" // o Editar
    icono: string = "dialogos" // o pdf
    isHtml = false
    showModal = false
    materialOpen = false
    material = new Material()

    @ViewChild(MaterialPreviewModalComponent)
    materialPreviewModalComponent: MaterialPreviewModalComponent
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    @Input() retroalimentacion: Retroalimentacion
    @Input() receptor: { id: number; type: string }
    @Input() perfil: string = "profesor"
    @Input()
    set retroalimentable(retroalimentable: { id: number; type: string }) {
        this._retroalimentable = retroalimentable
    }
    get retroalimentable() {
        return this._retroalimentable
    }

    constructor(
        protected simpleModalService: SimpleModalService,
        protected cdr: ChangeDetectorRef,
        protected retroalimentacionesService: Retroalimentaciones
    ) {}

    ngOnInit() {
        const paramsRetroalimentacion = {
            retroalimentacion: {
                retroalimentable_type: this._retroalimentable.type,
                retroalimentable_id: this._retroalimentable.id,
                receptor_type: this.receptor.type,
                receptor_id: this.receptor.id
            }
        }

        this.retroalimentacionesService
            .where(paramsRetroalimentacion)
            .then((retroalimentaciones: Retroalimentacion[]) => {
                if (retroalimentaciones.length > 0) {
                    this.retroalimentacion = retroalimentaciones[0]
                    this.setConfig()
                    console.log(this.material)
                }
            })
    }

    setConfig() {
        this.isHtml = false
        if (this.retroalimentacion) {
            switch (this.retroalimentacion.tipo) {
                case "html":
                    this.icono = "dialogos"
                    this.isHtml = true
                    break
                case "pdf":
                    this.icono = "pdf"

                    this.material.url = this.retroalimentacion.retroalimentacion
                    this.material.material = "Retroalimentación"
                    this.material.material_tipo_nombre = "Archivo"
                    break
            }
            this.boton = "Editar"
        }
    }

    openModalRetroalimentacion() {
        this.showModal = true
        this.openModal.next()
    }

    closeModalRetroalimentacion() {
        this.genericModal.close()
        this.showModal = false
    }

    retroalimentacionChanged(retroalimentacion) {
        this.retroalimentacion = retroalimentacion
        this.setConfig()
        this.closeModalRetroalimentacion()
    }

    openMaterialPreview(material) {
        this.materialOpen = true
        this.cdr.detectChanges()
        this.materialPreviewModalComponent.open(material)
    }

    closeMaterialPreview() {
        this.materialOpen = false
    }
}
