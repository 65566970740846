import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"

import { FlashMessageComponent } from "./flash_message.component"
import { FlashMessageService } from "./flash_message.service"

@NgModule({
    imports: [CommonModule],
    declarations: [FlashMessageComponent],
    exports: [FlashMessageComponent]
})
export class FlashMessageModule {
    static forRoot(): ModuleWithProviders<FlashMessageModule> {
        return {
            ngModule: FlashMessageModule,
            providers: [FlashMessageService]
        }
    }
}
