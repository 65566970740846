import { SaveType, BaseModel } from "@puntaje/shared/core"
import { BannerInstancia } from "./banner_instancias.model"
import { Universidad } from "./universidades.model"

export class Sede extends BaseModel {
    public sede: string
    public universidad_id: number
    public codigo_universidad: string
    public created_at: Date
    public updated_at: Date
    @SaveType(() => Universidad) public universidad: Universidad

    public toString() {
        return this.sede
    }
}
