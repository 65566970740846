import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { AnimEnterFromLeftComponent } from "./anim-library/anim-enter-from-left.components"
import { AnimEnterFromRightComponent } from "./anim-library/anim-enter-from-right.components"
import { AnimOnScrollFadeInComponent } from "./anim-library/on-scroll-fade-in/anim-on-scroll-fade-in.components"
import { AnimOnScrollEnterFromLeftComponent } from "./anim-library/on-scroll-enter-sliding/anim-on-scroll-enter-from-left.components"
import { AnimOnScrollEnterFromRightComponent } from "./anim-library/on-scroll-enter-sliding/anim-on-scroll-enter-from-right.components"
import { AnimOnScrollEnterFromTopComponent } from "./anim-library/on-scroll-enter-sliding/anim-on-scroll-enter-from-top.components"
import { AnimOnScrollEnterFromBottomComponent } from "./anim-library/on-scroll-enter-sliding/anim-on-scroll-enter-from-bottom.components"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { BrowserModule } from "@angular/platform-browser"
import { UtilModule } from "../util/util.module"

@NgModule({
    imports: [
        CommonModule,
        // BrowserAnimationsModule,
        // BrowserModule,
        UtilModule
    ],
    declarations: [
        AnimEnterFromLeftComponent,
        AnimEnterFromRightComponent,
        AnimOnScrollFadeInComponent,
        AnimOnScrollEnterFromLeftComponent,
        AnimOnScrollEnterFromRightComponent,
        AnimOnScrollEnterFromTopComponent,
        AnimOnScrollEnterFromBottomComponent
    ],
    exports: [
        AnimEnterFromLeftComponent,
        AnimEnterFromRightComponent,
        AnimOnScrollFadeInComponent,
        AnimOnScrollEnterFromLeftComponent,
        AnimOnScrollEnterFromRightComponent,
        AnimOnScrollEnterFromTopComponent,
        AnimOnScrollEnterFromBottomComponent
    ]
})
export class AnimationsModule {}
