import { Validators } from "@angular/forms"
import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Plataforma } from "./plataformas.model"
import { ReporteSubRazon } from "./reporte_sub_razones.model"

export class ReporteRazon extends BaseModel {
    public static className: string = "ReporteRazon"
    public id: number
    public razon: string
    public tabla: string
    public plataforma_id: number
    public created_at: Date
    public updated_at: Date

    @SaveType(() => Plataforma) public plataforma: Plataforma
    @SaveType(() => ReporteSubRazon) reporte_sub_razones: ReporteSubRazon[]

    constructor() {
        super()
        this.plataforma = new Plataforma()
        this.reporte_sub_razones = []
    }

    public static get model_params(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            razon: {
                label: "Razón",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            tabla: {
                label: "Tabla",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }

    public static get show_params(): any {
        return {
            id: { label: "Id", type: "text", tableVisible: true },
            razon: { label: "Razón", type: "text", tableVisible: true },
            tabla: { label: "Tabla", type: "text", tableVisible: true },
            created_at: { label: "Fecha de creación", type: "date", tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: "date", tableVisible: true }
        }
    }

    public toString() {
        return this.razon + " / " + this.tabla
    }
}
