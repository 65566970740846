import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PlanPersonalSesionGrupoUsuario } from "./plan_personal_sesion_grupo_usuarios.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class PlanPersonalSesionGrupoUsuarios extends PuntajeBaseService<PlanPersonalSesionGrupoUsuario> {
    tableName = "plan_personal_sesion_grupo_usuarios"
    singularTableName = "plan_personal_sesion_grupo_usuario"
    modelClass = PlanPersonalSesionGrupoUsuario

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    toggleActual(planPersonalSesionGrupoUsuarioId: number) {
        this.enableIgnoreModel()

        return this.one(planPersonalSesionGrupoUsuarioId)
            .concatExtra("toggle_actual")
            .post()
            .then(res => {
                this.disableIgnoreModel()

                return res
            })
    }

    wherePost(params: any = null, timeout: number = 30000) {
        return this.all().concatExtra("post").post(params, timeout)
    }
}
