import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { Libro } from "./libros.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { LibroCodigoRegistros } from "./libro-codigo-registros.service"
import { ErroresService, AppEnv } from "@puntaje/shared/core"

@Injectable()
export class Libros extends PuntajeBaseService<Libro> {
    tableName = "libros"
    singularTableName = "libro"
    modelClass = Libro

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected libroCodigoRegristrosService: LibroCodigoRegistros,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    async comprado(id: number, usuarioId: number) {
        const lcr = await this.libroCodigoRegristrosService.where({
            libro_codigo_registro: { libro_id: id, usuario_id: usuarioId }
        })
        return lcr.length > 0
    }
}
