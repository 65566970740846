import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PlanPersonalContenido } from "./plan_personal_contenidos.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class PlanPersonalContenidos extends PuntajeBaseService<PlanPersonalContenido> {
    tableName = "plan_personal_contenidos"
    singularTableName = "plan_personal_contenido"
    modelClass = PlanPersonalContenido

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    wherePost(params: any = null, timeout: number = 30000) {
        return this.all().concatExtra("post").post(params, timeout)
    }
}
