import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { CarrerasBaseService } from "./carreras_base.service"
import { CarrerasAuthService } from "./carreras_auth.service"
import { UniversidadRecomendada } from "./universidad_recomendadas.model"

@Injectable()
export class UniversidadRecomendadas extends CarrerasBaseService<UniversidadRecomendada> {
    tableName = "universidad_recomendadas"
    singularTableName = "universidad_recomendada"
    modelClass = UniversidadRecomendada

    constructor(
        protected http: HttpClient,
        protected auth: CarrerasAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
