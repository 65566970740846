import { Pipe, PipeTransform } from "@angular/core"

@Pipe({ name: "easyplaceholder" })
export class EasyPlaceholderPipe implements PipeTransform {
    transform(obj: any, placeholder: string | number) {
        if (obj == null || obj == undefined) {
            return placeholder
        }
        return obj
    }
}
