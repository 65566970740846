import { NgModule, ModuleWithProviders, Directive } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { UtilModule, ModelViewModule, SorterModule } from "@puntaje/shared/core"

// Utils
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { TooltipModule } from "ngx-bootstrap/tooltip"

// Commons
import { MainLayoutComponent } from "./main_layout/main_layout.component"
import { UnloggedLayoutComponent, UnloggedLayoutComponentTags } from "./unlogged_layout/unlogged_layout.component"
import { LoaderComponent } from "./loader/loader.component"
import { LoadingLayoutComponent } from "./loading_layout/loading_layout.component"
import { SimpleModalComponent } from "./simple_modal/simple_modal.component"
import { TableWithActionsComponent } from "./table_with_actions/table_with_actions.component"
import { TableWithActionsButtonsComponent } from "./table_with_actions/table_with_actions_buttons.component"
import { TableWithDependingActionsComponent } from "./table_with_actions/table_with_depending_actions.component"
import { TableWithDependingActionsButtonsComponent } from "./table_with_actions/table_with_depending_actions_buttons.component"
import { SimpleModalService } from "./simple_modal/simple_modal.service"
import { CarouselComponent } from "./carousel/carousel.component"
import { PlaceholderComponent } from "./placeholder/placeholder.component"
import { MenuItemBoxComponent } from "./menu_item_box/menu_item_box.component"
import { MenuGridLayoutComponent } from "./menu_item_box/menu_grid_layout.component"

import { CornerRibbonComponent } from "./decorations/corner-ribbon/corner-ribbon.component"
import { PopupCookiesComponent } from "./popup_cookies/popup_cookies.component"
import { SiteWarningComponent } from "./site-warning/site-warning.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilModule,
        ModelViewModule,
        Angular2FontawesomeModule,
        TooltipModule,
        RouterModule,
        SorterModule
    ],
    declarations: [
        MainLayoutComponent,
        LoadingLayoutComponent,
        LoaderComponent,
        SimpleModalComponent,
        TableWithActionsComponent,
        TableWithActionsButtonsComponent,
        TableWithDependingActionsComponent,
        TableWithDependingActionsButtonsComponent,
        UnloggedLayoutComponent,
        UnloggedLayoutComponentTags,
        CarouselComponent,
        PlaceholderComponent,
        MenuItemBoxComponent,
        MenuGridLayoutComponent,
        CornerRibbonComponent,
        PopupCookiesComponent,
        SiteWarningComponent
    ],
    exports: [
        MainLayoutComponent,
        LoadingLayoutComponent,
        LoaderComponent,
        SimpleModalComponent,
        TableWithActionsComponent,
        TableWithActionsButtonsComponent,
        TableWithDependingActionsComponent,
        TableWithDependingActionsButtonsComponent,
        UnloggedLayoutComponent,
        UnloggedLayoutComponentTags,
        CarouselComponent,
        PlaceholderComponent,
        MenuItemBoxComponent,
        MenuGridLayoutComponent,
        CornerRibbonComponent,
        PopupCookiesComponent,
        SiteWarningComponent
    ]
})
export class CommonsLayoutsModule {
    static forRoot(): ModuleWithProviders<CommonsLayoutsModule> {
        return {
            ngModule: CommonsLayoutsModule,
            providers: [SimpleModalService]
        }
    }
}
