<div class="kpi">
    <loading-layout #loadingLayout>
        <table *ngIf="ready" class="tabla-kpi table">
            <thead>
                <tr>
                    <th></th>
                    <th *ngIf="showColumns['n_ensayos']">{{ "progreso.estadisticas.tabla_resumen.n_ensayos" | t }}</th>
                    <th *ngIf="showColumns['ultimo_ptje']">
                        {{ "progreso.estadisticas.tabla_resumen.ultimo_ptje" | t }}
                    </th>
                    <th *ngIf="showColumns['correctas']">{{ "progreso.estadisticas.tabla_resumen.correctas" | t }}</th>
                    <th *ngIf="showColumns['incorrectas']">
                        {{ "progreso.estadisticas.tabla_resumen.incorrectas" | t }}
                    </th>
                    <th *ngIf="showColumns['omitidas']">{{ "progreso.estadisticas.tabla_resumen.omitidas" | t }}</th>
                    <th *ngIf="showColumns['maxima']">{{ "progreso.estadisticas.tabla_resumen.maxima" | t }}</th>
                    <th *ngIf="showColumns['promedio']">{{ "progreso.estadisticas.tabla_resumen.promedio" | t }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let i of listaAsignaturas | keys">
                    <ng-container *ngIf="kpiAsignatura[i]">
                        <th class="{{ listaAsignaturas[i].confByEvaluacionTipo[this.evaluacionTipo]?.clase }}">
                            <span class="asignatura">
                                <ogr-icon
                                    name="{{ listaAsignaturas[i].confByEvaluacionTipo[this.evaluacionTipo]?.icono }}"
                                    [align]="'baseline'"
                                    class="icono"
                                ></ogr-icon>
                                {{ listaAsignaturas[i].confByEvaluacionTipo[this.evaluacionTipo]?.abreviacion }}
                            </span>
                        </th>
                        <td *ngIf="showColumns['n_ensayos']" class="text-center">
                            {{ kpiAsignatura[i][0] | roundfloat }}
                        </td>
                        <td *ngIf="showColumns['ultimo_ptje']" class="text-center">
                            {{ kpiAsignatura[i][2] | roundfloat }}
                        </td>
                        <td *ngIf="showColumns['correctas']" class="text-center">
                            {{ kpiAsignatura[i][3] | roundfloat }}
                        </td>
                        <td *ngIf="showColumns['incorrectas']" class="text-center">
                            {{ kpiAsignatura[i][4] | roundfloat }}
                        </td>
                        <td *ngIf="showColumns['omitidas']" class="text-center">
                            {{ kpiAsignatura[i][5] | roundfloat }}
                        </td>
                        <td *ngIf="showColumns['maxima']" class="text-center">
                            {{ kpiAsignatura[i][6] | roundfloat }} {{ percentageSign ? "%" : "" }}
                        </td>
                        <td *ngIf="showColumns['promedio']" class="text-center">
                            <ng-container *ngIf="kpiAsignatura[i][1] != '-'">
                                {{ kpiAsignatura[i][1] | roundfloat }} {{ percentageSign ? "%" : "" }}
                            </ng-container>
                            <ng-container *ngIf="kpiAsignatura[i][1] == '-'">
                                {{ kpiAsignatura[i][1] }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </loading-layout>
</div>
