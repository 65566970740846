import { Component, Input, EventEmitter, OnInit } from "@angular/core"
import { EvaluacionInstancia, EvaluacionInstancias } from "@puntaje/puntaje/api-services"
import { Validations } from "@puntaje/shared/core"
import { FormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from "@angular/forms"

@Component({
    selector: "compartir-resultados-evaluacion",
    templateUrl: "compartir_resultados_evaluacion.component.html",
    styleUrls: ["compartir_resultados_evaluacion.component.scss"]
})
export class CompartirResultadosEvaluacionComponent implements OnInit {
    @Input() openModal: EventEmitter<any>
    @Input() evaluacionInstancia: EvaluacionInstancia
    @Input() tipoEvaluacion: string
    modalId: string
    form: UntypedFormGroup
    compartirDone: boolean = false
    errors: any[] = []

    constructor(private evaluacionInstanciaService: EvaluacionInstancias, private formBuilder: UntypedFormBuilder) {}

    ngOnInit() {
        this.modalId = "modal-compartir-ei-" + this.evaluacionInstancia.id
        this.form = this.formBuilder.group({
            email: ["", [Validators.required, Validators.email]]
        })
    }

    cancelar() {
        this.form.reset()
    }

    compartir() {
        this.compartirDone = false
        this.errors = []
        if (this.form.valid) {
            this.evaluacionInstanciaService.enableIgnoreModel()
            this.evaluacionInstanciaService
                .compartirResultados(this.evaluacionInstancia.id, { email: this.form.controls.email.value })
                .then((res: any) => {
                    this.evaluacionInstanciaService.disableIgnoreModel()
                    this.errors = res.errors
                    this.compartirDone = true
                })
        }
    }
}
