import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core"
import { Comentarios, ComentarioForm, Comentario } from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "comentario-edit",
    templateUrl: "comentario_form.component.html",
    styleUrls: ["comentario_form.component.scss"]
})
export class ComentarioEditComponent {
    @Input() comentario: Comentario
    @Output() onSaveDone: EventEmitter<any> = new EventEmitter<any>()
    @Output() onCancel: EventEmitter<any> = new EventEmitter<any>()
    saveBtnText = "Editar"
    labelText = "Editar comentario"
    form: UntypedFormGroup
    params: any = ComentarioForm.formParams
    oComentario: Comentario

    constructor(private comentariosService: Comentarios, private authService: AuthService) {}

    ngOnInit() {
        this.init()
    }

    init() {
        if (this.comentario) {
            this.oComentario = this.comentario.clone()
            this.form = ComentarioForm.getForm(this.comentario)
        }
    }

    save() {
        ComentarioForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.comentariosService.update(this.comentario.id, this.comentario).then((comentario: Comentario) => {
                this.onSaveDone.emit(comentario)
            })
        }
    }

    clear() {
        this.comentario = this.oComentario.clone()
    }

    cancelar() {
        this.clear()
        this.onCancel.emit(true)
    }
}
