<notificacion-layout
    [notificacion]="notificacion"
    [enableMini]="enableMini"
    [icono]="'dialogos'"
    [tipoNotificacion]="'plan_personal_sesion_recordatorio'"
    [disableMarkButton]="disableMarkButton"
>
    <notificacion-contenido>
        <ng-container *ngIf="planPersonal">
            <div>
                <p class="texto-notificacion">
                    {{ "notificaciones.profesor" | t }} {{ usuario_remitente }} {{ "notificaciones.del" | t }} {{ establecimiento?.establecimiento }} {{ "notificaciones.te_ha_enviado_un" | t }} {{ planPersonalSesion.plan_personal_sesion }} {{ "notificaciones.del_plan_de" | t }}
                    <a routerLink="/plan_estudios/{{ planPersonal.id }}" target="_blank" (click)="onClickLink()">
                        {{ planPersonal.plan_personal }}
                    </a>
                    {{ "notificaciones.de_la_asginatura" | t }} {{ asignatura.asignatura }}
                </p>
            </div>
        </ng-container>
    </notificacion-contenido>
</notificacion-layout>
