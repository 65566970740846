import { Component, ElementRef, Output, EventEmitter, Input, HostBinding } from "@angular/core"

@Component({
    selector: "[cui-tab],cui-tab",
    template: `
        <ng-container *ngIf="usingDisplayNone || active">
            <ng-content></ng-content>
        </ng-container>
    `
})
export class CUITabComponent {
    @Input() title: string
    @Input() usingDisplayNone: boolean
    @HostBinding("attr.aria-labelledby") @Input() ariaLabelledBy: string
    @HostBinding("attr.aria-selected") @HostBinding("class.active") @Input() active = false
    @Output() select: EventEmitter<any> = new EventEmitter<any>()

    constructor(protected el: ElementRef) {}
}
