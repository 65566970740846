<div role="group" aria-label="Cantidad de preguntas y tiempo de la evaluación">
    <div class="r-options" *ngFor="let v of valoresOpciones; let i = index">
        <ng-container *ngIf="v.preguntas >= 5">
            <label [class.selected]="currentOption == i">
                <input
                    type="radio"
                    [disabled]="disabled"
                    [value]="i"
                    [(ngModel)]="currentOption"
                    name="currentOption"
                    (ngModelChange)="optionsChange()"
                />
                <span class="checkmark"></span>
                <div class="option-content">
                    <div class="option-icon"><fa [name]="'pencil-square-o'"></fa></div>
                    <div class="option-text">
                        <span>
                            <b>{{ v.preguntas }}</b>
                        </span>
                        <span>{{ "numero_preguntas_tiempo.preguntas" | t }}</span>
                    </div>
                    <div class="separator"></div>
                    <div class="option-icon"><fa [name]="'clock-o'"></fa></div>
                    <div class="option-text">
                        <span>
                            <b>{{ v.tiempo }}</b>
                        </span>
                        <span>{{ "numero_preguntas_tiempo.minutos" | t }}</span>
                    </div>
                </div>
            </label>
        </ng-container>
    </div>
    <div class="r-options">
        <label [class.selected]="currentOption == valoresOpciones.length">
            <input
                aria-label="Numeros Personalizados"
                type="radio"
                [disabled]="disabled"
                [value]="valoresOpciones.length"
                [(ngModel)]="currentOption"
                name="currentOption"
                (change)="optionsChange()"
                aria-describedby="advertenciaNumerosPersonalizados"
            />
            <span class="checkmark"></span>
            <div class="option-content" (click)="selectLastOption()">
                <div class="option-icon"><fa [name]="'pencil-square-o'"></fa></div>
                <div class="option-text">
                    <input
                        aria-label="Cantidad de preguntas"
                        class="form-control questions-input"
                        [disabled]="disabled"
                        [placeholder]="'numero_preguntas_tiempo.ej_num_preguntas' | t"
                        type="text"
                        name="ensayo_configurar[personalizar_npreguntas]"
                        [(ngModel)]="numeroPreguntasInput"
                        (ngModelChange)="nPreguntasInputChange()"
                        (keyup)="validateInputPreguntas()"
                        [attr.aria-invalid]="showErrorMessage || showNumeroPreguntasError()"
                        [attr.aria-describedby]="
                            showErrorMessage || showNumeroPreguntasError()
                                ? 'MensajeError error-limite-numero-preguntas'
                                : ''
                        "
                        [tabindex]="tabIndex"
                    />
                    <span class="input-span">{{ "numero_preguntas_tiempo.preguntas" | t }}</span>
                </div>
                <div class="separator"></div>
                <div class="option-icon"><fa [name]="'clock-o'"></fa></div>
                <div class="option-text">
                    <input
                        aria-label="Número de minutos"
                        class="form-control form-admin time-input"
                        [disabled]="disabled"
                        [placeholder]="'numero_preguntas_tiempo.ej_num_minutos' | t"
                        type="text"
                        [(ngModel)]="tiempoInput"
                        (ngModelChange)="tiempoInputChange()"
                        (keyup)="validateInputTiempo()"
                        [attr.aria-invalid]="show_error_tiempo"
                        name="tiempo"
                        [attr.aria-describedby]="show_error_tiempo ? 'MensajeError error-tiempo-minutos' : ''"
                        [tabindex]="tabIndex"
                    />
                    <span class="input-span">{{ "numero_preguntas_tiempo.minutos" | t }}</span>
                </div>
            </div>
        </label>
    </div>
    <div
        id="MensajeError"
        [attr.aria-hidden]="!showErrorMessage"
        class="error-warning"
        [class.show-error]="showErrorMessage"
    >
        <p>
            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
            {{ "numero_preguntas_tiempo.mensaje_error" | t }}
        </p>
    </div>
    <div
        id="error-limite-numero-preguntas"
        *ngIf="generadorInstrumento"
        [attr.aria-hidden]="!showNumeroPreguntasError()"
        class="error-warning"
        [class.show-error]="showNumeroPreguntasError()"
    >
        <p>
            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
            {{ "numero_preguntas_tiempo.error_limite_numero_preguntas" | t }}
            {{ generadorInstrumento.numero_preguntas * 2 }}
        </p>
    </div>
    <div
        id="advertenciaNumerosPersonalizados"
        class="warning-about-evaluation"
        [attr.aria-hidden]="!show_warning"
        [class.show-warning]="show_warning"
        [class.hide-warning]="!show_warning"
    >
        <div class="warning-content">
            <div class="warning-icon"><fa [name]="'info-circle'"></fa></div>
            <div class="warning-message">
                <p>{{ "numero_preguntas_tiempo.advertencia_numeros_personalizados_uno" | t }}:</p>
                <ul>
                    <li>{{ "numero_preguntas_tiempo.advertencia_numeros_personalizados_dos" | t }}</li>
                    <li>{{ "numero_preguntas_tiempo.advertencia_numeros_personalizados_tres" | t }}</li>
                    <li>{{ "numero_preguntas_tiempo.advertencia_numeros_personalizados_cuatro" | t }}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
