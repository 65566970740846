import { Component } from "@angular/core"
import { View } from "./view.component"

@Component({
    selector: "dateview",
    template: `
        <ng-template #viewLabel>{{ label }}:</ng-template>
        <ng-template #viewValue>{{ value[key] | prettydate }}</ng-template>
    `,
    styleUrls: ["model_simple_view.component.scss"]
})
export class DateView extends View {}
