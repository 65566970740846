<span>Puedes subir un archivo PDF, Word (docx) o imágen.</span>
<file [ngModel]="urlFile" (ngModelChange)="onChangeFile($event)" [key]="'pdf_' + contestableId"></file>
<div class="alert alert-danger" *ngIf="errorMsg">
    {{ errorMsg }}
</div>
<loading-layout *ngIf="loading"></loading-layout>
<ng-container *ngIf="!loading">
    <object *ngIf="fileType == 'pdf' && urlFile" class="pdf-object" [data]="urlFilePath | trustedurl"></object>
    <model-plain-value
        *ngIf="fileType == 'word' && alternativa"
        [params]="params"
        [value]="alternativa"
        [key]="'url'"
    ></model-plain-value>
    <img *ngIf="fileType == 'img' && urlFile" [src]="urlFilePath | trustedurl" />
</ng-container>
