<loading-layout #loadingLayout>
    <div *ngIf="post">
        <span class="text-left tags">{{ post.tag }}</span>
        <h1 class="card-title mb-0">{{ post.titulo }}</h1>
        <div class="card card-resumen" [innerHTML]="post?.resumen"></div>
        <br />
        <div *ngIf="post.usuario" class="autor">
            <div class="user-circle-container">
                <img *ngIf="usuario_avatar" class="avatar" aria-label="Avatar Usuario" [src]="usuario_avatar_safe" />
                <fa
                    *ngIf="!usuario_avatar"
                    class="fa-placeholder"
                    aria-label="Avatar Usuario por Defecto"
                    [name]="'user'"
                ></fa>
            </div>
            <div class="detalles-autor">
                <div>
                    <p class="nombre-autor">
                        Por:
                        <b>{{ post.usuario.nombre }} {{ post.usuario.apellido_paterno }}</b>
                    </p>
                </div>
                <div>
                    <footer class="blockquote-footer">
                        <p class="text-muted">
                            {{ post.fecha_inicial | date: "MMM" }} {{ post.fecha_inicial | date: "d" }}
                            <span class="pl pr">.</span>
                            <cite class="text-muted tiempo-lectura">{{ post.tiempo_lectura }} min</cite>
                        </p>
                    </footer>
                </div>
            </div>
        </div>
        <figure class="figure">
            <div class="imagen-lg">
                <img
                    *ngIf="post.imagen"
                    [src]="post.imagen"
                    class="figure-img img-fluid rounded img-post"
                    alt="{{ post.titulo }}"
                />
                <img
                    *ngIf="!post.imagen"
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/blog/default.png"
                    alt="{{ post.titulo }}"
                    class="figure-img img-fluid rounded img-post"
                />
            </div>
            <div class="imagen-sm">
                <img
                    *ngIf="post.imagen_sm"
                    [src]="post.imagen_sm"
                    class="figure-img img-fluid rounded img-post"
                    alt="{{ post.titulo }}"
                />
                <img
                    *ngIf="!post.imagen_sm"
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/blog/default.png"
                    alt="{{ post.titulo }}"
                    class="figure-img img-fluid rounded img-post"
                />
            </div>
        </figure>
        <div class="card card-contenido" [innerHTML]="post?.contenido ? (post?.contenido | trustedhtml) : ''"></div>
        <br />
        <div class="card card-video" *ngIf="post && post?.video">
            <iframe
                id="video-iframe"
                width="420"
                height="315"
                [src]="post?.video | trustedurl"
                frameborder="0"
                allowfullscreen="allowfullscreen"
            ></iframe>
        </div>
    </div>
    <hr />
    <p class="text-left entradas">Entradas anteriores</p>
    <div class="row" *ngIf="ultimosPosts">
        <post-thumbnail
            *ngFor="let post of ultimosPosts"
            [post]="post"
            [external]="external"
            class="col-sm-12 col-md-4"
        ></post-thumbnail>
    </div>
</loading-layout>
