import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { CommonModule } from "@angular/common"
import { UtilModule, FormModule, ModelViewModule, PaginatorModule } from "@puntaje/shared/core"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { PopoverModule } from "ngx-bootstrap/popover"

import { CommonsLayoutsModule } from "@puntaje/shared/layouts"

import { BannerComponent } from "./banner.component"
import { BannersComponent } from "./banners.component"
import { BannersNewComponent } from "./banners.new.component"
import { BannersEditComponent } from "./banners.edit.component"
import { BannersDeleteComponent } from "./banners.delete.component"
import { BannersToolNavbarComponent } from "./banners.toolnavbar.component"

import { BannerZonaComponent } from "./banner_zonas/banner_zona.component"
import { BannerZonasComponent } from "./banner_zonas/banner_zonas.component"
import { BannerZonasNewComponent } from "./banner_zonas/banner_zonas.new.component"
import { BannerZonasEditComponent } from "./banner_zonas/banner_zonas.edit.component"
import { BannerZonasDeleteComponent } from "./banner_zonas/banner_zonas.delete.component"

import { BannerPaginaComponent } from "./banner_paginas/banner_pagina.component"
import { BannerPaginasComponent } from "./banner_paginas/banner_paginas.component"
import { BannerPaginasNewComponent } from "./banner_paginas/banner_paginas.new.component"
import { BannerPaginasEditComponent } from "./banner_paginas/banner_paginas.edit.component"
import { BannerPaginasDeleteComponent } from "./banner_paginas/banner_paginas.delete.component"

import { BannerInstanciasComponent } from "./banner_instancias.component"
import { BannerShowComponent } from "./banner_show.component"

import { BannersShowcaseBoxComponent } from "./banners_showcasebox.component"
import { BannersShowcaseBoxBarraComponent } from "./banners_showcasebox_barra.component"
import { BannersBarraInternaComponent } from "./banners_barra_interna.component"
import { BannerModalComponent } from "./banner_modal.component"
import { BannersActivosComponent } from "./banners-activos.component"
import { BannersActivosPaginaComponent } from "./banners-activos-pagina.component"
import { CUIInputsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        ModelViewModule,
        PaginatorModule,
        Angular2FontawesomeModule,
        PopoverModule,
        CUIInputsModule
    ],
    declarations: [
        BannerComponent,
        BannersComponent,
        BannersNewComponent,
        BannersEditComponent,
        BannersDeleteComponent,
        BannersToolNavbarComponent,
        BannerZonaComponent,
        BannerZonasComponent,
        BannerZonasNewComponent,
        BannerZonasEditComponent,
        BannerZonasDeleteComponent,
        BannerPaginaComponent,
        BannerPaginasComponent,
        BannerPaginasNewComponent,
        BannerPaginasEditComponent,
        BannerPaginasDeleteComponent,
        BannerInstanciasComponent,
        BannerShowComponent,
        BannersShowcaseBoxComponent,
        BannersShowcaseBoxBarraComponent,
        BannersBarraInternaComponent,
        BannerModalComponent,
        BannersActivosComponent,
        BannersActivosPaginaComponent
    ],
    exports: [
        BannerComponent,
        BannersComponent,
        BannersNewComponent,
        BannersEditComponent,
        BannersDeleteComponent,
        BannersToolNavbarComponent,
        BannerZonaComponent,
        BannerZonasComponent,
        BannerZonasNewComponent,
        BannerZonasEditComponent,
        BannerZonasDeleteComponent,
        BannerPaginaComponent,
        BannerPaginasComponent,
        BannerPaginasNewComponent,
        BannerPaginasEditComponent,
        BannerPaginasDeleteComponent,
        BannerInstanciasComponent,
        BannerShowComponent,
        BannersShowcaseBoxComponent,
        BannersShowcaseBoxBarraComponent,
        BannersBarraInternaComponent,
        BannerModalComponent,
        BannersActivosComponent,
        BannersActivosPaginaComponent
    ]
})
export class BannersModule {}
