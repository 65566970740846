import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Asociacion } from "./asociaciones.model"
import { Establecimiento } from "./establecimientos.model"

export class AsociacionEstablecimiento extends BaseModel {
    public id: number
    public asociacion_id: number
    public establecimiento_id: number
    @SaveType(() => Asociacion) public asociacion: Asociacion
    @SaveType(() => Establecimiento) public establecimiento: Establecimiento

    public toString() {
        return this.id
    }
}
