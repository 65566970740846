import { Component, OnInit, Input } from "@angular/core"
import { Alternativa } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "respuesta-imagen",
    templateUrl: "./respuesta-imagen.component.html",
    styleUrls: ["./respuesta-imagen.component.scss"]
})
export class RespuestaImagenComponent implements OnInit {
    @Input() respuestaId: number
    @Input() alternativas: Alternativa[]

    alternativa: Alternativa

    constructor() {}

    ngOnInit() {
        this.alternativa = this.alternativas.find(a => a.id == this.respuestaId)
    }
}
