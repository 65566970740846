<select
    [hidden]="hidden"
    class="form-control"
    [(ngModel)]="generadorInstrumento"
    (ngModelChange)="generadorInstrumentoChanges()"
>
    <option [ngValue]="undefined" [selected]="true">{{ "generar_instrumento_select.titulo_option" | t }}</option>
    <option *ngFor="let generadorInstrumento of generadorInstrumentos" [ngValue]="generadorInstrumento">
        {{ generadorInstrumento.generador_instrumento }}
    </option>
</select>
