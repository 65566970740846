<loading-layout #loadingLayout>
    <table class="table tabla-evaluaciones" *ngIf="evaluacionesMultiples.length > 0">
        <thead>
            <tr>
                <th>{{ "evaluacion.compartidas.evaluaciones" | t }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let evaluacionMultiple of evaluacionesMultiples; let i = index">
                <td>
                    <table class="table tabla-interna">
                        <thead>
                            <tr>
                                <th colspan="4">
                                    <h3 [attr.aria-label]="evaluacionMultiple || 'Título vacío'">
                                        <ogr-icon name="prueba" class="icono"></ogr-icon>
                                        {{ evaluacionMultiple }}
                                    </h3>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let evaluacion of evaluacionMultiple.evaluaciones; let i = index"
                                [class.odd]="i % 2 == 1"
                            >
                                <td>
                                    <p>{{ evaluacion.evaluacion | easyplaceholder: "Evaluación sin nombre" }}</p>
                                    <p class="subtitulo">Forma: #{{ evaluacion.id }}</p>
                                </td>
                                <td>
                                    <p>{{ evaluacion.fecha_inicio | date: "dd/MM/yyyy HH:mm" }}</p>
                                    <p class="subtitulo">
                                        <fa name="clock-o" class="clock"></fa>
                                        {{ "evaluacion.compartidas.inicio" | t }}
                                    </p>
                                </td>
                                <td>
                                    <p>{{ evaluacion.fecha_termino | date: "dd/MM/yyyy HH:mm" }}</p>
                                    <p class="subtitulo">
                                        <fa name="clock-o" class="clock"></fa>
                                        {{ "evaluacion.compartidas.fin" | t }}
                                    </p>
                                </td>
                                <td>
                                    <p>{{ evaluacion.fecha_mostrar_respuestas | date: "dd/MM/yyyy HH:mm" }}</p>
                                    <p class="subtitulo">
                                        <fa name="clock-o" class="clock"></fa>
                                        {{ "evaluacion.compartidas.publicacion" | t }}
                                    </p>
                                </td>
                                <td>
                                    <cui-button-link
                                        class="btn_style btn-sm tabla-btn"
                                        [routerLinkValue]="['/evaluaciones', evaluacion.id, 'realizar']"
                                    >
                                    {{ "evaluacion.compartidas.realizar" | t }}
                                    </cui-button-link>
                                </td>
                                <td
                                    *ngIf="
                                        evaluacion.evaluacion_instancias && evaluacion.evaluacion_instancias.length > 0
                                    "
                                >
                                    <cui-button-link
                                        class="btn_style btn-sm tabla-btn"
                                        [routerLinkValue]="[
                                            '/ensayo_instancias',
                                            evaluacion.evaluacion_instancias[0].id
                                        ]"
                                    >
                                    {{ "evaluacion.compartidas.ver" | t }}
                                    </cui-button-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
    <p class="not-found" *ngIf="evaluacionesMultiples && evaluacionesMultiples.length == 0">
        {{ "evaluacion.compartidas.not_found" | t }}
    </p>
    <paginator [getList]="setData"></paginator>
</loading-layout>
