import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule, registerLocaleData } from "@angular/common"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { ModalModule } from "ngx-bootstrap/modal"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { RouterModule } from "@angular/router"
import { DemoComponent } from "./demo.component"
import { CUIInputsModule } from "../inputs/inputs.module"
import { CUIContainersModule } from "../containers/containers.module"
import { CUITextsModule } from "../texts/texts.module"
import { CUILayoutsModule } from "../layouts/layouts.module"
import { CUITablesModule } from "../tables/tables.module"
import { UtilModule } from "@puntaje/shared/core"

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        Angular2FontawesomeModule,
        ModalModule,
        ProgressbarModule,
        PopoverModule,
        TooltipModule,
        CUIInputsModule,
        CUIContainersModule,
        CUITextsModule,
        CUILayoutsModule,
        CUITablesModule,
        UtilModule
    ],
    declarations: [DemoComponent],
    exports: [DemoComponent],
    providers: []
})
export class CUIDemoModule {
    static forRoot(): ModuleWithProviders<CUIDemoModule> {
        return {
            ngModule: CUIDemoModule,
            providers: []
        }
    }
}
