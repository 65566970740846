import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"
import { Pregunta } from "./preguntas.model"
import { Alternativas } from "./alternativas.service"

type RespuestaLike = {
    pregunta_id: number
    contestable_id: number
    alternativa_id: number
}

export type VariacionPrompt = {
    id: number
    propmt: string
    generador_instrumento_id: number
    descripcion: string
    generador_instrumento: string
}
@Injectable()
export class Preguntas extends NebuBaseService<Pregunta> {
    tableName = "preguntas"
    singularTableName = "pregunta"
    modelClass = Pregunta

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv,
        protected alternativasService: Alternativas
    ) {
        super(http, auth, router, errores, environment)
    }

    public exists(params: any) {
        return this.all().concatExtra("exists").get(params)
    }

    public loteSearch(params: any = null) {
        if (this.canConcat) {
            var otherParams = ""
            if (params) {
                otherParams = "?"
                for (var key in params) {
                    if (otherParams.length == 1) otherParams += key + "=" + params[key]
                    else otherParams += "&" + key + "=" + params[key]
                }
            }
            this.restQuery += "/" + this.tableName + "/busqueda_lotes" + otherParams
            this.canConcat = false
            this.httpVerb = HttpVerbs.GET
        } else {
            throw new SyntaxError(
                "Imposible concatenar una llamada GET (find) a una llamada " + HttpVerbs[this.httpVerb]
            )
        }
        return this
    }

    public like(preguntaId: number, params: any) {
        return this.one(preguntaId).concatExtra("like").post(params)
    }

    public dislike(preguntaId: number, params: any) {
        return this.one(preguntaId).concatExtra("dislike").post(params)
    }

    public sentiment(preguntaId: number, params: any) {
        return this.one(preguntaId).concatExtra("sentiment").get(params)
    }

    public previewLatex(params: any) {
        return this.all().concatExtra("preview_latex").post(params)
    }

    public previewLatexBlob(params: any) {
        this.enableIgnoreModel()
        this.enableIgnoreCatch()

        return this.all()
            .concatExtra("preview_latex")
            .post(params)
            .then(response => {
                this.disableIgnoreModel()

                const object = response as any

                if (object.info.errors || !object.success) {
                    return Promise.resolve({ error: true }) as any
                }

                return this.downloadPDF(object.info.link).then((responseLink: any) => {
                    return { blob: new Blob([responseLink], { type: "application/pdf" }) }
                })
            })
            .catch(_ => {
                return Promise.resolve({ error: true }) as any
            })
    }

    public estados() {
        return this.all().concatExtra("estados").get()
    }

    public getEstadisticas(id: number) {
        this.enableIgnoreModel()
        return this.one(id).concatExtra("get_estadisticas").get()
    }

    /**
     * Calcula las estadisticas acumuladas de todas las plataformas para el arreglo de preguntas
     * @param params Objeto con el arreglo de ids de las preguntas { pregunta_ids: [ids]}
     * @returns Estadisticas acumuladas de nebu para la preguntas
     */
    public estadisticasAccum(params: any) {
        this.enableIgnoreModel()
        return this.all()
            .concatExtra("get_estadisticas_accum")
            .post(params)
            .then(estadisticas => {
                this.disableIgnoreModel()

                return estadisticas
            })
    }

    public getChangeLog(id: number, page: number, per: number) {
        this.enableIgnoreModel()
        return this.one(id)
            .concatExtra("changelog?page=" + page + "&per=" + per)
            .get()
    }

    public editarMasivamente() {
        this.restQuery = ""

        return this.all().concatExtra("editar_masivamente")
    }

    /**
     * Función que además de ir a buscar las preguntas, va a buscar las alternativas de las preguntas que son libres asociadas a las respuestas
     * y que no se traen por defecto (porque todas son demasiadas)
     *
     * @param params Parámetros de búsqueda de las preguntas
     * @param respuestas Arreglo de objetos tipo Respuestas
     *
     * @returns Promise con el arreglo de preguntas y con sus alternativas ya seteadas
     */
    public async whereWithAlternativasLibres(params: any = null, respuestas: RespuestaLike[] = null) {
        const preguntas = await this.where(params)

        const preguntasById = preguntas.reduce((acc, pregunta) => {
            acc[pregunta.id] = pregunta

            return acc
        }, {})

        const respuestasOmitidasSpr = respuestas.filter(respuesta => {
            return !respuesta.alternativa_id &&
                    preguntasById[respuesta.pregunta_id].contestables.find(
                        c => c.id == respuesta.contestable_id &&
                        c.contestable_tipo &&
                        c.contestable_tipo.contestable_tipo == "Respuesta construida"
                    )
        })

        const respuestasAlternativasLibres = respuestas.filter(respuesta => {
            if (!respuesta.alternativa_id) {
                return false
            }

            const pregunta = preguntasById[respuesta.pregunta_id]

            if (!pregunta) {
                return false
            }

            return pregunta.contestables.find(
                c =>
                    c.id == respuesta.contestable_id &&
                    c.contestable_tipo &&
                    !["Pregunta de alternativas", "Lista Desplegable"].includes(c.contestable_tipo.contestable_tipo)
            )
        })

        const alternativaIds = respuestasAlternativasLibres.map(respuesta => respuesta.alternativa_id)

        const alternativasParams = {
            alternativa: {
                id: alternativaIds
            }
        }

        const alternativas = await this.alternativasService.where(alternativasParams)

        const alternativasSprOmitidas = await this.alternativasService.forSpr({
            alternativa: {
                contestable_id: respuestasOmitidasSpr.map(respuesta => respuesta.contestable_id),
                correcta: 1,
            }
        })

        const respuestasAlternativasLibresByAlternativaId = respuestasAlternativasLibres.reduce((acc, respuesta) => {
            acc[respuesta.alternativa_id] = respuesta

            return acc
        }, {})

        alternativasSprOmitidas.forEach(alternativa => {
            const respuestaAlternativa = respuestasOmitidasSpr.find(respuesta => respuesta.contestable_id == alternativa.contestable_id)

            const pregunta = preguntasById[respuestaAlternativa.pregunta_id]

            const contestable = pregunta.contestables.find(c => c.id == respuestaAlternativa.contestable_id)
            contestable.alternativas = contestable.alternativas || []
            contestable.alternativas.push(alternativa)
        })

        alternativas.forEach(alternativa => {
            const respuestaAlternativa = respuestasAlternativasLibresByAlternativaId[alternativa.id]
            const pregunta = preguntasById[respuestaAlternativa.pregunta_id]

            const contestable = pregunta.contestables.find(c => c.id == respuestaAlternativa.contestable_id)
            contestable.alternativas = contestable.alternativas || []
            contestable.alternativas.push(alternativa)
        })

        return preguntas
    }

    public generarVariaciones(
        preguntaId: number,
        promptId: number,
        cantidadVariaciones: number,
        grupoPreguntaId: number,
        notas: string
    ): Promise<Pregunta[]> {
        return this.one(preguntaId)
            .concatExtra("generar_variaciones")
            .post(
                {
                    cantidad_variaciones: cantidadVariaciones,
                    prompt_id: promptId,
                    grupo_pregunta_id: grupoPreguntaId,
                    notas
                } as any,
                60000000
            )
            .then((response: Pregunta[]) => {
                return response
            })
    }

    public preguntaVariacionPrompts(): Promise<VariacionPrompt[]> {
        return this.all()
            .concatExtra("pregunta_variacion_prompts")
            .get()
            .then((prompt, count) => {
                return prompt as unknown as VariacionPrompt[]
            })
    }
}

enum HttpVerbs {
    GET = 0,
    POST = 1,
    PATCH = 2,
    DELETE = 3,
    ALL = 4
}
