import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core"
import { S3Service, S3, DocsView } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Material, NebuAuthService } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "contestable-archivo",
    templateUrl: "./contestable-archivo.component.html",
    styleUrls: ["./contestable-archivo.component.scss"]
})
export class ContestableArchivoComponent implements OnChanges {
    @Input() alternativaTexto: any
    @Output() alternativaTextoChange = new EventEmitter()

    @Input() contestableId: number

    urlFile: string
    urlFilePath: string
    fileType: string
    fileTypeByExtension: any = {
        pdf: "pdf",
        docx: "word",
        png: "img",
        jpg: "img",
        jpeg: "img",
        bmp: "img"
    }
    alternativa: any
    params = { label: "Previsualizar", type: DocsView, tableVisible: true }

    loading: boolean = false

    errorMsg: string

    constructor(protected s3Service: S3Service, protected nebuAuthService: NebuAuthService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["alternativaTexto"] && this.alternativaTexto.alternativa_texto) {
            this.setConfigFile(this.alternativaTexto.alternativa_texto)
        }
    }

    onChangeFile(file) {
        this.errorMsg = undefined

        if (!file) {
            this.urlFile = undefined
            this.urlFilePath = undefined

            this.onChangeTextoRespuesta()

            return
        }

        if (file.size / 1024 / 1024 > 10) {
            this.errorMsg = "El archivo no puede superar los 10 MB."

            return
        }

        // if(file.name.match(/\.jpg|\.jpeg|\.png|\.bmp$/))
        if (!file.name.toLowerCase().match(/\.pdf|\.docx|\.jpg|\.jpeg|\.png|\.bmp$/)) {
            this.errorMsg = "El archivo solo puede ser un PDF/WORD o imágen."

            return
        }

        this.loading = true

        this.s3Service.enableNebuBaseUrl()
        this.s3Service.changeAuthService(this.nebuAuthService)

        const s3Params = {
            key: this.cleanFileNameForUpload(file.name),
            model_name: "alternativa"
        }

        this.s3Service.where(s3Params).then(policy => {
            const p = policy as any as S3

            this.s3Service.uploadToS3(p, file, "", () => {
                this.setConfigFile(p["key"])

                this.loading = false

                this.onChangeTextoRespuesta()
            })
        })
    }

    onChangeTextoRespuesta() {
        this.alternativaTextoChange.emit({
            alternativa_texto: this.urlFile
        })
    }

    setConfigFile(urlFile) {
        this.urlFile = urlFile
        this.urlFilePath = config.amazon.s3BucketUriNebu + this.urlFile
        const extensionFile = this.urlFile.split(".").pop()
        this.fileType = this.fileTypeByExtension[extensionFile]
        this.alternativa = { url: this.urlFilePath }
    }

    cleanFileNameForUpload(fileName: string) {
        return fileName.replace(/[#%&{}<>'"@:$=\|\`\+\/\!\?\*\\]/g, "")
    }
}
