import { Component, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "material.component.html"
})
export class MaterialComponent implements OnDestroy {
    id: number
    private sub: Subscription
    subData: Subscription

    enableDudas = true
    enableFavorito = true
    isViewOnYoutubeEnabled = true

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.materiales.material")
        this.sub = this.route.params.subscribe(params => {
            this.id = +params["id"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.enableDudas = data.enableDudas ?? this.enableDudas
            this.enableFavorito = data.enableFavorito ?? this.enableFavorito
            this.isViewOnYoutubeEnabled = data.isViewOnYoutubeEnabled ?? this.isViewOnYoutubeEnabled
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }
}
