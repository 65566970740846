import {
    Component,
    OnInit,
    ViewChild,
    Output,
    Input,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    Injector
} from "@angular/core"
import { Router } from "@angular/router"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { AuthService } from "@puntaje/shared/core"
import { Usuario, Tutor, TutorForm, Tutores } from "@puntaje/puntaje/api-services"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "tutores",
    templateUrl: "tutores.component.html",
    styleUrls: ["tutores.component.scss"]
})
export class TutoresComponent implements OnInit, OnChanges {
    @Input() usuario: Usuario
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent
    tutores: Tutor[]
    tutor: Tutor
    form: UntypedFormGroup
    adding: boolean = false
    limit: boolean = false
    params = TutorForm.formParams

    constructor(
        protected tutoresService: Tutores,
        protected authService: AuthService,
        protected router: Router,
        protected injector: Injector,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.loadTutores()
        if (!this.tutor) {
            this.loadForm()
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["usuario"].currentValue && !changes["usuario"].isFirstChange()) {
            this.loadTutores()
            if (!this.tutor) {
                this.loadForm()
            }
        }
    }

    loadTutores() {
        if (this.usuario) {
            this.loadingLayout.standby()
            this.tutoresService.where({ tutor: { usuario_id: this.usuario.id } }).then((tutores: Tutor[]) => {
                this.tutores = tutores
                if (this.tutores.length > 5) {
                    this.limit = true
                }

                this.loadingLayout.ready()
            })
        }
    }

    loadForm() {
        this.makeTutor()
        this.form = TutorForm.getForm(this.tutor, null, this.injector)
    }

    makeTutor() {
        this.tutor = new Tutor()
        this.tutor.usuario_id = this.authService.getUserData()["id"]
        this.tutor.confirmado = false
    }

    add() {
        this.adding = true
    }

    confirmar() {
        TutorForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.tutoresService.save(this.tutor).then(response => {
                this.tutores.push(response as Tutor)
                this.adding = false
                if (this.tutores.length > 5) {
                    this.limit = true
                }
            })
        } else {
            console.log(this.form.errors)
        }
    }

    quitar(tutor: Tutor) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea quitar a este tutor?")
        this.simpleModalService.setModalCallback(() => this.delete(tutor.id))
        this.simpleModalService.showSimpleModal()
    }

    delete(id: number) {
        this.tutoresService.remove(id).then(response => {
            this.tutores = this.tutores.filter(t => t.id != id)
            this.makeTutor()
        })
    }

    estado(tutor: Tutor) {
        if (tutor.confirmado) {
            return "Confirmado"
        } else {
            return "Pendiente"
        }
    }
}
