import {
    Component,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnInit,
    HostBinding,
    HostListener,
    OnDestroy
} from "@angular/core"
import {
    PlanPersonal,
    PlanPersonales,
    PlanPersonalInstancias,
    PlanPersonalInstancia
} from "@puntaje/puntaje/api-services"
import { Subscription, BehaviorSubject, combineLatest } from "rxjs"
import { trigger, state, style, animate, transition, keyframes } from "@angular/animations"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"

import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Router } from "@angular/router"
import {
    GeneradorInstrumentos,
    GeneradorInstrumento,
    Clasificacion,
    GeneradorInstrumentoCategoria
} from "@puntaje/nebulosa/api-services"

@Component({
    selector: "plan-clase-box",
    templateUrl: "./plan-clase-box.component.html",
    styleUrls: ["./plan-clase-box.component.scss"],
    animations: [
        trigger("dropIn", [
            state("in", style({ transform: "translateY(0)" })),
            transition("void => *", [
                animate(
                    "0.5s 0ms ease-in",
                    keyframes([
                        style({ opacity: 0, transform: "translateY(-20px)", offset: 0 }),
                        style({ opacity: 1, transform: "translateY(5px)", offset: 0.2 }),
                        style({ opacity: 1, transform: "translateY(0)", offset: 1.0 })
                    ])
                )
            ])
        ])
    ]
})
export class PlanClaseBoxComponent implements OnInit, OnDestroy {
    @Input() enableEditPlan: boolean = false
    @Input() verSinCompartir: boolean = false
    @Input() showAsignatura: boolean = true

    animatedValue: boolean = false
    progressBarValue: number = 0
    @Input() plan_clase_route = "/plan_clases"
    asignatura: any
    showSesiones: boolean = false
    isDone: boolean = false
    _planificacion: PlanPersonal
    planPersonalInstancia: PlanPersonalInstancia
    usuarioId: number
    nivel: Clasificacion
    config: typeof config = config
    nombreSesiones: String = config.plataforma.nombreSesiones || "Clases"
    aprendolibreCondition: boolean = config.plataforma.name == "Aprendolibre" ? true : false
    disableNivelCalugas: boolean

    sub: Subscription
    planificacionSubject = new BehaviorSubject<PlanPersonal>(null)
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    @Input()
    set planificacion(planificacion: any) {
        this._planificacion = planificacion
        this.planificacionSubject.next(planificacion)
    }

    get planificacion() {
        return this._planificacion
    }

    @Input() hideProgress: boolean = true
    @HostBinding("class.plan-estudio-box") isBox: boolean = true
    @HostBinding("@dropIn") buttonState: string = "in"

    constructor(
        private authService: AuthService,
        private planPersonalInstanciasService: PlanPersonalInstancias,
        private router: Router,
        private planificacionesService: PlanPersonales,
        protected generadorInstrumentoService: GeneradorInstrumentos,
        private store: Store<State>
    ) {
        this.disableNivelCalugas = config.plataforma?.disableNivelCalugas || false
    }

    ngOnInit() {
        this.usuarioId = this.authService.getUserData().id
        this.sub = combineLatest(
            this.planificacionSubject.pipe(filter(x => !!x)),
            this.asignaturasByEvaluacionTipo$
        ).subscribe(([planificacion, asignaturasByEvaluacionTipo]) => {
            let lista_asignaturas = asignaturasByEvaluacionTipo[config.plataforma.evaluacionDefault]
            this.asignatura = lista_asignaturas.find(
                l => l.asignatura == this.planificacion.generador_instrumento.asignatura.asignatura
            )

            let params = {
                plan_personal_instancia: {
                    plan_personal_id: this.planificacion.id,
                    usuario_id: this.usuarioId
                },
                render_options: {
                    methods: ["avance"]
                }
            }

            this.generadorInstrumentoService
                .find(this.planificacion.generador_instrumento_id, {
                    include: "generador_instrumento_categorias:categoria:clasificacion_tipo"
                })
                .then((generador: GeneradorInstrumento) => {
                    this.nivel = generador.generador_instrumento_categorias.filter(
                        (gic: GeneradorInstrumentoCategoria) =>
                            gic.categoria.clasificacion_tipo.clasificacion_tipo ==
                            config.plataforma.clasificacionTipoNivel
                    )[0]?.categoria
                })

            this.planPersonalInstanciasService.where(params).then((planPersonalInstancias: PlanPersonalInstancia[]) => {
                this.showSesiones = true

                if (planPersonalInstancias.length > 0) {
                    this.planPersonalInstancia = planPersonalInstancias[0]

                    const avance = this.planPersonalInstancia.avance || 0
                    this.setProgressBarValue(avance, this.planificacion.total_ciclos)
                    this.checkIfDone(avance, this.planificacion.total_ciclos)
                }
            })
        })
    }

    @HostListener("@dropIn.done", ["$event"]) animationDropInDone(event) {
        this.animatedValue = true
        // if(this.starRating) {
        //     this.starRating.startAnimation();
        // }
    }

    setProgressBarValue(avance: number, total: number) {
        this.progressBarValue = (avance / total) * 100
    }

    navigateToEdit() {
        this.router.navigate([this.plan_clase_route, this.planificacion.id, "edit"])
    }

    navigateToPlanificacion() {
        const queryParams: any = {}
        if (this.verSinCompartir) {
            queryParams.disableCompartir = true
        }

        this.router.navigate([this.plan_clase_route, this.planificacion.id], { queryParams })
    }

    checkIfDone(avance: number, total: number) {
        this.isDone = avance === total
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
