<notificacion-layout
    [notificacion]="notificacion"
    [enableMini]="enableMini"
    [tipoNotificacion]="'plan-personal'"
    [disableMarkButton]="disableMarkButton"
>
    <notificacion-contenido>
        <p class="texto-notificacion">
            <a [routerLink]="remitenteLink">
                {{ notificacionDetails.usuario.nombre }} {{ notificacionDetails.usuario.apellido_paterno }}
            </a>
            {{ "notificaciones.de" | t }} {{ notificacionDetails.receptor?.to_notif_s?.establecimiento }} {{ "notificaciones.del_curso" | t }}
            {{ notificacionDetails.receptor?.nombre }} {{ "notificaciones.te_ha_compartido_cla" | t }}
            <a [routerLink]="realizarLink">
                {{ notificacionDetails.plan_personal_sesion.plan_personal_sesion }} con fecha de cierre:
                {{ notificacionDetails.plan_personal_sesion.fecha_cierre | date: "dd/mm/yyyy HH:mm" }}
            </a>
        </p>
    </notificacion-contenido>
    <notificacion-acciones-footer>
        <a [routerLink]="realizarLink">
            <cui-button type="button" class="btn_style pull-right">{{ "notificaciones.realizar_ahora" | t }}</cui-button>
        </a>
    </notificacion-acciones-footer>
</notificacion-layout>
