<p *ngIf="mensajePersonalizar" class="mensaje-personalizar">
    <b>{{ mensajePersonalizar }}</b>
</p>
<div class="clickable" tabindex="0" role="button" [toggleExpandable]="id" [expandedOnCreation]="showOpened">
    <h5>
        {{ name }}
        <span class="tog-icon plus" [attr.aria-label]="'general.ver_mas' | t"><fa name="plus-circle"></fa></span>
        <span class="tog-icon minus" [attr.aria-label]="'general.ver_menos' | t"><fa name="minus-circle"></fa></span>
    </h5>
</div>

<ng-container *ngIf="!hasHijos">
    <div class="checkbox-list" [id]="id" expandableContent [expandOnCreation]="showOpened">
        <ng-container *ngFor="let clasificacion of clasificaciones; let i = index">
            <div class="inner-expandable">
                <div class="checkbox expand-checkbox">
                    <div class="expand-checkbox-inner">
                        <label>
                            <input
                                type="checkbox"
                                checked="checked"
                                name="clasificacion_{{ clasificacion.id }}"
                                [(ngModel)]="clasificacion.selected"
                                (ngModelChange)="clasificacionChange(clasificacion, null)"
                            />
                            <span class="checkmark"><fa class="icon" name="check"></fa></span>
                            <span class="clasificacion-name">
                                {{ clasificacion.clasificacion }}

                                <ng-container
                                    *ngIf="
                                        concats &&
                                        clasificacion[concats[clasificacion.clasificacion_tipo.clasificacion_tipo]]
                                    "
                                >
                                    ({{
                                        clasificacion[concats[clasificacion.clasificacion_tipo.clasificacion_tipo]]
                                            ?.clasificacion
                                    }})
                                </ng-container>
                            </span>
                        </label>
                        <div class="right-side">
                            <div
                                *ngIf="enableCantidadPreguntas"
                                class="numero-preguntas"
                                tooltip="{{ clasificacion.cantidad_preguntas }} pregunta{{
                                    clasificacion.cantidad_preguntas != 1 ? 's' : ''
                                }}"
                            >
                                <b>{{ clasificacion.cantidad_preguntas }}</b>
                                &nbsp;p.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="hasHijos">
    <div class="checkbox-list" [id]="id" expandableContent [expandOnCreation]="showOpened">
        <ng-container *ngFor="let clasificacion of clasificaciones; let i = index">
            <div class="checkbox expand-checkbox">
                <div class="expand-checkbox-inner">
                    <label>
                        <input
                            type="checkbox"
                            checked="checked"
                            name="clasificacion_{{ clasificacion.id }}"
                            [(ngModel)]="clasificacion.selected"
                            (ngModelChange)="clasificacionChange(clasificacion, null)"
                        />
                        <span class="checkmark"><fa class="icon" name="check"></fa></span>
                        <span class="clasificacion-name">
                            {{ clasificacion.clasificacion }}

                            <ng-container
                                *ngIf="
                                    concats &&
                                    clasificacion[concats[clasificacion.clasificacion_tipo.clasificacion_tipo]]
                                "
                            >
                                ({{
                                    clasificacion[concats[clasificacion.clasificacion_tipo.clasificacion_tipo]]
                                        ?.clasificacion
                                }})
                            </ng-container>
                        </span>
                    </label>
                    <div class="right-side">
                        <div
                            *ngIf="enableCantidadPreguntas"
                            class="numero-preguntas"
                            tooltip="{{ clasificacion.cantidad_preguntas }} pregunta{{
                                clasificacion.cantidad_preguntas != 1 ? 's' : ''
                            }}"
                        >
                            <b>{{ clasificacion.cantidad_preguntas }}</b>
                            &nbsp;p.
                        </div>
                        <button
                            *ngIf="
                                clasificacion.clasificaciones_hijas && clasificacion.clasificaciones_hijas.length != 0
                            "
                            type="button"
                            class="tog-icon-2 btn btn-link"
                            toggleExpandable="subclasificaciones_{{ id }}_{{ i }}"
                        >
                            <span class="plus">
                                {{ "general.ver_mas" | t }}
                            </span>
                            <span class="minus">{{ "general.ver_menos" | t }}</span>
                        </button>
                    </div>
                </div>
                <div
                    *ngIf="clasificacion.clasificaciones_hijas && clasificacion.clasificaciones_hijas.length != 0"
                    class="checkbox-list small-checkboxes"
                    id="subclasificaciones_{{ id }}_{{ i }}"
                    expandableContent
                >
                    <div class="checkbox-hijo-container">
                        <clasificacion-checkboxes
                            *ngFor="let clasificacionHija of clasificacion.clasificaciones_hijas"
                            [clasificacion]="clasificacionHija"
                            [clasificacionPadre]="clasificacion"
                            [enableCantidadPreguntas]="enableCantidadPreguntas"
                            [enableObjetivoPriorizado]="enableObjetivoPriorizado"
                            [concats]="concats"
                            [id]="id"
                            (clasificacionChange)="clasificacionChange($event.clasificacion, $event.clasificacionPadre)"
                        ></clasificacion-checkboxes>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
