<cui-showcasebox [openStyle]="!loadingLayout.loading" type="noticias">
    <cui-showcasebox-title-icon>
        <ogr-icon name="{{ config.plataforma.iconSet.noticiasShowcase }}"></ogr-icon>
    </cui-showcasebox-title-icon>
    <!-- <cui-showcasebox-title-text>Noticias</cui-showcasebox-title-text> -->
    <cui-showcasebox-title-text>{{ "home.showcasebox.noticias.titulo" | t }}</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <loading-layout #loadingLayout>
            <ul class="lista-notificaciones" *ngIf="noticias && noticias.length > 0">
                <li *ngFor="let noticia of noticias">
                    {{ noticia.titulo }}
                    <br />
                    <div class="clearfix" *ngIf="noticia.noticia">
                        <a (click)="showNoticia(noticia)" class="ver-mas pull-right">
                            {{ "home.showcasebox.noticias.ver_mas" | t }}...
                        </a>
                    </div>
                </li>
            </ul>
        </loading-layout>
    </cui-showcasebox-content>
    <cui-showcasebox-data-not-found *ngIf="!loadingLayout.loading && (!noticias || (noticias && noticias.length == 0))">
        <p class="not-found" [class.default-not-found]="defaultNotFound">{{ "home.showcasebox.noticias.none" | t }}</p>
    </cui-showcasebox-data-not-found>
</cui-showcasebox>

<generic-modal [buttonClicked]="openModal" [id]="'modal-noticias'" #ModalNoticias>
    <modal-titulo class="modal-titulo">
        <div class="noticias-title">
            <div class="noticias-icon" *ngIf="hasNewsIcon">
                <img src="{{ logoUrl }}" (error)="hasNewsIcon = false" />
            </div>
            <div class="noticias-icon" *ngIf="!hasNewsIcon">
                <ogr-icon name="megafono" class="noticias-icon-default"></ogr-icon>
            </div>
            <div class="noticias-title-text" *ngIf="currentNoticia">
                {{ currentNoticia.titulo }}
            </div>
        </div>
    </modal-titulo>
    <modal-contenido class="modal-contenido">
        <div *ngIf="currentNoticia">
            <div [innerHTML]="currentNoticia.noticia | trustedhtml"></div>
        </div>
    </modal-contenido>
    <modal-botones>
        <cui-button buttonType="cancel" type="button" class="btn_style_2" (click)="ModalNoticias.close()">
            {{ "home.showcasebox.noticias.cerrar" | t }}
        </cui-button>
    </modal-botones>
</generic-modal>
