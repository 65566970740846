<ll-titulo>Evaluaciones Compartidas</ll-titulo>
<ll-subtitulo>{{ asignatura?.asignatura }}</ll-subtitulo>

<menu-asignaturas-horizontal
    [asignaturaId]="asignaturaId"
    [tipoAsignaturas]="tipoInstrumento"
    (asignaturaChanged)="onSelectAsignatura($event)"
></menu-asignaturas-horizontal>
<evaluaciones-compartidas-alumno
    *ngIf="asignaturaId"
    [asignaturaId]="asignaturaId"
    [evaluacionTipo]="tipoInstrumento"
    (ready)="onReady($event)"
    [hideButtonEscanear]="hideButtonEscanear"
></evaluaciones-compartidas-alumno>
