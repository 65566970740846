<div class="titlebar">
    <div class="row">
        <div class="col-md-12">
            <h1>
                <span #titulo><ng-content select="titlebar-titulo"></ng-content></span>
                <ng-template *ngIf="titulo.children.length == 0">-Título por defecto-</ng-template>
                <small class="subtitle"><ng-content select="titlebar-subtitulo"></ng-content></small>
            </h1>
        </div>
        <div class="menu-redes col-md-5 col-sm-5 col-xs-5 pull-right" *ngIf="!noSocialLinks">
            <ul>
                <li placement="top" tooltip="Lee nuestro blog">
                    <div class="small-round-btn">
                        <div class="small-round-btn-inner">
                            <a href=""><fa [name]="'comments'"></fa></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="small-round-btn">
                        <div class="small-round-btn-inner">
                            <a href="{{ urlFacebook }}" target="_blank"><fa [name]="'facebook'"></fa></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="small-round-btn">
                        <div class="small-round-btn-inner">
                            <a href="{{ urlTwitter }}" target="_blank"><fa [name]="'twitter'"></fa></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="small-round-btn">
                        <div class="small-round-btn-inner">
                            <a href="{{ urlYoutube }}" target="_blank"><fa [name]="'youtube-play'"></fa></a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
