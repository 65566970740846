import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { Material } from "@puntaje/nebulosa/api-services"

export class MaterialFavorito extends BaseModel {
    public usuario_id: number
    public material_id: number
    public created_at: string | Date
    public updated_at: string | Date

    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => Material) material: Material
}
