import { Input, Component, EventEmitter, Output, OnInit, ChangeDetectorRef, SimpleChanges } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { Universidades, Universidad, PreferenciaUniversidad } from "@puntaje/carreras/api-services"
import { CapitalizePipe } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "embed-preferencia-universidades",
    styleUrls: ["embed_preferencia_universidades.component.scss"],
    templateUrl: "embed_preferencia_universidades.component.html"
})
export class EmbedPreferenciaUniversidadesComponent implements OnInit {
    @Input() initialPreferenciaUniversidades: PreferenciaUniversidad[] = []
    @Input() amount: number = 3
    @Input() form: UntypedFormGroup
    @Input() notRequired = false
    @Output() emitPreferencias: EventEmitter<PreferenciaUniversidad[]> = new EventEmitter<PreferenciaUniversidad[]>()

    preferenciaUniversidades: PreferenciaUniversidad[] = []
    universidades: Universidad[] = []
    universidad: number[]
    universidadesByIndex: { [key: number]: Universidad[] } = {}
    params: any[] = []

    universidadesLoaded: boolean = false
    controls: any[] = []

    // TODO: usar capitalize de ogr-core-lib en vez del pipe
    capitalize = new CapitalizePipe()

    constructor(private universidadesService: Universidades, public cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.setSelectsAndModels()
        this.universidad = new Array(this.amount)
        const universidadesParams = {
            include: "[sede:[lugar:[pais]]]"
        }

        this.universidadesService.where(universidadesParams).then((universidades: Universidad[]) => {
            if (universidades.length > 0) {
                this.universidades = universidades.filter(universidad => {
                    return universidad.sedes.find(
                        sede => sede.lugar.pais.pais == this.capitalize.transform(config.plataforma.pais)
                    )
                })

                if (this.universidades.length == 1) {
                    this.setVals(0, this.universidades[0].id)
                }

                this.universidadesByIndex[0] = this.universidades
                this.universidadesLoaded = true

                this.initialPreferenciaUniversidades
                    .slice(0, this.amount)
                    .forEach((preferenciaUniversidad, indexPreferenciaUniversidad) => {
                        this.preferenciaUniversidades[indexPreferenciaUniversidad] = preferenciaUniversidad
                        this.setVals(indexPreferenciaUniversidad, {
                            target: { value: `${indexPreferenciaUniversidad}: ${preferenciaUniversidad.universidadId}` }
                        })
                    })
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes["initialPreferenciaUniversidades"] &&
            !changes["initialPreferenciaUniversidades"].isFirstChange() &&
            changes["initialPreferenciaUniversidades"].previousValue !=
                changes["initialPreferenciaUniversidades"].currentValue
        ) {
            this.initialPreferenciaUniversidades.forEach((preferenciaUniversidad, indexPreferenciaUniversidad) => {
                this.preferenciaUniversidades[indexPreferenciaUniversidad] = preferenciaUniversidad
                this.setVals(indexPreferenciaUniversidad, {
                    target: {
                        value: `${indexPreferenciaUniversidad}: ${
                            preferenciaUniversidad._destroy ? "undefined" : preferenciaUniversidad.universidadId
                        }`
                    }
                })
            })
        }
    }

    setSelectsAndModels() {
        this.params = []
        this.universidades = []
        this.preferenciaUniversidades = []
        let amount = this.amount ? this.amount : 3

        for (var i = 0; i < amount; i++) {
            let paramI = {}

            const sufijo = this.amount == 1 ? "" : "" + (i + 1)

            const extra = config.plataforma.withIPCFT ? " / IP / CFT" : ""

            // Universidad
            const labelUniversidad = `Universidad${extra} de preferencia ${sufijo}`
            const keyUniversidad = "universidad_" + i
            paramI["universidadParams"] = {
                type: "select",
                label: labelUniversidad,
                visible: true,
                key: keyUniversidad,
                done: false
            }

            this.preferenciaUniversidades.push(new PreferenciaUniversidad())

            this.params.push(paramI)
        }

        // Primera universidad obligatoria
        if (!this.notRequired) {
            this.params[0].universidadParams.validations = [Validators.required]
        }

        // set form controls
        this.params.forEach(params => {
            const control = new UntypedFormControl("", params.universidadParams.validations)
            this.form.addControl(params.universidadParams.key, control)

            this.controls.push(control)
        })

        this.params[0].universidadParams.done = true
    }

    validatePreferenciaUniversidadUnica(formControl) {
        const val = Number(formControl.value)

        let count = 0
        for (let index = 0; index <= this.amount; index++) {
            if (this.form.controls["universidad_" + index])
                count += Number(this.form.controls["universidad_" + index].value) == val ? 1 : 0
        }
        // const valid = this.params.reduce((acc, param) => acc + (param.universidadId == val ? 1 : 0), 0) < 1
        const valid = count <= 1

        return valid
            ? null
            : {
                  validatePreferenciaUniversidadUnica: {
                      valid: false
                  }
              }
    }

    setVals(index: number, event: any) {
        const numberVal = Number(event.target.value.split(":")[1])
        const val = isNaN(numberVal) ? undefined : numberVal

        this.form.controls["universidad_" + index].setValue(val)
        if (val) {
            this.universidad[index] = val
            // set preferencia
            this.preferenciaUniversidades[index].universidadId = val
            this.preferenciaUniversidades[index].orden = index + 1
            this.preferenciaUniversidades[index]._destroy = false

            this.params[index].universidadId = Number(val)

            // habilitar siguiente input universidad
            if (index + 1 < this.params.length) {
                this.params[index + 1].universidadParams.done = true
            }

            let universidades

            // se filtran las universidades seleccionadas de los arreglos de los selects
            for (let i = 0; i < this.params.length; i++) {
                if (i != index && (this.params[i].universidadId || i == index + 1)) {
                    universidades = this.universidades.slice()
                    const idsSelected = this.params
                        .filter((param, j) => j != i && param.universidadId)
                        .map(param => param.universidadId)
                    universidades = universidades.filter((c: any) => !idsSelected.includes(c.id))

                    this.universidadesByIndex[i] = universidades
                }
            }
        } else {
            this.universidad[index] = undefined
            this.preferenciaUniversidades[index]._destroy = true
            this.hideParams(index)
        }

        const universidadesSelected = this.preferenciaUniversidades.filter(
            x => x.universidadId !== null && x.universidadId !== undefined
        )

        this.emitPreferencias.next(universidadesSelected)

        this.cdr.detectChanges()
    }

    hideParams(index) {
        let j = index + 1
        while (j < this.params.length) {
            this.params[j].universidadParams.done = false
            this.universidad[j] = undefined
            j += 1
        }
    }

    public reset() {
        this.setSelectsAndModels()
        this.emitPreferencias.next([])
        this.cdr.detectChanges()
    }
}
