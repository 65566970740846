import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { Asociacion } from "./asociaciones.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class Asociaciones extends PuntajeBaseService<Asociacion> {
    tableName = "asociaciones"
    singularTableName = "asociacion"
    modelClass = Asociacion

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public stats(asociacionId: number, params: any = null) {
        this.enableIgnoreModel()
        return this.one(asociacionId)
            .concatExtra("stats")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }

    public top_docentes(asociacionId: number, params: any = null) {
        this.enableIgnoreModel()
        return this.one(asociacionId)
            .concatExtra("top_docentes")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }
}
