import { Component, OnChanges, Input, Output, EventEmitter, OnInit } from "@angular/core"
import { Notificacion, Notificaciones } from "@puntaje/puntaje/api-services"
import { NotificacionesPendingService } from "../notificaciones_pending.service"
import { Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "notificacion-pro",
    templateUrl: "notificacion_pro.component.html",
    styleUrls: ["notificacion.component.scss"]
})
export class NotificacionProComponent implements OnInit {
    @Input() notificacion: Notificacion
    @Input() enableMini: boolean = false
    @Input("disableMarkButton") disableMarkButton: boolean = false
    nombre: string = ""
    genero: string = ""
    plataforma: string = ""
    links: string[] = []
    fecha_creacion: Date

    animateExit: boolean = false
    @Output() markAsNotPending: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        protected notificacionesService: Notificaciones,
        protected notificacionesPendingService: NotificacionesPendingService,
        protected router: Router
    ) {}

    ngOnInit() {}

    ngOnChanges() {
        if (this.notificacion) {
            this.notificacion.notificacion = JSON.parse(this.notificacion.notificacion)
            let data = this.notificacion.notificacion as any
            this.nombre = data.nombre
            this.genero = data.genero
            this.plataforma = data.platform
            this.links = data.links
            this.fecha_creacion = new Date(this.notificacion.created_at)
        }
    }

    onClickLink(link_num) {
        if (this.notificacion.fecha_visto) {
            this.router.navigate([this.links[link_num]])
        } else {
            this.disableMarkButton = true
            this.animateExit = true
            let n = new Notificacion()
            n.fecha_visto = new Date()
            this.notificacionesService.update(this.notificacion.id, n).then(() => {
                this.notificacionesPendingService.update()
                this.markAsNotPending.emit(this.notificacion.id)
                this.router.navigate([this.notificacion.link])
            })
        }
    }
}
