import { BaseModel, Validations, SaveType, AppConfig } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { Lugar } from "./lugares.model"
import { Establecimiento } from "./establecimientos.model"
import { UsuarioChile } from "./usuario_chile.model"
import { UsuarioColombia } from "./usuario_colombia.model"
import { UsuarioMexico } from "./usuario_mexico.model"
import { PreferenciaCarreraSede } from "@puntaje/carreras/api-services"
import { UsuarioBrasil } from "./usuario_brasil.model"

declare const config: AppConfig

export class UsuarioRegistroBase extends BaseModel {
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public fecha_nacimiento: Date
    public email: string
    public telefono: string
    public lugar_id: number
    public establecimiento_id: number
    public nivel_id: number
    public especialidad: string
    public codigo_registro: string

    @SaveType(() => Lugar) public lugar: Lugar
    @SaveType(() => Establecimiento) public colegio: Establecimiento

    classes = {
        chile: new UsuarioChile(),
        colombia: new UsuarioColombia(),
        mexico: new UsuarioMexico()
    }

    @SaveType(() => UsuarioChile) public usuario_chile: UsuarioChile
    @SaveType(() => UsuarioColombia) public usuario_colombia: UsuarioColombia
    @SaveType(() => UsuarioMexico) public usuario_mexico: UsuarioMexico
    @SaveType(() => UsuarioBrasil) public usuario_brasil: UsuarioBrasil

    public pais_id: number
    public password: string
    public password_confirmation: string
    public terminos_y_condiciones: boolean

    public static global_validations = [Validations.equalPasswords(["password", "password_confirmation"])]

    public preferenciaCarreraSedes: PreferenciaCarreraSede[] = []

    constructor() {
        super()
    }

    public nombreCompleto() {
        return this.nombre + " " + this.apellido_paterno
    }

    public toString() {
        return this.email
    }

    public toUsuario() {
        let identificador: any[string] =
            config.plataforma.identificadorUsuario[0].toLowerCase() == "ti"
                ? "cc"
                : config.plataforma.identificadorUsuario[0].toLowerCase()
        let user = new Usuario()
        user.nombre = this.nombre
        user.apellido_paterno = this.apellido_paterno
        user.apellido_materno = this.apellido_materno
        user.fecha_nacimiento = this.fecha_nacimiento
        user.email = this.email
        user.especialidad = this.especialidad

        user.password_confirmation = this.password_confirmation
        user.password = this.password
        user.rol_id = 3

        if (this.lugar && this.lugar.id) {
            user.lugar = this.lugar
            user.lugar_id = this.lugar.id
        }
        if (this.colegio) {
            user.establecimiento_id = this.colegio.id
        }
        if (this.nivel_id !== undefined && this.nivel_id !== null) {
            user.nivel_id = this.nivel_id
        }

        user.codigo_registro = this.codigo_registro

        user["usuario_" + config.plataforma.pais] = this.classes[config.plataforma.pais]
        user["usuario_" + config.plataforma.pais][identificador] =
            this["usuario_" + config.plataforma.pais][identificador]
        user["usuario_" + config.plataforma.pais].telefono = this.telefono
        user.preferencia_carrera_sedes = this.preferenciaCarreraSedes
        return user
    }
}
