import { RestrictedLayoutComponent } from "../layouts/restricted_layout/restricted_layout.component"
import {
    EvaluacionInstanciaEstadisticasComponent,
    EstadisticaEvaluacionComponent,
    EstadisticasProgresoComponent,
    EstadisticasAlumnoComponent,
    ProgresoAlumnoComponent,
    TutorEstadisticasComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const estadisticasRoutes: Routes = [
    {
        path: "estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasAlumnoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "tutor/estadisticas",
        component: RestrictedLayoutComponent,
        children: [
            { path: "", component: TutorEstadisticasComponent, canActivate: [TokenGuard, AuthGuard, TokenGuard] }
        ]
    },
    {
        path: "mi_progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoAlumnoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayo_instancias/estadisticas/:id",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: EvaluacionInstanciaEstadisticasComponent, canActivate: [TokenGuard, AuthGuard] }
        ]
    },
    {
        path: "evaluacion/:id/estadistica",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticaEvaluacionComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const estadisticasRouting = RouterModule.forChild(estadisticasRoutes)
