import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { mergeMap, map } from "rxjs/operators"
import { from } from "rxjs"
import { Observable } from "rxjs"
import { Action } from "@ngrx/store"
import { EvaluacionTiposActionTypes, SetEvaluacionTipoAliases } from "../actions/evaluacion_tipos.actions"
import { TipoInstrumentoPlataformas } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"

@Injectable()
export class EvaluacionTiposEffects {
    
    evaluacionTipoAliases$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(EvaluacionTiposActionTypes.GetEvaluacionTipoAliases),
        mergeMap(action => {
            return from(
                new Promise<{ evaluacionTipoAliases: any }>(async resolve => {
                    this.tipoInstrumentoPlataformasService
                        .configurarAliases(this.config.plataforma.name, this.config.plataforma.evaluacionTipos)
                        .then((dictAliases: any) => {
                            resolve({ evaluacionTipoAliases: dictAliases })
                        })
                })
            ).pipe(map((data: { evaluacionTipoAliases: any }) => new SetEvaluacionTipoAliases(data)))
        })
    ))

    constructor(
        private actions$: Actions,
        private tipoInstrumentoPlataformasService: TipoInstrumentoPlataformas,
        private config: AppConfig
    ) {}
}
