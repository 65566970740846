import { Component, Input, ElementRef } from "@angular/core"
import { ToggleClassService } from "@puntaje/shared/core"

@Component({
    template: `
        <span>{{ valor }}</span>
    `,
    selector: "subir-respuestas-valor-feedback"
})
export class SubirRespuestasValorFeedbackComponent {
    _valor: string

    @Input("valor")
    set valor(v) {
        if (this._valor !== v) {
            this.showFeedback()
        }
        this._valor = v
    }

    get valor() {
        return this._valor
    }

    constructor(protected el: ElementRef, protected toggleClassService: ToggleClassService) {}

    showFeedback() {
        this.toggleClassService.removeClass(this.el.nativeElement, "updated")
        setTimeout(() => {
            this.toggleClassService.addClass(this.el.nativeElement, "updated")
        }, 10)
    }
}
