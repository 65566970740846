<cui-button-link
    *ngIf="carreraSede.preferenciaCarreraSedes.length == 0"
    class="btn_style btn-xs btn-seguir"
    (click)="seguirCarreraSede(carreraSede)"
>
    <fa name="heart-o" class="icono"></fa>
    Seguir
</cui-button-link>
<ng-template [ngIf]="carreraSede.preferenciaCarreraSedes.length > 0">
    <a *ngIf="!hideNotFollow" class="dejarseguir" (click)="noSeguirCarreraSede(carreraSede)">Dejar de seguir</a>
    <span class="siguiendo">
        <fa name="heart-o" class="icono"></fa>
        Siguiendo
    </span>
</ng-template>
