<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <!-- Titulo -->
        <cui-section-headline *ngIf="!hideTitle">{{ material.material }}</cui-section-headline>
        <!-- Comentarios -->
        <div class="comentario-container" *ngIf="material.comentarios">
            <p>{{ material.comentarios }}</p>
        </div>
        <div class="options-toolnavbar">
            <div class="options-left">
                <a *ngIf="showVolver" type="button" (click)="goBack()" class="option">
                    <span class="glyphicon glyphicon-chevron-left"></span>
                    {{ "estudiar.materiales.volver" | t }}
                </a>
                <span *ngIf="showVolver" class="separador"></span>
                <crear-duda
                    *ngIf="enableDudas"
                    class="option"
                    [type]="'link'"
                    [tabla]="'Material'"
                    [entidad_id]="material.id"
                    [queryParams]="dudaQueryParams"
                ></crear-duda>
                <span *ngIf="enableReporte && !isFromProfesor && enableDudas" class="separador"></span>
                <reportes-modal
                    *ngIf="enableReporte && !isFromProfesor"
                    [enableLink]="true"
                    [enableButton]="false"
                    [tabla]="'Material'"
                    [entidad_id]="material.id"
                    class="reportes option"
                ></reportes-modal>
            </div>
            <div class="options-right">
                <sentiment *ngIf="enableSentiment" [object]="material" class="option"></sentiment>
                <span class="separador"></span>
                <a
                    (click)="toggleFavorito()"
                    *ngIf="showFavorito"
                    class="favorito option"
                    [class.hearted]="materialFavorito"
                >
                    <ng-container *ngIf="materialFavorito">
                        <fa name="heart" class="icono"></fa>
                        <span>{{ "estudiar.materiales.favorito" | t }}</span>
                    </ng-container>
                    <ng-container *ngIf="!materialFavorito">
                        <fa name="heart-o"></fa>
                        <span>{{ "estudiar.materiales.favoritos" | t }}</span>
                    </ng-container>
                </a>
                <span class="separador" *ngIf="showFavorito"></span>
                <a *ngIf="isHTML" (click)="printElem(material)" class="option">
                    <fa name="print"></fa>
                    {{ "estudiar.materiales.imprimir" | t }}
                </a>
                <span class="separador" *ngIf="isHTML"></span>
                <a
                    *ngIf="!isFileLink(material) && isVideo && isViewOnYoutubeEnabled && !lookViewOnYoutube"
                    [attr.href]="getDownloadLink(material)"
                    target="_blank"
                    class="option"
                >
                    <fa name="youtube-play" class="youtube-btn"></fa>
                    {{ "estudiar.materiales.youtube" | t }}
                </a>
                <a
                    *ngIf="isFileLink(material) && (isArchivo || isPPT || isWord || isExcel)"
                    [attr.href]="getDownloadLink(material)"
                    download
                    class="option"
                    (click)="onDownload()"
                >
                    <span class="glyphicon glyphicon-cloud-download"></span>
                    &nbsp;{{ "estudiar.materiales.descargar" | t }}
                </a>
                <a
                    *ngIf="isFileLink(material) && isArchivo && isPdf"
                    (click)="this.currentPage = 1; fullscreenService.openFullscreen(pdfObject)"
                    download
                    class="option"
                >
                    <span class="glyphicon glyphicon-eye-open"></span>
                    &nbsp;{{ "estudiar.materiales.fullscreen" | t }}
                </a>
                <a
                    *ngIf="isInteractivo"
                    (click)="fullscreenService.openFullscreen(materialObject)"
                    download
                    class="option"
                >
                    <span class="glyphicon glyphicon-eye-open"></span>
                    &nbsp;{{ "estudiar.materiales.fullscreen" | t }}
                </a>
                <a
                    *ngIf="isFileLink(material) && isArchivo && !isPdf"
                    (click)="onNavigate(material)"
                    download
                    class="option"
                >
                    <span class="glyphicon glyphicon-eye-open"></span>
                    &nbsp;{{ "estudiar.materiales.fullscreen" | t }}
                </a>
            </div>
        </div>

        <!-- Previsualización -->
        <ng-template #materialObject>
            <div
                *ngIf="!enableMaterialRelacionado || (materialRelaciones && materialRelaciones.length == 0)"
                class="material-wrap"
                (keydown.arrowleft)="arrowLeft($event)"
                (keydown.arrowright)="arrowRight($event)"
                tabindex="0"
            >
                <material-preview
                    [material]="material"
                    [class.fullscreenPreview]="fullscreenService.isFullscreen"
                ></material-preview>
                <button
                    class="closeFullscreen"
                    *ngIf="fullscreenService.isFullscreen"
                    (click)="fullscreenService.closeFullscreen()"
                >
                    &times;
                </button>
            </div>
        </ng-template>
        <ng-container *ngTemplateOutlet="materialObject"></ng-container>

        <ng-template #pdfObject>
            <object
                (keydown.arrowleft)="arrowLeft($event)"
                (keydown.arrowright)="arrowRight($event)"
                *ngIf="isPdf && materialPdfService.numberOfPages"
                [data]="urlsafe"
                type="application/pdf"
            ></object>
        </ng-template>
        <div *ngIf="enableMaterialRelacionado && materialRelaciones && materialRelaciones.length > 0" class="row">
            <div class="col-md-9 col-sm-12">
                <material-preview [material]="material"></material-preview>
                <br />
            </div>
            <div class="col-md-3 col-sm-12">
                <cui-section-headline>Material relacionado</cui-section-headline>
                <ul class="lista-material-relacionado">
                    <ng-container *ngFor="let c of materialRelaciones">
                        <li *ngIf="c.relacion_type == 'evaluacion'" (click)="goToEvaluacion(c.relacion_id)">
                            <div class="mr-icono">
                                <ogr-icon class="icono" name="editar-prueba"></ogr-icon>
                            </div>
                            <p>Evaluación de ejercitación</p>
                        </li>
                        <li *ngIf="c.relacion_type == 'guia'" (click)="goToGuia(c.relacion_id)">
                            <div class="mr-icono">
                                <ogr-icon class="icono" name="guia"></ogr-icon>
                            </div>
                            <p>Guía de estudio</p>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</loading-layout>
