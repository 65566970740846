import { Component, Input, Directive } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Barra con el título que va en la parte superior. Puede tener una imagen en la parte izquierda (pendiente).

#################################*/

@Component({
    templateUrl: "titlebar.component.html",
    selector: "titlebar",
    styleUrls: ["titlebar.component.scss"]
})
export class TitlebarComponent {
    urlFacebook: string
    urlTwitter: string
    urlYoutube: string
    @Input() noSocialLinks: boolean = true

    constructor() {
        let cfg = config
        this.urlFacebook = config.plataforma.info.social.facebook
        this.urlTwitter = config.plataforma.info.social.twitter
        this.urlYoutube = config.plataforma.info.social.youtube
    }
}

@Directive({ selector: "titlebar-titulo, titlebar-subtitulo" })
export class TitlebarComponentTags {}
