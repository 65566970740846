import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "plan-clase-alumno.component.html"
})
export class PlanClaseAlumnoComponent implements OnInit, OnDestroy {
    sub: Subscription
    subData: Subscription
    sesiones_route: string
    planEstudioId: number

    titulo: string = "Sesiones - Plan de Estudio"
    baseRoute: string = "/plan_clases"
    vistaPrevia = config.app.name == "admin"

    constructor(protected titleService: TitleService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_clases.plan_clase_alumno")
        this.sub = this.route.params.subscribe(params => {
            this.planEstudioId = +params["id"]
            this.sesiones_route = "/plan_estudios/" + this.planEstudioId + "/sesiones"
        })

        this.subData = this.route.data.subscribe(data => {
            this.titulo = data.titulo ?? this.titulo
            this.baseRoute = data.baseRoute ?? this.baseRoute
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
