import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Instrumento } from "./instrumentos.model"
import { GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { Usuario } from "./usuarios.model"
import { InstrumentoMultipleInstrumento } from "./instrumento_multiple_instrumentos.model"

export class InstrumentoMultiple extends BaseModel {
    usuario_id: number
    generador_instrumento_id: number

    @SaveType(() => Instrumento) public instrumentos: Instrumento[]
    @SaveType(() => InstrumentoMultipleInstrumento)
    public instrumento_multiple_instrumentos: InstrumentoMultipleInstrumento[]
    @SaveType(() => GeneradorInstrumento) public generador_instrumento: GeneradorInstrumento
    @SaveType(() => Usuario) public usuario: Usuario

    constructor() {
        super()
        this.instrumentos = new Array<Instrumento>()
        this.generador_instrumento = null
        this.usuario_id = null
    }

    public toString() {
        if (!this.generador_instrumento) return "" + this.id

        return "(" + this.generador_instrumento.id + ")"
    }
}
