import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router"
import { Observable } from "rxjs"
import { Lote } from "../lotes.model"
import { Lotes } from "../lotes.service"
import { SessionService } from "@puntaje/shared/core"

@Injectable()
export class LoteGuard implements CanActivate {
    constructor(protected sessionService: SessionService, protected lotesService: Lotes) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.sessionService.getPerfiles().includes("Super Administrador")) return true

        return this.lotesService.find(route.params["id"]).then((lote: Lote) => {
            return this.sessionService.getUserData().persona_id == lote.propietario_id
        })
    }
}
