import { BaseModel, SaveType } from "@puntaje/shared/core"

export class EstablecimientoMexico extends BaseModel {
    public codigo_unico: string
    public establecimiento_id: number

    getFieldName() {
        return "Código Único"
    }

    getFieldValue() {
        return this.codigo_unico
    }
}
