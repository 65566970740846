import { BaseModel } from "@puntaje/shared/core"

export class BannerClick extends BaseModel {
    public usuario_id: number
    public banner_instancia_id: number

    constructor() {
        super()
    }

    public toString() {
        return "(" + this.usuario_id + "," + this.banner_instancia_id + ")"
    }
}
