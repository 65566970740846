export class BaseModel {
    public id: number
    public created_at: string | Date
    public updated_at: string | Date

    public className = ""
    public _destroy?: boolean | number

    public getClassName() {
        return this.className
    }

    public static recursiveLabels(show_params: any) {
        let labels = []
        for (const key in show_params) {
            if (show_params[key].type == "model" && show_params[key].class != undefined) {
                let res = BaseModel.recursiveLabels(show_params[key].class.show_params)
                labels = labels.concat(res)
            } else {
                labels.push(show_params[key].label)
            }
        }
        return labels
    }

    public static recursiveShowParams(show_params: any) {
        for (const key in show_params) {
            if (show_params[key].type == "model" && show_params[key].class != undefined) {
                let s_p = BaseModel.recursiveShowParams(show_params[key].class.show_params)
                show_params[key].show_params = s_p
            }
        }
        return show_params
    }

    public clone(): this {
        let clone: this = new (this.constructor as any)(true)
        let properties = JSON.parse(JSON.stringify(this))

        for (const key in properties) {
            if (this[key] instanceof BaseModel) {
                clone[key] = this[key] ? this[key].clone() : null
            } else if (this[key] instanceof Array) {
                clone[key] = !this[key]
                    ? null
                    : this[key].map(v => {
                          return v instanceof BaseModel ? v.clone() : v
                      })
            } else {
                clone[key] = properties[key]
            }
        }

        return clone
    }

    public concatAttributeToChilds(): void {
        for (const key in this) {
            if ((this[key] as any) instanceof BaseModel) {
                let model: any = this[key]
                model.concatAttributeToChilds()

                this[key + "_attributes"] = model
                delete this[key]
            } else if ((this[key] as any) instanceof Array) {
                var isPrimitiveArray = true
                let arr: any = this[key]
                arr.forEach(l => {
                    if (l instanceof BaseModel) {
                        l.concatAttributeToChilds()
                        isPrimitiveArray = false
                    }
                })
                if (!isPrimitiveArray) {
                    this[key + "_attributes"] = arr
                    delete this[key]
                }
            }
        }
    }

    cleanEmpty(): void {
        for (const key in this) {
            if ((this[key] as any) == "" || this[key] == undefined || this[key] == null) {
                delete this[key]
            }
        }
    }
}
