<ll-titulo>{{ "participa.dudas.listado.titulo" | t }}</ll-titulo>
<ll-topside>
    <cui-button-link
        class="new-btn"
        routerLinkValue="/dudas/new"
        [queryParamsValue]="{ show_cursos: showCursos ? 1 : 0 }"
    >
        <fa name="plus-circle" class="left-icon"></fa>
        &nbsp;{{ "participa.dudas.listado.btn_nueva" | t }}
    </cui-button-link>
</ll-topside>

<dudas [categoriaId]="categoriaId" [showCursos]="showCursos" [fromProfesor]="fromProfesor"></dudas>
