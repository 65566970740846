import { BaseModel, SaveType } from "@puntaje/shared/core"
import { EscalaGlobal } from "./escala_globales.model"

export class EscalaGlobalEvaluacion extends BaseModel {
    escala_global_id: number
    evaluacion_id: number
    created_at: Date
    updated_at: Date

    @SaveType(() => EscalaGlobal) escala_global: EscalaGlobal
}
