import { Pipe, PipeTransform } from "@angular/core"

@Pipe({ name: "highlight" })
export class HighlightPipe implements PipeTransform {
    transform(value: string, match?: string): any {
        if (!value) return null

        if (!match) {
            match = value
        }

        var re = new RegExp(match, "gi") //'gi' for case insensitive, 'g' to be case sensitive.
        return value.replace(re, "<mark>" + "$&" + "</mark>")
    }
}
