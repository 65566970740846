import { Component, Input, ViewChild, EventEmitter, Output } from "@angular/core"
import {
    CarreraSedes,
    CarreraSede,
    Sede,
    Sedes,
    Universidad,
    Universidades,
    InfoAdicionales
} from "@puntaje/carreras/api-services"
import { AuthService, CapitalizePipe } from "@puntaje/shared/core"
import { CUITableComponent } from "@puntaje/shared/cui"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { ActivatedRoute, Router } from "@angular/router"
import { Subscription } from "rxjs"
import { CarrerasBuscadorService } from "./carreras_buscador.service"

@Component({
    selector: "buscador-carreras",
    templateUrl: "buscador-carreras.component.html",
    styleUrls: ["buscador-carreras.component.scss"]
})
export class BuscadorCarrerasComponent {
    query: boolean = false
    universidad: Universidad | string
    sede: Sede | string
    carreraSedeInput: CarreraSede | string
    queryUniversidad: string = ""
    querySede: string = ""
    queryCarrera: string = ""
    queryInfoAdicionales: Object[] = []
    universidades: Universidad[]
    sedes: Sede[]
    filteredCarreraSedes: CarreraSede[] = []
    carreraSedes: CarreraSede[]
    showSedes: boolean = false
    enableAdditionalSearch: boolean = config.carreras?.criteriaFilter?.length > 0 ? true : false
    aditionalFilters: Array<any> = config.carreras?.criteriaFilter
    showAditionalFilter: boolean = false
    selectedFilters: any
    dropdownAditionalSettings = {
        singleSelection: false,
        idField: "tipo",
        textField: "filter",
        itemsShowLimit: 10,
        allowSearchFilter: false,
        enableCheckAll: false
    }
    milisecondsTime = new Date().getTime()

    @ViewChild(CUITableComponent) cuiTable: CUITableComponent

    @Output() seguirEvent: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Input() withPais = false

    capitalize = new CapitalizePipe()
    areaQuery: string
    modalidadQuery: string

    sub: Subscription

    constructor(
        protected carreraSedesService: CarreraSedes,
        protected universidadesService: Universidades,
        protected sedesService: Sedes,
        protected authService: AuthService,
        protected infoAdicionalesServices: InfoAdicionales,
        protected route: ActivatedRoute,
        protected router: Router,
        protected carrerasBuscadorService: CarrerasBuscadorService
    ) {}

    ngOnInit() {
        this.getAllUniversidades().then(() => {
            let firstLoad = true

            this.sub = this.route.queryParams.subscribe(params => {
                this.universidad = this.queryUniversidad = params["universidad"] || ""
                this.sede = this.querySede = params["sede"] || ""
                this.carreraSedeInput = this.queryCarrera = params["carrera"] || ""
                this.queryInfoAdicionales = JSON.parse(params["infoAdicionales"] || "[]")
                this.areaQuery = params["area"] || ""
                this.modalidadQuery = params["modalidad"] || ""

                if (firstLoad) {
                    this.setInitialValues()
                    firstLoad = false
                }

                if (this.cuiTable) this.cuiTable.changePage(1)
                this.query = true
            })
        })
    }

    setInitialValues() {
        if (this.universidad) {
            const universidadSelected = this.universidades.find(u => u.universidad == this.universidad)

            if (universidadSelected) {
                this.getSedes(universidadSelected.id).then(() => {
                    if (this.sede) {
                        const sedeSelected = this.sedes.find(s => s.sede == this.sede)

                        if (sedeSelected) {
                            this.getFilteredCarrerasSedes()
                        }
                    } else {
                        this.getFilteredCarrerasSedes()
                    }
                })
            }
        }
    }

    getAllUniversidades() {
        const params: any = {}
        if (this.withPais) {
            params["pais"] = this.capitalize.transform(config.plataforma.pais)
        }
        if (config.registro?.universidades && config.registro.universidades.length > 0) {
            params["universidad"] = { universidad: config.registro.universidades }
        }
        return this.universidadesService.where(params).then((universidades: Universidad[]) => {
            this.universidades = universidades
            if (this.universidades.length == 1) {
                this.getSedes(this.universidades[0].id)
            }
        })
    }

    getSedes(universidadId = null) {
        const params = {
            sede: { universidadId: universidadId },
            pais: { pais: this.capitalize.transform(config.plataforma.pais) },
            include: "[lugar:pais]"
        }

        return this.sedesService.where(params).then((sedes: Sede[]) => {
            this.sedes = sedes
            this.showSedes = true
        })
    }

    getFilteredCarrerasSedes() {
        // Esta función puede ser que esté demás (se hace esto mismo en la función setData)
        let params: any = {
            include: "[sede:universidad]",
            pais: {
                pais: this.capitalize.transform(config.plataforma.pais)
            },
            sede: {
                universidadId: this.universidades.map(u => u.id)
            }
        }

        if (this.universidad) {
            params.universidad = { like: { universidad: `%${this.universidad.toString()}%` } }
        }

        if (this.sede) {
            params.sede["like"] = { sede: `%${this.sede.toString()}%` }
        }

        return this.carreraSedesService.wherePost(params).then(carreraSedes => {
            this.filteredCarreraSedes = carreraSedes
        })
    }

    updateSearch() {
        const queryUniversidad = this.universidad ? this.universidad.toString() : ""
        const querySede = this.sede ? this.sede.toString() : ""
        const queryCarrera = this.carreraSedeInput ? this.carreraSedeInput.toString() : ""
        const queryInfoAdicionales = this.queryInfoAdicionales
        const areaQuery = this.areaQuery
        const modalidadQuery = this.modalidadQuery

        this.carrerasBuscadorService.search(
            queryUniversidad,
            querySede,
            queryCarrera,
            areaQuery,
            modalidadQuery,
            queryInfoAdicionales
        )

        const queryParams: any = {}

        if (queryUniversidad) {
            queryParams.universidad = queryUniversidad
        }

        if (querySede) {
            queryParams.sede = querySede
        }

        if (queryCarrera) {
            queryParams.carrera = queryCarrera
        }

        if (queryInfoAdicionales && queryInfoAdicionales.length > 0) {
            queryParams.infoAdicionales = JSON.stringify(queryInfoAdicionales)
        }

        if (areaQuery) {
            queryParams.area = areaQuery
        }

        if (modalidadQuery) {
            queryParams.modalidad = modalidadQuery
        }

        this.router.navigate([], { queryParams })
    }

    setData = (page: number, per: number) => {
        const params: any = {
            include: "[carrera?,preferencia_carrera_sedes?,info_adicionales?,sede:universidad]",
            preferenciaCarreraSede: {
                usuarioId: this.authService.getUserData().id
            },
            sede: {
                universidadId: this.universidades.map(u => u.id)
            },
            pais: {
                pais: this.capitalize.transform(config.plataforma.pais)
            },
            page,
            per
        }

        let someQuery = false

        if (this.queryUniversidad) {
            someQuery = true
            params.universidad = { like: { universidad: `%${this.queryUniversidad}%` } }
        }
        if (this.querySede) {
            someQuery = true
            params.sede["like"] = { sede: `%${this.sede.toString()}%` }
        }
        if (this.queryCarrera) {
            someQuery = true
            params.carreraSede = { like: { alias: `%${this.queryCarrera}%` } }
        }
        if (this.areaQuery) {
            someQuery = true
            params.carreraSede = { ...params.carreraSede, area: this.areaQuery }
        }
        if (this.modalidadQuery) {
            someQuery = true
            params.carreraSede = { ...params.carreraSede, modalidad: this.modalidadQuery }
        }
        if (this.queryInfoAdicionales.length > 0) {
            someQuery = true
            params.infoFilters = JSON.stringify(this.queryInfoAdicionales)
        }

        if (!someQuery) {
            params.random = this.milisecondsTime
        }

        return Promise.all([
            this.carreraSedesService.wherePost(params),
            this.carreraSedesService.wherePostCount(params)
        ]).then(([carreraSedes, total]) => {
            this.carreraSedes = carreraSedes

            return total
        })
    }

    clearSearch() {
        this.query = null
        this.carreraSedes = null
    }

    updateUniversidadDependencias(universidadId) {
        this.getSedes(universidadId)
        this.getFilteredCarrerasSedes()
    }

    onSelectUniversidad(event) {
        this.updateUniversidadDependencias(event.item.id)
    }

    onSelectSede(_) {
        this.getFilteredCarrerasSedes()
    }

    onInputUniversidadChanged(event) {
        let universidad = event.target.value
        let selectedUniversidad = this.universidades.find(u => u.universidad == universidad)

        if (!selectedUniversidad) {
            this.sede = null
            this.showSedes = false
            this.carreraSedeInput = ""
            this.filteredCarreraSedes = []
        } else {
            this.updateUniversidadDependencias(selectedUniversidad.id)
        }
    }

    onInputSedeChanged(event) {
        let sede = event.target.value
        let selectedSede = this.sedes.find(s => s.sede == sede)

        if (!selectedSede) {
            this.carreraSedeInput = ""
            this.filteredCarreraSedes = []
        } else {
            this.getFilteredCarrerasSedes()
        }
    }

    onChangeInfoAdicional(filtro, valor, tipo) {
        if (tipo.toLowerCase() == "area") return (this.areaQuery = valor)
        if (tipo.toLowerCase() == "modalidad") return (this.modalidadQuery = valor)

        const infoAdicionalIndex = this.queryInfoAdicionales.findIndex((ia: any) => ia.infoAdicional == filtro)
        if (infoAdicionalIndex != -1) {
            this.queryInfoAdicionales[infoAdicionalIndex]["valor"] = valor
        } else {
            this.queryInfoAdicionales.push({ infoAdicional: filtro, valor: valor })
        }
    }

    onItemSelectFilter(item: any) {
        const index = this.aditionalFilters.findIndex((x: any) => x.tipo == item.tipo)
        this.aditionalFilters[index]["visible"] = !this.aditionalFilters[index]["visible"]
        if (this.aditionalFilters[index]["value"].lenght != 0) {
            if (item.tipo == "modalidad" || item.tipo == "area") {
                this.carreraSedesService
                    .getValueModalidadArea({ carreraSedes: {}, attr: item.tipo })
                    .then(response => (this.aditionalFilters[index]["value"] = response))
            } else {
                this.infoAdicionalesServices
                    .getValueOfInfoAdicional({ infoAdicional: { infoAdicional: item.filter } })
                    .then(response => {
                        this.aditionalFilters[index]["value"] = response
                    })
            }
            this.showAditionalFilter = true
        }
    }

    onUnselectFilter(item: any) {
        const index = this.aditionalFilters.findIndex((x: any) => x.tipo == item.tipo)
        this.aditionalFilters[index]["visible"] = false
        //this.showAditionalFilter = false
        this.queryInfoAdicionales = this.queryInfoAdicionales.filter((f: any) => f.infoAdicional != item.filter)
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe()
    }
}
