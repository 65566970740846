import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { LibroEvaluacion } from "./libro_evaluaciones.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { ErroresService, AppEnv } from "@puntaje/shared/core"

@Injectable()
export class LibroEvaluaciones extends PuntajeBaseService<LibroEvaluacion> {
    tableName = "libro_evaluaciones"
    singularTableName = "libro_evaluacion"
    modelClass = LibroEvaluacion

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public cargarLibroEvaluaciones() {
        this.restQuery = ""
        return this.concatExtra("cargar_libro_evaluaciones")
    }

    public createWithInstrumento(params: any) {
        return this.all().concatExtra("create_with_instrumento").post(params)
    }
}
