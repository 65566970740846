import { Component, ChangeDetectorRef } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Subscription, combineLatest } from "rxjs"
import { ActivatedRoute, Router } from "@angular/router"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import {
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    State,
    SetSelectedAsignaturaWithId,
    SetSelectedEvaluacionTipo,
    selectEvaluacionTipoAliases
} from "@puntaje/puntaje/store"
import { select, Store } from "@ngrx/store"
import { filter } from "rxjs/operators"
import { AuthService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "ensayos-recomendados",
    templateUrl: "ensayos_recomendados.component.html",
    styleUrls: ["ensayos_recomendados.component.scss"]
})
export class EnsayosRecomendadosComponent {
    asignaturaId: number
    tipoInstrumento: string
    titulo: string
    asignatura: Asignatura

    subQuery: Subscription
    subAlias: Subscription
    subData: Subscription
    evaluacionTipoAlias: string
    evaluacionTipos: string[] = []

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(
        protected titleService: TitleService,
        protected cdr: ChangeDetectorRef,
        protected route: ActivatedRoute,
        protected router: Router,
        protected store: Store<State>,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.ensayos_recomendados")
        this.subQuery = combineLatest(this.asignatura$, this.evaluacionTipo$).subscribe(
            ([asignatura, evaluacionTipo]) => {
                this.asignatura = asignatura
                this.asignaturaId = asignatura.id
                this.tipoInstrumento = evaluacionTipo
            }
        )

        this.subAlias = this.evaluacionTipoAliases$.subscribe(evaluacionTipoAliases => {
            this.evaluacionTipoAlias = evaluacionTipoAliases[this.tipoInstrumento]
        })

        this.subData = this.route.data.subscribe(data => {
            this.evaluacionTipos = data.evaluacionTipos ?? this.evaluacionTipos
            this.titulo = data.titulo ?? this.titulo
        })
    }

    ngOnDestroy() {
        this.subQuery.unsubscribe()
        this.subAlias.unsubscribe()
    }

    onSelectAsignatura(asignaturaId: number) {
        this.asignaturaId = asignaturaId
        // this.router.navigate(["ensayos", "recomendados"], {
        //     queryParams: { asignatura_id: asignaturaId, tipo_instrumento: this.tipoInstrumento }
        // })
        // this.cdr.detectChanges()
    }
}
