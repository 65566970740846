<div *ngIf="comentarios">
    <comentario
        *ngFor="let comentario of comentarios; let i = index"
        [comentario]="comentario"
        [hidden]="i > 3 ? !_showAll : false"
        (editDone)="onSaveDoneListener($event)"
    ></comentario>
    <div class="clearfix comment-options">
        <a *ngIf="!_showAll && comentarios.length > 3" (click)="showAll()" class="pointer">
            {{ "dudas.comentarios_list.mostrar_todos" | t }}
            <fa name="comments"></fa>
        </a>
        <a *ngIf="_showAll && comentarios.length > 3" (click)="showAll()" class="pointer">
            {{ "dudas.comentarios_list.ocultar" | t }}
        </a>
        <!--<a (click)="toggleCommentNew()" class="pointer pull-right">Agregar comentario<fa name="reply"></fa></a>-->
    </div>
</div>
<div #comentarioNuevo>
    <comentario-new
        *ngIf="showCommentNew"
        [dudaRespuestaId]="dudaRespuestaId"
        (onSaveDone)="onSaveDoneListener($event)"
        (onCancel)="onCancelListener($event)"
    ></comentario-new>
</div>
