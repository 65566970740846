import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core"
import {
    Clasificacion,
    Clasificaciones,
    ClasificacionTipo,
    ClasificacionTipos,
    Asignatura,
    Asignaturas
} from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"

@Component({
    selector: "filtros-clasificaciones-evaluacion-tipo",
    templateUrl: "./filtros-clasificaciones-evaluacion-tipo.component.html",
    styleUrls: ["./filtros-clasificaciones-evaluacion-tipo.component.scss"]
})
export class FiltrosClasificacionesEvaluacionTipoComponent implements OnChanges {
    asignatura: Asignatura
    @Input() evaluacionTipo: string
    @Input() asignaturaId: number
    @Input() preloadedClasificaciones =  {}
    @Output() changeFilter: EventEmitter<number[]> = new EventEmitter<number[]>()

    cts: any = {}
    updateOptions: any

    clasificacionTiposByTipoInstrumento = {
        "prueba de transición": [
            "curso",
            "nivel 1 prueba de transición",
            "nivel 2 prueba de transición",
            "nivel 3 prueba de transición",
            "habilidad prueba de transición"
        ],
        curricular: ["curso", "eje temático LGE", "objetivo de aprendizaje", "habilidad cognitiva LGE"],
        "curricular colombia": ["curso colombia"],
        saber11: ["competencia", "componente"],
        "curricular mexico": ["Curso MX", "Bloque_mx", "Temas_mx", "Subtemas_mx"]
    }

    clasificacionTiposAsignaturasByTipoInstrumento = {
        paes: {
            Matemática: ["Eje PAES", "Unidad Temática PAES"],
            Lenguaje: ["Eje PAES"],
            "Historia y Ciencias Sociales": ["Eje PAES", "Unidad Temática PAES"],
            "Ciencias Naturales": ["Eje PAES", "Unidad Temática PAES", "Tema PAES"]
        }
    }

    arbolDependencias = {
        "prueba de transición": {
            curso: null,
            "nivel 1 prueba de transición": {
                "nivel 2 prueba de transición": {
                    "nivel 3 prueba de transición": null
                }
            },
            "habilidad prueba de transición": {}
        },
        curricular: {
            curso: {
                "eje temático LGE": {
                    "objetivo de aprendizaje": null
                },
                "habilidad cognitiva LGE": null
            }
        },
        "curricular colombia": {
            "curso colombia": null
        },
        "curricular mexico": {
            curso: {
                Bloque_mx: {
                    Temas_mx: {
                        Subtemas_mx: null
                    }
                }
            }
        },
        paes: {
            "Eje PAES": {
                "Unidad Temática PAES": {
                    "Tema PAES": null
                }
            }
        },
        saber11: {
            curso: {
                componente: null,
                competencia: null
            },
            componente: null,
            competencia: null
        }
    }

    clasificacionesByTipo: { [key: string]: Clasificacion[] } = {}
    clasificacionSelectedByTipo: { [key: string]: Clasificacion } = {}
    clasificacionTipoById: { [id: number]: ClasificacionTipo } = {}

    loadedFiltros = false

    isExpanded = false

    controlNameByClasificacionTipo = {
        curso: "curso_id",
        "nivel 1 prueba de transición": "nivel1_id",
        "nivel 2 prueba de transición": "nivel2_id",
        "nivel 3 prueba de transición": "nivel3_id",
        "habilidad prueba de transición": "habilidad_pdt_id",
        "eje temático LGE": "eje_id",
        "objetivo de aprendizaje": "oa_id",
        "habilidad cognitiva LGE": "hab_cog_id",
        componente: "componente_id",
        competencia: "compotencia_id",
        "curso colombia": "curso_id",
        Bloque_mx: "bloque_mx_id",
        Temas_mx: "tema_mx_id",
        Subtemas_mx: "subtema_mx_id",
        "Eje PAES": "eje_id",
        "Unidad Temática PAES": "unidad_tematica_id",
        "Tema PAES": "tema_id"
    }

    resetOptions = {}

    constructor(
        protected clasificacionTiposService: ClasificacionTipos,
        protected clasificacionesService: Clasificaciones,
        protected asignaturasService: Asignaturas,
        protected config: AppConfig,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.setResetOptions()
    }

    setResetOptions() {
        this.setResetOptionsArbol(this.arbolDependencias[this.evaluacionTipo])
    }

    setResetOptionsArbol(arbol: any) {
        if (!arbol) return

        for (let [clasificacionTipo, deepArbol] of Object.entries(arbol)) {
            this.setResetOptionsArbol(deepArbol)

            let controls = []
            let fields = []

            if (deepArbol) {
                fields = Object.keys(deepArbol)
                    .map(ct => {
                        return this.resetOptions[ct].controls
                    })
                    .flat()

                controls = [Object.keys(deepArbol), fields].flat()
            }

            this.resetOptions[clasificacionTipo] = {
                controls: controls,
                fields: fields
            }
        }
    }

    setUpdateOptionsArbol(arbol) {
        if (!arbol) return

        for (let [clasificacionTipo, deepArbol] of Object.entries(arbol)) {
            this.setUpdateOptionsArbol(deepArbol)

            if (deepArbol) {
                this.updateOptions.onUpdate[clasificacionTipo] = Object.keys(deepArbol).reduce((acc, ct) => {
                    acc[ct] = this.cts[ct]
                    return acc
                }, {})
            }
        }
    }

    setUpdateOptions(clasificacionTipos) {
        for (let clasificacionTipo of clasificacionTipos) {
            this.cts[clasificacionTipo.clasificacion_tipo] = clasificacionTipo.id
        }

        this.updateOptions = {
            clasificaciones: Object.keys(this.arbolDependencias[this.evaluacionTipo]),
            onUpdate: {}
        }

        this.setUpdateOptionsArbol(this.arbolDependencias[this.evaluacionTipo])
    }

    async getFiltros() {
        this.loadedFiltros = false
        this.isExpanded = false
        this.clasificacionSelectedByTipo = {}

        const tipo = this.evaluacionTipo || this.config.plataforma.evaluacionDefault
        let clasificacionTipoNombres = this.clasificacionTiposByTipoInstrumento[tipo]
        if (!clasificacionTipoNombres) {
            if (!this.asignatura || this.asignatura.id !== this.asignaturaId)
                await this.asignaturasService.find(this.asignaturaId).then(asignatura => {
                    this.asignatura = asignatura
                })
            const asignaturaName = this.asignatura.asignatura
            clasificacionTipoNombres = this.clasificacionTiposAsignaturasByTipoInstrumento[tipo][asignaturaName]
        }

        if (clasificacionTipoNombres) {
            const clasificacionTipos = await this.clasificacionTiposService.where({
                clasificacion_tipo: { clasificacion_tipo: clasificacionTipoNombres }
            })

            this.setUpdateOptions(clasificacionTipos)

            this.clasificacionTipoById = clasificacionTipos.reduce((acc, ct) => {
                acc[ct.id] = ct

                return acc
            }, {})

            const clasificacionTipoIniciales = clasificacionTipoNombres.filter(clasificacionTipoNombre => {
                return this.updateOptions.clasificaciones.includes(clasificacionTipoNombre)
            })

            let clasificaciones = await this.clasificacionesService.where({
                clasificacion_tipo: {
                    clasificacion_tipo: clasificacionTipoIniciales
                },
                clasificacion: {
                    asignatura_id: this.asignaturaId
                },
                raw: 1,
                render_options: {
                    only: ["id", "clasificacion_tipo_id", "clasificacion"]
                }
            })

            const clasificacionesCursos = await this.clasificacionesService.where({
                clasificacion_tipo: {
                    clasificacion_tipo: clasificacionTipoNombres.filter(ctn => ctn.toLowerCase().indexOf("curso") >= 0)
                },
                raw: 1,
                render_options: {
                    only: ["id", "clasificacion_tipo_id", "clasificacion"]
                }
            })

            clasificaciones = clasificaciones.concat(clasificacionesCursos)

            this.clasificacionesByTipo = clasificacionTipoNombres.reduce((acc, clasificacionTipo) => {
                acc[clasificacionTipo] = []

                return acc
            }, {})

            clasificaciones.forEach(clasificacion => {
                const clasificacion_tipo =
                    this.clasificacionTipoById[clasificacion.clasificacion_tipo_id].clasificacion_tipo

                this.clasificacionesByTipo[clasificacion_tipo].push(clasificacion)
            })

            this.clasificacionSelectedByTipo = {}

            this.loadedFiltros = true
        }
    }

    onChangeFilter() {
        this.changeFilter.emit(
            Object.values(this.clasificacionSelectedByTipo)
                .filter(c => !!c)
                .map(c => c.id)
        )

        this.cdr.detectChanges()
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"] || changes["tipoInstrumento"]) {
            this.setResetOptions()
            await this.getFiltros()
        }
        if (changes["preloadedClasificaciones"]) {
            const value = changes["preloadedClasificaciones"]
            Object.keys(value.currentValue).forEach(k => {
                (this.clasificacionesByTipo[k] || []).forEach(v => {
                    if (v.id == parseInt(this.preloadedClasificaciones[k])) {
                        this.clasificacionSelectedByTipo[k] = v
                        this.updateClasificacion(k)
                    }
                })
            })
            this.cdr.detectChanges()
        }
    }

    updateClasificacion(tipo) {
        const value = this.clasificacionSelectedByTipo[tipo]
        const clasificacionId = value ? value.id : value

        if (value) {
            const options = this.updateOptions

            if (options && options.onUpdate[tipo]) {
                Object.keys(options.onUpdate[tipo]).forEach(nameVar => {
                    const params: any = {
                        clasificacion: {
                            asignatura_id: this.asignaturaId,
                            clasificacion_tipo_id: options.onUpdate[tipo][nameVar]
                        },
                        clasificacion_clasificaciones: {
                            clasificacion_id: clasificacionId
                        }
                    }

                    this.clasificacionesService.where(params).then((clasificaciones: Clasificacion[]) => {
                        this.clasificacionesByTipo[nameVar] = clasificaciones
                    })
                })
            }
        }

        if (this.resetOptions[tipo]) {
            this.resetOptions[tipo].controls.forEach(field => {
                if (this.clasificacionSelectedByTipo[field]) this.clasificacionSelectedByTipo[field] = undefined
            })

            this.resetOptions[tipo].fields.forEach(field => {
                this.clasificacionesByTipo[field] = []
            })
        }

        this.onChangeFilter()
    }
}
