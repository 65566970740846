import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { DudaRespuestas, DudaRespuestaForm, DudaRespuesta } from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { CheckAchievementAsync, CheckActividadAsync } from "@puntaje/shared/core"
import { Store } from "@ngrx/store"
import { State, CrearAlertaLogros } from "@puntaje/puntaje/store"

@Component({
    selector: "duda-respuesta-new",
    templateUrl: "./duda_respuesta_form.component.html",
    styleUrls: ["./duda_respuesta_form.component.scss"]
})
export class DudaRespuestaNewComponent implements OnInit {
    @Input() dudaId: number
    @Output() save: EventEmitter<any> = new EventEmitter()
    @Output() cancelReply: EventEmitter<any> = new EventEmitter<any>()

    form: UntypedFormGroup
    params = DudaRespuestaForm.formParams
    dudaRespuesta: DudaRespuesta

    loaded: boolean = false
    edit: boolean = false

    constructor(protected dudaRespuestasService: DudaRespuestas, protected store: Store<State>) {}

    ngOnInit() {
        this.dudaRespuesta = new DudaRespuesta()
        this.form = DudaRespuestaForm.getForm(this.dudaRespuesta)
        this.loaded = true
    }

    @CheckAchievementAsync("PN_COMUNIDAD_DUDAS_RESPONDIDAS", CrearAlertaLogros)
    @CheckActividadAsync("RD")
    responder() {
        this.dudaRespuesta.duda_id = this.dudaId

        return this.dudaRespuestasService.save(this.dudaRespuesta).then((dudaRespuesta: DudaRespuesta) => {
            this.save.emit()

            return dudaRespuesta
        })
    }

    cancelar() {
        this.cancelReply.emit()
    }
}
