import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from "@angular/forms"
import { Injector } from "@angular/core"
import { BaseModel } from "./base.model"
import { Validations, GroupValidator } from "../form/validations"
import { enumerable } from "../util/decorators"

export class BaseForm {
    @enumerable(false)
    public static get formParams(): any {
        throw new Error("Not Implemented")
    }

    public static get globalValidations(): GroupValidator[] {
        return null
    }

    public static get updateOn(): "change" | "blur" | "submit" {
        return "change"
    }
    static injector: Injector

    public static getForm(model: BaseModel, formParams?: any, injector?: Injector) {
        const params = formParams || this.formParams
        const globalValidations = this.globalValidations

        const group: { [key: string]: UntypedFormControl | UntypedFormArray } = {}

        for (const key in params) {
            if (!params.hasOwnProperty(key)) {
                continue
            }
            if (params[key].type == "model") {
                group[key] = params[key].class.getForm(model[key], null, injector)
            } else if (params[key].type == "models") {
                group[key] = new UntypedFormArray(
                    model[key].map(model => params[key].class.getForm(model, null, injector))
                )
            } else {
                let serviceValidations = []
                if (params[key].serviceValidators && injector) {
                    serviceValidations = params[key].serviceValidators.map(sv => {
                        const service = injector.get(sv.class)

                        return sv.validator(service)
                    })
                }
                let validations = []
                if (params[key].validations) {
                    validations = params[key].validations
                }
                group[key] = new UntypedFormControl(model[key] ?? "", validations, serviceValidations)
            }

            if (params[key].global) {
                group[key]["global"] = params[key].global
            }
        }

        return new UntypedFormGroup(group, {
            validators: Validations.validateAll(globalValidations),
            updateOn: this.updateOn
        })
    }

    public static markFormControlsAsTouched(form: UntypedFormGroup | UntypedFormArray) {
        for (const key in form.controls) {
            if (form.controls[key] instanceof UntypedFormGroup || form.controls[key] instanceof UntypedFormArray) {
                this.markFormControlsAsTouched(form.controls[key])
            } else {
                form.controls[key].markAsTouched()
            }
        }
    }

    public static markFormControlsAsPristine(form: UntypedFormGroup | UntypedFormArray) {
        for (const key in form.controls) {
            if (form.controls[key] instanceof UntypedFormGroup || form.controls[key] instanceof UntypedFormArray) {
                this.markFormControlsAsPristine(form.controls[key])
            } else {
                form.controls[key].markAsPristine()
            }
        }
    }

    public static markFormControlsAsDirty(form: UntypedFormGroup | UntypedFormArray) {
        for (const key in form.controls) {
            if (form.controls[key] instanceof UntypedFormGroup || form.controls[key] instanceof UntypedFormArray) {
                this.markFormControlsAsDirty(form.controls[key])
            } else {
                form.controls[key].markAsDirty()
            }
        }
    }

    public static markFormControlsAsUntouched(form: UntypedFormGroup | UntypedFormArray) {
        for (const key in form.controls) {
            if (form.controls[key] instanceof UntypedFormGroup || form.controls[key] instanceof UntypedFormArray) {
                this.markFormControlsAsUntouched(form.controls[key])
            } else {
                form.controls[key].markAsUntouched()
            }
        }
    }
}
