<div *ngIf="item" class="duda-marco">
    <div [ngClass]="{ 'duda-item': !expanded }">
        <div *ngIf="material">
            <material-preview [material]="material"></material-preview>
        </div>
        <div *ngIf="pregunta">
            <loading-layout #hackxorLayout *ngIf="!showPreguntas"></loading-layout>
            <div [class.show-preguntas]="showPreguntas">
                <ver-pregunta [pregunta]="pregunta"></ver-pregunta>
            </div>
        </div>
    </div>
    <cui-button (click)="expand()" class="btn_style btn-expand">
        <span *ngIf="!expanded">
            <fa name="expand"></fa>
            &nbsp;{{ "dudas.duda_item.expandir" | t }}
        </span>
        <span *ngIf="expanded">
            <fa name="compress"></fa>
            &nbsp;{{ "dudas.duda_item.contraer" | t }}
        </span>
    </cui-button>
</div>
