import { Directive, Input, Renderer2, ElementRef } from "@angular/core"

@Directive({
    selector: "[focused]"
})
export class FocusedDirective {
    @Input()
    set focused(value: boolean) {
        if (value && this.elementRef.nativeElement.scrollIntoViewIfNeeded) {
            this.elementRef.nativeElement.scrollIntoViewIfNeeded()
            //this.renderer.invokeElementMethod(this.elementRef.nativeElement, 'scrollIntoViewIfNeeded');
        }
    }

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
}
