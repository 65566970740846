<div class="main">
    <div class="left-data" [class.processed]="perfil === 'profesor' && data && state >= State.Processed">
        <!-- [class.data-processed]="data && state >= State.Processed" -->
        <div class="canvas-container">
            <video class="canvas" #player controls muted autoplay playsinline></video>
            <!-- <canvas #canvas class="canvas" ></canvas>
            <canvas #buffer></canvas> -->
            <canvas #finalImageCanvasRef id="finalImageCanvasRef" class="canvas"></canvas>
            <canvas class="foreground-video" #markersCanvasChild id="markersCanvasChild" (click)="onClickCanvas($event)"></canvas>
        </div>
    
        <div class="tutorial" *ngIf="perfil == 'profesor' && state >= State.Processed">
            <div class="tutorial-image">
                <div class="tutorial-image-pos">
                    <div class="tutorial-image-cell">
                        <img
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/correccion_tutorial_3.png"
                            alt="Revisar respuestas"
                        />
                    </div>
                </div>
            </div>
            <h3 class="step">{{ "subir_respuestas_imagen_multiple.titulo_step_2" | t }}</h3>
            <p class="tutorial-text">
                {{ "subir_respuestas_imagen_multiple.desc_step_2" | t }}
            </p>
            <div class="clearfix">
                <button
                    type="button"
                    class="next btn btn-default btn-sm"
                    href="#resultados"
                    easyScrollTo
                    [easyScrollToPadding]="0"
                    [easyScrollToDuration]="800"
                    [easyScrollToEasing]="'easeInOut'"
                >
                    {{ "subir_respuestas_imagen_multiple.siguiente" | t }}&nbsp;
                    <fa name="caret-right"></fa>
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="perfil === 'profesor' && data" class="right-data" id="resultados">
        <div class="resultados-container">
            <h3>{{ "subir_respuestas_imagen_multiple.resultados" | t }}</h3>
            <div class="forma datos" [class.wrong-data]="data.forma.indexOf(',') != -1">
                <label>{{ "subir_respuestas_imagen_multiple.forma" | t }}</label>
                &nbsp;
                <subir-respuestas-valor-feedback
                    *ngIf="state != State.Editing"
                    [valor]="data.forma"
                    class="valor"
                ></subir-respuestas-valor-feedback>
                <input
                    type="text"
                    *ngIf="state == State.Editing"
                    [(ngModel)]="data.forma"
                    class="valor edit-forma form-control"
                />

                <cui-button class="btn_style pull-right btn-sm" id="btn-enable-edit" *ngIf="state != State.Editing" (click)="enableEditObj()">
                    <fa name="pencil"></fa>
                    {{ "subir_respuestas_imagen_multiple.editar" | t }}
                </cui-button>
                <cui-button class="btn_style pull-right btn-sm" id="btn-disable-edit" *ngIf="state == State.Editing" (click)="disableEditObj()">
                    <fa name="pencil"></fa>
                    {{ "subir_respuestas_imagen_multiple.listo" | t }}
                </cui-button>
            </div>
            <div *ngIf="data.formaType">
                <label>{{ "subir_respuestas_imagen_multiple.tipo_forma" | t }}</label>
                &nbsp;
                <subir-respuestas-valor-feedback
                    *ngIf="state != State.Editing"
                    [valor]="data.formaType"
                    class="valor"
                ></subir-respuestas-valor-feedback>
                <input
                    type="text"
                    *ngIf="state == State.Editing"
                    [(ngModel)]="data.formaType"
                    class="valor edit-forma form-control"
                />
            </div>
            <div class="rut datos" >
                <label>{{ identificadorUsuario }}:</label>
                &nbsp;
                <subir-respuestas-valor-feedback
                    *ngIf="state != State.Editing"
                    [valor]="data.idValue.join('-')"
                    class="valor"
                ></subir-respuestas-valor-feedback>

                <ng-container *ngFor="let part of data.idValue; let i = index; trackBy: getIndex">
                    <input
                        type="text"
                        *ngIf="state == State.Editing"
                        [(ngModel)]="data.idValue[i]"
                        class="valor edit-id form-control"
                    />
                    <ng-container *ngIf="i != data.idValue.length - 1 && state == State.Editing">-</ng-container>
                </ng-container>
            </div>

            <div class="multiple-col">
                <ul class="lista-resultados">
                    <li *ngFor="let respuestaIndex of data.respuestas | keys" class="respuesta">
                        <span class="numero">{{ parseInt(respuestaIndex) + 1 }}:</span>
                        &nbsp;
                        <subir-respuestas-valor-feedback
                            *ngIf="state != State.Editing"
                            [valor]="data.respuestas[respuestaIndex] | easyplaceholder: '-'"
                            class="valor"
                        ></subir-respuestas-valor-feedback>

                        <input
                            type="text"
                            *ngIf="state == State.Editing"
                            [(ngModel)]="data.respuestas[respuestaIndex]"
                            class="valor edit-respuesta form-control"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>  
</div>

<ng-container *ngIf="state == State.Uploaded">
    <div class="warning-content">
        <div class="warning-message clearfix">
            <ng-container *ngIf="uploadMsg.warnings && uploadMsg.warnings.length != 0">
                <p class="alert alert-success">
                    La operación se completó con advertencias. La hoja no pudo cargarse por uno o más errores:
                </p>
                <p class="alert alert-warning">{{ uploadMsg.warnings }}</p>
            </ng-container>
            <ng-container *ngIf="!uploadMsg.warnings || uploadMsg.warnings.length == 0">
                <p *ngIf="showMensaje">
                    {{ mensaje }}
                </p>

                <p *ngIf="!showMensaje" class="alert alert-success">Resultados registrados satisfactoriamente.</p>
                <dl class="dl-horizontal" *ngIf="!showMensaje">
                    <dt>Identificador Usuario</dt>
                    <dd>{{ data.idValue }}</dd>
                    <dt>Forma</dt>
                    <dd>{{ data.forma }}</dd>
                </dl>
            </ng-container>
            <ng-container *ngIf="uploadMsg.instancia_id">
                <a (click)="verResultados(uploadMsg.instancia_id)">Ver Resultados</a>
            </ng-container>
            <cui-button type="button" class="btn_style pull-right" (click)="startRecording()">
                <fa name="refresh"></fa>
                &nbsp;{{ uploadMsg.warnings ? "Reintentar" : "Subir más resultados" }}
            </cui-button>
        </div>
    </div>
</ng-container>

<div class="boton-subir">
    <cui-button
        *ngIf="state == State.Processed"
        type="button"
        class="btn_style pull-right"
        (click)="verificaExisteEval()"
    >
        <fa name="refresh"></fa>
        &nbsp;Subir
    </cui-button>
</div>

<div class="modal fade" bsModal #detectingModal="bs-modal" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <loading-layout [noMarginY]="true" class="loading"></loading-layout>
                <p class="loader-msg">La hoja está siendo procesada.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" bsModal #detectionModal="bs-modal">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">La hoja ha sido detectada exitosamente.</div>

            <div class="modal-footer">
                <cui-button type="button" class="btn_style" (click)="onCloseDetectionModal()">Continuar</cui-button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" bsModal #loadingModal="bs-modal" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <loading-layout [noMarginY]="true" class="loading"></loading-layout>
                <p class="loader-msg">La hoja está siendo guardada.</p>
            </div>
        </div>
    </div>
</div>
