import { BaseModel, SaveType } from "@puntaje/shared/core"
import { TipoAudiovisual } from "./tipo_audiovisuales.model"

export class Audiovisual extends BaseModel {
    public static className: string = "Audiovisual"

    public tipoAudiovisualId: number
    public carreraSedeId: number
    public url: string

    public audiovisualTipoNombre: string

    @SaveType(() => TipoAudiovisual) tipoAudiovisual: TipoAudiovisual

    public getExtension() {
        return this.url.split(".").pop()
    }
}
