import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, HttpVerbs, AppEnv, AppConfig } from "@puntaje/shared/core"
import { Nomina } from "./nominas.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

declare const config: AppConfig

@Injectable()
export class Nominas extends PuntajeBaseService<Nomina> {
    tableName = "nominas"
    singularTableName = "nomina"
    modelClass = Nomina
    defaultNominas = {
        alumnos: {
            chile: "NominaAlumnoChile",
            colombia: "NominaAlumnoColombia",
            mexico: "NominaAlumnoMexico",
            brasil: "NominaAlumnoBrasil"
        },
        profesores: {
            chile: "NominaProfeChile",
            colombia: "NominaProfePuntajeColombia",
            mexico: "NominaProfeMexico"
        }
    }

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public save_nomina_alumnos(objectQuery: Nomina) {
        if (this.canConcat && !this.withParams) {
            this.objectQuery = new Nomina()
            this.objectQuery[this.singularTableName] = objectQuery.clone()
            this.restQuery += "/cargar_nomina_alumnos"
            this.canConcat = false
            this.httpVerb = HttpVerbs.POST
        } else {
            throw new SyntaxError(
                "Imposible concatenar una llamada POST (save) a una llamada " + HttpVerbs[this.httpVerb]
            )
        }
        return this
    }

    public cargarNomina(subplataforma: "alumnos" | "profesores") {
        const nomina = config.cargaNomina?.nomina?.[subplataforma]
            ? config.cargaNomina.nomina[subplataforma]
            : this.defaultNominas[subplataforma][config.plataforma.pais]

        this.restQuery = ""

        return this.concatExtra("/cargar_nomina?nomina_type=" + nomina)
    }

    public plantillaNomina(subplataforma: "alumnos" | "profesores") {
        const nomina = config.cargaNomina?.nomina?.[subplataforma]
            ? config.cargaNomina.nomina[subplataforma]
            : this.defaultNominas[subplataforma][config.plataforma.pais]

        this.restQuery = ""

        this.enableIgnoreModel()

        return this.concatExtra("/plantilla_nomina")
            .get({ nomina: nomina })
            .then((response: any) => {
                this.disableIgnoreModel()

                return response
            })
    }
}
