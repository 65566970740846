import { BaseModel } from "@puntaje/shared/core"

export class Persona extends BaseModel {
    public static className: string = "Persona"
    public id: number
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public email: string
    public created_at: Date
    public updated_at: Date

    nombre_completo: string

    public toString() {
        return this.nombre + " " + this.apellido_paterno
    }
}
