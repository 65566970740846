import { BaseForm, Validations, GroupValidator, AppConfig } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Usuarios } from "./usuarios.service"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"

import { UsuariosCompletarRegistroChileForm } from "./usuarios_completar_registro_chile.form"
import { UsuariosCompletarRegistroChilePuntajeForm } from "./usuarios_completar_registro_chile_puntaje.form"
import { UsuariosCompletarRegistroColombiaPuntajeForm } from "./usuarios_completar_registro_colombia_puntaje.form"
import { UsuariosCompletarRegistroMexicoPuntajeForm } from "./usuarios_completar_registro_mexico_puntaje.form"
import { HttpClient } from "@angular/common/http"
import { Areas } from "@puntaje/carreras/api-services"

declare const config: AppConfig

export class UsuariosCompletarRegistroPuntajeForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    companyName: string = ""

    public static get formParams(): any {
        const companyName = config.plataforma.info.companyName

        let params: any = {
            id: { label: "ID", type: "hidden", visible: false },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmailNotSelf }]
            },
            password: {
                label: "Contraseña",
                type: "password",
                visible: true,
                validations: [Validators.minLength(8)],
                serviceValidators: [{ class: HttpClient, validator: Validations.checkPasswordBreach }]
            },
            password_confirmation: {
                label: "Confirmar contraseña",
                type: "password",
                visible: true,
                global: true,
                validations: [Validators.required, Validators.minLength(8)]
            },
            terminos_y_condiciones: {
                innerhtml:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de " +
                    companyName +
                    "</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            },
            egresado: { label: "Egresado", type: "checkbox", visible: true },
            colegio: { label: "Establecimiento", type: "text", visible: true },
            especialidad: {
                label: "Especialidad",
                type: "select",
                visible: true,
                options: {
                    class: Clasificaciones,
                    params: {
                        clasificacion_tipo: { clasificacion_tipo: "Especialidad TP" },
                        sort_by: "clasificaciones.clasificacion",
                        order: "asc"
                    }
                },
                validations: [config.registro?.enableEspecialidad ? Validators.required : null].filter(x => !!x)
            },
            nivel_id: {
                label: "Curso",
                type: "select",
                visible: true,
                options: {
                    class: Clasificaciones,
                    params: {
                        clasificacion_tipo: { clasificacion_tipo: config.plataforma.clasificacionTipoNivel || "curso" }
                    }
                }
            },
            area_id: {
                label: "Área de interés",
                type: "select",
                visible: true,
                options: { class: Areas, params: {} },
                validations: config.registro?.completarRegistro?.enableAreas ? [Validators.required] : null
            }
        }

        let classes = {
            chile: UsuariosCompletarRegistroChilePuntajeForm,
            colombia: UsuariosCompletarRegistroColombiaPuntajeForm,
            mexico: UsuariosCompletarRegistroMexicoPuntajeForm
        }

        params["usuario_" + config.plataforma.pais] = { type: "model", class: classes[config.plataforma.pais] }

        if (config.registro && config.registro.enablePoliticasPrivacidad) {
            params["politicas_privacidad"] = {
                innerhtml:
                    "He leído y acepto las <a href='/politicas_privacidad_y_proteccion_datos' target='_blank'>políticas de privacidad y protección de datos personales</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar las políticas de privacidad y protección de datos personales"
            }
        }

        return params
    }
}
