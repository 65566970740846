import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PlanEstudioSesionAplicadaInstancia } from "./plan_estudio_sesion_aplicada_instancias.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class PlanEstudioSesionAplicadaInstancias extends PuntajeBaseService<PlanEstudioSesionAplicadaInstancia> {
    tableName = "plan_estudio_sesion_aplicada_instancias"
    singularTableName = "plan_estudio_sesion_aplicada_instancia"
    modelClass = PlanEstudioSesionAplicadaInstancia

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
