<div class="contenido" *ngIf="!enableItsNotTimeYet && !unpermitted">
    <loading-layout #loadingLayout>
        <div *ngIf="!loadingLayout.loading">
            <div *ngIf="!disableWarningOmitidas && !evaluacion.instrumento.propio" class="warning-omitidas">
                <fa name="warning" class="warning-icon"></fa>
                {{ "preguntas_ensayo.warning_init" | t }}
            </div>
            <div *ngIf="enableTimeframeAlert && timeframeAlert" class="warning-timeframe">
                <fa name="clock-o" class="warning-icon"></fa>
                <p>
                    {{ "preguntas_ensayo.warning_time" | t }}
                </p>
                <p class="info">
                    <span>
                        <b>{{ "preguntas_ensayo.inicio" | t }}:</b>
                        {{ evaluacion.fecha_inicio | date: "dd/MM/yyyy HH:mm" }}
                    </span>
                    &nbsp;
                    <span>
                        <b>{{ "preguntas_ensayo.termino" | t }}:</b>
                        {{ evaluacion.fecha_termino | date: "dd/MM/yyyy HH:mm" }}
                    </span>
                    &nbsp;
                    <span>
                        <b>{{ "preguntas_ensayo.actual" | t }}:</b>
                        {{ dateNow | date: "dd/MM/yy HH:mm" }}
                    </span>
                </p>
            </div>
            <div
                class="clearfix btns-container"
                *ngIf="displayPreguntas && displayBtnEntregarEnsayo && !evaluacion.instrumento.propio"
            >
                <cui-button
                    type="button"
                    class="btn_style pull-right"
                    (click)="entregarEnsayo(true)"
                    [disabled]="btnEntregarEnsayoDisabled"
                >
                    <ng-container *ngIf="btnEntregarEnsayoDisabled">
                        <ng-container *ngIf="enableEntregarGeneric">
                            {{ "preguntas_ensayo.completar_evaluacion" | t }}...
                        </ng-container>
                        <ng-container *ngIf="!enableEntregarGeneric">
                            <ng-container *ngIf="!testlet">
                                {{ "preguntas_ensayo.completar" | t }}
                                {{ tipoInstrumentoAlias | transformInstrumento: "singular" }}...
                            </ng-container>
                            <ng-container *ngIf="testlet">{{ "preguntas_ensayo.siguiente" | t }}...</ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!btnEntregarEnsayoDisabled">
                        <ng-container *ngIf="enableEntregarGeneric">
                            {{ "preguntas_ensayo.completar_evaluacion" | t }}
                        </ng-container>
                        <ng-container *ngIf="!enableEntregarGeneric">
                            <ng-container *ngIf="!testlet">
                                {{ "preguntas_ensayo.completar" | t }}
                                {{ tipoInstrumentoAlias | transformInstrumento: "singular" }}
                            </ng-container>
                            <ng-container *ngIf="testlet">
                                {{ "preguntas_ensayo.siguiente" | t }}
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </cui-button>
            </div>

            <div class="clearfix btns-container">
                <sticky-top>
                    <instrucciones-fijas
                        class="pull-right"
                        *ngIf="evaluacion"
                        [evaluacion]="evaluacion"
                    ></instrucciones-fijas>
                </sticky-top>
            </div>

            <div
                class="clearfix btns-container"
                *ngIf="displayPreguntas && displayBtnEntregarEnsayo && evaluacion.instrumento.propio"
            >
                <cui-button
                    type="button"
                    class="btn_style pull-right"
                    (click)="entregarEnsayo(true)"
                    [disabled]="btnEntregarEnsayoDisabled"
                >
                    <ng-container *ngIf="btnEntregarEnsayoDisabled">
                        {{ "preguntas_ensayo.entregando_evaluacion" | t }} ...
                    </ng-container>
                    <ng-container *ngIf="!btnEntregarEnsayoDisabled">
                        {{ "preguntas_ensayo.entregar_evaluacion" | t }}
                    </ng-container>
                </cui-button>
            </div>
            <loading-layout #secondLoadingLayout *ngIf="!displayPreguntas"></loading-layout>
            <div
                id="preguntas"
                #preguntasDiv
                class="preguntas-container"
                [class.show-preguntas-container]="displayPreguntas"
                *ngIf="!evaluacion.instrumento.propio"
            >
                <ng-container *ngFor="let pregunta of preguntas$ | async; let i = index">
                    <div
                        class="instruccion"
                        *ngIf="pregunta.generador_instrumento_instrucciones[0]?.instruccion"
                        [innerHTML]="pregunta.generador_instrumento_instrucciones[0].instruccion.instruccion"
                    ></div>
                    <!--revisar si se renderea todo al cambiar uno-->
                    <pregunta-ensayo
                        [pregunta]="pregunta"
                        [index]="i"
                        [grupoPreguntaPrev]="preguntas[i - 1]?.grupo_pregunta"
                        [grupoPreguntaNext]="preguntas[i + 1]?.grupo_pregunta"
                        [enableDudaBtn]="enableDudasBtn"
                        [enableReporte]="enableReporte"
                        [enablePreguntaId]="enablePreguntasId"
                        [evaluacionTipo]="tipoEvaluacion"
                        [disableOmitida]="disableOmitida"
                        [generadorInstrumento]="evaluacion.instrumento.generador_instrumento"
                    ></pregunta-ensayo>
                </ng-container>
            </div>

            <div class="row" *ngIf="displayPreguntas && preguntasAreVisible && evaluacion.instrumento.propio">
                <div class="col-md-12">
                    <a class="btn btn_style" [href]="material.url">{{ "preguntas_ensayo.descargar_archivo" | t }}</a>
                    <model-plain-value [params]="fileParams" [value]="material" key="url"></model-plain-value>
                </div>
            </div>
            <div *ngIf="timeoutAlert" class="warning-timeout">
                <fa name="clock-o" class="warning-icon"></fa>
                <p>{{ "preguntas_ensayo.warning_limit_time" | t }}</p>
            </div>
            <div
                class="clearfix btns-container"
                *ngIf="
                    preguntasAreVisible &&
                    displayPreguntas &&
                    displayBtnEntregarEnsayo &&
                    !evaluacion.instrumento.propio
                "
            >
                <cui-button
                    type="button"
                    class="btn_style pull-right"
                    [disabled]="btnEntregarEnsayoDisabled"
                    (click)="entregarEnsayo(true)"
                >
                    <ng-container *ngIf="enableEntregarGeneric">
                        {{ "preguntas_ensayo.completar_evaluacion" | t }}
                    </ng-container>
                    <ng-container *ngIf="!enableEntregarGeneric">
                        <ng-container *ngIf="!testlet">
                            {{ "preguntas_ensayo.completar" | t }}
                            {{ tipoInstrumentoAlias | transformInstrumento: "singular" }}
                        </ng-container>
                        <ng-container *ngIf="testlet">
                            {{ "preguntas_ensayo.siguiente" | t }}
                        </ng-container>
                    </ng-container>
                </cui-button>
            </div>
            <div
                class="clearfix btns-container"
                *ngIf="
                    preguntasAreVisible && displayPreguntas && displayBtnEntregarEnsayo && evaluacion.instrumento.propio
                "
            >
                <cui-button
                    type="button"
                    class="btn_style pull-right"
                    [disabled]="btnEntregarEnsayoDisabled"
                    (click)="entregarEnsayo(true)"
                >
                    {{ "preguntas_ensayo.completar_evaluacion" | t }}
                </cui-button>
            </div>
        </div>
    </loading-layout>
</div>
<div *ngIf="enableItsNotTimeYet" class="warning-timeframe">
    <fa name="clock-o" class="warning-icon"></fa>
    <p>{{ itsNotTimeYetMessage }}</p>
</div>
<div *ngIf="unpermitted" class="warning-timeframe">
    <fa name="exclamation-circle" class="warning-icon"></fa>
    <p>{{ "preguntas_ensayo.warning_unpermitted" | t }}</p>
    <p>
        <a [routerLink]="['/home']">{{ "preguntas_ensayo.ir_a_home" | t }}</a>
    </p>
</div>
