import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { MonitorPregunta } from "./monitor_preguntas.model"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"

@Injectable()
export class MonitorPreguntas extends NebuBaseService<MonitorPregunta> {
    tableName = "monitor_preguntas"
    singularTableName = "monitor_pregunta"
    modelClass = MonitorPregunta

    constructor(
        http: HttpClient,
        auth: NebuAuthService,
        router: Router,
        errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public static sumMonitores(monitores: MonitorPregunta[]): number {
        var total: number = monitores.reduce((a: number, b: MonitorPregunta) => {
            return a + b.cantidad
        }, 0)
        return total
    }
}
