import { Component, Injectable, HostListener } from "@angular/core"
import { ErroresService } from "./errores.service"
import { Router } from "@angular/router"
import { AppConfig } from "../../conf/app_config"
import { I18nService } from "../../i18n/i18n.service"

@Component({
    templateUrl: "errores.component.html",
    styleUrls: ["errores.component.scss"]
})
export class ErroresComponent {
    status: number | string
    message: string
    trace: string
    contact_mail: string
    disableContactInfo: boolean
    customErrorMessage: string = this.config.plataforma.customErrorMessage
    showAll: boolean = false

    @HostListener("window:load") onRefreshGoBack() {
        if (window.performance && performance.navigation.type == 1) {
            this.router.navigate([""])
        }
    }

    constructor(
        public errores: ErroresService,
        protected router: Router,
        public config: AppConfig,
        protected i18nService: I18nService
    ) {
        this.contact_mail = this.config.plataforma.info.soporte.mail || ""
        this.disableContactInfo = this.config.plataforma.disableContactInfo
        this.setStuff()
    }

    goHome() {
        this.router.navigate(["/home"])
    }

    setStuff() {
        //error
        this.message = this.errores.message
        if (this.message === undefined) {
            this.message = this.i18nService.translate("errores.error_default")
        }
        this.status = this.errores.status
        if (this.status == null && !this.errores.nonHttpError) {
            this.status = 404
            this.message = this.i18nService.translate("errores.error_404_front")

            if (this.errores.lastRoutes) {
                console.log("La última ruta que fue intentada acceder fue: ", this.errores.lastRoutes[1].url)
            }
        } else if (this.status == null && this.errores.nonHttpError) {
            // si no es un error de http
            this.status = this.i18nService.translate("errores.status_front")
            this.message = this.i18nService.translate("errores.error_no_manejado")
        }
        this.trace = this.errores.trace

        console.log(this.status)
        console.log(this.message)
        console.log(this.trace)
    }

    copyToClipboard(element) {
        if (window.getSelection) {
            if (window.getSelection().empty) {
                // Chrome
                window.getSelection().empty()
            } else if (window.getSelection().removeAllRanges) {
                // Firefox
                window.getSelection().removeAllRanges()
            }
        } else if ((<any>document).selection) {
            // IE?
            ;(<any>document).selection.empty()
        }

        if ((<any>document).selection) {
            const range = (<any>document.body).createTextRange()
            range.moveToElementText(element)
            range.select().createTextRange()
            document.execCommand("copy")
        } else if (window.getSelection) {
            const range = document.createRange()
            range.selectNode(element)
            window.getSelection().addRange(range)
            document.execCommand("copy")
        }
    }

    toggleMoreCode() {
        this.showAll = !this.showAll
    }
}
