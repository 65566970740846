import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core"
import { InstrumentoMultiple } from "@puntaje/puntaje/api-services"

@Component({
    selector: "instrumento-multiple",
    templateUrl: "instrumento_multiple.component.html",
    styleUrls: ["instrumento_multiple.component.scss"]
})
export class InstrumentoMultipleComponent {
    _instrumentoMultiple: InstrumentoMultiple
    @Input() enableReporte: boolean = true
    @Input() enableOpcionesPregunta = true
    @Output() ready = new EventEmitter<any>()

    constructor() {}

    @Input()
    get instrumentoMultiple(): InstrumentoMultiple {
        return this._instrumentoMultiple
    }

    set instrumentoMultiple(im: InstrumentoMultiple) {
        this._instrumentoMultiple = im
    }

    onInsrumentoMultipleReady() {}
}
