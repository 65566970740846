<a class="showLink" id="link-open-modal" (click)="openModalEvent.emit()">
    {{ "subir_respuestas_imagen_multiple.modal_instructivo.link" | t }}
</a>
<generic-modal
    [buttonClicked]="openModalEvent"
    [id]="'instructivo-subir-respuestas-modal'"
    [autoHeight]="true"
    [justifyBody]="true"
    [modalLarge]="true"
    #genericModal
>
    <modal-titulo>
        <h2 class="instr-title">{{ "subir_respuestas_imagen_multiple.modal_instructivo.titulo" | t }}</h2>
        <hr />
    </modal-titulo>
    <modal-contenido class="modal-contenido">
        <div class="section">
            <p>{{ "subir_respuestas_imagen_multiple.modal_instructivo.desc" | t }}</p>
        </div>
        <div class="section">
            <div class="instr-container" *ngFor="let instrImage of instrImages">
                <div class="col-img">
                    <img src="{{ instrImage.url }}" alt="{{ instrImage.desc }}" class="instr-img" />
                </div>
                <div class="col-txt">
                    <p [innerHTML]="instrImage.desc | trustedhtml"></p>
                </div>
            </div>
        </div>

        <div class="disclaimer">
            <div class="header">
                <img src="{{ headerImage.url }}" alt="warning-sign" class="warning-sign" />
                <h4 class="warning">{{ headerImage.desc }}</h4>
                <img src="{{ headerImage.url }}" alt="warning-sign" class="warning-sign" />
            </div>
            <div class="warning-section">
                <div class="warning-container" *ngFor="let warnImage of warnImages">
                    <img src="{{ warnImage.url }}" alt="{{ warnImage.desc }}" class="warning-img" />
                    <div class="warning-text">
                        <p>{{ warnImage.desc }}</p>
                    </div>
                </div>
            </div>
        </div>
    </modal-contenido>
    <modal-botones>
        <div class="pull-left">
            <label [attr.for]="'dont-show-again'">
                <input
                    type="checkbox"
                    (change)="setDontShowAgain($event)"
                    [checked]="dontShowAgain"
                    name="check-dont-show"
                    id="check-dont-show"
                    class="pull-left"
                />
                &nbsp;{{ "subir_respuestas_imagen_multiple.modal_instructivo.no_mostrar" | t }}
            </label>
        </div>
        <div>
            <cui-button type="button" class="" (click)="onAccept()">
                {{ "subir_respuestas_imagen_multiple.modal_instructivo.aceptar" | t }}
            </cui-button>
        </div>
    </modal-botones>
</generic-modal>
