import { Component, OnInit, ViewChild, OnDestroy, Input } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Duda, Dudas, DudaCategoria, DudaCategorias, GrupoUsuario, GrupoUsuarios } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { DudasBuscadorService } from "./dudas_buscador.service"
import { FiltroDudasComponent } from "./filtro_dudas.component"

@Component({
    selector: "dudas",
    templateUrl: "dudas.component.html",
    styleUrls: ["dudas.component.scss"]
})
export class DudasComponent implements OnInit, OnDestroy {
    dudas: Duda[] = []
    @ViewChild("filtroDudas", { static: true }) filtroDudas: FiltroDudasComponent
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("paginator", { static: true }) paginatorComponent: PaginatorComponent
    searchParams: any
    sub: Subscription
    @Input() categoriaId: number
    @Input() showCursos: boolean = false
    @Input() fromProfesor: boolean = false

    constructor(
        protected route: ActivatedRoute,
        protected dudasService: Dudas,
        protected dudasCategoriasService: DudaCategorias,
        protected dudasBuscadorService: DudasBuscadorService
    ) {}

    ngOnInit() {
        this.clear()
        this.sub = this.dudasBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            if (this.paginatorComponent.getList) this.paginatorComponent.reload()
        })
        if (this.categoriaId) {
            this.dudasBuscadorService.setDudaCategoriaId(this.categoriaId)
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    showAll = (page: number, per: number) => {
        this.loadingLayout.standby()
        let params = {
            page: page,
            per: per,
            sort_by: "created_at",
            order: "desc"
        }
        params = { ...params, ...this.searchParams, include: "[usuario,duda_categorias]", methods: "[participacion]" }
        if (this.showCursos) {
            params["with_grupo_usuarios"] = true
        }

        return this.dudasService.where(params).then((dudas: Duda[], total: number) => {
            this.dudas = dudas
            this.loadingLayout.ready()
            return total
        })
    }

    search(currentSearch: string) {
        this.dudasBuscadorService.setSearchBarParams(currentSearch)
    }

    clear() {
        this.dudasBuscadorService.setSearchBarParams("")
    }
}
