import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Evaluacion } from "./evaluaciones.model"
import { EvaluacionMultipleUsuario } from "./evaluacion_multiple_usuarios.model"
import { InstrumentoMultiple } from "./instrumento_multiples.model"
import { EvaluacionTipo } from "./evaluacion_tipos.model"
import { EvaluacionMultipleEvaluacion } from "./evaluacion_multiple_evaluaciones.model"

export class EvaluacionMultiple extends BaseModel {
    evaluacion_multiple: string
    evaluacion_tipo_id: number
    instrumento_multiple_id: number
    generador_instrumento_id: number
    created_at: Date
    updated_at: Date

    @SaveType(() => Evaluacion) evaluaciones: Evaluacion[]
    @SaveType(() => EvaluacionMultipleEvaluacion) evaluacion_multiple_evaluaciones: EvaluacionMultipleEvaluacion[]
    @SaveType(() => EvaluacionMultipleUsuario) evaluacion_multiple_usuarios: EvaluacionMultipleUsuario[]
    @SaveType(() => InstrumentoMultiple) instrumento_multiple: InstrumentoMultiple
    @SaveType(() => EvaluacionTipo) evaluacion_tipo: EvaluacionTipo

    public toString() {
        return this.evaluacion_multiple
    }

    get evaluacion_usuarios() {
        return this.evaluacion_multiple_usuarios
    }
}
