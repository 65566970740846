import { BaseModel, SaveType } from "@puntaje/shared/core"
import { GrupoRecursoTipo } from "./grupo_recurso_tipos.model"

export class GrupoRecurso extends BaseModel {
    recurso_type: string
    recurso_id: number
    orden: number
    activo: boolean
    grupo_recurso: string
    grupo_recurso_tipo_id: number

    receptor: any

    @SaveType(() => GrupoRecursoTipo) grupo_recurso_tipo: GrupoRecursoTipo
}
