import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core"
import {
    DudaRespuestas,
    DudaRespuesta,
    Dudas,
    Duda,
    Comentario,
    Comentarios,
    UsuarioBaneado,
    UsuarioBaneados
} from "@puntaje/puntaje/api-services"
import { Router } from "@angular/router"
import { SessionService } from "@puntaje/shared/core"
import { ComentariosListComponent } from "./comentarios_list.component"

@Component({
    selector: "duda-respuesta",
    templateUrl: "./duda_respuesta.component.html",
    styleUrls: ["./duda_respuesta.component.scss"]
})
export class DudaRespuestaComponent implements OnInit {
    @Input() duda: Duda
    @Input() dudaRespuesta: DudaRespuesta
    @Input() enableAcceptAnswer = false
    @Output() updateRespuesta = new EventEmitter<any>()
    @ViewChild("comentariosList") comentariosList: ComentariosListComponent
    comentarios: Comentario[]

    isAcceptedAnswer = false
    isDocente = false
    isAdmin = false
    isPro = false
    isEdited = false
    marcoPro = ""
    editando = false
    canEdit = false
    comentariosLoaded = false

    constructor(
        protected dudaRespuestasService: DudaRespuestas,
        protected dudasService: Dudas,
        protected router: Router,
        private sessionService: SessionService,
        private comentariosService: Comentarios,
        private usuarioBaneadosService: UsuarioBaneados
    ) {}

    ngOnInit() {
        if (this.dudaRespuesta) {
            this.canEdit = this.dudaRespuesta.usuario_id === this.sessionService.getUserData().id
            if (this.duda?.mejor_respuesta_id == this.dudaRespuesta?.id) {
                this.isAcceptedAnswer = true
            }
            this.marcoPro = this.dudaRespuesta.usuario.is_pro ? "marco01" : ""
            this.setBadges()
            this.checkAdmin()
            this.getComentarios()
        }
    }

    aceptarRespuesta() {
        if (this.duda) {
            const d = new Duda()
            d.mejor_respuesta_id = this.dudaRespuesta.id
            this.dudasService.update(this.duda.id, d).then(value => {
                this.updateRespuesta.emit()
            })
        }
    }

    setBadges() {
        //if(this.duda.usuario.rol_usuarios)
        const rol_docente = this.dudaRespuesta.usuario.rol_usuarios.find(r => {
            return r.rol.rol == "Docente"
        })
        if (rol_docente) {
            this.isDocente = true
        }
        if (this.dudaRespuesta.created_at != this.dudaRespuesta.updated_at) {
            this.isEdited = true
        }
        this.isPro = this.dudaRespuesta.usuario.is_pro
    }

    checkAdmin() {
        const some_admin = this.sessionService.getRoles().find(r => {
            return r == "SuperAdministrador" || r == "Administrador" || r == "Monitor"
        })
        if (some_admin) {
            this.isAdmin = true
        }
    }

    toggleEditar() {
        this.editando = !this.editando
    }

    saveListener(dudaRespuesta: DudaRespuesta) {
        this.updateRespuesta.emit()
        this.editando = false
    }

    saveSentiment() {}

    toggleComentar() {
        this.comentariosList.forceCommentNew()
    }

    getComentarios() {
        this.comentariosLoaded = false
        const params: any = {
            comentario: {
                duda_respuesta_id: this.dudaRespuesta.id
            },
            include: "usuario",
            sort_by: "created_at",
            order: "asc"
        }
        this.comentariosService.where(params).then((comentarios: Comentario[]) => {
            this.comentarios = comentarios
            //this.cdr.detectChanges(); // esta línea causa 'da bug'
            this.comentariosLoaded = true
        })
    }

    banHammer() {
        this.dudaRespuesta.ban = true
        this.dudaRespuestasService.update(this.dudaRespuesta.id, this.dudaRespuesta).then(resp => {})
    }

    banUser(usuarioId) {
        const now = new Date()

        const usuarioBaneado = new UsuarioBaneado()
        usuarioBaneado.usuario_id = usuarioId
        usuarioBaneado.fecha_inicio = new Date()
        usuarioBaneado.fecha_termino = new Date()
        usuarioBaneado.fecha_termino.setDate(now.getDate() + 7)

        this.usuarioBaneadosService.save(usuarioBaneado).then((usuarioBaneado: UsuarioBaneado) => {})
    }
}
