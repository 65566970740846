import { Component } from "@angular/core"

@Component({
    selector: "ll-toolnavbar, l-toolnavbar",
    template: `
        <l-slot-content name="toolnavbar">
            <ng-content></ng-content>
        </l-slot-content>
    `
})
export class LToolnavbarComponent {}
