<notificacion-layout
    [notificacion]="notificacion"
    [enableMini]="enableMini"
    [icono]="'dialogos'"
    [tipoNotificacion]="'plan de clase profesor'"
    [disableMarkButton]="disableMarkButton"
>
    <notificacion-contenido>
        <ng-container *ngIf="planPersonal">
            <div>
                <p class="texto-notificacion">
                    {{ "notificaciones.profesor" | t }} {{ usuario_remitente }}
                    {{ "notificaciones.te_ha_compartido" | t }}
                    <a routerLink="/{{ routerPlan }}/{{ planPersonal.id }}" target="_blank" (click)="onClickLink()">
                        {{ planPersonal.plan_personal }}
                    </a>
                </p>
            </div>
        </ng-container>
    </notificacion-contenido>
</notificacion-layout>
