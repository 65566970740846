import { Component, ElementRef, ViewEncapsulation, HostListener, ContentChildren, Input } from "@angular/core"
import { CUIPillComponent } from "./cui-pill.component"

@Component({
    selector: "cui-pills",
    template: `
        <div class="pill-container" [class.centered]="centered" [class.expanded]="expanded">
            <ng-content select="cui-pill"></ng-content>
        </div>
    `,
    styleUrls: ["cui-pills.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUIPillsComponent {
    @Input() centered: boolean = true
    @Input() expanded: boolean = false
    @ContentChildren("cui-pill") options: CUIPillComponent[]
    @HostListener("click", ["$event"]) onClick(e) {
        e.preventDefault()
        e.stopPropagation()
    }
    constructor(protected el: ElementRef) {}
}
