import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Material } from "@puntaje/nebulosa/api-services"

export class MaterialRelacion extends BaseModel {
    public material_id: number
    public relacion_type: string
    public relacion_id: number
    public created_at: string | Date
    public updated_at: string | Date

    @SaveType(() => Material) material: Material
}
