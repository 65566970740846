import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { HttpClientModule } from "@angular/common/http"
import { RouterModule } from "@angular/router"
import { AyudasWithThumbnailsComponent } from "./ayudas_with_thumbnails.component"
import { AyudaWithThumbnailComponent } from "./ayuda_with_thumbnail.component"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { Angulartics2Module } from "angulartics2"
import { AyudaComponent } from "./ayuda.component"
import { AyudaPreviewComponent } from "./ayuda_preview.component"
import { UtilModule, ModelViewModule, PaginatorModule } from "@puntaje/shared/core"
import { ApiModule } from "@puntaje/puntaje/api-services"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { CUIInputsModule, CUITextsModule } from "@puntaje/shared/cui"

import { AyudaListComponent } from "./ayuda_list.component"
import { PreguntasFrecuentesComponent } from "./preguntas-frecuentes/preguntas-frecuentes.component"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { AyudaInfoComponent } from "./ayuda_info.component"

@NgModule({
    imports: [
        CommonModule,
        CommonsLayoutsModule,
        UtilModule,
        HttpClientModule,
        ModelViewModule,
        RouterModule,
        Angular2FontawesomeModule,
        ApiModule,
        MaterialesModule,
        Angulartics2Module,
        SharedModule,
        PaginatorModule,
        CUIInputsModule,
        CUITextsModule
    ],
    declarations: [
        AyudaListComponent,
        AyudasWithThumbnailsComponent,
        AyudaWithThumbnailComponent,
        AyudaComponent,
        AyudaPreviewComponent,
        PreguntasFrecuentesComponent,
        AyudaInfoComponent
    ],
    exports: [
        AyudaListComponent,
        AyudasWithThumbnailsComponent,
        AyudaWithThumbnailComponent,
        AyudaComponent,
        AyudaPreviewComponent,
        PreguntasFrecuentesComponent,
        AyudaInfoComponent
    ],
    providers: []
})
export class AyudaModule {}
