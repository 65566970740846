import { Component, ViewChild, Input } from "@angular/core"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { PlanPersonal } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Router } from "@angular/router"

@Component({
    selector: "plan-personal-diagnostico-box",
    templateUrl: "plan_personal_diagnostico_box.component.html",
    styleUrls: ["plan_personal_sesion_box.component.scss"]
})
export class PlanPersonalDiagnosticoBoxComponent {
    @Input() isDone: boolean = false
    @Input() pointRight: boolean = false
    @Input() enableStartBtn: boolean = false
    _planPersonal: PlanPersonal
    _evaluacionId: any
    clasificaciones: Clasificacion[]
    previewMode: boolean = false //para que se deje de caer
    @Input()
    set planPersonal(planPersonal: PlanPersonal) {
        this._planPersonal = planPersonal
    }
    get planPersonal() {
        return this._planPersonal
    }
    _clasificacionesIds: string[]
    @Input()
    set clasificacionesIds(clasificacionesIds: any) {
        this._clasificacionesIds = clasificacionesIds
        this.getClasificaciones()
    }
    get clasificacionesIds() {
        return this._clasificacionesIds
    }

    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected clasificacionesService: Clasificaciones
    ) {}

    getClasificaciones() {
        let params = {
            clasificacion: {
                id: this.clasificacionesIds.split(",")
            }
        }
        this.clasificacionesService.where(params).then((clasificaciones: Clasificacion[]) => {
            this.clasificaciones = clasificaciones
        })
    }

    comenzarDiagnostico() {
        let params = {
            tipo_evaluacion: this.planPersonal.generador_instrumento.tipo_instrumento.tipo_instrumento,
            asignatura_id: this.planPersonal.generador_instrumento.asignatura.id,
            generador_instrumento_id: this.planPersonal.generador_instrumento_id,
            clasificaciones: this.clasificacionesIds.split(",")
        }
        this.router.navigate(["/diagnostico"], { queryParams: params })
    }
}
