import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Asignatura } from "./asignaturas.model"
import { GeneradorInstrumento } from "./generador_instrumentos.model"
import { Plataforma } from "./plataformas.model"

export class AsignaturaPlataforma extends BaseModel {
    public static className: string = "AsignaturaPlataforma"
    public asignatura_id: number
    public plataforma_id: number

    @SaveType(() => Asignatura) public asignatura: Asignatura
    @SaveType(() => Plataforma) public plataforma: Plataforma
    @SaveType(() => GeneradorInstrumento) public generador_instrumentos: GeneradorInstrumento[]

    constructor() {
        super()

        this.asignatura = new Asignatura()
        this.plataforma = new Plataforma()
        this.generador_instrumentos = []
    }

    public toString() {
        return "(" + this.asignatura_id + "," + this.plataforma_id + ")"
    }
}
