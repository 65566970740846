import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core"
import { Retroalimentaciones, Retroalimentacion } from "@puntaje/puntaje/api-services"

@Component({
    selector: "ver-retroalimentacion",
    templateUrl: "./ver-retroalimentacion.component.html",
    styleUrls: ["./ver-retroalimentacion.component.scss"]
})
export class VerRetroalimentacionComponent implements OnInit, OnChanges {
    @Input() evaluacionInstanciaId: number

    retroalimentacion: Retroalimentacion

    constructor(protected retroalimentacionesService: Retroalimentaciones) {}

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes["evaluacionInstanciaId"] &&
            changes["evaluacionInstanciaId"].previousValue != changes["evaluacionInstanciaId"].currentValue
        ) {
            this.setData()
        }
    }

    ngOnInit() {}

    async setData() {
        const params = {
            retroalimentacion: {
                retroalimentable_type: "EvaluacionInstancia",
                retroalimentable_id: this.evaluacionInstanciaId
            }
        }

        const retroalimentaciones = await this.retroalimentacionesService.where(params)
        this.retroalimentacion = retroalimentaciones[0]
    }
}
