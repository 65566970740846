import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "curso-select",
    templateUrl: "curso_select.component.html",
    styleUrls: ["curso_select.component.scss"]
})
export class CursoSelectComponent {
    @Input() curso: number
    @Output() cursoChange: EventEmitter<any> = new EventEmitter<any>()

    @Input() hidden: boolean = false

    _cursos: { id: number; label: string }[]
    config: typeof config = config

    constructor(private csService: Clasificaciones, protected cdr: ChangeDetectorRef) {}

    @Input()
    get cursos() {
        return this._cursos
    }

    set cursos(cs: any[]) {
        let oldCs = this._cursos
        if (cs && cs.length > 0) {
            let ids = cs.map(c => c.id).filter(c => c)
            let clasificaciones = cs.map(c => c.clasificacion).filter(c => c)
            if (ids.length != cs.length) {
                this.csService
                    .where({
                        clasificacion: { clasificacion: clasificaciones },
                        clasificacion_tipo: { clasificacion_tipo: config.plataforma.clasificacionTipoNivel }
                    })
                    .then(response => {
                        let clean_response = response as any
                        clean_response.forEach(c => {
                            cs.forEach(ci => {
                                if (ci.id == c.id || ci.clasificacion == c.clasificacion) {
                                    ci.id = c.id
                                    ci.clasificacion = c.clasificacion
                                    if (!ci.label) {
                                        ci.label = ci.clasificacion
                                    }
                                }
                            })
                        })
                        this._cursos = cs
                        if (cs.length > 0 && !this.curso) {
                            this.curso = cs[0].id
                            this.cdr.detectChanges()
                            this.cursoChange.emit(this.curso)
                        }
                        setTimeout(() => {
                            this.cursos &&
                                this.cursos.length > 0 &&
                                (!this.curso || oldCs == null || (oldCs != null && oldCs != cs)) &&
                                this.cursoChange.emit(this.curso)
                        }, 1)
                    })
            } else {
                this._cursos = cs
                this.curso = cs[0].id
                setTimeout(() => {
                    this.cursos &&
                        this.cursos.length > 0 &&
                        (!this.curso || oldCs == null || (oldCs != null && oldCs != cs)) &&
                        this.cursoChange.emit(this.cursos[0].id)
                }, 1)
            }
        } else {
            this._cursos = null
            this.curso = null
            setTimeout(() => {
                this.cursoChange.emit(null)
            }, 1)
        }
    }

    cursoChanges() {
        this.cdr.detectChanges()
        this.cursoChange.emit(this.curso)
    }
}
