import { BaseModel, SaveType } from "@puntaje/shared/core"
import { ClasificacionTipo } from "./clasificacion_tipos.model"
import { AsignaturaPlataforma } from "./asignatura_plataformas.model"

export class ClasificacionTipoAlias extends BaseModel {
    public static className: string = "ClasificacionTipoAlias"
    public alias: string
    public asignatura_plataforma_id: number
    public clasificacion_tipo_id: number
    public created_at: Date
    public updated_at: Date

    @SaveType(() => ClasificacionTipo) public clasificacion_tipo: ClasificacionTipo
    @SaveType(() => AsignaturaPlataforma) public asignatura_plataforma: AsignaturaPlataforma

    public toString() {
        return this.alias
    }
}
