import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { UtilModule, FormModule, AnimationsModule } from "@puntaje/shared/core"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { RouterModule } from "@angular/router"
import { LazyLoadImageModule } from "ng-lazyload-image"

import { HomeComponent } from "./home.component"
import { LandingComponent } from "./landing.component"
import { SidemenuLandingComponent } from "./sidemenu_landing.component"
import { TermsAndConditionsComponent } from "./terms_and_conditions.component"
import { TopMenuLandingComponent } from "./topmenu_landing.component"
import { ContactFormComponent } from "./contact_form.component"
import { CalendarShowcaseComponent } from "./calendar_showcase.component"
import { TermsAndConditionsColombiaComponent } from "./terms_and_conditions_colombia.component"
import { PrivacidadProteccionDatosColombiaComponent } from "./privacidad-proteccion-datos-colombia/privacidad-proteccion-datos-colombia.component"
import { TechnicalRequirementsComponent } from "./technical-requirements/technical-requirements.component"

import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { RecaptchaModule } from "ng-recaptcha"
import { InfoProComponent } from "./info_pro.component"
import { StatusProComponent } from "./status_pro.component"
import { TiempoProComponent } from "./tiempo_pro.component"
import { MenuShowcaseComponent } from "./menu-showcase/menu-showcase.component"
import { ComunidadProfesorShowcaseboxComponent } from "./comunidad-profesor-showcasebox/comunidad-profesor-showcasebox.component"
import { LandingAprendolibreComponent } from "./landing-aprendolibre/landing-aprendolibre.component"
import { LandingAprendolibreColombiaComponent } from "./landing-aprendolibre/landing-aprendolibre-colombia.component"
import { StreamingModule } from "@puntaje/puntaje/new-modules/streaming"
import { ContactFormGenericComponent } from "./contact-form-generic/contact-form-generic.component"
import { BaseLandingComponent } from "./base-landing/base-landing.component"
import { FAQBaseComponent } from "./faqs-base/faq-base.component"
import { FooterAprendolibreComponent } from "./landing-aprendolibre/footer-aprendolibre/footer-aprendolibre.component"
import {
    AcercaDeAprendolibreComponent,
    AcercaDeAprendolibreContenido,
    AcercaDeAprendolibreTitulo
} from "./acerca-de-aprendolibre/acerca-de-aprendolibre.component"
import { DudasModule } from "@puntaje/puntaje/new-modules/dudas"
import { CountrysComponent } from "./landing-aprendolibre/countrys/countrys.component"
import { PrensaComponent } from "./prensa/prensa.component"
import { ContactoPrensaComponent } from "./contacto-prensa/contacto-prensa.component"
import { AcercaDeAprendolibreMexicoComponent } from "./acerca-de-aprendolibre/acerca-de-aprendolibre-mexico/acerca-de-aprendolibre-mexico.component"
import { AcercaDeAprendolibreColombiaComponent } from "./acerca-de-aprendolibre/acerca-de-aprendolibre-colombia/acerca-de-aprendolibre-colombia.component"
import { AcercaDeAprendolibreChileComponent } from "./acerca-de-aprendolibre/acerca-de-aprendolibre-chile/acerca-de-aprendolibre-chile.component"
import { LinkPrensaComponent } from "./link-prensa/link-prensa.component"
import { TermsAndConditionsUsachComponent } from "./terms-and-conditions-usach/terms-and-conditions-usach.component"
import { TermsAndConditionsUaComponent } from "./terms-and-conditions-ua/terms-and-conditions-ua.component"
import { TermsAndConditionsAlmxComponent } from "./terms-and-conditions-almx/terms-and-conditions-almx.component"
import { TermsAndConditionsCBComponent } from "./terms-and-conditions-cb/terms-and-conditions-cb.component"
import { BaseContactFormComponent } from "./base-contact-form/base-contact-form.component"
import { PuntajeCoContactFormComponent } from "./puntajeco-contact-form.component"
import { TermsAndConditionsGuanajuatoComponent } from "./terms_and_conditions_guanajuato.component"
import { FooterAprendolibreMexicoComponent } from "./landing-aprendolibre/footer-aprendolibre-mexico/footer-aprendolibre-mexico.component"
import { InstructionsDataDeletionComponent } from "./instructions-data-deletion/instructions-data-deletion.component"
import { GenericShowcaseboxComponent } from "./generic-showcasebox/generic-showcasebox.component"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { NoticiasModule } from "@puntaje/puntaje/new-modules/noticias"
import { GrupoUsuariosModule } from "@puntaje/puntaje/new-modules/grupo-usuarios"
import { EstablecimientosModule } from "@puntaje/puntaje/new-modules/establecimientos"
import { ResumenSemanaShowcaseboxComponent } from "./resumen-semana-showcasebox/resumen-semana-showcasebox.component"
import { CUIInputsModule } from "@puntaje/shared/cui"
import { LayoutsModule } from "@puntaje/puntaje/new-modules/layouts"
import { UdlaLandingComponent } from "./udla-landing/udla-landing.component"
import { PlanEstudiosModule } from "@puntaje/puntaje/new-modules/plan-estudios"
import { BlogModule } from "@puntaje/puntaje/new-modules/blog"
import { TermsAndConditionsChileComponent } from "./terms-and-conditions-chile/terms-and-conditions-chile.component"
import { LandingAbilyComponent } from "./landing-abily/landing-abily.component"
import { LandingNavbarComponent } from "./landing-abily/landing-navbar/landing-navbar.component"
import { DynamicModule } from "ng-dynamic-component"
import { ActionMenuShowcaseComponent } from "./action-menu-showcase/action-menu-showcase.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        UsuariosModule,
        Angular2FontawesomeModule,
        CommonsLayoutsModule,
        FormModule,
        RecaptchaModule,
        StreamingModule,
        NebuModule,
        RouterModule,
        DudasModule,
        LazyLoadImageModule,
        AnimationsModule,
        InstrumentosModule,
        NotificacionesModule,
        BannersModule,
        NoticiasModule,
        GrupoUsuariosModule,
        EstablecimientosModule,
        CUIInputsModule,
        LayoutsModule,
        PlanEstudiosModule,
        BlogModule,
        DynamicModule
    ],
    declarations: [
        HomeComponent,
        LandingComponent,
        SidemenuLandingComponent,
        TermsAndConditionsComponent,
        TopMenuLandingComponent,
        ContactFormComponent,
        CalendarShowcaseComponent,
        TermsAndConditionsColombiaComponent,
        PrivacidadProteccionDatosColombiaComponent,
        InfoProComponent,
        StatusProComponent,
        TiempoProComponent,
        MenuShowcaseComponent,
        ComunidadProfesorShowcaseboxComponent,
        TechnicalRequirementsComponent,
        LandingAprendolibreComponent,
        LandingAprendolibreColombiaComponent,
        ContactFormGenericComponent,
        BaseLandingComponent,
        FAQBaseComponent,
        FooterAprendolibreComponent,
        FooterAprendolibreMexicoComponent,
        AcercaDeAprendolibreComponent,
        CountrysComponent,
        PrensaComponent,
        ContactoPrensaComponent,
        AcercaDeAprendolibreContenido,
        AcercaDeAprendolibreTitulo,
        AcercaDeAprendolibreMexicoComponent,
        AcercaDeAprendolibreColombiaComponent,
        AcercaDeAprendolibreChileComponent,
        LinkPrensaComponent,
        TermsAndConditionsUsachComponent,
        TermsAndConditionsGuanajuatoComponent,
        TermsAndConditionsUaComponent,
        TermsAndConditionsAlmxComponent,
        TermsAndConditionsCBComponent,
        BaseContactFormComponent,
        PuntajeCoContactFormComponent,
        InstructionsDataDeletionComponent,
        TermsAndConditionsUaComponent,
        TermsAndConditionsGuanajuatoComponent,
        GenericShowcaseboxComponent,
        ResumenSemanaShowcaseboxComponent,
        UdlaLandingComponent,
        TermsAndConditionsChileComponent,
        LandingAbilyComponent,
        LandingNavbarComponent,
        ActionMenuShowcaseComponent
    ],
    exports: [
        HomeComponent,
        LandingComponent,
        TermsAndConditionsComponent,
        TopMenuLandingComponent,
        ContactFormComponent,
        CalendarShowcaseComponent,
        TermsAndConditionsColombiaComponent,
        PrivacidadProteccionDatosColombiaComponent,
        InfoProComponent,
        StatusProComponent,
        TiempoProComponent,
        MenuShowcaseComponent,
        ComunidadProfesorShowcaseboxComponent,
        TechnicalRequirementsComponent,
        LandingAprendolibreComponent,
        LandingAprendolibreColombiaComponent,
        ContactFormGenericComponent,
        BaseLandingComponent,
        FooterAprendolibreMexicoComponent,
        FAQBaseComponent,
        AnimationsModule,
        AcercaDeAprendolibreComponent,
        PrensaComponent,
        AcercaDeAprendolibreMexicoComponent,
        AcercaDeAprendolibreColombiaComponent,
        AcercaDeAprendolibreChileComponent,
        TermsAndConditionsUsachComponent,
        PuntajeCoContactFormComponent,
        TermsAndConditionsUaComponent,
        TermsAndConditionsAlmxComponent,
        TermsAndConditionsCBComponent,
        PuntajeCoContactFormComponent,
        TermsAndConditionsGuanajuatoComponent,
        InstructionsDataDeletionComponent,
        TermsAndConditionsUsachComponent,
        PuntajeCoContactFormComponent,
        TermsAndConditionsUaComponent,
        TermsAndConditionsGuanajuatoComponent,
        GenericShowcaseboxComponent,
        ResumenSemanaShowcaseboxComponent,
        UdlaLandingComponent,
        TermsAndConditionsChileComponent,
        LandingAbilyComponent,
        ActionMenuShowcaseComponent
    ]
})
export class LandingModule {
    static forRoot(): ModuleWithProviders<LandingModule> {
        return {
            ngModule: LandingModule,
            providers: []
        }
    }
}
