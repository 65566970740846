import { Component, EventEmitter, Input, OnInit, ViewChild } from "@angular/core"
import { AuthService, GenericModalComponent, Validations } from "@puntaje/shared/core"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { Login } from "@puntaje/puntaje/services"
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"

@Component({
    selector: "google-button",
    templateUrl: "./google-button.component.html",
    styleUrls: ["./google-button.component.scss"]
})
export class GoogleButtonComponent implements OnInit {
    pais = config.plataforma.pais
    googleUser: any

    identificador_pais = config.plataforma.identificadorUsuario[0]
    identificador = config.plataforma.identificadorUsuarioAlias?.[this.identificador_pais]
    usuarioPaisForm: UntypedFormGroup
    showMensaje: boolean = false
    placeholder: string = ""
    @Input() googleBtnDarkTheme = false // default es Light
    @Input() validarIdentificador = true // si se envía, no pide identificador
    @Input() showIcono = true
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    constructor(
        public loginService: Login,
        public authService: AuthService,
        public usuarioService: Usuarios,
        protected router: Router
    ) { }

    ngOnInit() {
        if (this.validarIdentificador) {
            switch (this.pais) {
                case "chile":
                    this.usuarioPaisForm = new UntypedFormGroup({
                        identificador: new UntypedFormControl(
                            "",
                            [Validators.required, Validations.validateRut],
                            [Validations.validateUniqueRut(this.usuarioService) as AsyncValidatorFn]
                        )
                    })
                    this.placeholder = "11111111-1"
                    break

                case "mexico":
                    this.usuarioPaisForm = new UntypedFormGroup({
                        identificador: new UntypedFormControl(
                            "",
                            [Validators.required],
                            [Validations.validateUniqueNumeroUnico(this.usuarioService) as AsyncValidatorFn]
                        )
                    })
                    break

                case "colombia":
                    this.usuarioPaisForm = new UntypedFormGroup({
                        identificador: new UntypedFormControl(
                            "",
                            [Validators.required],
                            [Validations.validateUniqueCC(this.usuarioService) as AsyncValidatorFn]
                        )
                    })
                    break
                case "brasil":
                    this.usuarioPaisForm = new UntypedFormGroup({
                        identificador: new UntypedFormControl(
                            "",
                            [Validators.required],
                            [Validations.validateUniqueCPF(this.usuarioService) as AsyncValidatorFn]
                        )
                    })
                    break
            }
        }
    }

    async loginGoogle() {
        this.googleUser = await this.authService.signInWithGoogle()
        if (!this.validarIdentificador) {
            this.goToLoginGeneral()
        } else {
            const params = {
                email: this.googleUser.email,
                identificador_pais: this.identificador_pais
            }

            const response = await this.usuarioService.exists_email_identificador(params)
            if (response["existe_usuario"]) {
                this.goToLoginGeneral()
            } else {
                this.openModal.next()
            }
        }
    }

    enviar() {
        this.showMensaje = false
        if (!this.usuarioPaisForm.valid) {
            this.showMensaje = true
        } else {
            this.goToLoginGeneral()
            this.genericModal.close()
        }
    }

    goToLoginGeneral() {
        const params = {
            idtoken: this.googleUser.idToken,
            identificador_pais: this.identificador_pais
        }
        if (this.validarIdentificador) params["identificador"] = this.usuarioPaisForm.controls.identificador.value

        this.loginService.loginGeneral(
            this.authService.loginGeneral(environment.endpoints.base + "/usuarios/sign_in_google", params),
            () => { },
            false,
            false
        )
    }

    forgotPassword() {
        this.genericModal.close()
        this.router.navigate(["/usuarios/passwords/forgot"])
    }
}
