import { BaseForm, Validations } from "@puntaje/shared/core"
import { Usuarios } from "./usuarios.service"
import { Validators } from "@angular/forms"

export class TutorForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            email: {
                label: "Email Tutor",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail]
            },
            usuario_id: { label: "Usuario", type: "select", visible: true, options: { class: Usuarios } },
            confirmado: { label: "Confirmado", type: "boolean", visible: true },
            tutor_id: { label: "Tutor", type: "select", visible: true, options: { class: Usuarios } },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
