import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subject } from "rxjs"
import { Router } from "@angular/router"
import { EvaluacionInstancias } from "@puntaje/puntaje/api-services"
import { FiltroEstadisticasService } from "@puntaje/puntaje/new-modules/estadisticas"
import { CableService, AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "subir-respuestas-csv",
    templateUrl: "subir_respuestas_csv.component.html",
    styleUrls: ["subir_respuestas_csv.component.scss"]
})
export class SubirRespuestasCSVComponent implements OnInit, OnDestroy {
    file: File
    xhrUpload: Subject<XMLHttpRequest> = new Subject()
    wrongFileType: boolean = false
    uploadingResultsSubscription: any
    progress: number = 0
    totalProgress: number = 0
    progressWidth: string = "0%"
    messages: any
    loadingMessages: boolean = false
    private datosBoton: any
    goToViewPath: string

    pais = config.plataforma.pais

    urlFormato = `https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/${this.pais}/formatos_carga_resultados/evaluacion-formato-para-llenar.csv`
    urlFormatoAB = `https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/${this.pais}/formatos_carga_resultados/evaluacion-formato-forma-para-llenar.csv`

    constructor(
        protected evaluacionesInstanciasService: EvaluacionInstancias,
        protected filtroEstadisticasService: FiltroEstadisticasService,
        protected router: Router,
        protected cableService: CableService,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        let usuarioId = this.authService.getUserData().id

        this.uploadingResultsSubscription = this.cableService.subscribe(
            "UploadingResultsChannel",
            { usuario_id: usuarioId },
            {
                connected: function () {
                    console.log("uploadingResultsSubscription connected")
                },
                disconnected: function () {
                    console.log("uploadingResultsSubscription disconnected")
                },
                rejected: function () {
                    console.log("uploadingResultsSubscription rejected")
                },
                received: data => {
                    this.progress = data.estudiantes_procesados
                    this.totalProgress = data.total_estudiantes

                    if (this.progress == this.totalProgress) {
                        this.progress = 0
                        this.totalProgress = 0
                    } else {
                        this.progress = data.estudiantes_procesados
                        this.totalProgress = data.total_estudiantes
                    }
                    this.updateProgressWidth()
                }
            }
        )
    }

    ngOnDestroy() {
        this.uploadingResultsSubscription.unsubscribe()
    }

    subirCSV() {
        this.checkIfCSVExtension()
        if (this.wrongFileType) {
            return
        }
        this.loadingMessages = true

        this.evaluacionesInstanciasService.subir().uploadFile(
            { file: this.file, pais: config.plataforma.pais },
            null,
            this.xhrUpload,
            response => {
                this.messages = JSON.parse(response)
                this.loadingMessages = false
                if (this.messages && this.messages["estadisticas"]) {
                    this.datosBoton = this.messages["estadisticas"][0]
                    if (config.plataforma.afterUploadResultGoToViewPath) {
                        this.goToViewPath = config.plataforma.afterUploadResultGoToViewPath.replace(
                            ":evaluacionId",
                            this.datosBoton.evaluacion_id
                        )
                    }
                }
            },
            0
        )
    }

    bottonEstadisticas() {
        if (this.datosBoton) {
            let b = this.datosBoton
            this.filtroEstadisticasService.setFiltrosId(
                b.establecimiento_id,
                null,
                null,
                b.evaluacion_id,
                b.generador_instrumento_id,
                b.evaluacion_tipo,
                b.asignatura_id
            )
            this.router.navigate(["/pruebas/estadisticas"], {
                queryParams: { asignatura_id: b.asignatura_id, tipo_instrumento: b.evaluacion_tipo }
            })
        }
    }

    checkIfCSVExtension() {
        if (this.file && (this.file.name as any).endsWith(".csv")) {
            this.wrongFileType = false
        } else {
            this.wrongFileType = true
        }
    }

    getProgressPercent() {
        if (this.totalProgress == 0) {
            return 0
        }

        return (this.progress / this.totalProgress) * 100
    }

    getProgressWidth() {
        return this.getProgressPercent() + "%"
    }

    updateProgressWidth() {
        this.progressWidth = this.getProgressWidth()
    }
}
