import { Component, EventEmitter, Injectable, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { GenericModalComponent } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Subscription } from "rxjs"
import {
    ReporteForm,
    Reporte,
    Reportes,
    ReporteRazon,
    ReporteRazones,
    ReporteSubRazon,
    ReporteSubRazones
} from "@puntaje/nebulosa/api-services"
import { Usuario, Usuarios } from "@puntaje/puntaje/api-services"

declare var $: any //MOST PRETTY LINE

@Component({
    templateUrl: "report.component.html",
    selector: "reportes-modal",
    styleUrls: ["report.component.scss"]
})
@Injectable()
export class ReportComponent implements OnInit, OnDestroy {
    @Input() tabla: string
    @Input() entidad_id: number
    @Input() enableButton: boolean = true
    @Input() enableLink: boolean = false
    buttonPressed = new EventEmitter()
    reporte: Reporte = new Reporte()
    razones: ReporteRazon[]
    sub_razones: ReporteSubRazon[]
    nombre_modal = "generic_modal" //No debe cambiar, no se por que
    form: UntypedFormGroup
    filling: boolean = true
    usuario: Usuario

    sub: Subscription

    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent

    constructor(
        private reportesService: Reportes,
        private usuariosService: Usuarios,
        private reporteRazonesService: ReporteRazones,
        private reporteSubRazonesService: ReporteSubRazones
    ) {}

    ngOnInit() {
        this.sub = this.reporteRazonesService.reporteRazonesSubject.subscribe(reporteRazonesByTabla => {
            this.razones = reporteRazonesByTabla[this.tabla]
            if (!this.razones || this.razones.length == 0) {
                this.reporteRazonesService.loadReporteRazones()
            }
        })

        this.form = ReporteForm.getForm(this.reporte)
    }

    pressButton() {
        this.buttonPressed.emit()
    }

    loadSub(event) {
        let razon = this.razones.find(r => r.id == event.target.value)

        if (razon) this.sub_razones = razon.reporte_sub_razones
        else this.sub_razones = []
    }

    async save() {
        let report = this.form.value

        this.reporte.reporte_razon_id = report.reporte_razon_id
        this.reporte.reporte_sub_razon_id = report.reporte_sub_razon_id
        this.reporte.reporte = report.reporte
        ReporteForm.markFormControlsAsTouched(this.form)
        let localusuario = JSON.parse(localStorage.getItem("usuario"))
        await this.usuariosService
            .find(localusuario.id, { include: "[rol_usuarios:[rol]]" })
            .then((usuario: Usuario) => {
                this.usuario = usuario
            })
        this.reporte.entidad_id = this.entidad_id
        this.reporte.tabla = this.tabla
        this.reporte.plataforma_id = config.plataforma.id
        this.reporte.estado_reporte_id = 1 //Pendiente
        if (this.usuario) {
            this.reporte.usuario_reporte_id = this.usuario.id
            this.reporte.usuario_reporte_nombre = this.usuario.nombre
            this.reporte.usuario_reporte_apellido_paterno = this.usuario.apellido_paterno
            this.reporte.usuario_reporte_apellido_materno = this.usuario.apellido_materno
            this.reporte.usuario_reporte_email = this.usuario.email
            this.reporte.usuario_reporte_rol_id = this.usuario.rol_usuarios[0].rol_id
            this.reporte.usuario_reporte_rol_rol = this.usuario.rol_usuarios[0].rol.rol
        }

        this.form.controls["tabla"].setValue(this.tabla)
        this.form.controls["entidad_id"].setValue(this.entidad_id)
        this.form.controls["usuario_reporte_nombre"].setValue(this.usuario.nombre)
        this.form.controls["usuario_reporte_id"].setValue(this.reporte.usuario_reporte_id)
        this.form.controls["usuario_reporte_email"].setValue(this.usuario.email)
        this.form.controls["usuario_reporte_apellido_paterno"].setValue(this.usuario.apellido_paterno)
        this.form.controls["usuario_reporte_apellido_materno"].setValue(this.usuario.apellido_materno)
        this.form.controls["usuario_reporte_rol_id"].setValue(this.usuario.rol_usuarios[0].rol_id)
        this.form.controls["usuario_reporte_rol_rol"].setValue(this.usuario.rol_usuarios[0].rol.rol)

        if (this.form.valid && this.usuario) {
            this.reportesService
                .where({
                    reporte: {
                        reporte_razon_id: this.reporte.reporte_razon_id,
                        entidad_id: this.entidad_id,
                        tabla: this.reporte.tabla,
                        plataforma_id: this.reporte.plataforma_id,
                        estado_reporte_id: 1,
                        usuario_reporte_id: this.reporte.usuario_reporte_id,
                        usuario_reporte_rol_id: this.reporte.usuario_reporte_rol_id,
                        usuario_reporte_rol_rol: this.reporte.usuario_reporte_rol_rol
                    }
                })
                .then(response => {
                    if ((response as Reporte[]).length == 0) {
                        this.reportesService.save(this.reporte).then(response => {
                            this.filling = false
                        })
                    } else {
                        this.filling = false
                    }
                })
        }
    }

    clear() {
        setTimeout(() => {
            $("#" + this.tabla + "_" + this.entidad_id).modal("hide")
            this.genericModal.close()
            this.reporte = new Reporte()
            this.form = ReporteForm.getForm(this.reporte)
            ReporteForm.markFormControlsAsPristine(this.form)
            ReporteForm.markFormControlsAsUntouched(this.form)
            this.filling = true
        }, 150)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
