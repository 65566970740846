<ng-container>
    <h4 class="subtitulo titulo-dificultad print-hide">
        <span class="icon-dificultad">
            <span class="glyphicon glyphicon-signal" aria-hidden="true"></span>
        </span>
        Estadísticas
    </h4>
    <table class="table tabla-estadisticas">
        <tbody>
            <tr>
                <th>Dificultad</th>
                <td>{{ dificultad }}</td>
            </tr>
            <tr>
                <th>Respuestas</th>
                <td *ngIf="estadistica">
                    {{ estadistica.correctas }} de {{ totalRespuestas }} respuestas (un {{ porcentajeCorrectas }}%)
                    fueron correctas.
                </td>
                <td>-</td>
            </tr>
        </tbody>
    </table>
</ng-container>
