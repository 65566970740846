<div
    class="modal fade staticModal"
    bsModal
    #modalImprimirEvaluacionAlumnos="bs-modal"
    tabindex="0"
    role="dialog"
    aria-labelledby="modalImprimirEvaluacionAlumno"
    aria-hidden="true"
>
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">{{ "generar_ensayos.modal_instrucciones_descargar.titulo" | t }}</h3>
                <button type="button" class="close" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="step">
                    <div class="img-wrap">
                        <img
                            alt="Escanea código QR"
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/escanear-codigo-qr.png"
                        />
                    </div>
                    <p [innerHTML]="'generar_ensayos.modal_instrucciones_descargar.parrafo1' | t | trustedhtml"></p>
                </div>
                <div class="step">
                    <div class="img-wrap">
                        <img
                            alt="Escanea hoja de respuesta"
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/escanear-hoja-respuesta.png"
                        />
                    </div>
                    <p [innerHTML]="'generar_ensayos.modal_instrucciones_descargar.parrafo2' | t | trustedhtml"></p>
                </div>
            </div>
            <div class="modal-footer">
                <cui-button type="button" class="btn-primary btn_style" (click)="confirm()">
                    {{ "generar_ensayos.modal_instrucciones_descargar.boton" | t }}
                </cui-button>
            </div>
        </div>
    </div>
</div>
