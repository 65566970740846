import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()
export class GrupoPreguntaTextoService {
    public sentenceToLine: any = {}

    setSentenceToLine(grupoPreguntaId: number, value: any) {
        this.sentenceToLine[grupoPreguntaId] = { ...this.sentenceToLine[grupoPreguntaId], ...value }
    }
}
