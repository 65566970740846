import { BaseForm, Validations, GroupValidator, AppConfig, I18nService } from "@puntaje/shared/core"

import { Lugares } from "./lugares.service"
import { Validators } from "@angular/forms"

import { Clasificaciones } from "@puntaje/nebulosa/api-services"
import { UsuariosCompletarRegistroSocialChileForm } from "./usuarios_completar_registro_social_chile.form"
import { UsuariosCompletarRegistroSocialColombiaForm } from "./usuarios_completar_registro_social_colombia.form"
import { UsuariosCompletarRegistroSocialMexicoForm } from "./usuarios_completar_registro_social_mexico.form"
import { UsuariosCompletarRegistroSocialBrasilForm } from "./usuarios_completar_registro_social_brasil.form"

declare const config: AppConfig

export class UsuariosCompletarRegistroSocialForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    companyName: string = ""

    public static get formParams(): any {
        let translatorService
        if (this.injector) translatorService = this.injector.get(I18nService)
        const companyName = config.plataforma.info.companyName

        const classes = {
            chile: UsuariosCompletarRegistroSocialChileForm,
            colombia: UsuariosCompletarRegistroSocialColombiaForm,
            mexico: UsuariosCompletarRegistroSocialMexicoForm,
            brasil: UsuariosCompletarRegistroSocialBrasilForm
        }

        const params: any = {
            id: { label: "ID", type: "hidden", visible: false },
            nombre: {
                label: this.injector
                    ? translatorService.translate("landing.registro.datos_personales.nombres", {})
                    : "Nombre",
                type: "text",
                validations: [Validators.required],
                visible: true
            },
            apellido_paterno: {
                label: this.injector
                    ? translatorService.translate("landing.registro.datos_personales.apellido_paterno", {})
                    : "Apellido Paterno",
                type: "text",
                validations: [Validators.required],
                visible: true
            },
            terminos_y_condiciones: {
                innerhtml: this.injector
                    ? translatorService.translate("landing.registro.datos_personales.terminos_y_condiciones", {
                          companyName
                      })
                    : "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de " +
                      companyName +
                      "</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            },
            egresado: { label: "Egresado", type: "checkbox", visible: true },
            colegio: {
                label: "Colegio",
                type: "text",
                visible: true,
                // TODO: hacer esto alguna opcion de configuracion razonable que la puedan usar los clones tambien (y puntaje)
                validations: [config.plataforma.name == "Puntaje Nacional" ? Validators.required : null].filter(
                    x => !!x
                )
            },
            nivel_id: {
                label: "Curso",
                type: "select",
                visible: true,
                options: {
                    class: Clasificaciones,
                    params: {
                        clasificacion_tipo: { clasificacion_tipo: config.plataforma.clasificacionTipoNivel || "curso" }
                    }
                },
                validations: [config.plataforma.name == "Puntaje Nacional" ? Validators.required : null].filter(
                    x => !!x
                )
            },
            lugar: {
                label: "Municipio",
                type: "autocomplete",
                visible: true,
                placeholder: "Escribe y selecciona entre las opciones",
                options: { class: Lugares, params: { lugar_tipo: "Municipio", pais: config.plataforma.pais } }
            }
            /* usuario_chile: {
                type: "model",
                class: UsuariosCompletarRegistroSocialChileForm
            } */
        }
        params["usuario_" + config.plataforma.pais] = { type: "model", class: classes[config.plataforma.pais] }

        if (config.registro && config.registro.enablePoliticasPrivacidad) {
            params["politicas_privacidad"] = {
                innerhtml:
                    "He leído y acepto las <a href='/politicas_privacidad_y_proteccion_datos' target='_blank'>políticas de privacidad y protección de datos personales</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar las políticas de privacidad y protección de datos personales"
            }
        }

        return params
    }
}
