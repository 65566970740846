import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Pregunta } from "@puntaje/nebulosa/api-services"
import { EvaluacionFormaInstrumentoPregunta } from "./evaluacion_forma_instrumento_preguntas.model"
import { EvaluacionFormaUsuario } from "./evaluacion_forma_usuarios.model"

export class EvaluacionForma extends BaseModel {
    evaluacion_id: number
    forma: string
    created_at: Date
    updated_at: Date

    @SaveType(() => Pregunta) preguntas: Pregunta[]
    @SaveType(() => EvaluacionFormaInstrumentoPregunta)
    evaluacion_forma_instrumento_preguntas: EvaluacionFormaInstrumentoPregunta[]
    @SaveType(() => EvaluacionFormaUsuario) evaluacion_forma_usuarios: EvaluacionFormaUsuario[]
}
