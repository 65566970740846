import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { EstadisticasEvolutivasService } from "../estadisticas_evolutivas.service"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo, selectSelectedEvaluacionTipo } from "@puntaje/puntaje/store"
import { combineLatest, Subscription } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    templateUrl: "tabla_kpi_auto.component.html",
    selector: "tabla-kpi-auto",
    styleUrls: ["tabla_kpi_auto.component.scss"]
})
export class TablaKpiAutoComponent implements OnInit {
    evaluacionTipo: string
    listaAsignaturas: { [key: number]: any } = {}
    generadorAsignatura: { [key: number]: number } = {}
    estadisticasAsignatura: { [key: number]: any[] } = {}
    kpiAsignatura: { [key: number]: any } = {}
    ready = false
    percentageSign = false

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    sub: Subscription
    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(protected store: Store<State>, private estadisticasEvolutivasService: EstadisticasEvolutivasService) {}

    ngOnInit() {
        this.setAsignaturas()
    }

    setAsignaturas() {
        this.sub = combineLatest(this.asignaturasByEvaluacionTipo$, this.evaluacionTipo$).subscribe(
            ([asignaturasByEvaluacionTipo, evaluacionTipo]) => {
                this.ready = false
                this.loadingLayout.standby()
                this.listaAsignaturas = {}
                this.kpiAsignatura = {}
                this.generadorAsignatura = {}
                this.estadisticasAsignatura = {}
                this.evaluacionTipo = evaluacionTipo
                if (this.evaluacionTipo == "paa") {
                    this.percentageSign = true
                }
                const lista_asignaturas =
                    asignaturasByEvaluacionTipo[this.evaluacionTipo || config.plataforma.evaluacionDefault]
                this.estadisticasEvolutivasService
                    .getAsignaturasConGeneradores(this.evaluacionTipo)
                    .then((response: Asignatura[]) => {
                        const generadores = []
                        lista_asignaturas.map(a => {
                            response.forEach(asig => {
                                if (asig.id == a.id) {
                                    asig.asignatura_plataformas.forEach(ap => {
                                        ap.generador_instrumentos.forEach(g => {
                                            this.generadorAsignatura[g.id] = a.id
                                            generadores.push(g.id)
                                        })
                                    })
                                }
                            })
                            this.listaAsignaturas[a["id"]] = a
                        })
                        this.getData(generadores)
                    })
                    .finally(() => {
                        this.ready = true
                        this.loadingLayout.ready()
                    })
            }
        )
    }

    getData(generadores: number[]) {
        this.estadisticasEvolutivasService
            .getEstadisticaUsuario(generadores, undefined, undefined, this.evaluacionTipo, false)
            .then((estadisticas: any) => {
                estadisticas.forEach(e => {
                    const asig = this.generadorAsignatura[e.generador_instrumento_id]
                    if (this.estadisticasAsignatura[asig]) {
                        this.estadisticasAsignatura[asig].push(e)
                    } else {
                        this.estadisticasAsignatura[asig] = [e]
                    }
                })

                Object.keys(this.listaAsignaturas).forEach(i => {
                    const list = this.getUniqIds(this.estadisticasAsignatura[i])
                    if (list !== null && list !== undefined && list.length > 0) {
                        let n = 0
                        let puntaje_promedio = 0
                        let ultima_fecha = new Date(0)
                        let ultimo = 0
                        let correctas = 0
                        let incorrectas = 0
                        let omitidas = 0
                        let puntaje_maximo = 0

                        list.forEach(e => {
                            n = n + e["numero_evaluaciones"]
                            puntaje_promedio = puntaje_promedio + e["numero_evaluaciones"] * e["promedio"]
                            const fecha = new Date(e["fecha_ultimo"])
                            if (ultima_fecha < fecha) {
                                ultima_fecha = fecha
                                ultimo = e["ultimo"]
                            }
                            correctas = correctas + e["correctas"]
                            incorrectas = incorrectas + e["incorrectas"]
                            omitidas = omitidas + e["omitidas"]
                            if (puntaje_maximo < e["maximo"]) {
                                puntaje_maximo = e["maximo"]
                            }
                        })
                        puntaje_promedio = puntaje_promedio / Math.max(n, 1)
                        this.kpiAsignatura[i] = [
                            n,
                            puntaje_promedio,
                            ultimo,
                            correctas,
                            incorrectas,
                            omitidas,
                            puntaje_maximo
                        ]
                    } else {
                        this.kpiAsignatura[i] = ["-", "-", "-", "-", "-", "-", "-"]
                    }
                })
            })
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.sub.unsubscribe()
    }

    getUniqIds(arr) {
        const ids = []
        const result = []
        ;(arr || []).forEach(element => {
            ids.push(element._id)
            if (ids.filter(id => id.$oid === element._id.$oid).length == 1) {
                result.push(element)
            }
        })
        return result
    }
}
