/** @format */

import { RegistroActions, RegistroActionTypes } from "./../actions/registro.actions"
export interface CodigoConfirmacionData {
    id: number
    email: string
    tipo_envio: string
}

export interface State {
    codigoConfirmacionData: CodigoConfirmacionData | null
}

export const initialState: State = {
    codigoConfirmacionData: null
}

export function reducer(state = initialState, action: RegistroActions): State {
    switch (action.type) {
        case RegistroActionTypes.SetCodigoConfirmacionData:
            return { ...state, ...action.payload }

        case RegistroActionTypes.ClearCodigoConfirmacionData:
            return { ...state, codigoConfirmacionData: null }

        default:
            return state
    }
}
