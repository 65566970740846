import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { select, Store } from "@ngrx/store"
import { filter } from "rxjs/operators"
import {
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    State,
    SetSelectedEvaluacionTipo,
    SetSelectedAsignaturaWithId,
    selectEvaluacionTipoAliases
} from "@puntaje/puntaje/store"
import { Subscription, combineLatest } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "ensayo-instancias.component.html"
})
export class EnsayoInstanciasComponent implements OnInit, OnDestroy {
    asignaturaId: number
    tipoInstrumento: string
    sub: Subscription
    asignatura: Asignatura

    subQuery: Subscription
    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    subData: Subscription
    theme: string
    hideNombre = false
    hideCompartidoPor = false
    showTitleOverMenuAsignaturas = true
    urlRealizar = "/ensayos"
    disableDownloadNotPropio = false
    hideBtnEstadisticaByEvalTipo = false
    attrPresencial = false
    evaluacionTipos: string[] = []
    hideReforzamiento = false
    allTipoInstrumentos = false

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected authService: AuthService,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.ensayo_instancias")
        this.subQuery = combineLatest(this.asignatura$, this.evaluacionTipo$).subscribe(
            ([asignatura, evaluacionTipo]) => {
                this.asignatura = asignatura
                this.asignaturaId = asignatura.id
                this.tipoInstrumento = evaluacionTipo
            }
        )

        this.subData = this.route.data.subscribe(data => {
            this.theme = data.theme ?? this.theme
            this.hideNombre = data.hideNombre ?? this.hideNombre
            this.hideCompartidoPor = data.hideCompartidoPor ?? this.hideCompartidoPor
            this.showTitleOverMenuAsignaturas = data.showTitleOverMenuAsignaturas ?? this.showTitleOverMenuAsignaturas
            this.urlRealizar = data.urlRealizar ?? this.urlRealizar
            this.disableDownloadNotPropio = data.disableDownloadNotPropio ?? this.disableDownloadNotPropio
            this.hideBtnEstadisticaByEvalTipo = data.hideBtnEstadisticaByEvalTipo ?? this.hideBtnEstadisticaByEvalTipo
            this.attrPresencial = data.attrPresencial ?? this.attrPresencial
            this.evaluacionTipos = data.evaluacionTipos ?? this.evaluacionTipos
            this.hideReforzamiento = data.hideReforzamiento ?? this.hideReforzamiento
            this.allTipoInstrumentos = data.allTipoInstrumentos ?? this.allTipoInstrumentos
        })
    }

    ngOnDestroy() {
        this.subQuery.unsubscribe()
        this.subData.unsubscribe()
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    onSelectAsignatura(asignaturaId) {
        this.asignaturaId = asignaturaId
    }

    onReadyAsignaturaDropdown(asignaturaId) {
        this.router.navigate(["/ensayo_instancias"], { queryParams: { asignatura_id: asignaturaId } })
    }
}
