<div class="seccion" #externalFrameReference>
    <cui-section-headline>
        1. Seleccione un método para generar {{ tipoEvaluacion | transformInstrumento: "conArticuloIndefinido" }}
    </cui-section-headline>
    <p class="tutorial">
        {{ tipoEvaluacion | transformInstrumento: "conArticuloPlural" | capitalize }} se generan a partir de un
        Instrumento de evaluación.
        <br />
        Seleccione un instrumento para generar la evaluación a partir de las siguientes opciones:
    </p>
    <div class="">
        <h4 class="h4_sub_title_1">
            <fa name="arrow-right" class="highlight-chevron"></fa>
            &nbsp;Seleccione alguno de nuestros instrumentos recomendados
        </h4>
        <ensayos-multiples-recomendados
            (onReadyInstrumento)="callbackRecomendado($event)"
        ></ensayos-multiples-recomendados>
    </div>
</div>

<div easyScroll [scrollOnVariableChange]="instrumentoMultiple" [easyScrollPadding]="-100">
    <div *ngIf="instrumentoMultiple">
        <cui-section-headline id="instrumentoSeleccionado">
            2. Compartir {{ tipoEvaluacion | transformInstrumento: "singular" }} a partir del Instrumento seleccionado
            de la sección "evaluar"
        </cui-section-headline>
        <p class="tutorial">
            Para terminar de generar {{ tipoEvaluacion | transformInstrumento: "conArticuloIndefinido" }}, elija la
            opción de Compartir en el menú.
        </p>
        <div class="row">
            <div class="col-md-4 inverted-col">
                <opciones-instrumento-multiple
                    [instrumentoMultiple]="instrumentoMultiple"
                    [tipoEvaluacion]="'simulacro saber11'"
                    [scrollAndFollow]="true"
                    [responsiveBreak]="991"
                    [topPadding]="20"
                    [bottomPadding]="20"
                    [followTopReference]="followReference"
                    [followBottomReference]="followReference"
                    [redirect]="redirect"
                ></opciones-instrumento-multiple>
            </div>
            <div class="col-md-8" #followReference>
                <instrumento-multiple
                    [instrumentoMultiple]="instrumentoMultiple"
                    [enableOpcionesPregunta]="enableOpcionesPregunta"
                ></instrumento-multiple>
            </div>
        </div>
    </div>
</div>
