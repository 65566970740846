import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { CarrerasAuthService } from "./carreras_auth.service"
import { CarrerasBaseService } from "./carreras_base.service"
import { Carrera } from "./carreras.model"

@Injectable()
export class Carreras extends CarrerasBaseService<Carrera> {
    tableName = "carreras"
    singularTableName = "carrera"
    modelClass = Carrera

    constructor(
        protected http: HttpClient,
        protected auth: CarrerasAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
