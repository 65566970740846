import { Component, Directive, ViewChild, AfterContentInit, Input, ChangeDetectorRef, TemplateRef } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { Subscription, queueScheduler } from "rxjs"
import { observeOn } from "rxjs/operators"
import { LoggedLayoutService } from "./logged-layout.service"

/*#################################

Template simple para páginas una vez que usuario se haya logeado. Incluye el navbar en la parte
superior.
Implementa una sección de contenido, un título, un subtítulo y un set de tabs (no son requeridos, pero título puede incluir
un valor por defecto si no está definido).

logged-layout-toolnavbar
logged-layout-titulo
logged-layout-subtitulo
logged-layout-contenido
logged-layout-tabs
logged-layout-topside
logged-layout-over-title

#################################*/

@Component({
    templateUrl: "cui-logged-layout.component.html",
    selector: "cui-logged-layout",
    styleUrls: ["cui-logged-layout.component.scss"]
})
export class CUILoggedLayoutComponent {
    @ViewChild("loggedLayoutContenido", { static: true }) loggedLayoutContenido

    showTabs: boolean = false
    showContenido: boolean = false
    showTopside: boolean = false
    showOverTitle: boolean = false
    showMiddleGreySection: boolean = false
    showContenidoExtra: boolean = false
    showTopContenido: boolean = false

    sub: Subscription
    subTemplate: Subscription

    @Input() freeContentStyle: boolean = false
    @Input() hideTitleOnPrint: boolean = false
    @Input() noTitleBar: boolean = false

    templateObj: { [name: string]: TemplateRef<any> }

    constructor(
        public authService: AuthService,
        protected cdr: ChangeDetectorRef,
        protected loggedLayoutService: LoggedLayoutService
    ) {}

    ngOnInit() {
        this.loggedLayoutService.resetSlots()
        this.loggedLayoutService.setOptions({
            freeContentStyle: this.freeContentStyle,
            noTitleBar: this.noTitleBar,
            hideTitleOnPrint: this.hideTitleOnPrint
        })

        this.sub = this.loggedLayoutService.options$.subscribe(options => {
            this.freeContentStyle = options.freeContentStyle
            this.noTitleBar = options.noTitleBar
            this.hideTitleOnPrint = options.hideTitleOnPrint
        })

        this.subTemplate = this.loggedLayoutService.templates$.pipe(observeOn(queueScheduler)).subscribe(templates => {
            this.templateObj = templates

            this.checkSlots()
            this.cdr.detectChanges()
        })
    }

    checkSlots() {
        this.showTabs = !!this.templateObj?.["tabs"]
        this.showContenido = this.loggedLayoutContenido.nativeElement.children.length > 0
        this.showTopside = !!this.templateObj?.["topside"]
        this.showOverTitle = !!this.templateObj?.["over-title"]
        this.showMiddleGreySection = !!this.templateObj?.["middle-grey-section"]
        this.showContenidoExtra = !!this.templateObj?.["contenido-extra"]
        this.showTopContenido = !!this.templateObj?.["top-contenido"]
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
        this.subTemplate.unsubscribe()
    }
}
