/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, Input, ElementRef } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    Audiovisual,
    CarreraSede,
    CarreraSedes,
    PreferenciaCarreraSede,
    PreferenciaCarreraSedes,
    SolicitudInformaciones,
    SolicitudInformacion
} from "@puntaje/carreras/api-services"
import { Router, ActivatedRoute } from "@angular/router"
import { CarrerasPonderaciones } from "../carreras-ponderaciones.service"
import { AuthService } from "@puntaje/shared/core"
import { MapsAPILoader } from "@agm/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
// import {} from "googlemaps"

@Component({
    selector: "carrera-sede",
    templateUrl: "./carrera-sede.component.html",
    styleUrls: ["./carrera-sede.component.scss"]
})
export class CarreraSedeComponent implements OnInit {
    @Input() carreraSedeId: number

    carreraSede: CarreraSede
    preferenciaCarreraSedes: PreferenciaCarreraSede[] = []
    orden = 1
    malla: Audiovisual
    video: Audiovisual
    following = false
    params: any = {}
    audiovisuales: Audiovisual[] = []
    editingOrden = false
    latitude = -28.68352
    longitude = -147.20785
    showMap = false
    zoom: number
    mapType = "roadmap"
    geoCoder: any
    informacionSolicitada = false
    @Input() enableSolicitarInformacion = false //flag para deshabilitar esto por ahora
    promedioPonderado: number
    simulacionPonderado: number
    disablePonderaciones: boolean = !config.carreras?.ponderables || config.carreras.ponderables.length == +0

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("search", { static: true }) public searchElementRef: ElementRef

    constructor(
        protected carreraSedesService: CarreraSedes,
        protected carrerasPonderaciones: CarrerasPonderaciones,
        protected preferenciaCarreraSedesService: PreferenciaCarreraSedes,
        protected solicitudInformacionesService: SolicitudInformaciones,
        protected router: Router,
        protected route: ActivatedRoute,
        protected authService: AuthService,
        private mapsAPILoader: MapsAPILoader
    ) {}

    ngOnInit() {
        this.loadingLayout.standby()
        this.solicitudInformacionesService
            .where({
                solicitudInformacion: {
                    carreraSedeId: this.carreraSedeId,
                    usuarioId: this.authService.getUserData().id
                }
            })
            .then(informaciones => {
                this.informacionSolicitada = informaciones.length > 0
            })

        this.setData()
    }

    setData() {
        const carreraSedeParams = {
            preferenciaCarreraSede: {
                usuarioId: this.authService.getUserData().id
            },
            include:
                "[preferencia_carrera_sedes?,carrera_sede_campo_extras?:campo_extra?,info_adicionales?,audiovisuales?:tipo_audiovisual,sede:universidad,ponderaciones?:ponderable?]"
        }

        this.carreraSedesService.find(this.carreraSedeId, carreraSedeParams).then((carreraSede: CarreraSede) => {
            if (carreraSede) {
                this.carreraSede = carreraSede

                this.following = this.carreraSede.preferenciaCarreraSedes.length > 0
                if (this.following) {
                    this.orden = this.carreraSede.preferenciaCarreraSedes[0].orden
                }

                this.carrerasPonderaciones.loadVariables()

                this.searchLocationAddress(this.carreraSede.sede.direccion)

                this.malla = this.carreraSede.audiovisuales.find(
                    audiovisual => audiovisual.tipoAudiovisual.tipoAudiovisual == "Malla"
                )
                this.video = this.carreraSede.audiovisuales.find(
                    audiovisual => audiovisual.tipoAudiovisual.tipoAudiovisual == "Video"
                )

                const preferenciaParams = {
                    preferenciaCarreraSede: {
                        usuarioId: this.authService.getUserData().id
                    }
                }
                this.preferenciaCarreraSedesService
                    .where(preferenciaParams)
                    .then((preferencias: PreferenciaCarreraSede[]) => {
                        this.preferenciaCarreraSedes = preferencias

                        this.loadingLayout.ready()
                    })
            }
        })
    }

    startEditOrden() {
        this.editingOrden = true
    }

    updateCarreraSede(event) {
        this.setData()
    }

    updateOrden() {
        const preferenciaCarreraSede = this.carreraSede.preferenciaCarreraSedes[0]
        const oldOrden = preferenciaCarreraSede.orden
        const newOrden = this.orden
        const preferenciaCarreraSedeRelated = this.preferenciaCarreraSedes.find(pcs => pcs.orden == newOrden)

        if (!preferenciaCarreraSedeRelated) return

        this.editingOrden = true
        const updatedPreferenciaCarreraSede = new PreferenciaCarreraSede()
        Object.assign(updatedPreferenciaCarreraSede, preferenciaCarreraSede)
        updatedPreferenciaCarreraSede.orden = newOrden
        this.preferenciaCarreraSedesService
            .update(updatedPreferenciaCarreraSede.id, updatedPreferenciaCarreraSede)
            .then(pcs => {
                preferenciaCarreraSedeRelated.orden = oldOrden
                preferenciaCarreraSede.orden = pcs.orden

                this.editingOrden = false
            })
    }

    searchLocationAddress(address: string) {
        if (!address) {
            return
        }
        this.mapsAPILoader.load().then(() => {
            const service = new google.maps.places.AutocompleteService()
            service.getQueryPredictions({ input: address }, (predictions, status) => {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    if (predictions.length > 0) {
                        const service = new google.maps.places.PlacesService(document.createElement("div"))
                        service.getDetails(
                            {
                                placeId: predictions[0].place_id
                            },
                            (place, status) => {
                                if (status === google.maps.places.PlacesServiceStatus.OK) {
                                    //verify result
                                    if (place.geometry === undefined || place.geometry === null) {
                                        return
                                    }

                                    //set latitude, longitude and zoom
                                    this.latitude = place.geometry.location.lat()
                                    this.longitude = place.geometry.location.lng()
                                    this.showMap = true
                                }
                            }
                        )
                    }
                }
            })
        })
    }

    solicitarInformacion() {
        const si = new SolicitudInformacion()
        const usuario = this.authService.getUserData()
        si.plataformaId = config.plataforma.id
        si.carreraSedeId = this.carreraSedeId
        si.usuarioId = usuario.id
        si.nombreCompleto = `${usuario.nombre} ${usuario.apellido_paterno} ${usuario.apellido_materno}`
        si.identificadorPais = usuario.usuario_chile.rut
        si.nem = usuario.usuario_chile.nem
        this.solicitudInformacionesService.save(si).then(solicitud => (this.informacionSolicitada = true))
    }
}
