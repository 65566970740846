import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { EvaluacionInstancia, EvaluacionInstancias } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "calificacion",
    templateUrl: "./calificacion.component.html",
    styleUrls: ["./calificacion.component.scss"]
})
export class CalificacionComponent implements OnInit {
    calificacion: number
    @Input() evaluacionInstancia: EvaluacionInstancia
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    showEdit: boolean = false
    config: typeof config = config
    mensajeInf: string
    rangoCalificacion
    placeHolder: string

    constructor(protected evaluacionInstanciasService: EvaluacionInstancias) {}

    ngOnInit() {
        this.rangoCalificacion = config.evaluaciones[
            this.evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo
        ]?.rangoCalificacion || { min: 0, max: 100 }

        this.placeHolder = "Ingrese valor de " + this.rangoCalificacion.min + " a " + this.rangoCalificacion.max
        this.loadingLayout.ready()
    }

    changeEdit() {
        this.showEdit = !this.showEdit
    }

    calificarManual() {
        this.loadingLayout.standby()
        this.mensajeInf = ""
        if (this.calificacion == undefined) {
            this.mensajeInf = "Debe ingresar una nota"
            this.loadingLayout.ready()
        } else {
            if (this.calificacion < this.rangoCalificacion.min || this.calificacion > this.rangoCalificacion.max) {
                this.mensajeInf =
                    "La calificacion debe ser en un rango entre " +
                    this.rangoCalificacion.min +
                    " y " +
                    this.rangoCalificacion.max
                this.loadingLayout.ready()
            } else {
                this.evaluacionInstancia.calificacion = this.calificacion
                this.evaluacionInstanciasService
                    .update(this.evaluacionInstancia.id, this.evaluacionInstancia)
                    .then(response => {
                        this.changeEdit()
                        this.loadingLayout.ready()
                    })
            }
        }
    }
}
