<div class="post-thumbnail-container" *ngIf="post">
    <a [routerLink]="[route + post.slug]">
        <img *ngIf="post && post.imagen_sm" [src]="post.imagen_sm" alt="imagen post" class="imagen img-post" />
        <img
            *ngIf="!post.imagen_sm"
            src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/blog/default.png"
            alt="imagen post"
            class="imagen img-default"
        />
    </a>
    <div class="caption">
        <p class="tag">{{ post.tag | uppercase }}</p>
        <h3 class="titulo">
            <a [routerLink]="[route + post.slug]">{{ post.titulo }}</a>
        </h3>
        <p class="resumen">{{ post.resumen }}</p>
        <p class="info">
            <b class="autor">{{ autor }}</b>
            <span class="separador"></span>
            <span>{{ post.created_at | prettydate }}</span>
        </p>
    </div>
</div>
