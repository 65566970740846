import { Action } from "@ngrx/store"
import { Logro } from "@puntaje/achievements/api-services"

export enum AlertasActionTypes {
    CrearAlertaLogros = "[AlertasAchievements] Crear Alertas Logros",
    ResetAlertaLogros = "[AlertasAchievements] Resetear Alertas Logros"
}

export class CrearAlertaLogros implements Action {
    readonly type = AlertasActionTypes.CrearAlertaLogros
    constructor(public payload: { logros: Logro[] }) {}
}

export class ResetAlertaLogros implements Action {
    readonly type = AlertasActionTypes.ResetAlertaLogros
}

export type AlertasActions = CrearAlertaLogros | ResetAlertaLogros
