import { GenderizePipe } from "./genederize.pipe"
import { Diccionario } from "./diccionario.interface"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { TranslatorService } from "./translator.service"
import { I18nService } from "./i18n.service"
@NgModule({
    imports: [],
    declarations: [GenderizePipe],
    exports: [GenderizePipe]
})
export class TranslationsModule {
    /**
     * Configura el modulo de traducciones
     * Por ahora se espera que el diccionario este bien configurado
     *
     * @param {() => Diccionario} diccionario - valores usados por el servicio
     */
    static forRoot(diccionario: () => Diccionario): ModuleWithProviders<TranslationsModule> {
        return {
            ngModule: TranslationsModule,
            providers: [
                TranslatorService,
                I18nService,
                {
                    provide: "DICCIONARIO",
                    useFactory: diccionario
                }
            ]
        }
    }
}
