import { BaseModel } from "@puntaje/shared/core"

export class Curriculum extends BaseModel {
    public static className: string = "Curriculum"
    public id: number
    public curriculum: string
    public created_at: Date
    public updated_at: Date

    public toString() {
        return this.curriculum
    }
}
