import { OnInit, OnDestroy, Directive } from "@angular/core"
import { AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { Observable, Subscription } from "rxjs"
import { filter, first } from "rxjs/operators"

/*#################################

Menú sitio.

#################################*/

@Directive({ selector: "internal-menu" })
export class MenuComponent implements OnInit, OnDestroy {
    asignaturasByEvaluacionTipo$: Observable<{ [tipoEvaluacion: string]: AsignaturaWithConfig[] }> = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x),
        first()
    )
    sub: Subscription

    constructor(protected store: Store<State>) {}

    ngOnInit() {}

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
    }
}
