import { BaseModel, Validations, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { Lugar } from "./lugares.model"
import { Establecimiento } from "./establecimientos.model"
import { Pais } from "./paises.model"
import { UsuarioRegistroBase } from "./usuarios_registro_base.model"

export class UsuarioRegistroCB extends UsuarioRegistroBase {
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public fecha_nacimiento: Date
    public email: string

    @SaveType(() => Pais) public pais: Pais

    public pais_id: number
    public password: string
    public password_confirmation: string
    public terminos_y_condiciones: boolean
    public is_registro: boolean

    public static global_validations = [Validations.equalPasswords(["password", "password_confirmation"])]

    constructor() {
        super()
    }

    public nombreCompleto() {
        return this.nombre + " " + this.apellido_paterno
    }

    public toString() {
        return this.email
    }

    public toUsuario() {
        var user = new Usuario()
        user.nombre = this.nombre
        user.apellido_paterno = this.apellido_paterno
        user.apellido_materno = this.apellido_materno
        user.fecha_nacimiento = this.fecha_nacimiento
        user.email = this.email

        user.password_confirmation = this.password_confirmation
        user.password = this.password

        if (this.pais && this.pais.id) {
            user.pais_id = this.pais.id // Generalizar
        }
        user.rol_id = 3 // Generalizar, debería siempre ser 3 (alumno)
        user.usuario_colombia = null
        user.usuario_chile = null
        user.usuario_tec = null
        user.is_registro = true
        return user
    }
}
