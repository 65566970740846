import { Subscription } from "rxjs"
import { filter, debounceTime, distinctUntilChanged } from "rxjs/operators"
import { Component, Input, forwardRef, Output, EventEmitter, ViewChild } from "@angular/core"
import { UntypedFormControl } from "@angular/forms"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"
import { BaseModel } from "../api/base.model"

@Component({
    selector: "autocomplete",
    template: `
        <div class="form-group">
            <input
                type="text"
                class="form-control autocomplete-input"
                [attr.disabled]="disabled ? '' : null"
                placeholder="{{ placeholder }}"
                [formControl]="control"
                (keyup)="filter($event.code)"
                (window:keydown)="keepIndex($event.code)"
                (focus)="onAutocompleteFocus()"
                (focusout)="onAutocompleteLoseFocus()"
            />
            <div class="loader-autocomplete" *ngIf="showLoader"></div>
            <div class="suggestions-container">
                <div class="suggestions" *ngIf="options?.length > 0" #suggestions>
                    <ul class="ui-autocomplete ui-menu" *ngIf="hasFocus">
                        <li
                            *ngFor="let item of options; let i = index"
                            class="ui-menu-item"
                            [class.ui-state-hover]="filterId == i + 1"
                            [focused]="filterId == i + 1"
                            (mouseover)="hoverSelect(i)"
                        >
                            <a *ngIf="!showTextFun" (click)="select(item)">{{ item }}</a>
                            <a *ngIf="showTextFun" (click)="select(item)">{{ $any(item)[showTextFun]() }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    `,
    styleUrls: ["autocomplete.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true
        }
    ]
})
export class AutocompleteComponent implements ControlValueAccessor {
    sub: Subscription

    control: UntypedFormControl = new UntypedFormControl("")

    selected: BaseModel | string

    _options: BaseModel[]
    @Input() showTextFun: string
    @Input() placeholder: string
    @Output() filterChange: EventEmitter<any> = new EventEmitter<any>()
    @Output() controlValue: EventEmitter<any> = new EventEmitter<any>()
    showLoader: boolean = false
    filterId: number
    onChange: (_: any) => {}
    onTouch = () => {}
    @ViewChild("suggestions") suggestions
    hasFocus: boolean = false
    @Input() disabled: boolean = false
    @Input() autoSelect: boolean = true
    @Input()
    set options(options: BaseModel[]) {
        this._options = options
        this.showLoader = false
    }
    get options() {
        return this._options
    }

    ngOnInit() {
        this.sub = this.control.valueChanges
            .pipe(
                filter(v => v.length >= 1),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe(value => {
                this.showLoader = true
                this.filterChange.emit(value)
            })
    }

    writeValue(value: any) {
        if (value instanceof BaseModel) {
            this.selected = value
            const modelToString = this.showTextFun ? value[this.showTextFun]() : value
            this.control.setValue(modelToString ? modelToString.toString() : "", { emitEvent: false })
            /*this.onChange(this.selected);*/
        } else if (value == null || value == undefined) {
            this.selected = null
            this.control.setValue("", { emitEvent: false })
        }
    }

    onAutocompleteFocus() {
        //(this as any).filter();
        this.hasFocus = true
    }

    onAutocompleteLoseFocus() {
        this.hasFocus = false
        if (this.options && this.options.length != 0) {
            this.select(this.options[this.filterId - 1])
        } else if (!this.autoSelect) {
            this.select(null)
        } else if (!this.selected) {
            this.select(null)
        }

        this.onTouch()
    }

    filter(code = null) {
        //     if (this.selected && this.options && this.selected !== "" && code != "Enter" && code != "ArrowRight" && code != "ArrowLeft") {
        //         this.options = this.options.filter(function(el){
        //             return el.toString().toLowerCase().indexOf(this.value.toString().toLowerCase()) > -1;
        //         }.bind(this));
        //     } else {
        //         this.options = [];
        //     }
    }

    hoverSelect(item) {
        this.filterId = item + 1
    }

    select(item) {
        if (!item && this.options && this.options.length >= 1) {
            if (this.autoSelect) {
                item = this.options[0]
            } else {
                item = null
            }
        } else if (!item && this.options && this.options.length == 0) {
            item = null
        }
        this.selected = item
        this.onChange(item)
        const modelToString = this.showTextFun && item ? item[this.showTextFun]() : item
        this.control.setValue(modelToString ? modelToString.toString() : "", { emitEvent: false })
        this.controlValue.emit(item)
        this.options = []
        this.filterId = 0
    }

    keepIndex(code) {
        if (code != "Escape") {
            if (this.filterId !== undefined && this.filterId >= 0) {
                if (code == "ArrowDown") {
                    if (this.options) {
                        this.filterId = Math.min(this.options.length, this.filterId + 1)
                    } else {
                        this.filterId = 0
                    }
                } else if (code == "ArrowUp") {
                    this.filterId = Math.max(0, this.filterId - 1)
                } else if (code == "Enter") {
                    ;(document.activeElement as HTMLElement).blur()
                }
            } else {
                this.options = []
                this.filterId = 0
            }
        } else {
            this.options = []
            this.select(null)
        }
    }

    registerOnChange(fn: (value: any) => any) {
        this.onChange = fn
    }

    registerOnTouched(fn: () => any) {
        this.onTouch = fn
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
