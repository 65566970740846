<loading-layout #loadingLayout>
    <nav
        class="menu-asignaturas print-hide"
        [class.enableResponsive]="enableResponsive"
        [class.dropdownMode]="activateDropdownMode"
        [class.openDropdown]="activateDropdownMode && openDropdown"
        [attr.aria-label]="'progreso.profe.menu_asignatura.sel' | t"
        resize-aware
        #navContainer
        #resizeNav
        (currentSize)="adaptToSize($event)"
        *ngIf="lista_asignaturas && lista_asignaturas.length && lista_asignaturas.length > 0"
    >
        <ng-container *ngIf="currentAsignatura && activateDropdownMode">
            <a
                class=" asignaturaDropdown {{
                    currentAsignatura.confByEvaluacionTipo[tipoEvaluacion || evaluacionTipoDefault].clase
                }}"
                aria-current
                (click)="toggleDropdown()"
                href="javascript:void(0)"
            >
                <span class="name-wrap">
                    <ogr-icon
                        [name]="currentAsignatura.confByEvaluacionTipo[tipoEvaluacion || evaluacionTipoDefault].icono"
                        class="icono"
                        align="center"
                    ></ogr-icon>
                    <span class="texto">
                        {{
                            currentAsignatura.confByEvaluacionTipo[tipoEvaluacion || evaluacionTipoDefault].abreviacion
                        }}
                    </span>
                </span>
                <fa name="caret-down" class="drop-icon"></fa>
            </a>
        </ng-container>
        <div class="list-wrap">
            <div class="shadow-wrap">
                <ul>
                    <li
                        *ngIf="enableAllBtn"
                        [class.current-asignatura]="!asignaturaId"
                        [class.inactive]="!!asignaturaId"
                    >
                        <a
                            class="all-btn"
                            [attr.aria-current]="!asignaturaId"
                            (click)="changeAsignatura(0)"
                            href="javascript:void(0)"
                        >
                            <ogr-icon name="todos" class="icono" align="center"></ogr-icon>
                            <span class="texto">Ver todos</span>
                        </a>
                    </li>
                    <li
                        *ngFor="let a of lista_asignaturas"
                        [class.current-asignatura]="a.id == asignaturaId"
                        [class.inactive]="a.id != asignaturaId"
                    >
                        <a
                            class="{{ a.confByEvaluacionTipo[currentEvaluacionTipo].clase }}"
                            [attr.aria-current]="a.id == asignaturaId"
                            (click)="changeAsignatura(a.id)"
                            href="javascript:void(0)"
                            title="{{ a.confByEvaluacionTipo[currentEvaluacionTipo].abreviacion }}"
                        >
                            <ogr-icon
                                [name]="a.confByEvaluacionTipo[currentEvaluacionTipo].icono"
                                class="icono"
                                align="center"
                            ></ogr-icon>
                            <span class="texto">
                                {{ a.confByEvaluacionTipo[currentEvaluacionTipo].abreviacion }}
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <div *ngIf="!lista_asignaturas || lista_asignaturas.length === 0">
        <div>
            <span class="texto">Esta sección no está activa para tu establecimiento.</span>
        </div>
    </div>
</loading-layout>
