import { Component, Input, OnInit } from "@angular/core"
import { Asignatura, Asignaturas, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { trigger, state, style, animate, transition, keyframes } from "@angular/animations"

@Component({
    selector: "barra-distribucion-asignatura",
    templateUrl: "barra_distribucion_asignatura.component.html",
    styleUrls: ["barra_distribucion_asignatura.component.scss"],

    animations: [
        trigger("dropIn", [
            state("in", style({ transform: "translateY(0)" })),
            transition("void => in", [
                animate(
                    "0.3s 0ms ease-in",
                    keyframes([
                        style({ opacity: 0, transform: "translateY(-10px)", offset: 0 }),
                        style({ opacity: 1, transform: "translateY(2px)", offset: 0.2 }),
                        style({ opacity: 1, transform: "translateY(0)", offset: 1.0 })
                    ])
                )
            ])
        ])
    ]
})
export class BarraDistribucionAsignaturaComponent implements OnInit {
    @Input() asignatura: AsignaturaWithConfig
    @Input() tipoInstrumento: string
    infoReady: boolean = false
    showCategorias: boolean = false
    config: typeof config

    _valores = [0, 0, 0]
    @Input()
    set valores(valores: number[]) {
        this._valores = valores
        if (this.clasificaciones) {
            this.infoReady = true
        }
    }
    get valores() {
        return this._valores
    }

    _clasificaciones: any[]
    @Input()
    set clasificaciones(clasificaciones: any[]) {
        this._clasificaciones = clasificaciones
        if (this.valores) {
            this.infoReady = true
        }
    }
    get clasificaciones() {
        return this._clasificaciones
    }

    constructor() {}

    ngOnInit() {
        this.config = config
    }

    toggleCategorias() {
        this.showCategorias = false
    }

    setClasificacionesValues() {
        if (this.clasificaciones) {
            this.clasificaciones.forEach(c => {
                let total = c.correctas + c.omitidas + c.incorrectas
                let val = (100 / total) * c.correctas
                c.progressValue = val
                if (c.clasificaciones) {
                    c.clasificaciones.forEach(c2 => {
                        let total = c2.correctas + c2.omitidas + c2.incorrectas
                        let val = (100 / total) * c2.correctas
                        c2.progressValue = val
                    })
                }
            })
        }
        this.showCategorias = true
    }

    resetClasificacionesValues() {
        //this.toggleSubejesAnimation = false;
        this.showCategorias = false
        if (this.clasificaciones) {
            this.clasificaciones.forEach(c => {
                c.progressValue = 0
                if (c.clasificaciones) {
                    c.clasificaciones.forEach(c2 => {
                        c2.progressValue = 0
                    })
                }
            })
        }
    }

    getAnimatedValue(value: number) {
        if (this.showCategorias) {
            return value
        } else {
            return 0
        }
    }
}
