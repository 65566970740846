<loading-layout>
    <ng-container *ngIf="planPersonal">
        <!--Descripcion de la asignatura -->
        <div class="{{ asignatura?.clase }} info-container clearfix print-page-break-after">
            <div class="info-container-icon">
                <ogr-icon class="icono" [name]="'lenguaje-3'"></ogr-icon>
            </div>
            <div class="info-container-info">
                <div class="row">
                    <div *ngIf="!disableNivelCalugas" class="col-lg-6 col-md-12 print-hide">
                        <h4 class="title">{{ planPersonal.plan_personal }}</h4>
                        <p class="asignatura">
                            {{
                                asignatura?.asignaturaPlataforma ||
                                    asignatura?.asignatura ||
                                    planPersonal?.generador_instrumento?.asignatura?.asignatura
                            }}{{ nivel ? " - " + nivel.clasificacion : "" }}
                        </p>
                    </div>
                    <div *ngIf="disableNivelCalugas" class="col-lg-6 col-md-12 print-hide">
                        <h4 class="title">{{ planPersonal.plan_personal }}</h4>
                        <p class="asignatura">
                            {{ planPersonal.generador_instrumento.asignatura.asignatura }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!--Plan semanal -->
        <div class="planes-semanales">
            <div *ngFor="let planPersonalSesion of planPersonalSesiones; let i = index">
                <plan-clase-semanas-alumnos-new
                    [planPersonal]="planPersonal"
                    [planPersonalSesion]="planPersonalSesion"
                    [index]="i"
                    [clasificacionActividadByMaterialId]="clasificacionActividadByMaterialId"
                    [clasificacionContextoByMaterialId]="clasificacionContextoByMaterialId"
                    [grupoUsuarioId]="grupoUsuario?.id"
                    [vistaPrevia]="vistaPrevia"
                    [baseRoute]="baseRoute"
                    [position]="indexImpar(i) ? 'right' : 'left'"
                    [isLast]="i === planPersonalSesiones.length - 1"
                    [beforePlanContenidos]="planPersonalSesiones[i - 1]?.plan_personal_contenidos"
                ></plan-clase-semanas-alumnos-new>
            </div>
        </div>
    </ng-container>
</loading-layout>
