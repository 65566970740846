<generic-modal [buttonClicked]="openModal" [id]="modalId" (closeCrossCallback)="cancelar()">
    <modal-titulo>Compartir {{ tipoEvaluacion | transformInstrumento: "singular" }}</modal-titulo>
    <modal-contenido class="modal-contenido" *ngIf="!resetForm">
        <form #form="ngForm" *ngIf="!evaluacionMultipleCompartida">
            <h4 class="h4_sub_title_1">
                Crear {{ "nuevo" | genderize: tipoEvaluacion }} {{ tipoEvaluacion | transformInstrumento: "singular" }}:
            </h4>
            <div class="row">
                <div class="col-sm-12">
                    <input
                        #nombre="ngModel"
                        type="text"
                        [(ngModel)]="evaluacionMultiple.evaluacion_multiple"
                        name="nombre"
                        placeholder="Nombre {{ tipoEvaluacion | transformInstrumento: 'singular' }}"
                        class="form-control"
                        required
                    />
                    <div
                        *ngIf="nombre.errors && (nombre.dirty || nombre.touched || validationsPending)"
                        class="error-warning"
                    >
                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                        {{ tipoEvaluacion | transformInstrumento: "conArticulo" | capitalize }} debe tener un nombre.
                    </div>
                </div>
            </div>

            <div class="fechas-container">
                <h4 class="h4_sub_title_1 no-bottom-margin">
                    {{
                        "compartir_inst.exp_fecha"
                            | t: { evaluacionTipo: (tipoEvaluacion | transformInstrumento: "conDeterminante") }
                    }}:
                </h4>
                <div class="row">
                    <div class="col-sm-6">
                        <p>{{ "compartir_inst.desde" | t }}:</p>
                        <datepicker-form
                            #fechaInicio="ngModel"
                            name="fechaInicio"
                            [(ngModel)]="evaluacion.fecha_inicio"
                            required
                        ></datepicker-form>
                        <div
                            *ngIf="
                                fechaInicio.errors && (fechaInicio.dirty || fechaInicio.touched || validationsPending)
                            "
                            class="error-warning"
                        >
                            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                            {{ tipoEvaluacion | transformInstrumento: "conArticulo" | capitalize }}
                            {{ "compartir_inst.desde_warn" | t }}.
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <p>{{ "compartir_inst.hasta" | t }}:</p>
                        <datepicker-form
                            #fechaTermino="ngModel"
                            name="fechaTermino"
                            [(ngModel)]="evaluacion.fecha_termino"
                            required
                        ></datepicker-form>
                        <div
                            *ngIf="
                                fechaTermino.errors &&
                                (fechaTermino.dirty || fechaTermino.touched || validationsPending)
                            "
                            class="error-warning"
                        >
                            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                            {{ tipoEvaluacion | transformInstrumento: "conArticulo" | capitalize }}
                            {{ "compartir_inst.hasta_warn" | t }}.
                        </div>
                    </div>
                </div>
                <div *ngIf="!validateDateInterval()" class="error-warning">
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    {{ "compartir_inst.fechas_warn" | t }}.
                </div>
                <h4 class="h4_sub_title_1">
                    {{
                        "compartir_inst.desde_ver"
                            | t: { evaluacionTipo: (tipoEvaluacion | transformInstrumento: "conDeterminante") }
                    }}
                </h4>
                <div class="row">
                    <div class="col-sm-6">
                        <datepicker-form
                            #fechaResultados="ngModel"
                            name="fechaResultados"
                            [(ngModel)]="evaluacion.fecha_mostrar_respuestas"
                            required
                        ></datepicker-form>
                    </div>
                </div>

                <div
                    *ngIf="
                        fechaResultados.errors &&
                        (fechaResultados.dirty || fechaResultados.touched || validationsPending)
                    "
                    class="error-warning"
                >
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    {{ tipoEvaluacion | transformInstrumento: "conArticulo" | capitalize }}
                    {{ "compartir_inst.desde_ver1" | t }}.
                </div>
            </div>

            <ng-template [ngIf]="grupoUsuarios && grupoUsuarios.length > 0 && !compartirTodosCursos">
                <h4 class="h4_sub_title_1">
                    {{ nombreGrupo | pluralize | capitalize }} a los cuales compartir
                    {{ tipoEvaluacion | transformInstrumento: "conArticulo" }}:
                </h4>
                <ng-template [ngIf]="grupoUsuarios">
                    <checkboxes
                        #grupos="ngModel"
                        [options]="grupoUsuarios"
                        [(ngModel)]="gruposProfesor"
                        [groupBy]="groupBy"
                        name="grupos"
                    ></checkboxes>
                    <div
                        *ngIf="(grupos.dirty || validationsPending) && gruposProfesor.length <= 0"
                        class="error-warning"
                    >
                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                        Debe seleccionar al menos un {{ nombreGrupo }}
                    </div>
                </ng-template>
            </ng-template>

            <ng-template [ngIf]="!isAdmin && grupoUsuarios && grupoUsuarios.length == 0 && !compartirTodosCursos">
                Usted no puede compartir una evaluación: No posee {{ nombreGrupo | pluralize }} activos.
            </ng-template>

            <ng-container *ngIf="enableAdminOptions && isAdmin">
                <br />
                <h4 class="h4_sub_title_1">Marque esta opción para evaluaciones masivas:</h4>
                <a class="tutorial-info-link" toggleExpandable="masivo-info">¿Qué es esto?</a>
                <label [attr.for]="'shareWithEverybodyEver'">
                    <input
                        type="checkbox"
                        [(ngModel)]="shareWithEverybodyEver"
                        name="shareWithEverybodyEver"
                        id="shareWithEverybodyEver"
                    />
                    &nbsp;Compartir con todos los estudiantes del sitio.
                </label>
                <div id="masivo-info" expandableContent>
                    <div class="tutorial-info">
                        Opción para administradores. Los estudiantes no recibirán una notificación para esta evaluación.
                        Se recomienda no seleccionar {{ nombreGrupo | pluralize }} de la lista anterior al usar esta
                        opción.
                    </div>
                </div>
            </ng-container>
        </form>
        <div *ngIf="evaluacionMultipleCompartida">
            Se ha creado una evaluacion multiple con ID #{{ evaluacionMultipleCompartida.id }}. 
            <!-- Puedes ver el listado de evaluaciones tipo {{ generadorInstrumento.tipo_instrumento.tipo_instrumento }} compartidas
            <a data-dismiss="modal" class="pointer" (click)="goToListado()">aquí</a>
            . -->
        </div>
    </modal-contenido>
    <modal-botones>
        <loader *ngIf="sharing" class="sharing" loaderType="default-xsmall"></loader>
        <cui-button buttonType="cancel" type="button" class="btn_style_2" data-dismiss="modal" (click)="cancelar()">
            Cancelar
        </cui-button>
        <cui-button
            type="button"
            class="btn_style"
            *ngIf="!evaluacionMultipleCompartida"
            [disabled]="
                (!generadorInstrumento && !generadorInstrumentosByInstrumentoId) ||
                (!isAdmin && grupoUsuarios && grupoUsuarios.length == 0 && !compartirTodosCursos) ||
                sharing
            "
            (click)="compartir()"
        >
            Compartir
        </cui-button>
    </modal-botones>
</generic-modal>
