import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Injectable()
export class AsociacionesBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    constructor() { }

    search(asociacion) {
        let params: any = {}

        asociacion = Object.assign({}, asociacion)

        Object.keys(asociacion).forEach(k => {
            if (asociacion[k] === "" || asociacion[k] === undefined) delete asociacion[k]
        })

        if (asociacion.id && asociacion.id.indexOf(",") != -1) {
            asociacion.id = asociacion.id.split(/\s*,\s*/)
        }

        if (asociacion.like.asociacion === undefined || asociacion.like.asociacion === null || asociacion.like.asociacion == "") {
            delete asociacion.like
        }

        if (asociacion.asociacion_monitor.monitor_id === undefined || asociacion.asociacion_monitor.monitor_id === null || asociacion.asociacion_monitor.monitor_id == "") {
            delete asociacion.asociacion_monitor
        }

        params = {
            asociacion
        }

        this.searchParams.next(params)
    }
}
