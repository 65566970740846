import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()
export class NotificacionAccionesService {
    checked = new Subject<any>()

    constructor() {}

    clickChecked(id) {
        this.checked.next(id)
    }
}
