import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { Rol } from "@puntaje/puntaje/api-services"
import { ToolNavbar } from "@puntaje/shared/core"

@Component({
    templateUrl: "materiales.toolnavbar.component.html",
    selector: "materiales-toolnavbar"
})
export class MaterialesToolNavbarComponent implements OnInit {
    displayNavbar: boolean = true

    constructor(protected router: Router, protected route: ActivatedRoute) {}

    ngOnInit() {}
}
