import {
    Component,
    OnInit,
    ViewChild,
    Output,
    Input,
    EventEmitter,
    SimpleChanges,
    ViewChildren,
    QueryList
} from "@angular/core"
import { Router } from "@angular/router"
import { Material, Materiales, Asignaturas, Asignatura } from "@puntaje/nebulosa/api-services"
import { MaterialFavoritos, MaterialFavorito } from "@puntaje/puntaje/api-services"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService, PaginatorComponent, I18nService } from "@puntaje/shared/core"

@Component({
    selector: "materiales-favoritos",
    templateUrl: "materiales_favoritos.component.html",
    styleUrls: ["materiales_favoritos.component.scss"]
})
export class MaterialesFavoritosComponent implements OnInit {
    asignatura: Asignatura
    materialFavoritos: MaterialFavorito[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent
    config: typeof config = config
    usuario_id: number
    enableRemove: boolean = true

    constructor(
        protected authService: AuthService,
        protected asignaturasService: Asignaturas,
        protected router: Router,
        protected materialFavoritosService: MaterialFavoritos,
        protected simpleModalService: SimpleModalService,
        protected i18nService: I18nService
    ) {
        let usuario = this.authService.getUserData()
        this.usuario_id = usuario.id
    }

    ngOnInit() {
        //this.getFavoritos();
    }

    getFavoritos(page: number = 1, per: number = 10) {
        if (this.loadingLayout) this.loadingLayout.standby()
        let params = {
            page: page,
            per: per,
            material_favorito: {
                usuario_id: this.usuario_id
            },
            methods: "material"
        }

        return this.materialFavoritosService.where(params).then((response: MaterialFavorito[], total: number) => {
            this.materialFavoritos = response
            if (this.loadingLayout) this.loadingLayout.ready()

            return total
        })
    }

    showModalDelete(mf: MaterialFavorito) {
        if (mf && this.enableRemove) {
            this.simpleModalService.setModalHeader(this.i18nService.translate("materiales_favoritos.confirmacion", {}))
            this.simpleModalService.setModalStringContent(
                this.i18nService.translate("materiales_favoritos.confirmacion_mensaje", {})
            )
            this.simpleModalService.setModalCallback(() => this.removeFavorite(mf))
            this.simpleModalService.toggleSimpleModal()
        }
    }

    removeFavorite(mf: MaterialFavorito) {
        this.enableRemove = false
        this.materialFavoritosService.remove(mf.id).then((response: MaterialFavorito) => {
            this.paginator.changePage(this.paginator.currentPage)
            this.enableRemove = true
        })
    }
}
