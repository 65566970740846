import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PuntajeBaseService } from "./puntaje_base.service"
import { LogEvaluacion } from "./log_evaluaciones.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { Router } from "@angular/router"

@Injectable()
export class LogEvaluaciones extends PuntajeBaseService<LogEvaluacion> {
    tableName = "log_evaluaciones"
    singularTableName = "log_evaluacion"
    modelClass = LogEvaluacion

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public eliminarResultadosPorAlumno(evaluacionId: number, usuarioIds: number[]) {
        return this.sendAction("eliminar_resultados_por_alumno", {
            evaluacion_id: evaluacionId,
            usuario_ids: usuarioIds
        })
    }

    public eliminarEvaluacionInstanciasGrupoUsuarios(evaluacionId: number, grupoUsuarioIds: number[]) {
        return this.sendAction("eliminar_evaluacion_instancias_grupo_usuarios", {
            evaluacion_id: evaluacionId,
            grupo_usuario_ids: grupoUsuarioIds
        })
    }

    public eliminarEvaluacionUsuarios(evaluacionId: number, grupoUsuarioIds: number[]) {
        return this.sendAction("eliminar_evaluacion_usuarios", {
            evaluacion_id: evaluacionId,
            grupo_usuario_ids: grupoUsuarioIds
        })
    }

    public eliminarEvaluacion(evaluacionId: number) {
        return this.sendAction("eliminar_evaluacion", {
            evaluacion_id: evaluacionId
        })
    }

    protected sendAction(actionName: string, params: any) {
        return this.http.post(this.environment.endpoints.base + "/log_evaluaciones/" + actionName, params, {
            headers: { Authorization: this.auth.getToken() }
        })
    }
}
