<div class="content-box" *ngIf="asignatura && infoReady && config">
    <h3 class="box-title {{ asignatura.clase }}">
        <ogr-icon name="{{ asignatura.icono }}" align="bottom" class="icono"></ogr-icon>
        {{ asignatura["confByEvaluacionTipo"][tipoInstrumento].abreviacion }}
    </h3>
    <div class="main-bar-container">
        <horizontal-stacked-bar [values]="valores" [isMain]="true"></horizontal-stacked-bar>
    </div>
    <div class="verMas" *ngIf="clasificaciones.length != 0">
        <a toggleExpandable="barras-subejes-{{ asignatura.asignatura }}" (click)="toggleCategorias()">
            <span>Ver {{ config.evaluaciones[tipoInstrumento].clasificacionDistribucion }}...</span>
            <span class="ocultar">Ocultar {{ config.evaluaciones[tipoInstrumento].clasificacionDistribucion }}...</span>
        </a>
    </div>
    <div id="barras-subejes-{{ asignatura.asignatura }}" class="clasificaciones" [expandableContent]>
        <div *ngFor="let c of clasificaciones" class="secondaryProgressbar-container">
            <h4>{{ c.clasificacion }}</h4>
            <horizontal-stacked-bar [values]="[c.correctas, c.incorrectas, c.omitidas]"></horizontal-stacked-bar>
            <div id="barras-subsubejes-{{ asignatura.asignatura }}" class="subClasificaciones">
                <div *ngFor="let c2 of c.clasificaciones">
                    <h4>{{ c2.clasificacion }}</h4>
                    <horizontal-stacked-bar
                        [values]="[c2.correctas, c2.incorrectas, c2.omitidas]"
                        class="tertiaryProgressbar"
                    ></horizontal-stacked-bar>
                </div>
            </div>
        </div>
    </div>
</div>
