import { Injectable, TemplateRef } from "@angular/core"
import { BehaviorSubject } from "rxjs"

interface TemplateRefMap {
    [name: string]: TemplateRef<any>
}

@Injectable({ providedIn: "root" })
export class LayoutSlotsService {
    templates = {}

    templates$ = new BehaviorSubject<TemplateRefMap>(this.templates)

    resetSlots() {
        this.templates = {}
        this.templates$.next(this.templates)
    }

    addSlot(name: string, templateRef: TemplateRef<any>) {
        this.templates = {
            ...this.templates,
            [name]: templateRef
        }

        this.templates$.next(this.templates)
    }
}
