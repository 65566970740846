import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { UtilModule } from "@puntaje/shared/core"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { NebuModule } from "@puntaje/nebulosa/api-services"
import { ApiModule } from "@puntaje/puntaje/api-services"

import { SentimentComponent } from "./sentiment.component"
import { SentimentActiveDirective } from "./sentiment_active.directive"
import { SentimentBarDirective } from "./sentiment_bar.directive"
import { SingleValueSentimentComponent } from "./single_value_sentiment.component"

@NgModule({
    imports: [CommonModule, NebuModule, ApiModule, UtilModule, Angular2FontawesomeModule],
    declarations: [SentimentComponent, SentimentActiveDirective, SentimentBarDirective, SingleValueSentimentComponent],
    exports: [SentimentComponent, SingleValueSentimentComponent],
    providers: []
})
export class SentimentsModule {}
