import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { AchievementsAuthService } from "./achievements_auth.service"
import { AchievementsBaseService } from "./achievements_base.service"
import { LogroUsuarioPlataforma } from "./logro_usuario_plataformas.model"

@Injectable({
    providedIn: "root"
})
export class LogroUsuarioPlataformas extends AchievementsBaseService<LogroUsuarioPlataforma> {
    tableName = "logro_usuario_plataformas"
    singularTableName = "logro_usuario_plataformas"
    modelClass = LogroUsuarioPlataforma

    constructor(
        protected http: HttpClient,
        protected auth: AchievementsAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public progreso(params: any) {
        return this.all().concatExtra("progreso").get(params)
    }
}
