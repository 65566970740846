import { BaseModel, SaveType } from "@puntaje/shared/core"
import { CampoExtra } from "./campo_extras.model"

export class UniversidadCampoExtra extends BaseModel {
    public static className: string = "UniversidadCampoExtra"

    public universidadId: number
    public campoExtraId: number

    @SaveType(() => CampoExtra) campoextra: CampoExtra
}
