import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: "css-config",
    template: `
        <ng-content></ng-content>
    `
})
export class CssConfigComponent implements OnInit, OnDestroy {
    config: typeof config = config
    styleObj: any
    rootDeclarations = {}
    cssStatements = []
    topCssStatements = []

    variableMap = {
        "base-titlebar_color": { configVariableName: "tituloColor", default: "#333333" },
        "base-titlebar_size": { configVariableName: "tituloTamano", default: "26px" },
        "base-titlebar_subtitle_color": { configVariableName: "subtituloTituloColor", default: "#4f585d" },
        "base-titlebar_subtitle_size": { configVariableName: "subtituloTituloTamano", default: "18px" },
        "base-subtitle_color": { configVariableName: "subtituloColor", default: "#333333" },
        "base-subtitle_size": { configVariableName: "subtituloTamano", default: "18px" },
        "base-subtitle_underline_color": { configVariableName: "subtituloColorSubrayado", default: "#cccccc" },
        "base-primary_button_background_color": { configVariableName: "contenidoBotonColor", default: "#353535" },
        "base-primary_button_background_color_hover": {
            configVariableName: "contenidoBotonColorHover",
            default: `#${this.darken("#353535", 10)}`
        },
        "base-primary_button_font_color": { configVariableName: "contenidoBotonColorFuente", default: "white" },
        "base-primary_button_font_color_hover": {
            configVariableName: "contenidoBotonColorFuenteHover",
            default: "white"
        },
        "base-cancel_button_background_color": {
            configVariableName: "contenidoBotonCancelarColor",
            default: "#353535"
        },
        "base-cancel_button_background_color_hover": {
            configVariableName: "contenidoBotonCancelarColorHover",
            default: `#${this.darken("#353535", 10)}`
        },
        "base-cancel_button_font_color": { configVariableName: "contenidoBotonCancelarColorFuente", default: "white" },
        "base-cancel_button_font_color_hover": {
            configVariableName: "contenidoBotonCancelarColorFuenteHover",
            default: "white"
        },
        "base-secondary_button_background_color": {
            configVariableName: "contenidoBotonSecundarioColor",
            default: "#353535"
        },
        "base-secondary_button_background_color_hover": {
            configVariableName: "contenidoBotonSecundarioColorHover",
            default: `#${this.darken("#353535", 10)}`
        },
        "base-secondary_button_font_color": {
            configVariableName: "contenidoBotonSecundarioColorFuente",
            default: "white"
        },
        "base-secondary_button_font_color_hover": {
            configVariableName: "contenidoBotonSecundarioColorFuenteHover",
            default: "white"
        },
        "base-menu_item_background_left_color": { configVariableName: "calugasColorFondoIzq", default: "#353535" },
        "base-menu_item_background_left_font_color": { configVariableName: "calugasColorIconoIzq", default: "white" },
        "base-menu_item_title_color": { configVariableName: "calugasColorTitulo", default: "#353535" },
        "base-menu_item_title_underline_color": { configVariableName: "calugasColorSubrayado", default: "#cccccc" },
        "base-showcasebox_icon_color": { configVariableName: "showcaseboxColorIcono", default: "#353535" },
        "base-showcasebox_title_color": { configVariableName: "showcaseboxColorTitulo", default: "#353535" },
        "base-showcasebox_title_background_color": {
            configVariableName: "showcaseboxColorFondoTitulo",
            default: "white"
        },
        "base-navbar_height": { configVariableName: "navbarAlto", default: "50px" },
        "base-navbar_avatar_size": { configVariableName: "navbarAltoAvatar", default: "40px" },
        "base-navbar_logo_height": { configVariableName: "navbarAltoLogo", default: "30px" },
        "base-navbar_background_color": { configVariableName: "navbarColorFondo", default: "white" },
        "base-navbar_background_color_hover": { configVariableName: "navbarColorFondoHover", default: "#e6e6e6" },
        "base-navbar_font_color": { configVariableName: "navbarColorFuente", default: "#333333" },
        "base-navbar_font_color_hover": { configVariableName: "navbarColorFuenteHover", default: "#333333" },
        "base-navbar_menu_btn_background_color": {
            configVariableName: "navbarColorFondoBotonMenu",
            default: "transparent"
        },
        "base-navbar_menu_btn_background_color_hover": {
            configVariableName: "navbarColorFondoBotonMenuHover",
            default: "transparent"
        },
        "base-navbar_font_color_menu_icon": { configVariableName: "navbarColorIconoBotonMenu", default: "#333333" },
        "base-navbar_font_color_menu_icon_hover": {
            configVariableName: "navbarColorIconoBotonMenuHover",
            default: "#333333"
        },
        "base-navbar_avatar_background_color": { configVariableName: "navbarColorFondoAvatar", default: "#a5adb3" },
        "base-topmenu_background_color": { configVariableName: "menuColorFondo", default: "white" },
        "base-topmenu_background_color_hover": { configVariableName: "menuColorFondoHover", default: "#e6e6e6" },
        "base-topmenu_font_color": { configVariableName: "menuColorFuente", default: "#333333" },
        "base-topmenu_font_color_hover": { configVariableName: "menuColorFuenteHover", default: "#333333" },
        "base-menu_horizontal_background_color": { configVariableName: "menuHorizontalColorFondo", default: "white" },
        "base-menu_horizontal_background_color_hover": {
            configVariableName: "menuHorizontalColorFondoHover",
            default: "#333333"
        },
        "base-menu_horizontal_font_color": { configVariableName: "menuHorizontalColorFuente", default: "#333333" },
        "base-menu_horizontal_font_color_hover": {
            configVariableName: "menuHorizontalColorFuenteHover",
            default: "white"
        },
        "base-menu_horizontal_border_marker": {
            configVariableName: "menuHorizontalMarcadorLinea",
            default: false,
            cssGenerator: value => {
                this.addStatement(`
                .base-menu-active-menu {
                    ${
                        value
                            ? `
                        position: relative;
                        &:after {
                            content: " ";
                            position: absolute;
                            left: -1px;
                            right: -1px;
                            height: 4px;
                            bottom: 0;
                            transform-origin: 50% 100%;
                            -webkit-animation: accentbar 0.3s;
                            animation: accentbar 0.3s;
                            -webkit-transform: scale(1, 0);
                            transform: scale(1, 0);
                            -webkit-animation-fill-mode: forwards;
                            animation-fill-mode: forwards;
                            background-color: var(--base-menu_horizontal_background_color_hover);
                        }
                    `
                            : `
                        background-color: var(--base-menu_horizontal_background_color_hover);
                        border-color: var(--base-menu_horizontal_background_color_hover);
                        a {
                            color: var(--base-menu_horizontal_font_color_hover);
                        }
                    `
                    }
                }
            `)
            }
        },
        "base-menu_horizontal_with_bottom_border": {
            configVariableName: "menuHorizontalLineaAbajo",
            default: false,
            cssGenerator: value => {
                this.addVariableToStyle(
                    "base-menu_horizontal_with_bottom_border",
                    value ? "4px solid var(--base-menu_horizontal_background_color_hover)" : "inherit",
                    "inherit",
                    false
                )
            }
        },
        "base-menu_horizontal_no_icon": { configVariableName: "menuHorizontalSinIcono", default: "block" },
        "base-menu_showcasebox_background_color": { configVariableName: "menuShowcaseboxColorFondo", default: "white" },
        "base-menu_showcasebox_background_color_hover": {
            configVariableName: "menuShowcaseboxColorFondoHover",
            default: "#e6e6e6"
        },
        "base-menu_showcasebox_font_color": { configVariableName: "menuShowcaseboxColorFuente", default: "#333333" },
        "base-menu_showcasebox_font_color_hover": {
            configVariableName: "menuShowcaseboxColorFuenteHover",
            default: "#333333"
        },
        "base-menu_showcasebox_icon_font_color": {
            configVariableName: "menuShowcaseboxIconoColorFuente",
            default: "white"
        },
        "base-menu_showcasebox_icon_background_color": {
            configVariableName: "menuShowcaseboxIconoColorFondo",
            default: "#333333"
        },
        "base-menu_showcasebox_with_arrow": { configVariableName: "menuShowcaseboxConFlecha", default: "block" },
        "base-menu_showcasebox_rounded": {
            configVariableName: "menuShowcaseboxRedondeado",
            default: false,
            cssGenerator: value => {
                this.addVariableToStyle("base-menu_showcasebox_radius", value ? "3px" : "200px", "3px", false)
            }
        },
        "base-footer_background_color": { configVariableName: "footerColor", default: "#353535" },
        "base-footer_font_color": { configVariableName: "footerColorFuente", default: "white" },
        "base-footer_font_color_hover": { configVariableName: "footerColorFuenteHover", default: "white" },
        "base-footer_social_media_background_color": {
            configVariableName: "footerColorFuenteSocial",
            default: "transparent"
        },
        "base-footer_social_media_background_color_hover": {
            configVariableName: "footerColorFuenteSocialHover",
            default: `#${this.darken("#353535", 10)}`
        },
        "base-landing_subtitle_font_color": { configVariableName: "landingTituloBajadaColor", default: "white" },
        "base-landing_footer_background_color": { configVariableName: "landingColorMiniFooter", default: "#353535" },
        // palette-colors
        "palette-colors-primary": { configVariableName: "paletteColorPrimario", default: "#2691c5" },
        "palette-colors-secondary": { configVariableName: "paletteColorSecundario", default: "#0160c7" },
        "palette-colors-background": { configVariableName: "paletteColorBackground", default: "#fafafa" },
        "palette-colors-fontColor": { configVariableName: "contenidoColorFuente", default: "#333333" },
        "palette-colors-fontColorOverSecondary": {
            configVariableName: "contenidoColorFuenteSobreSecundario",
            default: "white"
        },
        "palette-colors-noticias": { configVariableName: "paletteColorNoticias", default: "#7faf5b" },
        "palette-colors-establecimientos": { configVariableName: "paletteColorEstablecimientos", default: "#309fb2" },
        "palette-colors-facebook": { configVariableName: "paletteColorFacebook", default: "#3c5a99" },
        "palette-colors-instagram": { configVariableName: "paletteColorInstagram", default: "#c13584" },
        "palette-colors-twitter": { configVariableName: "paletteColorTwitter", default: "#38a1f3" },
        "palette-colors-linkedin": { configVariableName: "paletteColorLinkedin", default: "#0077b5" },
        "palette-colors-youtube": { configVariableName: "paletteColorYoutube", default: "#ff0000" },
        "palette-colors-twitch": { configVariableName: "paletteColoTwitch", default: "#6441a5" },
        "palette-colors-google": { configVariableName: "paletteColorGoogle", default: "#4285f4" },
        "palette-colors-tiktok": { configVariableName: "paletteColorTiktok", default: "#0defe9" },
        "palette-colors-carreras": { configVariableName: "paletteColorCarreras", default: "#966fe6" },
        "palette-colors-carreras-like": { configVariableName: "paletteColorCarrerasLike", default: "#d3267f" },
        "palette-colors-carreras-like-universidad": {
            configVariableName: "paletteColorCarrerasLikeUniversidad",
            default: "#22daa5"
        },
        "palette-colors-blog": { configVariableName: "paletteColorBlog", default: "#e6b74c" },
        "palette-colors-logros": { configVariableName: "paletteColorLogros", default: "#e6b74c" },
        // ---
        "sub-titlebar-color": { configVariableName: "subtituloTituloColor", default: "#333333" },
        "titlebar-color": { configVariableName: "tituloColor", default: "#333333" },
        "custom-font-family1": { configVariableName: "fuente1Nombre", default: "Raleway" },
        "custom-font-family2": { configVariableName: "fuente2Nombre", default: "Roboto" }
    }

    ngOnInit() {
        const cssVariables = config["cssVariables"] || {}
        const variablesClonador = Object.keys(this.variableMap)
        //variables clonador
        variablesClonador.forEach(variable => {
            if (this.variableMap[variable].cssGenerator) {
                this.variableMap[variable].cssGenerator(
                    cssVariables[this.variableMap[variable].configVariableName] ?? this.variableMap[variable].default
                )
            } else {
                this.addVariableToStyle(
                    variable,
                    cssVariables[this.variableMap[variable].configVariableName],
                    this.variableMap[variable].default,
                    variable.includes("color")
                )
            }
        })

        //colores asignatura
        if (cssVariables["clasesConfig"]) {
            cssVariables["clasesConfig"].forEach(asignatura => {
                this.addVariableToStyle(`asignatura-colors-${asignatura["clase"]}`, asignatura["colorFondo"], "", true)
                this.addVariableToStyle(
                    `asignatura-font-colors-${asignatura["clase"]}`,
                    asignatura["colorFuente"],
                    "",
                    true
                )
            })
        }

        this.addTopStatement(
            `@import url("${
                cssVariables["fuente1Link"] ??
                "https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap"
            }");`
        )
        this.addTopStatement(
            `@import url("${
                cssVariables["fuente2Link"] ??
                "https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap"
            }");`
        )

        this.styleObj = document.createElement("style")
        this.styleObj.innerHTML = `
            ${this.topCssStatementsString()}

            :root {
                ${this.rootDeclarationsString()}
            }

            ${this.cssStatementsString()}
        `
        document.head.appendChild(this.styleObj)
    }

    addVariableToStyle(variable: string, value: string, defaultValue: string, isColor?: boolean) {
        value = value || defaultValue

        this.rootDeclarations[`--${variable}`] = value

        if (isColor) {
            const { h, s, l } = this.extractHsl(value)
            this.rootDeclarations[`--${variable}-h`] = `${h}deg`
            this.rootDeclarations[`--${variable}-s`] = `${s}%`
            this.rootDeclarations[`--${variable}-l`] = `${l}%`
            const { r, g, b } = this.hexToRgb(value)
            this.rootDeclarations[`--${variable}-r`] = r
            this.rootDeclarations[`--${variable}-g`] = g
            this.rootDeclarations[`--${variable}-b`] = b
        }
    }

    addStatement(statement: string, isNull: boolean = false) {
        if (!isNull) {
            this.cssStatements.push(statement)
        }
    }

    addTopStatement(statement: string, isNull: boolean = false) {
        if (!isNull) {
            this.topCssStatements.push(statement)
        }
    }

    extractHsl(color: string) {
        const rgb = this.hexToRgb(color)
        const hsl = this.rgbToHsl({ r: rgb.r, g: rgb.g, b: rgb.b })
        return hsl
    }

    hexToRgb(hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16)
              }
            : { r: 0, g: 0, b: 0 }
    }

    rgbToHsl({ r, g, b }: { r: number; g: number; b: number }) {
        ;(r /= 255), (g /= 255), (b /= 255)
        const max = Math.max(r, g, b),
            min = Math.min(r, g, b)
        let h,
            s,
            l = (max + min) / 2

        if (max == min) {
            h = s = 0 // achromatic
        } else {
            const d = max - min
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0)
                    break
                case g:
                    h = (b - r) / d + 2
                    break
                case b:
                    h = (r - g) / d + 4
                    break
            }
            h /= 6
        }

        return { h: h < 0 ? 360 * (1 - h) : 369 * h, s: s * 100, l: l * 100 }
    }

    hslToRgb({ h, s, l }: { h: number; s: number; l: number }) {
        let r,
            g,
            b = undefined

        h = parseFloat(h.toFixed(5)) / 360
        s = parseFloat(s.toFixed(5)) / 100
        l = parseFloat(l.toFixed(5)) / 100

        if (s === 0) {
            r = g = b = l
        } else {
            let p,
                q = undefined

            q = l < 0.5 ? l * (1 + s) : l + s - l * s
            p = 2 * l - q

            r = this.hueToRgb(p, q, h + 1 / 3)
            g = this.hueToRgb(p, q, h)
            b = this.hueToRgb(p, q, h - 1 / 3)
        }

        return {
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255)
        }
    }

    hueToRgb(p, q, t) {
        if (t < 0) {
            t += 1
        }
        if (t > 1) {
            t -= 1
        }
        if (t < 1 / 6) {
            return p + (q - p) * 6 * t
        }
        if (t < 1 / 2) {
            return q
        }
        if (t < 2 / 3) {
            return p + (q - p) * (2 / 3 - t) * 6
        }
        return p
    }

    rgbToHex(rgb) {
        return ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)
    }

    hexToHsl(hex: string) {
        return this.rgbToHsl(this.hexToRgb(hex))
    }

    hslToHex({ h, s, l }: { h: number; s: number; l: number }) {
        return this.rgbToHex(this.hslToRgb({ h, s, l }))
    }

    lighten(hex: string, percent: number) {
        const { h, s, l } = this.hexToHsl(hex)
        return this.hslToHex({ h, s, l: Math.max(Math.min(l + percent, 100), 0) })
    }

    darken(hex: string, percent: number) {
        const { h, s, l } = this.hexToHsl(hex)
        return this.hslToHex({ h, s, l: Math.max(Math.min(l - percent, 100), 0) })
    }

    saturate(hex: string, percent: number) {
        const { h, s, l } = this.hexToHsl(hex)
        return this.hslToHex({ h, s: Math.max(Math.min(s + percent, 100), 0), l })
    }

    desaturate(hex: string, percent: number) {
        const { h, s, l } = this.hexToHsl(hex)
        return this.hslToHex({ h, s: Math.max(Math.min(s - percent, 100), 0), l })
    }

    tint(hex: string, percent: number) {
        const { h, s, l } = this.hexToHsl(hex)
        return this.hslToHex({
            h,
            s: Math.max(Math.min(s + percent, 100), 0),
            l: Math.max(Math.min(l + percent, 100), 0)
        })
    }

    shade(hex: string, percent: number) {
        const { h, s, l } = this.hexToHsl(hex)
        return this.hslToHex({
            h,
            s: Math.max(Math.min(s - percent, 100), 0),
            l: Math.max(Math.min(l - percent, 100), 0)
        })
    }

    rootDeclarationsString() {
        return Object.keys(this.rootDeclarations)
            .map(key => `${key}: ${this.rootDeclarations[key]};`)
            .join("\n")
    }

    cssStatementsString() {
        return this.cssStatements.join("\n")
    }

    topCssStatementsString() {
        return this.topCssStatements.join("\n")
    }

    ngOnDestroy() {
        document.head.removeChild(this.styleObj)
    }
}
