import { Component, Injector } from "@angular/core"
import { View } from "./view.component"

@Component({
    selector: "linkview",
    template: `
        <ng-template #viewLabel>{{ label }}:</ng-template>
        <ng-template [ngIf]="plain">
            <ng-template #viewValue>
                <a [attr.href]="urlLink" [attr.target]="withTargetBlank ? '_blank' : ''">
                    <fa name="link" *ngIf="withIcon"></fa>
                    {{ text }}
                </a>
            </ng-template>
        </ng-template>
        <ng-template [ngIf]="!plain">
            <ng-template #viewValue>
                <a [routerLink]="urlLink" [attr.target]="withTargetBlank ? '_blank' : ''">
                    <fa name="link" *ngIf="withIcon"></fa>
                    {{ text }}
                </a>
            </ng-template>
        </ng-template>
    `,
    styleUrls: ["model_simple_view.component.scss"]
})
export class LinkView extends View {
    text: string
    urlLink: string
    plain: boolean = false
    withIcon: boolean = false
    withTargetBlank: boolean = false

    constructor(private injector: Injector) {
        super()
    }

    ngOnInit() {
        if (this.options !== undefined && this.options.class !== undefined) {
            let service = this.injector.get(this.options.class)
            this.urlLink = "/" + service.getTableName() + "/" + this.value[this.options.key]
            this.text = this.value[this.key]

            if (this.options.modelKey !== undefined && this.value[this.key]) {
                let modelKey = this.options.modelKey
                this.text = this.value[this.key][modelKey]
            }
        } else if (this.options !== undefined && this.options.fromFunction !== undefined) {
            let servClass = this.options.fromFunction(this.value[this.options.from])
            let service = this.injector.get(servClass)
            this.urlLink = "/" + service.getTableName() + "/" + this.value[this.options.key]
            this.text = this.value[this.key]

            if (this.options.modelKey !== undefined && this.value[this.key]) {
                let modelKey = this.options.modelKey
                this.text = this.value[this.key][modelKey]
            }
        } else if (this.options !== undefined) {
            this.urlLink = this.value[this.options.key]
            if (this.options.textKey) {
                this.text = this.value[this.options.textKey]
            } else {
                this.text = this.options.text
            }

            this.withIcon = this.options.withIcon
            this.withTargetBlank = this.options.withTargetBlank

            this.plain = true
        }
    }
}
