import { BaseModel, SaveType } from "@puntaje/shared/core"
import { InstrumentoPregunta } from "./instrumento_preguntas.model"
import { Usuario } from "./usuarios.model"
import { GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { InstrumentoMensual } from "./instrumento_mensuales.model"
import { InstrumentoMaterial } from "./instrumento_materiales.model"
import { Material } from "@puntaje/nebulosa/api-services"

export class Instrumento extends BaseModel {
    numero_preguntas: number
    numero_alternativas: number
    tiempo: number
    generador_instrumento_id: number
    asignatura_id: number
    categorias: string
    oficial: boolean
    usuario_id: number
    propio: boolean
    validado: boolean
    created_at: Date
    updated_at: Date
    calificacion_manual: boolean
    adaptive: boolean
    numero_preguntas_testlet: number
    tipo_contestables: string

    @SaveType(() => InstrumentoPregunta) instrumento_preguntas: InstrumentoPregunta[]
    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => GeneradorInstrumento) generador_instrumento: GeneradorInstrumento
    @SaveType(() => InstrumentoMensual) instrumento_mensuales: InstrumentoMensual[]
    @SaveType(() => InstrumentoMaterial) instrumento_material: InstrumentoMaterial
    @SaveType(() => Material) material: Material
    @SaveType(() => Instrumento) instrumento: Instrumento

    public toString() {
        return this.id + ""
    }
}
