<div *ngIf="universidadesLoaded">
    <ng-container *ngFor="let param of params; let i = index">
        <ng-container>
            <div
                [formGroup]="form"
                class="form-group"
                *ngIf="param.universidadParams.done && universidadesByIndex[i].length > 1"
            >
                <label for="">
                    {{ params[i].universidadParams.label }}
                    <span *ngIf="i == 0 && !notRequired" class="required-mark"></span>
                </label>
                <select
                    [formControlName]="params[i].universidadParams.key"
                    [(ngModel)]="universidad[i]"
                    name="universidad_{{ i }}"
                    id="universidad_{{ i }}"
                    class="form-control"
                    (change)="setVals(i, $event)"
                >
                    <option [ngValue]="undefined">-- Seleccionar --</option>
                    <option *ngFor="let universidad of universidadesByIndex[i]" [ngValue]="universidad.id">
                        {{ universidad.universidad }}
                    </option>
                </select>
            </div>
            <form-input-error
                [form]="form"
                [params]="params[i].universidadParams"
                [value]="universidad[i]"
                [key]="params[i].universidadParams.key"
                [control]="controls[i]"
            ></form-input-error>
        </ng-container>
    </ng-container>
</div>
