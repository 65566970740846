import { Component, Input, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
import { Asignaturas, Asignatura, ClasificacionTipo, ClasificacionTipos } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Evaluacion, Evaluaciones, EvaluacionInstancia, EvaluacionInstancias } from "@puntaje/puntaje/api-services"

@Component({
    selector: "estadistica-evaluacion-alumno",
    templateUrl: "./estadistica-evaluacion-alumno.component.html",
    styleUrls: ["./estadistica-evaluacion-alumno.component.scss"]
})
export class EstadisticaEvaluacionAlumnoComponent implements OnInit {
    @Input() evaluacionId: number
    evaluacion: Evaluacion
    dataEvaluacionInstancias: EvaluacionInstancia[]
    asignatura: Asignatura
    allOmitidas: number
    allIncorrectas: number
    allCorrectas: number
    clasificacionTiposEstadisticas: any
    clasificacionTipos: any[]
    dataGraficoClasificacion: any[] = []

    constructor(
        protected config: AppConfig,
        protected evaluacionesService: Evaluaciones,
        protected authService: AuthService,
        protected evaluacionInstanciasServices: EvaluacionInstancias,
        protected asignaturasService: Asignaturas,
        protected clasificacionTiposService: ClasificacionTipos
    ) {}

    ngOnInit(): void {
        this.getEvaluacion()
    }

    async getEvaluacion() {
        this.evaluacion = await this.evaluacionesService.find(this.evaluacionId)
        this.clasificacionTiposEstadisticas =
            this.config.evaluaciones[this.evaluacion.evaluacion_tipo.evaluacion_tipo].clasificacionTiposEstadisticas
        if (this.evaluacion) this.getEvaluacionInstancias()
    }

    getEvaluacionInstancias() {
        const params = {
            sort_by: "created_at",
            order: "desc",
            evaluacion_instancia: {
                evaluacion_id: this.evaluacionId,
                usuario_id: this.authService.getUserData().id
            }
        }
        this.evaluacionInstanciasServices.where(params).then((evaluacionInstancias: EvaluacionInstancia[]) => {
            this.dataEvaluacionInstancias = evaluacionInstancias
            this.allCorrectas = evaluacionInstancias.reduce(function (acc, obj) {
                return acc + obj.correctas
            }, 0)
            this.allIncorrectas = evaluacionInstancias.reduce(function (acc, obj) {
                return acc + obj.incorrectas
            }, 0)
            this.allOmitidas = evaluacionInstancias.reduce(function (acc, obj) {
                return acc + obj.omitidas
            }, 0)

            this.getAsignatura()
        })
    }

    async getAsignatura() {
        this.asignatura = await this.asignaturasService.find(this.evaluacion.instrumento.asignatura_id, { simple: 1 })

        this.getClasificacionTipos()
    }

    async getClasificacionTipos() {
        const cTipos = this.clasificacionTiposEstadisticas.filter(ct => ct.alumnos).map(ct => ct.nombre)
        let params = {
            with_clasificacion: true,
            clasificacion_tipo: { clasificacion_tipo: cTipos }
        }
        this.clasificacionTipos = <ClasificacionTipo[]>await this.clasificacionTiposService.where(params)
        this.datosGraficoClasificacionInstancia()
    }

    async datosGraficoClasificacionInstancia() {
        if (this.clasificacionTipos)
            for (let ct of this.clasificacionTipos) {
                let allClasificaciones = ct.clasificaciones
                    .map(c => {
                        let resp = {
                            id: c.id,
                            clasificacion: c.clasificacion
                        }
                        return resp
                    })
                    .flat()

                let dataGrafico = await this.evaluacionInstanciasServices.data_grafico_clasificacion({
                    id: this.dataEvaluacionInstancias.map(ei => ei.id),
                    clasificaciones: allClasificaciones,
                    clasificacion_tipo: ct.clasificacion_tipo
                })

                let tipoClasificacion = dataGrafico[0] ? dataGrafico[0]["clasificacion_tipo"] : ""

                this.dataGraficoClasificacion.push({
                    tipoClasificacion,
                    clasificaciones: dataGrafico.map(c => c["clasificacion"]),
                    data: dataGrafico.map(c => c["estadistica_clasificacion"]["correctas"]),
                    dataTotal: dataGrafico.map(
                        c =>
                            c["estadistica_clasificacion"]["correctas"] +
                            c["estadistica_clasificacion"]["omitidas"] +
                            c["estadistica_clasificacion"]["incorrectas"]
                    )
                })
            }
    }
}
