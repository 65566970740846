export const esCO = {
    landing: {
        login: {
            user: "Email o TI/CC",
            error_user: "Debes incluir un correo electrónico o TI/CC válido.",
            error_auth1:
                "<strong>La combinación de correo electrónico/contraseña o TI-CC/contraseña es incorrecta.</strong> Las contraseñas de ",
            error_auth2:
                ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
        }
    },

    home: {
        showcasebox: {
            grupo_usuarios: {
                soy_docente: "Niveles donde soy Docente",
                ir_cursos: "Ir a Mis Niveles"
            }
        }
    }
}
