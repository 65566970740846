import { SaveType, BaseModel } from "@puntaje/shared/core"
import { EvaluacionInstancia } from "./evaluacion_instancias.model"
import { PlanPersonalInstancia } from "./plan_personal_instancias.model"

export class PlanPersonalEvaluacionInstancia extends BaseModel {
    public plan_personal_instancia_id: number
    public plan_personal_contenido_id: number
    public evaluacion_instancia_id: number

    @SaveType(() => EvaluacionInstancia) public evaluacion_instancia: EvaluacionInstancia
    @SaveType(() => PlanPersonalInstancia) public plan_personal_instancia: PlanPersonalInstancia
}
