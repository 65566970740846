<div class="clearfix">
    <cui-button type="button" (click)="goBack()" class="btn_style pull-left btn-sm">
        <span class="glyphicon glyphicon-chevron-left"></span>
        &nbsp;Volver
    </cui-button>
</div>
<br />
<div #iframeContainer (window:resize)="onResize($event)" *ngIf="urlReady" class="contenido-udd">
    <iframe [width]="width" [height]="height" [src]="safeUrl" (load)="onLoadIframe()"></iframe>
</div>
