import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, HttpVerbs, AppEnv } from "@puntaje/shared/core"
import { PlanEstudioAplicado } from "./plan_estudio_aplicados.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class PlanEstudioAplicados extends PuntajeBaseService<PlanEstudioAplicado> {
    tableName = "plan_estudio_aplicados"
    singularTableName = "plan_estudio_aplicado"
    modelClass = PlanEstudioAplicado

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public update_pea(objectQuery: PlanEstudioAplicado) {
        if (this.canConcat && !this.withParams) {
            this.objectQuery = new PlanEstudioAplicado()
            this.objectQuery["plan_estudio_aplicado"] = objectQuery.clone()
            this.restQuery += "/actualizar_plan_estudio"
            this.canConcat = false
            this.httpVerb = HttpVerbs.POST
        } else {
            throw new SyntaxError(
                "Imposible concatenar una llamada POST (save) a una llamada " + HttpVerbs[this.httpVerb]
            )
        }
        return this
    }
}
