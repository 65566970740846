<ng-container>
    <form novalidate [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <form-input
                    #fechaNacimientoContainer
                    [form]="form"
                    [params]="params['fecha_nacimiento']"
                    [(value)]="usuarioRegistro['fecha_nacimiento']"
                    [key]="'fecha_nacimiento'"
                ></form-input>
                <button
                    (click)="validarFecha()"
                    type="button"
                    class="btn btn-default btn-block submit-btn"
                    aria-describedby="textoValidacionDeEdad"
                >
                    Validar
                </button>
                <a routerLink="/landing" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </a>
            </div>
            <div class="col-md-6">
                <div id="textoValidacionDeEdad" aria-live="polite" *ngIf="mostrarMensaje">
                    Nos encantará verte de nuevo cuando hayas cumplido 13 años. Por ahora, nuestro portal solo está
                    diseñado para estudiantes de 13 años o más.

                    <a class="btn-default btn btn-block" routerLink="/landing">Aceptar</a>
                </div>
                <div *ngIf="mostrarBotones">
                    <div class="facebook-login">
                        <a type="button" routerLink="/usuarios/registro" class="btn-default btn btn-block">
                            Correo electrónico
                        </a>
                    </div>
                    <google-button [validarIdentificador]="validarIdentificador"></google-button>
                </div>
            </div>
        </div>
    </form>
</ng-container>
