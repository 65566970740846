import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()
export class TabService {
    public tabState: any = {}
    public tabSubject: Subject<any> = new Subject()

    setComponentTab(component: string, tab: any) {
        this.tabState[component] = this.tabState[component] || {}
        this.tabState[component] = tab
        this.tabSubject.next(this.tabState)
    }

    getComponentTab(component: string) {
        if (this.tabState[component] && this.tabState[component]) {
            return this.tabState[component]
        } else {
            return null
        }
    }
}
