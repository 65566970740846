export const es = {
    file: {
        cargar_archivo: "Elegir archivo",
        cargar_archivo_mobile: "Sacar foto"
    },
    puntaje_core: {
        carreras: {
            buscador_carreras: {
                titulo: "Buscador de carreras"
            },
            carrera_sede: {
                titulo: "Carrera"
            }
        },
        estadisticas: {
            evaluacion_instancia_estadisticas: {
                titulo: "Estadísticas"
            }
        },
        revisar_ensayos: {
            titulo: "Revisar evaluaciones creadas"
        },
        evaluaciones_corregir: {
            titulo_alumnos: "Corregir Evaluaciones",
            titulo: "Cargar Resultados"
        },
        estadisticas_evaluacion_recomendada: {
            titulo: "Estadísticas Evaluacion Sugerida #%{id}"
        },
        evaluacion: {
            titulo: "Evaluación: %{nombreEvaluacion}"
        },
        plan_clases: {
            titulo: "Planes de clase",
            tab_planes_alumnos: "Plan clases estudiantes",
            tab_mis_planes: "Mis Planes de clase",
            planes_compartidos: "Planes de clase compartidos por mí",
            planes_recomendados: "Planes de clase recomendados"
        },
        completar_datos_core: {
            titulo: "Completa tus Datos"
        },
        actualizar_datos_core: {
            titulo: "Actualiza tus Datos"
        },
        home_ayudas: {
            label_preguntas_frecuentes: "Preguntas frecuentes",
            text_preguntas_frecuentes:
                "Si tienes dudas de las principales funcionalidades de la plataforma, te recomendamos ingrensar acá.",
            label_tutoriales: "Tutoriales",
            text_tutoriales: "Si necesitas ayuda personalizada ponte en contacto con tu monitor."
        }
    },
    puntaje_modules: {
        completar_datos: {
            omitir: "Omitir",
            guardar: "Guardar"
        },
        actualizar_datos: {
            omitir: "Omitir",
            guardar: "Guardar"
        }
    },
    corregir_ensayos_tabs_profesor: {
        partir_imagen: "A partir de una imagen/foto",
        partir_imagenes: "A partir de múltiples imágenes/fotos",
        partir_csv: "A partir de archivo CSV",
        partir_camara: "A partir de cámara"
    },
    subir_respuestas_imagen: {
        titulo: "Sigue los siguientes pasos:",
        titulo_step_1: "Adjunta una imagen de la hoja de respuestas",
        desc_step_1_1: "Toma una fotografía de la",
        desc_step_1_2: "hoja de respuestas",
        desc_step_1_3:
            "Asegúrate que la hoja esté derecha y la iluminación sea adecuada para un buen contraste entre el fondo blanco y las respuestas",
        desc_step_1_4: "Puedes encontrar una hoja de respuestas",
        desc_step_1_5: "acá",
        desc_step_1_6: "Para el uso de esta, no es necesario que llenes tus datos",
        siguiente: "Siguiente",
        step_1_error: "El archivo no corresponde a una imagen válida",
        step_1_error_desc_1: "Compruebe que se haya adjuntado el archivo correcto",
        step_1_error_desc_2:
            "Si se trata de una fotografía, pruebe con enderezar la hoja y mejorar la iluminación y contraste",
        subir_foto: "Subir Foto",
        titulo_step_2: "Comprueba los resultados",
        desc_step_2:
            "Revisa que los resultados se hayan leído correctamente a partir de la imagen. Puedes corregir marcando la alternativa contestada que corresponda en caso de un error de lectura, haciendo click en las secciones azules de la imagen",
        hoja_respuesta: "Hoja de respuesta",
        resultados: "Resultados",
        titulo_step_3: "Sube los resultados",
        desc_step_3: "Completa la operación haciendo click en el botón subir resultados",
        step_3_error: "La operación se completó con advertencias. La imágen no pudo cargarse por uno o más errores",
        subir_resultados: "Subir Resultados"
    },
    subir_respuestas_imagen_multiple: {
        titulo: "Sigue los siguientes pasos:",
        titulo_step_1: "Arrastra las imágenes",
        desc_step_1:
            "Escanea en formato JPEG o toma una fotografía para cada hoja de respuestas. Asegúrate que las hojas estén derechas y la iluminación sea adecuada para un buen contraste entre el fondo blanco y las respuestas.",
        file_box: "Arrastra las imágenes aquí",
        opcion_contraste: "Aplicar corrección de contraste (para imágenes muy claras o desteñidas)",
        progreso: "%{n} archivos procesados",
        titulo_malos: "Archivos con problemas",
        archivos_no_imagenes: "Archivos que no son imágenes",
        imagenes_malas: "Imágenes con problemas",
        titulo_step_2: "Comprueba los resultados",
        desc_step_2:
            "Revisa que los resultados se hayan leído correctamente a partir de la imagen. Puedes corregir marcando la alternativa contestada que corresponda en caso de un error de lectura, haciendo click en las secciones azules de la imagen.",
        siguiente: "Siguiente",
        forma_global: "Forma Global",
        tooltip_forma_global:
            "Forma utilizada por todas las imágenes. Si su valor esta especificado, las formas de las imágenes se ignorarán.",
        selecciona_imagen: "Selecciona una imagen",
        hoja_respuesta: "Hoja de respuesta",
        resultados: "Resultados",
        forma: "Forma",
        editar: "Editar",
        listo: "Listo",
        tipo_forma: "Tipo de forma",
        titulo_step_3: "Sube los resultados",
        desc_step_3: "Completa la operación haciendo click en el botón subir resultados.",
        warning_no_completado:
            "La operación para la imagen <b>%{fileName}</b> no pudo completarse por uno o más errores:",
        ver_resultados: "Ver resultados",
        aviso_completado: "La operación para la imagen <b>%{fileName}</b> se completó exitosamente.&nbsp;",
        subir_resultados: "Subir Resultados",
        nueva_carga: "Nueva carga",
        modal_instructivo: {
            link: "Instructivo y mejores prácticas",
            no_mostrar: "No volver a mostrar este mensaje",
            titulo: "Mejores prácticas para la corrección automática con hoja de respuestas impresa",
            desc: "Para evitar inconvenientes a la hora de escanear las hojas de respuestas de tus cursos, sigue las siguientes recomendaciones:",
            instr1: "Las hojas de respuestas deben <b>imprimirse</b> para asegurar así su calidad. <b>No se recomienda fotocopiarlas.</b>",
            instr2: "Asegúrate haber usado hojas <b>tamaño carta, versión 2022 de la plataforma</b> y con buena calidad de impresión.",
            instr3: "Asegúrate de que estén completos todos los campos obligatorios <b>(RUT, Forma)</b> y completamente rellenados los círculos de las respuestas de tus estudiantes.",
            instr4: "Limpia el <b>lente de la cámara</b> y asegúrate de tener la <b>iluminación adecuada</b> para tomar las fotografías. El formato del archivo debe ser JPEG o PNG.",
            instr5: "Revisa que los <b>códigos QR</b> de las hojas de respuestas <b>no estén cortados, incompletos o rayados.</b>",
            instr6: "Revisa que estén bien rellenados los <b>círculos de las respuestas</b> y los datos requeridos. Tus estudiantes deberán usar lápiz <br>No. 2</b> o <b>HB.</b>",
            instr7: "Al escanear la hoja, procura posicionarla en los márgenes especificados en el dispositivo, <b>evitando espacios sobrantes</b> que no corresponden al formato de la hoja.",
            instr8: "Cuando llegues al paso 2, podrás <b>editar el número de evaluación, Rut, y alternativas</b> marcadas de tus estudiantes.",
            advertencia: "Hojas de respuesta que NO serán bien procesadas por la plataforma:",
            adv1: "Impresión sin nitidez",
            adv2: "Hojas con manchas",
            adv3: "Hojas maltratadas y arrugadas",
            adv4: "Códigos QR cortados",
            adv5: "Archivo cargado en formato PDF",
            aceptar: "Aceptar"
        }
    },
    subir_respuestas_csv: {
        titulo: "Sigue los siguientes pasos:",
        titulo_step_1: "Adjunta un archivo CSV",
        desc_step_1_1: "Adjunta un archivo de tipo CSV con la información que deseas ingresar.",
        desc_step_1_2: 'Puedes obtener el formato <a href="%{urlFormato}">aquí</a>.',
        titulo_step_2: "Edita el archivo",
        desc_step_2: "Al abrir el archivo haga clic en separar columnas por coma y utilice el archivo de forma normal.",
        subir_archivo: "Subir archivo"
    },
    plan_clase_sesion_form: {
        agregar_material: {
            titulo: "Agregar material",
            tab_biblioteca: "Desde la biblioteca",
            tab_mi_material: "Desde mi material",
            tab_subir: "Subir material",
            tab_agregar_evaluacion: "Agregar evaluación",
            tab_agregar_prueba_propia: "Agregar prueba propia"
        }
    },
    subir_material_profesor: {
        titulo: "Subir material",
        error_nombre: "Debe escoger un nombre para el material",
        error_video_url: "Debe colocar la URL del video",
        error_video_url_youtube_valida: "Debe colocar una URL válida de un video de YouTube",
        error_pdf: "El archivo no es un PDF",
        error_pdf_archivo: "Debe seleccionar un archivo PDF",
        error_ppt: "El archivo no es un PPT",
        error_ppt_archivo: "Debe seleccionar un archivo PPT",
        error_word: "El archivo no es un Word",
        error_word_archivo: "Debe seleccionar un archivo Word",
        error_excel: "El archivo no es una planilla Excel",
        error_excel_archivo: "Debe seleccionar un archivo Excel",
        error_mp4: "El archivo no es un MP4",
        error_mp4_archivo: "Debe seleccionar un archivo MP4",
        error_link: "Debe colocar la URL del vínculo",
        tab_subir_pdf: "Subir archivo PDF",
        subir_pdf_nombre: "Nombre del material",
        subir_pdf_archivo: "Archivo a subir",
        tab_subir_ppt: "Subir PPT",
        subir_ppt_nombre: "Nombre del material",
        subir_ppt_archivo: "Archivo a subir",
        tab_subir_word: "Subir Word",
        subir_word_nombre: "Nombre del material",
        subir_word_archivo: "Archivo a subir",
        tab_subir_excel: "Subir Excel",
        subir_excel_nombre: "Nombre del material",
        subir_excel_archivo: "Archivo a subir",
        tab_agregar_video: "Agregar un video",
        agregar_video_nombre: "Nombre del material",
        agregar_video_url: "Dirección del video",
        tab_subir_video: "Subir un video",
        subir_video_nombre: "Nombre del material",
        subir_video_archivo: "Video a subir",
        tab_cargar_link: "Cargar un vínculo",
        cargar_link_nombre: "Nombre del material",
        cargar_link_url: "Vínculo externo",
        con_clasificacion: "Con clasificación",
        sin_clasificacion: "Sin clasificación",
        pdf_advertencia: "El archivo debe estar en formato PDF y no debe exceder los 35 MB",
        ppt_advertencia: "El archivo debe estar en formato PPT (ppt, pptx) y no debe exceder los 35 MB",
        word_advertencia: "El archivo debe estar en formato Word (doc, docx) y no debe exceder los 35 MB",
        excel_advertencia: "El archivo debe estar en formato Excel (xls, xlsx) y no debe exceder los 35 MB",
        mp4_advertencia: "El archivo debe estar en formato MP4 y no debe exceder los 35 MB",
        boton_subir_pdf: "Subir PDF",
        boton_subir_ppt: "Subir PPT",
        boton_subir_word: "Subir Word",
        boton_subir_excel: "Subir Excel",
        boton_subir_video_url: "Agregar video",
        boton_subir_mp4: "Subir video",
        boton_cargar_link: "Cargar vínculo"
    },
    request_reset_password: {
        titulo: "Actualización de contraseña",
        parrafo1: "Tu contraseña ha sido restablecida recientemente.",
        parrafo2: "Antes de continuar, te pedimos cambiar tu contraseña por una más segura.",
        label_password_actual: "Contraseña Actual",
        error_vacia_password_actual: "La contraseña actual no puede estar vacía.",
        error_invalida_password_actual: "La contraseña actual no es válida.",
        label_password_nueva: "Nueva Contraseña",
        error_tamano_password_nueva: "La contraseña debe tener mínimo %{passwordMinLength} caracteres.",
        error_vulnerable_password_nueva: "Esta contraseña es vulnerable. Prueba colocar otra.",
        label_password_confirmar: "Confirmar Nueva Contraseña",
        error_match_password_confirmar: "La confirmación de la contraseña no coincide con la contraseña.",
        save_success: "La contraseña se actualizó correctamente.<br />En breve lo redirigiremos al sitio.",
        btn_save: "Cambiar contraseña"
    },
    datos_generales_evaluacion: {
        titulo: "Datos Generales de la evaluación",
        titulo_card: "Evaluación #%{evaluacionId}",
        nombre: "Nombre",
        creacion: "Creación",
        inicio: "Inicio",
        cierre: "Cierre",
        resultados: "Resultados",
        presencial: "Presencial",
        si: "Sí",
        no: "No",
        compartido_con: "Compartido con:",
        fecha: "Fecha",
        acciones: "Acciones",
        editar_button: "Editar",
        tipo: "Tipo de evaluación",
        sumativa: "Sumativa",
        formativa: "Formativa"
    },
    resultados_evaluacion: {
        historial_label: "Historial",
        historial_text: "Encuentra aquí la lista de todas las evaluaciones online, impresas y sin configurar.",
        historial_link_text: "Ver historial",
        estadisticas_label: "Estadísticas",
        estadisticas_text:
            "Encuentra aquí estadísticas generadas a partir de los resultados obtenidos en evaluaciones.",
        estadisticas_link_text: "Ver estadísticas",
        progreso_label: "Progreso de mis estudiantes",
        progreso_text: "Observa la evolución en el tiempo de los resultados obtenidos por los estudiantes.",
        progreso_link_text: "Ver progreso",
        informes_label: "Informe de Estudiantes",
        informes_text:
            "Aquí podrás ver y descargar Informes de Uso y desempeño en la plataforma de cada uno de tus estudiantes.",
        informes_link_text: "Ver informe"
    },
    dudas: {
        titulo: "Comunidad",
        titulo_crear: "Crear tema",
        titulo_editar: "Editar tema",
        comentario: {
            editar: "Editar",
            editado: "editado",
            comentario_eliminado: "El mensaje ha sido eliminado.",
            deshabilitar: "Deshabilitar"
        },
        comentarios_list: {
            mostrar_todos: "Mostrar todos",
            ocultar: "Ocultar"
        },
        duda: {
            por: "Por",
            eliminada: "La duda ha sido eliminada.",
            editar: "Editar",
            compartir: "Compartir",
            responder: "Responder",
            ban: "Deshabilitar"
        },
        duda_avatar: {
            dudas: "Dudas",
            respuestas_correctas: "Respuestas correctas",
            comentarios: "Comentarios",
            lirgos: "Logros",
            inicio: "Inicio",
            termino: "Término",
            deshabilitar: "Deshabilitar"
        },
        duda_form: {
            seleccione: "-- Seleccione curso --"
        },
        duda_item: {
            expandir: "Expandir",
            contraer: "Contraer"
        },
        duda_respuesta_new: {
            cancelar: "Cancelar",
            contestar: "Contestar",
            guardar: "Guardar cambios"
        },
        duda_respuesta: {
            por: "Por",
            eliminada: "La respuesta ha sido eliminada.",
            aceptar: "Aceptar como respuesta",
            aceptada: "Respuesta aceptada",
            deshabilitar: "Deshabilitar",
            editar: "Editar",
            comentar: "Comentar"
        },
        duda_respuestas: {
            titulo: "Respuestas",
            no_hay_respuestas: "No se encontraron respuestas para este tema. ¡Sé el primero en responder!"
        }
    },
    entrenador: {
        entrenador_showcasebox: {
            titulo: "Entrenador virtual",
            no_config: "No tienes configurado tu entrenador virtual.<br />Configúralo ",
            link_config: "aquí",
            btn_config: "Configurar",
            preguntas_contestadas: "Preguntas contestadas<br />la semana pasada: ",
            comenzar: "¿Qué esperas para comenzar?",
            sigue_asi: "¡Sigue así!",
            ya_casi: "Ya casi terminas. ¡Tú puedes!",
            buen_trabajo: "¡Buen trabajo!"
        },
        entrenador_usuarios_edit: {
            descripcion: ` ¿Cuántas preguntas te propones contestar por semana? Selecciona una meta y mantente motivado monitoreando tu
            progreso en la página de inicio.`,
            descripcion_dos: `También puedes cambiar tu meta para adaptarla a vacaciones o periodos de estudio más intensos.`,
            semanal: "Meta semanal",
            meta_actual: "Tu meta actual de preguntas por semana es",
            guardar: "Guardar",
            entrenador_virtual: "Tu Entrenador Virtual",
            selecciona_entreador: "Selecciona un entrenador",
            cancelar: "Cancelar"
        }
    },
    /*  estadisticass: {
        ensayos_recientes_showcasebox: {
            titulo: "Ensayos recientes",
            ver: "Ver",
            ver_todos: "Ver todos",
            none: "No hay ensayos recientes"
        },
        estadistica_evaluacion_alumno: {},
        estadistica_usuarios_preguntas_flex: {},
        estadisticas_multiples_curso: {},
        estadisticas_multiples_cursos: {},
        estadisticas_multiples_evaluacion: {},
        estadisticas_multiples_evaluacion_alumno: {},
        estadisticas_multiples_portada: {},
        estadisticas_multiples_profesor: {},
        estadisticas_portada: {},
        filtro_estadistica_multiples: {},
        grafico_mi_progreso_showcasebox: {
            titulo: "Resumen de tu progreso"
        },
        grafico_comparacion_puntaje_grupos: {},
        grafico_desempeno_clasificacion_compuesto: {},
        grafico_desempeno_promedio: {
            titulo: "Desempeño promedio por tipo de respuesta para %{asignatura}",
            descripcion:
                "Cantidad de respuestas de los estudiantes clasificadas por tipo de resultado: correctas, incorrectas y omitidas.",
            nombre: "Desempeño promedio %{asignatura}",
            correctas: "Correctas",
            incorrectas: "Incorrectas",
            omitidas: "Omitidas"
        },
        grafico_distribucion_tramo_puntaje: {
            titulo: "Distribución de resultados por tramo de ",
            descripcion:
                "Cantidad de evaluaciones realizadas, ordenadas por tramos de %{escala} de acuerdo a los resultados obtenidos en cada una de ellas.",
            eje_x: "Tramos de %{escala}",
            eje_y: "Cantidad de evaluaciones",
            leyenda: "Cantidad de evaluaciones por tramo"
        },
        graficos_clasificacion_tipo_container: {},
        graficos_tipo_clasificacion: {},
        informe_anual: {},
        numero_estudiantes_activos: {},
        resumen_estadisticas_showcasebox: {
            resumen: "Resumen de estadísticas",
            reciente: "Más reciente",
            tendencia: "Tendencia",
            promedio: "Promedio",
            maximo: "Máximo"
        },
        tabla_desempeno_estudiantes: {},
        tabla_desempeno_por_usuario_clasificacion: {},
        tabla_numero_estudiantes_por_curso: {},
        tabla_numero_evaluaciones_realizadas: {},
        tabla_promedio_general_y_asignaturas: {},
        tabla_resumen_evaluacion_instancia: {
            estudiante: "Estudiante",
            resultado: "Resultados",
            puntaje: "Puntaje",
            fecha: "Fecha",
            correctas: "Correctas",
            incorrectas: "Incorrectas",
            omitidas: "Omitidas",
            intento: "Intento",
            intentos: "Intentos"
        },
        top_alumnos_anual: {},
        top_docentes_anual: {},
        avances_asignaturas: {},
        barra_avance_asignatura: {},
        barra_distribucion_asignatura: {},
        desempeno_asignaturas: {},
        estadisticas_cb_showcasebox: {},
        estadisticas_curso: {},
        estadisticas_cursos: {},
        estadisticas_declarados: {},
        estadisticas_evaluacion_portada: {},
        estadisticas_evaluacion: {},
        estadisticas_profesor: {},
        estadisticas_showcasebox: {},
        estadisticas_simple: {
            eje_y: "Cantidad de evaluaciones",
            leyenda: "Cantidad de evaluaciones por tramo"
        },
        estadisticas_usuario_portada: {},
        estadisticas_usuarios: {},
        estadisticas: {},
        filtro_estadisticas_declarados: {},
        filtro_estadisticas: {},
        grafico_mi_progreso_cb: {},
        grafico_mi_progreso: {
            titulo: "evolutivo",
            descripcion: "Acá puedes ver la evolución de tus >>",
            progreso_por: "Progreso por",
            desenpenio_para: "Desempeño para"
        },
        informacion_general_grupos: {},
        informacion_general_usuario: {},
        listado_preguntas_mas_incorrectas: {},
        pregunta_informe_estadistica: {},
        resumen_estadisticas_generales: {},
        resumen_utp_anual: {},
        tabla_kpi: {
            n_ensayos: "Nº Ensayos",
            ultimo_ptje: "Último Ptje.",
            correctas: "Correctas",
            incorrectas: "Incorrectas",
            omitidas: "Omitidas",
            maxima: "Calificación Máxima",
            promedio: "Calificación Promedio"
        },
        tabla_mi_progreso: {},
        tabla_resultados_por_pregunta: {},
        tabla_resumen_evaluacion_por_alumno: {}
    }, */ /// hay que hacer mach con los cambios de ricardo
    plan_clases: {
        agregar_evaluacion_plan_clase: {},
        agregar_prueba_propia_plan_clase: {},
        barra_avance_plan_clase: {},
        card_material_alumno: {},
        card_material_profesor: {},
        carousel_semanal: {},
        carousel_semanal_alumno: {},
        clase_plan_clase: {},
        clase_showcase: {},
        clase_showcase_profesor: {},
        clases_home_alumno: {},
        cursos_plan_clase: {},
        datos_generales_plan_clase: {},
        plan_clase: {},
        plan_clase_alumno: {},
        plan_clase_box: {
            button_ver: "Ver",
            button_editar: "Editar"
        },
        plan_clase_contenido_box: {},
        plan_clase_edit: {},
        plan_clase_form: {},
        plan_clase_modelo: {},
        plan_clase_modelos: {},
        plan_clase_new: {},
        plan_clase_semanas: {},
        plan_clase_semanas_alumnos: {
            vamos_aqui: "¡Vamos aquí!",
            ver_clase: "Ver clase"
        },
        plan_clase_sesion_avance_por_alumno: {},
        plan_clase_sesion_detalle_por_contenido: {},
        plan_clase_sesion_form: {},
        plan_clases_alumnos: {},
        plan_clases_profesor: {},
        replicar_plan: {},
        respuestas_actividad: {},
        resultados_actividad: {},
        plan_clase_semanas_alumnos_new: {
            ver_clase: "Ver clase",
            estara_disponible: "Esta sesión estará visible a partir del:",
            no_disponible: " Esta sesión no esta disponible",
            avance: "Avance",
            sesion: "Sesión de estudio"
        }
    },
    universidad: {
        buscador_universidades: {
            titulo: "Buscador de universidades",
            universidad: "Universidad",
            buscador_placeholder: "Universidad...",
            buscar: "Buscar",
            not_found: ""
        },
        universidad: {
            presentacion: "Presentación:",
            universidad: "Universidad",
            universidades: "Universidades",
            les_gusta: " usuarios les gusta",
            sede: "Sede",
            sedes: "Sedes",
            carrera: "Carrera",
            carreras: "Carreras",
            ver: "Ver",
            seguir: "Seguir",
            siguiendo: "Siguiendo",
            carreras_not_found: "No se han encontrado carreras con esos criterios de búsqueda."
        },
        universidad_recomendada: { titulo: "Universidad del mes" }
    },
    materiales: {
        titulo: "Material:",
        curso_select: {
            seleccione: "Seleccione"
        },
        filtros_clasificaciones_evaluacion_tipo: {
            seleccione: "-- Seleccione un/a",
            mas: "+ Más ",
            menos: "- Menos "
        }
    },
    usuarios: {
        preferencia_carreras: {
            mis_preferencia_carrera: {
                title: "Preferencia Carreras",
                agregar: "Agregar",
                guardar: "Guardar",
                cancelar: "Cancelar"
            }
        }
    },
    //////////////////////////////////
    alternativas: {
        max: "El número de alternativas no puede ser mayor a %{count} ",
        min: "El número de alternativas no puede ser menor o igual a 1 "
    },
    ayuda: {
        descargar: "Descargar",
        fecha_subida: "Fecha de subida",
        ver: "Ver",
        visualizar: "Visualizar"
    },
    ayudas: {
        ayuda: {
            descargar: "Descargar",
            imprimir: "Imprimir",
            ver_youtube: "Ver en Youtube",
            volver: "Volver",
            titulo: "Ayuda"
        },
        buscar: "Buscar...",
        no_disponible: "Ayuda no disponible.",
        titulo_alumnos: "Material de ayuda para Estudiantes",
        titulo_profesores: "Material de ayuda para Profesores",
        home: {
            contacto: {
                enviado: "Su información se ha enviado con éxito. Nos comunicaremos con usted a la brevedad.",
                contactanos: "Contáctanos",
                establecimiento: "Establecimiento",
                placeholder_establecimiento: "Ej: nombre de tu colegio",
                telefono: "Teléfono",
                telefono_requerido: "Teléfono es requerido (y debe ser válido).",
                mensaje: "Mensaje",
                motivo: "Motivo del contacto",
                motivo_requerido: "Motivo del contacto es requerido.",
                enviar_mensaje: "Enviar mensaje"
            }
        }
    },
    base_topmenu: {
        alumno: {
            blog: "Blog",
            practica: "Practica",
            resultados: "Resultados",
            plan_de_clase: "Planes de clase",
            dudas_curso: "Dudas del curso",
            comunidad: "Comunidad",
            ejercitar: "Ejercitar",
            practicar: "Practicar",
            planes_adaptativos: "Planes Adaptativos",
            centro_ayuda: "Centro de Ayuda",
            carreras: "Carreras",
            planes_de_clase: "Planes de Clase",
            planes_estudio: "Planes de Estudio",
            biblioteca: "Biblioteca"
        },
        profe: {
            evaluar: "Evaluar",
            corregir: "Corregir",
            resultados: "Resultados",
            planes_de_clase: "Planes de clase",
            mis_noticias: "Mis noticias",
            comunidad: "Comunidad",
            planes_clase_predefinido: "Planes de clase predefinido",
            centro_ayuda: "Centro de Ayuda",
            resumen_uso: "Resumen de uso",
            blog: "Blog"
        }
    },
    biblioteca: {
        abrir_boton: "Abrir",
        descargar: "Descargar",
        detalles_boton: "Detalles",
        mostrando_resultados: "Mostrando resultados para",
        not_found: "El material no fue encontrado",
        prev_boton: "Previsualizar",
        resultados_warn: "No se encontraron resultados para",
        search_placeholder: "Buscar...",
        subtitulo: "Material por",
        subtitulo_clasificacion: "Material del %{clasificacion}",
        titulo: "Biblioteca",
        vista_previa: "Vista previa material"
    },
    clase_plan_clase: {
        volver: "Volver",
        ver_libro: "Ver libro digital",
        anterior: "Anterior",
        volver_plan: "Volver al plan",
        siguiente: "Siguiente",
        enviar: "Enviar",
        volver_a_realizar: "Volver a realizar",
        sesion: "Sesión"
    },
    compartir_inst: {
        cancelar: "Cancelar",
        cerrar: "Cerrar",
        comp_cursos_1: "Se han compartido las",
        comp_cursos_2: "Evaluaciones con IDs",
        comp_cursos_3: "a los siguientes cursos",
        compartir: "Compartir %{evaluacionTipo}",
        compartir_button: "Compartir",
        con_forma: "Con forma/fila aqui",
        copiar: "Copiar",
        crear: "Crear %{evaluacionTipo}",
        crear_escala: "Crear una escala",
        desde: "Desde el día",
        desde_ver_warn: "debe tener una fecha de publicación de resultados",
        desde_ver: "Los estudiantes podrán ver las respuestas de %{evaluacionTipo} a partir del día",
        desde_warn: "debe tener una fecha de inicio",
        editar: "Editar",
        error_nombre: "debe tener un nombre",
        exito: "exitosamente",
        exp_fecha: "Los estudiantes podrán realizar %{evaluacionTipo}",
        fechas_warn: "La fecha de Inicio debe ser antes que la fecha de término",
        formativo: "Formativo",
        grupos_ver: "%{nombreGrupo} a los cuales compartir %{evaluacionTipo}",
        grupos_ver_warn: "Debe seleccionar al menos un",
        hasta: "Hasta el día",
        hasta_warn: "debe tener una fecha de término",
        marque1: "Marque la siguiente opción si",
        marque2: "tiene formas",
        nombre: "Nombre",
        see_list: "Ver el listado de",
        see_list_here: "aquí",
        sel_escala: "*Selecciona el tipo de escala",
        sel_eval_masiva: "Marque esta opción para evaluaciones masivas",
        sel_eval_masiva_all: "Compartir con todos los estudiantes del sitio",
        sel_tipo: "Selecciona el tipo de evaluacion",
        sin_grupos1: "Usted no puede compartir una evaluación: No posee",
        sin_grupos2: "activos",
        success_can1: "Los estudiantes podrán",
        success_can2: "en el enlace",
        success_can3: "en los enlaces",
        success1: "Se ha compartido la",
        success2: "a los siguientes cursos",
        sumativo: "Sumativo",
        ver_mas: "Ver más",
        warn_pauta: "Advertencia: no podrá editar la pauta luego de compartir",
        what: "¿Qué es esto?",
        what_exp:
            "Esta opción generará dos evaluaciones con las mismas preguntas pero en diferente orden\n(Forma/Fila A y B)",
        what_exp2:
            "La evaluación sumativa es aquella que se aplica para obtener una calificación y evaluar a\nlos alumnos.",
        what_exp3:
            "La evaluación formativa es un proceso en el cual se obtiene información acerca del\naprendizaje de los alumnos y luego se usa para modificar las actividades de enseñanza y\naprendizaje.",
        what_exp4_1:
            "Opción para administradores. Los estudiantes no recibirán una notificación para esta evaluación. Se recomienda no seleccionar",
        what_exp4_2: "de la lista anterior al usar esta opción"
    },
    contestable_tipos: {
        titulo: {
            alternativas: "Pregunta de alternativas",
            archivo: "Respuesta archivo",
            libre: "Respuesta libre",
            mixto: "Mixto"
        },
        contenido: {
            alternativas: "El alumno responderá las preguntas seleccionando alternativas.",
            archivo:
                "El alumno subirá un pdf, word o imagen como respuesta de la prueba. Se tiene que calificar manualmente.",
            libre: "El alumno responderá toda la prueba dentro de un cuadro de texto. Se tiene que calificar manualmente.",
            mixto: "Incluye preguntas de todos los tipos anteriores. Se tiene que calificar manualmente."
        }
    },
    duda: {
        comentario: "comentario",
        comentarios: "comentarios",
        contestada: "Contestada",
        creado_por: "Creado por",
        editado: "editado",
        pendiente: "Pendiente",
        sin_comentarios: "Sin comentarios",
        titulo: "*Titulo"
    },
    ensayos: {
        crear_duda: "Crear duda",
        confirmacion: "Confirmación",
        confirmacion_pregunta: "¿Está seguro que desea escribir una duda?",
        a_quien: "Seleccione a quién va dirigida la duda",
        comunidad_general: "Comunidad General",
        curso: "Curso",
        aceptar: "Aceptar",
        realizar: {
            no_disponible: "La evaluación estará disponible desde el %{fechaInicio} hasta el %{fechaTermino}",
            sin_permiso: "No tienes acceso a esta evaluación."
        }
    },
    ensayo_instancias: {
        titulo: "Evaluaciones realizadas",
        n_preguntas: "N preguntas",
        realizar: "Realizar",
        de: "de",
        puntaje: "Puntaje"
    },
    /*  entrenador: {
        cancelar: "Cancelar",
        descripcion: ` ¿Cuántas preguntas te propones contestar por semana? Selecciona una meta y mantente motivado monitoreando tu progreso en la página de inicio.`,
        descripcion_dos: `También puedes cambiar tu meta para adaptarla a vacaciones o periodos de estudio más intensos.`,
        entrenador_virtual: "Tu Entrenador Virtual",
        guardar: "Guardar",
        meta_actual: "Tu meta actual de preguntas por semana es",
        selecciona_entreador: "Selecciona un entrenador",
        semanal: "Meta semanal"
    }, */
    errores: {
        codigo_error: "Código de error:",
        contacto: "<p>Si tienes más problemas contáctanos al mail:</p><p><b>%{contact_mail}</b></p>",
        contacto_codigo:
            "Si tienes más problemas contáctanos al mail <b>%{contact_mail}</b> y envíanos tu código de error.",
        copiar: "Copiar",
        error_400: "El servidor no supo interpretar la solicitud.",
        error_401: "Necesitas autenticarte para acceder a este recurso.",
        error_403: "No tienes permiso para acceder a este recurso.",
        error_404: "Recurso no encontrado.",
        error_404_front: "Parece que la página que estás buscando no existe.",
        error_500: "El servidor encontró problemas accediendo a este recurso.",
        error_500_pundit: "No tienes permiso para acceder al recurso correspondiente (Pundit).",
        error_503: "El servidor no se encuentra disponible actualmente.",
        error_default: "Se ha producido un error.",
        error_inesperado: "Ha ocurrido un error inesperado, por favor ponte en contacto con nosotros.",
        error_no_manejado: "Hubo un error no manejado por la aplicación.",
        gracias: "Gracias",
        status_front: "de la aplicación cliente",
        titulo_error: "Error %{status}",
        ver_mas: "Ver más",
        ver_menos: "Ver menos",
        volver_pagina_principal: "Volver a la página principal"
    },
    estadisticas: {
        cursos: {
            pre_title: "Resultados para",
            title: "Mis Cursos"
        },
        dg: {
            comp: "Composición",
            instrumento: "Instrumento",
            n_preguntas: "Nº preguntas",
            piloto: "Preguntas Piloto",
            piloto_exp: "Estas preguntas no se consideran para el cálculo del puntaje",
            preg_abbr: "preg.",
            tiempo: "Tiempo",
            tipo: "Tipo",
            title: "Datos Generales del Instrumento de evaluación"
        },
        filtro: {
            filtrar_res: "Filtrar resultados",
            not_found1: "Usted no posee",
            not_found2: "asociados",
            sel: "Seleccione",
            sel_est: "Seleccione establecimiento",
            title: "Filtrar datos"
        },
        graficos: {
            cpg: {
                alumno: "Promedio puntaje",
                descripcion_alumno: "Comparación del promedio de todos los ensayos realizados por estudiante.",
                descripcion_profe:
                    "Comparación del promedio de todos los ensayos realizados en cada uno de los cursos.",
                title: "Comparación de puntajes",
                title_alumno: "Comparación de puntajes entre estudiantes para ",
                title_profe: "Comparación de puntajes entre cursos para "
            },
            dcc: {
                correctas: "Correctas",
                descripcion1:
                    "Distribución de respuestas entre correctas, incorrectas y omitidas, de los contenidos evaluados según el/la ",
                descripcion2: " al que corresponden.",
                desempeno: "Desempeño por ",
                incorrectas: "Incorrectas",
                omitidas: "Omitidas",
                para: "para"
            },
            dtp: {
                alumno: "*Estudiante",
                not_found: "*No se encuentran estudiantes en este tramo",
                puntaje: "*Puntaje",
                resultado: "*Resultado",
                title: "Estudiantes por tramo"
            },
            lpmi: {
                ocultar: "Ocultar",
                resp_incorrectas: "de respuestas incorrectas",
                title: "Preguntas ordenadas según porcentaje de respuestas incorrectas",
                ver_mas: "Ver más"
            },
            ree: {
                correctas: "Correctas",
                estudiantes: "Estudiantes",
                incorrectas: "Incorrectas",
                maximo: "Máximo",
                minimo: "Mínimo",
                omitidas: "Omitidas",
                promedio: "Promedio"
            },
            rpp: {
                alternativas: "Alternativas",
                asignatura: "Asignatura",
                curso: "Curso",
                desc: "La tabla muestra el porcentaje de estudiantes que respondieron esa alternativa con respecto al total de estudiantes que respondieron la evaluación",
                msg_decimales: "Los valores pueden ser números decimales aproximados a números enteros.",
                forma_a: "Forma A",
                forma_b: "Forma B",
                incorrectas: "Incorrectas",
                legend: "El recuadro color verde corresponde a la alternativa correcta",
                omitidas: "Omitidas",
                porcentaje: "Porcentaje",
                pregunta: "Pregunta",
                tiempo_prom: "Tiempo Promedio",
                title: "Resultados por Pregunta",
                universo: "Universo"
            },
            tdpuc: {
                descripcion: "Porcentaje de respuestas correctas por estudiante en cada una de las clasificaciones",
                estudiante: "Estudiante",
                not_found: "No hay datos disponibles",
                ocultar: "Ocultar",
                title1: "Tabla de desempeño por",
                title2: "por estudiante del curso",
                ver_mas: "Ver más",
                descargar_excel: "Descargar como Excel"
            },
            trepa: {
                acciones: "Acciones",
                anterior: "Anterior",
                anterior_desc: "Esta columna señala el resultado obtenido en la última evaluación del estudiante",
                aprob: "Porcentaje de aprobación para 4,0",
                cal: "Nota",
                cancelar: "Cancelar",
                colegio: "*Colegio",
                comparacion: "Comparación",
                comparacion_desc:
                    "Esta columna señala la diferencia porcentual del resultado del estudiante con la media de su curso en esta evaluación",
                confirmar: "Confirmar",
                correctas: "Correctas",
                curso: "*Curso",
                descargar_excel: "Descargar como Excel",
                escala: "Info Escala",
                escala_desc: "Las notas de esta evaluación fueron calculadas con la siguiente escala",
                estudiante: "Estudiante",
                evolucion: "Evolución",
                evolucion_desc: "Esta columna señala la variación porcentual entre la evaluación pasada y la actual",
                fecha: "Fecha",
                forma: "Forma",
                id_eval: "ID Evaluación",
                incorrectas: "Incorrectas",
                max: "Nota Máxima",
                min: "Nota Mínima",
                nombre: "Nombre",
                nombre_eval: "Nombre Evaluación",
                nota: "nota",
                oficial: "Oficial",
                omitidas: "Omitidas",
                puntaje: "Puntaje",
                puntuacion: "Puntuación",
                realizado1: "Se han realizado",
                realizado2: "de",
                realizado3: "evaluaciones enviadas",
                realizado4: "estudiantes han realizado la evaluación",
                reforzamiento: "Reforzamiento",
                reiniciar: "Reiniciar",
                resultados: "Resultados",
                rut: "Rut",
                seleccionar_oficial: "Elegir entrega oficial",
                seleccionar_oficial_desc: "Seleccionar intento oficial",
                seleccionar_oficial_detalle:
                    "Se muestra la cantidad de veces que el alumno respondió la evaluación y sus resultados. La evaluación seleccionada se presentará como la entrega oficial al establecimiento",
                tiempo: "Tiempo",
                title: "Resumen por Estudiante",
                ver: "Ver",
                ver_reforzamiento: "Ver reforzamiento",
                ver_sesion: "Ver Sesion"
            },
            trepa_buscador: {
                con_respuestas: "Con respuestas",
                sin_respuestas: "Sin respuestas",
                todos: "Todos"
            }
        },
        info: {
            acciones: "Acciones",
            cursos: "Cursos",
            n_ensayos: "Nº de Ensayos",
            promedio: "Ptje. Promedio",
            title: "Información General"
        },
        profe: {
            imprimir: "Imprimir informe general"
        }
    },
    estudiar: {
        itemsmenu: {
            biblioteca: {
                title: "Biblioteca de Materiales %{evaluacionTipo}",
                curricular: "Biblioteca de Materiales Curriculares",
                prueba_transicion: "Biblioteca de Materiales Prueba de Transición",
                paes: "Biblioteca de Materiales PAES"
            },
            plan_estudios: "Planes de Estudio",
            plan_personales: "Plan de estudio adaptativo",
            streaming: {
                title: "Clases en vivo: %{evaluacionTipo}",
                curricular: "Clases en vivo: Curricular",
                prueba_transicion: "Clases en vivo: Prueba de Transición",
                paes: "Clases en vivo: PAES"
            }
        },
        materiales: {
            descargar: "Descargar",
            favorito: "Favorito",
            favoritos: "Agregar a favoritos",
            fullscreen: "Ver pantalla completa",
            imprimir: "Imprimir",
            material: "Material",
            volver: "Volver",
            youtube: "Ver en YouTube"
        },
        plan_de_clases: {
            aceptar: "Aceptar",
            actividad: "*Actividad",
            agregar: "Agregar",
            asignatura: "Asignatura",
            avance: "Avance",
            avance_est: "Avance estimado",
            cancelar: "Cancelar",
            comenzar_modelo: "Comenzar a partir de un modelo",
            compartido1: "Se ha compartido el plan de clase",
            compartido2: "a los siguientes",
            compartir: "Compartir",
            compartir_plan: "Compartir Plan de clase",
            confirm_message: "¿Está seguro de querer eliminar el plan de clase?",
            confirm_message2:
                "Por favor escriba ELIMINAR en el siguiente campo de texto para confirmar su decisión. Recuerde que esta acción es IRREVERSIBLE.",
            confirm_elimnar_text: "ELIMINAR",
            create: "Crear Plan de Clase",
            cursos_comp: "Cursos compartidos",
            decir_vamos_aqui: "Decir a mi curso que vamos aquí",
            editar_comp: "Editar cursos compartidos",
            eliminar_plan: "Eliminar plan de clase",
            enviar_retroalimentacion: "Enviar retroalimentación",
            estudiantes: "estudiantes",
            form_eliminar: "*Eliminar",
            form_error: "No se pudo borrar el plan de clase, por favor inténtelo más tarde.",
            form_exito: "Exito",
            form_exito_mensaje: "*El plan de clase se ha eliminado exitosamente.",
            listo: "listo",
            marcada_vista: "marcada como vista",
            marcar_vista1: "Marcar",
            marcar_vista2: "como vista",
            material_arrastre: "Arrastre materiales para ordenarlos o desde una sesión a otra",
            material_clases: "Material de clases",
            no_vamos_aqui: "No vamos aquí",
            not_comp: "Este plan no ha sido compartido",
            not_found: "*No se encontraron planes.",
            not_found_grupos: "Usted no puede compartir un plan de clase: No posee",
            plan_aplicado1: "Se ha aplicado la planificación",
            plan_aplicado2: "a los siguientes",
            promedio_logro: "Porcentaje de logro promedio",
            promedio_tiempo: "Porcentaje de tiempo promedio",
            redirigido1: "Serás redirigido en unos segundos al listado de planificaciones o puedes ir haciendo click",
            redirigido2: "acá",
            redirigido3: "Serás redirigido en unos segundos al listado de planes de clases",
            replicar: "Replicar",
            replicar_context: "Replicar Plan a otro curso",
            replicar_success: "Plan replicado correctamente",
            replicar_title: "Replicar Plan",
            required_tipo: "El campo Tipo / nivel es obligatorio",
            retroalimentacion: "Retroalimentación",
            sel_asignatura: "Seleccione asignatura",
            sel_curso: "Seleccione un curso",
            sel_curso_title: "Seleccione Curso",
            sel_ano: "Seleccione año",
            seleccionar_aplicar: "Seleccionar y aplicar",
            seleccione_modelo:
                "Selecciona uno de los modelos propuestos a partir del cual deseas comenzar a crear tu plan de clases",
            select_modelo: "Comenzar plan a partir de este modelo",
            title: "Compartir plan de clase",
            title_simple: "Planes de clases",
            recomendados: {
                titulo: "Planes de clases recomendados"
            },
            title_grupos: "a los cuales compartira el plan de clase:",
            vamos_aqui: "Vamos aquí",
            ver_actividad: "Ver actividad",
            ver_clase: "Ver clase",
            ver_respuestas: "Ver respuestas",
            visible_desde: "Visible desde"
        },
        plan_estudios: {
            asignaturas: "Asignaturas",
            compartidos: {
                titulo: "Planes de estudio compartidos por mi profesor",
                titulo_simple: "Planes de estudio"
            },
            editar: "Editar",
            evaluacion: "Evaluación",
            msj_no_planes: "no se ha encontrado ningún plan.",
            recomendados: {
                titulo: "Planes de estudio recomendados"
            },
            titulo: "Planes de Estudio",
            ver: "Ver"
        },
        plan_personales: {
            btn_comenzar: "Comenzar plan personal",
            plan_personal: {
                avance: "Avance",
                comenzar: "Comenzar",
                continuar: "Continuar",
                continuar_estudiando: "Continuar Estudiando",
                evaluacion_diagnostico: "Evaluación de diagnóstico",
                mensaje_continuar: "Completa las sesiones de estudio antes de volver a diagnosticar.",
                pendiente: "Pendiente",
                repasar: "Repasar",
                repaso_sesion: "Te recomendamos repasar:",
                resultados: "Resultados",
                subtitulo: "Progreso de tu plan personal",
                subtitulo_sesion: "Sesión de estudio",
                titulo_sesion: "Sesión",
                clase_actual: "Clase Actual"
            },
            plan_personal_evaluacion: {
                desempeno: "Tu último porcentaje de desempeño fue de %{evaluacionPorcentaje}%",
                soluciones: "Soluciones",
                volver_a_realizar: "Volver a realizar"
            },
            plan_personal_sesion: {
                anterior: "Anterior",
                siguiente: "Siguiente",
                siguiente_sesion: "Siguiente sesión",
                subtitulo: "Sesión",
                titulo: "Plan personal",
                titulo_evaluacion: "Evaluación",
                volver_planes_personales: "Volver a los Planes Personales",
                pronto: "Pronto",
                no_disponible: "Esta sección aún no se encuentra disponible",
                volver_plan: "Volver al plan"
            },
            plan_personal_sesiones: {
                ciclo: "Ciclo",
                ciclo_anterior: "Ciclo anterior",
                ciclo_siguiente: "Ciclo siguiente"
            },
            side_menu: {
                instrucciones: {
                    parrafo1: "Selecciona el tipo de plan que te interesa y realiza la evaluación de diagnóstico.",
                    parrafo2:
                        "Evaluaremos qué áreas necesitas reforzar y te propondremos un plan de estudio personal de acuerdo a tus resultados.",
                    parrafo3: "Completa las sesiones de este plan y luego reevalúa tus fortalezas y debilidades.",
                    titulo: "Instrucciones:"
                },
                select_tipo: "Seleccione un tipo",
                tipo_diagnostico: "Tipo de diagnóstico:",
                no_seleccionado_tipo_diagnostico: "No se ha seleccionado Tipo de diagnóstico:"
            },
            titulo: "Planes adaptativos",
            tipo_diagnostico: "tipo de diagnóstico"
        },
        showcasebox: {
            material_favorito: {
                blurb: "¿Encontraste una guía y/o video que te gustó? Puedes agregarlo a tu lista de Favoritos.",
                lista: "Lista de favoritos",
                titulo: "Material favorito",
                ver: "Ver"
            },
            plan_estudios: {
                agregar: "Agregar Plan de Estudio",
                continuar: "Continuar",
                desactivar: "Desactivar",
                planesAdactativosTitulo: "Planes Adaptativos",
                planesDeEstudioTitulo: "Planes de estudio",
                titulo: "Mis Planes de estudio"
            }
        },
        streaming: {
            anteriores: "Clases Anteriores",
            horario: "Horario clases de streaming",
            materiales: {
                fecha: "Fecha de subida:",
                ver: "Ver",
                tipo_evaluacion: "Evaluación de ejercitación",
                tipo_guia_estudio: "Guía de estudio"
            },
            offline: "Transmisión offline.",
            relacionado: "Material relacionado",
            search: {
                search_placeholder: "Buscar por nombre...",
                todos: "Todos"
            },
            sin_canal: "No existe un canal asociado.",
            visitar_canal: `Puedes visitar nuestro canal
                                                <a href="https://www.youtube.com/channel/%{canal}?view_as=subscriber" target="_blank">
                                                                aquí
                                                </a>
                                                .`,
            vivo: "En vivo!",
            ultima_clase: "Última Clase"
        },
        titulo: "Estudiar"
    },
    evaluacion: {
        editar: {
            alumnos_can: "Los estudiantes podrán realizar",
            cancelar: "Cancelar",
            desde: "Desde el día",
            desde_ver_warn: "debe tener una fecha de publicación de resultados",
            desde_ver1: "Los estudiantes podrán ver las respuestas de",
            desde_ver2: "a partir del día",
            desde_warn: "debe tener una fecha de inicio",
            editar: "Editar",
            fechas_warn: "La fecha de Inicio debe ser antes que la fecha de término",
            grupos_ver: "a los cuales compartir",
            grupos_ver_warn: "Debe seleccionar al menos un",
            grupos_ver_warn2_1: "Al desmarcar un",
            grupos_ver_warn2_2: "este ya no podrá acceder a la evaluación y no podrás ver sus resultados",
            hasta: "Hasta el día",
            hasta_warn: "debe tener una fecha de término",
            nombre: "Nombre",
            sin_grupos1: "Usted no puede compartir una evaluación: No posee",
            sin_grupos2: "activos"
        },
        compartidas: {
            evaluaciones: "Evaluaciones",
            fin: "Fin",
            historial: "Historial de simulacros compartidos",
            inicio: "Inicio",
            not_found: "No se encontraron evaluaciones compartidas.",
            publicacion: "Publicación resultados",
            realizar: "Realizar",
            ver: "Ver"
        }
    },
    evaluacion_recomendada: {
        comenzar: "Comenzar",
        estadisticas: "Estadísticas",
        preguntas_abreviacion: "Preg",
        repetir_evaluacion: "Realizar nuevamente",
        recomendadas: "Recomendadas"
    },
    vista_evaluacion_multiple_compartida: {
        titulo: "Simulacros Saber Pro"
    },
    evaluacion_secuencial: {
        anterior: "Anterior",
        duda_anterior: "Duda Anterior",
        duda_siguiente: "Duda Siguiente",
        no_completar: "No, quiero volver",
        pregunta_completar: "¿Quiere completar esta sección?",
        si_completar: "Sí, la quiero completar",
        si_completar_param: "Sí, la quiero %{completar}",
        siguiente: "Siguiente"
    },
    evaluar: {
        buscador: {
            boton_buscar: "Buscar",
            placeholder: "Id Instrumento",
            buscar: "Buscar Instrumento existente",
            resultados: "Resultados de la Búsqueda",
            tabla: {
                id: "Instrumento Nº",
                instrumento: "Instrumento",
                not_found: "No se ha encontrado el instrumento.",
                preguntas: "preguntas",
                seleccionar: "Seleccionar",
                tipo: "Tipo"
            }
        },
        dynamic_tabs: {
            buscar: "Buscar instrumento",
            compartir: "2. Compartir",
            compartir2: "a partir del Instrumento seleccionado",
            generar: "Generar nuevo instrumento",
            instrumento: "Instrumento",
            instrumento_multiple: "Instrumento Múltiple",
            mis_propias: "Mis pruebas propias",
            seleccione: "1. Seleccione un método para generar %{evaluacionTipo}",
            seleccione_recomendados: "Seleccione alguno de nuestros instrumentos recomendados",
            seleccione2: "%{evaluacionTipo} se generan a partir de un Instrumento de evaluación.",
            seleccione3: "Seleccione un instrumento para generar la evaluación a partir de las siguientes opciones:",
            subir: "Subir prueba propia",
            tab_compartir: "Compartir",
            tab_pauta: "Pauta",
            terminar: "Para terminar de generar",
            terminar2: ", elija la opción de Compartir en el menú.",
            utilizar: "Utilizar instrumento recomendado"
        },
        generar_instrumento: {
            opciones: {
                asignar: "Crear %{evaluacionTipo} para imprimir",
                button_undo: "Guardar sin configurar (no compartir)",
                cancel_button: "Cancelar",
                cerrar_button: "Cerrar",
                compartir: "Compartir %{evaluacionTipo}",
                enviar: "Enviar %{evaluacionTipo}",
                error1: "Se ha producido un error al generar su archivo para impresión.",
                error2: "Por favor espere unos minutos e intente de nuevo.",
                impresion: {
                    tooltip:
                        "Al crear una prueba para imprimir, tendrás que subir los resultados de tus alumnos ya que no se les enviará una notificación para ser respondida online",
                    tooltip_label: "¿Qué es esto?",
                    alumnos: "Imprimir para alumnos",
                    alumnos_compartir_copiar: "Copiar",
                    alumnos_compartir1: "Los estudiantes podrán",
                    alumnos_compartir2: "en el enlace",
                    formas: "Imprimir para alumnos con formas",
                    formas_detail: "(sin respuestas)",
                    hoja: "Imprimir hojas de respuestas",
                    hoja_formas: "Imprimir hojas de respuestas con formas",
                    hoja_formas_detail: "(por alumno)",
                    hoja_vacia: "Imprimir hoja de respuesta <b>vacía</b>",
                    hoja_vacia_detail: "(sin pre llenar)",
                    label: "Impresión",
                    profes: "Imprimir para profesor",
                    profes_formas: "Imprimir para profesor con formas",
                    profes_formas_detail: "(con respuestas)",
                    titulo: "Opciones de impresión",
                    instructivo_hoja_respuestas: "Instructivo y mejores prácticas",
                    warning:
                        "Advertencia: Estamos optimizando la impresión de los ensayos, por lo que se pueden generar vistas con\npequeños errores de formato. Para esto, favor contactar al correo contacto@ogr.cl para dar solución en\ncaso de haber algún problema."
                },
                loading_message1: "Estamos generando el archivo para su impresión.",
                loading_message2: "Esta operación puede tardar unos minutos, por favor espere.",
                mas: "Más opciones",
                tabla_espec: "Descargar Tabla Especificaciones",
                popup: {
                    titulo: "¿Está seguro que desea proceder con esta acción?",
                    contenido: "La evaluación se creará con %{num_preguntas} preguntas.",
                    contenido_pilotaje:
                        "La evaluación se creará con %{num_preguntas} preguntas en donde %{num_pilotaje} son de pilotaje. Estas corresponden a las preguntas %{id_pilotajes}."
                },
                download_instrumento_material: {
                    title: "Descargar archivo original",
                    tooltip:
                        "Te recomendamos descargar el archivo word desde este botón ya que en las opciones de impresión solo podrás ver la forma y las hoja de respuesta."
                }
            },
            profesor: {
                cantidad_tiempo: "Cantidad de preguntas y tiempo",
                generar: "Generar Instrumento de evaluación",
                personalizar: "Personalizar",
                select_asignatura: "Seleccione asignatura",
                select_categorias:
                    "Selecciona las categorías para personalizar los contenidos del instrumento de evaluación.",
                total_preguntas: "Total de preguntas"
            }
        },
        pasos: {
            de: "de",
            sel_eval_asig: {
                asignatura: "Asignatura",
                button: "Confirmar y continuar",
                desc: "Indique el tipo de evaluación, la asignatura y el tiempo de la prueba propia.",
                message: "Tipo de evaluación, asignatura y tiempo.",
                missing: "Faltan campos por llenar.",
                sel_asig: "Seleccione Asignatura",
                tiempo: "Tiempo (minutos)",
                tipo: "Tipo/Nivel"
            },
            sel_tipo: {
                button: "Confirmar y continuar",
                desc: "Indique el tipo de respuesta de la prueba propia.",
                message: "Tipo de respuesta",
                missing: "Faltan campos por llenar.",
                tipo: "Tipo de Respuesta"
            },
            step: "Paso",
            subir_archivo: {
                button: "Subir Archivo",
                desc: "El archivo debe ser en formato PDF o Word y no debe exceder los 35 MB",
                error: "El archivo no corresponde a un PDF válido.",
                header: "Sigue los siguientes pasos",
                message: "Adjunta el archivo con tu prueba."
            },
            subir_pauta: {
                button: "Confirmar pauta y continuar",
                desc: "Establece cuantas alternativas tendran las preguntas",
                instr1: "Completa la siguiente hoja de respuestas con las alternativas correctas de tu prueba, para\nestablecerla como pauta.",
                instr2: "Completa solo el número de preguntas que tenga tu evaluación.",
                instr3: "Si quieres dejar una pregunta como piloto y no considerarla en la calificación, haz clic en\n(P).",
                instr4: 'Una vez completada la pauta, selecciona "Confirmar pauta y continuar".',
                instruccion_puntuacion: "Puedes colocar una puntuación personalizada a cada pregunta.",
                message: "Establecer Pauta.",
                warn1: "Debe llenar la pauta.",
                warn2: "No pueden haber alternativas vacias entre medio.",
                warn3: "El numero de alternativas no puede ser menor a 2 ni mayor a 5."
            },
            subir_pauta_mixta: {
                button: "Confirmar pauta y continuar",
                desc: "Establece cuántas preguntas tiene la prueba",
                instr1: "Completa e indica el tipo de pregunta para cada una, incluyendo la cantidad de alternativas\ny la alternativa correcta si se trata de una pregunta de alternativas.",
                instr2: "Si quieres dejar una pregunta como piloto y no considerarla en la calificación, haz clic en\n(P).",
                instr3: 'Una vez completada la pauta, selecciona "Confirmar pauta y continuar".',
                instruccion_puntuacion: "Puedes colocar una puntuación personalizada a cada pregunta.",
                message: "Establecer Pauta.",
                warn: "El número de preguntas es requerido y tiene que ser mayor a 0.",
                warn1: "Tiene que llenar todos los campos, tanto la cantidad de alternativas de cada pregunta\ncomo su alternativa correcta.",
                warn2: "El numero de alternativas de cada pregunta no puede ser menor a 2.",
                warn3: "La alternativa correcta tiene que corresponder con el número de alternativas de cada\npregunta.",
                warn4: "La alternativa correcta sólo puede tener un carácter.",
                warn5: "El número de preguntas no puede ser mayor a 90."
            },
            clasificar_preguntas: {
                button_clasificar: "Clasificar",
                button_skip: "Ahora no y continuar",
                button_prev: "Anterior",
                button_next: "Siguiente",
                button_save: "Guardar clasificaciones y continuar",
                message: "Clasificar preguntas",
                desc: "Clasificar tus preguntas en contenidos y/o habilidades te permitirá obtener un informe de resultados más completo en las estadísticas."
            },
            tc: {
                accept1: "Acepto los",
                accept2: "términos y condiciones",
                accept3: "respecto al almacenamiento y privacidad de esta evaluación.",
                button: "Completar subida de prueba propia",
                desc: "Establece la cantidad preguntas",
                desc2: 'Para terminar, confirme que está de acuerdo con los términos y condiciones antes de seleccionar\nel botón de "Completar subida de prueba propia".',
                message: "Cantidad de preguntas.",
                message2: "Términos y condiciones.",
                modal_part01:
                    "Los Términos y Condiciones generales que a continuación se establecen regulan el uso de la comunidad de",
                modal_part02: "creada a través del sitio",
                modal_part03: "(en adelante",
                modal_part04: "), el cual es puesto a disposición de los usuarios de internet.",
                modal_part05:
                    'La sola utilización del portal le atribuye la condición de usuario (en adelante, el "usuario") de la\ncomunidad de',
                modal_part06:
                    "y expresa la aceptación plena y sin reservas de todos y cada uno de sus Términos y Condiciones que se\nencuentren publicados al acceder al portal",
                modal_part07:
                    "En consecuencia, el usuario deberá leer atentamente los Términos y Condiciones en cada una de las\nocasiones en que se proponga utilizar o acceder al portal",
                modal_part08: "Los Términos y Condiciones podrán ser modificados, total o parcialmente, por",
                modal_part09:
                    "y dichos cambios tendrán vigencia a partir del momento mismo en que sean publicados o insertados en el\nsitio de la comunidad o desde que sean notificados al usuario por cualquier medio. A partir de lo\nexpuesto, se sugiere que los visite periódicamente.",
                modal_part10: "Las violaciones a los Términos y Condiciones otorgan a",
                modal_part11:
                    "el derecho de suspender o terminar la prestación del servicio al usuario que las haya realizado, por\nacción u omisión.",
                modal_subcontent1_11:
                    'Se entenderá por "contenido subido" todo archivo que cargue un usuario en la plataforma',
                modal_subcontent1_12: "o docentes.",
                modal_subcontent1_13:
                    "El usuario se obliga a cargar “contenidos subidos” de forma diligente, correcta y lícita.",
                modal_subcontent1_21: "El usuario autoriza a",
                modal_subcontent1_22: "a mantener el contenido subido en los servidores de",
                modal_subcontent1_23: "en un plazo perpetuo. El contenido sólo podrá ser mostrado por parte de",
                modal_subcontent1_24:
                    "a usuarios que estén asociados a un mismo establecimiento que el usuario que cargó el contenido subido.",
                modal_subcontent1_31: "no será responsable por el uso indebido que hagan los usuarios de la comunidad",
                modal_subcontent1_32: "del contenido subido a la plataforma por usuarios docentes.",
                modal_subcontent1_41:
                    "no garantiza la veracidad del contenido subido por un usuario docente a la plataforma.",
                modal_subtitle1: "1° Cargar contenido",
                modal_title: "Términos y condiciones"
            },
            vista_previa: {
                button: "Confirmar y continuar",
                desc: "Confirma que el documento se ha subido correctamente con la vista previa.",
                message: "Vista previa."
            }
        },
        pruebas_propias: {
            abrir: "Abrir",
            acciones: "Acciones",
            compartir: "Compartir",
            fecha: "Fecha de creación",
            instrumento: "Instrumento",
            not_found: "Sin resultados para esta categoría",
            title: "Pruebas propias",
            tipo_contestables: "Tipo de respuestas"
        },
        recomendados: {
            not_found: "No se encontraron instrumentos recomendados para esta categoría.",
            seleccionar: "Seleccionar",
            ver: "Ver descripción"
        },
        subir_propia: {
            asignar_impr: "Asignar Impresión",
            boton_subir: "Subir nueva prueba propia",
            cancel_button: "Cancelar",
            cerrar_button: "Cerrar",
            comp_tarde: "Compartir más tarde",
            enviar_online: "Enviar Online",
            error1: "Se ha producido un error al generar su archivo para impresión.",
            error2: "Por favor espere unos minutos e intente de nuevo.",
            exito: "*Tu prueba se ha subido exitosamente y se ha guardado como el",
            instrumento: "Instrumento",
            loading_message1: "Estamos generando el archivo para su impresión.",
            loading_message2: "Esta operación puede tardar unos minutos, por favor espere.",
            loading1: "Preparando subida",
            loading2: "Subiendo archivo",
            loading3: "Asociando recurso",
            loading4: "Creando Instrumento (1/2)",
            loading5: "Creando Instrumento (2/2)",
            sugerencia: "Si tienes sugerencias para mejorar esta función, escríbenos a contacto@ogr.cl"
        }
    },
    extras: {
        Abril: "Abril",
        Agosto: "Agosto",
        Diciembre: "Diciembre",
        Enero: "Enero",
        Febrero: "Febrero",
        Julio: "Julio",
        Junio: "Junio",
        Marzo: "Marzo",
        Mayo: "Mayo",
        Noviembre: "Noviembre",
        Octubre: "Octubre",
        Septiembre: "Septiembre"
    },
    footer: {
        aprende: {
            estudiar: "Estudiar",
            inicio: "Inicio",
            intro: "Aprende con",
            orientacion: "Orientación",
            participa: "Participa",
            practicar: "Practicar",
            progreso: "Tu progreso"
        },
        ayuda: {
            sobre: {
                item: "Aprendo libre en otros países",
                titulo: "SOBRE APRENDO LIBRE"
            },
            soporte: {
                item: "Terminos y Condiciones",
                item2: "Perguntas más frecuentes",
                contacto: "contacto@ogr.cl",
                titulo: "SOPORTE"
            }
        },
        contacto: {
            correo: "contacto@ogr.cl",
            escribe: "¿Dudas? Escríbenos:",
            titulo: "Contacto"
        },
        derechos: 'Todos los Derechos Reservados a "Open Green Road S.A." ®',
        info: {
            ayuda: "Preguntas frecuentes",
            terms: "Términos y condiciones de uso",
            titulo: "Más información"
        },
        navegacion: "Navegación",
        siguenos: "¡Síguenos!",
        social_titulo: "Encuéntranos en redes sociales",
        informacion_contacto: "Información de contacto"
    },
    general: {
        detalles: "Detalles",
        nombre: "nombre",
        ver_mas: "Ver más",
        ver_menos: "Ver menos"
    },
    generar_ensayos: {
        asignatura: "Escoge Asignatura",
        configurar: "Configurar ",
        configurar_preguntas_tiempo: "Cantidad de preguntas y tiempo",
        descargar: "Descargar",
        descripcion_personalizar:
            "Haz clic en las diferentes categorías para personalizar los contenidos de la evaluación.",
        loading_mensaje_dos: "Esta operación puede tardar unos segundos, por favor espere.",
        loading_mensaje_uno: "Estamos generando el archivo para su impresión.",
        modal_instrucciones_descargar: {
            boton: "Entendido",
            parrafo1: `En la última página de la evaluación se encuentra una hoja de respuesta.
                                                <br />
                                                Llénala con tus datos y luego escanea el código QR desde tu celular.`,
            parrafo2: `El código QR te dirigirá a una página en la cual debes subir una foto de tu hoja de respuesta
                                                contestada.
                                                <br />
                                                Toma la foto con luz clara y la hoja derecha.`,
            titulo: "Instrucciones para subir tus resultados"
        },
        personalizar: "Personalizar",
        realizar: "Realizar",
        titulo: "Generar"
    },
    generar_ensayo_profesor: {
        site_warning: "<p>Estamos trabajando para habilitar nuevas funcionalidades en esta sección.</p>",
        titulo: "Generar %{evaluacionTipo}"
    },
    generar_instrumento_select: {
        titulo_option: "Seleccione asignaturas"
    },
    grafico_desempeno_clasificacion: {
        descripcion:
            "En una escala de 0 a 100, donde 0 es no logrado y 100 es totalmente logrado, se muestran las respuestas correctas respecto al total de preguntas aplicadas de cada ",
        desempeno: "Desempeño",
        titulo: "Desempeño por "
    },
    grupo_usuarios: {
        popup: {
            cancelar: "Cancelar",
            debe_seleccionar: "Debe seleccionar al menos un"
        },
        profesores: {
            all_establecimiento: "Todos los establecimientos",
            info: {
                establecimiento: "Establecimiento",
                integrantes: "integrantes",
                modals: {
                    restart_password: {
                        q1: "¿Está seguro que desea restablecer la contraseña de ",
                        q2: "?",
                        title: "Confirmación*"
                    },
                    restart_password2: {
                        a1: "El usuario no posee ",
                        a2: " válido. Debido a la falta de datos, por favor póngase en contacto con el encargado correspondiente antes de proceder.",
                        a3: "La contraseña ha sido restablecida exitosamente a los primeros 6 dígitos del ",
                        a4: ".",
                        title: "Aviso"
                    },
                    restart_passwords: {
                        q1: "¿Está seguro que desea restablecer la contraseña del curso ",
                        q2: "?",
                        title: "Confirmación"
                    },
                    restart_passwords2: {
                        a1: "*La contraseña ha sido restablecida exitosamente a los primeros 6 dígitos del ",
                        a2: ".",
                        title: "Aviso"
                    }
                },
                ocultar: "Ocultar",
                restart_passwords: "Restablecer todas las contraseñas",
                tabla: {
                    acciones: "Acciones",
                    n_ingresos: "Nº ingresos últimos 30 dias",
                    nombre: "Nombre",
                    restart_password: "Restablecer contraseña",
                    ultima_fecha: "Última fecha de ingreso"
                },
                ver: "Ver"
            },
            list: "Lista de",
            not_found1: "Usted no posee",
            not_found2: "asociados para el periodo seleccionado",
            select_establecimiento: "Seleccione un establecimiento",
            cursos: "cursos",
            titulo: "Mis Niveles"
        }
    },
    historial: {
        eval_comp: {
            acciones: "Acciones",
            avance: "Avance",
            cierre: "Cierre",
            creacion: "Creación",
            creador: "Creador",
            edit_eval: "Editar evaluación",
            est: "Establecimiento",
            eval: "Evaluaciones",
            eval_id: "Evaluación ID",
            eval_not_found: "No se encontraron %{evaluacionTipo}",
            eval_not_found2: "Usted no posee grupos asociados",
            inicio: "Inicio",
            inst_id: "Instrumento ID",
            pers_rev: "Personalizar revisión",
            propia: "Prueba Propia",
            recompartir: "Volver a compartir",
            sel_curso: "Seleccione un curso",
            sel_doc: "Seleccione docente",
            sel_est: "Seleccione un establecimiento",
            sel_eval: "*Buscar por Evaluación ID",
            sel_inst: "*Buscar por Instrumento ID",
            titulo_impresas: "%{evaluacionTipo} %{tipo}",
            titulo_online: "%{evaluacionTipo} %{tipo}",
            filtros: "Filtros de búsqueda",
            usada_plan: "Usada en planificación",
            ver_est: "Ver estadísticas",
            ver_eval: "Ver evaluación"
        },
        eval_no_comp: {
            acciones: "Acciones",
            comp_inst: "Compartir instrumento",
            creacion: "Creación",
            creador: "Creador",
            inst_id: "Instrumento ID",
            not_found: "No se encontraron instrumentos en esta categoría",
            sel_curso: "Seleccione un curso",
            sel_doc: "Seleccione docente",
            sel_est: "Seleccione un establecimiento",
            sel_inst: "Buscar por Instrumento ID",
            sub_title: "Filtros de búsqueda",
            title: "Instrumentos sin configurar (no compartidos)",
            ver_inst: "Ver instrumento"
        },
        eval_pres: {
            eval_not_found: "No se encontraron",
            eval_not_found2: "presenciales",
            sel_curso: "Seleccione un curso",
            sel_est: "Seleccione un establecimiento",
            tabla: {
                acciones: "Acciones",
                colegio: "Colegio",
                creador: "Creador",
                instrumento: "Instrumento",
                nombre: "Nombre",
                tipo: "Tipo"
            },
            title: "presenciales"
        }
    },
    home: {
        itemsmenu: {
            como_usar: "¿Cómo usar PuntajeNacional?",
            generar_ensayo: "Hacer ensayo",
            plan_estudios: "Planes de Estudio",
            streaming: "Clases en vivo",
            logros: "Logros",
            subir_hoja: "Subir hoja de respuestas"
        },
        resumen: {
            dudas: "Dudas contestadas",
            ensayos: "Ensayos",
            preguntas: "Preguntas contestadas",
            semana: "Esta semana has hecho..."
        },
        showcasebox: {
            carreras_interes: {
                sin_carreras: "Aún no has seleccionado tus carreras de interés.",
                titulo: "Mis carreras de interés",
                ver_todas: "Ver todas"
            },
            dudas_recientes: {
                contestada: "Contestada %{resp} veces",
                solucionada: "Solucionada",
                creado_por: "Creado por",
                no_dudas: "No se encontraron dudas recientes.",
                pendiente: "Pendiente",
                titulo: "Dudas recientes",
                ver_mas: "Ver más"
            },
            grupo_usuarios: {
                actualizaciones: "Últimas actualizaciones",
                ir_cursos: "Ir a Mis Cursos",
                sin_cursos1: "No tienes",
                sin_cursos2: "asociados",
                soy_docente: "Cursos donde soy Docente",
                titulo: "Mi curso",
                todos: "Ver todos"
            },
            noticias: {
                cerrar: "Cerrar",
                none: "Sin noticias por el momento.",
                titulo: "Noticias",
                ver_mas: "Ver más"
            },
            notificaciones: {
                titulo: "Notificaciones",
                ver_mas: "Ver más"
            }
        },
        welcome: "Bienvenid@"
    },
    informe_alumno: {
        titulo: "Informe de Estudiantes",
        instrucciones: "Instrucciones",
        instrucciones_desc:
            "A continuación podrás ver y descargar Informes de Uso y desempeño en la plataforma de cada uno de tus estudiantes. Selecciona un establecimiento, un curso, un estudiante y un período de tiempo que quieras observar para obtener el/los informes.",
        descarga: {
            button_individual: "Descargar informe individual",
            desc_button_individual: "Se descargará el informe del estudiante seleccionado en formato PDF.",
            button_grupo: "Descargar informes del curso",
            desc_button_grupo:
                "Se descargará una carpeta comprimida con los informes de todos los estudiantes del curso en formato PDF. Esto puede tardar unos minutos dependiendo de la cantidad de estudiantes. Si el curso tiene más de 100 estudiantes, no es posible realizar la descarga.",
            loading_message:
                "Estamos enviando la información a los estudiantes por e-mail.<br />Esta operación puede tardar unos minutos, por favor espere.",
            error_message:
                "Se ha producido un error al generar su archivo para descargar.<br />Por favor espere unos minutos e intente de nuevo."
        },
        send_email: {
            button_grupo: "Enviar a estudiantes por correo",
            desc_button_grupo:
                "Se enviará un correo a cada estudiante del curso con botón para acceder a su informe de uso."
        },
        informe: {
            titulo: "INFORME DE ESTUDIANTE",
            descripcion:
                "En el siguiente informe encontrarás información sobre el uso y desempeño en la plataforma de:",
            subtitulo_uso: "Uso de la plataforma",
            detalle_uso: "A continuación se detallan algunas estadísticas de uso de la plataforma:",
            n_ingresos: "Número de ingresos a la plataforma:",
            n_ingresos_desc:
                "(Este número se calcula en base a la cantidad de veces que un alumno hace Login en la plataforma, no contabiliza la cantidad de veces que ingresó sin autentificarse. La plataforma permanece autentificada por varios días a menos que se cierre la sesión o no se utilice)",
            n_mat_vistos: "Cantidad de materiales vistos:",
            n_mat_descargados: "Cantidad de materiales descargados:",
            tiempo_view_mat: "Tiempo visualizando materiales:",
            n_sesiones_terminadas: "Sesiones de planes de clase terminadas:",
            n_eval_respondidas: "Número de evaluaciones respondidas:",
            logros_obtenidos: "Logros obtenidos:",
            logros_obtenidos_desc:
                "En la medida en que los estudiantes desarrollan ciertas actividades en la plataforma (como estudiar, practicar, participar, etc.) obtienen reconocimientos llamados “Logros”.",
            resultados: {
                titulo: "Resultados",
                descripcion:
                    "A continuación se presenta el desempeño de el o la estudiante por asignatura, para todas las evaluaciones que haya respondido, tanto a través de su establecimiento como de forma autónoma.",
                resumen_gral: { titulo: "Resumen general" }
            }
        }
    },
    instruncciones_fijas: {
        instruccion: "Instrucción"
    },
    instrumento: {
        titulo: "Instrumento",
        vista_previa: "Vista previa"
    },
    landing: {
        inicio: {
            seccion1: {
                subtitulo: `Completamente <em>GRATIS</em> , para siempre`,
                titulo: `Preparación para <em>ENEM 2023</em>`
            },
            seccion2: {
                subtitulo: `Online, Fácil y efetivo`,
                titulo: `Preparación gratuita para as
                                                                <em>4 prurbas</em>
                                                                de ENEM`
            },
            seccion3: {
                btn: `Comenzar ahora!`,
                parrafo: `El acceso a
                                                                <em>Aprendo Libre</em>
                                                                es totalmente gratuito para todos los alumnos que buscan prepararse para la ENEM de forma
                                                                online y efetiva.`,
                titulo: `Soporte
                                                                <em>gratuito</em>
                                                                para alumnos`
            },
            seccion4: {
                contenido1: {
                    parrafo: `Encontrarás clases y videos con material para ENEM, haz preguntas con el
                                                                                Comunidad, participa en las pruebas mensuales y participa en el ranking.`,
                    titulo: `Material multimedia`
                },
                contenido2: {
                    parrafo: `A través de nuestros Planes Adaptativos, te recomendamos sesiones de estudio personalizadas
                                                                                para mejorar sus conocimientos y habilidades.`,
                    titulo: `Planes Adaptativos`
                },
                contenido3: {
                    parrafo: `Practique para el examen con cuestionarios de opción múltiple y acceda al
                                                                                realimentación.`,
                    titulo: `Realimentación inmediata`
                },
                subtitulo: `Estas son solo algunas de las características que tenemos para apoyar su preparación.`,
                titulo: `Te ayudamos a prepararte para dar <em>lo mejor de ti</em>`
            },
            seccion5: {
                btn: `Comenzar`,
                parrafo: `Consulta nuestro calendario de clases en vivo para las 4 pruebas y no te pierdas ninguna de nuestras sesiones de preparación ENEM.`,
                titulo: `Clases en vivo gratis para ENEM`
            },
            topfooter: {
                btn: `Suscribirse`,
                titulo: `Regístrese en Aprendo Livre hoy.
                                                                <b>Es gratis y siempre lo será</b>`
            }
        },
        login: {
            btn_email: "Ingresar",
            btn_fb: "Ingresar con Facebook",
            btn_google: "Acceder con <em>Google</em>",
            btn_home: "Home",
            btn_logout: "Cerrar Sesión",
            btn_recover: "¿Olvidaste tu contraseña?",
            btn_register: "Regístrate con tu correo",
            error_auth1:
                "<strong>La combinación de correo electrónico/contraseña o NUIP/contraseña es incorrecta.</strong> Las contraseñas de ",
            error_auth2:
                ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.',
            error_auth3:
                "Para aumentar el resguardo de tus datos aumentamos los requisitos de seguridad de las contraseñas, por lo que debes ingresar una nueva. Para esto debes dirigirte a ¿Olvidaste tu contraseña? o enviar un correo a %{mail}",
            error_auth3_UST:
                "Para aumentar el resguardo de tus datos aumentamos los requisitos de seguridad de las contraseñas, por lo que debes ingresar una nueva. Para esto debes dirigirte a ¿Olvidaste tu contraseña? o enviar un correo a contactost@ogr.cl",
            error_auth4:
                "La combinación de correo electrónico/contraseña es incorrecta. Si no recuerdas tu contraseña haz click <a href='/usuarios/passwords/forgot'>aquí</a>",
            error_auth5: " para restablecerla.",

            error_pswd: "Debes incluir una contraseña.",
            error_user: "Debes incluir un correo electrónico o NUIP válido.",
            pswd: "Contraseña",
            titulo: "Ingresar",
            titulo_alumno: "Iniciar sesión",
            user: "Email o NUIP"
        },
        menu: {
            aulas_vivo: "Clases en vivo",
            comenzar: "¡Empieza hoy!",
            como_funciona: "¿Cómo funciona?",
            contacto: "Contacto",
            pagina_inicial: "Página Inicial",
            provas_enem: "Pruebas ENEM"
        },
        registro: {
            datos_personales: {
                apellido_materno: "Apellido Materno",
                apellido_paterno: "Apellido Paterno",
                confirmar_password: "Confirmar contraseña",
                cpf: "CPF",
                //wtf xD
                email: "Email",
                municipio: "Município",
                //Entendiendo caleta lo q es i18n
                nombres: "Nombres",
                password: "Contraseña",
                telefono: "Teléfono",
                terminos_y_condiciones:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de %{companyName}</a>",
                year_egreso: "¿En que año egreso?"
            },
            completar_social: {
                titulo: "Completa tus datos"
            },
            msgSaved: `Le enviamos un email con instrucciones instrucciones para validar su cuenta. Si no puede verlo en 5 minutos, verifique su SPAM`,
            titulo: `Datos personales`,
            tituloDeslogueado: "Crear cuenta"
        },
        recuperar_password: {
            instruccion_email:
                "Ingresa tu correo electrónico o %{identificador} para recibir instrucciones de cómo restablecer tu contraseña.",
            instruccion_email_identificador:
                "Ingresa tu %{identificador} para recibir instrucciones de cómo restablecer tu contraseña.",
            instruccion_email_no_identificador:
                "Ingresa tu correo electrónico para recibir instrucciones de cómo restablecer tu contraseña.",
            boton_volver: "Volver",
            boton_continuar: "Continuar",
            boton_reenviar: "Reenviar",
            mensaje_recuperacion:
                "Si el correo electrónico entregado existe, un mensaje se te enviará a tal dirección con instrucciones. Si no ves el mensaje en la bandeja de entrada, busca en la carpeta de correo no deseado.",
            mensaje_recuperacion_id:
                "Si el identificador ingresado existe, un mensaje se te enviará a la dirección %{email} con las instrucciones.",
            mensaje_contacto_no_modal:
                "¿No recibiste el correo?<br />Para acceder a tu cuenta escríbenos a <b>%{email}</b>."
        },
        reiniciar_password: {
            ingresa_nuevo_password: "Ingresa tu nueva contraseña",
            fortaleza_password: "Fortaleza de la contraseña:",
            muy_debil: "Muy débil",
            debil: "Débil",
            moderada: "Moderada",
            segura: "Segura",
            muy_segura: "Muy segura",
            nuevo_password_placeholder: "Nueva contraseña",
            confirmar_password_placeholder: "Confirmar nueva contraseña",
            password_largo_minimo: "La contraseña debe ser de mínimo 8 carácteres.",
            guardar_password: "Guardar nueva contraseña",
            guardando: "Guardando ...",
            reinicio_exitoso:
                "Se han actualizado tus datos correctamente. Puedes ingresar a tu cuenta con tu nueva contraseña.\nSerás redirigido a la página de inicio en 5 segundos...",
            token_invalido: "Token inválido.",
            campos_no_coinciden: "Los campos no coinciden.",
            password_minimo_largo: "La contraseña tiene que ser de al menos 8 carácteres.",
            password_vulnerable: "Esta contraseña es vulnerable. Prueba colocar otra.",
            error: "Error."
        }
    },
    libro_notas: {
        alumno_aun_no_realizo: "estudiante aún no realiza reforzamiento",
        alumno_realizo: "estudiante realizó reforzamiento",
        buscar_pagina: "Buscar página",
        csv: "Descargar CSV",
        de: "de",
        estudiantes: "Estudiantes",
        evaluacion: "Evaluación",
        fin: "Fin",
        formativa: "Formativa",
        inicio: "Inicio",
        not_found: "Sin resultados",
        not_found_cursos: "No hay cursos para el periodo seleccionado",
        not_found2: "No hay información para mostrar",
        periodo: "Periodo",
        promedio: "Promedio",
        promedio_tooltip_escalas_globales:
            "El promedio de notas del alumno o evaluación es el promedio calculado con notas reales.",
        promedio_tooltip_formativas:
            "Si has aplicado Evaluaciones formativas a tus alumnos, sus resultados no serán considerados para el promedio",
        promedios: "Promedios",
        sumativa: "Sumativa",
        tareas_pagina: "tareas. Página"
    },
    libs: {
        api_services: {
            view_params: {
                usuario: {
                    email: "Correo electrónico",
                    fecha_nacimiento: "Fecha de nacimiento",
                    establecimiento: "Establecimiento"
                }
            }
        },
        modules: {
            estadisticas: {
                timeframe: {
                    desde: "Desde",
                    hasta: "Hasta",
                    ultimo_ano: "Último año",
                    ultimo_mes: "Último mes",
                    ultimos_meses: "Últimos 6 meses"
                }
            }
        }
    },
    materiales_favoritos: {
        abrir: "Abrir",
        confirmacion: "Confirmación",
        confirmacion_mensaje: "¿Está seguro que desea eliminar este material de su lista de favoritos?",
        not_found: "No has guardado materiales favoritos aún",
        title: "Materiales favoritos",
        tutorial: "Explora y administra la lista de materiales que has marcado como favoritos"
    },
    modules: {
        estadisticas: {
            timeframe: {
                desde: "Desde",
                hasta: "Hasta",
                ultimo_ano: "Último año",
                ultimo_mes: "Último més",
                ultimos_meses: "Últimos 6 meses"
            }
        }
    },
    navbar: {
        aria: "Navegación Usuario",
        avatar: {
            aria: "Avatar Usuario",
            default_aria: "Avatar Usuario por Defecto"
        },
        ayuda: "Ayuda",
        edit: "Editar cuenta",
        logout: "Cerrar sesión",
        faq: "Preguntas frecuentes",
        menu: {
            aria: "Menú de usuario"
        }
    },
    noticias: {
        profe: {
            actualizado: "Actualizado el",
            alumnos: "Alumnos",
            alumnos_desc: "todos los alumnos de la plataforma",
            alumnos_libres: "Alumnos libres",
            alumnos_libres_desc:
                "todos los alumnos que se han registrado independientemente o que ya no son alumnos de nómina",
            alumnos_nomina: "Alumnos nomina",
            alumnos_nomina_desc: "alumnos que hacen parte de un establecimiento y grupo actualmente activo",
            confirmacion: "Confirmación",
            confirmacion_message: "¿Está seguro que desea eliminar esta noticia?",
            cursos: "Cursos",
            cursos_compartir: "Cursos a los cuales compartirá la noticia",
            cursos_warn: "Debe seleccionar al menos un curso",
            editar: "Editar noticia",
            editar_boton: "Editar",
            eliminar_boton: "Eliminar",
            establecimient_aplicar: "Establecimiento a aplicar",
            establecimiento: "Establecimiento",
            establecimiento_todos: "Todos los establecimientos",
            grupo_usuarios_todos: "Todos",
            lugar_aplicar: "Lugar a aplicar",
            not_found: "No has publicado notícias aún",
            noticia_perfil: "Noticia Perfil",
            periodo: "Periodo",
            publicar: "Publicar noticia",
            publicar_boton: "Publicar noticia",
            ver_mas_boton: "Ver más"
        },
        form: {
            noticia: "Noticia",
            fecha_inicio: "Fecha inicio noticia",
            fecha_termino: "Fecha término noticia"
        }
    },
    notificaciones: {
        a_tu_r: "a tu resultado en",
        agrego_comentario: "ha agregado un comentario",
        aqui: "aquí",
        de: "de",
        de_la_asginatura: "de la asignatura",
        del: "del",
        del_curso: "del curso",
        del_grupo: "del grupo",
        del_plan_de: "del plan de clases",
        detalles: "Detalles",
        ha_contestado: "ha contestado",
        ha_creado: "ha creado",
        ha_marcado_c: "ha marcado como correcta tu respuesta a",
        ha_subido_m_c: "ha subido material complementario al módulo",
        la_duda: "la duda",
        material_complementario: "ha subido material complementario",
        n_preguntas: "Nº de preguntas",
        no_pendientes: "No tienes notificaciones pendientes.",
        plazo_hasta: "Plazo para responder hasta",
        pro_entrenador: "Configurar tu entrenador virtual",
        pro_imprimir: "Imprimir ensayos",
        pro_planes: "Disfrutar de tus planes de estudio, versión Pro,",
        pro_tutor: "Agregar tu tutor",
        pro1: "Estimad",
        pro2: "le informamos que su cuenta Pro de",
        pro3: "ha sido activada. Ahora puedes",
        profesor: "Profesor",
        realizar_ahora: "¡Realizar ahora!",
        revisalo: "Revísalo",
        te_ha_compartido: "te ha compartido el plan de clases",
        te_ha_compartido_cla: "te ha compartido la clase",
        te_ha_compartido_r: "te ha compartido el reforzamiento",
        te_ha_compartido_ta: "te ha compartido la tarea",
        te_ha_enviado: "te ha enviado una solicitud para realizar",
        te_ha_enviado_un: "te ha enviado un recordatorio para realizar la sesión",
        tiempo: "Tiempo",
        titulo: "Notificaciones",
        ver_todas: "Ver todas",
        pendientes: "Pendientes",
        historial: "Historial de Notificaciones"
    },
    numero_preguntas_tiempo: {
        advertencia_numeros_personalizados_dos: "No pueden tener menos de 5 preguntas.",
        advertencia_numeros_personalizados_tres: "No se consideran para el cálculo de estadísticas.",
        advertencia_numeros_personalizados_uno: "Las evaluaciones personalizadas",
        advertencia_numeros_personalizados_cuatro:
            "Considera que las hoja de respuesta impresa tiene hasta 90 items para responder.",
        ej_num_minutos: "ej. 20",
        ej_num_preguntas: "ej. 5",
        error_limite_numero_preguntas: "El número de preguntas no puede ser mayor a",
        error_cero_preguntas: "El número de preguntas no puede ser 0.",
        mensaje_error: " Ingresa un número de preguntas y minutos válido.",
        minutos: "minutos",
        preguntas: "preguntas"
    },
    orientacion: {
        blog_showcasebox: {
            not_found: "Sin novedades por el momento.",
            titulo: "Lo último"
        },
        carreras: {
            buscador_carreras: {
                buscador_placeholder: "Carrera...",
                buscador_placeholder_sede: "Sede...",
                buscador_placeholder_universidad: "Universidad...",
                buscar: "Buscar",
                carrera: "Carrera",
                mas_opciones_busqueda: "Ver más opciones de búsqueda",
                menos_opciones_busqueda: "Ver menos opciones de búsqueda",
                not_found: "No se han encontrado carreras con esos criterios de búsqueda.",
                opciones_adicionales: "Opciones adicionales de búsqueda:",
                opciones_adicionales_placeholder: "Seleccione una opción",
                sede: "Sede",
                seguir: "Seguir",
                siguiendo: "Siguiendo",
                titulo: "Buscador de carreras",
                universidad: "Universidad",
                ver: "Ver"
            },
            carrera: {
                area: "Área",
                detalles: "Detalles",
                guardar: "Guardar",
                informacion_adicional: "Información adicional",
                informacion_solicitada: "Información Solicitada",
                malla_curricular: "Malla curricular",
                modalidad: "Modalidad",
                ponderaciones: "Ponderaciones",
                preferencia: "Preferencia:",
                solicitar_informacion: "Solicitar información a la universidad",
                ubicacion: "Ubicación",
                video: "Video"
            },
            terminos_modal: {
                terminos_y_condiciones: "Términos de la sección carreras"
            }
        },
        universidades: {
            /*  buscador_universidades: {
                buscador_placeholder: "Universidad...",
                buscar: "Buscar",
                not_found: "",
                titulo: "Buscador de universidades",
                universidad: "Universidad"
            }, */
            /*  universidad: {
                carrera: "Carrera",
                carreras: "Carreras",
                carreras_not_found: "No se han encontrado carreras con esos criterios de búsqueda.",
                les_gusta: " usuarios les gusta",
                presentacion: "Presentación:",
                sede: "Sede",
                sedes: "Sedes",
                seguir: "Seguir",
                siguiendo: "Siguiendo",
                universidad: "Universidad",
                universidades: "Universidades",
                ver: "Ver"
            },
            universidad_recomendada: {
                titulo: "Universidad del mes"
            } */
        },
        buscador_carreras_showcasebox: {
            title: "Búsqueda de Carreras"
        }
    },
    orientacion_profesor: {
        grafico_preferencias_areas: {
            titulo: "Preferencias de áreas de interés del curso %{curso}",
            usuarios_tramos: {
                titulo: "Estudiantes por área de interés",
                header_value: "Núm. preferencia",
                header_alumno: "Estudiante(s)",
                not_found: "No se encuentran estudiantes en esta área de interés."
            }
        },
        grafico_preferencias_universidades: {
            titulo: "Preferencias de Universidad del curso %{curso}",
            usuarios_tramos: {
                titulo: "Estudiantes por universidad",
                not_found: "No se encuentran estudiantes en esta universidad."
            }
        }
    },
    otros: {
        fecha_atras_pipe: {
            ayer: "hace 1 día",
            hace_ano: "hace más de %{smart_count} año |||| hace más de %{smart_count} años",
            hace_dia: "hace %{smart_count} día |||| hace %{smart_count} días",
            hace_mes: "hace más de %{smart_count} mes |||| hace más de %{smart_count} meses",
            hace_semana: "hace más de %{smart_count} semana |||| hace más de %{smart_count} semanas",
            hoy: "hoy"
        },
        paginador_html_1: "Se encontraron",
        paginador_html_2: "Mostrando resultados del <b>%{first}</b> al <b>%{last}</b>.",
        reportar: "Reportar"
    },
    participa: {
        dudas: {
            btn_lista: "Ir a lista de dudas",
            categorias: {
                sin_actividad: "Sin actividad.",
                total: "Total de dudas:",
                ultima: "Última duda:"
            },
            listado: {
                btn_nueva: "Nueva duda",
                buscar: "Buscar",
                filtro: {
                    categoria_select: "Cualquier categoria",
                    curso: "Curso:",
                    curso_select: "curso",
                    mias: "Mis dudas",
                    mostrando: "Mostrando por:",
                    todas: "Todas las dudas"
                },
                not_found: "No se encontraron conversaciones dentro de los criterios de búsqueda.",
                orden: {
                    fecha: "Fecha"
                },
                titulo: "Comunidad"
            },
            nueva: {
                btn_anterior: "Anterior",
                btn_cancelar: "Cancelar",
                btn_publicar: "Publicar",
                btn_siguiente: "Siguiente",
                chkbox_publicar: "Listo para publicar",
                paso_categ: {
                    nombre: "Categorización",
                    opcion_default: "-- Seleccione Categoría --",
                    pregunta: "¿Cómo clasificarías tu duda?"
                },
                paso_cont: {
                    detalle: "Escribe tu duda a continuación",
                    nombre: "Contenido",
                    titulo: "Título"
                },
                paso_curs: {
                    curso: "Curso",
                    nombre: "Cursos",
                    opcion_default: "-- Seleccione curso --"
                },
                paso_prev: {
                    nombre: "Previsualización",
                    predeterminado: "El titulo de su duda sigue siendo el predeterminado",
                    sin_detalle: "Aún no has agregado tu duda.",
                    sin_titulo: "Aún no has agregado un título"
                },
                titulo: "Crear tema"
            },
            titulo: "Comunidad",
            todas: "Todas las dudas"
        },
        itemsmenu: {
            nueva_duda: "Pregúntale a la comunidad",
            responder_duda: "Responde dudas"
        },
        rrss_showcase: {
            titulo: "Encúentranos en redes sociales"
        }
    },
    perfil: {
        editar_cuenta: "Editar cuenta",
        curso_indefinido: "No definido",
        fecha_indefinida: "Número de Usuario",
        preferencia_carrera: "Preferencia carrera",
        user_id: "Número de Usuario"
    },
    plan_clase: {
        ver_clase: "Ver clase",
        vamos: "¡Vamos aquí!",
        estara_disponible: "Esta sesión estará visible a partir del:",
        no_disponible: " Esta sesión no esta disponible"
    },
    plan_clase_alumno: {
        title: "Mis planes de clases",
        not_found: "No se encontraron planes."
    },
    plan_personales: {
        diagnostico: {
            generando: "Estamos creando tu evaluación de diagnóstico.\nEsto puede tardar unos minutos."
        }
    },
    popup_cookies: {
        btn_ok: "Entiendo",
        texto: `Utilizamos cookies propias y de terceros para mejorar nuestros servicios. Si continúa con la navegación, consideraremos que acepta este uso.`,
        titulo: "Privacidad y cookies",
        label_cerrar: "Cerrar mensaje de privacidad y cookies"
    },
    practicar: {
        itemsmenu: {
            hacer_ensayo: "Hacer ensayos",
            hacer_ensayo_recomendados: "Hacer ensayo recomendado"
        },
        recomendados: {
            no_encontrados: "No se encontraron evaluaciones recomendadas.",
            titulo: "recomendados"
        },
        showcasebox: {
            entrenador: {
                btn_config: "Configurar",
                link_config: "aquí",
                no_config: "No tienes configurado tu entrenador virtual.<br />Configúralo ",
                preguntas_contestadas: "Preguntas contestadas<br />la semana pasada: ",
                texto_animo: {
                    buen_trabajo: "¡Buen trabajo!",
                    comenzar: "¿Qué esperas para comenzar?",
                    sigue_asi: "¡Sigue así!",
                    ya_casi: "Ya casi terminas. ¡Tú puedes!"
                },
                titulo: "Entrenador virtual"
            },
            evaluaciones_mensuales: {
                comenzar: "Comenzar",
                este_mes: "%{evaluacionTipo} este mes",
                no_disponible: "No disponible",
                por_definir: "Por definir",
                titulo: "Desafíos %{evaluacionTipo} mensuales",
                ultimos_resultados: "Últimos<br />resultados"
            },
            ranking_comunidad: {
                nivel: "Nivel",
                puntos: "Puntos",
                titulo: "Ranking Comunidad"
            },
            ranking_evaluaciones_mensuales: {
                calificacion: "Puntaje",
                not_found: "Aún no has participado en este ranking.",
                posicion: "Tu posición",
                sin_datos: "Sin datos disponibles.",
                titulo: "Ranking %{evaluacionTipo} mensual",
                top_50: "Top 50",
                total: "Total"
            },
            resumen: {
                ensayos: "Ensayos",
                preguntas_contestadas: "Preguntas contestadas",
                preguntas_correctas: "Preguntas correctas",
                semana: "Esta semana has hecho..."
            },
            universidad_recomendada: {
                titulo: "Universidad del mes"
            }
        },
        titulo: "Practicar"
    },
    pregunta_ensayo: {
        correcto: "Correcto",
        formulas: "Fórmulas",
        incorrecto: "Incorrecto",
        instruccion: "Instrucción"
    },
    preguntas_ensayo: {
        completar: "Entregar",
        completar_evaluacion: `Entregar evaluación`,
        descargar_archivo: "Descargar Archivo",
        entregando_evaluacion: "Entregando evaluación",
        entregar_evaluacion: "Entregar evaluación",
        ir_a_home: "Volver al home.",
        modal_error: {
            contenido:
                "Ha ocurrido un problema al entregar la evaluación. ¿Deseas descargar un archivo con las respuestas en caso de que te quede poco tiempo? De esta forma podrás recordar lo que respondiste.",
            titulo: "Advertencia"
        },
        warning_init: "A partir de ahora, te recomendamos marcar también cuando omites una pregunta.",
        warning_limit_time: "La evaluación ha superado el tiempo límite y por lo tanto no es posible entregarla.",
        warning_time: `Estás intentando contestar una evaluación fuera de su período de entrega asignado. No se considerará
                                válida para el cálculo de tus estadísticas, y tampoco será considerado como una entrega válida para
                                tu establecimiento.`,
        warning_unpermitted: "Esta evaluación no ha sido compartida contigo.",
        not_yet: "Todavía no puedes ver esta evaluación.",
        previsualizar: "Previsualizar",
        advertencia: "Advertencia",
        not_yet_profesor_tiempo: "No se puede entregar la evaluación porque tu profesor ha reiniciado el tiempo",
        inicio: "Inicio",
        termino: "Término",
        actual: "Actual",
        siguiente: "Siguiente",
        alerta_evaluacion_tiempo: {
            msj: `Te quedan %{time} para finalizar tu evaluación. ¡Suerte!`
        }
    },
    progreso: {
        estadisticas: {
            graficos_asignatura: {
                correctas: "Correctas",
                descripcion:
                    "Cantidad de respuestas de los estudiantes clasificadas por tipo de resultado: correctas, incorrectas y omitidas.",
                distr_tramos: {
                    descripcion:
                        "Cantidad de evaluaciones realizadas, ordenadas por tramos de %{escala} de acuerdo a los resultados obtenidos en cada una de ellas.",
                    description_with_ranges:
                        "Cantidad de evaluaciones realizadas, ordenadas por tramos de %{escala} de acuerdo a los resultados obtenidos en cada una de ellas. Rangos: %{rangos}.",
                    eje_x: "Tramos de %{escala}",
                    eje_y: "Cantidad de evaluaciones",
                    leyenda: "Cantidad de evaluaciones por tramo",
                    para: "para",
                    titulo: "Distribución de resultados por tramo de "
                },
                incorrectas: "Incorrectas",
                nombre: "Desempeño promedio %{asignatura}",
                omitidas: "Omitidas",
                titulo: "Desempeño promedio por tipo de respuesta para %{asignatura}"
            },
            subtitulo1: "Resumen General",
            subtitulo2: "Gráficos por Asignatura",
            tabla_resumen: {
                correctas: "Correctas",
                incorrectas: "Incorrectas",
                maxima: "Calificación máxima",
                n_ensayos: "Núm. evaluaciones",
                omitidas: "Omitidas",
                promedio: "Calificación promedio",
                ultimo_ptje: "Última calificación"
            },
            titulo: "Estadísticas"
        },
        historial: {
            estadisticas: {
                maximo: "Máximo",
                promedio: "Promedio",
                reciente: "Más reciente",
                resumen: "Resumen de estadísticas",
                tendencia: "Tendencia"
            },
            pendientes: {
                acciones: "Acciones",
                descargar: "Descargar",
                escanear: "Escanear",
                fecha_fin: "Fecha Fin",
                fecha_inicio: "Fecha Inicio",
                forma: "Forma",
                imprimir_1: "Estamos generando el archivo para su impresión.",
                imprimir_2: "Esta operación puede tardar unos segundos, por favor espere.",
                n_preguntas: "N preguntas",
                nombre: "Nombre",
                not_found: "No se encontraron evaluaciones compartidas.",
                realizar: "Realizar",
                titulo: "pendientes",
                tooltip: "Puedes usar una hoja de respuesta para obtener tus resultados online."
            },
            recientes: {
                none: "No hay ensayos recientes",
                titulo: "Ensayos recientes",
                ver: "Ver",
                ver_todos: "Ver todos"
            },
            respuesta: {
                indicaste_dudas: "Indicaste tener dudas en esta pregunta",
                sin_texto_asociado: "Pregunta sin texto asociado",
                preguntas_piloto: "Pregunta de Pilotaje",
                preguntas_piloto_mensaje:
                    "Las preguntas piloto no se consideran al momento de calificar la evaluación.",
                omitida: "respuesta omitida",
                correcta: "respuesta correcta",
                incorrecta: "respuesta incorrecta",
                respuesta_estudiante: "respuesta del estudiante",
                minuto: "minuto",
                segundo: "segundo",
                solucion_pregunta: "Solución a la pregunta ",
                respuesta_pregunta: "Respuesta pregunta",
                cerrar: "Cerrar",
                ver: "Ver"
            },
            respuestas: {
                correctas: "Respuestas correctas",
                desempeno: "Desempeño",
                incorrectas: "Respuestas incorrectas",
                omitidas: "Respuestas omitidas",
                respuesta_correcta: "Respuesta <br/> correcta",
                resultados: "Mis respuestas",
                solucion: {
                    ocultar: "Ocultar",
                    solucion: "solución",
                    ver: "Ver"
                },
                tiempo: "Tiempo aproximado de respuesta:",
                titulo: "Respuestas",
                desempeno_por: "Desempeño por",
                detalle_por_subeje: "Detalle por",
                resultados_disponibles_en: "Los resultados de la evaluación se encontrarán disponibles el",
                preguntas_piloto:
                    "Las preguntas marcadas con (P) corresponden a preguntas piloto y no son consideradas en el cálculo\nde la calificación.",
                no_tuviste_preguntas: "No tuviste preguntas",
                updating: "Actualizando respuesta ..."
            },
            resultados: {
                compartir: "Compartir resultados por email",
                correctas: "Correctas",
                debilidades: "Tus debilidades fueron",
                incorrectas: "Incorrectas",
                omitidas: "Omitidas",
                opciones: "Opciones",
                pilotaje: "Pilotaje",
                realizar: "Realizar",
                recomendaciones: "Recomendaciones",
                tarea: "Resultado tarea",
                titulo: "Resultado",
                ver: "Ver",
                ver_solucion:
                    'Para ver la forma de como llegar a la alternativa correcta de cada pregunta, haz click en "Ver solución".',
                volver: "Volver a realizar"
            },
            terminadas: {
                acciones: "Acciones",
                calificacion: "Puntaje",
                compartido: "Compartido por",
                estadisticas: "Estadisticas",
                fecha: "Fecha",
                fecha_disponible: "Resultados disponibles el",
                forma: "Forma",
                nombre: "Nombre",
                not_found: "No se encontraron %{evaluacionTipo} en esta categoría",
                porcentaje: "Porcentaje",
                reforzamiento: "Reforzamiento",
                resultados: "Publicación resultados",
                titulo: "terminados",
                ver: "Ver",
                ver_reforzamiento: "Ver reforzamiento",
                realizar_reforzamiento: "Realizar reforzamiento"
            },
            titulo: "Evaluaciones realizadas"
        },
        itemsmenu: {
            estadisticas: "Estadísticas",
            evolucion: "Evolución de tu progreso",
            historial: "Historial de ensayos"
        },
        mi_progreso: {
            evolucion: {
                titulo: "Evolución de tus resultados",
                tutorial:
                    "Aquí puedes seguir el progreso de tus resultados. Selecciona las asignaturas y el intervalo de tiempo que te interesa y observa tu avance."
            },
            desempeno_asignaturas: {
                titulo: "Tus respuestas por asignatura",
                tutorial: `Aquí puedes monitorear la cantidad de preguntas que has omitido o contestado de forma correcta o incorrecta en cada asignatura. También puedes hacer click en "Ver %{clasificacionTipo}" para resultados más precisos, y así conocer tus fortalezas y debilidades en cada asignatura.`
            },
            titulo: "Mi Progreso"
        },
        profe: {
            alumno: "Alumno",
            curso: "Curso",
            tipo_instrumento: "Tipo de instrumento",
            desempeno_evolutivo: {
                fecha: "fecha",
                resultado: "Resultado"
            },
            establecimiento: "Establecimiento",
            instrucciones: "Instrucciones",
            instrucciones_desc:
                "A continuación podrás ver la evolución de las calificaciones de cada alumno agrupadas por asignatura. Selecciona un establecimiento, un curso, un alumno y un período de tiempo quieras observar para obtener los resultados.",
            menu_asignatura: {
                sel: "Selección de asignatura"
            },
            seleccione: "Seleccione",
            titulo: "Progreso de los estudiantes"
        },
        showcasebox: {
            grafico: {
                descripcion: "Acá puedes ver la evolución de tus ",
                desenpenio_para: "Desempeño para",
                progreso_por: "Progreso por",
                titulo: "evolutivo"
            },
            titulo: "Resumen de tu progreso"
        },
        titulo: "Tu progreso"
    },
    reportes_modal: {
        cancelar: "Cancelar",
        categoria: "Categoría",
        cerrar: "Cerrar",
        detalle: "Detalle",
        detalle_descripcion: "El detalle es obligatorio y tiene que tener al menos 2 carácteres.",
        detalle_placeholder: "Ingrese aquí información adicional",
        mensaje_reporte: "¡Gracias por tu reporte! Nuestros docentes están trabajando en resolver el problema.",
        razon: "Razón",
        reportar: "Reportar",
        seleccione_categoria: "-- Seleccione Categoría --",
        seleccione_razon: "-- Seleccione Razón --",
        titulo: "Reportar %{tabla}"
    },
    respuestas_ensayo: {
        previsualizar: "Previsualizar"
    },
    simple_modal: {
        cancelar: "Cancelar",
        ok: "Ok"
    },
    tabla_resumen_evaluacion_instancia: {
        correctas: "Correctas",
        estudiante: "Estudiante",
        fecha: "Fecha",
        incorrectas: "Incorrectas",
        intento: "Intento",
        intentos: "Intentos",
        omitidas: "Omitidas",
        puntaje: "Puntaje"
    },
    typeform: {
        button: "Contestar Encuesta"
    },
    topmenu: {
        ayudas: "Centro de ayuda",
        resumen_de_uso: "Resumen de uso",
        biblioteca: "Biblioteca",
        comunidad: "Comunidad",
        corregir: "Corregir",
        estudiar: "Estudiar",
        evaluar: "Evaluar",
        home: "Inicio",
        materiales: "Materiales",
        mis_noticias: "Mis noticias",
        orientacion: "Orientación",
        participa: "Participa",
        planes_de_clase: "Planes de clase",
        planes_de_clase_predefinidos: "Planes de clase predefinidos",
        planes_estudio: "Planes de estudio",
        practicar: "Practicar",
        resultados: "Resultados",
        tu_progreso: "Tu Progreso",
        videos: "Videos y clases"
    },
    tour: {
        btn: {
            next: "",
            ok: "¡Listo!",
            prev: ""
        },
        home: {
            actividades: "Actividades recomendadas"
        },
        topmenu: {
            estudiar: "Material de estudio, guías y clases en vivo",
            orientacion: "Información de carreras y universidades",
            participa: "Pregunta o responde dudas de la comunidad",
            practicar: "Ensayos para practicar",
            tu_progreso: "Historial y estadísticas"
        },
        navbar: {
            ayudas: "Tutoriales de uso para estudiantes"
        }
    },
    reportes: {
        filtrar_datos: "Filtrar datos",
        resumen_uso: {
            titulo: "Resumen de uso",
            evaluacion: "Evaluacion:",
            asignatura: "Asignatura:",
            tipo: "Tipo:",
            asociaciones: "Asociaciones:",
            establecimiento: "Establecimiento:",
            curso: "Curso",
            seleccionar_curso: "-- Seleccione curso --",
            desde: "Desde:",
            hasta: "Hasta",
            info: "*Si se escoge un Tipo, los datos de las evaluaciones estarán filtrados por éste.",
            buscar: "Buscar",
            resultados: {
                resultados: "Resultados",
                resultados_acumulados: "Resultados acumulados de los establecimientos seleccionados",
                resultados_establecimiento: "Resultados por establecimiento",
                docentes: "Docentes",
                ingresos_sistema: "Ingresos al sistema",
                instrumentos_generados: "Instrumentos generados",
                evaluaciones_compartidas: "Evaluaciones compartidas",
                evaluaciones_presenciales: "Evaluaciones presenciales",
                evaluaciones_contestadas: "Evaluaciones contestadas",
                estudiantes: "Estudiantes",
                cuentas_administradoras: "Cuentas administradoras",
                not_found: "No se encontraron resultados",
                administrador_instrumentos_descripcion:
                    "N° de instrumentos (únicos) generados por los administradores en el rango de fechas definidas.",
                actividad_docente: {
                    titulo: "Actividad docente",
                    nombre: "Nombre",
                    ultimo_ingreso: "Último Ingreso",
                    ingresos_30_dias: "Ingresos Últimos 30 Días",
                    total_instrumentos: "Total Instrumentos",
                    total_evaluaciones: "Total Evaluaciones"
                }
            }
        },
        informe_uso: {
            titulo: "Informe de uso"
        },
        resultados_evaluaciones: {
            titulo: "Resultados evaluaciones"
        },
        informe_anual: {
            titulo: "Informe anual"
        },
        avance_planes_adaptive: {
            titulo: "Planes Adaptativos",
            establecimiento: "Establecimiento:",
            seleccionar_establecimiento: "-- Seleccione establecimiento --",
            curso: "Curso:",
            seleccionar_curso: "-- Seleccione curso --",
            asignatura: "Asignatura:",
            seleccionar_asignatura: "-- Seleccione asignatura --",
            tipo: "Tipo de diagnóstico:",
            seleccionar_tipo: "-- Seleccione tipo de diagnóstico --",
            buscar: "Generar"
        }
    },
    titles: {
        core: {
            achievements: {
                actividades: {
                    edit: "Editar actividad",
                    list: "Actividades"
                },
                logros: {
                    edit: "Editar logro",
                    list: "Logros"
                }
            },
            artezonas: {
                artezona_paginas: {
                    edit: "Editar Artezona pagina",
                    list: "Artezona paginas",
                    new: "Crear Artezona pagina",
                    show: "Artezona pagina"
                },
                artezonas: {
                    activas: "Artezonas activas",
                    edit: "Editar artezona",
                    list: "Artezonas",
                    new: "Crear artezona",
                    show: "Artezona"
                },
                artezona_instancias: {
                    list: "Artezona instancias"
                },
                artezona_zonas: {
                    edit: "Editar artezona zona",
                    show: "Artezona zona"
                }
            },
            asignatura_evaluacion_tipos: {
                edit: "Editar asignagura evaluacion tipo",
                list: "Asignatura evaluacion tipos",
                new: "Crear asignatura evaluacion tipo"
            },
            asociaciones: {
                asociacion_contactos: {
                    edit: "Editar asociacion contacto",
                    list: "Asociacion contactos",
                    new: "Crear asociacion contacto",
                    show: "Asociacion contacto"
                },
                asociaciones: {
                    edit: "Editar asociaciones",
                    list: "Asociaciones",
                    new: "Crear asociaciones",
                    show: "Asociacion"
                }
            },
            ayudas: {
                home_ayudas_profesor: "Ayudas",
                list: "Ayudas",
                show: "Ayuda"
            },
            ayudas_admin: {
                categoria_ayudas: {
                    admin: "Categorias ayudas",
                    edit: "Editar categoria ayuda",
                    new: "Crear categoria ayuda"
                },
                list: "Ayudas"
            },
            blog: {
                posts: {
                    edit: "Editar post",
                    list: "Posts",
                    new: "Crear post",
                    show: "Post"
                },
                posts_admin: {
                    list: "Posts",
                    show: "Post"
                }
            },
            carga_nominas: {
                nomina_alumnos: "Nomina alumnos",
                nomina_profesores: "Nomina profesores"
            },
            carreras: {
                buscador_carreras: "Buscador de carreras",
                carrera_sede: "Carrera sede",
                mis_carreras: "Mis carreras"
            },
            cuentas: {
                deshabilitadas: "Cuentas deshabilitadas",
                edit: "Editar cuenta",
                list: "Cuentas",
                new: "Crear cuenta"
            },
            desafios: {
                show: "Desafio",
                desafio_saber11: "Desafio saber 11"
            },
            dudas: {
                dudas: {
                    edit: "Editar duda",
                    list: "Dudas",
                    new: "Crear duda",
                    show: "Duda"
                },
                duda_categorias: "Duda categorias"
            },
            ensayos: {
                encuesta_ensayo_adaptive: "Encuesta",
                ensayo_instancias: "Evaluaciones realizadas",
                ensayos_mensuales: "Evaluaciones mensuales",
                ensayos_recomendados: "Evaluaciones recomendadas",
                evaluacion: "Evaluación",
                evaluaciones_compartidas: "Evaluaciones compartidas",
                evaluaciones_multiples: {
                    compartidas: "Evaluaciones compartidas",
                    generar: "Generar evaluación",
                    realizar: "Realizar evaluación",
                    recomendadas: "Evaluaciones recomendadas",
                    resultados: "Resultados",
                    revisar_simulacros: "Revisar simulacros"
                },
                evaluaciones_corregir: "Corrregir evaluación",
                generar_ensayo: "Generar evaluación",
                generar_ensayo_profesor: "Generar evaluación",
                generar_instrumento_validado: "Generar instrumento validado",
                imprimir_ensayo: "Imprimir evaluación",
                libro_notas: "Libro de notas",
                realizar_ensayo: "Realizar evaluación",
                realizar_ensayo_adaptive: "Realizar evaluación",
                realizar_ensayo_secuencial: "Realizar evaluación",
                realizar_evaluacion_interactiva: "Realizar evaluación",
                resultados_ensayo_adaptive: "Resultados",
                resultados_ensayo_alumno: "Resultados",
                resultados_ensayo: "Resultados",
                revisar_ensayos: "Revisar evaluaciones"
            },
            entrenador: {
                entrenador_usuarios: {
                    edit: "Editar entrenador usuario"
                }
            },
            escala_globales: {
                edit: "Editar escala global",
                list: "Escalas globales",
                new: "Crear escala global",
                show: "Escala global"
            },
            establecimientos: {
                establecimiento_contactos: {
                    edit: "Editar establecimiento contacto",
                    list: "Establecimiento contactos",
                    new: "Crear establecimiento contacto",
                    show: "Establecimiento contacto"
                },
                establecimiento_reportes: {
                    edit: "Editar establecimiento reporte",
                    list: "Establecimiento reportes",
                    new: "Crear establecimiento reporte",
                    show: "Establecimiento reporte"
                },
                establecimientos: {
                    edit: "Editar establecimiento",
                    list: "Establecimientos",
                    new: "Crear establecimiento",
                    show: "Establecimiento"
                },
                administrar: "Administrar establecimientos"
            },
            estadisticas: {
                estadisticas: "Estadísticas",
                estadisticas_alumno: "Estadísticas",
                estadisticas_declarados: "Estadísticas",
                estadisticas_evaluacion: "Estadísticas",
                estadisticas_evaluacion_multiple: "Estadísticas",
                estadisticas_evoluticas_plan: "Estadísticas evolutivas",
                estadisticas_multiples: "Estadisticas",
                estadisticas_progreso: "Estadísticas",
                evaluacion_instancia_estadisticas: "Estadísticas",
                informe_anual: "Informa anual",
                informes: "Informes",
                progreso: "Progreso",
                progreso_alumno: "Progreso alumno",
                resumen_general: "Resumen general",
                resumen_uso: "Resumen de uso",
                resumen_uso_asociaciones: "Resumen de uso",
                tutor_estadisticas: "Estadísticas"
            },
            evaluaciones: {
                admin_resultados: {
                    historial_evaluaciones: "Historial",
                    recalificar_evaluacion: "Recalificar evaluación",
                    admin_resultados_menu: "Menu resultados",
                    eliminar_evaluacion: "Eliminar evaluación",
                    eliminar_resultados_alumno: "Eliminar resultados alumnos"
                },
                estadisticas_evaluacion_recomendada: "Estadísticas",
                evaluaciones_recomendadas: {
                    edit: "Editar evaluación recomendada",
                    list: "Evaluaciones recomendadas",
                    new: "Crear evaluación recomendada"
                },
                crear_evaluacion_multiple: "Crear evaluación",
                evaluacion_admin: "Evaluación",
                evaluaciones_masivas: "Evaluaciones masivas",
                vista_evaluaciones_multiples_compartidas: "Simulacro Saber Pro"
            },
            grupo_usuarios: {
                list: "Cursos"
            },
            grupo_recursos: {
                edit: "Editar grupo recurso",
                list: "Grupos recursos",
                new: "Crear grupo recurso",
                show: "Grupo recurso"
            },
            horario: {
                edit: "Editar horario",
                list: "Horarios",
                new: "Crear horarios",
                show: "Horario"
            },
            instrumentos: {
                pruebas_propias: "Pruebas propias",
                subir_prueba_propia: "Subir prueba propia",
                buscador_instrumento: "Buscador instrumento",
                crear_instrumento_multiple: "Crear instrumento",
                ensayos_mensuales_admin: "Evaluaciones mensuales",
                ensayos_mes: "Evaluaciones mes",
                instrumento_desde_lista_preguntas: "Crear instrumento desde lista pregunntas",
                instrumento_desde_lote: "Crear instrumento desde lote",
                instrumento_multiple: "Instrumento múltiple",
                instrumento: "Instrumento",
                instrumentos_predefinidos: {
                    edit: "Editar instrumento predefinido",
                    list: "Instrumentos predefinidos",
                    new: "Crear instrumento predefinido",
                    show: "Instrumento predefinido"
                }
            },
            landing: {
                demo: "Demo",
                home: "Home",
                instrucciones_eliminacion_datos: "Instrucciones para eliminar datos",
                landing: "Inicio",
                terminos_y_condiciones: "Términos y condiciones"
            },
            materiales: {
                biblioteca: "Biblioteca",
                list: "Materiales",
                material_apps: "Material",
                material_udd: "Material",
                material: "Material",
                materiales_saber: "Materiales",
                materiales_simce: "Materiales",
                materiales_thumbnails: "Thumbnails materiales",
                materiales_v1: "Materiales",
                recursos_interactivos: "Recursos interactivos"
            },
            niveles: {
                edit: "Editar nivel",
                list: "Niveles",
                new: "Crear nivel",
                show: "Nivel"
            },
            noticias: {
                noticias: {
                    edit: "Editar noticia",
                    list: "Noticias",
                    new: "Crear noticia",
                    show: "Noticia"
                },
                noticia_admin: "Noticia",
                noticias_profesor: "Noticias"
            },
            notificaciones: {
                list: "Notificaciones"
            },
            orientacion: {
                orientacion_profesor: "Orientación"
            },
            plan_estudios: {
                plan_estudio_sesion: "Sesión de plan de estudio",
                plan_estudio_sesiones: "Sesiones de plan de estudio",
                show: "Plan de estudio",
                list: "Planes de estudio"
            },
            plan_personales: {
                avance_tarea: "Avance tarea",
                detalle_plan_alumno: "Detalle plan alumno",
                diagnostico: "Diagnóstico",
                estadisticas_planes_personales: "Estadísticas",
                generar_plan_personal: "Generar plan",
                historial_planes_compartidos: "Historial planes compartidos",
                historial_planes_personales: "Historial planes",
                show: "Plan",
                plan_personal_profesor: "Plan",
                plan_personal_sesion: "Sesión",
                list: "Planes",
                planes_personales_asignaturas: "Planes",
                planes_personales_reforzamientos: "Reforzamientos",
                realizar_plan_compartido: "Realizar plan"
            },
            plan_clases: {
                clase_plan_clase: "Clase",
                mis_plan_clases: "Mis planes de clase",
                plan_clase_alumno: "Plan",
                plan_clases: {
                    edit: "Editar plan de clase",
                    list: "Planes de clase",
                    new: "Crear plan de clase",
                    show: "Plan de clase"
                },
                plan_clases_alumnos: "Planes",
                plan_clases_de_alumnos: "Planes"
            },
            planificaciones: {
                estadisticas_clase: "Estadísticas",
                estadisticas_planificaciones: "Estadísticas",
                lista_planificaciones: "Planificaciones",
                plan_personal_contenidos: {
                    edit: "Editar contenido",
                    new: "Crear contenido"
                },
                planificaciones: {
                    compartida: "Planificación compartida",
                    edit: "Editar planificación",
                    estadisticas: "Estadísticas",
                    new: "Crear planificación",
                    predefinida: "Planificación predefinida",
                    show: "Planificacion"
                },
                profesores_planificaciones_predefinidas: "Planificaciones predefinidas",
                profesores_revisar_planificaciones: "Revisar planificaciones",
                sesiones: {
                    edit: "Editar sesión",
                    new: "Crear sesión"
                }
            },
            push_notificaciones: {
                push_notificaciones: {
                    edit: "Editar notificacion push",
                    list: "Push Notificaciones",
                    new: "Crear notificacion push",
                    show: "Push Notificacion"
                }
            },
            streaming: {
                material_streaming: "Material streaming",
                streaming_admin: "Streaming",
                streaming_alumnos: "Streaming alumnos",
                streaming: "Streaming"
            },
            typeform_widgets: {
                edit: "Editar typeform widget",
                list: "Typeform widgets",
                new: "Crear typeform widget",
                show: "Typeform widget",
                view: "Typeform widget"
            },
            universidad: {
                buscador_universidad: "Buscador universidad",
                show: "Universidad"
            },
            usuarios: {
                passwords: {
                    forgot: "Recuperar contraseña",
                    reset: "Ingresa tu nueva contraseña"
                },
                password: "¿Olvidaste tu contraseña?",
                recuperar_cuenta: "Recuperar cuenta",
                registro: {
                    completar_registro_social: "Completar registro",
                    completar_registro: "Completar registro",
                    incomplete_register: "Completar registro",
                    opciones_registro_nacimiento: "Registro: nacimiento",
                    register: "Registro",
                    registro: "Registro"
                },
                request_confirmation: "Confirmación",
                usuario_registrado: "Usuario registrado",
                register_confirmation: "Confirmación",
                tutor_confirmar: "Confirmación",
                usuario_perfil_admin: "Perfil",
                usuario_perfil_alumno: "Perfil",
                usuario_perfil: "Perfil",
                usuarios: {
                    edit: "Editar usuario",
                    show: "Usuario"
                },
                completar_datos: "Completar Datos",
                actualizar_datos: "Actualizar Datos"
            }
        }
    },
    /* terminos & condiciones */
    tyc: {
        seccion0: {
            parrafo0: `
                                                                Los Términos y Condiciones generales que a continuación se establecen regulan el uso de la comunidad de
                                                                <span class="entity-name">%{companyName}</span>
                                                                , creada a través del sitio
                                                                <span class="entity-name">%{companySite}</span>
                                                                (en adelante
                                                                <span class="entity-name">"%{companyName}"</span>
                                                                ), el cual es puesto a disposición de los usuarios de internet.
                                                                <br />
                                                                <br />
                                                                La sola utilización del portal le atribuye la condición de usuario (en adelante, el "usuario") de la comunidad
                                                                de
                                                                <span class="entity-name">%{companyName}</span>
                                                                y expresa la aceptación plena y sin reservas de todos y cada uno de sus Términos y Condiciones que se encuentren
                                                                publicados al acceder al portal
                                                                <span class="entity-name">%{companyName}</span>
                                                                . En consecuencia, el usuario deberá leer atentamente los Términos y Condiciones en cada una de las ocasiones en
                                                                que se proponga utilizar o acceder al portal
                                                                <span class="entity-name">%{companyName}</span>
                                                                .
                                                                <br />
                                                                <br />
                                                                Los Términos y Condiciones podrán ser modificados, total o parcialmente, por
                                                                <span class="entity-name">%{companyName}</span>
                                                                , y dichos cambios tendrán vigencia a partir del momento mismo en que sean publicados o insertados en el sitio
                                                                de la comunidad o desde que sean notificados al usuario por cualquier medio. A partir de lo expuesto, se sugiere
                                                                que los visite periódicamente.
                                                                <br />
                                                                <br />
                                                                Las violaciones a los Términos y Condiciones otorgan a
                                                                <span class="entity-name">%{companyName}</span>
                                                                el derecho de suspender o terminar la prestación del servicio al usuario que las haya realizado, por acción u
                                                                omisión.
                                                `
        },
        seccion1: {
            parrafo11: `
                                                                Los contenidos que periódicamente se publican en la comunidad de
                                                                <span class="entity-name">%{companyName}</span>
                                                                tienen por finalidad brindar al usuario todo tipo de información relacionada con el desarrollo de su educación
                                                                escolar y, fundamentalmente, de la Enseñanza Media, según las regulaciones del <strong>Ministerio de Educación de Chile</strong>.
                                                                Por consiguiente, <span class="entity-name">%{companyName}</span>
                                                                contribuye a preparar de manera satisfactoria la Prueba de Acceso a la Educación Superior (PAES) en el mismo país, mediante
                                                                la generación permanente de pruebas de ensayo, informes estadísticos, material educativo, artículos, noticias,
                                                                planificaciones de clases, publicaciones de interés y otros contenidos relacionados con la comunidad escolar en
                                                                todos sus niveles.
                                                `,
            parrafo12: `
                                                                En ningún caso la información contenida en la comunidad
                                                                <span class="entity-name">%{companyName}</span>
                                                                tiene la obligación de satisfacer la totalidad de las necesidades formativas o educativas del usuario. En
                                                                algunas oportunidades, la información contenida en la comunidad
                                                                <span class="entity-name">%{companyName}</span>
                                                                simplemente está destinada a complementar otros materiales a los que el usuario pueda acceder por su cuenta,
                                                                acerca de un asunto o materia de su interés.
                                                `,
            parrafo13: `
                                                                La prestación del servicio del sitio por parte de la comunidad
                                                                <span class="entity-name">%{companyName}</span>
                                                                es de carácter libre y gratuito para los usuarios. No obstante, su utilización sólo puede hacerse como usuario
                                                                registrado.
                                                `,
            subtitulo11: "1.1.",
            subtitulo12: "1.2.",
            subtitulo13: "1.3.",
            titulo1: "1° El servicio y la información"
        },
        seccion2: {
            parrafo21: `Para ser un usuario de la comunidad es obligatorio completar el formulario de registro ("Registro de usuarios")
                                                con su información personal de manera precisa y veraz.`,
            parrafo22: `
                                                                Salvo en los campos en que se indique lo contrario, las respuestas a las preguntas del “Registro de usuarios”
                                                                son voluntarias, sin que la falta de respuesta implique una merma en la calidad o cantidad de los servicios
                                                                correspondientes, a menos que se indique otra cosa. El usuario registrado se compromete a notificar a la
                                                                comunidad de
                                                                <span class="entity-name">%{companyName}</span>
                                                                en forma inmediata cualquier uso no autorizado de su clave.
                                                `,
            parrafo23: `
                                                                <span class="entity-name">%{companyName}</span>
                                                                se reserva el derecho de rechazar cualquier solicitud de registro o de cancelar un registro previamente
                                                                aceptado.
                                                `,
            parrafo24: `
                                                                <span class="entity-name">%{companyName}</span>
                                                                se reserva el derecho de enviar a la dirección de correo indicada por el usuario en el formulario de registro,
                                                                información que considere relevante para sus usuarios. No obstante lo anterior,
                                                                <span class="entity-name">%{companyName}</span>
                                                                adhiere a las iniciativas contra el correo no solicitado
                                                                <i>(spamming)</i>
                                                                , en el entendido de que la información enviada a sus usuarios no contiene publicidad comercial e incorpora
                                                                mecanismos sencillos y explícitos para dejar de recibir dichos correos.
                                                `,
            subtitulo21: "2.1.",
            subtitulo22: "2.2.",
            subtitulo23: "2.3.",
            subtitulo24: "2.4.",
            titulo2: "2° Registro"
        },
        seccion3: {
            parrafo31: `
                                                                Debido a que en la actualidad los medios técnicos disponibles no permiten prevenirse totalmente contra la acción
                                                                de terceras personas en los sitios publicados en Internet,
                                                                <span class="entity-name">%{companyName}</span>
                                                                de ninguna manera garantiza la exactitud y/o veracidad de la totalidad o parcialidad de la información contenida
                                                                en el sitio, ni su actualización, ni que dicha información no haya sido alterada o modificada en algún grado,
                                                                luego de haber sido incluida en el sitio, ni cualquier otro aspecto o característica de la información brindada
                                                                en el sitio o a través de los links eventualmente incluidos en el mismo.
                                                `,
            parrafo32: `
                                                                Respecto de aquellos programas, materiales o información que se hayan puesto a disposición del usuario para que
                                                                éste pueda descargarlos en su equipo, sistema o cualquier medio o soporte de memoria de su propiedad o cuya
                                                                tenencia detente ("materiales de
                                                                <i>download</i>
                                                                "),
                                                                <span class="entity-name">%{companyName}</span>
                                                                informa que inicialmente dichos programas fueron revisados, razonablemente, a efectos de considerarlos libres de
                                                                virus, u otros medios tecnológicos de tipo destructivo.
                                                `,
            parrafo33: `
                                                                Por lo mismo,
                                                                <span class="entity-name">%{companyName}</span>
                                                                no será responsable por ningún daño que se derive de cualquier alteración que se haya efectuado a los materiales
                                                                de download provistos directamente por la comunidad
                                                                <span class="entity-name">%{companyName}</span>
                                                `,
            subtitulo31: "3.1.",
            subtitulo32: "3.2.",
            subtitulo33: "3.3.",
            titulo3: "3° Responsabilidad por la información contenida"
        },
        seccion4: {
            parrafo41: `
                                                                La recolección y tratamiento automatizado de los datos personales tiene como finalidad mantener una fluida
                                                                relación entre el usuario y la comunidad
                                                                <span class="entity-name">%{companyName}</span>
                                                                , favoreciendo la gestión, administración, prestación, ampliación y mejora de los servicios en los que el
                                                                usuario decida suscribirse, así como la utilización de dichos servicios por parte de los usuarios, el diseño de
                                                                nuevas ofertas relacionadas con dichos servicios, el envío de actualizaciones de los servicios, el envío, por
                                                                medios tradicionales y electrónicos, de información técnica, operativa y comercial acerca de productos y
                                                                servicios ofrecidos por
                                                                <span class="entity-name">%{companyName}</span>
                                                                a través de su portal.
                                                `,
            parrafo42: `
                                                                El servicio de
                                                                <span class="entity-name">%{companyName}</span>
                                                                automáticamente recaba cierta información general como, por ejemplo, la cantidad y frecuencia de los visitantes
                                                                a la comunidad
                                                                <span class="entity-name">%{companyName}</span>
                                                                y a sus distintas áreas. Dicha información se utiliza únicamente con un propósito estadístico. Estos datos
                                                                ayudan a determinar cuántos de los usuarios de la comunidad
                                                                <span class="entity-name">%{companyName}</span>
                                                                acceden a un área determinada y qué partes de la comunidad utilizan, a fin de poder mejorarlo y asegurar que sea
                                                                lo más atractivo posible.
                                                `,
            parrafo43: `
                                                                Si decides participar en las encuestas realizadas en el sitio,
                                                                <span class="entity-name">%{companyName}</span>
                                                                podrá utilizar tu RUT para acceder a estos datos, los que serán utilizados de manera anónima, en forma agregada,
                                                                confidencial y sólo para objetivos de investigación de
                                                                <span class="entity-name">%{companyName}</span>
                                                                o de cualquier entidad a la cual consideremos apropiado cederle esta información. Asimismo, las respuestas de la
                                                                encuesta se podrán unir a datos recolectados por el
                                                                <strong>Ministerio de Educación</strong>
                                                                . La decisión de participar en las encuestas es completamente voluntaria y no existen riesgos asociados a ella.
                                                                Si decides no contestar la encuesta no habrá ninguna consecuencia negativa.
                                                `,
            parrafo44: `Las campañas generadas por terceros, puede facultar el uso de datos personales de quienes participen de éstas.`,
            subtitulo41: "4.1.",
            subtitulo42: "4.2.",
            subtitulo43: "4.3.",
            subtitulo44: "4.4.",
            titulo4: "4° Privacidad de la Información"
        },
        seccion5: {
            parrafo51: `
                                                                El usuario autoriza a
                                                                <span class="entity-name">%{companyName}</span>
                                                                para utilizar toda la información oficial de la Prueba de Acceso a la Educación Superior (PAES) (puntajes en las distintas pruebas, datos personales, etc.)
                                                                con el fin de llevar a cabo investigaciones. Esta información no se divulgará públicamente, pero los informes resultantes de dichas investigaciones podrán
                                                                ser difundidos y publicados.
                                                `,
            subtitulo51: "5.1.",
            titulo5: "5° Datos oficiales PAES"
        },
        seccion6: {
            parrafo61: `
                                                                <span class="entity-name">%{companyName}</span>
                                                                no transmitirá, comercializará, ni publicará la información de los usuarios obtenida a través de
                                                                <i>Facebook</i>
                                                                . Esta información es utilizada de manera interna para la autentificación del sitio mediante la cuenta de
                                                                <i>Facebook</i>
                                                                . Para lo anterior solo se guardará el
                                                                <i>ID</i>
                                                                del usuario de
                                                                <i>Facebook</i>
                                                                , de manera de poder asociarlo a la cuenta de
                                                                <span class="entity-name">%{companyName}</span>
                                                                permitiendo la autentificación del sitio.
                                                                <br />
                                                                <br />
                                                                Además se utilizará, en caso de que el usuario lo requiera, el
                                                                <i>ID</i>
                                                                para publicar en el muro del usuario el resultado de un ensayo realizado.
                                                `,
            subtitulo61: "6.1.",
            titulo6: "6° Privacidad en Facebook"
        },
        seccion7: {
            lista71: {
                item1: `Usar los contenidos de forma diligente, correcta y lícita.`,
                item2: `
                                                                                No reproducir, copiar, distribuir, transformar o modificar, poner a disposición, arrendar o comunicar al
                                                                                público los contenidos a menos que se cuente con la autorización expresa del titular de los correspondientes
                                                                                derechos.
                                                                `,
                item3: `
                                                                                No suprimir, eludir, o manipular el copyright y demás datos identificatorios de los derechos de la
                                                                                <span class="entity-name">%{companyName}</span>
                                                                                o de sus titulares incorporados a los contenidos, así como los dispositivos técnicos de protección, los
                                                                                identificadores digitales o cualesquiera sean los mecanismos de información que pudieran contener los
                                                                                contenidos o la gestión de derechos.
                                                                `,
                item4: `
                                                                                No emplear los contenidos y, en particular, la información de cualquier otra clase obtenida a través de la
                                                                                comunidad
                                                                                <span class="entity-name">%{companyName}</span>
                                                                                o de los servicios, para emitir publicidad de cualquier tipo bajo cualquier formato, en Chile o en el
                                                                                extranjero.
                                                                `
            },
            parrafo71: `
                                                                Se entenderá por "contenidos" todas las informaciones, mensajes, gráficos, dibujos, diseños, logotipos, nombres,
                                                                marcas, archivos de sonido y/o imagen, fotografías, grabaciones, software y, en general, cualquier clase de
                                                                material accesible a través de la comunidad
                                                                <span class="entity-name">%{companyName}</span>
                                                                (en adelante referido como los “contenidos”).
                                                                <br />
                                                                <br />
                                                                El usuario se obliga a:
                                                `,
            parrafo72: `
                                                                <span class="entity-name">%{companyName}</span>
                                                                no será responsable por el uso indebido que hagan los usuarios del contenido de la comunidad
                                                                <span class="entity-name">%{companyName}.</span>
                                                `,
            parrafo73: `
                                                                El usuario acepta que la publicidad de terceros que aparezca en la pantalla es parte inseparable de la comunidad
                                                                <span class="entity-name">%{companyName}.</span>
                                                `,
            parrafo74: `
                                                                <span class="entity-name">%{companyName}</span>
                                                                no garantiza la veracidad de dicha publicidad y no será responsable por la correspondencia ni por los contratos
                                                                que el usuario celebre con terceros.
                                                `,
            subtitulo71: "7.1.",
            subtitulo72: "7.2.",
            subtitulo73: "7.3.",
            subtitulo74: "7.4.",
            titulo7: "7° Propiedad Intelectual"
        },
        test: "Este es texto de prueba para %{companyName}",
        title: "Términos y Condiciones"
    },
    usuario: {
        actividades: {
            title: "Puntuación de Actividades",
            not_found: "No hay actividades para ganar experiencia en este momento"
        },
        logros: {
            lista: {
                titulo: "Lista de logros"
            },
            resumen: {
                guia_niveles: "Guía de niveles",
                prox_nivel: "Próximo nivel:",
                puntos: "Puntos",
                titulo: "Resumen de logros"
            },
            ultimos: {
                titulo: "Últimos logros"
            },
            logro: {
                experiencia: "Puntos de Experiencia"
            }
        },
        tabs: {
            favoritos: "Favoritos",
            logros: "Logros",
            perfil: "Perfil"
        }
    },
    usuario_edit_password: {
        cambiar_contrasena: "Cambiar Contraseña",
        confirmar_contrasena: "Confirmar Nueva Contraseña",
        contrasena_actual: "Contraseña Actual",
        minimo_caracteres: "La contraseña debe tener mínimo %{passwordMinLength} caracteres",
        no_coinciden: "La confirmacion de la contraseña no coincide con la contraseña",
        no_vacia: "La contraseña actual no puede estar vacia",
        no_valida: "La contraseña actual es invalida",
        nueva_contrasena: "Nueva Contraseña",
        vulnerable: "Esta contraseña es vulnerable. Prueba colocar otra."
    },
    usuarios_perfil_edit: {
        titulo: "Editar mis datos",
        aceptar: "Aceptar",
        apellido_materno: "Apellido materno",
        apellido_paterno: "Apellido paterno",
        cambiar_contrasena: "Cambiar contraseña",
        cambiar_imagen: "Cambiar imagen",
        cancelar: "Cancelar",
        correo_electronico: "Correo electrónico",
        deshacer: "Deshacer",
        editar: "Editar",
        fecha_actualizacion: "Fecha de actualización",
        fecha_creacion: "Fecha de creación",
        foto_perfil: "Foto de perfil",
        cambiar_foto_perfil: "Cambiar foto de perfil",
        guardar: "Guardar",
        informacion_personal: "Información personal",
        nombre: "Nombre",
        opciones: "Opciones",
        no_definido: "No definido",
        fecha_nacimiento: "Fecha de nacimiento",
        curso: "Curso",
        asignatura: "Asignatura",
        asignatura_definida: "Asignatura definida",
        eliminar_cuenta: "Eliminar Cuenta",
        tutorias: "Tutorias",
        seleccione: "Seleccione",
        ayuda_asignatura: "Los menús se abrirán por defecto en la asignatura seleccionada"
    },
    validations: {
        messages: {
            breachedPassword: "Esta contraseña es vulnerable. Prueba con otra.",
            breachedPasswordOnLogin:
                "Para aumentar el resguardo de tus datos aumentamos los requisitos de seguridad de las contraseñas, por lo que debes ingresar una nueva. Para esto debes dirigirte a ¿Olvidaste tu contraseña? o enviar un correo a contacto@ogr.cl",
            codigoRegistro: "Tu código de acceso no existe o ya ha sido utilizado",
            duplicateCC: "El C.C. ya fue tomado. Si está correcto, por favor ponte en contacto con nosotros.",
            duplicateCI:
                "La Cédula de Identidad ya está registrada. Si tu CI está correcto ponte en contacto con nosotros.",
            duplicateEmail: "La dirección de email ya está registrada.",
            duplicateNumeroUnico:
                "El número único ya está registrado. Si tu código está correcto ponte en contacto con nosotros.",
            duplicateRut: "El Rut ya está registrado. Si tu Rut está correcto ponte en contacto con nosotros.",
            duplicateTi: "T.I. ya fue tomado. Si está correcto, por favor ponte en contacto con nosotros.",
            equalEmails: "Los correos no coinciden",
            equalPasswords: "Las contraseñas no coinciden",
            extensionNotSuported: "El archivo solo puede ser de tipo .pdf o .pptx.",
            extensionNotSuported2: "El archivo solo puede ser de tipo .xls o .xlsx.",
            extensionNotSuported3: "El archivo solo puede ser de tipo .csv",
            fileNotPresent: "Debe adjuntar un archivo.",
            imageExtensionNotSuported: "El archivo solo puede ser de tipo .png o .jpg.",
            invalidCC: "El número de la Cedula de Ciudadania no es válido.",
            invalidPreguntaId: "No existe pregunta con este ID.",
            invalidString: "debe ingresar caracteres válidos sin números",
            invalidTelephoneCL: "El número de teléfono es incorrecto. Ingrese al menos los 9 dígitos correspondientes.",
            invalidTelephoneCO: "El número de teléfono es incorrecto. Debe incluir código de ciudad.",
            invalidTelephoneEC: "El número de teléfono es incorrecto. Ingrese al menos los 8 dígitos correspondientes.",
            invalidTelephoneTec: "El número de teléfono es incorrecto.Debe incluir código de ciudad.",
            invalidTelephoneUss:
                "El número de teléfono es incorrecto. Ingrese al menos los 8 dígitos correspondientes.",
            invalidTI: "El número de la Tarjeta de Identidad no es válido.",
            max: "Necesita ser menor o igual que %{max}",
            maxlength: "Necesita tener a lo más %{requiredLength} carácteres",
            min: "Necesita ser mayor o igual que %{min}",
            minlength: "Necesita tener al menos %{requiredLength} carácteres",
            multipleOrRequired: "Se requiere que al menos el campo {fields} este presente.",
            notExistingEmail: "La dirección de email no existe",
            notValidURI: "URI no es válida.",
            required: "El campo %{required} es obligatorio",
            validateCI: "La Cédula de Identidad es inválida.",
            validateEmail: "La dirección de email es inválida",
            validateNem: "El NEM no es válido",
            validateRut: "El Rut es inválido. Rut debe ser ingresado con guión y sin puntos.",
            validateNumeroUnico: "CURP inválido. Debe tener 18 caracteres.",
            validatePasaporte: `Ingrese un número de documento correcto.`,
            validatePreferenciaUniversidadUnica: "La preferencia universidad debe ser única.",
            invalidStrArrayNumbers: "Debe ingresar números separados por comas"
        }
    },
    ver: "Ver",
    widget_menu_reguntas: {
        preguntas: "preguntas"
    },
    widget_prueba_propias: {
        cerrar: "cerrar",
        constestar: "Contestar",
        editar: "Editar",
        respuest_a_pregunta: "Respuesta a pregunta",
        respuestas: "Respuestas"
    },
    widget_tiempo_ensayo: {
        tiempo_terminado: "¡Se acabó el tiempo!"
    }
}
