<div
    class="sesion-container"
    [class.pointLeft]="!pointRight"
    [class.pointRight]="pointRight"
    [class.pendiente]="!isDone"
    [class.active]="isDone || previewMode"
>
    <div class="sesion-grid">
        <div class="info">
            <h2 class="titulo-sesion">{{ "estudiar.plan_personales.plan_personal.continuar_estudiando" | t }}</h2>
            <ng-container *ngIf="isDone">
                <p class="resultados">{{ "estudiar.plan_personales.plan_personal.repaso_sesion" | t }}</p>
                <label class="clasificacion" *ngFor="let c of clasificaciones" title="{{ c.clasificacion }}">
                    {{ c.clasificacion }}
                </label>
            </ng-container>
            <p class="resultados-pendiente" *ngIf="!isDone">
                {{ "estudiar.plan_personales.plan_personal.pendiente" | t }}
            </p>
            <p class="inactive-text" *ngIf="!isDone">
                {{ "estudiar.plan_personales.plan_personal.mensaje_continuar" | t }}
            </p>
            <div class="bottom-info">
                <div class="bottom-info-row">
                    <div class="bottom-info-cell"></div>
                    <div class="bottom-info-cell">
                        <button
                            type="button"
                            class="start-btn"
                            [class.inactive]="!enableStartBtn"
                            *ngIf="!isDone"
                            [disabled]="!enableStartBtn"
                            (click)="comenzarDiagnostico()"
                        >
                            <span>{{ "estudiar.plan_personales.plan_personal.comenzar" | t }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="tipo">
            <ogr-icon name="resultados" class="icono"></ogr-icon>
            <p>{{ "estudiar.plan_personales.plan_personal.evaluacion_diagnostico" | t }}</p>
        </div>
    </div>
</div>
