<generic-modal [id]="modalId" [modalLarge]="true" (onHide)="closeModalMaterial()" (closeCrossCallback)="closeCross()">
    <modal-titulo>{{ "biblioteca.vista_previa" | t }}</modal-titulo>
    <modal-contenido>
        <loading-layout #loadingLayout>
            <div *ngIf="material">
                <h4>{{ material.material }}</h4>
                <cui-button-link
                    [href]="material.url"
                    targetValue="_blank"
                    class="btns-style"
                    *ngIf="
                        material.material_tipo_nombre === 'Archivo' || material.material_tipo_nombre === 'Instrumento'
                    "
                    (click)="onDownload()"
                >
                    <span class="glyphicon glyphicon-cloud-download"></span>
                    &nbsp;{{ "biblioteca.descargar" | t }}
                </cui-button-link>
                <!-- <cui-button-link (click)="goToMaterial(material)" class=" btn-primary btn_style btn-sm btns-style">
                    <fa name="search"></fa>
                    &nbsp;Abrir
                </cui-button-link> -->
                <material-preview
                    #previewMaterial
                    [clasificacionTipo]="clasificacionTipo"
                    [material]="material"
                    [disableSaveTiempoMaterialUsuario]="disableSaveTiempoMaterialUsuario"
                ></material-preview>
            </div>
        </loading-layout>
    </modal-contenido>
</generic-modal>
