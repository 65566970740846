<div
    class="enunciado"
    [class.make-inline]="isInline"
    [class.make-first-child-inline]="makeFirstChildInline"
    [class.make-last-child-inline]="makeLastChildInline"
    [innerHTML]="
        taxativo
            | taxativoAlternativas
            | clearLatex
            | taxativoNumeroTexto: grupoPreguntaTextoService.sentenceToLine[grupoPreguntaId]
            | katex
            | trustedhtml
    "
></div>
