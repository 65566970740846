import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { mergeMap, map, tap, switchMap, withLatestFrom, filter } from "rxjs/operators"
import { from, forkJoin, of, config } from "rxjs"
import { Observable } from "rxjs"
import { Action, select, Store } from "@ngrx/store"
import { Preguntas, GeneradorInstrumentoInstrucciones, Instruccion, Pregunta } from "@puntaje/nebulosa/api-services"
import {
    EvaluacionesActionTypes,
    GetItems,
    Item,
    SetItems,
    ResetDudaPreguntas,
    SetGrupoPreguntaItems,
    SetInstruccionFija,
    SetRespuestasActual,
    SetTiemposActual,
    SetSeeingIndex,
    SaveRespuestasActual,
    SetLoadingItems,
    ToggleDudaPreguntaActual
} from "../actions/evaluaciones.actions"
import {
    selectEvaluacionesActual,
    selectEvaluacionesEvaluacionTiempoActual,
    selectEvaluacionesItems,
    selectEvaluacionesRespuestasActual,
    selectEvaluacionesSeeingIndex,
    selectEvaluacionesTiemposActual,
    State
} from "../reducers"
import { AuthService } from "@puntaje/shared/core"

function getLocalStorageKey(evaluacionId, usuarioId, evaluacionTiempoId) {
    return "evaluacion_" + evaluacionId + "_usuario_" + usuarioId + "_et_" + evaluacionTiempoId
}

@Injectable()
export class EvaluacionesEffects {
    items$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(EvaluacionesActionTypes.GetItems),
            map((action: GetItems) => action.payload.evaluacion),
            switchMap(evaluacion => {
                let preguntaIds = []
                if (evaluacion.instrumento.adaptive) {
                    // Las evaluaciones adaptive solo deben mostrar las ultimas cuatro preguntas
                    preguntaIds = evaluacion.instrumento.instrumento_preguntas
                        .map(ip => ip.pregunta_id)
                        .slice(-evaluacion.instrumento.numero_preguntas_testlet)
                } else {
                    preguntaIds = evaluacion.instrumento.instrumento_preguntas.map(ip => ip.pregunta_id)
                }
                const params = {
                    with_grupo_pregunta: 1,
                    pregunta: {
                        id: preguntaIds
                    },
                    generador_instrumento_instruccion: {
                        generador_instrumento_id: [evaluacion.instrumento.generador_instrumento_id, null]
                    },
                    reduced: 1,
                    without_alternativa_correcta: 1,
                    include: "generador_instrumento_instrucciones"
                }

                const giInstruccionParams = {
                    generador_instrumento_instruccion: {
                        generador_instrumento_id: evaluacion.instrumento.generador_instrumento_id
                    },
                    sort_by: "generador_instrumento_instrucciones.orden",
                    order: "asc",
                    include: "[instruccion,clasificacion]"
                }

                return forkJoin(
                    of(evaluacion),
                    from(this.generadorInstrumentoInstruccionesService.where(giInstruccionParams)).pipe(
                        tap(generadorInstrumentoInstrucciones => {
                            generadorInstrumentoInstrucciones.forEach(gii => {
                                const i = gii.instruccion
                                i.instruccion = i.instruccion.replace(
                                    "{minutos}",
                                    evaluacion.instrumento.tiempo.toString()
                                )
                                i.instruccion = i.instruccion.replace(
                                    "{ejercicios}",
                                    evaluacion.instrumento.numero_preguntas.toString()
                                )
                            })
                        })
                    ),
                    this.preguntasService.where(params)
                )
            }),
            switchMap(([evaluacion, generadorInstrumentoInstrucciones, preguntas]) => {
                const instrucciones = generadorInstrumentoInstrucciones.map(gii => gii.instruccion)

                let items = []

                let idGrupoPregunta = 0

                preguntas.forEach((pregunta, i) => {
                    if (pregunta.contestables[0].contestable_tipo) {
                        const contestableTipo = pregunta.contestables[0].contestable_tipo.contestable_tipo
                        const gii = generadorInstrumentoInstrucciones
                            .filter(gii => gii.clasificacion)
                            .find(gii => {
                                return gii.clasificacion.clasificacion == contestableTipo
                            })
                        if (!pregunta.generador_instrumento_instrucciones.includes(gii)) {
                            pregunta.generador_instrumento_instrucciones.push(gii)
                            pregunta.generador_instrumento_instrucciones.sort((a, b) => (a.orden > b.orden ? 1 : -1))
                        }
                    }

                    pregunta.generador_instrumento_instrucciones.forEach(gii => {
                        const instruccionIndex = instrucciones.findIndex(ins => {
                            return ins.id == gii?.instruccion_id
                        })

                        if (instruccionIndex != -1 && instrucciones[instruccionIndex].instruccion) {
                            gii.instruccion = instrucciones[instruccionIndex]
                            items.push({
                                tipo: "instruccion",
                                item: instrucciones[instruccionIndex]
                            })

                            instrucciones.splice(instruccionIndex, 1)
                        }
                    })

                    items.push({
                        numero: i,
                        tipo: "pregunta",
                        duda: false,
                        item: pregunta,
                        iniGrupoPregunta: false,
                        endGrupoPregunta: false
                    })

                    const index = items.length - 1

                    if (pregunta.grupo_pregunta_id) {
                        // Pertenece a un grupo pregunta
                        if (pregunta.grupo_pregunta_id != idGrupoPregunta) {
                            // Inicia grupo pregunta
                            idGrupoPregunta = pregunta.grupo_pregunta_id
                            items[index].iniGrupoPregunta = true

                            if (i - 1 >= 0 && items[index - 1].item.grupo_pregunta_id) {
                                // Marca el anterior como fin de grupo pregunta si pertenece a uno
                                items[index - 1].endGrupoPregunta = true
                            }
                        } else if (i + 1 == preguntas.length) {
                            // Fin de grupo pregunta si es la ultima pregunta
                            items[index].endGrupoPregunta = true
                        }
                    } else if (i - 1 >= 0 && items[index - 1].item.grupo_pregunta_id) {
                        // Marca el anterior como fin de grupo pregunta
                        items[index - 1].endGrupoPregunta = true
                    }
                })

                let instruccionFija: Instruccion

                const instruccionFijaIndex = instrucciones.findIndex(i => {
                    return generadorInstrumentoInstrucciones.find(gii => gii.instruccion_id == i.id && gii.fija)
                })

                if (instruccionFijaIndex != -1) {
                    instruccionFija = instrucciones[instruccionFijaIndex]
                    instrucciones.splice(instruccionFijaIndex, 1)
                }

                const instruccionesIniciales: Item[] = instrucciones
                    .filter(i => {
                        const clasificacion_id = generadorInstrumentoInstrucciones.find(
                            gii => gii.instruccion_id == i.id
                        ).clasificacion_id

                        return (clasificacion_id == null || clasificacion_id == undefined) && i.instruccion
                    })
                    .map((i, index) => ({
                        tipo: "instruccion" as "instruccion",
                        item: i,
                        first: index == 0
                    }))
                items = instruccionesIniciales.concat(items)
                items.push({
                    tipo: "confirmacion",
                    item: {},
                    last: true
                })

                items[0].first = true

                return [
                    new SetItems({ items }),
                    new SetGrupoPreguntaItems({ items }),
                    new SetInstruccionFija({ instruccionFija }),
                    new ResetDudaPreguntas(),
                    new SetLoadingItems({ loadingItems: false })
                ]
            })
        )
    )

    respuestas$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(EvaluacionesActionTypes.FetchRespuestasActual),
            withLatestFrom(
                this.store$.pipe(select(selectEvaluacionesEvaluacionTiempoActual)),
                this.store$.pipe(select(selectEvaluacionesActual)),
                this.store$.pipe(
                    select(selectEvaluacionesItems),
                    filter(items => items.length != 0),
                    map(items => items.filter(item => item.tipo == "pregunta").map(item => item.item as Pregunta))
                )
            ),
            switchMap(([_, evaluacionTiempo, evaluacion, preguntas]) => {
                const usuario = this.authService.getUserData()

                const localStorageKey = getLocalStorageKey(
                    evaluacionTiempo.evaluacion_id,
                    usuario.id,
                    evaluacionTiempo.id
                )
                const evalLocal = localStorage.getItem(localStorageKey)

                const actions: Action[] = []

                if (evalLocal) {
                    const parsedEval = JSON.parse(evalLocal)
                    const alternativaIds = parsedEval["alternativas"]
                    const tiempos = parsedEval["tiempos"]

                    let indexActual = parsedEval["lastPreguntaIndex"]
                    if (indexActual == -1) {
                        indexActual++
                    }

                    actions.push(new SetRespuestasActual({ respuestasActual: alternativaIds }))
                    actions.push(new SetTiemposActual({ tiemposActual: tiempos }))
                    actions.push(new SetSeeingIndex(indexActual))
                } else {
                    const nPreguntas = evaluacion.instrumento.instrumento_preguntas.length
                    const alternativaIds = preguntas.map(pregunta => {
                        return pregunta.contestables.map(_ => "")
                    })
                    const tiempos = new Array(nPreguntas).fill(0.0)

                    actions.push(new SetRespuestasActual({ respuestasActual: alternativaIds }))
                    actions.push(new SetTiemposActual({ tiemposActual: tiempos }))
                    actions.push(new SaveRespuestasActual(-1))
                }

                return actions
            })
        )
    )

    saveRespuestas$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(EvaluacionesActionTypes.SaveRespuestasActual),
            withLatestFrom(
                this.store$.pipe(select(selectEvaluacionesEvaluacionTiempoActual)),
                this.store$.pipe(select(selectEvaluacionesSeeingIndex)),
                this.store$.pipe(select(selectEvaluacionesRespuestasActual)),
                this.store$.pipe(select(selectEvaluacionesTiemposActual))
            ),
            switchMap(([action, evaluacionTiempo, seeingIndex, respuestasActual, tiemposActual]) => {
                const usuario = this.authService.getUserData()

                const lastPreguntaIndex = (<SaveRespuestasActual>action).lastPreguntaIndex || seeingIndex

                const localStorageKey = getLocalStorageKey(
                    evaluacionTiempo.evaluacion_id,
                    usuario.id,
                    evaluacionTiempo.id
                )
                const jsonString = JSON.stringify({
                    alternativas: respuestasActual,
                    tiempos: tiemposActual,
                    lastPreguntaIndex
                })

                localStorage.setItem(localStorageKey, jsonString)

                return []
            })
        )
    )

    cleanRespuestas$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(EvaluacionesActionTypes.CleanRespuestasActual),
            withLatestFrom(this.store$.pipe(select(selectEvaluacionesEvaluacionTiempoActual))),
            switchMap(([_, evaluacionTiempo]) => {
                const usuario = this.authService.getUserData()

                const localStorageKey = getLocalStorageKey(
                    evaluacionTiempo.evaluacion_id,
                    usuario.id,
                    evaluacionTiempo.id
                )

                localStorage.removeItem(localStorageKey)

                return []
            })
        )
    )

    constructor(
        private actions$: Actions,
        private store$: Store<State>,
        private preguntasService: Preguntas,
        private generadorInstrumentoInstruccionesService: GeneradorInstrumentoInstrucciones,
        private authService: AuthService
    ) {}
}
