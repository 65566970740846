<div class="row">
    <div class="col-md-6">
        <div class="alternativa-contestada">
            <ng-container *ngIf="!alternativaContestada; else contestada">
                <div class="caption"></div>
                <div class="omitida">Pregunta Omitida.</div>
            </ng-container>
            <ng-template #contestada>
                <div *ngIf="alternativaContestada && !alternativaContestada.correcta" class="caption">
                    <b>Respuesta Contestada:</b>
                </div>
                <div *ngIf="alternativaContestada && !alternativaContestada.correcta" class="inputs has-error">
                    <ng-container *ngFor="let input of inputsContestada; let i = index">
                        <input
                            [attr.aria-label]="inputsContestada[i]"
                            class="form-control"
                            type="text"
                            [disabled]="true"
                            [(ngModel)]="inputsContestada[i]"
                        />
                    </ng-container>
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="col-md-6">
        <div class="alternativa-correcta">
            <div class="caption">
                <b>Respuesta Correcta:</b>
            </div>
            <div class="inputs has-success">
                <ng-container *ngFor="let input of inputsCorrecta; let i = index">
                    <input
                        [attr.aria-label]="inputsCorrecta[i]"
                        class="form-control"
                        type="text"
                        [disabled]="true"
                        [(ngModel)]="inputsCorrecta[i]"
                    />
                </ng-container>
                <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
            </div>
        </div>
    </div>
</div>
