import { singularize } from "./inflector"

/**
 * Clase que ayuda con el manejo del español
 */
export class SpanishHelper {
    private static participioRules = {
        regular: [
            {
                regexp: /(er|ir)$/i,
                f: "ida",
                m: "ido"
            },
            {
                regexp: /(ar)$/,
                f: "ada",
                m: "ado"
            }
        ],
        irregular: {
            escribir: { f: "escrita", m: "escrito" }
        }
    }

    private static generoRules = {
        regular: [
            {
                regexp: /[a|triz]$/i,
                genero: "f"
            },
            {
                regexp: /(e|o|((?![aeiou])\w))$/i,
                genero: "m"
            }
        ],
        irregular: {
            gente: "f",
            mujer: "f"
        }
    }

    /**
     * "f" => femenino
     * "m" => masculino
     */
    private static genderizeRules = {
        regular: {
            f: [
                { regexp: /(os)$/, replace: "as" },
                { regexp: /(o|e)$/, replace: "a" },
                { regexp: /(n)$/, replace: "na" }
            ],
            m: [
                { regexp: /(as)$/, replace: "os" },
                { regexp: /(a)$/, replace: "o" },
                { regexp: /(na)$/, replace: "n" }
            ]
        },
        irregular: [
            {
                f: "la",
                m: "el"
            },
            {
                f: "online",
                m: "online"
            }
        ]
    }

    public static participioOf(base: string, verbo: string) {
        let genero = this.guessGenero(base)
        let result = this.participioRules.irregular[verbo]
        if (result) {
            return result[genero]
        } else {
            for (let rule of this.participioRules.regular) {
                if (rule.regexp.test(verbo)) {
                    return verbo.replace(rule.regexp, rule[genero])
                }
            }
        }
        return verbo
    }

    /**
     * Asigna un genero a una palabra
     * @param {string} word - palabra a transformar
     * @param {"f" | "m" } genero - genero a usar
     * @returns {string} retorna word con el genero cambiado
     *
     * @todo refactorizar para soportar generos neutros (esto, lo, etc)
     */
    public static genderize(word: string, genero: "f" | "m") {
        if (genero) {
            let foundWord = this.genderizeRules.irregular.find(x => x[this.contraryGender(genero)] === word)
            if (foundWord) {
                return foundWord[genero]
            } else {
                for (let rule of this.genderizeRules.regular[genero]) {
                    if (rule.regexp.test(word)) {
                        return word.replace(rule.regexp, rule.replace)
                    }
                }
            }
        }
        return word
    }

    public static genderizeByWord(word: string, wordGenero: string) {
        let genero = this.guessGenero(wordGenero.singularize())
        return this.genderize(word, genero)
    }

    /**
     * retorna el genero contrario
     * @param genero {"f" | "m"} - genero query
     * @returns {"f" | "m"} el genero contrario al input
     */
    private static contraryGender(genero: "f" | "m") {
        if (genero === "m") return "f"
        return "m"
    }

    /**
     * Si el target contiene varias palabras separadas por espacio,
     * se adivina el genero basado en la primera palabra (siguiendo
     * las reglas del idioma español)
     * @param target string a determinar el genero
     * @requires {Array<"f"|"m">} generos de la palabra
     */
    public static guessGenero(target: string) {
        if (target == "" || !target) {
            return undefined
        }
        let word = singularize(target, "es")
        let result = this.generoRules.irregular[word]
        if (result) {
            return result
        } else {
            for (let rule of this.generoRules.regular) {
                if (rule.regexp.test(word)) {
                    return rule.genero
                }
            }
        }
        return "m"
    }
}
