<generic-modal [buttonClicked]="openModal" [id]="'usuario-email-modal'" #genericModal>
    <modal-titulo>Aviso</modal-titulo>
    <modal-contenido class="modal-contenido">
        <div>
            <p>
                Para hacer efectivo el cambio del correo electrónico debes revisar tu bandeja de entrada y confirmar la operación con el
                link que te enviamos para ello.
            </p>
        </div>
    </modal-contenido>
</generic-modal>
