import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    ChangeDetectorRef,
    SimpleChanges,
    OnChanges
} from "@angular/core"
import { Alternativa } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "contestable-spr",
    templateUrl: "./contestable-spr.component.html",
    styleUrls: ["./contestable-spr.component.scss"]
})
export class ContestableSprComponent implements OnInit, OnChanges {
    respuesta: string
    hasErrors = false
    errors = []
    hasWarnings = false
    warnings = []
    touched: boolean

    datalist1 = [null, ".", null].concat("123456789".split(""))
    datalist2 = "/.0123456789".split("")
    datalist3 = [null].concat(".0123456789".split(""))

    @Input() alternativaId: any
    @Input() preguntaId: number
    @Output() alternativaIdChange: EventEmitter<any> = new EventEmitter<any>()
    @Output() respuestaChange: EventEmitter<any> = new EventEmitter<any>()

    doNotTriggerChanges: boolean = false

    inputs = [
        {
            datalist: this.datalist1,
            referenceName: "input1",
            regEx: /^([1-9]|\.){1}$/,
            value: "",
            ariaLabel: "Caja de respuesta 1",
            valid: true
        },
        {
            datalist: this.datalist2,
            referenceName: "input2",
            regEx: /^(\d|\.|\/){1}$/,
            value: "",
            ariaLabel: "Caja de respuesta 2",
            valid: true
        },
        {
            datalist: this.datalist2,
            referenceName: "input3",
            regEx: /^(\d|\.|\/){1}$/,
            value: "",
            ariaLabel: "Caja de respuesta 3",
            valid: true
        },
        {
            datalist: this.datalist3,
            referenceName: "input4",
            regEx: /^(\d|\.){1}$/,
            value: "",
            ariaLabel: "Caja de respuesta 4",
            valid: true
        }
    ]

    constructor(protected cdr: ChangeDetectorRef) {}

    ngOnInit() {}

    setRespuestaValues() {
        this.doNotTriggerChanges = false

        if (this.alternativaId !== "" && this.alternativaId["alternativa_texto"]) {
            const characters = this.alternativaId["alternativa_texto"].split("")
            for (let i = 0; i < 4; i++) {
                this.inputs[i].value = characters[i] || ""
            }

            this.cdr.detectChanges()
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["alternativaId"] && !this.doNotTriggerChanges) {
            this.setRespuestaValues()
        }
    }

    validateInput(input: any) {
        const value = input.value || ""
        if (value == ".") {
            const already =
                this.inputs
                    .map(i => i.value)
                    .join("")
                    .match(/\./g).length > 1

            if (already) input.value = ""
        }
        if (value == "/") {
            const already =
                this.inputs
                    .map(i => i.value)
                    .join("")
                    .match(/\//g).length > 1

            if (already) input.value = ""
        }
        input.valid = input.regEx.test(value) || input.value == ""
        this.validateCompleteExpression()
    }

    initValidation() {
        this.hasErrors = false
        this.hasWarnings = false
        this.errors = []
        this.warnings = []
    }

    validateCompleteExpression() {
        this.initValidation()
        const inputsHaveErrors = this.inputs.map(i => i.valid).some(v => !v)
        const inputsAreEmpty = this.inputs.every(i => i.value == "")

        if (inputsHaveErrors || inputsAreEmpty) {
            this.hasErrors = true
            this.hasWarnings = true
            this.errors.push("Existen campos vacios o caracteres no permitidos.")
            this.warnings.push("Una respuesta con caracteres inválidos o vacios sera considerada como omitida.")
        }
        this.respuestaChange.emit(this.alternativaInfo())
    }

    alternativaInfo() {
        let alternativaId
        if (this.hasErrors) {
            alternativaId = 0
        } else {
            alternativaId = {
                alternativa_texto: this.inputs.map(x => x.value).join("")
            }
        }
        this.doNotTriggerChanges = true

        this.alternativaIdChange.emit(alternativaId)
        this.cdr.detectChanges()

        return alternativaId
    }
}
