import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { AsociacionMonitor } from "./asociacion_monitores.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class AsociacionMonitores extends PuntajeBaseService<AsociacionMonitor> {
    tableName = "asociacion_monitores"
    singularTableName = "asociacion_monitor"
    modelClass = AsociacionMonitor

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
