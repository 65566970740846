<ll-titulo>{{ titulo }}</ll-titulo>
<clase-plan-clase
    [planUrl]="planUrl"
    [isLibroFrom]="isLibroFrom"
    [planPersonalId]="planEstudioId"
    [planPersonalSesionId]="planEstudioSesionId"
    [grupoUsuarioId]="grupoUsuarioId"
    [enableReporte]="enableReporte"
    [enableDudas]="enableDudas"
    [showVerLibro]="showVerLibro"
    [esAdmin]="esAdmin"
></clase-plan-clase>
