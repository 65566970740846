import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Clasificacion } from "./clasificaciones.model"

export class ClasificacionClasificacion extends BaseModel {
    public static className: string = "ClasificacionClasificacion"
    public clasificacion_id: number
    public clasificacion_ref_id: number
    public created_at: Date
    public updated_at: Date

    @SaveType(() => Clasificacion) public clasificacion: Clasificacion
    @SaveType(() => Clasificacion) public clasificacion_ref: Clasificacion

    constructor() {
        super()
    }

    public toString() {
        return this.clasificacion_id.toString()
    }
}
