import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Injectable()
export class PlanPersonalesBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    constructor() {}

    search(planPersonal, generadorInstrumento, csvName = null) {
        let params: any = {}

        let plan_personal = Object.assign({}, planPersonal)
        let generador_instrumento = Object.assign({}, generadorInstrumento)

        Object.keys(plan_personal).forEach(k => {
            if (typeof plan_personal[k] !== "boolean" && (plan_personal[k] == "" || plan_personal[k] == undefined)) {
                delete plan_personal[k]
            }
        })

        Object.keys(generador_instrumento).forEach(k => {
            if (generador_instrumento[k] == "") delete generador_instrumento[k]
        })

        if (plan_personal.id && plan_personal.id.indexOf(",") != -1) {
            plan_personal.id = plan_personal.id.split(/\s*,\s*/)
        }

        if (plan_personal.plan_personal) {
            plan_personal.like = { plan_personal: plan_personal.plan_personal }
            delete plan_personal.plan_personal
        }

        if (generador_instrumento.generador_instrumento) {
            generador_instrumento.like = { generador_instrumento: generador_instrumento.generador_instrumento }
            delete generador_instrumento.generador_instrumento
        }

        params = {
            plan_personal,
            generador_instrumento
        }

        if (plan_personal.activo != undefined) {
            params.activo = plan_personal.activo
            delete params.plan_personal.activo
        }

        if (csvName) {
            params.to_csv = csvName
        }

        this.searchParams.next(params)
    }
}
