import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild,
    Output,
    Input,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef,
    ApplicationRef
} from "@angular/core"
import { Router } from "@angular/router"
import { Ayuda, Ayudas, TipoMaterial } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { PaginatorComponent } from "@puntaje/shared/core"
import { CategoriaAyuda, CategoriaAyudas } from "@puntaje/puntaje/api-services"

@Component({
    selector: "ayudas-with-thumbnails",
    templateUrl: "ayudas_with_thumbnails.component.html",
    styleUrls: ["ayudas_with_thumbnails.component.scss"]
})
export class AyudasWithThumbnailsComponent implements OnInit {
    @Input() tracking: boolean = false
    params = Ayuda.show_params
    paginatorContext: any
    tableButtonsArray: any
    reloadPaginator: boolean = true
    ayudas: Ayuda[]
    ayudaQuery: string
    categoriasAyudas: CategoriaAyuda[]
    ayudasCategoriaFiltradas: any = {}
    @Input() sortByDate: string
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent

    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    @Input() tipoMaterial: number
    @Input() enableSearch: boolean = true
    @Input() targetApp: string | string[] = undefined

    constructor(
        protected router: Router,
        protected ayudasService: Ayudas,
        protected categoriaAyudasService: CategoriaAyudas,
        public cdr: ChangeDetectorRef,
        public appRef: ApplicationRef
    ) {}

    async ngOnInit() {
        // await this.ayudasService.asignaturasPromise;
        // this.asignaturaId = config.asignaturas[0].id;
        this.setData(1, 12)
    }

    setData(page: number, per: number) {
        this.loadingLayout.loading = true
        this.cdr.detectChanges()

        const categoriaQuery = {
            render_options: {
                include: {
                    ayuda_categoria_ayudas: null
                }
            },
            sort: [{ field: "orden", criteria: "asc" }]
        }

        return this.categoriaAyudasService.where(categoriaQuery).then(response => {
            this.categoriasAyudas = response
            const ayudaIds = response.map(c => c.ayuda_categoria_ayudas.map(ca => ca.ayuda_id)).flat()

            let query: any = {
                ayuda: {
                    id: ayudaIds,
                    visible: 1
                },
                plataforma_id: config.plataforma.id
            }

            if (this.targetApp) {
                query["ayuda"]["target_app"] = this.targetApp
            }
            if (this.tipoMaterial) {
                query["ayuda"]["material_tipo_id"] = this.tipoMaterial
            }
            if (this.sortByDate) {
                query["sort_by"] = "created_at"
                query["order"] = this.sortByDate
            }
            if (this.ayudaQuery) {
                query["ayuda"]["like"] = { ayuda: this.ayudaQuery }
            }

            return this.ayudasService.where(query).then((response: Ayuda[], total: number) => {
                this.ayudas = null
                this.ayudas = response
                this.loadingLayout.loading = false
                this.ayudasCategoriaFiltradas = this.categoriasAyudas.sort((ca1, ca2) => ca1.orden - ca2.orden).reduce((acc, categoria) => {
                    acc[categoria.id] = categoria.ayuda_categoria_ayudas
                        .map(ca => {
                            return this.ayudas.find(a => a.id === ca.ayuda_id)
                        })
                        .filter(a => !!a)
                    return acc
                }, {})
                return total
            })
        })
    }

    updateSearch(currentSearch: string) {
        this.ayudaQuery = currentSearch
        this.buscar()
    }

    buscar() {
        if (this.paginator) this.paginator.changePage(1)
        else this.setData(1, 12)
    }

    setAyudas(ayudas: Ayuda[]) {
        this.ayudas = ayudas
    }

    bindData(binding) {
        this.paginatorContext = binding
        return this.setData.bind(binding)
    }
}
