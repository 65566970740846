import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { TiempoMaterialUsuario } from "./tiempo_material_usuarios.model"

@Injectable()
export class TiempoMaterialUsuarios extends PuntajeBaseService<TiempoMaterialUsuario> {
    tableName = "tiempo_material_usuarios"
    singularTableName = "tiempo_material_usuario"
    modelClass = TiempoMaterialUsuario

    constructor(
        http: HttpClient,
        auth: PuntajeAuthService,
        router: Router,
        errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
