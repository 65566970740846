// TODO: revisar la utilidad de tener una cantidad de decimales, la roundingFunction y la necesidad de hacer e+2 y e-2
export function percentage(
    value: any,
    exp?: any,
    roundingFunction: (x: number) => number = Math.round,
    suffix: string = "%"
) {
    if (value != null && value != undefined) {
        if (exp !== null && exp !== undefined) {
            return +parseFloat(value).toFixed(exp) + suffix
        }

        return +(roundingFunction(parseFloat(value + "e+2")) + "e-2") + suffix
    }

    return null
}
