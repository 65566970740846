import { Component, OnInit, Input, Output, ViewChild, ElementRef } from "@angular/core"
import {
    UsuarioBaneados,
    UsuarioBaneado,
    RolUsuario,
    DudaUsuarioInfo,
    Usuario,
    Usuarios
} from "@puntaje/puntaje/api-services"
import { Router } from "@angular/router"
import { SessionService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "duda-avatar",
    templateUrl: "duda_avatar.component.html",
    styleUrls: ["duda_avatar.component.scss"] /*,
    host: {
        '(document:click)': 'onClickOutside($event)',
    }*/
})
export class DudaAvatarComponent implements OnInit {
    @Input() usuario: Usuario
    isDocente = false
    isAdmin = false
    @Input() isPro = false
    @Input() rolesUsuario: RolUsuario[] = []
    @Input() avatar: string
    @Input() usuarioValores: DudaUsuarioInfo
    @Input() enableMiniMode = false
    showUserInfo = false
    marcoPro = ""
    @ViewChild("userInfo") userInfo: ElementRef
    isCursorInside = false
    timeOutId: any
    fetchAvailable = true
    doingFetch = false
    usuarioBaneado: UsuarioBaneado
    @Input() enableAchievementsInfo = false

    constructor(
        protected router: Router,
        protected usuarioBaneadosService: UsuarioBaneados,
        protected usuariosService: Usuarios,
        protected sessionService: SessionService,
        private el: ElementRef
    ) {}

    ngOnInit() {
        if (this.usuario) {
            this.avatar = this.usuario.avatar
            this.rolesUsuario = this.usuario.rol_usuarios
            this.isPro = this.usuario.is_pro
        }
        this.enableAchievementsInfo = config.plataforma?.enableAchievements
        this.setData()
        this.checkAdmin()
    }

    setData() {
        this.setBadges()
        this.marcoPro = this.isPro ? "marco01" : ""
    }

    setBadges() {
        const rol_docente = this.rolesUsuario.find(r => {
            return r.rol.rol == "Docente"
        })
        if (rol_docente) {
            this.isDocente = true
        }
    }

    checkAdmin() {
        const some_admin = this.sessionService.getRoles().find(r => {
            return r == "SuperAdministrador" || r == "Administrador" || r == "Monitor"
        })
        if (some_admin) {
            this.isAdmin = true
            //this.fetchBannedInfo();
        }
    }

    enterUserInfo() {
        this.isCursorInside = true
        this.timeOutId = setTimeout(() => {
            this.showUserInfo = this.isCursorInside
            if (this.showUserInfo) this.fetchMissingData()
        }, 500)
    }

    leaveUserInfo() {
        this.isCursorInside = false
        this.showUserInfo = false
        clearTimeout(this.timeOutId)
        this.timeOutId = null
    }

    toggleUserInfo() {
        this.showUserInfo = !this.showUserInfo
        if (this.showUserInfo) this.fetchMissingData()
        if (this.isAdmin && !this.usuarioBaneado) {
            this.fetchBannedInfo()
        }
    }

    fetchMissingData() {
        if (this.usuario && !this.usuarioValores && this.fetchAvailable) {
            this.doingFetch = true
            const params = {
                usuario: {
                    email: this.usuario.email
                },
                include: "[rol_usuarios:[rol]]",
                methods: "[dudas_valores]"
            }
            this.usuariosService.where(params).then((response: Usuario[]) => {
                if (response && response.length > 0) {
                    const user = response.find(u => u.id == this.usuario.id)
                    this.rolesUsuario = user.rol_usuarios
                    this.usuarioValores = user.dudas_valores
                    this.isPro = user.is_pro
                    this.setData()
                }
                this.doingFetch = false
            })
        }
        this.fetchAvailable = false
    }

    fetchBannedInfo() {
        if (!this.usuarioBaneado) {
            const now = new Date()
            const params = {
                usuario_baneado: {
                    usuario_id: this.usuario.id,
                    lte: {
                        fecha_inicio: now.toISOString()
                    },
                    gte: {
                        fecha_termino: now.toISOString()
                    }
                }
            }
            this.usuarioBaneadosService.where(params).then((usuarioBaneado: UsuarioBaneado[]) => {
                if (usuarioBaneado && usuarioBaneado.length > 0) {
                    this.usuarioBaneado = usuarioBaneado[0]
                }
            })
        }
    }

    banUser() {
        const now = new Date()
        const usuarioBaneado = new UsuarioBaneado()
        usuarioBaneado.usuario_id = this.usuario.id
        usuarioBaneado.fecha_inicio = new Date()
        usuarioBaneado.fecha_termino = new Date()
        usuarioBaneado.fecha_termino.setDate(now.getDate() + 7)

        this.usuarioBaneadosService.save(usuarioBaneado).then((usuarioBaneado: UsuarioBaneado) => {
            this.usuarioBaneado = usuarioBaneado
        })
    }
}
