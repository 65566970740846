import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { CarrerasAuthService } from "./carreras_auth.service"
import { CarrerasBaseService } from "./carreras_base.service"
import { InfoAdicional } from "./info_adicionales.model"

@Injectable()
export class InfoAdicionales extends CarrerasBaseService<InfoAdicional> {
    tableName = "info_adicionales"
    singularTableName = "info_adicional"
    modelClass = InfoAdicional

    constructor(
        protected http: HttpClient,
        protected auth: CarrerasAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public getValueOfInfoAdicional(params: any) {
        return this.all().concatExtra("getValueOf").get(params)
    }
}
