import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./buscador-carreras.component.html",
    styleUrls: ["./buscador-carreras.component.scss"]
})
export class BuscadorCarrerasComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.carreras.buscador_carreras")
    }
}
