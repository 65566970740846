import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { MyDatePickerModule } from "mydatepicker"
import { CUIContainersModule, CUITextsModule, CUIInputsModule } from "@puntaje/shared/cui"

import { ApiModule } from "@puntaje/puntaje/api-services"
import { UtilModule, PaginatorModule, FormModule, ModelViewModule } from "@puntaje/shared/core"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"

import { GrupoUsuariosAsociadosComponent } from "./grupo_usuarios_asociados.component"
import { GrupoUsuariosProfesorComponent } from "./grupo_usuarios_profesor.component"
import { InfoGrupoUsuariosProfesorComponent } from "./info_grupo_usuarios_profesor.component"
import { GrupoUsuariosProfesorShowcaseComponent } from "./grupo_usuarios_profesor_showcase.component"
import { AsociarUsuarioComponent } from "./asociar-usuario/asociar-usuario.component"
import { AsociarUsuarioMasivoComponent } from "./asociar-usuario/asociar-usuario-masivo.component"
import { GrupoUsuariosNotificacionesShowcaseboxComponent } from "./grupo-usuarios-notificaciones-showcasebox/grupo-usuarios-notificaciones-showcasebox.component"
import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { GrupoUsuarioFormComponent } from "./grupo-usuario-form.component"
import { BuscadorUsuarioComponent } from "./buscador-usuario/buscador-usuario.component"
import { BuscadorUsuarioService } from "./buscador-usuario/buscador_usuario.service"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        PaginatorModule,
        RouterModule,
        Angular2FontawesomeModule,
        MyDatePickerModule,
        SharedModule,
        CUIContainersModule,
        CUITextsModule,
        CUIInputsModule,
        NotificacionesModule
    ],
    declarations: [
        GrupoUsuariosAsociadosComponent,
        GrupoUsuariosProfesorComponent,
        InfoGrupoUsuariosProfesorComponent,
        GrupoUsuariosProfesorShowcaseComponent,
        AsociarUsuarioComponent,
        AsociarUsuarioMasivoComponent,
        GrupoUsuariosNotificacionesShowcaseboxComponent,
        GrupoUsuarioFormComponent,
        BuscadorUsuarioComponent,
    ],
    exports: [
        GrupoUsuariosAsociadosComponent,
        GrupoUsuariosProfesorComponent,
        InfoGrupoUsuariosProfesorComponent,
        GrupoUsuariosProfesorShowcaseComponent,
        AsociarUsuarioComponent,
        AsociarUsuarioMasivoComponent,
        GrupoUsuariosNotificacionesShowcaseboxComponent
    ]
})
export class GrupoUsuariosModule {
    static forRoot(): ModuleWithProviders<GrupoUsuariosModule> {
        return {
            ngModule: GrupoUsuariosModule,
            providers: [BuscadorUsuarioService]
        }
    }
}
