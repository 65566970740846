<ul class="nav nav-tabs">
    <li [class.active]="datos.curs.activo" *ngIf="showCursos">
        <a href="#tab{{ datos.curs.numero }}" role="tab" data-toggle="tab" (click)="selectTab('curs')">
            <span class="tab-number">{{ datos.curs.numero }}</span>
            <span class="tab-text">{{ datos.curs.nombre }}</span>
        </a>
    </li>
    <li [class.active]="datos.cont.activo">
        <a href="#tab{{ datos.cont.numero }}" role="tab" data-toggle="tab" (click)="selectTab('cont')">
            <span class="tab-number">{{ datos.cont.numero }}</span>
            <span class="tab-text">{{ datos.cont.nombre }}</span>
        </a>
    </li>
    <li [class.active]="datos.categ.activo">
        <a href="#tab{{ datos.categ.numero }}" role="tab" data-toggle="tab" (click)="selectTab('categ')">
            <span class="tab-number">{{ datos.categ.numero }}</span>
            <span class="tab-text">{{ datos.categ.nombre }}</span>
        </a>
    </li>
    <li [class.active]="datos.prev.activo">
        <a href="#tab{{ datos.prev.numero }}" role="tab" data-toggle="tab" (click)="selectTab('prev')">
            <span class="tab-number">{{ datos.prev.numero }}</span>
            <span class="tab-text">{{ datos.prev.nombre }}</span>
        </a>
    </li>
    <cui-button class="pull-right" (click)="cancel()">{{ "participa.dudas.nueva.btn_cancelar" | t }}</cui-button>
</ul>

<form *ngIf="showForm" novalidate [formGroup]="form">
    <div class="tab-content">
        <div
            id="tab{{ datos.curs.numero }}"
            [class.active]="datos.curs.activo"
            class="tab-pane clearfix"
            *ngIf="showCursos"
        >
            <label>{{ "participa.dudas.nueva.paso_curs.curso" | t }}&nbsp;</label>
            <select [(ngModel)]="duda.grupo_usuario_id" class="form-control" formControlName="grupo_usuario_id">
                <option [ngValue]="undefined">{{ "dudas.duda_form.seleccione" | t }}</option>
                <option *ngFor="let option of grupoUsuarios" [ngValue]="option.id">
                    {{ option.nombre }}
                </option>
            </select>

            <div class="bottom-buttons">
                <cui-button-link
                    class="pull-right"
                    roleValue="tab"
                    dataToggle="tab"
                    (click)="selectTab(datos.curs.next)"
                >
                    {{ "participa.dudas.nueva.btn_siguiente" | t }}
                </cui-button-link>
            </div>
        </div>

        <div id="tab{{ datos.cont.numero }}" [class.active]="datos.cont.activo" class="tab-pane clearfix">
            <form-input [form]="form" [params]="params.duda" [(value)]="duda.duda" key="duda"></form-input>
            <form-input [form]="form" [params]="params.detalle" [(value)]="duda.detalle" key="detalle"></form-input>

            <div class="push-bottom-buttons"></div>
            <div class="bottom-buttons">
                <cui-button-link
                    class="pull-left"
                    roleValue="tab"
                    dataToggle="tab"
                    (click)="selectTab(datos.cont.previous)"
                    *ngIf="showCursos"
                >
                    {{ "participa.dudas.nueva.btn_anterior" | t }}
                </cui-button-link>
                <cui-button-link
                    class="pull-right"
                    roleValue="tab"
                    dataToggle="tab"
                    (click)="selectTab(datos.cont.next)"
                >
                    {{ "participa.dudas.nueva.btn_siguiente" | t }}
                </cui-button-link>
            </div>
        </div>

        <div id="tab{{ datos.categ.numero }}" class="tab-pane clearfix" [class.active]="datos.categ.activo">
            <p>{{ "participa.dudas.nueva.paso_categ.pregunta" | t }}</p>
            <div class="row">
                <div class="col-md-4" *ngIf="dudaCategoriasFirst && dudaCategoriasFirst.length > 0">
                    <div class="form-group">
                        <select
                            class="form-control"
                            [compareWith]="compareDudaCategoria"
                            [(ngModel)]="dudaCategoriaFirst"
                            (ngModelChange)="getDudaCategoriasSecond()"
                            [ngModelOptions]="{ standalone: true }"
                        >
                            <option [ngValue]="undefined">
                                {{ "participa.dudas.nueva.paso_categ.opcion_default" | t }}
                            </option>
                            <option *ngFor="let dudaCategoria of dudaCategoriasFirst" [ngValue]="dudaCategoria">
                                {{ dudaCategoria.duda_categoria }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-4" *ngIf="dudaCategoriasSecond && dudaCategoriasSecond.length > 0">
                    <div class="form-group">
                        <select
                            class="form-control"
                            [compareWith]="compareDudaCategoria"
                            [(ngModel)]="dudaCategoriaSecond"
                            (ngModelChange)="getDudaCategoriasThird()"
                            [ngModelOptions]="{ standalone: true }"
                        >
                            <option [ngValue]="undefined">
                                {{ "participa.dudas.nueva.paso_categ.opcion_default" | t }}
                            </option>
                            <option *ngFor="let dudaCategoria of dudaCategoriasSecond" [ngValue]="dudaCategoria">
                                {{ dudaCategoria.duda_categoria }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-4" *ngIf="dudaCategoriasThird && dudaCategoriasThird.length > 0">
                    <div class="form-group">
                        <select
                            class="form-control"
                            [compareWith]="compareDudaCategoria"
                            [(ngModel)]="dudaCategoriaThird"
                            [ngModelOptions]="{ standalone: true }"
                        >
                            <option [ngValue]="undefined">
                                {{ "participa.dudas.nueva.paso_categ.opcion_default" | t }}
                            </option>
                            <option *ngFor="let dudaCategoria of dudaCategoriasThird" [ngValue]="dudaCategoria">
                                {{ dudaCategoria.duda_categoria }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="push-bottom-buttons"></div>
            <div class="bottom-buttons">
                <cui-button-link
                    class="pull-left"
                    roleValue="tab"
                    dataToggle="tab"
                    (click)="selectTab(datos.categ.previous)"
                >
                    {{ "participa.dudas.nueva.btn_anterior" | t }}
                </cui-button-link>
                <cui-button-link
                    class="pull-right"
                    roleValue="tab"
                    dataToggle="tab"
                    (click)="selectTab(datos.categ.next)"
                >
                    {{ "participa.dudas.nueva.btn_siguiente" | t }}
                </cui-button-link>
            </div>
        </div>

        <div id="tab{{ datos.prev.numero }}" class="tab-pane clearfix" [class.active]="datos.prev.activo">
            <h3 class="preview-title">{{ duda.duda }}</h3>
            <h3 class="preview-title cont-pendiente" *ngIf="!duda.duda || (duda.duda && duda.duda.length == 0)">
                {{ "participa.dudas.nueva.paso_prev.sin_titulo" | t }}
            </h3>
            <h3 class="preview-title cont-warning" *ngIf="checkPremade()">
                {{ "participa.dudas.nueva.paso_prev.predeterminado" | t }}
            </h3>
            <div [innerHTML]="duda.detalle"></div>
            <p class="cont-pendiente" *ngIf="!duda.detalle || (duda.detalle && duda.detalle.length == 0)">
                {{ "participa.dudas.nueva.paso_prev.sin_detalle" | t }}
            </p>

            <div class="push-bottom-buttons"></div>
            <div class="bottom-buttons">
                <cui-button-link
                    class="pull-left"
                    roleValue="tab"
                    dataToggle="tab"
                    (click)="selectTab(datos.prev.previous)"
                >
                    {{ "participa.dudas.nueva.btn_anterior" | t }}
                </cui-button-link>
                <cui-button class="pull-right" [disabled]="!ready || btnSaveDisabled" (click)="publicar()">
                    {{ "participa.dudas.nueva.btn_publicar" | t }}
                </cui-button>
                <div class="checkbox confirmar">
                    <label>
                        <input type="checkbox" [(ngModel)]="ready" [ngModelOptions]="{ standalone: true }" />
                        {{ "participa.dudas.nueva.chkbox_publicar" | t }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</form>
