import { DatePipe } from "@angular/common"

export class DateHelper {
    public static today() {
        return new DatePipe("en-US").transform(new Date(), "yyyy-MM-dd")
    }

    public static firstDayYear(minusYears: number = 0) {
        return new Date().getFullYear() - minusYears + "-01-01"
    }
}
