<div class="duda-box" *ngIf="duda">
    <div class="puntaje">
        <single-value-sentiment [object]="duda" [vertical]="true"></single-value-sentiment>
    </div>
    <div class="duda-box-container">
        <h2>
            <a routerLink="/dudas/{{ duda.id }}">{{ duda.duda }}</a>
        </h2>
        <p class="thread-info">
            {{ 'duda.creado_por' | t }}:&nbsp;
            <a class="thread-author" routerLink="/usuarios/{{ duda.usuario_id }}">
                {{ duda.usuario.nombreCompleto() }}
            </a>
            &nbsp;
            <span class="thread-time">{{ duda.created_at | fechaAtras }}</span>
            &nbsp;
            <span *ngIf="!duda.ban && duda.created_at != duda.updated_at" class="thread-time">
                ({{ 'duda.editado' | t }} {{ duda?.updated_at | fechaAtras }})
            </span>
        </p>
        <p *ngIf="duda.duda_categorias" class="category-info">
            <span class="categoria {{ c.asignatura?.clase }}" *ngFor="let c of duda.duda_categorias">
                {{ c.duda_categoria }}
            </span>
        </p>

        <div class="bottom-info clearfix">
            <p *ngIf="duda.participacion && duda.participacion > 0" class="participacion">
                <b>{{ duda.participacion }}&nbsp;</b>
                {{ comentarios }}
            </p>
            <!-- <span class="separator"></span> -->
            <p *ngIf="hasSolution && !hasAnswer" class="contestada">
                {{ 'duda.contestada' | t }}
                <ogr-icon class="icono" name="ticket-circle"></ogr-icon>
            </p>
            <p *ngIf="!hasSolution && !hasAnswer" class="sin-contestar">{{ 'duda.pendiente' | t }}</p>
        </div>
    </div>
</div>
