import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, ParamMap, Router, RouterStateSnapshot } from "@angular/router"
import { AppConfig, AuthService, SessionService } from "@puntaje/shared/core"
import { Store } from "@ngrx/store"
import { State, GetStoreConfig } from "@puntaje/puntaje/store"
import { ReporteRazones } from "@puntaje/nebulosa/api-services"

@Injectable({ providedIn: "root" })
export class TokenGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private sessionService: SessionService,
        private reporteRazonesService: ReporteRazones,
        private router: Router,
        public store: Store<State>,
        protected config: AppConfig
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let routeParams: ParamMap = route.queryParamMap
        if (routeParams.has("token") && routeParams.has("nebu_token")) {
            // GetAsignaturasConfig
            /* Setea los token de puntaje y nebu */
            this.authService.setToken(routeParams.get("token"))
            // TODO: Buscar una forma de no poner el nombre literal del token sin usar nebu auth service
            this.authService.setProperty("auth_token_nebuloso", routeParams.get("nebu_token"))
            if (routeParams.get("carreras_token")) {
                this.authService.setProperty("auth_token_carreras", routeParams.get("carreras_token"))
            }
            if (routeParams.get("logros_token")) {
                this.authService.setProperty("auth_token_logros", routeParams.get("logros_token"))
            }

            if (routeParams.has("impersonando")) {
                this.config.app.routeUser.manual_reset_password = false
                this.config.app.routeUser.nomina = false
                this.sessionService.setResetPasswordSentAt(null)
            }
            this.sessionService.setUserData(this.config.app.routeUser)
            this.authService.setSessionValues(this.config.app.routeUserInfo)

            this.store.dispatch(new GetStoreConfig())
            this.reporteRazonesService.loadReporteRazones()
            //switchProfileClassOnBody -> AppComponent
            ;(<HTMLElement>document.querySelector("body")).className = "body-default"
            ;(<HTMLElement>document.querySelector("body")).classList.add("profile1")
            this.authService.profileChanged.next("profile1")
            var hash = {}
            const path = state.url.split('?')[0]
            const exceptions = ["token", "nebu_token", "carreras_token", "logros_token"]
            
            routeParams.keys.forEach(e => {
                if (!exceptions.includes(e)) {
                    hash[e] = routeParams.get(e)
                }
            })

            this.router.navigate([path], { queryParams: hash })
        }
        return true
    }
}
