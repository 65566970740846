import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnInit, HostBinding } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "arte-home-showcasebox-barra",
    templateUrl: "banners_showcasebox_barra.component.html",
    styleUrls: ["banners_showcasebox_barra.component.scss"]
})
export class BannersShowcaseBoxBarraComponent {
    @Input() pagina: string
    @Input() zona: string
    @HostBinding("class") innerTheme: string = "showcasebox_style_1"

    constructor() {}
}
