import { Component, OnInit, Input, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { Evaluacion } from "@puntaje/puntaje/api-services"

import { Instrucciones, Instruccion } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "instrucciones-fijas",
    templateUrl: "./instrucciones-fijas.component.html",
    styleUrls: ["./instrucciones-fijas.component.scss"]
})
export class InstruccionesFijasComponent implements OnInit {
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @Input() startingPosition: boolean = false
    @Input() evaluacion: Evaluacion
    showComponent: boolean = true
    showModal: boolean = false
    instruccionfija: Instruccion = new Instruccion()
    showButton: boolean = false

    constructor(protected cdr: ChangeDetectorRef, protected instruccionesService: Instrucciones) {}

    ngOnInit() {
        this.getInstrucciones()
    }

    getInstrucciones() {
        let params = {
            generador_instrumento_instruccion: {
                generador_instrumento_id: this.evaluacion.instrumento.generador_instrumento_id,
                fija: true
            }
        }

        this.instruccionesService.where(params).then((instrucciones: Instruccion[]) => {
            this.instruccionfija = instrucciones[0]
            this.cdr.detectChanges()
            this.showModal = true
            this.showButton = true
        })
    }

    openModalInstruccion() {
        this.showModal = true
        this.openModal.next()
    }

    ngOnDestroy() {
        this.showButton = false
    }

    toggleComponent() {
        this.showComponent = !this.showComponent
    }
}
