<generic-modal [buttonClicked]="openModal" [id]="'arte-zona-modal'" *ngIf="showModal">
    <modal-contenido class="modal-contenido">
        <div class="modal-contenido-wrap">
            <banner-show
                class="margin-target"
                [pagina]="pagina"
                [zona]="zona"
                [bannerTipo]="'Imagen con link'"
                (load)="onLoad()"
            ></banner-show>
        </div>
    </modal-contenido>
    <modal-botones>
        <cui-button
            buttonType="cancel"
            type="button"
            class="btn_style_2"
            data-dismiss="modal"
            (click)="genericModal.close()"
        >
            Cerrar
        </cui-button>
    </modal-botones>
</generic-modal>
