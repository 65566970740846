import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "dudas.component.html",
    styleUrls: ["dudas.component.scss"]
})
export class DudasComponent implements OnInit {
    showCursos: boolean
    private sub: Subscription
    subData: Subscription
    categoriaId: number

    fromProfesor = config.app.name == "profesores"

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected loggedLayoutService: LoggedLayoutService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.dudas.dudas.list")
        this.sub = this.route.queryParams.subscribe(params => {
            this.categoriaId = +params["categoria_id"]
            this.showCursos = !!+params["show_cursos"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.showCursos = data.showCursos ?? this.showCursos
        })

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }
}
