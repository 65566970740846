import { BaseModel } from "@puntaje/shared/core"

export class TypeformWidgetTipoUsuario extends BaseModel {
    public tipo_usuario: string

    public toString() {
        if (this.tipo_usuario) {
            return this.tipo_usuario
        } else {
            return ""
        }
    }
}
