import { PuntajeEnsayosModule } from "@puntaje/puntaje/core"
import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { UtilModule } from "@puntaje/shared/core"
import { TranslationsModule } from "@puntaje/shared/core"
import { routing } from "./alumnos_ensayos.routing"

import { HomePruebasComponent } from "./home_pruebas.component"
import { HomeSaberComponent } from "./home_saber.component"
import { HomeSaber11Component } from "./home_saber11.component"

import { CUIContainersModule, CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EnsayosModule,
        AlumnosLayoutsModule,
        SharedModule,
        routing,
        Angular2FontawesomeModule,
        UtilModule,
        NebuModule,
        TranslationsModule,
        CUIContainersModule,
        CUILayoutsModule,
        PuntajeEnsayosModule
    ],
    declarations: [HomeSaberComponent, HomeSaber11Component, HomePruebasComponent],
    exports: []
})
export class AlumnosEnsayosModule {}
