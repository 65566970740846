import { Component, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { View } from "./view.component"

@Component({
    selector: "videoview",
    template: `
        <ng-template #viewLabel></ng-template>
        <ng-template #viewValue>
            <div #videoContainer (window:resize)="onResize($event)">
                <video
                    controls
                    preload
                    [width]="width"
                    [src]="safeUrl"
                    (loadstart)="onLoadVideo()"
                    allowfullscreen
                ></video>
            </div>
        </ng-template>
    `,
    styleUrls: ["model_simple_view.component.scss"]
})
export class VideoView extends View {
    width: number = 640
    height: number = 480
    max_width: number = 640
    max_height: number = 480
    url: string
    safeUrl: SafeResourceUrl
    urlReady: boolean = false
    @ViewChild("videoContainer") videoContainer: ElementRef

    constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {
        super()
    }

    ngOnInit() {
        this.url = this.value[this.key]

        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
        this.urlReady = true

        this.setRatioSize()
    }

    onResize(event) {
        this.setRatioSize()
    }

    setRatioSize() {
        if (this.videoContainer) {
            if (this.videoContainer.nativeElement.offsetWidth > this.max_width) {
                this.width = this.max_width
            } else {
                this.width = this.videoContainer.nativeElement.offsetWidth
            }
        }
        this.height = (this.width * this.max_height) / this.max_width
    }

    onLoadVideo() {
        this.cdr.detectChanges()
        this.setRatioSize()
    }
}
