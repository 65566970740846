<ll-titulo>
    {{ evaluacionTipoAlias | titlecase }}
    {{ titulo ? titulo : ("evaluacion_recomendada.recomendadas" | t | genderize: tipoInstrumento) }}
</ll-titulo>

<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas
        [showTitleOverMenu]="false"
        [evaluacionTipos]="evaluacionTipos"
    ></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>

<ng-template [ngIf]="tipoInstrumento === 'Simulacro Saber Pro'">
    <vista-instrumentos-multiples [listaView]="false"></vista-instrumentos-multiples>
</ng-template>

<ng-template [ngIf]="tipoInstrumento !== 'Simulacro Saber Pro'">
    <evaluaciones-recomendadas-alumno
        *ngIf="asignaturaId"
        [asignaturaId]="asignaturaId"
        [evaluacionTipo]="tipoInstrumento"
    ></evaluaciones-recomendadas-alumno>
</ng-template>
