import { BaseForm, Validations } from "@puntaje/shared/core"
import { Usuarios } from "./usuarios.service"

export class UsuarioRegistroBaseColombiaForm extends BaseForm {
    companyName: string = ""
    public static get formParams(): any {
        return {
            usuario_id: { type: "hidden", visible: true },
            cc: {
                label: "Número de Identificación Personal",
                type: "text",
                validations: [Validations.validateCC],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueCC }],
                global: true,
                visible: true
            }
        }
    }
}
