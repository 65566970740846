/** @format */

import { HttpClientModule } from "@angular/common/http"
import { ModuleWithProviders, NgModule } from "@angular/core"
import { AuthGuard } from "./auth-guard.service"
import { AuthService } from "./auth.service"
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider } from "angularx-social-login"

let config = new AuthServiceConfig([
    {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider("1007956327129-uaiiij4g096587sfcabjiur6os436bop")
    }
])

export function provideConfig() {
    return config
}

@NgModule({
    imports: [HttpClientModule, SocialLoginModule],
    declarations: [],
    exports: [],
    providers: []
})
export class AuthModule {
    static forRoot(options?): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                AuthService,
                {
                    provide: AuthGuard,
                    useClass: options && options.authGuard ? options.authGuard : AuthGuard
                },
                {
                    provide: AuthServiceConfig,
                    useFactory: provideConfig
                }
            ]
        }
    }
}
