import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PlanPersonal } from "./plan_personales.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class PlanPersonales extends PuntajeBaseService<PlanPersonal> {
    tableName = "plan_personales"
    singularTableName = "plan_personal"
    modelClass = PlanPersonal

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    createReforzamientos(params: any = {}) {
        return this.all().concatExtra("reforzamientos").post(params)
    }

    datosEvolucionEvaluacion(params: any = {}) {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("evolucion_evaluacion_seguimiento")
            .post(params)
            .then((evolucion_evaluacion: any) => {
                this.disableIgnoreModel()
                return evolucion_evaluacion
            })
    }

    alumnoReforzamientoStats(evaluacion_id: number) {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("stats_por_alumno")
            .get({ evaluacion_id })
            .then((stats: any) => {
                this.disableIgnoreModel()
                return stats
            })
    }

    planClaseInit(planPersonalId: number, grupoUsuarioId: number) {
        this.enableIgnoreModel()

        const params: any = {
            grupo_usuario_id: grupoUsuarioId
        }

        return this.one(planPersonalId)
            .concatExtra("plan_clase_init")
            .post(params)
            .then(response => {
                this.disableIgnoreModel()

                return response
            })
    }

    avanceSesionesAlumnos(id, params) {
        this.enableIgnoreModel()
        return this.one(id)
            .concatExtra("avance_sesiones_alumnos")
            .get(params)
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }

    public wherePost(params: any = null, timeout: number = 30000) {
        return this.all().concatExtra("post").post(params, timeout)
    }

    public replicar(id: number, grupoUsuarioOrigenId: number, grupoUsuarioObjetivoIds: number[]) {
        const params: any = {
            grupo_usuario_origen_id: grupoUsuarioOrigenId,
            grupo_usuario_objetivo_ids: grupoUsuarioObjetivoIds
        }
        return this.one(id).concatExtra("replicar").post(params)
    }
}
