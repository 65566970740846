<div class="notificacion" [class.animateExit]="animateExit" [class.mini]="enableMini" [@slideWhenChecked]="slideState">
    <div class="tipo-notificacion {{ tipoNotificacion }}">
        <ogr-icon [name]="icono"></ogr-icon>
    </div>
    <div class="notificacion-contenido">
        <div class="clearfix pull-right" *ngIf="!disableMarkButton">
            <button type="button" class="pull-right ready-btn" (click)="onClickChecked()">
                <ogr-icon [name]="'cruz'"></ogr-icon>
            </button>
        </div>
        <ng-content select="notificacion-contenido"></ng-content>
        <div class="clearfix foot">
            <p class="fecha_creacion">
                <fa [name]="'clock-o'"></fa>
                {{ notificacion.created_at | date: "dd-MM-yyyy" }} a las {{ notificacion.created_at | date: "HH:mm" }}
            </p>
            <ng-content select="notificacion-acciones-footer"></ng-content>
        </div>
    </div>
</div>
