import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule, PaginatorModule, FormModule } from "@puntaje/shared/core"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { CUIContainersModule, CUIInputsModule } from "@puntaje/shared/cui"

import { PostsEditComponent } from "./posts.edit.component"
import { PostsNewComponent } from "./posts.new.component"
import { PostsComponent } from "./posts/posts.component"
import { PostsBuscadorComponent } from "./posts-buscador/posts-buscador.component"
import { PostsToolNavbarComponent } from "./posts-toolnavbar/posts-toolnavbar.component"
import { PostsBuscadorService } from "./posts-buscador/posts-buscador.service"
import { BlogComponent } from "./blog/blog.component"
import { PostThumbnailComponent } from "./post-thumbnail/post-thumbnail.component"
import { PostComponent } from "./post/post.component"
import { BlogShowcaseboxComponent } from "./blog-showcasebox/blog-showcasebox.component"
import { BlogShowcaseboxProfesorComponent } from "./blog-showcasebox-profesor/blog-showcasebox-profesor.component"
import { BlogLandingComponent } from "./blog-landing/blog-landing.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormModule,
        UtilModule,
        PaginatorModule,
        CommonsLayoutsModule,
        Angular2FontawesomeModule,
        RouterModule,
        SharedModule,
        CUIContainersModule,
        CUIInputsModule
    ],
    declarations: [
        PostsComponent,
        PostsBuscadorComponent,
        PostsToolNavbarComponent,
        PostsEditComponent,
        PostsNewComponent,
        PostsToolNavbarComponent,
        BlogComponent,
        PostComponent,
        PostThumbnailComponent,
        BlogShowcaseboxComponent,
        BlogShowcaseboxProfesorComponent,
        BlogLandingComponent
    ],
    exports: [
        PostsComponent,
        PostsBuscadorComponent,
        PostsToolNavbarComponent,
        PostsEditComponent,
        PostsNewComponent,
        BlogComponent,
        PostsToolNavbarComponent,
        PostComponent,
        PostThumbnailComponent,
        BlogShowcaseboxComponent,
        BlogShowcaseboxProfesorComponent,
        BlogLandingComponent
    ],
    providers: [PostsBuscadorService]
})
export class BlogModule { }
