import { Component, Input, HostBinding, ElementRef, Output, EventEmitter, HostListener } from "@angular/core"

@Component({ template: "" })
export class CUIButtonBaseComponent {
    buttonClass: "btn_base_1" | "btn_base_2" | "btn_base_3"

    @Input() isBtn = true
    @Input() isBlock = false
    // Tipos: default primary secondary cancel table-btn
    @Input() buttonType: "secondary" | "cancel" | "table-btn" | "confirm" | "danger" | "primary" = "primary"
    // Tamaños: xsmall small medium large xlarge
    @Input() size: "xsmall" | "small" | "large" | "xlarge" | "medium" = "medium"
    @Input("social") socialMedia:
        | "facebook"
        | "instagram"
        | "twitter"
        | "linkedin"
        | "youtube"
        | "twitch"
        | "tiktok"
        | "google"
        | "" = ""
    @Output() click: EventEmitter<any> = new EventEmitter<any>()
    @Input() disabled: boolean | string = false
    @Input() isLoading = false
    @Input() loaderPosition: "right" | "inside-left" | "inside-right" | "left" = "left"
    @Input() loaderLight = false
    @Input() type = "button"

    @Input() class = "" // override the standard class attr with a new one.
    @HostBinding("class")
    get hostClasses(): string {
        return [
            "cui-button",
            this.buttonType,
            this.isLoading ? "isLoading" : "",
            this.socialMedia ? this.socialMedia : "",
            this.isBlock ? "block" : "",
            this.class // include our new one
        ].join(" ")
    }

    buttonTypes: any = {
        danger: "btn_base_1",
        primary: "btn_base_1",
        cancel: "btn_base_2",
        confirm: "btn_base_3",
        secondary: "btn_base_3"
    }

    constructor(protected el: ElementRef) {}

    onClickButton(event) {
        this.click.emit(event)
    }

    ngOnInit() {
        switch (this.buttonType) {
            case "danger":
            case "primary": {
                this.buttonClass = "btn_base_1"
                break
            }
            case "cancel": {
                this.buttonClass = "btn_base_2"
                break
            }
            case "confirm": {
                this.buttonClass = "btn_base_3"
                break
            }
            case "secondary": {
                this.buttonClass = "btn_base_3"
                break
            }
        }
    }
}
