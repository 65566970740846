<a
    [routerLink]="!menuItem.locked ? [menuItem.route] : null"
    [queryParams]="menuItem.params"
    role="button"
    *ngIf="menuItem"
    class="menu_item_box"
    [class.locked]="menuItem.locked"
    (click)="callAction($event)"
>
    <div *ngIf="menuItem.cornerRibbon" corner-ribbon>{{ menuItem.cornerRibbon.text }}</div>
    <div class="menu_item_box_wrapper">
        <div class="menu_item_row_wrapper">
            <div class="menu_item_box_icon_container">
                <div class="menu_item_box_icon">
                    <ogr-icon *ngIf="menuItem.icon" name="{{ menuItem.icon }}" class="icono"></ogr-icon>
                </div>
            </div>
            <div class="menu_item_box_body">
                <h3 class="menu_item_box_title" *ngIf="menuItem.label">{{ menuItem.label }}</h3>
                <div class="menu_item_box_text" *ngIf="menuItem.text">
                    {{ menuItem.text }}
                </div>
            </div>
        </div>
    </div>
    <div class="menu_item_box_link">
        {{ menuItem.linkText | easyplaceholder: ('ver' | t) }}
        <fa name="play" class="arrow"></fa>
    </div>
    <div class="locked-menu-item" *ngIf="menuItem.locked">
        <div class="padlock">
            <fa name="lock" class="icono-padlock"></fa>
        </div>
        <p class="locked-info">
            {{ menuItem.lockedText }}
        </p>
    </div>
</a>
