import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { Injector } from "@angular/core"
import { HttpClient, HttpResponse } from "@angular/common/http"
import { of, timer } from "rxjs"
import { switchMap } from "rxjs/operators"
import sha1 from "js-sha1"
import { I18nService } from "../i18n/i18n.service"
import { AppConfig } from "../conf/app_config"
declare const config: AppConfig

export type GroupValidator = (g: UntypedFormGroup) => any

async function digestMessage(message) {
    const hashHex = sha1(message)

    return hashHex.toUpperCase()
}

export class Validations {
    constructor(private injector: Injector) {}

    public static validateAll(validators: GroupValidator[]): any {
        return function (g: UntypedFormGroup): any {
            if (!validators) return null

            const result = {}
            validators.forEach((f: GroupValidator) => {
                Object.assign(result, f(g))
            })

            return result
        }
    }

    public static messages = {
        required: "El campo {name} es obligatorio",
        multipleOrRequired: "Se requiere que al menos el campo {fields} este presente.",
        minlength: "Necesita tener al menos {requiredLength} carácteres",
        maxlength: "Necesita tener a lo más {requiredLength} carácteres",
        min: "Necesita ser mayor o igual que {min}",
        max: "Necesita ser menor o igual que {max}",
        validateEmail: "La dirección de email es inválida",
        notExistingEmail: "La dirección de email no existe",
        validateRut: "El Rut es inválido. Rut debe ser ingresado con guión y sin puntos.",
        validateCI: "La Cédula de Identidad es inválida.",
        validateNem: "El NEM no es válido",
        validateCPF: "CPF inválido",
        validateNumeroUnico: "CURP inválido. Debe tener 18 caracteres.",
        // validatePasaporte: `Ingrese un número de documento correcto. Si el error persiste contactar a ${config?.plataforma?.info?.soporte?.mail}`,
        validatePasaporte: `Ingrese un número de documento correcto. Si el error persiste contactar a soporte.`,
        equalPasswords: "Las contraseñas no coinciden",
        equalEmails: "Los correos no coinciden",
        fileNotPresent: "Debe adjuntar un archivo.",
        extensionNotSuported: "El archivo solo puede ser de tipo .pdf o .pptx.",
        extensionNotSuported2: "El archivo solo puede ser de tipo .xls o .xlsx.",
        extensionNotSuported3: "El archivo solo puede ser de tipo .csv",
        imageExtensionNotSuported: "El archivo solo puede ser de tipo .png o .jpg.",
        notValidURI: "URI no es válida.",
        duplicateRut: "El Rut ya está registrado. Si tu Rut está correcto ponte en contacto con nosotros.",
        duplicateCI:
            "La Cédula de Identidad ya está registrada. Si tu CI está correcto ponte en contacto con nosotros.",
        duplicateEmail: "La dirección de email ya está registrada.",
        duplicateTi: "T.I. ya fue tomado. Si está correcto, por favor ponte en contacto con nosotros.",
        duplicateCC: "El C.C. ya fue tomado. Si está correcto, por favor ponte en contacto con nosotros.",
        duplicateNumeroUnico:
            "El número único ya está registrado. Si tu código está correcto ponte en contacto con nosotros.",
        duplicateCPF: "O CPF já está cadastrado. Se o seu código estiver correto, entre em contato.",
        invalidTI: "El número de la Tarjeta de Identidad no es válido.",
        invalidCC: "El número de la Cedula de Ciudadania no es válido.",
        invalidTelephoneCL: "El número de teléfono es incorrecto. Ingrese al menos los 9 dígitos correspondientes.",
        invalidTelephoneEC: "El número de teléfono es incorrecto. Ingrese al menos los 8 dígitos correspondientes.",
        invalidTelephoneUss: "El número de teléfono es incorrecto. Ingrese al menos los 8 dígitos correspondientes.",
        invalidTelephoneCO: "El número de teléfono es incorrecto. Debe incluir código de ciudad.",
        invalidTelephoneTec: "El número de teléfono es incorrecto.Debe incluir código de ciudad.",
        invalidPreguntaId: "No existe pregunta con este ID.",
        breachedPassword: "La contraseña no es segura. Prueba con otra de otros caracteres.",
        breachedPasswordOnLogin:
            "Para aumentar el resguardo de tus datos aumentamos los requisitos de seguridad de las contraseñas, por lo que debes ingresar una nueva. Para esto debes dirigirte a ¿Olvidaste tu contraseña? o enviar un correo a contacto@ogr.cl"
    }

    public static checkPasswordBreach(
        http: HttpClient,
        isLogin: boolean = false
    ): (control: UntypedFormControl) => any {
        return (control: UntypedFormControl) => {
            const password = control.value

            if (!password) {
                return of(null)
            }

            return timer(500).pipe(
                switchMap(_ =>
                    digestMessage(password).then(sha1pass => {
                        const first5 = sha1pass.substring(0, 5)
                        const rest = sha1pass.substring(5)

                        return http
                            .get("https://api.pwnedpasswords.com/range/" + first5, { responseType: "text" })
                            .toPromise()
                            .then(textList => {
                                let isBreached = textList.split("\n").some(hashCount => hashCount.split(":")[0] == rest)
                                const isValidPassGuanajuato =
                                    password.length == 9 && !isNaN(password) && config?.plataforma?.name == "Guanajuato"
                                // if para que no les salga el mensaje para aquellas personas que aun tiene 4 digitos de contraseña
                                // ni para la gente que se le reseteo la contraseña
                                // ni para la contraseña de desarrollo
                                // TODO: este if tiene que volar en algún momento
                                if (
                                    isLogin &&
                                    (password.length == 4 ||
                                        password.length == 6 ||
                                        password == "holiholi" ||
                                        isValidPassGuanajuato)
                                ) {
                                    isBreached = false
                                }

                                return isBreached
                                    ? {
                                          [isLogin ? "breachedPasswordOnLogin" : "breachedPassword"]: {
                                              valid: false
                                          }
                                      }
                                    : null
                            })
                    })
                )
            )
        }
    }

    public static getMessage(
        name: string,
        errorKey: string,
        error: any,
        customMessage: string | { [key: string]: string } = null,
        translator?: I18nService
    ) {
        if (customMessage) {
            if (typeof customMessage === "object" && customMessage[errorKey]) {
                return customMessage[errorKey]
            } else if (typeof customMessage === "string") {
                return customMessage
            }
        }

        let message: string

        if (translator) {
            const options = typeof error === "object" ? { ...error } : { [errorKey]: name }
            message = translator.translate(`validations.messages.${errorKey}`, options)
        } else {
            for (const key in error) {
                message = message.replace("{" + key + "}", error[key])
            }
        }

        return message
    }

    public static validateEmail(control: UntypedFormControl) {
        const EMAIL_REGEXP =
            /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|school|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i

        return EMAIL_REGEXP.test(control.value)
            ? null
            : {
                  validateEmail: {
                      valid: false
                  }
              }
    }

    public static validateNEMOrEmpty(control: UntypedFormControl) {
        if (!control.value) return null

        const NEM_REGEXP = /^([1-6][\.,]?[0-9])$|^(7[\.,]?0)$/

        return NEM_REGEXP.test(control.value)
            ? null
            : {
                  validateNem: {
                      valid: false
                  }
              }
    }

    private static verificarRut(rut, digito) {
        let resto = parseInt(rut)
        let suma = 0
        let mutiplicador = 2
        let dig = 0
        if (digito.toLowerCase() == "k") {
            dig = 10
        } else {
            dig = parseInt(digito)
        }
        if (dig == 0) {
            dig = 11
        }
        while (resto > 0) {
            suma = suma + (resto % 10) * mutiplicador
            resto = Math.floor(resto / 10)
            mutiplicador = Math.max((mutiplicador + 1) % 8, 2)
        }
        if (11 - (suma % 11) == dig) {
            return true
        } else {
            return false
        }
    }

    public static validateRut(control: UntypedFormControl) {
        const RUT_REGEXP = /^([0-9]{6,12})[-]([0-9kK])$/i
        const parts = RUT_REGEXP.exec(control.value)
        if (parts) {
            return Validations.verificarRut(parts[1], parts[2])
                ? null
                : {
                      validateRut: {
                          valid: false
                      }
                  }
        } else {
            return {
                validateRut: {
                    valid: false
                }
            }
        }
    }

    public static validateRutOrEmpty(control: UntypedFormControl) {
        if (!control.value) {
            return null
        }

        return Validations.validateRut(control)
    }

    public static validatePasaporte(control: UntypedFormControl) {
        const RUT_REGEXP = /^([a-zA-Z0-9]{3,})$/i
        const parts = RUT_REGEXP.exec(control.value)

        return parts
            ? null
            : {
                  validatePasaporte: {
                      valid: false
                  }
              }
    }

    private static coeficientesCI = [2, 1, 2, 1, 2, 1, 2, 1, 2]

    private static verificarCI(ci: string) {
        if (ci.length != 10 || isNaN(+ci) || +ci[2] >= 6) {
            return false
        }

        const dv = +ci[9]

        let sum = 0
        for (let i = 0; i < 9; i++) {
            const digito = +ci[i]
            const toSum = digito * this.coeficientesCI[i]
            sum += toSum

            if (toSum >= 10) {
                sum -= 9
            }
        }

        const calculatedDv = 10 - (sum % 10)

        return dv == calculatedDv
    }

    public static validateCI(control: UntypedFormControl) {
        return Validations.verificarCI(control.value)
            ? null
            : {
                  validateCI: {
                      valid: false
                  }
              }
    }

    public static validateNumeroUnico(control: UntypedFormControl) {
        const numeroUnico = control.value

        return numeroUnico.length == 18
            ? null
            : {
                  validateNumeroUnico: {
                      valid: false
                  }
              }
    }

    public static validateUniqueCI(usuarioService: any): (form: UntypedFormControl) => any {
        return function (control: UntypedFormControl): any {
            const paramsUsuario = {
                from_nomina: 1,
                usuario_ecuador: {
                    ci: control.value
                }
            }

            return usuarioService.exists(paramsUsuario).then(response => {
                return response.empty || response.from_nomina
                    ? null
                    : {
                          duplicateCI: {
                              valid: false
                          }
                      }
            })
        }
    }

    public static validateUniqueRut(usuarioService: any): (form: UntypedFormControl) => any {
        return function (control: UntypedFormControl): any {
            if (control.parent.controls["serial"] && control.parent.controls["serial"].value)
                return new Promise(resolve => resolve(null))
            const paramsUsuario = {
                from_nomina: 1,
                usuario_chile: {
                    rut: control.value
                }
            }

            return usuarioService.exists(paramsUsuario).then(response => {
                return response.empty || response.from_nomina
                    ? null
                    : {
                          duplicateRut: {
                              valid: false
                          }
                      }
            })
        }
    }

    public static validateUniqueNumeroUnico(usuarioService: any): (form: UntypedFormControl) => any {
        return function (control: UntypedFormControl): any {
            if (control.parent.controls["serial"] && control.parent.controls["serial"].value)
                return new Promise(resolve => resolve(null))
            const paramsUsuario = {
                from_nomina: 1,
                usuario_mexico: {
                    numero_unico: control.value
                }
            }

            return usuarioService.exists(paramsUsuario).then(response => {
                return response.empty || response.from_nomina
                    ? null
                    : {
                          duplicateNumeroUnico: {
                              valid: false
                          }
                      }
            })
        }
    }

    public static validateUniqueNumeroUnicoNotSelf(usuarioService: any) {
        return (control: UntypedFormControl): any => {
            return new Promise((resolve, reject) => {
                if (!control.parent?.controls?.["usuario_id"]) return resolve(null) //caso para cuando muere el formgroup/control

                usuarioService.where({ usuario_mexico: { numero_unico: control.value } }).then(usuarios => {
                    const usuario = usuarios[0]
                    if (
                        !usuario ||
                        !control.parent.controls["usuario_id"] ||
                        usuario.id == control.parent.controls["usuario_id"].value
                    )
                        return resolve(null)

                    return resolve({
                        duplicateNumeroUnico: {
                            valid: false
                        }
                    })
                })
            })
        }
    }

    public static validateUniqueEmail(usuarioService: any): (form: UntypedFormControl) => any {
        return function (control: UntypedFormControl): any {
            return usuarioService.exists({ usuario: { email: control.value } }).then(response => {
                return response.empty
                    ? null
                    : {
                          duplicateEmail: {
                              valid: false
                          }
                      }
            })
        }
    }

    public static validateExistingEmail(usuarioService: any): (form: UntypedFormControl) => any {
        return function (control: UntypedFormControl): any {
            return usuarioService.exists({ usuario: { email: control.value } }).then(response => {
                return !response.empty
                    ? null
                    : {
                          notExistingEmail: {
                              valid: false
                          }
                      }
            })
        }
    }

    public static validateUniqueRutNotSelf(usuarioService: any) {
        return (control: UntypedFormControl): any => {
            return new Promise((resolve, reject) => {
                if (!control.parent?.controls?.["usuario_id"]) return resolve(null) //caso para cuando muere el formgroup/control

                usuarioService.where({ usuario_chile: { rut: control.value } }).then(usuarios => {
                    const usuario = usuarios[0]
                    if (
                        !usuario ||
                        !control.parent.controls["usuario_id"] ||
                        usuario.id == control.parent.controls["usuario_id"].value
                    )
                        return resolve(null)

                    return resolve({
                        duplicateRut: {
                            valid: false
                        }
                    })
                })
            })
        }
    }

    public static validateUniqueCINotSelf(usuarioService: any) {
        return (control: UntypedFormControl): any => {
            return new Promise((resolve, reject) => {
                if (!control.parent?.controls?.["usuario_id"]) return resolve(null) //caso para cuando muere el formgroup/control

                usuarioService.where({ usuario_ecuador: { ci: control.value } }).then(usuarios => {
                    const usuario = usuarios[0]
                    if (
                        !usuario ||
                        !control.parent.controls["usuario_id"] ||
                        usuario.id == control.parent.controls["usuario_id"].value
                    )
                        return resolve(null)

                    return resolve({
                        duplicateCI: {
                            valid: false
                        }
                    })
                })
            })
        }
    }

    public static validateUniqueEmailNotSelf(usuarioService: any) {
        return (control: UntypedFormControl): any => {
            return new Promise((resolve, reject) => {
                if (!control.parent?.controls?.["id"]) return resolve(null) //caso para cuando muere el formgroup/control

                usuarioService.where({ usuario: { email: control.value } }).then(usuarios => {
                    const usuario = usuarios[0]
                    if (!usuario || !control.parent.controls["id"] || usuario.id == control.parent.controls["id"].value)
                        return resolve(null)

                    return resolve({
                        duplicateEmail: {
                            valid: false
                        }
                    })
                })
            })
        }
    }

    // las tres funciones siguientes se usan para validar el TI y el CC
    public static validateNIP(control: UntypedFormControl): any {
        const nip = control.value
        const year = parseInt(nip.substring(0, 2))
        const month = parseInt(nip.substring(2, 4))
        const day = parseInt(nip.substring(4, 6))

        return nip.length == 11 && year >= 62 && month <= 12 && day <= 31 && /^\d+$/.test(nip)
            ? null
            : {
                  invalidNIP: {
                      valid: false
                  }
              }
    }

    public static validateAlphaNUIP(control: UntypedFormControl): any {
        const anuip = control.value
        const sede = anuip.substring(0, 3)
        const number = anuip.substring(3)

        return anuip.length == 10 && /^[a-zA-Z]+$/.test(sede) && /^\d+$/.test(number)
            ? null
            : {
                  invalidAlphaNUIP: {
                      valid: false
                  }
              }
    }

    public static validateNUIP(control: UntypedFormControl): any {
        const nuip = control.value

        return nuip.length == 10 && parseInt(nuip) >= 1000000000
            ? null
            : {
                  invalidNUIP: {
                      valid: false
                  }
              }
    }

    public static validateTI(control: UntypedFormControl): any {
        return control.value == "" ||
            !Validations.validateNIP(control) ||
            !Validations.validateAlphaNUIP(control) ||
            !Validations.validateNUIP(control)
            ? null
            : {
                  invalidTI: {
                      valid: false
                  }
              }
    }

    public static validateCC(control: UntypedFormControl): any {
        const cc = control.value
        const ncc = parseInt(cc)

        return control.value == "" ||
            (cc.length == 8 && ncc >= 10000000) ||
            !Validations.validateAlphaNUIP(control) ||
            !Validations.validateNUIP(control)
            ? null
            : {
                  invalidCC: {
                      valid: false
                  }
              }
    }

    public static validateTIoCC(control: UntypedFormControl): any {
        const validTi = Validations.validateTI(control)
        const validCC = Validations.validateCC(control)
        return validTi === null || validCC === null ? null : validTi || validCC
    }

    public static validateUniqueTiNotSelf(usuarioService: any) {
        return (control: UntypedFormControl): any => {
            return new Promise((resolve, reject) => {
                if (!control.parent?.controls?.["usuario_id"]) return resolve(null) //caso para cuando muere el formgroup/control

                usuarioService.where({ usuario_colombia: { ti_o_cc: control.value } }).then(usuarios => {
                    const usuario = usuarios[0]
                    if (
                        !usuario ||
                        !control.parent.controls["usuario_id"] ||
                        usuario.id == control.parent.controls["usuario_id"].value
                    )
                        return resolve(null)

                    return resolve({
                        duplicateTi: {
                            valid: false
                        }
                    })
                })
            })
        }
    }

    public static validateUniqueCCNotSelf(usuarioService: any) {
        return (control: UntypedFormControl): any => {
            return new Promise((resolve, reject) => {
                if (!control.parent?.controls?.["usuario_id"]) return resolve(null) //caso para cuando muere el formgroup/control

                usuarioService.where({ usuario_colombia: { ti_o_cc: control.value } }).then(usuarios => {
                    const usuario = usuarios[0]
                    if (
                        !usuario ||
                        !control.parent.controls["usuario_id"] ||
                        usuario.id == control.parent.controls["usuario_id"].value
                    )
                        return resolve(null)

                    return resolve({
                        duplicateCC: {
                            valid: false
                        }
                    })
                })
            })
        }
    }

    public static validateUniqueTi(usuarioService: any) {
        return (control: UntypedFormControl): any => {
            return new Promise((resolve, reject) => {
                const paramsUsuario = {
                    from_nomina: 1,
                    usuario_colombia: {
                        ti_o_cc: control.value
                    }
                }

                usuarioService.exists(paramsUsuario).then(obj => {
                    return resolve(
                        obj.empty || obj.from_nomina
                            ? null
                            : {
                                  duplicateTi: {
                                      valid: false
                                  }
                              }
                    )
                })
            })
        }
    }

    public static validateTelefonoChile(control: UntypedFormControl): any {
        if (!control.value) return null
        const rex = /([+]56)?[0-9]{9}/
        const telefono = control.value
        return rex.test(telefono)
            ? null
            : {
                  invalidTelephoneCL: {
                      valid: false
                  }
              }
    }

    public static validateCelularChile(control: UntypedFormControl): any {
        if (!control.value) return null
        const rex = /^([+]569)?[0-9]{8}$/
        const telefono = control.value
        return rex.test(telefono)
            ? null
            : {
                  invalidTelephoneUss: {
                      valid: false
                  }
              }
    }

    public static validateCelularEcuador(control: UntypedFormControl): any {
        if (!control.value) return null
        const rex = /([+]593)?[2457][0-9]{7}/
        const telefono = control.value
        return rex.test(telefono)
            ? null
            : {
                  invalidTelephoneEC: {
                      valid: false
                  }
              }
    }

    public static validateTelefonoTec(control: UntypedFormControl): any {
        // if (!control.value) return null;
        // let rex = /([+]56)?[0-9]{9}/
        // let telefono = control.value;
        // return rex.test(telefono) ? null : {
        //   invalidTelephoneCL: {
        //     valid: false
        //   }
        // }
        return null
    }

    public static validateTelefonoMexico(control: UntypedFormControl): any {
        if (!control.value) return null
        const rex = /([+]52)?([0-9]{10})/
        const telefono = control.value
        return rex.test(telefono)
            ? null
            : {
                  invalidTelephoneCO: {
                      valid: false
                  }
              }
        return null
    }

    public static validateTelefonoUss(control: UntypedFormControl): any {
        if (!control.value) return null
        const rex = /^[0-9]{8}$/
        const telefono = control.value
        return rex.test(telefono)
            ? null
            : {
                  invalidTelephoneUss: {
                      valid: false
                  }
              }
    }

    public static validateTelefonoColombia(control: UntypedFormControl): any {
        if (!control.value) return null
        const rex = /([+]57)?([0-9]{8}([0-9]{3})?)/
        const telefono = control.value
        return rex.test(telefono)
            ? null
            : {
                  invalidTelephoneCO: {
                      valid: false
                  }
              }
    }

    public static validateUniqueCC(usuarioService: any) {
        return (control: UntypedFormControl): any => {
            return new Promise((resolve, reject) => {
                const paramsUsuario = {
                    from_nomina: 1,
                    usuario_colombia: {
                        ti_o_cc: control.value
                    }
                }

                usuarioService.exists(paramsUsuario).then(obj => {
                    return resolve(
                        obj.empty || obj.from_nomina
                            ? null
                            : {
                                  duplicateCC: {
                                      valid: false
                                  }
                              }
                    )
                })
            })
        }
    }

    public static requiredOne(keys: string[]): (form: UntypedFormGroup) => any {
        return function (form: UntypedFormGroup): any {
            const notEmptyKeys = keys.filter(k => form.controls[k] && form.controls[k].value != "")

            return notEmptyKeys.length > 0
                ? null
                : {
                      multipleOrRequired: {
                          valid: false,
                          keys: keys,
                          fields: keys.join(" o ")
                      }
                  }
        }
    }

    public static notEqualPreferencias(keys: string[]): (form: UntypedFormGroup) => any {
        return function (form: UntypedFormGroup): any {
            let value = null
            let error: any = null
            keys.forEach(key => {
                if (error) {
                    return
                }

                if (value === form.controls[key].value) {
                    error = {
                        preferenciasNotEqual: {
                            keys: keys,
                            valid: false
                        }
                    }
                }
                value = form.controls[key].value
            })
            return error
        }
    }

    public static equalPasswords(keys: string[]): (form: UntypedFormGroup) => any {
        return function (form: UntypedFormGroup): any {
            const p1 = form.controls[keys[0]]
            const p2 = form.controls[keys[1]]

            return !p1 || !p2 || p1.value == p2.value || p1.untouched
                ? null
                : {
                      equalPasswords: {
                          keys: keys,
                          valid: false
                      }
                  }
        }
    }

    public static equalEmails(keys: string[]): (form: UntypedFormGroup) => any {
        return function (form: UntypedFormGroup): any {
            const p1 = form.controls[keys[0]]
            const p2 = form.controls[keys[1]]

            return !p1 || !p2 || p1.value == p2.value || p1.untouched || p2.untouched
                ? null
                : {
                      equalEmails: {
                          keys: keys,
                          valid: false
                      }
                  }
        }
    }

    public static validateFile(control: UntypedFormControl) {
        const controls = control.root["controls"]
        if (controls) {
            if (controls["material_tipo_id"] && controls["material_tipo_id"].value === "1") {
                const extension =
                    control.value === "" || control.value === undefined
                        ? ""
                        : control.value.name.substr((~-control.value.name.lastIndexOf(".") >>> 0) + 2)
                const ext_regex = /(pdf|pptx|ppt)$/i
                return ext_regex.test(extension)
                    ? null
                    : {
                          extensionNotSuported: {
                              valid: false
                          }
                      }
            } else {
                return null
            }
        }

        return null
    }

    public static validateNomina(control: UntypedFormControl) {
        const controls = control.root["controls"]
        if (controls) {
            const extension =
                control.value === "" || control.value === undefined
                    ? ""
                    : control.value.name.substr((~-control.value.name.lastIndexOf(".") >>> 0) + 2)
            const ext_regex = /(xls|xlsx|csv|ods)$/i
            return ext_regex.test(extension)
                ? null
                : {
                      extensionNotSuported2: {
                          valid: false
                      }
                  }
        }

        return null
    }

    public static validateEditarMasivamente(control: UntypedFormControl) {
        const controls = control.root["controls"]
        if (controls) {
            const extension =
                control.value === "" || control.value === undefined
                    ? ""
                    : control.value.name.substr((~-control.value.name.lastIndexOf(".") >>> 0) + 2)
            const ext_regex = /(csv)$/i
            return ext_regex.test(extension)
                ? null
                : {
                      extensionNotSuported3: {
                          valid: false
                      }
                  }
        }

        return null
    }

    public static validateImage(control: UntypedFormControl) {
        if (!control.value) return null
        const controls = control.root["controls"]
        if (controls) {
            const extension =
                control.value === "" || control.value === undefined
                    ? ""
                    : control.value.name.substr((~-control.value.name.lastIndexOf(".") >>> 0) + 2)
            const ext_regex = /(png|PNG|jpg|JPG|jpeg|JPEG)$/i
            return ext_regex.test(extension)
                ? null
                : {
                      imageExtensionNotSuported: {
                          valid: false
                      }
                  }
        }

        return null
    }

    public static validateYoutubeURI(control: UntypedFormControl) {
        const controls = control.root["controls"]
        if (controls) {
            if (controls["material_tipo_id"] && controls["material_tipo_id"].value === "2") {
                return control.value !== "" && control.value !== undefined
                    ? null
                    : {
                          notValidURI: {
                              valid: false
                          }
                      }
            } else {
                return null
            }
        }

        return null
    }

    public static validatePreguntaIdorEmpty(preguntasService: any): (form: UntypedFormControl) => any {
        return function (control: UntypedFormControl): any {
            if (!control.value) {
                return new Promise(resolve => resolve(null))
            }

            const paramsPregunta = {
                pregunta: {
                    id: control.value
                }
            }
            return preguntasService.exists(paramsPregunta).then(response => {
                return !response.empty
                    ? null
                    : {
                          invalidPreguntaId: {
                              valid: false
                          }
                      }
            })
        }
    }

    public static validateUniqueCPFNotSelf(usuarioService: any) {
        return (control: UntypedFormControl): any => {
            return new Promise((resolve, reject) => {
                if (!control.parent?.controls?.["usuario_id"]) return resolve(null) //caso para cuando muere el formgroup/control

                usuarioService.where({ usuario_brasil: { cpf: control.value } }).then(usuarios => {
                    const usuario = usuarios[0]
                    if (
                        !usuario ||
                        !control.parent.controls["usuario_id"] ||
                        usuario.id == control.parent.controls["usuario_id"].value
                    )
                        return resolve(null)

                    return resolve({
                        duplicateCPF: {
                            valid: false
                        }
                    })
                })
            })
        }
    }

    public static validateUniqueCPF(usuarioService: any): (form: UntypedFormControl) => any {
        return function (control: UntypedFormControl): any {
            if (control.parent && control.parent.controls["serial"] && control.parent.controls["serial"].value)
                return new Promise(resolve => resolve(null))
            const paramsUsuario = {
                from_nomina: 1,
                usuario_brasil: {
                    cpf: control.value
                }
            }

            return usuarioService.exists(paramsUsuario).then(response => {
                return response.empty || response.from_nomina
                    ? null
                    : {
                          duplicateCPF: {
                              valid: false
                          }
                      }
            })
        }
    }
    //Validar CPF
    public static validateCPF(control: UntypedFormControl) {
        if (!control) {
            return false
        }
        const CPF_REGEXP = /^([0-9]{9,11})([0-9])$/i
        const parts = CPF_REGEXP.exec(control.value)
        return parts
            ? null
            : {
                  validateCPF: {
                      valid: false
                  }
              }
    }
    //Valid only strings
    public static validateString(control: UntypedFormControl) {
        if (!control) {
            return false
        }
        const STRING_REGEXP = /^[A-Za-z]+$/
        const parts = STRING_REGEXP.exec(control.value)
        return parts
            ? null
            : {
                  invalidString: {
                      valid: false
                  }
              }
    }

    //Valid only strings
    public static validateNombresWithSpaces(control: UntypedFormControl) {
        if (!control) {
            return false
        }
        const STRING_REGEXP = /^[A-Za-z]+( [A-Za-z]+){0,2}$/
        const parts = STRING_REGEXP.exec(control.value)
        return parts
            ? null
            : {
                  invalidString: {
                      valid: false
                  }
              }
    }

    public static validateCodigoRegistro(usuarioService) {
        return function (control: UntypedFormControl) {
            return usuarioService.codigoRegistroValido(control.value).then(valido => {
                console.log(valido)
                return valido
                    ? null
                    : {
                          codigoRegistro: {
                              valid: false
                          }
                      }
            })
        }
    }

    public static validateStrArrayNumbers(control: UntypedFormControl) {
        if (!control) {
            return false
        }
        const STRING_REGEXP = /^[0-9]+(,[0-9]+)*$/
        const parts = STRING_REGEXP.exec(control.value)

        return parts
            ? null
            : {
                  invalidStrArrayNumbers: {
                      valid: false
                  }
              }
    }
}
