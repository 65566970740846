<generic-modal [buttonClicked]="openModal" [id]="modalId" (closeCrossCallback)="cancelar()">
    <modal-titulo>Compartir resultados {{ tipoEvaluacion }} {{ evaluacionInstancia.id }}</modal-titulo>
    <modal-contenido>
        <div>
            <form [formGroup]="form">
                <h4>Ingrese una dirección de correo electrónico para compartir sus resultados</h4>
                <label [attr.for]="'email'">
                    <input class="form-control" type="text" [formControlName]="'email'" name="email" id="email" />
                </label>
                <div *ngIf="form.dirty || form.touched">
                    <div
                        *ngIf="form.controls.email.errors && form.controls.email.errors.required"
                        class="alert alert-danger"
                    >
                        Debe ingresar una dirección de correo electrónico
                    </div>
                    <div
                        *ngIf="form.controls.email.errors && form.controls.email.errors.email"
                        class="alert alert-danger"
                    >
                        La dirección de correo no es válida
                    </div>
                </div>
                <div *ngIf="errors.length == 0 && compartirDone" class="alert alert-success">
                    Se han enviado los resultados a la dirección {{ form.controls.email.value }}
                </div>
                <div *ngIf="errors.length > 0 && compartirDone" class="alert alert-danger">
                    Se produjo un error al intentar compartir los resultados a la dirección
                    {{ form.controls.email.value }}
                </div>
            </form>
        </div>
    </modal-contenido>
    <modal-botones>
        <cui-button buttonType="cancel" type="button" class="btn_style_2" data-dismiss="modal" (click)="cancelar()">
            Cancelar
        </cui-button>
        <cui-button type="button" class="btn_style" (click)="compartir()" [disabled]="!!form.controls.email.errors">
            Compartir
        </cui-button>
    </modal-botones>
</generic-modal>
