import { Respuesta } from "./respuestas.model"
import { Alternativa, Pregunta } from "@puntaje/nebulosa/api-services"
import { BaseModel, SaveType } from "@puntaje/shared/core"
import { EvaluacionFormaInstrumentoPregunta } from "./evaluacion_forma_instrumento_preguntas.model"

export class InstrumentoPregunta extends BaseModel {
    pregunta_id: number
    instrumento_id: number
    dificultad: number

    orden: number
    piloto: boolean
    puntuacion: number

    @SaveType(() => Alternativa) alternativa_correcta: Alternativa
    @SaveType(() => EvaluacionFormaInstrumentoPregunta)
    evaluacion_forma_instrumento_preguntas: EvaluacionFormaInstrumentoPregunta[]
    @SaveType(() => Respuesta) respuestas: Respuesta[]
    @SaveType(() => Pregunta) pregunta: Pregunta
}
