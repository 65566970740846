import { BaseModel, SaveType } from "@puntaje/shared/core"
import { GrupoUsuario } from "./grupo_usuarios.model"
import { Usuario } from "./usuarios.model"
import { Evaluacion } from "./evaluaciones.model"

export class EvaluacionUsuario extends BaseModel {
    usuario_id: number
    evaluacion_id: number

    receptor_type: string
    receptor_id: number

    @SaveType(() => GrupoUsuario) grupo_usuario: GrupoUsuario
    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => Evaluacion) evaluacion: Evaluacion
}
