import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Clasificacion } from "./clasificaciones.model"
import { Pregunta } from "./preguntas.model"

export class ClasificacionPregunta extends BaseModel {
    public static className: string = "ClasificacionPregunta"
    public clasificacion_id: number
    public pregunta_id: number
    public created_at: Date
    public updated_at: Date

    @SaveType(() => Pregunta) public pregunta: Pregunta
    @SaveType(() => Clasificacion) public clasificacion: Clasificacion

    constructor() {
        super()
    }

    public toString() {
        return this.clasificacion_id.toString()
    }
}
