import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { Rol } from "./roles.model"

export class RolUsuario extends BaseModel {
    public id: number
    public usuario_id: number
    public rol_id: number
    public estado: number

    @SaveType(() => Usuario) public usuario: Usuario
    @SaveType(() => Rol) public rol: Rol

    constructor() {
        super()
        this.usuario = new Usuario()
        this.rol = new Rol()
    }

    public toString() {
        return "(" + this.usuario_id + "," + this.rol_id + ")"
    }
}
