import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Injectable()
export class PeriodosService {
    config: typeof config = config
    periodosChange: BehaviorSubject<any> = new BehaviorSubject<any>(null)

    constructor() {}

    getPeriodo(date: Date) {
        //retorna el periodo al que pertenece una fecha
        let year = date.getFullYear()
        let currentTerm = config.plataforma.periodos.find(
            p => {
                new Date()
                const [month , day] = p.fechaInicial
                const  fechaInicial = new Date(year, month, day)
                const [month2, day2, hour, min, second] = p.fechaFinal
                const fechafinal = new Date(year, month2, day2, hour, min, second)
                return fechaInicial.getTime() <= date.getTime() && date.getTime() <= fechafinal.getTime()
            }
        )

        const periodo = currentTerm || config.plataforma.periodos[0]
        const [month, day] = periodo.fechaInicial
        const [month2, day2, hour, min, second] = periodo.fechaFinal

        return {
            label: year => periodo.label + year,
            fechaInicial: year => new Date(year, month, day),
            fechaFinal: year => new Date(year, month2, day2, hour, min, second)
        }
    }
}
