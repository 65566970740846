import { Component, OnInit, Input, ElementRef } from "@angular/core"
import { RealizarEnsayoService } from "../realizar_ensayo.service"
import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { filter } from "rxjs/operators"
import { Pregunta, ContestableTipoEnum } from "@puntaje/nebulosa/api-services"
import { NgModel } from "@angular/forms"
import { ResponderEvaluacionPropiaComponent } from "../responder-evaluacion-propia/responder-evaluacion-propia.component"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

@Component({
    selector: "widget-responder-evaluacion-propia",
    templateUrl: "./widget-responder-evaluacion-propia.component.html",
    styleUrls: ["./widget-responder-evaluacion-propia.component.scss"]
})
export class WidgetResponderEvaluacionPropiaComponent extends ResponderEvaluacionPropiaComponent implements OnInit {
    @Input() animateOnStart: boolean = true
    shrinkValueX: number
    shrinkValueY: number
    el: HTMLElement
    enableToggle: boolean = true
    @Input() animateOnStartTrigger: boolean = false

    constructor(realizarEnsayoService: RealizarEnsayoService, store: Store<State>, el: ElementRef) {
        super(realizarEnsayoService, store)
        this.el = el.nativeElement
    }

    toggleComponent() {
        if (this.enableToggle) {
            this.enableToggle = false
            this.showComponent = !this.showComponent
            let width = window.innerWidth > 767 ? 40 : 30
            this.shrinkValueX = !this.showComponent ? width / this.el.offsetWidth : undefined
            this.shrinkValueY = !this.showComponent ? 50 / this.el.offsetHeight : undefined
            setTimeout(() => {
                this.enableToggle = true
            }, 400)
        }
    }
}
