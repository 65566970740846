<div class="clock" *ngIf="!invisibleClock">
    <div
        class="clock-positioning"
        [class.clock-hidden]="!showClock"
        [class.collapse-on-mobile]="collapseOnMobile"
        [class.move-on-mobile]="!collapseOnMobile"
    >
        <div
            class="clock-container"
            [class.time-is-running-out]="segundosRestantes < warningTime && segundosRestantes > 0"
            [class.time-ran-out]="segundosRestantes <= 0"
            [class.starting-animation]="animateOnStart && !evaluacionTiempo"
        >
            <div
                class="clock-icon"
                (click)="toggleClock()"
                (keydown.enter)="toggleClock()"
                tabindex="0"
                aria-label="Mostrar o ocultar el temporizador"
                role="button"
                [attr.aria-expanded]="showClock"
            >
                <fa [name]="'clock-o'"></fa>
            </div>
            <div class="clock-display">
                <div class="clock-content">
                    <ng-container *ngIf="segundosRestantes > 0">
                        <span class="time" [class.show-time]="showClock" [class.hide-time]="!showClock">
                            {{ horas | number: "2.0-2" }}:{{ minutos | number: "2.0-2" }}:{{
                                segundos | number: "2.0-2"
                            }}
                        </span>
                    </ng-container>
                    <ng-container id="time_out" *ngIf="segundosRestantes == 0">
                        <span class="time_out_mensaje">{{ "widget_tiempo_ensayo.tiempo_terminado" | t }}</span>
                    </ng-container>
                </div>
                <button
                    (click)="toggleClock()"
                    type="button"
                    class="closeBtn"
                    aria-label="Ocultar el temporizador"
                    tabindex="-1"
                >
                    <fa *ngIf="showClock" name="times"></fa>
                    <fa *ngIf="!showClock" name="chevron-right"></fa>
                </button>
            </div>
        </div>
    </div>

    <alerta-evaluacion-tiempo 
        *ngIf='evaluacionTiempo'
        [evaluacionTiempo]='evaluacionTiempo'
        [segundosRestantes]='segundosRestantes' 
        [horas]='horas | number: "2.0-2"'
        [minutos]='minutos | number: "2.0-2"'
        [segundos]='segundos | number: "2.0-2"'
    >
    </alerta-evaluacion-tiempo>
</div>
