import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Injector, Input } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    UsuarioRegistroColombia,
    UsuarioRegistroColombiaForm,
    Usuarios,
    Usuario,
    Establecimiento,
    Establecimientos,
    LibroCodigoRegistro
} from "@puntaje/puntaje/api-services"
import { Clasificaciones, Clasificacion, NebuAuthService, ReporteRazones } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService, SessionService } from "@puntaje/shared/core"
import { Store } from "@ngrx/store"
import { State, SetCodigoConfirmacionData, GetStoreConfig } from "@puntaje/puntaje/store"
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { BaseRegisterComponent } from "./base-register/base-register.component"

@Component({
    selector: "usuario-registro-colombia",
    templateUrl: "register_colombia.form.component.html",
    styleUrls: ["register_colombia.component.scss"]
})
export class RegisterColombiaComponent extends BaseRegisterComponent implements OnInit {
    @Input() libro: boolean = false
    usuario: Usuario
    oUsuario: Usuario
    usuarioRegistroColombiaForm: UsuarioRegistroColombiaForm
    // params = UsuarioRegistroForm.formParams;
    params: any = UsuarioRegistroColombiaForm.formParams
    form: UntypedFormGroup
    save_button_text = "Guardar"

    saved: boolean = false

    pais: string = config.plataforma.pais
    usuarioPaisParams: any = this.params["usuario_" + this.pais].class.formParams
    usuarioPaisForm: UntypedFormGroup

    egresado: Clasificacion

    filteredEstablecimientos: Establecimiento[]
    libroRegistro: LibroCodigoRegistro
    withPoliticasPrivacidad: boolean = config.registro && config.registro.enablePoliticasPrivacidad
    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()

    constructor(
        protected usuariosService: Usuarios,
        protected clasificacionesService: Clasificaciones,
        protected establecimientosService: Establecimientos,
        protected router: Router,
        protected injector: Injector,
        protected cdr: ChangeDetectorRef,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected nebuAuthService: NebuAuthService,
        protected reporteRazonesService: ReporteRazones,
        protected store: Store<State>
    ) {
        super(usuariosService, router, null, establecimientosService)
    }

    ngOnInit() {
        if (this.libro == true) {
            this.libroRegistro = JSON.parse(localStorage.getItem("LibroCodigoRegistro"))
        }
        this.usuario = new Usuario()
        this.usuario["usuario_" + this.pais] = new Usuario["usuario_" + this.pais]()

        this.form = UsuarioRegistroColombiaForm.getForm(this.usuario, null, this.injector)
        this.usuarioPaisForm = this.form.controls["usuario_" + this.pais] as UntypedFormGroup

        this.clasificacionesService
            .where({
                clasificacion_tipo: { clasificacion_tipo: "nivel colombia" },
                clasificacion: { clasificacion: "Egresado" }
            })
            .then((clasificaciones: Clasificacion[]) => {
                this.egresado = clasificaciones[0]
            })
    }

    save() {
        UsuarioRegistroColombiaForm.markFormControlsAsTouched(this.form)
        UsuarioRegistroColombiaForm.markFormControlsAsDirty(this.form)

        if ((this.usuario as any).egresado) {
            this.form.controls["nivel_id"].setValue(this.egresado.id, { emitEvent: false })
        }

        if (this.form.valid) {
            this.usuario["is_registro"] = true
            if ((this.usuario as any).egresado) {
                this.usuario.nivel_id = this.egresado.id
            }

            if (this.usuario.establecimiento && !(this.usuario as any).egresado) {
                this.usuario.establecimiento_id = this.usuario.establecimiento.id
            }

            if (this.libro == true) {
                this.usuario["libro_codigo_registro"] = this.libroRegistro
            }

            this.usuariosService.enableIgnoreModel()
            this.usuariosService.save(this.usuario).then(response => {
                // this.onTitleChange.emit('¡Ya casi estás listo!');
                // this.form.clearValidators();
                // this.saved = true
                this.usuariosService.disableIgnoreModel()
                const verifyFromNomina = false
                return this.loginGeneral(this.authService.loginRegistroOPassword(response), verifyFromNomina)
            })
        }
    }

    loginGeneral(response, verifyFromNomina = true) {
        let res = response
        let redirect

        this.nebuAuthService.setToken(res.nebu_token)
        this.reporteRazonesService.loadReporteRazones()
        this.store.dispatch(new GetStoreConfig())

        if (this.sessionService.isFromNomina() && verifyFromNomina) {
            this.router.navigate(["/usuarios/" + res.user.id + "/completar_registro"])
            return
        }

        // this.invalidLogin = false
        // this.setMessage()

        let host = window.location.hostname
        var new_host = host

        let roles = this.sessionService.getRoles()
        // let permisos = this.authService.getPermisos()
        let queryParams = { queryParams: { typoEnvio: res.user.tipo_envio } }

        if (config.plataforma.name == "Puntaje Nacional" || config.plataforma.name == "Mineduc") {
            if (res.confirmed_at && !this.sessionService.isFromNomina()) {
                redirect = this.authService.redirectUrl ? this.authService.redirectUrl : "/home"
            } else {
                this.store.dispatch(
                    new SetCodigoConfirmacionData({
                        codigoConfirmacionData: {
                            id: res.user.id,
                            email: res.user.email,
                            tipo_envio: res.user.tipo_envio
                        }
                    })
                )
                redirect = "usuarios/registrado"
            }
        } else {
            redirect = this.authService.redirectUrl ? this.authService.redirectUrl : "/home"
        }

        let token = response["token"]
        let nebuToken = res.nebu_token
        let user = response["user"]
        if (environment.production) {
            // Get the redirect URL from our auth service
            // If no redirect has been set, use the default
            let prefix = /^(www|alumnos|docentes|admin)/g

            let docentesDomain = ""
            let alumnosDomain = ""
            let adminDomain = ""

            if (environment.domain) {
                docentesDomain = environment.domain.profesores || ""
                alumnosDomain = environment.domain.alumnos || ""
                adminDomain = environment.domain.admin || ""
            }

            let getPrefixRegex = /^([^\.]+)\./
            let docentesPrefixMatch = docentesDomain.match(getPrefixRegex)
            let alumnosPrefixMatch = alumnosDomain.match(getPrefixRegex)
            let adminPrefixMatch = adminDomain.match(getPrefixRegex)

            let docentesPrefix = docentesPrefixMatch ? docentesPrefixMatch[1] : "www"
            let alumnosPrefix = alumnosPrefixMatch ? alumnosPrefixMatch[1] : "alumnos"
            let adminPrefix = adminPrefixMatch ? adminPrefixMatch[1] : "admin"

            let alumnos = new RegExp("^" + alumnosPrefix, "g")

            if (roles.length > 0) {
                if (
                    roles.includes("SuperAdministrador") ||
                    roles.includes("Administrador") ||
                    roles.includes("Monitor")
                ) {
                    new_host = host //Que vaya a donde quiera.
                } else if (roles.includes("Docente") || roles.includes("DocenteDemo")) {
                    if (prefix.test(host)) {
                        new_host = host.replace(/^([a-z]+)\./g, docentesPrefix + ".")
                    } else {
                        new_host = docentesPrefix + "." + host
                    }
                } else {
                    if (config.plataforma.name != "Graduate")
                        if (prefix.test(host)) {
                            new_host = host.replace(/^([a-z]+)\./g, alumnosPrefix + ".")
                        } else {
                            new_host = alumnosPrefix + "." + host
                        }
                }
            } else {
                //Esto no debería pasar nunca
                new_host = host.replace(/^([a-z]+)\./g, alumnosPrefix + ".")
            }
        }
        // Redirect the user
        if (host == new_host) {
            if (config.plataforma.name == "Puntaje Nacional") {
                this.router.navigate([redirect], queryParams)
            } else {
                this.router.navigate([redirect])
            }
        } else {
            this.authService.logout() // Si lo redirijo, entonces no tengo que estar logueado acá.
            window.location.href =
                "http://" + new_host + redirect.replace(host, "") + "?token=" + token + "&nebu_token=" + nebuToken
        }

        return res
    }

    back() {
        this.router.navigate([""])
    }

    whereGetEstablecimientos(establecimientoFilter: string): any {
        return {
            per: 100,
            raw: 1,
            establecimiento: { establecimiento: establecimientoFilter },
            lugar_tipo: { lugar_tipo: ["Municipio", "Departamento"] }
        }
    }
}
