<ng-template [ngIf]="!listaView">
    <div
        class="columna-evaluaciones"
        *ngIf="evaluacionMultipleInstanciasByEvaluacionMultipleId && evaluacionInstanciasByEvaluacionMultipleId"
    >
        <ng-container *ngFor="let evaluacionMultiple of evaluacionMultiples">
            <unlocked-instrumento-cb-row
                [evaluacionMultiple]="evaluacionMultiple"
                [evaluacionMultipleInstancia]="
                    evaluacionMultipleInstanciasByEvaluacionMultipleId[evaluacionMultiple.id]
                "
                [evaluacionInstancias]="evaluacionInstanciasByEvaluacionMultipleId[evaluacionMultiple.id]"
                [locked]="false"
                class="evaluacion"
            ></unlocked-instrumento-cb-row>
        </ng-container>
    </div>
</ng-template>
<ng-template [ngIf]="listaView">
    <ul
        class="lista-evaluaciones"
        *ngIf="evaluacionMultipleInstanciasByEvaluacionMultipleId && evaluacionInstanciasByEvaluacionMultipleId"
    >
        <li *ngFor="let evaluacionMultiple of evaluacionMultiples">
            <unlocked-instrumento-cb-row
                [evaluacionMultiple]="evaluacionMultiple"
                [evaluacionMultipleInstancia]="
                    evaluacionMultipleInstanciasByEvaluacionMultipleId[evaluacionMultiple.id]
                "
                [evaluacionInstancias]="evaluacionInstanciasByEvaluacionMultipleId[evaluacionMultiple.id]"
                [locked]="false"
                class="evaluacion"
            ></unlocked-instrumento-cb-row>
        </li>
    </ul>
</ng-template>
