import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges } from "@angular/core"
import { Audiovisual, Audiovisuales } from "@puntaje/carreras/api-services"
import { PdfView, YoutubeVideoView, GameView, HtmlView } from "@puntaje/shared/core"

@Component({
    selector: "audiovisual-preview",
    templateUrl: "audiovisual-preview.component.html",
    styleUrls: ["audiovisual-preview.component.scss"]
})
export class AudiovisualPreviewComponent {
    @Input() audiovisual: Audiovisual
    enablePreview: boolean = false
    params: any = {}
    isVideo: boolean = false
    isFile: boolean = false
    isPdf: boolean = false
    isGame: boolean = false
    isHTML: boolean = false
    notFound: boolean = false
    @Input() id: number

    constructor(protected audiovisualesService: Audiovisuales) {
        this.params = {
            Video: { params: { label: "Reproducir", type: YoutubeVideoView, tableVisible: true }, key: "url" },
            Archivo: { params: { label: "Previsualizar", type: PdfView, tableVisible: true }, key: "url" },
            Malla: { params: { label: "Previsualizar", type: PdfView, tableVisible: true }, key: "url" }
        }
    }

    ngOnInit() {
        if (this.id) {
            this.audiovisualesService.enableIgnoreCatch()
            this.audiovisualesService
                .find(this.id)
                .then((response: Audiovisual) => {
                    this.audiovisualesService.disableIgnoreCatch()
                    this.audiovisual = response
                    this.loadPreview()
                })
                .catch(e => (this.notFound = true))
        }
    }

    loadPreview() {
        this.enablePreview = false
        if (this.audiovisual) {
            const audiovisualTipoNombre = this.audiovisual.tipoAudiovisual.tipoAudiovisual

            this.isVideo = audiovisualTipoNombre == "Video"
            this.isFile = ["Archivo", "Malla"].includes(audiovisualTipoNombre)
            this.isPdf = this.isFile && this.audiovisual.getExtension() == "pdf"
            this.isHTML = audiovisualTipoNombre == "HTML"
            setTimeout(() => {
                this.enablePreview = true
            }, 1)
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["audiovisual"]) {
            this.loadPreview()
        }
    }
}
