<div class="centered-div">
    <div class="logo-container">
        <a routerLink="/home">
            <img alt="logo" src="{{ config.app.assets.logoLarge }}" class="landing-logo" />
        </a>
    </div>
    <div>
        <register-confirmation [token]="token"></register-confirmation>
    </div>
</div>
