import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { EstablecimientoReporte } from "./establecimiento_reportes.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class EstablecimientoReportes extends PuntajeBaseService<EstablecimientoReporte> {
    tableName = "establecimiento_reportes"
    singularTableName = "establecimiento_reporte"
    modelClass = EstablecimientoReporte

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
