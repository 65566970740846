import {
    Component,
    OnInit,
    ViewChild,
    Input,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    OnDestroy
} from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignatura, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { EstadisticasEvolutivasService } from "../estadisticas_evolutivas.service"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators"
import { EvaluacionInstancias, GrupoUsuarios } from "@puntaje/puntaje/api-services"

@Component({
    templateUrl: "tabla_kpi.component.html",
    selector: "tabla-kpi",
    styleUrls: ["tabla_kpi.component.scss"]
})
export class TablaKpiComponent implements OnInit, OnChanges, OnDestroy {
    @Input() evaluacionTipo: string = config.plataforma.evaluacionDefault
    @Input() usuarioId: number = undefined
    /**
     * String que define las columnas a mostrar. Son los nombres de las columnas separados por coma.
     */
    @Input() columns: string = undefined
    /** Fecha inicio desde donde buscar estadísticas */
    @Input() fechaInicio: any = null
    /** Fecha final hasta donde buscar estadísticas */
    @Input() fechaFin: any = null
    /** Boolean que activa o no el uso de asignaturas que ya no se muestran y que
     * fueron respondidas dentro del periodo seleccionado.
     */
    @Input() useAsignaturasPeriodo: boolean = false
    @Input() establecimientoId: number

    listaAsignaturas: { [key: number]: any } = {}
    generadorAsignatura: { [key: number]: number } = {}
    estadisticasAsignatura: { [key: number]: any[] } = {}
    kpiAsignatura: { [key: number]: any } = {}
    ready: boolean = false
    percentageSign: boolean = false
    showColumns: { [key: string]: boolean } = {
        n_ensayos: true,
        ultimo_ptje: true,
        correctas: true,
        incorrectas: true,
        omitidas: true,
        maxima: true,
        promedio: true
    }
    generadores: number[]

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    sub: Subscription
    asignaturasByEvaluacionTipo: any
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(
        protected store: Store<State>,
        private estadisticasEvolutivasService: EstadisticasEvolutivasService,
        private cdr: ChangeDetectorRef,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected grupoUsuariosService: GrupoUsuarios
    ) {}

    ngOnInit() {
        this.loadingLayout.standby()
        this.configShowColumns()

        if (this.evaluacionTipo == "paa") {
            this.percentageSign = true
        }

        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            this.asignaturasByEvaluacionTipo = asignaturasByEvaluacionTipo
            this.setAsignaturas()
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes &&
            changes["usuarioId"] &&
            !changes["usuarioId"].firstChange &&
            changes["usuarioId"].currentValue !== null &&
            changes["usuarioId"].currentValue !== undefined
        ) {
            this.loadingLayout.standby()
            this.setAsignaturas()
        }
    }

    /**
     * Define las columnas a mostrar segun el input columns.
     */
    configShowColumns() {
        if (this.columns) {
            Object.keys(this.showColumns).forEach(column => {
                this.showColumns[column] = this.columns.includes(column)
            })
        }
    }

    /**
     * Concatena la lista de asignaturas (las de asignatura evaluacion tipo) con asignaturas extras
     * de evaluaciones realizadas en el periodo (fechaInicio-fechaFin) y que no ya no estén asociadas al
     * establecimiento.
     * @param lista_asignaturas Asignaturas guardadas en el store para evaluacionTipo seleccionado.
     * @returns Nueva lista de asignaturas
     */
    async includeAllAsignaturaIdsRespondidas(lista_asignaturas) {
        const asignaturaIdsRespondidas: number[] =
            await this.evaluacionInstanciasService.asignaturaIdsRespondidasByPeriodo({
                usuario_id: this.usuarioId,
                evaluacion_tipo: this.evaluacionTipo,
                fecha_inicio: this.fechaInicio,
                fecha_fin: this.fechaFin
            })

        const listaAsignaturasIds = lista_asignaturas.map(asignatura => asignatura.id)

        const asignaturasExtras = asignaturaIdsRespondidas
            .filter(asigId => !listaAsignaturasIds.includes(asigId))
            .map(
                asigId =>
                    ({
                        id: asigId,
                        confByEvaluacionTipo: {
                            [this.evaluacionTipo]: { clase: "", icono: "", abreviacion: null }
                        }
                    } as AsignaturaWithConfig)
            )

        return lista_asignaturas.concat(asignaturasExtras)
    }

    async setAsignaturas() {
        let lista_asignaturas
        if (this.establecimientoId) {
            lista_asignaturas = this.asignaturasByEvaluacionTipo[this.evaluacionTipo].filter(asignatura => {
                return (
                    asignatura.confByEvaluacionTipo[this.evaluacionTipo].predefinido ||
                    (asignatura.confByEvaluacionTipo[this.evaluacionTipo].asignatura_evaluacion_tipo_establecimientos &&
                        asignatura.confByEvaluacionTipo[this.evaluacionTipo].asignatura_evaluacion_tipo_establecimientos
                            .map(aete => aete.establecimiento_id)
                            .includes(this.establecimientoId))
                )
            })
        } else {
            lista_asignaturas =
                this.asignaturasByEvaluacionTipo[this.evaluacionTipo || config.plataforma.evaluacionDefault]
        }

        if (this.useAsignaturasPeriodo) {
            lista_asignaturas = await this.includeAllAsignaturaIdsRespondidas(lista_asignaturas)
        }

        this.estadisticasEvolutivasService
            .getAsignaturasConGeneradores(this.evaluacionTipo)
            .then((response: Asignatura[]) => {
                this.generadores = []

                lista_asignaturas.map(a => {
                    response.forEach(asig => {
                        if (asig.id == a.id) {
                            asig.asignatura_plataformas.forEach(ap => {
                                ap.generador_instrumentos.forEach(g => {
                                    this.generadorAsignatura[g.id] = a.id
                                    this.generadores.push(g.id)
                                })
                            })

                            if (
                                this.useAsignaturasPeriodo &&
                                !a.confByEvaluacionTipo[this.evaluacionTipo].abreviacion
                            ) {
                                a.confByEvaluacionTipo[this.evaluacionTipo].abreviacion = asig.asignatura
                            }
                        }
                    })
                    this.listaAsignaturas[a["id"]] = a
                })

                this.getData()
            })
    }

    async getData() {
        let grupoUsuarioId
        if (this.establecimientoId) {
            // limitar estadisticas a grupo_usuarios del establecimiento si establecimientoId existe
            const grupoUsuarioParams = {
                grupo_usuario: { establecimiento_id: this.establecimientoId },
                fecha_inicial: this.fechaInicio,
                fecha_final: this.fechaFin,
                grupo_usuario_usuario: { usuario_id: this.usuarioId, propietario: 0 }
            }
            const grupoUsuarios = await this.grupoUsuariosService.where(grupoUsuarioParams)
            grupoUsuarioId = grupoUsuarios.map(gu => gu.id).concat([null])
        }

        this.estadisticasEvolutivasService
            .getEstadisticaUsuario(this.generadores, this.usuarioId, grupoUsuarioId, this.evaluacionTipo, false)
            .then((estadisticas: any) => {
                this.estadisticasAsignatura = {}
                estadisticas.forEach(e => {
                    const asig = this.generadorAsignatura[e.generador_instrumento_id]
                    if (this.estadisticasAsignatura[asig]) {
                        this.estadisticasAsignatura[asig].push(e)
                    } else {
                        this.estadisticasAsignatura[asig] = [e]
                    }
                })

                Object.keys(this.listaAsignaturas).forEach(i => {
                    const list = this.getUniqIds(this.estadisticasAsignatura[i])
                    if (list !== null && list !== undefined && list.length > 0) {
                        let n = 0
                        let puntaje_promedio = 0
                        let ultima_fecha = new Date(0)
                        let ultimo = 0
                        let correctas = 0
                        let incorrectas = 0
                        let omitidas = 0
                        let puntaje_maximo = 0

                        list.forEach(e => {
                            n = n + e["numero_evaluaciones"]
                            puntaje_promedio = puntaje_promedio + e["numero_evaluaciones"] * e["promedio"]
                            const fecha = new Date(e["fecha_ultimo"])
                            if (ultima_fecha < fecha) {
                                ultima_fecha = fecha
                                ultimo = e["ultimo"]
                            }
                            correctas = correctas + e["correctas"]
                            incorrectas = incorrectas + e["incorrectas"]
                            omitidas = omitidas + e["omitidas"]
                            if (puntaje_maximo < e["maximo"]) {
                                puntaje_maximo = e["maximo"]
                            }
                        })
                        puntaje_promedio = puntaje_promedio / Math.max(n, 1)
                        this.kpiAsignatura[i] = [
                            n,
                            puntaje_promedio,
                            ultimo,
                            correctas,
                            incorrectas,
                            omitidas,
                            puntaje_maximo
                        ]
                    } else {
                        this.kpiAsignatura[i] = ["-", "-", "-", "-", "-", "-", "-"]
                    }
                })

                this.cdr.detectChanges()
                this.ready = true
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.sub.unsubscribe()
    }

    getUniqIds(arr) {
        const ids = []
        const result = []
        ;(arr || []).forEach(element => {
            ids.push(element._id)
            if (ids.filter(id => id.$oid === element._id.$oid).length == 1) {
                result.push(element)
            }
        })
        return result
    }
}
