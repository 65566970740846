import { Component, Input } from "@angular/core"
import { MenuItemBoxData } from "./menu_item_box.interface"

@Component({
    selector: "menu-item-box",
    templateUrl: "menu_item_box.component.html",
    styleUrls: ["menu_item_box.component.scss"]
})
export class MenuItemBoxComponent {
    @Input() menuItem: MenuItemBoxData

    callAction(event) {
        if (this.menuItem.action) {
            this.menuItem.action()
        }
    }
}
