/** @format */

import { Component, OnInit, Input } from "@angular/core"
import { Store } from "@ngrx/store"
import { selectCorrecionTabClasses, State, ChangeCorreccionTab } from "@puntaje/puntaje/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "corregir-ensayos-tabs-profesor",
    templateUrl: "./corregir-ensayos-tabs-profesor.component.html",
    styleUrls: ["./corregir-ensayos-tabs-profesor.component.scss"]
})
export class CorregirEnsayosTabsProfesorComponent implements OnInit {
    @Input() typePlatform: string
    @Input() dispatchCamara: boolean = true
    camaraActive: boolean

    constructor(protected store: Store<State>) {}

    correccionClasses$ = this.store.select(selectCorrecionTabClasses)

    ngOnInit() {
        if (config.app.name == "alumnos" && this.typePlatform != "alumnoFour") {
            if (this.dispatchCamara) this.store.dispatch(new ChangeCorreccionTab("camara"))
        } else if (this.typePlatform == "alumnoFour") {
            this.store.dispatch(new ChangeCorreccionTab("imagen"))
        } else if (config.app.name == "profesores") {
            this.store.dispatch(new ChangeCorreccionTab("imagen-multi"))
        }
    }

    selectTab(tab: string) {
        this.store.dispatch(new ChangeCorreccionTab(tab))
    }
}
