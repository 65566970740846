import { SaveType, BaseModel } from "@puntaje/shared/core"
import { PlanEstudioAplicadoInstancia } from "./plan_estudio_aplicado_instancias.model"
import { PlanEstudioContenidoInstancia } from "./plan_estudio_contenido_instancias.model"
import { Usuario } from "./usuarios.model"

export class PlanEstudioSesionAplicadaInstancia extends BaseModel {
    public plan_estudio_aplicado_instancia_id: number
    public plan_estudio_sesion_id: number
    public usuario_id: number
    public estado: number
    public avance: number
    @SaveType(() => PlanEstudioAplicadoInstancia) public plan_estudio_aplicado_instancia: PlanEstudioAplicadoInstancia
    @SaveType(() => Usuario) public usuario: Usuario
    @SaveType(() => PlanEstudioContenidoInstancia)
    public plan_estudio_contenido_instancias: PlanEstudioContenidoInstancia[]

    constructor() {
        super()
        this.plan_estudio_aplicado_instancia = new PlanEstudioAplicadoInstancia()
        this.usuario = new Usuario()
        this.plan_estudio_contenido_instancias = []
    }

    public toString() {
        return this.plan_estudio_aplicado_instancia_id + "," + this.plan_estudio_sesion_id + "," + this.usuario_id
    }
}
