import { Component } from "@angular/core"
import { View } from "./view.component"

@Component({
    selector: "booleanview",
    template: `
        <ng-template #viewLabel>{{ label }}:</ng-template>
        <ng-template #viewValue>
            <ng-template [ngIf]="value[key]">
                <span class="property_check">
                    <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
                    {{ options && options["labelTrue"] ? options["labelTrue"] : label }}
                </span>
            </ng-template>
            <ng-template [ngIf]="!value[key]">
                <span class="property_cross">
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    {{ options && options["labelFalse"] ? options["labelFalse"] : label }}
                </span>
            </ng-template>
        </ng-template>
    `,
    styleUrls: ["model_simple_view.component.scss"]
})
export class BooleanView extends View {}
