import {
    Directive,
    ElementRef,
    OnInit,
    AfterViewInit,
    HostListener,
    Output,
    EventEmitter,
    Input,
    Renderer2,
    ChangeDetectorRef
} from "@angular/core"
import { GrupoPreguntaTextoService } from "../services/grupo-pregunta-texto.service"

@Directive({
    selector: "[grupoPreguntaTexto]",
    exportAs: "grupoPreguntaTexto"
})
export class GrupoPreguntaTextoDirective {
    el: HTMLElement
    _grupoPreguntaTexto: string
    @Input() grupoPreguntaId: number
    @Input() perLines: number = 5

    initialized = false
    isHidden = false

    sentenceToLineNumber: any = {}

    constructor(
        el: ElementRef,
        protected renderer: Renderer2,
        protected cdr: ChangeDetectorRef,
        protected grupoPreguntaTextoService: GrupoPreguntaTextoService
    ) {
        this.el = el.nativeElement
    }

    @HostListener("window:resize", ["$event"]) onResize(event) {
        this.calculateLineNumbers()
    }

    @Input()
    get grupoPreguntaTexto() {
        return this._grupoPreguntaTexto
    }

    @Input() numeroPrimeraPregunta: number
    @Input() numeroUltimaPregunta: number

    set grupoPreguntaTexto(texto) {
        this._grupoPreguntaTexto = texto
        if (this._grupoPreguntaTexto && this.initialized) this.applyReplacements()
    }

    ngOnInit(): void {
        this.cdr.detectChanges()

        this.initialized = true
        this.isHidden = !this.el.offsetParent

        setTimeout(() => {
            this.applyReplacements()
        }, 100)
    }

    ngDoCheck() {
        if (!this.el.offsetParent && !this.isHidden) {
            this.isHidden = true
        } else if (this.el.offsetParent && this.isHidden) {
            this.isHidden = false
            this.calculateLineNumbers()
        }
    }

    applyReplacements() {
        const comenzarNumeracion = this._grupoPreguntaTexto.match(/\[\[comenzarNumeracion\]\]/g)
        const terminarNumeracion = this._grupoPreguntaTexto.match(/\[\[terminarNumeracion\]\]/g)
        if (Number.isInteger(this.numeroPrimeraPregunta)) {
            this._grupoPreguntaTexto = this._grupoPreguntaTexto.replace(
                "[[nPrimeraPregunta]]",
                (this.numeroPrimeraPregunta + 1).toString()
            )
        }
        if (Number.isInteger(this.numeroUltimaPregunta)) {
            this._grupoPreguntaTexto = this._grupoPreguntaTexto.replace(
                "[[nUltimaPregunta]]",
                (this.numeroUltimaPregunta + 1).toString()
            )
        }
        if (!comenzarNumeracion || !terminarNumeracion || comenzarNumeracion.length != terminarNumeracion.length) {
            this._grupoPreguntaTexto = this._grupoPreguntaTexto
                .replace("[[comenzarNumeracion]]", "")
                .replace("[[terminarNumeracion]]", "")
                .replace(/\{\{[^\}]+\}\}/g, "")
            this.renderer.setProperty(this.el, "innerHTML", this._grupoPreguntaTexto)
        } else {
            this._grupoPreguntaTexto = this._grupoPreguntaTexto.replace(
                /<p[^>]*>\[\[comenzarNumeracion\]\]<\/p>/g,
                function (match) {
                    return "<div class='numerado'>"
                }
            )

            this._grupoPreguntaTexto = this._grupoPreguntaTexto.replace(
                /<p[^>]*>\[\[terminarNumeracion\]\]<\/p>/g,
                function (match) {
                    return "</div>"
                }
            )

            this._grupoPreguntaTexto = this._grupoPreguntaTexto.replace(
                /<p([^>]*)>\[\[ignorarNumeracion\]\](.*)<\/p>/g,
                function (match, p1, p2) {
                    return `<p${p1} aria-level="3" role="heading" class="ignore">${p2}&nbsp;</p>`
                }
            )

            this._grupoPreguntaTexto = this._grupoPreguntaTexto.replace(
                /<p([^>]*)>\[\[ignorarNumeracion\]\](.*)<\/p>/g,
                function (match, p1, p2) {
                    return `<p${p1} aria-level="3" role="heading" class="ignore">${p2}&nbsp;</p>`
                }
            )

            this._grupoPreguntaTexto = this._grupoPreguntaTexto.replace(/\{\{([^\}]+)\}\}/g, function (match, p1) {
                return `<span class="palabra">${p1}</span>`
            })

            this.renderer.setProperty(this.el, "innerHTML", this._grupoPreguntaTexto)

            this.calculateLineNumbers()
        }
    }

    calculateLineNumbers() {
        this.sentenceToLineNumber = {}
        const numerados: any = this.el.querySelectorAll(".numerado")

        numerados.forEach(numerado => {
            const dummyDiv = this.renderer.createElement("div")
            this.renderer.addClass(dummyDiv, "to-delete")
            this.renderer.appendChild(numerado, dummyDiv)
            this.renderer.setProperty(dummyDiv, "innerHTML", "...")
            const lineHeight = dummyDiv.clientHeight
            this.renderer.setProperty(dummyDiv, "innerHTML", null)
            this.renderer.removeChild(numerado, dummyDiv)

            if (lineHeight == 0) return

            this.renderer.setStyle(numerado, "position", "relative")
            this.renderer.setStyle(numerado, "margin-left", "28px")

            numerado.querySelectorAll(".numeros").forEach(elem => {
                this.renderer.addClass(elem, "to-delete")
                this.renderer.removeChild(this.renderer.parentNode(elem), elem)
            })

            const toPutLines = []
            for (const child of numerado.children) {
                if (!child.classList.contains("ignore") && !child.classList.contains("to-delete")) {
                    toPutLines.push(child)
                }
            }

            let currentLine = 1
            for (const child of toPutLines) {
                const div = this.renderer.createElement("div")
                const parent = this.renderer.parentNode(child)

                this.renderer.setStyle(div, "position", "absolute")
                this.renderer.setStyle(div, "left", "-28px")
                this.renderer.addClass(div, "numeros")

                const totalHeight = child.clientHeight
                const lines = Math.floor(totalHeight / lineHeight)

                this.renderer.setProperty(
                    div,
                    "innerHTML",
                    new Array(lines)
                        .fill(0)
                        .map((_, i) => ((i + currentLine) % this.perLines == 0 ? "(" + (i + currentLine) + ")" : ""))
                        .join("<br>")
                )

                this.renderer.setStyle(child, "position", "relative")
                child.querySelectorAll("span.palabra").forEach(palabra => {
                    const palabraLine = ~~(palabra.offsetTop / lineHeight) + currentLine

                    this.sentenceToLineNumber[palabra.textContent] = palabraLine
                })

                currentLine += lines

                this.renderer.insertBefore(parent, div, child)
            }
        })
        setTimeout(
            _ => this.grupoPreguntaTextoService.setSentenceToLine(this.grupoPreguntaId, this.sentenceToLineNumber),
            50
        )
    }
}
