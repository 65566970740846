import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { AppConfig, I18nService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "evaluaciones-corregir.component.html"
})
export class EvaluacionesCorregirComponent {
    titulo: string
    tituloKey: string =
        config.app.name == "alumnos"
            ? "puntaje_core.evaluaciones_corregir.titulo_alumnos"
            : "puntaje_core.evaluaciones_corregir.titulo"
    typePlatform: string = config.app.name == "alumnos" ? "alumnoOne" : "profesor"
    perfil: string
    evaluacionId: number

    sub: Subscription
    subData: Subscription

    constructor(protected titleService: TitleService, public route: ActivatedRoute) {}

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.evaluacionId = params["id"] ? +params["id"] : undefined
        })

        this.subData = this.route.data.subscribe(data => {
            this.titulo = data.titulo ?? this.titulo
            this.typePlatform = data.typePlatform ?? this.typePlatform
            this.perfil = data.perfil ?? this.perfil
        })
    }
}
