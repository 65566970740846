import { BaseModel, Validations, SaveType, AppConfig } from "@puntaje/shared/core"
import { Establecimiento } from "./establecimientos.model"
import { GrupoUsuario } from "./grupo_usuarios.model"
import { Lugar } from "./lugares.model"
import { UsuarioChile } from "./usuario_chile.model"
import { UsuarioColombia } from "./usuario_colombia.model"
import { PreferenciaCarrera } from "./preferencia_carreras.model"
import { GrupoUsuarioUsuario } from "./grupo_usuario_usuarios.model"
import { RolUsuario } from "./rol_usuarios.model"
import { Rol } from "./roles.model"
import { PremiumUsuario } from "./premium_usuarios.model"
import { DudaUsuarioInfo } from "./duda_usuario_info.model"
import { UsuarioTec } from "./usuario_tec.model"
import { UsuarioMexico } from "./usuario_mexico.model"
import { PreferenciaArea, PreferenciaCarreraSede, PreferenciaUniversidad } from "@puntaje/carreras/api-services"
import { UsuarioPaa } from "./usuario_paa.model"
import { UsuarioBrasil } from "./usuario_brasil.model"

declare const config: AppConfig

export class Usuario extends BaseModel {
    public static global_validations = [Validations.equalPasswords(["password", "password_confirmation"])]
    public static usuario_chile = UsuarioChile
    public static usuario_colombia = UsuarioColombia
    public static usuario_mexico = UsuarioMexico
    public static usuario_brasil = UsuarioBrasil
    public activo: boolean
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public email: string
    public pasaporte: string
    public rol_id: number
    public pais_id: number
    public lugar_id: number
    public avatar: string
    public fecha_nacimiento: Date
    public confirmed_at: Date
    public nomina: boolean
    public nivel_id: number
    public establecimiento_id: number
    public telefono: string
    public asignatura_id: number
    public codigo_registro: string
    public restriccion_datos: boolean

    public password: string
    public password_confirmation: string
    public genero: number
    public facebook_id: string
    public is_pro: boolean
    public manual_reset_password: boolean

    public current_sign_in_at: Date
    public n_intentos: number

    public confirm_email_no_validation: boolean
    public skip_nombre_validation: boolean
    public skip_apellido_paterno_validation: boolean

    public tipo_envio: string
    public instagram: string

    public codigo_confirmacion: number | string
    public google_id: string
    public is_registro: boolean

    public with_carreras_token: number
    public plataforma_id: number

    public ano_egreso: string
    public actividad: string
    public email_apoderado: string
    public tipo_registro: string
    public profesor: boolean

    public count_logins: number
    public fecha_ultimo_login: any

    public fecha_inicio_plus: string
    public fecha_termino_plus: string

    public asociacion_ids: number[]
    public completed_extended_registration: boolean
    public last_updated_data: string

    public especialidad: string

    nombre_completo: string

    @SaveType(() => GrupoUsuarioUsuario) public grupo_usuario_usuarios: GrupoUsuarioUsuario[]

    @SaveType(() => UsuarioChile) public usuario_chile: UsuarioChile
    @SaveType(() => UsuarioColombia) public usuario_colombia: UsuarioColombia
    @SaveType(() => UsuarioMexico) public usuario_mexico: UsuarioMexico
    @SaveType(() => UsuarioBrasil) public usuario_brasil: UsuarioBrasil
    @SaveType(() => UsuarioTec) public usuario_tec: UsuarioTec
    @SaveType(() => UsuarioPaa) public usuario_paa: UsuarioPaa
    @SaveType(() => Establecimiento) public establecimiento: Establecimiento
    @SaveType(() => Establecimiento) public establecimiento_registro: Establecimiento
    @SaveType(() => Lugar) public lugar: Lugar
    @SaveType(() => GrupoUsuario) public grupo_usuarios: GrupoUsuario[]
    @SaveType(() => Usuario) public super_amigos: Usuario[]
    @SaveType(() => PreferenciaCarrera) public preferencia_carreras: PreferenciaCarrera[]
    @SaveType(() => PreferenciaCarreraSede) public preferencia_carrera_sedes: PreferenciaCarreraSede[]
    @SaveType(() => PreferenciaUniversidad) public preferencia_universidades: PreferenciaUniversidad[]
    @SaveType(() => PreferenciaArea) public preferencia_areas: PreferenciaArea[]
    @SaveType(() => RolUsuario) public rol_usuarios: RolUsuario[]
    @SaveType(() => Rol) public roles: Rol[]
    @SaveType(() => PremiumUsuario) public premium_usuarios: PremiumUsuario[]
    @SaveType(() => DudaUsuarioInfo) dudas_valores: DudaUsuarioInfo

    constructor(empty = false) {
        super()
        if (!empty) {
            this.rol_usuarios = []
            this.premium_usuarios = []
        }
    }

    public nombreCompleto() {
        let nc = this.nombre
        if (this.apellido_paterno) {
            nc = nc + " " + this.apellido_paterno
        }
        if (this.apellido_materno && this.apellido_materno !== "null" && this.apellido_materno !== null) {
            nc = nc + " " + this.apellido_materno
        }
        return nc
    }

    public nombreCompletoApellido() {
        return `${this.apellido_paterno} ${this.apellido_materno}, ${this.nombre}`
    }

    public toString() {
        return this.nombreCompleto()
    }

    public toStringDatos() {
        let string = this.nombreCompleto()
        if (this.usuario_chile !== undefined && this.usuario_chile !== null) {
            string += " - RUT: "
            if (this.usuario_chile.rut !== undefined && this.usuario_chile.rut !== null) {
                string += this.usuario_chile.rut
            } else {
                string += "Sin especificar."
            }
        }
        if (this.usuario_colombia !== undefined && this.usuario_colombia !== null) {
            string += " - TI: "
            if (this.usuario_colombia.ti !== undefined && this.usuario_colombia.ti !== null) {
                string += this.usuario_colombia.ti
            } else {
                string += "Sin especificar."
            }
            string += " - CC: "
            if (this.usuario_colombia.cc !== undefined && this.usuario_colombia.cc !== null) {
                string += this.usuario_colombia.cc
            } else {
                string += "Sin especificar."
            }
        }
        return string
    }

    public fillDataFromFB(data: any) {
        var newData = this
        if (data["email"]) newData["email"] = data["email"]
        if (data["first_name"]) newData["nombre"] = data["first_name"]
        if (data["last_name"]) newData["apellido_paterno"] = data["last_name"]
        if (data["gender"]) {
            if (data["gender"] == "female") newData["genero"] = 0
            if (data["gender"] == "male") newData["genero"] = 1
        }
        if (data["id"] && data["app"]) {
            if (data["app"] == "Facebook") newData["facebook_id"] = data["id"]
            newData["password"] = data["id"]
            newData["password_confirmation"] = data["id"]
        }
        return newData
    }

    public paisIdentificador() {
        let identificadores = config.plataforma.identificadorUsuario.map(id => {
            let usuario_pais = this["usuario_" + config.plataforma.pais]
            if (usuario_pais) return usuario_pais[id.toLowerCase()]
            else return null
        })
        return identificadores.filter(x => x != null)[0]
    }

    public hasSomeAsociacion(asociacionIds: number[]) {
        return this.asociacion_ids.some(id => asociacionIds.includes(id))
    }

    public isPlus() {
        if (!this.fecha_inicio_plus && !this.fecha_termino_plus) return false

        const today = new Date()

        if (this.fecha_inicio_plus && !this.fecha_termino_plus) {
            const fechaInicio = new Date(this.fecha_inicio_plus)
            return fechaInicio.getTime() <= today.getTime()
        }

        if (!this.fecha_inicio_plus && this.fecha_termino_plus) {
            const fechaTermino = new Date(this.fecha_termino_plus)
            const finDiaFechaTermino = new Date(
                fechaTermino.getFullYear(),
                fechaTermino.getMonth(),
                fechaTermino.getDate(),
                23,
                59,
                59
            )
            return finDiaFechaTermino.getTime() >= today.getTime()
        }

        const fechaInicio = new Date(this.fecha_inicio_plus)
        const fechaTermino = new Date(this.fecha_termino_plus)
        const finDiaFechaTermino = new Date(
            fechaTermino.getFullYear(),
            fechaTermino.getMonth(),
            fechaTermino.getDate(),
            23,
            59,
            59
        )

        return fechaInicio.getTime() <= today.getTime() && finDiaFechaTermino.getTime() >= today.getTime()
    }
}
