import { BaseForm, Validations, GroupValidator, DateHelper, AppConfig } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Usuarios } from "./usuarios.service"
import { UsuarioRegistroBaseChileForm } from "./usuario_registro_base_chile.form"
import { UsuarioRegistroBaseColombiaForm } from "./usuario_registro_base_colombia.form"
import { UsuarioRegistroBaseMexicoForm } from "./usuario_registro_base_mexico.form"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"
import { HttpClient } from "@angular/common/http"

declare const config: AppConfig

export class UsuarioRegistroBaseForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    companyName: string = ""

    public static get formParams(): any {
        const companyName = config.plataforma.info.companyName
        const telefonoObligatorio = config.registro.enableTelefono && config.registro.telefonoObligatorio
        const fechaNacimientoObligatorio =
            config.registro.enableFechaNacimiento && config.registro.fechaNacimientoObligatorio
        const nivelObligatorio = config.registro.enableNivel && config.registro.nivelObligatorio
        const nombresObligatorio = config.registro.nombresObligatorio
        const apellidoPaternoObligatorio = config.registro.apellidoPaternoObligatorio
        const apellidoMaternoObligatorio = config.registro.apellidoMaternoObligatorio

        const params: any = {
            nombre: {
                label: "Nombres",
                type: "text",
                visible: true,
                placeholder: "Nombres",
                validations: [nombresObligatorio ? Validators.required : null, Validators.minLength(2)].filter(x => !!x)
            },
            apellido_paterno: {
                label: "Apellido paterno",
                type: "text",
                visible: true,
                placeholder: "Apellido paterno",
                validations: [apellidoPaternoObligatorio ? Validators.required : null, Validators.minLength(2)].filter(
                    x => !!x
                )
            },
            apellido_materno: {
                label: "Apellido materno",
                type: "text",
                visible: true,
                placeholder: "Apellido materno",
                validations: [apellidoMaternoObligatorio ? Validators.required : null, Validators.minLength(2)].filter(
                    x => !!x
                )
            },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                placeholder: "correo@mail.com",
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmail }]
            },
            password: {
                label: "Contraseña",
                type: "password",
                visible: true,
                placeholder: "Contraseña",
                validations: [Validators.required, Validators.minLength(8)],
                serviceValidators: [{ class: HttpClient, validator: Validations.checkPasswordBreach }]
            },
            password_confirmation: {
                label: "Confirmar contraseña",
                type: "password",
                visible: true,
                placeholder: "Repetir contraseña",
                global: true,
                validations: [Validators.required, Validators.minLength(8)]
            },
            terminos_y_condiciones: {
                innerhtml:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de " +
                    companyName +
                    "</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.required],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            },
            telefono: {
                label: "Teléfono celular",
                type: "text",
                visible: true,
                placeholder: "981234567",
                validations: [
                    Validations.validateTelefonoChile,
                    telefonoObligatorio ? Validators.required : null
                ].filter(x => !!x)
            },
            nivel_id: {
                label: "Curso",
                type: "select",
                visible: true,
                options: {
                    class: Clasificaciones,
                    params: {
                        clasificacion: {
                            clasificacion: Object.values(config.evaluaciones || {})
                                .map(tipoEvaluacionConfig =>
                                    tipoEvaluacionConfig.cursos?.map(curso => curso.clasificacion)
                                )
                                .filter(x => !!x)
                                .flat()
                                .concat("Egresado")
                        },
                        clasificacion_tipo: { clasificacion_tipo: config.plataforma.clasificacionTipoNivel },
                        sort_by: "clasificaciones.id",
                        order: "asc"
                    }
                },
                validations: [nivelObligatorio ? Validators.required : null].filter(x => !!x)
            },
            especialidad: {
                label: "Especialidad",
                type: "select",
                visible: true,
                options: {
                    class: Clasificaciones,
                    params: {
                        clasificacion_tipo: { clasificacion_tipo: "Especialidad TP" },
                        sort_by: "clasificaciones.clasificacion",
                        order: "asc"
                    }
                }
            },

            colegio: { label: "Colegio", type: "text", visible: true },
            fecha_nacimiento: {
                label: "Fecha de nacimiento",
                type: "birthdate",
                visible: true,
                min: DateHelper.firstDayYear(150),
                max: DateHelper.today(),
                validations: [fechaNacimientoObligatorio ? Validators.required : null].filter(x => !!x)
            }
        }

        const classes = {
            chile: UsuarioRegistroBaseChileForm,
            colombia: UsuarioRegistroBaseColombiaForm,
            mexico: UsuarioRegistroBaseMexicoForm
        }

        params["usuario_" + config.plataforma.pais] = { type: "model", class: classes[config.plataforma.pais] }

        return params
    }
}
