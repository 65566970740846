import { PuntajeMaterialesModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule } from "@puntaje/shared/core"

//import { MaterialesAsignaturasComponent } from './materiales_asignaturas.component';
import { HomeBibliotecaComponent } from "./home_biblioteca.component"

import { materialesRouting } from "./alumnos_materiales.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AlumnosLayoutsModule,
        MaterialesModule,
        materialesRouting,
        Angular2FontawesomeModule,
        SharedModule,
        UtilModule,
        CUILayoutsModule,
        PuntajeMaterialesModule
    ],
    declarations: [HomeBibliotecaComponent],
    exports: []
})
export class AlumnosMaterialesModule {}
