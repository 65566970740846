import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { FacebookService, LoginResponse, LoginOptions, InitParams, UIParams } from "@ogr-sa/ngx-facebook"
import { GoogleLoginProvider, AuthService as SocialAuthService } from "angularx-social-login"

import { Subject } from "rxjs"
import { SessionService } from "../session/session.service"
import { AppConfig } from "../conf/app_config"
import { AppEnv } from "../conf/app_env"

declare let ga: any

function getWindow(): any {
    return window
}

@Injectable()
export class AuthService {
    redirectUrl: string
    userProfile: string = "profile1"
    authTokenName: string = "auth_token_nebuloso"
    authUriPath: string = "/authenticate"
    apiTokenKeyName: string = "auth_token"
    apiUserKey: string = "user"
    oauthData: any = {}

    private window

    public profileChanged: Subject<any> = new Subject()

    constructor(
        protected http: HttpClient,
        protected fb: FacebookService,
        protected socialAuthService: SocialAuthService,
        protected sessionService: SessionService,
        protected config: AppConfig,
        protected environment: AppEnv
    ) {
        if (!this.isLoggedIn()) {
            this.userProfile = ""
        }

        const initParams: InitParams = {
            appId: this.environment.facebook?.appId,
            xfbml: true,
            version: this.environment.facebook?.apiVersion
        }
        if (this.fb && initParams.appId && initParams.version) {
            this.fb.init(initParams)
        }
        this.window = getWindow()
        this.profileChanged.next(this.userProfile)
    }

    private options: LoginOptions = {
        scope: "public_profile,email",
        return_scopes: true,
        enable_profile_selector: true
    }

    public loginWithFacebook(throwError: boolean = true) {
        return new Promise((resolve, reject) => {
            this.fb
                .login(this.options)
                .then((response: LoginResponse) => {
                    //console.log(response);
                    if (response.authResponse && response.authResponse.signedRequest) {
                        var parts = response.authResponse.signedRequest.split(".")
                        var info = JSON.parse(atob(parts[1]))
                        this.fb
                            .api(
                                "/" +
                                    info.user_id +
                                    "?fields=id,address,birthday,email,first_name,gender,last_name,middle_name"
                            )
                            .then(res => {
                                //console.log(res);
                                this.oauthData = res
                                this.oauthData["app"] = "Facebook"
                                resolve(res)
                            })
                            .catch(e => {
                                console.log(e)
                                reject(e)
                            })
                    } else {
                        console.log(response)
                        reject(new Error("Error con Facebook"))
                    }
                })
                .catch((error: any) => {
                    console.log(error)
                    if (error && error.status && throwError) {
                        reject(new Error("Error " + error.status))
                    } else {
                        reject(error)
                    }
                })
        })
    }

    public getToken() {
        return localStorage.getItem(this.authTokenName)
    }

    public setToken(token: string) {
        localStorage.setItem(this.authTokenName, token)
    }

    public isLoggedIn() {
        const conditionResetPasswordSentAt =
            this.sessionService.getResetPasswordSentAt() == null ||
            this.sessionService.getResetPasswordSentAt() == "null"

        return (
            this.getToken() != null &&
            !this.sessionService.isFromNomina() &&
            conditionResetPasswordSentAt &&
            !this.sessionService.isRecentManualResetPassword()
        ) // && conditionConfirmedAt
    }

    public getReferrer() {
        return localStorage.getItem("referrer")
    }

    public signInWithGoogle() {
        const promesa = new Promise(resolve => {
            this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(googleUser => {
                resolve(googleUser)
            })
        })
        return promesa
    }

    public refererSet() {}

    public saveReferrer() {}

    public saveRefererAndUtmParams(
        utm_source: string = null,
        utm_medium: string = null,
        utm_campaign: string = null,
        utm_term: string = null,
        utm_content: string = null
    ) {}

    public updateReferer(userId: number) {}

    public checkInitLogin = () => {
        this.http
            .get(this.environment.endpoints.base + this.config.api.checkTokenPath)
            .toPromise()
            .then(res => console.log(res))
    }

    public getAuthObject(email: string, password: string, recaptchaResponse: any = null): any {
        return { email: email.trim(), password: password, recaptcha_response: recaptchaResponse }
    }

    public getUserData() {
        const usuario = this.sessionService.getUserData()
        if (!usuario) {
            this.logout()
            return {}
        } else return usuario
    }

    public login(email: string, password: string, throwError: boolean = true, recaptchaResponse: any = null) {
        return this.loginGeneral(
            this.environment.endpoints.base + this.authUriPath,
            this.getAuthObject(email, password, recaptchaResponse),
            throwError
        )
    }

    public loginFacebook(facebook_id: string, email: string, throwError: boolean = true) {
        return this.loginGeneral(
            this.environment.endpoints.base + "/usuarios/sign_in_facebook",
            { facebook_id, email },
            throwError
        )
    }

    public loginRegistroOPassword(response) {
        let res = response
        if (res[this.apiTokenKeyName]) {
            this.setToken(res[this.apiTokenKeyName])
            if (res[this.apiUserKey]) {
                this.setUserData(res[this.apiUserKey])
                this.setAnalyticsUser((res[this.apiUserKey] as any).id)
            }

            this.setSessionValues(res)

            this.userProfile = "profile1" //esto deberia cambiarse al usuario mas adelante
            this.profileChanged.next(this.userProfile)
        }
        return response
    }

    public loginGeneral(url: string, object?: any, throwError: boolean = true, method = "post") {
        var promise: Promise<any>
        if (method == "get") {
            promise = this.http.get(url).toPromise()
        } else {
            promise = this.http.post(url, object).toPromise()
        }

        return promise
            .then((response: any) => {
                let res = response
                // Para desviar a docentes con establecimientos inactivos
                let rs = res["roles"] ? res["roles"].map(r => r.rol) : []
                const isDocente = rs.includes("Docente")
                if (
                    isDocente &&
                    this.config.mensajesLogin &&
                    this.config.mensajesLogin.invalidLoginInactiveEstablishments &&
                    this.config.mensajesLogin.invalidLoginInactiveEstablishments.length > 0
                ) {
                    if (isDocente && res.establecimientos.length == 0 && res.is_grupo_usuario_activo == false) {
                        response.inactiveEstablishments = true
                        console.log("inactivo")
                        throw response
                    }
                }

                if (res[this.apiTokenKeyName]) {
                    this.setToken(res[this.apiTokenKeyName])
                    if (res[this.apiUserKey]) {
                        this.setUserData(res[this.apiUserKey])
                        this.setAnalyticsUser((res[this.apiUserKey] as any).id)
                    }

                    this.setSessionValues(res)

                    this.userProfile = "profile1" //esto deberia cambiarse al usuario mas adelante
                    this.profileChanged.next(this.userProfile)
                }
                return response
            })
            .catch(response => {
                if (response && response.status && throwError) {
                    throw new Error("Error " + response.status)
                } else {
                    throw response
                }
            })
    }

    setSessionValues(res) {
        this.sessionService.setEstablecimientos(res.establecimientos)
        this.sessionService.setAsociaciones(res.asociaciones)

        let roles = res["roles"] ? res["roles"].map(r => r.rol) : []
        let permisos = res["permisos"] ? res["permisos"].map(p => p.permiso) : []
        let perfiles = res["perfiles"] ? res["perfiles"].map(p => p.perfil) : []

        this.sessionService.setPerfiles(perfiles)
        this.sessionService.setRoles(roles)
        this.sessionService.setPermisos(permisos)
        this.sessionService.setPro(res["extras"])
        this.sessionService.setConfirmedAt(res["confirmed_at"])
        this.sessionService.setResetPasswordSentAt(res["reset_password_sent_at"])
        this.sessionService.setGrupoUsuariosActivos(res["grupo_usuarios_activos"])
        this.sessionService.setNivelesActivos(res["niveles_activos"])
        this.sessionService.setIsFreeUser(res["is_free_user"])
    }

    public setAnalyticsUser(userId) {
        if (typeof ga !== "undefined") {
            ga("set", "userId", userId)
        }
    }

    public beforeLogout() {}

    public logout() {
        this.beforeLogout()

        const regexEvaluacionTiempo = /ensayoTiempoId/
        const regexAlternativaIds = /alternativaIds/
        const regexRedirectAfterRegister = /redirectAfterRegister/
        const regexCookies = /cookiesAccepted/
        const planPersonalDoing = /plan_personal_/
        const carrerasTerminosModal = /carrerasTerminosModal/
        const utmParams = /utmParams/

        for (const key in localStorage as any) {
            if (
                !regexEvaluacionTiempo.test(key) &&
                !regexAlternativaIds.test(key) &&
                !regexRedirectAfterRegister.test(key) &&
                !regexCookies.test(key) &&
                !planPersonalDoing.test(key) &&
                !carrerasTerminosModal.test(key) &&
                !utmParams.test(key)
            ) {
                localStorage.removeItem(key)
            }
        }
        if (typeof ga !== "undefined") {
            // unset analytics userid
            ga("set", "userId", undefined)
        }
        this.userProfile = ""
        this.profileChanged.next(this.userProfile)
    }

    public shareFacebook(url: string) {
        this.fb
            .login(this.options)
            .then((response: LoginResponse) => {
                if (response.authResponse && response.authResponse.signedRequest) {
                    let params: UIParams = {
                        href: url,
                        link: url,
                        method: "share"
                    }
                    this.fb
                        .ui(params)
                        .then(res => {
                            console.log(res)
                        })
                        .catch(e => {
                            console.log(e)
                            throw e
                        })
                } else {
                    console.log(response)
                    throw new Error("Error con Facebook")
                }
            })
            .catch((error: any) => {
                console.log(error)
                throw error
            })
    }

    public showLoginMessage() {
        var user = this.getUserData()
        if (user && user.just_logged_in) {
            user.just_logged_in = false
            this.setUserData(user)
            return true
        }
        return false
    }

    public setProperty(name: string, value: any) {
        localStorage.setItem(name, value.toString())
    }

    public removeProperty(name: string) {
        localStorage.removeItem(name)
    }

    public getProperty(name: string) {
        return localStorage.getItem(name)
    }

    public getUserFromToken(token: string) {
        let payload = token.split(".")[1]
        let data = JSON.parse(window.atob(payload))
        return data["user_id"]
    }

    public setRedirectUrl(url: string) {
        this.redirectUrl = url
    }

    public setRedirectAfterRegister(url: string) {
        localStorage.setItem("redirectAfterRegister", url)
    }

    public getRedirectAfterRegister() {
        return localStorage.getItem("redirectAfterRegister")
    }

    public removeRedirectAfterRegister() {
        localStorage.removeItem("redirectAfterRegister")
    }

    //delegated to session service TODO: cambiar todas las llamadas de la app a que llamen al session service

    public getEstablecimientos() {
        return this.sessionService.getEstablecimientos()
    }

    public getGrupoUsuariosActivos() {
        return this.sessionService.getGrupoUsuariosActivos()
    }

    public setUserData(usuario) {
        this.sessionService.setUserData(usuario)
    }
}
