<div *ngIf="loadedFiltros">
    <div id="filtros-clasificaciones-container" class="filtros-clasificaciones-container" expandableContent>
        <ng-container *ngFor="let clasificacionTipo of clasificacionesByTipo | keys">
            <div class="form-group filtro-clasificacion" [hidden]="preloadedClasificaciones[clasificacionTipo]">
                <label>{{ clasificacionTipo }}</label>
                <select
                    id="{{ controlNameByClasificacionTipo[clasificacionTipo] }}"
                    class="form-control"
                    [(ngModel)]="clasificacionSelectedByTipo[clasificacionTipo]"
                    (ngModelChange)="updateClasificacion(clasificacionTipo)"
                >
                    <option [ngValue]="undefined">
                        {{ "materiales.filtros_clasificaciones_evaluacion_tipo.seleccione" | t }}
                        {{ clasificacionTipo }}--
                    </option>
                    <option
                        *ngFor="let clasificacion of clasificacionesByTipo[clasificacionTipo]"
                        [ngValue]="clasificacion"
                    >
                        {{ clasificacion.clasificacion }}
                    </option>
                </select>
            </div>
        </ng-container>
    </div>

    <a
        id="linkExpand"
        href="javascript:void(0)"
        toggleExpandable="filtros-clasificaciones-container"
        (click)="isExpanded = !isExpanded"
    >
        {{
            isExpanded
                ? ("materiales.filtros_clasificaciones_evaluacion_tipo.menos" | t)
                : ("materiales.filtros_clasificaciones_evaluacion_tipo.mas" | t)
        }}filtros
    </a>
</div>
