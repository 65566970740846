<loading-layout #loadingLayout *ngIf="showLoader"></loading-layout>
<div [class.hideContent]="showLoader">
    <div *ngIf="evaluationState == evaluationStates.Start" [@fadeIn]="animState">
        <ng-container *ngTemplateOutlet="progressButtonsUp"></ng-container>
        <div class="result-title">
            {{
                "estudiar.plan_personales.plan_personal_evaluacion.desempeno"
                    | t: { evaluacionPorcentaje: evaluacionPorcentaje }
            }}
        </div>
        <ng-container [ngTemplateOutlet]="circleResults"></ng-container>
        <ng-container *ngTemplateOutlet="progressButtonsDown"></ng-container>
    </div>
    <div *ngIf="evaluationState == evaluationStates.Evaluating" [@fadeIn]="animState">
        <evaluacion-tiempo [invisibleClock]="true"></evaluacion-tiempo>

        <evaluacion-interactiva
            #evaluacionInteractivaEnsayo
            *ngIf="evaluacion.interactivo"
            [evaluacionId]="evaluacionId"
            (onReadyEvaluacion)="onReadyEvaluacion($event)"
            (onAfterEvaluacionEntregada)="onEvaluationSent($event)"
            (onFinishedEvaluacion)="onSendEvaluation()"
            [enableReporte]="enableReporte"
            [enableEntregarEnsayo]="false"
            [enableRedirectAfterDone]="false"
        ></evaluacion-interactiva>

        <div *ngIf="!evaluacion.interactivo">
            <progreso-preguntas-evaluacion-secuencial></progreso-preguntas-evaluacion-secuencial>

            <evaluacion-secuencial
                [evaluacionId]="evaluacionId"
                [evaluacionTiempo]="evaluacionTiempo"
                [enableBtnMarkAsUncertain]="false"
                [isFromPlanPersonal]="true"
                (onReadyEvaluacion)="onReadyEvaluacion($event)"
                (onLoadClockChange)="onLoadClockChange($event)"
                (evaluacionEntregada)="onEvaluationSent($event.id)"
            ></evaluacion-secuencial>
        </div>
    </div>
    <div *ngIf="evaluationState == evaluationStates.Results" [@fadeIn]="animState">
        <ng-container *ngTemplateOutlet="progressButtonsUp"></ng-container>
        <h3 class="result-title">
            {{
                "estudiar.plan_personales.plan_personal_evaluacion.desempeno"
                    | t: { evaluacionPorcentaje: evaluacionPorcentaje }
            }}
        </h3>
        <ng-container *ngTemplateOutlet="circleResults"></ng-container>
        <ng-container *ngTemplateOutlet="progressButtonsDown"></ng-container>
        <h3 class="solution-title">{{ "estudiar.plan_personales.plan_personal_evaluacion.soluciones" | t }}</h3>
        <respuestas-ensayo
            *ngIf="evaluacionInstanciaId"
            [evaluacionInstanciaId]="evaluacionInstanciaId"
            (onEvaluacionInstanciaReady)="onEvaluationInstanciaReady($event)"
            [enableDudas]="enableDudas"
            [enableReporte]="enableReporte"
        ></respuestas-ensayo>
    </div>
</div>

<ng-template #circleResults>
    <!--Barra circular de resultados-->
    <div class="circulo-resultado">
        <div class="radial-progress" [attr.data-progress]="evaluacionPorcentajeAnimated">
            <div class="circle">
                <div class="mask full">
                    <div class="fill"></div>
                </div>
                <div class="mask half">
                    <div class="fill"></div>
                    <div class="fill fix"></div>
                </div>
                <div class="shadow"></div>
            </div>
            <div class="inset">
                <div class="percentage">
                    <div class="numbers">
                        <span>{{ evaluacionPorcentaje }}%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #progressButtonsUp>
    <!--Botones de progreso-->
    <div class="clearfix progress-btns">
        <button type="button" class="btn btn-default btn-progress pull-left" (click)="onRepeatEvaluation()">
            {{ "estudiar.plan_personales.plan_personal_evaluacion.volver_a_realizar" | t }}
            <fa name="refresh" class="icono-right"></fa>
        </button>
        <ng-content select="plan-personal-evaluacion-progress-buttons-slot-up"></ng-content>
    </div>
</ng-template>
<ng-template #progressButtonsDown>
    <!--Botones de progreso-->
    <div class="clearfix progress-btns">
        <button type="button" class="btn btn-default btn-progress pull-left" (click)="onRepeatEvaluation()">
            {{ "estudiar.plan_personales.plan_personal_evaluacion.volver_a_realizar" | t }}
            <fa name="refresh" class="icono-right"></fa>
        </button>
        <ng-content select="plan-personal-evaluacion-progress-buttons-slot-down"></ng-content>
    </div>
</ng-template>
