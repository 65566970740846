import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { LogrosPerfilComponent } from "./logros-perfil/logros-perfil.component"
import { LogrosResumenNivelComponent } from "./logros-resumen-nivel/logros-resumen-nivel.component"
import { TotalLogrosComponent } from "./total-logros/total-logros.component"
import { CUIContainersModule, CUIInputsModule, CUITextsModule } from "@puntaje/shared/cui"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { FormModule, PaginatorModule, UtilModule } from "@puntaje/shared/core"
import { LogrosRankingShowcaseboxComponent } from "./logros-ranking-showcasebox/logros-ranking-showcasebox.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { LogroComponent } from "./logro/logro.component"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { LogrosComponent } from "./logros/logros.component"
import { LogrosBuscadorComponent } from "./logros-buscador/logros-buscador.component"
import { LogrosBuscadorService } from "./logros-buscador/logros-buscador.service"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LogroEditComponent } from "./logro-edit/logro-edit.component"
import { LogroFormComponent } from "./logro-form/logro-form.component"
import { RouterModule } from "@angular/router"
import { CUITablesModule } from "@puntaje/shared/cui"

@NgModule({
    declarations: [
        LogrosPerfilComponent,
        LogroComponent,
        LogrosResumenNivelComponent,
        TotalLogrosComponent,
        LogrosComponent,
        LogrosBuscadorComponent,
        LogrosRankingShowcaseboxComponent,
        LogroEditComponent,
        LogroFormComponent
    ],
    imports: [
        CommonModule,
        CUITextsModule,
        CUIContainersModule,
        CUIInputsModule,
        ProgressbarModule,
        UtilModule,
        PaginatorModule,
        CommonsLayoutsModule,
        TooltipModule,
        Angular2FontawesomeModule,
        PaginatorModule,
        FormModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CUITablesModule
    ],
    exports: [
        LogrosPerfilComponent,
        LogroComponent,
        LogrosResumenNivelComponent,
        TotalLogrosComponent,
        LogrosComponent,
        LogrosBuscadorComponent,
        LogrosRankingShowcaseboxComponent,
        LogroEditComponent
    ],
    providers: [LogrosBuscadorService]
})
export class LogrosModule {}
