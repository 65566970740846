<ll-titulo>{{ titulo }}</ll-titulo>
<ll-subtitulo>{{ asignatura?.asignatura }}</ll-subtitulo>
<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas
        (asignaturaChanged)="onChangeAsignatura($event)"
        [soloMaterialVisible]="true"
    ></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>
<curso-select [cursos]="cursos" [(curso)]="cursoId" (cursoChange)="onChangeCurso($event)"></curso-select>
<loading-layout #innerContent>
    <materiales-colapsables-por-clasificacion
        *ngIf="asignaturaId"
        [tipoInstrumento]="tipoInstrumento"
        [cursoId]="cursoId"
        [asignaturaId]="asignaturaId"
        [enableSearch]="true"
        [rutaMaterial]="rutaMaterial"
        [openOnInit]="openOnInit"
    ></materiales-colapsables-por-clasificacion>
</loading-layout>
