import { BaseForm, I18nService, Validations } from "@puntaje/shared/core"

export class UsuarioAvatarEditForm extends BaseForm {
    public static get formParams() {
        const translatorService = this.injector?.get(I18nService)

        return {
            file: {
                label:
                    translatorService?.translate("usuarios_perfil_edit.cambiar_foto_perfil") ||
                    "Cambiar foto de perfil:",
                type: "file",
                visible: true,
                validations: [Validations.validateImage]
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
