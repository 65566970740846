import { CommonModule } from "@angular/common"
import { routing } from "./app.routing"

import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    ErrorHandler,
    Injectable,
    ModuleWithProviders,
    NgModule,
    Provider,
    Type
} from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import {
    AppConfig,
    AppEnv,
    AuthModule,
    AuthService,
    BaseModule,
    CableModule,
    ErroresModule,
    FlashMessageModule,
    Loader,
    LoaderModule,
    TranslationsModule,
    UtilModule
} from "@puntaje/shared/core"
import { Angulartics2Module } from "angulartics2"

// ngx-bootstrap
import { AccordionModule } from "ngx-bootstrap/accordion"
import { AlertModule } from "ngx-bootstrap/alert"
import { BsDropdownModule } from "ngx-bootstrap/dropdown"
import { ButtonsModule } from "ngx-bootstrap/buttons"
import { CarouselModule } from "ngx-bootstrap/carousel"
import { CollapseModule } from "ngx-bootstrap/collapse"
import { DatepickerModule } from "ngx-bootstrap/datepicker"
import { ModalModule } from "ngx-bootstrap/modal"
import { PaginationModule } from "ngx-bootstrap/pagination"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { RatingModule } from "ngx-bootstrap/rating"
import { SortableModule } from "ngx-bootstrap/sortable"
import { TabsModule } from "ngx-bootstrap/tabs"
import { TimepickerModule } from "ngx-bootstrap/timepicker"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { TypeaheadModule } from "ngx-bootstrap/typeahead"

import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from "ng-recaptcha"
import { Ng2HighchartsModule } from "@ogr-sa/ng2-highcharts"
import { LazyLoadImageModule } from "ng-lazyload-image"

import { LoaderPuntaje, PuntajeAuthGuard } from "@puntaje/puntaje/services"
import { ApiModule, PuntajeAuthService } from "@puntaje/puntaje/api-services"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { NebuModule, TipoInstrumentoDiccionario } from "@puntaje/nebulosa/api-services"
import { CarrerasApiModule } from "@puntaje/carreras/api-services"

import { EstablecimientosModule } from "@puntaje/puntaje/new-modules/establecimientos"
import { PlanPersonalesModule } from "@puntaje/puntaje/new-modules/plan-personales"
import { PlanificacionesModule } from "@puntaje/puntaje/new-modules/planificaciones"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { EstadisticasModule } from "@puntaje/puntaje/new-modules/estadisticas"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { AsociacionesModule } from "@puntaje/puntaje/new-modules/asociaciones"
import { CarrerasModule } from "@puntaje/puntaje/new-modules/carreras"
import { TypeformWidgetsModule } from "@puntaje/puntaje/new-modules/typeform-widgets"

import { AyudaModule } from "@puntaje/puntaje/new-modules/ayuda"

import { StoreModule } from "@ngrx/store"
import {
    AsignaturasEffects,
    reducers,
    HojaRespuestasEffects,
    EvaluacionesEffects,
    EvaluacionTiposEffects,
    StoreEffects
} from "@puntaje/puntaje/store"
import { StoreDevtoolsModule } from "@ngrx/store-devtools"
import { EffectsModule } from "@ngrx/effects"
import { AppComponent } from "./app.component"
import { RouterModule } from "@angular/router"
import { FacebookService } from "@ogr-sa/ngx-facebook"
import { AgmCoreModule } from "@agm/core"
import { CssConfigComponent } from "./css-config.component"

export function getProviders(config: AppConfig, environment: AppEnv): Provider[] {

    return [
        { provide: AppConfig, useFactory: () => (window as any).config },
        // { provide: AppConfig, useFactory: () => config },
        { provide: AppEnv, useValue: environment }
    ]
}

export function diccionario() {
    return { tipoInstrumento: TipoInstrumentoDiccionario.getDiccionario() }
}

export function configFactory(loader: Loader) {
    return () => {
        return loader.loadConfig()
    }
}

declare let newrelic: any

@Injectable()
export class ErrorsHandler implements ErrorHandler {
    handleError(error: Error) {
        if (typeof newrelic !== "undefined") {
            newrelic.noticeError(error)
        }
        console.error(error)
    }
}

@NgModule({
    imports: [
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyAMZ-LGWnN6RlpVHP1hxWUES1S4kbcJC8M",
            libraries: ["places"]
            /* apiKey is required, unless you are a
            premium customer, in which case you can
            use clientId
            */
        }),

        CommonModule,
        Angulartics2Module.forRoot(),

        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        DatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        PopoverModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        SortableModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        TypeaheadModule.forRoot(),
        LoaderModule.forRoot(LoaderPuntaje),
        AuthModule.forRoot({ authGuard: PuntajeAuthGuard }),
        BaseModule.forRoot(),
        ErroresModule.forRoot(),
        ApiModule.forRoot(),
        UtilModule.forRoot(),
        CableModule.forRoot(),
        CommonsLayoutsModule.forRoot(),
        NebuModule.forRoot(),
        CarrerasApiModule.forRoot(),
        CarrerasModule.services(),
        FlashMessageModule.forRoot(),
        UsuariosModule.forRoot(),
        SharedModule.forRoot(),
        EstablecimientosModule.forRoot(),
        AsociacionesModule.forRoot(),
        EstadisticasModule.services(),
        NotificacionesModule.services(),
        PlanificacionesModule.services(),
        MaterialesModule.services(),
        StoreModule.forRoot(reducers as any, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false
            }
        }),
        StoreDevtoolsModule.instrument(),
        EffectsModule.forRoot([
            AsignaturasEffects,
            HojaRespuestasEffects,
            EvaluacionesEffects,
            EvaluacionTiposEffects,
            StoreEffects
        ]),
        PlanPersonalesModule.services(),
        TypeformWidgetsModule,
        TranslationsModule.forRoot(diccionario),

        routing
    ],
    declarations: [AppComponent, CssConfigComponent],
    exports: [
        AppComponent,
        CommonsLayoutsModule,
        BrowserModule,
        BrowserAnimationsModule,
        Angular2FontawesomeModule,

        RecaptchaModule,
        Ng2HighchartsModule,
        LazyLoadImageModule,

        AyudaModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: configFactory,
            deps: [Loader],
            multi: true
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: "6Lcd3UAUAAAAAKlPXRBK7qXd51NDeBj8QQeVs4ZG",
                useValue: "es-419"
            } as RecaptchaSettings
        },
        { provide: AuthService, useClass: PuntajeAuthService },
        { provide: ErrorHandler, useClass: ErrorsHandler },
        RouterModule,
        FacebookService
    ]
})
export class PuntajeCoreModule {}
