import { Component, Input, OnInit, ElementRef, ViewChild } from "@angular/core"
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms"
import { UsuarioRegistroCB } from "@puntaje/puntaje/api-services"
import { BaseForm } from "@puntaje/shared/core"
import { Router } from "@angular/router"

@Component({
    selector: "opciones-registro-nacimiento",
    templateUrl: "./opciones-registro-nacimiento.component.html",
    styleUrls: ["./opciones-registro-nacimiento.component.scss"]
})
export class OpcionesRegistroNacimientoComponent implements OnInit {
    @Input() validarIdentificador: boolean = true

    @ViewChild("fechaNacimientoContainer", { read: ElementRef }) fechaNacimientoContainer: ElementRef

    usuarioRegistro: UsuarioRegistroCB = new UsuarioRegistroCB()
    params: any = {
        fecha_nacimiento: {
            label: "Fecha de nacimiento",
            type: "birthdate",
            visible: true,
            validations: [Validators.required, Validators.minLength(2)]
        }
    }
    form: UntypedFormGroup
    mostrarBotones: boolean = false
    mostrarMensaje: boolean = false

    constructor(protected router: Router) {}

    ngOnInit() {
        this.form = new UntypedFormGroup({
            "Fecha de nacimiento": new UntypedFormControl()
        })
    }

    validarFecha() {
        this.form.valid
        BaseForm.markFormControlsAsTouched(this.form)
        let edad = this.calcularEdad(this.usuarioRegistro.fecha_nacimiento)
        if (this.form.valid) {
            if (edad >= 13) {
                this.mostrarBotones = true
                this.mostrarMensaje = false
                localStorage.setItem("fechaNacimiento", JSON.stringify(this.usuarioRegistro.fecha_nacimiento))
            } else {
                this.mostrarMensaje = true
                this.mostrarBotones = false
            }
        } else {
            this.fechaNacimientoContainer.nativeElement.querySelector("select").focus()
        }
    }

    calcularEdad(fecha) {
        let hoy = new Date()
        let cumpleanos = new Date(fecha)
        let edad = hoy.getFullYear() - cumpleanos.getFullYear()
        let mes = hoy.getMonth() - cumpleanos.getMonth()

        if (mes < 0 || (mes === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--
        }

        return edad
    }
}
