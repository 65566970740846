<loading-layout #loadingLayout>
    <div class="row">
        <div *ngIf="showFiltro" class="col-md-3">
            <filter-list
                [values]="filtroValues"
                [labels]="filtroLabels"
                [noPaddingLeft]="true"
                (filtrosChange)="onFilterChange($event)"
            ></filter-list>
        </div>
        <div [ngClass]="{ 'col-md-12': !showFiltro, 'col-md-9': showFiltro }">
            <ul class="lista-notificaciones" *ngIf="notificaciones && notificaciones.length > 0" @listaNotificaciones>
                <li *ngFor="let notificacion of notificaciones; let i = index; trackBy: notificationId" @notificacion>
                    <ng-container [ngSwitch]="tipoNotificaciones[notificacion.id]">
                        <notificacion-plan-personal-reforzamiento
                            *ngSwitchCase="'reforzamiento'"
                            [notificacion]="notificacion"
                            (markAsNotPending)="markNotificationAsViewed($event)"
                            [enableMini]="enableMini"
                            [disableMarkButton]="!pendientes"
                        ></notificacion-plan-personal-reforzamiento>
                        <notificacion-plan-personal-tarea
                            *ngSwitchCase="'tarea'"
                            [notificacion]="notificacion"
                            (markAsNotPending)="markNotificationAsViewed($event)"
                            [enableMini]="enableMini"
                            [disableMarkButton]="!pendientes"
                        ></notificacion-plan-personal-tarea>
                        <notificacion-plan-personal-planificacion
                            *ngSwitchCase="'planificacion'"
                            [notificacion]="notificacion"
                            (markAsNotPending)="markNotificationAsViewed($event)"
                            [enableMini]="enableMini"
                            [disableMarkButton]="!pendientes"
                        ></notificacion-plan-personal-planificacion>
                        <notificacion-evaluacion
                            *ngSwitchCase="'evaluacion'"
                            [notificacion]="notificacion"
                            (markAsNotPending)="markNotificationAsViewed($event)"
                            [enableMini]="enableMini"
                            [disableMarkButton]="!pendientes"
                        ></notificacion-evaluacion>
                        <notificacion-duda
                            *ngSwitchCase="'duda'"
                            [notificacion]="notificacion"
                            (markAsNotPending)="markNotificationAsViewed($event)"
                            [enableMini]="enableMini"
                            [disableMarkButton]="!pendientes"
                        ></notificacion-duda>
                        <notificacion-pro
                            *ngSwitchCase="'pro'"
                            [notificacion]="notificacion"
                            (markAsNotPending)="markNotificationAsViewed($event)"
                            [enableMini]="enableMini"
                            [disableMarkButton]="!pendientes"
                        ></notificacion-pro>
                        <notificacion-retroalimentacion
                            *ngSwitchCase="'retroalimentacion'"
                            [notificacion]="notificacion"
                            (markAsNotPending)="markNotificationAsViewed($event)"
                            [enableMini]="enableMini"
                            [disableMarkButton]="!pendientes"
                        ></notificacion-retroalimentacion>
                        <notificacion-plan-personal-sesion-recordatorio
                            *ngSwitchCase="'plan_personal_sesion_recordatorio'"
                            [notificacion]="notificacion"
                            (markAsNotPending)="markNotificationAsViewed($event)"
                            [enableMini]="enableMini"
                            [disableMarkButton]="!pendientes"
                        ></notificacion-plan-personal-sesion-recordatorio>
                        <notificacion-plan-clase-profesor
                            *ngSwitchCase="'plan de clase profesor'"
                            [notificacion]="notificacion"
                            (markAsNotPending)="markNotificationAsViewed($event)"
                            [enableMini]="enableMini"
                            [disableMarkButton]="!pendientes"
                        ></notificacion-plan-clase-profesor>
                    </ng-container>
                </li>
            </ul>
            <p class="not-found" *ngIf="!notificaciones || (notificaciones && notificaciones.length == 0)">
                <ogr-icon [name]="'campana-o'"></ogr-icon>
                {{ "notificaciones.no_pendientes" | t }}
            </p>
            <paginator
                *ngIf="!enableMaximo && tipoEvaluacion"
                [getList]="showAll"
                [class.hidePaginator]="!notificaciones || (notificaciones && notificaciones.length == 0)"
            ></paginator>
        </div>
    </div>
</loading-layout>
