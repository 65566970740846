<div class="artes_misticas2">
    <div class="add-margin">
        <banner-show
            class="margin-target"
            [pagina]="pagina"
            [zona]="zona || 'showcasebox3'"
            [bannerTipo]="'Imagen con link'"
        ></banner-show>
    </div>
</div>
