import { Component, Input, HostBinding, ViewEncapsulation } from "@angular/core"

@Component({
    selector: "cui-button-bar,[cui-button-bar]",
    template: `
        <div class="container-wrap">
            <div class="left-side">
                <ng-content select=".left-side"></ng-content>
                <ng-content></ng-content>
            </div>
            <div class="center-side">
                <ng-content select=".center-side"></ng-content>
            </div>
            <div class="right-side">
                <ng-content select=".right-side"></ng-content>
            </div>
        </div>
    `,
    styleUrls: ["cui-button-bar.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUIButtonBarComponent {
    @HostBinding("style.marginBottom.px") @Input() marginBottom: string | number = "10"
    @HostBinding("class.cui-button-bar") useClass: boolean = true

    constructor() {}
}
