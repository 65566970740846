import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ChangeDetectorRef,
    SimpleChanges,
    OnChanges,
    ViewChild
} from "@angular/core"
import { Router } from "@angular/router"
import { select, Store } from "@ngrx/store"
import {
    SaveRespuestasActual,
    SetRespuestaIndex,
    ToggleDudaPreguntaActual,
    State,
    GrupoPreguntaItem
} from "@puntaje/puntaje/store"
import {
    Alternativa,
    GrupoPregunta,
    Pregunta,
    ContestableTipoEnum,
    Contestable,
    Taxativo,
    GeneradorInstrumento
} from "@puntaje/nebulosa/api-services"
import { GrupoPreguntaTextoService, GenericModalComponent } from "@puntaje/shared/core"
import { selectEvaluacionesSeeingIndex, selectEvaluacionesInstruccionFija } from "@puntaje/puntaje/store"
import { combineLatest, empty, fromEvent, Subscription } from "rxjs"
import { filter, map, switchMap } from "rxjs/operators"
import { RealizarEnsayoService } from "./realizar_ensayo.service"
import { FormControl, NgModel } from "@angular/forms"

@Component({
    selector: "pregunta-ensayo",
    templateUrl: "pregunta_ensayo.component.html",
    styleUrls: ["pregunta_ensayo.component.scss"]
})
export class PreguntaEnsayoComponent implements OnInit {
    @Input() pregunta: Pregunta
    @Input() grupoPreguntaItem: GrupoPreguntaItem
    @Input() index: number
    @Input() showComplains: boolean = true
    @Input() grupoPreguntaPrev: GrupoPregunta
    @Input() grupoPreguntaNext: GrupoPregunta
    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>()
    @Output() onToggleDuda: EventEmitter<any> = new EventEmitter<any>()
    @Input() onSelectPregunta: EventEmitter<any>
    @Input() tiempoInicial: Date
    @Input() enableDudaBtn: boolean = false
    @Input() enableReporte: boolean = true
    @Input() enablePreguntaId: boolean = false
    @Input() disableOmitida: boolean = false
    @Input() evaluacionTipo: string = null
    @Input() enableBtnMarkAsUncertain: boolean = false
    @Input() @HostBinding("class.compact-view") enableCompactView: boolean = false
    @Input() onlyLetras: boolean = false
    @Input() generadorInstrumento: GeneradorInstrumento

    enableFeedbackRight: boolean = false
    enableFeedbackWrong: boolean = false
    grupoPregunta: GrupoPregunta
    grupoPreguntaId: number

    contestableTipo = ContestableTipoEnum

    seeingIndex$ = this.realizarEnsayoService.seeingIndex$
    currentItem$ = this.realizarEnsayoService.currentItem$
    respuestas$ = this.realizarEnsayoService.respuestas$

    duda$ = this.realizarEnsayoService.duda$
    dudaQueryParams: any = {}

    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    instruccionFija$ = this.store.pipe(select(selectEvaluacionesInstruccionFija))

    respuestaSub: Subscription

    contenidos: Array<Taxativo | Contestable>
    respuesta: any[] = []
    contenidoIndexToContestableIndex: any

    constructor(
        protected realizarEnsayoService: RealizarEnsayoService,
        protected router: Router,
        protected store: Store<State>,
        public grupoPreguntaTextoService: GrupoPreguntaTextoService,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (this.pregunta.grupo_pregunta && this.pregunta.grupo_pregunta.id) {
            this.grupoPreguntaId = this.pregunta.grupo_pregunta.id
        }

        this.dudaQueryParams = {
            item_id: this.pregunta.id,
            item_type: "Pregunta",
            item_categoria: this.pregunta["nombre_asignatura"]
        }

        this.grupoPregunta = this.grupoPreguntaItem
            ? this.grupoPreguntaItem.grupoPregunta
            : this.pregunta.grupo_pregunta

        this.contenidos = [...this.pregunta.taxativos, ...this.pregunta.contestables].sort((a, b) => a.orden - b.orden)
        this.contenidoIndexToContestableIndex = {}

        let contestableIndex = 0
        this.contenidos.forEach((c, index) => {
            if (c instanceof Contestable) {
                this.contenidoIndexToContestableIndex[index] = contestableIndex
                contestableIndex++
            }
        })

        this.respuestaSub = this.respuestas$.pipe(map(respuestas => respuestas[this.index])).subscribe(respuesta => {
            this.respuesta = respuesta || []
        })
    }

    buildRespuesta(contenidoIndex, respuesta?) {
        const newRespuesta = [...this.respuesta]
        newRespuesta[this.contenidoIndexToContestableIndex[contenidoIndex]] = respuesta

        this.onSelectAlternativa(newRespuesta)
    }

    onSelectAlternativa(respuesta) {
        this.store.dispatch(new SetRespuestaIndex(respuesta, this.index))
        this.realizarEnsayoService.setTiempos(this.index)
        this.realizarEnsayoService.saveAlternativa()

        if (this.enableFeedbackRight) this.enableFeedbackRight = false
        if (this.enableFeedbackWrong) this.enableFeedbackWrong = false
    }

    showFeedbackRightAnswer() {
        this.enableFeedbackRight = true
    }

    showFeedbackWrongAnswer() {
        this.enableFeedbackWrong = true
    }

    toggleDuda() {
        this.store.dispatch(new ToggleDudaPreguntaActual())
        this.onToggleDuda.emit()
    }

    ngOnDestroy(): void {
        this.respuestaSub.unsubscribe()
    }
}
