import { Component, Injector } from "@angular/core"
import { BaseModel } from "../api/base.model"
import { View } from "./view.component"

@Component({
    selector: "downloadview",
    template: `
        <ng-template #viewLabel>{{ label }}:</ng-template>
        <ng-template #viewValue>
            <a [attr.href]="fun(value)" download>{{ text }}</a>
        </ng-template>
    `,
    styleUrls: ["model_simple_view.component.scss"]
})
export class DownloadView extends View {
    text: string = "Descargar"
    fun: (m: BaseModel) => string

    constructor(private injector: Injector) {
        super()
    }

    ngOnInit() {
        if (this.options !== undefined && this.options.class !== undefined) {
            let service = this.injector.get(this.options.class)
            this.fun = service.getDownloadLink
        }
    }
}
