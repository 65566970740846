import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { GeneradorInstrumento } from "./generador_instrumentos.model"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"

@Injectable()
export class GeneradorInstrumentos extends NebuBaseService<GeneradorInstrumento> {
    tableName = "generador_instrumentos"
    singularTableName = "generador_instrumento"
    protected objectQuery: GeneradorInstrumento
    modelClass = GeneradorInstrumento
    ignoreModel = true // que onda esta linea, me mata el paginador, la voy a comentar, si se cae algo mas adelante la descomentamos xD

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public filtroClasificaciones(generadorInstrumentoId: number, params: any = null) {
        return this.one(generadorInstrumentoId).concatExtra("filtro_clasificaciones").get(params)
    }

    public filtroClasificacionTiposConfiguracion(generadorInstrumentoId: number, params: any = null) {
        return this.one(generadorInstrumentoId).concatExtra("filtro_clasificacion_tipos_configuracion").get(params)
    }

    public getSubset(generadorInstrumentoId: number, params: any = null) {
        return this.one(generadorInstrumentoId).concatExtra("get_subset").get(params)
    }

    public copy(generadorInstrumentoId: number, params: any = null) {
        return this.one(generadorInstrumentoId).concatExtra("copy").post(params)
    }

    public totalFiltroClasificaciones(generadorInstrumentoId: number, params: any = null) {
        return this.one(generadorInstrumentoId).concatExtra("total_filtro_clasificaciones").get(params)
    }
}
