<div class="row">
    <div class="col-md-8">
        <loading-layout>
            <cui-section-headline>Mis top 10 carreras</cui-section-headline>
            <ng-container *ngIf="carreraSedes">
                <carreras-list-item
                    class="carrera"
                    *ngFor="let carreraSede of carreraSedes; let i = index"
                    [carreraSede]="carreraSede"
                    (changePosition)="onChangePosition($event)"
                    [changingOrder]="changingOrder"
                    [enableOrderEdit]="true"
                    [isLast]="i == carreraSedes.length - 1"
                ></carreras-list-item>
            </ng-container>
            <ng-container *ngIf="carreraSedes && carreraSedes.length == 0">
                <p class="not-found">Aún no has seleccionado tus carreras de interés.</p>
            </ng-container>
            <br />
        </loading-layout>
    </div>
    <div class="col-md-4" *ngIf="!hidePuntajesPromedio && !disablePonderaciones">
        <mis-puntajes-promedio-carreras></mis-puntajes-promedio-carreras>
    </div>
</div>
