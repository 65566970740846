import { BaseModel } from "@puntaje/shared/core"

export class UsuarioReferrer extends BaseModel {
    public referrer: string
    public usuario_id: number
    public utm_source: string
    public utm_medium: string
    public utm_campaign: string
    public utm_term: string
    public utm_content: string
    public timestamp: Date
}
