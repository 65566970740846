import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule, registerLocaleData } from "@angular/common"
import { UtilModule } from "@puntaje/shared/core"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { ModalModule } from "ngx-bootstrap/modal"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { RouterModule } from "@angular/router"
import { CUIButtonBarComponent } from "./cui-button-bar/cui-button-bar.component"
import {
    CUIShowcaseBoxComponent,
    CUIShowcaseBoxContent,
    CUIShowcaseBoxTitleIcon,
    CUIShowcaseBoxTitleText,
    CUIShowcaseBoxDataNotFound,
    CUIShowcaseBoxViewMore
} from "./cui-showcasebox/cui-showcasebox.component"
import { CUIWidgetZoneComponent } from "./cui-widget-zone/cui-widget-zone.component"
import {
    CUIInfoCardComponent,
    CUIInfoCardTitle,
    CUIInfoCardDataPairComponent,
    CUIInfoCardDataPairLabel,
    CUIInfoCardDataPairValue,
    CUIInfoCardIcon,
    CUIInfoCardArea,
    CUIInfoCardStatus,
    CUIInfoCardLeftContent,
    CUIInfoCardRightContent,
    CUIInfoCardUnderContent
} from "./cui-info-card/cui-info-card.component"
import {
    CUIAccordionComponent,
    CUIAccordionContent,
    CUIAccordionTitle,
    CUIAccordionInfo,
    CUIAccordionIconClose,
    CUIAccordionIconOpen,
    CUIAccordionIconRotating
} from "./cui-accordion/cui-accordion.component"

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        Angular2FontawesomeModule,
        ModalModule,
        ProgressbarModule,
        PopoverModule,
        TooltipModule,
        UtilModule
    ],
    declarations: [
        CUIButtonBarComponent,
        CUIShowcaseBoxComponent,
        CUIShowcaseBoxContent,
        CUIShowcaseBoxTitleIcon,
        CUIShowcaseBoxTitleText,
        CUIShowcaseBoxDataNotFound,
        CUIShowcaseBoxViewMore,
        CUIWidgetZoneComponent,
        CUIInfoCardComponent,
        CUIInfoCardTitle,
        CUIInfoCardDataPairComponent,
        CUIInfoCardDataPairLabel,
        CUIInfoCardDataPairValue,
        CUIInfoCardIcon,
        CUIInfoCardArea,
        CUIInfoCardStatus,
        CUIInfoCardLeftContent,
        CUIInfoCardRightContent,
        CUIInfoCardUnderContent,
        CUIAccordionComponent,
        CUIAccordionContent,
        CUIAccordionTitle,
        CUIAccordionInfo,
        CUIAccordionIconClose,
        CUIAccordionIconOpen,
        CUIAccordionIconRotating
    ],
    exports: [
        CUIButtonBarComponent,
        CUIShowcaseBoxComponent,
        CUIShowcaseBoxContent,
        CUIShowcaseBoxTitleIcon,
        CUIShowcaseBoxTitleText,
        CUIShowcaseBoxDataNotFound,
        CUIShowcaseBoxViewMore,
        CUIWidgetZoneComponent,
        CUIInfoCardComponent,
        CUIInfoCardTitle,
        CUIInfoCardDataPairComponent,
        CUIInfoCardDataPairLabel,
        CUIInfoCardDataPairValue,
        CUIInfoCardIcon,
        CUIInfoCardArea,
        CUIInfoCardStatus,
        CUIInfoCardLeftContent,
        CUIInfoCardRightContent,
        CUIInfoCardUnderContent,
        CUIAccordionComponent,
        CUIAccordionContent,
        CUIAccordionTitle,
        CUIAccordionInfo,
        CUIAccordionIconClose,
        CUIAccordionIconOpen,
        CUIAccordionIconRotating
    ],
    providers: []
})
export class CUIContainersModule {
    static forRoot(): ModuleWithProviders<CUIContainersModule> {
        return {
            ngModule: CUIContainersModule,
            providers: []
        }
    }
}
