import { BaseForm, Validations, GroupValidator } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Usuarios } from "./usuarios.service"

export class UsuarioColombiaForm extends BaseForm {
    companyName: string = ""
    // constructor(){
    //  super();
    //  this.companyName = config.plataforma.info.companyName;
    // }

    public static get formParams(): any {
        return {
            usuario_id: { type: "hidden", visible: true },
            cc: {
                label: "Número de identificación personal",
                type: "text",
                placeholder: "1111111111",
                validations: [Validators.required, Validations.validateTIoCC],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueCC }],
                visible: true
            },
            telefono: {
                label: "Teléfono",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateTelefonoColombia]
            }
        }
    }
}
