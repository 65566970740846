import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { S3, S3Service, PdfView, AuthService, BaseModel, BaseForm } from "@puntaje/shared/core"
import { Retroalimentaciones, Retroalimentacion } from "@puntaje/puntaje/api-services"
import { UntypedFormGroup, Validators } from "@angular/forms"

@Component({
    selector: "retroalimentacion-compartir",
    templateUrl: "./retroalimentacion-compartir.component.html",
    styleUrls: ["./retroalimentacion-compartir.component.scss"]
})
export class RetroalimentacionCompartirComponent implements OnInit {
    files: File[]
    _retroalimentacion: Retroalimentacion = new Retroalimentacion()
    wrongFileType: boolean = false
    showPreview: boolean = false
    pdfParams = { label: "Previsualizar", type: PdfView }
    nombreButton: string = "Enviar retroalimentación"
    showConfirmation: boolean = false
    showSuccess: boolean = false
    processingFeedback: boolean = false
    changeView: boolean = false
    editing: boolean = false
    form: UntypedFormGroup
    texto: string
    params = {
        texto: {
            label: "Texto",
            type: "ckeditor",
            visible: true,
            validations: [Validators.required, Validators.minLength(5), Validators.maxLength(3000)]
        }
    }

    @Input() receptorType: string
    @Input() receptorId: number
    @Input() retroalimentableType: string
    @Input() retroalimentableId: number
    @Input() showTitulo: boolean = true
    @Input() titleConfirm: string = "Confirmación"
    @Input() descripcionConfirm: string = "Confirma que el documento se ha subido correctamente con la vista previa."

    @Input()
    set receptor(receptor: { id: number; type: string }) {
        if (receptor) {
            this.receptorType = receptor.type
            this.receptorId = receptor.id
        }
    }

    @Input()
    set retroalimentable(retroalimentable: { id: number; type: string }) {
        if (retroalimentable) {
            this.retroalimentableType = retroalimentable.type
            this.retroalimentableId = retroalimentable.id
        }
    }

    @Input()
    set retroalimentacion(retroalimentacion: Retroalimentacion) {
        this.editing = false
        if (retroalimentacion) {
            this._retroalimentacion = retroalimentacion

            if (retroalimentacion.tipo == "html") {
                this._retroalimentacion.texto = retroalimentacion.retroalimentacion
            }

            this.editing = true
        }
    }
    get retroalimentacion() {
        return this._retroalimentacion
    }

    @Output() retroalimentacionChanged = new EventEmitter()

    constructor(
        public authService: AuthService,
        protected s3Service: S3Service,
        protected retroalimentacionesService: Retroalimentaciones,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = BaseForm.getForm(this.retroalimentacion, this.params) //new FormGroup({})
    }

    adaptToSize(cs: number) {
        this.changeView = cs >= 0 && cs <= 570
        this.cdr.detectChanges()
    }

    onChangeFile() {
        if (this.showSuccess) this.showSuccess = false
        if (this.showPreview) this.showPreview = false
        this.checkIfPdf()
        if (!this.wrongFileType) {
            const reader = new FileReader()

            reader.onloadend = (e: any) => {
                this.retroalimentacion.fileSrc = e.target.result
            }

            reader.readAsArrayBuffer(this.retroalimentacion.file)
        }
    }

    checkIfPdf() {
        if (this.retroalimentacion.file && (this.retroalimentacion.file.name as any).endsWith(".pdf")) {
            this.wrongFileType = false
        } else {
            this.wrongFileType = true
        }
    }

    goToShowPreview() {
        if (!this.wrongFileType) {
            this.showPreview = true
            setTimeout(() => {
                this.showPreview = true
            }, 1)
        }
    }

    cargarRetroalimentacion(tipo) {
        this.showSuccess = false
        this.processingFeedback = true
        switch (tipo) {
            case "html":
                if (this.form.valid) {
                    this.retroalimentacion.retroalimentacion = this.retroalimentacion.texto
                    this.retroalimentacion.tipo = tipo
                    this.saveRetroalimentacion()
                } else {
                    this.processingFeedback = false
                }
                break

            case "pdf":
                this.s3Service
                    .where(this.retroalimentacion.getS3Params(this.retroalimentacion.file))
                    .then((policies: S3[]) => {
                        let policy = policies as any as S3
                        this.retroalimentacion.retroalimentacion = policy["key"]
                        this.retroalimentacion.tipo = tipo
                        this.s3Service.uploadToS3(
                            policy,
                            this.retroalimentacion.file,
                            this.retroalimentacionesService.tableName,
                            this.saveRetroalimentacion
                        )
                    })
                break
            default:
                this.processingFeedback = false
        }
    }

    /** para multiples
     *  this.files = event.target.files
     * for (var i = 0, file; (file = this.files[i]); i++) {
            this.s3Service.where(this.getS3Params(file)).then((policies: S3[]) => {
                let policy = (policies as any) as S3
                this.retroalimentacion.url = policy["key"]
                this.s3Service.uploadToS3(
                    policy,
                    file,
                    this.retroalimentacionesService.tableName,
                    this.saveRetroalimentacion
                )
            })
        }
     */

    saveRetroalimentacion = () => {
        if (this.receptorId && this.receptorType && this.retroalimentableId && this.retroalimentableType) {
            if (!this.editing) {
                this.retroalimentacion.retroalimentable_type = this.retroalimentableType
                this.retroalimentacion.retroalimentable_id = this.retroalimentableId
                this.retroalimentacion.usuario_id = this.authService.getUserData().id
                this.retroalimentacion.receptor_type = this.receptorType
                this.retroalimentacion.receptor_id = this.receptorId
            }

            if (this.editing) {
                this.retroalimentacionesService
                    .update(this.retroalimentacion.id, this.retroalimentacion)
                    .then((retroalimentacion: Retroalimentacion) => {
                        this.showSuccess = true
                        this.processingFeedback = false
                        this.retroalimentacionChanged.emit(retroalimentacion)
                    })
                    .catch(e => {
                        this.processingFeedback = false
                    })
            } else {
                this.retroalimentacionesService
                    .save(this.retroalimentacion)
                    .then((retroalimentacion: Retroalimentacion) => {
                        this.showSuccess = true
                        this.processingFeedback = false
                        this.retroalimentacionChanged.emit(retroalimentacion)
                    })
                    .catch(e => {
                        this.processingFeedback = false
                    })
            }
        }
    }
}
