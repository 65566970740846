import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { Estadisticas } from "@puntaje/puntaje/api-services"
import { Asignatura, Asignaturas, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
import { select, Store } from "@ngrx/store"
import { State, selectSelectedEvaluacionTipo, selectSelectedAsignatura } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { combineLatest, Subscription } from "rxjs"

declare const config: AppConfig

@Component({
    selector: "estadisticas-simple-auto",
    templateUrl: "estadisticas_simple_auto.component.html",
    styleUrls: ["estadisticas_simple_auto.component.scss"]
})
export class EstadisticasSimpleAutoComponent implements OnInit {
    evaluacionTipo: string
    estadisticas: any
    @Input() evaluacionTipos: string[] = []

    asignatura: Asignatura

    generadorInstrumento: GeneradorInstrumento
    generadorInstrumentos: GeneradorInstrumento[]

    distribucion_ensayos_tramo_puntaje: number
    desempeno_promedio_omitidas: number
    desempeno_promedio_correctas: number
    desempeno_promedio_incorrectas: number

    enableGraphs = false
    hideSelect = false
    sub: Subscription
    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )
    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected estadisticasService: Estadisticas,
        protected authService: AuthService,
        protected asignaturasService: Asignaturas,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.sub = combineLatest(this.asignatura$, this.evaluacionTipo$).subscribe(([asignatura, evaluacionTipo]) => {
            this.asignatura = asignatura
            this.evaluacionTipo = evaluacionTipo || config.plataforma.evaluacionDefault
            this.changeAsignatura()
            //this.cdr.detectChanges()
        })
    }

    changeAsignatura() {
        this.loadingLayout.standby()
        this.enableGraphs = false
        this.generadorInstrumento = null
        this.asignaturasService
            .find(this.asignatura.id, { with_generador_instrumentos: 1, tipo_instrumento: this.evaluacionTipo })
            .then((asignatura: Asignatura) => {
                this.asignatura = asignatura
                this.generadorInstrumentos = asignatura.generador_instrumentos
                this.enableGraphs = true
            })
            .finally(() => {
                if (!this.generadorInstrumentos || this.generadorInstrumentos.length == 0) {
                    this.distribucion_ensayos_tramo_puntaje = null
                    this.desempeno_promedio_correctas = null
                    this.desempeno_promedio_incorrectas = null
                    this.desempeno_promedio_omitidas = null
                    this.enableGraphs = false
                    this.loadingLayout.ready()
                }
            })
    }

    getEstadisticas(selectedGenerador) {
        this.loadingLayout.standby()
        this.enableGraphs = false
        if (selectedGenerador) {
            this.generadorInstrumento = selectedGenerador
            this.hideSelect = true
        }
        if (this.generadorInstrumentos && this.generadorInstrumentos.length > 1 && !this.generadorInstrumento) {
            this.generadorInstrumento = this.generadorInstrumentos[0]
        }
        this.estadisticasService.enableIgnoreModel()
        this.estadisticasService
            .where({
                collection: "EstadisticaUsuario",
                estadistica: {
                    generador_instrumento_id: this.generadorInstrumento.id,
                    oficial: 1,
                    evaluacion_tipo: this.generadorInstrumento.tipo_instrumento.tipo_instrumento,
                    usuario_id: this.authService.getUserData().id,
                    formativa: false
                }
            })
            .then((estadisticas: any) => {
                this.estadisticasService.disableIgnoreModel()
                this.estadisticas = estadisticas
                if (this.estadisticas.length > 0) this.setValues()
                else {
                    this.distribucion_ensayos_tramo_puntaje =
                        this.desempeno_promedio_correctas =
                        this.desempeno_promedio_incorrectas =
                        this.desempeno_promedio_omitidas =
                            null
                }
                this.loadingLayout.ready()
                this.enableGraphs = true
            })
    }

    setValues() {
        this.distribucion_ensayos_tramo_puntaje = this.estadisticas.reduce((acc, e) => {
            Object.keys(e.distribucion_calificaciones).forEach(key => {
                const calificacion = +key.replace(",", ".")

                acc[calificacion] = acc[calificacion] || 0
                acc[calificacion] += e.distribucion_calificaciones[key]
            })

            return acc
        }, {})
        const correctas = this.estadisticas.map(e => e.correctas).reduce((acc, x) => acc + x)
        const incorrectas = this.estadisticas.map(e => e.incorrectas).reduce((acc, x) => acc + x)
        const omitidas = this.estadisticas.map(e => e.omitidas).reduce((acc, x) => acc + x)
        const numeroEvaluaciones = this.estadisticas.map(e => e.numero_evaluaciones).reduce((acc, x) => acc + x)

        this.desempeno_promedio_omitidas = ~~(omitidas / numeroEvaluaciones)
        this.desempeno_promedio_correctas = ~~(correctas / numeroEvaluaciones)
        this.desempeno_promedio_incorrectas = ~~(incorrectas / numeroEvaluaciones)
    }

    ngOnChanges(changes) {
        if (changes["evaluacionTipo"] && changes["asignaturaId"] && this.asignatura) {
            this.changeAsignatura()
        }
    }
}
