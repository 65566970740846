import { EventEmitter, Injectable, Optional } from "@angular/core"
import { CarrerasAuthService } from "@puntaje/carreras/api-services"
import { NebuAuthService, ReporteRazones } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { Subject } from "rxjs"
import { State, GetAsignaturasConfig, GetStoreConfig } from "@puntaje/puntaje/store"
import { SessionService, AuthService, AppConfig, AppEnv } from "@puntaje/shared/core"
import { Router, NavigationExtras } from "@angular/router"
import { AchievementsAuthService } from "@puntaje/achievements/api-services"
import { UsuarioPaa, UsuariosPaa } from "@puntaje/puntaje/api-services"

@Injectable({
    providedIn: "root"
})
export class Login {
    message: string
    invalidLogin: boolean = false

    delayedNavigation: () => any

    public loginMessageChanged: Subject<any> = new Subject()
    public mandatoryPasswordChange: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        protected reporteRazonesService: ReporteRazones,
        @Optional() protected carrerasAuthService: CarrerasAuthService,
        @Optional() protected achievementsAuthService: AchievementsAuthService,
        protected nebuAuthService: NebuAuthService,
        protected store: Store<State>,
        protected sessionService: SessionService,
        protected authService: AuthService,
        protected router: Router,
        protected usuarioPaaServices: UsuariosPaa,
        protected config: AppConfig,
        protected environment: AppEnv
    ) {}

    loginGeneral(pro: Promise<any>, catchFun: (response) => void, checkForm: boolean = true, isValidForm) {
        this.message = "Trying to log in ..."
        if (!checkForm || isValidForm) {
            return pro
                .then(response => {
                    let res = response

                    if (res?.user?.usuario_plataforma_externa && this.config.plataforma.name == "Graduate") {
                        this.loginMessageChanged.next(true)
                        this.authService.logout()
                    }

                    // set nebu y carreras token
                    this.setTokens(res)

                    this.reporteRazonesService.loadReporteRazones()
                    this.store.dispatch(new GetStoreConfig())

                    // redirige en casos especiales para completar datos en registro, pagar, etc
                    return this.redirectIfNecessary(res).then(redirectIfNecessary => {
                        if (redirectIfNecessary) return

                        return this.goWhereNeccesary(res)
                    })
                })
                .catch(response => {
                    console.log(response)
                    //
                    catchFun(response)
                })
        }

        return null
    }

    goWhereNeccesary(res) {
        const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : "/home"
        this.invalidLogin = false
        this.setMessage()

        let navigateOptions: NavigationExtras = {
            queryParams: { typoEnvio: res.user.tipo_envio },
            queryParamsHandling: "merge"
        }

        let token = res["token"]
        let nebuToken = res["nebu_token"]
        let carrerasToken = res["carreras_token"]

        // set host y new_host
        let host = window.location.host
        let new_host = this.setNewHost(host)

        // Redirect the user
        this.redirectUser(host, new_host, redirect, navigateOptions, token, nebuToken, carrerasToken)

        return res
    }

    executeDelayedNavigation() {
        if (this.delayedNavigation) {
            const nav = this.delayedNavigation()
            this.delayedNavigation = undefined

            return nav
        }

        return null
    }

    setTokens(res: any) {
        this.nebuAuthService.setToken(res.nebu_token)

        if (res.carreras_token && this.carrerasAuthService) {
            this.carrerasAuthService.setToken(res.carreras_token)
        }

        if (res.logros_token && this.achievementsAuthService) {
            this.achievementsAuthService.setToken(res.logros_token)
        }
    }

    redirectIfNecessary(res: any) {
        if (this.sessionService.isFromGoogleOrFacebook() && !this.sessionService.minUserFields()) {
            this.router.navigate(["/usuarios/" + res.user.id + "/completar_registro_social"])
            return Promise.resolve(true)
        }

        if (this.sessionService.isFromNomina() || !this.sessionService.minUserFields()) {
            let id = res?.user?.id ? res.user.id : res.id
            this.router.navigate(["/usuarios/" + id + "/completar_registro"])
            return Promise.resolve(true)
        }

        // Si resetearon su contraseña manualmente
        if (this.sessionService.isRecentManualResetPassword()) {
            this.delayedNavigation = () => {
                return this.goWhereNeccesary(res)
            }

            this.mandatoryPasswordChange.emit(true)
            return Promise.resolve(true)
        }

        const roles = this.sessionService.getRoles()
        const isNotAlumno =
            roles.includes("SuperAdministrador") ||
            roles.includes("Administrador") ||
            roles.includes("Monitor") ||
            roles.includes("Docente") ||
            roles.includes("DocenteDemo")

        if (!isNotAlumno && this.sessionService.shouldUpdateData() && this.config.plataforma.enableUpdateData) {
            // this.router.navigate(["/usuarios/" + res.user.id + "/actualizar_datos"])
            this.authService.redirectUrl = "/usuarios/" + res.user.id + "/actualizar_datos"
            this.goWhereNeccesary(res)
            return Promise.resolve(true)
        }

        if (
            !isNotAlumno &&
            !this.sessionService.hasCompletedExtendedRegistration() &&
            this.sessionService.shouldAskForExtendedRegistration() &&
            this.config.plataforma.enableExtendedRegistration
        ) {
            // this.router.navigate(["/usuarios/" + res.user.id + "/completar_datos"])
            this.authService.redirectUrl = "/usuarios/" + res.user.id + "/completar_datos"
            this.goWhereNeccesary(res)
            return Promise.resolve(true)
        }

        if (this.config.plataforma.name == "Tu Reto") {
            if (!this.sessionService.isPro()) {
                this.router.navigate(["/completar_pago"])
                return Promise.resolve(true)
            }
        }

        if (this.config.plataforma.usuarioPaa) {
            return this.validarUsuarioPaa(res.user.id)
        }

        return Promise.resolve(false)
    }

    validarUsuarioPaa(usuarioId) {
        let params = {
            usuario_paa: {
                usuario_id: usuarioId
            }
        }
        return this.usuarioPaaServices.where(params).then((response: UsuarioPaa[]) => {
            if (response.length == 0) {
                this.router.navigate(["/usuarios", usuarioId, "informacion_paa"])
                return true
            } else {
                return false
            }
        })
    }

    setMessage() {
        this.message = "Logged " + (this.authService.isLoggedIn() ? "in" : "out")
    }

    setNewHost(host: string): string {
        let roles = this.sessionService.getRoles()

        var new_host = host

        if (this.environment.production) {
            let docentesDomain = ""
            let alumnosDomain = ""
            let adminDomain = ""

            if (this.environment.domain) {
                docentesDomain = this.environment.domain.profesores || ""
                alumnosDomain = this.environment.domain.alumnos || ""
                adminDomain = this.environment.domain.admin || ""
            }

            if (roles.length > 0) {
                if (
                    roles.includes("SuperAdministrador") ||
                    roles.includes("Administrador") ||
                    roles.includes("Monitor")
                ) {
                    new_host = host //Que vaya a donde quiera.
                } else if (roles.includes("Docente") || roles.includes("DocenteDemo")) {
                    new_host = docentesDomain
                } else {
                    if (this.config.plataforma.name != "Graduate") new_host = alumnosDomain
                }
            } else {
                //Esto no debería pasar nunca
                new_host = alumnosDomain
            }
        }

        return new_host
    }

    redirectUser(
        host: string,
        new_host: string,
        redirect: any,
        navigateOptions: NavigationExtras,
        token: any,
        nebuToken: any,
        carrerasToken: any = undefined,
        logrosToken: any = undefined
    ) {
        if (host == new_host) {
            if (this.config.plataforma.name == "Puntaje Nacional" || this.config.plataforma.name == "Mineduc") {
                this.router.navigateByUrl(redirect, navigateOptions)
            } else {
                this.router.navigateByUrl(redirect)
            }
        } else {
            this.authService.logout() // Si lo redirijo, entonces no tengo que estar logueado acá.
            let redirectString = "http://" + new_host + redirect + "?token=" + token + "&nebu_token=" + nebuToken
            if (carrerasToken !== null && carrerasToken !== undefined) {
                redirectString += "&carreras_token=" + carrerasToken
            }

            if (logrosToken !== null && logrosToken !== undefined) {
                redirectString += "&logros_token=" + logrosToken
            }

            window.location.href = redirectString
        }
    }
}
