<loading-layout>
    <div class="container-clase" *ngIf="planPersonalSesion">
        <div class="header-clase">
            <a href="javascript:void(0)" (click)="location.back()">
                <fa name="list-alt"></fa>
                <span>&nbsp;{{ "clase_plan_clase.volver_plan" | t }}</span>
            </a>
            <span class="linea"></span>
            <div class="sesion-box">
                <div class="numero-sesion">
                    <span>{{ "clase_plan_clase.sesion" | t }} {{ planPersonalSesion.orden + 1 }}</span>
                </div>
                <div class="titulo-sesion">
                    <h2 *ngIf="planPersonalSesion.plan_personal_sesion; else defaultNombreSemana" class="titulo-clase">
                        {{ planPersonalSesion.plan_personal_sesion | capitalize }}
                    </h2>
                </div>
            </div>
            <ng-template #defaultNombreSemana>
                <h2 class="titulo-clase">{{ nombreSesion }} {{ planPersonalSesion.orden + 1 }}</h2>
            </ng-template>
            <!-- <cui-button
                *ngIf="libroUrl"
                (click)="fullscreenService.openFullscreen(libroContainer)"
                class="  btn_style"
            >
                <span>Ver libro digital&nbsp;</span>
                <ogr-icon name="libro2-o"></ogr-icon>
            </cui-button> -->
            <cui-button-link *ngIf="showVerLibro && libroUrl" class="btn_style" target="_blank" [href]="libroUrl">
                <span>{{ "clase_plan_clase.ver_libro" | t }}&nbsp;</span>
                <ogr-icon name="libro2-o"></ogr-icon>
            </cui-button-link>
        </div>

        <div class="container-preview-material">
            <div class="container-preview-caret">
                <div class="container-preview">
                    <ng-container
                        *ngFor="let planPersonalContenido of planPersonalSesion.plan_personal_contenidos; let i = index"
                    >
                        <div
                            *ngIf="shouldShowContenido[planPersonalContenido.id]"
                            #previewElem
                            (click)="
                                planPersonalContenido.id != planPersonalContenidoSelected.id && goToContenidoReal(i)
                            "
                            class="preview"
                            [class.selected]="planPersonalContenido.id == planPersonalContenidoSelected.id"
                            [ngClass]="{ inverse: config.aditionalClass }"
                        >
                            <div class="icon-preview">
                                <ogr-icon name="libro2-o"></ogr-icon>
                            </div>
                            <div class="thumbnail-preview">
                                <!-- <img
                                    *ngIf="
                                        planClaseService.getTipoMaterial(planPersonalContenido) != 'otros';
                                        else otros
                                    "
                                    [src]="planClaseService.thumbnailsContenido(planPersonalContenido)"
                                />
                                <ng-template #otros>
                                    <ogr-icon class="icono-archivo" name="archivo"></ogr-icon>
                                </ng-template> -->
                                <thumbnail-material
                                    class="icono"
                                    [material]="planPersonalContenido.material"
                                ></thumbnail-material>
                            </div>
                            <div class="nombre-material">
                                <span *ngIf="planPersonalContenido.material">
                                    {{ planPersonalContenido.material.material }}
                                </span>
                                <span *ngIf="planPersonalContenido.evaluacion">
                                    {{
                                        !planPersonalContenido.evaluacion.evaluacion
                                            ? "Evaluación " + planPersonalContenido.evaluacion.id
                                            : planPersonalContenido.evaluacion.evaluacion
                                    }}
                                </span>
                            </div>
                            <div class="contexto-material">
                                <span *ngIf="planPersonalContenido.material">
                                    {{
                                        clasificacionContextoByMaterialId[planPersonalContenido.material.id]
                                            ?.clasificacion
                                    }}
                                </span>
                            </div>
                            <div class="actividad-material">
                                <span *ngIf="planPersonalContenido.material">
                                    <ogr-icon name="libro2-o"></ogr-icon>
                                    &nbsp;{{
                                        clasificacionActividadByMaterialId[planPersonalContenido.material.id]
                                            ?.clasificacion
                                    }}
                                </span>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <!-- <div class="caret-down"><fa name="chevron-down"></fa></div> -->
            </div>

            <div class="container-material">
                <div class="header-material">
                    <a
                        class="prev"
                        href="javascript:void(0)"
                        (click)="goToContenido(planPersonalContenidoSelectedIndex - 1)"
                    >
                        <ng-container *ngIf="planPersonalContenidoSelectedIndex > 0">
                            <fa name="long-arrow-left"></fa>
                            <span>&nbsp;{{ "clase_plan_clase.anterior" | t }}</span>
                        </ng-container>
                        <!-- <ng-container *ngIf="planPersonalContenidoSelectedIndex <= 0">
                            <fa name="list-alt"></fa>
                            <span>&nbsp;{{ "clase_plan_clase.volver_plan" | t }}</span>
                        </ng-container> -->
                    </a>
                    <h2 [ngClass]="config.aditionalClass ? 'titulo-material' : 'titulo-material inverse'">
                        <span *ngIf="planPersonalContenidoSelected.material">
                            {{ planPersonalContenidoSelected.material.material }}
                        </span>
                        <span *ngIf="planPersonalContenidoSelected.evaluacion">
                            <a
                                *ngIf="
                                    planPersonalContenidoSelected.plan_personal_evaluacion_instancias &&
                                    planPersonalContenidoSelected.plan_personal_evaluacion_instancias.length > 0
                                "
                                href="{{
                                    'pruebas/instancias/' +
                                        planPersonalContenidoSelected.plan_personal_evaluacion_instancias[0]
                                            .evaluacion_instancia_id
                                }}"
                            >
                                {{
                                    !planPersonalContenidoSelected.evaluacion.evaluacion
                                        ? "Evaluación " + planPersonalContenidoSelected.evaluacion.id
                                        : planPersonalContenidoSelected.evaluacion.evaluacion
                                }}
                            </a>
                        </span>
                    </h2>
                    <a
                        class="next"
                        href="javascript:void(0)"
                        (click)="goToContenido(planPersonalContenidoSelectedIndex + 1)"
                    >
                        <ng-container
                            *ngIf="
                                planPersonalContenidoSelectedIndex <
                                planPersonalSesion.plan_personal_contenidos.length - 1
                            "
                        >
                            <span>{{ "clase_plan_clase.siguiente" | t }}&nbsp;</span>
                            <fa name="long-arrow-right"></fa>
                        </ng-container>
                        <!-- <ng-container *ngIf="
                                planPersonalContenidoSelectedIndex >=
                                planPersonalSesion.plan_personal_contenidos.length - 1
                            ">
                            <span>{{ "clase_plan_clase.volver_plan" | t }}&nbsp;</span>
                            <fa name="list-alt"></fa>
                        </ng-container> -->
                    </a>
                </div>

                <div class="material">
                    <material
                        *ngIf="enabled && planPersonalContenidoSelected.material"
                        [hideTitle]="true"
                        [enableDudas]="enableDudas"
                        [enableReporte]="enableReporte"
                        [material]="planPersonalContenidoSelected.material"
                        [showVolver]="false"
                        [isViewOnYoutubeEnabled]="false"
                        [openInFullscreen]="openInFullscreen"
                        (destroy)="onDestroyMaterial(planPersonalContenidoSelected.material)"
                        (goNext)="goToContenido(planPersonalContenidoSelectedIndex + 1, true)"
                        (goPrev)="goToContenido(planPersonalContenidoSelectedIndex - 1, true)"
                    ></material>

                    <ng-container *ngIf="enabled && planPersonalContenidoSelected.evaluacion">
                        <ng-container *ngIf="!seeingInstance">
                            <evaluacion-tiempo [invisibleClock]="true"></evaluacion-tiempo>

                            <ng-container
                                *ngIf="!enableSecuencial || planPersonalContenidoSelected.evaluacion.instrumento.propio"
                            >
                                <ng-container *ngIf="planPersonalContenidoSelected.evaluacion">
                                    <widget-evaluacion-menu-preguntas
                                        *ngIf="!planPersonalContenidoSelected.evaluacion.instrumento.propio"
                                    ></widget-evaluacion-menu-preguntas>
                                    <widget-responder-evaluacion-propia
                                        *ngIf="planPersonalContenidoSelected.evaluacion.instrumento.propio"
                                        [firstElement]="
                                            planPersonalContenidoSelected.evaluacion.instrumento.tiempo == 0
                                        "
                                        [animateOnStartTrigger]="true"
                                    ></widget-responder-evaluacion-propia>
                                </ng-container>

                                <preguntas-ensayo
                                    [evaluacionId]="planPersonalContenidoSelected.evaluacion.id"
                                    [tipoEvaluacion]="
                                        planPersonalContenidoSelected.evaluacion.evaluacion_tipo.evaluacion_tipo
                                    "
                                    [enableTimeframeAlert]="false"
                                    [displayBtnEntregarEnsayo]="false"
                                    [disableWarningOmitidas]="true"
                                    [enableRedirectAfterDone]="false"
                                ></preguntas-ensayo>

                                <div class="volver-realizar-container">
                                    <div class="separator"></div>
                                    <cui-button-link
                                        class="btn_style"
                                        (click)="entregarEvaluacion(planPersonalContenidoSelected)"
                                    >
                                        {{ "clase_plan_clase.enviar" | t }}
                                    </cui-button-link>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="enableSecuencial && !planPersonalContenidoSelected.evaluacion.instrumento.propio"
                            >
                                <progreso-preguntas-evaluacion-secuencial></progreso-preguntas-evaluacion-secuencial>

                                <evaluacion-secuencial
                                    [evaluacionId]="planPersonalContenidoSelected.evaluacion.id"
                                    [enableBtnMarkAsUncertain]="true"
                                    (evaluacionEntregada)="
                                        onEvaluacionSecuencialEntregada(planPersonalContenidoSelected, $event)
                                    "
                                ></evaluacion-secuencial>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="seeingInstance">
                            <div class="volver-realizar-container">
                                <div class="separator"></div>
                                <cui-button-link class="btn_style" (click)="seeingInstance = false">
                                    {{ "clase_plan_clase.volver_a_realizar" | t }}
                                </cui-button-link>
                            </div>

                            <respuestas-ensayo
                                [evaluacionInstanciaId]="
                                    planPersonalContenidoSelected.plan_personal_evaluacion_instancias[0]
                                        .evaluacion_instancia_id
                                "
                                [disableTabs]="true"
                            ></respuestas-ensayo>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <ng-template #libroContainer>
        <div *ngIf="libroUrl" class="libro-wrap">
            <iframe class="iframe-libro" [src]="libroUrl | trustedurl"></iframe>
            <button class="closeFullscreen" (click)="fullscreenService.closeFullscreen()">&times;</button>
        </div>
    </ng-template>
</loading-layout>
