import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { Material } from "./materiales.model"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"

@Injectable()
export class Materiales extends NebuBaseService<Material> {
    tableName = "materiales"
    singularTableName = "material"
    modelClass = Material

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public getDownloadLink = (m: Material) => {
        return m.url
    }

    public isFileLink = (m: Material) => {
        return m.isFile()
    }

    public like(materialId: number, params: any) {
        return this.one(materialId).concatExtra("like").post(params)
    }

    public dislike(materialId: number, params: any) {
        return this.one(materialId).concatExtra("dislike").post(params)
    }

    public sentiment(materialId: number, params: any) {
        return this.one(materialId).concatExtra("sentiment").get(params)
    }

    public wherePost(params: any = null, timeout: number = 30000) {
        return this.all().concatExtra("post").post(params, timeout)
    }

    public editarMasivamente() {
        this.restQuery = ""

        return this.all().concatExtra("editar_masivamente")
    }

    public getEstadisticasTiempoMaterial(id: number) {
        this.enableIgnoreModel()
        let response = this.one(id).concatExtra("get_estadisticas_tiempo_material").get()
        this.disableIgnoreModel()
        return response
    }
}
