import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { NgCircleProgressModule } from "ng-circle-progress"
import { NivelesComponent } from "./niveles/niveles.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { NivelFormComponent } from "./nivel-form/nivel-form.component"
import { FormModule, ModelViewModule, UtilModule } from "@puntaje/shared/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { NivelEditComponent } from "./nivel-edit/nivel-edit.component"
import { NivelNewComponent } from "./nivel-new/nivel-new.component"
import { NivelComponent } from "./nivel/nivel.component"
import { NivelCircleBarComponent } from "./nivel-circlebar/nivel-circlebar.component"

@NgModule({
    declarations: [
        NivelesComponent,
        NivelFormComponent,
        NivelEditComponent,
        NivelNewComponent,
        NivelComponent,
        NivelCircleBarComponent
    ],
    exports: [NivelesComponent, NivelEditComponent, NivelNewComponent, NivelComponent, NivelCircleBarComponent],
    imports: [
        CommonModule,
        CommonsLayoutsModule,
        FormModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        ModelViewModule,
        ProgressbarModule,
        NgCircleProgressModule.forRoot({})
    ]
})
export class NivelesModule {}
