import { BaseModel } from "@puntaje/shared/core"

export class BannerZona extends BaseModel {
    public id: number
    public zona: string
    public ancho: number
    public alto: number

    constructor() {
        super()
    }

    public toString() {
        return this.zona
    }
}
