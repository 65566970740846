import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { HttpClientModule } from "@angular/common/http"
import { RouterModule } from "@angular/router"
import { CUITextsModule, CUIInputsModule, CUIContainersModule } from "@puntaje/shared/cui"

import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { Angulartics2Module } from "angulartics2"

import { UtilModule, PaginatorModule } from "@puntaje/shared/core"
import { ApiModule } from "@puntaje/puntaje/api-services"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"

import { StreamingComponent } from "./streaming.component"
import { Streaming2Component } from "./streaming2.component"
import { StreamingEmbeddedChatComponent } from "./streaming_embedded_chat.component"
import { StreamingEmbeddedVideoComponent } from "./streaming_embedded_video.component"
import { StreamingLastComponent } from "./streaming_last.component"
import { StreamingShowcaseComponent } from "./streaming_showcase.component"
import { StreamingVideosListComponent } from "./streaming_videos_list.component"
import { StreamingLiveComponent } from "./streaming_live.component"
import { StreamingAdminComponent } from "./streaming_admin.component"
import { StreamingVideosListAdminComponent } from "./streaming_videos_list_admin.component"

import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { StreamingLiveSampleComponent } from "./streaming-live-sample/streaming-live-sample.component"
import { StreamingVideosComponent } from "./streaming-videos/streaming-videos.component"
import { CalendarioStreamingShowcaseboxComponent } from "./calendario-streaming/calendario-streaming-showcasebox.component"
import { StreamingHomeComponent } from "./streaming-home/streaming-home.component"
import { HorariosModule } from "@puntaje/puntaje/new-modules/horarios"

@NgModule({
    imports: [
        CommonModule,
        CommonsLayoutsModule,
        UtilModule,
        HttpClientModule,
        RouterModule,
        Angular2FontawesomeModule,
        ApiModule,
        MaterialesModule,
        BannersModule,
        HorariosModule,
        Angulartics2Module,
        SharedModule,
        PaginatorModule,
        FormsModule,
        CUIInputsModule,
        CUITextsModule,
        CUIContainersModule
    ],
    declarations: [
        StreamingEmbeddedChatComponent,
        StreamingEmbeddedVideoComponent,
        StreamingComponent,
        Streaming2Component,
        StreamingLastComponent,
        StreamingShowcaseComponent,
        StreamingVideosListComponent,
        StreamingLiveComponent,
        StreamingAdminComponent,
        StreamingVideosListAdminComponent,
        StreamingLiveSampleComponent,
        StreamingVideosComponent,
        StreamingHomeComponent,
        CalendarioStreamingShowcaseboxComponent
    ],
    exports: [
        StreamingEmbeddedChatComponent,
        StreamingEmbeddedVideoComponent,
        StreamingComponent,
        Streaming2Component,
        StreamingLastComponent,
        StreamingShowcaseComponent,
        StreamingVideosListComponent,
        StreamingLiveComponent,
        StreamingAdminComponent,
        StreamingVideosListAdminComponent,
        StreamingLiveSampleComponent,
        StreamingVideosComponent,
        StreamingHomeComponent,
        CalendarioStreamingShowcaseboxComponent
    ],
    providers: []
})
export class StreamingModule {}
