import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Banner } from "./banners.model"

export class BannerTipo extends BaseModel {
    public nombre: string
    public nombre_modelo: string
    public tabla_model: string

    @SaveType(() => Banner) banners: Banner[]

    public toString() {
        return this.nombre
    }
}
