import {
    ChangeDetectorRef,
    Component,
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    TemplateRef,
    ContentChild
} from "@angular/core"
import { ModalDirective, BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal"
import { Subscription } from "rxjs"

@Directive({ selector: "modal-titulo" })
export class GenericModalTitulo {}

@Directive({ selector: "modal-contenido" })
export class GenericModalContenido {}

@Directive({ selector: "modal-botones" })
export class GenericModalBotones {}

@Component({
    templateUrl: "generic_modal.component.html",
    selector: "generic-modal",
    styleUrls: ["generic_modal.component.scss"]
})
export class GenericModalComponent implements OnInit, OnDestroy {
    @Input() id: string
    @Input() buttonClicked: EventEmitter<boolean> = new EventEmitter<any>()
    /** @deprecated not used anymore @todo delete */
    @Input() stopReappend: boolean = false
    @Input() modalLarge: boolean
    @Input() autoHeight: boolean = false
    @Input() justifyBody: boolean = false
    @Input() unskipable: boolean = false

    @Output() closeCrossCallback: EventEmitter<any> = new EventEmitter<any>()
    @Output() onHide: EventEmitter<any> = new EventEmitter<any>()

    @ViewChild("genericModalTemplate", { static: true }) genericModalTemplate: TemplateRef<any>
    genericModalRef: BsModalRef
    genericModalConfig: ModalOptions = {}

    buildModal: boolean = false
    subscriptions: Subscription[] = []

    calledHide: boolean = true

    @ContentChild(GenericModalBotones) botones: GenericModalBotones

    constructor(private cdr: ChangeDetectorRef, private modalService: BsModalService) {}

    ngOnInit() {
        if (this.modalLarge) {
            this.genericModalConfig["class"] = "modal-lg"
        }

        this.subscriptions.push(
            this.buttonClicked.subscribe(event => {
                this.buttonPressed()
            })
        )
        this.subscriptions.push(
            this.modalService.onHide.subscribe((reason: string) => {
                if (this.genericModalRef) {
                    this.onHide.emit(reason)
                }
            })
        )

        this.subscriptions.push(
            this.modalService.onHidden.subscribe(val => {
                if (this.calledHide) {
                    this.genericModalRef = null
                }
            })
        )
        if (this.unskipable) {
            this.genericModalConfig["ignoreBackdropClick"] = true
        }
    }

    buttonPressed() {
        this.buildModal = true
        this.cdr.detectChanges()
        if (this.genericModalTemplate) {
            this.genericModalRef = this.modalService.show(this.genericModalTemplate, this.genericModalConfig)
            this.calledHide = false
            const hide = this.genericModalRef.hide
            this.genericModalRef.hide = () => {
                this.calledHide = true
                hide()
            }
        }
    }

    close() {
        if (this.genericModalTemplate && this.genericModalRef) {
            this.genericModalRef.hide()
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe()
        })
        this.subscriptions = []
    }

    closeCross() {
        this.closeCrossCallback.emit()
    }
}
