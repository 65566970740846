import { Component, OnInit, Input } from "@angular/core"
import { Alternativa } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "respuesta-completable",
    templateUrl: "./respuesta-completable.component.html",
    styleUrls: ["./respuesta-completable.component.scss"]
})
export class RespuestaCompletableComponent implements OnInit {
    @Input() respuestaId: number
    @Input() alternativas: Alternativa[]

    respuesta: Alternativa

    constructor() {}

    ngOnInit() {
        this.respuesta = this.alternativas.find(a => a.id == this.respuestaId)
    }
}
