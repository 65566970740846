import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Evaluacion } from "./evaluaciones.model"

export class LibroEvaluacion extends BaseModel {
    libro_id: number
    evaluacion_id: number
    capitulo: number
    orden: number

    @SaveType(() => Evaluacion) evaluacion: Evaluacion
}
