import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { MyDatePickerModule } from "mydatepicker"
import { CUIInputsModule, CUITextsModule } from "@puntaje/shared/cui"
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown"

import { ApiModule } from "@puntaje/puntaje/api-services"
import { UtilModule, PaginatorModule, FormModule, ModelViewModule, SorterModule } from "@puntaje/shared/core"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { PopoverModule } from "ngx-bootstrap/popover"
import { TabsModule } from "ngx-bootstrap/tabs"

import { GrupoUsuariosModule } from "@puntaje/puntaje/new-modules/grupo-usuarios"
import { EstadisticasModule } from "@puntaje/puntaje/new-modules/estadisticas"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"

import { EstablecimientoComponent } from "./establecimiento/establecimiento.component"
import { EstablecimientosComponent } from "./establecimientos/establecimientos.component"
import { EstablecimientosNewComponent } from "./establecimientos.new.component"
import { EstablecimientosEditComponent } from "./establecimientos.edit.component"
import { EstablecimientosAdministrarComponent } from "./establecimientos_administrar.component"
import { EstablecimientosBuscadorComponent } from "./establecimientos_buscador.component"
import { EstablecimientoContactosBuscadorComponent } from "./establecimiento_contactos_buscador.component"
import { EstablecimientoContactosComponent } from "./establecimiento_contactos.component"
import { EstablecimientoContactosNewComponent } from "./establecimiento_contactos.new.component"
import { EstablecimientoContactosEditComponent } from "./establecimiento_contactos.edit.component"
import { EstablecimientoContactoComponent } from "./establecimiento_contacto.component"

import { EstablecimientoReportesBuscadorComponent } from "./establecimiento_reportes.buscador.component"
import { EstablecimientoReportesComponent } from "./establecimiento_reportes.component"
import { EstablecimientoReportesNewComponent } from "./establecimiento_reportes.new.component"
import { EstablecimientoReportesEditComponent } from "./establecimiento_reportes.edit.component"
import { EstablecimientoReporteComponent } from "./establecimiento_reporte.component"
import { ResumenUtpComponent } from "./resumen_utp.component"

import { EstablecimientosBuscadorService } from "./establecimientos_buscador.service"
import { EstablecimientoContactosBuscadorService } from "./establecimiento_contactos_buscador.service"
import { EstablecimientoReportesBuscadorService } from "./establecimiento_reportes_buscador.service"

import { ResumenUsoComponent } from "./resumen_uso.component"
import { EstablecimientosToolNavbarComponent } from "./establecimientos.toolnavbar.component"
import { EstablecimientoContactosToolNavbarComponent } from "./establecimiento_contactos.toolnavbar.component"
import { EstablecimientoReportesToolNavbarComponent } from "./establecimiento_reportes.toolnavbar.component"
import { ListaEstablecimientosProfesorComponent } from "./lista_establecimientos_profesor/lista_establecimientos_profesor.component"
import { EstablecimientosAdministrarService } from "./establecimientos_administrar.service"
import { EstablecimientoResumenUtpComponent } from "./establecimiento_resumen_utp.component"
import { RecordatorioModalComponent } from "./modal/recordatorio_modal.component"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { ResultadosEvaluacionesUtpComponent } from "./resultados-evaluaciones-utp/resultados-evaluaciones-utp.component"
import { AsignaturaEvaluacionTiposModule } from "@puntaje/puntaje/new-modules/asignatura-evaluacion-tipos"
import { AsignaturasEstablecimientoComponent } from "./asignaturas-establecimiento/asignaturas-establecimiento.component"
import { AvancePlanesAdaptiveComponent } from "./avance-planes-adaptive.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        ModelViewModule,
        PaginatorModule,
        RouterModule,
        Angular2FontawesomeModule,
        MyDatePickerModule,
        UsuariosModule,
        GrupoUsuariosModule,
        EstadisticasModule,
        SharedModule,
        PopoverModule,
        TabsModule,
        SorterModule,
        CUIInputsModule,
        CUITextsModule,
        AsignaturaEvaluacionTiposModule,
        NgMultiSelectDropDownModule.forRoot()
    ],
    declarations: [
        EstablecimientoComponent,
        EstablecimientosToolNavbarComponent,
        EstablecimientosComponent,
        EstablecimientosEditComponent,
        EstablecimientosNewComponent,
        EstablecimientosBuscadorComponent,
        EstablecimientoContactosBuscadorComponent,
        ListaEstablecimientosProfesorComponent,
        EstablecimientosAdministrarComponent,
        EstablecimientoContactosComponent,
        EstablecimientoContactosNewComponent,
        EstablecimientoContactosEditComponent,
        EstablecimientoContactoComponent,
        EstablecimientoContactosToolNavbarComponent,
        EstablecimientoReportesComponent,
        EstablecimientoReportesNewComponent,
        EstablecimientoReportesEditComponent,
        EstablecimientoReporteComponent,
        EstablecimientoReportesToolNavbarComponent,
        EstablecimientoReportesBuscadorComponent,
        ResumenUsoComponent,
        EstablecimientoResumenUtpComponent,
        ResumenUtpComponent,
        RecordatorioModalComponent,
        ResultadosEvaluacionesUtpComponent,
        AsignaturasEstablecimientoComponent,
        AvancePlanesAdaptiveComponent
    ],
    exports: [
        EstablecimientoComponent,
        EstablecimientosToolNavbarComponent,
        EstablecimientosComponent,
        EstablecimientosEditComponent,
        EstablecimientosNewComponent,
        EstablecimientosBuscadorComponent,
        EstablecimientoContactosBuscadorComponent,
        ListaEstablecimientosProfesorComponent,
        EstablecimientosAdministrarComponent,
        EstablecimientoContactosComponent,
        EstablecimientoContactosNewComponent,
        EstablecimientoContactosEditComponent,
        EstablecimientoContactoComponent,
        EstablecimientoContactosToolNavbarComponent,
        EstablecimientoReportesComponent,
        EstablecimientoReportesNewComponent,
        EstablecimientoReportesEditComponent,
        EstablecimientoReporteComponent,
        EstablecimientoReportesToolNavbarComponent,
        EstablecimientoReportesBuscadorComponent,
        ResumenUsoComponent,
        EstablecimientoResumenUtpComponent,
        ResumenUtpComponent,
        RecordatorioModalComponent,
        ResultadosEvaluacionesUtpComponent,
        AvancePlanesAdaptiveComponent
    ],
    providers: [
        // Este lo dejé acá porque es de uso interno al modulo, mover a un forRoot en caso que se requiera en otro lado.
        EstablecimientosBuscadorService,
        EstablecimientoContactosBuscadorService,
        EstablecimientoReportesBuscadorService
    ]
})
export class EstablecimientosModule {
    static forRoot(): ModuleWithProviders<EstablecimientosModule> {
        return {
            ngModule: EstablecimientosModule,
            providers: [EstablecimientosAdministrarService]
        }
    }
}
