import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()
export class OpcionesInstrumentoService {
    ultimaEvaluacionIdByInstrumentoId: any = {}
    subUltimaEvaluacionId: Subject<any> = new Subject<any>()

    constructor() {}

    getUltimaEvaluacionId(instrumentoId) {
        return this.ultimaEvaluacionIdByInstrumentoId[instrumentoId]
    }

    setUltimaEvaluacionId(evaluacionId, instrumentoId) {
        this.ultimaEvaluacionIdByInstrumentoId[instrumentoId] = evaluacionId
        this.subUltimaEvaluacionId.next(this.ultimaEvaluacionIdByInstrumentoId)
    }
}
