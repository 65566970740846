import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Pregunta } from "./preguntas.model"
import { Plataforma } from "./plataformas.model"

export class PreguntaPlataforma extends BaseModel {
    public static className: string = "PreguntaPlataforma"
    public pregunta_id: number
    public plataforma_id: number

    @SaveType(() => Pregunta) public pregunta: Pregunta
    @SaveType(() => Plataforma) public Plataforma: Plataforma
}
