<el-titulo *ngIf="!evaluacionTipoAlias">
    {{ tipoEvaluacionFromEvaluacion | transformInstrumento: "singular" | titlecase }}
</el-titulo>
<el-titulo *ngIf="evaluacionTipoAlias">
    {{ evaluacionTipoAlias | transformInstrumento: "singular" }}
</el-titulo>
<el-contenido-lateral>
    <evaluacion-menu-preguntas
        *ngIf="showPreguntasMenu"
        [evaluacion]="evaluacion"
        [startingPosition]="!showPreguntasMenu"
    ></evaluacion-menu-preguntas>
</el-contenido-lateral>
<generar-evaluacion-diagnostico
    *ngIf="enableDiagnostico"
    (onReadyEvaluacion)="onReadyEvaluacion($event)"
    (showPreguntasMenuChange)="onShowPreguntasMenuChange($event)"
    [onSelectPregunta]="onSelectPregunta"
    [tipoEvaluacion]="tipoEvaluacion"
    [generadorInstrumentoId]="generadorInstrumentoId"
    [asignaturaId]="asignaturaId"
    [rutaRedirect]="rutaRedirect"
    [redirectToPlan]="redirectToPlan"
    [generarDesdeEvaluacion]="generarDesdeEvaluacion"
></generar-evaluacion-diagnostico>
