import { Component } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "estadisticas_alumno.component.html",
    styleUrls: ["estadisticas_alumno.component.scss"]
})
export class EstadisticasAlumnoComponent {
    asignatura: Asignatura
    cursoId: number = 1
    asignaturaId: number
    private sub: Subscription
    subData: Subscription
    enableResponsive: boolean = false
    tipoInstrumento: string
    evaluacionTipos: string[] = []

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas.estadisticas_alumno")
        this.sub = this.route.queryParams.subscribe(queryParams => {
            this.tipoInstrumento = queryParams["tipo_instrumento"] ?? config.plataforma.evaluacionDefault
        })

        this.subData = this.route.data.subscribe(data => {
            this.evaluacionTipos = data.evaluacionTipos ?? this.evaluacionTipos
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
