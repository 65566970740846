import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, HostListener } from "@angular/core"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { AuthService } from "@puntaje/shared/core"
import { Router } from "@angular/router"
import {
    AsociacionUsuarios,
    Establecimientos,
    EstablecimientoUsuario,
    EstablecimientoUsuarios
} from "@puntaje/puntaje/api-services"

/*#################################

Menú arriba del sitio.

#################################*/

@Component({
    templateUrl: "base-topmenu.component.html",
    selector: "base-topmenu",
    styleUrls: ["base-topmenu.component.scss"]
})
export class BaseTopmenuComponent extends MenuComponent {
    @Output() toggleTopmenuEvent = new EventEmitter()
    @ViewChild("topmenu") insideElement
    @HostListener("document:click", ["$event.target"]) public onClick(targetElement) {
        const clickedInside = this.insideElement.nativeElement.contains(targetElement)
        if (this.catchOutsideClick && !clickedInside) {
            this.toggleTopmenuEvent.emit()
        }
    }
    catchOutsideClick: boolean = false
    itemsMenu: any[]
    menuItemsAlumnos = [
        {
            name: "base_topmenu.alumno.practica",
            route: "/evaluaciones/recomendadas",
            icon: "prueba"
        },
        {
            name: "base_topmenu.alumno.resultados",
            route: "/pruebas/resultados",
            icon: "resultados"
        },
        {
            name: "base_topmenu.alumno.plan_de_clase",
            route: "/plan_clases",
            icon: "planificacion-o"
        },
        {
            name: "base_topmenu.alumno.dudas_curso",
            route: "/dudas",
            icon: "dialogos",
            params: {
                show_cursos: 1
            }
        },
        {
            name: "base_topmenu.alumno.comunidad",
            route: "/dudas_categorias",
            icon: "dialogos"
        }
    ]

    menuItemsProfesores = [
        {
            name: "base_topmenu.profe.evaluar",
            route: "/pruebas/generar_prueba",
            icon: "editar-prueba"
        },
        {
            name: "base_topmenu.profe.corregir",
            route: "/evaluacion_instancias/subir",
            icon: "upload"
        },
        {
            name: "base_topmenu.profe.resultados",
            route: "/evaluaciones/resultados",
            icon: "resultados"
        },
        {
            name: "base_topmenu.profe.planes_de_clase",
            route: "/plan_clases",
            icon: "planificacion-o"
        },
        {
            name: "base_topmenu.profe.mis_noticias",
            route: "/noticias",
            icon: "megafono-o"
        },
        {
            name: "base_topmenu.profe.comunidad",
            route: "/dudas_categorias",
            icon: "dialogos"
        }
    ]

    menuItemsByApp = {
        alumnos: this.menuItemsAlumnos,
        profesores: this.menuItemsProfesores
    }

    @Input() config: any

    constructor(
        store: Store<State>,
        protected authService: AuthService,
        protected establecimientosService: Establecimientos,
        protected router: Router,
        protected establecimientoUsuariosService: EstablecimientoUsuarios,
        protected asociacionUsuariosService: AsociacionUsuarios
    ) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        setTimeout(() => {
            this.catchOutsideClick = true
        }, 300)

        if (this.config.app.navigationByApp && this.config.app.navigationByApp[this.config.app.name]) {
            this.itemsMenu = [...this.config.app.navigationByApp[this.config.app.name]]
        } else {
            const itemsMenu = this.menuItemsByApp[this.config.app.name] || this.menuItemsAlumnos
            this.itemsMenu = [...itemsMenu]
        }

        this.enableReportes()
    }

    enableReportes() {
        const hasEstablecimientos = this.authService.getEstablecimientos().length > 0

        if (hasEstablecimientos && this.config.app.name == "profesores") {
            this.itemsMenu.push({
                icon: "establecimiento-o",
                name: "Reportes",
                route: "/estadisticas/resumen_uso"
            })
        }
    }

    onMenuClickOutside() {
        this.toggleTopmenuEvent.emit()
    }

    close() {
        this.toggleTopmenuEvent.emit()
    }
}
