import { BaseForm, Validations, GroupValidator, AppConfig } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"

import { UsuariosCompletarRegistroChileForm } from "./usuarios_completar_registro_chile.form"
import { UsuariosCompletarRegistroColombiaForm } from "./usuarios_completar_registro_colombia.form"
import { UsuariosCompletarRegistroMexicoForm } from "./usuarios_completar_registro_mexico.form"
import { UsuariosCompletarRegistroBrasilForm } from "./usuarios_completar_registro_brasil.form"
import { HttpClient } from "@angular/common/http"

declare const config: AppConfig

export class UsuariosCompletarRegistroForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    companyName: string = ""
    // constructor(){
    //  super();
    //  this.companyName = config.plataforma.info.companyName;
    // }

    public static get formParams(): any {
        // let companyName = this.companyName;
        let companyName = config.plataforma.info.companyName
        let femenino = { id: 0, toString: () => "Femenino" }
        let masculino = { id: 1, toString: () => "Masculino" }

        let params: any = {
            id: { label: "ID", type: "hidden", visible: false },
            nombre: {
                label: "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: "Apellido paterno",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_materno: {
                label: "Apellido materno",
                type: "text",
                visible: true,
                validations: [Validators.minLength(2)]
            },
            fecha_nacimiento: {
                label: "Fecha de nacimiento",
                type: "birthdate",
                visible: true,
                validations: [Validators.required]
            },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmailNotSelf }]
            },
            lugar: {
                label: "Comuna",
                type: "autocomplete",
                visible: true,
                validations: [Validators.required],
                options: { class: Lugares, params: { lugar_tipo: config.plataforma.lugarTipo } }
            },
            password: {
                label: "Contraseña",
                type: "password",
                visible: true,
                validations: [Validators.minLength(8)],
                serviceValidators: [{ class: HttpClient, validator: Validations.checkPasswordBreach }]
            },
            password_confirmation: {
                label: "Confirmar contraseña",
                type: "password",
                visible: true,
                global: true,
                validations: [Validators.required, Validators.minLength(8)]
            },
            terminos_y_condiciones: {
                innerhtml:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de " +
                    companyName +
                    "</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            },
            telefono: { label: "Teléfono celular", type: "text", visible: true, validations: [] },
            genero: {
                label: "Género",
                type: "radio",
                visible: true,
                options: [femenino, masculino],
                validations: [Validators.required]
            }
        }

        let classes = {
            chile: UsuariosCompletarRegistroChileForm,
            colombia: UsuariosCompletarRegistroColombiaForm,
            mexico: UsuariosCompletarRegistroMexicoForm,
            brasil: UsuariosCompletarRegistroBrasilForm
        }

        params["usuario_" + config.plataforma.pais] = { type: "model", class: classes[config.plataforma.pais] }

        return params
    }
}
