<div class="content">
    <!-- Intro -->
    <p
        [innerHTML]="'tyc.seccion0.parrafo0' | t: { companyName: companyName, companySite: companySite } | trustedhtml"
    ></p>

    <!-- Seccion 1 -->
    <h3>{{ "tyc.seccion1.titulo1" | t }}</h3>
    <h5>{{ "tyc.seccion1.subtitulo11" | t }}</h5>
    <p [innerHTML]="'tyc.seccion1.parrafo11' | t: { companyName: companyName } | trustedhtml"></p>

    <h5>{{ "tyc.seccion1.subtitulo12" | t }}</h5>
    <p [innerHTML]="'tyc.seccion1.parrafo12' | t: { companyName: companyName } | trustedhtml"></p>

    <h5>{{ "tyc.seccion1.subtitulo13" | t }}</h5>
    <p [innerHTML]="'tyc.seccion1.parrafo13' | t: { companyName: companyName } | trustedhtml"></p>

    <!-- Seccion 2 -->
    <h3>{{ "tyc.seccion2.titulo2" | t }}</h3>
    <h5>{{ "tyc.seccion2.subtitulo21" | t }}</h5>
    <p>{{ "tyc.seccion2.parrafo21" | t }}</p>

    <h5>{{ "tyc.seccion2.subtitulo22" | t }}</h5>
    <p [innerHTML]="'tyc.seccion2.parrafo22' | t: { companyName: companyName } | trustedhtml"></p>

    <h5>{{ "tyc.seccion2.subtitulo23" | t }}</h5>
    <p [innerHTML]="'tyc.seccion2.parrafo23' | t: { companyName: companyName } | trustedhtml"></p>

    <h5>{{ "tyc.seccion2.subtitulo24" | t }}</h5>
    <p [innerHTML]="'tyc.seccion2.parrafo24' | t: { companyName: companyName } | trustedhtml"></p>

    <!-- Seccion 3 -->
    <h3>{{ "tyc.seccion3.titulo3" | t }}</h3>
    <h5>{{ "tyc.seccion3.subtitulo31" | t }}</h5>
    <p [innerHTML]="'tyc.seccion3.parrafo31' | t: { companyName: companyName } | trustedhtml"></p>

    <h5>{{ "tyc.seccion3.subtitulo32" | t }}</h5>
    <p [innerHTML]="'tyc.seccion3.parrafo32' | t: { companyName: companyName } | trustedhtml"></p>

    <h5>{{ "tyc.seccion3.subtitulo33" | t }}</h5>
    <p [innerHTML]="'tyc.seccion3.parrafo33' | t: { companyName: companyName } | trustedhtml"></p>

    <!-- Seccion 4 -->
    <h3>{{ "tyc.seccion4.titulo4" | t }}</h3>
    <h5>{{ "tyc.seccion4.subtitulo41" | t }}</h5>
    <p [innerHTML]="'tyc.seccion4.parrafo41' | t: { companyName: companyName } | trustedhtml"></p>

    <h5>{{ "tyc.seccion4.subtitulo42" | t }}</h5>
    <p [innerHTML]="'tyc.seccion4.parrafo42' | t: { companyName: companyName } | trustedhtml"></p>

    <h5>{{ "tyc.seccion4.subtitulo43" | t }}</h5>
    <p [innerHTML]="'tyc.seccion4.parrafo43' | t: { companyName: companyName } | trustedhtml"></p>

    <h5>{{ "tyc.seccion4.subtitulo44" | t }}</h5>
    <p [innerHTML]="'tyc.seccion4.parrafo44' | t: { companyName: companyName } | trustedhtml"></p>

    <!-- Seccion 5 -->
    <h3>{{ "tyc.seccion5.titulo5" | t }}</h3>
    <h5>{{ "tyc.seccion5.subtitulo51" | t }}</h5>
    <p [innerHTML]="'tyc.seccion5.parrafo51' | t: { companyName: companyName } | trustedhtml"></p>

    <!-- Seccion 6 -->
    <h3>{{ "tyc.seccion6.titulo6" | t }}</h3>
    <h5>{{ "tyc.seccion6.subtitulo61" | t }}</h5>
    <p [innerHTML]="'tyc.seccion6.parrafo61' | t: { companyName: companyName } | trustedhtml"></p>

    <!-- Seccion 7 -->
    <h3>{{ "tyc.seccion7.titulo7" | t }}</h3>
    <h5>{{ "tyc.seccion7.subtitulo71" | t }}</h5>
    <p [innerHTML]="'tyc.seccion7.parrafo71' | t: { companyName: companyName } | trustedhtml"></p>

    <ul>
        <li>{{ "tyc.seccion7.lista71.item1" | t }}</li>
        <li>{{ "tyc.seccion7.lista71.item2" | t }}</li>
        <li [innerHTML]="'tyc.seccion7.lista71.item3' | t: { companyName: companyName } | trustedhtml"></li>
        <li [innerHTML]="'tyc.seccion7.lista71.item4' | t: { companyName: companyName } | trustedhtml"></li>
    </ul>

    <h5>{{ "tyc.seccion7.subtitulo72" | t }}</h5>
    <p [innerHTML]="'tyc.seccion7.parrafo72' | t: { companyName: companyName } | trustedhtml"></p>

    <h5>{{ "tyc.seccion7.subtitulo73" | t }}</h5>
    <p [innerHTML]="'tyc.seccion7.parrafo73' | t: { companyName: companyName } | trustedhtml"></p>

    <h5>{{ "tyc.seccion7.subtitulo74" | t }}</h5>
    <p [innerHTML]="'tyc.seccion7.parrafo74' | t: { companyName: companyName } | trustedhtml"></p>
</div>
