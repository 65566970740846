<div *ngIf="showRegisterLink">
    <ng-container *ngTemplateOutlet="urlRegister"></ng-container>
</div>
<div *ngIf="showSmowlIframe">
    <ng-container *ngTemplateOutlet="smowlIframe"></ng-container>
</div>
<ng-template #urlRegister><a [attr.href]="smowlRegistrationUri">Regístrate en SMOWL</a></ng-template>
<ng-template #smowlIframe>
    <iframe
        allow="microphone; camera"
        sandbox="allow-top-navigation allow-scripts allow-modals allow-same-origin"
        width="220"
        height="300"
        [src]="smowlIframeUri"
        frameborder="0"
        allowfullscreen
        scrolling="no"
    ></iframe>
</ng-template>
