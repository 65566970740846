import { Injectable } from "@angular/core"
import { Subject } from "rxjs"
import { Material } from "@puntaje/nebulosa/api-services"

@Injectable()
export class MaterialPreviewModalService {
    open$: Subject<Material> = new Subject()
    close$: Subject<any> = new Subject()

    open(material: Material) {
        this.open$.next(material)
    }

    close() {
        this.close$.next(true)
    }
}
