import { Component, Input, Output, EventEmitter, ChangeDetectorRef, forwardRef } from "@angular/core"
import { IMyDpOptions, IMyDayLabels, IMyDateModel } from "mydatepicker"
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from "@angular/forms"

@Component({
    selector: "datepicker-form",
    template: `
        <my-date-picker
            [options]="myDatePickerOptions"
            [(ngModel)]="date"
            (ngModelChange)="onChangeDate()"
            [locale]="locale"
        ></my-date-picker>
        <timepicker
            *ngIf="!disableTimepicker"
            [(ngModel)]="time"
            (ngModelChange)="onChangeDate()"
            [showMeridian]="!isMeridian"
        ></timepicker>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerFormComponent),
            multi: true
        }
    ]
})
export class DatepickerFormComponent implements ControlValueAccessor {
    date: any
    time: Date = new Date()
    locale: string = "es"
    firstTimeLock: boolean = false
    @Input() isMeridian: boolean
    @Input() asISOString: boolean = false
    @Input() disableTimepicker: boolean = false

    public myDatePickerOptions: IMyDpOptions = {
        dateFormat: "dd/mm/yyyy",
        editableDateField: false,
        openSelectorOnInputClick: true
    }

    onChange: (_: any) => {}
    onTouch = () => {}

    constructor(private cdr: ChangeDetectorRef) {
        this.time.setHours(0, 0, 0, 0)
    }

    onChangeDate() {
        if (this.date && this.time && this.date.jsdate) {
            const d = new Date(this.date.jsdate.getTime())
            d.setHours(this.time.getHours())
            d.setMinutes(this.time.getMinutes())
            this.onChange(d)
        } else {
            if (this.firstTimeLock) {
                this.onChange(undefined)
            }
            this.firstTimeLock = true
        }
    }

    writeValue(value: any) {
        const d: any = new Date(value)
        if (value == null || value == undefined) {
            this.date = null
            this.time.setHours(0, 0, 0, 0)
        } else if (d != "Invalid Date") {
            this.time = new Date()
            this.time.setHours(d.getHours())
            this.time.setMinutes(d.getMinutes())

            this.date = {}
            this.date.jsdate = d
        } else if (value instanceof Date) {
            this.time = new Date()
            this.time.setHours(value.getHours())
            this.time.setMinutes(value.getMinutes())

            this.date = { jsdate: value }

            //this.onChange(this.date ? this.date.jsdate : null);
        }

        this.cdr.detectChanges()
    }

    registerOnChange(fn: (value: any) => any) {
        this.onChange = (v: any) => {
            if (this.asISOString && v instanceof Date) {
                v = v.toISOString()
            }

            return fn(v)
        }
    }

    registerOnTouched(fn: () => any) {
        this.onTouch = fn
    }
}
