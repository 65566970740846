import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { UsuarioPaa } from "./usuario_paa.model"

@Injectable()
export class UsuariosPaa extends PuntajeBaseService<UsuarioPaa> {
    tableName = "usuario_paa"
    singularTableName = "usuario_paa"
    modelClass = UsuarioPaa

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public wherePost(params: any = null) {
        return this.all().concatExtra("post").post(params, 120000)
    }
}
