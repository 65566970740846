<ll-titulo>Saber 11</ll-titulo>

<div class="row">
    <div class="col-md-6 with-separator">
        <h3 class="h3_sub_title_2">Ejercicios por asignatura</h3>
        <p class="info clearfix">
            <span *ngIf="asignaturas" class="iconos-asignaturas">
                <ogr-icon *ngFor="let a of asignaturas" [name]="a.icono" class="icono {{ a.clase }}"></ogr-icon>
            </span>
            ¿Necesitas reforzar una asignatura en particular?
            <br />
            Aquí encontrarás opciones para realizar simulacros tipo Saber 11 separados por asignatura.
        </p>
        <menu-grid-layout [menuItems]="menuItemsCol1" [maxColumns]="1"></menu-grid-layout>
    </div>
    <div class="col-md-6">
        <h3 class="h3_sub_title_2">Simulacros Saber 11</h3>
        <p class="info">
            Practica con simulacros Saber 11 predefinidos.
            <br />
            Revisa tus resultados y progreso.
        </p>
        <menu-grid-layout [menuItems]="menuItemsCol2" [maxColumns]="1"></menu-grid-layout>
    </div>
</div>
