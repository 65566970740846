import { Component, Input, SimpleChanges } from "@angular/core"
import { Material, Materiales, Clasificacion, ClasificacionMateriales } from "@puntaje/nebulosa/api-services"
import {
    PdfView,
    YoutubeVideoView,
    GameView,
    HtmlView,
    AudioView,
    VideoView,
    DocsView,
    AuthService,
    LinkView
} from "@puntaje/shared/core"
import { Observable, Subscription } from "rxjs"
import { TiempoMaterialUsuario, TiempoMaterialUsuarios } from "@puntaje/puntaje/api-services"

@Component({
    selector: "material-preview",
    templateUrl: "material_preview.component.html",
    styleUrls: ["material_preview.component.scss"]
})
export class MaterialPreviewComponent {
    @Input() material: Material
    @Input() clasificacionTipo: string
    @Input() id: number
    // TODO: esto está hecho especificamente para aquellos materiales "de mentira" instanciados en base a la URL de la retroalimentación
    // Independiente de la opción, que puede ser util si es que el profesor es el que está mirando, creo que hay que refactorizar
    // lo de mostrar un material en un componente que no dependa de un objeto material, para mostrar cualquier tipo de contenido.
    @Input() disableSaveTiempoMaterialUsuario: boolean = false

    enablePreview: boolean = false
    params: any = {}
    isVideo: boolean = false
    isFile: boolean = false
    isPdf: boolean = false
    isGame: boolean = false
    isHTML: boolean = false
    isInteractivo: boolean = false
    isAudio: boolean = false
    isMp4: boolean = false
    isPPT: boolean = false
    isLink: boolean = false
    isWord: boolean = false
    isExcel: boolean = false
    notFound: boolean = false
    clasificaciones: Clasificacion[]

    dateStart: Date
    intervalo: any
    sub: Subscription
    tiempoMaterialUsuario: TiempoMaterialUsuario

    constructor(
        protected materialesService: Materiales,
        protected clasificacionMaterialesService: ClasificacionMateriales,
        protected tiempoMaterialUsuarioServices: TiempoMaterialUsuarios,
        protected authService: AuthService
    ) {
        this.params = {
            Video: { params: { label: "Reproducir", type: YoutubeVideoView, tableVisible: true }, key: "url" },
            Streaming: { params: { label: "Reproducir", type: YoutubeVideoView, tableVisible: true }, key: "url" },
            Archivo: { params: { label: "Previsualizar", type: PdfView, tableVisible: true }, key: "url" },
            Juego: { params: { label: "Jugar", type: GameView, tableVisible: true }, key: "url" },
            HTML: { params: { label: "Ver", type: HtmlView, tableVisible: true }, key: "contenido" },
            Instrumento: { params: { label: "Previsualizar", type: PdfView, tableVisible: true }, key: "url" },
            Interactivo: {
                params: { label: "Ver", type: GameView, tableVisible: true, options: { withoutPredefinedSize: true } },
                key: "url"
            },
            Audio: { params: { label: "Ver", type: AudioView, tableVisible: true }, key: "url" },
            "Video MP4": { params: { label: "Ver", type: VideoView, tableVisible: true }, key: "url" },
            PPT: { params: { label: "Previsualizar", type: DocsView, tableVisible: true }, key: "url" },
            Word: { params: { label: "Previsualizar", type: DocsView, tableVisible: true }, key: "url" },
            Excel: { params: { label: "Previsualizar", type: DocsView, tableVisible: true }, key: "url" },
            Link: {
                params: {
                    label: "Ir",
                    type: LinkView,
                    tableVisible: true,
                    options: { key: "url", textKey: "material", withTargetBlank: true, withIcon: true }
                },
                key: "url"
            }
        }
    }

    ngOnInit() {
        if (this.id) {
            this.materialesService.enableIgnoreCatch()
            this.materialesService
                .find(this.id)
                .then((response: Material) => {
                    this.materialesService.disableIgnoreCatch()
                    this.material = response
                    this.getClasificaciones().then(() => {
                        this.loadPreview()
                    })
                })
                .catch(e => (this.notFound = true))
        }
    }

    async getClasificaciones() {
        if (!this.clasificacionTipo) {
            return []
        }

        const params = {
            clasificacion_material: { material_id: this.material.id }
        }
        if (this.clasificacionTipo) {
            params["clasificacion_tipo"] = {
                clasificacion_tipo: this.clasificacionTipo
            }
            params["joins"] = {
                clasificacion_tipo: { clasificacion: "clasificacion_tipo" }
            }
        }
        const clasificaciones = await this.clasificacionMaterialesService.where(params)
        this.clasificaciones = clasificaciones.map(cm => cm.clasificacion)

        return this.clasificaciones
    }

    loadPreview() {
        this.enablePreview = false
        if (this.material) {
            this.isVideo =
                this.material.material_tipo_nombre == "Video" || this.material.material_tipo_nombre == "Streaming"
            this.isFile = this.material.material_tipo_nombre == "Archivo"
            this.isPdf = this.material.material_tipo_nombre == "Archivo" && this.material.getExtension() == "pdf"
            this.isGame = this.material.material_tipo_nombre == "Juego"
            this.isHTML = this.material.material_tipo_nombre == "HTML"
            this.isInteractivo = this.material.material_tipo_nombre == "Interactivo"
            this.isAudio = this.material.material_tipo_nombre == "Audio"
            this.isMp4 = this.material.material_tipo_nombre == "Video MP4"
            this.isPPT = this.material.material_tipo_nombre == "PPT"
            this.isLink = this.material.material_tipo_nombre == "Link"
            this.isWord = this.material.material_tipo_nombre == "Word"
            this.isExcel = this.material.material_tipo_nombre == "Excel"
            setTimeout(() => {
                this.enablePreview = true
            }, 1)
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.disableSaveTiempoMaterialUsuario) {
            this.saveTiempoMaterialUsuario()
            this.sub = this.updateTiempoMaterialUsuario().subscribe()
        }

        if (changes["material"]) {
            this.getClasificaciones().then(() => this.loadPreview())
        }
    }

    saveTiempoMaterialUsuario() {
        // se crea una fila en tiempoMaterialUsuario y se guarda en su respectiva variable
        let params = new TiempoMaterialUsuario()
        params.material_id = this.material.id
        params.usuario_id = this.authService.getUserData().id
        params.fecha_inicial = new Date()
        params.fecha_final = new Date()
        params.tiempo = 0

        this.tiempoMaterialUsuarioServices.save(params).then((tiempoMaterialUsuario: TiempoMaterialUsuario) => {
            this.tiempoMaterialUsuario = tiempoMaterialUsuario
        })
    }

    updateTiempoMaterialUsuario() {
        // Cada 10 segundos actualiza tiempo en segundos y fehca final de tiempoMaterialUsuario
        return new Observable(observer => {
            this.intervalo = setInterval(() => {
                if (this.tiempoMaterialUsuario !== undefined && this.tiempoMaterialUsuario !== null) {
                    this.tiempoMaterialUsuario.fecha_final = new Date()
                    this.tiempoMaterialUsuario.tiempo = Math.round(
                        (this.tiempoMaterialUsuario.fecha_final.getTime() -
                            new Date(this.tiempoMaterialUsuario.fecha_inicial).getTime()) /
                            1000
                    )
                    this.tiempoMaterialUsuarioServices
                        .update(this.tiempoMaterialUsuario.id, this.tiempoMaterialUsuario)
                        .then((tiempoMaterialUsuario: TiempoMaterialUsuario) => {
                            this.tiempoMaterialUsuario = tiempoMaterialUsuario
                        })
                }
            }, 10000)
        })
    }

    public clearIntervalo() {
        // se cierra el intervalo y la subcripcion
        clearInterval(this.intervalo)
        if (this.sub) this.sub.unsubscribe()
    }

    ngOnDestroy() {
        this.clearIntervalo()
    }
}
