import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { GrupoUsuario } from "./grupo_usuarios.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class GrupoUsuarios extends PuntajeBaseService<GrupoUsuario> {
    tableName = "grupo_usuarios"
    singularTableName = "grupo_usuario"
    modelClass = GrupoUsuario

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public imprimirParaApoderado(grupoUsuarioId: number, params: any = null) {
        return this.one(grupoUsuarioId).concatExtra("imprimir_informe_apoderado_curso").get(params)
    }

    public vaciar(grupoUsuarioId: number, params: any = null) {
        return this.one(grupoUsuarioId).concatExtra("vaciar").post(params)
    }

    public vaciarAll(params: any) {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("vaciar_all")
            .post(params)
            .then(resp => {
                this.disableIgnoreModel()

                return resp
            })
    }

    public promediosLibroNotas(grupoUsuarioId: number, params: any = null) {
        this.enableIgnoreModel()
        return this.one(grupoUsuarioId)
            .concatExtra("promedios_libro_notas")
            .get(params)
            .then(resp => {
                this.disableIgnoreModel()
                return resp
            })
    }

    public destroyAllRange(params: any) {
        this.enableIgnoreModel()
        this.enableIgnoreCatch()

        return this.all()
            .concatExtra("destroy_all_range")
            .post(params)
            .then(resp => {
                this.disableIgnoreModel()

                return resp
            })
    }

    public reset_alum_pass(grupoUsuarioId: number) {
        return this.one(grupoUsuarioId).concatExtra("reset_alum_pass").post()
    }

    public obtener_csv_alumnos(grupoUsuarioId: number, params: any = null) {
        this.enableIgnoreModel()
        return this.one(grupoUsuarioId)
            .concatExtra("obtener_csv_alumnos")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }

    public numeroAlumnos(grupoUsuarioIds: number[], unique: Boolean = false) {
        this.enableIgnoreModel()
        let queryMethod = unique ? "numero_alumnos_unicos" : "numero_alumnos"

        return this.all()
            .concatExtra(queryMethod)
            .get({ grupo_usuario_ids: grupoUsuarioIds })
            .then(data => {
                this.disableIgnoreModel()

                return data
            })
    }

    public numeroAlumnosPost(grupoUsuarioIds: number[], unique: Boolean = false) {
        this.enableIgnoreModel()
        let queryMethod = unique ? "numero_alumnos_unicos" : "numero_alumnos"

        return this.all()
            .concatExtra(queryMethod)
            .post({ grupo_usuario_ids: grupoUsuarioIds } as any)
            .then(data => {
                this.disableIgnoreModel()

                return data
            })
    }

    public numeroDocentes(grupoUsuarioIds: number[], unique: Boolean = false) {
        this.enableIgnoreModel()
        let queryMethod = unique ? "numero_docentes_unicos" : "numero_docentes"

        return this.all()
            .concatExtra(queryMethod)
            .get({ grupo_usuario_ids: grupoUsuarioIds } as any)
            .then(data => {
                this.disableIgnoreModel()

                return data
            })
    }

    public wherePost(params: any = null, timeout: number = 30000) {
        return this.all().concatExtra("post").post(params, timeout)
    }

    public csvLibroNotas(grupoUsuarioId: number, params: any = null) {
        this.enableIgnoreModel()
        return this.one(grupoUsuarioId)
            .concatExtra("csv_libro_notas")
            .get(params)
            .then(data => {
                this.disableIgnoreModel()
                return data
            })
    }
}
