export const esPNCL = {
    landing: {
        inicio: {
            seccion1: {
                subtitulo: `Completamente <em>GRATIS</em> , para siempre`,
                titulo: `Preparación para <em>ENEM 2023</em>`
            },
            seccion2: {
                subtitulo: `Online, Fácil y efetivo`,
                titulo: `Preparación gratuita para as
                                                                <em>4 prurbas</em>
                                                                de ENEM`
            },
            seccion3: {
                btn: `Comenzar ahora!`,
                parrafo: `El acceso a
                                                                <em>Aprendo Libre</em>
                                                                es totalmente gratuito para todos los alumnos que buscan prepararse para la ENEM de forma
                                                                online y efetiva.`,
                titulo: `Soporte
                                                                <em>gratuito</em>
                                                                para alumnos`
            },
            seccion4: {
                contenido1: {
                    parrafo: `Encontrarás clases y videos con material para ENEM, haz preguntas con el
                                                                                Comunidad, participa en las pruebas mensuales y participa en el ranking.`,
                    titulo: `Material multimedia`
                },
                contenido2: {
                    parrafo: `A través de nuestros Planes Adaptativos, te recomendamos sesiones de estudio personalizadas
                                                                                para mejorar sus conocimientos y habilidades.`,
                    titulo: `Planes Adaptativos`
                },
                contenido3: {
                    parrafo: `Practique para el examen con cuestionarios de opción múltiple y acceda al
                                                                                realimentación.`,
                    titulo: `Realimentación inmediata`
                },
                subtitulo: `Estas son solo algunas de las características que tenemos para apoyar su preparación.`,
                titulo: `Te ayudamos a prepararte para dar <em>lo mejor de ti</em>`
            },
            seccion5: {
                btn: `Comenzar`,
                parrafo: `Consulta nuestro calendario de clases en vivo para las 4 pruebas y no te pierdas ninguna de nuestras sesiones de preparación ENEM.`,
                titulo: `Clases en vivo gratis para ENEM`
            },
            topfooter: {
                btn: `Suscribirse`,
                titulo: `Regístrese en Aprendo Livre hoy.
                                                                <b>Es gratis y siempre lo será</b>`
            }
        },
        login: {
            btn_email: "Ingresar",
            btn_fb: "Ingresar con Facebook",
            btn_google: "Acceder con <em>Google</em>",
            btn_home: "Home",
            btn_logout: "Cerrar Sesión",
            btn_recover: "¿Olvidaste tu contraseña?",
            btn_register: "Regístrate con tu correo",
            error_auth1:
                "<strong>La combinación de correo electrónico/contraseña o NUIP/contraseña es incorrecta.</strong> Las contraseñas de ",
            error_auth2:
                ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.',
            error_auth3:
                "Para aumentar el resguardo de tus datos aumentamos los requisitos de seguridad de las contraseñas, por lo que debes ingresar una nueva. Para esto debes dirigirte a ¿Olvidaste tu contraseña? o enviar un correo a %{mail}",
            error_auth3_UST:
                "Para aumentar el resguardo de tus datos aumentamos los requisitos de seguridad de las contraseñas, por lo que debes ingresar una nueva. Para esto debes dirigirte a ¿Olvidaste tu contraseña? o enviar un correo a contactost@ogr.cl",
            error_auth4:
                "La combinación de correo electrónico/contraseña es incorrecta. Si no recuerdas tu contraseña haz click <a href='/usuarios/passwords/forgot'>aquí</a>",
            error_auth5: " para restablecerla.",
            error_pswd: "Debes incluir una contraseña.",
            error_user: "Debes incluir un correo electrónico o NUIP válido.",
            pswd: "Contraseña",
            titulo: "Ingresar",
            titulo_alumno: "Iniciar sesión",
            user: "Email o NUIP"
        },
        menu: {
            aulas_vivo: "Clases en vivo",
            comenzar: "¡Empieza hoy!",
            como_funciona: "¿Cómo funciona?",
            contacto: "Contacto",
            pagina_inicial: "Página Inicial",
            provas_enem: "Pruebas ENEM"
        },
        registro: {
            datos_personales: {
                apellido_materno: "Segundo apellido",
                apellido_paterno: "Primer apellido",
                confirmar_password: "Confirmar contraseña",
                cpf: "CPF",
                //wtf xD
                email: "Email",
                municipio: "Município",
                //Entendiendo caleta lo q es i18n
                nombres: "Nombres",
                password: "Contraseña",
                telefono: "Teléfono",
                terminos_y_condiciones:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de %{companyName}</a>",
                year_egreso: "¿En que año egreso?"
            },
            completar_social: {
                titulo: "Completa tus datos"
            },
            msgSaved: `Le enviamos un email con instrucciones instrucciones para validar su cuenta. Si no puede verlo en 5 minutos, verifique su SPAM`,
            titulo: `Datos personales`,
            tituloDeslogueado: "Crear cuenta"
        },
        recuperar_password: {
            instruccion_email:
                "Ingresa tu correo electrónico o %{identificador} para recibir instrucciones de cómo restablecer tu contraseña.",
            instruccion_email_no_identificador:
                "Ingresa tu correo electrónico para recibir instrucciones de cómo restablecer tu contraseña.",
            boton_volver: "Volver",
            boton_continuar: "Continuar",
            boton_reenviar: "Reenviar",
            mensaje_recuperacion:
                "Si el correo electrónico entregado existe, un mensaje se te enviará a tal dirección con instrucciones. Si no ves el mensaje en la bandeja de entrada, busca en la carpeta de correo no deseado.",
            mensaje_recuperacion_id:
                "Hemos enviado un mensaje a la dirección %{email} con las instrucciones para restablecer la contraseña."
        },
        reiniciar_password: {
            ingresa_nuevo_password: "Ingresa tu nueva contraseña",
            fortaleza_password: "Fortaleza de la contraseña:",
            muy_debil: "Muy débil",
            debil: "Débil",
            moderada: "Moderada",
            segura: "Segura",
            muy_segura: "Muy segura",
            nuevo_password_placeholder: "Nueva contraseña",
            confirmar_password_placeholder: "Confirmar nueva contraseña",
            password_largo_minimo: "La contraseña debe ser de mínimo 8 carácteres.",
            guardar_password: "Guardar nueva contraseña",
            guardando: "Guardando ...",
            reinicio_exitoso:
                "Se han actualizado tus datos correctamente. Puedes ingresar a tu cuenta con tu nueva contraseña.\nSerás redirigido a la página de inicio en 5 segundos...",
            token_invalido: "Token inválido.",
            campos_no_coinciden: "Los campos no coinciden.",
            password_minimo_largo: "La contraseña tiene que ser de al menos 8 carácteres.",
            password_vulnerable: "Esta contraseña es vulnerable. Prueba colocar otra.",
            error: "Error."
        }
    },
    noticias: {
        form: {
            noticia: "Comunicación",
            fecha_inicio: "Fecha de inicio comunicación",
            fecha_termino: "Fecha de término comunicación"
        },
        profe: {
            publicar: "Publicar comunicación",
            publicar_boton: "Publicar comunicación",
            not_found: "No has publicado comunicaciones aún",
            cursos_compartir: "Cursos a los cuales compartirá la comunicación"
        }
    },
    titles: {
        core: {
            noticias: {
                noticias_profesor: "Mis comunicaciones"
            }
        }
    },
    usuarios_perfil_edit: {
        titulo: "Editar mis datos",
        aceptar: "Aceptar",
        apellido_materno: "Segundo apellido",
        apellido_paterno: "Primer apellido",
        cambiar_contrasena: "Cambiar contraseña",
        cambiar_imagen: "Cambiar imágen",
        cancelar: "Cancelar",
        correo_electronico: "Correo electrónico",
        deshacer: "Deshacer",
        editar: "Editar",
        fecha_actualizacion: "Fecha de actualización",
        fecha_creacion: "Fecha de creación",
        foto_perfil: "Foto de perfil",
        cambiar_foto_perfil: "Cambiar foto de perfil",
        guardar: "Guardar",
        informacion_personal: "Información personal",
        nombre: "Nombre",
        opciones: "Opciones",
        no_definido: "No definido",
        fecha_nacimiento: "Fecha de nacimiento",
        curso: "Curso",
        asignatura: "Asignatura",
        asignatura_definida: "Asignatura definida",
        eliminar_cuenta: "Eliminar Cuenta",
        tutorias: "Tutorias",
        seleccione: "Seleccione",
        ayuda_asignatura: "Los menús se abrirán por defecto en la asignatura seleccionada"
    }
}
