import { BaseModel, SaveType } from "@puntaje/shared/core"
import { GeneradorInstrumentoInstruccion } from "./generador_instrumento_instrucciones.model"

export class Instruccion extends BaseModel {
    public static className: string = "Instruccion"
    public instruccion: string
    public created_at: Date
    public updated_at: Date
    public instruccion_latex: string

    @SaveType(() => GeneradorInstrumentoInstruccion)
    generador_instrumento_instrucciones: GeneradorInstrumentoInstruccion[]
}
