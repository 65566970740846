import { PluralizePipe } from "./pipes/pluralize.pipe"
import { NgModule, ModuleWithProviders } from "@angular/core"

// directives
import { EasyScrollToDirective } from "./directives/easyscrollto.directive"
import { ExpandableContentDirective } from "./directives/expandable_content.directive"
import { FocusedDirective } from "./directives/focused.directive"
import { ToggleClassOnClickDirective } from "./directives/toggle_class_on_click.directive"
import { ToggleExpandableDirective } from "./directives/toggle_expandable.directive"
import { ScrollAndFollowDirective } from "./directives/scroll_and_follow.directive"
import { EasyScrollDirective } from "./directives/easyscroll.directive"
import { TransitionGroupItemDirective, TransitionGroupComponent } from "./directives/transition_group_item.directive"
import { ResizeAwareDirective } from "./directives/resize_aware.directive"
import { StickyTopDirective, StickyTopComponent } from "./directives/sticky_top.directive"
import { OnScrolledToViewDirective } from "./directives/on_scrolled_to_view.directive"
import { TextHighlightComponent } from "./directives/text_highlight.directive"
import { GrupoPreguntaTextoDirective } from "./directives/grupo-pregunta-texto.directive"
import { FocusInvalidInputDirective } from "./directives/focus_invalid_input.directive"

// pipes
import { ClearLatexPipe } from "./pipes/clearlatex.pipe"
import { EasyPlaceholderPipe } from "./pipes/easyplaceholder.pipe"
import { FechaAtrasPipe } from "./pipes/fechaAtras.pipe"
import { KeysPipe } from "./pipes/keys.pipe"
import { PrettyDatePipe } from "./pipes/prettydate.pipe"
import { TrustedHTMLPipe } from "./pipes/trustedhtml.pipe"
import { CapitalizePipe } from "./pipes/capitalize.pipe"
import { RoundFloatPipe } from "./pipes/roundfloat.pipe"
import { HighlightPipe } from "./pipes/highlight.pipe"
import { PercentagePipe } from "./pipes/percentage.pipe"
import { PercentageFloorPipe } from "./pipes/percentage_floor.pipe"
import { TaxativoNumeroTextoPipe } from "./pipes/taxativo-numero-texto.pipe"
import { TrustedUrlPipe } from "./pipes/trustedurl.pipe"
import { SecondsToTime } from "./pipes/secondsToTime.pipe"
import { SumTotalPercentage } from "./pipes/sumTotalPercentage.pipe"
import { TranslatePipe, TranslateExtrasPipe } from "./pipes/translate.pipe"

// services
import { PdfService } from "./services/pdf.service"
import { StateService } from "./services/state.service"
import { TabService } from "./services/tab.service"
import { ToggleClassService } from "./services/toggleClass.service"
import { ScrollToService } from "./services/scrollto.service"
import { TitleService } from "./services/title.service"

// components
import { OGRIconComponent } from "./icons/ogr_icon.component"
import {
    GenericModalComponent,
    GenericModalTitulo,
    GenericModalContenido,
    GenericModalBotones
} from "./generic_modal/generic_modal.component"
import { EmbeddedYoutubeVideoComponent } from "./video/embedded_youtube_video.component"
import { WordCountComponent } from "./word-count/word-count.component"

import { CommonModule } from "@angular/common"
import { SingularizePipe } from "./pipes/singularize.pipe"
import { OrderByPipe } from "./pipes/order-by.pipe"
import { ModalModule } from "ngx-bootstrap/modal"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { TaxativoWithAlternativasPipe } from "./pipes/taxativo_with_alternativas.pipe"
import { GrupoPreguntaTextoService } from "./services/grupo-pregunta-texto.service"
import { MaterialPdfService } from "./services/material-pdf.service"
import { FullscreenService } from "./fullscreen/fullscreen.service"
import { FullscreenComponent } from "./fullscreen/fullscreen.component"
import { KatexPipe } from "./pipes/katex.pipe"
import { KatexComponent } from "./pipes/katex.component"

@NgModule({
    imports: [CommonModule, ModalModule, TooltipModule],
    declarations: [
        KeysPipe,
        PrettyDatePipe,
        EasyPlaceholderPipe,
        TrustedHTMLPipe,
        RoundFloatPipe,
        ClearLatexPipe,
        FechaAtrasPipe,
        CapitalizePipe,
        TaxativoWithAlternativasPipe,
        HighlightPipe,
        PercentagePipe,
        PercentageFloorPipe,
        TrustedUrlPipe,
        TranslatePipe,
        TranslateExtrasPipe,
        ToggleExpandableDirective,
        ExpandableContentDirective,
        EasyScrollToDirective,
        EasyScrollDirective,
        ToggleClassOnClickDirective,
        TransitionGroupItemDirective,
        FocusedDirective,
        ScrollAndFollowDirective,
        ResizeAwareDirective,
        OnScrolledToViewDirective,
        GenericModalComponent,
        GenericModalTitulo,
        GenericModalContenido,
        GenericModalBotones,
        OGRIconComponent,
        EmbeddedYoutubeVideoComponent,
        WordCountComponent,
        PluralizePipe,
        SingularizePipe,
        OrderByPipe,
        TransitionGroupComponent,
        StickyTopDirective,
        StickyTopComponent,
        TextHighlightComponent,
        TaxativoNumeroTextoPipe,
        SecondsToTime,
        GrupoPreguntaTextoDirective,
        FullscreenComponent,
        SumTotalPercentage,
        FocusInvalidInputDirective,
        KatexComponent,
        KatexPipe
    ],
    exports: [
        KeysPipe,
        PrettyDatePipe,
        EasyPlaceholderPipe,
        TrustedHTMLPipe,
        RoundFloatPipe,
        ClearLatexPipe,
        TaxativoWithAlternativasPipe,
        FechaAtrasPipe,
        CapitalizePipe,
        HighlightPipe,
        PercentagePipe,
        PercentageFloorPipe,
        TrustedUrlPipe,
        TranslatePipe,
        TranslateExtrasPipe,
        ToggleExpandableDirective,
        ExpandableContentDirective,
        EasyScrollToDirective,
        EasyScrollDirective,
        ToggleClassOnClickDirective,
        FocusedDirective,
        ScrollAndFollowDirective,
        TransitionGroupItemDirective,
        ResizeAwareDirective,
        OnScrolledToViewDirective,
        GenericModalComponent,
        GenericModalTitulo,
        GenericModalContenido,
        GenericModalBotones,
        OGRIconComponent,
        EmbeddedYoutubeVideoComponent,
        WordCountComponent,
        PluralizePipe,
        SingularizePipe,
        OrderByPipe,
        TransitionGroupComponent,
        StickyTopDirective,
        StickyTopComponent,
        TextHighlightComponent,
        GrupoPreguntaTextoDirective,
        TaxativoNumeroTextoPipe,
        SecondsToTime,
        FullscreenComponent,
        SumTotalPercentage,
        FocusInvalidInputDirective,
        KatexComponent,
        KatexPipe
    ]
})
export class UtilModule {
    static forRoot(): ModuleWithProviders<UtilModule> {
        return {
            ngModule: UtilModule,
            providers: [
                PdfService,
                ToggleClassService,
                StateService,
                TabService,
                ScrollToService,
                GrupoPreguntaTextoService,
                MaterialPdfService,
                FullscreenService,
                TitleService
            ]
        }
    }
}
