import { Component, EventEmitter, Input, OnInit, ViewChild } from "@angular/core"
import { GenericModalComponent, I18nService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

export interface Image {
    url: string
    desc: string
}

@Component({
    selector: "modal-instructivo-subir-respuestas",
    templateUrl: "./modal-instructivo-subir-respuestas.component.html",
    styleUrls: ["./modal-instructivo-subir-respuestas.component.scss"]
})
export class ModalInstructivoSubirRespuestasComponent implements OnInit {
    openModalEvent: EventEmitter<any> = new EventEmitter<any>()
    storageKey: string = "modalInstructivoSubirRespuestas"
    dontShowAgain: boolean
    instrImages: Image[]
    warnImages: Image[]
    headerImage: Image
    openInstructivoImprimirSub: Subscription

    @Input() openInstructivoImprimirEvent: EventEmitter<boolean> = new EventEmitter<boolean>()
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent

    constructor(private translator: I18nService) {
        this.instrImages = [
            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/printer.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.instr1")
            },
            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/paper-inputs.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.instr3")
            },
            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/paper.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.instr2")
            },
            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/cam.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.instr4")
            },
            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/qr.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.instr5")
            },
            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/pen.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.instr6")
            },

            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/scanner.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.instr7")
            },
            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/paper-checked.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.instr8")
            }
        ]

        this.headerImage = {
            url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/warn.png",
            desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.advertencia")
        }

        this.warnImages = [
            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/tint.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.adv1")
            },
            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/dirt.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.adv2")
            },
            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/creased.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.adv3")
            },
            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/qr-incomplete.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.adv4")
            },
            {
                url: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/shared/instructivo-hojas-respuesta/pdf.png",
                desc: this.translator.translate("subir_respuestas_imagen_multiple.modal_instructivo.adv5")
            }
        ]

        this.dontShowAgain = localStorage.getItem(this.storageKey) ? true : false
    }

    ngOnInit() {
        this.openInstructivoImprimirSub = this.openInstructivoImprimirEvent.subscribe(force => {
            const show = localStorage.getItem(this.storageKey) !== "true"

            if (force || show) {
                this.openModalEvent.emit()
            }
        })
    }

    onAccept() {
        this.genericModal.close()
    }

    setDontShowAgain(event: any) {
        const storageValue = event.target.checked ? "true" : null
        this.dontShowAgain = event.target.checked

        localStorage.setItem(this.storageKey, storageValue)
    }

    ngOnDestroy() {
        this.openInstructivoImprimirSub.unsubscribe()
    }
}
