import {
    OnInit,
    ViewContainerRef,
    ElementRef,
    Component,
    ViewChild,
    HostListener,
    ChangeDetectorRef
} from "@angular/core"
import { FullscreenService } from "./fullscreen.service"
import { Subscription } from "rxjs"

@Component({
    selector: "fullscreen",
    templateUrl: "./fullscreen.component.html",
    styleUrls: ["./fullscreen.component.scss"]
})
export class FullscreenComponent implements OnInit {
    sub: Subscription
    subClose: Subscription

    @HostListener("fullscreenchange", ["$event"])
    @HostListener("webkitfullscreenchange", ["$event"])
    @HostListener("mozfullscreenchange", ["$event"])
    @HostListener("MSFullscreenChange", ["$event"])
    screenChange(event) {
        this.fullscreenService.isFullscreen = !!this.isFullcreen(document)
        if (!this.fullscreenService.isFullscreen) {
            this.fullscreenService.closeFullscreen()
        }
    }

    @ViewChild("fullscreenContainer", { static: true, read: ViewContainerRef }) fullscreenContainer: ViewContainerRef

    constructor(
        protected elementRef: ElementRef,
        protected fullscreenService: FullscreenService,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.sub = this.fullscreenService.templateRef$.subscribe(templateRef => {
            this.fullscreenContainer.clear()
            const view = this.fullscreenContainer.createEmbeddedView(templateRef)
            const elem = this.elementRef.nativeElement

            this.cdr.detectChanges()
            setTimeout(() => {
                const toFocus = view.rootNodes.find(r => r.nodeName != "#comment")
                toFocus.focus()
            }, 1000)
            const methodToBeInvoked =
                elem.requestFullscreen ||
                elem.webkitRequestFullScreen ||
                elem["mozRequestFullscreen"] ||
                elem["msRequestFullscreen"]

            if (methodToBeInvoked) {
                methodToBeInvoked.call(elem)
            }
        })

        this.subClose = this.fullscreenService.close$.subscribe(() => {
            if (this.fullscreenService.isFullscreen) this.closeFullscreen()
            this.fullscreenContainer.clear()
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subClose.unsubscribe()
    }

    isFullcreen(docu: any) {
        return (
            docu.fullscreenElement ||
            docu.webkitFullscreenElement ||
            docu.mozFullScreenElement ||
            docu.webkitCurrentFullScreenElement
        )
    }

    closeFullscreen() {
        const methodToBeInvoked =
            document.exitFullscreen ||
            (document as any).mozCancelFullScreen ||
            (document as any).webkitExitFullscreen ||
            (document as any).msExitFullscreen

        if (methodToBeInvoked) {
            methodToBeInvoked.call(document)
        }
    }
}
