import { BaseForm, Validations, GroupValidator } from "@puntaje/shared/core"

export class UsuariosCompletarRegistroBrasilForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.requiredOne(["cpf"])]
    }

    companyName: string = ""
    // constructor(){
    //  super();
    //  this.companyName = config.plataforma.info.companyName;
    // }

    public static get formParams(): any {
        return {
            usuario_id: { type: "hidden", visible: true },
            telefono: { label: "Teléfono", type: "text", visible: true }
        }
    }
}
