import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Material } from "@puntaje/nebulosa/api-services"

export class Streaming extends BaseModel {
    public channel_id: string
    public channel_alias: string
    public callback: string
    public topic: string
    public mode: string
    public secret: string
    public lease_seconds: number
    public transmitiendo: boolean
    public video_id: string
    public last_video: string
    public title: string
    public description: string
    public streaming_enabled: boolean
    public material_id: number
    public alias: string

    @SaveType(() => Material) material: Material

    constructor() {
        super()
        this.id = null
        this.channel_id = ""
        this.channel_alias = ""
        this.callback = ""
        this.topic = ""
        this.mode = ""
        this.secret = ""
        this.lease_seconds = null
        this.transmitiendo = false
        this.video_id = null
        this.last_video = null
        this.title = null
        this.description = null
    }

    public static getStreamingUrl(channel_id: string) {
        if (channel_id) {
            return "https://www.youtube.com/embed/live_stream?channel=" + channel_id
        } else {
            return ""
        }
    }

    public static getChatUrl(video_id: string) {
        if (video_id) {
            return "https://www.youtube.com/live_chat?v=" + video_id
        } else {
            return null
        }
    }

    public chatUrl() {
        return Streaming.getChatUrl(this.video_id)
    }

    public streamingUrl() {
        return Streaming.getStreamingUrl(this.channel_id)
    }

    public populate(object: any) {
        let keys = Object.keys(object)
        Object.keys(this).forEach(key => {
            if (object.hasOwnProperty(key)) {
                this[key] = object[key]
            }
        })
        return this
    }
}
