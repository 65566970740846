import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { TypeformWidgetTipoUsuario } from "./typeform_widget_tipo_usuarios.model"

@Injectable()
export class TypeformWidgetTipoUsuarios extends PuntajeBaseService<TypeformWidgetTipoUsuario> {
    tableName = "typeform_widget_tipo_usuarios"
    singularTableName = "typeform_widget_tipo_usuario"
    modelClass = TypeformWidgetTipoUsuario

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
