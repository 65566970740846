<generic-modal [buttonClicked]="openModal">
    <modal-titulo>Deseo recibir el código de confirmación mediante:</modal-titulo>
    <modal-contenido>
        <!--Se comenta mientras cambiasmos para q los mensajes funcione -->
        <!-- <div class="container" ><input type="radio"  name="tipo" value="sms" class="radio" [(ngModel)]="type"> <label>Mensaje de texto </label>   </div>  -->
        <div class="container">
            <input type="radio" name="tipo" value="mail" class="radio" [(ngModel)]="type" />
            <label>Correo electrónico</label>
        </div>

        <div *ngIf="showInput">
            <label>Ingresa el número de telefono</label>
            <input type="text" class="form-control" placeholder="981234567" [(ngModel)]="telefono" />
        </div>
    </modal-contenido>
    <modal-botones>
        <button class="btn btn-default" (click)="guardar()">Envíar</button>
    </modal-botones>
</generic-modal>
