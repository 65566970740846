import { ChangeDetectorRef, Component, Directive, Input, TemplateRef, ViewChild } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
import { queueScheduler, Subscription } from "rxjs"
import { observeOn } from "rxjs/operators"
import { SharedLayoutService } from "./shared-layout.service"
declare const config: AppConfig

/*#################################

Template simple para páginas para usuario sin tipo de usuario en particular.
Implementa una sección de contenido y un título.

shared-layout-titulo
shared-layout-contenido

#################################*/

@Component({
    templateUrl: "cui-shared-layout.component.html",
    selector: "cui-shared-layout",
    styleUrls: ["cui-shared-layout.component.scss"]
})
export class CUISharedLayoutComponent {
    config: typeof config = config
    logo: string
    landingImage: string = "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"
    @Input() imageCustomStyles: string //MUAHAHAHAHAHAHA
    showContenido: boolean
    logoFooter: string
    contentWrapStyle: string = ""
    @Input() alignRight = false
    @Input() marginTopContent: string
    @Input() marginRightContent: string
    @Input() showLogo: boolean = true
    sub: Subscription
    subTemplate: Subscription
    isMobile: boolean
    templateObj: { [name: string]: TemplateRef<any> } = {}

    constructor(protected cdr: ChangeDetectorRef, protected sharedLayoutService: SharedLayoutService) {
        this.landingImage = this.config.app.baseStyle?.sharedLayout?.backgroundImage ?? this.landingImage
        this.logo = this.config.app.baseStyle?.sharedLayout?.logo
            ? this.config.app.assets[this.config.app.baseStyle?.sharedLayout?.logo]
            : this.config.app.assets.logoLargeLight
        this.logoFooter = this.config.app.baseStyle?.landing?.logoFooter
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logoFooter]
            : this.config.app.assets.logoMediumLight
        this.contentWrapStyle = this.config.app.baseStyle?.sharedLayout?.backgroundColor
            ? `background: ${this.config.app.baseStyle?.sharedLayout?.backgroundColor}`
            : ""
    }

    ngOnInit(): void {
        this.sharedLayoutService.resetSlots()
        this.sharedLayoutService.setOptions({})

        this.subTemplate = this.sharedLayoutService.templates$
            .pipe(observeOn(queueScheduler))
            .subscribe(templateObj => {
                this.templateObj = templateObj

                this.cdr.detectChanges()
            })

        this.resizeElements()
    }

    resizeElements() {
        const backgroundImageMobile = this.config.app.baseStyle?.sharedLayout?.backgroundImageMobile

        const windowWidth = window.innerWidth
        if (windowWidth < 768) {
            this.landingImage = backgroundImageMobile || this.landingImage
            this.isMobile = true
        } else {
            this.isMobile = false
        }
    }
}
