import { BaseModel } from "@puntaje/shared/core"

export class BannerPagina extends BaseModel {
    public pagina: string

    constructor() {
        super()
    }

    public toString() {
        return this.pagina
    }
}
