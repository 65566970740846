import { Component, HostBinding, OnDestroy, Input } from "@angular/core"
import { Router } from "@angular/router"
import { Subscription } from "rxjs"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { AuthService, TabService, SessionService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { trigger, transition, style, animate, keyframes, query, state } from "@angular/animations"

/*#################################

Barra de navegación que va en la parte superior.

#################################*/

@Component({
    templateUrl: "base-navbar.component.html",
    selector: "base-navbar",
    styleUrls: ["base-navbar.component.scss"],
    animations: [
        trigger("topmenu", [
            transition(":enter", [
                animate(
                    "0.2s ease-in",
                    keyframes([
                        style({ opacity: 0, transform: "translateX(-100px)", offset: 0 }),
                        style({ opacity: 1, transform: "translateX(0)", offset: 1.0 })
                    ])
                )
            ]),
            transition(":leave", [
                animate(
                    "0.2s ease-in",
                    keyframes([
                        style({ opacity: 1, transform: "translateX(0)", offset: 0 }),
                        style({ opacity: 0, transform: "translateX(-100px)", offset: 1.0 })
                    ])
                )
            ])
        ])
    ]
})
export class BaseNavbarComponent implements OnDestroy {
    @HostBinding("class.profile1") useProfile1: boolean = false
    usuario_nombre: string
    usuario_apellido_paterno: string
    usuario_id: string
    usuario_avatar: string
    usuario_avatar_safe: SafeResourceUrl
    userUpdateSubscription: Subscription
    contactMail: string
    companyName: string
    showTopmenu: boolean = false
    logo: string
    logoMobile: string
    @Input() enableMenu: boolean = config.app.menuConfig?.tipo == "showcasebox"
    nivel: string
    ptosToNextLvl: number = 0
    config: typeof config

    @Input() showNotificaciones: boolean = true
    @Input() showAyuda: boolean = config.plataforma.showAyuda || false
    @Input() showUserID: boolean = false
    @Input() showMenuHorizontal: boolean = config.app.menuConfig?.tipo == "horizontal"

    constructor(
        protected authService: AuthService,
        protected router: Router,
        protected sanitizer: DomSanitizer,
        protected tabService: TabService,
        protected sessionService: SessionService
    ) {
        this.useProfile1 = this.authService.userProfile == "profile1"
        let usuario = this.authService.getUserData()
        this.usuario_id = usuario.id ? usuario.id + "" : "1"
        this.usuario_nombre = usuario.nombre ? usuario.nombre + "" : null
        this.usuario_apellido_paterno = usuario.apellido_paterno ? usuario.apellido_paterno + "" : null
        this.usuario_avatar = usuario.avatar ? usuario.avatar + "" : undefined
        this.usuario_avatar_safe = this.sanitize(this.usuario_avatar)
        this.contactMail = config.plataforma.info.contacto.mail
        this.companyName = config.plataforma.info.companyName
        this.userUpdateSubscription = this.sessionService.userDataUpdated.subscribe(usuario => {
            this.updateUserData(usuario)
        })

        this.config = config
        this.logo = this.config.app.baseStyle?.navbar?.logo
            ? this.config.app.assets[this.config.app.baseStyle?.navbar?.logo]
            : this.config.app.assets.logoSmall
        this.logoMobile = this.config.app.baseStyle?.navbar?.logoMobile
            ? this.config.app.assets[this.config.app.baseStyle?.navbar?.logoMobile]
            : this.config.app.assets.logoSquare

        if (!this.showMenuHorizontal && !this.enableMenu) {
            this.enableMenu = true
        }
    }

    logout() {
        this.authService.logout()
        let host = window.location.hostname
        var new_host = host
        // Redirect the user

        this.router.navigate(["/landing"])
    }

    edit() {
        if (this.usuario_id != null) {
            this.router.navigate(["/usuarios/editar"])
        }
    }

    goToProfile() {
        if (this.usuario_id != null) {
            this.tabService.setComponentTab("UsuarioComponent", "perfil")
            this.router.navigate(["/usuarios/" + this.usuario_id])
        }
    }

    sanitize(input: string): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(input)
    }

    updateUserData(usuario) {
        //usuario = this.authService.getUserData();
        this.usuario_id = usuario.id ? usuario.id + "" : "1"
        this.usuario_nombre = usuario.nombre ? usuario.nombre + "" : null
        this.usuario_apellido_paterno = usuario.apellido_paterno ? usuario.apellido_paterno + "" : null
        this.usuario_avatar = usuario.avatar ? usuario.avatar + "" : null
    }

    ngOnDestroy() {
        this.userUpdateSubscription.unsubscribe()
    }

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }

    toggleTopmenu() {
        this.showTopmenu = !this.showTopmenu
    }

    goToAyuda() {
        this.router.navigate(["/ayudas"])
    }

    setNivel(event) {
        this.nivel = event.nivel
        this.ptosToNextLvl = event.ptosToNextLvl
    }
}
