/** @format */

import { ModuleWithProviders, NgModule } from "@angular/core"
import { Alternativas } from "./alternativas.service"
import { Asignaturas } from "./asignaturas.service"
import { AsignaturaPlataformas } from "./asignatura_plataformas.service"
import { Ayudas } from "./ayudas.service"
import { CertificacionRecursos } from "./certificacion_recursos.service"
import { Clasificaciones } from "./clasificaciones.service"
import { ClasificacionClasificaciones } from "./clasificacion_clasificaciones.service"
import { ClasificacionMateriales } from "./clasificacion_materiales.service"
import { ClasificacionPreguntas } from "./clasificacion_preguntas.service"
import { ClasificacionTipos } from "./clasificacion_tipos.service"
import { ClasificacionTipoAliases } from "./clasificacion_tipo_aliases.service"
import { ContestableTipos } from "./contestable_tipos.service"
import { Curriculums } from "./curriculums.service"
import { EstadoReportes } from "./estado_reportes.service"
import { GeneradorInstrumentos } from "./generador_instrumentos.service"
import { GeneradorInstrumentoConversiones } from "./generador_instrumento_conversiones.service"
import { GeneradorInstrumentoInstrucciones } from "./generador_instrumento_instrucciones.service"
import { GrupoPreguntas } from "./grupo_preguntas.service"
import { AdminGuard } from "./guards/admin.guard"
import { AsignaturasGuard } from "./guards/asignaturas.guard"
import { LoteGuard } from "./guards/lote.guard"
import { MaterialGuard } from "./guards/material.guard"
import { PreguntaGuard } from "./guards/pregunta.guard"
import { Instrucciones } from "./instrucciones.service"
import { Lotes } from "./lotes.service"
import { LotePreguntas } from "./lote_preguntas.service"
import { Materiales } from "./materiales.service"
import { MaterialTipos } from "./material_tipos.service"
import { MonitorPreguntas } from "./monitor_preguntas.service"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"
import { Perfiles } from "./perfiles.service"
import { Personas } from "./personas.service"
import { TransformInstrumentoPipe } from "./pipes/tipo_instrumento.pipe"
import { PlanEstudios } from "./plan_estudios.service"
import { PlanEstudioLotes } from "./plan_estudio_lotes.service"
import { PlanEstudioMateriales } from "./plan_estudio_materiales.service"
import { PlanEstudioSesiones } from "./plan_estudio_sesiones.service"
import { Plataformas } from "./plataformas.service"
import { Preguntas } from "./preguntas.service"
import { PreguntaCambiadas } from "./pregunta_cambiadas.service"
import { Reportes } from "./reportes.service"
import { ReporteRazones } from "./reporte_razones.service"
import { ReporteSubRazones } from "./reporte_sub_razones.service"
import { RevisionRecursos } from "./revision_recursos.service"
import { StreamingHorarios } from "./streaming_horarios.service"
import { TipoInstrumentos } from "./tipo_instrumentos.service"
import { TipoInstrumentoPlataformas } from "./tipo_instrumento_plataformas.service"
import { Usuarios } from "./usuarios.service"
import { UsuarioPerfiles } from "./usuario_perfiles.service"
import { UsuarioRecursos } from "./usuario_recursos.service"
import { UsuarioRecursoTipos } from "./usuario_recurso_tipos.services"
import { UsuarioUsuarios } from "./usuario_usuarios.service"

@NgModule({
    imports: [],
    declarations: [TransformInstrumentoPipe],
    exports: [TransformInstrumentoPipe]
})
export class NebuModule {
    static forRoot(): ModuleWithProviders<NebuModule> {
        return {
            ngModule: NebuModule,
            providers: [
                TransformInstrumentoPipe,
                AdminGuard,
                AsignaturasGuard,
                MaterialGuard,
                PreguntaGuard,
                LoteGuard,
                Asignaturas,
                AsignaturaPlataformas,
                Ayudas,
                Clasificaciones,
                ClasificacionClasificaciones,
                ClasificacionMateriales,
                ClasificacionPreguntas,
                ClasificacionTipos,
                ClasificacionTipoAliases,
                Curriculums,
                EstadoReportes,
                GeneradorInstrumentos,
                GeneradorInstrumentoInstrucciones,
                GeneradorInstrumentoConversiones,
                GrupoPreguntas,
                Instrucciones,
                Lotes,
                LotePreguntas,
                Materiales,
                MaterialTipos,
                MonitorPreguntas,
                NebuAuthService,
                NebuBaseService,
                Perfiles,
                Personas,
                PlanEstudios,
                PlanEstudioLotes,
                PlanEstudioMateriales,
                PlanEstudioSesiones,
                Plataformas,
                Preguntas,
                PreguntaCambiadas,
                Alternativas,
                Reportes,
                ReporteRazones,
                ReporteSubRazones,
                StreamingHorarios,
                TipoInstrumentos,
                TipoInstrumentoPlataformas,
                Usuarios,
                UsuarioPerfiles,
                UsuarioUsuarios,
                UsuarioRecursoTipos,
                RevisionRecursos,
                UsuarioUsuarios,
                UsuarioRecursos,
                CertificacionRecursos,
                ContestableTipos
            ]
        }
    }
}
