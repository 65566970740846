<div *ngIf="!multipleBars">
    <div class="flex-ponderaciones" [class.flex-wrap]="!visibleLabels">
        <mi-ponderacion [ponderacion]="promedioPonderado" [simulacion]="simulacionPonderado"></mi-ponderacion>
        <ponderaciones-bar
            [carreraSede]="carreraSede"
            [visibleLabels]="visibleLabels"
            class="barra-ponderaciones"
            [ponderablesBarra]="ponderablesBarra"
            [class.hide-child-labels]="homeView"
        ></ponderaciones-bar>
    </div>
</div>

<div *ngIf="multipleBars">
    <div class="flex-ponderaciones" [class.flex-wrap]="!visibleLabels">
        <mi-ponderacion
            [ponderacion]="promedioPonderadoHistoria"
            [simulacion]="simulacionPonderadoHistoria"
        ></mi-ponderacion>
        <ponderaciones-bar
            [carreraSede]="carreraSede"
            [visibleLabels]="visibleLabels"
            class="barra-ponderaciones"
            [ponderablesBarra]="ponderablesBarraHistoria"
            [class.hide-child-labels]="homeView"
        ></ponderaciones-bar>
    </div>
    <br />
    <div class="flex-ponderaciones" [class.flex-wrap]="!visibleLabels">
        <mi-ponderacion
            [ponderacion]="promedioPonderadoCiencia"
            [simulacion]="simulacionPonderadoCiencia"
        ></mi-ponderacion>
        <ponderaciones-bar
            [carreraSede]="carreraSede"
            [visibleLabels]="visibleLabels"
            class="barra-ponderaciones"
            [ponderablesBarra]="ponderablesBarraCiencia"
            [class.hide-child-labels]="homeView"
        ></ponderaciones-bar>
    </div>

    <div *ngIf="!disableLegenda && !homeView" class="legenda-hist-cs">
        <i>
            *Esta carrera requiere prueba de Historia y Ciencias Sociales o de Ciencias. En este caso el puntaje
            ponderado se calculará con el puntaje más alto, en caso de haber rendido ambas pruebas.
        </i>
    </div>
</div>
