import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, ViewChild, Output } from "@angular/core"
import { select, Store } from "@ngrx/store"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import {
    EscalaGlobal,
    EvaluacionInstancia,
    EvaluacionInstancias,
    EvaluacionMultiple,
    EvaluacionMultiples
} from "@puntaje/puntaje/api-services"
import { selectAsignaturasById, State } from "@puntaje/puntaje/store"
import { Observable, Subscription } from "rxjs"
import { filter } from "rxjs/operators"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { EvaluacionInstanciaEscalasService } from "./evaluacion_instancia_escalas.service"

@Component({
    selector: "ensayo-instancia-resumen",
    templateUrl: "ensayo_instancia_resumen.component.html",
    styleUrls: ["ensayo_instancia_resumen.component.scss"]
})
export class EnsayoInstanciaResumenComponent implements OnInit {
    @Input() rutaVerEvaluacionesMultiples: string
    @Input() evaluacionInstancia: EvaluacionInstancia
    @ViewChild("lateralLoadingLayout", { static: true }) lateralLoadingLayout: LoadingLayoutComponent
    @Input() tipoEvaluacion: string
    @Input() tipoEvaluacionParaTexto: string
    @Input() porcentaje: boolean = false
    @Input() disableOptions: boolean = false
    @Input() rutaVerEvaluaciones: string
    @Input() enableCompartirResultados: boolean = false
    @Input() volverARealizar = true
    @Input() realizaNuevo = false
    @Input() rutaVolverARealizar: string = "/ensayos"
    @Output() tabSelected = new EventEmitter<string>()
    openModal: EventEmitter<any> = new EventEmitter<any>()
    backButton: boolean = false
    backButtonUrl: string = ""
    backButtonText: string = ""
    lista_asignaturas: any = []
    nombreAsignatura: string = ""
    quantityPilotaje: number

    calificacion: number
    existenEscalasGlobales: boolean = null

    sub: Subscription
    asignaturasById$: Observable<{ [id: number]: AsignaturaWithConfig }> = this.store.pipe(
        select(selectAsignaturasById),
        filter(x => !!x)
    )

    nombreTipoCalificacion: string
    layaoutResultadoCalificacion: "horizontal" | "vertical"

    escalasGlobalesAliases: EscalaGlobal[]

    config: typeof config

    constructor(
        protected store: Store<State>,
        protected evaluacionMultiplesService: EvaluacionMultiples,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected evaluacionInstanciaEscalasService: EvaluacionInstanciaEscalasService,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.config = config
        this.tipoEvaluacion = this.tipoEvaluacion || this.evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo

        this.nombreTipoCalificacion =
            config.evaluaciones[this.evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo]
                .nombreTipoCalificacion || "puntos"

        this.layaoutResultadoCalificacion =
            config.evaluaciones[this.evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo]
                .layaoutResultadoCalificacion || "vertical"

        this.sub = this.asignaturasById$.subscribe(asignaturasById => {
            this.nombreAsignatura =
                asignaturasById[this.evaluacionInstancia.evaluacion.instrumento.asignatura_id].asignaturaPlataforma
        })

        this.calificacion = this.evaluacionInstancia.calificacion
        this.quantityPilotaje = this.evaluacionInstancia.evaluacion.instrumento.instrumento_preguntas.reduce(
            (acc, ip) => acc + (ip.piloto ? 1 : 0),
            0
        )

        this.lateralLoadingLayout.ready()

        this.evaluacionInstanciaEscalasService
            .getEscalas(this.evaluacionInstancia)
            .then((escalas: EscalaGlobal[]) => (this.escalasGlobalesAliases = escalas))
    }

    porcentajeCorrectas(ei: EvaluacionInstancia) {
        return (ei.correctas / (ei.correctas + ei.incorrectas + ei.omitidas)) * 100
    }

    setBackButton(url, text) {
        this.backButton = true
        this.backButtonUrl = url
        this.backButtonText = text
    }

    clearButton() {
        this.backButton = false
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    selectTab(tab) {
        this.tabSelected.emit(tab)
    }
}
