import { Component, Input, ViewChild, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { GeneradorInstrumentos, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { Evaluaciones } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({ template: "" })
//export abstract class AbstractGenerarEnsayoComponent implements OnInit {
export class AbstractGenerarEnsayoComponent implements OnInit {
    @Input() asignaturaId: number
    @ViewChild("loadingLayout", /* TODO: add static flag */ { static: true }) loadingLayout: LoadingLayoutComponent

    generadorInstrumentos: GeneradorInstrumento[]
    @Input() generadorInstrumento: GeneradorInstrumento

    numeroPreguntas: number
    tiempo: number

    clasificacionesIds: number[]

    show_contenidos: boolean

    constructor(
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected evaluacionesService: Evaluaciones
    ) {}

    ngOnInit() {
        //this.loadingLayout.ready();
    }

    onReady() {}

    generarEnsayo() {}

    toggleContenidos() {
        //this.show_contenidos = !this.show_contenidos;
    }

    obtainGeneradorInstrumentos() {
        /*this.generadorInstrumentosService.where({generador_instrumento: {asignatura_id: this.asignaturaId}}).then((gis: GeneradorInstrumento[]) => {
			this.generadorInstrumentos = gis;
			gis.length > 0 && (this.generadorInstrumentoId = gis[0].id);
		});*/
    }
}
