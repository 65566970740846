import { Component, Input, SimpleChanges, OnInit, ViewChild } from "@angular/core"
import { Asignatura, Asignaturas, ClasificacionTipos } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { EstadisticasEvolutivasService } from "./estadisticas_evolutivas.service"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter, last, first } from "rxjs/operators"

@Component({
    selector: "desempeno-asignaturas",
    templateUrl: "desempeno_asignaturas.component.html",
    styleUrls: ["desempeno_asignaturas.component.scss"]
})
export class DesempenoAsignaturasComponent implements OnInit {
    @Input() enableResponsive: boolean = false
    @Input() tipoEvaluacion: string
    //lista_asignaturas: any;
    listaAsignaturas: any[]
    firstColumn: any[]
    secondColumn: any[]
    @Input() timeFrame: Date[]
    timeFrameUTC: number[]
    @Input() demoEnabled = false
    @Input() userIds: number[]
    @Input() grupoUserIds: number[] = []
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(
        protected store: Store<State>,
        protected estadisticasEvolutivasService: EstadisticasEvolutivasService
    ) {}

    ngOnInit() {
        var fechaTermino = new Date()
        var fechaInicio = new Date()
        fechaInicio.setMonth(fechaInicio.getMonth() - 12)
        this.timeFrame = [fechaInicio, fechaTermino]
        this.timeFrameUTC = [this.timeFrame[0].getTime(), this.timeFrame[1].getTime()]
    }

    ngOnChanges(changes: SimpleChanges) {
        var check = false
        var grupo = false
        if (changes["tipoEvaluacion"]) {
            check = true
        }
        if (changes["timeFrame"]) {
            this.timeFrameUTC = [this.timeFrame[0].getTime(), this.timeFrame[1].getTime()]
            check = true
        }
        if (changes["userIds"] || changes["grupoUserIds"]) {
            check = true
            if (changes["grupoUserIds"]) {
                grupo = true
            }
        }
        if (check) {
            this.setAsignaturas(grupo)
        }
    }

    async setAsignaturas(isGrupo: boolean) {
        this.loadingLayout.standby()
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.pipe(first()).toPromise()
        this.listaAsignaturas = asignaturasByEvaluacionTipo[
            this.tipoEvaluacion || config.plataforma.evaluacionDefault
        ].map(a => Object.assign({}, a, { selected: true }))
        let lista_asignaturas = asignaturasByEvaluacionTipo[this.tipoEvaluacion || config.plataforma.evaluacionDefault]
        //this.listaAsignaturas = lista_asignaturas;
        this.estadisticasEvolutivasService
            .getAsignaturasConGeneradores(this.tipoEvaluacion || config.plataforma.evaluacionDefault)
            .then((response: Asignatura[]) => {
                this.listaAsignaturas = lista_asignaturas.map(a => {
                    response.forEach(asig => {
                        if (asig.id == a.id) {
                            asig.asignatura_plataformas.forEach(ap => {
                                if (!a["generadores"]) {
                                    a["generadores"] = []
                                }
                                a["generadores"] = ap.generador_instrumentos.map(a =>
                                    Object.assign({}, a, { selected: true })
                                )
                            })
                        }
                    })
                    return Object.assign({}, a, { selected: true })
                })
                this.getData(isGrupo)
            })
    }

    getData(isGrupo: boolean) {
        let ids = this.listaAsignaturas.reduce((accum, a) => accum.concat(a.generadores.map(g => g.id)), [])
        let from =
            this.timeFrame[0].getDate() +
            "-" +
            (this.timeFrame[0].getMonth() + 1) +
            "-" +
            this.timeFrame[0].getFullYear()
        let to =
            this.timeFrame[1].getDate() +
            "-" +
            (this.timeFrame[1].getMonth() + 1) +
            "-" +
            this.timeFrame[1].getFullYear()
        var p: Promise<any>
        if (isGrupo) {
            p = this.estadisticasEvolutivasService.getEstadisticasInstanciaClasificacionesGrupo(
                ids,
                from,
                to,
                this.tipoEvaluacion,
                this.grupoUserIds
            )
        } else {
            p = this.estadisticasEvolutivasService.getEstadisticasInstanciaClasificaciones(
                ids,
                from,
                to,
                this.tipoEvaluacion,
                this.userIds
            )
        }
        p.then(response => {
            this.listaAsignaturas.forEach(a => {
                let cls = response[a.id]
                var cls2 = []
                var total_correctas = 0
                var total_incorrectas = 0
                var total_omitidas = 0
                if (cls) {
                    cls2 = Object.keys(cls).map(cl => {
                        total_correctas = total_correctas + cls[cl]["correctas"]
                        total_incorrectas = total_incorrectas + cls[cl]["incorrectas"]
                        total_omitidas = total_omitidas + cls[cl]["omitidas"]
                        return cls[cl]
                    })
                }
                a.estadistica_clasificaciones = {
                    valores: [total_correctas, total_incorrectas, total_omitidas],
                    clasificaciones: cls2
                }
            })
            this.setColumns()
            this.loadingLayout.ready()
        })
    }

    setColumns() {
        let indexToSplit = this.listaAsignaturas.length / 2
        this.firstColumn = this.listaAsignaturas.slice(0, indexToSplit)
        this.secondColumn = this.listaAsignaturas.slice(indexToSplit)
    }
}
