import { Component, OnInit, OnDestroy, ViewChild, Input, Injector } from "@angular/core"
import { Usuario, UsuarioView, PreferenciaCarrera } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "usuario",
    templateUrl: "usuario.component.html",
    styleUrls: ["usuario.component.scss"]
})
export class UsuarioComponent implements OnInit {
    @Input("usuario") usuario: Usuario
    @Input() preferencia_carreras: PreferenciaCarrera[]
    params: typeof UsuarioView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    pais: string
    idPais: any
    idPaisAliasMap: { [key: string]: string } = config.plataforma.identificadorUsuarioAlias
    @Input() enableShowCountryData: boolean = false
    @Input() enableShowEstablecimientoData: boolean = true
    @Input() enableShowExtraData: boolean = false
    usuarioId: number
    isPrivate: boolean = true
    showUserId: boolean = false
    curso: string

    constructor(
        protected authService: AuthService,
        protected clasificacionesService: Clasificaciones,
        protected injector: Injector
    ) {
        this.usuarioId = this.authService.getUserData().id
        this.showUserId = config.plataforma.showUserId

        UsuarioView.injector = injector
        this.params = UsuarioView.viewParams
    }

    async ngOnInit() {
        this.pais = config.plataforma.pais
        this.idPais = config.plataforma.identificadorUsuario
        this.isPrivate = !(this.usuarioId == this.usuario.id)
        if (this.usuario.nivel_id !== undefined || this.usuario.nivel_id !== null) {
            let clasificaciones: Clasificacion[] = await this.clasificacionesService.where({
                clasificacion: { id: this.usuario.nivel_id }
            })
            if (clasificaciones.length > 0) this.curso = clasificaciones[0].clasificacion
        }
        this.loadingLayout.ready()
    }
}
