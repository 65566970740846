import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    Injector,
    ChangeDetectorRef,
    Directive
} from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Usuario,
    Usuarios,
    UsuariosCompletarRegistroForm,
    GrupoUsuarios,
    UsuariosCompletarRegistroSocialForm,
    Establecimientos,
    Lugares,
    Lugar,
    Establecimiento
} from "@puntaje/puntaje/api-services"
import { AuthService, GenericModalComponent, BaseForm, SessionService, AppEnvironment } from "@puntaje/shared/core"

import { BaseRegisterCompletarComponent } from "./base_register_completar.component"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
declare const environment: AppEnvironment
declare const gtag: any
import { Clasificaciones, Clasificacion } from "@puntaje/nebulosa/api-services"

@Directive()
export class BaseRegisterCompletarSocialComponent extends BaseRegisterCompletarComponent {
    formType: typeof BaseForm = UsuariosCompletarRegistroSocialForm
    params: any = this.formType.formParams
    usuarioPaisParams: any = this.params["usuario_" + this.pais].class.formParams

    egresado: Clasificacion
    filteredEstablecimientos: Establecimiento[]
    filteredLugarColegio: Lugar[]

    lugarColegio: Lugar

    lugarTipo: string

    constructor(
        usuariosService: Usuarios,
        authService: AuthService,
        sessionService: SessionService,
        router: Router,
        injector: Injector,
        grupoUsuariosService: GrupoUsuarios,
        cdr: ChangeDetectorRef,
        protected establecimientosService: Establecimientos,
        protected lugaresService: Lugares,
        protected clasificacionesService: Clasificaciones
    ) {
        super(
            usuariosService,
            authService,
            sessionService,
            router,
            injector,
            grupoUsuariosService,
            cdr,
            lugaresService,
            establecimientosService
        )
        UsuariosCompletarRegistroSocialForm.injector = this.injector
        this.params = UsuariosCompletarRegistroSocialForm.formParams
    }

    ngOnInit() {
        super.ngOnInit()

        this.lugarTipo = config.plataforma.lugarTipoColegio || "Comuna"

        const nivelTipo = {
            chile: "curso",
            colombia: "nivel colombia",
            mexico: "Curso MX",
            brasil: "curso"
        }

        this.clasificacionesService
            .where({
                clasificacion_tipo: { clasificacion_tipo: nivelTipo[this.pais] },
                clasificacion: { clasificacion: "Egresado" }
            })
            .then((clasificaciones: Clasificacion[]) => {
                this.egresado = clasificaciones[0]
            })
    }

    save() {
        if ((this.usuario as any).egresado) {
            this.usuario.nivel_id = this.egresado.id
        }

        const savePromise = super.save()

        if (savePromise) {
            savePromise.then(() => {
                if (
                    environment.globalSiteTag &&
                    environment.globalSiteTag[config.app.name] &&
                    environment.globalSiteTag[config.app.name].sendTo &&
                    environment.globalSiteTag[config.app.name].sendTo.registro_social
                ) {
                    gtag?.("event", "conversion", {
                        send_to: environment.globalSiteTag[config.app.name].sendTo.registro_social
                    })
                }
            })
        }
    }

    getEstablecimientos(establecimientoFilter: string) {
        let lugares = []
        if (this.lugarColegio) lugares = [this.lugarColegio.id]
        this.establecimientosService
            .where({
                per: 100,
                raw: 1,
                establecimiento: { establecimiento: establecimientoFilter, lugar_id: this.lugarColegio.id }
            })
            .then((establecimientos: Establecimiento[]) => {
                this.filteredEstablecimientos = establecimientos
            })
    }

    getLugarColegio(lugarFilter: string) {
        this.lugaresService
            .where({ per: 100, raw: 1, filter: lugarFilter, lugar_tipo: [this.lugarTipo] })
            .then((lugares: Lugar[]) => {
                this.filteredLugarColegio = lugares
            })
    }

    updateColegio(establecimiento: Establecimiento) {
        this.usuario.establecimiento_id = establecimiento.id
    }

    updateLugar(lugar: any) {
        if (lugar instanceof Lugar) {
            this.usuario.lugar_id = lugar.id
        }
    }

    updateLugarColegio(lugar: any) {
        if (lugar instanceof Lugar) {
            this.lugarColegio = lugar
        }
    }
}
