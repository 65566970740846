import { Component, EventEmitter, OnInit, ViewChild } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { NgModel } from "@angular/forms"
import { Router } from "@angular/router"
import { AuthService, GenericModalComponent, I18nService, Validations } from "@puntaje/shared/core"
import { Usuario, Usuarios } from "@puntaje/puntaje/api-services"
import { Login } from "@puntaje/puntaje/services"
import { Subscription } from "rxjs"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "request-reset-password",
    templateUrl: "./request-reset-password.component.html",
    styleUrls: ["./request-reset-password.component.scss"]
})
export class RequestResetPasswordComponent implements OnInit {
    usuario: Usuario = new Usuario()
    usuario_id: number
    current_password: string
    password: string
    password_confirmation: string
    hasPassword: boolean
    userData: any
    currentPaswordMsg: string
    passwordConfirmationMsg: string
    passwordMsg: string
    invalidPassword: boolean = false
    passwordMinLength: number = 8

    checkingSubscription: Subscription
    breachedPassword: boolean = false

    @ViewChild("passwordControl") passwordControl: NgModel
    openModal: EventEmitter<any> = new EventEmitter<any>()
    sub: Subscription
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent
    loading: boolean = false
    success: boolean = false

    constructor(
        protected authService: AuthService,
        protected usuariosService: Usuarios,
        protected router: Router,
        protected http: HttpClient,
        protected loginService: Login,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {
        this.sub = this.loginService.mandatoryPasswordChange.subscribe(() => {
            this.userData = this.authService.getUserData()
            this.hasPassword = this.userData.has_password
            this.usuario_id = this.userData.id
            if (this.loadingLayout) this.loadingLayout.ready()
            this.openModal.emit()
        })
    }

    async changePassword() {
        if (this.loadingLayout) this.loadingLayout.standby()
        this.loading = true
        this.usuario.id = this.usuario_id

        await this.isBreachedPassword(this.passwordControl)

        if (
            this.validPassword(this.password) &&
            this.validPassword(this.password_confirmation) &&
            !this.breachedPassword
        ) {
            if (this.hasPassword) {
                this.usuario["current_password"] = this.current_password
            }
            this.usuario["password"] = this.password
            this.usuario["password_confirmation"] = this.password_confirmation
            this.usuario["manual_reset_password"] = false
            this.updateUser()
        } else {
            if (!this.validPassword(this.password) || !this.validPassword(this.password_confirmation)) {
                this.invalidPassword = true
            }

            this.loading = false
            if (this.loadingLayout) this.loadingLayout.ready()
        }
    }

    validPassword(password) {
        return password && password.length >= this.passwordMinLength
    }

    isBreachedPassword(control) {
        const validator = Validations.checkPasswordBreach(this.http)

        return new Promise((resolve, reject) => {
            this.checkingSubscription?.unsubscribe()

            this.checkingSubscription = validator(control).subscribe(validation => {
                this.breachedPassword = validation?.breachedPassword?.valid == false ? true : false // checkeo si es falso porque si es null, es como si fuese valid == true

                resolve(this.breachedPassword)
            })
        })
    }

    updateUser = () => {
        this.currentPaswordMsg = ""
        this.passwordConfirmationMsg = ""

        this.usuariosService.enableIgnoreCatch()
        this.usuariosService
            .updatePassword(this.usuario_id, this.usuario)
            .then(response => {
                this.userData.has_password = true
                this.userData.manual_reset_password = false
                this.authService.setUserData(this.userData)
                this.onPasswordUpdate()
            })
            .catch(response => {
                this.loading = false
                if (this.loadingLayout) this.loadingLayout.ready()
                if (response && response.status == 422) {
                    if (response.error.current_password) {
                        if (response.error.current_password[0] == `can't be blank`) {
                            this.currentPaswordMsg = this.i18nService.translate(
                                "request_reset_password.error_vacia_password_actual"
                            )
                        } else if (response.error.current_password[0] == `is invalid`) {
                            this.currentPaswordMsg = this.i18nService.translate(
                                "request_reset_password.error_invalida_password_actual"
                            )
                        }
                    } else {
                        this.currentPaswordMsg = ""
                    }

                    if (
                        response.error.password_confirmation &&
                        response.error.password_confirmation[0] == `doesn't match Password`
                    ) {
                        this.passwordConfirmationMsg = this.i18nService.translate(
                            "request_reset_password.error_match_password_confirmar"
                        )
                    } else {
                        this.passwordConfirmationMsg = ""
                    }
                } else {
                    throw new Error("Error " + response.status)
                }
            })
    }

    onPasswordUpdate() {
        this.loading = false
        if (this.loadingLayout) this.loadingLayout.ready()
        this.success = true
        setTimeout(() => {
            this.genericModal.close()
        }, 4000)
        setTimeout(() => {
            this.loginService.executeDelayedNavigation()
        }, 5000)
    }

    ngOnDestroy(): void {
        if (this.sub) this.sub.unsubscribe()
        if (this.checkingSubscription) this.checkingSubscription.unsubscribe()
    }
}
