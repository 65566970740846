import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { PlanEstudio } from "@puntaje/nebulosa/api-services"
import { PlanPersonal } from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "plan_personal_sesion.component.html"
})
export class PlanPersonalSesionComponent {
    sub: any
    plan_personal_id: number
    sesion_id: number
    ciclo: number
    sesiones_route: string

    titulo: string

    subData: Subscription

    enableDudas = false
    enableReportes = false
    enableNavigateToPlan = false
    backAtEnd = false
    routeWithId = false
    planesPersonalesRoute: string
    enableUnrestrictedProgressionMode = false
    planPersonal: PlanPersonal

    unrestrictedProgressionMode = false
    goToDiagnosticoAtEnd = false

    constructor(protected titleService: TitleService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.plan_personal_sesion")
        this.sub = this.route.params.subscribe(params => {
            this.plan_personal_id = +params["id"]
            this.sesion_id = +params["sesion_id"]
            this.ciclo = +params["ciclo"]
            this.sesiones_route = "/plan_personal/" + this.plan_personal_id + "/ciclo" + this.ciclo + "/sesiones"
        })

        this.subData = this.route.data.subscribe(data => {
            this.enableDudas = data.enableDudas ?? this.enableDudas
            this.enableReportes = data.enableReportes ?? this.enableReportes
            this.enableNavigateToPlan = data.enableNavigateToPlan ?? this.enableNavigateToPlan
            this.backAtEnd = data.backAtEnd ?? this.backAtEnd
            this.routeWithId = data.routeWithId ?? this.routeWithId
            this.planesPersonalesRoute = data.planesPersonalesRoute ?? this.planesPersonalesRoute
            this.enableUnrestrictedProgressionMode =
                data.enableUnrestrictedProgressionMode ?? this.enableUnrestrictedProgressionMode
            this.goToDiagnosticoAtEnd = data.goToDiagnosticoAtEnd ?? this.goToDiagnosticoAtEnd
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    onPlanPersonalChange(planPersonal) {
        const nombreTituloSesion = {
            planificacion: "Clase"
        }

        this.titulo = nombreTituloSesion[planPersonal.plan_personal_tipo.tipo]

        this.planPersonal = planPersonal
        if (
            this.enableUnrestrictedProgressionMode &&
            this.planPersonal.plan_personal_tipo &&
            this.planPersonal.plan_personal_tipo.tipo == "plan de clase"
        ) {
            this.unrestrictedProgressionMode = true
        }
    }
}
