<div
    class="sesion-container"
    *ngIf="planPersonalSesion"
    [class.pointLeft]="!pointRight"
    [class.pointRight]="pointRight"
    [class.pendiente]="!actual && !isDone"
    [class.actual]="actual"
    [class.completada]="!actual && (isDone || previewMode)"
    [class.active]="!actual && (isDone || avance) > 0"
>
    <div class="sesion-grid">
        <div class="info">
            <h2 class="titulo-sesion">
                {{ "estudiar.plan_personales.plan_personal.titulo_sesion" | t }}
                {{ planPersonalCiclo * 3 + planPersonalSesion.orden + 1 }}
            </h2>
            <span *ngIf="actual" class="label label-success" title="Estudia hasta acá">
                {{ "estudiar.plan_personales.plan_personal.clase_actual" | t }}
                <fa name="question-circle"></fa>
            </span>
            <label id="progressbar-label-{{ index }}" class="avance">
                {{ "estudiar.plan_personales.plan_personal.avance" | t }}
            </label>
            <div class="progressbar">
                <progressbar
                    id="progressbar-{{ planPersonalSesion.id }}"
                    class="active"
                    [value]="animatedProgressBarValue"
                    [type]="'success'"
                >
                    <i>{{ animatedProgressBarValue | percentage }}</i>
                </progressbar>
            </div>
            <div class="bottom-info">
                <div class="bottom-info-row">
                    <div *ngIf="!disableCalificacion" class="bottom-info-cell">
                        <p class="resultados-calificacion" *ngIf="calificacion >= 0">
                            {{ calificacion | number: "1.0-1" }}%
                        </p>
                        <p class="resultados-pendiente" *ngIf="!isDone && calificacion == 0">
                            {{ "estudiar.plan_personales.plan_personal.pendiente" | t }}
                        </p>
                        <small>{{ "estudiar.plan_personales.plan_personal.resultados" | t }}</small>
                    </div>
                    <div class="bottom-info-cell">
                        <button
                            type="button"
                            class="start-btn"
                            [class.active]="isDone || avance > 0"
                            (click)="navigateToSesion()"
                        >
                            <span *ngIf="!isDone && avance == 0">
                                {{ "estudiar.plan_personales.plan_personal.comenzar" | t }}
                            </span>
                            <span *ngIf="!isDone && avance > 0">
                                {{ "estudiar.plan_personales.plan_personal.continuar" | t }}
                            </span>
                            <span *ngIf="isDone">{{ "estudiar.plan_personales.plan_personal.repasar" | t }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="tipo">
            <ogr-icon name="libro2-o" class="icono"></ogr-icon>
            <p>{{ "estudiar.plan_personales.plan_personal.subtitulo_sesion" | t }}</p>
        </div>
    </div>
</div>
