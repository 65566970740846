import { Component, ViewEncapsulation, Input } from "@angular/core"

@Component({
    selector: "cui-section-headline",
    template: `
        <h2 [attr.role]="role" [attr.aria-level]="ariaLevel" class="h3_sub_title_1"><ng-content></ng-content></h2>
    `,
    styleUrls: ["cui-section-headline.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUISectionHeadlineComponent {
    @Input() role: string
    @Input() ariaLevel: string
    constructor() {}
}
