import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Clasificacion } from "./clasificaciones.model"
import { Clasificaciones } from "./clasificaciones.service"
import { Material } from "./materiales.model"
import { Materiales } from "./materiales.service"

export class ClasificacionMaterial extends BaseModel {
    public static className: string = "ClasificacionMaterial"
    public material_id: number
    public clasificacion_id: number
    public created_at: Date
    public updated_at: Date

    @SaveType(() => Clasificacion) public clasificacion: Clasificacion
    @SaveType(() => Material) public material: Material

    constructor() {
        super()

        this.clasificacion = new Clasificacion()
        this.material = new Material()
    }

    public static get show_params(): any {
        return {
            id: { label: "Id", type: "text", tableVisible: true },
            material_id: {
                label: "Material",
                type: "modelLink",
                tableVisible: true,
                options: { class: Materiales, key: "id", modelKey: "material" }
            },
            clasificacion_id: {
                label: "Clasificación",
                type: "modelLink",
                tableVisible: false,
                options: { class: Clasificaciones, key: "id", modelKey: "clasificacion" }
            },
            created_at: { label: "Fecha de creación", type: "date", tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: "date", tableVisible: true }
        }
    }

    public toString() {
        return this.clasificacion_id.toString()
    }
}
