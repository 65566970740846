<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <form *ngIf="usuarioAvatarEdit" novalidate [formGroup]="formAvatar">
                <cui-section-headline>{{ "usuarios_perfil_edit.foto_perfil" | t }}</cui-section-headline>
                <div class="avatar-edit-container">
                    <cui-button class="edit-avatar-btn" (click)="toggleAvatarEdit($event)">
                        {{ "usuarios_perfil_edit.cambiar_imagen" | t }}
                    </cui-button>
                    <div *ngIf="usuarioAvatarEdit" class="avatar-container">
                        <loading-layout #loadingLayoutAvatar [opaque]="true" [loading]="false">
                            <img
                                class="avatar"
                                alt="avatar"
                                *ngIf="usuarioAvatarEdit.avatar"
                                [src]="usuario.avatar | trustedurl"
                                (load)="onLoadProfileImage()"
                            />
                            <img
                                class="avatar"
                                alt="no avatar"
                                *ngIf="!usuarioAvatarEdit.avatar"
                                src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/default-profile.png"
                                (load)="onLoadProfileImage()"
                            />
                        </loading-layout>
                    </div>
                    <div id="avatarInput" [class.show-file-name]="usuarioAvatarEdit.file">
                        <form-input
                            [form]="formAvatar"
                            [params]="paramsAvatar['file']"
                            [(value)]="usuarioAvatarEdit['file']"
                            [key]="'file'"
                            [xhr_upload]="s3Service.xhr_upload"
                        ></form-input>
                        <div class="avatar-buttons clearfix">
                            <cui-button class="pull-right" *ngIf="usuarioAvatarEdit.file" (click)="saveProfileImage()">
                                {{ "usuarios_perfil_edit.aceptar" | t }}
                            </cui-button>
                            <cui-button
                                class="pull-right"
                                buttonType="cancel"
                                *ngIf="usuarioAvatarEdit.file"
                                (click)="cancelNewAvatar()"
                            >
                                {{ "usuarios_perfil_edit.cancelar" | t }}
                            </cui-button>
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="usuarioEdit" novalidate [formGroup]="form">
                <cui-section-headline>{{ "usuarios_perfil_edit.informacion_personal" | t }}</cui-section-headline>

                <div class="user-info-container">
                    <cui-button
                        class="edit-user-info-btn"
                        [buttonType]="edit_user_info ? 'cancel' : 'primary'"
                        (click)="toggleUserInfo($event)"
                    >
                        <span *ngIf="!edit_user_info">{{ "usuarios_perfil_edit.editar" | t }}</span>
                        <span *ngIf="edit_user_info">{{ "usuarios_perfil_edit.cancelar" | t }}</span>
                    </cui-button>
                    <div class="user-info" [class.hide]="edit_user_info">
                        <label>{{ params.nombre.label }}:</label>
                        <p>{{ usuario.nombreCompleto() }}</p>
                        <label>{{ params.email.label }}:</label>
                        <p>{{ usuario?.email }}</p>
                        <ng-container *ngIf="!disableViewLugar">
                            <label>{{ lugarLabel ? lugarLabel : params.lugar.label }}:</label>
                            <p>{{ usuario?.lugar | easyplaceholder: ("no_definido" | t) }}</p>
                        </ng-container>
                        <label>{{ params.fecha_nacimiento.label }}:</label>
                        <p>{{ usuario?.fecha_nacimiento | prettydate | easyplaceholder: ("no_definido" | t) }}</p>
                        <ng-container *ngIf="!disableViewNivel">
                            <label>{{ params.nivel_id.label }}:</label>
                            <p>{{ curso | easyplaceholder: ("no_definido" | t) }}</p>
                        </ng-container>

                        <div *ngIf="enableShowCountryData">
                            <div *ngFor="let idP of idPais">
                                <ng-container
                                    *ngIf="
                                        usuario['usuario_' + pais] && usuario['usuario_' + pais][idP.toLowerCase()];
                                        else noIdPais
                                    "
                                >
                                    <label>{{ idPaisAliasMap ? idPaisAliasMap[idP] : idP }}:</label>
                                    <p>
                                        {{ usuario["usuario_" + pais][idP.toLowerCase()] }}
                                    </p>
                                </ng-container>
                                <ng-template #noIdPais>
                                    <label>{{ idP }}:</label>
                                    <p>-</p>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <!--Edicion-->
                    <div class="user-edit" [class.show]="edit_user_info">
                        <form-input
                            [form]="form"
                            [params]="params['id']"
                            [(value)]="usuarioEdit['id']"
                            [key]="'id'"
                        ></form-input>
                        <form-input
                            *ngIf="!disableEditNombres"
                            [form]="form"
                            [params]="params['nombre']"
                            [(value)]="usuarioEdit['nombre']"
                            [key]="'nombre'"
                        ></form-input>
                        <form-input
                            *ngIf="!disableEditNombres"
                            [form]="form"
                            [params]="params['apellido_paterno']"
                            [(value)]="usuarioEdit['apellido_paterno']"
                            [key]="'apellido_paterno'"
                        ></form-input>
                        <form-input
                            *ngIf="!disableEditNombres"
                            [form]="form"
                            [params]="params['apellido_materno']"
                            [(value)]="usuarioEdit['apellido_materno']"
                            [key]="'apellido_materno'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['email']"
                            [(value)]="usuarioEdit['email']"
                            [key]="'email'"
                        ></form-input>
                        <form-input
                            *ngIf="!disableEditLugar"
                            [form]="form"
                            [params]="params['lugar']"
                            [(value)]="usuarioEdit['lugar']"
                            [key]="'lugar'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['fecha_nacimiento']"
                            [(value)]="usuarioEdit['fecha_nacimiento']"
                            [key]="'fecha_nacimiento'"
                        ></form-input>
                        <form-input
                            *ngIf="!disableEditNivel"
                            [form]="form"
                            [params]="params['nivel_id']"
                            [(value)]="usuarioEdit['nivel_id']"
                            [key]="'nivel_id'"
                        ></form-input>
                        <div class="clearfix user-edit-buttons">
                            <cui-button (click)="save()" type="button" class="btn_style pull-right">
                                {{ "usuarios_perfil_edit.guardar" | t }}
                            </cui-button>
                            <cui-button
                                buttonType="cancel"
                                (click)="clear()"
                                type="button"
                                class="btn_cancel pull-right"
                            >
                                {{ "usuarios_perfil_edit.deshacer" | t }}
                            </cui-button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-6 col-sm-12">
            <cui-section-headline>{{ "usuarios_perfil_edit.opciones" | t }}</cui-section-headline>
            <cui-button *ngIf="!edit_password" (click)="showEditPassword()">
                {{ "usuarios_perfil_edit.cambiar_contrasena" | t }}
            </cui-button>
            <cui-button
                *ngIf="
                    enableDeleteMe &&
                    (enableDeleteMeWithGrupoUsuarios || (!enableDeleteMeWithGrupoUsuarios && !userHasGrupoUsuarios))
                "
                (click)="deleteModal.buttonPressed()"
                buttonType="danger"
                class="pull-right"
            >
                <fa name="times" class="left-icon"></fa>
                {{ "usuarios_perfil_edit.eliminar_cuenta" | t }}
            </cui-button>
            <div *ngIf="edit_password">
                <div class="edit-pass">
                    <cui-button class="edit-user-info-btn" buttonType="cancel" (click)="showEditPassword()">
                        {{ "usuarios_perfil_edit.cancelar" | t }}
                    </cui-button>
                    <usuario-edit-password></usuario-edit-password>
                </div>
            </div>
            <br />
            <ng-container *ngIf="enableTutor">
                <cui-section-headline>{{ "usuarios_perfil_edit.tutorias" | t }}</cui-section-headline>
                <tutores [usuario]="usuario"></tutores>
            </ng-container>
            <br />
        </div>
        <div class="col-md-6 col-sm-12" *ngIf="enableAsignaturaDefinida && usuarioEdit">
            <cui-section-headline>{{ "usuarios_perfil_edit.asignatura_definida" | t }}</cui-section-headline>
            <div class="user-info-container">
                <form *ngIf="usuarioEdit" novalidate [formGroup]="form">
                    <cui-button
                        class="edit-user-info-"
                        [buttonType]="edit_user_asignatura ? 'cancel' : 'primary'"
                        (click)="edit_asignatura()"
                    >
                        <span *ngIf="!edit_user_asignatura">{{ "usuarios_perfil_edit.editar" | t }}</span>
                        <span *ngIf="edit_user_asignatura">{{ "usuarios_perfil_edit.cancelar" | t }}</span>
                    </cui-button>
                    <div class="user-info" [class.hide]="edit_user_asignatura">
                        <label>{{ "usuarios_perfil_edit.asignatura" | t }}:</label>
                        <p *ngIf="asignatura">{{ asignatura.abreviacion }}</p>
                        <p *ngIf="!asignatura">{{ "usuarios_perfil_edit.no_definido" | t }}</p>
                    </div>
                    <div class="user-edit" [class.show]="edit_user_asignatura">
                        <p>{{ "usuarios_perfil_edit.asignatura" | t }}:</p>
                        <select
                            class="form-control"
                            [ngModel]="asignatura"
                            (ngModelChange)="setAsignatura($event)"
                            [ngModelOptions]="{ standalone: true }"
                        >
                            <option [ngValue]="undefined">-- {{ "usuarios_perfil_edit.seleccione" | t }} --</option>
                            <option *ngFor="let a of asignaturas" [ngValue]="a">{{ a.abreviacion }}</option>
                        </select>
                        <p>{{ "usuarios_perfil_edit.ayuda_asignatura" | t }}</p>
                        <div class="clearfix user-edit-buttons">
                            <cui-button (click)="saveAsignatura()" class="pull-right">
                                {{ "usuarios_perfil_edit.guardar" | t }}
                            </cui-button>
                            <cui-button (click)="clear()" class="pull-right">
                                {{ "usuarios_perfil_edit.deshacer" | t }}
                            </cui-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <usuario-email-modal></usuario-email-modal>
</loading-layout>

<generic-modal #deleteModal>
    <modal-titulo>Eliminar cuenta</modal-titulo>
    <modal-contenido>
        <p class="alert alert-danger">Cuidado, esta acción es irreversible.</p>
        <div class="form-group">
            <label>
                Escribe
                <strong>"ELIMINAR CUENTA"</strong>
                para proceder
            </label>
            <input [(ngModel)]="deleteInput" type="text" class="form-control" />
            <br />
            <button
                (click)="deleteModal.close(); eliminarCuenta()"
                [disabled]="deleteInput != 'ELIMINAR CUENTA'"
                class="btn btn-danger"
            >
                Eliminar cuenta
            </button>
        </div>
    </modal-contenido>
</generic-modal>

<generic-modal #cuentaEliminadaModal>
    <modal-titulo>Cuenta eliminada</modal-titulo>
    <modal-contenido>
        <p class="alert alert-info">Tu cuenta ha sido eliminada con éxito.</p>
    </modal-contenido>
</generic-modal>
