<cui-section-headline>{{ "subir_respuestas_csv.titulo" | t }}</cui-section-headline>
<div class="row">
    <div class="col-md-5">
        <div class="tutorial clearfix">
            <div class="tutorial-image">
                <div class="tutorial-image-pos">
                    <div class="tutorial-image-cell">
                        <img
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/correccion_tutorial_4.png"
                            alt="Imagen de la hoja de respuestas"
                        />
                    </div>
                </div>
                <div class="step-number">1</div>
            </div>
            <h3 class="step">{{ "subir_respuestas_csv.titulo_step_1" | t }}</h3>
            <p class="tutorial-text">{{ "subir_respuestas_csv.desc_step_1_1" | t }}</p>
            <p
                class="tutorial-text"
                [innerHTML]="'subir_respuestas_csv.desc_step_1_2' | t: { urlFormato, urlFormatoAB }"
            ></p>
        </div>
        <div class="tutorial clearfix">
            <div class="tutorial-image">
                <div class="tutorial-image-pos">
                    <div class="tutorial-image-cell">
                        <img
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/correccion_tutorial_4.png"
                            alt="Imagen de la hoja de respuestas"
                        />
                    </div>
                </div>
                <div class="step-number">2</div>
            </div>
            <h3 class="step">{{ "subir_respuestas_csv.titulo_step_2" | t }}</h3>
            <p class="tutorial-text">
                {{ "subir_respuestas_csv.desc_step_2" | t }}
            </p>
        </div>
    </div>
    <div class="col-md-7">
        <div class="form-group">
            <file [(ngModel)]="file" [xhr_upload]="xhrUpload" key="file" (change)="checkIfCSVExtension()"></file>
            <div
                class="progress-bar"
                [class.hide]="totalProgress == 0"
                role="progressbar"
                [ngStyle]="{ width: progressWidth }"
            >
                {{ progress }} de {{ totalProgress }}
            </div>
            <div class="warning hide-warning" [class.show-warning]="wrongFileType">
                <div class="warning-content">
                    <fa name="warning" class="warning-icon"></fa>
                    <div class="warning-message">
                        <p>El archivo no corresponde a un CSV válido.</p>
                    </div>
                </div>
            </div>
            <div
                class="warning hide-warning"
                [class.show-warning]="messages && messages?.warnings?.length > 0 && !this.loadingMessages"
            >
                <div class="warning-content">
                    <fa name="warning" class="warning-icon"></fa>
                    <div class="warning-message">
                        <p>La operación se completó con advertencias:</p>
                        <ul *ngIf="messages">
                            <li *ngFor="let warning of messages.warnings">{{ warning }}</li>
                        </ul>
                        <p *ngIf="goToViewPath"><a [routerLink]="[goToViewPath]">Ver resultados</a></p>
                    </div>
                </div>
            </div>
            <div
                class="success hide-warning"
                [class.show-warning]="
                    messages && messages.warnings && messages.warnings.length == 0 && !this.loadingMessages
                "
            >
                <div class="warning-content">
                    <fa name="smile-o" class="warning-icon"></fa>
                    <div class="warning-message">
                        <p>
                            La operación se completó exitosamente.&nbsp;
                            <a (click)="bottonEstadisticas()">Ver estadística.</a>
                        </p>
                        <p *ngIf="goToViewPath"><a [routerLink]="[goToViewPath]">Ver resultados</a></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="clearfix">
            <cui-button [disabled]="loadingMessages" class="btn_style pull-right" (click)="subirCSV()">
                <ogr-icon name="upload" align="text-top"></ogr-icon>
                &nbsp;{{ "subir_respuestas_csv.subir_archivo" | t }}
            </cui-button>
            <loader *ngIf="loadingMessages" class="pushed pull-right" loaderType="default-xsmall"></loader>
        </div>
    </div>
</div>
