<div class="point-info" [class.vertical]="isVertical">
    <span class="points">
        <span class="valor">
            <b>{{ usuarioPlataforma?.experiencia || 0 }}</b>
        </span>
        <span class="subtitle">Puntos</span>
    </span>
    <span class="separator"></span>
    <span class="achieved" title="{{ nivel?.nivel }}">
        <b>{{ nivel?.nivel }}</b>
    </span>
</div>
