import { Component, ViewChild, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { PlanPersonalContenido } from "@puntaje/puntaje/api-services"

@Component({
    selector: "plan-personal-sesion-menu",
    templateUrl: "plan_personal_sesion_menu.component.html",
    styleUrls: ["plan_personal_sesion_menu.component.scss"]
})
export class PlanPersonalSesionMenuComponent {
    @Input() currentComponentIndex: number = 0
    @Output() onComponentChange: EventEmitter<any> = new EventEmitter<any>()
    @Input() planPersonalComponentes: PlanPersonalContenido[]
    @Input() previewMode: boolean = false
    @Input() unrestrictedProgressionMode: boolean = false
    @Input() componentesAvance: { [x: number]: boolean }
    constructor() {}

    ngOnInit() {}

    changeComponent(i: number) {
        this.currentComponentIndex = i
        this.onComponentChange.emit(this.currentComponentIndex)
    }

    isVisited(component: PlanPersonalContenido) {
        return this.componentesAvance[component.id]
    }

    getTypeIcon(comp: PlanPersonalContenido) {
        let thumbType = "libro"
        if (comp.tipo == "Material") {
            switch (comp.material.material_tipo_nombre) {
                case "Video":
                    thumbType = "video"
                    break
                case "Streaming":
                    thumbType = "video"
                    break
                case "Archivo":
                    thumbType = "guia-2"
                    break
                default:
                    thumbType = "guia-2"
                    break
            }
        } else if (comp.tipo == "Evaluacion") {
            thumbType = "hacer-prueba"
        }
        return thumbType
    }

    checkLine(index: number) {
        if (index >= this.planPersonalComponentes.length - 1) return false
        if (
            this.isVisited(this.planPersonalComponentes[index]) &&
            this.isVisited(this.planPersonalComponentes[index + 1])
        ) {
            return true
        }
        return false
    }
}
