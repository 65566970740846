<div [ngSwitch]="loaderType" [class.no-margin-y]="noMarginY">
    <div *ngSwitchCase="'default'" class="loader"></div>
    <div *ngSwitchCase="'default-small'" class="loader-small"></div>
    <div *ngSwitchCase="'default-xsmall'" class="loader-xsmall"></div>
    <div *ngSwitchCase="'puntaje'" class="loader-puntaje"></div>
    <div *ngSwitchCase="'puntaje-small'" class="loader-puntaje-small"></div>
    <div *ngSwitchCase="'aprendolibre'" class="loader-aprendolibre">
        <div class="l1"></div>
        <div class="l2"></div>
        <div class="l3"></div>
        <div class="l4"></div>
        <div class="l5"></div>
        <div class="l6"></div>
        <div class="l7"></div>
        <div class="l8"></div>
    </div>
    <div *ngSwitchCase="'aprendolibre2'" class="loader-aprendolibre2"></div>
    <div *ngSwitchCase="'umayor'" class="loader-umayor">
        <div class="spinner"></div>
    </div>
    <div *ngSwitchCase="'nebu'" class="kawaii-loader">
        <div class="c1"></div>
        <div class="c2"></div>
        <div class="c3"></div>
        <div class="c4"></div>
    </div>
    <div *ngSwitchCase="'graduate'" class="loader-graduate">
        <div class="g1"></div>
    </div>
    <div *ngSwitchCase="'tec'" class="loader-tec"></div>
    <div *ngSwitchCase="'miap'" class="loader-miap"></div>
    <div *ngSwitchDefault class="loader"></div>
</div>
