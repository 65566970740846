import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Material } from "./materiales.model"
import { Plataforma } from "./plataformas.model"

export class MaterialPlataforma extends BaseModel {
    public static className: string = "MaterialPlataforma"
    public plataforma_id: number
    public material_id: number
    @SaveType(() => Material) public material: Material
    @SaveType(() => Plataforma) public plataforma: Plataforma

    constructor() {
        super()
    }

    public toString() {
        return this.id.toString()
    }
}
