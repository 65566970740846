import { EstadisticasActions, EstadisticasActionTypes } from "../actions/estadisticas.actions"

export interface Filtro {
    establecimientoId: number
    grupoUsuarioId: number
    usuarioId: number
    evaluacionId: number
    generadorInstrumentoId: number
    periodo: number[]
}

export interface GroupedFiltro {
    [evaluacionTipo: string]: { [asignaturaId: number]: Filtro }
}

export interface State {
    filtros: GroupedFiltro
}

export const initialState: State = {
    filtros: {}
}

export function reducer(state = initialState, action: EstadisticasActions): State {
    switch (action.type) {
        case EstadisticasActionTypes.SetFiltros:
            return {
                ...state,
                filtros: {
                    ...state.filtros,
                    [action.payload.evaluacionTipo]: {
                        ...state.filtros[action.payload.evaluacionTipo],
                        [action.payload.asignaturaId]: action.payload.filtro
                    }
                }
            }
        default:
            return state
    }
}
