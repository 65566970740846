import { Component, Input, EventEmitter, Output } from "@angular/core"
import { AuthService, SessionService } from "@puntaje/shared/core"
import {
    Asignatura,
    Asignaturas,
    GeneradorInstrumento,
    GeneradorInstrumentos,
    TipoInstrumento
} from "@puntaje/nebulosa/api-services"
import { GrupoUsuarios, GrupoUsuario } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "planes-personales-sidemenu",
    templateUrl: "planes_personales_sidemenu.component.html",
    styleUrls: ["planes_personales_sidemenu.component.scss"]
})
export class PlanesPersonalesSidemenuComponent {
    generadorInstrumento: GeneradorInstrumento
    generadorInstrumentos: GeneradorInstrumento[]
    @Input() tipoEvaluacion: string
    @Input() generadorInstrumentoId: number
    @Input() hideInfo: boolean = false
    nivelId: number[]
    @Output() generadorInstrumentoChange: EventEmitter<any> = new EventEmitter<any>()
    hideSelector: boolean = false
    _tipoInstrumento: string
    _asignaturaId: number
    @Input()
    set tipoInstrumento(tipo: string) {
        this._tipoInstrumento = tipo
        if (this.asignaturaId) this.getGeneradores()
    }
    get tipoInstrumento() {
        return this._tipoInstrumento
    }
    @Input()
    set asignaturaId(id: number) {
        this._asignaturaId = id
        if (this.asignaturaId) this.getGeneradores()
    }
    get asignaturaId() {
        return this._asignaturaId
    }

    planPersonalesDisableNivel: boolean = config.plataforma.planPersonalesDisableNivel

    constructor(
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected sessionService: SessionService,
        protected grupoUsuariosService: GrupoUsuarios
    ) {}

    async getGeneradores() {
        const fechaInicial = new Date(new Date().getFullYear(), 0, 1).toISOString()
        const fechaFinal = new Date(new Date().getFullYear() + 1, 0, 1).toISOString()

        const grupoUsuarioParams = {
            grupo_usuario: {
                id: this.sessionService.getGrupoUsuariosActivos()
            },
            fecha_inicial: fechaInicial,
            fecha_final: fechaFinal
        }

        if (!this.planPersonalesDisableNivel) {
            await this.grupoUsuariosService.where(grupoUsuarioParams).then(grupoUsuarios => {
                if (grupoUsuarios.length > 0) {
                    this.nivelId = grupoUsuarios.filter(g => !!g.curso_id).map(g => g.curso_id)
                } else {
                    const nid = this.sessionService.getUserData().nivel_id
                    if (nid) {
                        this.nivelId = nid
                    }
                }
            })
        }

        const params = {
            asignatura_plataforma: {
                asignatura_id: this.asignaturaId,
                plataforma_id: config.plataforma.id
            },
            tipo_instrumento: {
                tipo_instrumento: this.tipoInstrumento
            },
            visible: true,
            include: "[tipo_instrumento]"
        }

        if (this.nivelId) {
            params["generador_instrumento_categoria"] = {
                categoria_id: this.nivelId
            }
        }

        this.generadorInstrumentosService.where(params).then((generadorInstrumentos: GeneradorInstrumento[]) => {
            this.generadorInstrumentos = generadorInstrumentos
            if (this.generadorInstrumentoId) {
                const generadorInstrumento = generadorInstrumentos.find(gi => gi.id == this.generadorInstrumentoId)

                if (generadorInstrumento) {
                    this.generadorInstrumento = generadorInstrumento
                    this.generadorInstrumentoChanges()
                } else {
                    this.setFirstGeneradorInstrumento()
                }
            } else {
                this.setFirstGeneradorInstrumento()
            }
        })
    }

    setFirstGeneradorInstrumento() {
        if (this.generadorInstrumentos.length > 0) {
            this.generadorInstrumento = this.generadorInstrumentos[0]
            this.generadorInstrumentoChanges()
        } else {
            this.generadorInstrumentoChange.emit(null)
        }
    }

    generadorInstrumentoChanges() {
        this.generadorInstrumentoChange.emit(this.generadorInstrumento)
    }
}
