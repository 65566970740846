<div class="caja-envio">
    <h5>Compartir {{ tipoEvaluacion | transformInstrumento: "singular" }}</h5>
    <cui-button-link
        [isBlock]="true"
        buttonType="confirm"
        id="compartir_link"
        class="btn2 btn_style_3"
        (click)="imprimirAlCompartir = false; openModal.emit()"
    >
        Compartir
    </cui-button-link>
    <cui-button-link
        [isBlock]="true"
        buttonType="confirm"
        *ngIf="!hideCompartirImprimir && !evaluacionMultipleId"
        class="btn2 btn_style_3 separador"
        (click)="imprimirAlCompartir = true; openModal.emit()"
    >
        Compartir e Imprimir
    </cui-button-link>
    <ng-container
        *ngIf="!hideOpcionesImpresion && (evaluacionMultipleId || ultimaEvaluacionMultipleId) && instrumentoMultiple"
    >
        <h5>Opciones de impresión</h5>
        <cui-button-link [isBlock]="true" id="print_link" class="btn_style" (click)="imprimirTodos('alumno')">
            <span class="glyphicon glyphicon-print"></span>
            Imprimir para alumnos
        </cui-button-link>
        <p class="details">(sin respuestas)</p>
        <cui-button-link [isBlock]="true" id="print_link_prof" class="btn_style" (click)="imprimirTodos('profesor')">
            <span class="glyphicon glyphicon-print"></span>
            Imprimir para profesor
        </cui-button-link>
        <p class="details">(con respuestas)</p>
        <cui-button-link [isBlock]="true" id="print_link_prof" class="btn_style" (click)="imprimirHojasTodos()">
            <span class="glyphicon glyphicon-print"></span>
            Imprimir hojas de respuestas
        </cui-button-link>
        <p class="details">(por alumno)</p>
        <div class="warning separador">
            Advertencia: Estamos optimizando la impresión de los ensayos, por lo que se pueden generar vistas con
            pequeños errores de formato. Para esto, favor contactar al correo contacto&#64;ogr.cl para dar solución en caso
            de haber algún problema.
        </div>
    </ng-container>
</div>
<compartir-instrumento-multiple-profesor
    *ngIf="instrumentoMultiple && !compartirSimple"
    [compartirTodosCursos]="compartirTodosCursos"
    [instrumentoMultiple]="instrumentoMultiple"
    (evaluacionMultipleCompartida)="onEvaluacionMultipleCompartida($event)"
    [openModal]="openModal"
    [closeOnFinished]="imprimirAlCompartir"
    [tipoEvaluacion]="tipoEvaluacion"
    [redirect]="redirect"
    [enableAdminOptions]="enableAdminOptions"
></compartir-instrumento-multiple-profesor>

<compartir-instrumento-multiple-simple-profesor
    *ngIf="instrumentoMultiple && compartirSimple"
    [compartirTodosCursos]="compartirTodosCursos"
    [instrumentoMultiple]="instrumentoMultiple"
    (evaluacionMultipleCompartida)="onEvaluacionMultipleCompartida($event)"
    [openModal]="openModal"
    [closeOnFinished]="imprimirAlCompartir"
    [tipoEvaluacion]="tipoEvaluacion"
    [redirect]="redirect"
    [enableAdminOptions]="enableAdminOptions"
    >
</compartir-instrumento-multiple-simple-profesor>

<!-- <compartir-instrumento-profesor *ngIf="instrumentos" [instrumentos]="instrumentos" (evaluacionesCompartidas)="onEvaluacionesCompartidas($event)" [openModal]="openModal" [closeOnFinished]="imprimirAlCompartir" [tipoEvaluacion]="tipoEvaluacion" [redirect]="redirect" [enableAdminOptions]="enableAdminOptions"></compartir-instrumento-profesor> -->

<div
    class="modal fade staticModal"
    bsModal
    #mandatoryLoading="bs-modal"
    [config]="{ backdrop: 'static', keyboard: false }"
    tabindex="0"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <ng-container *ngIf="!errorMsg">
                    <loading-layout #loadingLayout [noMarginY]="true" class="loading"></loading-layout>
                    <p class="loading-message">
                        Estamos generando el archivo para su impresión.
                        <br />
                        Esta operación puede tardar unos minutos, por favor espere.
                    </p>
                </ng-container>
                <ng-container *ngIf="errorMsg">
                    <p class="warning-icon">
                        <fa name="warning"></fa>
                    </p>
                    <p class="error-message">
                        Se ha producido un error al generar su archivo para impresión.
                        <br />
                        Por favor espere unos minutos e intente de nuevo.
                    </p>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    (click)="cancelPrint()"
                    *ngIf="!errorMsg"
                >
                    Cancelar
                </button>
                <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    *ngIf="errorMsg"
                    (click)="mandatoryLoading.hide()"
                >
                    Cerrar
                </button>
            </div>
        </div>
    </div>
</div>
