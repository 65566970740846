import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { DudaRespuesta } from "./duda_respuestas.model"

export class Comentario extends BaseModel {
    public usuario_id: number
    public duda_respuesta_id: number
    public comentario: string
    public ban: boolean
    public created_at: string | Date
    public updated_at: string | Date

    @SaveType() usuario: Usuario
    @SaveType() duda_respuesta: DudaRespuesta
}
