import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Injectable()
export class TypeformWidgetsBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    search(typeformWidget, establecimientoPais, idEstablecimientoKey) {
        let params: any = {}

        typeformWidget = Object.assign({}, typeformWidget)
        establecimientoPais = Object.assign({}, establecimientoPais)

        Object.keys(typeformWidget).forEach(k => {
            if (typeformWidget[k] === "" || typeformWidget[k] === undefined) delete typeformWidget[k]
        })

        Object.keys(establecimientoPais).forEach(k => {
            if (establecimientoPais[k] === "" || establecimientoPais[k] === undefined) delete establecimientoPais[k]
        })

        if (typeformWidget.id && typeformWidget.id.indexOf(",") != -1) {
            typeformWidget.id = typeformWidget.id.split(/\s*,\s*/)
        }

        if (typeformWidget.nombre_referencia) {
            typeformWidget.like = { nombre_referencia: typeformWidget.nombre_referencia }
            delete typeformWidget.nombre_referencia
        }

        if (establecimientoPais[idEstablecimientoKey] && establecimientoPais[idEstablecimientoKey].indexOf(",") != -1) {
            establecimientoPais[idEstablecimientoKey] = establecimientoPais[idEstablecimientoKey].split(/\s*,\s*/)
        }

        params = {
            typeform_widget: typeformWidget,
            ["establecimiento_" + config.plataforma.pais]: establecimientoPais
        }

        this.searchParams.next(params)
    }
}
