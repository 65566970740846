import { Component, OnInit, Input } from "@angular/core"
import { Store } from "@ngrx/store"
import { State, selectCorrecionTabClasses } from "@puntaje/puntaje/store"

@Component({
    selector: "corregir-ensayos-tabs-contenido-profesor",
    templateUrl: "./corregir-ensayos-tabs-contenido-profesor.component.html",
    styleUrls: ["./corregir-ensayos-tabs-contenido-profesor.component.scss"]
})
export class CorregirEnsayosTabsContenidoProfesorComponent implements OnInit {
    @Input() typePlatform: string
    @Input() evaluacionId: any
    @Input() perfil: any

    tabClases$ = this.store.select(selectCorrecionTabClasses)

    constructor(protected store: Store<State>) {}

    ngOnInit() {}
}
