<loading-layout #loadingLayout>
    <cui-section-headline id="titlePreferencias">
        {{ "usuarios.preferencia_carreras.mis_preferencia_carrera.title" | t }}
    </cui-section-headline>
    <cui-button
        id="btnAgregar"
        class="btn_style"
        type="button"
        (click)="addCarreras()"
        *ngIf="carreraSedes.length < amountConfi"
    >
        {{ "usuarios.preferencia_carreras.mis_preferencia_carrera.agregar" | t }}
    </cui-button>
    <div class="content_carrera_sede" *ngFor="let carreraSede of carreraSedes; let i = index">
        <div *ngIf="!showEditCarreras || i !== indexSelected">
            <a
                *ngIf="!disableEliminarPrimeraPreferenciaCarreras || carreraSede.preferenciaCarreraSedes[0].orden > 1"
                (click)="showModalDelete(carreraSede.preferenciaCarreraSedes[0], i)"
            >
                <fa class="pull-right" style="margin-left: 10px" name="trash"></fa>
            </a>
            <a>
                <fa id="btnEditar" class="pull-right" name="pencil" (click)="editPreferenciaCarrera(i)"></fa>
            </a>
            <h3>{{ carreraSede.alias }} {{ carreraSede.jornada }}</h3>
            <div class="universidad">{{ carreraSede.sede.universidad.universidad }}</div>
            <div class="sede">{{ carreraSede.sede.sede }}</div>
        </div>
        <div *ngIf="showEditCarreras && i === indexSelected">
            <embed-preferencia-carrera-sedes-by-sede
                #preferencias
                [universidadesNombres]="universidades"
                [form]="form"
                [amount]="1"
                sedeCarreraSedes="carreraSede"
                (emitPreferencias)="updatePreferencias($event)"
            ></embed-preferencia-carrera-sedes-by-sede>
            <cui-button class="btn_style" style="margin-right: 10px" (click)="saveCarreraSede()" type="button">
                {{ "usuarios.preferencia_carreras.mis_preferencia_carrera.guardar" | t }}
            </cui-button>
            <cui-button class="btn_style" (click)="cancelarCarreraSede()" type="button">
                {{ "usuarios.preferencia_carreras.mis_preferencia_carrera.cancelar" | t }}
            </cui-button>
        </div>
    </div>
    <div *ngIf="showAddCarreras">
        <embed-preferencia-carrera-sedes-by-sede
            #preferencias
            [universidadesNombres]="universidades"
            [form]="form"
            [amount]="amount"
            (emitPreferencias)="updatePreferencias($event)"
        ></embed-preferencia-carrera-sedes-by-sede>
        <cui-button class="btn_style" style="margin-right: 10px" (click)="saveCarreraSede()" type="button">
            {{ "usuarios.preferencia_carreras.mis_preferencia_carrera.guardar" | t }}
        </cui-button>
        <cui-button class="btn_style" (click)="cancelarCarreraSede()" type="button">
            {{ "usuarios.preferencia_carreras.mis_preferencia_carrera.cancelar" | t }}
        </cui-button>
    </div>
</loading-layout>
