import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { Comentario } from "./comentarios.model"
import { DudaRespuestas } from "./duda_respuestas.service"
import { DudaUsuarioInfo } from "./duda_usuario_info.model"
import { Injector } from "@angular/core"

export class DudaRespuesta extends BaseModel {
    public duda_respuesta: string
    public duda_id: number
    public usuario_id: number
    public thumbs_up: number
    public thumbs_down: number
    public ban: boolean

    public created_at: string | Date
    public updated_at: string | Date

    public className: string = "DudaRespuesta"

    @SaveType(() => DudaUsuarioInfo) usuario_valores: DudaUsuarioInfo
    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => Comentario) comentarios: Comentario[]

    public getLikePromise(injector: Injector, params: any = {}): Promise<any> {
        return injector
            .get(DudaRespuestas)
            .like(this.id, params)
            .then((dudaRespuesta: DudaRespuesta) => {
                let obj: any = {}
                obj.likes = dudaRespuesta.thumbs_up
                obj.dislikes = dudaRespuesta.thumbs_down

                return obj
            })
    }

    public getDislikePromise(injector: Injector, params: any = {}): Promise<any> {
        return injector
            .get(DudaRespuestas)
            .dislike(this.id, params)
            .then((dudaRespuesta: DudaRespuesta) => {
                let obj: any = {}
                obj.likes = dudaRespuesta.thumbs_up
                obj.dislikes = dudaRespuesta.thumbs_down

                return obj
            })
    }

    public getSentimentPromise(injector: Injector, params: any = {}): Promise<any> {
        return injector
            .get(DudaRespuestas)
            .find(this.id)
            .then((dudaRespuesta: DudaRespuesta) => {
                let obj: any = {}
                obj.likes = dudaRespuesta.thumbs_up
                obj.dislikes = dudaRespuesta.thumbs_down

                return obj
            })
    }
}
