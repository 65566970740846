import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router"
import { Observable, Observer } from "rxjs"
import { Duda, Dudas, PuntajeAuthService } from "@puntaje/puntaje/api-services"

@Injectable({ providedIn: "root" })
export class EditDudaGuard implements CanActivate {
    constructor(private router: Router, private dudasService: Dudas, private authService: PuntajeAuthService) {}

    canActivate(routeSnapshot: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        const baseRoute: string = routeSnapshot.data["baseRoute"]
        if (this.authService.isLoggedIn()) {
            const identifier: string = routeSnapshot.data["identifier"]
            const dudaId: number = +routeSnapshot.params[identifier]
            const userId: number = this.authService.getUserData().id

            return new Observable<boolean>((observer: Observer<boolean>) => {
                this.dudasService.find(dudaId).then((duda: Duda) => {
                    if (duda.usuario_id === userId) {
                        observer.next(true)
                    } else {
                        this.redirect(baseRoute)
                        observer.next(false)
                    }
                })
            })
        } else {
            this.redirect(baseRoute)
            return false
        }
    }

    redirect(route: string = ""): void {
        this.router.navigate(["/" + route])
    }
}
