import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, HostListener } from "@angular/core"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { AuthService, SessionService } from "@puntaje/shared/core"
import { Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"

/*#################################

Menú arriba del sitio.

#################################*/

@Component({
    templateUrl: "base-menu-horizontal.component.html",
    selector: "base-menu-horizontal",
    styleUrls: ["base-menu-horizontal.component.scss"]
})
export class BaseMenuHorizontalComponent extends MenuComponent {
    @Input() withBorder = false
    itemsMenu: any[]
    menuItemsAlumnos = [
        {
            name: "Practica",
            route: "/evaluaciones/recomendadas",
            icon: "prueba"
        },
        {
            name: "Resultados",
            route: "/pruebas/resultados",
            icon: "resultados"
        },
        {
            name: "Planes de clase",
            route: "/plan_clases",
            icon: "planificacion-o"
        },
        {
            name: "Dudas del curso",
            route: "/dudas",
            icon: "dialogos",
            params: {
                show_cursos: 1
            }
        },
        {
            name: "Comunidad",
            route: "/dudas_categorias",
            icon: "dialogos"
        }
    ]

    menuItemsProfesores = [
        {
            name: "Evaluar",
            route: "/pruebas/generar_prueba",
            icon: "editar-prueba"
        },
        {
            name: "Corregir",
            route: "/evaluacion_instancias/subir",
            icon: "upload"
        },
        {
            name: "Resultados",
            route: "/evaluaciones/resultados",
            icon: "resultados"
        },
        {
            name: "Planes de clase",
            route: "/plan_clases",
            icon: "planificacion-o"
        },
        {
            name: "Mis noticias",
            route: "/noticias",
            icon: "megafono-o"
        },
        {
            name: "Comunidad",
            route: "/dudas_categorias",
            icon: "dialogos"
        }
    ]

    menuItemsByApp = {
        alumnos: this.menuItemsAlumnos,
        profesores: this.menuItemsProfesores
    }

    config: typeof config

    constructor(
        store: Store<State>,
        protected authService: AuthService,
        protected router: Router,
        private sessionService: SessionService
    ) {
        super(store)
        this.config = config
    }

    ngOnInit() {
        super.ngOnInit()
        const userId = this.sessionService.getUserData().id

        if (this.config.app.navigationByApp && this.config.app.navigationByApp[this.config.app.name]) {
            this.itemsMenu = this.config.app.navigationByApp[this.config.app.name].map(m => {
                m.route = m.route.includes(":id") ? m.route.replace(":id", userId) : m.route
                return m
            })

            this.itemsMenu = this.config.app.navigationByApp[this.config.app.name]
        } else {
            this.itemsMenu = this.menuItemsByApp[this.config.app.name] || this.menuItemsAlumnos
        }

        this.enableReportes()
    }

    enableReportes() {
        const hasEstablecimientos = this.authService.getEstablecimientos().length > 0

        if (hasEstablecimientos && this.config.app.name == "profesores") {
            if (this.itemsMenu.every(m => !m.name.includes("resumen_uso"))) {
                this.itemsMenu.push({
                    icon: "resultados",
                    name: "base_topmenu.profe.resumen_uso",
                    route: "/estadisticas/resumen_uso"
                })
            }
        } else {
            this.itemsMenu = this.itemsMenu.filter(m => m.name.includes("resumen_uso") == false)
        }
    }
}
