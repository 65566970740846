import { Action } from "@ngrx/store"
import { Asignatura, AsignaturaConfig, AsignaturaWithConfig, Asignaturas } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
import { AsignaturasActionTypes, AsignaturasActions } from "../actions/asignaturas.actions"

declare const config: AppConfig

export interface AsignaturaById {
    [id: number]: AsignaturaWithConfig
}
export interface AsignaturasByEvaluacionTipo {
    [evaluacionTipo: string]: AsignaturaWithConfig[]
}

export interface State {
    list: AsignaturaWithConfig[]
    byId: AsignaturaById
    byEvaluacionTipo: AsignaturasByEvaluacionTipo
    selectedAsignaturaId: number
}

export const initialState: State = {
    list: null,
    byId: null,
    byEvaluacionTipo: null,
    selectedAsignaturaId: null
}

export function reducer(state = initialState, action: AsignaturasActions): State {
    switch (action.type) {
        case AsignaturasActionTypes.GetAsignaturasConfigSuccess: {
            const asignaturaEvaluacionTipos = action.payload.asignaturaEvaluacionTipos
            const asignaturas = action.payload.asignaturas

            const list: AsignaturaWithConfig[] = asignaturas.map((asignatura: Asignatura) => {
                const asignaturaEvaluacionTiposConf = asignaturaEvaluacionTipos
                    .filter(asignaturaEvaluacionTipo => asignaturaEvaluacionTipo.asignatura_id == asignatura.id)
                    .reduce(
                        (acc, asignaturaEvaluacionTipo) => {
                            const evaluacion_tipo_nombre = asignaturaEvaluacionTipo.evaluacion_tipo.evaluacion_tipo

                            acc["byEvaluacionTipo"][evaluacion_tipo_nombre] = asignaturaEvaluacionTipo

                            if (
                                asignaturaEvaluacionTipo.asignatura_material_clasificacion_tipos &&
                                asignaturaEvaluacionTipo.asignatura_material_clasificacion_tipos.length > 0
                            ) {
                                const materialClasificionTipo =
                                    asignaturaEvaluacionTipo.asignatura_material_clasificacion_tipos[0]

                                acc["materialClasificacion"][evaluacion_tipo_nombre] = {
                                    clasificacion: materialClasificionTipo.clasificacion_tipo,
                                    label: materialClasificionTipo.label
                                }
                            }

                            if (
                                asignaturaEvaluacionTipo.asignatura_material_niveles &&
                                asignaturaEvaluacionTipo.asignatura_material_niveles.length > 0
                            ) {
                                acc["materialNiveles"][evaluacion_tipo_nombre] =
                                    asignaturaEvaluacionTipo.asignatura_material_niveles.map(mct => mct.clasificacion)
                            }

                            return acc
                        },
                        { byEvaluacionTipo: {}, materialNiveles: {}, materialClasificacion: {} }
                    )

                const asignaturaConfig = {
                    id: asignatura.id,
                    confByEvaluacionTipo: asignaturaEvaluacionTiposConf.byEvaluacionTipo,
                    evaluacionTipo: Object.keys(asignaturaEvaluacionTiposConf.byEvaluacionTipo),
                    materialClasificacion: asignaturaEvaluacionTiposConf.materialClasificacion,
                    materialNiveles: asignaturaEvaluacionTiposConf.materialNiveles
                }

                // caso seccion sin evaluacion tipo
                if (asignaturaEvaluacionTiposConf.byEvaluacionTipo[config.plataforma.evaluacionDefault]) {
                    asignaturaConfig["icono"] =
                        asignaturaEvaluacionTiposConf.byEvaluacionTipo[config.plataforma.evaluacionDefault].icono
                    asignaturaConfig["clase"] =
                        asignaturaEvaluacionTiposConf.byEvaluacionTipo[config.plataforma.evaluacionDefault].clase
                } else {
                    asignaturaConfig["icono"] = asignaturaEvaluacionTipos[0].icono
                    asignaturaConfig["clase"] = asignaturaEvaluacionTipos[0].clase
                }

                return Object.assign(new Asignatura(), asignatura, asignaturaConfig as any)
            })

            const byId: { [id: number]: AsignaturaWithConfig } = list.reduce((acc, a) => {
                acc[a.id] = a

                return acc
            }, {})

            const byEvaluacionTipo: { [evaluacionTipo: string]: AsignaturaWithConfig[] } = list.reduce((acc, x) => {
                x.evaluacionTipo.forEach(et => {
                    const asignaturaWithConfig = Object.assign(new Asignatura(), x)
                    asignaturaWithConfig.clase = x.confByEvaluacionTipo[et].clase
                    asignaturaWithConfig.icono = x.confByEvaluacionTipo[et].icono
                    asignaturaWithConfig.abreviacion = x.confByEvaluacionTipo[et].abreviacion
                    asignaturaWithConfig.asignaturaPlataforma = x.confByEvaluacionTipo[et].asignatura_plataforma

                    acc[et] = acc[et] || []
                    acc[et].push(asignaturaWithConfig)
                })

                return acc
            }, {})

            return { ...state, list, byId, byEvaluacionTipo }
        }

        case AsignaturasActionTypes.GetAsignaturasConfigSuccessOld: {
            const list: AsignaturaWithConfig[] = config.asignaturas
                .map(asignaturaConfig => {
                    const asignatura = action.payload.find(a => asignaturaConfig.asignatura == a.asignatura)
                    if (asignatura) {
                        asignaturaConfig.id = asignatura.id
                        asignaturaConfig.confByEvaluacionTipo = asignaturaConfig.evaluacionTipo.reduce((acc, et) => {
                            const materialVisible =
                                typeof asignaturaConfig.materialVisible == "object"
                                    ? !!asignaturaConfig.materialVisible[et]
                                    : asignaturaConfig.materialVisible
                            const soloContenido =
                                typeof asignaturaConfig.onlyMaterialVisible == "object"
                                    ? !!asignaturaConfig.onlyMaterialVisible[et]
                                    : asignaturaConfig.onlyMaterialVisible

                            acc[et] = {
                                material_visible: materialVisible,
                                solo_contenido: soloContenido,
                                with_items: true,
                                with_evaluaciones_recomendadas: true,
                                with_pruebas_propias: true,
                                with_buscador_en_generador: true,
                                with_planes_de_clase: true,
                                clase: asignaturaConfig.clase,
                                icono: asignaturaConfig.icono,
                                asignatura_plataforma: asignaturaConfig.asignaturaPlataforma,
                                abreviacion: asignaturaConfig.abreviacion
                            }

                            return acc
                        }, {})

                        return Object.assign(new Asignatura(), asignatura, asignaturaConfig as any as AsignaturaConfig)
                    } else {
                        return null
                    }
                })
                .filter(a => a)

            const byId: { [id: number]: AsignaturaWithConfig } = list.reduce((acc, a) => {
                acc[a.id] = a

                return acc
            }, {})

            const byEvaluacionTipo: { [evaluacionTipo: string]: AsignaturaWithConfig[] } = list.reduce((acc, x) => {
                x.evaluacionTipo.forEach(et => {
                    acc[et] = acc[et] || []
                    acc[et].push(x)
                })

                return acc
            }, {})

            return { ...state, list, byId, byEvaluacionTipo }
        }

        case AsignaturasActionTypes.SetSelectedAsignatura: {
            const selectedAsignaturaId = action.payload.selectedAsignatura ? action.payload.selectedAsignatura.id : null

            return { ...state, selectedAsignaturaId }
        }
        case AsignaturasActionTypes.SetSelectedAsignaturaWithId: {
            const selectedAsignaturaId = action.payload.asignaturaId

            return { ...state, selectedAsignaturaId }
        }

        case AsignaturasActionTypes.CheckAsignaturaInEvaluacionTipo: {
            const asignaturaId = state.selectedAsignaturaId
            const evaluacionTipo = action.payload.evaluacionTipo

            const asignaturasEvaluacionTipo = state.byEvaluacionTipo[evaluacionTipo]
            const asignatura = asignaturasEvaluacionTipo.find(a => a.id == asignaturaId)

            return { ...state, selectedAsignaturaId: asignatura ? asignaturaId : asignaturasEvaluacionTipo[0]?.id }
        }

        default:
            return state
    }
}
