import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "evaluaciones_multiples_compartidas.component.html",
    styleUrls: ["evaluaciones_multiples_compartidas.component.scss"]
})
export class EvaluacionesMultiplesCompartidasComponent implements OnInit, OnDestroy {
    sub: Subscription
    tipoInstrumento: string

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.evaluaaciones_multiples.compartidas")
        this.sub = this.route.queryParamMap.subscribe(queryParamsMap => {
            if (queryParamsMap.has("tipo_instrumento")) {
                this.tipoInstrumento = queryParamsMap.get("tipo_instrumento")
            }
        })
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe()
        }
    }
}
