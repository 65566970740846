import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule, AnimationsModule } from "@puntaje/shared/core"
import { DynamicModule } from "ng-dynamic-component"

import { LandingModule } from "@puntaje/puntaje/new-modules/landing"

import { CUILayoutsModule } from "@puntaje/shared/cui"
import { PlaceholderPageComponent } from "./placeholder_page.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { TermsAndConditionsComponent } from "./terms_and_conditions.component"
import { InstruccionesEliminacionDatosComponent } from "./instrucciones-eliminacion-datos/instrucciones-eliminacion-datos.component"
import { DemoComponent } from "./demo.component"
import { HomeComponent } from "./home.component"
import { CUIDemoModule } from "@puntaje/shared/cui"
import { PuntajeTypeformWidgetsModule } from "../typeform-widgets"
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        LandingModule,
        Angular2FontawesomeModule,
        UtilModule,
        AnimationsModule,
        CUILayoutsModule,
        CommonsLayoutsModule,
        CUIDemoModule,
        DynamicModule,
        PuntajeTypeformWidgetsModule
    ],
    declarations: [
        PlaceholderPageComponent,
        TermsAndConditionsComponent,
        InstruccionesEliminacionDatosComponent,
        DemoComponent,
        HomeComponent
    ],
    exports: [
        PlaceholderPageComponent,
        TermsAndConditionsComponent,
        InstruccionesEliminacionDatosComponent,
        DemoComponent,
        HomeComponent
    ]
})
export class PuntajeLandingModule {
    static forRoot(): ModuleWithProviders<PuntajeLandingModule> {
        return {
            ngModule: PuntajeLandingModule,
            providers: []
        }
    }
}
