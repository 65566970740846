import { Component, OnInit, Input, ViewChild, EventEmitter } from "@angular/core"
import { Router } from "@angular/router"
import { GenericModalComponent } from "@puntaje/shared/core"

@Component({
    selector: "crear-duda",
    templateUrl: "./crear-duda.component.html",
    styleUrls: ["./crear-duda.component.scss"]
})
export class CrearDudaComponent implements OnInit {
    contexto: string
    defaultContexto: string = "comunidad"
    buttonPressed = new EventEmitter()

    @Input() type: "link" | "btn" = "btn"
    @Input() tabla: string
    @Input() entidad_id: number
    @Input() queryParams = {}
    @Input() dontEvenAsk = true

    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent

    constructor(protected router: Router) {}

    ngOnInit() {}

    showModalDuda() {
        this.contexto = this.defaultContexto
        if (this.dontEvenAsk) {
            this.makeDuda()
        } else {
            this.buttonPressed.emit()
        }
    }

    makeDuda(withModal = false) {
        if (withModal) this.close()
        this.queryParams["show_cursos"] = this.contexto == "comunidad" ? 0 : 1
        this.router.navigate(["/dudas/new"], {
            queryParams: this.queryParams
        })
    }

    public close() {
        this.genericModal.close()
    }
}
