import { Component, Input, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { EvaluacionMenuPreguntasComponent } from "../evaluacion_menu_preguntas.component"

@Component({
    selector: "widget-evaluacion-menu-preguntas",
    templateUrl: "widget-evaluacion-menu-preguntas.component.html",
    styleUrls: ["widget-evaluacion-menu-preguntas.component.scss"]
})
export class WidgetEvaluacionMenuPreguntasComponent extends EvaluacionMenuPreguntasComponent implements OnInit {
    @Input() animateOnStart: boolean = true
    @Input() animateOnStartTrigger: boolean = false

    readySubForTrigger: Subscription

    ngOnInit() {
        super.ngOnInit()

        this.readySubForTrigger = this.realizarEnsayoService.ready.subscribe(() => {
            setTimeout(() => {
                this.animateOnStartTrigger = true
            }, 500)
        })
    }

    ngOnDestroy() {
        super.ngOnDestroy()
        this.readySubForTrigger.unsubscribe()
    }
}
