import { SaveType, BaseModel } from "@puntaje/shared/core"
import { PlanEstudio, Asignatura } from "@puntaje/nebulosa/api-services"
import { Establecimiento } from "./establecimientos.model"
import { PlanEstudioEvaluacion } from "./plan_estudio_evaluaciones.model"
import { PlanEstudioAplicadoInstancia } from "./plan_estudio_aplicado_instancias.model"

export class PlanEstudioAplicado extends BaseModel {
    public plan_estudio_id: number
    public asignatura_id: number
    public plan_estudio_nombre: string
    public asignatura_nombre: string
    public estado: number
    public plan_estudio_aplicado: string
    public inscritos: number
    @SaveType(() => PlanEstudio) public plan_estudio: PlanEstudio
    @SaveType(() => PlanEstudioEvaluacion) public plan_estudio_evaluaciones: PlanEstudioEvaluacion[]
    @SaveType(() => PlanEstudioAplicadoInstancia)
    public plan_estudio_aplicado_instancias: PlanEstudioAplicadoInstancia[]

    constructor(empty: boolean = false) {
        super()
        if (!empty) {
            this.plan_estudio = new PlanEstudio()
            this.plan_estudio_evaluaciones = []
            this.plan_estudio_aplicado_instancias = []
        }
    }

    public toString() {
        return this.plan_estudio.plan_estudio
    }
}
