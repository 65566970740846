<ul class="menu-componentes">
    <li *ngFor="let component of planPersonalComponentes; let i = index">
        <div class="menu-circle-wrap">
            <a
                class="menu-circle"
                [class.visited]="componentesAvance[component.id]"
                [class.current]="i == currentComponentIndex"
                [class.pending]="!componentesAvance[component.id]"
                [class.unrestricted]="unrestrictedProgressionMode"
                (click)="(componentesAvance[component.id] || unrestrictedProgressionMode) && changeComponent(i)"
            >
                <ogr-icon [name]="getTypeIcon(component)" class="icono"></ogr-icon>
                <span class="arrow-pointer" *ngIf="i == currentComponentIndex"></span>
            </a>
        </div>
        <span class="tab-pointer" *ngIf="i == currentComponentIndex"></span>
        <div class="segment" [class.connectLines]="checkLine(i)"></div>
    </li>
</ul>
