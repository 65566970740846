import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { Banner } from "./banners.model"
import { BannerInstancia } from "./banner_instancias.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class Banners extends PuntajeBaseService<Banner> {
    tableName = "banners"
    singularTableName = "banner"
    modelClass = Banner
    wea: Promise<any>

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public saveInstanceList(banner: Banner, instances: BannerInstancia[]) {
        banner["banner_instancias"] = instances

        return this.one(banner.id).patch(banner)
    }

    public usuariosClick(id: number) {
        return this.one(id).concatExtra("usuarios_click").get({ to_csv: "usuarios.csv" })
    }
}
