<ll-titulo>{{ titulo ? titulo : ("estudiar.plan_personales.plan_personal_sesion.titulo" | t) }}</ll-titulo>
<plan-personal-sesion
    *ngIf="plan_personal_id && sesion_id"
    [plan_personal_id]="plan_personal_id"
    [plan_personal_sesion_id]="sesion_id"
    [plan_personal_ciclo_orden]="ciclo"
    (planPersonalChange)="onPlanPersonalChange($event)"
    [enableDudas]="enableDudas"
    [enableReporte]="enableReportes"
    [planesPersonalesRoute]="planesPersonalesRoute"
    [routeWithId]="routeWithId"
    [unrestrictedProgressionMode]="unrestrictedProgressionMode"
    [enableNavigateToPlan]="enableNavigateToPlan"
    [goToDiagnosticoAtEnd]="goToDiagnosticoAtEnd"
></plan-personal-sesion>
