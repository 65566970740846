import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { PlanPersonalCiclo, PlanPersonalSesion, PlanPersonal } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { trigger, state, style, animate, transition, keyframes, query, stagger } from "@angular/animations"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Location } from "@angular/common"
import { PlanPersonalSesionBoxComponent } from "./plan_personal_sesion_box.component"

@Component({
    selector: "plan-personal-sesiones",
    templateUrl: "plan_personal_sesiones.component.html",
    styleUrls: ["plan_personal_sesiones.component.scss"],
    animations: [
        trigger("slideIn", [
            transition("* => *", [
                query(".slideRight", style({ opacity: 0 }), { optional: true }),
                query(".slideLeft", style({ opacity: 0 }), { optional: true }),
                query(
                    ".slideRight",
                    [
                        stagger(200, [
                            animate(
                                "0.3s ease-in",
                                keyframes([
                                    style({ opacity: 0, transform: "translateX(-30px)", offset: 0 }),
                                    style({ opacity: 1, transform: "translateX(5px)", offset: 0.8 }),
                                    style({ opacity: 1, transform: "translateX(0)", offset: 1.0 })
                                ])
                            )
                        ])
                    ],
                    { optional: true }
                ),
                query(
                    ".slideLeft",
                    [
                        stagger(200, [
                            animate(
                                "0.3s 0.1s ease-in",
                                keyframes([
                                    style({ opacity: 0, transform: "translateX(30px)", offset: 0 }),
                                    style({ opacity: 1, transform: "translateX(-5px)", offset: 0.8 }),
                                    style({ opacity: 1, transform: "translateX(0)", offset: 1.0 })
                                ])
                            )
                        ])
                    ],
                    { optional: true }
                )
            ])
        ]),
        trigger("smallSlide", [
            state("slideUp", style({ opacity: 1, transform: "translateY(0)" })),
            state("slideDown", style({ opacity: 1, transform: "translateY(0)" })),
            state("slideNone", style({ opacity: 1 })),
            transition("* => slideUp", [
                animate(
                    "0.4s 0ms ease-in",
                    keyframes([
                        style({ opacity: 0, transform: "translateY(30px)", offset: 0 }),
                        style({ opacity: 1, transform: "translateY(0)", offset: 1.0 })
                    ])
                )
            ]),
            transition("* => slideDown", [
                animate(
                    "0.4s 0ms ease-in",
                    keyframes([
                        style({ opacity: 0, transform: "translateY(-30px)", offset: 0 }),
                        style({ opacity: 1, transform: "translateY(0)", offset: 1.0 })
                    ])
                )
            ]),
            transition("* => slideNone", [
                animate(
                    "0.4s 0ms ease-in",
                    keyframes([style({ opacity: 0, offset: 0 }), style({ opacity: 1, offset: 1.0 })])
                )
            ])
        ])
    ]
})
export class PlanPersonalSesionesComponent implements OnInit {
    currentPlanPersonalCiclo: PlanPersonalCiclo
    currentCicloIndex: number
    planPersonalSesiones: PlanPersonalSesion[]
    @Input() planPersonalId: number
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("cicloSelect") cicloSelect: ElementRef
    @Input() sesiones_route: string
    @Input() cicloInicial: number
    @Input() personal = true
    @Input() showDiagnosticoBox = true
    enableStartBtn: boolean = false
    asignaturaId: number
    slideState: string = "none"
    sesionesAvance: { [x: number]: boolean } = {}
    planPersonalTipo: string

    _planPersonal: PlanPersonal
    @Input()
    set planPersonal(planPersonal: PlanPersonal) {
        this._planPersonal = planPersonal
        this.planPersonalTipo = planPersonal.plan_personal_tipo.tipo
        this.asignaturaId = this._planPersonal.generador_instrumento.asignatura_plataforma_id

        this.setLastCiclo()
        this.checkSesionesAvance()
        this.checkIfEnableDiagnosticButton()
    }
    get planPersonal() {
        return this._planPersonal
    }

    constructor(private router: Router, private authService: AuthService, protected location: Location) {}

    ngOnInit() {
        this.loadingLayout.ready()
    }

    ngAfterViewChecked() {
        document.querySelectorAll("bar").forEach((element, index) => {
            element.setAttribute("aria-labelledby", "progressbar-label-" + index)
        })
    }

    navigateToSesion(sesion_id) {}

    goBack() {
        this.location.back()
    }

    checkSesionesAvance() {
        this.planPersonalSesiones.forEach(sesion => {
            let avance = sesion.plan_personal_contenidos.reduce((accc, ppc) => {
                if (ppc.tipo == "Material") {
                    return accc && ppc.plan_personal_material_instancias.length > 0
                } else if (ppc.tipo == "Evaluacion") {
                    return accc && ppc.plan_personal_evaluacion_instancias.length > 0
                }

                return accc
            }, true)

            this.sesionesAvance[sesion.id] = avance
        })
    }

    checkIfEnableDiagnosticButton() {
        let enable = true
        for (let sesion of this.planPersonalSesiones) {
            enable = enable && this.sesionesAvance[sesion.id]
        }

        this.enableStartBtn = enable
    }

    setLastCiclo() {
        if (
            this.planPersonal &&
            this.planPersonal.plan_personal_ciclos &&
            this.planPersonal.plan_personal_ciclos.length > 0
        ) {
            this.currentPlanPersonalCiclo =
                this.planPersonal.plan_personal_ciclos[this.planPersonal.plan_personal_ciclos.length - 1]
            this.planPersonalSesiones = this.currentPlanPersonalCiclo.plan_personal_sesiones
            this.currentCicloIndex = this.planPersonal.plan_personal_ciclos.length - 1
        }
    }

    previousCycle() {
        this.currentCicloIndex = this.currentCicloIndex - 1
        this.onChangeSelect(this.currentCicloIndex)
        this.slideState = "slideDown"
    }

    nextCycle() {
        this.currentCicloIndex = this.currentCicloIndex + 1
        this.onChangeSelect(this.currentCicloIndex)
        this.slideState = "slideUp"

        if (this.cicloSelect) {
            this.cicloSelect.nativeElement.focus()
        }
    }

    onChangeSelect(cicleIndex: number) {
        this.loadingLayout.standby()
        this.currentPlanPersonalCiclo = this.planPersonal.plan_personal_ciclos[this.currentCicloIndex]
        this.planPersonalSesiones = this.currentPlanPersonalCiclo.plan_personal_sesiones
        this.checkSesionesAvance()
        this.loadingLayout.ready()
        this.slideState = "slideNone"
    }

    animationSlideInDone() {}

    animationSmallSlideDone() {
        this.slideState = "none"
    }
}
