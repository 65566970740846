import { Component, Input, OnInit } from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { SessionService } from "@puntaje/shared/core"
import { Smowls, SmowlObject } from "@puntaje/puntaje/api-services"

@Component({
    selector: "smowl-frame",
    templateUrl: "./smowl-frame.component.html",
    styleUrls: ["./smowl-frame.component.scss"]
})
export class SmowlFrameComponent implements OnInit {
    @Input() courseName: string | number
    @Input() modality: "course" | "quiz" | "test" | "survey" = "test"
    @Input() lang: "es" | "en" = "es"
    @Input() courseContainer: string | number
    @Input() disablePhotoCapture: boolean = false
    registrationLink: string = encodeURI(
        environment.domain.alumnos + (config.plataforma?.smowl?.registrationPath || "")
    )
    userId: number
    showSmowlIframe: boolean = false
    showRegisterLink: boolean = false
    smowlRegistrationUri: string
    smowlIframeUri: SafeResourceUrl

    constructor(
        protected smowlService: Smowls,
        private sessionService: SessionService,
        protected sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.userId = this.sessionService.getUserData().id
        this.checkUserPriveleges()
    }

    checkUserPriveleges() {
        this.smowlService.confirmRegistration(this.userId).then((smowlObject: SmowlObject) => {
            const ack = smowlObject?.result?.ack
            if (ack && (parseInt(ack) === 0 || (parseInt(ack) === -1 && this.disablePhotoCapture))) {
                this.smowlIframeUri = this.smowlIframeLink(smowlObject)
                this.showSmowlIframe = true
            } else {
                this.smowlRegistrationUri = this.smowlRegistrationLink(smowlObject)
                this.showRegisterLink = true
            }
        })
    }

    smowlRegistrationLink(smowlObject: SmowlObject) {
        return encodeURI(
            [
                config.plataforma?.smowl?.registrationEndpoint,
                "?entity_Name=",
                smowlObject.entity,
                "&swlLicenseKey=",
                smowlObject.licenseKey,
                "&user_idUser=",
                this.userId.toString(),
                "&lang=",
                this.lang,
                "&course_Container=",
                this.courseName.toString(),
                "&Course_link=",
                this.registrationLink
            ].join("")
        )
    }

    smowlIframeLink(smowlObject: SmowlObject) {
        let urlArray = [
            config.plataforma?.smowl?.cameraEndpoint,
            "?entity_Name=",
            smowlObject.entity,
            "&swlLicenseKey=",
            smowlObject.licenseKey,
            "&modality_ModalityName=",
            this.modality,
            "&course_CourseName=",
            this.courseName.toString(),
            "&course_Container=",
            this.courseContainer.toString(),
            "&user_idUser=",
            this.userId,
            "&lang=",
            this.lang,
            "&type=0",
            "&Course_link=",
            encodeURI(this.registrationLink)
        ]

        if (this.disablePhotoCapture) {
            urlArray.push("&savePhoto=0")
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(urlArray.join(""))
    }
}
