<div *ngIf="evaluacion">
    <h3>#{{ evaluacion.id }} - {{ evaluacion.evaluacion | easyplaceholder: "Evaluación sin nombre" }}</h3>
    <tabla-resumen-evaluacion-instancia
        *ngIf="dataEvaluacionInstancias"
        [data]="dataEvaluacionInstancias"
        [multiples]="true"
        [fromAlumnos]="true"
    ></tabla-resumen-evaluacion-instancia>

    <grafico-desempeno-promedio
        *ngIf="dataEvaluacionInstancias && asignatura"
        [asignatura]="asignatura"
        [dataOmitidas]="allOmitidas"
        [dataCorrectas]="allCorrectas"
        [dataIncorrectas]="allIncorrectas"
    ></grafico-desempeno-promedio>
    <div *ngIf="dataGraficoClasificacion && dataGraficoClasificacion.length > 0">
        <div *ngFor="let d of dataGraficoClasificacion">
            <grafico-desempeno-clasificacion
                [clasificaciones]="d.clasificaciones"
                [data]="d.data"
                [dataTotal]="d.dataTotal"
                [tipoClasificacion]="d.tipoClasificacion"
                [asignatura]="asignatura"
            ></grafico-desempeno-clasificacion>
        </div>
    </div>
</div>
