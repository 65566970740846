import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { UtilModule, FormModule, ModelViewModule, PaginatorModule } from "@puntaje/shared/core"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { CUIInputsModule, CUITextsModule } from "@puntaje/shared/cui"

import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { CommonsLayoutsModule } from "@puntaje/shared/layouts"

import { GrupoUsuariosModule } from "@puntaje/puntaje/new-modules/grupo-usuarios"

import { AsociacionComponent } from "./asociacion.component"
import { AsociacionesComponent } from "./asociaciones.component"
import { AsociacionesNewComponent } from "./asociaciones.new.component"
import { AsociacionesEditComponent } from "./asociaciones.edit.component"
//import { AsociacionesDeleteComponent } 	from './asociaciones.delete.component';
import { AsociacionesToolNavbarComponent } from "./asociaciones.toolnavbar.component"
import { AsociacionesBuscadorService } from "./asociaciones_buscador.service"
import { AsociacionesBuscadorComponent } from "./asociaciones_buscador.component"
import { AsociacionContactosBuscadorService } from "./asociacion_contactos_buscador.service"
import { AsociacionContactosToolNavbarComponent } from "./asociacion_contactos.toolnavbar.component"
import { AsociacionContactoComponent } from "./asociacion_contacto.component"
import { AsociacionContactosEditComponent } from "./asociacion_contactos.edit.component"
import { AsociacionContactosNewComponent } from "./asociacion_contactos.new.component"
import { AsociacionContactosComponent } from "./asociacion_contactos.component"
import { AsociacionContactosBuscadorComponent } from "./asociacion_contactos_buscador.component"
import { TopDocentesAsociacionComponent } from "./top-docentes-asociacion/top-docentes-asociacion.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        ModelViewModule,
        PaginatorModule,
        GrupoUsuariosModule,
        Angular2FontawesomeModule,
        SharedModule,
        RouterModule,
        CUIInputsModule,
        CUITextsModule
    ],
    declarations: [
        AsociacionComponent,
        AsociacionesToolNavbarComponent,
        AsociacionesComponent,
        AsociacionesEditComponent,
        AsociacionesNewComponent,
        AsociacionesBuscadorComponent,
        AsociacionContactosComponent,
        AsociacionContactosNewComponent,
        AsociacionContactosEditComponent,
        AsociacionContactosBuscadorComponent,
        AsociacionContactoComponent,
        AsociacionContactosToolNavbarComponent,
        TopDocentesAsociacionComponent
    ],
    exports: [
        AsociacionComponent,
        AsociacionesToolNavbarComponent,
        AsociacionesComponent,
        AsociacionesEditComponent,
        AsociacionesNewComponent,
        AsociacionesBuscadorComponent,
        AsociacionContactosComponent,
        AsociacionContactosNewComponent,
        AsociacionContactosEditComponent,
        AsociacionContactosBuscadorComponent,
        AsociacionContactoComponent,
        AsociacionContactosToolNavbarComponent,
        TopDocentesAsociacionComponent
    ]
})
export class AsociacionesModule {
    static forRoot(): ModuleWithProviders<AsociacionesModule> {
        return {
            ngModule: AsociacionesModule,
            providers: [AsociacionesBuscadorService, AsociacionContactosBuscadorService]
        }
    }
}
