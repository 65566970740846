import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from "@angular/core"
import { ScannerService, ResolvedData } from "@puntaje/shared/core"
import { EvaluacionInstancias, EvaluacionInstancia, Usuarios, Usuario } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal"
import { AuthService } from "@puntaje/shared/core"
import { Router } from "@angular/router"
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { SimpleModalService } from "@puntaje/shared/layouts"
import { Scannerjs } from "@puntaje/shared/scanner"

declare let OffscreenCanvas
declare let loadImage
enum State {
    Recording,
    AlignmentDetected,
    Stopped,
    Processed,
    Editing,
    Uploading,
    Uploaded
}

@Component({
    selector: "corregir-ensayo-camara",
    templateUrl: "./corregir-ensayo-camara.component.html",
    styleUrls: ["./corregir-ensayo-camara.component.scss"]
})
export class CorregirEnsayoCamaraComponent implements OnInit {
    @Input() perfil = "profesor"
    @Output() evaluacionRevisada = new EventEmitter<EvaluacionInstancia>()

    @ViewChild("detectingModal", { static: true }) detectingModal: ModalDirective
    @ViewChild("detectionModal", { static: true }) detectionModal: ModalDirective
    @ViewChild("loadingModal", { static: true }) loadingModal: ModalDirective
    _active: boolean
    @Input() set active(active: boolean) {
        this._active = active
        console.log("camaraActive", active, this.player)
        if (this.player) {
            if (active) this.startRecording()
            else this.stopRecording()
        }
    }

    canvas_child: any
    buffer_child: any
    context: CanvasRenderingContext2D
    buffer_context: CanvasRenderingContext2D

    @ViewChild("button") button_child: ElementRef
    @ViewChild("player", { static: true }) player_child: ElementRef

    @ViewChild("markersCanvasChild", { static: true }) markersCanvasChild: ElementRef
    @ViewChild("finalImageCanvasRef", { static: true }) finalImageCanvasRef: ElementRef
    markersContext: CanvasRenderingContext2D
    markersCanvas: HTMLCanvasElement
    finalImageContext: CanvasRenderingContext2D
    finalImageCanvas: HTMLCanvasElement

    canvas: HTMLCanvasElement
    buffer: HTMLCanvasElement
    button: HTMLButtonElement
    player: HTMLVideoElement
    animationId: number
    state: State = State.Recording
    State: typeof State = State
    paused = false

    data: ResolvedData
    respuestas: any
    forma: any
    idValue: any

    downloadImg: any
    uploadMsg: any
    skip: boolean
    skipAcc = 0
    skipFrameNumber = 0
    mensaje: string
    showMensaje: boolean = false

    identificadorUsuario: string
    markers: any

    fps: number = 0
    start: number

    constructor(
        protected scanner: ScannerService,
        protected router: Router,
        protected evaluacionInstanciaService: EvaluacionInstancias,
        protected modalService: BsModalService,
        private authService: AuthService,
        protected usuarioService: Usuarios,
        protected evaluacionesInstanciasService: EvaluacionInstancias,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.identificadorUsuario = config.hojaRespuesta.usingUserID
            ? "ID de usuario"
            : config.plataforma.identificadorUsuario[0]

        this.canvas_child = new OffscreenCanvas(0, 0)
        this.buffer_child = new OffscreenCanvas(0, 0)
    }

    ngAfterViewInit() {
        this.canvas = <HTMLCanvasElement>this.canvas_child
        this.buffer = <HTMLCanvasElement>this.buffer_child
        this.markersCanvas = <HTMLCanvasElement>this.markersCanvasChild.nativeElement
        this.finalImageCanvas = <HTMLCanvasElement>this.finalImageCanvasRef.nativeElement

        this.context = this.canvas.getContext("2d")
        this.buffer_context = this.buffer.getContext("2d")
        this.markersContext = this.markersCanvas.getContext("2d")
        this.finalImageContext = this.finalImageCanvas.getContext("2d")

        this.player = <HTMLVideoElement>this.player_child.nativeElement
        this.player.hidden = false
        this.buffer.hidden = false
        this.canvas.hidden = false
        this.finalImageCanvas.hidden = true

        this.player.onloadeddata = event => {
            this.state = State.Recording
            this.player.play()

            this.canvas.width = 1200 * (this.player.videoWidth / this.player.videoHeight)
            this.canvas.height = 1200

            this.markersCanvas.width = this.canvas.width
            this.markersCanvas.height = this.canvas.height

            this.finalImageCanvas.width = this.canvas.width
            this.finalImageCanvas.height = this.canvas.height

            this.buffer.width = this.player.videoWidth
            this.buffer.height = this.player.videoHeight

            this.scanner.initialize(this.canvas, this.context)

            requestAnimationFrame(this.mainLoop)
            // setTimeout(this.mainLoop, 0, new Date().getTime())
        }

        if (this._active) this.startRecording()
    }

    drawCorners(markers) {
        if (markers.length == 0) return

        let corners, corner, i, j

        this.markersContext.lineWidth = 3
        for (i = 0; i !== markers.length; ++i) {
            corners = markers[i].corners

            this.markersContext.beginPath()
            this.markersContext.strokeStyle = "red"

            corner = corners[0]
            this.markersContext.moveTo(corner.x, corner.y)
            for (j = 1; j !== corners.length; ++j) {
                corner = corners[j]

                this.markersContext.lineTo(corner.x, corner.y)
            }
            this.markersContext.closePath()
            this.markersContext.stroke()

            this.markersContext.strokeStyle = "green"
            this.markersContext.strokeRect(corners[0].x - 2, corners[0].y - 2, 4, 4)
        }
    }

    drawId(markers) {
        let corners, corner, x, y, i, j

        this.markersContext.strokeStyle = "blue"
        this.markersContext.lineWidth = 1

        for (i = 0; i !== markers.length; ++i) {
            corners = markers[i].corners

            x = Infinity
            y = Infinity

            for (j = 0; j !== corners.length; ++j) {
                corner = corners[j]

                x = Math.min(x, corner.x)
                y = Math.min(y, corner.y)
            }
            this.markersContext.strokeText(markers[i].id, x, y)
        }
    }

    isHorizontal(id1, id2, markers) {
        const m1 = markers.find(m => m.id == id1)
        const m2 = markers.find(m => m.id == id2)

        return m1 && m2 && Math.abs(m2.corners[1].y - m1.corners[0].y) <= 10
    }

    isVertical(id1, id2, markers) {
        const m1 = markers.find(m => m.id == id1)
        const m2 = markers.find(m => m.id == id2)

        return m1 && m2 && Math.abs(m2.corners[3].x - m1.corners[0].x) <= 10
    }

    mainLoop = step => {
        this.markersContext.clearRect(0, 0, this.markersCanvas.width, this.markersCanvas.height)

        if (!this.start) {
            this.start = step
        }

        if (!environment.production) {
            this.fps++
            const time = (step - this.start) / 1000
            if (time >= 1) {
                this.fps = 1
                this.start = step - (time - 1) * 1000
            }
        }

        let markers, strokeStyle

        if (this.skipAcc == this.skipFrameNumber) {
            this.skip = false
            this.skipAcc = 0
        } else {
            this.skip = true
            this.skipAcc += 1
        }

        this.buffer_context.drawImage(this.player, 0, 0)

        const scaledImage = loadImage.scale(
            this.buffer, // img or canvas element
            { maxWidth: this.canvas.width, orientation: true }
        )

        this.context.drawImage(scaledImage, 0, 0)

        if (!this.skip) {
            markers = this.scanner.detectMarkers()
            this.markers = markers
            const horizontalMarkers = this.scanner.getHorizontalMarkers()
            const verticalMarkers = this.scanner.getVerticalMarkers()

            strokeStyle = "red"
            if (
                this.isHorizontal(horizontalMarkers[0], horizontalMarkers[1], markers) &&
                this.isVertical(verticalMarkers[0], verticalMarkers[1], markers)
            ) {
                strokeStyle = "green"
                if (this.scanner.allRespuestaMarkersVisible(markers)) {
                    this.paused = true
                    this.state = State.AlignmentDetected
                }
            }
        }

        if (!this.skip) {
            this.markersContext.beginPath()
            this.markersContext.strokeStyle = strokeStyle
            this.markersContext.lineWidth = 5
            this.markersContext.rect(20, 20, this.markersCanvas.width - 40, this.markersCanvas.height - 40)
            this.markersContext.stroke()

            this.drawCorners(markers)
            this.drawId(markers)
        }

        if (this.state == State.AlignmentDetected) {
            this.stopRecording()
            this.readHoja()
        }

        if (this.state == State.Recording) {
            this.animationId = requestAnimationFrame(this.mainLoop)
        }
    }

    onCloseDetectionModal() {
        this.detectionModal.hide()

        // Comenté esto porque el flujo cambió un poco y no sé la intención exacta de esto
        // this.verificarRut()
    }

    verificarRut() {
        if (config.app.name != "profesores" && this.data.idValue[0] != "" && this.perfil != "guest") {
            const us = "usuario_" + config.plataforma.pais
            return this.usuarioService
                .find(this.authService.getUserData().id, { render_options: { include: { [us]: null } } })
                .then((usuario: Usuario) => {
                    if (config.plataforma.pais == "chile") {
                        const rut = this.data.idValue[0] + "-" + this.data.idValue[1]
                        if (rut != usuario.usuario_chile.rut) {
                            this.uploadMsg = { warnings: ["El rut no corresponde al usuario"] }
                            this.state = State.Uploaded
                            return false
                        }
                    } else if (config.plataforma.pais == "colombia") {
                        const ci = this.data.idValue[0]
                        if (ci != usuario.usuario_colombia.cc && ci != usuario.usuario_colombia.ti) {
                            this.uploadMsg = { warnings: ["El ci/ti no corresponde al usuario"] }
                            this.state = State.Uploaded
                            return false
                        }
                    }

                    return false
                })
        }

        return null
    }

    stopRecording() {
        cancelAnimationFrame(this.animationId)
        this.paused = true
        this.state = State.Stopped
        this.player.pause()
        this.player.hidden = true
        this.finalImageCanvas.hidden = false
        if (this.state != State.Stopped) {
            this.finalImageContext.drawImage(this.canvas, 0, 0)
        }
        if (<MediaStream>this.player.srcObject)
            (<MediaStream>this.player.srcObject).getTracks().forEach(track => {
                track.stop()
            })
    }

    startRecording() {
        this.markersContext.clearRect(0, 0, this.markersCanvas.width, this.markersCanvas.height)
        this.finalImageCanvas.hidden = true

        let browser = <any>navigator
        browser.getUserMedia =
            browser.getUserMedia || browser.webkitGetUserMedia || browser.mozGetUserMedia || browser.msGetUserMedia

        const onLoad = stream => (this.player.srcObject = stream)
        const onFail = e => {
            // FIX para cuando no aguante la resolución más grande
            browser.mediaDevices
                .getUserMedia({
                    video: {
                        facingMode: "environment",
                        width: { ideal: 1920 },
                        height: { ideal: 1080 }
                    }
                })
                .then(onLoad)
        }

        browser.mediaDevices
            .getUserMedia({
                video: {
                    facingMode: "environment",
                    width: { ideal: 3840 },
                    height: { ideal: 2160 }
                }
            })
            .then(onLoad)
            .catch(onFail)

        this.paused = false
        this.player.hidden = false
    }

    readHoja() {
        this.detectingModal.show()

        setTimeout(() => {
            this.data = this.scanner.resolveData()
            this.finalImageContext.drawImage(this.canvas, 0, 0)

            this.state = State.Processed
            const verificarRutPromise = this.verificarRut() || Promise.resolve(false)

            verificarRutPromise.then(() => {
                this.detectingModal.hide()
                this.detectionModal.show()
            })
        }, 500)
    }

    processReading() {
        this.loadingModal.show()

        setTimeout(() => {
            this.state = State.Uploading

            this.evaluacionInstanciaService.enableIgnoreModel()

            const params: any = {
                row: [...this.data.idValue, this.data.forma].concat(Object.values(this.data.respuestas)),
                pais: config.plataforma.pais,
                perfil: this.perfil
            }

            if (config.hojaRespuesta?.usingUserID) {
                params.using_user_id = config.hojaRespuesta.usingUserID
            }

            this.evaluacionInstanciaService.subirLectura(params).then((resp: any) => {
                this.evaluacionInstanciaService.disableIgnoreModel()
                this.evaluacionRevisada.emit(resp.instancia)

                this.uploadMsg = resp
                this.state = State.Uploaded
                this.loadingModal.hide()
            })
        }, 400)
    }

    verResultados(ensayoInstanciaId) {
        if (config.app.name == "profesores") {
            this.router.navigate(["/ensayo_instancias_estudiante", ensayoInstanciaId])
        } else if (config.app.name == "alumnos") {
            this.router.navigate(["/ensayo_instancias", ensayoInstanciaId])
        }
    }

    ngOnDestroy() {
        this.stopRecording()
    }

    verificaExisteEval() {
        const params = {
            evaluacion_instancia: {
                evaluacion_id: this.data.forma,
                oficial: 1,
                usuario_id: this.authService.getUserData().id
            }
        }

        if (config.hojaRespuesta.formatEvalIdStr) {
            params["format_id_str"] = config.hojaRespuesta.formatEvalIdStr
        }

        this.evaluacionesInstanciasService.where(params).then(eis => {
            if (eis.length > 0) {
                this.showModalSubida()
            } else {
                this.processReading()
            }
        })
    }

    showModalSubida() {
        this.showMensaje = true
        this.mensaje =
            "Resultados de evaluación N " +
            this.data.forma +
            " estarán en tu historial, pero no serán considerados en tus estadísticas. Porque esta evaluación ya  fue corregida"
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que quiere subir el resultado nuevamente? ya tienes registrados resultados para esta prueba."
        )
        this.simpleModalService.setModalCallback(() => this.processReading())
        this.simpleModalService.showSimpleModal()
    }

    enableEditObj() {
        this.state = State.Editing
    }

    disableEditObj() {
        this.state = State.Processed
    }

    getIndex(index: number) {
        return index
    }

    parseInt(s: string) {
        return parseInt(s)
    }

    onClickCanvas(event: MouseEvent) {
        const _scanner = new Scannerjs({
            context: this.markersCanvas.getContext("2d"),
            markers: this.markers,
            canvasWidth: this.markersCanvas.width,
            canvasHeight: this.markersCanvas.height
        })
        const dimSectores = this.scanner.defaultDimSectores
        const sectores = config.hojaRespuesta.sectores
        const ratioX = this.markersCanvas.width / this.markersCanvas.offsetWidth
        const ratioY = this.markersCanvas.height / this.markersCanvas.offsetHeight

        let rutObj = this.scanner.rawObj.rutObj
        let formaObj = this.scanner.rawObj.formaObj
        let respuestasObj = this.scanner.rawObj.respuestasObj

        _scanner.changeMarked(
            ~~(event.offsetX * ratioX),
            ~~(event.offsetY * ratioY),
            respuestasObj.concat([rutObj], [formaObj]),
            dimSectores,
            sectores
        )

        this.data.respuestas = this.scanner.convertResponse(respuestasObj)
        this.data.idValue = this.scanner.convertId(rutObj)
        this.data.forma = this.scanner.convertForms(formaObj).forma
        this.data.formaType = this.scanner.convertForms(formaObj).formaType
    }
}
