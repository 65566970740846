import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"
import { Reporte } from "./reportes.model"

@Injectable()
export class Reportes extends NebuBaseService<Reporte> {
    tableName = "reportes"
    singularTableName = "reporte"
    modelClass = Reporte

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    grouped(params: any) {
        return this.all().concatExtra("grouped").get(params, true)
    }

    updateMany(params: any) {
        return this.all().concatExtra("update_many").patch(params)
    }
}
