import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { NotificacionesComponent } from "./notificaciones.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { UtilModule } from "@puntaje/shared/core"

@NgModule({
    imports: [CommonModule, FormsModule, NotificacionesModule, CUILayoutsModule, UtilModule],
    exports: [NotificacionesComponent],
    declarations: [NotificacionesComponent],
    providers: []
})
export class PuntajeNotificacionesModule {}
