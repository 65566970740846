import { Component, Input, Output, EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "simple-search-filter",
    templateUrl: "simple_search_filter.component.html",
    styleUrls: ["simple_search_filter.component.scss"]
})
export class SimpleSearchFilterComponent {
    currentSearch: string
    lastSearch: string = ""
    @Output() updateSearch = new EventEmitter()
    @Output() clearSearch = new EventEmitter()
    @Input() autoclearEnabled: boolean = true
    @Input() enableBarMode: boolean = true //El input de búsqueda flota a la derecha pero ocupa invisible todo el espacio a lo ancho
    @Input() placeholder: string = "Buscar..."
    @Input() elastic: boolean = false

    constructor() {}

    search() {
        if (this.currentSearch && this.currentSearch.length > 0) {
            this.lastSearch = this.currentSearch
            this.updateSearch.emit(this.currentSearch)
        } else {
            this.clear()
        }
    }

    clear() {
        this.currentSearch = ""
        if (this.lastSearch.length > 0) {
            this.lastSearch = ""
            this.clearSearch.emit()
        }
    }

    autoclear() {
        if (this.currentSearch == "" && this.autoclearEnabled) {
            this.clearSearch.emit()
        }
    }
}
