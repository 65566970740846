import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { TypeformWidget } from "./typeform_widgetes.model"

@Injectable()
export class TypeformWidgetes extends PuntajeBaseService<TypeformWidget> {
    tableName = "typeform_widgetes"
    singularTableName = "typeform_widget"
    modelClass = TypeformWidget

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public bestFit() {
        return this.all().concatExtra("best_fit").get()
    }
}
