import { Subject } from "rxjs"
import { Injectable } from "@angular/core"
import { Store, select } from "@ngrx/store"
import { State, SetFiltros, selectEstadisticasFiltros, SetSelectedAsignaturaWithId } from "@puntaje/puntaje/store"
import { map, filter, tap } from "rxjs/operators"

@Injectable()
export class FiltroEstadisticasService {
    changeParams: Subject<any> = new Subject()

    establecimientoId: number
    grupoUsuarioId: number
    evaluacionId: number
    generadorInstrumentoId: number
    fromEstadistica: boolean = false

    constructor(protected store: Store<State>) {}

    setFiltrosId(
        establecimientoId,
        grupoUsuarioId,
        usuarioId,
        evaluacionId,
        generadorInstrumentoId,
        evaluacionTipo,
        asignaturaId,
        periodo = null
    ) {
        this.store.dispatch(
            new SetFiltros({
                evaluacionTipo,
                asignaturaId,
                filtro: { establecimientoId, grupoUsuarioId, usuarioId, evaluacionId, generadorInstrumentoId, periodo }
            })
        )

        if (asignaturaId) {
            this.store.dispatch(new SetSelectedAsignaturaWithId({ asignaturaId }))
            this.fromEstadistica = true
        }
    }

    getFiltrosObservable(evaluacionTipo, asignaturaId) {
        return this.store.pipe(
            select(selectEstadisticasFiltros),
            map(filtros =>
                !!filtros[evaluacionTipo] && !!filtros[evaluacionTipo][asignaturaId]
                    ? filtros[evaluacionTipo][asignaturaId]
                    : {}
            )
        )
    }

    onChangeParams() {
        // this.changeParams.next({establecimientoId: this.establecimientoId, grupoUsuarioId: this.grupoUsuarioId, evaluacionId: this.evaluacionId, generadorInstrumentoId: this.generadorInstrumentoId});
        // this.clearParams();
    }

    clearParams() {
        // this.establecimientoId = this.grupoUsuarioId = this.evaluacionId = null;
    }
}
