import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Plataforma, TipoInstrumento } from ".."

export class TipoInstrumentoPlataforma extends BaseModel {
    public static className: string = "TipoInstrumentoPlataforma"
    public alias: string
    public plataforma_id: number
    public tipo_instrumento_id: number
    public created_at: Date
    public updated_at: Date

    @SaveType(() => TipoInstrumento) tipo_instrumento: TipoInstrumento[]
    @SaveType(() => Plataforma) plataforma: Plataforma[]

    public toString() {
        return this.alias
    }

    public getModelName() {
        return "TipoInstrumentoPlataforma"
    }
}
