<div class="container-dudas-categorias">
    <loading-layout #loadingLayout>
        <div class="lista-dudas-categorias">
            <div class="todas-box">
                <div class="duda-categoria-row">
                    <div class="icon-cell">
                        <ogr-icon name="dialogos" class="icono"></ogr-icon>
                    </div>
                    <div class="info-cell">
                        <h2>
                            <a routerLink="/dudas">{{ "participa.dudas.todas" | t }}</a>
                        </h2>
                    </div>
                    <div class="btn-cell">
                        <a routerLink="/dudas" class="btn btn-default btn-todas">
                            <span>{{ "participa.dudas.btn_lista" | t }}&nbsp;</span>
                            <fa name="chevron-right"></fa>
                        </a>
                    </div>
                </div>
            </div>
            <ng-container *ngFor="let categoria of categorias">
                <dudas-categoria-box
                    [categoria]="categoria"
                    [sub_categorias]="sub_categorias[categoria.id]"
                ></dudas-categoria-box>

                <div class="lista-sub-categorias" *ngIf="sub_categorias[categoria.id]">
                    <dudas-categoria-box
                        [categoria]="subcategoria"
                        *ngFor="let subcategoria of sub_categorias[categoria.id]"
                    ></dudas-categoria-box>
                </div>
            </ng-container>
        </div>
    </loading-layout>
</div>
