import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()

//interface callbackType { (myArgument: string): void }
export class ToggleClassService {
    toggleClass(elem, clase) {
        this.modifyClass(elem, clase, true, true)
    }

    removeClass(elem, clase) {
        this.modifyClass(elem, clase, false, true)
    }

    addClass(elem, clase) {
        this.modifyClass(elem, clase, true, false)
    }

    private modifyClass(elem, clase, allow_add: boolean, allow_remove: boolean) {
        //Para la función toggle, deben ser true los parámetros add y remove
        var classes = elem.className.split(" ")
        var classList = classes.filter(function (className) {
            return className == clase
        })
        if (classList.length == 1) {
            if (allow_remove) {
                var remainingClasses = classes.filter(function (className) {
                    return className != clase
                })
                var classString = remainingClasses.join(" ")
                elem.className = classString
            }
        } else {
            if (allow_add) {
                elem.className = elem.className + " " + clase
            }
        }
    }
}
