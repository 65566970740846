import { BaseModel } from "@puntaje/shared/core"

export class UsuarioBrasil extends BaseModel {
    public cpf: string
    public telefono: string
    public usuario_id: number
    public year_egreso: number

    constructor() {
        super()
    }

    public toString() {
        return this.cpf
    }
}
