import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"

@Injectable()
export class PdfService {
    public tabState: any = {}
    public tabSubject: Subject<any> = new Subject()

    constructor() {
        pdfMake.vfs = pdfFonts.pdfMake.vfs
    }

    /**
     * Crea y dercarga un archivo pdf a partir de un hash con todo el contenido del pdf.
     * @param {Hash} docDefinition hash con todo el contenido del pdf.
     * @param {string} fileName nombre del archivo a descargar
     * @return {Promise} promesa de la descarga del archivo
     */
    public download(docDefinition, fileName: string) {
        return pdfMake.createPdf(docDefinition).download(fileName)
    }

    /**
     * Crea el blob de un pdf a partir de un hash con todo el contenido del pdf.
     * @param {Hash} docDefinition hash con todo el contenido del pdf.
     * @return {Blob} blob del pdf
     */
    public async blob(docDefinition): Promise<Blob> {
        const pdfDocGenerator = pdfMake.createPdf(docDefinition)
        const blob = (await new Promise(resolve => {
            pdfDocGenerator.getBlob(blob => resolve(blob))
        })) as unknown as Blob

        return blob
    }
}
