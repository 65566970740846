import { Component, Input, HostBinding } from "@angular/core"

@Component({
    selector: "cui-widget-zone,[cui-widget-zone]",
    template: `
        <div class="position-container" [style.paddingTop]="paddingTop">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ["cui-widget-zone.component.scss"]
})
export class CUIWidgetZoneComponent {
    @Input() paddingTop: string = "10px"

    constructor() {}
}
