<loading-layout #loadingLayout>
    <ng-container *ngIf="!loadingLayout.loading">
        <div class="posts-container">
            <post-thumbnail
                *ngFor="let post of posts"
                [post]="post"
                [external]="external"
                class="post"
            ></post-thumbnail>
        </div>
    </ng-container>
</loading-layout>
<paginator
    *ngIf="posts && posts.length != 0"
    [getList]="setData.bind(this)"
    [class.hidePaginator]="!posts || (posts && posts.length == 0)"
    [per]="per"
></paginator>
<div *ngIf="!loadingLayout.loading && (!posts || (posts && posts.length == 0))">
    <p class="no-content">No hay entradas publicadas.</p>
</div>
