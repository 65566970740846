<div class="steps">
    <div
        *ngFor="let step of steps; let i = index"
        class="step"
        [class.active]="currentIndex == i"
        (click)="selectIndex(i)"
        [class.error]="step.status == RegisterStepStatus.Error"
        [class.ready]="step.status == RegisterStepStatus.Ready"
        [class.pending]="step.status == RegisterStepStatus.Pending"
    >
        <fa class="icono" [name]="step.icono" *ngIf="step.status != RegisterStepStatus.Ready"></fa>
        <fa class="icono" name="check" *ngIf="step.status == RegisterStepStatus.Ready"></fa>
    </div>
</div>
