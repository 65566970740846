import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

// import { routing } from "./profesores_dudas.routing"
import { DudasModule } from "@puntaje/puntaje/new-modules/dudas"
import { ApiModule as PuntajeApiModule } from "@puntaje/puntaje/api-services"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { DudasCategoriasComponent } from "./dudas_categorias.component"
import { DudasComponent } from "./dudas.component"
import { CUIInputsModule } from "@puntaje/shared/cui"
import { DudaComponent } from "./duda.component"
import { DudaNewComponent } from "./duda_new.component"
import { DudaEditComponent } from "./duda_edit.component"
import { UtilModule } from "@puntaje/shared/core"
// import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"

// import { DudaNewComponent } from "./duda_new.component"
// import { DudaEditComponent } from "./duda_edit.component"
// import { DudasComponent } from "./dudas.component"
// import { DudaComponent } from "./duda.component"
// import { DudasCategoriasComponent } from "./dudas_categorias.component"

@NgModule({
    imports: [
        CommonModule,
        PuntajeApiModule,
        DudasModule,
        Angular2FontawesomeModule,
        CUIInputsModule,
        CUILayoutsModule,
        UtilModule
    ],
    declarations: [DudasCategoriasComponent, DudasComponent, DudaComponent, DudaNewComponent, DudaEditComponent],
    exports: [DudasCategoriasComponent, DudasComponent, DudaComponent, DudaNewComponent, DudaEditComponent]
})
export class PuntajeDudasModule {}
