import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Establecimiento } from "./establecimientos.model"
import { Usuario } from "./usuarios.model"

export class EstablecimientoMonitor extends BaseModel {
    public id: number
    public monitor_id: number
    public establecimiento_id: number
    @SaveType(() => Establecimiento) public establecimiento: Establecimiento
    @SaveType(() => Usuario) public monitor: Usuario

    public toString() {
        return this.id
    }
}
