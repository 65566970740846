import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Criterio } from "./criterio"

export class CriterioAsignacion extends BaseModel {
    public static className: string = "CriterioAsignacion"
    criterio_type: string
    criterio_id: number

    @SaveType(() => Criterio) criterio: Criterio
}
