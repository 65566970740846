import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { TypeformWidget, TypeformWidgetes, ScriptsService } from "@puntaje/puntaje/api-services"
import { AuthService, I18nService } from "@puntaje/shared/core"

import { createPopup, createWidget, createSidetab, createSlider } from "@typeform/embed"

@Component({
    selector: "typeform-widgets-view",
    templateUrl: "typeform-widgets-view.component.html",
    styleUrls: ["typeform-widgets-view.component.scss"]
})
export class TypeformWidgetsViewComponent implements OnInit, OnDestroy {
    typeforms: TypeformWidget[]
    closeFunctions = []
    usuarioId: number
    @Input() widgetContainer: string

    constructor(
        protected auth: AuthService,
        protected translateServive: I18nService,
        protected typeformWidgetService: TypeformWidgetes,
        protected script: ScriptsService
    ) {}

    ngOnInit() {
        //'iVQY9Usc'
        this.typeformWidgetService.disableIgnoreCatch()
        this.usuarioId = this.auth.getUserData().id
        this.typeformWidgetService
            .bestFit()
            .then((response: TypeformWidget[]) => {
                this.typeforms = response
                this.typeforms.forEach(tf => {
                    const typeformType = tf.typeform_widget_tipo.tipo
                    switch (typeformType) {
                        case "Popup": {
                            const { open, close, toggle, refresh, unmount } = createPopup(tf.codigo, {
                                hidden: {
                                    usuario: this.usuarioId.toString()
                                }
                            })
                            this.closeFunctions.push(close)
                            this.checkForButton()
                            break
                        }
                        case "Side tab": {
                            const { open, close, toggle, refresh, unmount } = createSidetab(tf.codigo, {
                                buttonText: tf.texto_boton ?? this.translateServive.translate("typeform.button"),
                                buttonColor: tf.color_boton,
                                hidden: {
                                    usuario: this.usuarioId.toString()
                                }
                            })
                            this.closeFunctions.push(close)
                            break
                        }
                        case "Slider": {
                            const { open, close, toggle, refresh, unmount } = createSlider(tf.codigo, {
                                hidden: {
                                    usuario: this.usuarioId.toString()
                                }
                            })
                            this.closeFunctions.push(close)
                            break
                        }
                        case "Widget": {
                            //this requires a container name
                            const { refresh } = createWidget(tf.codigo, {
                                container: document.querySelector(this.widgetContainer),
                                hidden: {
                                    usuario: this.usuarioId.toString()
                                }
                            })
                            break
                        }
                        default: {
                            break
                        }
                    }
                })
                this.script
                    .load("typeform")
                    .catch(error => console.log(error))
            })
            .catch(error => {})
            .finally(() => {
                this.typeformWidgetService.enableIgnoreCatch()
            })
    }

    ngOnDestroy() {
        this.closeTypeforms()
    }

    closeTypeforms() {
        //yeah, nasty, I know
        document.querySelectorAll(".tf-v1-sidetab").forEach(elem => {
            elem.remove()
        })
        //this should work, but it doesn't :/
        this.closeFunctions.forEach(fun => {
            fun()
        })
    }

    checkForButton() {
        const elem = document.getElementById("typeform-popup-button")
        if (elem && elem.getAttribute("data-tf-loaded") === "true") {
            elem.click()
        } else {
            setTimeout(() => {
                this.checkForButton()
            }, 1000)
        }
    }
}
