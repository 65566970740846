import { Component, Input, forwardRef, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"
import { BaseModel } from "../api/base.model"
import { Subject, Subscription } from "rxjs"
import { I18nService } from "../i18n/i18n.service"

@Component({
    selector: "file",
    template: `
        <input
            type="file"
            #trueinput
            class="form-control inputfile"
            (change)="fileChange($event)"
            [id]="key"
            tabindex="-1"
            [attr.aria-label]="label"
        />
        <div class="progress-positioning">
            <div class="file-container">
                <input
                    type="text"
                    #fakeinput
                    [attr.aria-label]="fakeinput.value"
                    class="form-control disabled-fileinput"
                    placeholder=""
                    disabled=""
                />
                <span class="inputfile-label-wrapper">
                    <label
                        class="inputfile-label btn btn-default"
                        [attr.for]="key"
                        tabindex="0"
                        (keyup.enter)="onKey($event)"
                    >
                        <span class="icon-span-filestyle glyphicon glyphicon-folder-open"></span>
                        {{ nameButton }}
                    </label>
                </span>
            </div>
            <div class="progress">
                <div
                    class="progress-bar"
                    role="progressbar"
                    [attr.aria-valuenow]="upload_progress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    [ngStyle]="{ width: getProgressWidth() }"
                ></div>
            </div>
        </div>
    `,
    styleUrls: ["input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileComponent),
            multi: true
        }
    ]
})
export class FileComponent implements ControlValueAccessor, OnInit {
    selected: any[] = []
    nameButton: string

    @Input() file: any
    @Input() xhr_upload: Subject<XMLHttpRequest>
    @Input() key: string
    @Input() label: string
    sub: Subscription
    upload_progress: number = 0

    onChange: (_: any) => {}
    onTouch = () => {}

    @ViewChild("fakeinput", { static: true }) fakeinput: ElementRef
    @ViewChild("trueinput", { static: true }) trueinput: ElementRef

    constructor(protected i18nService: I18nService) {}

    ngOnInit() {
        this.getPlatform()
        if (this.xhr_upload !== undefined) {
            this.sub = this.xhr_upload.subscribe({
                next: (xhr: XMLHttpRequest) => {
                    xhr.upload.addEventListener(
                        "progress",
                        (e: any & { loaded: number; total: number }) => {
                            this.upload_progress = (e.loaded / e.total) * 100
                        },
                        false
                    )
                }
            })
        }
    }

    fileChange(event) {
        this.upload_progress = 0
        const target = event.target || event.srcElement
        this.file = target.files[0]
        this.onChange(this.file)
        if (this.file) {
            this.fakeinput.nativeElement.value = this.file.name
        } else {
            this.fakeinput.nativeElement.value = ""
            this.trueinput.nativeElement.value = ""
        }
    }

    getProgressWidth() {
        return this.upload_progress + "%"
    }

    writeValue(value: any) {
        if (value instanceof Array) {
            this.selected = value
            this.onChange(this.selected)
        } else if (!value) {
            this.fakeinput.nativeElement.value = ""
            this.trueinput.nativeElement.value = ""
        } else {
            this.fakeinput.nativeElement.value = value
        }
    }

    registerOnChange(fn: (value: any) => any) {
        this.onChange = (_: any) => {
            return fn(this.file)
        }
    }

    registerOnTouched(fn: () => any) {
        this.onTouch = fn
    }

    //ngondestroy -> unsuscribe del observable
    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
    }

    onKey(event: any) {
        event.target.click()
    }

    getPlatform() {
        if (
            window.navigator.platform == "iPhone" ||
            window.navigator.platform == "iPad" ||
            window.navigator.platform == "Android" ||
            window.navigator.platform == "BlackBerry"
        ) {
            this.nameButton = this.i18nService.translate("file.cargar_archivo_mobile") || "Sacar foto"
        } else {
            this.nameButton = this.i18nService.translate("file.cargar_archivo") || "Elegir archivo"
        }
    }
}
