import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Clasificacion } from "./clasificaciones.model"
import { ClasificacionTipoAlias } from "./clasificacion_tipo_aliases.model"
import { Criterio } from "./criterio"

export class ClasificacionTipo extends BaseModel {
    public static className: string = "ClasificacionTipo"
    public clasificacion_tipo: string
    public created_at: Date
    public updated_at: Date

    @SaveType(() => Clasificacion) public clasificaciones: Clasificacion[]
    @SaveType(() => ClasificacionTipoAlias) public clasificacion_tipo_aliases: ClasificacionTipoAlias[]

    public toString() {
        return this.clasificacion_tipo
    }
}
