import { Pipe, PipeTransform } from "@angular/core"

@Pipe({ name: "taxativoAlternativas" })
export class TaxativoWithAlternativasPipe implements PipeTransform {
    transform(value: any) {
        const regexp = /\[\[([^\]]+)\]\]/
        let r = 65
        if (value) {
            let new_val = value
            while (new_val.match(regexp)) {
                new_val = (new_val as string).replace(
                    regexp,
                    (m, o, s) =>
                        `<span class="frac"><span>${o}</span><span class="symbol">/</span><span class="bottom">${String.fromCharCode(
                            r
                        )}</span></span>`
                )
                r += 1
            }
            return new_val
        }
        return value
    }
}
