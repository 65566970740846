import { Component, OnInit, Input } from "@angular/core"
import { Alternativa } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "respuesta-spr",
    templateUrl: "./respuesta-spr.component.html",
    styleUrls: ["./respuesta-spr.component.scss"]
})
export class RespuestaSprComponent implements OnInit {
    @Input() respuestaId: number
    @Input() alternativas: Alternativa[]

    alternativaContestada: Alternativa
    alternativaCorrecta: Alternativa

    inputsCorrecta = new Array(4).fill("")
    inputsContestada = new Array(4).fill("")

    constructor() {}

    ngOnInit() {
        if (this.respuestaId) {
            this.alternativaContestada = this.alternativas.find(alternativa => alternativa.id == this.respuestaId)
        }
        if (this.alternativaContestada && this.alternativaContestada.correcta) {
            this.alternativaCorrecta = this.alternativaContestada
        } else {
            this.alternativaCorrecta = this.alternativas.find(
                alternativa => alternativa.correcta && alternativa.orden != -1
            )
        }
        this.setInputs()
    }

    setInputs() {
        if (this.alternativaCorrecta) {
            const textoSplit = this.alternativaCorrecta.alternativa.split("")
            textoSplit.forEach((val, index) => {
                this.inputsCorrecta[index] = val
            })
        }
        if (this.alternativaContestada) {
            const textoSplit = this.alternativaContestada.alternativa.split("")
            textoSplit.forEach((val, index) => {
                this.inputsContestada[index] = val
            })
        }
    }
}
