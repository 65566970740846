import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { Duda, DudaCategoria, DudaCategorias, GrupoUsuario, GrupoUsuarios } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"
import { DudasBuscadorService } from "./dudas_buscador.service"

@Component({
    selector: "filtro-dudas",
    templateUrl: "filtro_dudas.component.html",
    styleUrls: ["filtro_dudas.component.scss"]
})
export class FiltroDudasComponent implements OnInit {
    misDudas: boolean = false
    @Input() tipoEvaluacion: string
    @Input() dudaCategoriaId: number
    @Input() showCursos: boolean
    @Input() fromProfesor: boolean
    lista_asignaturas: any
    searchParams: any = {}
    usuarioId: number
    dudaCategoria: DudaCategoria
    dudaCategorias: DudaCategoria[]
    @Output() busquedaChange = new EventEmitter<any>()
    answerStatuses: any[]
    answerStatus: any

    grupoUsuario: GrupoUsuario
    grupoUsuarios: GrupoUsuario[]

    constructor(
        protected authService: AuthService,
        protected dudaCategoriasService: DudaCategorias,
        protected dudasBuscadorService: DudasBuscadorService,
        protected grupoUsuariosService: GrupoUsuarios
    ) {
        this.usuarioId = this.authService.getUserData().id
    }

    ngOnInit() {
        this.searchParams.duda = {}
        this.searchParams.duda_categoria = {}
        if (this.showCursos) {
            this.getGrupoUsuario()
        }

        this.getDudaCategorias()
        this.answerStatuses = this.dudasBuscadorService.getAnswerStatuses()
        this.answerStatus = this.answerStatuses[0]
    }

    toggleMisDudas(value: boolean) {
        this.misDudas = value
        let u = this.misDudas ? this.usuarioId : null
        this.dudasBuscadorService.setUsuario(u)
    }

    answerStatusChange() {
        this.dudasBuscadorService.setAnswerStatus(this.answerStatus)
    }

    getDudaCategorias() {
        this.dudaCategoriasService
            .where({ duda_categoria: { duda_categoria_padre_id: null } })
            .then((dudaCategorias: DudaCategoria[]) => {
                this.dudaCategorias = dudaCategorias
                if (this.dudaCategoriaId) {
                    this.dudaCategoria = this.dudaCategorias.find(c => this.dudaCategoriaId == c.id)
                }
            })
    }

    dudaCategoriaChange() {
        this.dudasBuscadorService.setDudaCategoria(this.dudaCategoria)
    }

    byId(d1: Duda, d2: Duda) {
        if (d1 && d2) {
            return d1.id === d2.id
        } else {
            return d1 == d2
        }
    }

    async getGrupoUsuario() {
        let params = {
            propios: 1
        }
        params["grupo_usuario_usuario"] = this.fromProfesor ? { propietario: 1 } : { propietario: 0 }
        this.grupoUsuarios = await this.grupoUsuariosService.where(params)
    }

    grupoUsuarioChange() {
        this.dudasBuscadorService.setGrupoUsuario(this.grupoUsuario)
    }
}
