import { Component, Input, OnInit } from "@angular/core"
import { Material, Pregunta } from "@puntaje/nebulosa/api-services"
import { YoutubeVideoView } from "@puntaje/shared/core"

@Component({
    selector: "solucion",
    templateUrl: "solucion.component.html",
    styleUrls: ["solucion.component.scss"]
})
export class SolucionComponent {
    @Input() pregunta: Pregunta
    @Input() showSolucion: boolean = false
    videoParams: any = { label: "Reproducir", type: YoutubeVideoView, tableVisible: true }
}
