import { Component, OnInit, EventEmitter, ViewChild, Output, Input } from "@angular/core"
import { GenericModalComponent } from "@puntaje/shared/core"

@Component({
    selector: "modal-select-tipo-envio",
    templateUrl: "./modal-select-tipo-envio.component.html",
    styleUrls: ["./modal-select-tipo-envio.component.scss"]
})
export class ModalSelectTipoEnvioComponent implements OnInit {
    openModal: EventEmitter<any> = new EventEmitter<any>()
    type: string
    mail: boolean = false
    showInput: boolean = false
    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent
    @Output() tipo: EventEmitter<any> = new EventEmitter<any>()
    @Input() telefono

    constructor() {}

    ngOnInit() {}

    guardar() {
        if (this.type == "sms") {
            if (this.telefono == "" || this.telefono == undefined) {
                this.showInput = true
            } else {
                this.tipo.emit({
                    tipo: "sms",
                    telefono: this.telefono
                })
            }
        }
        if (this.type == "mail") {
            this.tipo.emit({
                tipo: "mail"
            })
        }
    }

    public open() {
        // como el tema de los mensajes esta deshabilitado, no abrir popup y emitir directamente con mail
        // this.genericModal.buttonPressed()

        this.tipo.emit({
            tipo: "mail"
        })
    }

    public close() {
        this.genericModal.close()
    }
}
