import { Chart } from "./chart.interface"
import { BaseChart } from "./base_chart.model"
import { ChartType } from "./chart_type.enum"

export class ColumnChart extends BaseChart {
    // Gráfico de columnas

    constructor(g: Chart) {
        super(g)
    }

    public setChartData() {
        //tipo
        this.tipo = ChartType.Column
        this.chartData.chart = {
            type: "column"
        }
        //opciones de ploteo
        this.chartData.plotOptions = {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: this.formatter,
                    formatter: this.formatter
                }
            }
        }
        //EjeX
        if (this.ejeX && this.categorias) {
            this.chartData.xAxis = {
                title: {
                    enabled: true,
                    text: this.ejeX
                },
                categories: this.categorias,
                crosshair: true
            }
        } else if (this.ejeX) {
            this.chartData.xAxis = {
                categories: this.ejeX,
                crosshair: true
            }
        }
        //EjeY
        if (this.ejeY) {
            this.chartData.yAxis = {
                min: 0,
                title: {
                    text: this.ejeY
                }
            }
        }
        //datos
        let data_array = []
        for (let d of this.data) {
            if (d.nombre && d.data) {
                let v = { name: d.nombre, data: d.data }
                data_array.push(v)
            }
        }
        this.chartData.series = data_array
    }
}
