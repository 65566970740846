<ll-titulo>{{ "progreso.mi_progreso.titulo" | t }}</ll-titulo>

<cui-section-headline>{{ "progreso.mi_progreso.evolucion.titulo" | t }}</cui-section-headline>
<p class="tutorial">
    {{ "progreso.mi_progreso.evolucion.tutorial" | t }}
</p>
<br />
<grafico-mi-progreso-multiple
    [nombreClasificacionTipo]="nombreClasificacionTipo"
    [evaluacionTipos]="evaluacionTipos"
></grafico-mi-progreso-multiple>
<br />
<ng-container *ngIf="enableDesempenoAsignaturas && tipoInstrumento">
    <cui-section-headline>{{ "progreso.mi_progreso.desempeno_asignaturas.titulo" | t }}</cui-section-headline>
    <p class="tutorial">
        {{
            "progreso.mi_progreso.desempeno_asignaturas.tutorial"
                | t: { clasificacionTipo: config.evaluaciones[tipoInstrumento].clasificacionDistribucion }
        }}
    </p>
    <desempeno-asignaturas [tipoEvaluacion]="tipoInstrumento"></desempeno-asignaturas>
</ng-container>
