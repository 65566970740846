<ul class="alternativas-list" role="radiogroup">
    <li
        *ngFor="let alternativa of contestable.getAlternativas(generadorInstrumento); let i = index"
        [class.alternativa-selected]="alternativaId == alternativa.id"
    >
        <div class="alternativa_container">
            <div
                class="letra"
                id="{{ alternativa.id }}"
                [attr.aria-label]="'opción de respuesta ' + (getLetra(i) | uppercase)"
            >
                {{ getLetra(i) }})
            </div>
            <div class="radio alternativa">
                <input
                    #radio="ngModel"
                    type="radio"
                    [value]="alternativa.id"
                    id="alternativa_{{ alternativa.id }}_{{ instanceNumber }}"
                    name="contestable_{{ contestable.id }}_{{ instanceNumber }}"
                    [attr.aria-labelledby]="'label_alternativa_' + alternativa.id"
                    (click)="checkIfReset(radio, alternativa.id, $event)"
                    [ngModel]="alternativaId"
                    (ngModelChange)="onChangeAlternativa($event)"
                    [attr.aria-checked]="alternativaId == alternativa.id"
                    tabindex="{{ (!alternativaId && i == 0) || alternativaId == alternativa.id ? 0 : -1 }}"
                />
                <label
                    id="label_alternativa_{{ alternativa.id }}"
                    [attr.for]="'alternativa_' + alternativa.id + '_' + instanceNumber"
                    (keyup)="selectedFromKeyboard($event, alternativa.id)"
                >
                    <span class="marker"></span>
                    <span
                        [innerHTML]="
                            alternativa.alternativa
                                | clearLatex
                                | katex
                                | taxativoNumeroTexto: grupoPreguntaTextoService.sentenceToLine[grupoPreguntaId]
                                | trustedhtml
                        "
                    ></span>
                </label>
            </div>
        </div>
    </li>
    <li *ngIf="!disableOmitida">
        <div class="alternativa_container">
            <div class="radio alternativa omitir">
                <input
                    type="radio"
                    [value]="0"
                    id="alternativa_omitida_contestable_{{ contestable.id }}_{{ instanceNumber }}"
                    name="contestable_{{ contestable.id }}_{{ instanceNumber }}"
                    [ngModel]="alternativaId"
                    (ngModelChange)="onChangeAlternativa(0)"
                />
                <label [attr.for]="'alternativa_omitida_contestable_' + contestable.id + '_' + instanceNumber">
                    <span class="marker"></span>
                    <span>Omitida</span>
                </label>
            </div>
        </div>
    </li>
</ul>
