import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"

export class DudaRespuestaForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            duda_respuesta: {
                label: "",
                type: "ckeditor",
                visible: true,
                validations: [Validators.required, Validators.minLength(10)]
            }
        }
    }
}
