import { Component } from "@angular/core"

@Component({
    selector: "ll-top-contenido, l-top-contenido",
    template: `
        <l-slot-content name="top-contenido">
            <ng-content></ng-content>
        </l-slot-content>
    `
})
export class LTopContenidoComponent {}
