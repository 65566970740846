<loading-layout #loadingLayout [opaque]="true">
    <div
        class="pregunta_ensayo print-page-break-avoid"
        id="preg_{{ index + 1 }}"
        *ngIf="pregunta"
        [class.pilotaje]="instrumentoPregunta.piloto"
    >
        <div *ngIf="instrumentoPregunta.piloto" class="titulo-pilotaje print-hide">
            <span>Pregunta de Pilotaje</span>
            <a class="tutorial-info-link" [toggleExpandable]="pilotajeInfo">¿Qué es esto?</a>
            <div #pilotajeInfo expandableContent>
                <div class="pilotaje-info">
                    Las preguntas piloto no se consideran al momento de calificar la evaluación.
                </div>
            </div>
        </div>

        <grupo-pregunta
            *ngIf="
                pregunta &&
                pregunta.grupo_pregunta &&
                pregunta.grupo_pregunta.id &&
                (!grupoPreguntaPrev || pregunta.grupo_pregunta.id != grupoPreguntaPrev.id)
            "
            [grupoPregunta]="pregunta.grupo_pregunta"
            [preguntaIndex]="index"
            [enableGrupoPreguntaId]="true"
        ></grupo-pregunta>

        <div class="pregunta_ensayo_contenido" [class.with-cambiar-pregunta]="showCambiarPregunta">
            <div class="opciones-container print-hide">
                <cui-button
                    *ngIf="showCambiarPregunta && enableOpcionesPregunta"
                    class="opcion btn_style btn-piloto"
                    (click)="togglePilotaje()"
                >
                    <ng-container *ngIf="instrumentoPregunta.piloto">
                        <fa name="check-square-o"></fa>
                        &nbsp;Deshabilitar Pilotaje
                    </ng-container>
                    <ng-container *ngIf="!instrumentoPregunta.piloto">
                        <fa name="square-o"></fa>
                        &nbsp;Habilitar Pilotaje
                    </ng-container>
                    <loader *ngIf="togglingPilotaje" class="loaderBtn" loaderType="default-xsmall"></loader>
                </cui-button>
                <reportes-modal
                    *ngIf="enableReportBtn"
                    [tabla]="'Pregunta'"
                    [entidad_id]="pregunta.id"
                    class="opcion print-hide"
                ></reportes-modal>
                <cui-button
                    [id]="'changeButton'"
                    *ngIf="showCambiarPregunta && enableOpcionesPregunta"
                    class="opcion btn_style"
                    (click)="cambiarPregunta()"
                >
                    <fa name="exchange"></fa>
                    &nbsp;Cambiar Pregunta
                </cui-button>
                <cui-button
                    *ngIf="historialPreguntas.length > 0 && enableOpcionesPregunta"
                    class="opcion btn_style"
                    (click)="deshacerCambioPregunta()"
                >
                    <fa name="undo"></fa>
                    &nbsp;Deshacer cambio Pregunta
                </cui-button>
                <cui-button
                    *ngIf="showCambiarPregunta && enableOpcionesPregunta"
                    class="opcion btn_style"
                    (click)="deletePregunta()"
                >
                    <fa name="minus"></fa>
                    Sacar Pregunta
                </cui-button>
                <cui-button
                    *ngIf="showCambiarPregunta && enableOpcionesPregunta"
                    class="opcion btn_style"
                    (click)="openModalAgregarPregunta(agregarPreguntaModal)"
                >
                    <fa name="plus"></fa>
                    &nbsp;Agregar Pregunta
                </cui-button>

                <generic-modal #agregarPreguntaModal>
                    <modal-titulo>Agregar Pregunta/Texto</modal-titulo>
                    <modal-contenido>
                        <ng-container *ngIf="agregarPreguntaModalOpen">
                            <div class="radio" *ngIf="pregunta.grupo_pregunta">
                                <label>
                                    <input
                                        type="radio"
                                        name="preguntaOTexto"
                                        [(ngModel)]="preguntaOTexto"
                                        value="pregunta"
                                        checked
                                    />
                                    Agregar nueva pregunta
                                </label>
                            </div>
                            <div class="radio">
                                <label>
                                    <input
                                        type="radio"
                                        name="preguntaOTexto"
                                        [(ngModel)]="preguntaOTexto"
                                        value="grupoPregunta"
                                    />
                                    Agregar nuevo texto
                                </label>
                            </div>
                            <ng-container *ngIf="preguntaOTexto == 'pregunta' && pregunta.grupo_pregunta">
                                <br />
                                <div class="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="preguntaFromGrupo"
                                            [(ngModel)]="preguntaFromGrupo"
                                        />
                                        Agregar pregunta al texto
                                    </label>
                                </div>
                            </ng-container>
                            <br />
                            <div class="radio">
                                <label>
                                    <input type="radio" name="posicion" [(ngModel)]="posicion" value="arriba" checked />
                                    Arriba de la pregunta selecionada
                                </label>
                            </div>
                            <div class="radio">
                                <label>
                                    <input type="radio" name="posicion" [(ngModel)]="posicion" value="abajo" />
                                    Abajo de la pregunta seleccionada
                                </label>
                            </div>
                            <configurar-clasificaciones
                                *ngIf="instrumento"
                                [enableCantidadPreguntas]="true"
                                [tipoInstrumento]="evaluacionTipo"
                                (onReady)="onReady()"
                                [generadorInstrumentoId]="instrumento.generador_instrumento_id"
                                [asignaturaId]="instrumento.asignatura_id"
                                [(clasificacionesIds)]="clasificacionIds"
                                [customId]="'instrumento_pregunta_' + instrumentoPregunta.id"
                            ></configurar-clasificaciones>
                            <button
                                class="btn btn-primary"
                                (click)="agregarPregunta(index); agregarPreguntaModal.close()"
                            >
                                Agregar
                            </button>
                        </ng-container>
                    </modal-contenido>
                    <modal-botones>
                        <cui-button buttonType="cancel" type="button" class="btn_style_2" data-dismiss="modal">
                            Cerrar
                        </cui-button>
                    </modal-botones>
                </generic-modal>
            </div>
            <div class="numero_pregunta numero_pregunta_style" [class.with-cambiar-pregunta]="showCambiarPregunta">
                {{ index + 1 }}
            </div>
            <div class="enunciado-alternativas">
                <span class="id_pregunta print-hide">#{{ pregunta.id }}</span>
                <pregunta-taxativo [taxativo]="pregunta.taxativos[0].taxativo"></pregunta-taxativo>

                <div class="alternativas">
                    <ul class="alternativas-list">
                        <li
                            *ngFor="
                                let alternativa of pregunta.contestables[0].getAlternativas(
                                    instrumento?.generador_instrumento
                                );
                                let i = index
                            "
                            [class.alternativa-correcta]="alternativa.correcta"
                        >
                            <div class="alternativa_container">
                                <div class="letra">{{ getLetra(i) }})</div>
                                <div
                                    class="alternativa"
                                    [innerHTML]="alternativa.alternativa | clearLatex | katex | trustedhtml"
                                ></div>
                            </div>
                            <div *ngIf="alternativa.correcta" class="alternativa-correcta-icon print-hide">
                                <ogr-icon name="ticket-circle"></ogr-icon>
                            </div>
                        </li>
                    </ul>
                    <div class="autor print-hide">Autor: {{ profesor }}</div>
                    <div class="autor print-hide">Org.: {{ autor }}</div>
                    <div *ngIf="certificada" class="certificada" id="pregunta-certificada">
                        Pregunta certificada
                        <fa class="certificada logo-certificada" name="check-circle-o"></fa>
                    </div>
                </div>
            </div>
        </div>

        <tabla-clasificaciones-pregunta
            [evaluacionTipo]="evaluacionTipo"
            [pregunta]="pregunta"
            class="print-hide"
        ></tabla-clasificaciones-pregunta>
        <tabla-estadisticas-pregunta
            [dificultad]="dificultad"
            [estadistica]="estadisticaGlobal"
        ></tabla-estadisticas-pregunta>
        <p class="print-show-block hidden-info">
            <b>Autor:</b>
            &nbsp;{{ autor }}
        </p>
        <p class="print-show-block hidden-info">
            <b>Pregunta ID:</b>
            &nbsp;{{ pregunta.id }}
        </p>
        <solucion [pregunta]="pregunta" class="print-hide"></solucion>
        <!-- <div class="solucion-container">
            <cui-button
                class="btn_style pull-right btn-sol print-hide"
                type="button"
                toggleExpandable="preg_sol_{{ index + 1 }}"
            >
                <ogr-icon name="resultados"></ogr-icon>
                <span class="ver">Ver</span>
                <span class="ocultar">Ocultar</span>
                &nbsp;Solución
            </cui-button>
            <div id="preg_sol_{{ index + 1 }}" class="print-force-expand" expandableContent>
                <h4 class="subtitulo titulo-solucion">
                    <div class="icon-solucion print-hide">
                        <ogr-icon name="resultados"></ogr-icon>
                    </div>
                    Solución
                </h4>
                <div class="solucion" *ngIf="pregunta.solucion">
                    <div
                        class="solucion_contenido"
                        [class.solucion-show]="showSolution"
                        [innerHTML]="pregunta.solucion | clearLatex | katex | trustedhtml"
                    ></div>
                </div>
                <div class="sin-solucion print-hide" *ngIf="!pregunta.solucion">
                    <p>Solución aún no disponible</p>
                </div>
            </div>
        </div> -->
    </div>
</loading-layout>
