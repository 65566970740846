import { BaseModel, SaveType } from "@puntaje/shared/core"

export class EstablecimientoChile extends BaseModel {
    public rbd: number
    public establecimiento_id: number

    getFieldName() {
        return "RBD"
    }

    getFieldValue() {
        return this.rbd
    }
}
