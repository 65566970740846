import { Injectable, Injector } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { mergeMap, map, tap, concatAll, withLatestFrom, concatMap, catchError, switchMap } from "rxjs/operators"
import { from, combineLatest, forkJoin, of } from "rxjs"
import { Observable } from "rxjs"
import { Action, Store, select } from "@ngrx/store"
import * as fromHojaRespuestas from "../reducers"
// import { Asignaturas, Asignatura } from "@puntaje/nebulosa/api-services"
import {
    HojaRespuestasActionTypes,
    LoadImages,
    ScanImage,
    ProcessNotImage,
    ProcessImage,
    UpdateCanvas,
    SubirLecturas,
    ProcessClickCanvas,
    SetValuesHojaRespuesta,
    ProcessedAll,
    InitSubirLecturas,
    SubirLectura,
    FinishSubirLecturas,
    UpdateProcessed,
    LecturaProcesada,
    ChangeRespuesta,
    SetValuesObjHojaRespuesta,
    SetRespuestas,
    SetObjRespuestas,
    ChangeIdValuePart
} from "../actions/hoja_respuestas.actions"
import { State } from "../reducers/hoja_respuestas.reducer"
import { selectHojaRespuestas, selectCanvases } from "../reducers"
import { AppConfig, ScannerService2 } from "@puntaje/shared/core"
import { Scannerjs } from "@puntaje/shared/scanner"
import { EvaluacionInstancias } from "@puntaje/puntaje/api-services"

declare let loadImage: any

function isImage(file: any) {
    return (file.type as any).startsWith("image/")
}

@Injectable()
export class HojaRespuestasEffects {
    loadImages$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadImages>(HojaRespuestasActionTypes.LoadImages),
            concatMap(action =>
                of(action).pipe(withLatestFrom(this.store.pipe(select(fromHojaRespuestas.selectArchivosProcesados))))
            ),
            mergeMap(([action, archivosProcesados]) => {
                return new Observable<Action>(subscriber => {
                    archivosProcesados.original.map((file, index) => {
                        loadImage(
                            file,
                            loadedFile => {
                                if (isImage(file)) {
                                    loadedFile.name = file.name
                                    subscriber.next(new ScanImage({ image: loadedFile, index }))
                                } else {
                                    subscriber.next(new ProcessNotImage({ file, index }))
                                }
                                subscriber.next(new UpdateProcessed())
                            },
                            {
                                maxWidth: 1200,
                                orientation: 1,
                                canvas: true,
                                imageSmoothingEnabled: true,
                                imageSmoothingQuality: "high"
                            }
                        )
                    })
                })
            })
        )
    )

    scanImage$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType<ScanImage>(HojaRespuestasActionTypes.scanImage),
            concatMap(action =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.pipe(select(fromHojaRespuestas.selectCanvases)),
                        this.store.pipe(select(fromHojaRespuestas.selectEnableThreshold))
                    )
                )
            ),
            mergeMap(([action, canvases, enableThreshold]) => {
                const { image, index } = action.payload
                const canvas = canvases[index]
                let hasError = false
                let archivoProcesado = null

                // update canvas
                canvas.nativeElement.width = image.width
                canvas.nativeElement.height = image.height
                canvas.nativeElement
                    .getContext("2d")
                    .drawImage(image, 0, 0, canvas.nativeElement.width, canvas.nativeElement.height)

                if (this.config.hojaRespuesta.configuration) {
                    try {
                        const rawSectoresValues = new Array(this.config.hojaRespuesta.configuration.sectores.length)

                        const scanner = this.scannerService.initialize(
                            canvas.nativeElement,
                            canvas.nativeElement.getContext("2d")
                        )

                        this.scannerService.detectMarkers(scanner)
                        this.scannerService.align(
                            scanner,
                            this.config.hojaRespuesta.configuration.align[0],
                            this.config.hojaRespuesta.configuration.align[1]
                        )

                        this.scannerService.detectMarkers(scanner)

                        let { respuestas, idValue, forma, formaType } = this.scannerService.resolveData(
                            scanner,
                            rawSectoresValues
                        )

                        respuestas = respuestas.reduce((acc, r, i) => {
                            acc[i] = r

                            return acc
                        }, {})

                        archivoProcesado = {
                            file: image,
                            pos: index,
                            respuestas: respuestas,
                            forma: forma,
                            formaType: formaType,
                            idValue: idValue,
                            editing: false,
                            messages: null,
                            instancia_id: null,
                            datosBoton: null,
                            loadingMessages: false,
                            scanner: scanner,
                            rawSectoresValues: rawSectoresValues
                        }
                    } catch (error) {
                        hasError = true
                    }
                } else {
                    const respuestasObj = []
                    let respuestas = {}
                    let formaObj: any = null
                    let forma = ""
                    let rutObj: any = null
                    let idValue: string[] = []
                    let formaType = ""

                    let markers
                    let sectoresRespuestas
                    let dimSectores

                    const ctx = canvas.nativeElement.getContext("2d")

                    try {
                        const scanner = new Scannerjs({
                            image: image,
                            context: ctx,
                            enableThreshold: enableThreshold,
                            canvasWidth: canvas.nativeElement.width,
                            canvasHeight: canvas.nativeElement.height
                        })

                        // detect markers
                        markers = scanner.detectMarkers()

                        // default align markers
                        let alignMarker1 = 1
                        let alignMarker2 = 5
                        let tempMarkers = this.cleanMarkers(markers)

                        // set align markers by config
                        if (
                            this.config.hojaRespuesta.alignByMarkers &&
                            this.config.hojaRespuesta.alignByMarkers[tempMarkers.length]
                        ) {
                            const alignByMarkers = this.config.hojaRespuesta.alignByMarkers[tempMarkers.length]
                            alignMarker1 = alignByMarkers[0]
                            alignMarker2 = alignByMarkers[1]
                        } else if (tempMarkers.length > 10) {
                            // no sé qué parcha esto, but it's evil
                            alignMarker2 = 4
                        }
                        // align using markers
                        //console.log("alineado con: ", alignMarker1, "  ", alignMarker2)
                        scanner.align(alignMarker1, alignMarker2)

                        // redetect markers
                        markers = scanner.detectMarkers()

                        tempMarkers = this.cleanMarkers(markers)

                        // set sectores respuestas
                        if (
                            this.config.hojaRespuesta.sectoresByMarkers &&
                            this.config.hojaRespuesta.sectoresByMarkers[tempMarkers.length]
                        ) {
                            sectoresRespuestas = this.config.hojaRespuesta.sectoresByMarkers[tempMarkers.length]
                        } else {
                            sectoresRespuestas = this.config.hojaRespuesta.sectores
                        }

                        // set dimensiones sectores
                        if (
                            this.config.hojaRespuesta.dimSectoresByMarkers &&
                            this.config.hojaRespuesta.dimSectoresByMarkers[tempMarkers.length]
                        ) {
                            dimSectores = this.config.hojaRespuesta.dimSectoresByMarkers[tempMarkers.length]
                        } else {
                            dimSectores = this.defaultDimSectores()
                        }

                        // get respuestas by sector
                        sectoresRespuestas.forEach(s => {
                            const key = s[0] + "," + s[1]
                            const cols = dimSectores[key].cols
                            const rows = dimSectores[key].rows
                            respuestasObj.push(scanner.resolveIds(s[0], s[1], cols, rows))
                        })

                        let rutMarkerUp = 6
                        let rutMarkerBottom = 10
                        let rutCols = 10
                        let rutRows = 10
                        let formaMarkerUp = 9
                        let formaMarkerBottom = 6
                        let formaCols = 10
                        let formaRows = 10
                        if (
                            this.config.hojaRespuesta.rutFormaByMarkers &&
                            this.config.hojaRespuesta.rutFormaByMarkers[tempMarkers.length]
                        ) {
                            const configRutForma = this.config.hojaRespuesta.rutFormaByMarkers[tempMarkers.length]
                            rutMarkerUp = configRutForma.rutMarkerUp
                            rutMarkerBottom = configRutForma.rutMarkerBottom
                            rutCols = configRutForma.rutCols
                            rutRows = configRutForma.rutRows ? configRutForma.rutRows : 10
                            formaMarkerUp = configRutForma.formaMarkerUp
                            formaMarkerBottom = configRutForma.formaMarkerBottom
                            formaCols = configRutForma.formaCols
                            formaRows = configRutForma.formaRows ? configRutForma.formaRows : 10
                        }

                        let sectores = []
                        sectores = sectores.concat(sectoresRespuestas)
                        sectores = sectores.concat([
                            [rutMarkerUp, rutMarkerBottom],
                            [formaMarkerUp, formaMarkerBottom]
                        ])
                        rutObj = scanner.resolveIds(rutMarkerUp, rutMarkerBottom, rutCols, rutRows, true)
                        formaObj = scanner.resolveIds(formaMarkerUp, formaMarkerBottom, formaCols, formaRows, true)
                        ;[respuestas, idValue, forma, formaType] = this.setValues(respuestasObj, rutObj, formaObj)

                        archivoProcesado = {
                            file: image,
                            pos: index,
                            respuestas: respuestas,
                            forma: forma,
                            formaType: formaType,
                            idValue: idValue,
                            scanner: scanner,
                            rutObj: rutObj,
                            formaObj: formaObj,
                            respuestasObj: respuestasObj,
                            editing: false,
                            messages: null,
                            instancia_id: null,
                            datosBoton: null,
                            loadingMessages: false,
                            dimSectores: dimSectores,
                            sectores: sectores
                        }
                    } catch (error) {
                        console.log(error)
                        hasError = true
                    }
                }

                return [new ProcessImage({ index, archivoProcesado, error: hasError })]
            })
        )
    )

    processClickCanvas$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType<ProcessClickCanvas>(HojaRespuestasActionTypes.processClickCanvas),
            concatMap(action =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.pipe(select(fromHojaRespuestas.selectArchivosProcesados)),
                        this.store.pipe(select(fromHojaRespuestas.selectCanvases))
                    )
                )
            ),
            mergeMap(([action, archivosProcesados, canvases]) => {
                let respuestas, idValue, forma, formaType
                const { event, index } = action.payload

                const obj = archivosProcesados.good[index]
                const scanner = obj.scanner

                if (this.config.hojaRespuesta.configuration) {
                    const rawSectoresValues = obj.rawSectoresValues

                    this.scannerService.changeMarked(scanner, canvases[index].nativeElement, rawSectoresValues, event)

                    const response = this.scannerService.getProcessedData(rawSectoresValues)
                    respuestas = response.respuestas
                    idValue = response.idValue
                    forma = response.forma
                    formaType = response.formaType

                    respuestas = respuestas.reduce((acc, r, i) => {
                        acc[i] = r

                        return acc
                    }, {})

                    return [new SetValuesHojaRespuesta({ index, respuestas, idValue, forma, formaType })]
                } else {
                    const nCanvas = canvases[index].nativeElement
                    const dimSectores = obj.dimSectores
                    const sectores = obj.sectores

                    const ratioX = nCanvas.width / nCanvas.offsetWidth
                    const ratioY = nCanvas.height / nCanvas.offsetHeight

                    const respuestasObj = this.nestedCopy(obj.respuestasObj)
                    const rutObj = this.nestedCopy(obj.rutObj)
                    const formaObj = this.nestedCopy(obj.formaObj)

                    scanner.changeMarked(
                        ~~(event.offsetX * ratioX),
                        ~~(event.offsetY * ratioY),
                        respuestasObj.concat([rutObj], [formaObj]),
                        dimSectores,
                        sectores
                    )
                    // Actualizar los valores de respuestas, idValue, forma y formaType solo si fueron modificados en este click
                    ;[respuestas, idValue, forma, formaType] = this.setValues(respuestasObj, rutObj, formaObj)
                    if (JSON.stringify(obj.respuestasObj) === JSON.stringify(respuestasObj)) {
                        respuestas = obj.respuestas
                    }
                    if (JSON.stringify(obj.rutObj) === JSON.stringify(rutObj)) {
                        idValue = obj.idValue
                    }
                    if (JSON.stringify(obj.formaObj) === JSON.stringify(formaObj)) {
                        forma = obj.forma
                        formaType = obj.formaType
                    }

                    return [
                        new SetValuesObjHojaRespuesta({ index, respuestasObj, rutObj, formaObj }),
                        new SetValuesHojaRespuesta({ index, respuestas, idValue, forma, formaType })
                    ]
                }
            })
        )
    )

    subirLecturas$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType<SubirLecturas>(HojaRespuestasActionTypes.SubirLecturas),
            concatMap(action =>
                of(action).pipe(withLatestFrom(this.store.pipe(select(fromHojaRespuestas.selectArchivosProcesados))))
            ),
            mergeMap(([action, archivosProcesados]) => {
                return new Observable<Action>(subscriber => {
                    subscriber.next(new InitSubirLecturas())

                    Object.keys(archivosProcesados.good).map(index => {
                        // obj.loadingMessages = true
                        subscriber.next(new SubirLectura({ index: +index, perfil: action.payload.perfil }))
                    })

                    // subscriber.next(new FinishSubirLecturas())
                })
            })
        )
    )

    subirLectura$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType<SubirLectura>(HojaRespuestasActionTypes.SubirLectura),
            concatMap(action =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.pipe(select(fromHojaRespuestas.selectArchivosProcesados)),
                        this.store.pipe(select(fromHojaRespuestas.selectFormaGlobal))
                    )
                )
            ),
            mergeMap(([action, archivosProcesados, formaGlobal]) => {
                const { index, perfil } = action.payload
                const obj = archivosProcesados.good[index]
                const respArr = Object.keys(obj.respuestas).map((k, i) => obj.respuestas[k])
                let data
                let forma = obj.forma
                if (formaGlobal && formaGlobal > 0) {
                    forma = formaGlobal
                }
                if (obj.formaType != "") {
                    data = {
                        row: [...obj.idValue, forma, obj.formaType].concat(respArr),
                        pais: this.config.plataforma.pais,
                        perfil: perfil,
                        formaTipo: obj.formaType
                    }
                } else {
                    data = {
                        row: [...obj.idValue, forma].concat(respArr),
                        pais: this.config.plataforma.pais,
                        perfil: perfil,
                        formaTipo: obj.formaType
                    }
                }

                if (this.config.hojaRespuesta.formatEvalIdStr) {
                    data["format_id_str"] = this.config.hojaRespuesta.formatEvalIdStr
                }

                if (this.config.hojaRespuesta.usingUserID) {
                    data["using_user_id"] = this.config.hojaRespuesta.usingUserID
                }

                const result: any = {}

                if (this.config.plataforma.afterUploadResultGoToViewPath) {
                    result.goToViewPath = this.config.plataforma.afterUploadResultGoToViewPath.replace(
                        ":evaluacionId",
                        forma
                    )
                }

                if (obj.idValue.some(v => !v)) {
                    result.loadingMessages = false
                    result.messages = {
                        warnings: ["El identificador del estudiante es inválido o está vacío."]
                    }

                    return [new LecturaProcesada({ obj: result, index })]
                }

                this.evaluacionesInstanciasService.enableIgnoreModel()

                return from(this.evaluacionesInstanciasService.subirLectura(data, 60000)).pipe(
                    switchMap(resp => {
                        result.messages = resp
                        result.loadingMessages = false
                        if (resp && resp["instancia_id"]) {
                            result.instancia_id = resp["instancia_id"]
                        }

                        if (resp && resp["estadisticas"]) {
                            result.datosBoton = resp["estadisticas"][0]
                        }

                        return [new LecturaProcesada({ obj: result, index })]
                    }),
                    catchError(error => {
                        console.log(error)

                        result.loadingMessages = false
                        result.messages = {
                            warnings: ["Hubo un error en el servidor. Por favor intente nuevamente en un momento."]
                        }

                        return [new LecturaProcesada({ obj: result, index })]
                    })
                )
            })
        )
    )

    changeRespuesta$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType<ChangeRespuesta>(HojaRespuestasActionTypes.ChangeRespuesta),
            concatMap(action =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.pipe(select(fromHojaRespuestas.selectArchivosProcesados)),
                        this.store.pipe(select(fromHojaRespuestas.selectCanvases)),
                        this.store.pipe(select(fromHojaRespuestas.selectSectores))
                    )
                )
            ),
            mergeMap(([action, archivosProcesados, canvases]) => {
                let respuestas, idValue, forma, formaType
                const { indexFile, indexRespuesta, respuesta } = action.payload

                const obj = archivosProcesados.good[indexFile]
                const scanner = obj.scanner

                if (this.config.hojaRespuesta.configuration) {
                    const rawSectoresValues = obj.rawSectoresValues

                    this.scannerService.changeRespuestasMarkedByIndices(
                        scanner,
                        rawSectoresValues,
                        "respuestas",
                        +indexRespuesta,
                        respuesta
                    )
                    const response = this.scannerService.getProcessedData(rawSectoresValues)
                    respuestas = response.respuestas
                    idValue = response.idValue
                    forma = response.forma
                    formaType = response.formaType

                    respuestas = respuestas.reduce((acc, r, i) => {
                        acc[i] = r

                        return acc
                    }, {})

                    return [new SetValuesHojaRespuesta({ index: indexFile, respuestas, idValue, forma, formaType })]
                } else {
                    const dimSectores = obj.dimSectores
                    const sectores = obj.sectores

                    const nCanvas = canvases[indexFile].nativeElement
                    const ratioX = nCanvas.width / nCanvas.offsetWidth
                    const ratioY = nCanvas.height / nCanvas.offsetHeight
                    const respuestasObj = this.nestedCopy(obj.respuestasObj)
                    const rutObj = this.nestedCopy(obj.rutObj)
                    const formaObj = this.nestedCopy(obj.formaObj)

                    let rows = 0
                    let indexSector = 0
                    let valueKeySector
                    for (const sector of this.config.hojaRespuesta.sectores) {
                        const key = sector.join()

                        rows += dimSectores[key].rows
                        if (indexRespuesta < rows) {
                            valueKeySector = key
                            break
                        }

                        indexSector += 1
                    }

                    const letras = "ABCDEFGH"
                    const i = indexRespuesta - (rows - dimSectores[valueKeySector].rows)
                    let j

                    const respSectores = respuestasObj.concat([rutObj], [formaObj])

                    // se borran las antiguas respuestas
                    const respuestaOldRow = respuestasObj[indexSector].find(r => r.row == i)
                    if (respuestaOldRow) {
                        const respuestaOld = this.nestedCopy(respuestaOldRow.ans)

                        respuestaOld.forEach(indexLetra => {
                            j = indexLetra
                            scanner.changeMarkedByIndices(i, j, respSectores, dimSectores, sectores, indexSector)
                        })
                    }

                    // se rellenan las nuevas respuestas
                    const respuestaNew = respuesta.toUpperCase()
                    respuestaNew.split(",").forEach(letra => {
                        j = letras.indexOf(letra)
                        if (letra != "" && j > -1) {
                            scanner.changeMarkedByIndices(
                                i,
                                j,
                                respuestasObj.concat([rutObj], [formaObj]),
                                dimSectores,
                                sectores,
                                indexSector
                            )
                        }
                    })
                    ;[respuestas, idValue, forma, formaType] = this.setValues(respuestasObj, rutObj, formaObj)

                    return [
                        new SetObjRespuestas({ index: indexFile, respuestasObj }),
                        new SetRespuestas({ index: indexFile, respuestas })
                    ]
                }
            })
        )
    )

    evaluacionesInstanciasService: EvaluacionInstancias

    constructor(
        private scannerService: ScannerService2,
        private injector: Injector,
        private actions$: Actions,
        private store: Store<fromHojaRespuestas.State>,
        private config: AppConfig
    ) {
        setTimeout(() => (this.evaluacionesInstanciasService = this.injector.get(EvaluacionInstancias)))
    }

    defaultDimSectores() {
        let alternativas = 5
        if (this.config.hojaRespuesta.alternativas) {
            alternativas = this.config.hojaRespuesta.alternativas
        }

        return {
            "1,2": { cols: alternativas, rows: 10 },
            "2,3": { cols: alternativas, rows: 10 },
            "3,4": { cols: alternativas, rows: 10 },
            "5,2": { cols: alternativas, rows: 10 },
            "4,5": { cols: alternativas, rows: 10 },
            "2,7": { cols: alternativas, rows: 10 },
            "7,4": { cols: alternativas, rows: 10 },
            "5,6": { cols: alternativas, rows: 10 },
            "6,7": { cols: alternativas, rows: 10 },
            "8,9": { cols: alternativas, rows: 10 },
            "7,8": { cols: alternativas, rows: 10 },
            "9,6": { cols: 10, rows: 10 },
            "6,10": { cols: 10, rows: 10 }
        }
    }

    setValues(respuestasObj, rutObj, formaObj) {
        const idValue = []
        let respuestas = null
        let forma = null
        let formaType = ""

        const letras = "ABCDEFGH"
        let numeros = "0123456789"
        let rutChars = "0123456789K"
        const rutLength = rutObj.length

        // BORRAME POR FAVOR!!11!!!!11
        // if (this.config.plataforma.name == "ANUIES") {
        //     numeros = "0123456789"
        //     rutChars = "0123456789K"
        // }
        // BORRAME POR FAVOR!!11!!!!11

        let rowIni = 0
        respuestas = respuestasObj.reduce((acc, r, i) => {
            r.forEach(o => {
                acc[rowIni + o.row] = o.ans.map(a => letras.charAt(a)).join(",")
            })
            rowIni += r.length
            return acc
        }, {})

        if (this.config.plataforma.pais == "chile") {
            idValue.push(
                rutObj
                    .filter((o, i) => i < rutLength - 1)
                    .map((o, i) => {
                        return o.ans.map(a => rutChars.charAt(a)).join(",")
                    })
                    .join("")
            )
            idValue.push(rutObj[rutLength - 1].ans.map(a => rutChars.charAt(a)).join(","))
        } else {
            idValue.push(rutObj.map((o, i) => o.ans.map(a => rutChars.charAt(a)).join(",")).join(""))
        }

        const lastIndex = formaObj.length - 1
        const hasSomethingBeforeSpace = formaObj.slice(0, lastIndex - 1).some(obj => obj.ans.length > 0)

        if (formaObj[lastIndex - 1].ans.length == 0 && formaObj[lastIndex].ans.length > 0 && hasSomethingBeforeSpace) {
            forma = formaObj
                .map((o, i) => {
                    const valores = []
                    if (i != lastIndex - 1 && i != lastIndex) {
                        valores.push(o.ans.map(a => numeros.charAt(a)).join(","))
                    }
                    return valores
                })
                .join("")

            if (formaObj[lastIndex].ans[0] == 0) {
                formaType = "A"
            } else if (formaObj[lastIndex].ans[0] == 1) {
                formaType = "B"
            }
        } else {
            forma = formaObj.map(o => o.ans.map(a => numeros.charAt(a)).join(",")).join("")
        }

        return [respuestas, idValue, forma, formaType]
    }

    nestedCopy(array) {
        return JSON.parse(JSON.stringify(array))
    }

    cleanMarkers(markers) {
        // elimina los repetidos
        let tempMarkers = markers.filter(
            (marker, index, self) => self.findIndex((v, i) => v.id === marker.id) === index
        )
        // elimina los inválidos si existe la configuración
        if (this.config.hojaRespuesta?.validMarkers && this.config.hojaRespuesta.validMarkers.length > 0) {
            tempMarkers = tempMarkers.filter(
                (marker, index) => this.config.hojaRespuesta.validMarkers.indexOf(marker.id) != -1
            )
        }
        return tempMarkers
    }
}
