import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Contestable } from "./contestables.model"

export class Alternativa extends BaseModel {
    public static className: string = "Alternativa"
    public contestable_id: number
    public letra: string
    public alternativa: string
    public correcta: boolean
    public imagen: string
    public explicacion_distractor: string
    public alternativa_latex: string
    public created_at: Date
    public updated_at: Date
    public pista: string
    public ocultable?: boolean
    public orden_ocultar?: number
    public orden: number

    piloto?: boolean

    @SaveType(() => Contestable) contestable: Contestable

    public static get model_params(): any {
        return {
            letra: { label: "Letra", type: "text", visible: true },
            alternativa: { label: "Alternativa", type: "ckeditor", visible: true },
            correcta: { label: "Correcta", type: "checkbox", visible: true },
            ocultable: { label: "Ocultable", type: "checkbox", visible: true },
            imagen: { label: "Imagen", type: "text", visible: true },
            explicacion_distractor: { label: "Explicación Distractor", type: "textarea", visible: true },
            alternativa_latex: { label: "Alternativa Latex", type: "textarea", visible: true }
        }
    }

    public static getLetra(pos: number) {
        return "abcdefghijklmnopqrstuvwxyz".charAt(pos)
    }

    public getExtension() {
        return this.alternativa.split(".").pop()
    }
}
