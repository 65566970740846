<div *ngIf="typePlatform == 'profesor'">
    <ng-container *ngIf="tabClases$ | async as clases">
        <div class="tab-content">
            <div id="imagen-multi" class="tab-pane fade" [ngClass]="clases['imagen-multi']">
                <subir-respuestas-imagen-multi></subir-respuestas-imagen-multi>
            </div>
            <div id="csv" class="tab-pane fade" [ngClass]="clases['csv']">
                <subir-respuestas-csv></subir-respuestas-csv>
                <descargar-csv-prellenado></descargar-csv-prellenado>
            </div>
            <div id="camara" class="tab-pane fade" [ngClass]="clases['camara']">
                <corregir-ensayo-camara
                    [perfil]="typePlatform"
                    [active]="clases['camara'].active"
                ></corregir-ensayo-camara>
            </div>
        </div>
    </ng-container>
</div>

<div *ngIf="typePlatform == 'alumnoOne'">
    <ng-container *ngIf="tabClases$ | async as clases">
        <div class="tab-content">
            <div id="imagen" class="tab-pane fade" [ngClass]="clases['imagen']">
                <subir-respuestas-imagen-alumno [evaluacionId]="evaluacionId"></subir-respuestas-imagen-alumno>
            </div>
            <div id="camara" class="tab-pane fade" [ngClass]="clases['camara']">
                <corregir-ensayo-camara [perfil]="'alumno'" [active]="clases['camara'].active"></corregir-ensayo-camara>
            </div>
        </div>
    </ng-container>
</div>

<!--Desde el QR-->
<div *ngIf="typePlatform == 'alumnoTwo'">
    <ng-container *ngIf="tabClases$ | async as clases">
        <div class="tab-content">
            <div id="camara" class="tab-pane fade" [ngClass]="clases['camara']">
                <corregir-ensayo-camara [perfil]="'alumno'" [active]="clases['camara'].active"></corregir-ensayo-camara>
            </div>
            <div id="imagen" class="tab-pane fade" [ngClass]="clases['imagen']">
                <subir-respuestas-imagen [perfil]="perfil" [link_message]="'Ver resultados.'"></subir-respuestas-imagen>
            </div>
        </div>
    </ng-container>
</div>

<div *ngIf="typePlatform == 'alumnoThree'">
    <ng-container *ngIf="tabClases$ | async as clases">
        <div class="tab-content">
            <div id="camara" class="tab-pane fade" [ngClass]="clases['camara']">
                <corregir-ensayo-camara [perfil]="'alumno'" [active]="clases['camara'].active"></corregir-ensayo-camara>
            </div>
            <div id="imagen-multi" class="tab-pane fade" [ngClass]="clases['imagen-multi']">
                <subir-respuestas-imagen-multi perfil="alumno"></subir-respuestas-imagen-multi>
            </div>
            <div id="csv" class="tab-pane fade" [ngClass]="clases['csv']">
                <subir-respuestas-csv></subir-respuestas-csv>
                <descargar-csv-prellenado></descargar-csv-prellenado>
            </div>
        </div>
    </ng-container>
</div>

<!--Desde el QR 2-->
<div *ngIf="typePlatform == 'alumnoFour'">
    <ng-container *ngIf="tabClases$ | async as clases">
        <div class="tab-content">
            <div id="imagen" class="tab-pane fade" [ngClass]="clases['imagen']">
                <subir-respuestas-imagen [perfil]="perfil" [link_message]="'Ver resultados.'"></subir-respuestas-imagen>
            </div>
        </div>
    </ng-container>
</div>
