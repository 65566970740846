<div class="content-container">
    <ng-content select="cui-layout-header"></ng-content>
    <div class="container-fluid responsive-no-padding" [ngClass]="{ 'with-max-width': singleColumn }">
        <div [ngClass]="{ row: !singleColumn, 'custom-row': customRow }">
            <div class="{{ firstColumnWidth }}" *ngIf="!singleColumn">
                <l-slot name="contenido-lateral"></l-slot>
            </div>
            <div class="{{ secondColumnWidth }} max-width">
                <div class="bottom-padding">
                    <div class="clearfix" [class.content-header-with-topside]="showTopside">
                        <cui-titlebar [noSocialLinks]="true">
                            <cui-titlebar-titulo>
                                <l-slot name="titulo"></l-slot>
                            </cui-titlebar-titulo>
                            <cui-titlebar-subtitulo>
                                <l-slot name="subtitulo"></l-slot>
                            </cui-titlebar-subtitulo>
                        </cui-titlebar>
                        <h1 class="hidden-element" *ngIf="hideTitle">Contenido</h1>
                        <div [class.evaluation-layout-topside]="showTopside" #evaluationLayoutTopside>
                            <l-slot name="topside"></l-slot>
                        </div>
                    </div>
                    <div
                        [class.evaluation-layout-contenido]="showContenido"
                        [class.bordered]="borderedColumns"
                        #evaluationLayoutContenido
                    >
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="push-footer"></div>
</div>
<ng-content select="cui-layout-footer"></ng-content>
