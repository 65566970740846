import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { EntrenadorMeta } from "./entrenador_metas.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class EntrenadorMetas extends PuntajeBaseService<EntrenadorMeta> {
    tableName = "entrenador_metas"
    singularTableName = "entrenador_meta"
    modelClass = EntrenadorMeta

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
