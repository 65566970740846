import { Injectable, TemplateRef } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { LayoutSlotsService } from "./layout-slots.service"

interface LayoutOptions {
    [name: string]: any
}

/**
 * Este es un servicio base para poder manejar los slots y las opciones de los layouts a partir de cualquier componente, no usar directamente
 * sino crear uno que extienda de este en caso de necesitarse otro layout. Ver por ejemplo logged-layout.service.ts
 */
@Injectable()
export class LayoutService<T = LayoutOptions> {
    defaultOptions: T

    options$: BehaviorSubject<T>

    templates$: BehaviorSubject<{ [name: string]: TemplateRef<any> }> = this.layoutSlotsService.templates$

    constructor(protected layoutSlotsService: LayoutSlotsService) {}

    setOptions(options: T) {
        const mergedOptions = Object.keys(this.defaultOptions).reduce((acc, key) => {
            acc[key] = options[key] ?? this.defaultOptions[key]

            return acc
        }, {} as T)

        this.options$.next(mergedOptions)
    }

    resetOptions() {
        this.options$.next(this.defaultOptions)
    }

    resetSlots() {
        this.layoutSlotsService.resetSlots()
    }

    addSlot(name: string, templateRef: TemplateRef<any>) {
        this.layoutSlotsService.addSlot(name, templateRef)
    }
}
