import { BaseModel, SaveType, Validations } from "@puntaje/shared/core"
import { Perfil } from "./perfiles.model"
import moment from "moment-timezone"
import { Persona } from "./personas.model"

export class Usuario extends BaseModel {
    public static className: string = "Usuario"
    public persona_id: number
    public email: string
    public password: string
    public password_confirmation: string
    public password_digest: string
    public fecha_expiracion: any

    public static global_validations: any = [Validations.equalPasswords(["password", "password_confirmation"])]

    usuarios_hijos_count: number

    @SaveType(() => Perfil) perfiles: Perfil[]
    @SaveType(() => Usuario) usuarios_hijos: Usuario[]
    @SaveType(() => Usuario) usuarios: Usuario[]
    @SaveType(() => Persona) persona: Persona

    public toString() {
        return this.email
    }

    public getPersona() {
        return this.persona
    }

    get personasAsociadas() {
        if (this.usuarios) {
            return this.usuarios.map(u => u.persona).filter(u => u)
        } else {
            return []
        }
    }

    public get estado() {
        if (this.fecha_expiracion) {
            let fechaActual = moment(new Date())
            let expiracion = moment(new Date(this.fecha_expiracion))
            let horas = expiracion.diff(fechaActual, "hour")
            if (horas <= 0) {
                return "Deshabilitado"
            } else {
                return "Habilitado"
            }
        } else {
            return "Sin Fecha"
        }
    }
}
