import { Component } from "@angular/core"

@Component({
    selector: "sl-content-over-title, l-content-over-title",
    template: `
        <l-slot-content name="content-over-title">
            <ng-content></ng-content>
        </l-slot-content>
    `
})
export class LContentOverTitleComponent {}
