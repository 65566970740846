import { BaseModel } from "@puntaje/shared/core"

export class ContestableTipo extends BaseModel {
    public static className: string = "ContestableTipo"
    public contestable_tipo: string
    public created_at: Date
    public updated_at: Date
}

export enum ContestableTipoEnum {
    alternativa = "Pregunta de alternativas",
    spr = "Respuesta construida",
    libre = "Respuesta libre",
    completable = "Respuesta completable",
    imagen = "Respuesta imagen",
    archivo = "Respuesta archivo"
}

export const contestableTiposExplicacionKeys = {
    "Pregunta de alternativas": "contestable_tipos.contenido.alternativas",
    "Respuesta libre": "contestable_tipos.contenido.libre",
    "Respuesta archivo": "contestable_tipos.contenido.archivo",
    Mixto: "contestable_tipos.contenido.mixto"
}

export const contestableTiposNombreKeys = {
    "Pregunta de alternativas": "contestable_tipos.titulo.alternativas",
    "Respuesta libre": "contestable_tipos.titulo.libre",
    "Respuesta archivo": "contestable_tipos.titulo.archivo",
    Mixto: "contestable_tipos.titulo.mixto"
}
