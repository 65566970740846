import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { PlanClasesModule } from "@puntaje/puntaje/new-modules/plan-clases"
import { PlanEstudiosModule } from "@puntaje/puntaje/new-modules/plan-estudios"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { PlanClasesDeAlumnosComponent } from "./plan-clases-de-alumnos.component"
import { PlanClasesAlumnosComponent } from "./plan-clases-alumnos.component"
import { PlanClaseAlumnoComponent } from "./plan-clase-alumno.component"
import { ClasePlanClaseComponent } from "./clase-plan-clase.component"
import { MisPlanClasesComponent } from "./mis-plan-clases/mis-plan-clases.component"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { PlanClasesComponent } from "./plan-clases/plan-clases.component"
import { CUIInputsModule } from "@puntaje/shared/cui"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { PlanClaseComponent } from "./plan-clase/plan-clase.component"
import { PlanClaseNewComponent } from "./plan-clase-new/plan-clase-new.component"
import { PlanClaseEditComponent } from "./plan-clase-edit/plan-clase-edit.component"
import { TranslationsModule, UtilModule } from "@puntaje/shared/core"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PlanClasesModule,
        PlanEstudiosModule,
        BannersModule,
        CUILayoutsModule,
        SharedModule,
        CUIInputsModule,
        CommonsLayoutsModule,
        TranslationsModule,
        UtilModule
    ],
    exports: [
        PlanClasesDeAlumnosComponent,
        PlanClasesAlumnosComponent,
        PlanClaseAlumnoComponent,
        ClasePlanClaseComponent,
        MisPlanClasesComponent,
        PlanClasesComponent,
        PlanClaseComponent,
        PlanClaseNewComponent,
        PlanClaseEditComponent
    ],
    declarations: [
        PlanClasesDeAlumnosComponent,
        PlanClasesAlumnosComponent,
        PlanClaseAlumnoComponent,
        ClasePlanClaseComponent,
        MisPlanClasesComponent,
        PlanClasesComponent,
        PlanClaseComponent,
        PlanClaseNewComponent,
        PlanClaseEditComponent
    ]
})
export class PuntajePlanClasesModule {}
