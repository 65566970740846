<div class="contracted-error" [class.expanded-error]="isExpanded" [id]="containerId">
    <ul *ngIf="isExpanded" class="error-list" #error>
        <li *ngFor="let errorMessage of errorMessages">
            <div class="align-table">
                <div class="left-icon">
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                </div>
                <div [id]="'error-message-' + containerId" class="right-message">
                    <ng-container [ngTemplateOutlet]="errorMessage.templateRef"></ng-container>
                </div>
            </div>
        </li>
    </ul>
</div>
