import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Output, EventEmitter } from "@angular/core"

@Directive({
    selector: "[resize-aware]"
})
export class ResizeAwareDirective {
    el: HTMLElement
    @Output() currentSize = new EventEmitter()
    previousSize: number

    constructor(el: ElementRef) {
        this.el = el.nativeElement
        this.previousSize = this.el.offsetWidth
    }

    @HostListener("window:resize", ["$event"]) onResize(event) {
        //this.currentSize.emit(this.el.offsetWidth)
        this.triggerResize()
    }

    ngAfterViewChecked(): void {
        //this.currentSize.emit(this.el.offsetWidth)
        this.triggerResize()
    }

    ngAfterViewInit() {
        setTimeout(() => {
            //this.currentSize.emit(this.el.offsetWidth)
            this.triggerResize()
        }, 10)
    }

    public triggerResize() {
        const currentSize = this.el.offsetWidth
        if (currentSize != this.previousSize) {
            this.previousSize = currentSize
            this.currentSize.emit(currentSize)
        }
    }
}
