import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { UtilModule, FormModule, ModelViewModule, PaginatorModule, TranslationsModule } from "@puntaje/shared/core"

import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { registerLocaleData } from "@angular/common"
import localeEs from "@angular/common/locales/es"

import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"

import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { CUIInputsModule, CUITextsModule } from "@puntaje/shared/cui"

import { NebuModule } from "@puntaje/nebulosa/api-services"
import { planPersonalPredefinidosProfesoresComponent } from "./plan-personal-predefinidos-profesores/plan_personal-predefinidos-profesores.component"
import { PlanPersonalPredefinidoProfesorComponent } from "./plan-personal-predefinido-profesor/plan-personal-predefinido-profesor.component"
import { AplicarPlanPersonalProfesoresComponent } from "./aplicar-plan-personal-profesores/aplicar-plan-personal-profesores.component"
import { PlanificacionCompartidaProfesoresComponent } from "./planificacion-compartida-profesores/planificacion-compartida-profesores.component"
import { ProfesoresPlanificacionesCompartidasComponent } from "./profesores-planificaciones-compartidas/profesores-planificaciones-compartidas.component"
import { EstadisticasClaseProfesoresComponent } from "./estadisticas-clase-profesores/estadisticas-clase-profesores.component"
import { ClaseProfesorComponent } from "./clase-profesor/clase-profesor.component"
import { DatosGeneralesPlanificacionComponent } from "./datos-generales-planificacion/datos_generales_planificacion.component"
import { GraficoEvolutivoClaseSeguimientoComponent } from "./grafico-evolutivo-clase-seguimiento/grafico-evolutivo-clase-seguimiento.component"
import { EstadisticasModule } from "@puntaje/puntaje/new-modules/estadisticas"
import { FiltroGraficoEvolutivoClaseSeguimientoComponent } from "./filtro-grafico-evolutivo-clase-seguimiento/filtro-grafico-evolutivo-clase-seguimiento.component"
import { PlanificacionesCompartidasComponent } from "./planificaciones-compartidas/planificaciones-compartidas.component"
import { PlanificacionBoxComponent } from "./planificacion-box/planificacion-box.component"
import { PlanPersonalesModule } from "@puntaje/puntaje/new-modules/plan-personales"
import { PlanificacionesNewComponent } from "./planificaciones-new/planificaciones-new.component"
import { PlanificacionAdminComponent } from "./planificacion-admin/planificacion-admin.component"
import { PlanificacionSesionComponent } from "./planificacion-sesion/planificacion-sesion.component"
import { PlanificacionesEditComponent } from "./planificaciones-edit/planificaciones-edit.component"
import { ListaPlanificacionesComponent } from "./lista-planificaciones/lista-planificaciones.component"
import { SesionesNewComponent } from "./sesiones-new/sesiones-new.component"
import { SesionesEditComponent } from "./sesiones-edit/sesiones-edit.component"
import { PlanPersonalContenidoNewComponent } from "./plan-personal-contenido-new/plan-personal-contenido-new.component"
import { PlanPersonalContenidoEditComponent } from "./plan-personal-contenido-edit/plan-personal-contenido-edit.component"
import { BarraAvancePlanificacionComponent } from "./barra-avance-planificacion/barra-avance-planificacion.component"

registerLocaleData(localeEs)

@NgModule()
export class PlanificacionesServices {}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        ModelViewModule,
        PaginatorModule,
        RouterModule,
        MaterialesModule,
        ProgressbarModule,
        Angular2FontawesomeModule,
        EnsayosModule,
        SharedModule,
        InstrumentosModule,
        TooltipModule,
        PopoverModule,
        NebuModule,
        TranslationsModule,
        EstadisticasModule,
        PlanPersonalesModule,
        CUITextsModule,
        CUIInputsModule
    ],
    declarations: [
        planPersonalPredefinidosProfesoresComponent,
        PlanPersonalPredefinidoProfesorComponent,
        AplicarPlanPersonalProfesoresComponent,
        PlanificacionCompartidaProfesoresComponent,
        ProfesoresPlanificacionesCompartidasComponent,
        EstadisticasClaseProfesoresComponent,
        ClaseProfesorComponent,
        DatosGeneralesPlanificacionComponent,
        GraficoEvolutivoClaseSeguimientoComponent,
        FiltroGraficoEvolutivoClaseSeguimientoComponent,
        PlanificacionesCompartidasComponent,
        PlanificacionBoxComponent,
        PlanificacionesNewComponent,
        ListaPlanificacionesComponent,
        PlanificacionAdminComponent,
        PlanificacionSesionComponent,
        PlanificacionesEditComponent,
        SesionesNewComponent,
        SesionesEditComponent,
        PlanPersonalContenidoNewComponent,
        PlanPersonalContenidoEditComponent,
        BarraAvancePlanificacionComponent
    ],
    exports: [
        planPersonalPredefinidosProfesoresComponent,
        AplicarPlanPersonalProfesoresComponent,
        PlanPersonalPredefinidoProfesorComponent,
        PlanificacionCompartidaProfesoresComponent,
        ProfesoresPlanificacionesCompartidasComponent,
        EstadisticasClaseProfesoresComponent,
        ClaseProfesorComponent,
        DatosGeneralesPlanificacionComponent,
        GraficoEvolutivoClaseSeguimientoComponent,
        PlanificacionesCompartidasComponent,
        PlanificacionesNewComponent,
        ListaPlanificacionesComponent,
        PlanificacionAdminComponent,
        PlanificacionesEditComponent,
        SesionesNewComponent,
        SesionesEditComponent,
        PlanPersonalContenidoNewComponent,
        PlanPersonalContenidoEditComponent,
        BarraAvancePlanificacionComponent,
        PlanificacionBoxComponent
    ]
})
export class PlanificacionesModule {
    public static services(): ModuleWithProviders<PlanificacionesServices> {
        return {
            ngModule: PlanificacionesServices,
            providers: []
        }
    }
}
