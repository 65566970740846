import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { Ayuda } from "./ayudas.model"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"

@Injectable()
export class Ayudas extends NebuBaseService<Ayuda> {
    tableName = "ayudas"
    singularTableName = "ayuda"
    modelClass = Ayuda

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public getDownloadLink = (m: Ayuda) => {
        return m.url
    }

    public isFileLink = (m: Ayuda) => {
        return m.isFile()
    }

    public like(ayudaId: number, params: any) {
        return this.one(ayudaId).concatExtra("like").post(params)
    }

    public dislike(ayudaId: number, params: any) {
        return this.one(ayudaId).concatExtra("dislike").post(params)
    }

    public sentiment(ayudaId: number, params: any) {
        return this.one(ayudaId).concatExtra("sentiment").get(params)
    }
}
