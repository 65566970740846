import { Validators } from "@angular/forms"
import { BaseModel } from "@puntaje/shared/core"

export class Taxativo extends BaseModel {
    public static className: string = "Taxativo"
    public id: number
    public pregunta_id: number
    public taxativo: string
    public imagen: string
    public taxativo_latex: string
    public orden: number
    public created_at: Date
    public updated_at: Date

    public static get model_params(): any {
        return {
            taxativo: { label: "Enunciado", type: "ckeditor", visible: true, validations: [Validators.required] },
            taxativo_latex: { label: "Enunciado Latex", type: "textarea", visible: true },
            imagen: { label: "Imagen", type: "text", visible: true }
        }
    }

    public toString() {
        return this.taxativo
    }
}
