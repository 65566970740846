import { Component, ViewChild, ChangeDetectorRef, OnInit } from "@angular/core"
import { Pregunta, Preguntas, Material, Materiales } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "duda-item",
    templateUrl: "duda_item.component.html",
    styleUrls: ["duda_item.component.scss"]
})
export class DudaItemComponent implements OnInit {
    itemType: string
    item: any
    material: Material
    pregunta: Pregunta
    expanded: boolean = false
    @ViewChild("hackxorLayout") hackLayout: LoadingLayoutComponent
    showPreguntas: boolean = false

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {}

    load(itemType, item) {
        this.itemType = itemType
        this.item = item
        this.setItem()
    }

    setItem() {
        if (this.itemType) {
            switch (this.itemType) {
                case "Pregunta":
                    this.pregunta = this.item as Pregunta
                    break
                case "Material":
                    this.material = this.item as Material
                    break
                default:
                    break
            }
        }
        this.hackLayout?.ready()
        this.showPreguntas = true

        this.cdr.detectChanges()
    }

    expand() {
        this.expanded = !this.expanded
    }
}
