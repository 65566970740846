import { Component, Input, SimpleChanges, Output, EventEmitter, ViewChild, OnDestroy } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import {
    GeneradorInstrumentos,
    GeneradorInstrumento,
    Clasificacion,
    Asignatura,
    Asignaturas
} from "@puntaje/nebulosa/api-services"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import { Evaluaciones, Evaluacion, EvaluacionMultiple, EvaluacionMultiples } from "@puntaje/puntaje/api-services"
import { ModalDirective } from "ngx-bootstrap/modal"
import { AuthService, CapitalizePipe } from "@puntaje/shared/core"
import { ModalImprimirEvaluacionAlumnosComponent } from "./modal-imprimir-evaluacion-alumnos/modal-imprimir-evaluacion-alumnos.component"
import { AbstractGenerarEnsayoComponent } from "./abstract_generar_ensayo.component"
import { Subscription } from "rxjs"
import { State, selectEvaluacionTipoAliases } from "@puntaje/puntaje/store"
import { Store, select } from "@ngrx/store"
import { filter } from "rxjs/operators"

@Component({
    selector: "generar-ensayo-alumno",
    templateUrl: "generar_ensayo_alumno.component.html",
    styleUrls: ["generar_ensayo_alumno.component.scss"]
})
export class GenerarEnsayoAlumnoComponent extends AbstractGenerarEnsayoComponent implements OnDestroy {
    asignaturaId: number
    asignatura: Asignatura
    openModal: EventEmitter<any> = new EventEmitter<any>()
    plataforma: string
    showPersonalizar: boolean = true
    @Output() onReadyAsignatura: EventEmitter<any> = new EventEmitter<any>()
    @Input() tipoEvaluacion: string
    @Input() hideButtonDescargar?: boolean = false
    @ViewChild("mandatoryLoading", { static: true }) mandatoryLoading: ModalDirective
    @ViewChild("tutorialModal", { static: true }) tutorialModal: ModalImprimirEvaluacionAlumnosComponent

    subAlias: Subscription
    evaluacionTipoAlias: string
    capitalize = new CapitalizePipe()
    realizarButton = this.translatorService.translate("generar_ensayos.realizar", {})
    descargarButton = this.translatorService.translate("generar_ensayos.descargar", {})

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    constructor(
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected evaluacionesService: Evaluaciones,
        protected asignaturasService: Asignaturas,
        protected authService: AuthService,
        protected translatorService: I18nService,
        protected evaluacionMultiplesService: EvaluacionMultiples,
        private store: Store<State>
    ) {
        super(router, generadorInstrumentosService, evaluacionesService)
        this.plataforma = config.plataforma.name
    }

    ngOnInit() {
        super.ngOnInit()
        let skipRealizar = false
        let skipDescargar = false

        if (config.plataforma.realizarButton) {
            this.realizarButton = config.plataforma.realizarButton
            skipRealizar = true
        }
        if (config.plataforma.descargarButton) {
            this.descargarButton = config.plataforma.descargarButton
            skipDescargar = true
        }
        if (skipRealizar && skipDescargar) return

        this.subAlias = this.evaluacionTipoAliases$.subscribe(evaluacionTipoAliases => {
            this.evaluacionTipoAlias = evaluacionTipoAliases[this.tipoEvaluacion]
            if (this.evaluacionTipoAlias) {
                const capTipo = this.capitalize.transform(this.evaluacionTipoAlias)
                if (!skipRealizar) this.realizarButton += " " + capTipo
                if (!skipDescargar) this.descargarButton += " " + capTipo
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"] || changes["tipoEvaluacion"]) {
            this.loadData()
        }
        if (changes["generadorInstrumento"]) {
            this.loadingLayout.ready()
        }
    }

    loadData() {
        this.showPersonalizar = true
        this.loadingLayout.standby()
        if (this.asignaturaId) {
            this.asignaturasService
                .find(this.asignaturaId, { tipo_instrumento: this.tipoEvaluacion })
                .then((asignatura: Asignatura) => {
                    this.asignatura = asignatura
                    this.onReadyAsignatura.emit(this.asignatura)
                    this.generadorInstrumentos = asignatura.generador_instrumentos?.filter(gi => gi.visible)

                    if (
                        config.plataforma.hidePersonalizarByAsignatura &&
                        config.plataforma.hidePersonalizarByAsignatura.includes(asignatura.asignatura)
                    ) {
                        this.showPersonalizar = false
                    }

                    this.loadingLayout.ready()
                })
        } else {
            this.loadingLayout.ready()
        }
    }

    afterPreguntas(evaluacion: Evaluacion) {
        this.evaluacionesService.save(evaluacion).then((e: Evaluacion) => {})
    }

    makeEvaluacion() {
        const evaluacion = new Evaluacion() as any
        evaluacion.tiempo = this.tiempo
        evaluacion.asignatura_id = this.asignaturaId
        evaluacion.generador_instrumento_id = this.generadorInstrumento.id
        evaluacion.numero_preguntas = this.numeroPreguntas
        evaluacion.clasificaciones_id = this.clasificacionesIds
        evaluacion.evaluacion_tipo = this.tipoEvaluacion

        return this.evaluacionesService.save(evaluacion)
    }

    makeEvaluacionMultiple() {
        const evaluacionMultiple = new EvaluacionMultiple() as any
        evaluacionMultiple.tiempo = this.tiempo
        evaluacionMultiple.asignatura_id = this.asignaturaId
        evaluacionMultiple.generador_instrumento_id = this.generadorInstrumento.id
        evaluacionMultiple.numero_preguntas = this.numeroPreguntas
        evaluacionMultiple.clasificaciones_id = this.clasificacionesIds
        evaluacionMultiple.evaluacion_tipo = this.tipoEvaluacion
        evaluacionMultiple.evaluacion_multiple = ""

        return this.evaluacionMultiplesService.save(evaluacionMultiple)
    }

    generarEnsayo() {
        if (!this.generadorInstrumento) return

        this.loadingLayout.standby()

        if (!this.generadorInstrumento.multiple) {
            this.makeEvaluacion().then((e: Evaluacion) => {
                this.router.navigate(["/ensayos", e.id, "realizar"])
            })
        } else {
            this.makeEvaluacionMultiple().then((em: EvaluacionMultiple) => {
                this.router.navigate(["/evaluaciones", em.id, "realizar_multiple"])
            })
        }
    }

    logInfoLatex(statusCodeLambda, exitStatus, stdout, stderr) {
        console.log("STATUS CODE LAMBDA")
        console.log("%c " + statusCodeLambda, "color: blue")
        console.log("EXIT STATUS LATEX")
        console.log("%c " + exitStatus, "color: blue")
        console.log("SALIDA ESTANDAR LATEX")
        console.log("%c " + stdout, "color: blue")
        console.log("SALIDA DE ERRORES LATEX")
        console.log("%c " + stderr, "color: crimson")
    }

    createLinkAndOpen(link, downloadString) {
        const a = document.createElement("a")
        a.download = downloadString
        a.href = link
        a.click()
    }

    instruccionesImpresion() {
        this.tutorialModal.open()
    }

    confirmInstructionsAndContinue() {
        this.imprimir()
    }

    imprimir() {
        this.mandatoryLoading.show()
        let params: any = {}
        params.plataforma = config.plataforma.name

        if (this.generadorInstrumento?.maximo_alternativas) {
            params.cantidad_alternativas = this.generadorInstrumento.maximo_alternativas
        }

        if (!this.generadorInstrumento.multiple) {
            this.makeEvaluacion().then((e: Evaluacion) => {
                this.evaluacionesService.enableIgnoreModel()
                this.evaluacionesService.imprimir(e.id, params).then((obj: any) => {
                    this.evaluacionesService.disableIgnoreModel()
                    const info = obj.info
                    this.logInfoLatex(info.statusCode, info.exit_status, info.stdout, info.stderr)
                    this.mandatoryLoading.hide()
                    const downloadString = "evaluacion_" + e.id + ".pdf"
                    this.createLinkAndOpen(info.link, downloadString)
                })
            })
        } else {
            this.makeEvaluacionMultiple().then((em: EvaluacionMultiple) => {
                this.evaluacionMultiplesService
                    .find(em.id, { include: "evaluaciones" })
                    .then((em: EvaluacionMultiple) => {
                        em.evaluaciones.forEach((e: Evaluacion) => {
                            this.evaluacionesService.enableIgnoreModel()
                            this.evaluacionesService.imprimir(e.id, params).then((obj: any) => {
                                this.evaluacionesService.disableIgnoreModel()
                                const info = obj.info
                                this.logInfoLatex(info.statusCode, info.exit_status, info.stdout, info.stderr)
                                this.mandatoryLoading.hide()
                                const downloadString = "evaluacion_" + e.id + ".pdf"
                                this.createLinkAndOpen(info.link, downloadString)
                            })
                        })
                    })
            })
        }
    }

    ngOnDestroy() {
        if (this.subAlias) this.subAlias.unsubscribe()
    }
}
