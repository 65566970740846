import { Validators } from "@angular/forms"
import { BaseForm } from "@puntaje/shared/core"
import { Plataformas } from "./plataformas.service"
import { ReporteRazones } from "./reporte_razones.service"
import { ReporteSubRazones } from "./reporte_sub_razones.service"

export class ReporteForm extends BaseForm {
    public static get formParams(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            entidad_id: { label: "Entidad", type: "number", visible: true, validations: [Validators.required] },
            tabla: {
                label: "Tabla",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            plataforma_id: { label: "Plataforma", type: "select", visible: true, options: { class: Plataformas } },
            usuario_reporte_id: {
                label: "Usuario Id",
                type: "number",
                visible: true,
                validations: [Validators.required]
            },
            usuario_reporte_nombre: {
                label: "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            usuario_reporte_apellido_paterno: {
                label: "Apellido paterno",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            usuario_reporte_apellido_materno: { label: "Apellido materno", type: "text", visible: true },
            usuario_reporte_email: {
                label: "Email",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            usuario_reporte_rol_id: {
                label: "Rol ID",
                type: "text",
                visible: true,
                validations: [Validators.required]
            },
            usuario_reporte_rol_rol: {
                label: "Rol",
                type: "text",
                visible: true,
                validations: [Validators.required]
            },
            reporte: {
                label: "Reporte",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            reporte_razon_id: {
                label: "Razón de Reporte",
                type: "select",
                visible: true,
                options: { class: ReporteRazones }
            },
            reporte_sub_razon_id: {
                label: "Sub Razón de Reporte",
                type: "select",
                visible: true,
                options: { class: ReporteSubRazones }
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
