import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { EvaluacionInstancias, EvaluacionSugerida, EvaluacionSugeridas } from "@puntaje/puntaje/api-services"
import { AuthService, PaginatorComponent } from "@puntaje/shared/core"
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core"

@Component({
    selector: "evaluaciones-recomendadas-alumno",
    templateUrl: "evaluaciones-recomendadas-alumno.component.html",
    styleUrls: ["evaluaciones-recomendadas-alumno.component.scss"]
})
export class EvaluacionesRecomendadasAlumnoComponent implements OnChanges {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    @Input() asignaturaId: number
    @Input() evaluacionTipo: string
    @Input() showEvaluacionMultiple: boolean = false
    evaluacionesSugeridas: EvaluacionSugerida[] = []
    evaluacionSugeridasByGrupo: { [index: number]: { [grupo: string]: EvaluacionSugerida[] } }

    constructor(
        protected evaluacionesSugeridasService: EvaluacionSugeridas,
        protected authService: AuthService,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"] || changes["evaluacionTipo"]) {
            if (this.paginator) this.paginator.changePage(1)
        }
        if (this.showEvaluacionMultiple) {
            this.getEvaluacionesSugeridas()
        }
    }
    getEvaluacionesRealizadasIds(evaluacionSugeridaIds) {
        return this.evaluacionInstanciasService.where({
            evaluacion_instancia: {
                evaluacion_id: evaluacionSugeridaIds,
                usuario_id: this.authService.getUserData().id
            }
        })
    }

    getEvaluacionesSugeridas = (page: number = 1, per: number = 10) => {
        this.loadingLayout.standby()

        return this.evaluacionesSugeridasService
            .where(this.evaluacionSugeridaParams(page, per))
            .then(async (evaluacionSugeridas: EvaluacionSugerida[], total: number) => {
                const evaluacionSugeridaIds = evaluacionSugeridas.map(es => es.evaluacion_id)
                const evaluacionesRealizadas = await this.getEvaluacionesRealizadasIds(evaluacionSugeridaIds)
                evaluacionSugeridas.forEach((element, index) => {
                    if (evaluacionesRealizadas.find(er => er.evaluacion_id == element.evaluacion_id)) {
                        element["evaluacion_realizada"] = true
                    }
                })
                this.evaluacionesSugeridas = evaluacionSugeridas

                this.evaluacionSugeridasByGrupo = this.evaluacionesSugeridas.reduce((acc, es) => {
                    es.grupo_recursos.forEach(gr => {
                        acc[gr.grupo_recurso_tipo.orden + 1] = acc[gr.grupo_recurso_tipo.orden + 1] || {}
                        acc[gr.grupo_recurso_tipo.orden + 1][gr.grupo_recurso_tipo.grupo_recurso_tipo] =
                            acc[gr.grupo_recurso_tipo.orden + 1][gr.grupo_recurso_tipo.grupo_recurso_tipo] || []
                        acc[gr.grupo_recurso_tipo.orden + 1][gr.grupo_recurso_tipo.grupo_recurso_tipo][gr.orden] = es
                    })

                    if (es.grupo_recursos.length == 0) {
                        acc[0] = acc[0] || {}
                        acc[0][""] = acc[0][""] || []
                        acc[0][""].push(es)
                    }

                    return acc
                }, {})
                this.cdr.detectChanges()

                if (this.loadingLayout) this.loadingLayout.ready()
                return total
            })
    }

    evaluacionSugeridaParams(page: number = 1, per: number = 10) {
        const params = {
            evaluacion_tipo: {
                evaluacion_tipo: this.evaluacionTipo
            },
            per: per,
            page: page,
            order: "desc",
            sort_by: "evaluacion_sugeridas.id",
            with_evaluacion_multiple: this.showEvaluacionMultiple,
            include: "[grupo_recursos:grupo_recurso_tipo]"
        }
        if (!this.showEvaluacionMultiple) {
            params["evaluacion_sugerida"] = {
                asignatura_id: this.asignaturaId
            }
        }
        return params
    }
}
