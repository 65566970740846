<ll-titulo>{{ "puntaje_core.estadisticas.evaluacion_instancia_estadisticas.titulo" | t }}</ll-titulo>

<loading-layout #loadingLayout>
    <tabla-resumen-evaluacion-instancia *ngIf="data" [data]="data"></tabla-resumen-evaluacion-instancia>
    <grafico-desempeno-promedio
        *ngIf="data && asignatura"
        [asignatura]="asignatura"
        [dataOmitidas]="data.omitidas"
        [dataCorrectas]="data.correctas"
        [dataIncorrectas]="data.incorrectas"
        [tipoEvaluacion]="evaluacionTipo"
    ></grafico-desempeno-promedio>
    <div *ngIf="dataGraficoClasificacion.length > 0">
        <div *ngFor="let d of dataGraficoClasificacion">
            <grafico-desempeno-clasificacion
                [clasificaciones]="d.clasificaciones"
                [data]="d.data"
                [dataTotal]="d.dataTotal"
                [tipoClasificacion]="d.tipoClasificacion"
                [asignatura]="asignatura"
                [tipoEvaluacion]="evaluacionTipo"
            ></grafico-desempeno-clasificacion>
        </div>
    </div>
</loading-layout>
