import { Component, OnInit, Input, Optional } from "@angular/core"
import { ActivatedRoute, Params } from "@angular/router"
import { TipoMaterial } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/**
 * Este es el componente más inútil que existe y no sé por qué existe, pero para matarlo hay que recorrer los
 * platforms que lo llaman.
 */
@Component({
    templateUrl: "ayuda_list.component.html",
    selector: "ayuda-list"
})
export class AyudaListComponent implements OnInit {
    @Input() tracking: boolean = false
    @Input() targetApp: string | string[] = undefined
    tipoMaterial: number
    plataforma_id: number

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.plataforma_id = config.plataforma.id
        // this.tipoMaterial = TipoMaterial.STREAMING;
    }
}
