<div
    class="barra-wrap"
    [class.withLabels]="visibleLabels"
    resize-aware
    (currentSize)="adaptToSize($event)"
    [class.bigSize]="enableBigSize"
    *ngIf="ponderables && usuario && promedioByPonderable"
>
    <div class="barra-ponderacion" [class.fullwidth]="startAnimValues">
        <div
            *ngFor="let p of ponderablesBarra"
            popover="{{ p.ponderable + ': ' + p.ponderacion + '%' }}"
            [outsideClick]="true"
            triggers="mouseenter:mouseleave"
            class="ponderacion"
            [style.width]="p.value + '%'"
            [class.ponderacionAnim]="!visibleLabels"
            [style.background-color]="p.color"
        >
            <p *ngIf="visibleLabels" class="ponderacion-label">
                <span class="valor">{{ p.ponderacion + "%" }}</span>
                <span class="asignatura">{{ p.ponderable }}</span>
            </p>
        </div>
    </div>
</div>
