<loading-layout #loadingLayout>
    <div *ngIf="renderedAsignatura" class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="">
                <cui-section-headline>
                    {{ "biblioteca.subtitulo" | t: { clasificacion: tipoInstrumento } }} {{ textoClasificacion }}
                </cui-section-headline>
                <filtros-clasificaciones-evaluacion-tipo
                    [asignaturaId]="asignaturaId"
                    [evaluacionTipo]="tipoInstrumento"
                    [preloadedClasificaciones] = "{'curso': cursoId}"
                    (changeFilter)="onChangeFiltroClasificacion($event)"
                    *ngIf="enableFiltroClasificacionEvTipo"
                ></filtros-clasificaciones-evaluacion-tipo>
                <simple-search-filter
                    *ngIf="enableSearch"
                    (updateSearch)="search($event)"
                    (clearSearch)="clear()"
                    [placeholder]="'biblioteca.search_placeholder' | t"
                ></simple-search-filter>
                <p *ngIf="enableHighlight && renderedAsignatura.clasificaciones.length > 0" class="about-results">
                    {{ "biblioteca.mostrando_resultados" | t }}
                    <span>{{ highlightedText }}</span>
                </p>

                <p></p>
                <p *ngIf="enableHighlight && renderedAsignatura.clasificaciones.length == 0" class="about-results">
                    {{ "biblioteca.resultados_warn" | t }}:
                    <span>{{ highlightedText }}</span>
                </p>

                <p></p>
                <div
                    *ngFor="let clasificacion of renderedAsignatura.clasificaciones; let i = index"
                    class="wrap_container_level_1"
                >
                    <div class="container_level_1" *ngIf="!enableHideEmpty || contadorMateriales[i].total > 0">
                        <div class="title_level_1" [toggleExpandable]="contenidoNivel1" #firstLevelToggle>
                            <div class="t-body">
                                <div class="t-cell">
                                    <h2>{{ clasificacion.clasificacion }}</h2>
                                </div>
                                <div class="t-cell title_level_1_count">
                                    <span>
                                        <ogr-icon name="libro2-o" align="middle"></ogr-icon>
                                        &nbsp;({{ contadorMateriales[i].total }})
                                    </span>
                                </div>
                                <div class="t-cell title_level_1_icon">
                                    <span class="glyphicon glyphicon-minus-sign expanded-sign"></span>
                                    <span class="glyphicon glyphicon-plus-sign contracted-sign"></span>
                                </div>
                            </div>
                        </div>
                        <div [expandableContent] class="wrapper_level_1" #contenidoNivel1>
                            <div class="inner-margins_cont_level_1">
                                <div class="cont_level_1">
                                    <h3 class="title_level_2_extra" *ngIf="clasificacion.materiales_propios.length > 0">
                                        <div *ngIf="this.textoClasificacion">
                                            {{
                                                "biblioteca.subtitulo_clasificacion"
                                                    | t : {clasificacion: this.textoClasificacion}
                                            }}
                                        </div>
                                        <span class="contador-hija">
                                            <ogr-icon name="libro2-o" align="text-bottom"></ogr-icon>
                                            &nbsp;({{ contadorMateriales[i].propios }})
                                        </span>
                                    </h3>
                                    <table
                                        class="table tabla-materiales table-stripped"
                                        *ngIf="clasificacion.materiales_propios.length > 0"
                                    >
                                        <tbody>
                                            <tr
                                                *ngFor="let m of clasificacion.materiales_propios; let i = index"
                                                [class.odd]="i % 2 == 1"
                                            >
                                                <td class="file-icon-cell" *ngIf="m.visible">
                                                    <div class="file-icon">
                                                        <tipo-material-icon
                                                            [tipo]="m.material_tipo.material_tipo"
                                                        ></tipo-material-icon>
                                                    </div>
                                                </td>
                                                <td *ngIf="m.visible">
                                                    <a href="javascript:void(0)" (click)="openMaterialPreview(m)">
                                                        <span
                                                            [innerHtml]="
                                                                enableHighlight
                                                                    ? (m.material | highlight: highlightedText)
                                                                    : m.material
                                                            "
                                                            class="searchable"
                                                        ></span>
                                                    </a>
                                                </td>
                                                <td *ngIf="m.visible">
                                                    <cui-button-link
                                                        class="btn_style btn-small margin_right"
                                                        (click)="openMaterialPreview(m)"
                                                    >
                                                        {{ "biblioteca.prev_boton" | t }}
                                                    </cui-button-link>
                                                    <cui-button-link
                                                        [routerLinkValue]="[rutaMaterial, m.id]"
                                                        class="btn_style btn-small"
                                                    >
                                                        {{ "biblioteca.abrir_boton" | t }}
                                                    </cui-button-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div
                                        [class.primera-clasificacion-no-margin]="
                                            clasificacion.materiales_propios.length == 0
                                        "
                                    >
                                        <div
                                            *ngFor="
                                                let clasificacion_hija of clasificacion.clasificaciones_hijas;
                                                let j = index
                                            "
                                        >
                                            <div *ngIf="clasificacion_hija.materiales.length > 0">
                                                <h3
                                                    class="title_level_2"
                                                    [class.with-detalles]="clasificacion_hija.descripcion"
                                                >
                                                    <fa name="chevron-right" class="icono"></fa>
                                                    {{ clasificacion_hija.clasificacion }}
                                                    <span class="contador-hija">
                                                        <ogr-icon name="libro2-o" align="text-bottom"></ogr-icon>
                                                        &nbsp;({{ contadorMateriales[i].hijos[j] }})
                                                    </span>
                                                    <span
                                                        class="ver-detalles"
                                                        [toggleExpandable]="contenidoNivel2"
                                                        *ngIf="clasificacion_hija.descripcion"
                                                    >
                                                        {{ "biblioteca.detalles_boton" | t }}
                                                    </span>
                                                </h3>
                                                <div class="descripcion-clasificacion-wrap">
                                                    <div [expandableContent] #contenidoNivel2>
                                                        <div
                                                            class="descripcion-clasificacion"
                                                            [innerHTML]="clasificacion_hija.descripcion"
                                                        ></div>
                                                    </div>
                                                </div>
                                                <table
                                                    class="table tabla-materiales table-stripped"
                                                    *ngIf="clasificacion_hija.materiales.length > 0"
                                                >
                                                    <tbody>
                                                        <tr
                                                            *ngFor="
                                                                let m of clasificacion_hija.materiales;
                                                                let i = index
                                                            "
                                                            [class.odd]="i % 2 == 1"
                                                        >
                                                            <td class="file-icon-cell" *ngIf="m.visible">
                                                                <div class="file-icon">
                                                                    <tipo-material-icon
                                                                        [tipo]="m.material_tipo.material_tipo"
                                                                    ></tipo-material-icon>
                                                                </div>
                                                            </td>
                                                            <td *ngIf="m.visible">
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    (click)="openMaterialPreview(m)"
                                                                >
                                                                    <span
                                                                        [innerHtml]="
                                                                            enableHighlight
                                                                                ? (m.material
                                                                                  | highlight: highlightedText)
                                                                                : m.material
                                                                        "
                                                                        class="searchable"
                                                                    ></span>
                                                                </a>
                                                            </td>

                                                            <td *ngIf="m.visible">
                                                                <cui-button-link
                                                                    class="btn_style btn-small margin_right"
                                                                    (click)="openMaterialPreview(m)"
                                                                >
                                                                    {{ "biblioteca.prev_boton" | t }}
                                                                </cui-button-link>
                                                                <cui-button-link
                                                                    [routerLinkValue]="[rutaMaterial, m.id]"
                                                                    class="btn_style btn-small"
                                                                >
                                                                    {{ "biblioteca.abrir_boton" | t }}
                                                                </cui-button-link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <material-preview-modal #materialPreviewModal></material-preview-modal>
</loading-layout>
