import { BaseSentimentComponent } from "./base_sentiment.component"
import { Component, Injector, Input } from "@angular/core"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "single-value-sentiment",
    templateUrl: "single_value_sentiment.component.html",
    styleUrls: ["single_value_sentiment.component.scss"]
})
export class SingleValueSentimentComponent extends BaseSentimentComponent {
    @Input() vertical: boolean = false

    constructor(usuariosService: Usuarios, authService: AuthService, injector: Injector) {
        super(usuariosService, authService, injector)
    }
}
