<div class="menu-preguntas" *ngIf="evaluacion">
    <div
        class="menu-preguntas-container starting-animation"
        [class.menu-preguntas-hidden]="!showComponent"
        [class.starting-animation]="animateOnStart && !animateOnStartTrigger"
    >
        <div class="interactive-tab" (click)="toggleComponent()">
            <fa [name]="'pencil-square-o'" class="tab-icon"></fa>
        </div>
        <div class="menu-preguntas-content">
            <div class="menu-preguntas-header">
                <div class="inside-tab" (click)="toggleComponent()">
                    <fa [name]="'pencil-square-o'" class="tab-icon"></fa>
                </div>
                <span class="title">{{ "widget_menu_reguntas.preguntas" | t }}</span>
                <button (click)="toggleComponent()" type="button" class="closeBtn" aria-label="Close">
                    <fa *ngIf="showComponent" name="times"></fa>
                    <fa *ngIf="!showComponent" name="chevron-right"></fa>
                </button>
            </div>
            <div class="numeros-container">
                <div *ngFor="let j of pages" [class.show-page]="currentPage == j" class="page">
                    <div class="number" *ngFor="let i of numeros[j - 1]" [class.answered]="progressArray[i - 1]">
                        <a
                            easyScrollTo="preg_{{ i }}"
                            [easyScrollToPadding]="-40"
                            [easyScrollToDuration]="800"
                            title="Pregunta {{ i }}"
                        >
                            {{ i }}
                        </a>
                    </div>
                </div>
                <div class="menu-preguntas-controls">
                    <button
                        type="button"
                        [class.not-visible]="currentPage == 1"
                        class="btn prev-btn"
                        (click)="prevPage()"
                    >
                        <span class="glyphicon glyphicon-chevron-left"></span>
                    </button>
                    <div class="page-counter">
                        <div class="gauge-container">
                            <div
                                class="progress-circle p{{ progressPercentage }}"
                                [ngClass]="{ over50: progressPercentage > 50 }"
                            >
                                <span>{{ currentPage }}/{{ pageCount }}</span>
                                <div class="left-half-clipper">
                                    <div class="first50-bar"></div>
                                    <div class="value-bar"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        [class.not-visible]="currentPage >= pageCount"
                        class="btn next-btn"
                        (click)="nextPage()"
                    >
                        <span class="glyphicon glyphicon-chevron-right"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
