import { HttpClient } from "@angular/common/http"
import { Component, EventEmitter, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { AppEnv, BaseForm, GenericModalComponent, Validations } from "@puntaje/shared/core"
import { filter } from "rxjs/operators"

declare const environment: AppEnv

/**
 * Componente para el formulario de contacto ubicado en el registro para poder recuperar el RUT
 */
@Component({
    selector: "contacto-registro",
    templateUrl: "./contacto-registro.component.html",
    styleUrls: ["./contacto-registro.component.scss"]
})
export class ContactoRegistroComponent implements OnInit {
    openModal: EventEmitter<any> = new EventEmitter<any>()

    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent

    form: UntypedFormGroup
    contacto: any = {}
    params: any = {
        rut: {
            label: "RUT",
            type: "text",
            visible: true,
            placeholder: "11111111-1",
            validations: [Validators.required, Validations.validateRut]
        },
        numero_documento: {
            label: "Número de documento",
            type: "text",
            visible: true,
            placeholder: "511234567",
            validations: [Validators.required],
            tooltip:
                "Este dato aparece en la cara frontal de tu carnet de identidad. Te pedimos esta información para validar que el RUT es efectivamente tuyo y darte acceso a la cuenta que ya está creada con ese RUT. La información que nos entregues solo será utilizada para verificar en el sistema del registro civil que el número de documento entregado está asociado a ese RUT."
        },
        email: {
            label: "Email",
            type: "text",
            visible: true,
            placeholder: "correo@mail.com",
            validations: [Validators.required, Validations.validateEmail]
        },
        nombre_completo: {
            label: "Nombre completo",
            type: "text",
            visible: true,
            placeholder: "Nombre Apellido",
            validations: [Validators.required],
            tooltip:
                "El nombre que ingreses quedará asociado a tu cuenta y te informaremos a este correo los pasos a seguir para acceder a ella."
        }
    }

    sent = false
    error = false
    loading = false
    showMessageEmailExists = false

    constructor(protected router: Router, protected http: HttpClient, protected usuariosService: Usuarios) {}

    ngOnInit() {
        this.form = BaseForm.getForm(this.contacto, this.params)

        this.form.controls.rut.valueChanges.pipe(filter(x => !!x && x.length > 1)).subscribe((value: string) => {
            value = value.replace(/-/g, "")
            const almostLast = value.length - 1

            value = value.substring(0, almostLast) + "-" + value.substring(almostLast)

            this.form.controls.rut.setValue(value, { emitEvent: false })
        })
    }

    public close() {
        this.error = false
        this.sent = false
        this.genericModal.close()
    }

    public open() {
        this.openModal.emit()
    }

    save() {
        BaseForm.markFormControlsAsTouched(this.form)
        BaseForm.markFormControlsAsDirty(this.form)

        if (this.form.valid) {
            this.loading = true
            const url = environment.endpoints.base + "/contacto_recuperar_cuenta"

            this.http
                .post(url, {
                    contacto: {
                        ...this.contacto,
                        fecha: new Date().toString()
                    }
                })
                .toPromise()
                .then(response => {
                    this.sent = true

                    setTimeout(() => {
                        this.genericModal.close()
                        this.router.navigate(["/"])
                    }, 5000)
                })
                .catch(() => {
                    this.error = true
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }

    emailChange(value) {
        this.showMessageEmailExists = false
        if (value) {
            this.usuariosService.exists({ usuario: { email: value } }).then((response: any) => {
                if (!response.empty) {
                    this.showMessageEmailExists = true
                }
            })
        }
    }

    goToForgotPassword() {
        this.genericModal.close()
        this.router.navigate(["/usuarios/passwords/forgot"])
    }
}
