import { EvaluacionTiposActions, EvaluacionTiposActionTypes } from "../actions/evaluacion_tipos.actions"

export interface State {
    selectedEvaluacionTipo: string
    evaluacionTipos: string[]
    evaluacionTipoAliases: { [key: string]: string }
}

export const initialState: State = {
    selectedEvaluacionTipo: null,
    evaluacionTipos: [],
    evaluacionTipoAliases: {}
}

export function reducer(state = initialState, action: EvaluacionTiposActions): State {
    switch (action.type) {
        case EvaluacionTiposActionTypes.SetSelectedEvaluacionTipo:
            return { ...state, ...action.payload }

        case EvaluacionTiposActionTypes.SetEvaluacionTipos:
            const payload = action.payload
            const selectedEvaluacionTipo = state.selectedEvaluacionTipo
            const foundEvaluacionTipo = payload.evaluacionTipos.find(et => et == selectedEvaluacionTipo)

            let newObj: any = payload
            if (!foundEvaluacionTipo) {
                newObj = {
                    ...payload,
                    selectedEvaluacionTipo: payload.evaluacionTipos[0]
                }
            }

            return { ...state, ...newObj }

        case EvaluacionTiposActionTypes.SetEvaluacionTipoAliases:
            return { ...state, ...action.payload }

        default:
            return state
    }
}
