import { BaseModel } from "@puntaje/shared/core"

export class Area extends BaseModel {
    public static className: string = "Area"

    public area: string

    public toString() {
        return this.area
    }
}
