import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { SorterComponent } from "./sorter.component"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { SorterGroupComponent } from "./sorter_group.component"

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, FormsModule, Angular2FontawesomeModule],
    declarations: [SorterComponent, SorterGroupComponent],
    exports: [SorterComponent, SorterGroupComponent]
})
export class SorterModule {}
