import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Sede } from "./sedes.model"
import { UniversidadCampoExtra } from "./universidad_campo_extras.model"

export class Universidad extends BaseModel {
    public static className: string = "Universidad"

    public universidad: string
    public logo: string
    public anuncioImgNarrow: string
    public anuncioImgWide: string
    public anuncioLink: string
    public createdAt: Date
    public updatedAt: Date
    public likesUniversidad: number
    public promo: string
    public presentacion: string

    @SaveType(() => Sede) sedes: Sede[]

    @SaveType(() => UniversidadCampoExtra) universidadcampoextras: UniversidadCampoExtra[]

    public toString() {
        return this.universidad
    }
}
