import { Input, Component, Directive } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { AuthService, CheckAchievementAsync, Validations, SessionService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { ReporteRazones, NebuAuthService } from "@puntaje/nebulosa/api-services"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { Login } from "@puntaje/puntaje/services"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { Store } from "@ngrx/store"
import { State, CrearAlertaLogros } from "@puntaje/puntaje/store"
import { CarrerasAuthService } from "@puntaje/carreras/api-services"
import { AppEnv, I18nService } from "@puntaje/shared/core"
declare const environment: AppEnv
import { Subscription } from "rxjs"
import { HttpClient } from "@angular/common/http"
import { configFactory } from "angularx-social-login/sociallogin.module"
@Component({
    selector: "login-base",
    template: ""
})
export class LoginBaseComponent {
    email: string
    password: string
    message: string
    invalidLogin: boolean = false
    loginMessage: boolean = false
    form: UntypedFormGroup
    config: typeof config
    invalidLoginMessage: string
    subLoginMessage: Subscription

    @Input() enablePasswordRecovery: boolean = false
    @Input() enableRegister: boolean = false
    @Input() enableRememberMe: boolean = false
    @Input() enableContactUs: boolean = false
    @Input() contactUsDomId: string = "#"
    @Input() enableFacebookLogin: boolean = false
    @Input() enableGoogleLogin: boolean = true
    @Input() registerButtonType: "secondary" | "cancel" | "table-btn" | "confirm" | "danger" | "primary" = "primary"

    forgot_password_route: string

    captchaRequired: boolean
    captchaSiteKey = environment.recaptcha?.siteKey
    captchaResponse: any
    errorCaptcha: string

    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected nebuAuthService: NebuAuthService,
        protected reporteRazonesService: ReporteRazones,
        protected usuariosService: Usuarios,
        protected simpleModalService: SimpleModalService,
        protected store: Store<State>,
        protected router: Router,
        protected carrerasAuthService: CarrerasAuthService,
        protected loginService: Login,
        protected http: HttpClient,
        protected i18nService: I18nService
    ) {
        this.config = config
        this.i18nService = i18nService
        this.loginService.setMessage()

        const EMAIL_REGEXP =
            /(^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*)|(\b\d{7,10}\-[K|k|0-9])|([0-9]{5}[0-9]+)/

        this.form = formBuilder.group({
            email: ["", [Validators.required, Validators.pattern(EMAIL_REGEXP)]],
            //why in the mother of god is this here?: el registro que hizo la uss no tiene nuestros validators para passwords en cuenta y no es la idea hacer el checkpasswordbreach configurable por plataforma, asi que por eso esta esto.
            password: [
                "",
                Validators.required,
                config.plataforma.name == "PreuUSS" ? null : Validations.checkPasswordBreach(http, true)
            ]
        })

        this.form.valueChanges.subscribe(data => {
            this.invalidLogin = false
        })

        this.subLoginMessage = this.loginService.loginMessageChanged.subscribe(loginMessage => {
            this.loginMessage = loginMessage
        })
    }

    formHasErrors() {
        return this.passwordHasErrors() || this.emailHasErrors() || this.invalidLogin
    }

    checkPassword() {
        return this.form.controls["password"].hasError("required") && this.form.controls["password"].touched
    }

    checkEmail() {
        return (
            (this.form.controls["email"].hasError("required") || this.form.controls["email"].hasError("pattern")) &&
            this.form.controls["email"].touched
        )
    }

    passwordHasErrors() {
        return this.form.controls["password"].hasError("required") && this.form.controls["password"].touched
    }

    emailHasErrors() {
        return (
            (this.form.controls["email"].hasError("required") || this.form.controls["email"].hasError("pattern")) &&
            this.form.controls["email"].touched
        )
    }

    isValidForm() {
        let valid = this.form.valid

        if (this.captchaRequired && !this.captchaResponse) {
            this.errorCaptcha = "Recaptcha requerido."

            valid = false
        }

        return valid
    }

    @CheckAchievementAsync("PNACH1", CrearAlertaLogros)
    login() {
        if (this.checkEmail()) {
            this.setEmailFocus()

            return null
        }

        if (this.checkPassword()) {
            this.setPasswordFocus()

            return null
        }

        if (this.isValidForm()) {
            return this.loginService.loginGeneral(
                this.authService.login(this.email, this.password, false, this.captchaResponse),
                response => {
                    if (response && response.status == 401) {
                        if (response.error && response.error.captcha_required) {
                            this.captchaRequired = false
                            this.captchaResponse = null

                            setTimeout(() => {
                                this.captchaRequired = response.error.captcha_required
                            }, 1)
                        } else {
                            this.setEmailFocus()
                            this.invalidLogin = true
                            const configInvalidLoginMessage =
                                this.i18nService.translate("landing.login.error_auth4", {}) +
                                this.i18nService.translate("landing.login.error_auth5", {})
                            this.invalidLoginMessage = configInvalidLoginMessage
                        }
                    } else if (response && response.inactiveEstablishments) {
                        this.simpleModalService.setModalHeader("Advertencia")
                        this.simpleModalService.setModalHTMLContent(
                            config.mensajesLogin.invalidLoginInactiveEstablishments
                        )
                        if (
                            this.config.app.name == "profesores" &&
                            this.config.mensajesLogin.invalidLoginInactiveEstablishmentsRedirectText
                        ) {
                            this.simpleModalService.setModalCallbackBtnText(
                                this.config.mensajesLogin.invalidLoginInactiveEstablishmentsRedirectText
                            )
                            this.simpleModalService.setModalCallback(() => this.redirectToIfInactiveEstablishments())
                        }
                        this.simpleModalService.showSimpleModal()
                    } else {
                        throw new Error("Error " + response.status)
                    }
                },
                true,
                this.isValidForm()
            )
        } else {
            if (
                this.form.controls["password"].errors?.["breachedPasswordOnLogin"] &&
                !this.form.controls["password"].errors["breachedPasswordOnLogin"].valid
            ) {
                const mail =
                    this.config.plataforma.info?.soporte?.mail ??
                    this.config.plataforma.info?.contacto?.mail ??
                    "contacto@ogr.cl"
                this.invalidLogin = true
                this.invalidLoginMessage = this.i18nService.translate("landing.login.error_auth3", { mail })
                // TODO: why this???
                if (config.plataforma.name == "UST") {
                    this.invalidLoginMessage = this.i18nService.translate("landing.login.error_auth3_UST", {})
                }
            }

            return null
        }
    }

    logout() {
        this.authService.logout()
        this.loginService.setMessage()
    }

    goToRegister(url: string = "/usuarios/registro") {
        this.router.navigate([url])
    }

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }

    redirectToIfInactiveEstablishments() {
        window.location.href = "https://" + environment.domain.alumnos
    }

    setEmailFocus() {}

    setPasswordFocus() {}

    captchaResolved(response) {
        this.captchaResponse = response
        this.errorCaptcha = undefined
    }

    ngOnDestroy() {
        if (this.subLoginMessage) {
            this.subLoginMessage.unsubscribe()
        }
    }
}
