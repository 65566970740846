import {
    Component,
    ViewEncapsulation,
    Directive,
    ContentChildren,
    HostBinding,
    Input,
    ChangeDetectorRef,
    ViewChild
} from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"

@Directive({ selector: "tr[cui-table-header-row]" })
export class CUITableHeaderRowComponent {}

@Directive({ selector: "tr[cui-table-data-row]" })
export class CUITableDataRowComponent {}

@Directive({ selector: "cui-table-no-data" })
export class CUITableNoDataComponent {}

@Component({
    selector: "cui-table",
    templateUrl: "cui-table.component.html",
    styleUrls: ["cui-table.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUITableComponent {
    @Input() stickyHeader: boolean = false
    @Input() verticalOnBreak: number
    @Input() withVerticalLabels: boolean = false
    applyVertical: boolean = false
    @ContentChildren(CUITableDataRowComponent) rows: CUITableDataRowComponent[] = []
    @Input() withPaginator: boolean = true
    @Input() paginatorGetList: (page: number, per: number) => Promise<number>
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    isReady: boolean = false

    constructor(protected cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.isReady = !this.withPaginator
    }

    ngAfterViewInit() {
        if (this.isReady) this.loadingLayout.ready()
    }

    toggleLoading(loading: boolean) {
        if (this.loadingLayout) loading ? this.loadingLayout.standby() : this.loadingLayout.ready()
    }

    adaptToSize(cs: number) {
        if (this.verticalOnBreak) this.applyVertical = cs > 0 && cs <= this.verticalOnBreak
        this.cdr.detectChanges()
    }

    resetPaginator() {
        if (this.paginator) this.paginator.reload()
    }

    changePage(page: number) {
        if (this.paginator) this.paginator.changePage(page)
    }

    autoload(load: boolean) {
        if (this.withPaginator && this.loadingLayout) {
            this.toggleLoading(load)
            this.isReady = !load
        }
    }
}
