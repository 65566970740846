import { Directive, Input, ElementRef, HostListener, HostBinding } from "@angular/core"

/***********************
  Directiva que expande/contrae un elemento expandible al hacer click.
  Requiere indicar el id del elemento a expandir/contraer, o un ElementRef.
  El otro elemento debe tener la directiva ExpandableContentDirective para funcionar.
  Ej:
  <div toggleExpandable="id" openOnlyExpandable="true"></div>

  O también:
  <div toggleExpandable="expandable" openOnlyExpandable="true"></div>
  <div expandableContent #expandable></div>
************************/

@Directive({
    selector: "[toggleExpandable]"
})
export class ToggleExpandableDirective {
    @Input("toggleExpandable") toggleTarget: string | ElementRef | HTMLElement
    @Input("openOnlyExpandable") openOnlyExpandable: boolean = false
    @Input("closeOnlyExpandable") closeOnlyExpandable: boolean = false
    @Input() expandedOnCreation: boolean = false
    @HostBinding("class.expanded") isExpanded: boolean = false
    lockTransition: boolean = false

    constructor(protected el: ElementRef) {}

    @HostListener("keydown.enter", ["$event.target"]) onEnterHandler(event: KeyboardEvent) {
        this.expandOrCollapse()
    }

    @HostListener("keydown.space", ["$event.target"]) onSpaceBarHandler(event: KeyboardEvent) {
        this.expandOrCollapse()
    }

    @HostListener("click", ["$event.target"]) onClick(btn) {
        this.expandOrCollapse()
    }

    ngOnInit() {
        this.isExpanded = this.expandedOnCreation
        this.el.nativeElement.setAttribute("aria-expanded", this.expandedOnCreation + "")

        if (typeof this.toggleTarget == "string") {
            this.el.nativeElement.setAttribute("aria-controls", this.toggleTarget)
        }
    }

    expandOrCollapse() {
        let target = this.getTarget()
        if (!this.lockTransition) {
            this.lockTransition = true
            if (target.getAttribute("data-mutual-exclusive")) {
                let otherTargets = document.querySelectorAll(
                    "[data-mutual-exclusive='" + target.getAttribute("data-mutual-exclusive") + "']"
                )
                for (var i = 0; i < otherTargets.length; i++) {
                    if (otherTargets.item(i).id != this.toggleTarget && otherTargets.item(i).clientHeight) {
                        let subtarget = document.getElementById(otherTargets.item(i).id)
                        subtarget.style.overflow = "hidden"
                        subtarget.style.height = subtarget.scrollHeight + "px"
                        setTimeout(() => {
                            subtarget.style.height = "0px"
                        }, 10)
                    }
                }
            }
            if (target.clientHeight && !this.openOnlyExpandable) {
                target.style.overflow = "hidden"
                target.style.height = target.scrollHeight + "px"
                this.isExpanded = false
                this.el.nativeElement.setAttribute("aria-expanded", "false")

                setTimeout(() => {
                    target.style.height = "0px"
                    this.lockTransition = false
                }, 10)
            } else if (!this.closeOnlyExpandable) {
                target.style.height = target.scrollHeight + "px"
                this.isExpanded = true
                this.el.nativeElement.setAttribute("aria-expanded", "true")
                setTimeout(() => {
                    //target.style.height = "auto";
                    //target.style.overflow = "initial";
                    this.lockTransition = false
                }, 600)
            }
        }
    }

    forceOpen() {
        let target = this.getTarget()
        target.style.height = target.scrollHeight + "px"
        this.isExpanded = true
        setTimeout(() => {
            this.lockTransition = false
        }, 600)
    }

    forceClose() {
        let target = this.getTarget()
        target.style.overflow = "hidden"
        target.style.height = target.scrollHeight + "px"
        this.isExpanded = false
        setTimeout(() => {
            target.style.height = "0px"
            this.lockTransition = false
        }, 10)
    }

    getTarget() {
        let target
        if (typeof this.toggleTarget === "string") {
            target = document.getElementById(this.toggleTarget)
        } else {
            target = this.toggleTarget
        }
        return target
    }
}
