import { Component, Input, Output, EventEmitter } from "@angular/core"
import { GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "generador-instrumento-select",
    templateUrl: "generador_instrumento_select.component.html"
})
export class GeneradorInstrumentoSelectComponent {
    @Input() generadorInstrumento: GeneradorInstrumento
    @Output() generadorInstrumentoChange: EventEmitter<any> = new EventEmitter<any>()

    @Input() hidden: boolean = false
    @Input() showNonVisible: boolean = false

    _generadorInstrumentos: GeneradorInstrumento[]

    constructor(protected generadorInstrumentosService: GeneradorInstrumentos) {}

    @Input()
    get generadorInstrumentos() {
        return this._generadorInstrumentos
    }

    set generadorInstrumentos(gis: GeneradorInstrumento[]) {
        let oldGi = this._generadorInstrumentos
        this._generadorInstrumentos = gis
            .sort((gi1, gi2) => gi1.orden - gi2.orden)
            .filter(gi => gi.visible || this.showNonVisible)

        setTimeout(() => {
            if (
                this.generadorInstrumentos &&
                this.generadorInstrumentos.length == 1 &&
                (!this.generadorInstrumento || (oldGi != null && oldGi != gis))
            ) {
                this.generadorInstrumentoChange.emit(this.generadorInstrumentos[0])
            } else if (
                this.generadorInstrumentos &&
                this.generadorInstrumentos.length > 0 &&
                (!this.generadorInstrumento || (oldGi != null && oldGi != gis))
            ) {
                this.generadorInstrumentoChange.emit(undefined)
            }
        }, 1)
    }

    generadorInstrumentoChanges() {
        this.generadorInstrumentoChange.emit(this.generadorInstrumento)
    }
}
