/** @format */

import { CodigoConfirmacionData } from "./../reducers/registro.reducer"
import { Action } from "@ngrx/store"

export enum RegistroActionTypes {
    SetCodigoConfirmacionData = "[Registro] Set Password Recovery Data",
    ClearCodigoConfirmacionData = "[Registro] Clear Password Recovery Data"
}

export class SetCodigoConfirmacionData implements Action {
    readonly type = RegistroActionTypes.SetCodigoConfirmacionData

    constructor(public payload: { codigoConfirmacionData: CodigoConfirmacionData }) {
        console.log(payload)
    }
}

export class ClearCodigoConfirmacionData implements Action {
    readonly type = RegistroActionTypes.ClearCodigoConfirmacionData
}

export type RegistroActions = SetCodigoConfirmacionData | ClearCodigoConfirmacionData
