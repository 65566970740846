import { es } from "./es"
export const esCl = {
    topmenu: {
        prueba_transicion: "Prueba de Transición",
        pruebas_curriculares: "Pruebas curriculares"
    },
    landing: {
        login: {
            error_auth1:
                "<strong>La combinación de correo electrónico/contraseña o RUT/contraseña es incorrecta.</strong> Las contraseñas de ",
            error_user: "Debes incluir un correo electrónico o RUT válido.",
            user: "Email o RUT"
        }
    }
}
