<el-titulo>
    <ng-container *ngIf="nombreEvaluacion">{{ nombreEvaluacion }}</ng-container>
    <ng-container *ngIf="!nombreEvaluacion">
        {{ tipoInstrumentoAlias | transformInstrumento: "singular" | titlecase }}
    </ng-container>
</el-titulo>

<div class="eval-not-yet" *ngIf="evaluacionNoDisponible">
    <div class="eval-not-yet-inner">
        <div class="eval-not-yet-icon">
            <span class="glyphicon glyphicon-time" aria-hidden="true"></span>
        </div>
        <div class="eval-not-yet-text">
            {{
                "ensayos.realizar.no_disponible"
                    | t
                        : {
                              fechaInicio: (errorEvalNoDisponible.fechaInicio | date: "dd/MM/yyyy HH:mm"),
                              fechaTermino: (errorEvalNoDisponible.fechaTermino | date: "dd/MM/yyyy HH:mm")
                          }
            }}
        </div>
    </div>
</div>

<div class="eval-not-yet" *ngIf="unpermitted">
    <div class="eval-not-yet-inner">
        <div class="eval-not-yet-icon">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        </div>
        <div class="eval-not-yet-text">
            {{ "ensayos.realizar.sin_permiso" | t }}
        </div>
    </div>
</div>

<div *ngIf="!checkingEvaluacion && !evaluacionNoDisponible">
    <ng-container *ngIf="!checkingEvaluacion">
        <el-contenido-lateral>
            <cui-widget-zone>
                <widget-evaluacion-tiempo
                    *ngIf="!enableSecuencial"
                    [invisibleClock]="!evaluacion || evaluacion.instrumento.tiempo == 0"
                ></widget-evaluacion-tiempo>
                <widget-evaluacion-menu-preguntas
                    *ngIf="evaluacion && !evaluacion.instrumento.propio"
                ></widget-evaluacion-menu-preguntas>
                <widget-responder-evaluacion-propia
                    *ngIf="evaluacion && evaluacion.instrumento.propio"
                    [firstElement]="evaluacion.instrumento.tiempo == 0"
                    [animateOnStartTrigger]="showPreguntasMenu"
                ></widget-responder-evaluacion-propia>
            </cui-widget-zone>
            <smowl-frame
                *ngIf="enableSmowlFrame"
                [courseName]="'eval01'"
                [modality]="'test'"
                [lang]="'es'"
                [courseContainer]="'anuies01'"
                [disablePhotoCapture]="false"
            ></smowl-frame>
        </el-contenido-lateral>
    </ng-container>

    <preguntas-ensayo
        #preguntasEnsayo
        *ngIf="!enableSecuencial && !checkingEvaluacion"
        [evaluacionId]="evaluacionId"
        [tipoEvaluacion]="tipoEvaluacion"
        [enableTimeframeAlert]="true"
        [enableEntregarGeneric]="enableEntregarGeneric"
        [disableWarningOmitidas]="disableWarningOmitidas"
        [disableOmitida]="disableOmitida"
        [tipoInstrumentoAlias]="tipoInstrumentoAlias"
    ></preguntas-ensayo>

    <ng-container *ngIf="enableSecuencial && !checkingEvaluacion">
        <el-topside>
            <div class="fix-position">
                <widget-evaluacion-tiempo
                    [invisibleClock]="!evaluacion || evaluacion.instrumento.tiempo == 0"
                    [enableToggle]="true"
                    [enableFixed]="false"
                    [animateOnStart]="true"
                    [collapseOnMobile]="true"
                ></widget-evaluacion-tiempo>
            </div>
        </el-topside>

        <progreso-preguntas-evaluacion-secuencial [class]="theme"></progreso-preguntas-evaluacion-secuencial>

        <evaluacion-secuencial
            *ngIf="enableSecuencial"
            [evaluacionId]="evaluacionId"
            [enableBtnMarkAsUncertain]="enableBtnMarkAsUncertain"
            [isFromPlanPersonal]="isFromPlanPersonal"
            (onReadyEvaluacion)="onReadyEvaluacion($event)"
            (evaluacionEntregada)="onEvaluationSent($event)"
            [ngClass]="{ 'theme-cb': theme === 'theme-cb', 'with-instructions': theme === 'theme-cb' }"
        ></evaluacion-secuencial>
    </ng-container>
</div>
