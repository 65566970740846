import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Area } from "./areas.model"

export class PreferenciaArea extends BaseModel {
    public static className: string = "PreferenciaArea"

    public plataformaId: number
    public areaId: number
    public usuarioId: number
    public orden: number
    public _destroy?: boolean
    @SaveType(() => Area) public area?: Area
}
