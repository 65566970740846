import { Component, ViewChild, EventEmitter, Output } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Usuarios, Usuario, EvaluacionInstancias } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Store } from "@ngrx/store"
import { State, selectAsignaturasList } from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { CarrerasPonderaciones } from "../carreras-ponderaciones.service"
import { Subscription } from "rxjs"

@Component({
    selector: "mis-puntajes-promedio-carreras",
    templateUrl: "mis-puntajes-promedio-carreras.component.html",
    styleUrls: ["mis-puntajes-promedio-carreras.component.scss"]
})
export class MisPuntajesPromedioCarrerasComponent {
    ponderables: any = []

    msj: string =
        "Este puntaje se calcula promediando tus 3 últimos ensayos de cada materia y considerando las ponderaciones de la carrera."

    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    usuario: Usuario
    editing: boolean = false
    usuarioPaisString: string = `usuario_${config.plataforma.pais}`
    promedioByPonderable: any = {}
    simulacionByPonderable = {}

    asignaturas$ = this.store.select(selectAsignaturasList).pipe(
        filter(x => !!x),
        first()
    )

    sub: Subscription

    constructor(
        protected usuariosService: Usuarios,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected authService: AuthService,
        protected store: Store<State>,
        protected carrerasPonderaciones: CarrerasPonderaciones
    ) {}

    async ngOnInit() {
        this.loadingLayout.standby()

        await this.carrerasPonderaciones.loadVariables()
        this.usuario = this.carrerasPonderaciones.usuario
        this.ponderables = this.carrerasPonderaciones.ponderables
        this.promedioByPonderable = this.carrerasPonderaciones.promedioByPonderable
        this.carrerasPonderaciones.simulacionByPonderable$.subscribe(v => {
            this.simulacionByPonderable = v
        })

        this.loadingLayout.ready()
    }

    updateSimulacion($event, p) {
        this.carrerasPonderaciones.updatePonderacion(p.ponderable, $event.target.value)
    }

    saveUsuario() {
        const usuario = new Usuario(true)
        usuario.id = this.usuario.id

        usuario[this.usuarioPaisString] = new Usuario[this.usuarioPaisString]()
        usuario[this.usuarioPaisString].id = this.usuario[this.usuarioPaisString].id
        const ingresables = this.ponderables.filter(p => p.libre)

        ingresables.forEach(i => {
            usuario[this.usuarioPaisString][i.campoOrigen] = Math.max(
                i.valorMinimo,
                Math.min(i.valorMaximo, this.usuario[this.usuarioPaisString][i.campoOrigen])
            )
        })

        this.usuariosService.update(this.usuario.id, usuario).then(u => {
            this.carrerasPonderaciones.updateUsuario(usuario)
            this.editing = false
        })
    }
}
