import { Component } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "forgot_password.component.html"
})
export class ForgotPasswordComponent {
    useIdentificador = true
    subData: Subscription

    constructor(protected route: ActivatedRoute, protected titleService: TitleService) { }

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.password")
        this.subData = this.route.data.subscribe(data => {
            this.useIdentificador = data.useIdentificador ?? this.useIdentificador
        })
    }

    ngOnDestroy() {
        this.subData.unsubscribe()
    }
}
