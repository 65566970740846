import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()
export class RegisterStepsService {
    steps: any[] = [
        { icono: "user", titulo: "Datos personales", status: RegisterStepStatus.Default },
        { icono: "key", titulo: "Datos de ingreso", status: RegisterStepStatus.Pending },
        { icono: "graduation-cap", titulo: "Datos académicos", status: RegisterStepStatus.Pending }
    ]
    currentIndex: number = 0
    currentIndexSubject = new Subject<any>()
    currentStepsSubject = new Subject<any>()
    validateAndGoToStepSubject = new Subject<any>()

    constructor() {}

    goToStep(index: number) {
        if (index >= 0 && index < this.steps.length) {
            this.currentIndex = index
            this.currentIndexSubject.next(this.currentIndex)
            if (this.steps[this.currentIndex].status == RegisterStepStatus.Pending) {
                this.steps[this.currentIndex].status = RegisterStepStatus.Default
                this.currentStepsSubject.next(this.steps)
            }
        }
    }

    goToStepIfValid(index: number) {
        if (this.steps[this.currentIndex].status != RegisterStepStatus.Error) this.goToStep(index)
    }

    validateAndGoToStep(index: number) {
        this.validateAndGoToStepSubject.next(index)
    }

    updateStepStatus(status: RegisterStepStatus, index: number) {
        this.steps[index].status = status
        this.currentStepsSubject.next(this.steps)
    }
}

export enum RegisterStepStatus {
    Pending = 1,
    Default = 2,
    Error = 3,
    Ready = 4
}
