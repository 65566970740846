import { BaseModel, SaveType } from "@puntaje/shared/core"
import { GeneradorInstrumento } from "./generador_instrumentos.model"
import { ClasificacionTipo } from "./clasificacion_tipos.model"

export class GeneradorInstrumentoFiltroClasificacionTipo extends BaseModel {
    public static className: string = "GeneradorInstrumentoFiltroClasificacionTipo"
    public generador_instrumento_id: number
    public clasificacion_tipo_id: number
    public clasificacion_tipo_hija_id: number

    @SaveType(() => GeneradorInstrumento) public generador_instrumento: GeneradorInstrumento
    @SaveType(() => ClasificacionTipo) public clasificacion_tipo: ClasificacionTipo
    @SaveType(() => ClasificacionTipo) public clasificacion_tipo_hija: ClasificacionTipo
}
