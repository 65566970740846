import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()
export class PostsBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    constructor() {}

    search(post) {
        let params: any = {}

        post = Object.assign({}, post)

        Object.keys(post).forEach(k => {
            if (post[k] === "" || post[k] === undefined) delete post[k]
        })

        if (post.id && post.id.indexOf(",") != -1) {
            post.id = post.id.split(/\s*,\s*/)
        }

        if (post.titulo) {
            post.like = { post: post.titulo }
            delete post.titulo
        }

        params = {
            post
        }

        this.searchParams.next(params)
    }
}
