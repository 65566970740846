import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "dudas_categorias.component.html"
})
export class DudasCategoriasComponent {
    constructor(protected titleService: TitleService, protected loggedLayoutService: LoggedLayoutService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.dudas.duda_categorias")
        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }
}
