import { Directive, Input, ElementRef, HostListener, Output, EventEmitter } from "@angular/core"

/***********************
  Directiva que contrae un elemento (con css).
  Para usarse en conjunto con ToggleExpandableDirective. Se debe definir un id también.
  Ej:
  <div id="id" expandableContent></div>
************************/

@Directive({
    selector: "[expandableContent]",
    exportAs: "expandableContent"
})
export class ExpandableContentDirective {
    @Input("expandableContent") expandable: boolean | string = false
    @Input("expandOnCreation") expandOnCreation: boolean = false
    @Input("autoOnTransitionEnd") autoOnTransitionEnd: boolean = true
    @Output("onExpandStart") onExpandStart = new EventEmitter()
    @Output("onExpandEnd") onExpandEnd = new EventEmitter()

    constructor(protected el: ElementRef) {
        el.nativeElement.style.height = "0px"
        el.nativeElement.style.overflow = "hidden"
        el.nativeElement.style.MozTransition = "height .5s"
        el.nativeElement.style.MsTransition = "height .5s"
        el.nativeElement.style.OTransition = "height .5s"
        el.nativeElement.style.WebkitTransition = "height .5s"
        el.nativeElement.style.transition = "height .5s"
    }

    expanded: boolean

    _visibility: boolean
    @Input()
    set visibility(v: boolean) {
        this._visibility = v
    }

    @HostListener("transitionend", ["$event"]) onTransitionEnd($event) {
        if ($event.propertyName == "height" && this.el.nativeElement.style.height != 0 && !this.expanded) {
            if (this.autoOnTransitionEnd) {
                this.el.nativeElement.style.height = "auto"
                this.el.nativeElement.style.overflow = "initial"
            }
            this.expanded = true
            this.onExpandStart.emit()
        } else if ($event.propertyName == "height") {
            this.expanded = false
            this.onExpandEnd.emit()
        }
    }

    ngAfterViewInit() {
        if (this.expandOnCreation) {
            this.expanded = true

            if (this.autoOnTransitionEnd) {
                this.el.nativeElement.style.height = "auto"
            } else {
                this.el.nativeElement.style.height = this.el.nativeElement.scrollHeight + "px"
            }
        }
    }

    ngAfterViewChecked() {
        if (this._visibility === undefined || this.expanded) return

        if (this._visibility) {
            this.el.nativeElement.style.visibility = "visible"
            this.el.nativeElement.style.height = this.el.nativeElement.scrollHeight + "px"
        } else {
            this.el.nativeElement.style.visibility = "hidden"
            this.el.nativeElement.style.height = "0px"
        }
    }
}
