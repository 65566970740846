<loading-layout #loadingLayout>
    <cui-section-headline class="section-title">{{ "dudas.duda_respuestas.titulo" | t }}</cui-section-headline>
    <div class="duda-respuestas-container">
        <duda-respuesta
            *ngIf="mejorDudaRespuesta"
            [dudaRespuesta]="mejorDudaRespuesta"
            [enableAcceptAnswer]="enableAcceptAnswer"
            [duda]="duda"
            (updateRespuesta)="aceptarRespuesta()"
        ></duda-respuesta>
        <ng-container *ngFor="let dudaRespuesta of dudaRespuestas">
            <duda-respuesta
                [dudaRespuesta]="dudaRespuesta"
                [enableAcceptAnswer]="enableAcceptAnswer"
                [duda]="duda"
                (updateRespuesta)="aceptarRespuesta()"
            ></duda-respuesta>
        </ng-container>

        <p class="not-found" *ngIf="dudaRespuestas?.length == 0 && !mejorDudaRespuesta">
            {{ "dudas.duda_respuestas.no_hay_respuestas" | t }}
        </p>
    </div>
</loading-layout>
