import { Directive, ViewContainerRef, ContentChild, ViewChild, ChangeDetectorRef } from "@angular/core"

@Directive()
export class ViewLayout {
    @ContentChild("contentComponent", /* TODO: add static flag */ { read: ViewContainerRef })
    contentComponent: any

    @ViewChild("viewLabel", /* TODO: add static flag */ { read: ViewContainerRef })
    viewLabel: ViewContainerRef
    @ViewChild("viewValue", { read: ViewContainerRef, static: true }) viewValue: ViewContainerRef

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.addViews()
    }

    addViews() {
        if (this.contentComponent) {
            this.viewLabel && this.viewLabel.createEmbeddedView(this.contentComponent.viewLabel)
            this.viewValue && this.viewValue.createEmbeddedView(this.contentComponent.viewValue)

            this.cdr.detectChanges()
        }
    }
}

@Directive({ selector: "view-label, view-value" })
export class ViewTags {}
