import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Instrumento } from "./instrumentos.model"
import { EvaluacionTipo } from "./evaluacion_tipos.model"
import { EvaluacionUsuario } from "./evaluacion_usuarios.model"
import { EvaluacionInstancia } from "./evaluacion_instancias.model"
import { EvaluacionForma } from "./evaluacion_formas.model"
import { EvaluacionEvaluacion } from "./evaluacion_evaluaciones.model"
import { EvaluacionMultiples } from "./evaluacion_multiples.service"
import { EvaluacionMultiple } from "./evaluacion_multiples.model"
import { EscalaGlobalEvaluacion } from "./escala_global_evaluaciones.model"

export class Evaluacion extends BaseModel {
    public static className: string = "Evaluacion"

    evaluacion: string
    fecha_inicio: string | Date
    fecha_termino: string | Date
    fecha_mostrar_respuestas: string | Date
    instrumento_id: number
    evaluacion_tipo_id: number
    presencial: boolean
    interactivo: boolean
    tarea: boolean
    usada_en_planificacion: boolean
    created_at: Date
    updated_at: Date
    debilidades: boolean
    compartido_por: string
    sumativa: boolean

    @SaveType(() => Instrumento) instrumento: Instrumento
    @SaveType(() => EvaluacionUsuario) evaluacion_usuarios: EvaluacionUsuario[] = []
    @SaveType(() => EvaluacionTipo) evaluacion_tipo: EvaluacionTipo
    @SaveType(() => EvaluacionInstancia) evaluacion_instancias: EvaluacionInstancia[]
    @SaveType(() => EvaluacionForma) evaluacion_formas: EvaluacionForma[]
    @SaveType(() => EvaluacionEvaluacion) evaluacion_evaluaciones: EvaluacionEvaluacion[]
    @SaveType(() => EvaluacionMultiple) evaluacion_multiples: EvaluacionMultiples[]
    @SaveType(() => EscalaGlobalEvaluacion) escala_global_evaluaciones: EscalaGlobalEvaluacion[]

    public toString() {
        return this.evaluacion
    }

    public toStringWithId() {
        return `${this.evaluacion} #${this.id}`
    }

    public isAvailable() {
        const today = new Date()

        if (!this.fecha_inicio && !this.fecha_termino) {
            return true
        }

        if (!this.fecha_inicio) {
            const fechaTermino = new Date(this.fecha_termino)
            return fechaTermino.getTime() >= today.getTime()
        }

        if (!this.fecha_termino) {
            const fechaInicio = new Date(this.fecha_inicio)
            return fechaInicio.getTime() <= today.getTime()
        }

        const fechaInicio = new Date(this.fecha_inicio)
        const fechaTermino = new Date(this.fecha_termino)

        return fechaInicio.getTime() <= today.getTime() && fechaTermino.getTime() >= today.getTime()
    }
}
