<ng-container *ngIf="items$ | async as items">
    <div *ngIf="items.length != 0" class="wrap">
        <div class="progress-wheel">
            <div class="gauge-container">
                <div
                    class="progress-circle p{{ progreso$ | async | roundfloat: 0:true }}"
                    [class.over50]="+(progreso$ | async | roundfloat: 0) > 50"
                >
                    <span>{{ progreso$ | async | roundfloat: 0 }}%</span>
                    <div class="left-half-clipper">
                        <div class="first50-bar"></div>
                        <div class="value-bar"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="listado-container" #listaBox>
            <ul class="listado-preguntas clearfix">
                <ng-container *ngFor="let item of items; let i = index">
                    <li class="separador-grupo-ini" *ngIf="item.iniGrupoPregunta"></li>
                    <li
                        class="numero-pregunta"
                        [class.ini-grupo-pregunta]="item.iniGrupoPregunta"
                        [class.end-grupo-pregunta]="item.endGrupoPregunta"
                        #preguntaBox
                    >
                        <span class="sr-only" *ngIf="item.iniGrupoPregunta">Inicio grupo pregunta</span>
                        <ng-container [ngSwitch]="item.tipo">
                            <ng-container *ngSwitchCase="'pregunta'">
                                <a
                                    [attr.aria-current]="true"
                                    attr.aria-label="pregunta {{ item.numero + 1 }}"
                                    [class.seeing]="(indexActual$ | async) == i"
                                    [class.answered]="(answered$ | async)[item.numero]"
                                    [class.duda]="item.duda"
                                    (click)="changeItem(i)"
                                    href="javascript:void(0)"
                                    id="pregunta_numero_{{ item.numero }}"
                                >
                                    {{ item.numero + 1 }}
                                    <div *ngIf="(indexActual$ | async) == i" class="texto_actual sr-only">Actual</div>
                                    <fa name="flag" class="duda-icon" *ngIf="item.duda"></fa>
                                    <span class="sr-only" *ngIf="item.duda">
                                        Pregunta {{ item.numero + 1 }} marcada para revisión
                                    </span>
                                </a>
                            </ng-container>
                            <ng-container *ngSwitchCase="'instruccion'">
                                <a
                                    [class.seeing]="(indexActual$ | async) == i"
                                    (click)="changeItem(i)"
                                    href="javascript:void(0)"
                                    aria-label="información instruccion"
                                >
                                    <fa name="info"></fa>
                                </a>
                            </ng-container>
                            <ng-container *ngSwitchCase="'confirmacion'">
                                <a
                                    [class.seeing]="(indexActual$ | async) == i"
                                    (click)="changeItem(i)"
                                    href="javascript:void(0)"
                                    aria-label="información - sección completa"
                                >
                                    <fa name="info"></fa>
                                </a>
                            </ng-container>
                        </ng-container>
                    </li>
                    <li class="separador-grupo-end" *ngIf="$any(item).endGrupoPregunta">
                        <span class="sr-only" *ngIf="$any(item).endGrupoPregunta">Fin grupo pregunta</span>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</ng-container>
