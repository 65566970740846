import { Component, OnInit, ViewChild, Input, SimpleChanges, ChangeDetectorRef, OnChanges } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { EvaluacionInstancia, Usuario, Evaluacion, GrupoUsuario } from "@puntaje/puntaje/api-services"
import { BaseChart, ColumnChart, I18nService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
import { Store, select } from "@ngrx/store"
import { State, selectSelectedAsignatura } from "@puntaje/puntaje/store"
import { Subscription, combineLatest } from "rxjs"
import { first } from "rxjs/operators"
declare const config: AppConfig

// var Highcharts = require('highcharts');

/*********************************
Gráfico de barras de distribución de ensayos por tramo de puntaje para una asignatura.
Recibe data: number[]
ej: [96, 81, 91, 155, 100, 0, 0]
**********************************/

declare var Highcharts: any

@Component({
    selector: "grafico-distribucion-tramo-puntaje",
    template: `
        <loading-layout #loadingLayout>
            <ng-container *ngIf="hasData">
                <estadisticas-grafico [grafico]="grafico" *ngIf="!loadingLayout.loading"></estadisticas-grafico>

                <div *ngIf="usuarios && !loadingLayout.loading" class="clearfix">
                    <h4>{{ "estadisticas.graficos.dtp.title" | t }}</h4>
                    <div class="row">
                        <div *ngFor="let tramo of tramos; let i = index" class="col-md-4">
                            <div class="columna-tramo" [class.print-hide]="dataTramos[zonas[i]].length == 0">
                                <p class="titulo-tramo" toggleExpandable="tramo-{{ i }}">
                                    <span class="glyphicon glyphicon-minus-sign expanded-sign print-hide"></span>
                                    <span class="glyphicon glyphicon-plus-sign contracted-sign print-hide"></span>
                                    {{ tramo }}
                                    <span class="cantidad-tramo">({{ dataTramos[zonas[i]].length }})</span>
                                </p>
                                <div id="tramo-{{ i }}" [expandableContent] class="print-show-block">
                                    <div class="inner-tramo">
                                        <table *ngIf="dataTramos[zonas[i]].length > 0" class="table tabla-tramos">
                                            <thead>
                                                <tr>
                                                    <th>{{ "estadisticas.graficos.dtp.alumno" | t }}</th>
                                                    <th class="min-col">
                                                        {{ "estadisticas.graficos.dtp.puntaje" | t }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let d of dataTramos[zonas[i]]">
                                                    <td>{{ d.nombre }}</td>
                                                    <td class="calificacion min-col">
                                                        {{ d.calificacion | number: "1.0-2" }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p class="tramo-vacio" *ngIf="dataTramos[zonas[i]].length == 0">
                                            {{ "estadisticas.graficos.dtp.not_found" | t }}.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </loading-layout>
    `,
    styleUrls: ["grafico_distribucion_tramo_puntaje.component.scss"]
})
export class GraficoDistribucionTramoPuntajeComponent implements OnInit, OnChanges {
    @Input() asignatura: Asignatura
    @ViewChild("loadingLayout", { static: true })
    loadingLayout: LoadingLayoutComponent
    @Input() data: { [key: number]: number }
    grafico: BaseChart
    enableGraph: boolean = false
    @Input() tipoGrupo: string = ""
    nombreEvaluacion: string = "Evaluación"
    nombreEvaluacionPlural: string = "Evaluaciones"
    nombreEvaluacionSimple: string = "evaluación"
    nombreEvaluacionSimplePlural: string = "evaluaciones"
    @Input() tipoEvaluacion: string
    tramos: string[] = []
    zonas: number[]
    descripcionRangos: string[]
    dataTramos: any = {}
    dataByIndexTramo: number[] = []
    @Input() evaluacionInstancias: EvaluacionInstancia[]
    @Input() evaluacion: Evaluacion
    @Input() usuarios: Usuario[]
    @Input() grupoUsuarios: GrupoUsuario[]
    @Input() nombreEjeY: string = "Número de estudiantes"
    @Input() nombreLeyenda: string = "Número de estudiantes por tramo"
    config: typeof config
    escalaEval: string = "puntaje"
    subQuery: Subscription

    hasData: boolean = true

    asignatura$ = this.store.pipe(select(selectSelectedAsignatura))

    constructor(
        protected cdr: ChangeDetectorRef,
        private translatorService: I18nService,
        protected store: Store<State>
    ) {
        this.config = config
    }

    ngOnInit() {
        this.subQuery = combineLatest([this.asignatura$]).subscribe(([asignatura]) => {
            this.asignatura = asignatura
            this.setGrafico()
        })
    }

    setEscalaEval() {
        if (
            config.evaluaciones[this.tipoEvaluacion] &&
            (config.evaluaciones[this.tipoEvaluacion] as any).escalaEvaluacion
        ) {
            this.escalaEval = (config.evaluaciones[this.tipoEvaluacion] as any).escalaEvaluacion
        }
    }

    setTramos() {
        switch (this.tipoEvaluacion) {
            case "simce":
                this.tramos = ["Insuficiente", "Elemental", "Adecuado"]
                this.zonas = [0, 33.4, 66.7]
                const zonasByGenerador = {
                    "Lenguaje 4º SIMCE": [100, 245, 283],
                    "Lenguaje 6ºB SIMCE": [100, 233, 287],
                    "Lenguaje 2ºM SIMCE": [100, 252, 319],
                    "Matemática 4º SIMCE": [100, 245, 295],
                    "Matemática 6ºB SIMCE": [100, 233, 286],
                    "Matemática 2ºM SIMCE": [100, 255, 303]
                }
                // TODO: cuando se pase a react, mejorar esto (solucion general y ver si se usa traduccion o no)
                const descripcionRangosByGenerador = {
                    "Lenguaje 4º SIMCE": "Insuficiente (100 a 237), elemental (245-279) y adecuado (283-344)",
                    "Lenguaje 6ºB SIMCE": "Insuficiente (100 a 225), elemental (233-279) y adecuado (287-346)",
                    "Lenguaje 2ºM SIMCE": "Insuficiente (100 a 247), elemental (255-298) y adecuado (303-346)",
                    "Matemática 4º SIMCE": "Insuficiente (100 a 237), elemental (245-289) y adecuado (295-353)",
                    "Matemática 6ºB SIMCE": "Insuficiente (100 a 226), elemental (233-279) y adecuado (286-346)",
                    "Matemática 2ºM SIMCE": "Insuficiente (100 a 244), elemental (252-312) y adecuado (319-350)"
                }

                const generador = this.evaluacion?.instrumento?.generador_instrumento["generador_instrumento"]

                this.descripcionRangos =
                    descripcionRangosByGenerador[
                        this.evaluacion?.instrumento?.generador_instrumento["generador_instrumento"]
                    ]

                if (zonasByGenerador[generador]) {
                    this.zonas = zonasByGenerador[generador]
                } else if (
                    this.evaluacion &&
                    this.evaluacion.evaluacion_usuarios &&
                    this.evaluacion.evaluacion_usuarios.length > 0 &&
                    this.evaluacion.evaluacion_usuarios[0].grupo_usuario &&
                    config.evaluaciones[this.tipoEvaluacion].distribucionTramoPuntaje
                ) {
                    if (
                        config.evaluaciones[this.tipoEvaluacion].distribucionTramoPuntaje[this.asignatura.asignatura] &&
                        config.evaluaciones[this.tipoEvaluacion].distribucionTramoPuntaje[this.asignatura.asignatura][
                            this.evaluacion.evaluacion_usuarios[0].grupo_usuario.curso_id
                        ]
                    ) {
                        this.zonas =
                            config.evaluaciones[this.tipoEvaluacion].distribucionTramoPuntaje[
                                this.asignatura.asignatura
                            ][this.evaluacion.evaluacion_usuarios[0].grupo_usuario.curso_id]
                    }
                }
                break

            case "ensayoPSU":
                this.tramos = ["0-349", "350-449", "450-549", "550-649", "650-749", "750-849", "850"]
                this.zonas = [0, 350, 450, 550, 650, 750, 850]
                break

            case "saber":
                this.tramos = [
                    "0-99",
                    "100-149",
                    "150-199",
                    "200-249",
                    "250-299",
                    "300-349",
                    "350-399",
                    "400-499",
                    "500"
                ]
                this.zonas = [0, 100, 150, 200, 250, 300, 350, 400, 500]
                break

            case "saber11":
                this.tramos = ["0-19", "20-39", "40-59", "60-79", "80-99", "100"]
                this.zonas = [0, 20, 40, 60, 80, 100]
                break

            case "simulacro saber11":
                this.tramos = ["0-99", "100-199", "200-299", "300-399", "400-499", "500"]
                if (this.asignatura) {
                    this.tramos = ["0-19", "20-39", "40-59", "60-79", "80-99", "100"]
                    this.zonas = [0, 20, 40, 60, 80, 100]
                }
                this.zonas = [0, 100, 200, 300, 400, 500]
                break

            case "curricular":
                if (config.plataforma.name == "zero") {
                    this.tramos = ["0-19", "20-39", "40-59", "60-79", "80-99", "100"]
                    this.zonas = [0, 20, 40, 60, 80, 100]
                } else {
                    this.tramos = ["1-1,99", "2-2,99", "3-3,99", "4-4,99", "5-5,99", "6-6,99", "7"]
                    this.zonas = [1, 2, 3, 4, 5, 6, 7]
                }
                break

            case "curricular colombia":
            case "curricular mexico":
                this.tramos = ["0-19", "20-39", "40-59", "60-79", "80-99", "100"]
                this.zonas = [0, 20, 40, 60, 80, 100]
                break

            case "Enem":
                this.tramos = ["0-19", "20-39", "40-59", "60-79", "80-99", "100"]
                this.zonas = [0, 20, 40, 60, 80, 100]
                break

            case "Saber Pro":
                this.tramos = ["0-99", "100-199", "200-299", "300"]
                this.zonas = [0, 100, 200, 300]
                break

            default:
                this.tramos = ["100-249", "250-399", "400-549", "550-699", "700-849", "850-999", "1000"]
                this.zonas = [100, 250, 400, 550, 700, 850, 1000]
                break
        }
    }

    groupEvaluacionesInstancias() {
        if (this.usuarios && this.evaluacionInstancias) {
            this.zonas.forEach((n, i) => {
                let eis = this.evaluacionInstancias.filter(
                    ei => ei.calificacion >= n && (!this.zonas[i + 1] || ei.calificacion < this.zonas[i + 1])
                )
                if (this.tipoEvaluacion === "simce") {
                    this.dataByIndexTramo[i] = eis.length
                }
                eis = eis.sort((e1, e2) => e1.calificacion - e2.calificacion)

                this.dataTramos[n] = eis.map(ei => {
                    let d: any = {}
                    let usuario = this.usuarios.find(u => ei.usuario_id == u.id)

                    d.nombre = usuario.nombreCompleto()
                    d.calificacion = ei.calificacion

                    return d
                })
            })
        }
    }

    groupParaSimce() {
        if (!this.usuarios) {
            this.zonas.forEach((n, i) => {
                let eis = this.evaluacionInstancias.filter(
                    ei => ei.calificacion >= n && (!this.zonas[i + 1] || ei.calificacion < this.zonas[i + 1])
                )
                this.dataByIndexTramo[i] = eis.length
            })
        }
    }

    setDataByIndexTramo() {
        this.dataByIndexTramo = new Array(this.tramos.length).fill(0)
        const zonasReverse = this.zonas.slice(0).reverse()
        Object.keys(this.data).forEach(calificacion => {
            const calificacionNumber = +calificacion.replace(",", ".")
            const indexReverse = zonasReverse.findIndex(value => calificacionNumber >= value)
            const index = this.zonas.length - indexReverse - 1
            this.dataByIndexTramo[index] += this.data[calificacion]
        })
    }

    setGrafico() {
        if (!this.data) {
            this.data = {}
        }

        this.hasData = Object.keys(this.data).length > 0

        this.loadingLayout.standby()
        this.setEscalaEval()
        this.setTramos()
        this.setDataByIndexTramo()
        this.groupEvaluacionesInstancias()

        const dataSum = Object.keys(this.data).reduce((acc, calificacion) => acc + this.data[calificacion], 0)

        const g1: any = {}
        g1.ejeX = this.translatorService.translate("progreso.estadisticas.graficos_asignatura.distr_tramos.eje_x", {
            escala: this.escalaEval
        })
        g1.categorias = this.tramos
        g1.ejeY = this.nombreEjeY
        g1.data = [{ nombre: this.nombreLeyenda, data: this.dataByIndexTramo }]
        g1.leyenda = {
            layout: "vertical",
            align: "right",
            verticalAlign: "top",
            y: 30,
            floating: true
        }

        let sufix = ""
        if (this.asignatura) {
            const para = this.translatorService.translate("progreso.estadisticas.graficos_asignatura.distr_tramos.para")
            let confEvaluacionTipo = this.asignatura["confByEvaluacionTipo"]?.[this.tipoEvaluacion]
            if (!confEvaluacionTipo) {
                confEvaluacionTipo = this.asignatura["confByEvaluacionTipo"]?.[config.plataforma.evaluacionDefault]
            }

            sufix = " " + para + " " + (confEvaluacionTipo?.abreviacion ?? this.asignatura.asignatura)
        }

        const titulo = this.translatorService.translate("progreso.estadisticas.graficos_asignatura.distr_tramos.titulo")

        g1.titulo = titulo + this.escalaEval + sufix
        if (this.tipoEvaluacion === "simce") {
            g1.descripcion = this.translatorService.translate(
                "progreso.estadisticas.graficos_asignatura.distr_tramos.description_with_ranges",
                {
                    evaluaciones: this.nombreEvaluacionSimplePlural,
                    escala: this.escalaEval,
                    rangos: this.descripcionRangos
                }
            )
        } else {
            g1.descripcion = this.translatorService.translate(
                "progreso.estadisticas.graficos_asignatura.distr_tramos.descripcion",
                { evaluaciones: this.nombreEvaluacionSimplePlural, escala: this.escalaEval }
            )
        }

        // TODO: Revisar si en verdad es necesario este switch
        // switch (this.tipoGrupo) {
        //     case "alumnos":
        //         g1.titulo = titulo + this.escalaEval + sufix
        //         g1.descripcion = this.translatorService.translate(
        //             "progreso.estadisticas.graficos_asignatura.distr_tramos.descripcion",
        //             { evaluaciones: this.nombreEvaluacionSimplePlural, escala: this.escalaEval }
        //         )
        //         break
        //     case "cursos":
        //         g1.titulo = titulo + this.escalaEval + sufix
        //         g1.descripcion = this.translatorService.translate(
        //             "progreso.estadisticas.graficos_asignatura.distr_tramos.descripcion",
        //             { evaluaciones: this.nombreEvaluacionSimplePlural, escala: this.escalaEval }
        //         )
        //         break
        //     default:
        //         g1.titulo = titulo + this.escalaEval + sufix
        //         g1.descripcion = this.translatorService.translate(
        //             "progreso.estadisticas.graficos_asignatura.distr_tramos.descripcion",
        //             { evaluaciones: this.nombreEvaluacionSimplePlural, escala: this.escalaEval }
        //         )
        //         break
        // }
        g1.nombreSerie = "Ensayos"
        g1.formatter = function () {
            const pcnt = (this.y / dataSum) * 100

            return Highcharts.numberFormat(pcnt, 0) + "%"
        }
        this.grafico = new ColumnChart(g1)
        this.cdr.detectChanges()
        this.loadingLayout.ready()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignatura"] || changes["data"]) {
            this.setGrafico()
        }
    }

    ngOnDestroy() {
        this.subQuery.unsubscribe()
    }
}
