import { Component, Input, OnInit } from "@angular/core"
import { EscalaGlobal } from "@puntaje/puntaje/api-services"

@Component({
    selector: "evaluacion-escalas-globales",
    templateUrl: "./evaluacion-escalas-globales.component.html",
    styleUrls: ["./evaluacion-escalas-globales.component.scss"]
})
export class EvaluacionEscalasGlobalesComponent implements OnInit {
    @Input() escalasGlobales: EscalaGlobal[]
    @Input() contenedor: string = "ensayo"

    constructor() {}

    ngOnInit() {}
}
