import { Alternativa } from "@puntaje/nebulosa/api-services"
import { BaseModel, SaveType } from "@puntaje/shared/core"

export class Retroalimentacion extends BaseModel {
    usuario_id: number
    retroalimentacion: string
    retroalimentable_id: number
    retroalimentable_type: string
    receptor_type: string
    receptor_id: number
    tipo: "pdf" | "html"
    file: File
    fileSrc: ArrayBuffer
    texto: string

    getS3Params(file) {
        return file
            ? {
                  key: file.name,
                  model_name: "Retroalimentacion"
              }
            : {}
    }
}
