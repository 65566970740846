import { Component, ViewChild, OnInit, Input, ElementRef } from "@angular/core"
import {} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Subscription } from "rxjs"
import { Asignaturas, Asignatura } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "plan-personal-sesion-box",
    templateUrl: "plan_personal_sesion_box.component.html",
    styleUrls: ["plan_personal_sesion_box.component.scss"]
})
export class PlanPersonalSesionBoxComponent implements OnInit {
    @Input() previewMode: boolean

    _planPersonalSesion: any
    isDone: boolean = false
    actual: boolean = false
    progressBarValue: number = 0
    animatedProgressBarValue: number = 0
    isPro: boolean = false
    @Input() planPersonalId: number
    @Input() planPersonalCiclo: number
    @Input() pointRight: boolean = false
    @Input() index: number = 0
    sesionesRoute: string = ""
    avance: number = 0
    calificacion: number = 0
    disableCalificacion: boolean = true
    private sub: Subscription
    asignaturaId: number

    @Input()
    set planPersonalSesion(planPersonalSesion: any) {
        this._planPersonalSesion = planPersonalSesion
    }
    get planPersonalSesion() {
        return this._planPersonalSesion
    }

    constructor(
        protected asignaturaService: Asignaturas,
        protected route: ActivatedRoute,
        protected router: Router,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.sub = this.route.queryParams.subscribe(query => {
            this.asignaturaId = +query["asignatura_id"]
        })
        let avance = 0
        const total = this.planPersonalSesion?.plan_personal_contenidos?.length || 0
        avance =
            this.planPersonalSesion &&
            this.planPersonalSesion.plan_personal_contenidos.reduce((acc, ppc) => {
                if (ppc.plan_personal_material_instancias.length > 0) {
                    return acc + 1
                } else if (ppc.plan_personal_evaluacion_instancias.length > 0) {
                    return acc + 1
                }

                return acc
            }, 0)

        if (this.planPersonalSesion && avance == total) {
            this.isDone = true
        }

        this.actual = this.planPersonalSesion?.actual

        this.avance = avance

        this.setProgressBarValue(avance, total)
        this.checkProgress()
        this.setCalificacion()
        this.showPorcentaje()
    }

    async showPorcentaje() {
        this.disableCalificacion = false
        let asignatura = await this.asignaturaService.find(this.asignaturaId)

        if (asignatura.asignatura == "Lectura" || asignatura.asignatura == "Redacción") {
            config.plataforma.disableCalificacionPlanPersonalSession
                ? (this.disableCalificacion = config.plataforma.disableCalificacionPlanPersonalSession)
                : (this.disableCalificacion = this.disableCalificacion)
        }
    }

    navigateToSesion() {
        this.sesionesRoute = "/plan_personal/" + this.planPersonalId + "/ciclo/" + this.planPersonalCiclo + "/sesiones"
        this.router.navigate([this.sesionesRoute, this.planPersonalSesion.id])
    }

    setProgressBarValue(avance: number, total: number) {
        this.progressBarValue = (avance / total) * 100
    }

    checkProgress() {
        this.animatedProgressBarValue = this.progressBarValue
        if (this.animatedProgressBarValue == 0) {
            setTimeout(() => {
                document
                    .getElementById("progressbar-" + this.planPersonalSesion.id)
                    .firstElementChild.setAttribute("aria-valuetext", "0 %")
            }, 10)
        }
    }

    navigateToPreviewSesion(plan_id) {}

    setCalificacion() {
        if (!this.planPersonalSesion) {
            this.calificacion = 0
            return
        }

        let ppc =
            this.planPersonalSesion.plan_personal_contenidos[
                this.planPersonalSesion.plan_personal_contenidos.length - 1
            ]

        if (ppc.tipo == "Evaluacion" && ppc.plan_personal_evaluacion_instancias.length > 0) {
            this.calificacion = ppc.plan_personal_evaluacion_instancias[0].evaluacion_instancia.calificacion
        } else {
            this.calificacion = 0
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
