<tabset class="instrumento-tabset">
    <div
        *ngFor="let instrumento of instrumentoMultiple.instrumentos; let i = index"
        heading="Sesión {{ i + 1 }}"
        id="tab{{ i + 1 }}"
        tab
    >
        <h3 class="titulo-instrumento">
            <div class="titulo-instrumento-wrap">
                <span class="titulo-instrumento-icon"><ogr-icon [name]="'prueba'"></ogr-icon></span>
                <div class="titulo-instrumento-text">
                    <p>Sesión {{ i + 1 }}</p>
                    <p class="subtitulo-instrumento">Instrumento #{{ instrumento.id }}</p>
                </div>
            </div>
        </h3>
        <instrumento
            [enableReporte]="enableReporte"
            [instrumento]="instrumento"
            [showAsignaturaForGroup]="true"
            [enableOpcionesPregunta]="enableOpcionesPregunta"
        ></instrumento>
    </div>
</tabset>
