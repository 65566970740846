import { Component, Input, ChangeDetectorRef, Output, EventEmitter, ViewChild } from "@angular/core"
import { CarreraSede } from "@puntaje/carreras/api-services"
import { Usuario } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "carreras-list-item",
    templateUrl: "carreras-list-item.component.html",
    styleUrls: ["carreras-list-item.component.scss"]
})
export class CarrerasListItemComponent {
    @Input() carreraSede: CarreraSede
    ponderables: any[]
    promedioByPonderable: any
    usuario: Usuario

    @Input() changingOrder: boolean

    promedioPonderado: number
    simulacionPonderado: number
    valoresPonderaciones: any[] = []
    enableBigSize: boolean = false

    @Input() enableOrderEdit: boolean = false
    @Input() isLast: boolean = false
    @Input() showPonderaciones: boolean = true
    @Input() showPosition: boolean = true
    @Output() changePosition: EventEmitter<any> = new EventEmitter<any>()

    disablePonderaciones: boolean = !config.carreras?.ponderables || config.carreras.ponderables.length == 0

    constructor(protected cdr: ChangeDetectorRef) {}

    adaptToSize(cs: number) {
        this.enableBigSize = cs > 0 && cs >= 650
        this.cdr.detectChanges()
    }

    onChangePosition(value: number) {
        this.changePosition.emit({ preferenciaCarreraSede: this.carreraSede.preferenciaCarreraSedes[0], value })
    }
}
