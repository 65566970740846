import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Injectable()
export class UsuariosBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    constructor() {}

    search(usuario, idPaisUsuario, buscarEstudiantesCodigoProfesor) {
        let params: any = {}

        if (usuario.id === null || usuario.id === undefined || usuario.id == "") {
            delete usuario.id
        }
        if (usuario.email === null || usuario.email === undefined || usuario.email == "") {
            delete usuario.email
        }

        params = { usuario, ["usuario_" + config.plataforma.pais]: {} }
        if (idPaisUsuario) {
            params["usuario_" + config.plataforma.pais][
                config.plataforma.identificadorUsuario.join("_o_").toLowerCase()
            ] = idPaisUsuario
        }

        if (buscarEstudiantesCodigoProfesor) {
            params.buscar_estudiantes_con_codigo_profesor = 1
        }

        this.searchParams.next(params)
    }
}
