import { Injectable } from "@angular/core"
import { CableService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Noticias, Noticia } from "@puntaje/puntaje/api-services"

import { Subject } from "rxjs"

@Injectable()
export class AlertasService {
    noticias: any[]
    noticiasSubscription: any

    streamings: any[]
    streamingSubscription: any

    subject = new Subject<any>()
    streamingSubject = new Subject<any>()

    constructor(protected cableService: CableService, protected noticiasService: Noticias) {
        let received = data => {
            if (!this.noticias && data.noticias) {
                this.noticias = data.noticias
            } else if (data.noticias) {
                data.noticias.forEach(n => {
                    const noticia = this.noticias.find(nt => nt.id === n.id)

                    if (!noticia) {
                        this.noticias.shift()
                        this.noticias.push(n)

                        this.subject.next(n)
                    }
                })
            }

            if (data.noticia) {
                const noticia = this.noticias.find(nt => nt.id === data.noticia.id)
                if (!noticia) {
                    this.noticias.shift()
                    this.noticias.push(data.noticia)
                }

                this.subject.next(data.noticia)
            }
        }

        this.noticiasSubscription = this.cableService.subscribe(
            "NoticiasChannel",
            { noticia_tipo: config.app.name },
            {
                received: received,

                connected: () => {
                    this.noticiasService
                        .where({
                            noticias_perfil: { noticia_perfil: ["todos", config.app.name] },
                            sort_by: "created_at",
                            order: "desc",
                            per: 5
                        })
                        .then((noticias: Noticia[]) => {
                            received(noticias)
                        })
                }
            }
        )

        // this.streamingSubscription = this.cableService.subscribe("StreamingChannel", {
        //     received: data => {
        //         if (data.streamings) {
        //             this.streamings = data.streamings
        //         }
        //         if (data.streaming) {
        //             const index = this.streamings.findIndex(value => {
        //                 return value.id === data.streaming.id
        //             })
        //             if (index > -1) {
        //                 this.streamings[index] = data.streaming
        //             } else {
        //                 this.streamings.push(data.streaming)
        //             }
        //         }
        //         this.streamingSubject.next(this.streamings)
        //     },

        //     connected: () => {
        //         this.streamingSubscription.perform("streamings")
        //     }
        // })
    }
}
