import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"

import { ErroresService } from "./errores.service"
import { ErroresComponent } from "./errores.component"
import { routing } from "./errores.routing"
import { FormsModule } from "@angular/forms"
import { UtilModule } from "../util.module"

@NgModule({
    imports: [CommonModule, FormsModule, UtilModule, routing],
    declarations: [ErroresComponent],
    exports: []
})
export class ErroresModule {
    static forRoot(): ModuleWithProviders<ErroresModule> {
        return {
            ngModule: ErroresModule,
            providers: [ErroresService]
        }
    }
}
