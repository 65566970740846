import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { Evaluacion } from "./evaluaciones.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class Evaluaciones extends PuntajeBaseService<Evaluacion> {
    tableName = "evaluaciones"
    singularTableName = "evaluacion"
    modelClass = Evaluacion

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public imprimirInformeAlumnos(evaluacionId: number, params: any = null) {
        return this.one(evaluacionId).concatExtra("imprimir_informe_alumnos").get(params)
    }

    public imprimir(evaluacionId: number, params: any = null) {
        this.enableIgnoreModel()

        return this.one(evaluacionId)
            .concatExtra("imprimir")
            .get(params)
            .then(resp => {
                this.disableIgnoreModel()

                return resp
            })
    }

    public imprimirMultiple(evaluacionIds: number[], params: any = {}) {
        this.enableIgnoreModel()

        return this.all()
            .concatExtra("imprimir_multiple")
            .get({ ...params, evaluacion: { id: evaluacionIds } })
            .then(response => {
                this.disableIgnoreModel()

                return response
            })
    }

    public imprimirHojas(evaluacionId: number, params: any = null) {
        return this.one(evaluacionId).concatExtra("imprimir_hojas").get(params)
    }

    public reforzamientos(evaluacionId: number, params: any = {}) {
        return this.one(evaluacionId).concatExtra("reforzamientos").post(params)
    }

    public delete_data(evaluacionId: number, params: any = null, timeout: number = 300000) {
        return this.one(evaluacionId).concatExtra("delete_data").post(params, timeout)
    }

    public wherePost(params: any = null, timeout: number = 30000) {
        return this.all().concatExtra("post").post(params, timeout)
    }

    public recalificar(evaluacionId: number, params: any = null, timeout: number = 30000) {
        return this.one(evaluacionId).concatExtra("recalificar").post(params, timeout)
    }

    public numeroPageLibroById(evaluacionId: number, params: any = null, timeout: number = 30000) {
        return this.one(evaluacionId).concatExtra("numero_page_libro_by_id").post(params, timeout)
    }

    public adaptive(params: any = null, timeout: number = 30000) {
        return this.all().concatExtra("adaptive").post(params, timeout)
    }

    public tablaResumenPorPregunta(evaluacionId: number, params: any = null) {
        this.enableIgnoreModel()
        this.enableIgnoreCatch()

        return this.one(evaluacionId)
            .concatExtra("tabla_resumen_por_pregunta")
            .post(params)
            .then(response => {
                this.disableIgnoreModel()
                this.disableIgnoreCatch()

                return response
            })
    }

    public urlHojaRespuesta() {
        this.enableIgnoreModel()
        return this.all()
            .concatExtra("url_hoja_respuesta")
            .get()
            .then(response => {
                this.disableIgnoreModel()
                return response
            })
    }
}
