<div class="kpi">
    <loading-layout #loadingLayout>
        <table *ngIf="ready" class="tabla-kpi table">
            <thead>
                <tr>
                    <th></th>
                    <th>{{ "progreso.estadisticas.tabla_resumen.n_ensayos" | t }}</th>
                    <th>{{ "progreso.estadisticas.tabla_resumen.ultimo_ptje" | t }}</th>
                    <th>{{ "progreso.estadisticas.tabla_resumen.correctas" | t }}</th>
                    <th>{{ "progreso.estadisticas.tabla_resumen.incorrectas" | t }}</th>
                    <th>{{ "progreso.estadisticas.tabla_resumen.omitidas" | t }}</th>
                    <th>{{ "progreso.estadisticas.tabla_resumen.maxima" | t }}</th>
                    <th>{{ "progreso.estadisticas.tabla_resumen.promedio" | t }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let i of listaAsignaturas | keys">
                    <ng-container *ngIf="kpiAsignatura[i]">
                        <th class="{{ listaAsignaturas[i].confByEvaluacionTipo[this.evaluacionTipo]?.clase }}">
                            <span class="asignatura">
                                <ogr-icon
                                    name="{{ listaAsignaturas[i].confByEvaluacionTipo[this.evaluacionTipo]?.icono }}"
                                    [align]="'baseline'"
                                    class="icono"
                                ></ogr-icon>
                                {{ listaAsignaturas[i].confByEvaluacionTipo[this.evaluacionTipo]?.abreviacion }}
                            </span>
                        </th>
                        <td class="text-center">
                            {{ kpiAsignatura[i][0] | roundfloat }}
                        </td>
                        <td class="text-center">
                            {{ kpiAsignatura[i][2] | roundfloat: 1 }}
                        </td>
                        <td class="text-center">
                            {{ kpiAsignatura[i][3] | roundfloat }}
                        </td>
                        <td class="text-center">
                            {{ kpiAsignatura[i][4] | roundfloat }}
                        </td>
                        <td class="text-center">
                            {{ kpiAsignatura[i][5] | roundfloat }}
                        </td>
                        <td class="text-center">
                            {{ kpiAsignatura[i][6] | roundfloat }} {{ percentageSign ? "%" : "" }}
                        </td>
                        <td class="text-center">
                            <ng-container *ngIf="kpiAsignatura[i][1] != '-'">
                                {{ kpiAsignatura[i][1] | roundfloat: 1 }} {{ percentageSign ? "%" : "" }}
                            </ng-container>
                            <ng-container *ngIf="kpiAsignatura[i][1] == '-'">
                                {{ kpiAsignatura[i][1] }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </loading-layout>
</div>
