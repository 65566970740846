import { Component, ViewChild, OnInit, Input } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    EvaluacionMultiples,
    EvaluacionMultiple,
    EvaluacionInstancias,
    EvaluacionInstancia
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "evaluaciones-multiples-compartidas-alumno",
    templateUrl: "evaluaciones_multiples_compartidas_alumno.component.html",
    styleUrls: ["evaluaciones_multiples_compartidas_alumno.component.scss"]
})
export class EvaluacionesMultiplesCompartidasAlumnoComponent {
    @Input() evaluacionTipo: string
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    evaluacionesMultiples: EvaluacionMultiple[] = []
    evaluacionInstancias: EvaluacionInstancia[]

    constructor(
        protected evaluacionMultipleService: EvaluacionMultiples,
        protected evaluacionInstanciaService: EvaluacionInstancias,
        private authService: AuthService
    ) {}

    setData = (page: number, per: number) => {
        let params = {
            page: page,
            per: per,
            mios: 1,
            with_instrumento_multiple: 1
        }

        this.evaluacionTipo && (params["evaluacion_tipo"] = { evaluacion_tipo: this.evaluacionTipo })

        return this.evaluacionMultipleService
            .where(params)
            .then((evaluacionesMultiples: EvaluacionMultiple[], total: number) => {
                this.evaluacionesMultiples = evaluacionesMultiples

                let paramsEvaluacionInstancia = {
                    mios: 1,
                    evaluacion_instancia: {
                        evaluacion_id: this.evaluacionesMultiples
                            .map(em => {
                                return em.evaluaciones.map(e => {
                                    return e.id
                                })
                            })
                            .flat(),
                        oficial: 1,
                        usuario_id: this.authService.getUserData().id
                    }
                }

                this.evaluacionInstanciaService
                    .where(paramsEvaluacionInstancia)
                    .then((evaluacionInstancias: EvaluacionInstancia[]) => {
                        this.evaluacionesMultiples.map(evaluacionMultiple => {
                            evaluacionMultiple.evaluaciones.map(evaluacion => {
                                evaluacion.evaluacion_instancias = evaluacionInstancias.filter(
                                    evaluacionInstancia => evaluacionInstancia.evaluacion_id == evaluacion.id
                                )
                            })
                        })
                    })
                this.loadingLayout.ready()
                return total
            })
    }
}
