import { BaseModel, SaveType } from "@puntaje/shared/core"
import { InstrumentoPregunta } from "./instrumento_preguntas.model"
import { EvaluacionForma } from "./evaluacion_formas.model"

export class EvaluacionFormaInstrumentoPregunta extends BaseModel {
    evaluacion_forma_id: number
    instrumento_pregunta_id: number
    orden: number
    created_at: Date
    updated_at: Date

    @SaveType(() => EvaluacionForma) evaluacion_forma: EvaluacionForma
    @SaveType(() => InstrumentoPregunta) instrumento_pregunta: InstrumentoPregunta
}
