import { NgModule } from "@angular/core"

// modules
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { DatepickerModule } from "ngx-bootstrap/datepicker"
import { TimepickerModule } from "ngx-bootstrap/timepicker"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { MyDatePickerModule } from "mydatepicker"
import { Ng9PasswordStrengthBarModule } from "ng9-password-strength-bar"

// components
import { AutocompleteComponent } from "./autocomplete.component"
import { BirthdateFormComponent } from "./birthdateform.component"
import { CheckboxesComponent } from "./checkboxes.component"
import { DatepickerFormComponent } from "./datepickerform.component"
import { FileComponent } from "./file.component"
import { InputComponent } from "./input.component"
import { InputErrorComponent } from "./input-error.component"
import { SelectWithLoaderComponent, TargetSelectDirective } from "./select-with-loader.component"
import { PasswordStrengthBarComponent } from "./password-strength-bar.component"

import { CKEditorModule } from "ng2-ckeditor"
import { UtilModule } from "../util/util.module"
import { FocusedDirective } from "./focused.directive"
import { ErrorMessageDirective, ErrorMessagesComponent } from "./error-messages/error-messages.component"

@NgModule({
    imports: [
        ReactiveFormsModule,
        FormsModule,
        CKEditorModule,
        CommonModule,
        UtilModule,
        DatepickerModule,
        TimepickerModule,
        MyDatePickerModule,
        TooltipModule,
        Ng9PasswordStrengthBarModule
    ],
    declarations: [
        AutocompleteComponent,
        BirthdateFormComponent,
        CheckboxesComponent,
        DatepickerFormComponent,
        FileComponent,
        InputComponent,
        InputErrorComponent,
        FocusedDirective,
        SelectWithLoaderComponent,
        TargetSelectDirective,
        PasswordStrengthBarComponent,
        ErrorMessagesComponent,
        ErrorMessageDirective
    ],
    exports: [
        AutocompleteComponent,
        BirthdateFormComponent,
        CheckboxesComponent,
        DatepickerFormComponent,
        FileComponent,
        InputComponent,
        InputErrorComponent,
        SelectWithLoaderComponent,
        TargetSelectDirective,
        PasswordStrengthBarComponent,
        ErrorMessagesComponent,
        ErrorMessageDirective
    ]
})
export class FormModule {}
