import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import {
    Duda,
    Dudas,
    DudaForm,
    DudaCategoria,
    DudaCategorias,
    DudaDudaCategoria,
    GrupoUsuario
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Router } from "@angular/router"

@Component({
    selector: "duda-edit",
    templateUrl: "duda_form.component.html",
    styleUrls: ["duda_form.component.scss"]
})
export class DudaEditComponent implements OnInit {
    @Input() dudaId: number
    duda: Duda = new Duda()
    oDuda: Duda
    form: UntypedFormGroup
    params = DudaForm.formParams

    dudaCategoriaFirst: DudaCategoria
    dudaCategoriaSecond: DudaCategoria
    dudaCategoriaThird: DudaCategoria

    dudaCategoriasFirst: DudaCategoria[]
    dudaCategoriasSecond: DudaCategoria[]
    dudaCategoriasThird: DudaCategoria[]
    @Input() item_id: number
    @Input() item_type: string
    @Input() item_categoria: string
    @Input() showCursos: boolean = false

    grupoUsuario: GrupoUsuario
    grupoUsuarios: GrupoUsuario[]

    ready: boolean = false
    showForm: boolean = false
    btnSaveDisabled: boolean = false

    selectedTab = "cont"
    datos: any = {
        cont: {
            numero: 1,
            nombre: "Contenido",
            tab: "cont",
            next: "categ",
            activo: true,
            previous: "curs"
        },
        categ: {
            numero: 2,
            nombre: "Categorización",
            tab: "categ",
            next: "prev",
            activo: false,
            previous: "cont"
        },
        prev: {
            numero: 3,
            nombre: "Previsualización",
            activo: false,
            tab: "prev",
            previous: "categ"
        }
    }

    constructor(
        protected dudaCategoriasService: DudaCategorias,
        protected authService: AuthService,
        protected router: Router,
        protected dudasService: Dudas,
        protected cdr: ChangeDetectorRef
    ) {}

    async ngOnInit() {
        this.form = DudaForm.getForm(this.duda)
        this.dudasService
            .find(this.dudaId, { include: "[duda_categorias,duda_duda_categorias]" })
            .then((duda: Duda) => {
                this.duda = duda
                this.oDuda = duda.clone()
                this.dudaCategoriaFirst =
                    duda.duda_categorias.find((dc: DudaCategoria) => dc.duda_categoria_padre_id === null) || undefined
                if (this.dudaCategoriaFirst !== undefined && this.dudaCategoriaFirst !== null) {
                    this.dudaCategoriaSecond =
                        duda.duda_categorias.find(
                            (dc: DudaCategoria) => dc.duda_categoria_padre_id === this.dudaCategoriaFirst.id
                        ) || undefined
                    if (this.dudaCategoriaSecond !== undefined && this.dudaCategoriaSecond !== null) {
                        this.dudaCategoriaThird =
                            duda.duda_categorias.find(
                                (dc: DudaCategoria) => dc.duda_categoria_padre_id === this.dudaCategoriaSecond.id
                            ) || undefined
                    }
                }
                this.cdr.detectChanges()
                this.showForm = true
            })
        await this.getDudaCategoriasFirst()
    }

    getDudaCategorias(varName: string, dudaCategoriaPadre: DudaCategoria = null) {
        let dudaCategoriaId = dudaCategoriaPadre ? dudaCategoriaPadre.id : null

        this.dudaCategoriasService
            .where({ duda_categoria: { duda_categoria_padre_id: dudaCategoriaId } })
            .then((dudaCategorias: DudaCategoria[]) => {
                this[varName] = dudaCategorias
            })
    }

    getDudaCategoriasFirst() {
        this.getDudaCategorias("dudaCategoriasFirst")
    }

    getDudaCategoriasSecond() {
        this.dudaCategoriaSecond = undefined
        this.dudaCategoriaThird = undefined

        this.dudaCategoriasSecond = undefined
        this.dudaCategoriasThird = undefined

        this.getDudaCategorias("dudaCategoriasSecond", this.dudaCategoriaFirst)
    }

    getDudaCategoriasThird() {
        this.dudaCategoriaThird = undefined

        this.dudaCategoriasThird = undefined

        this.getDudaCategorias("dudaCategoriasThird", this.dudaCategoriaSecond)
    }

    publicar() {
        if (this.form.valid) {
            this.btnSaveDisabled = true
            this.duda.duda_duda_categorias = [
                this.dudaCategoriaFirst,
                this.dudaCategoriaSecond,
                this.dudaCategoriaThird
            ]
                .filter(x => x)
                .map(dudaCategoria => {
                    let dudaDudaCategoria = new DudaDudaCategoria()

                    dudaDudaCategoria.duda_categoria_id = dudaCategoria.id

                    return dudaDudaCategoria
                })
            let duda_duda_categorias = this.duda.duda_duda_categorias
            this.oDuda.duda_duda_categorias.forEach((ddc: DudaDudaCategoria) => {
                let pos = duda_duda_categorias.findIndex(
                    (e: DudaDudaCategoria) => e.duda_categoria_id === ddc.duda_categoria_id
                )
                if (pos < 0) {
                    let newDdc = new DudaDudaCategoria()
                    newDdc["_destroy"] = 1
                    newDdc.id = ddc.id
                    duda_duda_categorias = duda_duda_categorias.concat(newDdc)
                } else {
                    duda_duda_categorias[pos] = ddc
                }
            })
            this.duda.duda_duda_categorias = duda_duda_categorias
            this.dudasService.update(this.duda.id, this.duda).then((duda: Duda) => {
                this.router.navigate(["dudas"])
            })
        }
    }

    cancel() {
        this.clear()
        this.router.navigate(["dudas", this.dudaId])
    }

    clear() {
        this.duda = this.oDuda.clone()
        DudaForm.markFormControlsAsPristine(this.form)
        DudaForm.markFormControlsAsUntouched(this.form)
    }

    compareDudaCategoria(dc1: DudaCategoria, dc2: DudaCategoria): boolean {
        return dc1 && dc2 && dc1.id === dc2.id
    }

    setCategoriasSelected(duda: Duda) {
        this.dudaCategoriaFirst =
            duda.duda_categorias.find((dc: DudaCategoria) => dc.duda_categoria_padre_id === null) || undefined
        if (this.dudaCategoriaFirst !== undefined && this.dudaCategoriaFirst !== null) {
            this.dudaCategoriaSecond =
                duda.duda_categorias.find(
                    (dc: DudaCategoria) => dc.duda_categoria_padre_id === this.dudaCategoriaFirst.id
                ) || undefined
            if (this.dudaCategoriaSecond !== undefined && this.dudaCategoriaSecond !== null) {
                this.dudaCategoriaThird =
                    duda.duda_categorias.find(
                        (dc: DudaCategoria) => dc.duda_categoria_padre_id === this.dudaCategoriaSecond.id
                    ) || undefined
            }
        }
        this.cdr.detectChanges()
    }

    selectTab(tab) {
        if (this.showCursos) {
            this.datos["curs"].activo = false
        }
        this.datos.cont.activo = false
        this.datos.categ.activo = false
        this.datos.prev.activo = false
        this.selectedTab = tab

        this.datos[tab].activo = true
    }

    checkPremade() {}
}
