<div class="grupo-pregunta-contenido" *ngIf="pregunta.grupo_pregunta && pregunta.grupo_pregunta.id">
    <div class="resp-id">#{{ pregunta.grupo_pregunta }}</div>
    <div [innerHTML]="pregunta.grupo_pregunta.texto | clearLatex | katex | trustedhtml"></div>
</div>
<div class="pregunta_contenido">
    <div class="resp-id">#{{ pregunta.id }}</div>
    <pregunta-taxativo [taxativo]="pregunta.taxativos[0].taxativo"></pregunta-taxativo>

    <div class="prueba-alternativas">
        <div class="form-group">
            <ul class="alternativas-list">
                <li
                    *ngFor="let alternativa of pregunta.contestables[0].alternativas"
                    [class.alternativa-correcta]="alternativa.correcta"
                >
                    <div *ngIf="alternativa.correcta" class="alternativa-correcta-icon">
                        <ogr-icon name="ticket-circle"></ogr-icon>
                    </div>
                    <div class="alternativa_container">
                        <div class="letra">{{ alternativa.letra }})</div>
                        <div
                            class="alternativa"
                            [innerHTML]="alternativa.alternativa | clearLatex | katex | trustedhtml"
                        ></div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="solucion clearfix">
    <div class="solucion_contenido" [innerHTML]="pregunta.solucion | clearLatex | katex | trustedhtml"></div>
</div>
