import { BaseModel, SaveType } from "@puntaje/shared/core"

export class EvaluacionTipo extends BaseModel {
    evaluacion_tipo: string

    static curriculumByTipo: any = {
        curricular: "LGE",
        paa: "PAA",
        saber: "SABER",
        saber11: "SABER",
        "prueba de transición": "PDT",
        paes: "PAES",
        "Nivelación de estudios": "GRADUATE",
        "curricular mexico": "CurricularMexico",
        "curricular colombia": "CurricularColombiaDBA"
    }

    public curriculum() {
        return EvaluacionTipo.curriculumByTipo[this.evaluacion_tipo]
    }

    public toString() {
        return this.evaluacion_tipo
    }
}
