<div (mouseenter)="enterUserInfo()" (mouseleave)="leaveUserInfo()" [class.miniMode]="enableMiniMode">
    <div class="avatar-container {{ marcoPro }}" [class.avatar-pro]="isPro && !enableMiniMode">
        <div class="avatar-wrap" (click)="toggleUserInfo()">
            <img *ngIf="avatar" class="avatar" alt="avatar" [src]="avatar | trustedurl" />
            <img
                *ngIf="!avatar"
                class="avatar"
                alt="no avatar"
                src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/default-profile.png"
            />
        </div>
        <div class="user-info" *ngIf="showUserInfo" #userInfo>
            <div class="mouse-bridge"></div>
            <div class="user-info-content">
                <p *ngIf="usuario" class="user-name">
                    <a routerLink="/usuarios/{{ usuario.id }}">{{ usuario.nombreCompleto() }}</a>
                </p>
                <div class="loader-wrap" *ngIf="doingFetch">
                    <loader class="loader" loaderType="default-small"></loader>
                </div>
                <ng-container *ngIf="enableAchievementsInfo && !doingFetch && enableMiniMode">
                    <ng-container *ngTemplateOutlet="resumenLogros"></ng-container>
                </ng-container>
                <div class="badges-usuario-mini clearfix" *ngIf="enableMiniMode && !doingFetch">
                    <span *ngIf="isDocente" tooltip="Docente" class="bdg bdg-docente">
                        <ogr-icon name="docente"></ogr-icon>
                    </span>
                    <span *ngIf="isPro" tooltip="Usuario PRO" class="bdg bdg-pro">
                        <ogr-icon name="pro"></ogr-icon>
                    </span>
                </div>
                <p class="activity" *ngIf="usuarioValores && usuarioValores.dudas">
                    <ogr-icon name="hacer-prueba" class="icono nDudas"></ogr-icon>
                    <span class="valor">{{ usuarioValores.dudas }}</span>
                    <span class="subtitle">{{ "dudas.duda_avatar.dudas" | t }}</span>
                </p>
                <p class="activity" *ngIf="usuarioValores && usuarioValores.respuestas">
                    <ogr-icon name="ticket-circle" class="icono nContestadas"></ogr-icon>
                    <span class="valor">{{ usuarioValores.respuestas }}</span>
                    <span class="subtitle">{{ "dudas.duda_avatar.respuestas_correctas" | t }}</span>
                </p>
                <p class="activity" *ngIf="usuarioValores && usuarioValores.comentarios">
                    <ogr-icon name="dialogos" class="icono"></ogr-icon>
                    <span class="valor">{{ usuarioValores.comentarios }}</span>
                    <span class="subtitle">{{ "dudas.duda_avatar.comentarios" | t }}</span>
                </p>
                <p class="activity" *ngIf="enableAchievementsInfo">
                    <ogr-icon name="trofeo-o" class="icono achievements"></ogr-icon>
                    <span total-logros class="valor"></span>
                    <span class="subtitle">{{ "dudas.duda_avatar.logros" | t }}</span>
                </p>
            </div>
        </div>
        <ng-container *ngIf="isAdmin && usuarioBaneado">
            <ng-template #banPopover>
                <p class="popoverInfo">
                    <b>{{ "dudas.duda_avatar.inicio" | t }}:</b>
                    <br />
                    <span>{{ usuarioBaneado.fecha_inicio | prettydate }}</span>
                </p>
                <p class="popoverInfo">
                    <b>{{ "dudas.duda_avatar.termino" | t }}:</b>
                    <br />
                    <span>{{ usuarioBaneado.fecha_termino | prettydate }}</span>
                </p>
            </ng-template>
            <span class="banIndicator" [popover]="banPopover"><fa name="gavel"></fa></span>
        </ng-container>
    </div>

    <ng-container *ngIf="!enableMiniMode && enableAchievementsInfo">
        <ng-container *ngTemplateOutlet="resumenLogros"></ng-container>
    </ng-container>
    <div class="badges-usuario clearfix" *ngIf="!enableMiniMode">
        <span *ngIf="isDocente" tooltip="Docente" class="bdg bdg-docente">
            <ogr-icon name="docente"></ogr-icon>
        </span>
        <span *ngIf="isPro" tooltip="Usuario PRO" class="bdg bdg-pro">
            <ogr-icon name="pro"></ogr-icon>
        </span>
    </div>
    <cui-button *ngIf="isAdmin" buttonType="danger" class="btn_danger" size="xsmall" (click)="banUser()">
        <fa name="ban" class="left-icon"></fa>
        <fa name="user" class="miniIcon only-icon"></fa>
        <span>{{ "dudas.duda_avatar.deshabilitar" | t }}</span>
    </cui-button>
</div>

<ng-template #resumenLogros>
    <logros-resumen-nivel [isVertical]="!enableMiniMode"></logros-resumen-nivel>
</ng-template>
