import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"

import { ApiModule } from "@puntaje/puntaje/api-services"
import { UtilModule, PaginatorModule, FormModule, ModelViewModule } from "@puntaje/shared/core"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { PopoverModule } from "ngx-bootstrap/popover"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { CUIContainersModule, CUIInputsModule } from "@puntaje/shared/cui"

import { NoticiaComponent } from "./noticia/noticia.component"
import { NoticiasComponent } from "./noticias/noticias.component"
import { NoticiasNewComponent } from "./noticias.new.component"
import { NoticiasEditComponent } from "./noticias.edit.component"
import { NoticiasToolNavbarComponent } from "./noticias.toolnavbar.component"
import { NoticiasShowcaseBoxComponent } from "./noticias_showcasebox/noticias_showcasebox.component"
import { NoticiasAlumnosShowcaseBoxComponent } from "./noticias_showcasebox/noticias_alumnos_showcasebox.component"
import { VerNoticiaComponent } from "./ver_noticia.component"
import { NoticiasModalComponent } from "./noticias-modal/noticias-modal.component"
import { NoticiasProfesorComponent } from "./noticias-profesor/noticias-profesor.component"
import { NoticiaProfesorComponent } from "./noticia-profesor/noticia-profesor.component"
import { VerNoticiaModalComponent } from "./ver-noticia-modal/ver-noticia-modal.component"
import { CarouselModule } from "ngx-bootstrap"
import { NoticiasAvisoHomeComponent } from "./noticias-aviso-home/noticias-aviso-home.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        ModelViewModule,
        PaginatorModule,
        RouterModule,
        Angular2FontawesomeModule,
        CarouselModule,
        PopoverModule,
        SharedModule,
        CUIContainersModule,
        CUIInputsModule
    ],
    declarations: [
        NoticiaComponent,
        NoticiasComponent,
        NoticiasNewComponent,
        NoticiasEditComponent,
        NoticiasToolNavbarComponent,
        NoticiasShowcaseBoxComponent,
        NoticiasAlumnosShowcaseBoxComponent,
        VerNoticiaComponent,
        NoticiasModalComponent,
        NoticiasProfesorComponent,
        NoticiaProfesorComponent,
        VerNoticiaModalComponent,
        NoticiasAvisoHomeComponent
    ],
    exports: [
        NoticiaComponent,
        NoticiasComponent,
        NoticiasNewComponent,
        NoticiasEditComponent,
        NoticiasToolNavbarComponent,
        NoticiasShowcaseBoxComponent,
        NoticiasAlumnosShowcaseBoxComponent,
        VerNoticiaComponent,
        NoticiasModalComponent,
        NoticiasProfesorComponent,
        NoticiaProfesorComponent,
        VerNoticiaModalComponent,
        NoticiasAvisoHomeComponent
    ]
})
export class NoticiasModule {}
