import { Component, Input } from "@angular/core"

/*#################################

Componente tipo loader o spinner

#################################*/

@Component({
    templateUrl: "loader.component.html",
    selector: "loader",
    styleUrls: ["loader.component.scss"]
})
export class LoaderComponent {
    //Especifica el tipo de loader. Tipos disponibles:
    //default
    //default-small
    //default-xsmall
    //nebu
    //puntaje
    //puntaje-small
    //graduate
    //umayor
    //tec
    @Input() loaderType: string = ""
    @Input() noMarginY: boolean = false
}
