import { Component } from "@angular/core"

@Component({
    selector: "el-contenido-lateral, l-contenido-lateral",
    template: `
        <l-slot-content name="contenido-lateral">
            <ng-content></ng-content>
        </l-slot-content>
    `
})
export class LContenidoLateralComponent {}
