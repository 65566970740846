import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, Input, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "plan-clases",
    templateUrl: "./plan-clases-alumnos.component.html"
})
export class PlanClasesAlumnosComponent implements OnInit {
    subData: Subscription

    evaluacionTipo = config.plataforma.evaluacionDefault
    planClaseRoute = config?.plataforma?.planClasesRoute || "/plan_estudios"
    verificarFechaInicial = false

    constructor(
        protected titleService: TitleService,
        protected loggedLayoutService: LoggedLayoutService,
        protected route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_clases.plan_clases_alumnos")
        this.loggedLayoutService.setOptions({ noTitleBar: true })

        this.subData = this.route.data.subscribe(data => {
            this.evaluacionTipo = data.evaluacionTipo ?? this.evaluacionTipo
            this.planClaseRoute = data.planClaseRoute ?? this.planClaseRoute
            this.verificarFechaInicial = data.verificarFechaInicial ?? this.verificarFechaInicial
        })
    }

    ngOnDestroy() {
        this.subData.unsubscribe()
    }
}
