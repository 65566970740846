import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core"
import { DomSanitizer, SafeResourceUrl, Title } from "@angular/platform-browser"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Post, Posts } from "@puntaje/puntaje/api-services"
import { LoggedLayoutService } from "@puntaje/shared/cui"
import { AppConfiguration } from "@puntaje/shared/core"

declare const config: AppConfiguration

@Component({
    selector: "post",
    templateUrl: "./post.component.html",
    styleUrls: ["./post.component.scss"]
})
export class PostComponent implements OnChanges, OnInit, OnDestroy {
    @Input() postId: number
    @Input() postSlug: string
    @Input() external = false
    post: Post
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    usuario_avatar: string
    usuario_avatar_safe: SafeResourceUrl
    ultimosPosts: Post[]
    prevTitle: string

    constructor(
        protected postsServices: Posts,
        protected sanitizer: DomSanitizer,
        protected loggedLayoutService: LoggedLayoutService,
        protected titleService: Title
    ) {}

    ngOnInit() {
        if (this.prevTitle === undefined && this.post) {
            this.prevTitle = this.titleService.getTitle()
            this.titleService.setTitle(this.post.titulo)
        }
        this.loggedLayoutService.setOptions({ noTitleBar: true })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.postId || changes.postSlug) {
            this.loadingLayout.standby()
            const params: any = {
                render_options: {
                    include: ["usuario"]
                }
            }

            if (this.postId) {
                params.post = { id: this.postId }
            } else if (this.postSlug) {
                params.post = { slug: this.postSlug }
            } else {
                this.loadingLayout.ready()

                return
            }

            this.postsServices.where(params).then(posts => {
                const post = posts[0]

                this.post = post
                this.post.video = post.video
                    ? post.video
                          .replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/")
                          .replace("https://youtu.be", "https://www.youtube.com/embed")
                          .replace("https://vimeo.com/", "https://player.vimeo.com/video/")
                    : null
                this.usuario_avatar = post.usuario?.avatar
                this.usuario_avatar_safe = this.sanitizer.bypassSecurityTrustResourceUrl(this.usuario_avatar)
                if (this.prevTitle === undefined) {
                    this.prevTitle = this.titleService.getTitle()
                    this.titleService.setTitle(this.post.titulo)
                }

                this.loadingLayout.ready()
            })

            const paramsUltimos = {
                page: 1,
                per: 3,
                sort_by: "posts.created_at",
                order: "desc",
                post_perfil: {
                    post_perfil: ["todos", config.app.name == "profesores" ? "profesores" : "alumnos"]
                }
            }

            this.postsServices.where(paramsUltimos).then(posts => {
                this.ultimosPosts = posts
            })
        }
    }

    ngOnDestroy() {
        this.titleService.setTitle(this.prevTitle)
    }
}
