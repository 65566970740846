import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Ayuda } from "./ayudas.model"
import { Plataforma } from "./plataformas.model"

export class AyudaPlataforma extends BaseModel {
    public static className: string = "AyudaPlataforma"
    public plataforma_id: number
    public ayuda_id: number
    created_at: Date
    updated_at: Date
    @SaveType(() => Ayuda) public ayuda: Ayuda
    @SaveType(() => Plataforma) public plataforma: Plataforma

    constructor() {
        super()
    }

    public toString() {
        return this.id.toString()
    }
}
