export const esZero = {
    reportes: {
        filtrar_datos: "Filtrar datos",
        resumen_uso: {
            titulo: "Resumen de uso",
            evaluacion: "Evaluacion:",
            asignatura: "Asignatura:",
            tipo: "Tipo:",
            asociaciones: "Asociaciones:",
            establecimiento: "Establecimiento:",
            curso: "Curso",
            seleccionar_curso: "-- Seleccione curso --",
            desde: "Desde:",
            hasta: "Hasta",
            info: "*Si se escoge un Tipo, los datos de las evaluaciones estarán filtrados por éste.",
            buscar: "Buscar",
            resultados: {
                resultados: "Resultados",
                resultados_acumulados: "Resultados acumulados de los establecimientos seleccionados",
                resultados_establecimiento: "Resultados por establecimiento",
                docentes: "Docentes",
                ingresos_sistema: "Ingresos al sistema",
                instrumentos_generados: "Instrumentos generados",
                evaluaciones_compartidas: "Evaluaciones compartidas",
                evaluaciones_presenciales: "Evaluaciones presenciales",
                evaluaciones_contestadas: "Evaluaciones contestadas",
                estudiantes: "Estudiantes",
                cuentas_administradoras: "Cuentas administradoras",
                not_found: "No se encontraron resultados",
                administrador_instrumentos_descripcion:
                    "N° de instrumentos (únicos) generados por los administradores en el rango de fechas definidas.",
                actividad_docente: {
                    titulo: "Actividad docente",
                    nombre: "Nombre",
                    ultimo_ingreso: "Último Ingreso",
                    ingresos_30_dias: "Ingresos Últimos 30 Días",
                    total_instrumentos: "Total Instrumentos",
                    total_evaluaciones: "Total Evaluaciones"
                }
            }
        },
        informe_uso: {
            titulo: "Informe de uso"
        },
        resultados_evaluaciones: {
            titulo: "Resultados evaluaciones"
        },
        informe_anual: {
            titulo: "Informe anual"
        },
        avance_planes_adaptive: {
            titulo: "Ruta Adaptativa",
            establecimiento: "Establecimiento:",
            seleccionar_establecimiento: "-- Seleccione establecimiento --",
            curso: "Curso:",
            seleccionar_curso: "-- Seleccione curso --",
            asignatura: "Asignatura:",
            seleccionar_asignatura: "-- Seleccione asignatura --",
            tipo: "Tipo de diagnóstico:",
            seleccionar_tipo: "-- Seleccione tipo de diagnóstico --",
            buscar: "Generar"
        }
    },
    estudiar: {
        itemsmenu: {
            biblioteca: {
                title: "Biblioteca de Materiales %{evaluacionTipo}",
                curricular: "Biblioteca de Materiales Curriculares",
                prueba_transicion: "Biblioteca de Materiales Prueba de Transición",
                paes: "Biblioteca de Materiales PAES"
            },
            plan_estudios: "Planes de Estudio",
            plan_personales: "Plan de estudio adaptativo",
            streaming: {
                title: "Clases en vivo: %{evaluacionTipo}",
                curricular: "Clases en vivo: Curricular",
                prueba_transicion: "Clases en vivo: Prueba de Transición",
                paes: "Clases en vivo: PAES"
            }
        },
        materiales: {
            descargar: "Descargar",
            favorito: "Favorito",
            favoritos: "Agregar a favoritos",
            fullscreen: "Ver pantalla completa",
            imprimir: "Imprimir",
            material: "Material",
            volver: "Volver",
            youtube: "Ver en YouTube"
        },
        plan_de_clases: {
            aceptar: "Aceptar",
            actividad: "*Actividad",
            agregar: "Agregar",
            asignatura: "Asignatura",
            avance: "Avance",
            avance_est: "Avance estimado",
            cancelar: "Cancelar",
            comenzar_modelo: "Comenzar a partir de un modelo",
            compartido1: "Se ha compartido el plan de clase",
            compartido2: "a los siguientes",
            compartir: "Compartir",
            compartir_plan: "Compartir Plan de clase",
            confirm_message: "¿Está seguro de querer eliminar el plan de clase?",
            confirm_message2:
                "Por favor escriba ELIMINAR en el siguiente campo de texto para confirmar su decisión. Recuerde que esta acción es IRREVERSIBLE.",
            confirm_elimnar_text: "ELIMINAR",
            create: "Crear Plan de Clase",
            cursos_comp: "Cursos compartidos",
            decir_vamos_aqui: "Decir a mi curso que vamos aquí",
            editar_comp: "Editar cursos compartidos",
            eliminar_plan: "Eliminar plan de clase",
            enviar_retroalimentacion: "Enviar retroalimentación",
            estudiantes: "estudiantes",
            form_eliminar: "*Eliminar",
            form_error: "No se pudo borrar el plan de clase, por favor inténtelo más tarde.",
            form_exito: "Exito",
            form_exito_mensaje: "*El plan de clase se ha eliminado exitosamente.",
            listo: "listo",
            marcada_vista: "marcada como vista",
            marcar_vista1: "Marcar",
            marcar_vista2: "como vista",
            material_arrastre: "Arrastre materiales para ordenarlos o desde una sesión a otra",
            material_clases: "Material de clases",
            no_vamos_aqui: "No vamos aquí",
            not_comp: "Este plan no ha sido compartido",
            not_found: "*No se encontraron planes.",
            not_found_grupos: "Usted no puede compartir un plan de clase: No posee",
            plan_aplicado1: "Se ha aplicado la planificación",
            plan_aplicado2: "a los siguientes",
            promedio_logro: "Porcentaje de logro promedio",
            promedio_tiempo: "Porcentaje de tiempo promedio",
            redirigido1: "Serás redirigido en unos segundos al listado de planificaciones o puedes ir haciendo click",
            redirigido2: "acá",
            redirigido3: "Serás redirigido en unos segundos al listado de planes de clases",
            replicar: "Replicar",
            replicar_context: "Replicar Plan a otro curso",
            replicar_success: "Plan replicado correctamente",
            replicar_title: "Replicar Plan",
            required_tipo: "El campo Tipo / nivel es obligatorio",
            retroalimentacion: "Retroalimentación",
            sel_asignatura: "Seleccione asignatura",
            sel_curso: "Seleccione un curso",
            sel_curso_title: "Seleccione Curso",
            sel_ano: "Seleccione año",
            seleccionar_aplicar: "Seleccionar y aplicar",
            seleccione_modelo:
                "Selecciona uno de los modelos propuestos a partir del cual deseas comenzar a crear tu plan de clases",
            select_modelo: "Comenzar plan a partir de este modelo",
            title: "Compartir plan de clase",
            title_simple: "Planes de clases",
            recomendados: {
                titulo: "Planes de clases recomendados"
            },
            title_grupos: "a los cuales compartira el plan de clase:",
            vamos_aqui: "Vamos aquí",
            ver_actividad: "Ver actividad",
            ver_clase: "Ver clase",
            ver_respuestas: "Ver respuestas",
            visible_desde: "Visible desde"
        },
        plan_estudios: {
            asignaturas: "Asignaturas",
            compartidos: {
                titulo: "Planes de estudio compartidos por mi profesor",
                titulo_simple: "Planes de estudio"
            },
            editar: "Editar",
            evaluacion: "Evaluación",
            msj_no_planes: "no se ha encontrado ningún plan.",
            recomendados: {
                titulo: "Planes de estudio recomendados"
            },
            titulo: "Planes de Estudio",
            ver: "Ver"
        },
        plan_personales: {
            btn_comenzar: "Comenzar plan personal",
            plan_personal: {
                avance: "Avance",
                comenzar: "Comenzar",
                continuar: "Continuar",
                continuar_estudiando: "Continuar Estudiando",
                evaluacion_diagnostico: "Evaluación de diagnóstico",
                mensaje_continuar: "Completa las sesiones de estudio antes de volver a diagnosticar.",
                pendiente: "Pendiente",
                repasar: "Repasar",
                repaso_sesion: "Te recomendamos repasar:",
                resultados: "Resultados",
                subtitulo: "Progreso de tu plan personal",
                subtitulo_sesion: "Sesión de estudio",
                titulo_sesion: "Sesión",
                clase_actual: "Clase Actual"
            },
            plan_personal_evaluacion: {
                desempeno: "Tu último porcentaje de desempeño fue de %{evaluacionPorcentaje}%",
                soluciones: "Soluciones",
                volver_a_realizar: "Volver a realizar"
            },
            plan_personal_sesion: {
                anterior: "Anterior",
                siguiente: "Siguiente",
                siguiente_sesion: "Siguiente sesión",
                subtitulo: "Sesión",
                titulo: "Plan personal",
                titulo_evaluacion: "Evaluación",
                volver_planes_personales: "Volver a los Planes Personales",
                pronto: "Pronto",
                no_disponible: "Esta sección aún no se encuentra disponible",
                volver_plan: "Volver al plan"
            },
            plan_personal_sesiones: {
                ciclo: "Ciclo",
                ciclo_anterior: "Ciclo anterior",
                ciclo_siguiente: "Ciclo siguiente"
            },
            side_menu: {
                instrucciones: {
                    parrafo1: "Selecciona el tipo de ruta que te interesa y realiza la evaluación de diagnóstico.",
                    parrafo2:
                        "Evaluaremos qué áreas necesitas reforzar y te propondremos una ruta de estudio personal de acuerdo a tus resultados.",
                    parrafo3: "Completa las sesiones de esta ruta y luego reevalúa tus fortalezas y debilidades.",
                    titulo: "Instrucciones:"
                },
                select_tipo: "Seleccione un tipo",
                tipo_diagnostico: "Tipo de ruta adaptativa:",
                no_seleccionado_tipo_diagnostico: "No se ha seleccionado Tipo de diagnóstico:"
            },
            titulo: "Ruta adaptativa",
            tipo_diagnostico: "tipo de diagnóstico"
        },
        showcasebox: {
            material_favorito: {
                blurb: "¿Encontraste una guía y/o video que te gustó? Puedes agregarlo a tu lista de Favoritos.",
                lista: "Lista de favoritos",
                titulo: "Material favorito",
                ver: "Ver"
            },
            plan_estudios: {
                agregar: "Agregar Plan de Estudio",
                continuar: "Continuar",
                desactivar: "Desactivar",
                planesAdactativosTitulo: "Planes Adaptativos",
                planesDeEstudioTitulo: "Planes de estudio",
                titulo: "Mis Planes de estudio"
            }
        },
        streaming: {
            anteriores: "Clases Anteriores",
            horario: "Horario clases de streaming",
            materiales: {
                fecha: "Fecha de subida:",
                ver: "Ver",
                tipo_evaluacion: "Evaluación de ejercitación",
                tipo_guia_estudio: "Guía de estudio"
            },
            offline: "Transmisión offline.",
            relacionado: "Material relacionado",
            search: {
                search_placeholder: "Buscar por nombre...",
                todos: "Todos"
            },
            sin_canal: "No existe un canal asociado.",
            visitar_canal: `Puedes visitar nuestro canal
                                                <a href="https://www.youtube.com/channel/%{canal}?view_as=subscriber" target="_blank">
                                                                aquí
                                                </a>
                                                .`,
            vivo: "En vivo!",
            ultima_clase: "Última Clase"
        },
        titulo: "Estudiar"
    },
    base_topmenu: {
        alumno: {
            blog: "Blog",
            practica: "Practica",
            resultados: "Resultados",
            plan_de_clase: "Planes de clase",
            dudas_curso: "Dudas del curso",
            comunidad: "Comunidad",
            ejercitar: "Ejercitar",
            practicar: "Practicar",
            planes_adaptativos: "Diagnóstico - nivelación",
            centro_ayuda: "Centro de Ayuda",
            carreras: "Carreras",
            planes_de_clase: "Planes de Clase",
            planes_estudio: "Planes de Estudio",
            biblioteca: "Biblioteca"
        },
        profe: {
            evaluar: "Evaluar",
            corregir: "Corregir",
            resultados: "Resultados",
            planes_de_clase: "Planes de clase",
            mis_noticias: "Mis noticias",
            comunidad: "Comunidad",
            planes_clase_predefinido: "Planes de clase predefinido",
            centro_ayuda: "Centro de Ayuda",
            resumen_uso: "Resumen de uso",
            blog: "Blog"
        }
    }
}
