<div (window:resize)="checkResponsive()" #tablaContenedor>
    <loading-layout #loadingLayout>
        <div class="contenido">
            <cui-section-headline *ngIf="asignatura$ | async as asignatura">
                {{ evaluacionTipoAlias | transformInstrumento: "plural" | capitalize }} de
                {{ asignatura["confByEvaluacionTipo"][evaluacionTipo].abreviacion }}
                {{ "progreso.historial.pendientes.titulo" | t | genderize: evaluacionTipo }}
            </cui-section-headline>

            <table
                id="tabla_ensayos"
                class="table tabla_ensayos"
                [class.trigger-responsive]="enableResponsiveTable"
                *ngIf="evaluaciones.length > 0"
            >
                <thead>
                    <tr>
                        <th>{{ "progreso.historial.pendientes.forma" | t }}</th>
                        <th>{{ "progreso.historial.pendientes.nombre" | t }}</th>
                        <th>{{ "progreso.historial.pendientes.n_preguntas" | t }}</th>
                        <!-- <th>Oficial</th> -->
                        <!-- <th>Tipo Evaluación</th> -->
                        <!-- <th>Compartido por</th> -->
                        <!-- <th>Establecimiento</th> -->
                        <th>{{ "progreso.historial.pendientes.fecha_inicio" | t }}</th>
                        <th>{{ "progreso.historial.pendientes.fecha_fin" | t }}</th>
                        <!-- <th>Fecha publicación pauta</th> -->
                        <th>{{ "progreso.historial.pendientes.acciones" | t }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let evaluacion of evaluaciones; let i = index" [class.odd]="i % 2 == 1">
                        <th scope="row" class="row_header">
                            <span class="resp-th">{{ "progreso.historial.pendientes.forma" | t }}</span>
                            {{ evaluacion.id }}
                        </th>
                        <td>
                            <span class="resp-th">{{ "progreso.historial.pendientes.nombre" | t }}</span>
                            {{ evaluacion.evaluacion | easyplaceholder: "Evaluación sin nombre" }}
                        </td>
                        <td>
                            <span class="resp-th"></span>
                            {{ evaluacion.instrumento.numero_preguntas }}
                        </td>
                        <!-- <td><span class="resp-th">Oficial</span>{{ evaluacion.instrumento.oficial ? 'Sí' : 'No' }}</td> -->
                        <!-- <td><span class="resp-th">Tipo Evaluación</span>{{ evaluacion.evaluacion_tipo.evaluacion_tipo }}</td> -->
                        <!-- <td><span class="resp-th">Compartido por</span>{{ evaluacion.evaluacion_usuarios[0].usuario.nombreCompleto() }}</td> -->
                        <!-- <td><span class="resp-th">Establecimiento</span>{{ evaluacion.evaluacion_usuarios[0].grupo_usuario?.establecimiento?.establecimiento | easyplaceholder: "-"}}</td> -->
                        <td>
                            <span class="resp-th">{{ "progreso.historial.pendientes.fecha_inicio" | t }}</span>
                            {{ evaluacion.fecha_inicio | date: "dd-MM-yyyy h:mm a" }}
                        </td>
                        <td>
                            <span class="resp-th">{{ "progreso.historial.pendientes.fecha_fin" | t }}</span>
                            {{ evaluacion.fecha_termino | date: "dd-MM-yyyy h:mm a" }}
                        </td>
                        <!-- <td><span class="resp-th">Fecha para mostrar pauta</span>{{ evaluacion.fecha_mostrar_respuestas | date: "dd-MM-yyyy h:mm a" }}</td> -->
                        <td class="last">
                            <span class="resp-th">{{ "progreso.historial.pendientes.acciones" | t }}</span>
                            <cui-button-link
                                class="btn_style btn-sm tabla-btn"
                                *ngIf="!evaluacion.presencial"
                                [routerLinkValue]="[urlRealizar, evaluacion.id, 'realizar']"
                            >
                                {{ "progreso.historial.pendientes.realizar" | t }}
                            </cui-button-link>
                            <cui-button-link
                                *ngIf="!disableDownloadNotPropio || evaluacion.instrumento.propio"
                                class="btn_style btn-sm tabla-btn"
                                placement="right"
                                (click)="descargar(evaluacion)"
                            >
                                {{ "progreso.historial.pendientes.descargar" | t }}
                            </cui-button-link>
                            <!-- NO USAR DELAY distinto a 0 porque ngx-bootstrap tiene un bug que hace que cuando se va a una ruta sin haber pasado el delay se pega  -->
                            <cui-button-link
                                *ngIf="!hideButtonEscanear && !evaluacion.instrumento.calificacion_manual"
                                class="btn_style btn-sm tabla-btn"
                                [routerLinkValue]="['/evaluaciones', evaluacion.id, 'corregir']"
                                [tooltip]="tooltip"
                                placement="right"
                                [delay]="0"
                                containerClass="scan-tooltip"
                            >
                                {{ "progreso.historial.pendientes.escanear" | t }}
                            </cui-button-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <paginator *ngIf="asignatura" [getList]="showAll.bind(this)"></paginator>
        </div>
        <p class="no-data" *ngIf="evaluaciones.length == 0">{{ "progreso.historial.pendientes.not_found" | t }}</p>
    </loading-layout>
</div>

<div
    class="modal fade staticModal"
    bsModal
    #mandatoryLoading="bs-modal"
    [config]="{ backdrop: 'static', keyboard: false }"
    tabindex="0"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <loading-layout #loadingLayout></loading-layout>
                <p class="loading-message">
                    {{ "progreso.historial.pendientes.imprimir_1" | t }}
                    <br />
                    {{ "progreso.historial.pendientes.imprimir_2" | t }}
                </p>
            </div>
        </div>
    </div>
</div>
