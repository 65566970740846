<notificacion-layout
    [notificacion]="notificacion"
    [enableMini]="enableMini"
    [icono]="'pro'"
    [disableMarkButton]="disableMarkButton"
>
    <notificacion-contenido>
        <p class="texto-notificacion">
            {{ "notificaciones.pro1" | t }}{{ genero }} {{ nombre }}, {{ "notificaciones.pro2" | t }} {{ plataforma }} {{ "notificaciones.pro3" | t }}:
        </p>

        <ul>
            <li>
                {{ "notificaciones.pro_tutor" | t }}
                <a (click)="onClickLink(0)">{{ "notificaciones.aqui" | t }}</a>
                .
            </li>
            <li>
                {{ "notificaciones.pro_entrenador" | t }}
                <a (click)="onClickLink(1)">{{ "notificaciones.aqui" | t }}</a>
                .
            </li>
            <li>
                {{ "notificaciones.pro_imprimir" | t }}
                <a (click)="onClickLink(2)">{{ "notificaciones.aqui" | t }}</a>
                .
            </li>
            <li>
                {{ "notificaciones.pro_planes" | t }}
                <a (click)="onClickLink(3)">{{ "notificaciones.aqui" | t }}</a>
                .
            </li>
        </ul>
    </notificacion-contenido>
</notificacion-layout>
