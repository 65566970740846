<cui-section-headline>{{ "subir_respuestas_imagen_multiple.titulo" | t }}</cui-section-headline>
<div class="clearfix" id="step1">
    <div class="row">
        <div class="col-md-5">
            <div class="tutorial clearfix">
                <div class="tutorial-image">
                    <div class="tutorial-image-pos">
                        <div class="tutorial-image-cell">
                            <img
                                src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/correccion_tutorial_1.png"
                                alt="Imagen de la hoja de respuestas"
                            />
                        </div>
                    </div>
                    <div class="step-number">1</div>
                </div>
                <h3 class="step">{{ "subir_respuestas_imagen_multiple.titulo_step_1" | t }}</h3>
                <p class="tutorial-text">
                    {{ "subir_respuestas_imagen_multiple.desc_step_1" | t }}
                </p>
            </div>
            <modal-instructivo-subir-respuestas
                [openInstructivoImprimirEvent]="openInstructivoImprimirEvent"
            ></modal-instructivo-subir-respuestas>
        </div>
        <div class="col-md-7">
            <div class="form-group">
                <div class="box">
                    <input type="file" multiple (change)="onFileInputChange($event)" />
                    <p>{{ "subir_respuestas_imagen_multiple.file_box" | t }}</p>
                </div>
                <div class="form-control threshold-container">
                    <label for="checkbox-threshold">
                        <input
                            type="checkbox"
                            [ngModel]="enableThreshold$ | async"
                            (ngModelChange)="changeEnableThreshold($event)"
                            id="checkbox-threshold"
                        />
                        {{ "subir_respuestas_imagen_multiple.opcion_contraste" | t }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="(archivosOriginales$ | async).length > 0" class="step-container" id="step2">
    <div class="procesando">
        <div class="progress">
            <div class="progress-bar" role="progressbar" [ngStyle]="{ width: progressWidth$ | async }">
                <span>
                    {{
                        "subir_respuestas_imagen_multiple.progreso"
                            | t: { n: (processedFiles$ | async) + "/" + (archivosOriginales$ | async).length }
                    }}
                </span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="(archivosBad$ | async).length > 0 || (archivosNotImages$ | async).length > 0" class="step-container">
    <div class="panel-group">
        <div class="panel panel-warning">
            <div class="panel-heading">
                <h4 class="panel-title">
                    <a data-toggle="collapse" href="#collapse1">
                        {{ "subir_respuestas_imagen_multiple.titulo_malos" | t }}
                    </a>
                </h4>
            </div>
            <div id="collapse1" class="panel-collapse collapse">
                <div class="panel-body">
                    <div *ngIf="(archivosNotImages$ | async).length > 0">
                        <b>{{ "subir_respuestas_imagen_multiple.archivos_no_imagenes" | t }}</b>
                        <li *ngFor="let e of archivosNotImages$ | async">{{ e.name }}</li>
                    </div>
                    <div *ngIf="(archivosBad$ | async).length > 0">
                        <b>{{ "subir_respuestas_imagen_multiple.imagenes_malas" | t }}</b>
                        <li *ngFor="let e of archivosBad$ | async">{{ e.name }}</li>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- select -->
<div class="step-container" [hidden]="!((processedAll$ | async) && (archivosGood$ | async).length > 0)">
    <div class="tutorial clearfix">
        <div class="tutorial-image">
            <div class="tutorial-image-pos">
                <div class="tutorial-image-cell">
                    <img
                        src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/correccion_tutorial_3.png"
                        alt="Revisar respuestas"
                    />
                </div>
            </div>
            <div class="step-number">2</div>
        </div>
        <h3 class="step">{{ "subir_respuestas_imagen_multiple.titulo_step_2" | t }}</h3>
        <p class="tutorial-text">
            {{ "subir_respuestas_imagen_multiple.desc_step_2" | t }}
        </p>
        <div class="clearfix">
            <button
                type="button"
                class="next btn btn-default btn-sm"
                href="#step3"
                easyScrollTo
                [easyScrollToPadding]="0"
                [easyScrollToDuration]="800"
                [easyScrollToEasing]="'easeInOut'"
            >
                {{ "subir_respuestas_imagen_multiple.siguiente" | t }}&nbsp;
                <fa name="caret-right"></fa>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <label>{{ "subir_respuestas_imagen_multiple.forma_global" | t }}</label>
            <fa
                [name]="'question-circle'"
                placement="top"
                tooltip="{{ 'subir_respuestas_imagen_multiple.tooltip_forma_global' | t }}"
            ></fa>
            &nbsp;
            <input
                type="number"
                [ngModel]="formaGlobal$ | async"
                (ngModelChange)="changeFormaGlobal($event)"
                class="valor forma-global form-control"
            />
            <br />
        </div>
        <div class="col-md-12">
            {{ "subir_respuestas_imagen_multiple.selecciona_imagen" | t }}
            <div class="input-group">
                <span class="input-group-btn">
                    <button class="btn btn-default form-control" (click)="previousImage()">
                        <fa class="big" name="arrow-left"></fa>
                    </button>
                </span>
                <select
                    *ngIf="processedAll$ | async"
                    class="form-control"
                    [ngModel]="selectedFile$ | async"
                    (ngModelChange)="changeSelectedFile($event)"
                >
                    <option *ngFor="let obj of archivosGood$ | async; let i = index" [value]="obj.pos" [selected]="i == (selectedFile$ | async)">
                        [{{ i + 1 }}] {{ obj.file.name }}
                    </option>
                </select>
                <span class="input-group-btn">
                    <button class="btn btn-default form-control" (click)="nextImage()">
                        <fa class="big" name="arrow-right"></fa>
                    </button>
                </span>
            </div>
        </div>
        <div class="col-md-8" [hidden]="false">
            <div class="canvas-container">
                <h3>{{ "subir_respuestas_imagen_multiple.hoja_respuesta" | t }}</h3>
                <canvas
                    *ngFor="let file of archivosOriginales$ | async; let i = index"
                    #canvas
                    (click)="onClickCanvasWithIndex(i, $event)"
                    id="canvas"
                    style="width: 100%"
                    [class.hide]="i != (selectedFile$ | async)"
                ></canvas>
            </div>
        </div>
        <div
            *ngFor="let obj of archivosGood$ | async; trackBy: getPos"
            class="col-md-4"
            [hidden]="obj.pos != (selectedFile$ | async)"
        >
            <div class="resultados-container">
                <h3>{{ "subir_respuestas_imagen_multiple.resultados" | t }}</h3>
                <div class="forma datos" [class.wrong-data]="obj.forma.indexOf(',') != -1">
                    <label>{{ "subir_respuestas_imagen_multiple.forma" | t }}</label>
                    &nbsp;
                    <subir-respuestas-valor-feedback
                        *ngIf="!obj.editing"
                        [valor]="obj.forma"
                        class="valor"
                    ></subir-respuestas-valor-feedback>
                    <input
                        type="text"
                        *ngIf="obj.editing"
                        [ngModel]="obj.forma"
                        (ngModelChange)="changeForma(obj.pos, $event)"
                        class="valor edit-forma form-control"
                    />

                    <cui-button class="btn_style pull-right btn-sm" *ngIf="!obj.editing" (click)="enableEditObj(obj)">
                        <fa name="pencil"></fa>
                        {{ "subir_respuestas_imagen_multiple.editar" | t }}
                    </cui-button>
                    <cui-button class="btn_style pull-right btn-sm" *ngIf="obj.editing" (click)="disableEditObj(obj)">
                        <fa name="pencil"></fa>
                        {{ "subir_respuestas_imagen_multiple.listo" | t }}
                    </cui-button>
                </div>
                <div *ngIf="obj.formaType">
                    <label>{{ "subir_respuestas_imagen_multiple.tipo_forma" | t }}</label>
                    &nbsp;
                    <subir-respuestas-valor-feedback
                        *ngIf="!obj.editing"
                        [valor]="obj.formaType"
                        class="valor"
                    ></subir-respuestas-valor-feedback>
                    <input
                        type="text"
                        *ngIf="obj.editing"
                        [(ngModel)]="obj.formaType"
                        class="valor edit-forma form-control"
                    />
                </div>
                <div class="rut datos" [class.wrong-data]="obj.idValue.join('-').indexOf(',') != -1">
                    <label>{{ identificadorUsuario }}:</label>
                    &nbsp;
                    <subir-respuestas-valor-feedback
                        *ngIf="!obj.editing"
                        [valor]="obj.idValue.join('-')"
                        class="valor"
                    ></subir-respuestas-valor-feedback>

                    <ng-container *ngFor="let part of obj.idValue; let i = index; trackBy: getIndex">
                        <input
                            type="text"
                            *ngIf="obj.editing"
                            [ngModel]="obj.idValue[i]"
                            (ngModelChange)="changeIdValuePart(obj.pos, i, $event)"
                            class="valor edit-id form-control"
                        />
                        <ng-container *ngIf="i != obj.idValue.length - 1 && obj.editing">-</ng-container>
                    </ng-container>
                </div>

                <div class="multiple-col">
                    <ul class="lista-resultados">
                        <li *ngFor="let respuestaIndex of obj.respuestas | keys" class="respuesta">
                            <span class="numero">{{ parseInt(respuestaIndex) + 1 }}:</span>
                            &nbsp;
                            <subir-respuestas-valor-feedback
                                *ngIf="!obj.editing"
                                [valor]="obj.respuestas[respuestaIndex] | easyplaceholder: '-'"
                                class="valor"
                            ></subir-respuestas-valor-feedback>

                            <input
                                type="text"
                                *ngIf="obj.editing"
                                [ngModel]="obj.respuestas[respuestaIndex]"
                                (ngModelChange)="changeRespuesta(obj.pos, respuestaIndex, $event)"
                                class="valor edit-respuesta form-control"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="step3" [hidden]="!((processedAll$ | async) && (archivosGood$ | async).length > 0)">
    <div class="step-container">
        <div class="tutorial clearfix">
            <div class="tutorial-image">
                <div class="tutorial-image-pos">
                    <div class="tutorial-image-cell">
                        <img
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/correccion_tutorial_2.png"
                            alt="Revisar respuestas"
                        />
                    </div>
                </div>
                <div class="step-number">3</div>
            </div>
            <h3 class="step">{{ "subir_respuestas_imagen_multiple.titulo_step_3" | t }}</h3>
            <p class="tutorial-text">{{ "subir_respuestas_imagen_multiple.desc_step_3" | t }}</p>
            <br />
            <div *ngIf="(archivosOriginales$ | async).length > 0" class="step-container">
                <div class="procesando">
                    <div class="progress">
                        <div
                            class="progress-bar"
                            role="progressbar"
                            [ngStyle]="{ width: uploadProgressWidth$ | async }"
                        >
                            <span>
                                {{
                                    "subir_respuestas_imagen_multiple.progreso"
                                        | t: { n: (lecturasProcesadas$ | async) + "/" + (lecturasTotal$ | async) }
                                }}
                                {{ lecturasProcesadas$ | async }}/{{ lecturasTotal$ | async }} archivos procesados
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                *ngFor="let obj of archivosGood$ | async"
                class="warning hide-warning"
                [class.show-warning]="obj.messages && obj.messages.warnings.length > 0 && !obj.loadingMessages"
            >
                <div class="warning-content">
                    <fa name="warning" class="warning-icon"></fa>
                    <div class="warning-message">
                        <p
                            [innerHTML]="
                                'subir_respuestas_imagen_multiple.warning_no_completado'
                                    | t: { fileName: obj.file.name }
                            "
                        ></p>
                        <ul *ngIf="obj.messages">
                            <li *ngFor="let warning of obj.messages.warnings">{{ warning }}</li>
                        </ul>
                        <p *ngIf="obj.goToViewPath">
                            <a [routerLink]="[obj.goToViewPath]">
                                {{ "subir_respuestas_imagen_multiple.ver_resultados" | t }}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <div
                *ngFor="let obj of archivosGood$ | async"
                class="success hide-warning"
                [class.show-warning]="obj.messages && obj.messages.warnings.length == 0 && !obj.loadingMessages"
            >
                <div class="warning-content">
                    <fa name="smile-o" class="warning-icon"></fa>
                    <div class="warning-message">
                        <p>
                            <span
                                [innerHTML]="
                                    'subir_respuestas_imagen_multiple.aviso_completado' | t: { fileName: obj.file.name }
                                "
                            ></span>
                            <a *ngIf="obj.datosBoton" class="clickable" (click)="botonContinuar(obj)">
                                {{ link_message }}
                            </a>
                        </p>
                        <p *ngIf="obj.goToViewPath">
                            <a [routerLink]="[obj.goToViewPath]">
                                {{ "subir_respuestas_imagen_multiple.ver_resultados" | t }}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="clearfix">
                <cui-button class="btn_style pull-right" (click)="subirLecturas()">
                    <fa name="upload"></fa>
                    &nbsp;{{ "subir_respuestas_imagen_multiple.subir_resultados" | t }}
                </cui-button>
                <cui-button type="button" class="btn_style pull-right" (click)="reset()">
                    <fa name="refresh"></fa>
                    &nbsp;{{ "subir_respuestas_imagen_multiple.nueva_carga" | t }}
                </cui-button>
                <loading-layout
                    #loadingLayoutSubir
                    [loaderType]="'default-small'"
                    class="loader-upload"
                ></loading-layout>
            </div>
        </div>
    </div>
</div>
