<generic-modal #genericModal [buttonClicked]="openModal" [unskipable]="true">
    <modal-titulo>{{ "request_reset_password.titulo" | t }}</modal-titulo>
    <modal-contenido class="modal-contenido">
        <loading-layout #loadingLayout [opaque]="true">
            <p>{{ "request_reset_password.parrafo1" | t }}</p>
            <p>{{ "request_reset_password.parrafo2" | t }}</p>
            <br />
            <div class="form-group" *ngIf="hasPassword">
                <label for="current_password">{{ "request_reset_password.label_password_actual" | t }}</label>
                <input type="password" [(ngModel)]="current_password" id="current_password" class="form-control" />
                <div class="mensaje-error-input" *ngIf="currentPaswordMsg">
                    <p>{{ currentPaswordMsg }}</p>
                </div>
            </div>
            <div class="form-group">
                <label for="password">{{ "request_reset_password.label_password_nueva" | t }}</label>
                <input
                    type="password"
                    #passwordControl="ngModel"
                    [(ngModel)]="password"
                    (ngModelChange)="isBreachedPassword(passwordControl)"
                    id="password"
                    class="form-control"
                />
                <div
                    class="mensaje-error-input"
                    *ngIf="(password && password.length < passwordMinLength) || invalidPassword || breachedPassword"
                >
                    <p *ngIf="(password && password.length < passwordMinLength) || invalidPassword">
                        {{
                            "request_reset_password.error_tamano_password_nueva"
                                | t: { passwordMinLength: passwordMinLength }
                        }}
                    </p>

                    <p *ngIf="breachedPassword">{{ "request_reset_password.error_vulnerable_password_nueva" | t }}</p>
                </div>
                <password-strength-bar *ngIf="password" [passwordToCheck]="password"></password-strength-bar>
            </div>
            <div class="form-group">
                <label for="password_confirmation">{{ "request_reset_password.label_password_confirmar" | t }}</label>
                <input
                    type="password"
                    [(ngModel)]="password_confirmation"
                    id="password_confirmation"
                    class="form-control"
                />
                <div
                    class="mensaje-error-input"
                    *ngIf="
                        (password_confirmation && password_confirmation.length < passwordMinLength) ||
                        invalidPassword ||
                        passwordConfirmationMsg
                    "
                >
                    <p
                        *ngIf="
                            (password_confirmation && password_confirmation.length < passwordMinLength) ||
                            invalidPassword
                        "
                    >
                        {{
                            "request_reset_password.error_tamano_password_nueva"
                                | t: { passwordMinLength: passwordMinLength }
                        }}
                    </p>
                    <p *ngIf="passwordConfirmationMsg">{{ passwordConfirmationMsg }}</p>
                </div>
            </div>
            <p *ngIf="success" class="success-message">
                <fa name="smile-o" class="success-icon"></fa>
                <span [innerHTML]="'request_reset_password.save_success' | t"></span>
            </p>
        </loading-layout>
    </modal-contenido>
    <modal-botones>
        <cui-button *ngIf="!success" [disabled]="loading" (click)="changePassword()">
            {{ "request_reset_password.btn_save" | t }}
        </cui-button>
    </modal-botones>
</generic-modal>
