import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { MonitoreoInstrumentoPredefinido } from "./monitoreo_instrumento_predefinidos.model"

@Injectable()
export class MonitoreoInstrumentoPredefinidos extends PuntajeBaseService<MonitoreoInstrumentoPredefinido> {
    tableName = "monitoreo_instrumento_predefinidos"
    singularTableName = "monitoreo_instrumento_predefinido"
    modelClass = MonitoreoInstrumentoPredefinido

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
