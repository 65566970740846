import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"

export class UsuarioPaa extends BaseModel {
    public paa_tomada: boolean
    public year: number
    public ubicacion: string
    public resultado_matematicas: number
    public resultado_lectura_redaccion: number
    public resultado_ingles: number
    public created_at: Date
    public updated_at: Date
    public usuario_id: number
    public omitido: boolean

    @SaveType(() => Usuario) usuario: Usuario
}
