import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Curriculum } from "./curriculums.model"
import { Usuario } from "./usuarios.model"

export class CertificacionRecurso extends BaseModel {
    public static className: string = "CertificacionRecurso"
    public usuario_id: number
    public recurso_type: string
    public recurso_id: number
    public curriculum_id: number

    public created_at: string

    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => Curriculum) curriculum: Curriculum
}
