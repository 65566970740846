import { enableProdMode, Type } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"

import { AppConfiguration, AppEnvironment } from "@puntaje/shared/core"

export function bootstrapApp<T>(
    name: string,
    environment: AppEnvironment,
    config: AppConfiguration,
    AppModule: Type<T>
) {
    if (environment.production) {
        enableProdMode()
    }

    fetch(`${environment.endpoints.base}/configuraciones?configuracion[plataforma]=${config.plataforma.name}`)
        .then(res => {
            return res.json()
        })
        .then(configuraciones => {
            if (configuraciones[0]) {
                const config = configuraciones[0].configuracion
                config.app.name = name
                window["config"] = config

                const configToEnvironment = {
                    googleAnalyticsPropertyId: config.app.googleAnalyticsPropertyId,
                    facebookAnalyticsPropertyId: config.app.facebookAnalyticsPropertyId,
                    googleTagManagerPropertyId: config.app.googleTagManagerPropertyId,
                    globalSiteTag: config.app.globalSiteTag,
                    domain: config.app.domain,
                    facebook: config.app.facebook,
                    recaptcha: config.app.recaptcha
                }

                Object.assign(environment, configToEnvironment)

                platformBrowserDynamic()
                    .bootstrapModule(AppModule)
                    .catch(err => console.error(err))
            }
        })
}
