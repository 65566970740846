import { Injectable } from "@angular/core"
import { I18nService } from "@puntaje/shared/core"
import { Subject } from "rxjs"

declare var $: any //MOST PRETTY LINE

@Injectable()

//interface callbackType { (myArgument: string): void }
export class SimpleModalService {
    public callback: () => void
    public cancelback: () => void
    public header: string
    public contentString: string
    public contentHTML: string
    public callbackBtnText: string = this.i18nService.translate("simple_modal.ok")
    public disableBtnCancel: boolean = false
    public hideBtnX: boolean = false
    public dataKeyboard: boolean = true
    public dataBackdropStatic: boolean = false

    public headerSubject: Subject<any> = new Subject()
    public contentStringSubject: Subject<any> = new Subject()
    public contentHTMLSubject: Subject<any> = new Subject()
    public callbackBtnTextSubject: Subject<any> = new Subject()
    public disableBtnCancelSubject: Subject<any> = new Subject()
    public hideBtnXSubject: Subject<any> = new Subject()

    constructor(protected i18nService: I18nService) {}

    setModalHeader(header: string) {
        this.header = header
        this.headerSubject.next(this.header)
    }

    setModalStringContent(contentString: string) {
        this.contentString = contentString
        this.contentStringSubject.next(this.contentString)
    }

    setModalHTMLContent(contentHTML: string) {
        this.contentHTML = contentHTML
        this.contentHTMLSubject.next(this.contentHTML)
    }

    setModalCallbackBtnText(callbackBtnText: string) {
        this.callbackBtnText = callbackBtnText
        this.callbackBtnTextSubject.next(this.callbackBtnText)
    }

    setModalCallback(callback: () => void) {
        this.callback = callback
    }

    cancelModalCallback(cancelback: () => void) {
        this.cancelback = cancelback
    }

    setDisableBtnCancel(disable = false) {
        this.disableBtnCancel = disable
        this.disableBtnCancelSubject.next(disable)
    }

    setHideBtnX(hide = false) {
        this.hideBtnX = hide
        this.hideBtnXSubject.next(this.hideBtnX)
    }

    setDataKeyboard(dataKeyboard = true) {
        this.dataKeyboard = dataKeyboard
    }

    setDataBackdropStatic(dataBackdropStatic = true) {
        this.dataBackdropStatic = dataBackdropStatic
    }

    toggleSimpleModal() {
        $("#simpleModal").modal("toggle")
    }

    showSimpleModal() {
        $("#simpleModal").modal({ backdrop: this.dataBackdropStatic ? "static" : true })
        $("#simpleModal").modal("show")
    }

    closeSimpleModal() {
        $("#simpleModal").modal("hide")
    }

    cleanModal() {
        this.callback = null
        this.cancelback = null
        this.header = ""
        this.contentString = ""
        this.contentHTML = null
        this.callbackBtnText = this.i18nService.translate("simple_modal.ok")
        this.dataBackdropStatic = true

        this.setDisableBtnCancel(false)
        this.setHideBtnX(false)
    }
}
