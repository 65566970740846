import { Component, Input, OnInit } from "@angular/core"

@Component({
    selector: "ogr-icon",
    template: `
        <span class="ogr-icon-{{ name }} positioning">
            <ng-container *ngIf="paths">
                <span *ngFor="let p of paths; let i = index" class="path path{{ i + 1 }}"></span>
            </ng-container>
        </span>
    `,
    styles: [
        ":host { display: inline-block; }",
        ".positioning { display: inline-block; line-height: 1; font-size: inherit;}",
        ".positioning:before { vertical-align: top; }"
    ],
    host: {
        "[style.vertical-align]": "align"
    }
})
export class OGRIconComponent implements OnInit {
    @Input() name: string = ""
    @Input() align: string = "initial"
    multicolors = {
        "redaccion-cb": 3,
        "lectura-cb": 7,
        "ingles-cb": 9,
        "lectura-y-redaccion-cb": 6,
        "matematica-cb": 12,
        "prueba-cb": 11
    }
    paths: number[]

    ngOnInit() {
        if (this.multicolors[this.name]) this.paths = Array(this.multicolors[this.name]).fill(0)
    }
}
