import { Component, OnInit } from "@angular/core"

@Component({
    selector: "popup-cookies",
    templateUrl: "./popup_cookies.component.html",
    styleUrls: ["./popup_cookies.component.scss"]
})
export class PopupCookiesComponent implements OnInit {
    open: boolean = true

    constructor() {}

    ngOnInit(): void {
        this.open = !localStorage.getItem("cookiesAccepted")
    }

    close() {
        this.open = false
        localStorage.setItem("cookiesAccepted", "true")
    }

    closeWithoutAccepting() {
        this.open = false
    }
}
