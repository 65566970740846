import { NgModule, ModuleWithProviders, Type } from "@angular/core"
import { HttpClientModule } from "@angular/common/http"
import { RouterModule } from "@angular/router"
import { Loader } from "./loader.service"

@NgModule({
    imports: [HttpClientModule],
    declarations: [],
    exports: []
})
export class LoaderModule {
    static forRoot(customLoader: Type<Loader> = Loader): ModuleWithProviders<LoaderModule> {
        return {
            ngModule: LoaderModule,
            providers: [{ provide: Loader, useClass: customLoader }]
        }
    }
}
