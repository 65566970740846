<div class="checkbox form-subeje tooltip-container">
    <label>
        <input
            type="checkbox"
            checked="checked"
            [(ngModel)]="clasificacion.selected"
            (ngModelChange)="onClasificacionChange(clasificacion, clasificacionPadre)"
            name="clasificacion_{{ clasificacion.id }}"
        />
        <span class="checkmark"><fa class="icon" name="check"></fa></span>
        {{ clasificacion.clasificacion }}
        {{ clasificacion.medible ? "" : " (No medible)" }}
        {{ enableObjetivoPriorizado && clasificacion.priorizado ? " (Aprendizaje basal)" : "" }}
        <ng-container *ngIf="concats && clasificacion[concats[clasificacion.clasificacion_tipo.clasificacion_tipo]]">
            ({{ clasificacion[concats[clasificacion.clasificacion_tipo.clasificacion_tipo]]?.clasificacion }})
        </ng-container>
    </label>
    <div class="right-side">
        <div
            *ngIf="enableCantidadPreguntas"
            class="numero-preguntas"
            tooltip="{{ clasificacion.cantidad_preguntas }} pregunta{{
                clasificacion.cantidad_preguntas != 1 ? 's' : ''
            }}"
        >
            {{ clasificacion.cantidad_preguntas }} p.
        </div>
        <div class="tooltip-btn" *ngIf="clasificacion.descripcion">
            <button [toggleExpandable]="'subcla-' + id + '-' + clasificacion.id" type="button" class="btn btn-link">
                {{ "general.detalles" | t }}
            </button>
        </div>

        <button
            *ngIf="clasificacion.clasificaciones_hijas && clasificacion.clasificaciones_hijas.length != 0"
            type="button"
            class="tog-icon-2 btn btn-link"
            toggleExpandable="subclasificaciones_{{ id }}_{{ clasificacion.id }}"
        >
            <span class="plus">
                {{ "general.ver_mas" | t }}
            </span>
            <span class="minus">{{ "general.ver_menos" | t }}</span>
        </button>
    </div>
    <div
        class="descripcion-exp"
        id="subcla-{{ id }}-{{ clasificacion.id }}"
        expandableContent
        [attr.data-mutual-exclusive]="'cla-' + clasificacionPadre.id"
    >
        <div class="descripcion-inner">
            <div
                class="descripcion-sub"
                [innerHtml]="clasificacion.descripcion | clearLatex | katex | trustedhtml"
            ></div>
        </div>
    </div>
</div>
<div
    *ngIf="clasificacion.clasificaciones_hijas && clasificacion.clasificaciones_hijas.length != 0"
    class="checkbox-list small-checkboxes"
    id="subclasificaciones_{{ id }}_{{ clasificacion.id }}"
    expandableContent
>
    <div class="checkbox-hijo-container">
        <clasificacion-checkboxes
            *ngFor="let c of clasificacion.clasificaciones_hijas"
            [id]="id"
            [clasificacion]="c"
            [clasificacionPadre]="clasificacion"
            [concats]="concats"
            [enableCantidadPreguntas]="enableCantidadPreguntas"
            [enableObjetivoPriorizado]="enableObjetivoPriorizado"
            (clasificacionChange)="onClasificacionChange($event.clasificacion, $event.clasificacionPadre)"
        ></clasificacion-checkboxes>
    </div>
</div>
