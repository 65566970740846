import { BaseForm, Validations, GroupValidator, AppConfig } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"

import { Clasificaciones } from "@puntaje/nebulosa/api-services"

import { UsuarioChileForm } from "./usuario_chile.form"
import { UsuarioColombiaForm } from "./usuario_colombia.form"
import { HttpClient } from "@angular/common/http"

declare const config: AppConfig

export class UsuarioRegistroColombiaForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    companyName: string = ""
    // constructor(){
    //  super();
    //  this.companyName = config.plataforma.info.companyName;
    // }

    public static get formParams(): any {
        // let companyName = this.companyName;
        let companyName = config.plataforma.info.companyName
        let femenino = { id: 0, toString: () => "Femenino" }
        let masculino = { id: 1, toString: () => "Masculino" }

        let params: any = {
            id: { label: "ID", type: "hidden", visible: false },
            nombre: {
                label: "Nombre",
                type: "text",
                visible: true,
                placeholder: "Nombre",
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: "Apellido paterno",
                type: "text",
                visible: true,
                placeholder: "Apellido paterno",
                validations: [Validators.required, Validators.minLength(2)]
            },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                placeholder: "correo@mail.com",
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmail }]
            },
            password: {
                label: "Contraseña",
                type: "password",
                visible: true,
                placeholder: "Contraseña",
                validations: [Validators.minLength(8)],
                serviceValidators: [{ class: HttpClient, validator: Validations.checkPasswordBreach }]
            },
            password_confirmation: {
                label: "Confirmar contraseña",
                type: "password",
                visible: true,
                placeholder: "Repetir contraseña",
                global: true,
                validations: [Validators.minLength(8)]
            },
            terminos_y_condiciones: {
                innerhtml:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de " +
                    companyName +
                    "</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            },
            egresado: { label: "Egresado", type: "checkbox", visible: true },
            establecimiento: { label: "Colegio", type: "text", visible: true, validations: [] },
            nivel_id: {
                label: "Grado",
                type: "select",
                visible: true,
                options: {
                    class: Clasificaciones,
                    params: { clasificacion_tipo: { clasificacion_tipo: "nivel colombia" } }
                },
                validations: [Validators.required]
            },
            usuario_colombia: { type: "model", class: UsuarioColombiaForm }
        }

        if (config.registro && config.registro.enablePoliticasPrivacidad) {
            params["politicas_privacidad"] = {
                innerhtml:
                    "He leído y acepto las <a href='/politicas_privacidad_y_proteccion_datos' target='_blank'>políticas de privacidad y protección de datos personales</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar las políticas de privacidad y protección de datos personales"
            }
        }

        return params
    }
}
