import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { UtilModule, ModelViewModule } from "@puntaje/shared/core"
import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { LayoutsModule } from "@puntaje/puntaje/new-modules/layouts"
// Alumnos
import { LoggedLayoutComponent } from "./logged_layout/logged_layout.component"
import { RestrictedLayoutComponent } from "./restricted_layout/restricted_layout.component"
import { EvaluationLayoutComponent } from "./evaluation_layout/evaluation_layout.component"
import { NavbarComponent } from "./navbar/navbar.component"
import { SidemenuComponent } from "./sidemenu/sidemenu.component"
import { TitlebarComponent, TitlebarComponentTags } from "./titlebar/titlebar.component"
import { FooterComponent } from "./footer/footer.component"
import { TopmenuComponent } from "./topmenu/topmenu.component"
import {
    ProPromotionLayoutComponent,
    ProPromotionLayoutComponentTags
} from "./pro_promotion_layout/pro_promotion_layout.component"
import {
    BrandedEvaluationLayoutComponent,
    BrandedEvaluationLayoutComponentTags
} from "./branded_layouts/branded_evaluation_layout.component"
// Utils
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { TooltipModule } from "ngx-bootstrap"
// Commons
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { TypeformWidgetsModule } from "@puntaje/puntaje/new-modules/typeform-widgets"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilModule,
        ModelViewModule,
        Angular2FontawesomeModule,
        TooltipModule,
        CommonsLayoutsModule,
        RouterModule,
        NotificacionesModule,
        LayoutsModule,
        TypeformWidgetsModule,
        CUILayoutsModule
    ],
    declarations: [
        LoggedLayoutComponent,
        RestrictedLayoutComponent,
        NavbarComponent,
        SidemenuComponent,
        TitlebarComponent,
        TitlebarComponentTags,
        FooterComponent,
        EvaluationLayoutComponent,
        TopmenuComponent,
        ProPromotionLayoutComponent,
        ProPromotionLayoutComponentTags,
        BrandedEvaluationLayoutComponent,
        BrandedEvaluationLayoutComponentTags
    ],
    exports: [
        LoggedLayoutComponent,
        RestrictedLayoutComponent,
        NavbarComponent,
        SidemenuComponent,
        TitlebarComponent,
        TitlebarComponentTags,
        FooterComponent,
        EvaluationLayoutComponent,
        CommonsLayoutsModule,
        TopmenuComponent,
        ProPromotionLayoutComponent,
        ProPromotionLayoutComponentTags,
        BrandedEvaluationLayoutComponent,
        BrandedEvaluationLayoutComponentTags
    ]
})
export class AlumnosLayoutsModule {
    static forRoot(): ModuleWithProviders<AlumnosLayoutsModule> {
        return {
            ngModule: AlumnosLayoutsModule,
            providers: []
        }
    }
}
