import { Validators } from "@angular/forms"
import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Asignatura } from "./asignaturas.model"
import { AsignaturaPlataforma } from "./asignatura_plataformas.model"
import { Material } from "./materiales.model"
import { TipoInstrumento } from "./tipo_instrumentos.model"

export class Plataforma extends BaseModel {
    public static className: string = "Plataforma"
    public plataforma: string
    public created_at: Date
    public updated_at: Date

    @SaveType(() => AsignaturaPlataforma) public asignatura_plataformas: AsignaturaPlataforma[]
    @SaveType(() => Material) public materiales: Material[]
    @SaveType(() => Asignatura) public asignaturas: Asignatura[]
    @SaveType(() => TipoInstrumento) public tipo_instrumentos: TipoInstrumento[]

    constructor() {
        super()
        this.materiales = []
        this.asignatura_plataformas = []
    }

    public static get model_params(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            plataforma: {
                label: "Plataforma",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }

    public toString() {
        return this.plataforma
    }

    public getModelName() {
        return "Plataforma"
    }
}
