import { OnInit, Renderer2, Directive, Component } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { Angulartics2GoogleAnalytics } from "angulartics2/ga"
import { Angulartics2GoogleTagManager } from "angulartics2/gtm"
import { Angulartics2Facebook } from "angulartics2/facebook"
import { Angulartics2GoogleGlobalSiteTag } from "angulartics2/gst"
import { PuntajeAuthService } from "@puntaje/puntaje/api-services"
import { AppConfig, AppEnv, AuthService, CableService } from "@puntaje/shared/core"
import { Title, Meta } from "@angular/platform-browser"

declare let Highcharts: any
declare let ga: any
declare let gtag: any
declare let fbq: any
declare let $: any

@Component({
    selector: "puntaje",
    template: "<css-config><main-layout><router-outlet></router-outlet></main-layout></css-config>"
})
export class AppComponent implements OnInit {
    enable_loading: boolean
    userProfile: string = "profile1"
    intervalId: number

    constructor(
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected cableService: CableService,
        protected angularticsGa: Angulartics2GoogleAnalytics,
        protected angularticsGtm: Angulartics2GoogleTagManager,
        protected angularticsFb: Angulartics2Facebook,
        protected renderer: Renderer2,
        protected authService: AuthService,
        protected angularticsGst: Angulartics2GoogleGlobalSiteTag,
        protected config: AppConfig,
        protected environment: AppEnv,
        protected title: Title,
        protected meta: Meta
    ) {
        this.auth.profileChanged.subscribe(value => {
            this.switchProfileClassOnBody()
        })

        this.renderer.listen("body", "mousedown", event => {
            document.body.classList.add("using-mouse")
        })

        this.renderer.listen("body", "keydown", event => {
            if (event.keyCode == 9) {
                document.body.classList.remove("using-mouse")
            }
        })
    }

    ngOnInit() {
        if (this.environment.googleTagManagerPropertyId?.[this.config.app.name]) {
            const windowRef: any = window
            windowRef.dataLayer = windowRef.dataLayer || []
            windowRef.dataLayer.push({
                "gtm.start": new Date().getTime(),
                event: "gtm.js"
            })

            try {
                const script = document.createElement("script")
                script.async = true
                script.src =
                    "https://www.googletagmanager.com/gtm.js?id=" +
                    this.environment.googleTagManagerPropertyId[this.config.app.name]
                document.head.prepend(script)
            } catch (e) {}
            try {
                const noscript = document.createElement("noscript")
                const iframe = document.createElement("iframe")
                iframe.src =
                    "https://www.googletagmanager.com/ns.html?id=" +
                    this.environment.googleTagManagerPropertyId[this.config.app.name]
                noscript.appendChild(iframe)
                document.body.prepend(noscript)
            } catch (error) {}

            this.angularticsGtm.startTracking()
        }

        if (this.environment.globalSiteTag?.[this.config.app.name]?.propertyId) {
            const windowRef: any = window
            windowRef.dataLayer = windowRef.dataLayer || []
            windowRef.gtag = function () {
                windowRef.dataLayer.push(arguments)
            }

            let propertyIds = Array.isArray(this.environment.globalSiteTag[this.config.app.name].propertyId)
                ? this.environment.globalSiteTag[this.config.app.name].propertyId
                : [this.environment.globalSiteTag[this.config.app.name].propertyId]

            propertyIds.forEach((propertyId, index) => {
                const script = document.createElement("script")
                script.async = true

                script.src = "https://www.googletagmanager.com/gtag/js?id=" + propertyId

                document.head.prepend(script)
                if (index == 0) {
                    gtag("js", new Date())
                }

                gtag("config", propertyId)
            })

            this.angularticsGst.startTracking()
        }

        if (this.environment.googleAnalyticsPropertyId?.[this.config.app.name]) {
            ga("create", this.environment.googleAnalyticsPropertyId[this.config.app.name], "auto")
            this.angularticsGa.startTracking()
            this.listenToRoutes()
        }

        if (this.environment.facebookAnalyticsPropertyId?.[this.config.app.name]) {
            fbq("init", this.environment.facebookAnalyticsPropertyId[this.config.app.name])
            fbq("track", "PageView")
            this.angularticsFb.startTracking()
        }

        if (window.matchMedia) {
            // chrome & safari (ff supports it but doesn't implement it the way we need)
            var mediaQueryList = window.matchMedia("print")

            mediaQueryList.addEventListener("change", mql => {
                if (mql.matches) {
                    this.reflowForPrinting()
                } else {
                    this.reflowAfterPrinting()
                }
            })
        }

        window.addEventListener("beforeprint", ev => {
            this.reflowForPrinting()
        })

        window.addEventListener("afterprint", ev => {
            this.reflowAfterPrinting()
        })

        this.setHightchartsLocales()

        this.switchProfileClassOnBody()
        this.router.events.subscribe(evt => {
            if (!(evt instanceof NavigationEnd)) {
                return
            }
            document.body.scrollTop = 0
        })

        this.cableService.connect(this.environment.endpoints.base + "/websocket")

        this.config.plataforma.title = this.config.plataforma.title ?? {}
        this.config.plataforma.title.suffix = this.config.plataforma.title.suffix ?? this.config.plataforma.name

        this.title.setTitle(this.config.plataforma.title.suffix)

        if (this.config.plataforma.meta?.ogDescription) {
            this.meta.addTag({
                property: "og:description",
                content: this.config.plataforma.meta.ogDescription
            })

            this.meta.addTag({
                name: "description",
                content: this.config.plataforma.meta.ogDescription
            })
        }
    }

    switchProfileClassOnBody() {
        ;(<HTMLElement>document.querySelector("body")).className = "body-default"
        if (this.auth.isLoggedIn()) {
            ;(<HTMLElement>document.querySelector("body")).classList.add(this.userProfile)
        }
    }

    listenToRoutes() {
        this.router.events.subscribe(evt => {
            if (!(evt instanceof NavigationEnd)) {
                return
            }

            this.setUsuarioGoogleAnalytics()
            this.doHeartbeats(evt.urlAfterRedirects)
        })
    }

    doHeartbeats(urlAfterRedirects) {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }

        this.intervalId = <any>setInterval(
            () =>
                this.angularticsGa.eventTrack("Heartbeat", {
                    category: "Heartbeat",
                    label: urlAfterRedirects,
                    value: 0,
                    noninteraction: true
                }),
            60 * 1000
        )
    }

    setUsuarioGoogleAnalytics() {
        let usuario = this.authService.getUserData()
        if (usuario) {
            ga("set", "dimension1", usuario.id)
        }
    }

    setHightchartsLocales() {
        Highcharts.setOptions({
            lang: {
                months: [
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octubre",
                    "Noviembre",
                    "Diciembre"
                ],
                weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
            }
        })
    }

    reflowForPrinting() {
        if (typeof Highcharts.charts !== "undefined") {
            console.log("Resizing charts ready for printing", new Date())
            this.reflowTheseCharts(Highcharts.charts.filter(c => c))
        }
    }

    reflowAfterPrinting() {
        if (typeof Highcharts.charts !== "undefined") {
            console.log("Resizing charts back to screen size after printing", new Date())
            this.reflowTheseCharts(Highcharts.charts.filter(c => c))
        }
    }

    reflowTheseCharts(charts) {
        charts.forEach(chart => {
            // I'm assuming this check is quicker to execute than a chart
            // reflow so this check is actually saving time...
            if ($(chart.renderTo).is(":visible")) {
                console.log("reflowing chart ID ")
                // Chrome 126+ has a bug where the chart doesn't reflow properly
                if (this.getChromeVersion() >= 126) {
                    setTimeout(() => {
                        chart.reflow()
                    }, 0)
                } else {
                    chart.reflow()
                }
            } else {
                console.log("Not reflowing chart ID as container not visible")
            }
        })
    }

    getChromeVersion() {
        var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)
        return raw ? parseInt(raw[2], 10) : 0
    }
}
