import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core"
import { Store } from "@ngrx/store"
import { Scannerjs } from "@puntaje/shared/scanner"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { EvaluacionInstancias } from "@puntaje/puntaje/api-services"
import { Router } from "@angular/router"
import { ScrollToService, AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { ScannerService } from "@puntaje/shared/core"
import { SubirRespuestasImagenBaseComponent } from "../subir-respuestas-imagen-base/subir-respuestas-imagen-base.component"
import { State } from "@puntaje/puntaje/store"

declare let loadImage: any

@Component({
    selector: "subir-respuestas-imagen-alumno",
    templateUrl: "./subir-respuestas-imagen-alumno.component.html",
    styleUrls: ["./subir-respuestas-imagen-alumno.component.scss"]
})
export class SubirRespuestasImagenAlumnoComponent extends SubirRespuestasImagenBaseComponent {
    @Input() evaluacionId: number
    @Input() redirectRoute: string = config?.plataforma?.evaluacionInstanciasRoute || "/evaluacion_instancias"
    hojaRespuestaEjemploLink: string

    @ViewChild("canvas", { static: true }) canvas: ElementRef
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutSubir", { static: true }) loadingLayoutUpload: LoadingLayoutComponent

    constructor(
        protected evaluacionesInstanciasService: EvaluacionInstancias,
        protected router: Router,
        protected scrollToService: ScrollToService,
        protected scannerService: ScannerService,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef,
        protected authService: AuthService,
        protected simpleModalService: SimpleModalService
    ) {
        super(evaluacionesInstanciasService, router, scrollToService, scannerService, store, cdr, authService)
    }

    ngOnInit() {
        if (config.plataforma.name == "Puntaje Nacional Colombia") {
            this.hojaRespuestaEjemploLink =
                "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/latex/hoja_puntaje_nacional_colombia_2022.pdf"
        } else {
            this.hojaRespuestaEjemploLink = config.plataforma.hojaRespuestaEjemploLink
                ? config.plataforma.hojaRespuestaEjemploLink
                : "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/latex/hoja_puntaje_nacional_2_2022.pdf"
        }


        this.loadingLayout.ready()
        this.loadingLayoutUpload.ready()
    }

    subirLectura() {
        this.loadingMessages = true
        this.loadingLayoutUpload.standby()
        const usuario = this.authService.getUserData()
        let idValue
        if (config.plataforma.pais == "chile") {
            idValue = usuario.usuario_chile.rut.split("-")
        } else if (config.plataforma.pais == "colombia") {
            if (usuario.usuario_colombia.cc) idValue = [usuario.usuario_colombia.cc]
            if (usuario.usuario_colombia.ti) idValue = [usuario.usuario_colombia.ti]
        } else if (config.plataforma.pais == "mexico" && usuario.usuario_mexico) {
            idValue = [usuario.usuario_mexico.numero_unico]
        } else if (config.plataforma.pais == "brasil" && usuario.usuario_brasil) {
            idValue = [usuario.usuario_brasil.cpf]
        }
        const respArr = Object.keys(this.respuestas).map((k, i) => this.respuestas[k])

        this.evaluacionesInstanciasService.enableIgnoreModel()
        this.evaluacionesInstanciasService
            .subirLectura({
                row: [...idValue, this.evaluacionId].concat(respArr),
                pais: config.plataforma.pais,
                perfil: this.perfil
            })
            .then(resp => {
                this.evaluacionesInstanciasService.disableIgnoreModel()
                this.messages = resp
                this.loadingLayoutUpload.ready()
                this.loadingMessages = false
                if (resp && resp["instancia_id"]) {
                    this.instancia_id = resp["instancia_id"]

                    this.router.navigate([this.redirectRoute, this.instancia_id])
                }
            })
    }

    leerImagen() {
        this.checkIfImageExtension()
        if (this.wrongFileType) {
            return
        }
        this.loadingLayout.standby()
        const ctx = this.canvas.nativeElement.getContext("2d")

        loadImage(
            this.file,
            img => {
                //this.loadingLayout.standby();
                this.respuestasObj = []

                this.respuestas = {}
                this.forma = ""
                this.rut = ""
                try {
                    this.canvas.nativeElement.width = img.width
                    this.canvas.nativeElement.height = img.height
                    ctx.drawImage(img, 0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)

                    if (config.hojaRespuesta.configuration) {
                        this.scannerService.initialize(this.canvas.nativeElement, ctx)

                        this.scannerService.detectMarkers()
                        this.scannerService.align(
                            config.hojaRespuesta.configuration.align[0],
                            config.hojaRespuesta.configuration.align[1]
                        )
                        this.markers = this.scannerService.detectMarkers()

                        const { respuestas, idValue, forma } = this.scannerService.resolveData()

                        this.respuestas = respuestas.reduce((acc, r, i) => {
                            acc[i] = r

                            return acc
                        }, {})

                        this.forma = forma
                        this.idValue = idValue

                        this.loadingLayout.ready()
                        this.scrollToStep2()
                    } else {
                        const scanner = new Scannerjs({
                            image: img,
                            context: ctx,
                            canvasWidth: this.canvas.nativeElement.width,
                            canvasHeight: this.canvas.nativeElement.height
                        })

                        //console.log(this.canvas.nativeElement.width);
                        //console.log(this.canvas.nativeElement.height);
                        scanner.detectMarkers()
                        scanner.align(1, 5)
                        this.markers = scanner.detectMarkers()

                        if (
                            config.hojaRespuesta.sectoresByMarkers &&
                            config.hojaRespuesta.sectoresByMarkers[this.markers.length]
                        ) {
                            this.sectoresRespuestas = config.hojaRespuesta.sectoresByMarkers[this.markers.length]
                        } else {
                            this.sectoresRespuestas = config.hojaRespuesta.sectores
                        }

                        if (
                            config.hojaRespuesta.dimSectoresByMarkers &&
                            config.hojaRespuesta.dimSectoresByMarkers[this.markers.length]
                        ) {
                            this.dimSectores = config.hojaRespuesta.dimSectoresByMarkers[this.markers.length]
                        } else {
                            this.dimSectores = this.defaultDimSectores()
                        }

                        config.hojaRespuesta.sectores.forEach(s => {
                            this.respuestasObj.push(
                                scanner.resolveIds(s[0], s[1], config.hojaRespuesta.alternativas, 10)
                            )
                        })

                        const rutMarkerUp = 6
                        const rutMarkerBottom = 10
                        const rutCols = 10
                        const formaMarkerUp = 9
                        const formaMarkerBottom = 6
                        const formaCols = 10

                        this.sectores = []
                        this.sectores = this.sectores.concat(this.sectoresRespuestas)
                        this.sectores = this.sectores.concat([
                            [rutMarkerUp, rutMarkerBottom],
                            [formaMarkerUp, formaMarkerBottom]
                        ])

                        // this.rutObj = scanner.resolveIds(9, 6, 10, 10, true);
                        // this.formaObj = scanner.resolveIds(6, 10, 10, 10, true);
                        // this.rutObj = scanner.resolveIds(6, 10, 10, 10, true);
                        // this.formaObj = scanner.resolveIds(9, 6, 10, 10, true);

                        this.setValues()

                        this.scanner = scanner

                        this.loadingLayout.ready()
                        this.scrollToStep2()
                    }
                } catch (err) {
                    console.log(err)
                    this.wrongFileType = true
                    this.respuestas = null
                    this.loadingLayout.ready()
                }
            },
            {
                maxWidth: 1200,
                orientation: 1,
                canvas: true,
                imageSmoothingEnabled: true,
                imageSmoothingQuality: "high"
            }
        )
    }

    setValues() {
        this.idValue = []

        const letras = "ABCDE"
        const numeros = "0123456789"
        const rutChars = "0123456789K"

        this.respuestas = this.respuestasObj.reduce((acc, r, i) => {
            r.forEach(o => {
                acc[10 * i + o.row] = o.ans.map(a => letras.charAt(a)).join(",")
            })

            return acc
        }, {})

        // if(config.plataforma.pais == "chile") {
        //     this.idValue.push(this.rutObj.filter((o, i) => i < 9).map((o, i) => {
        //         return o.ans.map((a) => rutChars.charAt(a)).join(",");
        //     }).join(""));
        //     this.idValue.push(this.rutObj[9].ans.map((a) => rutChars.charAt(a)).join(","));
        // } else if(config.plataforma.pais == "colombia") {
        //     this.idValue.push(this.rutObj.map((o, i) => o.ans.map((a) => rutChars.charAt(a)).join(",")).join(""));
        // }

        // this.forma = this.formaObj.map((o) => o.ans.map((a) => numeros.charAt(a)).join(",")).join("");

        //console.log(this.respuestas);
        //console.log(this.rut);
        //console.log(this.forma);
    }

    onClickCanvas(event) {
        if (config.hojaRespuesta.configuration) {
            this.scannerService.changeMarked(event)

            const { respuestas, idValue, forma } = this.scannerService.getProcessedData()

            this.respuestas = respuestas.reduce((acc, r, i) => {
                acc[i] = r

                return acc
            }, {})

            this.forma = forma
            this.idValue = idValue
        } else {
            const nCanvas = this.canvas.nativeElement

            const ratioX = nCanvas.width / nCanvas.offsetWidth
            const ratioY = nCanvas.height / nCanvas.offsetHeight

            // this.scanner.changeMarked(~~(event.offsetX * ratioX), ~~(event.offsetY * ratioY), this.respuestasObj.concat([this.formaObj], [this.rutObj]), config.hojaRespuesta.alternativas, 10);
            this.scanner.changeMarked(
                ~~(event.offsetX * ratioX),
                ~~(event.offsetY * ratioY),
                this.respuestasObj.concat([this.rutObj], [this.formaObj]),
                this.dimSectores,
                this.sectores
            )
            this.setValues()
        }
    }

    enableEdit() {
        this.editing = true
    }

    disableEdit() {
        this.editing = false
    }

    verificaExisteEval() {
        const params = {
            evaluacion_instancia: {
                evaluacion_id: this.evaluacionId,
                oficial: 1,
                usuario_id: this.authService.getUserData().id
            }
        }

        if (config.hojaRespuesta.formatEvalIdStr) {
            params["format_id_str"] = config.hojaRespuesta.formatEvalIdStr
        }

        this.evaluacionesInstanciasService.where(params).then(eis => {
            if (eis.length > 0) {
                this.showModalSubida()
            } else {
                this.subirLectura()
            }
        })
    }

    showModalSubida() {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que quiere subir el resultado nuevamente? ya tienes registrados resultados para esta prueba."
        )
        this.simpleModalService.setModalCallback(() => this.subirLectura())
        this.simpleModalService.showSimpleModal()
    }
}
