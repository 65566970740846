import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, HostBinding } from "@angular/core"
import { Subscription, timer } from "rxjs"
import { EvaluacionTiempos, EvaluacionTiempo, Evaluacion } from "@puntaje/puntaje/api-services"
import { RealizarEnsayoService } from "./realizar_ensayo.service"
import { filter } from "rxjs/operators"

@Component({
    selector: "evaluacion-tiempo",
    templateUrl: "evaluacion_tiempo.component.html",
    styleUrls: ["evaluacion_tiempo.component.scss"]
})
export class EvaluacionTiempoComponent implements OnInit, OnDestroy {
    @Input() invisibleClock: boolean = false
    @Input() @HostBinding("class.fixed") enableFixed: boolean = true
    @Input() animateOnStart: boolean = true
    @Input() enableToggle: boolean = true
    @Input() collapseOnMobile: boolean = false
    @HostBinding("attr.role") role: string = "region"
    @HostBinding("attr.aria-label") aria: string = "tiempo evaluación"

    subLoadTiempo: Subscription
    subTimer: Subscription

    evaluacionTiempo: EvaluacionTiempo

    horas: number
    minutos: number
    segundos: number

    segundosRestantes: number

    showClock: boolean = true
    warningTime: number = 300

    // estos no se usan, no se borran para no matar todo el resto de las plataformas
    /**
     * @deprecated
     */
    @Output() onTimeUp: EventEmitter<any> = new EventEmitter<any>()
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    @Input() evaluacion: Evaluacion

    constructor(
        protected evaluacionTiemposService: EvaluacionTiempos,
        protected realizarEnsayoService: RealizarEnsayoService
    ) {}

    ngOnInit() {
        this.subLoadTiempo = this.realizarEnsayoService.evaluacionTiempo$
            .pipe(filter(et => !!et && (!this.evaluacionTiempo || this.evaluacionTiempo.id != et.id)))
            .subscribe(et => {
                this.evaluacionTiempo = et

                const tiempoDiferencia = new Date((et as any).datetime_now).getTime() - new Date().getTime()

                this.segundosRestantes = Math.max(
                    et.tiempo_limite -
                        ~~((new Date().getTime() + tiempoDiferencia - new Date(et.created_at).getTime()) / 1000),
                    0
                )
                const segundos = this.segundosRestantes

                this.horas = ~~(segundos / 3600)
                this.minutos = ~~((segundos % 3600) / 60)
                this.segundos = (segundos % 3600) % 60

                if (this.segundosRestantes == 0) {
                    // No se si esto esta bien
                    return
                }

                if (this.subTimer) {
                    this.subTimer.unsubscribe()
                }

                this.subTimer = timer(1000, 1000).subscribe(t => {
                    if (t % 30 == 0) {
                        this.segundosRestantes = Math.max(
                            et.tiempo_limite -
                                ~~(
                                    (new Date().getTime() + tiempoDiferencia - new Date(et.created_at).getTime()) /
                                    1000
                                ),
                            0
                        )
                        const segundos = this.segundosRestantes

                        this.horas = ~~(segundos / 3600)
                        this.minutos = ~~((segundos % 3600) / 60)
                        this.segundos = (segundos % 3600) % 60
                    } else {
                        this.segundosRestantes = Math.max(this.segundosRestantes - 1, 0)
                    }

                    if (this.segundosRestantes > 0) {
                        this.segundos--
                        this.minutos = this.minutos + Math.min(0, this.segundos)
                        this.horas = this.horas + Math.min(0, this.minutos)

                        if (this.segundos == -1) {
                            this.segundos = 59
                        }

                        if (this.minutos == -1) {
                            this.minutos = 59
                        }
                    } else {
                        if (this.realizarEnsayoService.componentEntregarEnsayo) {
                            this.realizarEnsayoService.componentEntregarEnsayo(false)
                        } else {
                            this.realizarEnsayoService.entregarEnsayo(false)
                        }

                        this.subTimer.unsubscribe()
                    }

                    if (this.horas == -1) throw new Error("Esto esta mal")
                })
                this.animateOnStart = false
            })
    }

    ngOnDestroy() {
        if (this.subTimer) {
            this.subTimer.unsubscribe()
        }

        this.subLoadTiempo.unsubscribe()
    }

    toggleClock() {
        if (this.enableToggle) this.showClock = !this.showClock
    }
}
