<div class="planes-sidemenu">
    <ng-template [ngIf]="hideInfo">
        <div class="">
            <p>
                <span class="inline-label">
                    <label>{{ "estudiar.plan_personales.side_menu.tipo_diagnostico" | t }}</label>
                </span>
                <button
                    type="button"
                    class="btn btn-default btn-secondary help-btn"
                    [popover]="helpPopUp"
                    popoverTitle="¿Qué significa 'Anterior'?"
                    placement="left"
                >
                    <fa name="question-circle-o" class="question-icon"></fa>
                </button>
            </p>
            <ng-template #helpPopUp>
                <ul>
                    <li>
                        <label>{{ "estudiar.plan_personales.side_menu.instrucciones.titulo" | t }}</label>
                        <p>{{ "estudiar.plan_personales.side_menu.instrucciones.parrafo1" | t }}</p>
                        <p>{{ "estudiar.plan_personales.side_menu.instrucciones.parrafo2" | t }}</p>
                        <p>{{ "estudiar.plan_personales.side_menu.instrucciones.parrafo3" | t }}</p>
                    </li>
                </ul>
            </ng-template>
        </div>
        <select class="form-control" [(ngModel)]="generadorInstrumento" (ngModelChange)="generadorInstrumentoChanges()">
            <option [ngValue]="undefined" [selected]="true">
                {{ "estudiar.plan_personales.side_menu.select_tipo" | t }}
            </option>
            <option *ngFor="let gp of generadorInstrumentos" [ngValue]="generadorInstrumento">
                {{ gp.generador_instrumento }}
            </option>
        </select>
    </ng-template>

    <ng-template [ngIf]="!hideInfo">
        <label for="select-tipo-diagnostico">{{ "estudiar.plan_personales.side_menu.tipo_diagnostico" | t }}</label>
        <select
            id="select-tipo-diagnostico"
            class="form-control"
            [(ngModel)]="generadorInstrumento"
            (ngModelChange)="generadorInstrumentoChanges()"
        >
            <option [ngValue]="undefined" [selected]="true">
                {{ "estudiar.plan_personales.side_menu.select_tipo" | t }}
            </option>
            <option *ngFor="let gp of generadorInstrumentos" [ngValue]="gp">
                {{ gp.alias ?? gp.generador_instrumento }}
            </option>
        </select>

        <label>{{ "estudiar.plan_personales.side_menu.instrucciones.titulo" | t }}</label>
        <p>{{ "estudiar.plan_personales.side_menu.instrucciones.parrafo1" | t }}</p>
        <p>{{ "estudiar.plan_personales.side_menu.instrucciones.parrafo2" | t }}</p>
        <p>{{ "estudiar.plan_personales.side_menu.instrucciones.parrafo3" | t }}</p>
    </ng-template>
</div>
