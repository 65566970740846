import { Injectable } from "@angular/core"
import { AppConfig } from "../conf/app_config"
import { Scannerjs } from "@puntaje/shared/scanner"

const letras = "ABCDE"
const numeros = "0123456789"
const rutChars = "0123456789K"

export interface ResolvedData {
    respuestas: any
    idValue: string[]
    forma: string
    formaType: string
}

@Injectable({
    providedIn: "root"
})
export class ScannerService {
    _scanner: Scannerjs
    canvas: HTMLCanvasElement
    rawSectoresValues: any[][]
    dimSectores: any[]
    lastMarkers: any[]

    configuration: any

    rawObj: { rutObj: any; formaObj: any; respuestasObj: any }
    resolvedData: ResolvedData

    /**
     * @constructor
     *
     * @param config app config
     */
    constructor(protected config: AppConfig) {}

    get defaultDimSectores() {
        let alternativas = 5
        if (this.config.hojaRespuesta.alternativas) {
            alternativas = this.config.hojaRespuesta.alternativas
        }

        return {
            "1,2": { cols: alternativas, rows: 10 },
            "2,3": { cols: alternativas, rows: 10 },
            "3,4": { cols: alternativas, rows: 10 },
            "5,2": { cols: alternativas, rows: 10 },
            "4,5": { cols: alternativas, rows: 10 },
            "2,7": { cols: alternativas, rows: 10 },
            "7,4": { cols: alternativas, rows: 10 },
            "5,6": { cols: alternativas, rows: 10 },
            "6,7": { cols: alternativas, rows: 10 },
            "8,9": { cols: alternativas, rows: 10 },
            "7,8": { cols: alternativas, rows: 10 },
            "9,6": { cols: 10, rows: 10 },
            "6,10": { cols: 10, rows: 10 }
        }
    }

    /**
     * Inicializa la lib scanner y el canvas a manipular. @see Scannerjs
     *
     * @param canvas
     * @param context
     */
    public initialize(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
        this.canvas = canvas

        this._scanner = new Scannerjs({
            context: context,
            canvasWidth: canvas.width,
            canvasHeight: canvas.height
        })
    }

    /**
     * Detecta los marcadores existentes en el contexto inicializado de la instancia scanner.
     *
     * @returns marcadaores detectados en el context
     */
    public detectMarkers() {
        const markers = this._scanner.detectMarkers()

        this.lastMarkers = markers

        return markers
    }

    /**
     * Wrapper para resolveIds de Scannerjs
     *
     * @param id1
     * @param id2
     * @param cols
     * @param rows
     * @param inverse
     * @returns array de ids y pregunta
     */
    public resolveIds(id1: number, id2: number, cols, rows, inverse = false) {
        return this._scanner.resolveIds(id1, id2, cols, rows, inverse)
    }

    /**
     * Wrapper para align de Scannerjs
     *
     * @param id1 primer índice de mercadores
     * @param id2 segundo índice de marcadores
     */
    public align(id1: number, id2: number) {
        this._scanner.align(id1, id2)
    }

    /**
     * Indica todas las respuestas visibles por sectores según config.
     *
     * @param markers marcadores detectados @see detectMarkers
     * @returns array de respuestas
     */
    public allRespuestaMarkersVisible(markers) {
        return Array.from(new Set(this.config.hojaRespuesta.sectores.flat().concat([6, 9, 10]))).every(x =>
            markers.find(m => m.id == x)
        )
    }

    /**
     * Cambia un marcador según coordenada del evento
     *
     * @param event html click event
     */
    changeMarked(event) {
        const nCanvas = this.canvas
        const ratioX = nCanvas.width / nCanvas.offsetWidth
        const ratioY = nCanvas.height / nCanvas.offsetHeight

        this._scanner.changeMarked(
            ~~(event.offsetX * ratioX),
            ~~(event.offsetY * ratioY),
            this.rawSectoresValues,
            this.dimSectores,
            this.configuration.sectores
        )
    }

    /**
     *
     *
     * @param resolvedObj
     * @param type
     * @param offsetX
     * @param offsetY
     * @param circles
     * @param group
     * @param inverted
     * @returns
     */
    private getGroup(resolvedObj, type, offsetX, offsetY, circles, group, inverted) {
        const fromIndexFirst = inverted ? offsetX : offsetY
        const toIndexFirst = fromIndexFirst + group

        const fromIndexSecond = inverted ? offsetY : offsetX
        const toIndexSecond = fromIndexSecond + circles

        if (type == "single") {
            return [
                resolvedObj
                    .filter(o => o.row < toIndexFirst && o.row >= fromIndexFirst)
                    .reduce(
                        (acc, o, i) => {
                            acc.ans = acc.ans.concat(
                                o.ans
                                    .filter(a => a >= fromIndexSecond && a < toIndexSecond)
                                    .map(a => a - fromIndexSecond + i * circles)
                            )

                            return acc
                        },
                        { row: 0, ans: [] }
                    )
            ]
        } else {
            return resolvedObj
                .filter(o => o.row < toIndexFirst && o.row >= fromIndexFirst)
                .map((o, i) => ({
                    row: i,
                    ans: o.ans.filter(a => a >= fromIndexSecond && a < toIndexSecond).map(a => a - fromIndexSecond)
                }))
        }
    }

    /**
     * Obtiene el valor de la sección dada según su nombre y configuración
     *
     * @param configuration
     * @param valueName
     * @returns objeto con valor del sector
     */
    private getSectoresValue(configuration, valueName) {
        const sectoresArray = []

        Object.keys(configuration[valueName]).forEach(key => {
            if (!isNaN(parseInt(key))) {
                // const sector = configuration.sectores[+key]
                const configSector = configuration[valueName][key]

                const resolvedObj = this.rawSectoresValues[+key]

                const elementsArray = []
                configSector.elements.forEach(element => {
                    const elementArray = []
                    const rObj = this.getGroup(
                        resolvedObj,
                        element.type,
                        element.offset.x,
                        element.offset.y,
                        element.size.circles,
                        element.size.group,
                        configSector.inverted
                    )

                    rObj.forEach(o => {
                        const value = o.ans
                            .map(a => {
                                if (Array.isArray(element.markValue)) {
                                    return element.markValue[a]
                                } else {
                                    return element.markValue.charAt(a)
                                }
                            })
                            .join(",")

                        elementArray.push(value)
                    })

                    if (element.separator != undefined && element.separator != null) {
                        elementsArray.push(elementArray.join(element.separator))
                    } else {
                        elementsArray.push(...elementArray)
                    }
                })

                if (configSector.separator != undefined && configSector.separator != null) {
                    sectoresArray.push(elementsArray.join(configSector.separator))
                } else {
                    sectoresArray.push(...elementsArray)
                }
            }
        })

        let value: any = sectoresArray
        if (configuration[valueName].separator != undefined && configuration[valueName].separator != null) {
            value = sectoresArray.join(configuration[valueName].separator)
        }

        return value
    }

    /**
     * Calcula el valor de cada sección según configuración
     * @see configuration
     */
    private calculateSectoresValues() {
        this.rawSectoresValues = new Array(this.configuration.sectores.length)
        this.dimSectores = new Array(this.configuration.sectores.length)

        const concatedConfiguration = {
            ...this.configuration.respuestas,
            ...this.configuration.forma,
            ...this.configuration.idValue
        }

        Object.keys(concatedConfiguration).forEach(key => {
            if (!isNaN(parseInt(key))) {
                const sector = this.configuration.sectores[+key]
                const configSector = concatedConfiguration[+key]

                this.rawSectoresValues[+key] = this.resolveIds(
                    sector[0],
                    sector[1],
                    configSector.columns,
                    configSector.rows,
                    configSector.inverted
                )

                this.dimSectores[+key] = {
                    cols: configSector.columns,
                    rows: configSector.rows,
                    inverted: configSector.inverted
                }
            }
        })
    }

    /**
     * Obtiene los valores procesados por sectores según sección respuestas, forma, identificador y formaType
     *
     * @returns data procesada por key-valor
     */
    public getProcessedData() {
        const respuestas = this.getSectoresValue(this.configuration, "respuestas")
        const forma = this.getSectoresValue(this.configuration, "forma")
        const idValue = this.getSectoresValue(this.configuration, "idValue")
        const formaType = "" //this.getSectoresValue(this.configuration, "formaType")

        return { respuestas, idValue, forma, formaType }
    }

    /**
     * Verifica si existe config específica por marcadores
     *
     * @returns boolean
     */
    private hasConfigurationByMarkers() {
        if (this.config.hojaRespuesta.configurationByMarkers) {
            const uniqueMarkersQty = new Set(this.lastMarkers.map(m => m.id)).size

            return !!this.config.hojaRespuesta.configurationByMarkers[uniqueMarkersQty]
        }

        return false
    }

    /**
     * Obtiene ids de marcadores horizontales
     *
     * @returns array de ids
     */
    public getHorizontalMarkers() {
        if (this.config.hojaRespuesta.configuration) {
            return this.config.hojaRespuesta.configuration.horizontalMarkers
        } else if (this.hasConfigurationByMarkers()) {
            const uniqueMarkersQty = new Set(this.lastMarkers.map(m => m.id)).size

            return this.config.hojaRespuesta.configurationByMarkers[uniqueMarkersQty].horizontalMarkers
        } else return [1, 9]
    }

    /**
     * Obtiene ids de marcadores verticales
     *
     * @returns array de ids
     */
    public getVerticalMarkers() {
        if (this.config.hojaRespuesta.configuration) {
            return this.config.hojaRespuesta.configuration.verticalMarkers
        } else if (this.hasConfigurationByMarkers()) {
            const uniqueMarkersQty = new Set(this.lastMarkers.map(m => m.id)).size

            return this.config.hojaRespuesta.configurationByMarkers[uniqueMarkersQty].verticalMarkers
        } else return [1, 3]
    }

    /**
     * Genera objeto estructurado por sección con la data procesada.
     *
     * @returns objeto key-valor por cada sección @type { respuestas, idValue, forma, formaType }
     */
    public resolveData() {
        if (this.config.hojaRespuesta.configuration) {
            this.configuration = this.config.hojaRespuesta.configuration

            this.calculateSectoresValues()

            return this.getProcessedData()
        } else if (this.hasConfigurationByMarkers()) {
            const uniqueMarkersQty = new Set(this.lastMarkers.map(m => m.id)).size
            this.configuration = this.config.hojaRespuesta.configurationByMarkers[uniqueMarkersQty]

            this.calculateSectoresValues()

            return this.getProcessedData()
        } else {
            let markers = this.detectMarkers()
            let tempMarkers = markers.filter(
                (marker, index, self) => self.findIndex((v, i) => v.id === marker.id) === index
            )

            let rutMarkerUp = 6
            let rutMarkerBottom = 10
            let rutCols = 10
            let rutRows = 10
            let formaMarkerUp = 9
            let formaMarkerBottom = 6
            let formaCols = 10
            let formaRows = 10
            if (
                this.config.hojaRespuesta.rutFormaByMarkers &&
                this.config.hojaRespuesta.rutFormaByMarkers[tempMarkers.length]
            ) {
                const configRutForma = this.config.hojaRespuesta.rutFormaByMarkers[tempMarkers.length]
                rutMarkerUp = configRutForma.rutMarkerUp
                rutMarkerBottom = configRutForma.rutMarkerBottom
                rutCols = configRutForma.rutCols
                rutRows = configRutForma.rutRows ? configRutForma.rutRows : 10
                formaMarkerUp = configRutForma.formaMarkerUp
                formaMarkerBottom = configRutForma.formaMarkerBottom
                formaCols = configRutForma.formaCols
                formaRows = configRutForma.formaRows ? configRutForma.formaRows : 10
            }

            const rutObj = this._scanner.resolveIds(rutMarkerUp, rutMarkerBottom, rutCols, rutRows, true)
            const formaObj = this._scanner.resolveIds(formaMarkerUp, formaMarkerBottom, formaCols, formaRows, true)
            const respuestasObj = this.config.hojaRespuesta.sectores.map(ids =>
                this._scanner.resolveIds(ids[0], ids[1], this.config.hojaRespuesta.alternativas || 5, 10)
            )

            const respuestas = this.convertResponse(respuestasObj)
            const idValue = this.convertId(rutObj)
            const forma = this.convertForms(formaObj).forma
            const formaType = this.convertForms(formaObj).formaType

            this.rawObj = { rutObj, formaObj, respuestasObj }
            this.resolvedData = { respuestas, idValue, forma, formaType }

            return { respuestas, idValue, forma, formaType }
        }
    }

    convertResponse(resps: any) {
        return resps.reduce((acc, r, i) => {
            r.forEach(o => {
                acc[10 * i + o.row] = o.ans.map(a => letras.charAt(a)).join(",")
            })
            return acc
        }, {})
    }

    convertId(rutObj: any) {
        const idValue = []

        if (this.config.plataforma.pais == "chile") {
            idValue.push(
                rutObj
                    .filter((o, i) => i < rutObj.length - 1)
                    .map((o, i) => {
                        return o.ans.map(a => rutChars.charAt(a)).join(",")
                    })
                    .join("")
            )
            idValue.push(rutObj[rutObj.length - 1].ans.map(a => rutChars.charAt(a)).join(","))
        } else if (this.config.plataforma.pais == "colombia") {
            idValue.push(rutObj.map((o, i) => o.ans.map(a => rutChars.charAt(a)).join(",")).join(""))
        } else {
            idValue.push(rutObj.map((o, i) => o.ans.map(a => numeros.charAt(a)).join(",")).join(""))
        }

        return idValue
    }

    convertForms(formaObj: any) {
        let forma = null
        let formaType = ""

        const lastIndex = formaObj.length - 1
        const hasSomethingBeforeSpace = formaObj.slice(0, lastIndex - 1).some(obj => obj.ans.length > 0)

        if (formaObj[lastIndex - 1].ans.length == 0 && formaObj[lastIndex].ans.length > 0 && hasSomethingBeforeSpace) {
            forma = formaObj
                .map((o, i) => {
                    let valores = []
                    if (i != 8 && i != 9) {
                        valores.push(o.ans.map(a => numeros.charAt(a)).join(","))
                    }
                    return valores
                })
                .join("")

            if (formaObj[9].ans[0] == 0) {
                formaType = "A"
            } else if (formaObj[9].ans[0] == 1) {
                formaType = "B"
            }
        } else {
            forma = formaObj.map(o => o.ans.map(a => numeros.charAt(a)).join(",")).join("")
        }

        return { forma, formaType }
    }
}
