<loading-layout #loadingLayout>
    <div class="clearfix">
        <div class="ciclo-select" *ngIf="planPersonal.plan_personal_ciclos.length > 1">
            <label for="select-ciclo">{{ "estudiar.plan_personales.plan_personal_sesiones.ciclo" | t }}:</label>
            <div class="ciclo-select-container">
                <select
                    #cicloSelect
                    id="select-ciclo"
                    class="form-control"
                    [(ngModel)]="currentCicloIndex"
                    (ngModelChange)="onChangeSelect($event)"
                >
                    <option *ngFor="let ciclo of planPersonal.plan_personal_ciclos; let i = index" [ngValue]="i">
                        {{ i }}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div
        class="sesiones-container"
        role="region"
        aria-label="línea de tiempo"
        [@smallSlide]="slideState"
        (@smallSlide.done)="animationSmallSlideDone()"
    >
        <div class="line-container" [@slideIn]="planPersonalSesiones" (@slideIn.done)="animationSlideInDone()">
            <div class="line-dashed-segment-space clearfix active" *ngIf="currentCicloIndex && currentCicloIndex > 0">
                <div class="line-dashed-segment">
                    <a class="previous-link" (click)="previousCycle()" href="javascript:void(0)">
                        <fa name="arrow-up"></fa>
                        {{ "estudiar.plan_personales.plan_personal_sesiones.ciclo_anterior" | t }}
                    </a>
                </div>
            </div>
            <div
                *ngIf="showDiagnosticoBox"
                role="region"
                aria-label="sesión diagnóstico"
                class="line-segment-space clearfix active"
            >
                <div class="line-bullet"></div>
                <div class="line-segment"></div>
                <plan-personal-diagnostico-box
                    [pointRight]="false"
                    class="sesion-box"
                    [class.slideRight]="false"
                    [class.slideLeft]="true"
                    [clasificacionesIds]="currentPlanPersonalCiclo.clasificaciones"
                    [isDone]="true"
                ></plan-personal-diagnostico-box>
            </div>
            <div
                class="line-segment-space clearfix"
                *ngFor="let sesion of planPersonalSesiones; let i = index"
                [class.active]="sesionesAvance[sesion.id]"
                role="region"
                [attr.aria-label]="'sesión ' + (i + 1)"
            >
                <div class="line-bullet"></div>
                <div class="line-segment"></div>
                <plan-personal-sesion-box
                    [index]="i"
                    [planPersonalSesion]="sesion"
                    [pointRight]="i % 2 == 0"
                    class="sesion-box"
                    [class.slideRight]="i % 2 == 0"
                    [class.slideLeft]="i % 2 != 0"
                    [planPersonalId]="planPersonal.id"
                    [planPersonalCiclo]="currentPlanPersonalCiclo.orden"
                ></plan-personal-sesion-box>
            </div>
            <div
                class="line-segment-space clearfix"
                *ngIf="
                    planPersonalTipo &&
                    planPersonalTipo == 'personal' &&
                    currentCicloIndex == planPersonal.plan_personal_ciclos.length - 1
                "
                role="region"
                aria-label="continuar estudiando"
            >
                <div class="line-bullet"></div>
                <div class="line-segment"></div>
                <plan-personal-diagnostico-box
                    [pointRight]="planPersonalSesiones.length % 2 == 0"
                    class="sesion-box"
                    [class.slideRight]="planPersonalSesiones.length % 2 == 0"
                    [class.slideLeft]="planPersonalSesiones.length % 2 != 0"
                    [enableStartBtn]="enableStartBtn"
                    [planPersonal]="planPersonal"
                    [clasificacionesIds]="currentPlanPersonalCiclo.clasificaciones"
                ></plan-personal-diagnostico-box>
            </div>
            <div
                class="line-dashed-segment-space clearfix active"
                *ngIf="currentCicloIndex < planPersonal.plan_personal_ciclos.length - 1"
            >
                <div class="line-dashed-segment-inv">
                    <a id="ciclo-siguiente" class="previous-link" (click)="nextCycle()" href="javascript:void(0)">
                        <fa name="arrow-down"></fa>
                        {{ "estudiar.plan_personales.plan_personal_sesiones.ciclo_siguiente" | t }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
