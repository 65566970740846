import { NgModule, ModuleWithProviders } from "@angular/core"
import { CableService } from "./cable.service"

@NgModule({
    imports: [],
    exports: [],
    declarations: []
})
export class CableModule {
    static forRoot(): ModuleWithProviders<CableModule> {
        return {
            ngModule: CableModule,
            providers: [CableService]
        }
    }
}
