import { BaseModel, SaveType } from "@puntaje/shared/core"
import { EscalaGlobalAlias } from "./escala_global_aliases.model"
import { EvaluacionTipo } from "./evaluacion_tipos.model"

export class EscalaGlobal extends BaseModel {
    evaluacion_tipo_id: number
    nombre: string
    tipo: string
    activa: boolean
    porcentaje_aprobacion: number
    porcentaje_logro: number
    nota_min: number
    nota_max: number
    created_at: Date
    updated_at: Date
    usuario_id: number
    profesor: boolean

    check: boolean

    @SaveType(() => EvaluacionTipo) evaluacion_tipo: EvaluacionTipo
    @SaveType(() => EscalaGlobalAlias) escala_global_aliases: EscalaGlobalAlias[]
}
