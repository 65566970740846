<loading-layout #loadingLayout>
    <ng-container *ngIf="!loadingLayout.loading">
        <div *ngIf="planPersonal">
            <cui-section-headline *ngIf="!hideTitle">
                {{ "estudiar.plan_personales.plan_personal.subtitulo" | t }}
            </cui-section-headline>
            <plan-personal-sesiones [planPersonal]="planPersonal" [showDiagnosticoBox]="!planPersonalPredefinido">
            </plan-personal-sesiones>
        </div>
        <div class="no-plan-yet" *ngIf="!planPersonal">
            <div *ngIf="generadorInstrumento">
                <p class="iconos">
                    <ogr-icon name="resultados"></ogr-icon>
                    <ogr-icon name="libro2-o"></ogr-icon>
                    <ogr-icon name="prueba"></ogr-icon>
                </p>
                <button type="button" class="start-btn" (click)="comenzarPlanPersonal()">
                    {{ "estudiar.plan_personales.btn_comenzar" | t }}
                </button>
            </div>
            <div *ngIf="!generadorInstrumento">
                {{ "estudiar.plan_personales.side_menu.no_seleccionado_tipo_diagnostico" | t }}.
            </div>
        </div>
    </ng-container>
</loading-layout>
