import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { AsignaturaPlataforma } from "./asignatura_plataformas.model"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"

@Injectable()
export class AsignaturaPlataformas extends NebuBaseService<AsignaturaPlataforma> {
    tableName = "asignatura_plataformas"
    singularTableName = "asignatura_plataforma"
    modelClass = AsignaturaPlataforma

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
