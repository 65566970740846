import { Injectable, Injector, Optional } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Store } from "@ngrx/store"
// import { AppConfig } from "../conf/app_config"
import { AppEnv } from "../conf/app_env"

@Injectable()
export class Loader {
    public routeUser: any

    constructor(
        protected http: HttpClient,
        protected injector: Injector,
        @Optional() protected store: Store<any>,
        // @Optional() protected config: AppConfig,
        protected environment: AppEnv
    ) {}

    loadConfig = (): Promise<any> => {
        return this.loadConfigMethod()
    }

    loadConfigMethod(): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            // TODO: PARCHE temporal para no tener que injectar config y environment en varios submodulos
            // favor de ir injectandolos en los componentes necesarios que no se esté haciendo, para borrar esto.
            ;(window as any).environment = this.environment
            // ;(window as any).config = this.config

            resolve(null)
        })

        return promise
    }
}
