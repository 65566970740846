import { HttpClient, HttpHeaders } from "@angular/common/http"

import { Injectable, Injector } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { forkJoin, of } from "rxjs"

import { AppEnv, Loader } from "@puntaje/shared/core"
import { Asignaturas, ReporteRazones, NebuAuthService } from "@puntaje/nebulosa/api-services"

import { Store } from "@ngrx/store"
import { GetStoreConfig } from "@puntaje/puntaje/store"
import { catchError } from "rxjs/operators"
// import { Configuraciones, Configuracion } from "@puntaje/puntaje/api-services"

@Injectable()
export class LoaderPuntaje extends Loader {
    constructor(
        http: HttpClient,
        injector: Injector,
        public store: Store<any>,
        // public config: AppConfig,
        public environment: AppEnv
    ) // public configuracionesService: Configuraciones,
    {
        super(http, injector, store, environment)
    }

    loadConfig = (): Promise<any> => {
        const promise = this.loadConfigMethod().then(() => {
            const reporteRazonesService: ReporteRazones = this.injector.get(ReporteRazones)
            const nebuAuthService: NebuAuthService = this.injector.get(NebuAuthService)
            const config = (window as any).config
            //

            const plataformObservable = this.http.get(
                this.environment.endpoints.nebulosa + "/plataformas?plataforma[plataforma]=" + config.plataforma.name
            )

            //ANGULAR VALE PICO, no se puede incluir acá ActivatedRoute
            let url: any = new URL(window.location.href)
            let token = null
            if (navigator.userAgent.indexOf("Safari") > -1) {
                let arrParams = url.search
                    .substring(1)
                    .split("&")
                    .map(s => s.split("="))
                let params: any = {}
                arrParams.forEach(p => (params[p[0]] = p[1]))
                token = params.token
            } else {
                token = url.searchParams.get("token")
            }
            var tokenObservable: any = Promise.resolve(null)
            var tokenObservable2: any = Promise.resolve(null)
            if (token) {
                let tokenData = JSON.parse(atob(token.split(".")[1]))
                //aditional data comes here, such as the user;
                let header = new HttpHeaders({ Authorization: token })
                let opts = { headers: header, params: { methods: "plus" } }
                tokenObservable = this.http
                    .get(this.environment.endpoints.base + "/usuarios/" + tokenData["user_id"], opts)
                    .pipe(catchError(_ => of(null)))
                tokenObservable2 = this.http
                    .get(this.environment.endpoints.base + "/usuarios/" + tokenData["user_id"] + "/user_info", opts)
                    .pipe(catchError(_ => of(null)))
            }

            if (this.environment.endpoints.nebulosa && this.environment.endpoints.base && config.plataforma.name) {
                let innerPromise = forkJoin(plataformObservable, tokenObservable, tokenObservable2).toPromise()
                innerPromise.then(res => {
                    config.plataforma.id = res[0][0].id

                    if (nebuAuthService.getToken() != null) {
                        this.store.dispatch(new GetStoreConfig())
                        reporteRazonesService.loadReporteRazones()
                    }

                    if (res[1]) {
                        config.app.routeUser = res[1]
                        config.app.routeUserInfo = res[2]
                    }
                })

                return innerPromise
            }

            return null
        })

        return promise
    }

    loadConfigMethod(): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            //
            // TODO: PARCHE temporal para no tener que injectar config y environment en varios submodulos
            // favor de ir injectandolos en los componentes necesarios que no se esté haciendo, para borrar esto.
            ;(window as any).environment = this.environment
            resolve(null)
        })

        return promise
    }
}
