import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { FormModule } from "../form/form.module"

import { FilterComponent } from "./filter.component"
import { UtilModule } from "../util/util.module"
import { FilterListComponent } from "./filter_list.component"

@NgModule({
    imports: [ReactiveFormsModule, FormsModule, CommonModule, UtilModule, FormModule, ReactiveFormsModule],
    declarations: [FilterComponent, FilterListComponent],
    exports: [FilterComponent, FilterListComponent]
})
export class FilterModule {}
