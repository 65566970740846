import { Component, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { View } from "./view.component"

@Component({
    selector: "youtubevideoview",
    template: `
        <ng-template #viewLabel></ng-template>
        <ng-template #viewValue>
            <div #iframeContainer class="video-youtube-container" (window:resize)="onResize($event)" *ngIf="urlReady">
                <iframe
                    [width]="width"
                    [height]="height"
                    [src]="safeUrl"
                    (load)="onLoadIframe()"
                    allowfullscreen
                ></iframe>
            </div>
        </ng-template>
    `,
    styleUrls: ["model_simple_view.component.scss"]
})
export class YoutubeVideoView extends View {
    width: number = 640
    height: number = 480
    max_width: number = 640
    max_height: number = 480
    url: string
    safeUrl: SafeResourceUrl
    urlReady: boolean = false
    @ViewChild("iframeContainer") iframeContainer: ElementRef

    constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {
        super()
    }

    ngOnInit() {
        this.url = this.value[this.key]
        this.url = this.url.replace("watch?v=", "embed/")
        if (this.url.indexOf("vimeo.com") >= 0) {
            this.height = 360
            this.max_height = 360
        }

        if (this.url.indexOf("youtu.be/") >= 0) {
            const yCode = this.url.substr(this.url.indexOf("youtu.be/") + 9, this.url.length)
            this.url = "https://youtube.com/embed/" + yCode + "?hl=es&cc_lang_pref=es&cc_load_policy=1"
        }
        if (this.url.indexOf("vimeo.com/") >= 0 && this.url.indexOf("player.vimeo") < 0) {
            const vCode = this.url.substr(this.url.indexOf("vimeo.com/") + 10, this.url.length)
            this.url = "https://player.vimeo.com/video/" + vCode + "?texttrack=es"
        }
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
        this.urlReady = true
    }

    onResize(event) {
        this.setRatioSize()
    }

    setRatioSize() {
        if (this.iframeContainer) {
            if (this.iframeContainer.nativeElement.offsetWidth > this.max_width) {
                this.width = this.max_width
            } else {
                this.width = this.iframeContainer.nativeElement.offsetWidth
            }
        }
        this.height = (this.width * this.max_height) / this.max_width
    }

    onLoadIframe() {
        this.cdr.detectChanges()
        this.setRatioSize()
    }
}
