import { Component, Input, OnInit, HostBinding, Output, EventEmitter } from "@angular/core"
import {
    Pregunta,
    Clasificacion,
    Contestable,
    Taxativo,
    Alternativa,
    GrupoPregunta,
    ContestableTipoEnum,
    GeneradorInstrumento
} from "@puntaje/nebulosa/api-services"
import { Respuesta, Respuestas } from "@puntaje/puntaje/api-services"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { GrupoPreguntaTextoService } from "@puntaje/shared/core"

@Component({
    selector: "respuesta-ensayo",
    templateUrl: "respuesta_ensayo.component.html",
    styleUrls: ["respuesta_ensayo.component.scss"]
})
export class RespuestaEnsayoComponent implements OnInit {
    @Input() pregunta: Pregunta
    @Input() respuestas: Respuesta[]
    @Input() respuestaId: number
    @Input() puntuacion: number
    @Input() index: number
    @Input() tiempo: number
    @Input() showClasificaciones: boolean = false
    @Input() enableCrearDuda: boolean = true
    @Input() grupoPreguntaPrev: GrupoPregunta
    @HostBinding("class.piloto") @Input() isPiloto: boolean = false
    @Input() evaluacionTipo: string = null
    @Input() generadorInstrumento: GeneradorInstrumento
    @Input() enableDuda: boolean = false
    @Input() enableReporte: boolean = true
    @Input() onlyLetras: boolean = false
    @Input() showOnly: string = "todas"
    @Input() numeroAlternativas: number
    @Input() disableEdit: boolean = false
    @Output() respuestaCorrectaUpdating = new EventEmitter<boolean>()
    @Output() respuestaCorrectaChanged = new EventEmitter<boolean>()
    @Input() evaluacionId: number = 0

    disableSolucion: boolean = false
    disableCorreccion: boolean = false

    showSolution: boolean = false
    isCorrect: boolean = false
    isOmitted: boolean = false
    safeUrl: SafeResourceUrl
    hasVideoUrl: boolean
    minutos: number
    segundos: number
    showPilotajeInfo: boolean = false
    grupoPreguntaId: number
    showDuda: boolean = true

    puntuacionObtenida: number

    contenidos: Array<Taxativo | Contestable>
    contenidoIndexByContenidoIndex: any

    contestableTipo = ContestableTipoEnum

    letrasAlternativas: string[]

    alternativaCorrecta: Alternativa
    alternativaEscogida: Alternativa
    config: typeof config
    dudaQueryParams: any = {}

    perfil: string = config.app.name
    tieneCorreccionManual: boolean
    isMarkedCorrecta: boolean

    posiblePuntuaciones: number[]
    selectedPuntuacion: number

    constructor(
        protected sanitizer: DomSanitizer,
        protected router: Router,
        public grupoPreguntaTextoService: GrupoPreguntaTextoService,
        protected respuestasService: Respuestas
    ) {}

    ngOnInit() {
        if (this.respuestas) {
            const respuesta = this.respuestas[0]
            this.puntuacionObtenida = respuesta.puntuacion
            this.tiempo = respuesta.tiempo
            this.enableDuda = respuesta.duda_marcada
        }

        if (config.plataforma.disableButtonDuda) {
            this.showDuda = false
        }

        if (config.evaluaciones[this.evaluacionTipo]) {
            if (config.evaluaciones[this.evaluacionTipo].disableSolucion) {
                this.disableSolucion = true
            }

            if (config.evaluaciones[this.evaluacionTipo].disableCorreccion) {
                this.disableCorreccion = true
            }
        }

        this.tieneCorreccionManual = this.pregunta.contestables.some(
            contestable =>
                contestable.contestable_tipo &&
                contestable.contestable_tipo?.contestable_tipo != "Pregunta de alternativas"
        )

        if (this.tieneCorreccionManual) {
            this.selectedPuntuacion = this.respuestas.reduce((acc, r) => {
                if (r.puntuacion == null || r.puntuacion == undefined || acc == null || acc == undefined) {
                    return undefined
                }

                return acc + r.puntuacion
            }, 0)

            this.posiblePuntuaciones = []

            for (let p = 0.0; p < this.puntuacion; p += 0.5) {
                this.posiblePuntuaciones.push(p)
            }

            this.posiblePuntuaciones.push(this.puntuacion)
        }

        this.setIsCorrecta()

        const letras = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        let numeroAlternativas = this.numeroAlternativas || 4

        this.letrasAlternativas = new Array(numeroAlternativas).fill("").map((_, i) => letras[i])

        this.minutos = ~~(this.tiempo / 60)
        this.segundos = ~~this.tiempo - this.minutos * 60
        if (this.pregunta) {
            this.contenidos = [...this.pregunta.taxativos, ...this.pregunta.contestables].sort(
                (c1, c2) => c1.orden - c2.orden
            )

            let contestableIndex = 0
            this.contenidoIndexByContenidoIndex = this.contenidos.reduce((acc, contenido, index) => {
                if (contenido instanceof Contestable) {
                    acc[index] = contestableIndex
                    contestableIndex++
                }

                return acc
            }, {})

            const contestables = this.pregunta.contestables.sort((c1, c2) => c1.orden - c2.orden)

            this.isCorrect = true
            this.isOmitted = true
            contestables.forEach((contestable, cIndex) => {
                let respuestaId = this.respuestaId
                if (this.respuestas) {
                    respuestaId = this.respuestas[cIndex].alternativa_id
                }

                this.isOmitted = this.isOmitted && !respuestaId

                // TODO(edu): guardar las alternativas escogidas cuando la pregunta tiene más
                // de un contestable
                contestable.alternativas.forEach(a => {
                    if (respuestaId == a.id) {
                        this.alternativaEscogida = a
                    }

                    if (a.correcta) {
                        this.alternativaCorrecta = a
                    }

                    if (!respuestaId || (respuestaId == a.id && !a.correcta)) {
                        this.isCorrect = false
                    }
                })
            })

            this.hasVideoUrl = this.realUrl(this.pregunta.solucion_video)
            this.safeUrl = this.getSafeUrl(this.pregunta.solucion_video)
            if (this.pregunta.grupo_pregunta && this.pregunta.grupo_pregunta.id) {
                this.grupoPreguntaId = this.pregunta.grupo_pregunta.id
            }

            this.dudaQueryParams = {
                item_id: this.pregunta.id,
                item_type: "Pregunta",
                item_categoria: this.pregunta["nombre_asignatura"]
            }
        }
    }

    realUrl(url: string) {
        if (url) {
            if (url == "None" || url == "") {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    getSafeUrl(url: string) {
        if (!this.hasVideoUrl) return null

        const regex = /&.+/gm
        var cleanUrl = url.replace("watch?v=", "embed/").replace("/v/", "/embed/").replace(regex, "")
        var safeUrl: SafeResourceUrl
        if (cleanUrl.indexOf("youtu.be/") >= 0) {
            let y_code = cleanUrl.substr(cleanUrl.indexOf("youtu.be/") + 9, cleanUrl.length)
            cleanUrl = "https://youtube.com/embed/" + y_code
        }
        safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(cleanUrl)
        return safeUrl
    }

    setIsCorrecta() {
        this.isMarkedCorrecta = this.respuestas.reduce((acc, r) => {
            if (acc == null || acc == false) {
                return r.correcta == false ? r.correcta : acc
            }

            return r.correcta
        }, true as boolean)
    }

    onChangePuntuacion(puntuacion: number) {
        if (puntuacion > 0) {
            this.toggleCorrecta(true, puntuacion, true)
        } else {
            this.toggleCorrecta(false, puntuacion, true)
        }
    }

    toggleSolution() {
        this.showSolution = !this.showSolution
    }

    toggleCorrecta(correcta: boolean, newPuntuacion: number = null, omitirNull: boolean = false) {
        const oldIsCorrecta = this.isMarkedCorrecta
        this.isMarkedCorrecta = this.isMarkedCorrecta == correcta && !omitirNull ? null : correcta

        const oldPuntuacion = this.selectedPuntuacion
        this.selectedPuntuacion =
            newPuntuacion ?? (this.isMarkedCorrecta == null ? null : this.isMarkedCorrecta ? this.puntuacion : 0)

        const totalRespuestas = this.respuestas.length

        Promise.all(
            this.respuestas.map(respuesta => {
                respuesta.correcta = this.isMarkedCorrecta
                if (this.selectedPuntuacion != null && this.selectedPuntuacion != undefined) {
                    respuesta.puntuacion = this.selectedPuntuacion / totalRespuestas
                } else if (this.isMarkedCorrecta == null) {
                    respuesta.puntuacion = null
                }

                this.respuestaCorrectaUpdating.emit()

                this.respuestasService.enableIgnoreCatch()
                this.respuestasService
                    .update(respuesta.id, respuesta)
                    .then(r => {
                        respuesta.correcta = r.correcta

                        this.respuestaCorrectaChanged.emit(true)
                    })
                    .catch(_ => {
                        this.isMarkedCorrecta = oldIsCorrecta
                        this.selectedPuntuacion = oldPuntuacion
                    })
            })
        ).finally(() => {
            this.respuestasService.disableIgnoreCatch()
        })
    }

    getLetra(pos: number) {
        return Alternativa.getLetra(pos)
    }
}
