import { BaseModel, SaveType } from "@puntaje/shared/core"
import { GrupoUsuario } from "./grupo_usuarios.model"
import { Usuario } from "./usuarios.model"
import { Noticia } from "./noticias.model"
import { Establecimiento } from "./establecimientos.model"

export class NoticiaUsuario extends BaseModel {
    usuario_id: number
    noticia_id: number

    receptor_type: string
    receptor_id: number

    @SaveType(() => GrupoUsuario) grupo_usuario: GrupoUsuario
    @SaveType(() => Establecimiento) establecimiento: Establecimiento
    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => Noticia) noticia: Noticia
}
