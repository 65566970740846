<div class="filter-box">
    <div class="filtroCurso" *ngIf="showCursos">
        <label>{{ "participa.dudas.listado.filtro.curso" | t }}</label>
        <div class="col-lg-3 col-md-4 col-sm-4 small-margin-col">
            <select class="form-control" [(ngModel)]="grupoUsuario" (change)="grupoUsuarioChange()">
                <option [ngValue]="undefined">{{ "participa.dudas.listado.filtro.curso_select" | t }}</option>
                <option *ngFor="let gu of grupoUsuarios" [ngValue]="gu">
                    {{ gu.nombre }}
                </option>
            </select>
        </div>
    </div>

    <div class="filtro">
        <label>{{ "participa.dudas.listado.filtro.mostrando" | t }}</label>
        <div class="row small-margin-row">
            <div class="col-lg-3 col-md-4 col-sm-4 small-margin-col">
                <div class="toggle-mis-dudas clearfix">
                    <button type="button" class="btn" [class.selected]="!misDudas" (click)="toggleMisDudas(false)">
                        {{ "participa.dudas.listado.filtro.todas" | t }}
                    </button>
                    <button type="button" class="btn" [class.selected]="misDudas" (click)="toggleMisDudas(true)">
                        {{ "participa.dudas.listado.filtro.mias" | t }}
                    </button>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-4 small-margin-col">
                <select class="form-control" [(ngModel)]="answerStatus" (change)="answerStatusChange()">
                    <option
                        *ngFor="let status of answerStatuses; let i = index"
                        [attr.selected]="i == 0"
                        [ngValue]="status"
                    >
                        {{ status.label }}
                    </option>
                </select>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-4 small-margin-col">
                <select
                    [compareWith]="byId"
                    class="form-control"
                    [(ngModel)]="dudaCategoria"
                    (change)="dudaCategoriaChange()"
                >
                    <option [selected]="!dudaCategoria" [ngValue]="undefined">
                        {{ "participa.dudas.listado.filtro.categoria_select" | t }}
                    </option>
                    <option *ngFor="let d of dudaCategorias" [ngValue]="d">
                        {{ d.duda_categoria }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>
