import { SaveType } from "@puntaje/shared/core"
import { Material } from "./materiales.model"
import { PlanEstudioComponente } from "./plan_estudio_componentes.model"
import { PlanEstudioMateriales } from "./plan_estudio_materiales.service"

export class PlanEstudioMaterial extends PlanEstudioComponente {
    public material_id: number
    public tipo: string = "Material"

    @SaveType(() => Material) public material: Material

    constructor(empty: boolean = false) {
        super(empty)
        if (!empty) {
            this.material = new Material()
        }
    }

    public static get serviceClass() {
        return PlanEstudioMateriales
    }
}
