import {
    ChangeDetectorRef,
    Component,
    DoCheck,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core"
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { Subscription } from "rxjs"
import { distinctUntilChanged } from "rxjs/operators"
import { BaseModel } from "../api/base.model"
import { BaseService } from "../api/base.service"
import { AppEnv } from "../conf/app_env"
import { Validations } from "./validations"

@Component({
    templateUrl: "input.component.html",
    styleUrls: ["input.component.scss"],
    selector: "form-input"
})
export class InputComponent implements OnInit, OnDestroy, OnChanges, DoCheck {
    @Input() params: any
    @Input() value: any
    @Input() key: string
    @Input() form: UntypedFormGroup
    @Input() disabled: boolean = false
    @Input() withLabel: boolean = true
    @Input() xhr_upload: any
    @Input() preload_options: BaseModel[] = []
    @Input() placeholder: string
    @Input() tooltip: string
    @Input() enablePasswordBar: boolean = false
    @Input() customError = false
    @Input() removeOnDestroy = false
    public static id_counter: number = 0
    id_instance_counter: number = 0
    passedValueChanges: boolean = false
    validations: typeof Validations = Validations
    control: AbstractControl & { global?: boolean }
    options: BaseModel[]
    filtered_options: BaseModel[] = []
    service: BaseService<any>

    model_params: any
    upload_progress: number = 0

    intermediateValue: any
    filterId: number

    isRequired: boolean

    @Output() valueChange: EventEmitter<any> = new EventEmitter()
    @Output() formChange: EventEmitter<any> = new EventEmitter()
    @Output() optionsChange: EventEmitter<any> = new EventEmitter()

    @ViewChild("autocomplete") autocomplete
    @ViewChild("suggestions") suggestions

    private sub: Subscription
    private dynamicSubs: Subscription[] = []

    dateModel: Date
    private showDatepicker: boolean = false
    datepickerOptions = { format: "DD/MM/YYYY", locale: "es" }

    ckApiUrl: string = this.environment.endpoints.base
    configDefault = { customConfig: "/assets/js/ckeditor_config.js", ckApiUrl: this.ckApiUrl }

    childForm: UntypedFormGroup

    addedAtEndForCreating = false

    constructor(protected injector: Injector, protected cdr: ChangeDetectorRef, protected environment: AppEnv) {}

    getOptions = (
        optionsParams: any,
        injector: Injector,
        control: AbstractControl,
        hidden: boolean = false,
        serviceMethod = "where"
    ): Promise<BaseModel[]> => {
        if (optionsParams !== undefined && optionsParams.class !== undefined) {
            let service = injector.get(optionsParams.class)
            if (!hidden) {
                return service[serviceMethod](optionsParams.params).then((options: BaseModel[]) => {
                    if (
                        this.params.type != "autocomplete" &&
                        this.params.type != "checkbox" &&
                        this.params.type != "select" &&
                        this.params.multiple
                    )
                        control.setValue("")
                    return options
                })
            }

            return null
        } else if (optionsParams) {
            return new Promise<BaseModel[]>((resolve, reject) => resolve(optionsParams))
        } else {
            return null
        }
    }

    private setOptions = () => {
        let temp_val = this.value
        let disabled = this.disabled
        if (this.preload_options.length > 0) {
            this.setPreloadOptions(temp_val, disabled)
        } else {
            if (this.params.options && this.params.options.parent_params) {
                this.params.options.params = this.params.options.params || {}
                this.params.options.parent_params.forEach(p => {
                    this.params.options.params[p] = this.form.parent.parent.controls[p].value
                })
            }
            let optionsPromise = this.getOptions(
                this.params.options,
                this.injector,
                this.control,
                this.params.hidden,
                this.params.options?.serviceMethod
            )
            optionsPromise &&
                optionsPromise.then((options: BaseModel[]) => {
                    this.options = this.params.orden ? this.orden(this.params.orden, options) : options
                    this.optionsChange.emit(options)
                    if (this.params.type == "select") {
                        this.control.setValue(this.value ?? "", { emitEvent: false })
                        if (disabled) this.control.disable()
                    }
                })
        }
    }

    private setPreloadOptions = (temp_val, disabled) => {
        this.options = this.preload_options
        this.optionsChange.emit(this.options)
        if (this.params.type == "select") {
            this.control.setValue(temp_val)
            if (disabled) this.control.disable()
        }
    }

    ngOnInit() {
        if (this.params.type == "model") {
            this.model_params = this.params.class.formParams
            this.childForm = this.form.controls[this.key] as UntypedFormGroup
        } else if (this.params.type == "models") {
            this.model_params = (this.params.class as any).formParams
        } else {
            let formExists = !!this.form
            if (!this.form) {
                this.form = new UntypedFormGroup({})
                this.formChange.emit(this.form)
                // this.cdr.detectChanges();
            }
            this.control = this.form.get(this.key)

            if (!this.control) {
                let serviceValidations = []
                if (this.params.serviceValidators) {
                    serviceValidations = this.params.serviceValidators.map(sv => {
                        let service = this.injector.get(sv.class)
                        return sv.validator(service)
                    })
                }
                this.control = new UntypedFormControl("", this.params.validations, serviceValidations)
                this.form.addControl(this.key, this.control)
            }

            this.control.setValue(this.value != undefined && this.value != null ? this.value : "")

            this.sub = this.control.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
                if (this.params.through && this.params.through.class && this.params.through.field) {
                    !(this.value instanceof this.params.through.class) && (this.value = new this.params.through.class())
                    this.value[this.params.through.field] = value
                } else {
                    if (this.params.filter) {
                        if (this.filterId) {
                            for (const key in this.params.filter[this.filterId]) {
                                this.form.removeControl(key)
                            }
                        }
                        this.filterId = value
                        for (const key in this.params.filter[this.filterId]) {
                            if (!this.form.controls[key]) {
                                this.form.addControl(key, new UntypedFormControl(""))
                            }
                        }
                        this.intermediateValue = []
                    } else {
                        this.value = this.form.get(this.key).value
                    }
                }
                value =
                    this.intermediateValue && this.intermediateValue.length == 0
                        ? this.intermediateValue
                        : value
                        ? this.value
                        : value
                if (!this.params.neglect) {
                    this.valueChange.emit(value)
                    this.passedValueChanges = true
                }
            })

            if (this.params.options && this.params.options.dynamic_params) {
                for (const key in this.params.options.dynamic_params) {
                    let control = this.form.get(this.params.options.dynamic_params[key])
                    this.dynamicSubs.push(
                        control.valueChanges.subscribe(value => {
                            if (!value) {
                                this.params.hidden = true
                                this.control.setValue("")
                                this.value = ""
                            } else {
                                this.params.hidden = false
                                this.params.options.params || (this.params.options.params = {})
                                this.params.options.params[key] = value
                                this.setOptions()
                            }
                        })
                    )
                }
            }
            if (this.params.validations) {
                this.params.validations.forEach(v => {
                    if (v == Validators.required) {
                        this.isRequired = true
                    }
                })
            }

            if (!formExists) {
                this.cdr.detectChanges()
            }
            this.setOptions()
        }
        if (this.params.config) {
            this.configDefault = this.params.config
        }
        this.id_instance_counter = this.constructor["id_counter"]++
    }

    orden(item, opciones) {
        opciones.sort(function (a, b) {
            return a[item] - b[item]
        })
        return opciones
    }

    setFormParentGroup(form) {
        form.setParent(this.form)
    }

    setFormParentArray(form) {
        form.setParent(this.form.controls[this.key])
    }

    ngDoCheck() {
        this.cdr.detectChanges()
    }

    onAutocompleteFocus() {
        ;(this as any).filter()
    }

    onAutocompleteLoseFocus() {
        this.select(this.filtered_options[this.filterId - 1])
    }

    filter(code = null) {
        this.addedAtEndForCreating = false

        if (
            this.value &&
            this.options &&
            this.value !== "" &&
            code != "Enter" &&
            code != "ArrowRight" &&
            code != "ArrowLeft"
        ) {
            this.filtered_options = this.options.filter(el => {
                if (el.toString() && this.value.toString()) {
                    return el.toString().toLowerCase().indexOf(this.value.toString().toLowerCase()) > -1
                } else {
                    return false
                }
            })

            if (this.params.type == "autocomplete" && this.params.options.enableAddNotFound) {
                const isPresent = this.filtered_options.find(
                    el => el.toString().toLowerCase() == this.value.toLowerCase()
                )
                if (!isPresent) {
                    this.filtered_options.push(this.value)
                    this.addedAtEndForCreating = true
                }
            }
        } else {
            this.filtered_options = []
        }
    }

    hoverSelect(item) {
        this.filterId = item + 1
    }

    select(item) {
        if (!item && this.filtered_options.length >= 1) {
            item = this.filtered_options[0]
        } else if (!item && this.filtered_options.length == 0) {
            item = ""
        }
        this.value = item
        this.control.setValue(this.value)
        this.filtered_options = []
        this.filterId = 0
    }

    keepIndex(code) {
        if (this.filterId !== undefined && this.filterId >= 0) {
            if (code == "ArrowDown") {
                if (this.filtered_options) {
                    this.filterId = Math.min(this.filtered_options.length, this.filterId + 1)
                } else {
                    this.filterId = 0
                }
            } else if (code == "ArrowUp") {
                this.filterId = Math.max(0, this.filterId - 1)
            } else if (code == "Enter") {
                ;(document.activeElement as HTMLElement).blur()
            }
        } else {
            this.filtered_options = []
            this.filterId = 0
        }
    }

    emitToParent() {
        this.valueChange.emit(this.intermediateValue.filter(x => x))
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes &&
            changes["preload_options"] &&
            !changes["preload_options"].isFirstChange() &&
            changes["preload_options"].currentValue !== changes["preload_options"].previousValue
        ) {
            this.preload_options = changes["preload_options"].currentValue
            // H4XX0R
            this.value = null
            this.ngOnInit()
            this.valueChange.emit(null)
        }
        if (
            !this.passedValueChanges &&
            this.control &&
            changes["value"] &&
            changes["value"].currentValue != changes["value"].previousValue
        ) {
            !this.params.filter && this.control.setValue(this.value)
        }
        this.passedValueChanges = false
    }

    ngOnDestroy() {
        if (this.form && Object.keys(this.form.controls).length == 1 && this.form.parent instanceof UntypedFormArray) {
            for (let i = 0; i < this.form.parent.controls.length; i++) {
                if (this.form.parent.controls[i] == this.control) {
                    this.form.parent.removeAt(i)
                    break
                }
            }
        }
        // TODO: Arreglar esto por que en el registro de puntaje se cae al ir para atrás dado que depende de un valor del control
        // y este deja de existir. Revisar en qué caso esto es necesario.
        if (this.form && this.removeOnDestroy) {
            this.form.removeControl(this.key)
        }

        if (this.sub) {
            this.sub.unsubscribe()
        }
        this.dynamicSubs.forEach(sub => {
            sub.unsubscribe()
        })
    }

    showPopup() {
        this.showDatepicker = true
    }

    hidePopup(event: Date) {
        this.showDatepicker = false
        this.dateModel = event
        this.valueChange.emit(event)
    }

    onDateModelChange(dateModel) {
        this.dateModel = dateModel
        this.cdr.detectChanges()
    }

    ckEditorFileReady(e) {
        this.cdr.detectChanges()
        setTimeout(() => {
            const data = e.editor.getData()
            if (data) {
                this.control.setValue(data)
                this.valueChange.emit(data)
            }
        }, 100)
    }
}
