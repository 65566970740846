import { Component, OnInit, Input } from "@angular/core"
import { Material, MaterialTipo } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "thumbnail-material",
    templateUrl: "./thumbnail-material.component.html",
    styleUrls: ["./thumbnail-material.component.scss"]
})
export class ThumbnailMaterialComponent implements OnInit {
    @Input() material: Material
    @Input() materialTipos: MaterialTipo[]

    materialTipoNombre: string

    tipo: string
    thumbnailUrl: string

    constructor() {}

    ngOnInit() {
        this.materialTipoNombre =
            this.material?.material_tipo_nombre ||
            this.materialTipos?.find(mt => this.material && mt.id == this.material.material_tipo_id)?.material_tipo
        this.tipo = this.getTipoMaterial(this.material, this.materialTipoNombre)
        this.thumbnailUrl = this.thumbnailsContenido(this.material, this.materialTipoNombre)
    }

    getYoutubeVideoId(url) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
        var match = url.match(regExp)
        return match && match[2].length == 11 ? match[2] : false
    }

    getTipoMaterial(material, materialTipoNombre) {
        if (
            !material ||
            materialTipoNombre == "Interactivo" ||
            materialTipoNombre == "HTML" ||
            materialTipoNombre == "Audio" ||
            materialTipoNombre == "Link" ||
            materialTipoNombre == "PPT" ||
            materialTipoNombre == "Word" ||
            materialTipoNombre == "Excel"
        ) {
            return "otros"
        }

        if (materialTipoNombre == "Archivo" || materialTipoNombre == "Instrumento") {
            return "pdf"
        }

        if (materialTipoNombre == "Video" || materialTipoNombre == "Streaming") {
            return "video"
        }

        if (materialTipoNombre == "Video MP4") {
            return "mp4"
        }

        return "otros"
    }

    thumbnailsContenido(material, materialTipoNombre) {
        if (material && material.thumbnail) {
            return material.thumbnail
        }
        if (material && (materialTipoNombre == "Archivo" || materialTipoNombre == "Instrumento")) {
            let nuevaUrl = material.url.replace("archivo", "thumbnails").replace(".pdf", "-thumbnail.png")
            return nuevaUrl
        }
        if (materialTipoNombre == "Video" || materialTipoNombre == "Streaming") {
            return "https://img.youtube.com/vi/" + this.getYoutubeVideoId(material.url) + "/mqdefault.jpg"
        }
    }
}
