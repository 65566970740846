import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { PlanPersonal } from "./plan_personales.model"
import { GrupoUsuario } from "./grupo_usuarios.model"

export class PlanPersonalUsuario extends BaseModel {
    public plan_personal_id: number
    public usuario_id: number
    public receptor_type: string
    public receptor_id: number

    created_at: string
    updated_at: string

    @SaveType(() => BaseModel) public receptor: BaseModel

    @SaveType(() => PlanPersonal) public plan_personal: PlanPersonal
    @SaveType(() => Usuario) public usuario: Usuario
    @SaveType(() => GrupoUsuario) public grupo_usuario: GrupoUsuario
    @SaveType(() => Usuario) public usuario_receptor: Usuario
}
