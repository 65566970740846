import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from "rxjs"
import { RegisterStepsService, RegisterStepStatus } from "./register-steps.service"

@Component({
    selector: "register-steps",
    templateUrl: "register-steps.component.html",
    styleUrls: ["register-steps.component.scss"]
})
export class RegisterStepsComponent implements OnInit, OnDestroy {
    steps: any[]
    currentIndex: number = 0
    subSteps: Subscription
    subIndex: Subscription
    RegisterStepStatus = RegisterStepStatus

    constructor(protected registerStepsService: RegisterStepsService) {}

    ngOnInit() {
        this.subIndex = this.registerStepsService.currentIndexSubject.subscribe((index: number) => {
            this.currentIndex = index
        })
        this.subSteps = this.registerStepsService.currentStepsSubject.subscribe((steps: any) => {
            this.steps = steps
        })
        this.steps = this.registerStepsService.steps
    }

    selectIndex(index: number) {
        if (this.steps[index].status != RegisterStepStatus.Pending && index != this.currentIndex)
            this.registerStepsService.validateAndGoToStep(index)
    }

    ngOnDestroy() {
        this.subIndex.unsubscribe()
        this.subSteps.unsubscribe()
    }
}
