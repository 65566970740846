import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { UtilModule, TranslationsModule } from "@puntaje/shared/core"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { CarrerasModule } from "@puntaje/puntaje/new-modules/carreras"

import { CUILayoutsModule } from "@puntaje/shared/cui"
import { MisCarrerasComponent } from "./mis-carreras.component"
import { BuscadorCarrerasComponent } from "./buscador-carreras/buscador-carreras.component"
import { CarreraSedeComponent } from "./carrera-sede/carrera-sede.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EnsayosModule,
        SharedModule,
        Angular2FontawesomeModule,
        UtilModule,
        BannersModule,
        NebuModule,
        TranslationsModule,
        CarrerasModule,
        CUILayoutsModule
    ],

    declarations: [MisCarrerasComponent, BuscadorCarrerasComponent, CarreraSedeComponent],
    exports: [MisCarrerasComponent, BuscadorCarrerasComponent, CarreraSedeComponent]
})
export class PuntajeCarrerasModule {}
