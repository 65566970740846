import { Component, Input, OnInit, Optional } from "@angular/core"
import { LoginBaseComponent } from "../login_base/login_base.component"
import { UntypedFormBuilder } from "@angular/forms"
import { Router } from "@angular/router"
import { AppConfig, AuthService, SessionService } from "@puntaje/shared/core"
import { ReporteRazones, NebuAuthService } from "@puntaje/nebulosa/api-services"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { Login } from "@puntaje/puntaje/services"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { CarrerasAuthService } from "@puntaje/carreras/api-services"
import { HttpClient } from "@angular/common/http"
import { I18nService } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "login-standard",
    templateUrl: "login-standard.component.html",
    styleUrls: ["login-standard.component.scss"]
})
export class LoginStandardComponent extends LoginBaseComponent implements OnInit {
    config: typeof config
    showLoginGoogle: boolean = false
    loginErrorUser: string
    @Input() placeholder: string
    @Input() externalRegisterUrl: string

    constructor(
        formBuilder: UntypedFormBuilder,
        authService: AuthService,
        nebuAuthService: NebuAuthService,
        sessionService: SessionService,
        reporteRazonesService: ReporteRazones,
        usuariosService: Usuarios,
        simpleModalService: SimpleModalService,
        store: Store<State>,
        router: Router,
        @Optional() carrerasAuthService: CarrerasAuthService,
        loginService: Login,
        http: HttpClient,
        i18nService: I18nService
    ) {
        super(
            formBuilder,
            authService,
            sessionService,
            nebuAuthService,
            reporteRazonesService,
            usuariosService,
            simpleModalService,
            store,
            router,
            carrerasAuthService,
            loginService,
            http,
            i18nService
        )
    }

    ngOnInit() {
        if (!this.placeholder) {
            this.placeholder =
                config.mensajesLogin?.idPlaceholder || this.i18nService.translate("landing.login.user", {})
        }
        this.loginErrorUser =
            config.mensajesLogin?.invalidLoginId || this.i18nService.translate("landing.login.errorUser", {})
        this.showLoginGoogle = this.config.plataforma.loginGoogle ? this.config.plataforma.loginGoogle : false
    }
}
