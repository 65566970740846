import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"
import { UsuarioUsuario } from "./usuario_usuarios.model"

@Injectable()
export class UsuarioUsuarios extends NebuBaseService<UsuarioUsuario> {
    tableName = "usuario_usuarios"
    singularTableName = "usuario_usuario"
    protected objectQuery: UsuarioUsuario
    modelClass = UsuarioUsuario

    constructor(
        http: HttpClient,
        auth: NebuAuthService,
        router: Router,
        errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
