import { BaseModel } from "@puntaje/shared/core"
export class UsuarioChile extends BaseModel {
    public rut: string
    public telefono: string
    public celular: string
    public codigo_etnia: number
    public direccion: string
    public usuario_id: number
    public ultimo_nivel_academico: number
    public nem: number
    public ranking: number
    public year_psu: number

    public skip_rut_validation: boolean

    constructor() {
        super()
    }

    public toString() {
        return this.rut
    }
}
