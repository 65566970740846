import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./opciones-registro-nacimiento.component.html",
    styleUrls: ["./opciones-registro-nacimiento.component.scss"]
})
export class OpcionesRegistroNacimientoComponent implements OnInit {
    validarIdentificador = true
    subData: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.registro.opciones_registro_nacimiento")
        this.subData = this.route.data.subscribe(data => {
            this.validarIdentificador = data.validarIdentificador ?? this.validarIdentificador
        })
    }

    ngOnDestroy(): void {
        this.subData.unsubscribe()
    }
}
