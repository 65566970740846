import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { AsociacionEstablecimiento } from "./asociacion_establecimientos.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class AsociacionEstablecimientos extends PuntajeBaseService<AsociacionEstablecimiento> {
    tableName = "asociacion_establecimientos"
    singularTableName = "asociacion_establecimiento"
    modelClass = AsociacionEstablecimiento

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
