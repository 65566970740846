<div class="centered-content">
    <div class="logo-container">
        <a routerLink="/home">
            <img [alt]="logoAlt" [src]="logoSrc" class="landing-logo" />
        </a>
    </div>
    <div class="shared-content" [class.simple-shared-layout-contenido]="showContenido" #simpleSharedLayoutContenido>
        <router-outlet></router-outlet>
    </div>
</div>
