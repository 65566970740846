import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { UtilModule, FormModule, ModelViewModule, PaginatorModule } from "@puntaje/shared/core"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { CUIInputsModule } from "@puntaje/shared/cui"

import { TypeformWidgetsComponent } from "./typeform-widgets.component"
import { TypeformWidgetComponent } from "./typeform-widget.component"
import { TypeformWidgetsNewComponent } from "./typeform-widgets.new.component"
import { TypeformWidgetsEditComponent } from "./typeform-widgets.edit.component"
import { TypeformWidgetsDeleteComponent } from "./typeform-widgets.delete.component"
import { TypeformWidgetsToolNavbarComponent } from "./typeform-widgets.toolnavbar.component"
import { TypeformWidgetsViewComponent } from "./typeform-widgets-view.component"
import { TypeformWidgetsBuscadorComponent } from "./typeform-widgets-buscador/typeform-widgets-buscador.component"
import { TypeformWidgetsBuscadorService } from "./typeform-widgets-buscador/typeform-widgets-buscador.service"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        ModelViewModule,
        CommonsLayoutsModule,
        CUIInputsModule,
        PaginatorModule
    ],
    declarations: [
        TypeformWidgetsComponent,
        TypeformWidgetComponent,
        TypeformWidgetsNewComponent,
        TypeformWidgetsEditComponent,
        TypeformWidgetsDeleteComponent,
        TypeformWidgetsToolNavbarComponent,
        TypeformWidgetsViewComponent,
        TypeformWidgetsBuscadorComponent
    ],
    exports: [
        TypeformWidgetsComponent,
        TypeformWidgetComponent,
        TypeformWidgetsNewComponent,
        TypeformWidgetsEditComponent,
        TypeformWidgetsDeleteComponent,
        TypeformWidgetsToolNavbarComponent,
        TypeformWidgetsViewComponent
    ],
    providers: [TypeformWidgetsBuscadorService]
})
export class TypeformWidgetsModule {}
