import {
    Component,
    Input,
    OnInit,
    AfterViewInit,
    ViewChild,
    Output,
    EventEmitter,
    Directive,
    ChangeDetectorRef
} from "@angular/core"
import { Router } from "@angular/router"
import { EvaluacionInteractivaComponent } from "@puntaje/puntaje/new-modules/ensayos"
import {
    Evaluacion,
    EvaluacionInstancias,
    EvaluacionInstancia,
    PlanPersonalInstancia,
    PlanPersonalSesion,
    PlanPersonalSesiones,
    PlanPersonalContenido,
    PlanPersonalContenidos,
    PlanPersonalEvaluacionInstancia,
    PlanPersonalEvaluacionInstancias,
    Evaluaciones,
    EvaluacionTiempo
} from "@puntaje/puntaje/api-services"
import { AuthService, ScrollToService, CheckAchievement, CheckActividad } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { trigger, state, style, animate, transition, keyframes, query, stagger } from "@angular/animations"
import { Subscription } from "rxjs"
import { RealizarEnsayoService } from "@puntaje/puntaje/new-modules/ensayos"
import { Store } from "@ngrx/store"
import { State, SetActual, CrearAlertaLogros } from "@puntaje/puntaje/store"
import { SetSeeingIndex, SetRespuestasActual, ResetDudaPreguntas, SetItems } from "@puntaje/puntaje/store"
import { EvaluacionSecuencialComponent } from "@puntaje/puntaje/new-modules/ensayos"

@Component({
    selector: "plan-personal-evaluacion",
    templateUrl: "plan_personal_evaluacion.component.html",
    styleUrls: ["plan_personal_evaluacion.component.scss"],
    animations: [
        trigger("fadeIn", [
            state("in", style({ opacity: 1 })),
            transition("void => *", [
                animate(
                    "0.8s 0ms ease-in",
                    keyframes([style({ opacity: 0, offset: 0 }), style({ opacity: 1, offset: 1.0 })])
                )
            ])
        ])
    ]
})
export class PlanPersonalEvaluacionComponent implements OnInit {
    evaluacion: Evaluacion
    @Input() evaluacionId: number
    @Input() planPersonalContenido: PlanPersonalContenido
    @Input() enableDudas: boolean = true
    @Input() enableReporte: boolean = true
    @Input() planPersonalInstancia: PlanPersonalInstancia
    @Input() componentesAvance: { [x: number]: boolean }
    @Input() planPersonalSesion: PlanPersonalSesion
    evaluacionInstanciaId: number
    previousEvaluacionInstancia: EvaluacionInstancia
    evaluacionInstancia: EvaluacionInstancia
    //Valores de este enum están definidos abajo en este mismo archivo
    evaluationState: EvaluationStates = EvaluationStates.Start
    evaluationStates = EvaluationStates
    loadClock: boolean = false
    evaluacionPorcentaje: number = 0
    evaluacionPorcentajeAnimated: number = 0
    showLoader: boolean = false
    animState: string = "in"
    hideBtnEntregar: boolean = true

    readyEvaluacionSub: Subscription
    evaluacionEntregadaSub: Subscription
    evaluacionTiempo: EvaluacionTiempo

    @ViewChild("evaluacionInteractivaEnsayo")
    evaluacionInteractivaEnsayo: EvaluacionInteractivaComponent
    @ViewChild(EvaluacionSecuencialComponent) evaluacionSecuencial: EvaluacionSecuencialComponent
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    readySub: Subscription

    constructor(
        protected realizarEnsayoService: RealizarEnsayoService,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected evaluacionesService: Evaluaciones,
        protected scrollToService: ScrollToService,
        protected authService: AuthService,
        private ppeiService: PlanPersonalEvaluacionInstancias,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef
    ) {}

    async ngOnInit() {
        this.showLoader = true

        this.store.dispatch(new SetActual({ actual: null }))
        this.store.dispatch(new SetRespuestasActual({ respuestasActual: [] }))
        this.store.dispatch(new SetSeeingIndex(0))

        //revisar si existe una evaluación previa
        this.evaluacion = await this.evaluacionesService.find(this.evaluacionId, { raw: true })
        this.hideBtnEntregar = this.evaluacion.interactivo
        this.evaluacionInstanciasService
            .where({
                evaluacion_instancia: {
                    evaluacion_id: this.evaluacionId,
                    usuario_id: this.authService.getUserData().id
                }
            })
            .then((eis: EvaluacionInstancia[]) => {
                if (eis.length > 0) {
                    //si la hay, mostrarla
                    this.previousEvaluacionInstancia = eis[eis.length - 1]
                    this.setEvaluacionPorcentaje(this.previousEvaluacionInstancia)
                } else {
                    //si no la hay, comenzar la evaluación
                    this.evaluationState = EvaluationStates.Evaluating
                }
                this.showLoader = false
            })

        this.readyEvaluacionSub = this.realizarEnsayoService.readyEvaluacion.subscribe(
            this.onReadyEvaluacion.bind(this)
        )
        this.evaluacionEntregadaSub = this.realizarEnsayoService.evaluacionEntregada.subscribe(
            this.onEvaluationSent.bind(this)
        )

        this.showLoader = false
    }

    onReadyEvaluacion(evaluacion) {
        this.evaluacion = evaluacion
    }

    onSendEvaluation() {
        this.showLoader = true
        if (this.evaluacion.interactivo) {
            this.evaluacionInteractivaEnsayo.entregarEnsayo(true)
        } else {
            this.evaluacionSecuencial.entregarEnsayo()
        }
        this.evaluacionPorcentajeAnimated = 0
        this.scrollToService.scrollToTop()
    }

    onRepeatEvaluation() {
        this.evaluationState = EvaluationStates.Evaluating
    }

    onLoadClockChange(loadClock) {
        this.loadClock = loadClock
    }

    onTiempoReady = function (ensayoTiempo) {
        this.evaluacionTiempo = ensayoTiempo
        this.cdr.detectChanges()
        this.evaluacionSecuencial.getRespuestasFromLocal()
        this.evaluacionSecuencial.displayPreguntas = true
    }

    onEvaluationSent(evaluacionInstanciaId: number) {
        this.resetStore()
        // this.evaluacion = undefined
        // this.evaluacionTiempo = undefined
        this.loadClock = false

        this.evaluacionInstanciaId = evaluacionInstanciaId
        this.evaluationState = EvaluationStates.Results
        this.evaluacionInstanciasService.find(this.evaluacionInstanciaId).then((ei: EvaluacionInstancia) => {
            if (this.planPersonalContenido) {
                if (this.planPersonalContenido.plan_personal_evaluacion_instancias.length == 0) {
                    const planPersonalEvaluacionInstancia = new PlanPersonalEvaluacionInstancia()
                    planPersonalEvaluacionInstancia.plan_personal_instancia_id = this.planPersonalInstancia.id
                    planPersonalEvaluacionInstancia.plan_personal_contenido_id = this.planPersonalContenido.id
                    planPersonalEvaluacionInstancia.evaluacion_instancia_id = ei.id

                    this.ppeiService
                        .save(planPersonalEvaluacionInstancia)
                        .then((ppEvaluacionInstancia: PlanPersonalEvaluacionInstancia) => {
                            this.planPersonalContenido.plan_personal_evaluacion_instancias = [ppEvaluacionInstancia]
                            this.componentesAvance[this.planPersonalContenido.id] = true
                            this.showLoader = false

                            this.checkCompletedSesion()
                        })
                } else {
                    const planPersonalEvaluacionInstancia =
                        this.planPersonalContenido.plan_personal_evaluacion_instancias[0]
                    const planPersonalEvaluacionInstanciaNew = new PlanPersonalEvaluacionInstancia()
                    planPersonalEvaluacionInstanciaNew.evaluacion_instancia_id = ei.id

                    this.ppeiService
                        .update(planPersonalEvaluacionInstancia.id, planPersonalEvaluacionInstanciaNew)
                        .then((_: PlanPersonalEvaluacionInstancia) => {
                            planPersonalEvaluacionInstancia.evaluacion_instancia_id = ei.id

                            this.showLoader = false

                            this.checkCompletedSesion()
                        })
                }
            } else {
                this.showLoader = false
            }
        })
        /*setTimeout(() => {
			this.showLoader = false;
		},1000);*/
        /*this.evaluacionInstanciasService.find(this.evaluacionInstanciaId).then((ei: EvaluacionInstancia) => {
			this.evaluacionInstancia = ei;
			this.evaluacionPorcentaje = Math.ceil((this.evaluacionInstancia.correctas * 100)/(this.evaluacionInstancia.correctas+this.evaluacionInstancia.omitidas+this.evaluacionInstancia.incorrectas));
		});*/
    }

    @CheckAchievement("PN_PLAN_CLASES_COMPLETAR_CICLO", CrearAlertaLogros)
    @CheckActividad("SC")
    checkCompletedSesion() {
        return this.planPersonalSesion
    }

    onEvaluationInstanciaReady(evaluacionInstancia: EvaluacionInstancia) {
        this.evaluacionInstancia = evaluacionInstancia
        this.setEvaluacionPorcentaje(evaluacionInstancia)
    }

    setEvaluacionPorcentaje(evaluacionInstancia: EvaluacionInstancia) {
        this.evaluacionPorcentaje = Math.ceil(
            (evaluacionInstancia.correctas * 100) /
                (evaluacionInstancia.correctas + evaluacionInstancia.omitidas + evaluacionInstancia.incorrectas)
        )
        setTimeout(() => {
            this.evaluacionPorcentajeAnimated = this.evaluacionPorcentaje
        }, 1000)
    }

    resetStore() {
        this.store.dispatch(new SetActual({ actual: null }))
        this.store.dispatch(new SetItems({ items: [] }))
        this.store.dispatch(new SetRespuestasActual({ respuestasActual: [] }))
        this.store.dispatch(new ResetDudaPreguntas())
        this.store.dispatch(new SetSeeingIndex(0))
    }

    ngOnDestroy() {
        if (this.readyEvaluacionSub) this.readyEvaluacionSub.unsubscribe()
        if (this.evaluacionEntregadaSub) this.evaluacionEntregadaSub.unsubscribe()
    }
}

@Directive({
    selector: "plan-personal-evaluacion-progress-buttons-slot-up,plan-personal-evaluacion-progress-buttons-slot-down"
})
export class PlanPersonalEvaluacionComponentTags {}
enum EvaluationStates {
    Start,
    Evaluating,
    Results
}
