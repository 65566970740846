import { BaseModel, SaveType, EvaluacionTipoNombre, AsignaturaType } from "@puntaje/shared/core"
import { AsignaturaPlataforma } from "./asignatura_plataformas.model"
import { Clasificacion } from "./clasificaciones.model"
import { GeneradorInstrumento } from "./generador_instrumentos.model"
import { Persona } from "./personas.model"

export type AsignaturaConfig = AsignaturaType

export class Asignatura extends BaseModel {
    public static className: string = "Asignatura"
    public asignatura: string
    public abreviacion: string
    public codigo_estilo: string
    public created_at: Date
    public updated_at: Date
    public encargado_id: number

    public nombre_encargado: string
    public plataforma_id: string
    public asignaturaPlataforma: string
    public clase: string
    @SaveType(() => Clasificacion) public clasificaciones: Clasificacion[]
    @SaveType(() => GeneradorInstrumento) public generador_instrumentos: GeneradorInstrumento[]
    @SaveType(() => AsignaturaPlataforma) public asignatura_plataformas: AsignaturaPlataforma[]
    @SaveType(() => Persona) public encargado: Persona

    materialVisible: boolean | { [tipo in EvaluacionTipoNombre]: boolean }

    constructor() {
        super()
        this.clasificaciones = []
        this.asignatura_plataformas = []
        this.generador_instrumentos = []
    }

    public toString() {
        return this.asignatura
    }
}

export type AsignaturaWithConfig = Asignatura & AsignaturaConfig
