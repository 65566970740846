/** @format */

import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router"
import { AuthService } from "./auth.service"
import { SessionService } from "../session/session.service"
import { ZopimService } from "../zopim/zopim.service"
import { AppConfig } from "../conf/app_config"
// import { Store } from "@ngrx/store";
// import { State, SetCodigoConfirmacionData } from "@puntaje/puntaje/store";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate {
    constructor(
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected zopimService: ZopimService,
        protected router: Router, // protected store: Store<State>,
        protected config: AppConfig
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const oldAttemptedUrl = this.getAttemptedUrl()
        const redirectAfterRegister = this.authService.getRedirectAfterRegister()

        if (
            this.authService.isLoggedIn() &&
            (this.config.app.name === "nebulosa" || this.sessionService.minUserFields())
        ) {
            this.setUserData()
            this.authService.removeRedirectAfterRegister()
            this.storeAttemptedUrl(null)

            return this.urlTreeFor(redirectAfterRegister) || this.urlTreeFor(oldAttemptedUrl) || true
        }

        // Esto solo se guarda si el usuario no está logueado
        this.storeAttemptedUrl(state.url)

        this.authService.logout()
        this.navigateToLanding()

        return false
    }

    private getAttemptedUrl(): string {
        const url = this.authService.redirectUrl

        return url
    }

    private storeAttemptedUrl(url: string): void {
        this.authService.redirectUrl = url
    }

    private urlTreeFor(url: string): UrlTree | boolean {
        if (!url) {
            return false
        }
        return this.router.parseUrl(url)
    }

    private setUserData(): void {
        const user = this.authService.getUserData()
        if (this.config.app.name === "profesores" && !this.config.plataforma.disableZopim) {
            this.zopimService.setZopimData()
            this.zopimService.showZopim()
        }
        if (user && (user as any).id) {
            this.authService.setAnalyticsUser((user as any).id)
        }
    }

    private navigateToLanding(): void {
        const landing = this.config.app.paths.landing
        this.router.navigate([landing])
    }
}
