import { Component, Directive, Input, HostBinding, ContentChild } from "@angular/core"

@Directive({ selector: "cui-showcasebox-title-icon" })
export class CUIShowcaseBoxTitleIcon {}

@Directive({ selector: "cui-showcasebox-title-text" })
export class CUIShowcaseBoxTitleText {}

@Directive({ selector: "cui-showcasebox-content" })
export class CUIShowcaseBoxContent {}

@Directive({ selector: "cui-showcasebox-data-not-found" })
export class CUIShowcaseBoxDataNotFound {}

@Directive({ selector: "cui-showcasebox-view-more" })
export class CUIShowcaseBoxViewMore {}

@Component({
    selector: "cui-showcasebox",
    templateUrl: "cui-showcasebox.component.html",
    styleUrls: ["cui-showcasebox.component.scss"]
})
export class CUIShowcaseBoxComponent {
    @Input() openStyle: boolean = false
    @Input() overDarkStyle: boolean = false
    @Input() hideContent: boolean = false
    @Input() type: string
    @HostBinding("class") innerTheme: string = ""
    @ContentChild(CUIShowcaseBoxContent) content: CUIShowcaseBoxContent
    @ContentChild(CUIShowcaseBoxDataNotFound) dataNotFound: CUIShowcaseBoxDataNotFound
    @ContentChild(CUIShowcaseBoxViewMore) viewMore: CUIShowcaseBoxViewMore

    constructor() {}

    ngOnInit(): void {
        this.innerTheme = this.type
            ? "cui-showcasebox-theme-default cui-showcasebox-theme-" + this.type
            : "cui-showcasebox-theme-default"
        //this.innerTheme = this.type
    }
}
