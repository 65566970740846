import { ModuleWithProviders, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { AlertModule } from "ngx-bootstrap/alert"
import { ModalModule } from "ngx-bootstrap/modal"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TabsModule } from "ngx-bootstrap/tabs"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { CUITextsModule, CUIInputsModule, CUITablesModule, CUIContainersModule } from "@puntaje/shared/cui"

import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import {
    UtilModule,
    FormModule,
    PaginatorModule,
    FlashMessageModule,
    TranslationsModule,
    ModelViewModule
} from "@puntaje/shared/core"

import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { InstrumentoComponent } from "./instrumento.component"
import { InstrumentoDesdeLoteComponent } from "./instrumento_desde_lote.component"
import { MisInstrumentosComponent } from "./mis_instrumentos.component"
import { OpcionesInstrumentoComponent } from "./opciones-instrumento/opciones_instrumento.component"
import { DatosGeneralesComponent } from "./datos_generales.component"
import { InstrumentoPreguntaComponent } from "./instrumento_pregunta.component"
import { CompartirInstrumentoProfesorComponent } from "./compartir_instrumento_profesor.component"
import { InstrumentosNoCompartidosComponent } from "./instrumentos_no_compartidos.component"
import { InstrumentosMultiplesNoCompartidosComponent } from "./instrumentos-multiples-no-compartidos/instrumentos_multiples_no_compartidos.component"
import { TablaClasificacionesPreguntaComponent } from "./tabla_clasificaciones_pregunta.component"
import { TablaEstadisticasPreguntaComponent } from "./tabla-estadisticas-pregunta/tabla-estadisticas-pregunta.component"
import { ListaResultadosInstrumentoComponent } from "./lista_resultados_instrumento.component"
import { GenerarInstrumentoProfesorComponent } from "./generar_instrumento_profesor.component"
import { NumeroPreguntasTiempoComponent } from "./numero_preguntas_tiempo.component"
import { GeneradorInstrumentoSelectComponent } from "./generador_instrumento_select.component"
import { ConfigurarClasificacionesComponent } from "./configurar_clasificaciones.component"
import { ClasificacionesCheckboxesComponent } from "./clasificaciones_checkboxes.component"
import { ClasificacionesCheckboxesCBComponent } from "./clasificaciones-checkboxes-cb/clasificaciones-checkboxes-cb.component"
import { AbstractGenerarInstrumentoComponent } from "./abstract_generar_instrumento.component"
import { GenerarInstrumentoListaPreguntasComponent } from "./generar_instrumento_lista_preguntas.component"
// mensuales
import { InstrumentoMensualAsignaturaComponent } from "./mensuales/instrumento_mensual_asignatura.component"
import { InstrumentosMensualComponent } from "./mensuales/instrumentos_mensual.component"
import { InstrumentoMensualShowcaseboxComponent } from "./mensuales/instrumento_mensual_showcasebox.component"
import { InstrumentoMensualRankingComponent } from "./mensuales/instrumento_mensual_ranking.component"
import { InstrumentosMensualesRankingComponent } from "./mensuales/instrumentos_mensuales_ranking.component"

import { InstrumentosCBShowcaseBoxComponent } from "./instrumentos_cb_showcasebox.component"
import { CompartirInstrumentoMultipleProfesorComponent } from "./compartir-instrumento-multiple-profesor/compartir-instrumento-multiple-profesor.component"
import { OpcionesInstrumentoMultipleComponent } from "./opciones-instrumento-multiple/opciones-instrumento-multiple.component"
import { InstrumentoMultipleComponent } from "./instrumento-multiple/instrumento_multiple.component"
import { SubirPruebaPropiaComponent } from "./subir-prueba-propia/subir-prueba-propia.component"
import { CompartirInstrumentoMultipleSimpleProfesorComponent } from "./compartir-instrumento-multiple-simple-profesor/compartir-instrumento-multiple-simple-profesor.component"

import { SubirArchivoComponent } from "./subir-prueba-propia/subir-archivo/subir-archivo.component"
import { VistaPreviaArchivoComponent } from "./subir-prueba-propia/vista-previa-archivo/vista-previa-archivo.component"
import { SelectsEvaluacionAsignaturaComponent } from "./subir-prueba-propia/selects-evaluacion-asignatura/selects-evaluacion-asignatura.component"
import { SubirPautaComponent } from "./subir-prueba-propia/subir-pauta/subir-pauta.component"
import { TerminosCondicionesComponent } from "./subir-prueba-propia/terminos-condiciones/terminos-condiciones.component"
import { PautaPruebaPropiaComponent } from "./subir-prueba-propia/pauta-prueba-propia/pauta-prueba-propia.component"
import { PruebasPropiasComponent } from "./pruebas-propias/pruebas-propias.component"
import { PruebasPropiasPorGeneradorComponent } from "./pruebas-propias/pruebas-propias-por-generador.component"
import { VerPreguntaModalComponent } from "./modal/ver-pregunta-modal/ver-pregunta-modal.component"
import { AlertPreguntasExclusivasComponent } from "./alert-preguntas-exclusivas/alert-preguntas-exclusivas.component"
import { UnlockedInstrumentoCbRowComponent } from "./unlocked-instrumento-cb-row/unlocked-instrumento-cb-row.component"
import { InstrumentosBuscadorComponent } from "./instrumentos-buscador/instrumentos-buscador.component"
import { SelectContestableTipoComponent } from "./subir-prueba-propia/select-contestable-tipo/select-contestable-tipo.component"
import { CrearInstrumentoMultipleComponent } from "./crear-instrumento-multiple/crear-instrumento-multiple.component"
import { SubirPautaMixtaComponent } from "./subir-prueba-propia/subir-pauta-mixta/subir-pauta-mixta.component"
import { EscalaGlobalesModule } from "@puntaje/puntaje/new-modules/escala-globales"
import { ClasificacionesModule } from "@puntaje/puntaje/new-modules/clasificaciones"
import { ClasificarPreguntasPautaComponent } from "./subir-prueba-propia/clasificar-preguntas-pauta/clasificar-preguntas-pauta.component"
import { VistaPreviaInstrumentoComponent } from "./vista-previa-instrumento/vista-previa-instrumento.component"
import { OpcionesInstrumentoService } from "./opciones-instrumento/opciones_instrumento.service"
import { ClasificacionCheckboxesComponent } from "./clasificacion-checkboxes.component"

import { GeneradorDiagnosticoComponent } from "./generador-diagnostico/generador-diagnostico.component"
import { GeneradorDiagnosticoEvaluacionComponent } from "./generador-diagnostico/generador-diagnostico-evaluacion.component"
import { GeneradorDiagnosticoEvaluacionEditComponent } from "./generador-diagnostico/generador-diagnostico-evaluacion.edit.component"
import { VistaInstrumentosMultiplesComponent } from "./vista-instrumentos-multiples/vista-instrumentos-multiples.component"

@NgModule({
    imports: [
        PaginatorModule,
        Angular2FontawesomeModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        UtilModule,
        NebuModule,
        TranslationsModule,
        FormModule,
        CommonsLayoutsModule,
        FlashMessageModule,
        RouterModule,
        SharedModule,
        ModalModule,
        ProgressbarModule,
        TooltipModule,
        TabsModule,
        ModelViewModule,
        AlertModule.forRoot(),
        CUITextsModule,
        CUIInputsModule,
        CUITablesModule,
        CUIContainersModule,
        EscalaGlobalesModule,
        ClasificacionesModule
    ],
    exports: [
        InstrumentoComponent,
        MisInstrumentosComponent,
        InstrumentoDesdeLoteComponent,
        OpcionesInstrumentoComponent,
        DatosGeneralesComponent,
        InstrumentoPreguntaComponent,
        CompartirInstrumentoProfesorComponent,
        InstrumentosNoCompartidosComponent,
        InstrumentosMultiplesNoCompartidosComponent,
        TablaClasificacionesPreguntaComponent,
        TablaEstadisticasPreguntaComponent,
        ListaResultadosInstrumentoComponent,
        InstrumentosMensualComponent,
        InstrumentoMensualAsignaturaComponent,
        InstrumentoMensualShowcaseboxComponent,
        InstrumentoMensualRankingComponent,
        InstrumentosMensualesRankingComponent,
        GenerarInstrumentoProfesorComponent,
        NumeroPreguntasTiempoComponent,
        GeneradorInstrumentoSelectComponent,
        ConfigurarClasificacionesComponent,
        ClasificacionesCheckboxesComponent,
        ClasificacionesCheckboxesCBComponent,
        InstrumentosCBShowcaseBoxComponent,
        AbstractGenerarInstrumentoComponent,
        GenerarInstrumentoListaPreguntasComponent,
        CompartirInstrumentoMultipleProfesorComponent,
        OpcionesInstrumentoMultipleComponent,
        CompartirInstrumentoMultipleSimpleProfesorComponent,
        InstrumentoMultipleComponent,
        SubirPruebaPropiaComponent,
        PautaPruebaPropiaComponent,
        PruebasPropiasComponent,
        VerPreguntaModalComponent,
        UnlockedInstrumentoCbRowComponent,
        InstrumentosBuscadorComponent,
        CrearInstrumentoMultipleComponent,
        VistaPreviaInstrumentoComponent,
        GeneradorDiagnosticoComponent,
        GeneradorDiagnosticoEvaluacionComponent,
        GeneradorDiagnosticoEvaluacionEditComponent,
        VistaInstrumentosMultiplesComponent
    ],
    declarations: [
        InstrumentoComponent,
        MisInstrumentosComponent,
        InstrumentoDesdeLoteComponent,
        OpcionesInstrumentoComponent,
        DatosGeneralesComponent,
        InstrumentoPreguntaComponent,
        CompartirInstrumentoProfesorComponent,
        InstrumentosNoCompartidosComponent,
        InstrumentosMultiplesNoCompartidosComponent,
        TablaClasificacionesPreguntaComponent,
        TablaEstadisticasPreguntaComponent,
        ListaResultadosInstrumentoComponent,
        InstrumentosMensualComponent,
        InstrumentoMensualAsignaturaComponent,
        InstrumentoMensualShowcaseboxComponent,
        InstrumentoMensualRankingComponent,
        InstrumentosMensualesRankingComponent,
        GenerarInstrumentoProfesorComponent,
        NumeroPreguntasTiempoComponent,
        GeneradorInstrumentoSelectComponent,
        ConfigurarClasificacionesComponent,
        ClasificacionesCheckboxesComponent,
        ClasificacionesCheckboxesCBComponent,
        InstrumentosCBShowcaseBoxComponent,
        AbstractGenerarInstrumentoComponent,
        GenerarInstrumentoListaPreguntasComponent,
        CompartirInstrumentoMultipleProfesorComponent,
        OpcionesInstrumentoMultipleComponent,
        CompartirInstrumentoMultipleSimpleProfesorComponent,
        InstrumentoMultipleComponent,
        SubirPruebaPropiaComponent,
        SubirArchivoComponent,
        VistaPreviaArchivoComponent,
        SelectsEvaluacionAsignaturaComponent,
        SubirPautaComponent,
        TerminosCondicionesComponent,
        PautaPruebaPropiaComponent,
        PruebasPropiasComponent,
        PruebasPropiasPorGeneradorComponent,
        VerPreguntaModalComponent,
        AlertPreguntasExclusivasComponent,
        UnlockedInstrumentoCbRowComponent,
        InstrumentosBuscadorComponent,
        SelectContestableTipoComponent,
        CrearInstrumentoMultipleComponent,
        SubirPautaMixtaComponent,
        ClasificarPreguntasPautaComponent,
        VistaPreviaInstrumentoComponent,
        ClasificacionCheckboxesComponent,
        GeneradorDiagnosticoComponent,
        GeneradorDiagnosticoEvaluacionComponent,
        GeneradorDiagnosticoEvaluacionEditComponent,
        VistaInstrumentosMultiplesComponent
    ],
    providers: [OpcionesInstrumentoService]
})
export class InstrumentosModule {}
