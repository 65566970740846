<nav>
    <ul class="menu-options" [class.style_default]="!withBorder" [class.style_with_border]="withBorder">
        <ng-container *ngFor="let item of itemsMenu">
            <li routerLinkActive="active-menu base-menu-active-menu style_default">
                <a [routerLink]="item.route" [queryParams]="item.params" role="button">
                    <ogr-icon class="icono" name="{{ item.icon }}" align="top"></ogr-icon>
                    <span>{{ item.name | t }}</span>
                </a>
            </li>
        </ng-container>
    </ul>
</nav>
