import { BaseModel } from "@puntaje/shared/core"

export class MaterialTipo extends BaseModel {
    public static className: string = "MaterialTipo"
    public material_tipo: string
    public created_at: Date
    public updated_at: Date

    public static model_params: any = {
        id: { label: "Id", type: "hidden", visible: false },
        material_tipo: { label: "Tipo de Material", type: "text", visible: true },
        created_at: { label: "Fecha de creación", type: "hidden", visible: false },
        updated_at: { label: "Fecha de actualización", type: "hidden", visible: false }
    }

    public toString() {
        return this.material_tipo
    }
}

export enum TipoMaterial {
    FILE = 1,
    VIDEO = 2,
    STREAMING = 3,
    HTML = 4,
    INSTRUMENTO = 5,
    INTERACTIVO = 6,
    AUDIO = 7,
    MP4 = 8,
    PPT = 9,
    LINK = 10,
    WORD = 11,
    EXCEL = 12
}
