import { PuntajeLandingModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule } from "@puntaje/shared/core"
import { LazyLoadImageModule } from "ng-lazyload-image"

import { HomeComponent } from "./home.component"
import { LandingComponent } from "./landing.component"

import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { SharedLayoutsModule } from "@puntaje/platforms/fueguino"
import { LandingRouting } from "./alumnos_landing.routing"

import { DudasModule } from "@puntaje/puntaje/new-modules/dudas"
import { CarrerasModule } from "@puntaje/puntaje/new-modules/carreras"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { EstadisticasModule } from "@puntaje/puntaje/new-modules/estadisticas"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { StreamingModule } from "@puntaje/puntaje/new-modules/streaming"
import { PlanPersonalesModule } from "@puntaje/puntaje/new-modules/plan-personales"
import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { NoticiasModule } from "@puntaje/puntaje/new-modules/noticias"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { LandingModule } from "@puntaje/puntaje/new-modules/landing"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { EntrenadorModule } from "@puntaje/puntaje/new-modules/entrenador"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"

@NgModule({
    imports: [
        InstrumentosModule,
        CommonModule,
        FormsModule,
        LandingRouting,
        AlumnosLayoutsModule,
        LandingModule,
        UsuariosModule,
        UtilModule,
        Angular2FontawesomeModule,
        SharedLayoutsModule,
        MaterialesModule,
        NotificacionesModule,
        EnsayosModule,
        StreamingModule,
        BannersModule,
        LazyLoadImageModule,
        DudasModule,
        EntrenadorModule,
        PlanPersonalesModule,
        EstadisticasModule,
        NoticiasModule,
        CarrerasModule,
        CUILayoutsModule,
        PuntajeLandingModule
    ],
    declarations: [HomeComponent, LandingComponent],
    exports: [HomeComponent, LandingComponent]
})
export class AlumnosLandingModule {
    static forRoot(): ModuleWithProviders<AlumnosLandingModule> {
        return {
            ngModule: AlumnosLandingModule,
            providers: []
        }
    }
}
