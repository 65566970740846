import { Component, OnInit, Output, EventEmitter, ViewChild } from "@angular/core"
import { InstrumentoPredefinidos, InstrumentoPredefinido, Instrumento } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { GeneradorInstrumentos, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "ensayos-multiples-recomendados",
    templateUrl: "./ensayos-multiples-recomendados.component.html",
    styleUrls: ["./ensayos-multiples-recomendados.component.scss"]
})
export class EnsayosMultiplesRecomendadosComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    instrumentos: InstrumentoPredefinido[]
    @Output() onReadyInstrumento: EventEmitter<any> = new EventEmitter<any>()
    currentInstrumento: InstrumentoPredefinido

    constructor(
        protected instrumentoPredefinidosService: InstrumentoPredefinidos,
        protected generadorInstrumentosService: GeneradorInstrumentos
    ) {}

    ngOnInit() {
        this.getInstrumentosPredefinidos()
    }

    getInstrumentosPredefinidos() {
        this.generadorInstrumentosService
            .where({ tipo_instrumento: { tipo_instrumento: "simulacro saber11" } })
            .then((generadorInstrumentos: GeneradorInstrumento[]) => {
                const params = {
                    instrumento_multiple: {
                        generador_instrumento_id: generadorInstrumentos.filter(gi => gi.multiple).map(gi => gi.id)
                    },
                    render_options: {
                        include: {
                            instrumento_multiple: {
                                include: {
                                    instrumentos: {
                                        include: {
                                            instrumento_preguntas: null
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                this.instrumentoPredefinidosService.where(params).then((instrumentos: InstrumentoPredefinido[]) => {
                    this.instrumentos = instrumentos
                    this.loadingLayout.ready()
                })
            })
    }

    seleccionar(instrumento: InstrumentoPredefinido) {
        // this.onLoadingInstrumento.emit();
        this.onReadyInstrumento.emit(instrumento)
        this.currentInstrumento = instrumento
    }
}
