import { Component, Input, Output, EventEmitter, ChangeDetectorRef, forwardRef } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Form, FormGroup, AbstractControl } from "@angular/forms"

@Component({
    selector: "birthdate-form",
    template: `
        <div>
            <select
                aria-label="día"
                class="form-control birthdate-select-d"
                [(ngModel)]="day"
                (ngModelChange)="onChangeDate('day')"
                [attr.aria-invalid]="invalid"
                [attr.aria-describedby]="describeError"
            >
                <option *ngFor="let d of days" [value]="d">{{ d }}</option>
            </select>
            /
            <select
                aria-label="mes"
                class="form-control birthdate-select-m"
                [(ngModel)]="month"
                (ngModelChange)="onChangeDate('month')"
                [attr.aria-invalid]="invalid"
                [attr.aria-describedby]="describeError"
            >
                <option *ngFor="let m of months; let i = index" [value]="i">{{ m }}</option>
            </select>
            /
            <select
                aria-label="año"
                class="form-control birthdate-select-y"
                [(ngModel)]="year"
                (ngModelChange)="onChangeDate('year')"
                [attr.aria-invalid]="invalid"
                [attr.aria-describedby]="describeError"
            >
                <option *ngFor="let y of years; let i = index" [value]="y">{{ y }}</option>
            </select>
        </div>
    `,
    styleUrls: ["input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BirthdateFormComponent),
            multi: true
        }
    ]
})
export class BirthdateFormComponent implements ControlValueAccessor {
    @Input() date: Date
    @Input() invalid: boolean
    @Input() describeError: string

    //@Output() dateChange: EventEmitter<Date> = new EventEmitter();
    days: number[] = []
    months: string[] = []
    years: number[] = []

    day: number = 1
    month: number = 0
    year: number = 1990

    onChange: (_: any) => {}
    onTouch = () => {}

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.days = Array(31)
            .fill(0)
            .map((x, i) => i + 1)
        this.months = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ]
        let currentYear = new Date().getFullYear()
        this.years = Array(100)
            .fill(0)
            .map((x, i) => currentYear - i)
        this.year = currentYear
    }

    onChangeDate(change: string) {
        setTimeout(() => {
            this.date = new Date(this.year, this.month, this.day)
            if (change == "month" || change == "year") {
                this.updateDays()
            }
            this.onChange(this.date)
        }, 0)
    }

    writeValue(value: any) {
        if (value instanceof Date) {
            this.date = value
            this.year = this.date.getFullYear()
            this.month = this.date.getMonth()
            this.day = this.date.getUTCDate()
            /*this.onChange(this.selected);*/
        }
    }

    registerOnChange(fn: (value: any) => any) {
        this.onChange = fn
    }

    registerOnTouched(fn: () => any) {
        this.onTouch = fn
    }

    updateDays() {
        let daysInCurrentMonth = this.daysInMonth(this.month, this.year)
        this.days = Array(daysInCurrentMonth)
            .fill(0)
            .map((x, i) => i + 1)
        if (this.day > daysInCurrentMonth) {
            this.day = daysInCurrentMonth
        }
    }

    daysInMonth(month, year) {
        return new Date(year, parseInt(month) + 1, 0).getDate()
    }
}
