import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { BlogModule } from "@puntaje/puntaje/new-modules/blog"

import { UtilModule, ModelViewModule } from "@puntaje/shared/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { PostComponent } from "./post/post.component"
import { PostsComponent } from "./posts/posts.component"
import { PostAdminComponent } from "./post-admin/post-admin.component"
import { PostsAdminComponent } from "./posts-admin/posts-admin.component"
import { PostsEditComponent } from "./posts.edit.component"
import { PostsNewComponent } from "./posts.new.component"

@NgModule({
    imports: [CommonModule, FormsModule, UtilModule, BannersModule, ModelViewModule, BlogModule, CUILayoutsModule],
    declarations: [
        PostComponent,
        PostsComponent,
        PostAdminComponent,
        PostsAdminComponent,
        PostsEditComponent,
        PostsNewComponent
    ],
    exports: [
        PostComponent,
        PostsComponent,
        PostAdminComponent,
        PostsAdminComponent,
        PostsEditComponent,
        PostsNewComponent
    ]
})
export class PuntajeBlogModule {}
