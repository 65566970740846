<ll-titulo>{{ "biblioteca.titulo" | t }}</ll-titulo>
<ll-top-contenido>
    <menu-asignaturas-horizontal
        [asignaturaId]="asignaturaId"
        [tipoAsignaturas]="tipoInstrumento"
        [enableResponsive]="enableResponsive"
        (asignaturaChanged)="onChangeAsignatura($event)"
        [soloMaterialVisible]="true"
    ></menu-asignaturas-horizontal>
</ll-top-contenido>
<loading-layout #innerContent>
    <ng-container *ngIf="cursoId">
        <curso-select [cursos]="cursos" [(curso)]="cursoId" (cursoChange)="onChangeCurso($event)"></curso-select>
    </ng-container>
    <materiales-colapsables-por-clasificacion
        [tipoInstrumento]="tipoInstrumento"
        [cursoId]="cursoId"
        [asignaturaId]="asignaturaId"
        [enableHideEmpty]="enableHideEmpty"
        (onReady)="onReady($event)"
        *ngIf="asignaturaId"
        [enableSearch]="true"
        [openOnInit]="openOnInit"
    ></materiales-colapsables-por-clasificacion>
</loading-layout>
