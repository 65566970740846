import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Location } from "@angular/common"

@Component({
    selector: "materiales-udds",

    templateUrl: "materiales_udds.component.html",
    styleUrls: ["materiales_udds.component.scss"]
})
export class MaterialesUDDSComponent implements OnInit {
    enableResponsive: boolean = false
    width: number = 1000
    height: number = 800
    max_width: number = 1000
    max_height: number = 1400
    url: string = "https://udd.prod.ogr.cl/alternativo.html"
    safeUrl: SafeResourceUrl
    urlReady: boolean = false
    @ViewChild("iframeContainer") iframeContainer: ElementRef

    constructor(protected sanitizer: DomSanitizer, protected location: Location) {}

    ngOnInit() {
        this.enableResponsive = window.innerWidth <= 900
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
        this.urlReady = true
    }

    onResize(event) {
        this.enableResponsive = event.target.innerWidth <= 900
        this.setRatioSize()
    }

    setRatioSize() {
        if (this.iframeContainer) {
            if (this.iframeContainer.nativeElement.offsetWidth > this.max_width) {
                this.width = this.max_width
            } else {
                this.width = this.iframeContainer.nativeElement.offsetWidth
            }
        }
        //this.height = (this.width * this.max_height) / this.max_width;
    }

    onLoadIframe() {
        this.setRatioSize()
    }
    goBack() {
        this.location.back()
    }
}
