import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { BaseChart, SplineChart } from "@puntaje/shared/core"

@Component({
    selector: "grafico-desempeno-evolutivo",
    template: `
        <loading-layout #loadingLayout>
            <estadisticas-grafico
                [titulo]="tituloPrincipal"
                [grafico]="grafico"
                *ngIf="!loadingLayout.loading"
                [ariaLabel]="'ver alternativa tabular a continuación'"
            ></estadisticas-grafico>
            <figure class="highcharts-figure" [hidden]="true">
                <table class="table table-striped table-bordered sr-only" *ngIf="data">
                    <caption>{{ tituloPrincipal }}</caption>
                    <thead>
                        <tr class="table-headers">
                            <th>{{ "progreso.profe.desempeno_evolutivo.fecha" | t }}</th>
                            <th>{{ "progreso.profe.desempeno_evolutivo.resultado" | t }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let fila of data[0].data; let i = index">
                            <td>{{ fila[0] }}</td>
                            <td>{{ fila[1] }}</td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </loading-layout>
    `
})
export class GraficoDesempenoEvolutivoComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    public grafico: BaseChart
    enableGraph: boolean = false
    @Input() tipoEvaluacion: string
    @Input() enablePorcentaje: boolean = false
    @Input() titulo: string
    @Input() tituloPrincipal: string
    @Input() descripcion: string
    rangoEscala: any
    @Input() enableDemoMode: boolean = false
    @Input() escala: number[]
    filteredDataSets: any[]
    data: any
    _dataSets: any[]
    @Input()
    set dataSets(dataSets: any[]) {
        this._dataSets = dataSets
        this.updateGrafico()
    }
    get dataSets() {
        return this._dataSets
    }

    constructor() {}

    ngOnInit() {
        if (!this.tituloPrincipal) {
            this.tituloPrincipal = this.titulo
        }

        if (this.enableDemoMode) {
            this.setGraficoDemo()
        } else {
            this.setGrafico()
        }
    }

    updateGrafico() {
        if (this.enableDemoMode) {
            this.setGraficoDemo()
        } else {
            if (this.dataSets) {
                this.setGrafico()
            }
        }
    }

    setEscala() {
        if (this.enablePorcentaje) {
            this.rangoEscala = { min: 0, max: 100 }
        } else {
            switch (this.tipoEvaluacion) {
                case "simce":
                    this.rangoEscala = { min: 0, max: 240 }
                    break

                case "ensayoPSU":
                    this.rangoEscala = { min: 0, max: 850 }
                    break

                case "saber":
                    this.rangoEscala = { min: 0, max: 100 }
                    break

                case "saber11":
                    this.rangoEscala = { min: 0, max: 100 }
                    break

                case "curricular colombia":
                    this.rangoEscala = { min: 0, max: 100 }
                    break

                case "curricular":
                    this.rangoEscala = { min: 1, max: 7 }
                    break

                case "paa":
                    this.rangoEscala = { min: 0, max: 100 }
                    break

                case "curricular mexico":
                    this.rangoEscala = { min: 0, max: 100 }
                    break

                case "Enem":
                    this.rangoEscala = { min: 0, max: 100 }
                    break

                case "Saber Pro":
                    this.rangoEscala = { min: 0, max: 300 }
                    break

                case "Simulacro Saber Pro":
                    this.rangoEscala = { min: 0, max: 300 }
                    break

                default:
                    this.rangoEscala = { min: 100, max: 1000 }
                    break
            }
        }
    }

    formatDataSet() {
        this.dataSets.filter(d => d.data !== undefined)
        this.filteredDataSets = this.dataSets.filter(d => d.data !== undefined)
    }

    setGrafico() {
        this.loadingLayout.standby()
        this.setEscala()
        this.formatDataSet()
        let g1: any = {}
        g1.data = this.filteredDataSets
        g1.titulo = this.titulo || "Desempeño evolutivo"
        g1.descripcion = this.descripcion || "Descripción"
        g1.nombreSerie = "Desempeño promedio"
        g1.ejeX = { type: "category", uniqueNames: false }
        g1.ejeY = { title: "Resultados", min: this.rangoEscala.min, max: this.rangoEscala.max }
        this.data = g1.data
        //g1.tipoColor = ChartColorType.Resultados;
        this.grafico = new SplineChart(g1)

        this.grafico.chartData.accessibility = {
            screenReaderSectionFormatter: () => "La alternativa tabular está debajo."
        }

        // this.grafico.setCustomChartColors(this.colorList)
        this.loadingLayout.ready()
    }

    setGraficoDemo() {
        let dataSetsDemo = [
            {
                nombre: "Matemáticas",
                data: [
                    ["10-10-2019", 4.0],
                    ["10-10-2019", 5.0]
                ]
            },
            {
                nombre: "Lenguaje",
                data: [
                    ["10-10-2019", 5.0],
                    ["10-10-2019", 5.5]
                ]
            }
        ]

        this.loadingLayout.standby()
        let g1: any = {}
        g1.data = dataSetsDemo
        g1.titulo = "Desempeño evolutivo"
        g1.descripcion = "Descripción"
        g1.nombreSerie = "Desempeño promedio"
        g1.ejeX = { type: "category", uniqueNames: false }
        g1.ejeY = { title: "Resultados", min: 1, max: 7 }
        //g1.tipoColor = ChartColorType.Resultados;
        this.grafico = new SplineChart(g1)
        // this.grafico.setCustomChartColors(this.colorList)
        this.loadingLayout.ready()
    }
}
