import { BaseModel, SaveType } from "@puntaje/shared/core"
import { EstadoReporte } from "./estado_reportes.model"
import { Plataforma } from "./plataformas.model"
import { Reportes } from "./reportes.service"
import { ReporteRazon } from "./reporte_razones.model"
import { ReporteSubRazon } from "./reporte_sub_razones.model"

export class Reporte extends BaseModel {
    public static className: string = "Reporte"
    public id: number
    public entidad_id: number
    public tabla: string
    public plataforma_id: number
    public usuario_reporte: string
    public usuario_reporte_id: number
    public usuario_reporte_nombre: string
    public usuario_reporte_apellido_paterno: string
    public usuario_reporte_apellido_materno: string
    public usuario_reporte_rol_id: number
    public usuario_reporte_rol_rol: string
    public usuario_reporte_email: string
    public reporte: string
    public reporte_razon_id: number
    public reporte_sub_razon_id: number
    public estado_reporte_id: number
    public detalles: string
    public created_at: Date
    public updated_at: Date
    public cantidad_reportes_recurso: number

    @SaveType(() => Plataforma) public plataforma: Plataforma
    @SaveType(() => ReporteRazon) public reporte_razon: ReporteRazon
    @SaveType(() => ReporteSubRazon) public reporte_sub_razon: ReporteSubRazon
    @SaveType(() => EstadoReporte) public estado_reporte: EstadoReporte

    constructor() {
        super()
        this.reporte_razon = new ReporteRazon()
        this.reporte_sub_razon = new ReporteSubRazon()
        this.plataforma = new Plataforma()
        this.estado_reporte = new EstadoReporte()
    }

    public static get show_params(): any {
        return {
            id: { label: "Id", type: "link", tableVisible: true, options: { class: Reportes, key: "id" } },
            entidad_id: { label: "Entidad", type: "text", tableVisible: false },
            tabla: { label: "Tabla", type: "text", tableVisible: false },
            usuario_reporte_id: { label: "Us. Id", type: "text", tableVisible: false },
            usuario_reporte_nombre: { label: "Nombre", type: "text", tableVisible: true },
            usuario_reporte_apellido_paterno: { label: "Apellido paterno", type: "text", tableVisible: true },
            usuario_reporte_apellido_materno: { label: "Apellido materno", type: "text", tableVisible: false },
            usuario_reporte_email: { label: "Email", type: "text", tableVisible: false },
            usuario_reporte_rol_id: { label: "Rol ID", type: "text", tableVisible: false },
            usuario_reporte_rol_rol: { label: "Rol", type: "text", tableVisible: true },
            reporte: { label: "Reporte", type: "text", tableVisible: true },
            reporte_razon_id: { label: "Razón de Reporte", type: "text", tableVisible: false },
            created_at: { label: "Fecha de creación", type: "date", tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: "date", tableVisible: true }
        }
    }

    public toString() {
        return this.reporte
    }
}
