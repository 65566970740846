import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Universidad } from "./universidades.model"
import { Plataforma } from "./plataformas.model"

export class UniversidadRecomendada extends BaseModel {
    public static className: string = "UniversidadRecomendada"

    public universidadId: number
    public plataformaId: number

    @SaveType(() => Universidad) universidad: Universidad
    @SaveType(() => Plataforma) plataforma: Plataforma
}
