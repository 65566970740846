import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./posts.component.html"
})
export class PostsComponent implements OnInit, OnDestroy {
    perfiles: string[] = [config.app.name, "todos"]
    external = false
    subData: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.blog.posts.list")
        this.subData = this.route.data.subscribe(data => {
            this.perfiles = data.perfiles ?? this.perfiles
            this.external = data.external ?? this.external
        })
    }

    ngOnDestroy(): void {
        if (this.subData) this.subData.unsubscribe()
    }
}
