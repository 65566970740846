import { Injectable, EventEmitter, Optional } from "@angular/core"
import {
    Evaluaciones,
    Evaluacion,
    EvaluacionInstancia,
    EvaluacionInstancias,
    EvaluacionTiempo,
    EvaluacionTiempos
} from "@puntaje/puntaje/api-services"
import {
    Asignaturas,
    Asignatura,
    Instruccion,
    Instrucciones,
    Preguntas,
    Pregunta,
    Material,
    Materiales,
    Alternativa
} from "@puntaje/nebulosa/api-services"
import { Router } from "@angular/router"
import {
    AuthService,
    CheckAchievementAsync,
    ErroresService,
    CheckActividadAsync,
    I18nService
} from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Subject, BehaviorSubject, combineLatest } from "rxjs"
import { Angulartics2 } from "angulartics2"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { ActionsSubject, select, Store } from "@ngrx/store"
import {
    GetItems,
    selectEvaluacionesActual,
    selectEvaluacionesItems,
    selectEvaluacionesSeeingIndex,
    selectEvaluacionesMaterial,
    SetActual,
    State,
    SetMaterial,
    SetEvaluacionTiempoActual,
    selectEvaluacionesEvaluacionTiempoActual,
    FetchRespuestasActual,
    AddTiempoIndex,
    ResetEvaluacion,
    selectEvaluacionesTiemposActual,
    SaveRespuestasActual,
    selectEvaluacionesRespuestasActual,
    selectEvaluacionesLoadingEvaluacion,
    selectEvaluacionesLoadingItems,
    SetLoadingItems,
    SetLoadingEvaluacion,
    CleanRespuestasActual,
    CrearAlertaLogros,
    EvaluacionesActionTypes
} from "@puntaje/puntaje/store"
import { filter, first, map, tap } from "rxjs/operators"
import { ofType } from "@ngrx/effects"

@Injectable()
export class RealizarEnsayoService {
    evaluacion: Evaluacion
    asignatura: Asignatura
    preguntas: Pregunta[]
    material: Material
    tiempoInicial: Date

    preguntaIds: number[]
    contestableIds: number[] | number[][]
    alternativaIds: any[]
    tiempos: number[]
    num_sesiones: number = config.plataforma?.numSesionesPlan || 3

    alternativaIds$: BehaviorSubject<(number | { alternativa_texto: string })[]> = new BehaviorSubject([])
    tiempos$ = this.store.pipe(select(selectEvaluacionesTiemposActual))
    respuestas$ = this.store.pipe(select(selectEvaluacionesRespuestasActual))
    evaluacionTiempo$ = this.store.pipe(
        select(selectEvaluacionesEvaluacionTiempoActual),
        filter(x => !!x)
    )

    letrasAlternativas: string[]

    readyEvaluacion: EventEmitter<Evaluacion> = new EventEmitter<Evaluacion>()
    ready: EventEmitter<any> = new EventEmitter<any>()
    loadClockChange: EventEmitter<boolean> = new EventEmitter<boolean>()
    /** @deprecated */
    showPreguntasMenuChange: EventEmitter<boolean> = new EventEmitter<boolean>()
    selectPregunta: EventEmitter<any> = new EventEmitter<any>()
    evaluacionEntregada: EventEmitter<number> = new EventEmitter<number>()

    tracking = false

    evaluacion$ = this.store.pipe(
        select(selectEvaluacionesActual),
        filter(x => !!x)
    )

    material$ = this.store.pipe(
        select(selectEvaluacionesMaterial),
        filter(x => !!x)
    )

    items$ = this.store.pipe(
        select(selectEvaluacionesItems),
        filter(items => items.length != 0)
    )
    preguntas$ = this.items$.pipe(
        map(items => items.filter(item => item.tipo == "pregunta").map(item => item.item as Pregunta))
    )

    loadingEvaluacion$ = this.store.pipe(select(selectEvaluacionesLoadingEvaluacion))
    loadingItems$ = this.store.pipe(select(selectEvaluacionesLoadingItems))

    seeingIndex$ = this.store.pipe(select(selectEvaluacionesSeeingIndex))
    currentItem$ = combineLatest(
        this.seeingIndex$,
        this.store.pipe(
            select(selectEvaluacionesItems),
            filter(items => items.length != 0)
        )
    ).pipe(map(([seeingIndex, items]) => items[seeingIndex]))

    duda$ = this.currentItem$.pipe(map(item => item.duda))

    evaluacionTiempo: EvaluacionTiempo

    componentEntregarEnsayo: (click: boolean, insideSesion?: boolean) => void
    withoutMultiple: boolean

    constructor(
        public evaluacionesService: Evaluaciones,
        public asignaturasService: Asignaturas,
        public instruccionesService: Instrucciones,
        public preguntasService: Preguntas,
        public materialesService: Materiales,
        public authService: AuthService,
        public evaluacionInstanciasService: EvaluacionInstancias,
        public evaluacionTiemposService: EvaluacionTiempos,
        @Optional() public angulartics2: Angulartics2,
        public router: Router,
        protected errores: ErroresService,
        protected simpleModalService: SimpleModalService,
        protected store: Store<State>,
        protected i18nService: I18nService,
        protected actionsSubject$: ActionsSubject
    ) {
        this.preguntas$.subscribe(preguntas => {
            this.preguntas = preguntas
            this.preguntaIds = preguntas.map(pregunta => pregunta.id)
            this.contestableIds = preguntas.map(pregunta => {
                return pregunta.contestables.map(contestable => contestable.id)
            })
        })

        this.tiempos$.subscribe(tiempos => {
            this.tiempos = tiempos
        })

        this.respuestas$.subscribe(respuestas => {
            this.alternativaIds = respuestas
        })
    }

    setTracking(tracking: boolean) {
        this.tracking = tracking
    }

    // TODO: ver como refactorizar esto
    setEntregarEnsayo(componentEntregarEnsayo) {
        this.componentEntregarEnsayo = componentEntregarEnsayo
    }

    load(evaluacionId) {
        this.store.dispatch(new ResetEvaluacion())
        this.store.dispatch(new SetLoadingEvaluacion({ loadingEvaluacion: true }))

        const resetItemsDone = this.store
            .pipe(
                select(selectEvaluacionesItems),
                filter(x => x.length == 0),
                first()
            )
            .toPromise()

        this.evaluacionesService.enableIgnoreCatch()

        return resetItemsDone.then(() =>
            this.evaluacionesService
                .find(evaluacionId)
                .then((e: Evaluacion) => {
                    this.evaluacionesService.disableIgnoreCatch()
                    this.evaluacion = e
                    this.store.dispatch(new SetActual({ actual: e }))
                    this.store.dispatch(new SetLoadingEvaluacion({ loadingEvaluacion: false }))

                    return this.asignaturasService
                        .find(this.evaluacion.instrumento.asignatura_id, { simple: true })
                        .then((asignatura: Asignatura) => {
                            this.asignatura = asignatura
                            this.readyEvaluacion.emit(e)
                            this.preguntaIds = this.evaluacion.instrumento.instrumento_preguntas.map(
                                ip => ip.pregunta_id
                            )

                            this.store.dispatch(new SetLoadingItems({ loadingItems: true }))
                            this.store.dispatch(new GetItems({ evaluacion: this.evaluacion }))

                            return this.loadMaterialIfPropio()
                        })
                })
                .catch(response => {
                    this.evaluacionesService.disableIgnoreCatch()
                    if (response && (response.status == 412 || response.status == 403)) {
                        throw response
                    } else {
                        this.errores.setResponse(response).then(val => {
                            this.router.navigate(["errores"])
                        })
                    }
                })
        )
    }

    async loadMaterialIfPropio() {
        if (this.evaluacion.instrumento.propio) {
            return this.materialesService.find(this.evaluacion.instrumento.instrumento_material.material_id).then(m => {
                this.material = m
                this.store.dispatch(new SetMaterial({ material: m }))

                this.onReady()
            })
        }
    }

    getLetrasAlternativas() {
        const letras = "ABCDEFGHI"
        let numeroAlternativas = 4

        if (this.evaluacion.instrumento.numero_alternativas) {
            numeroAlternativas = this.evaluacion.instrumento.numero_alternativas
        } else if (this.evaluacion.instrumento.generador_instrumento.maximo_alternativas) {
            numeroAlternativas = this.evaluacion.instrumento.generador_instrumento.maximo_alternativas
        }

        const letrasAlternativas = new Array(numeroAlternativas).fill("").map((_, i) => letras[i])

        return letrasAlternativas
    }

    async loadEvaluacionTiempo() {
        let e = new EvaluacionTiempo()
        e.evaluacion_id = this.evaluacion.id

        const evaluacionTiempo = await this.evaluacionTiemposService.save(e)
        this.store.dispatch(new SetEvaluacionTiempoActual({ evaluacionTiempoActual: evaluacionTiempo }))

        return evaluacionTiempo
    }

    async onReady() {
        await this.loadEvaluacionTiempo()

        const itemsPromise = this.store
            .pipe(
                select(selectEvaluacionesItems),
                filter(items => items.length != 0),
                first()
            )
            .toPromise()
        await itemsPromise

        this.store.dispatch(new FetchRespuestasActual())

        this.tiempoInicial = new Date()
        this.respuestas$.subscribe(respuestas => {
            this.ready.emit(true)
        })
    }

    // TODO: mover esto a un effect
    @CheckAchievementAsync(["PN_EVALUACIONES"], CrearAlertaLogros)
    @CheckAchievementAsync("PN_ENTRENADOR_METAS_COMPLETADAS", CrearAlertaLogros)
    @CheckActividadAsync("RE")
    @CheckActividadAsync("RER")
    entregarEnsayo(click, insideSesion = false) {
        const fun = () => {
            this.showPreguntasMenuChange.emit(false)

            const evaluacionInstancia = new EvaluacionInstancia()
            evaluacionInstancia.evaluacion_id = this.evaluacion.id
            Object.assign(evaluacionInstancia, {
                alternativa_ids: this.alternativaIds.map(arr => arr.map(value => (value == null ? "null" : value))),
                contestable_ids: this.contestableIds,
                pregunta_ids: this.preguntaIds,
                tiempos: this.tiempos,
                inside_sesion: insideSesion,
                sesiones_por_ciclo: this.num_sesiones,
                ...(this.withoutMultiple ? { without_multiple: 1 } : {})
            })

            this.evaluacionInstanciasService.enableIgnoreCatch()

            return this.evaluacionInstanciasService
                .save(evaluacionInstancia)
                .then((ei: EvaluacionInstancia) => {
                    this.trackClickEntregar(this.asignatura.asignatura)
                    this.evaluacionEntregada.emit(ei.id)

                    this.store.dispatch(new CleanRespuestasActual())

                    return ei
                })
                .catch(response => {
                    return this.openModalEnsayoError(response)
                })
                .finally(() => {
                    this.evaluacionInstanciasService.disableIgnoreCatch()
                    this.withoutMultiple = false
                })
        }

        return fun()
    }

    openModalEnsayoError(response) {
        return new Promise<EvaluacionInstancia>((resolve, reject) => {
            this.simpleModalService.cleanModal()
            this.simpleModalService.setModalHeader(this.i18nService.translate("preguntas_ensayo.modal_error.titulo"))
            this.simpleModalService.setModalStringContent(
                this.i18nService.translate("preguntas_ensayo.modal_error.contenido")
            )
            this.simpleModalService.setModalCallback(() => this.downloadAnswers(response))
            this.simpleModalService.cancelModalCallback(() => this.handleError(response))

            this.simpleModalService.showSimpleModal()

            reject(null)
        })
    }

    downloadAnswers(response) {
        let csv = "Forma de la Evaluación,Número de la pregunta,Respuesta"
        this.preguntas.forEach((pregunta, i) => {
            const row = ["" + this.evaluacion.id, "" + (i + 1)]
            const respuestas = []
            pregunta.contestables.forEach((contestable, j) => {
                let alternativaContestada: any = this.alternativaIds[i]
                if (Array.isArray(alternativaContestada)) {
                    alternativaContestada = alternativaContestada[j]
                }

                if (alternativaContestada && alternativaContestada.alternativa_texto) {
                    respuestas.push(alternativaContestada.alternativa_texto)
                } else {
                    const alternativas = contestable.getAlternativas(this.evaluacion.instrumento.generador_instrumento)
                    const alternativa = alternativas.find(a => a.id == +alternativaContestada)

                    let alternativasIds = alternativas.map(a => a.id)
                    let letra = ""
                    if (alternativa) {
                        letra = Alternativa.getLetra(alternativasIds.indexOf(alternativa.id)).toUpperCase()
                    }

                    respuestas.push(letra)
                }
            })

            row.push(`"${respuestas.join(",")}"`)
            csv += `\n${row.join(",")}`
        })

        this.createLinkAndOpen(`data:text/csv;charset=utf-8,${csv}`, `respuestas_forma_${this.evaluacion.id}.csv`)

        this.handleError(response)
    }

    handleError(response) {
        if (response && response.status == 422) {
            throw response
        } else {
            this.errores.setResponse(response).then(val => {
                this.router.navigate(["errores"])
            })
        }
    }

    private createLinkAndOpen(link, downloadString, time = 0) {
        const a = document.createElement("a")
        a.download = downloadString
        a.href = link
        setTimeout(() => {
            a.click()
        }, time)
    }

    trackClickEntregar(category: string): void {
        if (this.tracking && this.angulartics2) {
            this.angulartics2.eventTrack.next({
                action: "click",
                properties: {
                    category: category,
                    label: "Entregar ensayo"
                }
            })
        }
    }

    saveAlternativa() {
        this.tiempoInicial = new Date()

        this.store.dispatch(new SaveRespuestasActual())
    }

    setTiempos(index: number) {
        const tiempoExtra = (new Date().getTime() - this.tiempoInicial.getTime()) / 1000
        this.store.dispatch(new AddTiempoIndex(tiempoExtra, index))
    }

    /** @deprecated fix para que no se caigan todas las plataformas */
    getAlternativasFromStorage() {}
}
