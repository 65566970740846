import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Persona } from "./personas.model"
import { Pregunta } from "./preguntas.model"
import { RevisionRecurso } from "./revision_recursos.model"
import { CertificacionRecurso } from "./certificacion_recursos.model"

export class GrupoPregunta extends BaseModel {
    public static className: string = "GrupoPregunta"
    public texto: string
    public imagen: string
    public estado: number
    public visible: boolean
    public texto_latex: string
    public created_at: Date
    public updated_at: Date
    public profesor_id: number

    @SaveType(() => Persona) public profesor: Persona
    @SaveType(() => Pregunta) public preguntas: Pregunta[]
    @SaveType(() => RevisionRecurso) public revision_recursos: RevisionRecurso[]
    @SaveType(() => CertificacionRecurso) public certificacion_recurso: CertificacionRecurso

    constructor(empty: boolean = false) {
        super()

        if (!empty) {
            this.profesor = new Persona()
            this.estado = 1
            this.visible = false
        }
    }

    public toString() {
        //return this.texto;
        return "Grupo pregunta Id: " + this.id
    }

    public toStringId() {
        return this.id ? this.id.toString() : ""
    }
}
