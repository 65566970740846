/*
 * ScannerJS v0.9
 * Developed by DAVOSCRIPT EIRL & OGR SA
 * This is not public software
 */
import { AR } from "./lib/aruco"
import { CV } from "./lib/CV.js"
import { tinycolor } from "./lib/tinycolor"

// Create an immediately invoked functional expression to wrap our code
// Define our constructor

export class Scannerjs {
    markers: any[]
    options: any
    bufferCanvas: any

    constructor(options?: any) {
        // Create global element references
        this.markers = []

        // Define option defaults
        const defaults = {
            image: null,
            context: null,
            canvasWidth: 0,
            canvasHeight: 0,
            gridCellWidth: 30,
            gridCellHeight: 30,
            tolerance: 20
        }

        // Create options by extending defaults with the passed in arugments
        if (arguments[0] && typeof arguments[0] === "object") {
            this.options = extendDefaults(defaults, arguments[0])

            // Create a gray canvas to save image data in gray and with an adaptive threshold, to find betters answers
            // this.grayCanvas = document.createElement("canvas");
            // this.grayCanvas.width = this.options.canvasWidth;
            // this.grayCanvas.height = this.options.canvasHeight;

            const imageData = this.options.context.getImageData(
                0,
                0,
                this.options.canvasWidth,
                this.options.canvasHeight
            )

            // Calculate an image in gray and his adaptive threshold
            const grey = new CV.Image()
            const thres = new CV.Image()
            const mean = new CV.Image()
            const blur = new CV.Image()
            CV.grayscale(imageData, grey)
            CV.gaussianBlur(grey, blur, mean, 3)
            const threshold = CV.otsu(grey)
            CV.threshold(blur, thres, threshold)

            const imageDataBuffer = this.options.context.getImageData(
                0,
                0,
                this.options.canvasWidth,
                this.options.canvasHeight
            )
            for (let i = 0; i < imageDataBuffer.data.length; i += 4) {
                imageDataBuffer.data[i] =
                    imageDataBuffer.data[i + 1] =
                    imageDataBuffer.data[i + 2] =
                        thres.data[~~(i / 4)]
            }

            if (this.options.enableThreshold) {
                this.options.context.putImageData(imageDataBuffer, 0, 0)
            }

            // Get a buffer canvas to get clean markers' data
            this.bufferCanvas = document.createElement("canvas")
            this.bufferCanvas.width = this.options.canvasWidth
            this.bufferCanvas.height = this.options.canvasHeight

            this.bufferCanvas
                .getContext("2d")
                .drawImage(this.options.context.canvas, 0, 0, this.options.canvasWidth, this.options.canvasHeight)
        }
    }

    detectMarkers(w?, h?) {
        const _ = this

        w = typeof w == "undefined" ? _.options.canvasWidth : w
        h = typeof h == "undefined" ? _.options.canvasHeight : h

        const imageData = _.options.context.getImageData(0, 0, w, h)
        const detector = new AR.Detector()
        const markers = detector.detect(imageData)
        _.options.markers = markers
        //console.log(markers)

        return markers
    }

    align(id1, id2) {
        const _ = this

        //console.log(_.options.markers)
        // Get row markers
        const one = _.options.markers.find(function (p) {
            return p.id == id1
        })
        //console.log(id1 + "cell y: " + one.corners[0].y)
        const two = _.options.markers.find(function (p) {
            return p.id == id2
        })
        //console.log(id2 + "cell y: " + two.corners[1].y)

        // Determine slope
        const slope = getSlope(one.corners[0].x, one.corners[0].y, two.corners[1].x, two.corners[1].y)
        // console.log('slope', slope);
        const angle = getRotationAngle(slope)
        //var angle = -0.085;

        _.options.context.clearRect(0, 0, _.options.canvasWidth, _.options.canvasHeight)
        // Execute the rotation fix
        _.options.context.save()

        _.options.context.translate(_.options.canvasWidth / 2, _.options.canvasHeight / 2)
        _.options.context.rotate(-angle)
        _.options.context.translate(-_.options.canvasWidth / 2, -_.options.canvasHeight / 2)
        _.options.context.drawImage(this.bufferCanvas, 0, 0, _.options.canvasWidth, _.options.canvasHeight)
        _.options.context.restore()

        // var imageData = _.options.context.getImageData(0, 0, _.options.canvasWidth, _.options.canvasHeight);

        // // Calculate an image in gray and his adaptive threshold
        // var grey = new CV.Image();
        // var thres = new CV.Image();
        // CV.grayscale(imageData, grey);
        // CV.adaptiveThreshold(grey, thres, 9, 1);

        // //console.log(grey);

        // var imageDataBuffer = _.options.context.getImageData(0, 0, _.options.canvasWidth, _.options.canvasHeight);
        // for(var i = 0; i < imageDataBuffer.data.length; i += 4) {
        //     imageDataBuffer.data[i] = imageDataBuffer.data[i + 1] = imageDataBuffer.data[i + 2] = thres.data[~~(i/4)];
        // }
        // console.log(imageDataBuffer);

        // Save the gray and buffer image in canvases
        // _.grayCanvas.getContext("2d").putImageData(imageDataBuffer, 0, 0);
        _.bufferCanvas.getContext("2d").drawImage(_.options.context.canvas, 0, 0)
    }

    howDark(x, y, w, h, offsetx, offsety) {
        const _ = this

        w = typeof w == "undefined" ? _.options.canvasWidth : w
        h = typeof h == "undefined" ? _.options.canvasHeight : h

        offsetx = typeof offsetx == "undefined" ? 0 : offsetx
        offsety = typeof offsety == "undefined" ? 0 : offsety

        const x1 = x * w + offsetx
        const y1 = y * h + offsety
        const imageData = _.options.context.getImageData(x1, y1, w, h)
        // var imageData = _.grayCanvas.getContext("2d").getImageData(x1, y1, w, h);
        //console.log(imageData);
        //convertBW(ctx, x1, y1, w, h);
        const px = imageData.data.length / 4
        let darkCont = 0
        const darks = averageBrightness(imageData)
        const threshold = darks[1] * 0.7

        // Calculate a percentage of white (255) pixels, based on a ponderation depending on it being closer to the center
        const sumAll = 0
        const sum = 0
        let d, p, w1, h1
        const c = 0.0001
        let val

        const a = imageData.width / 2,
            b = imageData.height / 2

        //_.bufferCanvas.getContext("2d").putImageData(imageData, x1, y1);
        //console.log(a + " " + b);

        // Calculate the sum of ponderations
        for (let i = 0; i < imageData.data.length; i += 4) {
            // p = imageData.data[i] > 0 ? 1 : 0;
            // w1 = (~~(i / 4)) % imageData.width;
            // h1 = ~~((~~(i / 4)) / imageData.width);
            // d = Math.sqrt(Math.pow(a - w1, 2) + Math.pow(b - h1, 2));
            // //console.log(w1 + " " + h1 + " " + d + " " + p);

            // val = 1 / (d + c);
            // sumAll += val;
            // sum += val*p;

            //console.log(imageData.data[i], imageData.data[i+1], imageData.data[i+2], imageData.data[i+3]);
            const bness = tinycolor({
                r: imageData.data[i],
                g: imageData.data[i + 1],
                b: imageData.data[i + 2]
            }).getBrightness()
            //console.log(i, bness);
            darkCont = bness < threshold ? darkCont + 1 : darkCont
        }

        //console.log(darkCont);
        // Calculate the % of dark pixels in the scanned square
        // var percent = ((100/sumAll) * sum).toFixed(2);
        const percent = ((100 / px) * darkCont).toFixed(2)
        const ctx = _.options.context

        _.paintCell(ctx, parseInt(percent), _.options.tolerance, x1, y1, w, h)

        //console.log(dark + '/' + px + ' -> ' + ((100/px)*dark).toFixed(2) + '%');

        return percent
    }

    // Function to paint a cell in the canvas, depending on the buffer canvas
    paintCell(ctx, percent, tolerance, x, y, w, h) {
        tolerance = typeof tolerance == "undefined" ? 0 : tolerance
        ctx.fillStyle = +percent > tolerance ? "rgba(124,252,0,.5)" : "rgba(0,191,255,.5)"
        //ctx.globalAlpha = (typeof opacity == 'undefined') ? .75 : .5;
        if (tolerance == 0) {
            ctx.drawImage(this.bufferCanvas, x, y, w, h, x, y, w, h)
        }
        ctx.fillRect(x, y, w, h)

        ctx.font = "9px Arial"
        ctx.fillStyle = "black"
        ctx.fillText(+percent, x, y + h / 2)

        ctx.globalAlpha = 1.0
    }

    // Given a point x,y and the array of answers determined previously, determine if the point is in a cell, and if it is
    // change the value of the cell marked, in the image and in the answers
    changeMarked(x, y, resArray, dimSectores, groups) {
        const _ = this

        const markers = _.options.markers

        // Hard-coded groups to search
        if (!groups) {
            groups = [
                [1, 2],
                [2, 3],
                [3, 4],
                [5, 2],
                [2, 7],
                [7, 4],
                [5, 6],
                [6, 7],
                [7, 8],
                [9, 6],
                [6, 10]
            ]
        }

        let result
        let upCorner, downCorner, index
        let blockWidth, blockHeight, matrixX, matrixY

        // Find a pair of corners that are in a group zone
        groups.some(function (g, i) {
            index = i
            upCorner = markers.find(function (m) {
                return m.id == g[0]
            })
            downCorner = markers.find(function (m) {
                return m.id == g[1]
            })

            // Up is left & Down is right
            if (downCorner.corners[0].x > upCorner.corners[0].x) {
                blockWidth = downCorner.corners[0].x - upCorner.corners[2].x
                blockHeight = downCorner.corners[0].y - upCorner.corners[2].y
                matrixX = upCorner.corners[2].x
                matrixY = upCorner.corners[2].y
                // Up is right & Down is left
            } else {
                // console.log("Up is right & Down is left")
                blockWidth = upCorner.corners[3].x - downCorner.corners[1].x
                blockHeight = downCorner.corners[1].y - upCorner.corners[3].y
                matrixX = downCorner.corners[1].x
                matrixY = upCorner.corners[3].y
            }

            result = matrixX <= x && x <= matrixX + blockWidth && matrixY <= y && y <= matrixY + blockHeight

            return result
        })

        if (!result) return

        // console.log(markers);
        // console.log(upCorner);
        // console.log(downCorner);

        let cols = 5
        let rows = 10
        let inverted = false
        if (Array.isArray(dimSectores)) {
            cols = dimSectores[index].cols
            rows = dimSectores[index].rows
            inverted = dimSectores[index].inverted
        } else if (dimSectores[upCorner.id + "," + downCorner.id]) {
            const key = upCorner.id + "," + downCorner.id
            cols = dimSectores[key].cols
            rows = dimSectores[key].rows
            inverted = dimSectores[key].inverted !== undefined ? dimSectores[key].inverted : cols >= 10 //para que funcione como antes
        }

        /*if(upCorner.id == 9 || downCorner.id == 10) {
            cols = 10;
        }*/

        const gridCellWidth = blockWidth / cols
        const gridCellHeight = blockHeight / rows

        const xn = ~~((x - matrixX) / gridCellWidth)
        const yn = ~~((y - matrixY) / gridCellHeight)

        // console.log(xn);
        // console.log(yn);

        const pxn = gridCellWidth * xn + matrixX
        const pyn = gridCellHeight * yn + matrixY

        let i, j
        if (inverted) {
            i = xn
            j = yn
        } else {
            i = yn
            j = xn
        }

        const res = resArray[index]
        // console.log(res);
        // console.log(i);
        // console.log(resArray);
        const opt = res.find(function (r) {
            return r.row == i
        })
        // Add or remove the cell that was clicked from the answers, and then paint it.
        let percent
        if (!opt.ans) return
        const ansIndex = opt.ans.indexOf(j)
        if (ansIndex != -1) {
            opt.ans.splice(ansIndex, 1)
            percent = -30
        } else {
            opt.ans.push(j)
            percent = 30
        }

        this.paintCell(_.options.context, percent, 0, pxn, pyn, gridCellWidth, gridCellHeight)
    }

    // Given a point x,y and the array of answers determined previously, determine if the point is in a cell, and if it is
    // change the value of the cell marked, in the image and in the answers
    changeMarkedByIndices(i, j, resArray, dimSectores, groups, index = undefined) {
        const _ = this

        const markers = _.options.markers

        // Hard-coded groups to search
        if (!groups) {
            groups = [
                [1, 2],
                [2, 3],
                [3, 4],
                [5, 2],
                [2, 7],
                [7, 4],
                [5, 6],
                [6, 7],
                [7, 8],
                [9, 6],
                [6, 10]
            ]
        }

        let result
        var upCorner, downCorner, index
        let blockWidth, blockHeight, matrixX, matrixY

        upCorner = markers.find(function (m) {
            return m.id == groups[index][0]
        })
        downCorner = markers.find(function (m) {
            return m.id == groups[index][1]
        })

        if (downCorner.corners[0].x > upCorner.corners[0].x) {
            blockWidth = downCorner.corners[0].x - upCorner.corners[2].x
            blockHeight = downCorner.corners[0].y - upCorner.corners[2].y
            matrixX = upCorner.corners[2].x
            matrixY = upCorner.corners[2].y
            // Up is right & Down is left
        } else {
            blockWidth = upCorner.corners[3].x - downCorner.corners[1].x
            blockHeight = downCorner.corners[1].y - upCorner.corners[3].y
            matrixX = downCorner.corners[1].x
            matrixY = upCorner.corners[3].y
        }

        // if (!result) return

        let cols = 5
        let rows = 10
        let inverted = false
        if (Array.isArray(dimSectores)) {
            cols = dimSectores[index].cols
            rows = dimSectores[index].rows
            if (typeof dimSectores[index].inverted === "boolean") {
                inverted = dimSectores[index].inverted
            }
        } else if (dimSectores[upCorner.id + "," + downCorner.id]) {
            const key = upCorner.id + "," + downCorner.id
            cols = dimSectores[key].cols
            rows = dimSectores[key].rows
            inverted = cols >= 10 //para que funcione como antes
        }

        /*if(upCorner.id == 9 || downCorner.id == 10) {
            cols = 10;
        }*/

        const gridCellWidth = blockWidth / cols
        const gridCellHeight = blockHeight / rows

        // var xn = ~~((x - matrixX) / gridCellWidth)
        // var yn = ~~((y - matrixY) / gridCellHeight)

        let pxn = gridCellWidth * j + matrixX
        let pyn = gridCellHeight * i + matrixY

        if (inverted) {
            const aux = i
            i = j
            j = aux

            pxn = gridCellWidth * j + matrixX
            pyn = gridCellHeight * i + matrixY
        }

        const res = resArray[index]

        const opt = res.find(function (r) {
            return r.row == i
        })

        // Add or remove the cell that was clicked from the answers, and then paint it.
        let percent
        const ansIndex = opt.ans.indexOf(j)
        if (ansIndex != -1) {
            opt.ans.splice(ansIndex, 1)
            percent = -30
        } else {
            opt.ans.push(j)
            percent = 30
        }

        this.paintCell(_.options.context, percent, 0, pxn, pyn, gridCellWidth, gridCellHeight)
    }

    // Analyze a grid determined by xi, xf & yi, yf (offsets apply)
    resolve(xi, xf, yi, yf, offsetx, offsety, inverse?) {
        const _ = this

        const answers = []

        offsetx = typeof offsetx == "undefined" ? 0 : offsetx
        offsety = typeof offsety == "undefined" ? 0 : offsety

        //var letters = ['a', 'b', 'c', 'd', 'e'];
        let firstIteri, firstIterf, secondIteri, secondIterf
        if (!inverse) {
            firstIteri = yi
            firstIterf = yf
            secondIteri = xi
            secondIterf = xf
        } else {
            firstIteri = xi
            firstIterf = xf
            secondIteri = yi
            secondIterf = yf
        }

        let h, v
        for (let i = firstIteri; i <= firstIterf; ++i) {
            const pregIndex = parseInt((i - firstIteri) as any) // h+1 ???
            const answer = { row: pregIndex, ans: [] }

            for (let j = secondIteri; j <= secondIterf; ++j) {
                const altIndex = parseInt((j - secondIteri) as any) // v ??
                if (!inverse) {
                    h = i
                    v = j
                } else {
                    h = j
                    v = i
                }
                const scanOpacity = h % 2 == 0 ? 0.75 : 0.5
                const per = _.howDark(
                    v,
                    h,
                    _.options.gridCellWidth,
                    _.options.gridCellHeight,
                    offsetx,
                    offsety
                    // scanOpacity
                )

                if (parseInt(per) > _.options.tolerance) {
                    //console.log( pregIndex + ') ' + letters[altIndex] + ' -> ' + per);
                    answer.ans.push(altIndex)
                }
            }

            answers.push(answer)
        }

        return answers
    }

    resolveIds(id1, id2, cols, rows, inverse?) {
        const _ = this

        const markers = _.options.markers

        const upCorner = markers.find(function (p) {
            return p.id == id1
        })
        const downCorner = markers.find(function (p) {
            return p.id == id2
        })

        let blockWidth, blockHeight, matrixX, matrixY

        // console.log(id1);
        // console.log(id2);
        // console.log(markers);
        // Up is left & Down is right
        if (downCorner.corners[0].x > upCorner.corners[0].x) {
            // console.log('Up is left & Down is right');
            blockWidth = downCorner.corners[0].x - upCorner.corners[2].x
            blockHeight = downCorner.corners[0].y - upCorner.corners[2].y
            matrixX = upCorner.corners[2].x
            matrixY = upCorner.corners[2].y
            // Up is right & Down is left
        } else {
            // console.log('Up is right & Down is left');
            blockWidth = upCorner.corners[3].x - downCorner.corners[1].x
            blockHeight = downCorner.corners[1].y - upCorner.corners[3].y
            matrixX = downCorner.corners[1].x
            matrixY = upCorner.corners[3].y
        }

        _.options.gridCellWidth = blockWidth / cols
        _.options.gridCellHeight = blockHeight / rows

        //console.log('gw', _.options.gridCellWidth);
        //console.log('gh', _.options.gridCellHeight);
        //console.log('resolve(ctx, 0, 4, 0, 9, ' + matrixX + ', ' + matrixY + ');)');
        return _.resolve(0, cols - 1, 0, rows - 1, matrixX, matrixY, inverse)
    }
}

// Private Methods

// Utility method to extend defaults with user options
function extendDefaults(source, properties) {
    let property
    for (property in properties) {
        if (properties.hasOwnProperty(property)) {
            source[property] = properties[property]
        }
    }
    return source
}

function getSlope(x1, y1, x2, y2) {
    const slope = (y2 - y1) / (x2 - x1)
    //slope = (y2 > y1) ? -slope : slope;
    return slope
}

function getRotationAngle(slope) {
    return Math.atan(slope)
}

function getThreshold(context, width, height) {
    let blockSize = 1, // only visit every 5 pixels
        defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
        data,
        i = -4,
        length,
        rgb = { r: 0, g: 0, b: 0 },
        count = 0

    if (!context) {
        return defaultRGB
    }

    try {
        data = context.getImageData(0, 0, width, height)
    } catch (e) {
        /* security error, img on diff domain */ alert("x")
        return defaultRGB
    }

    length = data.data.length

    let avg,
        colorSum = 0,
        bness,
        bnessArray = []
    while ((i += blockSize * 4) < length) {
        ++count
        rgb.r = data.data[i]
        rgb.g = data.data[i + 1]
        rgb.b = data.data[i + 2]
        //avg = Math.floor( ( rgb.r + rgb.g + rgb.b ) / 3 );

        bness = tinycolor({ r: rgb.r, g: rgb.g, b: rgb.b }).getBrightness()
        //bness = Math.floor(bness);

        //colorSum += avg;
        if (bnessArray.indexOf(bness) == -1) {
            colorSum += bness
            bnessArray.push(bness)
        }
    }

    console.log(bnessArray)

    const brightness = Math.floor(colorSum / bnessArray.length)
    return brightness
}

// Get the min and max brightness in a given square
function averageBrightness(pxdata) {
    let i = -4
    const blockSize = 1
    let count = 0
    const rgb = { r: 0, g: 0, b: 0 }
    let bness
    const bnessArray = []
    let totalBrightness = 0

    // var gray = equalizeHistogram(pxdata);

    // while ( (i += 1) < gray.length ) {
    while ((i += blockSize * 4) < pxdata.data.length) {
        ++count
        rgb.r = pxdata.data[i]
        rgb.g = pxdata.data[i + 1]
        rgb.b = pxdata.data[i + 2]

        // bness = gray[i];
        bness = tinycolor({ r: rgb.r, g: rgb.g, b: rgb.b }).getBrightness()

        // Storing the brightness values so we can then get the average
        totalBrightness += bness

        if (bnessArray.indexOf(bness) == -1) {
            bnessArray.push(bness)
        }
    }

    // Return max & min brigthness values,
    // then use this values to calculate the "range average"
    const max = Math.max.apply(null, bnessArray)
    const min = Math.min.apply(null, bnessArray)

    // Or return the average brighness value
    //var max = totalBrightness/bnessArray.length;
    //var min = totalBrightness/bnessArray.length;

    //console.log(bnessArray);

    return [min, max]
}
