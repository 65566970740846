<div class="duda-respuesta-box clearfix" [class.aceptada]="isAcceptedAnswer" *ngIf="dudaRespuesta && !editando">
    <div class="user-col">
        <duda-avatar
            *ngIf="dudaRespuesta"
            [usuario]="dudaRespuesta.usuario"
            [usuarioValores]="dudaRespuesta.usuario_valores"
        ></duda-avatar>
    </div>
    <div class="content-col">
        <div class="pull-right">
            <single-value-sentiment [object]="dudaRespuesta"></single-value-sentiment>
        </div>
        <p class="thread-info">
            <b>{{ "dudas.duda_respuesta.por" | t }}:</b>
            &nbsp;
            <a class="thread-author" routerLink="/usuarios/{{ dudaRespuesta.usuario_id }}">
                {{ dudaRespuesta.usuario.nombreCompleto() }}
            </a>
            &nbsp;
            <span class="thread-time">{{ dudaRespuesta.created_at | fechaAtras }}</span>
            &nbsp;
            <span *ngIf="isEdited && !dudaRespuesta?.ban" class="thread-time">
                (editado {{ dudaRespuesta?.updated_at | fechaAtras }})
            </span>
        </p>
        <div
            *ngIf="!dudaRespuesta.ban"
            [innerHTML]="dudaRespuesta.duda_respuesta | katex | trustedhtml"
            class="contenido-respuesta"
        ></div>
        <div *ngIf="dudaRespuesta.ban">
            <span class="ban">
                <fa name="gavel"></fa>
                {{ "dudas.duda_respuesta.eliminada" | t }}
            </span>
        </div>
    </div>
    <div class="bottom-options-push"></div>
    <div class="clearfix bottom-options">
        <a class="aceptar-respuesta" *ngIf="enableAcceptAnswer && !isAcceptedAnswer" (click)="aceptarRespuesta()">
            {{ "dudas.duda_respuesta.aceptar" | t }}
        </a>
        <p *ngIf="isAcceptedAnswer" class="aceptada-flag">
            <ogr-icon name="ticket-circle" class="icono"></ogr-icon>
            <span>{{ "dudas.duda_respuesta.aceptada" | t }}</span>
        </p>
        <ul class="bottom-options-list">
            <li *ngIf="isAdmin && !dudaRespuesta.ban">
                <cui-button *ngIf="isAdmin && !dudaRespuesta.ban" (click)="banHammer()" class="btn_danger">
                    <span>{{ "dudas.duda_respuesta.deshabilitar" | t }}&nbsp;</span>
                    <fa name="gavel"></fa>
                </cui-button>
            </li>
            <li class="separator" *ngIf="isAdmin && !dudaRespuesta.ban"></li>
            <li *ngIf="canEdit">
                <a class="edit-link" (click)="toggleEditar()">
                    {{ "dudas.duda_respuesta.editar" | t }}
                    <fa name="pencil"></fa>
                </a>
            </li>
            <li class="separator" *ngIf="canEdit"></li>
            <li *ngIf="comentariosLoaded">
                <a class="edit-link" (click)="toggleComentar()">
                    {{ "dudas.duda_respuesta.comentar" | t }}
                    <fa name="reply"></fa>
                </a>
            </li>
        </ul>
        <!--<a class="aceptar-respuesta" *ngIf="enableAcceptAnswer && !isAcceptedAnswer" (click)="aceptarRespuesta()">Aceptar como respuesta</a>
        <p *ngIf="isAcceptedAnswer" class="aceptada-flag"><ogr-icon name="ticket-circle" class="icono"></ogr-icon>Respuesta aceptada</p>
        <a *ngIf="comentariosLoaded" class="edit-link pull-right" (click)="toggleComentar()">Comentar<fa name="reply"></fa></a>
        <span *ngIf="canEdit" class="separator"></span>
        <a *ngIf="canEdit" class="edit-link pull-right" (click)="toggleEditar()">Editar<fa name="pencil"></fa></a>
        <span *ngIf="isAdmin && (!dudaRespuesta.ban)" class="separator"></span>
        <button *ngIf="isAdmin && (!dudaRespuesta.ban)" (click)="banHammer()" type="button" class="btn btn_danger btn-default pull-right">Deshabilitar&nbsp;<fa name="gavel"></fa></button>-->
    </div>
</div>
<div *ngIf="editando">
    <duda-respuesta-edit
        [dudaRespuestaId]="dudaRespuesta.id"
        (cancelReply)="toggleEditar()"
        (save)="saveListener($event)"
    ></duda-respuesta-edit>
</div>
<div class="comentarios">
    <comentarios-list
        *ngIf="comentariosLoaded"
        [dudaRespuestaId]="dudaRespuesta.id"
        [comentarios]="comentarios"
        (comentariosChange)="getComentarios()"
        #comentariosList
    ></comentarios-list>
</div>
