import { AppConfig } from "../conf/app_config"
import { AppEnv } from "../conf/app_env"

declare let $: any
declare const environment: AppEnv
declare const config: AppConfig

export function enumerable(value: boolean) {
    return function (target: any, propertyKey: string, descriptor?: any) {
        if (descriptor) {
            descriptor.enumerable = value
        } else {
            let newKey = "_" + propertyKey

            let descriptor = {
                enumerable: value,
                writable: true,
                configurable: true
            }

            Object.defineProperty(target, propertyKey, {
                get: function () {
                    Object.defineProperty(this, propertyKey, descriptor)

                    return this[propertyKey]
                },
                set: function (val) {
                    Object.defineProperty(this, propertyKey, descriptor)

                    if (this[propertyKey] != val) {
                        this[propertyKey] = val
                    }
                },
                enumerable: false,
                configurable: true
            })
        }
    }
}

export function SaveType(arrayType?: () => any) {
    return function (target: any, propertyKey: string, descriptor?: PropertyDescriptor) {
        if (arrayType) {
            ;(Reflect as any).metadata("design:type", arrayType)(target, propertyKey, descriptor)
        }
    }
}

export function HasForm() {
    return function (target: any, propertyKey: string, descriptor?: PropertyDescriptor) {
        if (!descriptor) descriptor = { configurable: true }

        const privateKey = "_" + propertyKey

        descriptor.get = function () {
            return this[privateKey]
        }

        descriptor.set = function (model) {
            if (this[privateKey] != model) {
                this[privateKey] = model

                if (this.form) model.form = this.form
            }
        }

        Object.defineProperty(target, propertyKey, descriptor)
    }
}

export function CheckAchievement(codigo: string | string[], action = null, params: any = null) {
    return function (target: any, propertyKey: string, descriptor?: PropertyDescriptor) {
        if (descriptor) {
            const oldFun = descriptor.value

            descriptor.value = function (...args) {
                const res = oldFun.apply(this, args)

                const usuario = JSON.parse(localStorage.getItem("usuario"))

                const conf = this.config || config
                const env = this.environment || environment

                if (usuario && conf.plataforma.enableAchievements) {
                    fetch(env.endpoints.achievements + "/logros/check", {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            grupo_logro: { codigo: codigo },
                            object: res,
                            params: params,
                            plataforma: conf.plataforma.name,
                            usuario_id: usuario.id
                        })
                    })
                        .then(respues => respues.json())
                        .then((logros: any[]) => {
                            this.store.dispatch(new action({ logros }))
                        })
                        .catch(() => {})
                }
                return res
            }
        }

        return descriptor
    }
}

export function CheckAchievementAsync(codigo: string | string[], action = null, params: any = null) {
    return function (target: any, propertyKey: string, descriptor?: PropertyDescriptor) {
        if (descriptor) {
            const oldFun = descriptor.value
            descriptor.value = function (...args) {
                const promise = oldFun.apply(this, args)
                if (promise) {
                    promise.then(res => {
                        const usuario = JSON.parse(localStorage.getItem("usuario"))

                        const conf = this.config || config
                        const env = this.environment || environment

                        if (usuario && conf.plataforma.enableAchievements) {
                            fetch(env.endpoints.achievements + "/logros/check", {
                                method: "PATCH",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    grupo_logro: { codigo: codigo },
                                    object: res,
                                    params: params,
                                    plataforma: conf.plataforma.name,
                                    usuario_id: usuario.id
                                })
                            })
                                .then(respues => respues.json())
                                .then((logros: any[]) => {
                                    this.store.dispatch(new action({ logros }))
                                })
                                .catch(() => {})
                        }
                    })
                }
                return promise
            }
        }

        return descriptor
    }
}

export function CheckActividad(codigo: string | string[], params: any = null) {
    return function (target: any, propertyKey: string, descriptor?: PropertyDescriptor) {
        if (descriptor) {
            const oldFun = descriptor.value

            descriptor.value = function (...args) {
                const res = oldFun.apply(this, args)

                const usuario = JSON.parse(localStorage.getItem("usuario"))

                if (usuario) {
                    const conf = this.config || config
                    const env = this.environment || environment

                    fetch(env.endpoints.achievements + "/grupo_actividades/check", {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            grupo_actividad: { codigo: codigo },
                            object: res,
                            params: params,
                            plataforma: conf.plataforma.name,
                            usuario_id: usuario.id
                        })
                    })
                }

                return res
            }
        }

        return descriptor
    }
}

export function CheckActividadAsync(codigo: string | string[], params: any = null) {
    return function (target: any, propertyKey: string, descriptor?: PropertyDescriptor) {
        if (descriptor) {
            const oldFun = descriptor.value
            descriptor.value = function (...args) {
                const promise = oldFun.apply(this, args)

                if (promise) {
                    promise.then(res => {
                        const usuario = JSON.parse(localStorage.getItem("usuario"))

                        const conf = this.config || config
                        const env = this.environment || environment

                        if (usuario) {
                            fetch(env.endpoints.achievements + "/grupo_actividades/check", {
                                method: "PATCH",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    grupo_actividad: { codigo: codigo },
                                    object: res,
                                    params: params,
                                    plataforma: conf.plataforma.name,
                                    usuario_id: usuario.id
                                })
                            })
                        }
                    })
                }

                return promise
            }
        }

        return descriptor
    }
}

/**
 * Method decorator for marking obsolete functions
 * @param newMethod Name of the new function recomended to use
 * @returns         Decorator
 */
export function ObsoleteMethod(newMethod: string) {
    return function (this: any, target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const env = this.environment || environment

        if (!env.production) {
            const cssWarning: string = "font-weight: bold; background-color: yellow;"
            const cssItalic: string = "font-style: italic; background-color: yellow;"
            console.log(
                "%cWARNING: " + "%c%s is obsolete. Please use %s instead.",
                cssWarning,
                cssItalic,
                propertyKey,
                newMethod
            )
        }
    }
}
