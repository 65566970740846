<div class="circlebar-nivel-container">
    <circle-progress
        [percent]="experienciaNivelPer"
        [radius]="radius"
        [outerStrokeWidth]="stroke"
        [innerStrokeWidth]="stroke"
        [space]="-stroke"
        [outerStrokeColor]="colorBarra"
        [innerStrokeColor]="colorFondo"
        [animation]="true"
        [animationDuration]="300"
        [showTitle]="false"
        [showSubtitle]="false"
        [showUnits]="false"
        [backgroundPadding]="0"
    ></circle-progress>
</div>
