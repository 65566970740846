import { SaveType, BaseModel } from "@puntaje/shared/core"
import { PlanEstudioSesionAplicadaInstancia } from "./plan_estudio_sesion_aplicada_instancias.model"
import { PlanEstudioAplicadoInstancia } from "./plan_estudio_aplicado_instancias.model"
import { Usuario } from "./usuarios.model"

export class PlanEstudioContenidoInstancia extends BaseModel {
    public plan_estudio_sesion_aplicada_instancia_id: number
    public plan_estudio_contenido_id: number
    public tipo: string
    public usuario_id: number
    public estado: number
    public avance: number
    public estrellas: number

    @SaveType(() => PlanEstudioAplicadoInstancia)
    public plan_estudio_aplicado_instancia: PlanEstudioAplicadoInstancia
    @SaveType(() => PlanEstudioSesionAplicadaInstancia)
    public plan_estudio_sesion_aplicada_instancia: PlanEstudioSesionAplicadaInstancia
    @SaveType(() => Usuario) public usuario: Usuario

    constructor() {
        super()
        this.plan_estudio_sesion_aplicada_instancia = new PlanEstudioSesionAplicadaInstancia()
        this.usuario = new Usuario()
    }

    public toString() {
        return this.tipo + "," + this.plan_estudio_contenido_id + "," + this.usuario_id
    }
}
