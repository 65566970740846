import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnInit, EventEmitter } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { GenericModalComponent } from "@puntaje/shared/core"
import { BannerInstancia, BannerInstancias } from "@puntaje/puntaje/api-services"

@Component({
    selector: "arte-zona-modal",
    templateUrl: "banner_modal.component.html",
    styleUrls: ["banner_modal.component.scss"]
})
export class BannerModalComponent implements OnInit {
    @Input() pagina: string
    @Input() titulo: string = ""
    zona: string = "modal"
    storageKey: string = "arteModal"
    hasModalIcon: boolean = true
    openModal: EventEmitter<any> = new EventEmitter<any>()
    config: typeof config = config
    showModal: boolean = false

    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent

    constructor(private bannerInstanciasService: BannerInstancias) {}

    ngOnInit() {
        if (!localStorage.getItem(this.storageKey)) {
            this.bannerInstanciasService
                .where({ zona: this.zona, pagina: this.pagina })
                .then((bannerInstancias: BannerInstancia[]) => {
                    if (bannerInstancias && bannerInstancias.length > 0) {
                        this.showModal = true
                    }
                })
        }
    }

    onLoad() {
        this.openModal.emit()
        localStorage.setItem(this.storageKey, "true")
    }
}
