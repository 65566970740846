<loading-layout>
    <ng-container *ngFor="let pregunta of preguntas; let i = index">
        <div
            class="preguntas-container"
            [class.show-preguntas-container]="displayPreguntas"
            [class.pregunta-hide]="i != indexActual"
        >
            <pregunta-ensayo
                #preguntaEnsayo
                [pregunta]="pregunta"
                [index]="i"
                [enableDudaBtn]="enableDudasBtn"
                [enableReporte]="enableReporte"
                [enablePreguntaId]="enablePreguntasId"
                [disableOmitida]="true"
                [generadorInstrumento]="evaluacion.instrumento.generador_instrumento"
            ></pregunta-ensayo>
        </div>
    </ng-container>
    <div class="clearfix" *ngIf="!loading">
        <div class="pista-container clearfix" *ngIf="showPista && alternativaActual">
            <fa name="lightbulb-o" class="icono"></fa>
            <h4>Pista:</h4>
            <div class="pista-content" [innerHTML]="alternativaActual.pista"></div>
            <p *ngIf="alternativaActual && !alternativaActual.pista">Pista no disponible para esta pregunta.</p>
        </div>
        <cui-button class="btn-pista" [disabled]="!alternativaActual" *ngIf="!showPista" (click)="enableShowPista()">
            <fa name="lightbulb-o"></fa>
            Ver pista
        </cui-button>

        <div class="submit-btns clearfix">
            <cui-button class="btn-confirmar" [disabled]="!alternativaIds[indexActual]" (click)="check()">
                <fa name="pencil"></fa>
                Enviar respuesta
            </cui-button>
            <cui-button class="btn-skip" [disabled]="!enableBtnSaltar" (click)="skip()">
                {{ indexActual + 1 == preguntas.length ? "Entregar evaluación" : "Siguiente pregunta" }}
                <fa *ngIf="indexActual + 1 < preguntas.length" name="arrow-right"></fa>
            </cui-button>
        </div>
    </div>
</loading-layout>
