import { Component, Input, ViewChild, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from "@angular/core"
import {
    Pregunta,
    Preguntas,
    GrupoPregunta,
    Clasificacion,
    Personas,
    Persona,
    Alternativa
} from "@puntaje/nebulosa/api-services"
import { InstrumentoPregunta, InstrumentoPreguntas, Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "instrumento-pregunta",
    templateUrl: "instrumento_pregunta.component.html",
    styleUrls: ["instrumento_pregunta.component.scss"]
})
export class InstrumentoPreguntaComponent {
    @Input() pregunta: Pregunta
    @Input() index: number
    @Input() grupoPreguntaPrev: GrupoPregunta
    @Input() instrumentoPregunta: InstrumentoPregunta
    @Input() showCambiarPregunta: boolean
    @Input() dificultad: string
    @Input() estadisticaGlobal: any
    @Input() enableReportBtn: boolean = true
    @Input() evaluacionTipo: string = null
    @Input() showAsignaturaForGroup: boolean = false
    @Input() certificada: boolean = false
    @Input() disableIfSelectedEmpty: boolean = false
    @Input() almostAllPiloto: boolean
    @Input() enableOpcionesPregunta: boolean = true

    @Output() togglePiloto = new EventEmitter<any>()
    @Input() instrumento: Instrumento

    @Output() nuevaPregunta = new EventEmitter<{ instrumento_pregunta: InstrumentoPregunta; index: number }>()

    showSolution: boolean = false
    nombre: string
    apaterno: string
    amaterno: string
    autor: string
    profesor: string
    preguntas: Pregunta[]
    historialPreguntas: Pregunta[] = []

    @Output() changePregunta = new EventEmitter<InstrumentoPregunta>()
    @Output() deletedPregunta = new EventEmitter<number>()
    clasificacionIds: number[] = []
    posicion = "arriba"
    preguntaOTexto = "pregunta"
    preguntaFromGrupo = false

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    togglingPilotaje: boolean = false

    agregarPreguntaModalOpen: boolean = false

    constructor(
        protected instrumentoPreguntasService: InstrumentoPreguntas,
        protected simpleModalService: SimpleModalService,
        protected personasService: Personas,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (this.pregunta["autor_id"] !== null) {
            if (this.pregunta["nombre_autor"] === null || this.pregunta["nombre_autor"] === undefined) {
                this.personasService.find(this.pregunta["autor_id"]).then((response: Persona) => {
                    if (response.nombre != null) {
                        this.nombre = response.nombre
                    } else {
                        this.nombre = ""
                    }
                    if (response.apellido_paterno != null) {
                        this.apaterno = response.apellido_paterno
                    } else {
                        this.apaterno = ""
                    }
                    if (response.apellido_materno != null) {
                        this.amaterno = response.apellido_materno
                    } else {
                        this.amaterno = ""
                    }
                    this.autor = this.nombre + " " + this.apaterno + " " + this.amaterno
                })
            } else {
                this.autor = this.pregunta["nombre_autor"]
            }
        }

        if (this.pregunta["profesor_id"] !== null) {
            if (this.pregunta["nombre_profesor"] === null || this.pregunta["nombre_profesor"] === undefined) {
                this.personasService.find(this.pregunta["profesor_id"]).then((response: Persona) => {
                    if (response.nombre != null) {
                        this.nombre = response.nombre
                    } else {
                        this.nombre = ""
                    }
                    if (response.apellido_paterno != null) {
                        this.apaterno = response.apellido_paterno
                    } else {
                        this.apaterno = ""
                    }
                    if (response.apellido_materno != null) {
                        this.amaterno = response.apellido_materno
                    } else {
                        this.amaterno = ""
                    }
                    this.profesor = this.nombre + " " + this.apaterno + " " + this.amaterno
                })
            } else {
                this.profesor = this.pregunta["nombre_profesor"]
            }
        }
        this.loadingLayout.ready()
    }

    deshacerCambioPregunta() {
        this.loadingLayout.standby()
        const pregunta_anterior = this.pregunta
        const nueva_pregunta = this.historialPreguntas.pop()
        this.instrumentoPregunta.pregunta_id = nueva_pregunta.id

        this.instrumentoPreguntasService
            .update(this.instrumentoPregunta.id, this.instrumentoPregunta)
            .then((instrumentoPregunta: InstrumentoPregunta) => {
                if (!instrumentoPregunta.id) {
                    this.instrumentoPregunta.pregunta_id = pregunta_anterior.id
                    this.simpleModalService.cleanModal()
                    this.simpleModalService.setModalHeader("Advertencia")
                    this.simpleModalService.setModalStringContent(
                        "La pregunta original ya está siendo ocupada por el instrumento, no fue posible deshacer el cambio."
                    )
                    this.simpleModalService.showSimpleModal()
                    this.loadingLayout.ready()
                } else {
                    this.pregunta = nueva_pregunta
                    this.instrumentoPregunta.pregunta = this.pregunta
                    this.changePregunta.emit(this.instrumentoPregunta)
                    this.loadingLayout.ready()
                }
            })
    }

    cambiarPregunta() {
        this.loadingLayout.standby()
        const params: any = {}
        if (config.evaluaciones[this.evaluacionTipo].clasificaciones.cambiarPregunta) {
            params.clasificacion_tipo = {
                clasificacion_tipo: config.evaluaciones[this.evaluacionTipo].clasificaciones.cambiarPregunta
            }
        }

        this.instrumentoPreguntasService
            .cambiarPregunta(this.instrumentoPregunta.id, params)
            .then((instrumentoPregunta: InstrumentoPregunta) => {
                if (!instrumentoPregunta.pregunta) {
                    this.simpleModalService.cleanModal()
                    this.simpleModalService.setModalHeader("Advertencia")
                    this.simpleModalService.setModalStringContent(
                        "No se puede cambiar la pregunta de un instrumento con una evaluación que ya ha sido respondida!"
                    )
                    this.simpleModalService.setDisableBtnCancel(true)
                    this.simpleModalService.showSimpleModal()
                    this.loadingLayout.ready()

                    return
                }

                if (!instrumentoPregunta.pregunta.id) {
                    this.simpleModalService.cleanModal()
                    this.simpleModalService.setModalHeader("Advertencia")
                    this.simpleModalService.setModalStringContent(
                        "No fue posible encontrar una pregunta afín para la evaluación. Por favor intente nuevamente en unos momentos."
                    )
                    this.simpleModalService.setDisableBtnCancel(true)
                    this.simpleModalService.showSimpleModal()
                    this.loadingLayout.ready()

                    return
                }

                if (instrumentoPregunta.pregunta.id == this.pregunta.id) {
                    this.simpleModalService.cleanModal()
                    this.simpleModalService.setModalHeader("Advertencia")
                    this.simpleModalService.setModalStringContent(
                        "No existen preguntas disponibles con los mismos criterios del instrumento creado."
                    )
                    this.simpleModalService.setDisableBtnCancel(true)
                    this.simpleModalService.showSimpleModal()
                    this.loadingLayout.ready()

                    return
                }

                this.historialPreguntas.push(Object.assign(new Pregunta(), this.pregunta))

                this.instrumentoPregunta = instrumentoPregunta
                this.pregunta = instrumentoPregunta.pregunta

                this.changePregunta.emit(this.instrumentoPregunta)

                this.loadingLayout.ready()
            })
    }

    deletePregunta() {
        this.loadingLayout.standby()

        this.instrumentoPreguntasService.enableIgnoreModel()
        this.instrumentoPreguntasService.remove(this.instrumentoPregunta.id).then((response: any) => {
            if (response.error) {
                this.simpleModalService.cleanModal()
                this.simpleModalService.setModalHeader("Advertencia")
                this.simpleModalService.setModalStringContent(
                    "No se puede sacar la pregunta de un instrumento con una evaluación que ya ha sido respondida!"
                )
                this.simpleModalService.setDisableBtnCancel(true)
                this.simpleModalService.showSimpleModal()
                this.loadingLayout.ready()

                return
            }

            this.deletedPregunta.emit(this.instrumentoPregunta.id)
        })
    }

    togglePilotaje() {
        if (this.almostAllPiloto) {
            this.simpleModalService.cleanModal()
            this.simpleModalService.setModalHeader("Advertencia")
            this.simpleModalService.setModalStringContent(
                "No puedes marcar todas las preguntas como pilotaje. Debes dejar a lo menos una sin marcar."
            )
            this.simpleModalService.setDisableBtnCancel(true)
            this.simpleModalService.showSimpleModal()

            return
        }

        this.togglingPilotaje = true
        const instrumentoPregunta = this.instrumentoPregunta.clone()
        instrumentoPregunta.piloto = !instrumentoPregunta.piloto

        this.instrumentoPreguntasService
            .update(instrumentoPregunta.id, instrumentoPregunta)
            .then((ip: InstrumentoPregunta) => {
                this.instrumentoPregunta.piloto = ip.piloto
                this.togglingPilotaje = false

                this.togglePiloto.emit()
            })
    }

    getLetra(pos: number) {
        return Alternativa.getLetra(pos)
    }

    agregarPregunta(index: number) {
        if (this.posicion == "abajo") index += 1

        let ip = new InstrumentoPregunta()
        ip.instrumento_id = this.instrumento.id
        ip.orden = index
        ;(ip as any).clasificacion_ids = this.clasificacionIds

        if (this.preguntaOTexto == "grupoPregunta") {
            ;(ip as any).grupo_pregunta = true
        }

        if (this.preguntaFromGrupo) {
            ;(ip as any).grupo_pregunta_id = this.pregunta.grupo_pregunta_id
        }
        this.instrumentoPreguntasService.disableIgnoreModel()
        this.instrumentoPreguntasService
            .save(ip)
            .then(instrumento_pregunta => {
                this.nuevaPregunta.emit({ instrumento_pregunta, index })
            })
            .catch(e => {
                this.simpleModalService.cleanModal()
                this.simpleModalService.setModalHeader("Aviso")
                this.simpleModalService.setModalStringContent(
                    "No se encontraron preguntas para las clasificaciones seleccionadas."
                )
                this.simpleModalService.showSimpleModal()
            })
    }

    openModalAgregarPregunta(modal) {
        modal.buttonPressed()
        this.agregarPreguntaModalOpen = true
    }

    onReady() {}
}
