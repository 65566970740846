import { Component, Input, ElementRef, ViewEncapsulation, Directive, HostBinding, HostListener } from "@angular/core"
import { CUIButtonBaseComponent } from "./cui-button-base.component"

/**
 * El cui-button-link necesita poner un tipo de <a> si hay que usar routerLink y otro si hay que usar href
 * ya que interfieren entre sí. La directiva cui-button-link-anchor sirve para controlar mejor el resto de las
 * propiedades que los dos comparten
 */

@Directive({
    selector: "[cui-button-link-anchor]"
})
export class CUIButtonLinkDirective {
    @Input("isDowload") isDownload: string
    @HostBinding("attr.download") download: string
    @Input("targetValue") targetValue: string
    @HostBinding("attr.target") target: string
    @Input("roleValue") roleValue: string
    @HostBinding("attr.role") role: string
    @Input("dataToggle") dataToggle: string
    @HostBinding("attr.data-toggle") toggle: string
    @Input("size") size: string
    @Input("isBtn") isBtn: boolean
    @Input("isBlock") isBlock: boolean
    @Input() buttonClass: "btn_base_1" | "btn_base_2" | "btn_base_3"
    @HostBinding("class")
    get hostClasses(): string {
        return [
            this.buttonClass ? this.buttonClass : "",
            this.isBtn ? "btn" : "",
            this.isBlock ? "block" : "",
            this.isBtn && this.size == "xsmall" ? "btn-xs" : "",
            this.isBtn && this.size == "small" ? "btn-sm" : "",
            this.isBtn && this.size == "large" ? "btn-lg" : "",
            this.isBtn && this.size == "btn-xl" ? "btn-xs" : ""
        ].join(" ")
    }

    ngOnInit() {
        this.download = this.isDownload ? this.isDownload : undefined
        this.target = this.targetValue ? this.targetValue : undefined
    }
}

@Component({
    selector: "cui-button-link",
    template: `
        <ng-template #contenido>
            <ng-content select=".left-icon"></ng-content>
            <ng-content select=".only-icon"></ng-content>
            <ng-content></ng-content>
            <ng-content select=".right-icon"></ng-content>
        </ng-template>
        <a
            *ngIf="routerLinkValue"
            [routerLink]="routerLinkValue"
            [queryParams]="queryParamsValue"
            cui-button-link-anchor
            [buttonClass]="buttonClass"
            [isDowload]="isDownload + ''"
            [targetValue]="targetValue"
            [size]="size"
            [isBtn]="isBtn"
            [isBlock]="isBlock"
            [roleValue]="roleValue"
            [dataToggle]="dataToggle"
            (click)="onClickButton($event)"
        >
            <ng-container *ngTemplateOutlet="contenido"></ng-container>
        </a>
        <a
            *ngIf="!routerLinkValue"
            [attr.href]="href ? href : undefined"
            cui-button-link-anchor
            [buttonClass]="buttonClass"
            [isDowload]="isDownload + ''"
            [targetValue]="targetValue"
            [size]="size"
            [isBtn]="isBtn"
            [isBlock]="isBlock"
            [roleValue]="roleValue"
            [dataToggle]="dataToggle"
            (click)="onClickButton($event)"
        >
            <ng-container *ngTemplateOutlet="contenido"></ng-container>
        </a>
        <span *ngIf="isLoading" class="loader {{ loaderPosition }}" [class.loaderLight]="loaderLight"></span>
    `,
    styleUrls: ["cui-button.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUIButtonLinkComponent extends CUIButtonBaseComponent {
    @Input("routerLinkValue") routerLinkValue: string | any[]
    @Input("queryParamsValue") queryParamsValue: any
    @Input() href: string = ""
    @Input() isDownload: string | boolean
    @Input() targetValue: string = ""
    @Input("roleValue") roleValue: string = ""
    @Input("dataToggle") dataToggle: string = ""
    @HostListener("click", ["$event"]) onClick(e) {
        if (!this.href) {
            // cuando uso href, no quiero bloquear el funcionamiento normal
            e.preventDefault()
            e.stopPropagation()
        }
    }

    constructor(protected el: ElementRef) {
        super(el)
    }
}
