import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    OnChanges,
    SimpleChanges,
    OnDestroy
} from "@angular/core"
import { Asignatura, Asignaturas, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { Evaluacion, Evaluaciones } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { ModalDirective } from "ngx-bootstrap/modal"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import { State, selectSelectedAsignatura, selectEvaluacionTipoAliases } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    selector: "evaluaciones-compartidas-alumno",
    templateUrl: "evaluaciones_compartidas_alumno.component.html",
    styleUrls: ["evaluaciones_compartidas_alumno.component.scss"]
})
export class EvaluacionesCompartidasAlumnoComponent implements OnInit, OnChanges, OnDestroy {
    @Input() disableDownloadNotPropio: boolean = false
    @Input() asignaturaId: number
    @Input() evaluacionTipo: string
    @Input() attrPresencial?: boolean = false
    @Input() urlRealizar: string = "/ensayos"
    @Output() ready = new EventEmitter<any>()
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("mandatoryLoading", { static: true }) mandatoryLoading: ModalDirective
    @ViewChild("tablaContenedor", { static: true }) tabla_contenedor: ElementRef
    asignatura: Asignatura

    asignatura$ = this.store.pipe(select(selectSelectedAsignatura))

    enableResponsiveTable: boolean = false
    evaluaciones: Evaluacion[] = []
    @Input() hideButtonEscanear?: boolean = config.plataforma.hideButtonEscanear || false

    subAlias: Subscription
    evaluacionTipoAlias: string
    evaluacionTipoAliases: any

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    tooltip: string

    constructor(
        protected asignaturasService: Asignaturas,
        protected evaluacionesService: Evaluaciones,
        protected store: Store<State>,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected translatorService: I18nService
    ) {}

    ngOnInit() {
        this.subAlias = this.evaluacionTipoAliases$.subscribe(evaluacionTipoAliases => {
            this.evaluacionTipoAliases = evaluacionTipoAliases
            this.evaluacionTipoAlias = this.evaluacionTipoAliases[this.evaluacionTipo]
        })

        this.setData()

        this.tooltip = this.translatorService.translate("progreso.historial.pendientes.tooltip", {})
    }

    setData() {
        this.loadingLayout.standby()
        this.asignatura = null
        this.asignaturasService.find(this.asignaturaId, { simple: 1 }).then((asignatura: Asignatura) => {
            this.asignatura = asignatura
            this.ready.emit(asignatura)
        })
    }

    showAll(page: number, per: number) {
        this.loadingLayout.standby()

        let today = new Date().toISOString()

        let params: any = {
            page,
            per,
            mios: 1,
            no_contestadas: 1,
            sort_by: "created_at",
            order: "desc",
            include: "[instrumento,evaluacion_tipo,evaluacion_usuarios:[usuario,grupo_usuario:establecimiento]]",
            instrumento: {
                asignatura_id: this.asignaturaId
            },
            evaluacion: {
                lte: {
                    fecha_inicio: [today, "null"]
                },
                gte: {
                    fecha_termino: [today, "null"]
                }
            }
        }

        if (this.attrPresencial != undefined) {
            params.evaluacion.presencial = this.attrPresencial
        }

        if (this.evaluacionTipo) {
            params.evaluacion_tipo = { evaluacion_tipo: this.evaluacionTipo }
        }

        return this.evaluacionesService.where(params).then((evaluaciones: Evaluacion[], total: number) => {
            this.evaluaciones = evaluaciones.filter(evaluacion => !evaluacion.presencial)
            this.loadingLayout.ready()
            return total
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.asignaturaId && (changes["asignaturaId"] || changes["evaluacionTipo"])) {
            this.setData()
        }
        if (changes["evaluacionTipo"]) {
            this.evaluacionTipoAlias = this.evaluacionTipoAliases?.[this.evaluacionTipo]
        }
    }

    checkResponsive() {
        this.enableResponsiveTable = this.tabla_contenedor && this.tabla_contenedor.nativeElement.clientWidth < 690
    }

    async descargar(evaluacion) {
        this.mandatoryLoading.show()
        let params: any = {}
        params.plataforma = config.plataforma.name

        const generadorInstrumento = await this.generadorInstrumentosService.find(
            evaluacion.instrumento.generador_instrumento_id
        )
        if (generadorInstrumento.maximo_alternativas) {
            params.cantidad_alternativas = generadorInstrumento.maximo_alternativas
        }

        this.evaluacionesService.enableIgnoreModel()
        this.evaluacionesService.imprimir(evaluacion.id, params).then((obj: any) => {
            this.evaluacionesService.disableIgnoreModel()
            const info = obj.info
            this.logInfoLatex(info.statusCode, info.exit_status, info.stdout, info.stderr)
            this.mandatoryLoading.hide()
            const downloadString = "evaluacion_" + evaluacion.id + ".pdf"
            this.createLinkAndOpen(info.link, downloadString)
        })
    }

    //Buscar la manera de centralizar estos ultimos metodos
    createLinkAndOpen(link, downloadString) {
        const a = document.createElement("a")
        a.download = downloadString
        a.href = link
        a.click()
    }

    logInfoLatex(statusCodeLambda, exitStatus, stdout, stderr) {
        console.log("STATUS CODE LAMBDA")
        console.log("%c " + statusCodeLambda, "color: blue")
        console.log("EXIT STATUS LATEX")
        console.log("%c " + exitStatus, "color: blue")
        console.log("SALIDA ESTANDAR LATEX")
        console.log("%c " + stdout, "color: blue")
        console.log("SALIDA DE ERRORES LATEX")
        console.log("%c " + stderr, "color: crimson")
    }

    ngOnDestroy() {
        this.subAlias.unsubscribe()
    }
}
