import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Nivel, Niveles, UsuarioPlataforma, UsuarioPlataformas } from "@puntaje/achievements/api-services"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "nivel-circlebar",
    templateUrl: "./nivel-circlebar.component.html",
    styleUrls: ["./nivel-circlebar.component.scss"]
})
export class NivelCircleBarComponent implements OnInit {
    @Input() niveles: Nivel[]
    @Input() usuarioPlataforma: UsuarioPlataforma
    @Input() withTitleLvl: boolean = false
    @Input() withTitleNextLvl: boolean = false
    @Input() radius: number
    @Input() stroke: number = 4

    colorBarra: string = "#2691c5"
    colorFondo: string = "#D3D3D3"

    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()

    nivel: Nivel
    experienciaNivelPer: number = 0

    constructor(
        protected authService: AuthService,
        protected nivelesService: Niveles,
        protected usuarioPlataformasService: UsuarioPlataformas,
        private el: ElementRef
    ) {}

    async ngOnInit() {
        // ajuste color
        let style = getComputedStyle(document.body)
        this.colorBarra = style.getPropertyValue("--palette-colors-primary")
            ? style.getPropertyValue("--palette-colors-primary")
            : this.colorBarra
        //ajuste tamaño
        if (!this.radius) {
            if (this.el.nativeElement.offsetWidth > 40) {
                this.radius = (this.el.nativeElement.offsetWidth - this.stroke) / 2
            } else {
                this.radius = 18
            }
        }
        if (!this.niveles) {
            const niveles = await this.nivelesService.where({ plataforma: { plataforma: config.plataforma.name } })
            this.niveles = niveles.sort((n1, n2) => n1.experiencia - n2.experiencia)
        }

        if (!this.usuarioPlataforma) {
            const params = {
                usuario_plataforma: {
                    usuario_id: this.authService.getUserData().id
                },
                plataforma: {
                    plataforma: config.plataforma.name
                }
            }

            this.usuarioPlataforma = (await this.usuarioPlataformasService.where(params))[0]
        }

        this.setNivelUsuario()
    }

    setNivelUsuario() {
        const experiencia = this.usuarioPlataforma?.experiencia || 0

        const nivel = this.niveles.find(n => experiencia < n.experiencia)

        let ptosToNextLvl

        const nextNivelIndex = this.niveles.findIndex(n => experiencia < n.experiencia)
        if (nextNivelIndex) {
            this.nivel = this.niveles[nextNivelIndex - 1]
            const nextNivel = this.niveles[nextNivelIndex]
            this.experienciaNivelPer = (100 * experiencia) / (nextNivel.experiencia - this.nivel?.experiencia)
            ptosToNextLvl = nextNivel.experiencia - experiencia
        } else {
            this.nivel = null
            this.experienciaNivelPer = 100
            ptosToNextLvl = 0
        }

        this.onReady.emit({ nivel: this.nivel?.nivel, ptosToNextLvl })
    }
}
