import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { EntrenadorUsuariosEditComponent } from "./entrenador_usuarios_edit.component"
import { EntrenadorShowcaseBoxComponent } from "./entrenador_showcase_box.component"
import { RouterModule } from "@angular/router"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule } from "@puntaje/shared/core"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { CUITextsModule, CUIInputsModule } from "@puntaje/shared/cui"

@NgModule({
    declarations: [EntrenadorUsuariosEditComponent, EntrenadorShowcaseBoxComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        CommonsLayoutsModule,
        Angular2FontawesomeModule,
        UtilModule,
        ProgressbarModule,
        CUITextsModule,
        CUIInputsModule
    ],
    exports: [EntrenadorUsuariosEditComponent, EntrenadorShowcaseBoxComponent],
    providers: []
})
export class EntrenadorModule {}
