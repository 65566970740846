// tslint:disable:variable-name
import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { EvaluacionMultiple } from "./evaluacion_multiples.model"
import { EvaluacionInstancia } from "./evaluacion_instancias.model"

export class EvaluacionMultipleInstancia extends BaseModel {
    usuario_id: number
    evaluacion_multiple_id: number
    total_dudas: number
    nintento: number
    created_at: Date
    calificacion: number

    @SaveType(() => EvaluacionMultiple) evaluacion_multiple: EvaluacionMultiple
    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => EvaluacionInstancia) evaluacion_instancias: EvaluacionInstancia[]
}
