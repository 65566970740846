<div class="sentiment" [class.horizontal]="!vertical" [class.vertical]="vertical" [class.hide]="!loaded">
    <div class="sentiment-row">
        <div class="sentiment-cell">
            <button type="button" class="btn" (click)="like()">
                <span [class.inactive]="isDislikeValue"><ogr-icon name="arrow-up-o"></ogr-icon></span>
            </button>
        </div>
        <div class="sentiment-cell number-cell">
            <div class="number">{{ likes - dislikes }}</div>
        </div>
        <div class="sentiment-cell">
            <button type="button" class="btn" (click)="dislike()">
                <span [class.inactive]="isLikeValue"><ogr-icon name="arrow-down-o"></ogr-icon></span>
            </button>
        </div>
    </div>
</div>
