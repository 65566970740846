import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"
import { Rol } from "./roles.model"

@Injectable()
export class Roles extends PuntajeBaseService<Rol> {
    tableName = "roles"
    singularTableName = "rol"
    modelClass = Rol

    constructor(
        http: HttpClient,
        auth: PuntajeAuthService,
        router: Router,
        errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
