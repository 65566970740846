<div [class.hidden]="alumnoLibreSinCursos">
    <div class="row linea-verde-agua">
        <div class="col-md-3">
            <h3 *ngIf="!hideTitle" class="h3-bonito">{{ titulo }}</h3>
        </div>
        <div class="col-md-3" *ngIf="showYearFilter">
            <select class="form-control select-amigo-del-h3-bonito" [ngModel]="anoSeleccionado" (ngModelChange)="anoChanged($event)">
                <option [ngValue]="undefined">-- {{ 'estudiar.plan_de_clases.sel_ano' | t }} --</option>
                <option *ngFor="let ano of planesAnos" [ngValue]="ano">
                    {{ ano }}
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="!loadingLayout.loading && msjNoPlanes && planificaciones?.length === 0" class="alert no-results">
        {{ msjNoPlanes }}
    </div>
    <div
        class="mis-planes clearfix"
        #misPlanes
        [class.hidden]="!loadingLayout.loading && msjNoPlanes && planificaciones?.length == 0"
        (window:resize)="checkSize()"
    >
        <loading-layout #loadingLayout>
            <div
                class="flex-container"
                *ngIf="!loadingLayout.loading"
                [class.col-3-ghost]="colNumber3"
                [class.col-4-ghost]="colNumber4"
            >
                <ng-container *ngFor="let planificacion of shownPlanificaciones">
                    <plan-clase-box
                        [planificacion]="planificacion"
                        [class.col-number-1]="colNumber1"
                        [class.col-number-2]="colNumber2"
                        [class.col-number-3]="colNumber3"
                        [hideProgress]="false"
                        [plan_clase_route]="planClaseRoute"
                        [verSinCompartir]="verSinCompartir"
                        [showAsignatura]="showAsignatura"
                    ></plan-clase-box>
                </ng-container>
            </div>
        </loading-layout>
    </div>
</div>
