import { BaseModel } from "@puntaje/shared/core"

export class InfoAdicional extends BaseModel {
    public static className: string = "InfoAdicional"

    public infoAdicional: string
    public carreraSedeId: number
    public valor: string
    public isLink: boolean
}
