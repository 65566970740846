import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { Router, ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { GameView } from "@puntaje/shared/core"
import { Material } from "@puntaje/nebulosa/api-services"
import { Location } from "@angular/common"

@Component({
    selector: "materiales-apps",

    templateUrl: "materiales_apps.component.html",
    styleUrls: ["materiales_apps.component.scss"]
})
export class MaterialesAppsComponent implements OnInit, OnDestroy {
    enableResponsive: boolean = false
    width: number = 1280
    height: number = 800
    max_width: number = 1280
    max_height: number = 800
    url: string
    safeUrl: SafeResourceUrl
    urlReady: boolean = false
    private sub: Subscription
    aplicacion: string
    material: Material
    @ViewChild("iframeContainer") iframeContainer: ElementRef
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        protected sanitizer: DomSanitizer,
        protected router: Router,
        protected route: ActivatedRoute,
        protected location: Location
    ) {}

    ngOnInit() {
        this.enableResponsive = window.innerWidth <= 900
        this.sub = this.route.queryParams.subscribe(params => {
            this.aplicacion = params["aplicacion"]
            this.material = new Material()
            switch (this.aplicacion) {
                case "egipto":
                    this.material.url = "https://udd.prod.ogr.cl/apps/egipto_web/index.html"
                    this.material.material = "Egipto"
                    break

                case "piratas":
                    this.material.url = "https://udd.prod.ogr.cl/apps/piratas_web/index.html"
                    this.material.material = "Pequeños Piratas"
                    break

                case "parque_diversiones":
                    this.material.url = "https://udd.prod.ogr.cl/apps/parque_diversiones_web/index.html"
                    this.material.material = "Parque de Diversiones"
                    break

                case "sandwich":
                    this.material.url = "https://udd.prod.ogr.cl/apps/sandwich_web/index.html"
                    this.material.material = "Sandwich Espacial"
                    break

                case "calendario":
                    this.material.url = "https://udd.prod.ogr.cl/apps/calendario_web/index.html"
                    this.material.material = "El Calendario de Lumamié Juevisadom"
                    break

                default:
                    this.material.url = "https://udd.prod.ogr.cl/apps/egipto_web_build/Egipto/index.html"
                    this.material.material = "Egipto"
                    break
            }
            this.onReady.emit(this.material)
            this.urlReady = true
            this.loadingLayout.ready()
        })
    }

    getVisualizer(): any {
        if (this.urlReady) {
            return { label: "Jugar", type: GameView, tableVisible: true }
        } else {
            return null
        }
    }

    onResize(event) {
        this.enableResponsive = event.target.innerWidth <= 900
        this.setRatioSize()
    }

    setRatioSize() {
        if (this.iframeContainer) {
            if (this.iframeContainer.nativeElement.offsetWidth > this.max_width) {
                this.width = this.max_width
            } else {
                this.width = this.iframeContainer.nativeElement.offsetWidth
            }
        }
        //this.height = (this.width * this.max_height) / this.max_width;
    }

    onLoadIframe() {
        this.setRatioSize()
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe()
        }
    }
    goBack() {
        this.location.back()
    }
}
