<notificacion-layout
    [notificacion]="notificacion"
    [enableMini]="enableMini"
    [icono]="'dialogos'"
    [tipoNotificacion]="'dudas'"
    [disableMarkButton]="disableMarkButton"
>
    <notificacion-contenido>
        <p class="texto-notificacion">
            <a [routerLink]="usuario_remitente_link">{{ usuario_remitente }}</a>
            {{ texto_duda }} {{ "notificaciones.la_duda" | t }} "
            <button type="button" class="btn-link" (click)="onClickLink()">{{ duda.duda }}</button>
            ".
        </p>
        <p class="category-info" *ngIf="dudaCategoria && dudaCategoria.asignatura_id">
            <span class="categoria {{ dudaCategoria.asignatura?.clase }}">
                {{ dudaCategoria.duda_categoria }}
            </span>
        </p>
    </notificacion-contenido>
</notificacion-layout>
