import { Component, OnInit, Input } from "@angular/core"
import { Duda, DudaCategoria, DudaCategorias } from "@puntaje/puntaje/api-services"
import { Asignatura, Asignaturas, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import {
    State,
    selectAsignaturasByEvaluacionTipo,
    selectAsignaturasList,
    selectAsignaturasById
} from "@puntaje/puntaje/store"
import { Observable, Subscription, combineLatest } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    selector: "duda-list-item",
    templateUrl: "duda_list_item.component.html",
    styleUrls: ["duda_list_item.component.scss"]
})
export class DudasListItemComponent implements OnInit {
    @Input() duda: Duda
    titulo: string
    hasAnswer = false
    hasSolution = false
    comentarios: string

    sub: Subscription
    asignaturasById$: Observable<{ [id: number]: AsignaturaWithConfig }> = this.store.pipe(
        select(selectAsignaturasById),
        filter(x => !!x)
    )

    constructor(protected store: Store<State>, protected i18nService: I18nService) {}

    ngOnInit() {
        this.titulo = this.i18nService.translate("duda.titulo", {})
        this.comentarios = this.i18nService.translate("duda.sin_comentarios", {})
        const com = this.i18nService.translate("duda.comentario", {})
        const coms = this.i18nService.translate("duda.comentarios", {})

        this.sub = this.asignaturasById$.subscribe(asignaturasById => {
            if (this.duda.mejor_respuesta_id) {
                this.hasSolution = true
            }
            if (this.duda.participacion && this.duda.participacion > 0) {
                this.comentarios = com
                this.hasAnswer = true
                if (this.duda.participacion > 1) this.comentarios = coms
            }
            if (this.duda.duda_categorias) {
                this.duda.duda_categorias.forEach(c => {
                    if (c.asignatura_id) {
                        c["asignatura"] = asignaturasById[c.asignatura_id]
                    }
                })
            }
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
