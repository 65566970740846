import { Injectable, TemplateRef } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { LayoutService } from "../layout.service"

interface SharedLayoutOptions {}

@Injectable({ providedIn: "root" })
export class SharedLayoutService extends LayoutService<SharedLayoutOptions> {
    defaultOptions: SharedLayoutOptions = {}

    options$ = new BehaviorSubject<SharedLayoutOptions>(this.defaultOptions)
}
