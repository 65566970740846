import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { EvaluacionesCorregirComponent } from "./evaluaciones-corregir.component"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { CUIInputsModule, CUILayoutsModule } from "@puntaje/shared/cui"
import { EnsayoInstanciasComponent } from "./ensayo-instancias.component"
import { GenerarEnsayoProfesorComponent } from "./generar_ensayo_profesor.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { TranslationsModule, UtilModule } from "@puntaje/shared/core"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { GenerarEnsayoProfesor2Component } from "./generar_ensayo_profesor2.component"
import { RevisarEnsayosComponent } from "./revisar_ensayos.component"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { EvaluacionComponent } from "./evaluacion.component"
import { CUIContainersModule, CUITextsModule } from "@puntaje/shared/cui"
import { ResultadosEnsayoAlumnoComponent } from "./resultados_ensayo_alumno.component"
import { LibroNotasComponent } from "./libro-notas/libro-notas.component"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { EnsayosMensualesComponent } from "./ensayos_mensuales.component"
import { EnsayosRecomendadosComponent } from "./ensayos_recomendados.component"
import { EvaluacionesCompartidasComponent } from "./evaluaciones_compartidas.component"
import { GenerarEnsayoComponent } from "./generar_ensayo.component"
import { ImprimirEnsayoComponent } from "./imprimir_ensayo.component"
import { RealizarEnsayoComponent } from "./realizar_ensayo.component"
import { RealizarEvaluacionInteractivaComponent } from "./realizar_evaluacion_interactiva.component"
import { ResultadosEnsayoComponent } from "./resultados_ensayo.component"
import { AngularGradientProgressbarModule } from "angular-gradient-progressbar"
import { EncuestaEnsayoAdaptiveComponent } from "./encuesta_ensayo_adaptive.component"
import { GenerarEnsayoAdaptiveComponent } from "./generar_ensayo_adaptive.component"
import { RealizarEnsayoAdaptiveComponent } from "./realizar_ensayo_adaptive.component"
import { ResultadosEnsayoAdaptiveComponent } from "./resultados_ensayo_adaptive.component"
import { GenerarInstrumentoValidadoComponent } from "./generar_instrumento_validado.component"
import { TabsModule } from "ngx-bootstrap/tabs"
import { SmowlModule } from "@puntaje/puntaje/new-modules/smowl"
import { EvaluacionesMultiplesCompartidasComponent } from "./evaluaciones_multiples/evaluaciones_multiples_compartidas/evaluaciones_multiples_compartidas.component"
import { GenerarEnsayoMultipleComponent } from "./generar-ensayo-multiple/generar-ensayo-multiple.component"
import { GenerarEnsayoSimpleComponent } from "./generar_ensayo_simple.component"
import { RealizarEvaluacionMultipleComponent } from "./realizar_evaluacion_multiple.component"
import { ResultadosEvaluacionMultipleComponent } from "./resultados_evaluacion_multiple.component"
import { EvaluacionesMultiplesRecomendadasComponent } from "./evaluaciones_multiples_recomendadas.component"
import { RealizarEnsayoSecuencialComponent } from "./realizar_ensayo_secuencial.component"
import { RevisarSimulacrosComponent } from "./revisar_simulacros.component"
import { ResultadosEnsayosComponent } from "./resultados-ensayos/resultados-ensayos.component"

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CommonsLayoutsModule,
        UtilModule,
        NebuModule,
        EnsayosModule,
        SharedModule,
        InstrumentosModule,
        CUITextsModule,
        CUIContainersModule,
        Angular2FontawesomeModule,
        CUILayoutsModule,
        AngularGradientProgressbarModule,
        TranslationsModule,
        TabsModule,
        SmowlModule,
        CUIInputsModule
    ],
    declarations: [
        EvaluacionesCorregirComponent,
        EnsayoInstanciasComponent,
        GenerarEnsayoProfesorComponent,
        GenerarEnsayoProfesor2Component,
        RevisarEnsayosComponent,
        EvaluacionComponent,
        ResultadosEnsayoAlumnoComponent,
        LibroNotasComponent,
        EnsayosMensualesComponent,
        EnsayosRecomendadosComponent,
        EvaluacionesCompartidasComponent,
        GenerarEnsayoComponent,
        ImprimirEnsayoComponent,
        RealizarEnsayoComponent,
        RealizarEvaluacionInteractivaComponent,
        ResultadosEnsayoComponent,
        EncuestaEnsayoAdaptiveComponent,
        GenerarEnsayoAdaptiveComponent,
        RealizarEnsayoAdaptiveComponent,
        ResultadosEnsayoAdaptiveComponent,
        GenerarInstrumentoValidadoComponent,
        EvaluacionesMultiplesCompartidasComponent,
        GenerarEnsayoMultipleComponent,
        GenerarEnsayoSimpleComponent,
        RealizarEvaluacionMultipleComponent,
        ResultadosEvaluacionMultipleComponent,
        EvaluacionesMultiplesRecomendadasComponent,
        RealizarEnsayoSecuencialComponent,
        RevisarSimulacrosComponent,
        ResultadosEnsayosComponent
    ],
    exports: [
        EvaluacionesCorregirComponent,
        EnsayoInstanciasComponent,
        GenerarEnsayoProfesorComponent,
        GenerarEnsayoProfesor2Component,
        RevisarEnsayosComponent,
        EvaluacionComponent,
        ResultadosEnsayoAlumnoComponent,
        LibroNotasComponent,
        EnsayosMensualesComponent,
        EnsayosRecomendadosComponent,
        EvaluacionesCompartidasComponent,
        GenerarEnsayoComponent,
        ImprimirEnsayoComponent,
        RealizarEnsayoComponent,
        RealizarEvaluacionInteractivaComponent,
        ResultadosEnsayoComponent,
        ResultadosEnsayoComponent,
        EncuestaEnsayoAdaptiveComponent,
        GenerarEnsayoAdaptiveComponent,
        RealizarEnsayoAdaptiveComponent,
        ResultadosEnsayoAdaptiveComponent,
        GenerarInstrumentoValidadoComponent,
        EvaluacionesMultiplesCompartidasComponent,
        GenerarEnsayoMultipleComponent,
        GenerarEnsayoSimpleComponent,
        RealizarEvaluacionMultipleComponent,
        ResultadosEvaluacionMultipleComponent,
        EvaluacionesMultiplesRecomendadasComponent,
        RealizarEnsayoSecuencialComponent,
        RevisarSimulacrosComponent,
        ResultadosEnsayosComponent
    ]
})
export class PuntajeEnsayosModule {}
