import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core"
import { Noticia, Noticias } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "noticias-showcasebox",
    templateUrl: "noticias_showcasebox.component.html",
    styleUrls: ["noticias_showcasebox.component.scss"]
})
export class NoticiasShowcaseBoxComponent implements OnInit {
    noticias: Noticia[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() maximoNoticias: number = 5
    @Input("perfiles") perfiles: string
    noticiaPerfiles: string[] = ["todos", "profesores"]
    openModal: EventEmitter<any> = new EventEmitter<any>()
    currentNoticia: Noticia
    hasNewsIcon: boolean = true
    config: typeof config = config
    @Input() defaultNotFound: boolean = true
    logoUrl: string

    constructor(protected noticiasService: Noticias) {}

    ngOnInit() {
        if (this.perfiles) {
            this.noticiaPerfiles = this.perfiles.split(",")
        }

        this.logoUrl = config.app.assets.logoNoticias ?? config.app.assets.logoSquare

        this.showAll(1, this.maximoNoticias)
    }

    showAll(page: number, per: number) {
        let today = new Date().toISOString()
        return this.noticiasService
            .where({
                page: page,
                per: per,
                noticia: { lte: { fecha_inicio: today }, gte: { fecha_termino: today } },
                noticias_perfil: { noticia_perfil: this.noticiaPerfiles },
                from_lugar_establecimientos: 1,
                sort_by: "created_at",
                order: "desc",
                shared_with_me: 1
            })
            .then((noticias: Noticia[], total: number) => {
                this.noticias = noticias
                this.loadingLayout.ready()
                return total
            })
    }

    showNoticia(noticia: Noticia) {
        this.currentNoticia = noticia
        this.openModal.emit()
    }
}
