import { Component, ViewChild, Output, EventEmitter } from "@angular/core"
import { ModalDirective } from "ngx-bootstrap/modal"

@Component({
    selector: "modal-imprimir-evaluacion-alumnos",
    templateUrl: "modal-imprimir-evaluacion-alumnos.component.html",
    styleUrls: ["modal-imprimir-evaluacion-alumnos.component.scss"]
})
export class ModalImprimirEvaluacionAlumnosComponent {
    @ViewChild("modalImprimirEvaluacionAlumnos", { static: true }) modalImprimirEvaluacionAlumnos: ModalDirective
    @Output() confirmCallback: EventEmitter<any> = new EventEmitter<any>()

    constructor() {}

    open() {
        this.modalImprimirEvaluacionAlumnos.show()
    }

    close() {
        this.modalImprimirEvaluacionAlumnos.hide()
    }

    confirm() {
        this.confirmCallback.next()
        this.close()
    }
}
