import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"

export class Recordatorio extends BaseModel {
    id: number
    usuario_id: number
    enviado: boolean
    texto: string
    created_at: Date
    fecha_envio: Date

    public toString() {
        return this.texto
    }
}
