import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { TypeformWidgetsComponent } from "./typeform-widgets/typeform-widgets.component"
import { TypeformWidgetsModule } from "@puntaje/puntaje/new-modules/typeform-widgets"
import { ToolNavbarModule } from "@puntaje/shared/core"
import { TypeformWidgetsEditComponent } from "./typeform-widgets-edit/typeform-widgets-edit.component"
import { TypeformWidgetsNewComponent } from "./typeform-widgets-new/typeform-widgets-new.component"
import { TypeformWidgetComponent } from "./typeform-widget/typeform-widget.component"
import { TypeformWidgetViewComponent } from "./typeform-widget-view/typeform-widget-view.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    declarations: [
        TypeformWidgetsComponent,
        TypeformWidgetsEditComponent,
        TypeformWidgetsNewComponent,
        TypeformWidgetComponent,
        TypeformWidgetViewComponent
    ],
    exports: [
        TypeformWidgetsComponent, 
        TypeformWidgetsEditComponent, 
        TypeformWidgetsNewComponent, 
        TypeformWidgetComponent,
        TypeformWidgetViewComponent
    ],
    imports: [CommonModule, ToolNavbarModule, TypeformWidgetsModule, CUILayoutsModule]
})
export class PuntajeTypeformWidgetsModule {}
