<div class="form-container" *ngIf="comentario">
    <div class="clearfix">
        <label>{{ labelText }}</label>
        <cui-button buttonType="cancel" class="btn-cancel pull-right" (click)="cancelar()">Cancelar</cui-button>
    </div>
    <form-input
        [form]="form"
        [params]="params.comentario"
        [(value)]="comentario.comentario"
        key="comentario"
    ></form-input>
    <div class="clearfix">
        <cui-button class="pull-right" (click)="save()">{{ saveBtnText }}</cui-button>
    </div>
</div>
