<ll-toolnavbar>
    <materiales-toolnavbar></materiales-toolnavbar>
</ll-toolnavbar>
<ll-titulo>{{ "estudiar.materiales.material" | t }}:</ll-titulo>
<material
    [id]="id"
    [enableFavorito]="enableFavorito"
    [enableDudas]="enableDudas"
    [isViewOnYoutubeEnabled]="isViewOnYoutubeEnabled"
></material>
