import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, HostBinding } from "@angular/core"
import { Subscription, timer } from "rxjs"
import { EvaluacionTiempos, EvaluacionTiempo, Evaluacion } from "@puntaje/puntaje/api-services"
import { EvaluacionTiempoComponent } from "../evaluacion_tiempo.component"

@Component({
    selector: "widget-evaluacion-tiempo",
    templateUrl: "widget-evaluacion-tiempo.component.html",
    styleUrls: ["widget-evaluacion-tiempo.component.scss"]
})
export class WidgetEvaluacionTiempoComponent extends EvaluacionTiempoComponent implements OnInit, OnDestroy {}
