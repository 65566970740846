import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input, Injector } from "@angular/core"
import { Subscription } from "rxjs"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Usuario,
    Usuarios,
    UsuarioEditUss,
    UsuarioEditUssForm,
    UsuarioAvatarEdit,
    UsuarioAvatarEditForm
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { S3, S3Service, AuthService, CheckAchievementAsync, CheckActividadAsync, AppEnv } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
import { CrearAlertaLogros } from "@puntaje/puntaje/store"
import { UsuarioEmailModalComponent } from "../usuario-email-modal/usuario-email-modal.component"
declare const config: AppConfig

@Component({
    selector: "usuarios-clone-edit",
    templateUrl: "./usuarios-clone-edit.component.html",
    styleUrls: ["../usuarios.edit.component.scss"]
})
export class UsuariosCloneEditComponent implements OnInit {
    usuario: Usuario
    usuarioEdit: UsuarioEditUss
    usuarioAvatarEdit: UsuarioAvatarEdit
    oUsuarioEdit: UsuarioEditUss
    oUsuarioAvatarEdit: UsuarioAvatarEdit
    pais: string
    idPais: any
    idPaisAliases: any = {}
    @Input() enableShowCountryData: boolean = false
    @Input() enableFacebookLink: boolean = true
    @Input() enableTutorias: boolean = true
    @Input() showPreferencia: boolean = true
    enableEditNivel: boolean = config.registro?.enableNivel || false

    paramsAvatar = UsuarioAvatarEditForm.formParams
    params = UsuarioEditUssForm.formParams

    @Input() usuarioId: number

    form: UntypedFormGroup
    formAvatar: UntypedFormGroup

    edit_user_info: boolean = false
    edit_avatar: boolean = false
    edit_password: boolean = false
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutAvatar") loadingLayoutAvatar: LoadingLayoutComponent
    @ViewChild(UsuarioEmailModalComponent, { static: true }) usuarioEmailModalComponent: UsuarioEmailModalComponent

    loadingAvatar: boolean = false
    lugarLabel: string

    @Input() enableTutor: boolean = false

    constructor(
        protected authService: AuthService,
        protected usuariosService: Usuarios,
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        public s3Service: S3Service,
        protected injector: Injector,
        protected environment: AppEnv
    ) {
        this.lugarLabel = config.plataforma.lugarLabel ? config.plataforma.lugarLabel : null
    }

    ngOnInit() {
        UsuarioEditUssForm.injector = this.injector
        this.params = UsuarioEditUssForm.formParams
        this.showPreferencia = !!this.environment.endpoints.carreras && config.api.enable_carreras
        this.pais = config.plataforma.pais
        this.idPais = config.plataforma.identificadorUsuario
        let aliases = config.plataforma?.identificadorUsuarioAlias
        this.idPais.forEach(id => {
            this.idPaisAliases[id] = aliases?.[id] || id
        })
        this.usuariosService.find(this.usuarioId, { include: "usuario_" + this.pais }).then((usuario: Usuario) => {
            this.usuario = usuario
            this.usuarioEdit = new UsuarioEditUss(usuario.id)
            this.usuarioEdit.fromUsuario(usuario)
            this.oUsuarioEdit = this.usuarioEdit.clone()
            this.form = UsuarioEditUssForm.getForm(this.usuarioEdit, null, this.injector)

            this.usuarioAvatarEdit = new UsuarioAvatarEdit(usuario.id)
            this.usuarioAvatarEdit.fromUsuario(usuario)
            this.oUsuarioAvatarEdit = this.usuarioAvatarEdit.clone()
            this.formAvatar = UsuarioAvatarEditForm.getForm(this.usuarioAvatarEdit)

            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    saveProfileImage() {
        UsuarioAvatarEditForm.markFormControlsAsTouched(this.formAvatar)
        if (this.formAvatar.valid) {
            this.loadingLayoutAvatar.standby()
            this.s3Service.where(this.usuarioAvatarEdit.getS3Params()).then((policies: S3[]) => {
                let policy = policies as any as S3
                this.usuarioAvatarEdit["avatar"] = policy["key"]
                this.s3Service.uploadToS3(
                    policy,
                    this.usuarioAvatarEdit.file,
                    this.usuariosService.tableName,
                    this.updateUsuario.bind(this)
                )
            })
        }
    }

    @CheckAchievementAsync(["PN_SUBIR_AVATAR", "PN_COMPLETAR_PERFIL"], CrearAlertaLogros)
    @CheckActividadAsync("SA")
    updateUsuario() {
        return this.usuariosService
            .update(this.usuarioAvatarEdit.usuario_id, this.usuarioAvatarEdit.toUsuario())
            .then((response: Usuario) => {
                this.authService.setUserData(response)
                this.usuario = response
                setTimeout(() => {
                    this.usuarioAvatarEdit["file"] = undefined
                    this.usuarioEdit["avatar"] = this.usuario.avatar
                    UsuarioEditUssForm.markFormControlsAsPristine(this.formAvatar)
                    UsuarioEditUssForm.markFormControlsAsUntouched(this.formAvatar)
                    this.cdr.detectChanges()
                    this.loadingAvatar = true
                }, 150)

                return response
            })
    }

    onLoadProfileImage() {
        if (this.loadingAvatar) {
            this.loadingLayoutAvatar.ready()
            this.loadingAvatar = false
        }
    }

    save() {
        UsuarioEditUssForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.usuariosService.update(this.usuarioEdit.id, this.usuarioEdit.toUsuario()).then(response => {
                if (!this.form.controls["email"].pristine) {
                    this.usuarioEmailModalComponent.open()
                }
                this.authService.setUserData(response)
                this.router.navigate(["usuarios/" + this.usuarioEdit.id])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.usuarioEdit = this.oUsuarioEdit.clone()
            UsuarioEditUssForm.markFormControlsAsPristine(this.form)
            UsuarioEditUssForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }

    toggleUserInfo(event) {
        event.stopPropagation()
        this.edit_user_info = !this.edit_user_info
    }

    toggleAvatarEdit(event) {
        ;(document.getElementById("avatarInput").getElementsByClassName("inputfile-label")[0] as HTMLElement).click()
        event.stopPropagation()
    }

    cancelNewAvatar() {
        this.usuarioAvatarEdit["file"] = undefined
    }

    showEditPassword() {
        this.edit_password ? (this.edit_password = false) : (this.edit_password = true)
    }
}
