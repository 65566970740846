<p *ngIf="notFound">El audiovisual no fue encontrado</p>
<div class="preview" id="audiovisual{{ audiovisual.id }}" *ngIf="enablePreview">
    <ng-template [ngIf]="isPdf || isVideo || isGame || isHTML">
        <model-plain-value
            [params]="params[this.audiovisual.tipoAudiovisual.tipoAudiovisual].params"
            [value]="audiovisual"
            [key]="params[this.audiovisual.tipoAudiovisual.tipoAudiovisual].key"
            [class.html-content]="isHTML"
        ></model-plain-value>
    </ng-template>
</div>
