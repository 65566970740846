import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { UtilModule, FormModule, ModelViewModule, PaginatorModule, TranslationsModule } from "@puntaje/shared/core"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { CUITextsModule, CUIInputsModule } from "@puntaje/shared/cui"

import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"

import { PlanPersonalesBuscadorComponent } from "./plan-personales-buscador/plan-personales-buscador.component"
import { PlanPersonalesToolNavbarComponent } from "./plan_personales.toolnavbar.component"
import { PlanPersonalAlumnoComponent } from "./plan-personal-alumno/plan-personal-alumno.component"
import { PlanesPersonalesComponent } from "./planes_personales.component"
import { PlanesPersonalesSidemenuComponent } from "./planes_personales_sidemenu.component"
import { PreguntasDiagnosticoComponent } from "./preguntas_diagnostico.component"
import { PlanPersonalSesionesComponent } from "./plan_personal_sesiones.component"
import { PlanPersonalSesionBoxComponent } from "./plan_personal_sesion_box.component"
import { PlanPersonalSesionComponent } from "./plan_personal_sesion.component"
import { PlanPersonalNavbarComponent } from "./plan_personal_navbar.component"
import { PlanPersonalSesionMenuComponent } from "./plan_personal_sesion_menu.component"
import { PlanPersonalDiagnosticoBoxComponent } from "./plan_personal_diagnostico_box.component"
import {
    PlanPersonalEvaluacionComponent,
    PlanPersonalEvaluacionComponentTags
} from "./plan_personal_evaluacion.component"
import { GenerarEvaluacionDiagnosticoComponent } from "./generar_evaluacion_diagnostico.component"
import { PlanesPersonalesShowcaseBoxComponent } from "./planes_personales_cb_showcasebox.component"
import { GenerarPlanPersonalProfesorComponent } from "./generar_plan_personal_profesor.component"
import { PlanPersonalSesionesProfesorComponent } from "./plan_personal_sesiones_profesor.component"
import { PlanPersonalSesionBoxProfesorComponent } from "./plan_personal_sesion_box_profesor.component"
import { PlanPersonalSesionProfesorComponent } from "./plan_personal_sesion_profesor.component"
import { OpcionesPlanPersonalComponent } from "./opciones_plan_personal.component"
import { CompartirPlanPersonalProfesorComponent } from "./compartir_plan_personal_profesor.component"
import { HistorialPlanesPersonalesProfesorComponent } from "./historial-planes-personales-profesor/historial_planes_personales_profesor.component"
import { PlanesPersonalesCompartidosComponent } from "./planes_personales_compartidos.component"
import { PlanesPersonalesNoCompartidosComponent } from "./planes_personales_no_compartidos.component"
import { ConfigurarClasificacionesPlanPersonalComponent } from "./configurar_clasificaciones_plan_personal.component"
import { ClasificacionesCheckboxesPlanPersonalComponent } from "./clasificaciones_checkboxes_plan_personal.component"

import { PopoverModule } from "ngx-bootstrap/popover"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { TareasCompartidasAlumnosComponent } from "./tareas-compartidas-alumnos/tareas-compartidas-alumnos.component"
import { ReforzamientosCompartidosAlumnosComponent } from "./reforzamientos-compartidos-alumnos/reforzamientos-compartidos-alumnos.component"
import { EstadisticasPlanesPersonalesComponent } from "./estadisticas-planes-personales/estadisticas-planes-personales.component"
import { FiltroEstadisticaPlanesPersonalesComponent } from "./filtro-estadistica-planes-personales/filtro-estadistica-planes-personales.component"

import { DatosGeneralesPlanPersonalComponent } from "./datos_generales_plan_personal.component"
import { DatosGeneralesGeneradorPlanPersonalComponent } from "./datos_generales_generador_plan_personal.component"

import { FiltroEstadisticasPlanPersonalService } from "./filtro-estadistica-planes-personales/filtro_estadisticas_plan_personal.service"
import { PlanPersonalAvanceGrupoUsuarioComponent } from "./plan-personal-avance-grupo-usuario/plan-personal-avance-grupo-usuario.component"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { PlanPersonalSelectorBoxComponent } from "./plan-personal-selector-box/plan-personal-selector-box.component"
import { PlanesPersonalesAsignaturaGridComponent } from "./planes-personales-asignatura-grid/planes-personales-asignatura-grid.component"
import { RefuerzosShowcaseBoxComponent } from "./refuerzos-showcase-box/refuerzos-showcase-box.component"
import { DetallePlanAlumnoComponent } from "./detalle-plan-alumno/detalle-plan-alumno.component"

@NgModule()
export class PlanPersonalesServices {}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        ModelViewModule,
        PaginatorModule,
        RouterModule,
        MaterialesModule,
        ProgressbarModule,
        Angular2FontawesomeModule,
        EnsayosModule,
        SharedModule,
        InstrumentosModule,
        TooltipModule,
        PopoverModule,
        NebuModule,
        TranslationsModule,
        CUITextsModule,
        CUIInputsModule
    ],
    declarations: [
        PlanesPersonalesComponent,
        PlanesPersonalesSidemenuComponent,
        PlanPersonalesBuscadorComponent,
        PreguntasDiagnosticoComponent,
        PlanPersonalSesionesComponent,
        PlanPersonalSesionBoxComponent,
        PlanPersonalSesionComponent,
        PlanPersonalNavbarComponent,
        PlanPersonalSesionMenuComponent,
        PlanPersonalDiagnosticoBoxComponent,
        PlanPersonalEvaluacionComponent,
        PlanPersonalEvaluacionComponentTags,
        GenerarEvaluacionDiagnosticoComponent,
        PlanesPersonalesShowcaseBoxComponent,
        GenerarPlanPersonalProfesorComponent,
        PlanPersonalSesionesProfesorComponent,
        PlanPersonalSesionBoxProfesorComponent,
        PlanPersonalSesionProfesorComponent,
        OpcionesPlanPersonalComponent,
        CompartirPlanPersonalProfesorComponent,
        HistorialPlanesPersonalesProfesorComponent,
        PlanesPersonalesCompartidosComponent,
        PlanesPersonalesNoCompartidosComponent,
        ConfigurarClasificacionesPlanPersonalComponent,
        ClasificacionesCheckboxesPlanPersonalComponent,
        TareasCompartidasAlumnosComponent,
        ReforzamientosCompartidosAlumnosComponent,
        DatosGeneralesPlanPersonalComponent,
        DatosGeneralesGeneradorPlanPersonalComponent,
        EstadisticasPlanesPersonalesComponent,
        FiltroEstadisticaPlanesPersonalesComponent,
        PlanPersonalAvanceGrupoUsuarioComponent,
        PlanPersonalSelectorBoxComponent,
        PlanPersonalAlumnoComponent,
        PlanesPersonalesAsignaturaGridComponent,
        RefuerzosShowcaseBoxComponent,
        PlanPersonalesToolNavbarComponent,
        DetallePlanAlumnoComponent
    ],
    exports: [
        PlanesPersonalesComponent,
        PlanesPersonalesSidemenuComponent,
        PreguntasDiagnosticoComponent,
        PlanPersonalSesionesComponent,
        PlanPersonalSesionBoxComponent,
        PlanPersonalSesionComponent,
        PlanPersonalNavbarComponent,
        PlanPersonalSesionMenuComponent,
        PlanPersonalDiagnosticoBoxComponent,
        PlanPersonalEvaluacionComponent,
        PlanPersonalEvaluacionComponentTags,
        GenerarEvaluacionDiagnosticoComponent,
        PlanesPersonalesShowcaseBoxComponent,
        GenerarPlanPersonalProfesorComponent,
        HistorialPlanesPersonalesProfesorComponent,
        PlanPersonalSesionesProfesorComponent,
        PlanPersonalSesionBoxProfesorComponent,
        PlanPersonalSesionProfesorComponent,
        OpcionesPlanPersonalComponent,
        CompartirPlanPersonalProfesorComponent,
        PlanesPersonalesCompartidosComponent,
        PlanesPersonalesNoCompartidosComponent,
        ConfigurarClasificacionesPlanPersonalComponent,
        ClasificacionesCheckboxesPlanPersonalComponent,
        TareasCompartidasAlumnosComponent,
        ReforzamientosCompartidosAlumnosComponent,
        DatosGeneralesPlanPersonalComponent,
        DatosGeneralesGeneradorPlanPersonalComponent,
        EstadisticasPlanesPersonalesComponent,
        FiltroEstadisticaPlanesPersonalesComponent,
        PlanPersonalAvanceGrupoUsuarioComponent,
        PlanPersonalSelectorBoxComponent,
        PlanPersonalAlumnoComponent,
        PlanesPersonalesAsignaturaGridComponent,
        RefuerzosShowcaseBoxComponent,
        PlanPersonalesToolNavbarComponent,
        PlanPersonalesBuscadorComponent,
        DetallePlanAlumnoComponent
    ]
})
export class PlanPersonalesModule {
    public static services(): ModuleWithProviders<PlanPersonalesServices> {
        return {
            ngModule: PlanPersonalesServices,
            providers: [FiltroEstadisticasPlanPersonalService]
        }
    }
}
