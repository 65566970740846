import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router"
import { Observable } from "rxjs"
import { Material } from "../materiales.model"
import { Materiales } from "../materiales.service"
import { NebuAuthService } from "../nebu_auth.service"
import { SessionService } from "@puntaje/shared/core"

@Injectable()
export class MaterialGuard implements CanActivate {
    constructor(protected sessionService: SessionService, protected materialesService: Materiales) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.sessionService.getPerfiles().includes("Super Administrador")) return true
        if (!this.sessionService.getPerfiles().includes("Docente")) return false

        return this.materialesService.find(route.params["id"]).then((material: Material) => {
            return this.sessionService.getUserData().id == material.profesor_id
        })
    }
}
