import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Clasificacion } from "@puntaje/nebulosa/api-services"
import { PlanPersonalContenido } from "./plan_personal_contenidos.model"
import { PlanPersonalCiclo } from "./plan_personal_ciclos.model"
import { PlanPersonalSesionGrupoUsuario } from "./plan_personal_sesion_grupo_usuarios.model"

export class PlanPersonalSesion extends BaseModel {
    public clasificacion_id: number
    public clasificaciones: string
    public plan_personal_ciclo_id: number
    public calificacion: number
    public orden: number
    public avance: number
    public seguimiento: boolean
    public actual: boolean
    public estado: string // 'activa', 'pendiente', 'completada'

    public fecha_inicial: string
    public fecha_final: string
    public activa: boolean
    public fecha_pauta: string
    public plan_personal_sesion: string

    created_at: string | Date

    @SaveType(() => PlanPersonalCiclo) public plan_personal_ciclo: PlanPersonalCiclo
    @SaveType(() => Clasificacion) public clasificacion: Clasificacion
    @SaveType(() => Clasificacion) public nebu_clasificaciones: Clasificacion[]
    @SaveType(() => PlanPersonalContenido) public plan_personal_contenidos: PlanPersonalContenido[]
    @SaveType(() => PlanPersonalSesionGrupoUsuario)
    public plan_personal_sesion_grupo_usuarios: PlanPersonalSesionGrupoUsuario[]

    public checkAvance() {
        let avance = false
        if (this.plan_personal_contenidos) {
            avance = this.plan_personal_contenidos.reduce((accc: boolean, ppc) => {
                if (ppc.tipo == "Material") {
                    return accc && ppc.plan_personal_material_instancias.length > 0
                } else if (ppc.tipo == "Evaluacion") {
                    return accc && ppc.plan_personal_evaluacion_instancias.length > 0
                }

                return accc
            }, true)
        }

        return avance
    }

    public checkAvanceByContenido() {
        let avance: { [id: number]: boolean } = {}
        if (this.plan_personal_contenidos) {
            this.plan_personal_contenidos.forEach(ppc => {
                if (ppc.tipo == "Material") {
                    avance[ppc.id] = ppc.plan_personal_material_instancias.length > 0
                } else if (ppc.tipo == "Evaluacion") {
                    avance[ppc.id] = ppc.plan_personal_evaluacion_instancias.length > 0
                }
            })
        }

        return avance
    }

    public progressPercentage(avanceByContenido: { [key: number]: boolean } = null) {
        if (!avanceByContenido) {
            avanceByContenido = this.checkAvanceByContenido()
        }

        let progress = Object.values(avanceByContenido).reduce((acc, avance) => {
            return acc + (avance ? 1 : 0)
        }, 0)

        return (progress / this.plan_personal_contenidos.length) * 100
    }

    public toString() {
        return this.plan_personal_sesion
    }
}
