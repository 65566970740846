<div class="clearfix" *ngIf="curso && cursos && cursos.length > 0">
    <div class="selectCursoWrap">
        <label class="labelCurso">
            {{ "materiales.curso_select.seleccione" | t }}&nbsp;{{ config.plataforma.grupoUsuarioAlias }}:
        </label>
        <div class="selectCurso">
            <select [hidden]="hidden" class="form-control" [(ngModel)]="curso" (ngModelChange)="cursoChanges()">
                <!--<option [ngValue]="undefined">
					-- Seleccione --
				</option>-->
                <option *ngFor="let c of cursos" [ngValue]="c.id" [selected]="curso == c.id">
                    {{ c.label }}
                </option>
            </select>
        </div>
    </div>
</div>
