import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"

export class RevisionRecurso extends BaseModel {
    public static className: string = "RevisionRecurso"
    public usuario_id: number
    public recurso_type: string
    public recurso_id: number

    public created_at: string

    @SaveType(() => Usuario) usuario: Usuario
}
