import { BaseModel, SaveType, Validations } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"

export class Amigo extends BaseModel {
    public usuario_id: number
    public usuario_amigo_id: number
    public estado: number

    @SaveType() public usuario: Usuario
    @SaveType() public usuario_amigo: Usuario

    constructor() {
        super()
        this.usuario = new Usuario()
        this.usuario_amigo = new Usuario()
    }

    public static global_validations = [Validations.equalPasswords(["password", "password_confirmation"])]

    public toString() {
        return "(" + this.usuario_id + "," + this.usuario_amigo_id + ")"
    }
}
