import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core"
import { Router, ActivatedRoute, Params } from "@angular/router"
import { Subscription, combineLatest } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import {
    State,
    SetEvaluacionTipos,
    selectSelectedEvaluacionTipo,
    selectAsignaturasByEvaluacionTipo,
    selectEvaluacionTiposEvaluacionTipos,
    selectSelectedAsignatura,
    SetSelectedAsignaturaEvaluacionTipo,
    SetSelectedEvaluacionTipo
} from "@puntaje/puntaje/store"
import { map, filter } from "rxjs/operators"

@Component({
    selector: "menu-evaluacion-tipo-asignaturas",
    templateUrl: "./menu-evaluacion-tipo-asignaturas.component.html",
    styleUrls: ["./menu-evaluacion-tipo-asignaturas.component.scss"]
})
export class MenuEvaluacionTipoAsignaturasComponent implements OnInit, OnDestroy {
    @Input() soloMaterialVisible: boolean
    @Input() showAsignaturas: boolean = true
    @Input() evaluacionTipos = []
    @Input() showTitleOverMenu: boolean = true
    @Input() diagnostico: boolean = false
    @Input() allTipoInstrumentos = false
    @Input() onlyWithPlanesClase = false

    asignaturaId: number
    sub: Subscription
    subAsigEvalTipo: Subscription

    asignatura$ = this.store.pipe(select(selectSelectedAsignatura))
    evaluacionTipos$ = combineLatest([
        this.store.pipe(select(selectEvaluacionTiposEvaluacionTipos)),
        this.store.pipe(select(selectAsignaturasByEvaluacionTipo))
    ]).pipe(
        map(([evaluacionTipos, asignaturasByEvaluacionTipo]) =>
            evaluacionTipos.filter(et => asignaturasByEvaluacionTipo?.[et]?.length > 0)
        )
    )
    evaluacionTipo$ = this.store.pipe(select(selectSelectedEvaluacionTipo))
    asignaturasByEvaluacionTipo$ = this.store.pipe(select(selectAsignaturasByEvaluacionTipo))

    asignaturas$ = combineLatest([
        this.asignaturasByEvaluacionTipo$.pipe(filter(x => !!x)),
        this.evaluacionTipo$.pipe(filter(x => !!x))
    ]).pipe(map(([asignaturasByEvaluacionTipo, evaluacionTipo]) => asignaturasByEvaluacionTipo[evaluacionTipo] || []))

    queryParams: Params

    constructor(protected route: ActivatedRoute, protected store: Store<State>, protected router: Router) {}

    ngOnInit() {
        let evaluacionTiposOld: string[]
        let evaluacionTipoOld: string

        this.sub = combineLatest([this.route.queryParams, this.asignaturasByEvaluacionTipo$]).subscribe(
            ([queryParams, asignaturasByEvaluacionTipo]) => {
                this.queryParams = queryParams

                let evaluacionTipos
                if (this.evaluacionTipos.length > 0) {
                    evaluacionTipos = this.evaluacionTipos
                } else if (queryParams["evaluacion_tipos"]) {
                    evaluacionTipos = queryParams["evaluacion_tipos"]
                } else if (config.plataforma.evaluacionTiposMenu && config.plataforma.evaluacionTiposMenu.length > 0) {
                    evaluacionTipos = config.plataforma.evaluacionTiposMenu
                } else if (config.plataforma.evaluacionTipos && config.plataforma.evaluacionTipos.length > 0) {
                    evaluacionTipos = config.plataforma.evaluacionTipos

                    if (!this.allTipoInstrumentos) {
                        evaluacionTipos = evaluacionTipos.filter(
                            et =>
                                (!this.diagnostico && et.indexOf("diagnostico") == -1) ||
                                (this.diagnostico && et.indexOf("diagnostico") >= 0)
                        )
                    }
                }

                if (!Array.isArray(evaluacionTipos)) {
                    evaluacionTipos = [evaluacionTipos]
                }

                if (queryParams["asignatura_id"]) this.asignaturaId = +queryParams["asignatura_id"]

                evaluacionTipos = evaluacionTipos.filter(et => asignaturasByEvaluacionTipo?.[et]?.length > 0)

                if (JSON.stringify(evaluacionTipos) != JSON.stringify(evaluacionTiposOld)) {
                    this.store.dispatch(new SetEvaluacionTipos({ evaluacionTipos }))
                }

                if (queryParams["evaluacion_tipo"] && queryParams["evaluacion_tipo"] != evaluacionTipoOld) {
                    this.store.dispatch(
                        new SetSelectedEvaluacionTipo({ selectedEvaluacionTipo: queryParams["evaluacion_tipo"] })
                    )
                }

                evaluacionTiposOld = evaluacionTipos
                evaluacionTipoOld = queryParams["evaluacion_tipo"]
            }
        )

        this.subAsigEvalTipo = combineLatest([this.asignatura$, this.evaluacionTipo$]).subscribe(
            ([asignatura, evaluacionTipo]) => {
                if (!!asignatura && !!evaluacionTipo) {
                    const asignaturaEvaluacionTipo = asignatura.confByEvaluacionTipo[evaluacionTipo]
                    this.store.dispatch(new SetSelectedAsignaturaEvaluacionTipo({ asignaturaEvaluacionTipo }))

                    if (
                        +this.queryParams["asignatura_id"] != asignatura.id ||
                        this.queryParams["evaluacion_tipo"] != evaluacionTipo
                    ) {
                        const replaceUrl = !this.queryParams["asignatura_id"] || !this.queryParams["evaluacion_tipo"]

                        this.router.navigate([], {
                            queryParams: {
                                ...this.queryParams,
                                asignatura_id: asignatura.id,
                                evaluacion_tipo: evaluacionTipo
                            },
                            replaceUrl
                        })
                    }
                }
            }
        )
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
        this.subAsigEvalTipo.unsubscribe()
    }
}
