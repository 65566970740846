import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Pregunta } from "./preguntas.model"
import { Lote } from "./lotes.model"

export class LotePregunta extends BaseModel {
    public static className: string = "LotePregunta"
    public lote_id: number
    public pregunta_id: number
    public orden: number
    public created_at: Date
    public updated_at: Date

    @SaveType(() => Pregunta) public pregunta: Pregunta
    @SaveType(() => Lote) public lote: Lote

    public static get model_params(): any {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            lote_id: { label: "Lote id", type: "text", visible: true },
            pregunta_id: { label: "Pregunta id", type: "text", visible: true },
            orden: { label: "Orden", type: "text", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: false },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: false },
            pregunta: { type: "model" }
        }
    }

    public static get show_params(): any {
        return {
            id: { label: "Id", type: "text", tableVisible: true },
            orden: { label: "Orden", type: "text", tableVisible: true },
            pregunta: { type: "model", class: Pregunta },
            created_at: { label: "Fecha de creación", type: "date", tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: "date", tableVisible: true }
        }
    }

    public toString() {
        return this.id.toString()
    }
}
