<div *ngIf="loaded" class="responder-container">
    <div class="clearfix">
        <cui-button buttonType="cancel" class="btn_style_2 pull-right" (click)="cancelar()">
            {{ "dudas.duda_respuesta_new.cancelar" | t }}
        </cui-button>
    </div>
    <form novalidate [formGroup]="form">
        <form-input
            [form]="form"
            [params]="params.duda_respuesta"
            [(value)]="dudaRespuesta.duda_respuesta"
            key="duda_respuesta"
        ></form-input>
    </form>
    <div class="clearfix">
        <cui-button *ngIf="!edit" class="btn_style pull-right" (click)="responder()">
            {{ "dudas.duda_respuesta_new.contestar" | t }}
        </cui-button>
        <cui-button *ngIf="edit" class="btn_style pull-right" (click)="responder()">
            {{ "dudas.duda_respuesta_new.guardar" | t }}
        </cui-button>
    </div>
</div>
