import { Component, Input, OnInit, EventEmitter } from "@angular/core"
import { Evaluacion } from "@puntaje/puntaje/api-services"
import { combineLatest, Subscription } from "rxjs"
import { filter, map } from "rxjs/operators"
import { RealizarEnsayoService } from "./realizar_ensayo.service"

@Component({
    selector: "evaluacion-menu-preguntas",
    templateUrl: "evaluacion_menu_preguntas.component.html",
    styleUrls: ["evaluacion_menu_preguntas.component.scss"]
})
export class EvaluacionMenuPreguntasComponent implements OnInit {
    @Input() startingPosition: boolean = false
    @Input() onSelectPregunta: EventEmitter<any>
    numeros: number[][] = []
    showComponent: boolean = true
    pageCount: number = 0
    pages: number[] = []
    currentPage: number = 1
    numbersPerPage: number = 16
    progressEvaluacion: number = 0
    progressPercentage: number = 0
    progressArray: boolean[] = []

    selectPreguntaSub: Subscription
    readySub: Subscription

    // no se usa como input, no se saca para no matar el resto
    @Input() evaluacion: Evaluacion

    constructor(public realizarEnsayoService: RealizarEnsayoService) {}

    ngOnInit() {
        this.readySub = this.realizarEnsayoService.ready.subscribe(() => {
            this.evaluacion = this.realizarEnsayoService.evaluacion
            this.pageCount = Math.ceil(this.evaluacion.instrumento.numero_preguntas / this.numbersPerPage)
            this.pages = Array(this.pageCount)
                .fill(0)
                .map((x, i) => i + 1)
            this.numeros = []
            for (let j of this.pages) {
                this.numeros[j - 1] = Array<number>(
                    Math.min(
                        this.numbersPerPage,
                        this.evaluacion.instrumento.numero_preguntas - (j - 1) * this.numbersPerPage
                    )
                )
                    .fill((j - 1) * this.numbersPerPage)
                    .map((x, i) => x + i + 1)
            }
            this.progressArray = Array(this.evaluacion.instrumento.numero_preguntas).fill(false)
        })

        let observable: any
        if (this.onSelectPregunta) {
            observable = this.onSelectPregunta
            this.selectPreguntaSub = observable.subscribe(({ index: index, omitida: omitida }) => {
                this.markProgress(index, omitida)
            })
        } else {
            observable = this.realizarEnsayoService.respuestas$
            this.selectPreguntaSub = combineLatest(observable, this.realizarEnsayoService.ready)
                .pipe(map(([respuestas, _]) => respuestas))
                .subscribe(respuestas => {
                    this.markProgressFromRespuestas(respuestas)
                })
        }
    }

    toggleComponent() {
        this.showComponent = !this.showComponent
    }

    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--
        }
    }

    nextPage() {
        if (this.currentPage < this.pageCount) {
            this.currentPage++
        }
    }

    markProgress(index: number, omitida: boolean) {
        this.progressArray[index] = !omitida
        this.calculateProgress()
    }

    markProgressFromRespuestas(respuestas) {
        this.progressArray = respuestas.map(respuesta => respuesta.every(r => !!r))
        this.calculateProgress()
    }

    calculateProgress() {
        this.progressEvaluacion = 0
        for (let b of this.progressArray) {
            this.progressEvaluacion += b ? 1 : 0
        }
        this.progressPercentage = Math.round(
            (this.progressEvaluacion * 100) / this.evaluacion.instrumento.numero_preguntas
        )
    }

    ngOnDestroy() {
        this.selectPreguntaSub.unsubscribe()
        this.readySub.unsubscribe()
    }
}
