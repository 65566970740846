import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { UtilModule, PaginatorModule, TranslationsModule, FilterModule } from "@puntaje/shared/core"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { CUIContainersModule, CUIInputsModule } from "@puntaje/shared/cui"

import { NotificacionesComponent } from "./notificaciones.component"
import { NotificacionAccionesService } from "./notificacion_acciones.service"
import { AlertaNotificacionesComponent } from "./alerta_notificaciones.component"
import { NotificacionEvaluacionComponent } from "./tipos/notificacion_evaluacion.component"
import { NotificacionDudaComponent } from "./tipos/notificacion-duda/notificacion_duda.component"
import { NotificacionProComponent } from "./tipos/notificacion_pro.component"
import { NotificacionesPendingService } from "./notificaciones_pending.service"
import { NotificacionesShowcaseBoxComponent } from "./notificaciones_showcasebox.component"
import { NotificacionPlanPersonalTareaComponent } from "./tipos/notificacion_plan_personal_tarea.component"
import { NotificacionPlanPersonalReforzamientoComponent } from "./tipos/notificacion_plan_personal_reforzamiento.component"
import { NotificacionLayoutComponent, NotificacionLayoutComponentTags } from "./tipos/notificacion_layout.component"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { NotificacionPlanPersonalPlanificacionComponent } from "./tipos/notificacion_plan_personal_planificacion.component"
import { NotificacionRetroalimentacionComponent } from "./tipos/notificacion-retroalimentacion/notificacion-retroalimentacion.component"
import { NotificacionesOnNavbarComponent } from "./notifiaciones-on-navbar/notificaciones-on-navbar.component"
import { NotificacionPlanPersonalSesionRecordatorioComponent } from "./tipos/notificacion-plan-personal-sesion-recordatorio/notificacion-plan-personal-sesion-recordatorio.component"
import { NotificacionPlanClaseProfesorComponent } from "./tipos/notificacion-plan-clase-profesor/notificacion-plan-clase-profesor.component"

@NgModule()
export class NotificacionesServices {}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        UtilModule,
        Angular2FontawesomeModule,
        CommonsLayoutsModule,
        PaginatorModule,
        NebuModule,
        TranslationsModule,
        FilterModule,
        CUIContainersModule,
        CUIInputsModule
    ],
    exports: [
        NotificacionesComponent,
        AlertaNotificacionesComponent,
        NotificacionEvaluacionComponent,
        NotificacionDudaComponent,
        NotificacionesShowcaseBoxComponent,
        NotificacionPlanPersonalTareaComponent,
        NotificacionLayoutComponent,
        NotificacionLayoutComponentTags,
        NotificacionPlanPersonalReforzamientoComponent,
        NotificacionPlanPersonalPlanificacionComponent,
        NotificacionProComponent,
        NotificacionesOnNavbarComponent
    ],
    declarations: [
        NotificacionesComponent,
        AlertaNotificacionesComponent,
        NotificacionEvaluacionComponent,
        NotificacionDudaComponent,
        NotificacionesShowcaseBoxComponent,
        NotificacionPlanPersonalTareaComponent,
        NotificacionLayoutComponent,
        NotificacionLayoutComponentTags,
        NotificacionPlanPersonalReforzamientoComponent,
        NotificacionPlanPersonalPlanificacionComponent,
        NotificacionProComponent,
        NotificacionRetroalimentacionComponent,
        NotificacionesOnNavbarComponent,
        NotificacionPlanPersonalSesionRecordatorioComponent,
        NotificacionPlanClaseProfesorComponent
    ]
})
export class NotificacionesModule {
    public static services(): ModuleWithProviders<NotificacionesServices> {
        return {
            ngModule: NotificacionesServices,
            providers: [NotificacionesPendingService, NotificacionAccionesService]
        }
    }
}
