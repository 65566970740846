import { NgModule, ModuleWithProviders } from "@angular/core"
import { HttpClientModule } from "@angular/common/http"

import { BaseService } from "./base.service"
import { BaseModel } from "./base.model"
import { BaseForm } from "./base.form"
import { S3 } from "./s3.model"
import { S3Service } from "./s3.service"

@NgModule({
    imports: [HttpClientModule],
    declarations: [],
    exports: []
})
export class BaseModule {
    static forRoot(): ModuleWithProviders<BaseModule> {
        return {
            ngModule: BaseModule,
            providers: [BaseService, BaseModel, BaseForm, S3, S3Service]
        }
    }
}
