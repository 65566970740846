import { Component } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
import { select, Store } from "@ngrx/store"
import { selectSelectedEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "progreso_alumno.component.html",
    styleUrls: ["progreso_alumno.component.scss"]
})
export class ProgresoAlumnoComponent {
    asignatura: Asignatura
    cursoId: number = 1
    asignaturaId: number
    private sub: Subscription
    subData: Subscription
    enableResponsive: boolean = false
    tipoInstrumento: string
    config: typeof config

    enableDesempenoAsignaturas = true
    nombreClasificacionTipo: string = "eje"
    evaluacionTipos: string[] = []

    constructor(protected titleService: TitleService, protected route: ActivatedRoute, protected store: Store<State>) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas.progreso_alumno")
        this.sub = this.route.queryParams.subscribe(queryParams => {
            this.tipoInstrumento = queryParams["tipo_instrumento"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.nombreClasificacionTipo = data.nombreClasificacionTipo ?? this.nombreClasificacionTipo
            this.enableDesempenoAsignaturas = data.enableDesempenoAsignaturas ?? this.enableDesempenoAsignaturas
            this.evaluacionTipos = data.evaluacionTipos ?? this.evaluacionTipos
        })

        this.config = config
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
