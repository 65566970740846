import { PostComponent, PostsComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

export const bannersRoutes: Routes = [
    {
        path: "blog",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PostsComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "posts/:slug",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PostComponent, canActivate: [AuthGuard] }]
    }
]

export const alumnosBlogRouting = RouterModule.forChild(bannersRoutes)
