import { Component, OnInit, OnDestroy } from "@angular/core"
import { NotificacionesPendingService } from "./notificaciones_pending.service"
import { Subscription } from "rxjs"

@Component({
    selector: "alerta-notificaciones",
    template: `
        <div *ngIf="contador > 0" class="alertContainer">{{ contador }}</div>
    `,
    styleUrls: ["alerta_notificaciones.component.scss"]
})
export class AlertaNotificacionesComponent implements OnInit, OnDestroy {
    contador: number = 0
    contadorSubscription: Subscription

    constructor(private notificacionesPendingService: NotificacionesPendingService) {}

    ngOnInit() {
        this.contador = this.notificacionesPendingService.notificacionesPendingCount
        this.contadorSubscription = this.notificacionesPendingService.notificacionesCountSubject.subscribe(
            contador => (this.contador = contador)
        )
    }

    ngOnDestroy() {
        this.contadorSubscription.unsubscribe()
    }
}
