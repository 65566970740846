<ng-container *ngIf="pregunta && !disableIfSelectedEmpty">
    <h4 class="subtitulo titulo-clasificaciones">
        <div class="icon-clasificaciones"><span class="glyphicon glyphicon-list" aria-hidden="true"></span></div>
        Clasificaciones
    </h4>
    <table class="table tabla-clasificaciones">
        <tbody>
            <ng-container *ngFor="let c of clasificaciones | keys">
                <tr *ngIf="clasificaciones[c].enabled && clasificaciones[c].lista.length > 0">
                    <th>{{ clasificaciones[c].clasificacion }}</th>
                    <td>
                        <span class="clasificacion" *ngFor="let cl of clasificaciones[c].lista">
                            {{ cl.clasificacion }}
                        </span>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</ng-container>
