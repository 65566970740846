<ng-container *ngIf="evaluacionTipos$ | async as evaluacionTipos">
    <ng-container *ngIf="asignaturas$ | async as asignaturas">
        <div class="menus-container" [class.solo-asignaturas]="evaluacionTipos.length == 1">
            <div
                class="menu menu-evaluacion-tipo"
                [class.use-space]="!showAsignaturas"
                *ngIf="evaluacionTipos.length > 1"
            >
                <span *ngIf="showTitleOverMenu" class="title">{{ "estudiar.plan_estudios.evaluacion" | t }}</span>
                <menu-evaluacion-tipo></menu-evaluacion-tipo>
            </div>
            <ng-container *ngIf="showAsignaturas">
                <div class="separator" *ngIf="evaluacionTipos.length > 1 && asignaturas.length > 0"></div>
                <div class="menu menu-asignaturas" *ngIf="asignaturas.length > 0">
                    <span *ngIf="showTitleOverMenu" class="title">{{ "estudiar.plan_estudios.asignaturas" | t }}</span>
                    <menu-asignaturas-horizontal
                        class="m-a-h-theme-b no-margin"
                        [asignaturaId]="asignaturaId"
                        [soloMaterialVisible]="soloMaterialVisible"
                        [onlyWithPlanesClase]="onlyWithPlanesClase"
                    ></menu-asignaturas-horizontal>
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
