import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    Injector,
    ChangeDetectorRef,
    Directive
} from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Usuario,
    Usuarios,
    UsuariosCompletarRegistroForm,
    GrupoUsuarios,
    GrupoUsuario,
    Establecimientos,
    Establecimiento,
    Lugar,
    Lugares
} from "@puntaje/puntaje/api-services"
import { Login } from "@puntaje/puntaje/services"
import { AuthService, GenericModalComponent, BaseForm, SessionService } from "@puntaje/shared/core"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { NebuAuthService } from "@puntaje/nebulosa/api-services"
import { CarrerasAuthService } from "@puntaje/carreras/api-services"
import { AchievementsAuthService } from "@puntaje/achievements/api-services"

@Directive()
export class BaseRegisterCompletarComponent implements OnInit {
    @Input() usuarioId: number
    formType: typeof BaseForm = UsuariosCompletarRegistroForm

    withPoliticasPrivacidad: boolean = config.registro && config.registro.enablePoliticasPrivacidad

    usuario: Usuario
    oUsuario: Usuario
    usuarioCompletarRegistroForm: BaseForm
    params: any = this.formType.formParams
    form: UntypedFormGroup
    save_button_text = "Guardar"

    saved: boolean = false
    mailChanged: boolean = false

    grupoUsuario: GrupoUsuario

    pais: string = config.plataforma.pais
    usuarioPaisParams: any = this.params["usuario_" + this.pais].class.formParams
    usuarioPaisForm: UntypedFormGroup
    lugar: Lugar
    lugarColegio: Lugar
    filteredLugarColegio: Lugar[]
    filteredEstablecimientos: Establecimiento[]

    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()
    tituloRegistro: string = "¡Ya casi estás listo!"
    identificadorUsuarioValue: string

    saving = false

    constructor(
        protected usuariosService: Usuarios,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected router: Router,
        protected injector: Injector,
        protected grupoUsuariosService: GrupoUsuarios,
        protected cdr: ChangeDetectorRef,
        protected lugaresService: Lugares,
        protected establecimientosService: Establecimientos
    ) {}

    ngOnInit() {
        this.usuario = new Usuario()
        this.usuario["usuario_" + this.pais] = new Usuario["usuario_" + this.pais]()
        this.form = this.formType.getForm(this.usuario, null, this.injector)
        this.usuarioPaisForm = this.form.controls["usuario_" + this.pais] as UntypedFormGroup
        this.usuariosService.find(this.usuarioId, { include: "usuario_" + this.pais }).then((usuario: Usuario) => {
            this.identificadorUsuarioValue =
                this.pais == "mexico"
                    ? usuario["usuario_" + this.pais].numero_unico
                    : usuario["usuario_" + this.pais].rut
            const params = {
                propios: 1,
                activo: 1,
                grupo_usuario_usuario: { propietario: 0 },
                establecimiento: { activo: 1 },
                include: "[establecimiento]",
                sort_by: "grupo_usuarios.id",
                order: "desc"
            }
            this.grupoUsuariosService.where(params).then(grupoUsuarios => {
                this.form = null
                this.cdr.detectChanges()
                this.usuario = usuario
                usuario["usuario_" + this.pais] =
                    usuario["usuario_" + this.pais] || new Usuario["usuario_" + this.pais]()

                this.oUsuario = this.usuario.clone()
                this.form = this.formType.getForm(this.usuario, this.params, this.injector)
                this.usuarioPaisForm = this.form.controls["usuario_" + this.pais] as UntypedFormGroup

                this.grupoUsuario = grupoUsuarios[0]
                this.cdr.detectChanges()
            })
        })
    }

    save(): Promise<void> | void {
        this.formType.markFormControlsAsTouched(this.form)
        this.formType.markFormControlsAsDirty(this.form)
        this.cdr.detectChanges()

        if (this.form.valid) {
            this.saving = true
            this.cdr.detectChanges()
            this.usuario.nomina = false
            return this.usuariosService.update(this.usuarioId, this.usuario).then(usuario => {
                this.onTitleChange.emit(this.tituloRegistro)
                this.form.clearValidators()
                this.saved = true
                this.saving = false
                this.cdr.detectChanges()

                if (this.oUsuario.email != this.usuario.email) {
                    this.mailChanged = true
                } else {
                    setTimeout(() => {
                        let user = this.authService.getUserData()
                        Object.assign(user, usuario)

                        this.sessionService.setUserData(user)

                        let host = window.location.hostname
                        var new_host = host

                        let roles = this.sessionService.getRoles()
                        let permisos = this.sessionService.getPermisos()

                        let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : "/home"
                        let token = this.authService.getToken()
                        const nebuAuthService = this.injector.get(NebuAuthService)
                        const nebuToken = nebuAuthService.getToken()
                        const carrerasAuthService = this.injector.get(CarrerasAuthService, null)
                        let carrerasToken
                        if (carrerasAuthService) {
                            carrerasToken = carrerasAuthService.getToken()
                        }
                        const achievementsAuthService = this.injector.get(AchievementsAuthService, null)
                        let logrosToken
                        if (achievementsAuthService) {
                            logrosToken = achievementsAuthService.getToken()
                        }

                        if (environment.production) {
                            let docentesDomain = ""
                            let alumnosDomain = ""
                            let adminDomain = ""

                            if (environment.domain) {
                                docentesDomain = environment.domain.profesores || ""
                                alumnosDomain = environment.domain.alumnos || ""
                                adminDomain = environment.domain.admin || ""
                            }

                            if (roles.length > 0) {
                                if (
                                    roles.includes("SuperAdministrador") ||
                                    roles.includes("Administrador") ||
                                    roles.includes("Monitor")
                                ) {
                                    new_host = host //Que vaya a donde quiera.
                                } else if (roles.includes("Docente") || roles.includes("DocenteDemo")) {
                                    new_host = docentesDomain
                                } else {
                                    if (config.plataforma.name != "Graduate") new_host = alumnosDomain
                                }
                            } else {
                                //Esto no debería pasar nunca
                                new_host = alumnosDomain
                            }
                        }

                        const loginService = this.injector.get(Login)

                        loginService.redirectUser(
                            host,
                            new_host,
                            "/home",
                            {},
                            token,
                            nebuToken,
                            carrerasToken,
                            logrosToken
                        )
                    }, 5000)
                }
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.usuario = new Usuario()
            this.formType.markFormControlsAsPristine(this.form)
            this.formType.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    back() {
        this.router.navigate([""])
    }

    getEstablecimientos(establecimientoFilter: string) {
        this.beforeGetEstablecimientos()
        this.establecimientosService
            .where(this.whereGetEstablecimientos(establecimientoFilter))
            .then((establecimientos: Establecimiento[]) => {
                this.filteredEstablecimientos = establecimientos
            })
    }

    whereGetEstablecimientos(establecimientoFilter: string): any {
        return {
            per: 100,
            raw: 1,
            establecimiento: { establecimiento: establecimientoFilter },
            include: `[establecimiento_${this.pais}]`,
            lugar_tipo: { lugar_tipo: ["Ciudad", "Región", "Provincia", "Comuna"] }
        }
    }

    beforeGetEstablecimientos() {}

    updateLugar(lugar: any) {
        if (lugar instanceof Lugar) {
            this.usuario.lugar = lugar
            this.lugar = lugar
        }
    }

    updateLugarColegio(lugar: any) {
        if (lugar instanceof Lugar) {
            this.lugarColegio = lugar
        }
    }

    getLugarColegio(lugarFilter: string) {
        this.lugaresService
            .where({ per: 100, raw: 1, filter: lugarFilter, lugar_tipo: [config.plataforma.lugarTipo] })
            .then((lugares: Lugar[]) => {
                this.filteredLugarColegio = lugares
            })
    }

    updateColegio(establecimiento: Establecimiento) {
        this.usuario.establecimiento = establecimiento
    }
}
