import { Component, OnInit, Output, EventEmitter } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { BaseForm } from "@puntaje/shared/core"
import { Usuario, Usuarios, Establecimientos, Establecimiento, Lugar, Lugares } from "@puntaje/puntaje/api-services"
import { UsuarioRegistroBase } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "base-register",
    templateUrl: "./base-register.component.html",
    styleUrls: ["./base-register.component.scss"]
})
export class BaseRegisterComponent implements OnInit {
    pais: string = config.plataforma.pais.toLowerCase()
    usuarioRegistro: UsuarioRegistroBase
    form: UntypedFormGroup
    saved: boolean = false
    lugar: Lugar
    lugarColegio: Lugar
    filteredLugarColegio: Lugar[]
    filteredEstablecimientos: Establecimiento[]
    currentStep: number

    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()

    constructor(
        protected usuariosService: Usuarios,
        protected router: Router,
        protected lugaresService: Lugares,
        protected establecimientosService: Establecimientos
    ) {}

    ngOnInit() {}

    save(tipoEnvio?: string) {
        const usuario = this.usuarioRegistro.toUsuario()
        this.beforeSave(usuario, tipoEnvio)
        BaseForm.markFormControlsAsTouched(this.form)
        if (this.saveCondition()) {
            this.usuariosService.save(usuario).then((usuario: Usuario) => {
                return this.afterSave(usuario)
            })
        }
    }

    saveCondition() {
        return this.form.valid
    }

    beforeSave(usuario?: Usuario, tipoEnvio?: string) {}

    afterSave(usuario: Usuario) {
        this.onTitleChange.emit("¡Ya casi estás listo!")
        this.saved = true
    }

    clear() {
        setTimeout(() => {
            this.usuarioRegistro = new UsuarioRegistroBase()
            BaseForm.markFormControlsAsPristine(this.form)
            BaseForm.markFormControlsAsUntouched(this.form)
            this.afterClear()
        }, 150)
    }

    afterClear() {}

    back() {
        this.router.navigate([""])
    }

    getEstablecimientos(establecimientoFilter: string) {
        this.beforeGetEstablecimientos()
        this.establecimientosService
            .where(this.whereGetEstablecimientos(establecimientoFilter))
            .then((establecimientos: Establecimiento[]) => {
                this.filteredEstablecimientos = establecimientos
            })
    }

    whereGetEstablecimientos(establecimientoFilter: string): any {
        return {
            per: 100,
            raw: 1,
            establecimiento: { establecimiento: establecimientoFilter },
            include: `[establecimiento_${this.pais}]`,
            lugar_tipo: { lugar_tipo: ["Ciudad", "Región", "Provincia", "Comuna"] }
        }
    }

    beforeGetEstablecimientos() {}

    updateLugar(lugar: any) {
        if (lugar instanceof Lugar) {
            this.usuarioRegistro.lugar = lugar
            this.lugar = lugar
        }
    }

    updateLugarColegio(lugar: any) {
        if (lugar instanceof Lugar) {
            this.lugarColegio = lugar
        }
    }

    getLugarColegio(lugarFilter: string) {
        this.lugaresService
            .where({ per: 100, raw: 1, filter: lugarFilter, lugar_tipo: ["Comuna"] })
            .then((lugares: Lugar[]) => {
                this.filteredLugarColegio = lugares
            })
    }

    updateColegio(establecimiento: Establecimiento) {
        this.usuarioRegistro.colegio = establecimiento
    }

    validarTipoEnvio(event) {
        if (event.tipo == "sms") {
            this.usuarioRegistro.telefono = event.telefono
            this.save("sms")
        }
        if (event.tipo == "mail") {
            this.save("mail")
        }
    }
}
