import { Component, Input, OnInit } from "@angular/core"
import {
    EscalaGlobal,
    EscalaGlobalAlias,
    EvaluacionInstancia,
    EvaluacionInstanciaEscalaGlobalAlias,
    EvaluacionInstancias
} from "@puntaje/puntaje/api-services"
import { EvaluacionInstanciaEscalasService } from "../evaluacion_instancia_escalas.service"

@Component({
    selector: "escala-global-alias-instancia",
    templateUrl: "./escala-global-alias-instancia.component.html",
    styleUrls: ["./escala-global-alias-instancia.component.scss"]
})
export class EscalaGlobalAliasInstanciaComponent implements OnInit {
    @Input() evaluacionInstancia: EvaluacionInstancia
    @Input() escalaGlobal: EscalaGlobal
    @Input() evaluacionInstanciaEscalaGlobalAlias: EvaluacionInstanciaEscalaGlobalAlias

    escalaGlobalAlias: EscalaGlobalAlias
    originalEscalaGlobalAlias: EscalaGlobalAlias
    editing: boolean = false

    constructor(
        protected evaluacionInstanciaEscalasService: EvaluacionInstanciaEscalasService,
        protected evaluacionInstanciasService: EvaluacionInstancias
    ) {}

    ngOnInit() {
        this.setEscalaGlobalAlias()
    }

    setEscalaGlobalAlias() {
        this.editing = false
        this.escalaGlobalAlias = this.escalaGlobal.escala_global_aliases.find(
            ega => ega.id == this.evaluacionInstanciaEscalaGlobalAlias?.escala_global_alias_id
        )

        if (!this.escalaGlobalAlias) {
            this.escalaGlobalAlias = this.evaluacionInstanciaEscalasService.getEscalaGlobalAliasesPorcentaje(
                this.escalaGlobal,
                this.evaluacionInstancia
            )[0]
        }

        this.originalEscalaGlobalAlias = this.escalaGlobalAlias
    }

    toggleEdit() {
        this.editing = !this.editing

        this.escalaGlobalAlias = this.originalEscalaGlobalAlias
    }

    save() {
        if (this.escalaGlobalAlias) {
            const updatedEvaluacionInstancia = new EvaluacionInstancia()
            updatedEvaluacionInstancia.id = this.evaluacionInstancia.id

            let evaluacionInstanciaEscalaGlobalAlias = this.evaluacionInstanciaEscalaGlobalAlias
            if (!evaluacionInstanciaEscalaGlobalAlias) {
                evaluacionInstanciaEscalaGlobalAlias = new EvaluacionInstanciaEscalaGlobalAlias()
                evaluacionInstanciaEscalaGlobalAlias.escala_global_id = this.escalaGlobal.id
            }

            evaluacionInstanciaEscalaGlobalAlias.escala_global_alias_id = this.escalaGlobalAlias.id

            const obj = {
                evaluacion_instancia: {
                    ...updatedEvaluacionInstancia,
                    evaluacion_instancia_escala_global_aliases_attributes: [evaluacionInstanciaEscalaGlobalAlias]
                },
                include: "[evaluacion_instancia_escala_global_aliases:[escala_global_alias]]"
            }

            this.evaluacionInstanciasService.update(updatedEvaluacionInstancia.id, obj as any).then(ei => {
                this.evaluacionInstancia.evaluacion_instancia_escala_global_aliases =
                    ei.evaluacion_instancia_escala_global_aliases

                this.evaluacionInstanciaEscalaGlobalAlias = ei.evaluacion_instancia_escala_global_aliases.find(
                    eiega => eiega.escala_global_id == this.escalaGlobal.id
                )
                this.setEscalaGlobalAlias()

                this.evaluacionInstanciaEscalasService.evaluacionInstancia.next(this.evaluacionInstancia)
            })
        }
    }
}
