import { Component, Input, OnInit } from "@angular/core"
import { Post } from "@puntaje/puntaje/api-services"

@Component({
    selector: "post-thumbnail",
    templateUrl: "./post-thumbnail.component.html",
    styleUrls: ["./post-thumbnail.component.scss"]
})
export class PostThumbnailComponent implements OnInit {
    @Input() post: Post
    @Input() external: boolean = false //si necesito redirigir al blog logeado o deslogeado
    route: string = "/posts/"
    autor: string

    constructor() {}

    ngOnInit() {
        if (this.external) this.route = "/blog/"
        if (this.post.usuario) this.autor = this.post.usuario.nombreCompleto()
    }
}
