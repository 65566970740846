<notificacion-layout
    [notificacion]="notificacion"
    [enableMini]="enableMini"
    [tipoNotificacion]="'plan-personal'"
    [disableMarkButton]="disableMarkButton"
>
    <notificacion-contenido>
        <p class="texto-notificacion">
            {{ notificacionDetails.usuario.nombre }} {{ notificacionDetails.usuario.apellido_paterno }}
            <span *ngIf="establecimiento">{{ "notificaciones.de" | t }} {{ establecimiento }}, {{ "notificaciones.del" | t }} {{ nombreGrupo }} {{ grupo }}</span>
            <span *ngIf="grupo">{{ "notificaciones.del_grupo" | t }} {{ grupo }}</span>
            {{ "notificaciones.te_ha_compartido_r" | t }}
            <a [routerLink]="realizarLink" (click)="onClickLink()">
                {{ notificacionDetails.plan_personal.plan_personal }}
            </a>
        </p>
    </notificacion-contenido>
    <notificacion-acciones-footer>
        <a [routerLink]="realizarLink">
            <cui-button type="button" class="btn_style pull-right" (click)="onClickLink()">{{ "notificaciones.realizar_ahora" | t }}</cui-button>
        </a>
    </notificacion-acciones-footer>
</notificacion-layout>
