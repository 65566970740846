import { NebuModule } from "@puntaje/nebulosa/api-services"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import {
    UtilModule,
    FormModule,
    TranslationsModule,
    SorterModule,
    PaginatorModule,
    ModelViewModule
} from "@puntaje/shared/core"
import { CommonModule } from "@angular/common"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { ModalModule } from "ngx-bootstrap/modal"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { registerLocaleData } from "@angular/common"
import localeEs from "@angular/common/locales/es"
import { RouterModule } from "@angular/router"
import { Ng2HighchartsModule } from "@ogr-sa/ng2-highcharts"
import { CUIContainersModule } from "@puntaje/shared/cui"
import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { BaseNavbarComponent } from "./base-navbar/base-navbar.component"
import { BaseTopmenuComponent } from "./base-topmenu/base-topmenu.component"
import { BaseFooterComponent } from "./base-footer/base-footer.component"
import { BaseMenuShowcaseBoxComponent } from "./base-menu-showcasebox/base-menu-showcasebox.component"
import { BaseMenuHorizontalComponent } from "./base-menu-horizontal/base-menu-horizontal.component"
import { SubMenuComponent } from "./sub-menu/sub-menu.component"
import { RedesSocialesShowcaseboxComponent } from "./redes-sociales-showcasebox/redes-sociales-showcasebox.component"
import { NivelesModule } from "@puntaje/puntaje/new-modules/niveles"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
registerLocaleData(localeEs)

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        UtilModule,
        CommonsLayoutsModule,
        Angular2FontawesomeModule,
        FormModule,
        ModalModule,
        ProgressbarModule,
        PopoverModule,
        TooltipModule,
        SorterModule,
        PaginatorModule,
        NebuModule,
        TranslationsModule,
        Ng2HighchartsModule,
        ModelViewModule,
        NotificacionesModule,
        CUIContainersModule,
        NivelesModule,
        SharedModule
    ],
    declarations: [
        BaseNavbarComponent,
        BaseTopmenuComponent,
        BaseFooterComponent,
        BaseMenuShowcaseBoxComponent,
        BaseMenuHorizontalComponent,
        SubMenuComponent,
        RedesSocialesShowcaseboxComponent
    ],
    exports: [
        BaseNavbarComponent,
        BaseTopmenuComponent,
        BaseFooterComponent,
        BaseMenuShowcaseBoxComponent,
        BaseMenuHorizontalComponent,
        SubMenuComponent,
        RedesSocialesShowcaseboxComponent
    ],
    providers: []
})
export class LayoutsModule {
    static forRoot(): ModuleWithProviders<LayoutsModule> {
        return {
            ngModule: LayoutsModule,
            providers: []
        }
    }
}
