<div class="content-container">
    <ng-content select="cui-layout-header"></ng-content>
    <div class="container-fluid bottom-padding with-max-width responsive-no-padding">
        <div class="clearfix" [class.content-header-with-topside]="showTopside">
            <div [class.logged-layout-over-title]="showOverTitle" #loggedLayoutOverTitle>
                <l-slot name="over-title"></l-slot>
            </div>
            <cui-titlebar *ngIf="!noTitleBar" [class.print-hide]="hideTitleOnPrint">
                <cui-titlebar-titulo>
                    <l-slot name="titulo"></l-slot>
                </cui-titlebar-titulo>
                <cui-titlebar-subtitulo>
                    <l-slot name="subtitulo"></l-slot>
                </cui-titlebar-subtitulo>
            </cui-titlebar>
            <div [class.logged-layout-topside]="showTopside" #loggedLayoutTopside>
                <l-slot name="topside"></l-slot>
            </div>
        </div>
        <div [class.logged-layout-top-contenido]="showTopContenido" #loggedLayoutTopContenido>
            <l-slot name="top-contenido"></l-slot>
        </div>
        <div [class.logged-layout-tabs]="showTabs" #loggedLayoutTabs>
            <l-slot name="tabs"></l-slot>
        </div>
        <div
            [class.logged-layout-contenido]="showContenido && !freeContentStyle"
            [class.with-tabs]="showTabs"
            [class.free-logged-layout-contenido]="freeContentStyle"
            [class.no-title-margin]="noTitleBar"
            #loggedLayoutContenido
        >
            <router-outlet></router-outlet>
        </div>
    </div>
    <div [class.logged-layout-middle-grey-section]="showMiddleGreySection" #loggedLayoutMiddleGreySection>
        <div class="container-fluid inner-content with-max-width responsive-no-padding">
            <l-slot name="middle-grey-section"></l-slot>
        </div>
    </div>
    <div [class.logged-layout-contenido-extra]="showContenidoExtra" #loggedLayoutContenidoExtra>
        <div class="container-fluid inner-content with-max-width responsive-no-padding">
            <l-slot name="contenido-extra"></l-slot>
        </div>
    </div>
    <div class="push-footer"></div>
</div>
<ng-content select="cui-layout-footer"></ng-content>
