import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { EmbedClasificacionesComponent } from "./embed_clasificaciones/embed_clasificaciones.component"
import { ModalClasificarRecursoComponent } from "./modal-clasificar-recurso/modal-clasificar-recurso.component"
import { FormModule, UtilModule } from "@puntaje/shared/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { CUIInputsModule } from "@puntaje/shared/cui"
import { ClasificadorRecursosComponent } from "./clasificador-recursos/clasificador-recursos.component"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        CUIInputsModule,
        Angular2FontawesomeModule
    ],
    declarations: [EmbedClasificacionesComponent, ModalClasificarRecursoComponent, ClasificadorRecursosComponent],
    exports: [EmbedClasificacionesComponent, ModalClasificarRecursoComponent, ClasificadorRecursosComponent]
})
export class ClasificacionesModule {}
