import { Component, Input, ViewChild, ElementRef, OnInit } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignatura, Asignaturas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import {
    PlanPersonales,
    PlanPersonal,
    PlanPersonalCiclo,
    PlanPersonalInstancia,
    PlanPersonalInstancias,
    PlanPersonalPredefinido,
    PlanPersonalPredefinidos
} from "@puntaje/puntaje/api-services"
import { ActivatedRoute, Router } from "@angular/router"

@Component({
    selector: "planes-personales",
    templateUrl: "planes_personales.component.html",
    styleUrls: ["planes_personales.component.scss"]
})
export class PlanesPersonalesComponent implements OnInit {
    @Input() hideTitle: boolean
    @Input() enablePlanPersonalPredefinidoFirst: boolean = true

    planPersonal: PlanPersonal
    planPersonalInstancia: PlanPersonalInstancia
    planPersonalCicloActual: PlanPersonalCiclo
    usuarioId: number
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    _asignaturaId: number
    @Input()
    set asignaturaId(asignatura_id) {
        this._asignaturaId = asignatura_id
        this.loadingLayout.standby()
        this._generadorInstrumento = null
        this.setPlanPersonal()
    }
    get asignaturaId() {
        return this._asignaturaId
    }
    _generadorInstrumento: GeneradorInstrumento
    @Input()
    set generadorInstrumento(generadorInstrumento) {
        this._generadorInstrumento = generadorInstrumento
        this.setPlanPersonal()
    }
    get generadorInstrumento() {
        return this._generadorInstrumento
    }
    planPersonalPredefinido: PlanPersonalPredefinido

    constructor(
        protected planPersonalesService: PlanPersonales,
        protected planPersonalInstanciasService: PlanPersonalInstancias,
        protected planPersonalPredefinidosService: PlanPersonalPredefinidos,
        protected router: Router,
        protected authService: AuthService
    ) {
        this.usuarioId = this.authService.getUserData().id
    }

    ngOnInit() {}

    async setPlanPersonal() {
        if (this.generadorInstrumento) {
            // aquí debería ir a buscar el plan personal actual
            this.loadingLayout.standby()
            this.planPersonal = null
            // en verdad debería traerme sólo el último ciclo

            //ver si hay un plan personal predefinido de tipo plan_de_clases del generador correspondiente
            let paramsPlanPersonalPredefinido = {
                plan_personal: {
                    generador_instrumento_id: this.generadorInstrumento.id
                }
            }

            let paramsPlanPersonal = {
                plan_personal: {
                    generador_instrumento_id: this.generadorInstrumento.id
                },
                plan_personal_usuario: {
                    usuario_id: this.usuarioId,
                    item_id: this.usuarioId,
                    tabla: "Usuario"
                },
                render_options: {
                    include: {
                        plan_personal_tipo: null,
                        generador_instrumento: null,
                        plan_personal_ciclos: {
                            include: {
                                plan_personal_sesiones: {
                                    include: {
                                        plan_personal_contenidos: null
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (this.enablePlanPersonalPredefinidoFirst) {
                let planPersonalPredefinido = (await this.planPersonalPredefinidosService.where(
                    paramsPlanPersonalPredefinido
                )) as PlanPersonalPredefinido[]
                if (planPersonalPredefinido && planPersonalPredefinido.length > 0) {
                    this.planPersonalPredefinido = planPersonalPredefinido[0]
                    delete paramsPlanPersonal.plan_personal_usuario
                    paramsPlanPersonal.plan_personal["id"] = this.planPersonalPredefinido.plan_personal_id
                }
            }

            this.planPersonalesService.where(paramsPlanPersonal).then((planesPersonales: PlanPersonal[]) => {
                if (planesPersonales.length > 0) {
                    const planPersonal = planesPersonales[0]

                    this.planPersonalInstanciasService
                        .where({
                            plan_personal_instancia: {
                                plan_personal_id: planPersonal.id,
                                usuario_id: this.usuarioId
                            },
                            render_options: {
                                include: {
                                    plan_personal_material_instancias: null,
                                    plan_personal_evaluacion_instancias: {
                                        include: {
                                            evaluacion_instancia: null
                                        }
                                    }
                                }
                            }
                        })
                        .then((planPersonalInstancias: PlanPersonalInstancia[]) => {
                            if (planPersonalInstancias.length > 0) {
                                this.planPersonalInstancia = planPersonalInstancias[0]

                                this.setPlanPersonalWithInstancia(planPersonal)
                            } else {
                                const planPersonalInstancia = new PlanPersonalInstancia()
                                planPersonalInstancia.plan_personal_id = planPersonal.id
                                planPersonalInstancia.usuario_id = this.usuarioId

                                this.planPersonalInstanciasService
                                    .save(planPersonalInstancia)
                                    .then((ppi: PlanPersonalInstancia) => {
                                        ppi.plan_personal_material_instancias = []
                                        ppi.plan_personal_evaluacion_instancias = []

                                        this.planPersonalInstancia = ppi
                                        this.setPlanPersonalWithInstancia(planPersonal)
                                    })
                            }
                        })
                } else {
                    this.loadingLayout.ready()
                }
            })
        } else {
            this.loadingLayout.ready()
        }
    }

    comenzarPlanPersonal() {
        let params = {
            tipo_evaluacion: this.generadorInstrumento.tipo_instrumento.tipo_instrumento,
            asignatura_id: this.asignaturaId,
            generador_instrumento_id: this.generadorInstrumento.id,
            inicial: 1
        }
        this.router.navigate(["/diagnostico"], { queryParams: params })
    }

    setPlanPersonalWithInstancia(planPersonal) {
        const ppinstancia = this.planPersonalInstancia

        planPersonal.plan_personal_ciclos.forEach(ppciclo => {
            ppciclo.plan_personal_sesiones.forEach(ppsesion => {
                ppsesion.plan_personal_contenidos.forEach(ppcontenido => {
                    if (ppcontenido.tipo == "Material") {
                        ppcontenido.plan_personal_material_instancias =
                            ppinstancia.plan_personal_material_instancias.filter(
                                ppmi => ppmi.plan_personal_contenido_id == ppcontenido.id
                            )
                        ppcontenido.plan_personal_evaluacion_instancias = []
                    } else if (ppcontenido.tipo == "Evaluacion") {
                        ppcontenido.plan_personal_evaluacion_instancias =
                            ppinstancia.plan_personal_evaluacion_instancias.filter(
                                ppei => ppei.plan_personal_contenido_id == ppcontenido.id
                            )
                        ppcontenido.plan_personal_material_instancias = []
                    }
                })
            })
        })

        this.planPersonal = planPersonal
        this.loadingLayout.ready()
    }
}
