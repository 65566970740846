import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { PlanEstudioLote } from "@puntaje/nebulosa/api-services"
import { ErroresService, HttpVerbs, AppEnv } from "@puntaje/shared/core"
import { PlanEstudioEvaluacion } from "./plan_estudio_evaluaciones.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class PlanEstudioEvaluaciones extends PuntajeBaseService<PlanEstudioEvaluacion> {
    tableName = "plan_estudio_evaluaciones"
    singularTableName = "plan_estudio_evaluacion"
    modelClass = PlanEstudioEvaluacion

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public get_evaluacion_id(objectQuery: PlanEstudioLote) {
        if (this.canConcat && !this.withParams) {
            this.objectQuery = new PlanEstudioLote()
            this.objectQuery["plan_estudio_lote"] = objectQuery.clone()
            this.restQuery += "/generar_plan_estudio_evaluacion"
            this.canConcat = false
            this.httpVerb = HttpVerbs.POST
        } else {
            throw new SyntaxError(
                "Imposible concatenar una llamada POST (save) a una llamada " + HttpVerbs[this.httpVerb]
            )
        }
        return this
    }
}
