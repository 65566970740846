import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    CarrerasConf,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType
} from "@puntaje/shared/core"

export class BaseConfig implements AppConfiguration {
    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }

    generar = {
        generar: true,
        intrumentosRecomendados: true,
        pruebasPropias: true,
        buscador: true
    }

    registro = {
        enableCarreras: true,
        universidades: [],
        amount: 3,
        enableNivel: true,
        enableColegio: true
    }
    carreras = {
        ponderables: [
            {
                orden: 1,
                ponderable: "Matemática",
                asignaturaAsociada: "Matemática",
                tipo: "calificable"
            },
            {
                orden: 2,
                ponderable: "Lenguaje y Comunicación",
                asignaturaAsociada: "Lenguaje",
                tipo: "calificable"
            },
            {
                orden: 3,
                ponderable: "Historia y Ciencias Sociales",
                asignaturaAsociada: "Historia y Ciencias Sociales",
                tipo: "calificable",
                categoria: "ciencias-historia"
            },
            {
                orden: 4,
                ponderable: "Ciencias",
                asignaturaAsociada: "Ciencias PSU",
                tipo: "calificable",
                categoria: "ciencias-historia"
            },
            {
                orden: 5,
                ponderable: "NEM",
                alias: "Puntaje NEM",
                clase: "nem",
                tipo: "ingresable",
                field: "nem",
                minValue: 150,
                maxValue: 850
            },
            {
                orden: 6,
                ponderable: "Ranking",
                clase: "ranking",
                tipo: "ingresable",
                field: "ranking",
                minValue: 150,
                maxValue: 850
            }
        ]
    } as CarrerasConf
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoSquareLight: "assets/img/logos/logoSquareLight.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },

        baseStyle: {
            navbar: {
                logo: "logoSmall",
                logoMobile: "logoSquare"
            }
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [] as AsignaturaType[]

    plataforma = {
        name: "fueguino",
        id: null,
        pais: "chile",
        locale: "es",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["RUT"],
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        info: {
            social: {
                facebook: "https://www.facebook.com/aprendolibre.co/",
                twitter: "https://twitter.com/aprendolibre",
                youtube: "https://www.youtube.com/channel/UCTzMx0G4b7hLXDycu3DISjw"
            },
            soporte: {
                mail: "monitores@aprendolibre.co"
            },
            contacto: {
                telefono: "00000000",
                mail: "ventas@ogr.cl"
            },
            companyName: "Aprendolibre.net",
            companySite: "www.aprendolibre.net"
        },
        evaluacionTipoAdaptiveDefault: "curricular colombia",
        evaluacionDefault: "curricular",
        evaluacionResponder: "estandar",
        resumenGeneral: {
            curriculum: "curricular",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: [],
        evaluacionTipos: ["curricular", "prueba de transición"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: null,
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "curricular",
        tiposNotificacion: [
            {
                tipo: "curricular mexico",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            },
            {
                tipo: "retroalimentacion",
                label: "Retroalimentaciones"
            }
        ]
    } as PlataformaType

    evaluaciones = {
        curricular: {
            alias: "prueba",
            nombreTipoCalificacion: "%",
            layaoutResultadoCalificacion: "horizontal",
            cantidadPreguntas: 4,
            clasificaciones: {
                estadisticas: ["eje temático LGE"],
                groupBy: ["eje temático LGE,objetivo de aprendizaje"],
                clasificacionTiposTablaEspecificaciones: [],
                configuration: [
                    {
                        clasificacionTipo: "eje temático LGE",
                        name: "Eje de contenidos",
                        htmlId: "eje",
                        hasHijos: true
                    }
                ]
            },
            clasificacionDistribucion: "ejes",
            clasificacionTipoResultados: "eje temático LGE",
            clasificacionMaterial: {
                clasificacion: "eje temático LGE",
                label: "eje"
            },
            planPersonalClasificacionMateriales: [
                { clasificacion: "eje temático LGE", label: "eje" },
                { clasificacion: "objetivo de aprendizaje", label: "oa" }
            ],
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático LGE",
                    compuesto: false,
                    alumnos: true,
                    profesores: true
                },
                {
                    nombre: "habilidad cognitiva LGE",
                    compuesto: false,
                    alumnos: false,
                    profesores: true
                },
                {
                    nombre: "objetivo de aprendizaje",
                    compuesto: false,
                    alumnos: false,
                    profesores: true
                }
            ],
            cursoTipo: "curso",
            cursos: [
                { clasificacion: "Séptimo", label: "7º Grado" },
                { clasificacion: "Octavo", label: "8º Grado" },
                { clasificacion: "Noveno", label: "9º Grado" },
                { clasificacion: "Décimo", label: "10º Grado" },
                { clasificacion: "Once", label: "11º Grado" },
                { clasificacion: "Doce", label: "12º Grado" }
            ],
            cursosMaterial: [
                { id: 1, clasificacion: "Primer", label: "1º Grado" },
                { id: 2, clasificacion: "Segundo", label: "2º Grado" },
                { id: 3, clasificacion: "Tercero", label: "3º Grado" },
                { id: 4, clasificacion: "Cuarto", label: "4º Grado" },
                { id: 5, clasificacion: "Quinto", label: "5º Grado" },
                { id: 6, clasificacion: "Sexto", label: "6º Grado" },
                { id: 7, clasificacion: "Séptimo", label: "7º Grado" },
                { id: 8, clasificacion: "Octavo", label: "8º Grado" },
                { id: 9, clasificacion: "Noveno", label: "9º Grado" },
                { id: 10, clasificacion: "Décimo", label: "10º Grado" }
            ],
            estrellas: [
                { min: 0, max: 16 },
                { min: 17, max: 33 },
                { min: 34, max: 50 },
                { min: 51, max: 67 },
                { min: 68, max: 84 },
                { min: 85, max: 100 }
            ],
            nivelTipoInstrumentoInformeFinal: [],
            rangoCalificacion: {
                min: 0,
                tickInterval: 5,
                max: 100
            },
            streamingChannelAlias: "Clases Curriculares"
        },
        "prueba de transición": {
            alias: "prueba",
            nombreTipoCalificacion: "%",
            layaoutResultadoCalificacion: "horizontal",
            cantidadPreguntas: 4,
            clasificaciones: {
                estadisticas: ["eje temático LGE"],
                groupBy: ["eje temático LGE,objetivo de aprendizaje"],
                clasificacionTiposTablaEspecificaciones: [],
                configuration: [
                    {
                        clasificacionTipo: "eje temático LGE",
                        name: "Eje de contenidos",
                        htmlId: "eje",
                        hasHijos: true
                    }
                ]
            },
            clasificacionDistribucion: "ejes",
            clasificacionTipoResultados: "eje temático LGE",
            clasificacionMaterial: {
                clasificacion: "eje temático LGE",
                label: "eje"
            },
            planPersonalClasificacionMateriales: [
                { clasificacion: "eje temático LGE", label: "eje" },
                { clasificacion: "objetivo de aprendizaje", label: "oa" }
            ],
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático LGE",
                    compuesto: false,
                    alumnos: true,
                    profesores: true
                },
                {
                    nombre: "habilidad cognitiva LGE",
                    compuesto: false,
                    alumnos: false,
                    profesores: true
                },
                {
                    nombre: "objetivo de aprendizaje",
                    compuesto: false,
                    alumnos: false,
                    profesores: true
                }
            ],
            cursoTipo: "curso",
            cursos: [
                { clasificacion: "Séptimo", label: "7º Grado" },
                { clasificacion: "Octavo", label: "8º Grado" },
                { clasificacion: "Noveno", label: "9º Grado" },
                { clasificacion: "Décimo", label: "10º Grado" },
                { clasificacion: "Once", label: "11º Grado" },
                { clasificacion: "Doce", label: "12º Grado" }
            ],
            cursosMaterial: [
                { id: 1, clasificacion: "Primer", label: "1º Grado" },
                { id: 2, clasificacion: "Segundo", label: "2º Grado" },
                { id: 3, clasificacion: "Tercero", label: "3º Grado" },
                { id: 4, clasificacion: "Cuarto", label: "4º Grado" },
                { id: 5, clasificacion: "Quinto", label: "5º Grado" },
                { id: 6, clasificacion: "Sexto", label: "6º Grado" },
                { id: 7, clasificacion: "Séptimo", label: "7º Grado" },
                { id: 8, clasificacion: "Octavo", label: "8º Grado" },
                { id: 9, clasificacion: "Noveno", label: "9º Grado" },
                { id: 10, clasificacion: "Décimo", label: "10º Grado" }
            ],
            estrellas: [
                { min: 0, max: 16 },
                { min: 17, max: 33 },
                { min: 34, max: 50 },
                { min: 51, max: 67 },
                { min: 68, max: 84 },
                { min: 85, max: 100 }
            ],
            nivelTipoInstrumentoInformeFinal: [],
            rangoCalificacion: {
                min: 0,
                tickInterval: 5,
                max: 100
            },
            streamingChannelAlias: "Clases Curriculares"
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        alternativas: 5
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o NUIP",
        invalidLoginId: "Debes incluir un correo electrónico o NUIP válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o NUIP/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
    }
}
