import { Injectable } from "@angular/core"
import { Subject } from "rxjs"
import { AppConfig } from "../conf/app_config"

@Injectable({
    providedIn: "root"
})
export class SessionService {
    justLoggedIn: boolean
    public userDataUpdated = new Subject<any>()

    constructor(protected config: AppConfig) {}

    public minUserFields() {
        let usuario = this.getUserData()
        let condition = usuario.nombre && usuario.apellido_paterno
        if (this.config.plataforma.name !== "College Board") {
            condition = condition && usuario[`usuario_${this.config.plataforma.pais}`]
            let atLeastOneIdentificador = false
            if (condition) {
                this.config.plataforma.identificadorUsuario.forEach(idU => {
                    atLeastOneIdentificador =
                        atLeastOneIdentificador || usuario[`usuario_${this.config.plataforma.pais}`][idU.toLowerCase()]
                })
            }
            atLeastOneIdentificador = atLeastOneIdentificador || usuario.pasaporte

            condition = condition && atLeastOneIdentificador
        }
        return usuario ? !!condition : false
    }

    public getUserData() {
        let usuario = JSON.parse(localStorage.getItem("usuario"))
        if (!usuario) {
            return {}
        } else return usuario
    }

    public isFromNomina() {
        return this.getUserData() ? this.getUserData().nomina : false
    }

    public isFromGoogleOrFacebook() {
        const user = this.getUserData()

        return !!(user.facebook_id || user.google_id)
    }

    public setGrupoUsuariosActivos(grupoUsuarios) {
        let grupoU = grupoUsuarios ? grupoUsuarios.toString() : ""
        localStorage.setItem("grupoUsuarioActivos", grupoU)
    }

    public getGrupoUsuariosActivos() {
        const grupoUsuarioActivos = localStorage.getItem("grupoUsuarioActivos")
        if (!grupoUsuarioActivos) return []

        return grupoUsuarioActivos.split(",")
    }

    public setNivelesActivos(niveles) {
        let nivels = niveles ? niveles.toString() : ""
        localStorage.setItem("nivelesActivos", nivels)
    }

    public getNivelesActivos() {
        const nivelesActivos = localStorage.getItem("nivelesActivos")
        if (!nivelesActivos) return []

        return nivelesActivos.split(",")
    }

    public getRoles() {
        const rolesStr = localStorage.getItem("roles")
        if (!rolesStr) return []

        return rolesStr.split(",")
    }

    public setRoles(roles: string[]) {
        localStorage.setItem("roles", roles.toString())
    }

    public getPermisos() {
        const permisosStr = localStorage.getItem("permisos")
        if (!permisosStr) return []

        return permisosStr.split(",")
    }

    public setPermisos(permisos: string[]) {
        localStorage.setItem("permisos", permisos.toString())
    }

    public getPerfiles() {
        const perfilesStr = localStorage.getItem("perfiles")
        if (!perfilesStr) return []

        return perfilesStr.split(",")
    }

    public setPerfiles(perfiles: string[]) {
        localStorage.setItem("perfiles", perfiles.toString())
    }

    public setConfirmedAt(confirmedAt: any) {
        localStorage.setItem("confirmed_at", confirmedAt)
    }

    public getConfirmedAt() {
        return localStorage.getItem("confirmed_at")
    }

    public setResetPasswordSentAt(resetPasswordSentAt: any) {
        localStorage.setItem("reset_password_sent_at", resetPasswordSentAt)
    }

    public getResetPasswordSentAt() {
        if (localStorage.getItem("reset_password_sent_at") == "undefined") return undefined
        else return localStorage.getItem("reset_password_sent_at")
    }

    public getEstablecimientos() {
        let est = localStorage.getItem("establecimientos")
        if (!est) return []

        return est.split(",")
    }

    public getAsociaciones() {
        let est = localStorage.getItem("asociaciones")
        if (!est) return []

        return est.split(",")
    }

    public setEstablecimientos(establecimientos: string[]) {
        let est =
            establecimientos === undefined || establecimientos === null || establecimientos.length == 0
                ? ""
                : establecimientos.toString()
        localStorage.setItem("establecimientos", est)
    }

    public setAsociaciones(asociaciones: string[]) {
        const as =
            asociaciones === undefined || asociaciones === null || asociaciones.length == 0
                ? ""
                : asociaciones.toString()
        localStorage.setItem("asociaciones", as)
    }

    public hasRol(rol: string) {
        return this.getRoles().find(p => p == rol) ? true : false
    }

    public hasRoles(roles: string[]) {
        return roles.every(p => (this.getRoles().find(pr => pr == p) ? true : false))
    }

    public hasSomeRol(roles: string[]) {
        return roles.some(p => (this.getRoles().find(pr => pr == p) ? true : false))
    }

    public hasPermiso(permiso: string) {
        return this.getPermisos().find(p => p == permiso) ? true : false
    }

    public hasPermisos(permisos: string[]) {
        return permisos.every(p => (this.getPermisos().find(pr => pr == p) ? true : false))
    }

    public hasSomePermiso(permisos: string[]) {
        return permisos.some(p => (this.getPermisos().find(pr => pr == p) ? true : false))
    }

    public setPro(extras: string) {
        localStorage.setItem("extras", extras)
    }

    public isPro() {
        if (localStorage.getItem("extras")) {
            return localStorage.getItem("extras") == "true"
        } else {
            return false
        }
    }

    public setIsFreeUser(value: string) {
        localStorage.setItem("is_free_user", value)
    }

    public isFreeUser() {
        if (localStorage.getItem("is_free_user")) {
            return localStorage.getItem("is_free_user") == "true"
        } else {
            return false
        }
    }

    public setUserData(user: any) {
        if (user) {
            if (this.justLoggedIn) {
                user["just_logged_in"] = true
                this.justLoggedIn = false
            }
            localStorage.setItem("usuario", JSON.stringify(user))
            this.userDataUpdated.next(user)
        }
    }

    public isRecentManualResetPassword() {
        let usuario = this.getUserData()
        return usuario.manual_reset_password
    }

    public shouldAskForExtendedRegistration() {
        let usuario = this.getUserData()
        return usuario.should_ask_for_extended_registration
    }

    public hasCompletedExtendedRegistration() {
        let usuario = this.getUserData()
        return usuario.completed_extended_registration
    }

    public shouldUpdateData() {
        let usuario = this.getUserData()
        if (!usuario.last_updated_data) {
            return true
        }

        const today = new Date()
        const lastUpdatedData = new Date(usuario.last_updated_data)

        return lastUpdatedData.getFullYear() < today.getFullYear()
    }
}
