import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Noticia } from "./noticias.model"
import { Usuario } from "./usuarios.model"

export class NoticiaInstancia extends BaseModel {
    public static className: string = "NoticiaInstancia"

    public noticia_id: number
    public usuario_id: number
    public fecha_visto: string | Date

    @SaveType(() => Noticia) public noticia: Noticia
    @SaveType(() => Usuario) public usuario: Usuario
}
