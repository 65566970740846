import { HostListener, Component, Output, EventEmitter, Input, ElementRef, ChangeDetectorRef } from "@angular/core"
import { AppConfig } from "../../conf/app_config"

@Component({
    selector: "[text-highlight], text-highlight",
    template: `
        <div #divhightlight [ngClass]="{ erase: _eraseMode, highlight: !_eraseMode }" [attr.contenteditable]="editable">
            <ng-content></ng-content>
        </div>
    `,
    styles: [
        `
            [contenteditable="true"]:active,
            [contenteditable="true"]:focus {
                border: none;
                outline: none;
            }
            .highlight {
                cursor: url("https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/destacador.png") 0 30,
                    auto;
            }

            .erase {
                cursor: url("https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/borrador.png") 0 30, auto;
            }
        `
    ]
})
export class TextHighlightComponent {
    _eraseMode: boolean
    @Input() set eraseMode(value: boolean) {
        this._eraseMode = value
    }
    @Output() textHighlightEvent = new EventEmitter()
    editable: boolean = false

    constructor(private el: ElementRef, private cdr: ChangeDetectorRef, protected config: AppConfig) {}

    @HostListener("touchend", ["$event"])
    @HostListener("mouseup", ["$event"])
    private handleScroll($event: Event) {
        if (this.config.plataforma.canTextHighlight) {
            this.editable = true
            this.cdr.detectChanges()
            // setTimeout(() => {
            if (this._eraseMode) {
                document.execCommand("hiliteColor", false, "rgba(0, 0, 0, 0)")
            } else {
                // const color = "yellow" //"chartreuse"
                document.execCommand("hiliteColor", false, "yellow")
            }

            window.getSelection().removeAllRanges()

            this.textHighlightEvent.emit()
            this.editable = false
        }
    }
}
