import { Component, Input, OnInit } from "@angular/core"
import {
    EscalaGlobal,
    EscalaGlobalAlias,
    EvaluacionInstancia,
    EvaluacionInstanciaEscalaGlobalAlias
} from "@puntaje/puntaje/api-services"
import { EvaluacionInstanciaEscalasService } from "../evaluacion_instancia_escalas.service"

@Component({
    selector: "escala-global-aliases-instancia",
    templateUrl: "./escala-global-aliases-instancia.component.html",
    styleUrls: ["./escala-global-aliases-instancia.component.scss"]
})
export class EscalaGlobalAliasesInstanciaComponent implements OnInit {
    @Input() evaluacionInstancia: EvaluacionInstancia
    escalaGlobales: EscalaGlobal[]

    evaluacionInstanciaEscalaGlobalAliasByEscalaGlobalId: { [id: number]: EvaluacionInstanciaEscalaGlobalAlias }

    constructor(protected evaluacionInstanciaEscalasService: EvaluacionInstanciaEscalasService) {}

    ngOnInit() {
        this.evaluacionInstanciaEscalasService.getAllEscalas(this.evaluacionInstancia).then(escalaGlobales => {
            this.escalaGlobales = escalaGlobales

            this.evaluacionInstanciaEscalaGlobalAliasByEscalaGlobalId =
                this.evaluacionInstancia.evaluacion_instancia_escala_global_aliases.reduce(
                    (acc, evaluacionInstanciaEscalaGlobalAlias) => {
                        acc[evaluacionInstanciaEscalaGlobalAlias.escala_global_id] =
                            evaluacionInstanciaEscalaGlobalAlias

                        return acc
                    },
                    {}
                )
        })
    }
}
