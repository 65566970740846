/** @format */
import { HttpClient } from "@angular/common/http"
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { Store } from "@ngrx/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { AuthService, Validations, FlashMessageService } from "@puntaje/shared/core"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { SetCodigoConfirmacionData, State } from "@puntaje/puntaje/store"
import { ModalSelectTipoEnvioComponent } from "../registrations/modales/modal-select-tipo-envio/modal-select-tipo-envio.component"
import { UntypedFormGroup, Validators, UntypedFormControl, AsyncValidatorFn } from "@angular/forms"
import { I18nService } from "@puntaje/shared/core"
import { filter } from "rxjs/operators"
import { ContactoRegistroComponent } from "../registrations/contacto-registro/contacto-registro.component"

@Component({
    selector: "user-forgot-password",
    templateUrl: "forgot_password.component.html",
    styleUrls: ["forgot_password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    @Input() useIdentificador = true

    email: string
    message: string
    config: typeof config
    recoveryUrl: string
    confirmacionCodigoUrl: string
    canRecoverUrl: string
    response: any
    @ViewChild(ModalSelectTipoEnvioComponent, { static: true })
    modalSelectTipoEnvioComponent: ModalSelectTipoEnvioComponent
    telefonoHolder: string
    form: UntypedFormGroup
    identificadorPais: string
    sent: boolean
    sending: boolean
    valid: boolean = true
    hideForgotPassMessageContacto = config.plataforma.hideForgotPassMessageContacto
    disableForgotMessageContactoModal = config.plataforma?.disableForgotMessageContactoModal
    messageShowed: boolean = false
    placeholder: string
    useIdentificadorOnly = config.plataforma.useIdentificadorOnly || false

    @ViewChild(ContactoRegistroComponent) contactoRegistro: ContactoRegistroComponent

    constructor(
        public http: HttpClient,
        public flashMessageService: FlashMessageService,
        protected authService: AuthService,
        protected router: Router,
        protected store: Store<State>,
        protected usuarioServices: Usuarios,
        private translatorService: I18nService
    ) {
        this.config = config
    }

    ngOnInit() {
        this.authService.logout()
        this.form = new UntypedFormGroup({
            email: new UntypedFormControl("", [Validators.required /* Validations.validateEmail */])
        })

        this.recoveryUrl = environment.endpoints.base + "/usuarios/password"
        this.confirmacionCodigoUrl = "/usuarios/registrado"
        this.canRecoverUrl = environment.endpoints.base + "/usuarios/can_recover_password"

        this.sent = false
        this.sending = false
        //mover el if hacia adentro si pasa algo loco en otro país
        if (this.config.plataforma.pais === "chile") {
            this.form.controls.email.valueChanges.pipe(filter(x => !!x && x.length > 5)).subscribe((value: string) => {
                value = value.replace(/-/g, "")
                if (this.looksLikeRut(value)) {
                    const almostLast = value.length - 1
                    value = value.substring(0, almostLast) + "-" + value.substring(almostLast)
                    this.form.controls.email.setValue(value, { emitEvent: false })
                }
            })
        }

        this.identificadorPais = config.plataforma.pais === "chile" ? "RUT" : "Identificador"
        if (this.config.plataforma.identificadorUsuarioAlias) {
            const idAliasKeys = Object.keys(this.config.plataforma.identificadorUsuarioAlias)
            this.identificadorPais = this.config.plataforma.identificadorUsuarioAlias[idAliasKeys[0]]
        }

        this.flashMessageService.message_subject.subscribe(message => {
            this.messageShowed = !!message
        })
        if (this.config.plataforma.pais === "chile") {
            this.placeholder = this.useIdentificadorOnly
                ? `${this.identificadorPais} (sin puntos, con dígito verificador)`
                : this.useIdentificador
                ? `Email o ${this.identificadorPais} (sin puntos, con dígito verificador)`
                : "Email"
        } else {
            this.placeholder = this.useIdentificador ? `Email o ${this.identificadorPais}` : "Email"
        }
    }

    askForInstructions() {
        this.http
            .post(this.recoveryUrl, { email: this.email })
            .toPromise()
            .catch((reason: any) => {
                let msg = "Error."
                if (reason.errors && reason.errors.email) msg += " La dirección de correo electrónico no existe."
                this.flashMessageService.setParams("error", msg)
            })
    }

    handleRecovery(event: any) {
        const tipoEnvio = event["tipo"]

        if (tipoEnvio == "mail") {
            this.askForInstructions()
        } else {
            const params = {
                email: this.email,
                usuario: {
                    enviado_desde: "forgot_password",
                    tipo_envio: tipoEnvio
                }
            }

            this.usuarioServices.enableIgnoreModel()
            this.usuarioServices.enableIgnoreCatch()

            this.usuarioServices
                .sendCodigo(params)
                .then(response => {
                    this.usuarioServices.disableIgnoreModel()
                    this.modalSelectTipoEnvioComponent.close()
                    this.setPasswordRecoveryData({
                        codigoConfirmacionData: {
                            id: response.id,
                            email: response.email,
                            tipo_envio: response.tipo_envio
                        }
                    })
                    this.router.navigate([this.confirmacionCodigoUrl], {
                        queryParams: {
                            tipoEnvio: tipoEnvio,
                            enviadoDesde: "forgot_password"
                        }
                    })
                })
                .catch(response => ({}))
        }
    }

    setPasswordRecoveryData(data: any) {
        this.store.dispatch(new SetCodigoConfirmacionData(data))
    }

    canRecoverPassword() {
        this.checkEmail()
        if (this.valid) {
            this.sending = true
            this.getCanRecoverPassword().then(response => {
                let recoverText = this.translatorService.translate("landing.recuperar_password.mensaje_recuperacion")

                if (response["can_recover_password"]) {
                    if (!response["show_email"]) {
                        this.email = response["email"]
                        const hiddenEmail = this.hideEmail(this.email)
                        recoverText = this.translatorService.translate(
                            "landing.recuperar_password.mensaje_recuperacion_id",
                            { email: hiddenEmail }
                        )
                    }
                    if (response["has_telefono"]) {
                        this.telefonoHolder = " "
                        this.modalSelectTipoEnvioComponent.open()
                    } else {
                        this.handleRecovery({
                            tipo: "mail"
                        })
                    }

                    this.sent = true
                }
                this.sending = false
                this.flashMessageService.setParams("info", recoverText)
            })
        }
    }

    async getCanRecoverPassword() {
        const response = await this.http.get(this.canRecoverUrl, { params: { email: this.email } }).toPromise()

        return response
    }

    isValidEmail() {
        if (this.form.controls.email.valid) {
            this.email = this.form.controls.email.value
        } else {
            return false
        }
        return true
        //return this.email !== undefined && this.email !== null && this.email !== ""
    }

    private recoverMessage() {
        const recoverText = this.translatorService.translate("landing.recuperar_password.mensaje_recuperacion")
        this.flashMessageService.setParams("info", recoverText)
    }
    hideEmail(email: string, nShow: number = 3) {
        const hide = email.split("@")[0].length - nShow // number of characters to hide
        const r = new RegExp(".{" + hide + "}@", "g")

        return email.replace(r, `***@`)
    }

    ngOnDestroy(): void {
        this.flashMessageService.clear()
    }

    public looksLikeRut(value: string) {
        const RUT_REGEXP = /^([0-9kK]{6,14})$/i
        const parts = RUT_REGEXP.exec(value)
        return parts
    }

    checkEmail() {
        this.valid = this.isValidEmail()
    }

    openContactoRegistro() {
        this.contactoRegistro.open()
    }
}
