import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"

export class EscalaEvaluacion extends BaseModel {
    escala_evaluacion: string
    min: number
    mid: number
    max: number
    p_mid: number
    p_max: number
    created_at: Date
    updated_at: Date

    @SaveType(() => Usuario) usuario: Usuario
}
