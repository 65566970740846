import { Component, Input, OnInit } from "@angular/core"
import { Nivel, Niveles, UsuarioPlataforma, UsuarioPlataformas } from "@puntaje/achievements/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "logros-resumen-nivel",
    templateUrl: "./logros-resumen-nivel.component.html",
    styleUrls: ["./logros-resumen-nivel.component.scss"]
})
export class LogrosResumenNivelComponent implements OnInit {
    @Input() isVertical: boolean = false
    niveles: Nivel[]
    usuarioPlataforma: UsuarioPlataforma
    nivel: Nivel
    loadedNiveles: boolean = false
    loadedUsuarioPlataforma: boolean = false

    constructor(
        protected authService: AuthService,
        protected nivelesService: Niveles,
        protected usuarioPlataformasService: UsuarioPlataformas
    ) {}

    ngOnInit() {
        this.nivelesService.where().then(niveles => {
            this.niveles = niveles.sort((n1, n2) => n1.experiencia - n2.experiencia)

            this.loadedNiveles = true

            this.setNivelUsuario()
        })

        const params = {
            usuario_plataforma: {
                usuario_id: this.authService.getUserData().id
            },
            plataforma: {
                plataforma: config.plataforma.name
            }
        }

        this.usuarioPlataformasService.where(params).then(usuarioPlataformas => {
            this.usuarioPlataforma = usuarioPlataformas[0]
            this.loadedUsuarioPlataforma = true

            this.setNivelUsuario()
        })
    }

    setNivelUsuario() {
        if (this.loadedNiveles && this.loadedUsuarioPlataforma) {
            const experiencia = this.usuarioPlataforma?.experiencia || 0

            const nivel = this.niveles.find(n => experiencia < n.experiencia)

            this.nivel = nivel
        }
    }
}
