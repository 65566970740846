import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SmowlFrameComponent } from "./smowl-frame/smowl-frame.component"
import { ApiModule } from "@puntaje/puntaje/api-services"

@NgModule({
    declarations: [SmowlFrameComponent],
    exports: [SmowlFrameComponent],
    imports: [CommonModule, ApiModule]
})
export class SmowlModule {}
