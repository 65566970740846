import { Component, EventEmitter, AfterViewInit, ViewChild, Input, OnInit } from "@angular/core"
import { GenericModalComponent } from "@puntaje/shared/core"

@Component({
    selector: "usuario-email-modal",
    templateUrl: "./usuario-email-modal.component.html",
    styleUrls: ["./usuario-email-modal.component.scss"]
})
export class UsuarioEmailModalComponent implements OnInit {
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @Input() showModal: boolean = false
    storageKey: string = "usuarioEmailModal"
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    constructor() {}

    ngOnInit(): void {}

    open() {
        this.openModal.emit()
    }
}
