<ng-container *ngFor="let tf of typeforms">
    <!--<h2>{{ typeform.nombre_referencia }}</h2>-->
    <div *ngIf="tf.typeform_widget_tipo.tipo === 'Popup'" class="print-hide">
        <button
            id="typeform-popup-button"
            style="display: none"
            [attr.data-tf-popup]="tf.codigo"
            [attr.data-tf-hidden]="'usuario = ' + usuarioId"
        >
            {{ "typeform.button" | t }}
        </button>
    </div>
    <div *ngIf="tf.typeform_widget_tipo.tipo === 'Side tab'" class="print-hide">
        <div
            [attr.data-tf-sidetab]="tf.codigo"
            [attr.data-tf-button-color]="tf.color_boton"
            [attr.data-tf-button-text]="tf.texto_boton ?? ('typeform.button' | t)"
            [attr.data-tf-hidden]="'usuario = ' + usuarioId"
        ></div>
    </div>
    <div *ngIf="tf.typeform_widget_tipo.tipo === 'Slider'" class="print-hide">
        <a [attr.data-tf-slider]="tf.codigo" [attr.data-tf-hidden]="'usuario = ' + usuarioId">
            {{ "typeform.button" | t }}
        </a>
    </div>
    <div *ngIf="tf.typeform_widget_tipo.tipo === 'Widget'" class="print-hide">
        <div
            [attr.data-tf-widget]="tf.codigo"
            [attr.data-tf-hide-headers]
            [attr.data-tf-hide-footer]
            [attr.data-tf-opacity]="'0'"
            style="width: 100%; height: 500px"
            [attr.data-tf-hidden]="'usuario = ' + usuarioId"
        ></div>
    </div>
</ng-container>
