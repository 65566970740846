import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { CUITextsModule, CUIInputsModule } from "@puntaje/shared/cui"

//import { MaterialesAsignaturasComponent } from './materiales_asignaturas.component';
import { UtilModule } from "@puntaje/shared/core"
import { MaterialAppsComponent } from "./material_apps.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { MaterialUDDComponent } from "./material_udd.component"
import { MaterialComponent } from "./material.component"
import { MaterialesComponent } from "./materiales.component"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { RecursosInteractivosComponent } from "./recursos_interactivos.component"
import { RouterModule } from "@angular/router"
import { BibliotecaComponent } from "./biblioteca.component"
import { MaterialesSaberComponent } from "./materiales_saber.component"
import { MaterialesSIMCEComponent } from "./materiales_simce.component"
import { MaterialesV1Component } from "./materiales-v1.component"
import { MaterialesThumbnailsComponent } from "./materiales-thumbnails.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialesModule,
        Angular2FontawesomeModule,
        UtilModule,
        SharedModule,
        CUITextsModule,
        CUIInputsModule,
        CUILayoutsModule,
        RouterModule,
        CommonsLayoutsModule
    ],
    declarations: [
        MaterialAppsComponent,
        MaterialUDDComponent,
        MaterialComponent,
        MaterialesComponent,
        RecursosInteractivosComponent,
        BibliotecaComponent,
        MaterialesSaberComponent,
        MaterialesSIMCEComponent,
        MaterialesV1Component,
        MaterialesThumbnailsComponent
    ],
    exports: [
        MaterialAppsComponent,
        MaterialUDDComponent,
        MaterialComponent,
        MaterialesComponent,
        RecursosInteractivosComponent,
        BibliotecaComponent,
        MaterialesSaberComponent,
        MaterialesSIMCEComponent,
        MaterialesV1Component,
        MaterialesThumbnailsComponent
    ]
})
export class PuntajeMaterialesModule {}
