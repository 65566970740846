import { Injectable, TemplateRef } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()
export class FullscreenService {
    templateRef$: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>()
    close$: Subject<any> = new Subject<any>()

    isFullscreen: boolean = false

    openFullscreen(templateRef: TemplateRef<any>) {
        this.templateRef$.next(templateRef)
    }

    closeFullscreen() {
        this.close$.next()
    }
}
