import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { AsignaturaEvaluacionTipoEstablecimiento } from "./asignatura_evaluacion_tipo_establecimientos.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class AsignaturaEvaluacionTipoEstablecimientos extends PuntajeBaseService<AsignaturaEvaluacionTipoEstablecimiento> {
    tableName = "asignatura_evaluacion_tipo_establecimientos"
    singularTableName = "asignatura_evaluacion_tipo_establecimiento"
    modelClass = AsignaturaEvaluacionTipoEstablecimiento

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
