import { BaseForm, Validations } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"

export class UsuariosCompletarRegistroChilePuntajeForm extends BaseForm {
    companyName: string = ""
    // constructor(){
    //  super();
    //  this.companyName = config.plataforma.info.companyName;
    // }

    public static get formParams(): any {
        return {
            usuario_id: { type: "hidden", visible: true },
            telefono: {
                label: "Teléfono",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateTelefonoChile]
            }
        }
    }
}
