import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { NgForm } from "@angular/forms"
import { Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    Clasificacion,
    Clasificaciones,
    GeneradorInstrumento,
    GeneradorInstrumentos
} from "@puntaje/nebulosa/api-services"
import { GenericModalComponent, SessionService } from "@puntaje/shared/core"
import {
    Evaluacion,
    EvaluacionMultiple,
    EvaluacionMultiples,
    EvaluacionMultipleUsuario,
    EvaluacionUsuario,
    GrupoUsuario,
    GrupoUsuarios,
    InstrumentoMultiple
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "compartir-instrumento-multiple-profesor",
    templateUrl: "./compartir-instrumento-multiple-profesor.component.html",
    styleUrls: ["./compartir-instrumento-multiple-profesor.component.scss"]
})
export class CompartirInstrumentoMultipleProfesorComponent implements OnInit {
    @Input() compartirTodosCursos: boolean
    @Input() modalId: string = "compartir"
    private _instrumentoMultiple: InstrumentoMultiple
    generadorInstrumento: GeneradorInstrumento
    generadorInstrumentosByInstrumentoId: { [id: number]: GeneradorInstrumento }
    evaluacionMultiple: EvaluacionMultiple = new EvaluacionMultiple()
    grupoUsuarios: GrupoUsuario[]
    gruposProfesor: GrupoUsuario[] = []
    cursos: Clasificacion[]
    @Input() openModal: EventEmitter<any>
    validationsPending: boolean = false
    resetForm: boolean = false
    @ViewChild("form") form: NgForm
    @Input() tipoEvaluacion: string
    @Input() redirect: string
    nombreGrupo: string
    // nombreGrupoPlural: string = "cursos";
    isAdmin: boolean = false
    shareWithEverybodyEver: boolean = false
    sharing: boolean = false
    @Input() enableAdminOptions: boolean = false

    @Output("evaluacionMultipleCompartida") onEvaluacionMultipleCompartida = new EventEmitter<EvaluacionMultiple>()

    @Input() closeOnFinished: boolean = false
    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent

    evaluacionMultipleCompartida: EvaluacionMultiple

    constructor(
        protected evaluacioneMultiplesService: EvaluacionMultiples,
        protected grupoUsuariosService: GrupoUsuarios,
        protected clasificacionesService: Clasificaciones,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected sessionService: SessionService,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias || "curso"
    }

    @Input()
    get instrumentoMultiple() {
        return this._instrumentoMultiple
    }

    set instrumentoMultiple(i: InstrumentoMultiple) {
        this._instrumentoMultiple = i

        i &&
            this.generadorInstrumentosService
                .find(this.instrumentoMultiple.generador_instrumento_id, {
                    include: "generador_instrumentos_hijos:tipo_instrumento"
                })
                .then((gi: GeneradorInstrumento) => {
                    this.generadorInstrumento = gi
                })
    }

    ngOnInit() {
        this.setDefaultValues()
        if (this.enableAdminOptions) this.checkAdmin()

        this.grupoUsuariosService
            .where({
                establecimiento_id_not_nil: 1,
                activos: 1,
                propios: 1,
                utp_establecimiento: 1,
                utp_asociacion: 1,
                grupo_usuario: { privado: 1 },
                grupo_usuario_usuario: { propietario: 1 },
                establecimiento: { activo: 1 }
            })
            .then((grupoUsuarios: GrupoUsuario[]) => {
                this.grupoUsuarios = grupoUsuarios
            })
        this.clasificacionesService
            .where({ clasificacion: { clasificacion_tipo_id: 1 } })
            .then((clasificaciones: Clasificacion[]) => {
                this.cursos = clasificaciones
            })
    }

    compartir() {
        if (!this.validateData()) {
            this.validationsPending = true
            return
        }

        this.sharing = true

        if (this.instrumentoMultiple) {
            this.compartirInstrumento(this.instrumentoMultiple, this.generadorInstrumento).then(
                (e: EvaluacionMultiple) => {
                    this.sharing = false
                    this.validationsPending = false
                    this.form.reset()
                    this.setDefaultValues()

                    this.evaluacionMultipleCompartida = e
                    this.onEvaluacionMultipleCompartida.emit(e)

                    if (this.closeOnFinished) {
                        this.cancelar()
                        this.genericModal.close()
                    }
                }
            )
        }
    }

    compartirInstrumento(instrumentoMultiple: InstrumentoMultiple, generadorInstrumento: GeneradorInstrumento) {
        this.evaluacionMultiple.instrumento_multiple_id = instrumentoMultiple.id
        this.evaluacionMultiple["generador_instrumento_id"] = generadorInstrumento.id
        ;(this.evaluacionMultiple as any).evaluacion_tipo = generadorInstrumento.tipo_instrumento.tipo_instrumento

        this.evaluacionMultiple.evaluaciones.forEach((e, i) => {
            e.evaluacion = "Sesion " + (i + 1) + " " + this.evaluacionMultiple.evaluacion_multiple
            ;(e as any).evaluacion_tipo = this.generadorInstrumento.tipo_instrumento.tipo_instrumento
        })

        if (!this.validateData()) {
            this.validationsPending = true
            return null
        }
        if (!this.compartirTodosCursos) {
            this.evaluacionMultiple.evaluacion_multiple_usuarios = this.gruposProfesor.map(
                (grupoUsuario: GrupoUsuario) => {
                    let eu = new EvaluacionMultipleUsuario()
                    eu.receptor_type = "GrupoUsuario"
                    eu.receptor_id = grupoUsuario.id

                    return eu
                }
            )

            this.evaluacionMultiple.evaluaciones.forEach(e => {
                e.evaluacion_usuarios = this.gruposProfesor.map((grupoUsuario: GrupoUsuario) => {
                    let eu = new EvaluacionUsuario()
                    eu.receptor_type = "GrupoUsuario"
                    eu.receptor_id = grupoUsuario.id

                    return eu
                })
            })
        } else {
            let evaluacionUsuario = new EvaluacionMultipleUsuario()
            evaluacionUsuario.receptor_type = "GrupoUsuario"
            evaluacionUsuario.receptor_id = null
            this.evaluacionMultiple.evaluacion_multiple_usuarios = [evaluacionUsuario]

            this.evaluacionMultiple.evaluaciones.forEach(e => {
                let evaluacionUsuario = new EvaluacionUsuario()
                evaluacionUsuario.receptor_type = "GrupoUsuario"
                evaluacionUsuario.receptor_id = null
                e.evaluacion_usuarios = [evaluacionUsuario]
            })
        }
        // if(this.evaluacion.presencial){
        //     delete this.evaluacion.fecha_inicio;
        //     delete this.evaluacion.fecha_termino;
        //     delete this.evaluacion.fecha_mostrar_respuestas;
        // }
        if (this.shareWithEverybodyEver) {
            let evaluacionUsuario = new EvaluacionMultipleUsuario()
            evaluacionUsuario.receptor_type = "Usuario"
            evaluacionUsuario.receptor_id = null
            this.evaluacionMultiple.evaluacion_multiple_usuarios = [evaluacionUsuario]

            this.evaluacionMultiple.evaluaciones.forEach(e => {
                let evaluacionUsuario = new EvaluacionUsuario()
                evaluacionUsuario.receptor_type = "Usuario"
                evaluacionUsuario.receptor_id = null
                e.evaluacion_usuarios = [evaluacionUsuario]
            })
        }
        return this.evaluacioneMultiplesService.save(this.evaluacionMultiple)
    }

    cancelar() {
        this.sharing = false
        this.validationsPending = false
        this.form && this.form.reset()
        this.setDefaultValues()
        setTimeout(() => {
            this.evaluacionMultipleCompartida = null
        }, 500)
    }

    goToListado() {
        this.evaluacionMultipleCompartida = null
        if (this.redirect) {
            this.router.navigate([this.redirect], {
                queryParams: { tipo_instrumento: this.generadorInstrumento.tipo_instrumento.tipo_instrumento }
            })
        } else if (this.instrumentoMultiple) {
            const evaluacionesMultiplesRoute = config.plataforma.evaluacionesMultiplesRoute || "evaluaciones_multiples"
            this.router.navigate([evaluacionesMultiplesRoute], {
                queryParams: { tipo_instrumento: this.generadorInstrumento.tipo_instrumento.tipo_instrumento }
            })
        }

        this.genericModal.close()
    }

    setDefaultValues() {
        //this.evaluacion.presencial = false;
        this.evaluacionMultiple.evaluaciones = this.instrumentoMultiple.instrumentos.map(i => {
            let e = new Evaluacion()
            e.instrumento_id = i.id

            return e
        })
        this.evaluacionMultiple.evaluaciones.forEach(e => {
            e.fecha_inicio = new Date()
            e.fecha_termino = new Date()
            e.fecha_termino.setDate(e.fecha_termino.getDate() + 7)
            e.fecha_mostrar_respuestas = new Date()
        })

        this.gruposProfesor = []
    }

    groupBy = (gu: GrupoUsuario) => gu.establecimiento.establecimiento

    validateData() {
        let hasFechaMostrarRespuestas = this.evaluacionMultiple.evaluaciones.every(e => !!e.fecha_mostrar_respuestas)

        return (
            this.evaluacionMultiple.evaluacion_multiple &&
            this.validateDateIntervalAll() &&
            hasFechaMostrarRespuestas &&
            (this.gruposProfesor.length > 0 || this.compartirTodosCursos || this.shareWithEverybodyEver)
        )
    }

    validateDateInterval(e: Evaluacion) {
        if (e.fecha_inicio && e.fecha_termino) {
            if ((e.fecha_termino as any).getTime() < (e.fecha_inicio as any).getTime()) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    validateDateIntervalAll() {
        return this.evaluacionMultiple.evaluaciones.every(this.validateDateInterval.bind(this))
    }

    checkAdmin() {
        let some_admin = this.sessionService.getRoles().find(r => {
            return r == "SuperAdministrador" || r == "Administrador" || r == "Monitor"
        })
        if (some_admin) {
            this.isAdmin = true
            //this.fetchBannedInfo();
        }
    }
}
