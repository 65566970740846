// import { Asignatura, AsignaturaConfig, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { HojaRespuestasActions, HojaRespuestasActionTypes } from "../actions/hoja_respuestas.actions"
import { ElementRef } from "@angular/core"
// import { config } from "config/config"

export interface ArchivoProcesado {
    file: any
    pos: number
    respuestas: { [id: number]: any }
    forma: any
    formaType: string
    idValue: any
    scanner?: any
    rutObj?: any
    formaObj?: any
    respuestasObj?: any
    editing: boolean
    messages: any
    instancia_id: number
    datosBoton: any
    loadingMessages: boolean
    goToViewPath?: string
    rawSectoresValues: any
    dimSectores?: any
    sectores?: any
}

export interface ArchivoProcesados {
    original: any[]
    good: { [id: number]: ArchivoProcesado }
    bad: { [id: number]: any }
    notImages: { [id: number]: any }
}

export interface State {
    lecturasProcesadas: number
    lecturasTotal: number
    loadingLecturas: boolean
    archivosProcesados: ArchivoProcesados
    canvases: { [index: number]: ElementRef }
    sectores: any[]
    formaGlobal: number
    processedAll: boolean
    selectedFile: number
    processedFiles: number
    enableThreshold: boolean
    inputFile: File
}

export const initialState: State = {
    lecturasProcesadas: 0,
    lecturasTotal: 0,
    loadingLecturas: false,
    archivosProcesados: { original: [], good: {}, bad: {}, notImages: {} },
    canvases: {},
    sectores: [],
    formaGlobal: null,
    processedAll: false,
    selectedFile: 0,
    processedFiles: 0,
    enableThreshold: false,
    inputFile: null
}

export function reducer(state = initialState, action: HojaRespuestasActions): State {
    switch (action.type) {
        case HojaRespuestasActionTypes.ResetHojaRespuestas:
            return {
                ...state,
                processedFiles: 0,
                archivosProcesados: {
                    original: [],
                    good: {},
                    bad: {},
                    notImages: {}
                },
                selectedFile: null,
                processedAll: false,
                canvases: null,
                lecturasProcesadas: 0,
                lecturasTotal: 0
            }

        case HojaRespuestasActionTypes.InitArchivosOriginales:
            return {
                ...state,
                archivosProcesados: { ...state.archivosProcesados, original: action.payload.files }
            }

        case HojaRespuestasActionTypes.InitCanvases:
            return {
                ...state,
                canvases: { ...action.payload.canvases }
            }

        case HojaRespuestasActionTypes.UpdateProcessed:
            const firstGood = Object.values(state.archivosProcesados.good)[0]
            const processedFiles = state.processedFiles + 1
            const processed = {
                processedFiles,
                processedAll: state.archivosProcesados.original.length == processedFiles,
                selectedFile: firstGood ? firstGood.pos : null
            }

            return {
                ...state,
                ...processed
            }

        case HojaRespuestasActionTypes.ProcessNotImage:
            return {
                ...state,
                archivosProcesados: {
                    ...state.archivosProcesados,
                    notImages: { ...state.archivosProcesados.notImages, [action.payload.index]: action.payload.file }
                }
            }

        case HojaRespuestasActionTypes.ProcessImage: {
            const { index, archivoProcesado, error } = action.payload

            if (!error) {
                return {
                    ...state,
                    archivosProcesados: {
                        ...state.archivosProcesados,
                        good: { ...state.archivosProcesados.good, [index]: archivoProcesado }
                    }
                }
            } else {
                console.log(error)

                return {
                    ...state,
                    archivosProcesados: {
                        ...state.archivosProcesados,
                        bad: { ...state.archivosProcesados.bad, [index]: state.archivosProcesados.original[index] }
                    }
                }
            }
        }

        case HojaRespuestasActionTypes.setValues: {
            let { index, respuestas, idValue, forma, formaType } = action.payload

            return {
                ...state,
                archivosProcesados: {
                    ...state.archivosProcesados,
                    good: {
                        ...state.archivosProcesados.good,
                        [index]: {
                            ...state.archivosProcesados.good[index],
                            respuestas: respuestas,
                            idValue: idValue,
                            forma: forma,
                            formaType: formaType
                        }
                    }
                }
            }
        }

        case HojaRespuestasActionTypes.SetValuesObjHojaRespuesta: {
            let { index, respuestasObj, rutObj, formaObj } = action.payload

            return {
                ...state,
                archivosProcesados: {
                    ...state.archivosProcesados,
                    good: {
                        ...state.archivosProcesados.good,
                        [index]: {
                            ...state.archivosProcesados.good[index],
                            respuestasObj,
                            rutObj,
                            formaObj
                        }
                    }
                }
            }
        }

        case HojaRespuestasActionTypes.SetRespuestas: {
            let { index, respuestas } = action.payload

            return {
                ...state,
                archivosProcesados: {
                    ...state.archivosProcesados,
                    good: {
                        ...state.archivosProcesados.good,
                        [index]: {
                            ...state.archivosProcesados.good[index],
                            respuestas
                        }
                    }
                }
            }
        }

        case HojaRespuestasActionTypes.SetObjRespuestas: {
            let { index, respuestasObj } = action.payload

            return {
                ...state,
                archivosProcesados: {
                    ...state.archivosProcesados,
                    good: {
                        ...state.archivosProcesados.good,
                        [index]: {
                            ...state.archivosProcesados.good[index],
                            respuestasObj
                        }
                    }
                }
            }
        }

        case HojaRespuestasActionTypes.processedAll: {
            return {
                ...state,
                processedAll: true,
                selectedFile: Object.values(state.archivosProcesados.good)[0].pos
            }
        }

        case HojaRespuestasActionTypes.initSubirLecturas: {
            return {
                ...state,
                lecturasProcesadas: 0,
                lecturasTotal: Object.values(state.archivosProcesados.good).length,
                loadingLecturas: true
            }
        }

        case HojaRespuestasActionTypes.finishSubirLecturas: {
            return {
                ...state,
                loadingLecturas: false
            }
        }

        case HojaRespuestasActionTypes.LecturaProcesada: {
            let { obj, index } = action.payload
            return {
                ...state,
                lecturasProcesadas: state.lecturasProcesadas + 1,
                loadingLecturas: state.lecturasProcesadas + 1 == state.lecturasTotal,
                archivosProcesados: {
                    ...state.archivosProcesados,
                    good: {
                        ...state.archivosProcesados.good,
                        [index]: {
                            ...state.archivosProcesados.good[index],
                            ...obj
                        }
                    }
                }
            }
        }

        case HojaRespuestasActionTypes.PreviousImage: {
            let selectedFile = state.selectedFile
            if (selectedFile !== null && selectedFile !== undefined && selectedFile > 0) {
                const archivosGood = state.archivosProcesados.good
                const archivosGoodKeys = Object.keys(archivosGood)
                let index = archivosGoodKeys.indexOf(selectedFile + "")
                if (index > 0) {
                    selectedFile = archivosGood[archivosGoodKeys[index - 1]].pos
                }
            }

            return {
                ...state,
                selectedFile: selectedFile
            }
        }

        case HojaRespuestasActionTypes.NextImage: {
            let selectedFile = state.selectedFile
            if (
                selectedFile !== null &&
                selectedFile !== undefined &&
                selectedFile < Object.values(state.archivosProcesados.good).length - 1
            ) {
                const archivosGood = state.archivosProcesados.good
                const archivosGoodKeys = Object.keys(archivosGood)
                let index = archivosGoodKeys.indexOf(selectedFile + "")
                if (index > -1) {
                    selectedFile = archivosGood[archivosGoodKeys[index + 1]].pos
                }
            }
            return {
                ...state,
                selectedFile: selectedFile
            }
        }

        case HojaRespuestasActionTypes.ChangeSelectedFile: {
            return {
                ...state,
                selectedFile: action.payload.selectedFile
            }
        }

        case HojaRespuestasActionTypes.ChangeIdValuePart: {
            let { indexFile, indexPart, idValuePart } = action.payload
            let idValue = { ...state.archivosProcesados.good[indexFile].idValue }
            idValue[indexPart] = idValuePart
            idValue = Object.values(idValue)

            return {
                ...state,
                archivosProcesados: {
                    ...state.archivosProcesados,
                    good: {
                        ...state.archivosProcesados.good,
                        [indexFile]: {
                            ...state.archivosProcesados.good[indexFile],
                            idValue
                        }
                    }
                }
            }
        }

        case HojaRespuestasActionTypes.ChangeForma: {
            let { index, forma } = action.payload
            return {
                ...state,
                archivosProcesados: {
                    ...state.archivosProcesados,
                    good: {
                        ...state.archivosProcesados.good,
                        [index]: {
                            ...state.archivosProcesados.good[index],
                            forma
                        }
                    }
                }
            }
        }

        case HojaRespuestasActionTypes.ChangeFormaType: {
            let { index, formaType } = action.payload
            return {
                ...state,
                archivosProcesados: {
                    ...state.archivosProcesados,
                    good: {
                        ...state.archivosProcesados.good,
                        [index]: {
                            ...state.archivosProcesados.good[index],
                            formaType
                        }
                    }
                }
            }
        }

        case HojaRespuestasActionTypes.ChangeFileEditing: {
            let index = action.payload.index
            let editing =
                typeof action.payload.editing === "boolean"
                    ? action.payload.editing
                    : !state.archivosProcesados.good[index].editing
            return {
                ...state,
                archivosProcesados: {
                    ...state.archivosProcesados,
                    good: {
                        ...state.archivosProcesados.good,
                        [index]: {
                            ...state.archivosProcesados.good[index],
                            editing: editing
                        }
                    }
                }
            }
        }

        case HojaRespuestasActionTypes.ChangeFormaGlobal: {
            return {
                ...state,
                formaGlobal: action.payload.formaGlobal
            }
        }

        case HojaRespuestasActionTypes.ChangeEnableThreshold: {
            return {
                ...state,
                enableThreshold: action.payload.enableThreshold
            }
        }

        case HojaRespuestasActionTypes.ChangeInputFile: {
            return {
                ...state,
                inputFile: action.payload.file
            }
        }

        default:
            return state
    }
}
