import { BaseModel } from "@puntaje/shared/core"

export class Tutor extends BaseModel {
    public email: string
    public usuario_id: number
    public tutor_id: number
    public confirmado: boolean

    public toString() {
        return this.email
    }
}
