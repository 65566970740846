import { AudioView } from "./audioview.component"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { UtilModule } from "../util/util.module"
import { RouterModule } from "@angular/router"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"

// layouts
import { ModelSimpleViewLayout } from "./model_simple_view_layout.component"
import { ModelTableCellLayout } from "./model_table_cell_layout.component"
import { ViewLayout } from "./view_layout.component"
import { ModelPlainValueLayout } from "./model_plain_value_layout.component"

// components
import { BooleanView } from "./booleanview.component"
import { DateView } from "./dateview.component"
import { DownloadView } from "./downloadview.component"
import { EntitiesView } from "./entitiesview.component"
import { EntityView } from "./entityview.component"
import { HtmlView } from "./htmlview.component"
import { LinkOrDownloadView } from "./linkordownloadview.component"
import { LinkView } from "./linkview.component"
import { ModelPlainValueComponent } from "./model_plain_value.component"
import { ModelSimpleViewComponent } from "./model_simple_view.component"
import { ModelTableCellComponent } from "./model_table_cell.component"
import { ModelViewComponent } from "./model_view.component"
import { MultipleLinkView } from "./multiple_link_view.component"
import { PdfView } from "./pdfview.component"
import { TextView } from "./textview.component"
import { YoutubeVideoView } from "./youtubevideoview.component"
import { ThumbnailView } from "./thumbnailview.component"
import { GameView } from "./gameview.component"
import { SemaphoreView } from "./semaphoreview.component"
import { ArrayView } from "./arrayview.component"
import { MultipleArtezonaView } from "./multiple_artezona_view.component"

// others
import { View } from "./view.component"
import { ViewTags } from "./view_layout.component"
import { GrupoPreguntaTextoView } from "./grupopreguntatextoview.component"
import { VideoView } from "./videoview.component"
import { DocsView } from "./docsview.component"
import { NgxDocViewerModule } from "ngx-doc-viewer"
import { PdfJsViewerModule } from "ng2-pdfjs-viewer"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

@NgModule({
    imports: [
        CommonModule,
        UtilModule,
        RouterModule,
        ProgressbarModule.forRoot(),
        NgxDocViewerModule,
        PdfJsViewerModule,
        Angular2FontawesomeModule
    ],
    declarations: [
        BooleanView,
        DateView,
        DownloadView,
        EntitiesView,
        EntityView,
        HtmlView,
        LinkOrDownloadView,
        LinkView,
        ModelPlainValueComponent,
        ModelPlainValueLayout,
        ModelSimpleViewComponent,
        ModelSimpleViewLayout,
        ModelTableCellComponent,
        ModelTableCellLayout,
        MultipleLinkView,
        PdfView,
        //PdfViewerComponent,
        TextView,
        ViewTags,
        YoutubeVideoView,
        ThumbnailView,
        GameView,
        SemaphoreView,
        ArrayView,
        GrupoPreguntaTextoView,
        AudioView,
        MultipleArtezonaView,
        VideoView,
        DocsView
    ],
    exports: [ModelSimpleViewComponent, ModelTableCellComponent, ModelPlainValueComponent, PdfView, ViewTags]
})
export class ModelViewModule {}
