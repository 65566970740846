import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Asignatura } from "./asignaturas.model"
import { Persona } from "./personas.model"
import { Plataforma } from "./plataformas.model"

export class StreamingHorario extends BaseModel {
    public static className: string = "StreamingHorario"
    public asignatura_id: number
    public plataforma_id: number
    public persona_id: number
    public descripcion: string
    public horario: string
    public created_at: Date
    public updated_at: Date

    to_platform_timezone: string

    @SaveType(() => Asignatura) public asignatura: Asignatura
    @SaveType(() => Plataforma) public plataforma: Plataforma
    @SaveType(() => Persona) public persona: Persona

    public toString() {
        return this.descripcion
    }
}
