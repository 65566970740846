import { Component, OnInit, Renderer2, ElementRef } from "@angular/core"
import { trigger, transition, style, animate, keyframes, query } from "@angular/animations"
import { Subscription } from "rxjs"
import * as confetti from "canvas-confetti"
import { Store, select } from "@ngrx/store"
import { selectLogros, State, ResetAlertaLogros } from "@puntaje/puntaje/store"
import { Logro } from "@puntaje/achievements/api-services"
import { TabService, AuthService } from "@puntaje/shared/core"
import { Usuario } from "@puntaje/puntaje/api-services"

@Component({
    selector: "alerta-box",
    templateUrl: "alerta_box.component.html",
    styleUrls: ["alerta_box.component.scss"],
    animations: [
        trigger("SlideInAndOut", [
            transition("* => *", [
                query(
                    ":leave",
                    [
                        animate(
                            "0.3s 0ms ease",
                            keyframes([
                                style({ opacity: 1, transform: "translateX(0)", offset: 0 }),
                                style({ opacity: 0, transform: "translatex(-100px)", offset: 1.0 })
                            ])
                        )
                    ],
                    { optional: true }
                ),
                query(
                    ":enter",
                    [
                        animate(
                            "0.5s 0ms ease",
                            keyframes([
                                style({ opacity: 0, transform: "translateX(280px)", offset: 0 }),
                                style({ opacity: 1, transform: "translatex(0)", offset: 1.0 })
                            ])
                        )
                    ],
                    { optional: true }
                )
            ])
        ])
    ]
})
export class AlertaBoxComponent implements OnInit {
    sub: Subscription
    alertas: any[] = []
    usuario: Usuario

    constructor(
        protected tabService: TabService,
        protected authService: AuthService,
        private renderer2: Renderer2,
        private elementRef: ElementRef,
        private store: Store<State>
    ) {
        this.usuario = this.authService.getUserData()
    }

    ngOnInit() {
        this.store.pipe(select(selectLogros)).subscribe((logros: Logro[]) => {
            if (logros?.length > 0) {
                this.setAlertas(logros)
                this.tabService.setComponentTab("UsuarioComponent", "logros")
            }
        })
    }

    setAlertas(logros) {
        this.alertas = logros.map(res => {
            return { id: res.id, titulo: res.logro, subtitle: res.descripcion, logo: res.logo }
        })
        this.surprise()
        setTimeout(() => {
            this.alertas = []
            this.store.dispatch(new ResetAlertaLogros())
        }, 8000)
    }

    dismissAlert(alerta: any) {
        let index = this.alertas.findIndex(a => a.id == alerta.id)
        this.alertas.splice(index, 1)
    }

    surprise() {
        const canvas = this.renderer2.createElement("canvas")
        canvas.style = "z-index: 20;"
        this.renderer2.appendChild(this.elementRef.nativeElement, canvas)

        const myConfetti = confetti.create(canvas, {
            resize: true
        })

        myConfetti({
            particleCount: 150,
            spread: 90,
            zIndex: 100,
            origin: { y: 0.5 },
            colors: ["#F1C40F", "#8E44AD", "#D35400", "#28B463", "#2E86C1"]
        })
        setTimeout(() => {
            this.renderer2.removeChild(this.elementRef.nativeElement, canvas)
            myConfetti.reset()
        }, 4000)
    }
}
