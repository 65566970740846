import { ChangeDetectorRef, Component, Directive, NgZone, OnInit } from "@angular/core"
import { SimpleModalService } from "./simple_modal.service"
import { Subject, Subscription } from "rxjs"
import { I18nService } from "@puntaje/shared/core"

declare var $: any

/*#################################

Template de modal simple de bootstrap. Puede llamarse a través de un servicio.

#################################*/

@Component({
    templateUrl: "simple_modal.component.html",
    selector: "simple-modal",
    styleUrls: ["simple_modal.component.scss"]
})
export class SimpleModalComponent implements OnInit {
    public header: string
    public contentString: string
    public contentHTML: string
    headerSubscription: Subscription
    contentStringSubscription: Subscription
    contentHTMLSubscription: Subscription
    callbackBtnTextSubscription: Subscription
    disableBtnCancelSubscription: Subscription
    hideBtnXSubscription: Subscription

    public disableBtnCancel: boolean = false
    public callbackBtnText: string = this.i18nService.translate("simple_modal.ok")
    public hideBtnX: boolean = false

    actionType: "ok" | "cancel" = "cancel"

    constructor(
        private simpleModalService: SimpleModalService,
        protected ngZone: NgZone,
        protected i18nService: I18nService
    ) {}

    onHideModal = () => {
        this.ngZone.run(() => {
            if (this.actionType == "ok") {
                this.callOkCallback()
            } else {
                this.callCancelCallback()
            }

            this.actionType = "cancel"
        })
    }

    ngOnInit() {
        this.header = this.simpleModalService.header
        this.contentString = this.simpleModalService.contentString
        this.callbackBtnText = this.simpleModalService.callbackBtnText
        this.disableBtnCancel = this.simpleModalService.disableBtnCancel
        this.hideBtnX = this.simpleModalService.hideBtnX

        this.headerSubscription = this.simpleModalService.headerSubject.subscribe(header => (this.header = header))
        this.contentStringSubscription = this.simpleModalService.contentStringSubject.subscribe(
            contentString => (this.contentString = contentString)
        )
        this.contentHTMLSubscription = this.simpleModalService.contentHTMLSubject.subscribe(
            contentHTML => (this.contentHTML = contentHTML)
        )
        this.callbackBtnTextSubscription = this.simpleModalService.callbackBtnTextSubject.subscribe(
            callbackBtnText => (this.callbackBtnText = callbackBtnText)
        )

        this.disableBtnCancelSubscription = this.simpleModalService.disableBtnCancelSubject.subscribe(disable => {
            this.disableBtnCancel = disable
        })
        this.hideBtnXSubscription = this.simpleModalService.hideBtnXSubject.subscribe(hide => (this.hideBtnX = hide))

        $("#simpleModal").on("hidden.bs.modal", this.onHideModal)
    }

    onClickOk() {
        this.actionType = "ok"
    }

    onClickCancel() {
        this.actionType = "cancel"
    }

    callOkCallback() {
        if (typeof this.simpleModalService.callback === "function") {
            this.simpleModalService.callback()
        }

        setTimeout(() => {
            this.cleanModal()
        }, 1000)
    }

    callCancelCallback() {
        if (typeof this.simpleModalService.cancelback === "function") {
            this.simpleModalService.cancelback()
        }

        setTimeout(() => {
            this.cleanModal()
        }, 1000)
    }

    cleanModal() {
        this.simpleModalService.cleanModal()
    }

    ngOnDestroy() {
        this.headerSubscription.unsubscribe()
        this.contentStringSubscription.unsubscribe()
        this.contentHTMLSubscription.unsubscribe()
        this.callbackBtnTextSubscription.unsubscribe()
        this.disableBtnCancelSubscription.unsubscribe()
        this.hideBtnXSubscription.unsubscribe()

        $("#simpleModal").off("hidden.bs.modal", this.onHideModal)
    }
}
