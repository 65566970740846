import { BaseModel } from "./base.model"
import { BaseService, CountPromise } from "./base.service"

import { AuthService } from "../auth/auth.service"
import { ErroresService } from "../util/error/errores.service"
import { Subject } from "rxjs"
import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { S3 } from "./s3.model"
import { AppConfig } from "../conf/app_config"
import { AppEnv } from "../conf/app_env"

@Injectable()
export class S3Service extends BaseService<S3> {
    tableName = "get_s3_policy"
    singularTableName = "policy_response"
    modelClass = S3
    xhr_upload: Subject<XMLHttpRequest> = new Subject<XMLHttpRequest>()
    nebuEnabled: boolean = false
    oldAuth: AuthService

    constructor(
        protected http: HttpClient,
        protected auth: AuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected config: AppConfig,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public find(id: number): CountPromise<S3> {
        if (!this.canConcat || this.canConcat) {
            throw new Error("Method 'find(...)' not allowed for S3 service.")
        }
        return this.get()
    }

    public save(objectQuery: BaseModel): CountPromise<S3> {
        if (!this.canConcat || this.canConcat) {
            throw new Error("Method 'save(...)' not allowed for S3 service.")
        }
        return this.get()
    }

    public update(id: number, toUpdate: BaseModel): CountPromise<S3> {
        if (!this.canConcat || this.canConcat) {
            throw new Error("Method 'update(...)' not allowed for S3 service.")
        }
        return this.get()
    }

    public delete(id: number): CountPromise<S3 & S3[]> {
        if (!this.canConcat || this.canConcat) {
            throw new Error("Method 'delete(...)' not allowed for S3 service.")
        }
        return this.get()
    }

    public uploadToS3(policy: S3, file: File, redirect: string, fun: (value?: unknown) => void) {
        var xhr = new XMLHttpRequest()
        this.xhr_upload.next(xhr)
        let data: FormData = policy.getFormData()
        data.append("file", file)
        xhr.onreadystatechange = () => {
            if (xhr.readyState == XMLHttpRequest.DONE) {
                fun()
            }
        }
        xhr.open("POST", this.nebuEnabled ? this.config.amazon.s3BucketUriNebu : this.config.amazon.s3BucketUri, true)
        xhr.send(data)
    }

    // Métodos usados en puntaje para subir pruebas propias como materiales
    enableNebuBaseUrl() {
        this.nebuEnabled = true
        this.baseURL = this.environment.endpoints.nebulosa
    }

    disableNebuBaseUrl() {
        this.nebuEnabled = false
        this.baseURL = this.environment.endpoints.base
    }

    changeAuthService(auth: AuthService) {
        this.oldAuth = this.auth
        this.auth = auth
    }

    restoreAuthService() {
        this.auth = this.oldAuth
    }
}
