<loading-layout #loadingLayout>
    <div class="locked-wrapper" *ngIf="lockedContent">
        <div class="locked-content">
            <div class="padlock">
                <fa name="lock" class="icono-padlock"></fa>
            </div>
            <p class="locked-info">
                Si tu colegio tiene contratados los servicios de {{ companyName }}, tus profesores podrán compartirte
                {{ "este" | genderize: evaluacionTipo }} {{ evaluacionTipo | transformInstrumento: "singularSimple" }}
            </p>
        </div>
    </div>
    <div class="evaluacion-card" [ngClass]="{ blurred: lockedContent }">
        <div class="content-icon-descripcion">
            <ogr-icon name="prueba" class="icono"></ogr-icon>
            <span class="descripcion">{{ evaluacionSugerida.nombre }}</span>
        </div>
        <ng-container
            *ngFor="
                let evaluacion_multiple_evaluacion of evaluacionSugerida.evaluacion_multiple
                    .evaluacion_multiple_evaluaciones;
                let i = index
            "
        >
            <span class="sesion">Sesión {{ i + 1 }}</span>
            <div class="content-question-time-button">
                <div class="count-question">
                    <fa class="fa" name="pencil-square-o"></fa>
                    {{ evaluacion_multiple_evaluacion.evaluacion.instrumento.numero_preguntas }}preg.
                </div>
                <div class="time">
                    <fa class="fa" name="clock-o"></fa>
                    {{ evaluacion_multiple_evaluacion.evaluacion.instrumento.tiempo }}min.
                </div>
                <div>
                    <cui-button-link
                        class="btn_style btn-sm tabla-btn"
                        [routerLinkValue]="
                            !lockedContent
                                ? ['/ensayos', evaluacion_multiple_evaluacion.evaluacion.id, 'realizar']
                                : null
                        "
                    >
                        Seleccionar
                    </cui-button-link>
                </div>
            </div>
        </ng-container>
    </div>
</loading-layout>
