import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "paginator.component.html",
    styleUrls: ["paginator.component.scss"],
    selector: "paginator"
})
export class PaginatorComponent implements OnInit, OnDestroy {
    @Input() getList: (page: number, per: number) => Promise<number>
    @Input() per: number
    @Input() pageRange: number
    @Input() useQueryParams: boolean = false
    @Input() maxPage: number
    @Input() extraMiniMode: boolean = false
    @Input() resultadosStringMapping: { [k: string]: string } = {
        "=1": "resultado",
        other: "resultados"
    }

    currentPage: number
    total: number
    pages: number

    first: number
    last: number

    sub: Subscription
    @Output() onGetPage = new EventEmitter()

    constructor(protected router: Router, protected route: ActivatedRoute) {}

    ngOnInit() {
        if (!this.per) this.per = 10

        if (!this.pageRange) this.pageRange = 5

        if (this.useQueryParams) {
            this.sub = this.route.queryParams.subscribe(query => {
                this.per = +query["per"] || this.per
                let page = +query["page"] || 1

                this.getPage(page)
            })
        } else {
            this.changePage(1)
        }
    }

    public changePage(page: number) {
        if (this.useQueryParams) {
            this.router.navigate(["/" + this.route.url["value"].map(v => v.path).join("/")], {
                queryParams: { page: page, per: this.per }
            })
            if (this.currentPage == page) this.getPage(page)
        } else {
            this.getPage(page)
        }

        return false
    }

    getPage(page: number) {
        this.onGetPage.emit(true)
        this.getList(page, this.per).then(total => {
            this.total = total
            this.pages = Math.ceil(this.total / this.per)
            if (this.maxPage && this.maxPage < this.pages) this.pages = this.maxPage
            this.currentPage = page

            this.first = (this.currentPage - 1) * this.per + 1
            this.last = Math.min(this.currentPage * this.per, this.total)
            this.onGetPage.emit(false)
        })
    }

    range() {
        let initPage: number, endPage: number
        if (this.currentPage - this.pageRange / 2 <= 1) {
            initPage = 1
            endPage = Math.min(this.pageRange, this.pages)
        } else {
            initPage = this.currentPage - ~~(this.pageRange / 2)
        }

        if (this.currentPage + this.pageRange / 2 >= this.pages) {
            endPage = this.pages
        } else {
            endPage = endPage || this.currentPage + ~~(this.pageRange / 2)
        }

        const range = []
        for (let i = initPage; i < endPage + 1; i++) {
            range.push(i)
        }

        return range
    }

    min(x, y) {
        if (x > y) {
            return y
        } else {
            return x
        }
    }

    public reload() {
        this.changePage(this.currentPage || 1)
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
    }
}
