import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"
import { TipoInstrumento } from "./tipo_instrumentos.model"

@Injectable()
export class TipoInstrumentos extends NebuBaseService<TipoInstrumento> {
    tableName = "tipo_instrumentos"
    singularTableName = "tipo_instrumento"
    protected objectQuery: TipoInstrumento
    modelClass = TipoInstrumento

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
