<div *ngIf="typePlatform == 'profesor'">
    <ng-container *ngIf="correccionClasses$ | async as clases">
        <ul class="nav nav-tabs" #tabList>
            <li role="presentation" [ngClass]="clases['imagen-multi']">
                <a (click)="selectTab('imagen-multi'); camaraActive = false">
                    {{ "corregir_ensayos_tabs_profesor.partir_imagenes" | t }}
                </a>
            </li>
            <li role="presentation" [ngClass]="clases['csv']">
                <a data-toggle="tab" href="#csv" (click)="selectTab('csv'); camaraActive = false">
                    {{ "corregir_ensayos_tabs_profesor.partir_csv" | t }}
                </a>
            </li>
            <li role="presentation" [ngClass]="clases['camara']">
                <a (click)="selectTab('camara'); camaraActive = true">
                    {{ "corregir_ensayos_tabs_profesor.partir_camara" | t }}
                </a>
            </li>
        </ul>
    </ng-container>
</div>

<div *ngIf="typePlatform == 'alumnoOne'">
    <ng-container *ngIf="correccionClasses$ | async as clases">
        <ul class="nav nav-tabs" #tabList>
            <li role="presentation" [ngClass]="clases['camara']">
                <a (click)="selectTab('camara'); camaraActive = true">
                    {{ "corregir_ensayos_tabs_profesor.partir_camara" | t }}
                </a>
            </li>
            <li role="presentation" [ngClass]="clases['imagen']">
                <a (click)="selectTab('imagen'); camaraActive = false">
                    {{ "corregir_ensayos_tabs_profesor.partir_imagen" | t }}
                </a>
            </li>
        </ul>
    </ng-container>
</div>

<div *ngIf="typePlatform == 'alumnoTwo'">
    <ng-container *ngIf="correccionClasses$ | async as clases">
        <ul class="nav nav-tabs" #tabList>
            <li role="presentation" [ngClass]="clases['camara']">
                <a (click)="selectTab('camara'); camaraActive = false">
                    {{ "corregir_ensayos_tabs_profesor.partir_camara" | t }}
                </a>
            </li>
            <li role="presentation" [ngClass]="clases['imagen']">
                <a (click)="selectTab('imagen'); camaraActive = true">
                    {{ "corregir_ensayos_tabs_profesor.partir_imagen" | t }}
                </a>
            </li>
        </ul>
    </ng-container>
</div>

<div *ngIf="typePlatform == 'alumnoThree'">
    <ng-container *ngIf="correccionClasses$ | async as clases">
        <ul class="nav nav-tabs" #tabList>
            <li role="presentation" [ngClass]="clases['camara']">
                <a (click)="selectTab('camara'); camaraActive = true">
                    {{ "corregir_ensayos_tabs_profesor.partir_camara" | t }}
                </a>
            </li>
            <li role="presentation" [ngClass]="clases['imagen-multi']">
                <a (click)="selectTab('imagen-multi'); camaraActive = false">
                    {{ "corregir_ensayos_tabs_profesor.partir_imagen" | t }}
                </a>
            </li>
            <li role="presentation" [ngClass]="clases['csv']">
                <a data-toggle="tab" href="#csv" (click)="selectTab('csv'); camaraActive = false">
                    {{ "corregir_ensayos_tabs_profesor.partir_csv" | t }}
                </a>
            </li>
        </ul>
    </ng-container>
</div>

<div *ngIf="typePlatform == 'alumnoFour'">
    <ng-container *ngIf="correccionClasses$ | async as clases">
        <ul class="nav nav-tabs" #tabList>
            <li role="presentation" [ngClass]="clases['imagen']">
                <a (click)="selectTab('imagen'); camaraActive = true">
                    {{ "corregir_ensayos_tabs_profesor.partir_imagen" | t }}
                </a>
            </li>
        </ul>
    </ng-container>
</div>
