import { Injectable } from "@angular/core"
import { HttpHeaders, HttpClient } from "@angular/common/http"
import { FacebookService } from "@ogr-sa/ngx-facebook"
import { AppConfig, AppEnv, AuthService, SessionService } from "@puntaje/shared/core"

@Injectable()
export class NebuAuthService extends AuthService {
    /* The auth provided here refers to the auth system of the main app, and is the one
     * that handles the request to get the token from nebulosa
     */
    constructor(
        protected http: HttpClient,
        protected fb: FacebookService,
        private auth: AuthService,
        protected sessionService: SessionService,
        protected config: AppConfig,
        protected environment: AppEnv
    ) {
        super(http, fb, null, sessionService, config, environment)
        this.userProfile = "profile1"
        this.authTokenName = "auth_token_nebuloso"
        this.authUriPath = "/authenticate"
        this.apiTokenKeyName = "auth_token"
        this.apiUserKey = "user"
    }

    public getTokenFromAPI(): Promise<string> {
        var header = new HttpHeaders({ Authorization: this.auth.getToken() })
        var opts = { headers: header }
        var promise = this.http.get(this.environment.endpoints.base + "/get_nebu_token", opts).toPromise()
        return promise.then((response: any) => {
            this.setToken(response.auth_token)
            return this.getToken()
        })
    }
}
