import { Component, OnInit, Input, HostBinding } from "@angular/core"
import { Taxativo } from "@puntaje/nebulosa/api-services"
import { GrupoPreguntaTextoService } from "@puntaje/shared/core"

@Component({
    selector: "pregunta-taxativo",
    templateUrl: "./pregunta-taxativo.component.html",
    styleUrls: ["./pregunta-taxativo.component.scss"]
})
export class PreguntaTaxativoComponent implements OnInit {
    @Input() taxativo: string
    @Input() grupoPreguntaId: number

    @HostBinding("class.make-inline") get isInline() {
        return this.makeFirstChildInline || this.makeLastChildInline
    }

    @Input() makeFirstChildInline: boolean = false
    @Input() makeLastChildInline: boolean = false

    constructor(public grupoPreguntaTextoService: GrupoPreguntaTextoService) {}

    ngOnInit() {}
}
