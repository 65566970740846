// Angular
import { CommonModule } from "@angular/common"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { HttpClientModule } from "@angular/common/http"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { UtilModule, FormModule, ModelViewModule, FlashMessageModule, PaginatorModule } from "@puntaje/shared/core"

import { TutoresComponent } from "./tutores.component"
import { CUIInputsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        PaginatorModule,
        FormModule,
        ModelViewModule,
        CommonsLayoutsModule,
        Angular2FontawesomeModule,
        FlashMessageModule,
        RouterModule,
        CUIInputsModule
    ],
    declarations: [TutoresComponent],
    exports: [TutoresComponent]
})
export class TutoresModule {}
