import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Injectable()
export class EstablecimientosBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    constructor() {}

    search(
        establecimiento,
        establecimientoMonitor,
        establecimientoPais,
        idEstablecimientoNombre,
        idEstablecimientoKey,
        fechaInicialUso,
        fechaFinalUso,
        csvName = null
    ) {
        let params: any = {}

        let establecimiento_monitor = Object.assign({}, establecimientoMonitor)
        establecimientoPais = Object.assign({}, establecimientoPais)
        establecimiento = Object.assign({}, establecimiento)

        Object.keys(establecimientoPais).forEach(k => {
            if (establecimientoPais[k] === "" || establecimientoPais[k] === undefined) delete establecimientoPais[k]
        })

        Object.keys(establecimiento).forEach(k => {
            if (establecimiento[k] === "" || establecimiento[k] === undefined) delete establecimiento[k]
        })

        Object.keys(establecimiento_monitor).forEach(k => {
            if (establecimiento_monitor[k] === "" || establecimiento_monitor[k] === undefined)
                delete establecimiento_monitor[k]
        })

        if (establecimiento.id && establecimiento.id.indexOf(",") != -1) {
            establecimiento.id = establecimiento.id.split(/\s*,\s*/)
        }

        if (establecimiento.establecimiento) {
            establecimiento.like = { establecimiento: establecimiento.establecimiento }
            delete establecimiento.establecimiento
        }

        if (establecimientoPais[idEstablecimientoKey] && establecimientoPais[idEstablecimientoKey].indexOf(",") != -1) {
            establecimientoPais[idEstablecimientoKey] = establecimientoPais[idEstablecimientoKey].split(/\s*,\s*/)
        }

        params = {
            establecimiento,
            establecimiento_monitor,
            ["establecimiento_" + config.plataforma.pais]: establecimientoPais
        }
        if (fechaInicialUso && fechaFinalUso) {
            params.fecha_inicial_uso = fechaInicialUso
            params.fecha_final_uso = fechaFinalUso
        }

        if (csvName) {
            params.to_csv = csvName
        }

        this.searchParams.next(params)
    }
}
