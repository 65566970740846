import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { PremiumUsuario } from "./premium_usuarios.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class PremiumUsuarios extends PuntajeBaseService<PremiumUsuario> {
    tableName = "premium_usuarios"
    singularTableName = "premium_usuario"
    modelClass = PremiumUsuario

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
