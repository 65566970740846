<cui-section-headline>Puntajes promedio</cui-section-headline>
<loading-layout>
    <div class="wrap-contenido" *ngIf="!loadingLayout.loading">
        <table class="table promedios-table" id="tabla-promedios-nolibres">
            <thead>
                <th></th>
                <th class="text-center">Promedio</th>
                <th class="text-center">Simular</th>
            </thead>
            <tbody>
                <ng-container *ngFor="let p of ponderables">
                    <tr *ngIf="!p.libre">
                        <td>{{ p.ponderable }}</td>
                        <td class="promedio text-center">
                            {{ promedioByPonderable[p.ponderable] | number: "1.0-0" | easyplaceholder: "-" }}
                        </td>
                        <td>
                            <input
                                [value]="simulacionByPonderable[p.ponderable]"
                                (input)="updateSimulacion($event, p)"
                                min="{{ p.valorMinimo || 1 }}"
                                max="{{ p.valorMaximo || 850 }}"
                                class="form-control simulacion-input text-center"
                                type="number"
                            />
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <p class="help" [popover]="msj" [outsideClick]="true" placement="top">
            Cómo se calculan los puntajes promedio
            <fa name="question-circle"></fa>
        </p>
        <hr class="separador" />
        <table class="table promedios-table" id="tabla-promedios-libres">
            <tbody>
                <ng-container *ngFor="let p of ponderables">
                    <tr *ngIf="p.libre">
                        <td>{{ p.alias || p.ponderable }}</td>
                        <td class="promedio" *ngIf="!editing">
                            {{ usuario[usuarioPaisString][p.campoOrigen] | easyplaceholder: "-" }}&nbsp;
                            <ng-template #helpTemplate><div [innerHTML]="p.tooltip"></div></ng-template>
                            <a
                                href="javascript:void(0);$event.stopPropagation()"
                                [popover]="helpTemplate"
                                [outsideClick]="true"
                            >
                                <fa [name]="'question-circle-o'" name="question-circle"></fa>
                            </a>
                        </td>
                        <td class="promedio" *ngIf="editing">
                            <input
                                type="number"
                                [min]="p.valorMinimo"
                                [max]="p.valorMaximo"
                                class="form-control"
                                [(ngModel)]="usuario[usuarioPaisString][p.campoOrigen]"
                            />
                            <!-- {{ p.valor | easyplaceholder: "-" }} -->
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <div class="btn-bar">
            <cui-button *ngIf="!editing" (click)="editing = true" class="btn_style">
                Editar
                <fa class="icono" name="pencil"></fa>
            </cui-button>
            <cui-button *ngIf="editing" (click)="saveUsuario()" class="btn_style">
                Guardar
                <fa class="icono" name="pencil"></fa>
            </cui-button>
        </div>
    </div>
</loading-layout>
