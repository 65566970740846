<div class="plan-estudio-navbar" *ngIf="planPersonal">
    <div class="pe-row">
        <div class="pe-cell cell-inicio">
            <ng-container *ngIf="enableNavigateToPlan; else defaultNavigation">
                <a class="menuBtn" [routerLink]="['/plan_personal/', planPersonal.id]">
                    <ogr-icon name="plan-estudio"></ogr-icon>
                </a>
            </ng-container>
            <ng-template #defaultNavigation>
                <a
                    class="menuBtn"
                    [routerLink]="planesPersonalesRoute"
                    [queryParams]="{
                        asignatura_id: planPersonal.generador_instrumento.asignatura.id,
                        generador_instrumento_id: planPersonal.generador_instrumento.id
                    }"
                >
                    <ogr-icon aria-label="volver" name="plan-estudio"></ogr-icon>
                </a>
            </ng-template>
        </div>
        <div class="pe-cell cell-previous">
            <button *ngIf="enablePrevious" class="previousBtn btn btn-default" (click)="goToPrevious()">
                <fa class="icono" name="chevron-left"></fa>
                <span>&nbsp;{{ "estudiar.plan_personales.plan_personal_sesion." + buttonPrevName | t }}</span>
            </button>
        </div>
        <div class="pe-cell cell-title">
            <div class="nombrePlan">
                <span class="asignatura-icono {{ asignatura?.clase }}" *ngIf="asignatura">
                    <ogr-icon name="{{ asignatura?.icono }}"></ogr-icon>
                </span>
                {{ "estudiar.plan_personales.plan_personal_sesion.subtitulo" | t }} {{ orden + 1 + ciclo * 3 }}
            </div>
        </div>
        <div class="pe-cell cell-next">
            <button *ngIf="enableNext" class="nextBtn btn btn-default" (click)="goToNext()">
                <span>{{ "estudiar.plan_personales.plan_personal_sesion." + buttonNextName | t }}&nbsp;</span>
                <fa class="icono" name="chevron-right"></fa>
            </button>
        </div>
    </div>
</div>
