import { BaseModel, SaveType } from "@puntaje/shared/core"
import { EscalaGlobal } from "./escala_globales.model"

export class EscalaGlobalAlias extends BaseModel {
    escala_global_id: number
    equivalencia_min: number
    equivalencia_max: number
    file: File
    alias: string
    tramo: number
    created_at: Date
    updated_at: Date

    img: any

    @SaveType(() => EscalaGlobal) escala_golbal: EscalaGlobal

    public toString() {
        if (this.escala_golbal) return this.escala_golbal.nombre
        else return "EscalaGlobalAlias id: " + this.id
    }

    public getS3Params() {
        return this.file
            ? {
                  key: this.file.name,
                  model_name: "EscalaGlobalAlias"
              }
            : {}
    }
}
