import { Component, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { CarreraSede } from "@puntaje/carreras/api-services"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./carrera-sede.component.html",
    styleUrls: ["./carrera-sede.component.scss"]
})
export class CarreraSedeComponent implements OnInit {
    carreraSedeId: number
    carreraSede: CarreraSede
    sub: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.carreras.carrera_sede")
        this.sub = this.route.params.subscribe(params => {
            this.carreraSedeId = +params["id"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
