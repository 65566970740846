import { Component, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./generar-ensayo-multiple.component.html",
    styleUrls: ["./generar-ensayo-multiple.component.scss"]
})
export class GenerarEnsayoMultipleComponent implements OnInit {
    subData: Subscription

    tipoInstrumento: string
    redirect: string

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.evaluaciones_multiples.generar")
        this.subData = this.route.data.subscribe(data => {
            this.redirect = data.redirect ?? this.redirect
        })

        this.tipoInstrumento = config.plataforma.evaluacionDefault
    }

    ngOnDestroy(): void {
        this.subData.unsubscribe()
    }
}
