import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { PostsPerfil } from "./posts_perfil.model"

export class Post extends BaseModel {
    public titulo: string
    public resumen: string
    public contenido: string
    public imagen: string
    public imagen_sm: string
    public tag: string
    public usuario_id: number
    public tiempo_lectura: number
    public fecha_inicial: string | Date
    public fecha_final: string | Date
    public visible: boolean
    public created_at: string | Date
    public updated_at: string | Date
    public slug: string
    @SaveType(() => Usuario) usuario: Usuario
    @SaveType(() => PostsPerfil) public posts_perfil: PostsPerfil
    public image_file: File
    public image_file_sm: File
    public video: string
    public publico: boolean

    public toString() {
        return this.titulo
    }

    public getS3Params() {
        return this.image_file
            ? {
                  key: this.cleanFileNameForUpload(this.image_file.name),
                  model_name: "Post"
              }
            : {}
    }

    public getS3ParamsSm() {
        return this.image_file_sm
            ? { key: this.cleanFileNameForUpload(this.image_file_sm.name), model_name: "Post" }
            : {}
    }

    cleanFileNameForUpload(fileName: string) {
        return fileName.replace(/[#%&{}<>'"@:$=\|\`\+\/\!\?\*\\]/g, "")
    }
}
