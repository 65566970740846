import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { CUIInputsModule, CUITextsModule } from "@puntaje/shared/cui"
import { UtilModule, FormModule, ModelViewModule, PaginatorModule, TranslationsModule } from "@puntaje/shared/core"
import { ApiModule } from "@puntaje/puntaje/api-services"
import { EscalaGlobalesNewComponent } from "./escala-globales-new/escala-globales-new.component"
import { EscalaGlobalesEditComponent } from "./escala-globales-edit/escala-globales-edit.component"
import { EscalaGlobalesFormComponent } from "./escala-globales-form/escala-globales-form.component"
import { EscalaGlobalesListComponent } from "./escala-globales-list/escala-globales-list.component"
import { EscalaGlobalesToolnavbarComponent } from "./escala-globales-toolnavbar/escala-globales-toolnavbar.component"
import { EscalaGlobalesSearchComponent } from "./escala-globales-search/escala-globales-search.component"
import { EscalaGlobalesSearchService } from "./escala-globales-search.service"
import { EscalaGlobalesViewComponent } from "./escala-globales-view/escala-globales-view.component"

@NgModule({
    declarations: [
        EscalaGlobalesNewComponent,
        EscalaGlobalesEditComponent,
        EscalaGlobalesFormComponent,
        EscalaGlobalesListComponent,
        EscalaGlobalesToolnavbarComponent,
        EscalaGlobalesSearchComponent,
        EscalaGlobalesViewComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        ModelViewModule,
        PaginatorModule,
        TranslationsModule,
        CUITextsModule,
        CUIInputsModule
    ],
    exports: [
        EscalaGlobalesNewComponent,
        EscalaGlobalesEditComponent,
        EscalaGlobalesListComponent,
        EscalaGlobalesToolnavbarComponent,
        EscalaGlobalesViewComponent
    ],
    providers: [EscalaGlobalesSearchService]
})
export class EscalaGlobalesModule {}
