import { SaveType, BaseModel } from "@puntaje/shared/core"
import { Asociacion } from "./asociaciones.model"
import { Usuario } from "./usuarios.model"

export class AsociacionUsuario extends BaseModel {
    public id: number
    public usuario_id: number
    public asociacion_id: number
    @SaveType(() => Asociacion) public asociacion: Asociacion
    @SaveType(() => Usuario) public usuario: Usuario

    public toString() {
        return this.id
    }
}
