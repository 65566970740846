import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()
export class StateService {
    public state: any = {}
    public stateSubject: Subject<any> = new Subject()

    setComponentKey(component: string, key: string, value: any) {
        this.state[component] = this.state[component] || {}
        this.state[component][key] = value
        this.stateSubject.next(this.state)
    }

    getComponentKey(component: string, key: string) {
        if (this.state[component] && this.state[component][key]) {
            return this.state[component][key]
        } else {
            return null
        }
    }
}
