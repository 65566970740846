<div class="content-wrap" (window:resize)="resizeElements()" [attr.style]="contentWrapStyle">
    <div class="fading-image" *ngIf="landingImage" [lazyLoad]="landingImage" [offset]="200"></div>
    <div class="content-container bottom-margin">
        <div class="row">
            <div
                class="col-lg-6 col-md-8 col-sm-8 col-xs-12"
                [ngClass]="
                    alignRight
                        ? 'col-lg-offset-6 col-md-offset-4 col-sm-offset-4'
                        : 'col-lg-offset-3 col-md-offset-2 col-sm-offset-2'
                "
            >
                <div *ngIf="showLogo" class="title-logo">
                    <a routerLink="/home">
                        <img alt="logo" src="{{ logo }}" [attr.style]="imageCustomStyles" class="landing-logo" />
                    </a>
                </div>
                <div class="shared-content-over-title" #sharedLayoutContentOverTitle>
                    <l-slot name="content-over-title"></l-slot>
                </div>
                <div class="offmargin-padding"></div>
                <div
                    class="container-fluid content-subcontainer"
                    [ngStyle]="{
                        'margin-top': marginTopContent,
                        'margin-right': !isMobile ? marginRightContent : 'auto'
                    }"
                >
                    <h1 class="content-title">
                        <l-slot name="titulo"></l-slot>
                    </h1>
                    <div class="shared-content" [class.logged-layout-contenido]="showContenido" #loggedLayoutContenido>
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-content select="cui-layout-footer"></ng-content>
</div>
