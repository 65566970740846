import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import {
    Evaluacion,
    Evaluaciones,
    EvaluacionMultiple,
    EvaluacionMultiples,
    EvaluacionMultipleUsuario,
    EvaluacionMultipleUsuarios,
    EvaluacionSugerida,
    GrupoUsuarios
} from "@puntaje/puntaje/api-services"
import { selectAsignaturasById, State } from "@puntaje/puntaje/store"
import { Store } from "@ngrx/store"
import { filter } from "rxjs/operators"
import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "simulacro-recomendado-alumno-card",
    templateUrl: "./simulacro-recomendado-alumno-card.component.html",
    styleUrls: ["./simulacro-recomendado-alumno-card.component.scss"]
})
export class SimulacroRecomendadoAlumnoCardComponent implements OnInit {
    @Input() evaluacionSugerida: EvaluacionSugerida
    @Input() evaluacionTipo: string
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    asignatura: AsignaturaWithConfig
    asignaturasById$ = this.store.select(selectAsignaturasById).pipe(filter(x => !!x))
    sub: Subscription
    lockedContent: boolean = false
    loaded: boolean = false
    companyName = config.plataforma.info.companyName

    constructor(
        protected store: Store<State>,
        protected evaluacionService: Evaluaciones,
        protected evaluacionMultipleService: EvaluacionMultiples,
        protected evaluacionMultipleUsuarioService: EvaluacionMultipleUsuarios
    ) {}

    ngOnInit() {
        this.checkEvaluaciones()
    }

    async checkEvaluaciones() {
        // this.grupoUsuarioService.where({grupo_usuario: { grupo_usuario_usuario: {usuario_id: }}})
        const evaluacionIds = this.evaluacionSugerida.evaluacion_multiple.evaluacion_multiple_evaluaciones.map(
            e => e.evaluacion_id
        )
        this.evaluacionMultipleService.enableIgnoreCatch()
        this.evaluacionMultipleService
            .where({ evaluacion_multiple: { id: this.evaluacionSugerida.evaluacion_multiple_id }, mis_colegios: 1 })
            .then(async (evaluacionMultiples: EvaluacionMultiple[]) => {
                const evaluacionMultipleUsuarios: EvaluacionMultipleUsuario[] =
                    await this.evaluacionMultipleUsuarioService.where({
                        evaluacion_multiple_usuario: {
                            evaluacion_multiple_id: this.evaluacionSugerida.evaluacion_multiple_id,
                            receptor_type: "Usuario",
                            receptor_id: null
                        }
                    })
                if (evaluacionMultiples.length == 0 && evaluacionMultipleUsuarios.length == 0) {
                    this.lockedContent = true
                }
                this.loadingLayout.ready()
            })
            .catch(response => {
                this.evaluacionMultipleService.disableIgnoreCatch()
                if (response?.status == 403) {
                    this.lockedContent = true
                }
                this.loadingLayout.ready()
            })
    }
}
