import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core"
import { CreateHotToastRef, HotToastService } from "@ngneat/hot-toast"
import { EvaluacionTiempo } from "@puntaje/puntaje/api-services"
import { I18nService } from "@puntaje/shared/core"

@Component({
    selector: "alerta-evaluacion-tiempo",
    templateUrl: "./alerta-evaluacion-tiempo.component.html",
    styleUrls: ["./alerta-evaluacion-tiempo.component.scss"]
})
export class AlertaEvaluacionTiempoComponent implements OnInit, OnChanges, OnDestroy {

    shown: boolean
    timeUp: number
    toastRef: CreateHotToastRef<unknown>

    @Input() segundosRestantes: number
    @Input() horas: number | string
    @Input() minutos: number | string
    @Input() segundos: number | string
    @Input() evaluacionTiempo: EvaluacionTiempo

    constructor(private toast: HotToastService, private translator: I18nService) {}

    ngOnInit() {
        this.shown = false
        this.timeUp = 0.25
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.segundosRestantes) {
            this.update()
        }
    }

    update(): void {
        if (this.evaluacionTiempo?.tiempo_limite && 
            this.segundosRestantes <= this.evaluacionTiempo.tiempo_limite * this.timeUp
        ) {
            const msg = this.translator.translate(
                "preguntas_ensayo.alerta_evaluacion_tiempo.msj", 
                { time: `${this.horas}:${this.minutos}:${this.segundos}` }
            )

            if (!this.shown) {
                this.toastRef = this.toast.warning(msg, {
                    autoClose: false,
                    dismissible: true,
                    duration: this.segundosRestantes,
                    position: "top-center",
                    icon: "⏳"
                })
                this.shown = true
            } else if (this.toastRef) {
                this.toastRef.updateMessage(msg)
                this.toastRef.updateToast({theme: 'toast'})
            }
        }
    }

    ngOnDestroy(): void {
        if (this.toastRef) {
            this.toastRef.close()
        }
    }
}
