import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"
import { PlanEstudioSesion } from "./plan_estudio_sesiones.model"

@Injectable()
export class PlanEstudioSesiones extends NebuBaseService<PlanEstudioSesion> {
    tableName = "plan_estudio_sesiones"
    singularTableName = "plan_estudio_sesion"
    modelClass = PlanEstudioSesion

    constructor(
        http: HttpClient,
        auth: NebuAuthService,
        router: Router,
        errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }
}
