import { Injectable } from "@angular/core"
import { HttpResponse } from "@angular/common/http"
import { Router, RoutesRecognized, Event } from "@angular/router"
import { AuthService } from "../../auth/auth.service"
import { filter, pairwise } from "rxjs/operators"
import { AppConfig } from "../../conf/app_config"
import { I18nService } from "../../i18n/i18n.service"

@Injectable()
export class ErroresService {
    status: number
    message: string
    trace: string
    lastRoutes: [any, any]
    base64Response: string
    nonHttpError: boolean

    constructor(
        private router: Router,
        private auth: AuthService,
        protected config: AppConfig,
        protected i18nService: I18nService
    ) {
        this.router.events
            .pipe(
                filter(e => e instanceof RoutesRecognized),
                pairwise()
            )
            .subscribe(e => {
                this.lastRoutes = e
            })
    }

    setResponse(response: HttpResponse<any>) {
        return new Promise<Boolean>((resolve, reject) => {
            this.base64Response = btoa(JSON.stringify(this.decycle(response)))

            if (response.status == 401) {
                this.auth.redirectUrl = this.router.url
                this.router.navigate([this.config.app.paths.landing])
                resolve(false)

                return true
            }

            let res = response.body
            this.status = response.status
            if (this.status == undefined || this.status == null) {
                this.nonHttpError = true
            }

            if (res && res.json) this.trace = res.exception
            else this.trace = (response as any).stack
            if (res && res.json && res.message && res.show_message) {
                this.message = res.message
            } else {
                switch (this.status) {
                    case 400:
                        this.message = this.i18nService.translate("errores.error_400")
                        break
                    case 401:
                        this.message = this.i18nService.translate("errores.error_401")
                        break
                    case 403:
                        this.message = this.i18nService.translate("errores.error_403")
                        break
                    case 404:
                        this.message = this.i18nService.translate("errores.error_404")
                        break
                    case 500:
                        if (this.trace && this.trace.indexOf && this.trace.indexOf("Pundit") >= 0) {
                            this.message = this.i18nService.translate("errores.error_500_pundit")
                        } else {
                            this.message = this.i18nService.translate("errores.error_500")
                        }
                        break
                    case 503:
                        this.message = this.i18nService.translate("errores.error_503")
                        break
                    default:
                        this.message = this.i18nService.translate("errores.error_inesperado")
                }
            }

            console.log(this.message)

            return resolve(false)
        })
    }

    private decycle(obj, stack = []) {
        if (!obj || typeof obj !== "object") {
            return obj
        }

        if (stack.includes(obj)) {
            return null
        }

        let s = stack.concat([obj])

        return Array.isArray(obj)
            ? obj.map(x => this.decycle(x, s))
            : this.fromEntries(Object.entries(obj).map(([k, v]) => [k, this.decycle(v, s)]))
    }

    private fromEntries(entries) {
        if (!entries || !entries[Symbol.iterator]) {
            throw new Error("Object.fromEntries() requires a single iterable argument")
        }

        const o = {}

        Object.keys(entries).forEach(key => {
            const [k, v] = entries[key]

            o[k] = v
        })

        return o
    }
}
