import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren, Output, EventEmitter } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Alternativa, Asignatura, Asignaturas, Pregunta, Preguntas } from "@puntaje/nebulosa/api-services"
import {
    Evaluacion,
    Evaluaciones,
    EvaluacionInstancia,
    EvaluacionInstancias,
    EvaluacionTiempo,
    EvaluacionTiempos
} from "@puntaje/puntaje/api-services"
import { EvaluacionInteractivaStatusService } from "./evaluacion_interactiva_status.service"
import { PreguntaEnsayoComponent } from "./pregunta_ensayo.component"
import { ErroresService } from "@puntaje/shared/core"

@Component({
    selector: "evaluacion-interactiva",
    templateUrl: "./evaluacion_interactiva.component.html",
    styleUrls: ["./evaluacion_interactiva.component.scss"]
})
export class EvaluacionInteractivaComponent implements OnInit {
    enableDudasBtn: boolean = false
    enablePreguntasId: boolean = true

    @Input() evaluacionId: number
    @Input() enableEntregarEnsayo: boolean = true
    @Input() enableRedirectAfterDone: boolean = true
    @Input() enableReporte: boolean = true

    @Output() onReadyEvaluacion: EventEmitter<any> = new EventEmitter<any>()
    @Output() onFinishedEvaluacion: EventEmitter<any> = new EventEmitter<any>()
    @Output() onAfterEvaluacionEntregada: EventEmitter<any> = new EventEmitter<any>()

    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChildren("preguntaEnsayo", { read: PreguntaEnsayoComponent })
    preguntaEnsayoComponent: QueryList<PreguntaEnsayoComponent>

    evaluacion: Evaluacion
    asignatura: Asignatura

    preguntas: Pregunta[]

    preguntaIds: number[]
    contestableIds: number[]
    alternativaIds: number[]
    tiempos: number[]
    pistaVistas: boolean[]
    alternativasEscogidas: any[]

    loading: boolean = true
    displayPreguntas: boolean = false

    tiempoInicial: Date

    indexActual: number = 0
    alternativaActual: Alternativa
    showMessageCorrecta: boolean = false
    enableBtnSaltar: boolean = false
    showPista: boolean = false

    constructor(
        protected router: Router,
        protected evaluacionesService: Evaluaciones,
        protected asignaturasService: Asignaturas,
        protected preguntasService: Preguntas,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected evaluacionTiemposService: EvaluacionTiempos,
        protected evaluacionInteractivaStatusService: EvaluacionInteractivaStatusService,
        protected errores: ErroresService
    ) {}

    ngOnInit() {
        this.evaluacionInteractivaStatusService.resetStatus()
        this.evaluacionesService.find(this.evaluacionId, { interactivo: true }).then((e: Evaluacion) => {
            this.evaluacion = e
            this.asignaturasService
                .find(this.evaluacion.instrumento.asignatura_id, { simple: true })
                .then((asignatura: Asignatura) => {
                    this.asignatura = asignatura
                    this.onReadyEvaluacion.emit(e)
                    this.preguntaIds = this.evaluacion.instrumento.instrumento_preguntas.map(ip => ip.pregunta_id)

                    let evaluacionTiempo = new EvaluacionTiempo()
                    evaluacionTiempo.evaluacion_id = this.evaluacionId
                    ;(evaluacionTiempo as any).interactivo = 1

                    this.evaluacionTiemposService.save(evaluacionTiempo).then((et: EvaluacionTiempo) => {
                        this.loadPreguntas()
                    })
                })
        })
    }

    async loadPreguntas() {
        let params = {
            with_grupo_pregunta: 1,
            pregunta: {
                id: this.preguntaIds
            }
        }

        this.preguntasService.where(params).then((preguntas: Pregunta[]) => {
            let nPreguntas = this.evaluacion.instrumento.numero_preguntas

            this.preguntas = preguntas

            this.contestableIds = new Array(nPreguntas)
            this.preguntas.forEach((pregunta, i) => {
                this.contestableIds[i] = pregunta.contestables[0].id
            })
            this.loading = false
            this.alternativaIds = Array(nPreguntas).fill("")
            this.tiempos = Array(nPreguntas).fill(0)
            this.pistaVistas = Array(nPreguntas).fill(false)
            this.alternativasEscogidas = Array(nPreguntas)
                .fill(false)
                .map(a => ({}))
            this.evaluacionInteractivaStatusService.setTotalPreguntas(this.preguntas.length)
            this.evaluacionInteractivaStatusService.nextPregunta()
            this.loadingLayout.ready()

            this.tiempoInicial = new Date()

            this.displayPreguntas = true
        })
    }

    saveAlternativa() {
        this.tiempoInicial = new Date()
    }

    enableShowPista() {
        this.showPista = true
        this.pistaVistas[this.indexActual] = true
        this.evaluacionInteractivaStatusService.addPista()
    }

    skip() {
        if (this.alternativaActual) {
            let preguntasEnsayo = this.preguntaEnsayoComponent.toArray()
            this.evaluacionInteractivaStatusService.addIncorrecta()
        }
        this.alternativaActual = null
        this.showPista = false
        this.enableBtnSaltar = false
        this.showMessageCorrecta = false
        this.indexActual++
        this.evaluacionInteractivaStatusService.nextPregunta()

        if (this.indexActual == this.preguntas.length) {
            this.onFinishedEvaluacion.emit()
            if (this.enableEntregarEnsayo) {
                this.entregarEnsayo()
            }
        }
    }

    check() {
        if (!this.alternativaIds[this.indexActual]) return
        let preguntasEnsayo = this.preguntaEnsayoComponent.toArray()

        if (this.alternativasEscogidas[this.indexActual][this.alternativaIds[this.indexActual]] == undefined) {
            this.evaluacionInteractivaStatusService.addIntento()
        }
        this.alternativasEscogidas[this.indexActual][this.alternativaIds[this.indexActual]] = 1

        let preguntaActual = this.preguntas[this.indexActual]
        let alternativaActual = preguntaActual.contestables[0]?.alternativas.find(
            a => a.id == this.alternativaIds[this.indexActual]
        )
        if (!alternativaActual.correcta) {
            this.alternativaActual = alternativaActual
            this.enableBtnSaltar = true
            preguntasEnsayo[this.indexActual].showFeedbackWrongAnswer()
        } else {
            this.showMessageCorrecta = true
            this.alternativaActual = null
            this.enableBtnSaltar = false
            this.evaluacionInteractivaStatusService.addCorrecta()
            preguntasEnsayo[this.indexActual].showFeedbackRightAnswer()
            setTimeout(() => {
                this.showPista = false
                this.showMessageCorrecta = false
                this.indexActual++
                this.evaluacionInteractivaStatusService.nextPregunta()

                if (this.indexActual == this.preguntas.length) {
                    this.onFinishedEvaluacion.emit()
                    if (this.enableEntregarEnsayo) {
                        this.entregarEnsayo()
                    }
                }
            }, 2000)
        }
    }

    entregarEnsayo(insideSesion = false) {
        this.loadingLayout.standby()

        let evaluacionInstancia = new EvaluacionInstancia()
        evaluacionInstancia.evaluacion_id = this.evaluacion.id
        ;(evaluacionInstancia as any).alternativa_ids = this.alternativaIds.map(value =>
            value == null ? "null" : value
        )
        ;(evaluacionInstancia as any).contestable_ids = this.contestableIds
        ;(evaluacionInstancia as any).pregunta_ids = this.preguntaIds
        ;(evaluacionInstancia as any).pista_vistas = this.pistaVistas
        ;(evaluacionInstancia as any).nintentos = this.alternativasEscogidas.map(o => Object.keys(o).length)
        ;(evaluacionInstancia as any).tiempos = this.tiempos
        ;(evaluacionInstancia as any).inside_sesion = insideSesion

        this.evaluacionInstanciasService.enableIgnoreCatch()
        this.evaluacionInstanciasService
            .save(evaluacionInstancia)
            .then((ei: EvaluacionInstancia) => {
                this.evaluacionInstanciasService.disableIgnoreCatch()
                this.onAfterEvaluacionEntregada.emit(ei.id)
                if (this.enableRedirectAfterDone) {
                    this.router.navigate(["/ensayo_instancias", ei.id])
                }
            })
            .catch(response => {
                this.evaluacionInstanciasService.disableIgnoreCatch()
                if (response && response.status == 422) {
                    this.loadingLayout.ready()
                } else {
                    this.errores.setResponse(response).then(val => {
                        this.router.navigate(["errores"])
                    })
                }
            })
    }
}
