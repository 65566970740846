import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ChangeDetectorRef,
    HostListener
} from "@angular/core"
import { select, Store } from "@ngrx/store"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService, ResizeAwareDirective } from "@puntaje/shared/core"
import {
    selectAsignaturasByEvaluacionTipo,
    State,
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo
} from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"
import { SetSelectedAsignatura } from "@puntaje/puntaje/store"
import { AsignaturaWithConfig, Asignatura } from "@puntaje/nebulosa/api-services"
import { Subscription } from "rxjs"

@Component({
    selector: "menu-asignaturas-horizontal",
    templateUrl: "menu_asignaturas_horizontal.component.html",
    styleUrls: ["menu_asignaturas_horizontal.component.scss"]
})
export class MenuAsignaturasHorizontalComponent implements OnInit, OnChanges {
    @Input() asignaturaId: number
    @Input() enableResponsive = false
    @Input() enableMiniMode = false
    @Input() tipoAsignaturas = ""
    @Input() tipoEvaluacion: string
    @Input() lista_asignaturas: AsignaturaWithConfig[]
    @Input() enableAllBtn = false
    @Input() soloMaterialVisible = false
    @Input() asignaturaIds: number[]
    @Input() onlyWithItems = false
    @Input() onlyWithEvaluacionesRecomendadas = false
    @Input() onlyWithPlanesClase = false
    @Output() asignaturaObjChanged = new EventEmitter()
    @Output() asignaturaChanged = new EventEmitter()
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() enableDropdownMode = true // para habiliar que haya dropdown en responsive
    activateDropdownMode = false // para indicar que pantalla es responsive y activar dropdown
    openDropdown = false // para abrir/cerrar el dropdown
    currentAsignatura: AsignaturaWithConfig
    currentEvaluacionTipo: string
    @ViewChild("navContainer") insideElement
    @ViewChild("resizeNav", { read: ResizeAwareDirective }) resizeNav: ResizeAwareDirective
    lastSize: number
    firstTimeSettingAsignatura = true
    sub: Subscription
    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )
    evaluacionTipoDefault: string = config.plataforma.evaluacionDefault

    @HostListener("document:click", ["$event.target"]) public onClick(targetElement) {
        if (!this.insideElement) return
        const clickedInside = this.insideElement.nativeElement.contains(targetElement)
        if (this.openDropdown && !clickedInside) {
            this.openDropdown = false
        }
    }

    constructor(protected store: Store<State>, protected authService: AuthService, protected cdr: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.asignaturaId) {
            this.firstTimeSettingAsignatura = false
        }
        else {
            this.currentAsignatura = null
            this.store.dispatch(new SetSelectedAsignatura({ selectedAsignatura: null }))
        }

        this.setAsignaturas()
        if (!this.tipoAsignaturas) {
            this.sub = this.evaluacionTipo$.subscribe(evaluacionTipo => {
                this.tipoEvaluacion = evaluacionTipo
                this.setAsignaturas()
            })
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        let hasToReloadAsignaturas = false

        if (changes["tipoAsignaturas"] && changes["tipoAsignaturas"].currentValue) {
            if (!this.tipoEvaluacion) this.tipoEvaluacion = this.tipoAsignaturas
            hasToReloadAsignaturas = true
        }

        if (
            changes["asignaturaId"] &&
            !changes["asignaturaId"].firstChange &&
            changes["asignaturaId"].currentValue &&
            changes["asignaturaId"].previousValue != changes["asignaturaId"].currentValue
        ) {
            hasToReloadAsignaturas = true
        }

        if (hasToReloadAsignaturas) {
            this.setAsignaturas()
        }
    }

    // TODO(efrias): CONSIDERAR REFACTORIZAR ESTE MÉTODO, HACE MUCHO
    async setAsignaturas() {
        const asignaturasByEvaluacionTipo = await this.store
            .pipe(
                select(selectAsignaturasByEvaluacionTipo),
                filter(x => !!x),
                first()
            )
            .toPromise()

        let asignatura

        if (!this.asignaturaId) {
            asignatura = await this.store.pipe(select(selectSelectedAsignatura), first()).toPromise()
        }

        this.lista_asignaturas = asignaturasByEvaluacionTipo[this.tipoEvaluacion] || []

        if (this.lista_asignaturas.length == 0) {
            this.currentEvaluacionTipo = this.evaluacionTipoDefault
            this.lista_asignaturas = asignaturasByEvaluacionTipo[this.currentEvaluacionTipo]
            // this.changeAsignatura(0)
            // return
        } else {
            this.currentEvaluacionTipo = this.tipoEvaluacion
        }

        if (this.onlyWithItems) {
            this.lista_asignaturas = this.lista_asignaturas.filter(
                a => a.confByEvaluacionTipo[this.currentEvaluacionTipo].with_items
            )
        }

        if (this.onlyWithEvaluacionesRecomendadas) {
            this.lista_asignaturas = this.lista_asignaturas.filter(
                a => a.confByEvaluacionTipo[this.currentEvaluacionTipo].with_evaluaciones_recomendadas
            )
        }

        if (this.onlyWithPlanesClase) {
            this.lista_asignaturas = this.lista_asignaturas.filter(
                a => a.confByEvaluacionTipo[this.currentEvaluacionTipo].with_planes_de_clase
            )
        }

        if (this.soloMaterialVisible) {
            this.lista_asignaturas = this.lista_asignaturas.filter(
                a => a.confByEvaluacionTipo[this.currentEvaluacionTipo].material_visible
            )
        } else {
            this.lista_asignaturas = this.lista_asignaturas.filter(
                a => !a.confByEvaluacionTipo[this.currentEvaluacionTipo].solo_contenido
            )
        }

        if (this.asignaturaIds) {
            this.lista_asignaturas = this.lista_asignaturas.filter(a => this.asignaturaIds.includes(a.id))
        }

        if (
            !asignatura &&
            this.authService.getUserData().asignatura_id &&
            this.firstTimeSettingAsignatura &&
            this.lista_asignaturas.find(a => a.id == this.authService.getUserData().asignatura_id)
        ) {
            this.changeAsignatura(this.authService.getUserData().asignatura_id)
        } else if (this.enableAllBtn && !this.asignaturaId) {
            this.changeAsignatura(0)
        } else if (asignatura) {
            const foundAsignatura = this.lista_asignaturas.find(a => a.id == asignatura.id)
            if (!foundAsignatura) {
                this.changeAsignatura(this.lista_asignaturas[0].id)
            } else {
                this.changeAsignatura(asignatura.id)
            }
        } else {
            if (!this.asignaturaId) {
                this.changeAsignatura(this.lista_asignaturas[0]?.id)
            } else {
                const foundAsignatura = this.lista_asignaturas.find(a => a.id == this.asignaturaId)
                if (!foundAsignatura) {
                    this.changeAsignatura(this.lista_asignaturas[0].id)
                } else {
                    this.changeAsignatura(this.asignaturaId)
                }
            }
        }
        this.firstTimeSettingAsignatura = false
        this.currentAsignatura = this.lista_asignaturas.find(a => a.id == this.asignaturaId)
        this.loadingLayout.ready()
        if (this.lastSize && this.lastSize > 0) this.adaptToSize(this.lastSize)
        else if (this.resizeNav) this.resizeNav.triggerResize()
    }

    changeAsignatura(id: number) {
        if (this.openDropdown) this.openDropdown = false
        const selectedAsignatura = this.lista_asignaturas.find(a => a.id == id)
        if (this.asignaturaId == id) {
            this.store.dispatch(new SetSelectedAsignatura({ selectedAsignatura }))
            return
        }
        this.asignaturaId = id
        this.asignaturaChanged.emit(this.asignaturaId)
        this.asignaturaObjChanged.emit(selectedAsignatura)
        this.currentAsignatura = selectedAsignatura
        this.store.dispatch(new SetSelectedAsignatura({ selectedAsignatura }))
    }

    // Orden de las asignaturas apartir de una asignatura definida. No se usará pero dejare el codigo por si se usara en el futuro
    modificarAsignaturas() {
        let index = this.lista_asignaturas.findIndex(i => i.id === this.authService.getUserData().asignatura_id)
        let data = this.lista_asignaturas[index]
        this.lista_asignaturas.splice(index, 1)
        this.lista_asignaturas.unshift(data)
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
    }

    toggleDropdown() {
        this.openDropdown = !this.openDropdown
    }

    adaptToSize(cs: number) {
        this.activateDropdownMode = this.enableDropdownMode && cs > 0 && cs / this.lista_asignaturas.length <= 110
        this.openDropdown = this.openDropdown && this.activateDropdownMode
        this.cdr.detectChanges()
        this.lastSize = cs
    }
}
