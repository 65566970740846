<ng-container *ngIf="evaluacionTipos$ | async as evaluacionTipos">
    <ng-container *ngIf="evaluacionTipo$ | async as evaluacionTipo">
        <div class="evaluacion-tipos-container" *ngIf="evaluacionTipos.length > 1">
            <cui-pills [expanded]="true">
                <cui-pill
                    [isActive]="evaluacionTipo == et"
                    *ngFor="let et of evaluacionTipos$ | async"
                    (click)="selectEvaluacionTipo(et)"
                >
                    {{ !evalTiposDict[et] ? et : (evalTiposDict[et] | transformInstrumento: "evaluacionTipo") }}
                </cui-pill>
            </cui-pills>
        </div>
    </ng-container>
</ng-container>
