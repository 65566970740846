import {
    Component,
    OnInit,
    Input,
    ViewChild,
    SimpleChanges,
    ChangeDetectorRef,
    ViewChildren,
    QueryList,
    ElementRef,
    HostListener,
    TemplateRef
} from "@angular/core"
import {
    PlanPersonalSesion,
    PlanPersonalSesiones,
    PlanPersonales,
    PlanPersonalContenido,
    PlanPersonalContenidoGrupoUsuarios,
    PlanPersonalInstancia,
    PlanPersonalInstancias,
    PlanPersonalMaterialInstancia,
    PlanPersonalMaterialInstancias,
    PlanClaseService,
    Evaluaciones,
    EvaluacionInstancia,
    PlanPersonalEvaluacionInstancia,
    PlanPersonalEvaluacionInstancias
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Materiales, Material, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { Location } from "@angular/common"
import { Router } from "@angular/router"
import { AuthService, FullscreenService, SessionService, CheckAchievement, CheckActividad } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { RealizarEnsayoService } from "@puntaje/puntaje/new-modules/ensayos"
import { PreguntasEnsayoComponent } from "@puntaje/puntaje/new-modules/ensayos"
import { Store } from "@ngrx/store"
import { State, CrearAlertaLogros } from "@puntaje/puntaje/store"
@Component({
    selector: "clase-plan-clase",
    templateUrl: "./clase-plan-clase.component.html",
    styleUrls: ["./clase-plan-clase.component.scss"]
})
export class ClasePlanClaseComponent implements OnInit {
    @Input() planPersonalId: number
    @Input() isLibroFrom: string = "docente"
    @Input() planPersonalSesionId: number
    @Input() grupoUsuarioId: number
    @Input() enableDudas: boolean = false
    @Input() enableReporte: boolean = true
    @Input() esAdmin: boolean = false
    @Input() showVerLibro: boolean = true
    @Input() planUrl = "/plan_clases"
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayoutComponent: LoadingLayoutComponent
    @ViewChildren("previewElem") previewElems: QueryList<ElementRef<any>>

    nombreSesion: string = config.plataforma.nombreSesion || "Semana"
    config = config

    planPersonalSesion: PlanPersonalSesion
    clasificacionContextoByMaterialId: any
    clasificacionActividadByMaterialId: any

    planPersonalContenidoSelected: PlanPersonalContenido
    planPersonalContenidoSelectedIndex: number
    contenidoIndexAllByIndexShow: { [key: number]: number } = {}

    planPersonalInstancia: PlanPersonalInstancia

    libroUrl: string

    initialDate: Date
    enabled: boolean = false

    shouldShowContenido = {}

    @ViewChild("libroContainer", { read: TemplateRef }) libroContainer: TemplateRef<any>
    openInFullscreen: boolean

    loadClock: boolean = false
    seeingInstance: boolean = false
    enableSecuencial = config.plataforma.evaluacionResponder == "secuencial"

    constructor(
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        protected planPersonalSesionesService: PlanPersonalSesiones,
        protected planPersonalesService: PlanPersonales,
        protected materialesService: Materiales,
        public location: Location,
        protected authService: AuthService,
        public fullscreenService: FullscreenService,
        protected planPersonalInstanciasService: PlanPersonalInstancias,
        protected planPersonalMaterialInstanciasService: PlanPersonalMaterialInstancias,
        protected planPersonalEvaluacionInstanciasService: PlanPersonalEvaluacionInstancias,
        protected planPersonalContenidoGrupoUsuariosService: PlanPersonalContenidoGrupoUsuarios,
        public planClaseService: PlanClaseService,
        protected evaluacionesService: Evaluaciones,
        protected realizarEnsayoService: RealizarEnsayoService,
        protected sessionsService: SessionService,
        protected store: Store<State>
    ) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (
            (changes["planPersonalSesionId"] || changes["planPersonalId"]) &&
            this.planPersonalSesionId &&
            this.planPersonalId
        ) {
            this.getData()
        }
    }

    async getData() {
        this.loadingLayoutComponent.standby()

        this.clasificacionContextoByMaterialId = {}
        this.clasificacionActividadByMaterialId = {}

        let planPersonalMaterialInstancias: PlanPersonalMaterialInstancia[]
        let planPersonalEvaluacionInstancias: PlanPersonalEvaluacionInstancia[]
        if (this.grupoUsuarioId && this.isLibroFrom == "docente") {
            await this.planPersonalesService.planClaseInit(this.planPersonalId, this.grupoUsuarioId)
        } else {
            const usuarioId = this.authService.getUserData().id

            const params = {
                plan_personal_instancia: {
                    plan_personal_id: this.planPersonalId,
                    usuario_id: usuarioId
                }
            }

            const planPersonalInstancias = await this.planPersonalInstanciasService.where(params)

            if (planPersonalInstancias.length > 0) {
                this.planPersonalInstancia = planPersonalInstancias[0]
            } else {
                const newPlanPersonalInstancia = new PlanPersonalInstancia()
                newPlanPersonalInstancia.plan_personal_id = this.planPersonalId
                newPlanPersonalInstancia.usuario_id = usuarioId

                const planPersonalInstancia = await this.planPersonalInstanciasService.save(newPlanPersonalInstancia)

                this.planPersonalInstancia = planPersonalInstancia
            }

            const paramsMaterialInstancias = {
                plan_personal_material_instancia: {
                    plan_personal_instancia_id: this.planPersonalInstancia.id
                }
            }

            planPersonalMaterialInstancias = await this.planPersonalMaterialInstanciasService.where(
                paramsMaterialInstancias
            )

            const paramsEvaluacionInstancias = {
                plan_personal_evaluacion_instancia: {
                    plan_personal_instancia_id: this.planPersonalInstancia.id
                }
            }

            planPersonalEvaluacionInstancias = await this.planPersonalEvaluacionInstanciasService.where(
                paramsEvaluacionInstancias
            )
        }

        const planPersonalSesion = await this.planPersonalSesionesService.find(this.planPersonalSesionId, {
            include:
                "[plan_personal_sesion_grupo_usuarios,plan_personal_contenidos:[plan_personal_contenido_grupo_usuarios]]"
        })

        const materialIds = planPersonalSesion.plan_personal_contenidos
            .filter(ppco => ppco.tipo == "Material")
            .map(ppco => +ppco.tipo_id)

        const materialParams = {
            material: { id: materialIds },
            raw: 1,
            include: "[clasificaciones:clasificacion_tipo]",
            methods: "[material_tipo_nombre]"
        }

        const materiales = await this.materialesService.wherePost(materialParams)
        const materialesByMaterialId = materiales.groupBy(material => material.id)

        const evaluacionIds = planPersonalSesion.plan_personal_contenidos
            .filter(ppco => ppco.tipo == "Evaluacion")
            .map(ppco => +ppco.tipo_id)

        const evaluacionParams = {
            evaluacion: { id: evaluacionIds },
            include: "[evaluacion_tipo,instrumento]"
        }

        const evaluaciones = await this.evaluacionesService.where(evaluacionParams)
        const evaluacionesByEvaluacionId = evaluaciones.groupBy(evaluacion => evaluacion.id)

        planPersonalSesion.plan_personal_sesion_grupo_usuarios =
            planPersonalSesion.plan_personal_sesion_grupo_usuarios.filter(
                ppsgu => ppsgu.grupo_usuario_id == this.grupoUsuarioId
            )

        planPersonalSesion.plan_personal_contenidos = planPersonalSesion.plan_personal_contenidos.sort(
            (c1, c2) => c1.orden - c2.orden
        )

        planPersonalSesion.plan_personal_contenidos.forEach(ppco => {
            if (materialesByMaterialId[ppco.tipo_id] && ppco.tipo == "Material") {
                ppco.material = materialesByMaterialId[ppco.tipo_id][0]

                this.clasificacionActividadByMaterialId[ppco.material.id] = ppco.material.clasificaciones.find(
                    c => c.clasificacion_tipo.clasificacion_tipo == "tipo actividad"
                )
                this.clasificacionContextoByMaterialId[ppco.material.id] = ppco.material.clasificaciones.find(
                    c => c.clasificacion_tipo.clasificacion_tipo == "contexto"
                )
            } else if (evaluacionesByEvaluacionId[ppco.tipo_id] && ppco.tipo == "Evaluacion") {
                ppco.evaluacion = evaluacionesByEvaluacionId[ppco.tipo_id][0]
            }

            ppco.plan_personal_contenido_grupo_usuarios = ppco.plan_personal_contenido_grupo_usuarios.filter(
                ppcogu => ppcogu.grupo_usuario_id == this.grupoUsuarioId
            )
        })

        // solo si es profesor se filtran los marcados, si no se buscan las instancias
        const planPersonal = await this.planPersonalesService.find(this.planPersonalId)
        if (this.isLibroFrom == "docente") {
            this.libroUrl = planPersonal.libro_url_profesor
            planPersonalSesion.plan_personal_contenidos = planPersonalSesion.plan_personal_contenidos.filter(
                ppc =>
                    ppc.plan_personal_contenido_grupo_usuarios.length == 0 ||
                    ppc.plan_personal_contenido_grupo_usuarios[0].marcado
            )
        } else {
            this.libroUrl = planPersonal.libro_url_alumno
            planPersonalSesion.plan_personal_contenidos.forEach(ppc => {
                ppc.plan_personal_material_instancias = planPersonalMaterialInstancias.filter(
                    ppmi => ppmi.plan_personal_contenido_id == ppc.id
                )

                ppc.plan_personal_evaluacion_instancias = planPersonalEvaluacionInstancias.filter(
                    ppei => ppei.plan_personal_contenido_id == ppc.id
                )
            })
        }

        this.planPersonalSesion = planPersonalSesion

        let indexShow = 0
        this.planPersonalSesion.plan_personal_contenidos.forEach((contenido, index) => {
            if (contenido.material) {
                const roles = this.sessionsService.getRoles()
                const clasificaciones = contenido.material.clasificaciones.map(x => x.clasificacion)
                const esDocente: boolean = roles.includes("Docente")
                const esAlumno: boolean = roles.includes("Estudiante")
                const esMaterialDocente: boolean = clasificaciones.includes("Profesores")
                const esMaterialAlumno: boolean = clasificaciones.includes("Alumnos")

                this.shouldShowContenido[contenido.id] =
                    (esDocente && esMaterialDocente) || (esAlumno && esMaterialAlumno)

                if (this.shouldShowContenido[contenido.id]) {
                    this.contenidoIndexAllByIndexShow[indexShow] = index
                    indexShow++
                }
            } else if (contenido.evaluacion) {
                this.shouldShowContenido[contenido.id] = true

                this.contenidoIndexAllByIndexShow[indexShow] = index
                indexShow++
            }
        })

        this.planPersonalContenidoSelectedIndex = 0

        if (this.grupoUsuarioId && this.isLibroFrom == "docente") {
            planPersonalSesion.plan_personal_contenidos.forEach((ppcs, i) => {
                if (this.shouldShowContenido[ppcs.id]) {
                    const planPersonalContenidoGrupoUsuario = ppcs.plan_personal_contenido_grupo_usuarios[0]

                    if (planPersonalContenidoGrupoUsuario.visto) {
                        let indexShow = Object.values(this.contenidoIndexAllByIndexShow).findIndex(
                            indexReal => indexReal == i
                        )

                        this.planPersonalContenidoSelectedIndex = indexShow
                    }
                }
            })
        }

        this.initialDate = new Date()
        this.goToContenido(this.planPersonalContenidoSelectedIndex)

        this.loadingLayoutComponent.ready()
    }

    goToContenidoReal(i, openInFullscreen = false) {
        let indexShow = Object.values(this.contenidoIndexAllByIndexShow).findIndex(indexReal => indexReal == i)
        this.goToContenido(indexShow, openInFullscreen)
    }

    goToContenido(newIndex, openInFullscreen = false) {
        if (newIndex < 0 || newIndex >= Object.values(this.contenidoIndexAllByIndexShow).length) {
            this.fullscreenService.closeFullscreen()
            this.router.navigate([this.planUrl, this.planPersonalId])

            return
        }

        this.openInFullscreen = openInFullscreen
        // para que el componente de material se destruya
        this.enabled = false
        this.loadClock = false
        if (this.planPersonalContenidoSelected) this.cdr.detectChanges()
        this.enabled = true

        this.planPersonalContenidoSelectedIndex = newIndex
        this.planPersonalContenidoSelected =
            this.planPersonalSesion.plan_personal_contenidos[
                this.contenidoIndexAllByIndexShow[this.planPersonalContenidoSelectedIndex]
            ]

        if (this.planPersonalContenidoSelected.tipo == "Evaluacion") {
            const sub = this.realizarEnsayoService.ready.subscribe(() => {
                this.loadClock = true

                sub.unsubscribe()
            })
        }

        setTimeout(() => {
            const previewElems = this.previewElems.toArray()

            previewElems[newIndex]?.nativeElement?.scrollIntoView(false)
        }, 50)

        if (!this.esAdmin && this.isLibroFrom != "docente") {
            this.markAsSeen(this.planPersonalContenidoSelected)
        }
    }

    markAsSeen(planPersonalContenido: PlanPersonalContenido, evaluacionInstancia: EvaluacionInstancia = null) {
        // si no es profesor, se marca como visto de otra forma
        if (this.grupoUsuarioId && this.isLibroFrom == "docente") {
            const planPersonalContenidoGrupoUsuario = planPersonalContenido.plan_personal_contenido_grupo_usuarios[0]

            if (!planPersonalContenidoGrupoUsuario.visto) {
                planPersonalContenidoGrupoUsuario.visto = true
                this.planPersonalContenidoGrupoUsuariosService
                    .update(planPersonalContenidoGrupoUsuario.id, planPersonalContenidoGrupoUsuario)
                    .then(ppcgu => {
                        console.log("marcado como visto")
                    })
            }
        } else {
            if (planPersonalContenido.tipo == "Material") {
                if (planPersonalContenido.plan_personal_material_instancias.length == 0) {
                    const materialInstancia = new PlanPersonalMaterialInstancia()
                    materialInstancia.plan_personal_instancia_id = this.planPersonalInstancia.id
                    materialInstancia.plan_personal_contenido_id = planPersonalContenido.id
                    materialInstancia.tiempo = 0

                    this.planPersonalMaterialInstanciasService
                        .save(materialInstancia)
                        .then((planPersonalMaterialInstancia: PlanPersonalMaterialInstancia) => {
                            planPersonalContenido.plan_personal_material_instancias = [planPersonalMaterialInstancia]

                            this.checkCompletedSesion()
                        })
                }
            } else if (planPersonalContenido.tipo == "Evaluacion") {
                this.seeingInstance =
                    !!planPersonalContenido.plan_personal_evaluacion_instancias[0] &&
                    !!planPersonalContenido.plan_personal_evaluacion_instancias[0].evaluacion_instancia_id

                if (planPersonalContenido.plan_personal_evaluacion_instancias.length == 0) {
                    const planPersonalEvaluacionInstancia = new PlanPersonalEvaluacionInstancia()
                    planPersonalEvaluacionInstancia.plan_personal_instancia_id = this.planPersonalInstancia.id
                    planPersonalEvaluacionInstancia.plan_personal_contenido_id = planPersonalContenido.id
                    planPersonalEvaluacionInstancia.evaluacion_instancia_id = evaluacionInstancia?.id

                    this.planPersonalEvaluacionInstanciasService
                        .save(planPersonalEvaluacionInstancia)
                        .then((planPersonalEvaluacionInstanciaNew: PlanPersonalEvaluacionInstancia) => {
                            planPersonalContenido.plan_personal_evaluacion_instancias = [
                                planPersonalEvaluacionInstanciaNew
                            ]

                            this.checkCompletedSesion()

                            this.seeingInstance = !!evaluacionInstancia
                        })
                } else if (evaluacionInstancia) {
                    const planPersonalEvaluacionInstancia = planPersonalContenido.plan_personal_evaluacion_instancias[0]

                    const planPersonalEvaluacionInstanciaForUpdate = new PlanPersonalEvaluacionInstancia()
                    planPersonalEvaluacionInstanciaForUpdate.evaluacion_instancia_id = evaluacionInstancia.id
                    planPersonalEvaluacionInstanciaForUpdate.id = planPersonalEvaluacionInstancia.id

                    this.planPersonalEvaluacionInstanciasService
                        .update(planPersonalEvaluacionInstancia.id, planPersonalEvaluacionInstanciaForUpdate)
                        .then(planPersonalEvaluacionInstanciaUpdated => {
                            planPersonalEvaluacionInstancia.evaluacion_instancia_id =
                                planPersonalEvaluacionInstanciaUpdated.evaluacion_instancia_id

                            this.seeingInstance = false
                            this.checkCompletedSesion()

                            setTimeout(() => (this.seeingInstance = true), 0)
                        })
                }
            }
        }
    }

    @CheckAchievement("PN_PLAN_CLASES_COMPLETAR_SESIONES", CrearAlertaLogros)
    @CheckActividad("SC")
    checkCompletedSesion() {
        return this.planPersonalSesion
    }

    onDestroyMaterial(m: Material) {
        if (this.grupoUsuarioId && this.isLibroFrom == "docente") return

        const planPersonalContenido = this.planPersonalSesion.plan_personal_contenidos.find(
            c => c.tipo == "Material" && c.tipo_id == m.id
        )
        if (!planPersonalContenido) {
            console.log("Material sin plan personal contenido")

            return
        }

        const fun = (planPersonalMaterialInstancia: PlanPersonalMaterialInstancia) => {
            const tiempo = ~~((new Date().getTime() - this.initialDate.getTime()) / 1000)

            planPersonalMaterialInstancia.tiempo = planPersonalMaterialInstancia.tiempo || 0
            planPersonalMaterialInstancia.tiempo += tiempo

            this.planPersonalMaterialInstanciasService
                .update(planPersonalMaterialInstancia.id, planPersonalMaterialInstancia)
                .then((ppmi: PlanPersonalMaterialInstancia) => {
                    planPersonalContenido.plan_personal_material_instancias = [ppmi]
                })
        }

        const planPersonalMaterialInstancia = planPersonalContenido.plan_personal_material_instancias[0]

        fun(planPersonalMaterialInstancia)

        this.initialDate = new Date()
    }

    openFullscreen() {
        const elem = this.libroContainer.elementRef.nativeElement
        const methodToBeInvoked =
            elem.requestFullscreen ||
            elem.webkitRequestFullScreen ||
            elem["mozRequestFullscreen"] ||
            elem["msRequestFullscreen"]

        if (methodToBeInvoked) methodToBeInvoked.call(elem)
    }

    closeFullscreen() {
        const methodToBeInvoked =
            document.exitFullscreen ||
            (document as any).mozCancelFullScreen ||
            (document as any).webkitExitFullscreen ||
            (document as any).msExitFullscreen

        if (methodToBeInvoked) {
            methodToBeInvoked.call(document)
        }
    }

    onEvaluacionSecuencialEntregada(planPersonalContenido, ei) {
        this.markAsSeen(planPersonalContenido, ei)
    }

    entregarEvaluacion(planPersonalContenido) {
        this.realizarEnsayoService.entregarEnsayo(true).then((ei: EvaluacionInstancia) => {
            this.markAsSeen(planPersonalContenido, ei)
        })
    }
}
