import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { CUIInputsModule } from "@puntaje/shared/cui"
import { DynamicModule } from "ng-dynamic-component"

import { UtilModule, FormModule, ModelViewModule } from "@puntaje/shared/core"
import { UsuarioPerfilComponent } from "./usuario_perfil.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { ForgotPasswordComponent } from "./passwords/forgot_password.component"
import { ResetPasswordComponent } from "./passwords/reset_password.component"
import { RegisterConfirmationComponent } from "./register_confirmation.component"
import { UsuarioRegistradoComponent } from "./usuario-registrado/usuario-registrado.component"
import { RecuperarCuentaComponent } from "./recuperar-cuenta/recuperar-cuenta.component"
import { RequestConfirmationComponent } from "./request-confirmation/request-confirmation.component"
import { TutorConfirmarComponent } from "./tutor_confirmar.component"
import { UsuarioPerfilAlumnoComponent } from "./usuario_perfil_alumno.component"
import { LogrosModule } from "@puntaje/puntaje/new-modules/logros"
import { UsuarioPerfilAdminComponent } from "./usuario_perfil_admin.component"
import { UsuarioComponent } from "./usuario.component"
import { UsuariosEditComponent } from "./usuarios.edit.component"
import { CompletarRegistroComponent } from "./register/completar_registro.component"
import { IncompleteRegisterComponent } from "./register/incomplete_register.component"
import { RegisterComponent } from "./register/register.component"
import { CompletarRegistroSocialComponent } from "./register/completar_registro_social.component"
import { OpcionesRegistroNacimientoComponent } from "./register/opciones-registro-nacimiento.component"
import { RegistroComponent } from "./registro/registro.component"
import { CompletarDatosCoreComponent } from "./register/completar-datos-core.component"
import { ActualizarDatosCoreComponent } from "./register/actualizar-datos-core/actualizar-datos-core.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilModule,
        UsuariosModule,
        ModelViewModule,
        Angular2FontawesomeModule,
        MaterialesModule,
        CUIInputsModule,
        CUILayoutsModule,
        CommonsLayoutsModule,
        LogrosModule,
        DynamicModule
    ],
    declarations: [
        UsuarioPerfilComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        RegisterConfirmationComponent,
        UsuarioRegistradoComponent,
        RecuperarCuentaComponent,
        RequestConfirmationComponent,
        TutorConfirmarComponent,
        UsuarioPerfilAlumnoComponent,
        UsuarioPerfilAdminComponent,
        UsuarioComponent,
        UsuariosEditComponent,
        CompletarRegistroComponent,
        IncompleteRegisterComponent,
        RegisterComponent,
        CompletarRegistroSocialComponent,
        OpcionesRegistroNacimientoComponent,
        RegistroComponent,
        CompletarDatosCoreComponent,
        ActualizarDatosCoreComponent
    ],
    exports: [
        UsuarioPerfilComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        RegisterConfirmationComponent,
        UsuarioRegistradoComponent,
        RecuperarCuentaComponent,
        RequestConfirmationComponent,
        TutorConfirmarComponent,
        UsuarioPerfilAlumnoComponent,
        UsuarioPerfilAdminComponent,
        UsuarioComponent,
        UsuariosEditComponent,
        CompletarRegistroComponent,
        IncompleteRegisterComponent,
        RegisterComponent,
        CompletarRegistroSocialComponent,
        OpcionesRegistroNacimientoComponent,
        RegistroComponent,
        CompletarDatosCoreComponent,
        ActualizarDatosCoreComponent
    ]
})
export class PuntajeUsuariosModule {}
