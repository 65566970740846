import { Injectable } from "@angular/core"
import { Title } from "@angular/platform-browser"
import { I18nService } from "../../i18n/i18n.service"
import { AppConfig } from "../../conf/app_config"
declare const config: AppConfig

@Injectable()
export class TitleService {
    constructor(protected titleService: Title, protected translatorService: I18nService) {}

    public setTitle(title): void {
        if (!config.plataforma.title) {
            return
        }

        title = this.translatorService.translate(title, {})

        const prefix = config.plataforma.title.prefix
        const suffix = config.plataforma.title.suffix

        title = `${prefix ?? ""} ${prefix ? "|" : ""} ${title} ${suffix ? "|" : ""} ${suffix ?? ""}`

        this.titleService.setTitle(title.trim())
    }
}
