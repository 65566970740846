/** @format */

import { ClearCodigoConfirmacionData } from "@puntaje/puntaje/store"
/** @format */

import { Component, Input, ViewChild } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { FlashMessageService, AuthService, Validations, I18nService } from "@puntaje/shared/core"
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { Router } from "@angular/router"
import { Subscription } from "rxjs"
import { NgModel } from "@angular/forms"

@Component({
    selector: "user-password-reset",
    templateUrl: "reset_password.component.html",
    styleUrls: ["reset_password.component.scss"]
})
export class ResetPasswordComponent {
    @Input() reset_password_token

    password: string
    passwordConfirmation: string
    checkingSubscription: Subscription
    breachedPassword: boolean

    @ViewChild("passwordControl") passwordControl: NgModel

    constructor(
        public authService: AuthService,
        public flashMessageService: FlashMessageService,
        public router: Router,
        public http: HttpClient,
        protected store: Store<State>,
        protected i18nService: I18nService
    ) {}

    async changePassword() {
        await this.isBreachedPassword(this.passwordControl)

        if (this.isValidPassword()) {
            if (this.reset_password_token) {
                this.flashMessageService.setParams(
                    "info",
                    this.i18nService.translate("landing.reiniciar_password.guardando")
                )
                this.http
                    .put(environment.endpoints.base + "/usuarios/password", this.getRequestObject())
                    .toPromise()
                    .then((response: any) => {
                        this.authService.logout()
                        this.store.dispatch(new ClearCodigoConfirmacionData())
                        this.flashMessageService.setParams(
                            "success",
                            this.i18nService.translate("landing.reiniciar_password.reinicio_exitoso")
                        )
                        setTimeout(() => {
                            this.router.navigate([""])
                        }, 5000)
                    })
                    .catch((reason: any) => {
                        const errors: any = reason.errors
                        if (errors) {
                            let msg = this.i18nService.translate("landing.reiniciar_password.error")
                            if (errors.reset_password_token) {
                                msg =
                                    msg + " " + this.i18nService.translate("landing.reiniciar_password.token_invalido")
                            }
                            this.flashMessageService.setParams("error", msg)
                        }
                    })
            }
        } else {
            this.flashMessageService.setParams("warning", this.getError())
        }
    }

    isBreachedPassword(control) {
        const validator = Validations.checkPasswordBreach(this.http)

        return new Promise((resolve, reject) => {
            this.checkingSubscription?.unsubscribe()

            this.checkingSubscription = validator(control).subscribe(validation => {
                this.breachedPassword = validation?.breachedPassword?.valid == false ? true : false // checkeo si es falso porque si es null, es como si fuese valid == true

                resolve(this.breachedPassword)
            })
        })
    }

    isValidPassword() {
        return (
            this.password !== undefined &&
            this.password !== null &&
            this.password !== "" &&
            this.passwordConfirmation !== undefined &&
            this.passwordConfirmation !== null &&
            this.passwordConfirmation !== "" &&
            this.passwordConfirmation === this.password &&
            this.password.length >= 8 &&
            !this.breachedPassword
        )
    }

    getError() {
        if (this.passwordConfirmation != this.password) {
            return this.i18nService.translate("landing.reiniciar_password.campos_no_coinciden")
        }

        if (this.password.length < 8) {
            return this.i18nService.translate("landing.reiniciar_password.password_minimo_largo")
        }

        if (this.breachedPassword) {
            return this.i18nService.translate("landing.reiniciar_password.password_vulnerable")
        }

        return ""
    }

    getRequestObject() {
        return {
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            reset_password_token: this.reset_password_token
        }
    }
}
