import { Component, Type } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { RegisterColombiaComponent } from "@puntaje/puntaje/new-modules/usuarios"
import { Angulartics2Facebook } from "angulartics2/facebook"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "register.component.html",
    styleUrls: ["register.component.scss"]
})
export class RegisterComponent {
    unloggedLayoutTitle = "Crea una cuenta"
    subData: Subscription

    component: Type<any> = RegisterColombiaComponent
    outputs: any = {
        onTitleChange: this.onTitleChange,
        onUsuarioRegistrado: this.onUsuarioRegistrado
    }
    contentOverTitle: boolean = false
    subtitle: string = null

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected angularticsFb: Angulartics2Facebook
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.registro.register")
        this.subData = this.route.data.subscribe(data => {
            this.component = data.component ?? this.component
            this.subtitle = data.subtitle ?? this.subtitle
            this.contentOverTitle = data.contentOverTitle ?? this.contentOverTitle
        })
    }

    onTitleChange(title) {
        this.unloggedLayoutTitle = title
    }

    onUsuarioRegistrado(event) {
        this.angularticsFb.eventTrack("CompleteRegistration")
    }
}
