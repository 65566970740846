import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"
import { TipoInstrumentoPlataforma } from "./tipo_instrumento_plataformas.model"
import { Plataformas } from "./plataformas.service"
import { Plataforma } from "./plataformas.model"
import { TipoInstrumentos } from "./tipo_instrumentos.service"
import { TipoInstrumento } from "./tipo_instrumentos.model"

@Injectable()
export class TipoInstrumentoPlataformas extends NebuBaseService<TipoInstrumentoPlataforma> {
    tableName = "tipo_instrumento_plataformas"
    singularTableName = "tipo_instrumento_plataforma"
    protected objectQuery: TipoInstrumentoPlataforma
    modelClass = TipoInstrumentoPlataforma

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv,
        private plataformasService: Plataformas,
        private tipoInstrumentosService: TipoInstrumentos
    ) {
        super(http, auth, router, errores, environment)
    }

    async configurarAliases(nombrePlataforma: string, nombresTipoInstrumentos: string[]) {
        const plataformaParams = {
            plataforma: {
                plataforma: nombrePlataforma
            }
        }

        const plataformas: Plataforma[] = await this.plataformasService.where(plataformaParams)
        const plataforma = plataformas[0]

        const tipoInstrumentoParams = {
            tipo_instrumento: {
                tipo_instrumento: nombresTipoInstrumentos
            }
        }

        const tipoInstrumentos: TipoInstrumento[] = await this.tipoInstrumentosService.where(tipoInstrumentoParams)

        const tipoInstrumentoPlataformasParams = {
            tipo_instrumento_plataforma: {
                plataforma_id: plataforma.id,
                tipo_instrumento_id: tipoInstrumentos.map(ti => ti.id)
            }
        }

        const tipoInstrumentoPlataformas: TipoInstrumentoPlataforma[] = await this.where(tipoInstrumentoPlataformasParams)
        let tipoInstrumentosDict: any = {}
        tipoInstrumentos.map(ti => {
            let tip = tipoInstrumentoPlataformas.find(t => t.tipo_instrumento_id == ti.id)
            if(tip && tip.alias){
                tipoInstrumentosDict[ti.tipo_instrumento] = tip.alias
                return tip.alias
            }else{
                tipoInstrumentosDict[ti.tipo_instrumento] = ti.tipo_instrumento
                return ti.tipo_instrumento
            }
        })
        return tipoInstrumentosDict
    }
}
