<div *ngIf="!done">Procesando tu confirmacion de cuenta...</div>
<div *ngIf="done">
    <div *ngIf="!status">Hubo problemas al procesar tu solicitud.</div>
    <div *ngIf="status">
        <h3><p>Tu correo electrónico ha sido verificado con éxito.</p></h3>
        <h4>Puedes acceder a la plataforma usando tu cuenta de correo y contraseña.</h4>
        <div>
            <i>Serás redireccionado a la página principal en unos segundos.</i>
        </div>
    </div>
</div>
