import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"

import { BaseService, ErroresService, AppEnv } from "@puntaje/shared/core"
import { AchievementsAuthService } from "./achievements_auth.service"

@Injectable({
    providedIn: "root"
})
export class AchievementsBaseService<T> extends BaseService<T> {
    constructor(
        protected http: HttpClient,
        protected auth: AchievementsAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
        this.baseURL = this.environment.endpoints.achievements
    }
}
