import { HttpClient } from "@angular/common/http"
import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from "@angular/core"
import { EvaluacionForma, Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { AppConfig, PdfView } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
declare const config: AppConfig

@Component({
    selector: "vista-previa-instrumento",
    templateUrl: "./vista-previa-instrumento.component.html",
    styleUrls: ["./vista-previa-instrumento.component.scss"]
})
export class VistaPreviaInstrumentoComponent implements OnInit {
    @Input() instrumento: Instrumento
    @Input() evaluacionFormas: EvaluacionForma[]
    @Input() evaluacionForma: EvaluacionForma

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    pdfParams: any = { label: "Vista previa PDF", type: PdfView }
    instrumentoPDF: any
    instrumentoPDFByForma: any = {}
    errorMessage: string =
        "El PDF no se puede generar, por favor comunicarte con monitores@ogr.cl o " +
        "compartir la evaluación online para que los alumnos la contesten de esta forma."
    showMessage: boolean = false

    constructor(protected instrumentosService: Instrumentos, public http: HttpClient, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (this.instrumento) {
            this.generarVista()
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes["evaluacionForma"] && !changes["evaluacionForma"].isFirstChange()) {
            this.cdr.detectChanges()
        }
    }

    generarVista() {
        let paramsImprimir: any = {
            plataforma: config.plataforma.name
        }

        if (this.instrumento.generador_instrumento.maximo_alternativas) {
            paramsImprimir.cantidad_alternativas = this.instrumento.generador_instrumento.maximo_alternativas
        }

        let promises = [this.imprimirPromise(paramsImprimir)]
        if (this.evaluacionFormas) {
            this.evaluacionFormas.forEach(evaluacionForma => {
                promises.push(this.imprimirPromise({ ...paramsImprimir, evaluacion_forma_id: evaluacionForma.id }))
            })
        }

        this.instrumentosService.enableIgnoreModel()
        this.instrumentosService.enableIgnoreCatch()

        Promise.all(promises)
            .then((results: any[]) => {
                this.instrumentosService.disableIgnoreModel()
                console.log("results", results)
                if (results.find(obj => !!obj.info.errors || !obj.success)) {
                    console.log(
                        "error imprimir",
                        results.find(obj => !!obj.info.errors || !obj.success)
                    )
                    this.showMessage = true
                    this.loadingLayout.ready()
                    return
                }

                Promise.all(results.map(obj => this.downloadPromise(obj.info)))
                    .then((responseLinks: any) => {
                        console.log("responseLinks", responseLinks)
                        let blob = new Blob([responseLinks[0]], { type: "application/pdf" })
                        this.instrumentoPDF = { pdf: blob }

                        if (this.evaluacionFormas && responseLinks.length > 1) {
                            responseLinks.slice(1).forEach((response: any, index: number) => {
                                const blob = new Blob([response], { type: "application/pdf" })
                                const instrumentoPDF = { pdf: blob }
                                this.instrumentoPDFByForma[this.evaluacionFormas[index].id] = instrumentoPDF
                            })
                        }

                        this.loadingLayout.ready()
                    })
                    .catch(response => {
                        console.log("catch download", response)
                        this.instrumentosService.disableIgnoreModel()
                        this.showMessage = true
                        this.loadingLayout.ready()
                    })
            })
            .catch(response => {
                console.log("catch imprimir", response)
                this.instrumentosService.disableIgnoreModel()
                this.showMessage = true
                this.loadingLayout.ready()
            })
    }

    imprimirPromise(paramsImprimir) {
        return this.instrumentosService.imprimir(this.instrumento.id, paramsImprimir)
    }

    downloadPromise(info) {
        console.log("download", info.link)
        return this.http.get(info.link, { responseType: "blob" as "json" }).toPromise()
    }
}
