<div class="preview" id="ayuda{{ ayuda.id }}">
    <ng-template [ngIf]="isPdf || isVideo || isGame || isHTML">
        <model-plain-value
            [params]="params[this.ayuda.material_tipo_nombre].params"
            [value]="ayuda"
            [key]="params[this.ayuda.material_tipo_nombre].key"
            [class.html-content]="isHTML"
        ></model-plain-value>
    </ng-template>
</div>
