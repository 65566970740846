import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { select, Store } from "@ngrx/store"
import { selectInputFile } from "@puntaje/puntaje/store"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService, ScrollToService } from "@puntaje/shared/core"
import { EvaluacionInstancias, Usuarios } from "@puntaje/puntaje/api-services"
import { ChangeInputFile, LecturaProcesada, State } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators"
import { FiltroEstadisticasService } from "@puntaje/puntaje/new-modules/estadisticas"
import { ScannerService } from "@puntaje/shared/core"
import { SubirRespuestasImagenBaseComponent } from "./subir-respuestas-imagen-base/subir-respuestas-imagen-base.component"

@Component({
    selector: "subir-respuestas-imagen",
    templateUrl: "subir_respuestas_imagen.component.html",
    styleUrls: ["subir_respuestas_imagen.component.scss"]
})
export class SubirRespuestasImagenComponent extends SubirRespuestasImagenBaseComponent implements OnInit, OnDestroy {
    goToViewPath: string
    subIdValue: Subscription
    mensaje: string
    showMensaje: boolean = false
    aliasIdentificador = config.plataforma.aliasIdentificadorHojaRespuesta || "RUT"

    @ViewChild("canvas", { static: true }) canvas: ElementRef
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutSubir", { static: true }) loadingLayoutUpload: LoadingLayoutComponent
    sub: Subscription
    inputFile$ = this.store.pipe(
        select(selectInputFile),
        filter(x => !!x)
    )

    openInstructivoImprimirEvent: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        protected evaluacionesInstanciasService: EvaluacionInstancias,
        protected filtroEstadisticasService: FiltroEstadisticasService,
        protected router: Router,
        protected scrollToService: ScrollToService,
        protected scannerService: ScannerService,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef,
        protected authService: AuthService,
        protected usuarioService: Usuarios,
        protected simpleModalService: SimpleModalService
    ) {
        super(evaluacionesInstanciasService, router, scrollToService, scannerService, store, cdr, authService)
    }

    ngOnInit() {
        this.loadingLayout.ready()
        this.loadingLayoutUpload.ready()

        this.sub = this.inputFile$.subscribe(inputFile => {
            this.file = inputFile
            this.store.dispatch(new ChangeInputFile({ file: null }))
            this.leerImagen()
        })
    }

    verificarRut() {
        if (this.subIdValue) {
            this.subIdValue.unsubscribe()
        }

        this.subIdValue = this.archivosGood$.subscribe(archivosGood => {
            if (archivosGood.length > 0) {
                this.idValue = archivosGood[0].idValue
                this.forma = archivosGood[0].forma
            }
        })

        this.store.dispatch(new LecturaProcesada({ obj: { messages: null, loadingMessages: true }, index: 0 }))

        if (config.app.name != "profesores" && this.idValue[0] != "") {
            let usuario = this.authService.getUserData()
            if (config.hojaRespuesta.usingUserID) {
                let id = this.idValue[0]
                if (id == usuario.id) {
                    this.verificaExisteEval()
                } else {
                    const obj = {
                        messages: { warnings: ["El ID no corresponde al usuario actual."] },
                        loadingMessages: false
                    }

                    this.store.dispatch(new LecturaProcesada({ obj, index: 0 }))

                    return false
                }
            } else if (config.plataforma.pais == "chile") {
                let rut = this.idValue[0] + "-" + this.idValue[1]
                let dbRut = usuario.usuario_chile.rut?.toLowerCase()

                if (dbRut[dbRut.length - 1] == "k") {
                    dbRut = dbRut.slice(0, -1) + "0"
                }

                if (rut.toLowerCase() == dbRut) {
                    this.verificaExisteEval()
                } else {
                    const obj = {
                        messages: { warnings: ["El rut no corresponde al usuario actual."] },
                        loadingMessages: false
                    }

                    this.store.dispatch(new LecturaProcesada({ obj, index: 0 }))

                    return false
                }
            } else if (config.plataforma.pais == "colombia") {
                let ci = this.idValue[0]
                if (ci == usuario.usuario_colombia.cc || ci == usuario.usuario_colombia.ti) {
                    this.verificaExisteEval()
                } else {
                    const obj = {
                        messages: { warnings: ["El cc/ti no corresponde al usuario actual."] },
                        loadingMessages: false
                    }

                    this.store.dispatch(new LecturaProcesada({ obj, index: 0 }))

                    return false
                }
            }
        } else {
            if (config.app.name == "alumnos") {
                this.verificaExisteEval()
            } else {
                this.subirLectura()
            }
        }

        return false
    }

    verificaExisteEval() {
        const params = {
            evaluacion_instancia: {
                evaluacion_id: this.forma,
                oficial: 1,
                usuario_id: this.authService.getUserData().id
            }
        }

        if (config.hojaRespuesta.formatEvalIdStr) {
            params["format_id_str"] = config.hojaRespuesta.formatEvalIdStr
        }

        this.evaluacionesInstanciasService.where(params).then(eis => {
            if (eis.length > 0) {
                this.showModalSubida()
            } else {
                this.subirLectura()
            }
        })
    }

    showModalSubida() {
        this.showMensaje = true
        this.mensaje =
            "Resultados de evaluación N " +
            this.forma +
            " estarán en tu historial, pero no serán considerados en tus estadísticas. Porque esta evaluación ya  fue corregida"
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que quiere subir el resultado nuevamente? ya tienes registrados resultados para esta prueba."
        )
        this.simpleModalService.setModalCallback(() => this.subirLectura())
        this.simpleModalService.showSimpleModal()
    }

    bottonEstadisticas() {
        if (this.datosBoton) {
            let b = this.datosBoton
            this.filtroEstadisticasService.setFiltrosId(
                b.establecimiento_id,
                null,
                null,
                b.evaluacion_id,
                b.generador_instrumento_id,
                config.plataforma.evaluacionDefault,
                b.asignatura_id
            )
            this.router.navigate(["/estadisticas"], { queryParams: { asignatura_id: b.asignatura_id } })
        }
    }

    leerImagen() {
        this.reset()
        this.leerImagenes([this.file])
    }

    ngOnDestroy() {
        super.ngOnDestroy()
        if (this.subIdValue) {
            this.subIdValue.unsubscribe()
        }

        this.sub.unsubscribe()
    }
}
