<cui-section-headline>Sigue los siguientes pasos:</cui-section-headline>
<div class="clearfix" id="step1">
    <div class="row">
        <div class="col-md-5">
            <div class="tutorial clearfix">
                <div class="tutorial-image">
                    <div class="tutorial-image-pos">
                        <div class="tutorial-image-cell">
                            <img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/correccion_tutorial_1.png"
                                alt="Imagen de la hoja de respuestas" />
                        </div>
                    </div>
                    <div class="step-number">1</div>
                </div>
                <h3 class="step">Adjunta una imagen de la hoja de respuestas</h3>
                <p class="tutorial-text">
                    Escanea en formato JPEG o toma una fotografía para cada hoja de respuestas. Asegúrate que las hojas
                    estén derechas y la
                    iluminación sea adecuada para un buen contraste entre el fondo blanco y las respuestas.
                </p>
                <div class="clearfix" *ngIf="respuestas">
                    <button type="button" class="next btn btn-default btn-sm" href="#step2" easyScrollTo
                        [easyScrollToPadding]="0" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOut'">
                        Siguiente&nbsp;
                        <fa name="caret-right"></fa>
                    </button>
                </div>
            </div>
            <modal-instructivo-subir-respuestas
                [openInstructivoImprimirEvent]="openInstructivoImprimirEvent"></modal-instructivo-subir-respuestas>
        </div>
        <div class="col-md-7">
            <div class="form-group">
                <file [(ngModel)]="file" [xhr_upload]="xhrUpload" key="imgFile" (change)="checkIfImageExtension()">
                </file>
                <div class="warning hide-warning" [class.show-warning]="wrongFileType">
                    <div class="warning-content">
                        <fa name="warning" class="warning-icon"></fa>
                        <div class="warning-message">
                            <p>El archivo no corresponde a una imagen válida:</p>
                            <ul>
                                <li>Compruebe que se haya adjuntado el archivo correcto.</li>
                                <li>
                                    Si se trata de una fotografía, pruebe con enderezar la hoja y mejorar la iluminación
                                    y contraste.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group clearfix">
                <cui-button class="btn_style pull-right" (click)="leerImagen()">
                    <ogr-icon name="correccion-imagen"></ogr-icon>
                    &nbsp;Subir Foto
                </cui-button>
            </div>
        </div>
    </div>
</div>
<div id="step2" class="clearfix" [hidden]="!((processedAll$ | async) && (archivosGood$ | async).length > 0)">
    <loading-layout #loadingLayout>
        <div class="step-container">
            <div class="tutorial clearfix">
                <div class="tutorial-image">
                    <div class="tutorial-image-pos">
                        <div class="tutorial-image-cell">
                            <img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/correccion_tutorial_3.png"
                                alt="Revisar respuestas" />
                        </div>
                    </div>
                    <div class="step-number">2</div>
                </div>
                <h3 class="step">Comprueba los resultados</h3>
                <p class="tutorial-text">
                    Revisa que los resultados se hayan leído correctamente a partir de la imagen. Puedes corregir
                    marcando la alternativa contestada que corresponda en caso de un error de lectura, haciendo click en
                    las secciones azules de la imagen.
                </p>
                <div class="clearfix">
                    <button type="button" class="next btn btn-default btn-sm" href="#step3" easyScrollTo
                        [easyScrollToPadding]="0" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOut'">
                        Siguiente&nbsp;
                        <fa name="caret-right"></fa>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="canvas-container">
                        <h3>Hoja de respuesta</h3>
                        <canvas *ngFor="let file of archivosOriginales$ | async; let i = index" #canvas
                            (click)="onClickCanvasWithIndex(i, $event)" id="canvas" style="width: 100%"
                            [class.hide]="i != (selectedFile$ | async)"></canvas>
                    </div>
                </div>

                <div *ngFor="let obj of archivosGood$ | async; trackBy: getPos" class="col-md-4"
                    [hidden]="obj.pos != (selectedFile$ | async)">
                    <div class="resultados-container">
                        <h3>Resultados</h3>
                        <div class="forma datos" [class.wrong-data]="obj.forma.indexOf(',') != -1">
                            <label>Forma:</label>
                            &nbsp;
                            <subir-respuestas-valor-feedback *ngIf="!obj.editing" [valor]="obj.forma"
                                class="valor"></subir-respuestas-valor-feedback>
                            <input type="text" *ngIf="obj.editing" [ngModel]="obj.forma"
                                (ngModelChange)="changeForma(obj.pos, $event)" class="valor edit-forma form-control" />

                            <cui-button class="btn_style pull-right btn-sm" *ngIf="!obj.editing"
                                (click)="enableEditObj(obj)">
                                <fa name="pencil"></fa>
                                Editar
                            </cui-button>
                            <cui-button class="btn_style pull-right btn-sm" *ngIf="obj.editing"
                                (click)="disableEditObj(obj)">
                                <fa name="pencil"></fa>
                                Listo
                            </cui-button>
                        </div>
                        <div *ngIf="obj.formaType">
                            <label>Tipo de Forma:</label>
                            &nbsp;
                            <subir-respuestas-valor-feedback *ngIf="!obj.editing" [valor]="obj.formaType"
                                class="valor"></subir-respuestas-valor-feedback>
                            <input type="text" *ngIf="obj.editing" [(ngModel)]="obj.formaType"
                                class="valor edit-forma form-control" />
                        </div>
                        <div class="rut datos" [class.wrong-data]="obj.idValue.join('-').indexOf(',') != -1">
                            <label>{{ aliasIdentificador }}:</label>
                            &nbsp;
                            <subir-respuestas-valor-feedback *ngIf="!obj.editing" [valor]="obj.idValue.join('-')"
                                class="valor"></subir-respuestas-valor-feedback>

                            <ng-container *ngFor="let part of obj.idValue; let i = index; trackBy: getIndex">
                                <input type="text" *ngIf="obj.editing" [ngModel]="obj.idValue[i]"
                                    (ngModelChange)="changeIdValuePart(obj.pos, i, $event)"
                                    class="valor edit-id form-control" />
                                <ng-container *ngIf="i != obj.idValue.length - 1 && obj.editing">-</ng-container>
                            </ng-container>
                        </div>

                        <div class="multiple-col">
                            <ul class="lista-resultados">
                                <li *ngFor="let respuestaIndex of obj.respuestas | keys" class="respuesta">
                                    <span class="numero">{{ parseInt(respuestaIndex) + 1 }}:</span>
                                    &nbsp;
                                    <subir-respuestas-valor-feedback *ngIf="!obj.editing"
                                        [valor]="obj.respuestas[respuestaIndex] | easyplaceholder: '-'"
                                        class="valor"></subir-respuestas-valor-feedback>

                                    <input type="text" *ngIf="obj.editing" [ngModel]="obj.respuestas[respuestaIndex]"
                                        (ngModelChange)="changeRespuesta(obj.pos, respuestaIndex, $event)"
                                        class="valor edit-respuesta form-control" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </loading-layout>
</div>
<div id="step3" [hidden]="!((processedAll$ | async) && (archivosGood$ | async).length > 0) || loadingLayout.loading">
    <div class="step-container">
        <div class="tutorial clearfix">
            <div class="tutorial-image">
                <div class="tutorial-image-pos">
                    <div class="tutorial-image-cell">
                        <img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/tutorial/correccion_tutorial_2.png"
                            alt="Revisar respuestas" />
                    </div>
                </div>
                <div class="step-number">3</div>
            </div>
            <h3 class="step">Sube los resultados</h3>
            <p class="tutorial-text">Completa la operación haciendo click en el botón subir resultados.</p>
            <br />

            <div *ngFor="let obj of archivosGood$ | async" class="warning hide-warning"
                [class.show-warning]="obj.messages && obj.messages.warnings.length > 0 && !obj.loadingMessages">
                <div class="warning-content">
                    <fa name="warning" class="warning-icon"></fa>
                    <div class="warning-message">
                        <p>
                            La operación se completó con advertencias. La imágen
                            <b>{{ obj.file.name }}</b>
                            no pudo cargarse por uno o más errores:
                        </p>
                        <ul *ngIf="obj.messages">
                            <li *ngFor="let warning of obj.messages.warnings">{{ warning }}</li>
                        </ul>
                        <p *ngIf="obj.goToViewPath"><a [routerLink]="[obj.goToViewPath]">Ver resultados</a></p>
                    </div>
                </div>
            </div>

            <div *ngFor="let obj of archivosGood$ | async" class="success hide-warning"
                [class.show-warning]="obj.messages && obj.messages.warnings.length == 0 && !obj.loadingMessages">
                <div class="warning-content">
                    <fa name="smile-o" class="warning-icon"></fa>
                    <div class="warning-message">
                        <p *ngIf="showMensaje">
                            {{ mensaje }}
                        </p>
                        <p *ngIf="!showMensaje">
                            La operación para la imagen
                            <b>{{ obj.file.name }}</b>
                            se completó exitosamente.&nbsp;
                            <a *ngIf="obj.instancia_id" class="clickable" (click)="botonContinuar(obj)">
                                {{ link_message }}
                            </a>
                        </p>
                        <cui-button type="button" class="btn_style pull-right" (click)="reset()">
                            <fa name="refresh"></fa>
                            &nbsp;Subir otra imagen
                        </cui-button>
                        <p *ngIf="obj.goToViewPath"><a [routerLink]="[obj.goToViewPath]">Ver resultados</a></p>
                    </div>
                </div>
            </div>

            <div class="clearfix">
                <cui-button class="btn_style pull-right" (click)="verificarRut()">
                    <fa name="upload"></fa>
                    &nbsp;Subir Resultados
                </cui-button>
                <loading-layout #loadingLayoutSubir [loaderType]="'default-small'"
                    class="loader-upload"></loading-layout>
            </div>
        </div>
    </div>
</div>