import { Component, EventEmitter, AfterViewInit, ViewChild } from "@angular/core"
import { GenericModalComponent } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "carreras-terminos-modal",
    templateUrl: "./carreras-terminos-modal.component.html",
    styleUrls: ["./carreras-terminos-modal.component.scss"]
})
export class CarrerasTerminosModalComponent implements AfterViewInit {
    openModal: EventEmitter<any> = new EventEmitter<any>()
    storageKey: string = "carrerasTerminosModal"
    pais = config.plataforma.pais.toLowerCase()
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    constructor() {}

    ngAfterViewInit() {
        if (!localStorage.getItem(this.storageKey)) {
            this.openModal.emit()
        }
    }

    onAccept() {
        localStorage.setItem(this.storageKey, "true")
        this.genericModal.close()
    }
}
