import { BaseForm, Validations, GroupValidator, DateHelper, AppConfig, I18nService } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"
import { DatePipe } from "@angular/common"

declare const config: AppConfig

export class UsuarioEditForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    public static get formParams() {
        const translatorService = this.injector.get(I18nService)
        const cursosDefault = config.plataforma.cursosDefault
        const clasificacionesCursos =
            cursosDefault && cursosDefault.length > 0 ? cursosDefault.map(c => c.clasificacion) : undefined
        const clasificacionTipo = config.plataforma.clasificacionTipoNivel || "curso"

        return {
            id: { label: "id", type: "hidden", visible: false },
            nombre: {
                label: translatorService.translate("usuarios_perfil_edit.nombre") || "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: translatorService.translate("usuarios_perfil_edit.apellido_paterno") || "Apellido paterno",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_materno: {
                label: translatorService.translate("usuarios_perfil_edit.apellido_materno") || "Apellido materno",
                type: "text",
                visible: true,
                validations: [Validators.minLength(2)]
            },
            fecha_nacimiento: {
                label: translatorService.translate("usuarios_perfil_edit.fecha_nacimiento") || "Fecha de nacimiento",
                type: "date",
                visible: true,
                min: DateHelper.firstDayYear(150),
                max: DateHelper.today()
            },
            email: {
                label: translatorService.translate("usuarios_perfil_edit.correo_electronico") || "Correo electrónico",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmailNotSelf }]
            },
            lugar: {
                label: config.plataforma.lugarLabel || "Comuna",
                type: "autocomplete",
                visible: true,
                options: { class: Lugares, params: { lugar_tipo: config.plataforma.lugarTipo || "Comuna" } }
            },
            establecimiento_id: {
                label: translatorService.translate("usuarios_perfil_edit.establecimiento") || "Establecimiento",
                type: "text",
                visible: true
            },
            nivel_id: {
                label: translatorService.translate("usuarios_perfil_edit.curso") || "Curso",
                type: "select",
                visible: true,
                orden: "id",
                options: {
                    class: Clasificaciones,
                    params: {
                        clasificacion: {
                            clasificacion:
                                clasificacionesCursos ||
                                config.evaluaciones[config.plataforma.evaluacionDefault].cursos.map(
                                    curso => curso.clasificacion
                                )
                        },
                        clasificacion_tipo: { clasificacion_tipo: clasificacionTipo },
                        raw: true
                    }
                }
            },
            created_at: {
                label: translatorService.translate("usuarios_perfil_edit.fecha_creacion") || "Fecha de creación",
                type: "hidden",
                visible: true
            },
            updated_at: {
                label:
                    translatorService.translate("usuarios_perfil_edit.fecha_actualizacion") || "Fecha de actualización",
                type: "hidden",
                visible: true
            },
            asignatura_id: { label: "Asignatura", type: "hidden", visible: false }
        }
    }
}
