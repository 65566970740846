import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Libro } from "./libros.model"
import { Rol } from "./roles.model"
import { Usuario } from "./usuarios.model"

export class LibroCodigoRegistro extends BaseModel {
    public libro_id: number
    public codigo: string
    public usuario_id: number
    public rol_id: number

    @SaveType(() => Libro) public libro: Libro
    @SaveType(() => Usuario) public usuario: Libro
    @SaveType(() => Rol) public rol: Rol
}
