import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "ayudas.component.html",
    styleUrls: ["ayudas.component.scss"]
})
export class AyudasComponent implements OnInit, OnDestroy {
    // asignaturaId: number;
    private sub: Subscription
    subData: Subscription
    enableResponsive: boolean = false

    appName = config.app.name
    i18nKeyTitulo = `ayudas.titulo_${this.appName}`
    targetApp = config.app.name == "alumnos" ? "alumnos" : "profesores"

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ayudas.list")
        this.enableResponsive = window.innerWidth <= 900
        this.subData = this.route.data.subscribe(data => {
            this.i18nKeyTitulo = data.i18nKeyTitulo ?? this.i18nKeyTitulo
        })
    }

    ngOnDestroy() {
        // this.sub.unsubscribe();
    }

    onResize(event) {
        this.enableResponsive = event.target.innerWidth <= 900
    }
}
