import { Action } from "@ngrx/store"
import { Filtro } from "../reducers/estadisticas.reducer"

export enum EstadisticasActionTypes {
    SetFiltros = "[Estadisticas] Set Filtros"
}

export class SetFiltros implements Action {
    readonly type = EstadisticasActionTypes.SetFiltros

    constructor(public payload: { evaluacionTipo: string; asignaturaId: number; filtro: Filtro }) {}
}

export type EstadisticasActions = SetFiltros
