<ng-container *ngIf="!saved">
    <form novalidate [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <h3>Datos Personales</h3>
                <form-input [form]="form" [params]="params['id']" [(value)]="usuario['id']" [key]="'id'"></form-input>
                <form-input
                    [form]="form"
                    [params]="params['nombre']"
                    [(value)]="usuario['nombre']"
                    [key]="'nombre'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_paterno']"
                    [(value)]="usuario['apellido_paterno']"
                    [key]="'apellido_paterno'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['usuario_id']"
                    [(value)]="usuario['usuario_' + pais]['usuario_id']"
                    [key]="'usuario_id'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['ti']"
                    [(value)]="usuario['usuario_' + pais]['ti']"
                    [key]="'ti'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['cc']"
                    [(value)]="usuario['usuario_' + pais]['cc']"
                    [key]="'cc'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['email']"
                    [(value)]="usuario['email']"
                    [key]="'email'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['password']"
                    [enablePasswordBar]="true"
                    [(value)]="usuario['password']"
                    [key]="'password'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['password_confirmation']"
                    [(value)]="usuario['password_confirmation']"
                    [key]="'password_confirmation'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['telefono']"
                    [(value)]="usuario['usuario_' + pais]['telefono']"
                    [key]="'telefono'"
                ></form-input>
            </div>

            <div class="col-md-6">
                <h3>Datos Académicos</h3>
                <form-input
                    [form]="form"
                    [params]="params['egresado']"
                    [(value)]="usuario['egresado']"
                    [key]="'egresado'"
                ></form-input>

                <label *ngIf="usuario['egresado']" [attr.for]="'establecimiento'">
                    {{ params["establecimiento"].label }} del cual egresó
                </label>
                <label *ngIf="!usuario['egresado']" [attr.for]="'establecimiento'">
                    {{ params["establecimiento"].label }}
                </label>
                <autocomplete
                    [(ngModel)]="usuario['establecimiento']"
                    [formControlName]="'establecimiento'"
                    [options]="filteredEstablecimientos"
                    [placeholder]="'Escribe y selecciona entre las opciones'"
                    (filterChange)="getEstablecimientos($event)"
                    showTextFun="toStringWithIdPais"
                    class="autocomplete"
                ></autocomplete>
                <form-input-error
                    [form]="form"
                    [params]="params['establecimiento']"
                    [value]="usuario['establecimiento']"
                    [key]="'establecimiento'"
                ></form-input-error>

                <div [class.hide-school-info]="usuario['egresado']">
                    <form-input
                        [form]="form"
                        [params]="params['nivel_id']"
                        [(value)]="usuario['nivel_id']"
                        [key]="'nivel_id'"
                    ></form-input>
                </div>

                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuario['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                ></form-input>
                <form-input
                    *ngIf="withPoliticasPrivacidad"
                    [form]="form"
                    [params]="params['politicas_privacidad']"
                    [(value)]="usuario['politicas_privacidad']"
                    [key]="'politicas_privacidad'"
                ></form-input>

                <button (click)="save()" type="button" class="btn btn-default btn-block submit-btn">Registrarte</button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
    minutos más, por favor revisa tu carpeta de spam.
</ng-container>
