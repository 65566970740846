import { AlertasActions, AlertasActionTypes } from "../actions/alertas.actions"
export interface State {
    logros: any[]
}

export const initialState: State = {
    logros: null
}

export function reducer(state = initialState, action: AlertasActions) {
    switch (action.type) {
        case AlertasActionTypes.CrearAlertaLogros: {
            const logros = action.payload.logros
            return { ...state, logros }
        }
        case AlertasActionTypes.ResetAlertaLogros: {
            return initialState
        }
        default:
            return state
    }
}
