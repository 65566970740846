import { Component, Injector, ComponentFactoryResolver } from "@angular/core"
import { ModelViewComponent } from "./model_view.component"

@Component({
    template: `
        <model-plain-value-layout #layoutComponent>
            <div #content></div>
        </model-plain-value-layout>
    `,
    styleUrls: ["model_plain_value.component.scss"],
    selector: "model-plain-value"
})
export class ModelPlainValueComponent extends ModelViewComponent {
    constructor(injector: Injector, resolver: ComponentFactoryResolver) {
        super(injector, resolver)
    }
}
