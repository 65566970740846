import { Component, ViewChild, ElementRef, Renderer2, OnInit, Input } from "@angular/core"
import { BaseModel } from "../api/base.model"
import { View } from "./view.component"
import { MaterialPdfService } from "../util/services/material-pdf.service"

@Component({
    selector: "audioview",
    templateUrl: "audioview.component.html"
})
export class AudioView extends View implements OnInit {
    @Input() url: string

    constructor(private renderer: Renderer2, protected materialPdfService: MaterialPdfService) {
        super()
    }

    ngOnInit() {
        if (!this.url) {
            this.url = this.value[this.key]
        }
    }
}
