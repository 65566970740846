import {
    Component,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    ViewChildren,
    QueryList,
    Input,
    OnInit,
    EventEmitter,
    HostListener,
    OnDestroy
} from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { EvaluacionInstancias } from "@puntaje/puntaje/api-services"
import { ScrollToService, AuthService } from "@puntaje/shared/core"
import { FiltroEstadisticasService } from "@puntaje/puntaje/new-modules/estadisticas"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

import {
    State,
    LoadImages,
    ProcessClickCanvas,
    SubirLecturas,
    ResetHojaRespuestas,
    InitArchivosOriginales,
    InitCanvases,
    ChangeSelectedFile,
    PreviousImage,
    NextImage,
    ChangeFormaGlobal
} from "@puntaje/puntaje/store"
import { Store, select } from "@ngrx/store"
import { SubirRespuestasImagenBaseComponent } from "./subir-respuestas-imagen-base/subir-respuestas-imagen-base.component"
import { ScannerService } from "@puntaje/shared/core"
import { ModalInstructivoSubirRespuestasComponent } from "@puntaje/puntaje/new-modules/shared"

declare let loadImage: any

export enum KEY_CODE {
    RIGHT_ARROW = "ArrowRight",
    LEFT_ARROW = "ArrowLeft",
    LEFT = "Left",
    RIGHT = "Right"
}
@Component({
    selector: "subir-respuestas-imagen-multi",
    templateUrl: "subir_respuestas_imagen_multiple.component.html",
    styleUrls: ["subir_respuestas_imagen_multiple.component.scss"]
})
export class SubirRespuestasImagenMultipleComponent extends SubirRespuestasImagenBaseComponent implements OnDestroy {
    @HostListener("window:keyup", ["$event"])
    KeyEvent(event: KeyboardEvent) {
        if ((event.target as any).tagName.toUpperCase() !== "BODY") return

        this.archivosGood$.subscribe(archivosGood => {
            if (archivosGood && archivosGood.length > 0) {
                switch (event.key) {
                    case KEY_CODE.LEFT: {
                        this.previousImage()
                        break
                    }
                    case KEY_CODE.LEFT_ARROW: {
                        this.previousImage()
                        break
                    }
                    case KEY_CODE.RIGHT: {
                        this.nextImage()
                        break
                    }
                    case KEY_CODE.RIGHT_ARROW: {
                        this.nextImage()
                        break
                    }
                }
            }
        })
    }
    @Input() perfil = "profesor"
    @Input() link_message = "Ver estadística."

    openModal: EventEmitter<any> = new EventEmitter<any>()

    openInstructivoImprimirEvent: EventEmitter<any> = new EventEmitter<any>()

    processedFiles: number = 0
    progressWidth: string = "0%"

    uploadProgressWidth: string = "0%"

    archivosProcesados: any = {
        original: [],
        good: [],
        bad: [],
        notImages: []
    }

    processedAll: boolean = false
    @ViewChildren("canvas") canvases: QueryList<ElementRef>
    canvasesObj = []

    lecturasProcesadas = 0
    lecturasTotal: number
    loadingLecturas: boolean = false
    formaGlobal: number

    enableThreshold: boolean = false
    identificadorUsuario: string = ""

    @ViewChild("loadingLayoutSubir", { static: true }) loadingLayoutUpload: LoadingLayoutComponent
    constructor(
        protected cdr: ChangeDetectorRef,
        protected scrollToService: ScrollToService,
        protected scannerService: ScannerService,
        protected evaluacionesInstanciasService: EvaluacionInstancias,
        protected filtroEstadisticasService: FiltroEstadisticasService,
        protected router: Router,
        protected store: Store<State>,
        protected authService: AuthService
    ) {
        super(evaluacionesInstanciasService, router, scrollToService, scannerService, store, cdr, authService)
    }

    ngOnInit() {
        this.identificadorUsuario = config.hojaRespuesta.usingUserID
            ? "ID de usuario"
            : config.plataforma.identificadorUsuario[0]
        this.loadingLayoutUpload.ready()
    }

    reset() {
        this.store.dispatch(new ResetHojaRespuestas())

        this.progressWidth = "0%"
        this.uploadProgressWidth = "0%"

        this.scrollToService.scrollToTop()
    }

    async subirLecturas() {
        this.loadingLayoutUpload.standby()
        this.store.dispatch(new SubirLecturas({ perfil: this.perfil }))
    }

    onFileInputChange(event: any) {
        this.openInstructivoImprimirEvent.emit(false)

        this.reset()
        this.cdr.detectChanges()
        this.leerImagenes(Array.from(event.target.files))

        const file = event.target
        file.type = ""
        file.type = "file"
    }

    setProgress(current, total) {
        this.progressWidth = (current * 100) / total + "%"
        this.cdr.detectChanges()
    }

    isImage(file: any) {
        return (file.type as any).startsWith("image/")
    }

    leerImagenes(files: Array<any>) {
        if (files && files.length > 0) {
            this.store.dispatch(new InitArchivosOriginales({ files }))
            this.cdr.detectChanges()
            this.store.dispatch(new InitCanvases({ canvases: this.canvases.toArray() }))
            this.store.dispatch(new LoadImages())
            this.scrollToService.scrollTo("step2")
        }
    }

    botonContinuar(obj: any) {
        this.bottonEstadisticas(obj)
    }

    bottonEstadisticas(obj: any) {
        if (obj.datosBoton) {
            let b = obj.datosBoton
            this.filtroEstadisticasService.setFiltrosId(
                b.establecimiento_id,
                null,
                null,
                b.evaluacion_id,
                b.generador_instrumento_id,
                config.plataforma.evaluacionDefault,
                b.asignatura_id
            )
            this.router.navigate(["/estadisticas"], { queryParams: { asignatura_id: b.asignatura_id } })
        }
    }

    scrollToStep2() {
        this.scrollToService.scrollTo("step2")
    }

    onClickCanvasWithIndex(canvasIndex, event) {
        this.store.dispatch(new ProcessClickCanvas({ index: canvasIndex, event }))

        this.cdr.detectChanges()
    }

    previousImage() {
        this.store.dispatch(new PreviousImage())
    }

    nextImage() {
        this.store.dispatch(new NextImage())
    }

    changeSelectedFile(selectedFile) {
        this.store.dispatch(new ChangeSelectedFile({ selectedFile: +selectedFile }))
    }

    changeFormaGlobal(formaGlobal) {
        this.store.dispatch(new ChangeFormaGlobal({ formaGlobal }))
    }

    ngOnDestroy() {
        this.subScrollToStep2.unsubscribe()
        this.subLecturasProcesadas.unsubscribe()
    }
}
