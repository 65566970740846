<ul class="valuechart">
    <ng-template [ngIf]="isEmpty">
        <li class="emptyData" tooltip="sin información">&nbsp;</li>
    </ng-template>
    <ng-template [ngIf]="!isEmpty">
        <li
            [class]="classes[i]"
            *ngFor="let e of percentageValues; let i = index"
            [style.width]="e | percentageFloor"
            [tooltip]="e | percentage"
        >
            &nbsp;
        </li>
    </ng-template>
</ul>
