import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AsignaturaEvaluacionTipoEstablecimiento } from "./asignatura_evaluacion_tipo_establecimientos.model"
import { EvaluacionTipo } from "./evaluacion_tipos.model"
import { AsignaturaMaterialClasificacionTipo } from "./asignatura_material_clasificacion_tipos.model"
import { AsignaturaMaterialNivel } from "./asignatura_material_niveles.model"

export class AsignaturaEvaluacionTipo extends BaseModel {
    public evaluacion_tipo_id: number
    public asignatura_id: number
    public abreviacion: string
    public asignatura_plataforma: string
    public clase: string
    public icono: string
    public material_visible: boolean

    public solo_contenido: boolean
    public with_items: boolean
    public with_evaluaciones_recomendadas: boolean
    public with_buscador_en_generador: boolean
    public with_pruebas_propias: boolean
    public with_reforzamientos: boolean
    public with_planes_de_clase: boolean
    public created_at: Date
    public predefinido: boolean
    public plataforma: string

    @SaveType(() => EvaluacionTipo) public evaluacion_tipo: EvaluacionTipo
    @SaveType(() => Asignatura) public asignatura: Asignatura
    @SaveType(() => AsignaturaMaterialClasificacionTipo)
    public asignatura_material_clasificacion_tipos: AsignaturaMaterialClasificacionTipo[]
    @SaveType(() => AsignaturaMaterialNivel) public asignatura_material_niveles: AsignaturaMaterialNivel[]
    @SaveType(() => AsignaturaEvaluacionTipoEstablecimiento)
    public asignatura_evaluacion_tipo_establecimientos: AsignaturaEvaluacionTipoEstablecimiento[]
    @SaveType(() => AsignaturaEvaluacionTipoEstablecimiento)
    public asignatura_evaluacion_tipo_establecimiento: AsignaturaEvaluacionTipoEstablecimiento

    constructor(empty = false) {
        super()

        if (!empty) {
            this.asignatura_material_clasificacion_tipos = []
            this.asignatura_material_niveles = []
        }
    }
}
