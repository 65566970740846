<!-- dirty stuff -->
<ng-template
    let-indexActual
    [ngTemplateOutletContext]="{ $implicit: seeingIndex$ | async }"
    [ngTemplateOutlet]="self"
    #self
>
    <loading-layout>
        <ng-container *ngIf="items$ | async as items">
            <ng-container *ngIf="grupoPreguntaItems$ | async as grupoPreguntaItems">
                <ng-container *ngFor="let item of items; let i = index">
                    <div
                        [class.pregunta-hide]="i != indexActual || lastStep"
                        [class.current-content]="i == indexActual"
                    >
                        <ng-container [ngSwitch]="item.tipo">
                            <ng-container *ngSwitchCase="'pregunta'">
                                <div class="preguntas-container" [class.show-preguntas-container]="displayPreguntas">
                                    <pregunta-ensayo
                                        #preguntaEnsayo
                                        [grupoPreguntaItem]="grupoPreguntaItems[+$any(item.item).grupo_pregunta.id]"
                                        [pregunta]="$any(item.item)"
                                        [index]="item.numero"
                                        [enableDudaBtn]="enableDudasBtn"
                                        [enablePreguntaId]="enablePreguntasId"
                                        [showComplains]="false"
                                        [enableBtnMarkAsUncertain]="enableBtnMarkAsUncertain"
                                        [disableOmitida]="true"
                                        [enableCompactView]="true"
                                        [enableReporte]="enableReporte"
                                        [evaluacionTipo]="evaluacion.evaluacion_tipo.evaluacion_tipo"
                                        [generadorInstrumento]="evaluacion.instrumento.generador_instrumento"
                                        (onToggleDuda)="next()"
                                        class="clearfix"
                                    ></pregunta-ensayo>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'instruccion'">
                                <div class="instruccion" [innerHTML]="$any(item.item).instruccion"></div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'confirmacion'">
                                <div class="instruccion">
                                    <h3 class="msg-entrega">{{ "evaluacion_secuencial.pregunta_completar" | t }}</h3>
                                    <br />
                                    <div class="submit-btns">
                                        <cui-button class="btn_style big" (click)="cancelLastStep()">
                                            <fa name="arrow-left"></fa>
                                            {{ "evaluacion_secuencial.no_completar" | t }}
                                        </cui-button>
                                        <cui-button class="btn_style big" *ngIf="item.last" (click)="entregarEnsayo()">
                                            <ng-container *ngIf="textoCompletar; else sinTextoCompletar">
                                                {{ textoCompletar }}
                                            </ng-container>
                                            <ng-template #sinTextoCompletar>
                                                <ng-container
                                                    *ngIf="
                                                        completarButton == 'completar' || !completarButton;
                                                        else customCompletar
                                                    "
                                                >
                                                    {{ "evaluacion_secuencial.si_completar" | t }}
                                                </ng-container>
                                                <ng-template #customCompletar>
                                                    {{
                                                        "evaluacion_secuencial.si_completar_param"
                                                            | t: { completar: completarButton }
                                                    }}
                                                </ng-template>
                                            </ng-template>

                                            <fa name="arrow-right"></fa>
                                        </cui-button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="currentItem$ | async as currentItem">
            <nav [ngClass]="onlyCB ? 'navbar-submit-btns center' : 'navbar-submit-btns'" *ngIf="!loading">
                <cui-button class="btn_style" *ngIf="!currentItem.first && !currentItem.last" (click)="prev()">
                    <fa name="chevron-left"></fa>
                    {{ "evaluacion_secuencial.anterior" | t }}
                </cui-button>
                <nav class="inner-navbar" *ngIf="enableDudasBtn && showDudaButtons && !currentItem.last">
                    <cui-button buttonType="confirm" (click)="prevDuda()" class="btn_style_3">
                        <fa name="arrow-left" class="icono left"></fa>
                        {{ "evaluacion_secuencial.duda_anterior" | t }}
                        <fa name="question-circle" class="icono right"></fa>
                    </cui-button>

                    <cui-button buttonType="confirm" (click)="nextDuda()" class="btn_style_3">
                        {{ "evaluacion_secuencial.duda_siguiente" | t }}
                        <fa name="question-circle" class="icono right"></fa>
                        <fa name="chevron-right" class="icono right"></fa>
                    </cui-button>
                </nav>
                <cui-button
                    [ngClass]="onlyCB && !currentItem.last ? 'btn-derecha no-margin' : 'btn-derecha'"
                    class="btn_style"
                    *ngIf="!currentItem.last"
                    (click)="next()"
                >
                    {{ "evaluacion_secuencial.siguiente" | t }}
                    <fa name="chevron-right" class="icono right"></fa>
                </cui-button>
            </nav>
        </ng-container>
    </loading-layout>
</ng-template>
