import { BaseModel } from "@puntaje/shared/core"

export class EstadoReporte extends BaseModel {
    public static className: string = "EstadoReporte"
    public id: number
    public estado: string
    public created_at: Date
    public updated_at: Date

    public toString() {
        return this.estado
    }

    constructor() {
        super()
    }
}
