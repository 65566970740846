import { BaseModel } from "@puntaje/shared/core"

export class UsuarioColombia extends BaseModel {
    public ti: string
    public cc: string
    public telefono: string
    public usuario_id: number

    constructor() {
        super()
    }

    public toString() {
        return this.ti + "/" + this.cc
    }

    get tiOrCc() {
        return this.ti || this.cc || "-"
    }
}
