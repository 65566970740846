import { Pipe, PipeTransform } from "@angular/core"

@Pipe({ name: "clearLatex" })
export class ClearLatexPipe implements PipeTransform {
    transform(obj: any) {
        if (!obj || !obj.replace) {
            return null
        }
        let listStrings = [
            "\\\\f\\]",
            "\\\\f\\[",
            "\\\\'a",
            "\\\\'e",
            "\\\\'i",
            "\\\\'o",
            "\\\\'u",
            "\\\\'A",
            "\\\\'E",
            "\\\\'I",
            "\\\\'O",
            "\\\\'U",
            "\\?`",
            "\\\\~n",
            "&nbsp;",
            "&lt;",
            "&gt;",
            "\\\\\\[",
            "\\\\\\]",
            "\\\\\\(",
            "\\\\\\)"
        ]

        let mapObj = {
            "\\f]": "",
            "\\f[": "",
            "\\'a": "á",
            "\\'e": "é",
            "\\'i": "í",
            "\\'o": "ó",
            "\\'u": "ú",
            "\\'A": "Á",
            "\\'E": "É",
            "\\'I": "Í",
            "\\'O": "Ó",
            "\\'U": "Ú",
            "?`": "¿",
            "\\~n": "ñ",
            "&nbsp;": " ",
            "&lt;": "<",
            "&gt;": ">",
            "\\[": "$$",
            "\\]": "$$",
            "\\(": "$",
            "\\)": "$"
        }
        let re = new RegExp(listStrings.join("|"), "g")

        return obj
            .replace(re, function (matched) {
                return mapObj[matched]
            })
            .replace(/\\mbox\{([^\}]*)\}/g, "\\text{$1}")
    }
}
