import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { LogroUsuarioPlataformas, UsuarioPlataforma, UsuarioPlataformas } from "@puntaje/achievements/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "span[total-logros]",
    template: `
        <span>{{ total }}</span>
    `
})
export class TotalLogrosComponent implements OnInit {
    usuarioPlataforma: UsuarioPlataforma
    total: string = "-"

    constructor(
        protected authService: AuthService,
        protected usuarioPlataformasService: UsuarioPlataformas,
        protected logroUsuarioPlataformasService: LogroUsuarioPlataformas
    ) {}

    ngOnInit() {
        this.getLogros()
    }

    getLogros() {
        const paramsUltimosLogros = {
            usuario_plataforma: {
                usuario_id: this.authService.getUserData().id
            },
            plataforma: {
                plataforma: config.plataforma.name
            },
            count: ""
        }

        this.logroUsuarioPlataformasService.where(paramsUltimosLogros).then((logroUsuarioPlataformas, count) => {
            this.total = count == 0 ? "-" : count + ""
        })
    }
}
