import { Action } from "@ngrx/store"
import { Asignatura, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { AsignaturaEvaluacionTipo } from "@puntaje/puntaje/api-services"

export enum AsignaturasActionTypes {
    LoadAsignaturas = "[Asignaturas] Load Asignaturas",
    GetAsignaturasConfig = "[Asignaturas] Get Asignaturas",
    GetAsignaturasConfigSuccess = "[Asignaturas] Get Asignaturas Success",
    GetAsignaturasConfigSuccessOld = "[Asignaturas] Get Asignaturas Success Old",
    SetSelectedAsignatura = "[Asignaturas] Set Selected Asignatura",
    SetSelectedAsignaturaWithId = "[Asignaturas] Set Selected Asignatura With Id",
    SetSelectedAsignaturaEvaluacionTipo = "[Asignaturas] Set Selected AsignaturaEvaluacionTipo",
    CheckAsignaturaInEvaluacionTipo = "[Asignaturas] Check Asignatura In EvaluacionTipo"
}

export class LoadAsignaturas implements Action {
    readonly type = AsignaturasActionTypes.LoadAsignaturas
}

export class GetAsignaturasConfig implements Action {
    readonly type = AsignaturasActionTypes.GetAsignaturasConfig
}

export class GetAsignaturasConfigSuccess implements Action {
    readonly type = AsignaturasActionTypes.GetAsignaturasConfigSuccess

    constructor(public payload: { asignaturas: Asignatura[]; asignaturaEvaluacionTipos: AsignaturaEvaluacionTipo[] }) {}
}

export class GetAsignaturasConfigSuccessOld implements Action {
    readonly type = AsignaturasActionTypes.GetAsignaturasConfigSuccessOld

    constructor(public payload: Asignatura[]) {}
}

export class SetSelectedAsignatura implements Action {
    readonly type = AsignaturasActionTypes.SetSelectedAsignatura

    constructor(public payload: { selectedAsignatura: AsignaturaWithConfig }) {}
}

export class SetSelectedAsignaturaWithId implements Action {
    readonly type = AsignaturasActionTypes.SetSelectedAsignaturaWithId

    constructor(public payload: { asignaturaId: number }) {}
}

export class SetSelectedAsignaturaEvaluacionTipo implements Action {
    readonly type = AsignaturasActionTypes.SetSelectedAsignaturaEvaluacionTipo

    constructor(public payload: { asignaturaEvaluacionTipo: AsignaturaEvaluacionTipo }) {}
}

export class CheckAsignaturaInEvaluacionTipo implements Action {
    readonly type = AsignaturasActionTypes.CheckAsignaturaInEvaluacionTipo

    constructor(public payload: { evaluacionTipo: string }) {}
}

export type AsignaturasActions =
    | LoadAsignaturas
    | GetAsignaturasConfig
    | GetAsignaturasConfigSuccess
    | GetAsignaturasConfigSuccessOld
    | SetSelectedAsignatura
    | SetSelectedAsignaturaWithId
    | SetSelectedAsignaturaEvaluacionTipo
    | CheckAsignaturaInEvaluacionTipo
