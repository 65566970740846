import { BaseModel, BooleanView, DateView, DownloadView, SaveType, TextView } from "@puntaje/shared/core"
import { Ayudas } from "./ayudas.service"
import { AyudaPlataforma } from "./ayuda_plataformas.model"
import { Clasificacion } from "./clasificaciones.model"
import { MaterialTipo } from "./material_tipos.model"
import { MaterialTipos } from "./material_tipos.service"
import { Plataforma } from "./plataformas.model"

export class Ayuda extends BaseModel {
    public static className: string = "Ayuda"
    public material_tipo_id: number
    public ayuda: string
    public visible: boolean
    public comentarios: string
    public url: string
    public contenido: string
    public created_at: Date
    public updated_at: Date
    public file: File
    public className = "Ayuda"
    public profesor_id: number
    public cantidad_reportes: number
    public is_html: boolean
    public target_app: string

    public material_tipo_nombre: string
    @SaveType(() => AyudaPlataforma) public ayuda_plataformas: AyudaPlataforma[]
    @SaveType(() => Plataforma) public plataformas: Plataforma[]
    @SaveType(() => MaterialTipo) public material_tipo: MaterialTipo[]
    @SaveType(() => Clasificacion) public clasificaciones: Clasificacion[]

    constructor() {
        super()

        this.clasificaciones = []
        this.plataformas = []
        this.ayuda_plataformas = []
    }

    public static get show_params(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            material_tipo_nombre: {
                label: "Tipo de Ayuda",
                type: TextView,
                tableVisible: true,
                options: { class: MaterialTipos, key: "material_tipo_id" }
            },
            ayuda: { label: "Nombre ayuda", type: TextView, tableVisible: true },
            visible: { label: "Visible", type: BooleanView, tableVisible: true },
            comentarios: { label: "Comentarios", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true },
            download: { label: "Link", type: DownloadView, tableVisible: true, options: { class: Ayudas } }
        }
    }

    public toString() {
        return this.ayuda
    }

    public getExtension() {
        return this.url.split(".").pop()
    }

    public getS3Params() {
        return this.file
            ? {
                  key: this.file.name,
                  model_name: "ayuda"
              }
            : {}
    }

    public isFile() {
        if (this.url) {
            return this.url.split("/").pop().split(".").length > 1
        } else return false
    }

    public addPlataforma(id) {
        let ayuda_plataforma = new AyudaPlataforma()
        ayuda_plataforma.plataforma_id = id
        this.ayuda_plataformas.push(ayuda_plataforma)
    }

    public removePlataforma(id) {}
}
