import { Component, Input, OnInit } from "@angular/core"

@Component({
    selector: "tipo-material-icon",
    template: `
        <ogr-icon [name]="icon_name" class="{{ archive_type }}"></ogr-icon>
    `
})
export class TipoMaterialIconComponent implements OnInit {
    @Input("tipo") tipo_material_id: string
    icon_name: string
    archive_type = "t-file"

    ngOnInit() {
        switch (this.tipo_material_id) {
            case "Archivo":
                this.icon_name = "archivo"
                this.archive_type = "t-file"
                break

            case "Video":
                this.icon_name = "video"
                this.archive_type = "t-video"
                break

            case "Streaming":
                this.icon_name = "video"
                this.archive_type = "t-video"
                break

            case "HTML":
                this.icon_name = "archivo"
                this.archive_type = "t-html"
                break

            default:
                this.icon_name = "archivo"
                this.archive_type = "t-file"
                break
        }
    }
}
