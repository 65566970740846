import { SaveType, BaseModel } from "@puntaje/shared/core"
import { PlanEstudioAplicado } from "./plan_estudio_aplicados.model"
import { PlanEstudioSesionAplicadaInstancia } from "./plan_estudio_sesion_aplicada_instancias.model"
import { Usuario } from "./usuarios.model"

export class PlanEstudioAplicadoInstancia extends BaseModel {
    public plan_estudio_aplicado_id: number
    public usuario_id: number
    public estado: number
    public avance: number
    public estrellas: number
    @SaveType(() => PlanEstudioAplicado) public plan_estudio_aplicado: PlanEstudioAplicado
    @SaveType(() => Usuario) public usuario: Usuario
    @SaveType(() => PlanEstudioSesionAplicadaInstancia)
    public plan_estudio_sesion_aplicada_instancias: PlanEstudioSesionAplicadaInstancia[]

    constructor() {
        super()
        this.plan_estudio_aplicado = new PlanEstudioAplicado()
        this.usuario = new Usuario()
        this.plan_estudio_sesion_aplicada_instancias = []
    }

    public toString() {
        return this.plan_estudio_aplicado_id + "," + this.usuario_id
    }
}
