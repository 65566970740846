import { Injectable } from "@angular/core"
import { Subject } from "rxjs"

@Injectable()
export class LogrosBuscadorService {
    searchParams: Subject<any> = new Subject<any>()
    openSearch: Subject<any> = new Subject<any>()

    constructor() {}

    search(logro) {
        let params: any = {}

        logro = Object.assign({}, logro)

        Object.keys(logro).forEach(k => {
            if (logro[k] === "" || logro[k] === undefined) delete logro[k]
        })

        if (logro.id && logro.id.indexOf(",") != -1) {
            logro.id = logro.id.split(/\s*,\s*/)
        }

        if (logro.logro) {
            logro.like = { logro: logro.logro }
            delete logro.logro
        }

        params = {
            logro
        }

        this.searchParams.next(params)
    }
}
