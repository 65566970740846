import { Injectable, Inject } from "@angular/core"
import { Diccionario } from "./diccionario.interface"
/**
 * Servicio para buscar traducciones dado un diccionario
 * y un path
 */
@Injectable()
export class TranslatorService {
    /**
     *
     * @param {Diccionario} diccionario - diccionario
     *        llaves y valores para ser asignados por el servicio
     *
     * @todo Agregar un parametro locale(s) para el soporte de diferentes
     *       idiomas en runtime
     */
    constructor(@Inject("DICCIONARIO") private diccionario: Diccionario) {}

    /**
     * Traduce un path a una frase y reemplaza valores en la frase dado un objeto
     *
     * @param {string} path - path para buscar la frase
     * @param {Object} replaceValues - objeto con mapeo
     *
     * @returns {string} frase del diccionario con valores reemplazados
     * @throws {Error} si el path no existe en el diccionario o si replaceValues
     * no contiene el valor esperado
     */
    public translate(path, ...args: string[]): string {
        let result = this.getDiccionarioObject(path)
        if (result) {
            if (typeof result === "string") {
                const regEx = RegExp("#{([^{}]+)}*", "g")
                let matches,
                    output = []
                while ((matches = regEx.exec(result))) {
                    output.push(matches[1])
                }
                if (output.length > 0 && args) {
                    let i = 0
                    for (let key of output) {
                        result = result.replace("#{" + key + "}", args[i])
                        i++
                    }
                }
                return result
            } else if (typeof result === "function") {
                return result(...args)
            } else {
                return ""
            }
        } else {
            throw Error(path + " is not a valid path in the dicctionary.")
        }
    }

    /**
     * Cuando se soporte es8 se podran usar parametros con nombre
     * y esta funcion sera mas util
     * @param path
     * @param diccionario
     */
    private getDiccionarioObject(path: string, diccionario: Diccionario = this.diccionario) {
        const keys = path.split(".")
        let result = keys.reduce((obj, next) => {
            if (obj && typeof obj !== "function") {
                if (obj[next]) {
                    return obj[next]
                } else {
                    return null
                }
            } else {
                return null
            }
        }, diccionario as string | ((...args: string[]) => string) | Diccionario)
        if (result) {
            return result
        } else {
            return null
        }
    }

    public t = this.translate
}
