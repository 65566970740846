import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Angulartics2 } from "angulartics2"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    Asignatura,
    Asignaturas,
    Instrucciones,
    Material,
    Materiales,
    Pregunta,
    Preguntas
} from "@puntaje/nebulosa/api-services"
import { AuthService, DocsView, PdfView } from "@puntaje/shared/core"
import {
    Evaluacion,
    Evaluaciones,
    EvaluacionInstancia,
    EvaluacionInstancias,
    EvaluacionTiempos,
    Instrumento,
    Instrumentos
} from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"
import { RealizarEnsayoService } from "./realizar_ensayo.service"

declare let MathJax: any

@Component({
    selector: "preguntas-ensayo",
    templateUrl: "preguntas_ensayo.component.html",
    styleUrls: ["preguntas_ensayo.component.scss"]
})
export class PreguntasEnsayoComponent implements OnInit, OnDestroy {
    @Input() tracking: boolean = false
    @Input() evaluacionId: number
    @Input() enableDudas: boolean = true
    @Input() enableReporte: boolean = true
    @Input() enableEntregarGeneric: boolean = false

    @Input() disableWarningOmitidas: boolean = false
    @Input() disableOmitida: boolean = false

    @Input() resultadoUrl = "/ensayo_instancias"
    @Input() resultadoAdaptiveUrl = "/pruebas_de_transicion/encuesta_adaptive"
    @Input() tipoInstrumentoAlias: string

    evaluacion: Evaluacion
    instrumento: Instrumento
    testlet: boolean
    preguntaIds: number[]
    preguntas: Pregunta[] = []
    preguntas$ = this.realizarEnsayoService.preguntas$
    preguntasAreVisible = true

    loadingEvaluacion$ = this.realizarEnsayoService.loadingEvaluacion$
    loadingItems$ = this.realizarEnsayoService.loadingItems$

    asignatura: Asignatura

    contestableIds: number[]
    alternativaIds: (number | { alternativa_texto: string })[]
    tiempos: number[]

    loading = true

    timeSub: Subscription

    displayPreguntas: boolean = false

    tiempoInicial: Date

    @ViewChild("preguntasDiv") preguntasDiv: ElementRef
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @ViewChild("secondLoadingLayout") secondLoadingLayout: LoadingLayoutComponent
    // @Input() onSelectPregunta: EventEmitter<any>;
    @Input() tipoEvaluacion: string
    @Input() displayBtnEntregarEnsayo: boolean = true
    @Input() enableRedirectAfterDone: boolean = true
    @Input() enableDudasBtn: boolean = false
    @Input() enablePreguntasId: boolean = false
    @Input() enableTimeframeAlert: boolean = false
    timeframeAlert: boolean = false
    timeoutAlert: boolean = false
    dateNow: Date
    enableItsNotTimeYet: boolean = false
    itsNotTimeYetMessage: string
    btnEntregarEnsayoDisabled: boolean = false
    fileParams: any = {}

    material: Material

    letrasAlternativas: string[]

    unpermitted: boolean = false

    evaluacionSub: Subscription
    materialSub: Subscription
    readySub: Subscription
    preguntasSub: Subscription
    loadingItemsSub: Subscription

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected evaluacionesService: Evaluaciones,
        protected instrumentosService: Instrumentos,
        protected preguntasService: Preguntas,
        protected instruccionesService: Instrucciones,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected authService: AuthService,
        protected materialesService: Materiales,
        @Optional() public angulartics2: Angulartics2,
        public asignaturasService: Asignaturas,
        public realizarEnsayoService: RealizarEnsayoService,
        public evaluaciontiempoService: EvaluacionTiempos,
        protected auth: AuthService,
        protected simpleModalService: SimpleModalService,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {
        this.realizarEnsayoService.setTracking(this.tracking)
        this.realizarEnsayoService.setEntregarEnsayo(this.entregarEnsayo.bind(this))

        this.unpermitted = false

        this.evaluacionSub = this.realizarEnsayoService.evaluacion$.subscribe(evaluacion => {
            this.evaluacion = evaluacion
            this.instrumento = evaluacion.instrumento
            if (this.instrumento.adaptive) {
                if (this.instrumento.instrumento_preguntas.length < 13) {
                    this.testlet = true
                } else {
                    this.testlet = false
                }
            } else {
                this.testlet = false
            }
        })

        this.materialSub = this.realizarEnsayoService.material$.subscribe(material => {
            this.material = material
            this.letrasAlternativas = this.realizarEnsayoService.getLetrasAlternativas()
            this.getFileParams()
        })

        this.realizarEnsayoService
            .load(this.evaluacionId)
            .then(() => {
                this.loadingLayout.ready()

                this.checkTimeframe()
            })
            .catch(response => {
                this.itsNotTimeYetMessage = this.i18nService.translate("preguntas_ensayo.not_yet")
                if (response && response.status == 403) {
                    this.unpermitted = true
                } else {
                    this.enableItsNotTimeYet = true
                    if (response.json && response.message && response.show_message) {
                        this.itsNotTimeYetMessage = response.message
                    }
                }
            })

        if (config.plataforma.disableWarningOmitidas) this.disableWarningOmitidas = true

        this.readySub = this.realizarEnsayoService.ready.subscribe(() => {
            this.displayPreguntas = true
        })

        console.debug(this.disableWarningOmitidas)
        this.preguntasSub = this.preguntas$.subscribe(preguntas => {
            this.preguntas = preguntas

            this.realizarEnsayoService.onReady()
        })

        this.loadingItemsSub = this.loadingItems$.subscribe(loading => {
            if (!loading) {
            }
        })
    }

    getFileParams() {
        if (this.material.getExtension() == "docx" || this.material.getExtension() == "doc") {
            this.fileParams = { label: this.i18nService.translate("preguntas_ensayo.previsualizar"), type: DocsView }
        } else {
            this.fileParams = { label: this.i18nService.translate("preguntas_ensayo.previsualizar"), type: PdfView }
        }
    }

    entregarEnsayo(click = false, insideSesion = false) {
        this.btnEntregarEnsayoDisabled = true

        let params = {
            evaluacion_tiempo: {
                usuario_id: this.auth.getUserData().id,
                evaluacion_id: this.evaluacion.id
            }
        }
        this.evaluaciontiempoService.enableIgnoreCatch()
        this.evaluaciontiempoService
            .where(params)
            .then(response => {
                if (response.length > 0) {
                    if (!click) {
                        this.preguntasAreVisible = false
                    }

                    this.realizarEnsayoService
                        .entregarEnsayo(click, insideSesion)
                        .then((ei: EvaluacionInstancia) => {
                            if (this.evaluacion.instrumento.adaptive) {
                                let len = this.instrumento.instrumento_preguntas.length
                                if (len < 13) {
                                    this.evaluacionesService.adaptive(this.evaluacion).then((e: Evaluacion) => {
                                        window.location.reload()
                                    })
                                } else {
                                    this.router.navigate([this.resultadoAdaptiveUrl, ei.id])
                                }
                            } else if (this.enableRedirectAfterDone) {
                                this.router.navigate([this.resultadoUrl, ei.id])
                            }
                        })
                        .catch(response => {
                            this.loadingLayout.ready()
                            this.timeoutAlert = true
                            this.btnEntregarEnsayoDisabled = false
                            this.displayBtnEntregarEnsayo = false
                            this.preguntasAreVisible = false
                        })
                } else {
                    this.showModalAdvertencia()
                    this.btnEntregarEnsayoDisabled = false
                }
            })
            .catch(response => {
                return this.realizarEnsayoService.openModalEnsayoError(response)
            })
            .finally(() => {
                this.evaluaciontiempoService.disableIgnoreCatch()
            })
    }

    toggleDisplayPreguntas(value: boolean) {
        this.displayPreguntas = value
    }

    ngOnDestroy() {
        if (this.timeSub) {
            this.timeSub.unsubscribe()
        }

        this.realizarEnsayoService.setEntregarEnsayo(null)
        this.readySub.unsubscribe()
        this.evaluacionSub.unsubscribe()
        this.materialSub.unsubscribe()
    }

    checkTimeframe() {
        if (this.evaluacion.fecha_inicio && this.evaluacion.fecha_termino) {
            this.dateNow = new Date()
            let now = (this.dateNow as any).getTime()
            let inicio = (new Date(this.evaluacion.fecha_inicio as string) as any).getTime()
            let termino = (new Date(this.evaluacion.fecha_termino as string) as any).getTime()
            this.timeframeAlert = !(inicio <= now && now <= termino)
        }
    }

    showModalAdvertencia() {
        this.simpleModalService.setModalHeader(this.i18nService.translate("preguntas_ensayo.advertencia"))
        this.simpleModalService.setDisableBtnCancel(true)
        this.simpleModalService.setModalStringContent(
            this.i18nService.translate("preguntas_ensayo.not_yet_profesor_tiempo")
        )
        this.simpleModalService.setModalCallback(() => this.router.navigate(["/notificaciones"]))
        this.simpleModalService.showSimpleModal()
    }
}
