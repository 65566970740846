import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    SimpleChanges,
    ChangeDetectorRef,
    OnChanges,
    ViewChild,
    ElementRef
} from "@angular/core"
import { Comentario } from "@puntaje/puntaje/api-services"
import { ScrollToService } from "@puntaje/shared/core"

@Component({
    selector: "comentarios-list",
    templateUrl: "comentarios_list.component.html",
    styleUrls: ["comentarios_list.component.scss"]
})
export class ComentariosListComponent implements OnChanges {
    @Input() dudaRespuestaId: number
    @Input() comentarios: Comentario[]
    @Output() comentariosChange: EventEmitter<boolean> = new EventEmitter<boolean>()
    @ViewChild("comentarioNuevo", { read: ElementRef, static: true }) comentarioNuevo: ElementRef
    showCommentNew: boolean = false
    _showAll: boolean = false

    constructor(private cdr: ChangeDetectorRef, protected scrollToService: ScrollToService) {}

    ngOnChanges(changes: SimpleChanges) {
        this.cdr.detectChanges()
    }

    onSaveDoneListener(value: any) {
        this.comentariosChange.emit(true)
    }

    toggleCommentNew() {
        this.showCommentNew = !this.showCommentNew
    }

    forceCommentNew() {
        this.showCommentNew = true
        this.scrollToService.scrollTo(this.comentarioNuevo)
    }

    onCancelListener(value: any) {
        this.toggleCommentNew()
    }

    showAll() {
        this._showAll = !this._showAll
    }
}
